import {
  sideDrawerNetworkListReducer,
  SideDrawerNetworkListState,
} from './side-drawer-network-list.reducer';
import { ActionReducerMap } from '@ngrx/store';
import {
  sideDrawerNetworksRequestsReducer,
  SideDrawerNetworksRequestsState,
} from './side-drawer-networks-requests.reducer';
import {
  sideDrawerNetworksRequestsInfiniteListReducer,
  SideDrawerNetworksRequestsInfiniteListState,
} from './side-drawer-networks-requests-infinite-list.reducer';

export const featureName = 'sidedrawer-networks';

export interface SideDrawerNetworkState {
  list: SideDrawerNetworkListState;
  requests: SideDrawerNetworksRequestsState;
  infiniteList: SideDrawerNetworksRequestsInfiniteListState;
}

export const sideDrawerNetworkReducer: ActionReducerMap<SideDrawerNetworkState> =
  {
    list: sideDrawerNetworkListReducer,
    requests: sideDrawerNetworksRequestsReducer,
    infiniteList: sideDrawerNetworksRequestsInfiniteListReducer,
  };
