import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Option } from '../../models/option.model';

@Component({
  selector: 'app-sd-selector',
  templateUrl: './sd-selector.component.html',
  styleUrls: ['./sd-selector.component.scss'],
})
export class SdSelectorComponent {
  @Input() options: Option[];
  @Input() placeholder: string;
  @Input() controller: UntypedFormControl;
  @Input() error: string;
  @Input() withoutThis: boolean;
  @Input() fullOutput = false;
  @Output() selection = new EventEmitter<string | Option>();
}
