import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable, map, EMPTY, mergeMap } from 'rxjs';
import { FileItem } from '../models/file-item.model';
import { PaginatorService } from 'src/app/core/services/paginator.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/reducers';
import { FilesHelper } from '../helpers/files.helper';
import { PaginatorApiResponse } from 'src/app/core/models/paginator-api-response.dto';
import { MatDialog } from '@angular/material/dialog';
import { DeleteFileDialogComponent } from '../shared/delete-file-dialog/delete-file-dialog.component';
import { ConfirmDialogModel } from '../shared/delete-file-dialog/model/confirm-dialog.enum';

export interface FileHistoryManageState {
  files: FileItem[];
  data: PaginatorApiResponse<FileItem>;
}

@Injectable()
export class FileHistoryManageStore extends ComponentStore<FileHistoryManageState> {
  confirmDialogModel = ConfirmDialogModel;
  files = this.selectSignal(state => state.files);

  readonly confirmDeleteSelectedFiles = this.effect(
    (
      params$: Observable<{
        title: string;
        description: string;
        numberOfFilesSelected?: string;
        labelOfFilesSelected?: string;
        files?: FileItem[];
        callback?: (response: boolean) => void;
      }>
    ) =>
      params$.pipe(
        mergeMap(payload => {
          this.dialog
            .open(DeleteFileDialogComponent, {
              autoFocus: false,
              data: {
                title: payload?.title,
                description: payload?.description,
                numberOfFilesSelected: payload?.numberOfFilesSelected,
                labelOfFilesSelected: payload?.labelOfFilesSelected,
                template: this.confirmDialogModel.deleteMultipleFiles,
              },
            })
            .afterClosed()
            .subscribe(confirm => {
              if (confirm) {
                if (payload.callback) {
                  payload.callback(confirm);
                }
              }
            });

          return EMPTY;
        })
      )
  );

  readonly getAllFiles = this.effect(
    (
      params$: Observable<{
        recordId: string;
        sideDrawerId: string;
        callback?: (files: PaginatorApiResponse<FileItem>) => void;
        config?: {
          nextPage?: string;
        };
      }>
    ) =>
      params$.pipe(
        mergeMap(payload =>
          this.paginatorService
            .getPaginatedResource<FileItem>(
              FilesHelper.getFileHistoryResourceUrl(
                payload.sideDrawerId,
                payload.recordId,
                {
                  nextPage: payload?.config?.nextPage,
                }
              )
            )
            .pipe(
              map(response => ({
                ...response,
                data: response.data.map(fileItem =>
                  FilesHelper.getCloudStorageFileInformationFromUrl({
                    ...fileItem,
                    sideDrawerId: payload.sideDrawerId,
                    recordId: payload.recordId,
                  })
                ),
              })),
              map(response => {
                if (!this.get()?.files) {
                  this.setInitialFiles(response);
                } else if (
                  !!this.get()?.files &&
                  this.get()?.files?.length > 0
                ) {
                  this.setMoreFiles(response);
                }
                this.hasMoreFiles(payload, response);
              })
            )
        )
      )
  );

  private setInitialFiles(response): void {
    this.patchState({
      files: response?.data,
      data: response,
    });
  }

  private setMoreFiles(response): void {
    this.patchState({
      files: {
        ...this.get()?.files?.concat(response.data),
      },
      data: {
        ...this.get()?.data,
        data: this.get()?.data?.data?.concat(response.data),
      },
    });
  }

  clearFilesFromStore(): void {
    this.patchState({
      files: null,
      data: null,
    });
  }

  private hasMoreFiles(
    payload: {
      recordId: string;
      sideDrawerId: string;
      callback?: (files: PaginatorApiResponse<FileItem>) => void;
      config?: {
        nextPage?: string;
      };
    },
    response: PaginatorApiResponse<FileItem>
  ): Observable<never> {
    if (!response?.hasMore) {
      if (payload?.callback) {
        payload.callback(this.get()?.data);
      }
      return EMPTY;
    }

    if (response?.hasMore) {
      this.getAllFiles({
        recordId: payload.recordId,
        sideDrawerId: payload.sideDrawerId,
        config: {
          nextPage: response?.nextPage,
        },
        callback: payload.callback,
      });
    }
  }

  constructor(
    private paginatorService: PaginatorService,
    private store: Store<AppState>,
    private dialog: MatDialog
  ) {
    super({ files: null, data: null });
  }
}
