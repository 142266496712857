import { SdBreadcrumbsA11y } from '../model/sd-breadcrumbs-a11y.model';
import {
  SdBreadCrumbsA11yActions,
  SdBreadCrumbsA11yActionsTypes,
} from './sd-breadcrumbs-a11y.actions';

export const featureNameSdBreadcrumbsA11y = 'sd-breadcrumbs-a11y';

export interface SdBreadCrumbsA11yState {
  breadCrumbs: SdBreadcrumbsA11y[];
}

export const initialState: SdBreadCrumbsA11yState = {
  breadCrumbs: [],
};

export function sdBreadcrumbsA11yReducer(
  state: SdBreadCrumbsA11yState = initialState,
  action: SdBreadCrumbsA11yActions
): SdBreadCrumbsA11yState {
  switch (action.type) {
    case SdBreadCrumbsA11yActionsTypes.SetBreadCrumbs: {
      return {
        ...state,
        breadCrumbs: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
