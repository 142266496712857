import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/reducers';
import { SideDrawer } from 'src/app/sidedrawer/models/side-drawer.model';
import {
  activeSideDrawerSelector,
  basePathSelector,
} from 'src/app/sidedrawer/store/sidedrawer.selector';
import { SdBreadcrumbsA11y } from '../../model/sd-breadcrumbs-a11y.model';
import {
  SetBreadCrumbs,
  SetBreadCrumbsWithRouteStrategy,
} from '../../store/sd-breadcrumbs-a11y.actions';
import { sdBreadCrumbsA11ySelector } from '../../store/sd-breadcrumbs-a11y.selector';
import { currentBrandingSelector } from '../../../../core/store/core.selectors';
import { map } from 'rxjs/operators';
import { SidedrawerRoutesEnum } from '../../../../sidedrawer/routes/sidedrawer-routes.enum';
import { LandingRoutes } from '../../../../landing/routes/landing.routes';

@Component({
  selector: 'app-sd-breadcrumbs-a11y',
  templateUrl: './sd-breadcrumbs-a11y.component.html',
  styleUrls: ['./sd-breadcrumbs-a11y.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SdBreadcrumbsA11yComponent {
  breadCrumbs: SdBreadcrumbsA11y[];
  @Input() isMyAccount = false;
  breadCrumbs$: Observable<SdBreadcrumbsA11y[]> = this.store.pipe(
    select(sdBreadCrumbsA11ySelector)
  );
  activeSideDrawer$: Observable<SideDrawer> = this.store.select(
    activeSideDrawerSelector
  );
  basePath$: Observable<string> = this.store.select(basePathSelector);
  homePath$: Observable<string> = this.store
    .select(currentBrandingSelector)
    .pipe(
      map(brand => brand.hideSummary),
      map(hideSummary =>
        hideSummary ? SidedrawerRoutesEnum.root : LandingRoutes.root
      )
    );

  constructor(private readonly store: Store<AppState>) {
    if (!this.breadCrumbs) {
      this.store.dispatch(new SetBreadCrumbsWithRouteStrategy());
    }
  }

  @Input() set setBreadCrumbs(value: SdBreadcrumbsA11y[]) {
    this.breadCrumbs = value;
    this.store.dispatch(new SetBreadCrumbs(this.breadCrumbs));
  }
}
