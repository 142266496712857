import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { Injectable } from '@angular/core';
import { Record } from '../../models/record.model';
import { RecordsService } from '../../services/records.service';
import { exhaustMap, forkJoin, Observable, of, switchMap } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { activeRecordTypeSelector } from '../../store/records-list.selectors';
import { map, take, tap } from 'rxjs/operators';
import { localeDefaultSelector } from '../../../dictionary/store/dictionary.selectors';
import { Locale } from '../../../dictionary/models/locale.model';
import { activeSideDrawerSelector } from '../../../sidedrawer/store/sidedrawer.selector';
import {
  RecordsListRequested,
  SetActiveRecordFail,
} from '../../store/records-list.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { SideDrawerHomeOnBackgroundRequested } from '../../../sidedrawer/store/sidedrawer.actions';
import { RecordType } from '../../models/record-type.model';
import { RecordSubType } from '../../models/record-sub-type.model';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { RecordRenameAndChangeTypeDialogComponent } from './record-rename-and-change-type-dialog.component';

export interface RecordRenameAndChangeTypeViewState {
  gettingInformation: boolean;
  record: Record;
  form: UntypedFormGroup;
  subTypeSelected: RecordSubType;
  dialogRef: MatDialogRef<RecordRenameAndChangeTypeDialogComponent>;
}

@Injectable()
export class RecordRenameAndChangeTypeStore extends ComponentStore<RecordRenameAndChangeTypeViewState> {
  readonly gettingInformation$ = this.select(state => state.gettingInformation);
  readonly record$ = this.select(state => state.record);
  readonly form$ = this.select(state => state.form);
  readonly subTypeSelected$ = this.select(state => state.subTypeSelected);
  readonly dialog$ = this.select(state => state.dialogRef);

  readonly updateRecord = this.effect(
    (
      params$: Observable<{
        callback: (
          record: Record,
          sideDrawerId?: string,
          locale?: string
        ) => void;
      }>
    ) =>
      params$.pipe(
        tap(() => this.patchState({ gettingInformation: true })),
        switchMap(callback =>
          forkJoin([
            this.store.pipe(select(activeSideDrawerSelector), take(1)),
            this.store.pipe(select(activeRecordTypeSelector), take(1)),
            this.store.pipe(select(localeDefaultSelector), take(1)),
            this.select(state => state).pipe(take(1)),
            of(callback),
          ])
        ),
        map(([activeSideDrawer, recordType, locale, state, params]) => ({
          sideDrawerId: activeSideDrawer.id,
          recordId: state.record.id,
          record: this.generateRecordDto(this.record?.recordType ?? recordType),
          locale: Locale.getLocaleId(locale),
          params,
        })),
        exhaustMap(({ sideDrawerId, recordId, record, locale, params }) =>
          this.recordsService
            .updateRecord2(sideDrawerId, recordId, record)
            .pipe(
              tapResponse(
                ({ id }) => {
                  this.patchState({ gettingInformation: false });
                  const newRecord = { ...record, id };
                  this.record = newRecord;
                  if (params.callback) {
                    params.callback(newRecord);
                  }
                  this.store.dispatch(
                    new RecordsListRequested({
                      sideDrawerId: sideDrawerId,
                      locale: locale,
                      recordTypeName: record.recordTypeName,
                    })
                  );
                  this.store.dispatch(
                    new SideDrawerHomeOnBackgroundRequested({
                      sdId: sideDrawerId,
                      localeDefault: locale,
                    })
                  );

                  this.dialogRef.close();
                },
                (error: HttpErrorResponse) => {
                  this.patchState({ gettingInformation: false });
                  this.store.dispatch(new SetActiveRecordFail({ error }));
                }
              )
            )
        )
      )
  );

  constructor(
    private readonly recordsService: RecordsService,
    private readonly store: Store<AppState>
  ) {
    super({
      gettingInformation: false,
      record: null,
      form: null,
      subTypeSelected: null,
      dialogRef: null,
    });
  }

  get form(): UntypedFormGroup {
    return this.get().form;
  }

  get subTypeSelected(): RecordSubType {
    return this.get().subTypeSelected;
  }

  get record(): Record {
    return this.get().record;
  }

  set record(record: Record) {
    this.patchState({ record });
  }

  get dialogRef(): MatDialogRef<RecordRenameAndChangeTypeDialogComponent> {
    return this.get().dialogRef;
  }

  private generateRecordDto(recordType: RecordType): Record {
    const dto: Record = {
      ...this.record,
      name: this.form.get('nameControl').value,
      recordSubtype: this.subTypeSelected,
      recordTypeName: recordType?.name ?? this.record?.recordType?.name,
      recordSubtypeName: this.subTypeSelected?.name ?? 'other',
      recordSubtypeOther:
        this.subTypeSelected.name === 'other'
          ? this.form.get('subTypeControl').value
          : '',
      recordDetails: {
        ...(this.record.recordDetails ?? {}),
      },
    };
    return dto;
  }
}
