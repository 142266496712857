import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { DateHelper } from '../../../core/helpers/date.helper';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { localeDefaultSelector } from '../../../dictionary/store/dictionary.selectors';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'dateLocale',
})
export class DateLocalePipe implements PipeTransform {
  constructor(private readonly store: Store<AppState>) {}

  transform(value: unknown, localizeFormat?: string): Observable<string> {
    return this.store.pipe(
      select(localeDefaultSelector),
      map(locale =>
        DateHelper.formCustomDate(value as string, locale, localizeFormat)
      )
    );
  }
}
