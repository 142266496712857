<button
  id="upload-button"
  (keydown.enter)="!disabled ? fileInput.click() : null"
  (click)="!disabled ? fileInput.click() : null"
  *ngIf="!loading; else spinner"
  [attr.aria-label]="buttonText"
  [disabled]="disabled"
  #matTooltip="matTooltip"
  [matTooltip]="!!tooltip ? tooltip : ''"
  [ngClass]="disabled ? 'disabled' : null"
  (focus)="focus = true"
  (focusout)="focus = false"
  [tabIndex]="sdAccessibility?.tabIndex"
  class="sd-upload-button-a11y"
  mat-flat-button>
  <div class="sd-upload-button-a11y-content">
    <input
      [attr.aria-readonly]="disabled"
      class="sd-upload-button-a11y-input"
      #fileInput
      (change)="emitFiles($any($event?.target).files)"
      multiple
      type="file" />
    <div class="sd-upload-button-a11y-icon" *ngIf="withIcon">
      <app-sd-svg-a11y
        [color]="'var(--primaryColor)'"
        [height]="1.438"
        [setSdAccessibility]="{
          tabIndex: -1
        }"
        [src]="icon"
        [width]="2.063" />
    </div>

    <div class="sd-upload-button-a11y-text">
      {{ buttonText }}
    </div>
  </div>
</button>
<ng-template #spinner class="sd-upload-button-a11y-spinner">
  <app-sd-progress-spinner-a11y />
</ng-template>
