import { Action } from '@ngrx/store';
import { FileUpload } from '../models/file-upload.model';
import { Update } from '@ngrx/entity';

export enum UploadFilesActionsTypes {
  UploadFilesLoaded = '[Upload Files Buttons] Upload Files Loaded',
  UploadFileAdded = '[Upload Files Buttons] Upload Files Added',
  UploadFilesClear = '[Upload Files Views] Clear Upload Files Information',
  UploadFileUpdated = '[Upload File Component] Upload File Updated',
  UploadFileDeleted = '[Upload File Component] Upload File Deleted',
  UploadFilesFormErrorAdded = '[Upload Item Component] Upload Item Form Error Added',
  UploadFilesFormErrorRemoved = '[Upload Item Component] Upload Item Form Error Removed',
}

export class UploadFilesLoaded implements Action {
  readonly type = UploadFilesActionsTypes.UploadFilesLoaded;

  constructor(public payload: { data: FileUpload[] }) {}
}

export class UploadFileAdded implements Action {
  readonly type = UploadFilesActionsTypes.UploadFileAdded;

  constructor(public payload: { data: FileUpload }) {}
}

export class UploadFilesClear implements Action {
  readonly type = UploadFilesActionsTypes.UploadFilesClear;
}

export class UploadFileUpdated implements Action {
  readonly type = UploadFilesActionsTypes.UploadFileUpdated;

  constructor(public payload: { data: Update<FileUpload> }) {}
}

export class UploadFileDeleted implements Action {
  readonly type = UploadFilesActionsTypes.UploadFileDeleted;

  constructor(public payload: { id: number }) {}
}

export class UploadFilesFormErrorAdded implements Action {
  readonly type = UploadFilesActionsTypes.UploadFilesFormErrorAdded;

  constructor(public payload: { id: number }) {}
}

export class UploadFilesFormErrorRemoved implements Action {
  readonly type = UploadFilesActionsTypes.UploadFilesFormErrorRemoved;

  constructor(public payload: { id: number }) {}
}

export type UploadFilesActions =
  | UploadFilesLoaded
  | UploadFileAdded
  | UploadFilesClear
  | UploadFileUpdated
  | UploadFileDeleted
  | UploadFilesFormErrorAdded
  | UploadFilesFormErrorRemoved;
