export enum SfrWorkflowStatus {
  sfrNew = 'sfrNew',
  sfrComplete = 'sfrComplete',
  sfrRejected = 'sfrRejected',
  sfrApproved = 'sfrApproved',
}

export enum SfrWorkflowStatusDescription {
  sfrNew = 'New',
  sfrComplete = 'Complete',
  sfrRejected = 'Rejected',
  sfrApproved = 'Approved',
}

export const sfrWorkflowStatusforSort = {
  sfrNew: 1,
  sfrComplete: 2,
  sfrRejected: 3,
  sfrApproved: 4,
};
