import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  exhaustMap,
  forkJoin,
  map,
  Observable,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { Locale } from 'src/app/dictionary/models/locale.model';
import { localeDefaultSelector } from 'src/app/dictionary/store/dictionary.selectors';
import { RecordsService } from 'src/app/records/services/records.service';
import { Record } from 'src/app/records/models/record.model';
import {
  RecordsListRequested,
  SetActiveRecord,
  SetActiveRecordFail,
} from 'src/app/records/store/records-list.actions';
import { AppState } from 'src/app/reducers';
import { SideDrawerHomeOnBackgroundRequested } from 'src/app/sidedrawer/store/sidedrawer.actions';

export interface RecordFormForFilesState {
  gettingInformation: boolean;
  description: string;
  focusMandatoryInputs: boolean;
  record: Record;
  sideDrawerId: string;
}

@Injectable()
export class RecordFormForFilesStore extends ComponentStore<RecordFormForFilesState> {
  readonly requestInProgress$ = this.select(state => state.gettingInformation);

  setSideDrawerInformation(sideDrawerId: string): void {
    this.patchState({ sideDrawerId });
  }

  readonly createRecord = this.effect(
    (
      trigger$: Observable<(record: Record, error?: HttpErrorResponse) => void>
    ) =>
      trigger$.pipe(
        tap(() => this.patchState({ gettingInformation: true })),
        switchMap(callback =>
          forkJoin([
            this.store.pipe(select(localeDefaultSelector), take(1)),
            this.select(state => state).pipe(take(1)),
            of(callback),
          ])
        ),
        map(([locale, state, callback]) => ({
          sideDrawerId: state.sideDrawerId,
          record: state.record,
          locale: Locale.getLocaleId(locale),
          callback,
        })),
        exhaustMap(({ sideDrawerId, record, locale, callback }) =>
          this.recordsService.createRecord1(sideDrawerId, record).pipe(
            tapResponse(
              ({ id }) => {
                this.patchState({ gettingInformation: false });
                const newRecord = { ...record, id };
                this.store.dispatch(new SetActiveRecord({ data: newRecord }));
                this.store.dispatch(
                  new RecordsListRequested({
                    sideDrawerId: sideDrawerId,
                    locale: locale,
                    recordTypeName: record.recordTypeName,
                  })
                );
                this.store.dispatch(
                  new SideDrawerHomeOnBackgroundRequested({
                    sdId: sideDrawerId,
                    localeDefault: locale,
                  })
                );
                if (callback) {
                  callback(newRecord);
                }
              },
              (error: HttpErrorResponse) => {
                this.patchState({ gettingInformation: false });
                this.store.dispatch(new SetActiveRecordFail({ error }));
                if (callback) {
                  callback(null, error);
                }
              }
            )
          )
        )
      )
  );

  constructor(
    private readonly recordsService: RecordsService,
    private readonly store: Store<AppState>
  ) {
    super({
      gettingInformation: false,
      description: null,
      focusMandatoryInputs: false,
      record: null,
      sideDrawerId: '',
    });
  }
}
