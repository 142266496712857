import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'avatarBackgroundColor',
  standalone: true,
})
export class AvatarBackgroundColorPipe implements PipeTransform {
  transform(value: number): string {
    return ['#94008d', '#49e6f5', 'orange', 'grey'][value];
  }
}
