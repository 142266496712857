import { HttpErrorResponse } from '@angular/common/http';
import { NetworkV2 } from '../networks/models/network.v2.type';
import { inject, Injectable } from '@angular/core';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import { AppState } from '../reducers';
import { NetworkService } from '../networks/services/network.service';
import { NetworkResourceOptions } from '../networks/models/network-resource-options.type';
import { ContributorType } from '../networks/models/contributor-type.model';
import {
  NetworkListActions,
  networkListSelector,
} from '../networks/store/network-list.store';
import { NetworkEntity } from '../networks/models/network-entity.enum';
import { concat, mergeMap, Observable, of, switchMap } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UtilsHelper } from '../core/helpers/utils.helper';
import { NetworkHelper } from '../networks/helpers/network.helper';

type NetworkDeleteState = {
  operations: Map<
    string | number,
    {
      state: 'idle' | 'processing' | 'success' | 'error';
      httpError?: HttpErrorResponse;
      network: NetworkV2;
    }
  >;
};

@Injectable()
export class NetworkDeleteStore extends ComponentStore<NetworkDeleteState> {
  private readonly store = inject(Store<AppState>);
  private readonly service = inject(NetworkService);
  readonly operationsComplete = this.selectSignal(({ operations }) =>
    Array.from(operations.values())?.some(
      ({ state }) => state === 'success' || state === 'error'
    )
  );
  readonly processingOperations = this.selectSignal(({ operations }) =>
    Array.from(operations.values())?.some(({ state }) => state === 'processing')
  );
  readonly errors = this.selectSignal(({ operations }) =>
    Array.from(operations.values())
      ?.filter(({ state }) => state === 'error')
      ?.map(({ network }) => network)
  );

  requestsNetworks(network: NetworkV2, networkEntity: NetworkEntity): void {
    const options: NetworkResourceOptions = {};
    if (network.contributorType === ContributorType.team) {
      options.teamId = network.teamNetwork.teamId;
    } else {
      options.contributorEmail = network.contributor.email;
    }
    if (networkEntity === NetworkEntity.records) {
      options.recordId = network.record;
    }
    if (networkEntity === NetworkEntity.sideDrawer) {
      options.entity = 'sidedrawer';
    }
    this.store.dispatch(
      NetworkListActions.requested({
        sideDrawerId: network.sidedrawer,
        allPageRequested: true,
        options,
        clearRequests: true,
      })
    );
  }

  getNetworksToDelete(
    network: NetworkV2,
    networkEntity: NetworkEntity
  ): NetworkV2[] {
    const payload: { teamId?: string; email?: string } = {};
    if (network.contributorType === ContributorType.team) {
      payload.teamId = network.teamNetwork.teamId;
    } else {
      payload.email = network.contributor.email;
    }
    return this.store
      .selectSignal(networkListSelector(payload))()
      ?.filter(net => {
        if (networkEntity === NetworkEntity.records) {
          return net.record === network.record;
        }
        if (networkEntity === NetworkEntity.sideDrawer) {
          return net.sidedrawerRole?.length > 0;
        }
        return true;
      });
  }

  deleteNetworks = this.effect(
    (
      trigger$: Observable<{
        networks: NetworkV2[];
        callback?: () => void;
      }>
    ) =>
      trigger$.pipe(
        tap(({ networks }) => {
          const operations = UtilsHelper.arrayToMap<{
            state: 'idle' | 'processing' | 'success' | 'error';
            httpError?: HttpErrorResponse;
            network: NetworkV2;
          }>(
            'id',
            networks.map(network => ({ network, state: 'idle' }))
          );
          this.patchState({ operations });
        }),
        mergeMap(({ networks, callback }) =>
          concat(
            ...networks.map(network =>
              of(true).pipe(
                tap(() => {
                  const aux = new Map(
                    this.selectSignal(({ operations }) => operations)()
                  );
                  aux.set(network.id, { state: 'processing', network });
                  this.patchState({ operations: aux });
                }),
                switchMap(() =>
                  NetworkHelper.isRecordNetwork(network)
                    ? this.service.deleteRecordNetwork(
                        network.sidedrawer,
                        network.record,
                        network.id
                      )
                    : this.service.deleteSideDrawerNetwork(
                        network.sidedrawer,
                        network.id
                      )
                ),
                tapResponse(
                  () => {
                    const aux = new Map(
                      this.selectSignal(({ operations }) => operations)()
                    );
                    aux.set(network.id, { state: 'success', network });
                    this.store.dispatch(
                      NetworkListActions.delete({ networkId: network.id })
                    );
                    this.patchState({ operations: aux });
                  },
                  (httpError: HttpErrorResponse) => {
                    const aux = new Map(
                      this.selectSignal(({ operations }) => operations)()
                    );
                    aux.set(network.id, { state: 'error', network, httpError });
                    this.store.dispatch(
                      NetworkListActions.delete({ networkId: network.id })
                    );
                    this.patchState({ operations: aux });
                  }
                )
              )
            )
          ).pipe(tap(() => (callback ? callback() : null)))
        )
      )
  );

  constructor() {
    super({
      operations: new Map(),
    });
  }
}
