import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  Signal,
  computed,
} from '@angular/core';
import { RecordListItem } from '../../../../models/record-list-item.model';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../../reducers';
import { BehaviorSubject } from 'rxjs';
import { DateHelper } from '../../../../../core/helpers/date.helper';
import { DeleteRecordDialogComponent } from '../../../delete-record-dialog/components/delete-record-dialog/delete-record-dialog.component';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { SidedrawerRoutesEnum } from '../../../../../sidedrawer/routes/sidedrawer-routes.enum';
import { RecordsRoutes } from '../../../../routes/records.routes';
import {
  ActiveRecordRequested,
  CopyMoveRecordDialogRequested,
  RecordCopyLinkRequested,
  RecordDeleteRequested,
  RecordsSubtypesRequested,
} from '../../../../store/records-list.actions';
import { environment } from '../../../../../../environments/environment';
import {
  basePathSelector,
  isSdOwnerOrSdEditorSelector,
} from '../../../../../sidedrawer/store/sidedrawer.selector';
import { take, tap } from 'rxjs/operators';
import { SdLinkMenuLinkType } from 'src/app/shared/sd-link-menu-a11y/models/enums/sd-link-menu-link-type-enum';
import { SdLinkMenuItem } from 'src/app/shared/sd-link-menu-a11y/models/sd-link-menu-item.model';
import { MenuItemType } from '../../../../../shared/sd-header-a11y/models/menu-item.type.enum';
import { SdColorPalette } from 'src/app/core/models/enums/sd-color-palette-enum';
import { Record } from '../../../../models/record.model';
import { NetworkResourceType } from '../../../../../networks/models/network-resource-type.model';
import { RecordRenameAndChangeTypeDialogComponent } from '../../../record-rename-and-change-type-dialog/record-rename-and-change-type-dialog.component';
import {
  activeRecordTypeSelector,
  userCanOperateOnThisRecordSelector,
} from '../../../../store/records-list.selectors';
import { MatDialog } from '@angular/material/dialog';
import { RecordListItemTemplate } from 'src/app/records/models/record-list-item-template.model';
import { PlanRecordTypeListItemStore } from 'src/app/plans/components/plan-record-type-list-item/plan-record-type-list-item.store';
import { RecordListViewStore } from 'src/app/records/views/records-list/views/records-list-view/record-list-view.store';

@Component({
  selector: 'app-records-list-item',
  templateUrl: './records-list-item.component.html',
  styleUrls: ['./records-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecordsListItemComponent {
  @Input() itemStore: PlanRecordTypeListItemStore | RecordListViewStore = null;
  @Input() template = RecordListItemTemplate.default;
  @Input() dragAndDrop = true;
  @Input() menuTemplate: SdLinkMenuItem[];
  @Output() fileDroppedEmitter = new EventEmitter<FileList>();
  @Output() emiterClicked = new EventEmitter<SdLinkMenuItem>();
  @Output() listItemClicked = new EventEmitter<boolean>();
  @Output() recordRenameClicked = new EventEmitter<Record>();
  spinner$ = new BehaviorSubject<boolean>(false);
  @Input() set setSpinner(value: boolean) {
    this.spinner$.next(value);
  }
  cdn = environment.cdn;
  record: RecordListItem;
  happensToday: boolean;
  sdLinkMenuLinkType = SdLinkMenuLinkType;
  menuItemType = MenuItemType;
  sdColorPalette = SdColorPalette;
  activeRecord$ = this.store.pipe(select(activeRecordTypeSelector));
  onOrAtString = '';
  isSdOwnerOrSdEditor = this.store.selectSignal(isSdOwnerOrSdEditorSelector);
  recordListItemTemplate = RecordListItemTemplate;

  constructor(
    private readonly store: Store<AppState>,
    private readonly dialog: MatDialog,
    private readonly router: Router
  ) {}

  @Input() set recordItem(recordItem: RecordListItem) {
    this.record = recordItem;
    if (recordItem?.updatedAt) {
      this.happensToday = DateHelper.happensToday(`${recordItem.updatedAt}`);
      this.onOrAtString = this.happensToday
        ? 'globalparams_lastmodifiedat'
        : 'globalparams_lastmodifiedon';
    }
  }

  hasSdOrRecordEditPermission: Signal<boolean> = computed(() => {
    const isSdOwnerOrSdEditor = this.store.selectSignal(
      isSdOwnerOrSdEditorSelector
    );
    const isRecordOwnerOrEditor = this.store.selectSignal(
      userCanOperateOnThisRecordSelector
    );
    if (isSdOwnerOrSdEditor() || isRecordOwnerOrEditor()) {
      return true;
    }

    return false;
  });

  //TODO EMMIT
  onDelete(): void {
    this.spinner$.next(true);
    this.dialog
      .open(DeleteRecordDialogComponent, {
        data: { recordName: this.record?.name },
        autoFocus: false,
      })
      .afterClosed()
      .subscribe(confirm => {
        if (confirm) {
          this.store.dispatch(
            new RecordDeleteRequested({
              id: this.record.id,
              callback: () => {
                this.spinner$.next(false);
              },
            })
          );
          return;
        }
        this.spinner$.next(false);
      });
  }

  onRecordsDetails(menuItemType?: MenuItemType): void {
    this.spinner$.next(true);
    this.listItemClicked.emit(true);
    this.store.dispatch(
      new ActiveRecordRequested({
        recordId: this.record.id,
        callback: result => {
          this.spinner$.next(false);
          if (result instanceof HttpErrorResponse) {
            return;
          }

          switch (menuItemType) {
            case MenuItemType.files:
              this.redirectToRecordRoute(
                `${SidedrawerRoutesEnum.root}/${RecordsRoutes.root}/${result.recordType.name}/${RecordsRoutes.form}/${result.id}/${RecordsRoutes.filesTab}`
              );
              break;
            case MenuItemType.edit:
              this.redirectToRecordRoute(
                `${SidedrawerRoutesEnum.root}/${RecordsRoutes.root}/${result.recordType.name}/${RecordsRoutes.form}/${result.id}/${RecordsRoutes.generalTab}`
              );
              break;
            case MenuItemType.view:
              this.redirectToRecordRoute(
                `${SidedrawerRoutesEnum.root}/${RecordsRoutes.root}/${result.recordType.name}/${RecordsRoutes.view}/${result.id}`
              );
              break;
            case MenuItemType.reminders:
              this.redirectToRecordRoute(
                `${SidedrawerRoutesEnum.root}/${RecordsRoutes.root}/${result.recordType.name}/${RecordsRoutes.form}/${result.id}/${RecordsRoutes.remindersTab}`
              );
              break;
            case MenuItemType.share:
              this.redirectToRecordRoute(
                `${SidedrawerRoutesEnum.root}/${RecordsRoutes.root}/${result.recordType.name}/${RecordsRoutes.form}/${result.id}/${RecordsRoutes.collaboratorsTab}`
              );
              break;
            default:
          }
        },
      })
    );
  }

  redirectToRecordRoute(route: string): void {
    this.store
      .pipe(
        select(basePathSelector),
        take(1),
        tap(baseUrl => {
          const url = baseUrl + route;
          this.router.navigate([url]).then();
        })
      )
      .subscribe();
  }

  onEmitterClicked(linkMenu: SdLinkMenuItem) {
    if (linkMenu.key === MenuItemType.view) {
      this.onRecordsDetails(MenuItemType.view);
    }
    if (linkMenu.key === MenuItemType.edit) {
      this.onRecordsDetails(MenuItemType.edit);
    }
    if (linkMenu.key === MenuItemType.share) {
      this.onRecordsDetails(MenuItemType.share);
    }
    if (linkMenu.key === MenuItemType.reminders) {
      this.onRecordsDetails(MenuItemType.reminders);
    }
    if (linkMenu.key === MenuItemType.delete) {
      this.onDelete();
    }
    if (linkMenu.key === MenuItemType.files) {
      this.onRecordsDetails(MenuItemType.files);
    }
    if (linkMenu.key === MenuItemType.rename) {
      this.openRenameAndChangeTypeDialog(this.record);
    }
    if (linkMenu.key === MenuItemType.copy) {
      this.store.dispatch(
        new RecordCopyLinkRequested({
          record: this.record,
        })
      );
    }
    if (linkMenu.key === MenuItemType.copyRecord) {
      this.store.dispatch(
        new CopyMoveRecordDialogRequested({
          copyRecord: true,
          record: this.record,
        })
      );
    }
    if (linkMenu.key === MenuItemType.moveRecord) {
      this.store.dispatch(
        new CopyMoveRecordDialogRequested({
          copyRecord: false,
          record: this.record,
        })
      );
    }
  }

  openRenameAndChangeTypeDialog(record: Record, event?: Event): void {
    if (!record) {
      return;
    }
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.dialog
      .open(RecordRenameAndChangeTypeDialogComponent, {
        data: {
          resourceType: NetworkResourceType.record,
          record,
          setActive: true,
        },
        autoFocus: false,
      })
      .afterClosed()
      .subscribe(() => {
        this.store.dispatch(
          new RecordsSubtypesRequested({
            recordTypeName: record?.recordType?.name,
          })
        );
      });
  }

  emitRenameAndChangeTypeDialog(record: Record, event?: Event): void {
    if (!record) {
      return;
    }
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.recordRenameClicked.emit(record);
  }

  processFiles(fileList: FileList): void {
    this.fileDroppedEmitter.emit(fileList);
  }
}
