import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { CreateReportDto } from '../models/create-report.dto';
import { Observable } from 'rxjs';
import { authHeadersSelector } from '../../auth/store/auth.selectors';
import { ReportType } from '../models/report-type.model';

@Injectable()
export class ReportsService {
  private readonly store = inject(Store<AppState>);
  private readonly http = inject(HttpClient);
  private readonly reportsApi = environment.reportsApi;

  createReport(
    sideDrawerId: string,
    dto: CreateReportDto
  ): Observable<{ _id: string }> {
    return this.http.post<{ _id: string }>(
      this.reportsApi + `sidedrawer/sidedrawer-id/${sideDrawerId}/reports`,
      dto,
      {
        headers: this.store.selectSignal(authHeadersSelector)(),
      }
    );
  }

  getSideDrawerReportTypes(
    sideDrawerId: string,
    locale?: string
  ): Observable<ReportType[]> {
    const params = locale ? { locale } : {};
    return this.http.get<ReportType[]>(
      this.reportsApi + `sidedrawer/sidedrawer-id/${sideDrawerId}/report-types`,
      {
        headers: this.store.selectSignal(authHeadersSelector)(),
        params,
      }
    );
  }

  getSideDrawerReportTypeStatus(
    sideDrawerId: string,
    reportId: string
  ): Observable<{ reportId: string; status: string }> {
    return this.http.get<{ reportId: string; status: string }>(
      this.reportsApi +
        `sidedrawer/sidedrawer-id/${sideDrawerId}/reports/report-id/${reportId}/status`,
      {
        headers: this.store.selectSignal(authHeadersSelector)(),
      }
    );
  }
}
