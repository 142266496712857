import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import * as reducer from './store/core.reducer';
import { EffectsModule } from '@ngrx/effects';
import { CoreEffects } from './store/core.effects';
import { CoreRoutingModule } from './core-routing.module';
import { queueFeature } from '../queue/store/queue.reducer';
import * as QueueEffects from '../queue/store/queue.effects';
import { AccountService } from '../account/services/account.service';
import {
  featureName as timelineFeatureName,
  timelineReducer,
} from '../timeline/store/timeline.reducer';
import * as timelineEffects from '../timeline/store/timeline.effects';
import {
  featureName as sdBackDropFeatureName,
  sdBackdropReducer,
} from '../home/shared/sd-backdrop/store/sd-backdrop.reducer';
import {
  featureName as sideDrawerFeatureName,
  sidedrawerReducer,
} from '../sidedrawer/store/sidedrawer.reducer';
import { SidedrawerEffects } from '../sidedrawer/store/sidedrawer.effects';
import {
  featureName as signaturesFeatureName,
  signatureReducer,
} from '../signature/store/signature.reducer';
import { SignatureEffects } from '../signature/store/signature.effects';
import { SdDatePickerA11yModule } from '../shared/sd-date-picker-a11y/sd-date-picker-a11y.module';
import {
  featureName as networksFeatureName,
  networksReducer,
} from '../networks/store/networks.reducer';
import { NetworksEffects } from '../networks/store/networks.effects';
import { NetworksCrudEffects } from '../networks/store/networks-crud/networks-crud.effect';
import { PaginatorService } from './services/paginator.service';
import { ConfigService } from './services/config.service';
import { DragDropOverlaySnackBarContainer } from '../files/directives/drag-and-drop/directive/drag-and-drop/drag-drop-overlay-container';
import { FilesService } from '../files/services/files.service';
import { HomeService } from '../home/services/home.service';
import { IntegrationsService } from '../integrations/services/integrations.service';
import { LicensesService } from '../licenses/services/licenses.service';
import { NetworkService } from '../networks/services/network.service';
import { PaymentMethodsService } from '../payment-methods/services/payment-methods.service';
import { PlansService } from '../plans/services/plans.service';
import { RecordsService } from '../records/services/records.service';
import { RelatedRecordsService } from '../related-records/services/related-records.service';
import { RemindersService } from '../reminders/services/reminders.service';
import { SidedrawerService } from '../sidedrawer/services/sidedrawer.service';
import { SignatureService } from '../signature/services/signature.service';
import { FilingCabinetService } from '../filing-cabinet/services/filing-cabinet.service';
import { filingCabinetFeature } from '../filing-cabinet/store/filing-cabinet.store';
import * as FilingCabinetEffects from '../filing-cabinet/store/filing-cabinet.effect';
import { ReportsService } from '../reports/services/reports.service';
import { reportsFeature } from '../reports/store/reports.store';
import * as ReportsEffects from '../reports/store/reports.effects';
import { tenantsFeature } from '../tenant-features/store/tenants.store';
import * as TenantsEffects from '../tenant-features/store/tenants.effects';
import { TenantService } from '../tenant-features/service/tenants.service';
import { tenantPlansFeature } from '../tenant-features/store/tenant-plans.store';
import * as TenantsPlansEffects from '../tenant-features/store/tenant-plans.effects';
import { networkListFeature } from '../networks/store/network-list.store';
import * as NetworkListEffects from '../networks/store/network-list.effects';
import { networkSearchFeature } from '../networks/store/network-search.store';
import * as NetworkSearchEffects from '../networks/store/network-search.effects';
import { networkPermanentFeature } from '../networks/store/network-permanent.store';
import * as NetworkPermanentEffects from '../networks/store/network-permanent.effects';

@NgModule({
  imports: [
    CoreRoutingModule,
    StoreModule.forFeature(reducer.featureName, reducer.coreReducer),
    StoreModule.forFeature(sideDrawerFeatureName, sidedrawerReducer),
    StoreModule.forFeature(networksFeatureName, networksReducer),
    StoreModule.forFeature(networkListFeature),
    StoreModule.forFeature(networkSearchFeature),
    StoreModule.forFeature(networkPermanentFeature),
    StoreModule.forFeature(queueFeature),
    StoreModule.forFeature(signaturesFeatureName, signatureReducer),
    StoreModule.forFeature(timelineFeatureName, timelineReducer),
    StoreModule.forFeature(sdBackDropFeatureName, sdBackdropReducer),
    StoreModule.forFeature(filingCabinetFeature),
    StoreModule.forFeature(reportsFeature),
    StoreModule.forFeature(tenantsFeature),
    StoreModule.forFeature(tenantPlansFeature),
    EffectsModule.forFeature([
      CoreEffects,
      QueueEffects,
      timelineEffects,
      NetworksEffects,
      NetworksCrudEffects,
      SidedrawerEffects,
      SignatureEffects,
      FilingCabinetEffects,
      ReportsEffects,
      TenantsEffects,
      TenantsPlansEffects,
      NetworkListEffects,
      NetworkSearchEffects,
      NetworkPermanentEffects,
    ]),
    SdDatePickerA11yModule,
  ],
  providers: [
    AccountService,
    PaginatorService,
    ConfigService,
    DragDropOverlaySnackBarContainer,
    FilesService,
    HomeService,
    IntegrationsService,
    LicensesService,
    NetworkService,
    PaymentMethodsService,
    PlansService,
    RecordsService,
    RelatedRecordsService,
    RemindersService,
    SidedrawerService,
    SignatureService,
    FilingCabinetService,
    ReportsService,
    TenantService,
  ],
})
export class CoreModule {}
