import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  signal,
} from '@angular/core';
import {
  MatProgressBarModule,
  ProgressBarMode,
} from '@angular/material/progress-bar';
import { SdInputA11yTemplates } from '../../../sd-input-a11y/models/sd-input-a11y-templates.enum';
import { SdAccessibilitySetting } from '../../../../core/models/sd-accessibility-model';
import { AccessibilityHelper } from '../../../../core/helpers/accessibility.helper';
import { DictionaryPipeModule } from '../../../../dictionary/pipes/dictionary-pipe/dictionary-pipe.module';

@Component({
  selector: 'app-sd-progress-bar-a11y',
  template: `
    <div class="app-sd-progress-bar-a11y">
      <mat-progress-bar
        *ngIf="isLoading()"
        [attr.aria-busy]="isLoading()"
        [attr.aria-label]="
          mode === 'indeterminate' ? (sdAccessibility?.ariaLabel ?? '') + ' ' +
          ('sdprogressbar_progress' | dictionary | async)
          :
          ((sdAccessibility?.ariaLabel ?? '') + ' ' + ('sdprogressbar_progress' | dictionary | async) +
          progress + ' ' + '%')
        "
        [attr.aria-role]="'status'"
        [attr.aria-live]="'assertive'"
        [mode]="mode"
        [ngClass]="this.currentClass"
        [value]="progress" />
    </div>
  `,
  styleUrls: ['./sd-progress-bar-a11y.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatProgressBarModule,
    NgClass,
    DictionaryPipeModule,
    AsyncPipe,
    NgIf,
  ],
})
export class SdProgressBarA11yComponent {
  sdInputA11yTemplates = SdInputA11yTemplates;
  isLoading = signal(true);
  @Input() currentClass: string = 'primary-progress-bar';
  @Input() progress = 0;
  @Input() mode: ProgressBarMode = 'indeterminate';

  @Input() set progressBarType(
    type: 'primary' | 'danger' | 'success' | 'main'
  ) {
    this.currentClass = `${type ?? 'primary'}-progress-bar`;
  }

  @Input() set setIsLoading(value: boolean) {
    this.isLoading.set(value);
  }

  sdAccessibility: SdAccessibilitySetting;
  @Input() set setSdAccessibility(value: SdAccessibilitySetting) {
    this.sdAccessibility = AccessibilityHelper.setDefaultAccessibility(value);
  }
}
