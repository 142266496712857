import { createSelector } from '@ngrx/store';
import { selectAll } from './search-record-list.reducer';
import { searchStateSelector } from './search.selectors';
import { selectEntities } from './search-file-list.reducer';

export const searchFilesListStateSelector = createSelector(
  searchStateSelector,
  state => state.file
);

export const searchFilesListSelector = createSelector(
  searchFilesListStateSelector,
  selectAll
);

export const searchFilesEntitiesSelector = createSelector(
  searchFilesListStateSelector,
  selectEntities
);

export const searchFilesByIdSelector = (payload: { id: string }) =>
  createSelector(
    searchFilesEntitiesSelector,
    entities => entities[`${payload.id}`]
  );

export const searchFilesListCountSelector = createSelector(
  searchFilesListStateSelector,
  state => state?.totalCount
);

export const searchFilesListHasMoreSelector = createSelector(
  searchFilesListStateSelector,
  state => state?.hasMore
);

export const searchFilesNextPageSelector = createSelector(
  searchFilesListStateSelector,
  state => state?.nextPage
);

export const searchFilesFilterSelector = createSelector(
  searchFilesListStateSelector,
  state => state?.filter
);

export const searchFilesListFilteredByFilterSelector = createSelector(
  searchFilesListStateSelector,
  state => {
    return Object.values(state.entities).filter(
      file =>
        file.fileName
          .toLowerCase()
          .includes(state.filter.fileName.toLowerCase()) ||
        file.uploadTitle
          .toLowerCase()
          .includes(state.filter.uploadTitle.toLowerCase()) ||
        file.caption
          .toLowerCase()
          .includes(state.filter.uploadTitle.toLowerCase())
    );
  }
);
