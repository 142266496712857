import { StartFlowStep } from '../models/start-flow-step.model';
import { StartFlowStepStatus } from '../models/start-flow-step-status.model';
import { UtilsHelper } from '../../core/helpers/utils.helper';
import { StartFlowStepKeys } from '../models/start-flow-step-keys.model';
import { StartFlowActions, StartFlowActionsTypes } from './start-flow.actions';

export const featureName = 'start-flow';

export interface StartFlowState {
  origin: string;
  firstLogIn: boolean;
  bypassSdCreation: boolean;
  forceSdCreation: boolean;
  customBrand: boolean;
  invitationCode: string;
  steps: Map<string, StartFlowStep>;
  complete: boolean;
}

export const initialStartFlowState: StartFlowState = {
  origin: null,
  firstLogIn: false,
  bypassSdCreation: false,
  forceSdCreation: false,
  customBrand: false,
  invitationCode: null,
  steps: new Map<string, StartFlowStep>(
    UtilsHelper.getListFromEnum(StartFlowStepKeys).map(key => [
      key.value,
      { status: StartFlowStepStatus.pending },
    ])
  ),
  complete: false,
};

export function startFlowReducer(
  state: StartFlowState = initialStartFlowState,
  action: StartFlowActions
): StartFlowState {
  const updatedSteps = state.steps;
  switch (action.type) {
    case StartFlowActionsTypes.LaunchStartFlow:
    case StartFlowActionsTypes.RestartStartFlow:
      return {
        ...initialStartFlowState,
      };
    case StartFlowActionsTypes.StartFlowStepUpdated:
      updatedSteps.set(action.payload.key, action.payload.step);
      return {
        ...state,
        steps: updatedSteps,
      };
    case StartFlowActionsTypes.StartFlowConfigurationSetBrandsParams:
      return {
        ...state,
        bypassSdCreation: action.payload.bypassSdCreation,
        forceSdCreation: action.payload.forceSdCreation,
      };
    case StartFlowActionsTypes.StartFlowConfigurationSetFirstLogin:
      return {
        ...state,
        firstLogIn: action.payload.firstLogIn,
      };
    case StartFlowActionsTypes.StartFlowConfigurationSetURLParams:
      return {
        ...state,
        invitationCode: action.payload.invitationCode,
        customBrand: action.payload.customBrand,
        origin: action.payload.origin,
      };
    case StartFlowActionsTypes.StartFlowCompleted:
      return {
        ...state,
        complete: true,
      };
    case StartFlowActionsTypes.StartFlowInvitationUsed:
      return {
        ...state,
        invitationCode: null,
      };
    case StartFlowActionsTypes.GetSdHomeOriginSdIdFail:
      return {
        ...state,
        origin: null,
      };
    default:
      return state;
  }
}
