import { DriveItem } from '../models/drive-item.model';
import {
  IntegrationActions,
  IntegrationActionsTypes,
} from './integration.actions';

export const featureName = 'integrations';

export interface IntegrationState {
  filesToAdd: DriveItem[];
  filesToRemove: DriveItem[];
  foldersToAdd: DriveItem[];
  foldersToRemove: DriveItem[];
}

export const initialIntegrationState: IntegrationState = {
  filesToAdd: [],
  filesToRemove: [],
  foldersToAdd: [],
  foldersToRemove: [],
};

export function integrationReducer(
  state: IntegrationState = initialIntegrationState,
  action: IntegrationActions
): IntegrationState {
  switch (action.type) {
    case IntegrationActionsTypes.ClearDriveInformation:
      return initialIntegrationState;
    case IntegrationActionsTypes.DriveFileItemToAddAdded:
      return {
        ...state,
        filesToAdd: [...state.filesToAdd, action.payload.item],
      };
    case IntegrationActionsTypes.DriveFileItemToAddRemoved:
      return {
        ...state,
        filesToAdd: [
          ...state.filesToAdd.filter(item => item.id !== action.payload.id),
        ],
      };
    case IntegrationActionsTypes.DriveFolderItemToAddAdded:
      return {
        ...state,
        foldersToAdd: [...state.foldersToAdd, action.payload.item],
      };
    case IntegrationActionsTypes.DriveFolderItemToAddRemoved:
      return {
        ...state,
        foldersToAdd: [
          ...state.foldersToAdd.filter(item => item.id !== action.payload.id),
        ],
      };
    case IntegrationActionsTypes.DriveFileItemToRemoveAdded:
      return {
        ...state,
        filesToRemove: [...state.filesToRemove, action.payload.item],
      };
    case IntegrationActionsTypes.DriveFileItemToRemoveRemoved:
      return {
        ...state,
        filesToRemove: [
          ...state.filesToRemove.filter(item => item.id !== action.payload.id),
        ],
      };
    case IntegrationActionsTypes.DriveFolderItemToRemoveAdded:
      return {
        ...state,
        foldersToRemove: [...state.foldersToRemove, action.payload.item],
      };
    case IntegrationActionsTypes.DriveFolderItemToRemoveRemoved:
      return {
        ...state,
        foldersToRemove: [
          ...state.foldersToRemove.filter(
            item => item.id !== action.payload.id
          ),
        ],
      };
    default:
      return state;
  }
}
