import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SdHeaderA11yComponent } from './components/sd-header-a11y/sd-header-a11y.component';
import { SdFlatButtonA11yModule } from '../sd-flat-button-a11y/sd-flat-button-a11y.module';
import { SdLinkButtonA11yModule } from '../sd-link-button-a11y/sd-link-button-a11y.module';
import { SdLinkMenuA11yModule } from '../sd-link-menu-a11y/sd-link-menu-a11y.module';
import { DictionaryPipeModule } from '../../dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { ZipHeaderButtonModuleA11y } from 'src/app/zip/shared/zip-header-button-a11y/zip-header-button-a11y.module';
import { SdUploadButtonA11yComponent } from '../sd-upload-button-a11y/sd-upload-button-a11y.component';
import { DragAndDropModule } from 'src/app/files/directives/drag-and-drop/drag-and-drop.module';
import { SdSvgA11yModule } from '../sd-svg-a11y/sd-svg-a11y.module';
import { SdIconButtonA11yComponent } from '../sd-icon-button-a11y/components/sd-icon-button-a11y/sd-icon-button-a11y.component';
import { MainHeaderDisplayNamePipe } from 'src/app/home/pipes/main-header-display-name.pipe';
import { CreateRecordForFilesDialogComponent } from '../../records/shared/create-record-for-files-dialog/create-record-for-files-dialog.component';
import { SidedrawerMenuA11yComponent } from 'src/app/sidedrawer/shared/sidedrawer-menu-a11y/sidedrawer-menu-a11y.component';

@NgModule({
  declarations: [SdHeaderA11yComponent],
  imports: [
    CommonModule,
    RouterModule,
    SdFlatButtonA11yModule,
    SdIconButtonA11yComponent,
    SdLinkButtonA11yModule,
    SdLinkMenuA11yModule,
    DictionaryPipeModule,
    ZipHeaderButtonModuleA11y,
    SdUploadButtonA11yComponent,
    DragAndDropModule,
    CreateRecordForFilesDialogComponent,
    SdSvgA11yModule,
    MainHeaderDisplayNamePipe,
    SidedrawerMenuA11yComponent,
  ],
  exports: [SdHeaderA11yComponent],
})
export class SdHeaderA11yModule {}
