import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { AccessibilityHelper } from 'src/app/core/helpers/accessibility.helper';
import { SdAccessibilityTags } from 'src/app/core/models/enums/sd-accessibility-enum';
import { SdAccessibilitySetting } from 'src/app/core/models/sd-accessibility-model';

@Component({
  selector: 'app-sd-checkbox-a11y',
  templateUrl: './sd-checkbox-a11y.component.html',
  styleUrls: ['./sd-checkbox-a11y.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SdCheckboxA11yComponent {
  @Input() value = false;
  @Output() valueChange = new EventEmitter<boolean>();

  sdAccessibility: SdAccessibilitySetting;
  sdAccessibilityTags = SdAccessibilityTags;

  @Input() set setSdAccessibility(value: SdAccessibilitySetting) {
    this.sdAccessibility = AccessibilityHelper.setDefaultAccessibility(value);
  }

  onChange(): void {
    this.value = !this.value;
    this.valueChange.emit(this.value);
  }
}
