import {
  SidedrawerActions,
  SidedrawerActionsTypes,
} from './sidedrawer.actions';
import { SideDrawer } from '../models/side-drawer.model';
import { SideDrawerNetwork } from '../../sidedrawer-networks/models/side-drawer-network.model';

export const featureName = 'sidedrawer';

export interface SidedrawerState {
  activeSideDrawer: SideDrawer;
  sideDrawerDefault: SideDrawerNetwork;
  loading: boolean;
  updating: boolean;
  error: string;
  creatingSideDrawer: boolean;
  transferringSideDrawer: boolean;
}

export const initialSidedrawerState: SidedrawerState = {
  activeSideDrawer: null,
  sideDrawerDefault: null,
  loading: null,
  updating: false,
  error: null,
  creatingSideDrawer: false,
  transferringSideDrawer: false,
};

export function sidedrawerReducer(
  state: SidedrawerState = initialSidedrawerState,
  action: SidedrawerActions
): SidedrawerState {
  switch (action.type) {
    case SidedrawerActionsTypes.SetActiveSideDrawer:
    case SidedrawerActionsTypes.UpdateActiveSideDrawer:
      return {
        ...state,
        activeSideDrawer: action.payload.data,
      };
    case SidedrawerActionsTypes.SetSideDrawerDefault:
      return {
        ...state,
        sideDrawerDefault: action.payload.data,
      };
    case SidedrawerActionsTypes.SideDrawerSpinnerChange:
      return {
        ...state,
        loading: action.payload.state,
      };
    case SidedrawerActionsTypes.SideDrawerErrorChange:
      return {
        ...state,
        error: action.payload.actionName,
      };
    case SidedrawerActionsTypes.SideDrawerCleanError:
      return {
        ...state,
        error: null,
      };
    case SidedrawerActionsTypes.SideDrawerCreateSpinnerChange:
      return {
        ...state,
        creatingSideDrawer: action.payload.creatingSideDrawer,
      };
    case SidedrawerActionsTypes.SideDrawerTransferSpinnerChange:
      return {
        ...state,
        transferringSideDrawer: action.payload.transferringSideDrawer,
      };
    case SidedrawerActionsTypes.SideDrawerUpdatedChange:
      return {
        ...state,
        updating: action.payload.updating,
      };
    default:
      return state;
  }
}
