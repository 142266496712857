import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DocusignEnvelopeRecipient } from '../models/docusign-envelope-recipient.model';

@Pipe({
  name: 'emailsToItemListAvatar',
  standalone: true,
})
export class EmailsToItemListAvatarPipe implements PipeTransform {
  transform(
    recipients$: Observable<DocusignEnvelopeRecipient[]>
  ): Observable<string[]> {
    return recipients$.pipe(
      map(recipients => {
        if (recipients?.length === 0) {
          return [];
        }
        const avatars: string[] = [recipients[0]?.email[0]?.toUpperCase()];
        if (recipients.length === 2) {
          avatars.push(recipients[1]?.email[1]?.toUpperCase());
        }
        if (recipients.length > 2) {
          avatars.push(`+${recipients.length - 1}`);
        }
        return avatars;
      })
    );
  }
}
