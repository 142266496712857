import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';
import { SdLinkMenuItem } from '../sd-link-menu-a11y/models/sd-link-menu-item.model';
import { SdDivContainerA11yComponent } from '../sd-div-container-a11y/component/sd-div-container-a11y/sd-div-container-a11y.component';
import {
  AsyncPipe,
  NgClass,
  NgFor,
  NgIf,
  NgTemplateOutlet,
} from '@angular/common';
import { SdLinkMenuA11yModule } from '../sd-link-menu-a11y/sd-link-menu-a11y.module';
import { DictionaryPipeModule } from '../../dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { environment } from '../../../environments/environment';
import { SdSvgA11yModule } from '../sd-svg-a11y/sd-svg-a11y.module';
import { SdProgressSpinnerA11yModule } from '../sd-progress-spinner-a11y/sd-progress-spinner-a11y/sd-progress-spinner-a11y.module';
import { SdColorPalette } from '../../core/models/enums/sd-color-palette-enum';
import { Network } from 'src/app/networks/models/network.model';
import { TeamNetworkListItemMemberA11yComponent } from 'src/app/networks/shared/team-network-list-item-member-a11y/team-network-list-item-member-a11y.component';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { dictionarySelector } from 'src/app/dictionary/store/dictionary.selectors';
import { Store } from '@ngrx/store';
import { AppState } from '@auth0/auth0-angular';

@Component({
  selector: 'app-account-type-item',
  standalone: true,
  imports: [
    SdDivContainerA11yComponent,
    NgIf,
    SdLinkMenuA11yModule,
    AsyncPipe,
    DictionaryPipeModule,
    SdSvgA11yModule,
    SdProgressSpinnerA11yModule,
    NgClass,
    TeamNetworkListItemMemberA11yComponent,
    NgFor,
    NgTemplateOutlet,
  ],
  template: `
    <app-sd-div-container-a11y
      [tooltip]="tooltip"
      [setSdAccessibility]="{ tabIndex: clickable ? 0 : -1 }">
      <ng-container dialog-body>
        <div
          [ngClass]="{ 'account-type-item-no-actions': !clickable }"
          class="account-type-item"
          [attr.aria-label]="ariaLabel">
          <div
            class="account-type-item-avatar"
            (click)="itemClicked.emit(true)"
            (keydown)="itemClicked.emit(true)">
            <img *ngIf="avatarSrc" [src]="avatarSrc" [alt]="name" />
            <div *ngIf="!avatarSrc" class="circle-avatar">
              {{ name?.[0]?.toUpperCase() }}
            </div>
          </div>
          <div
            class="account-type-item-name"
            (click)="itemClicked.emit(true)"
            (keydown)="itemClicked.emit(true)">
            {{ name }}
            <span
              *ngIf="nameDescription?.length > 0"
              class="account-type-item-name-description">
              ({{ nameDescription }})
            </span>
            <app-sd-svg-a11y
              *ngIf="templateStyle !== 'none' && nameActionSrc?.length > 0"
              class="row_actions"
              [ngClass]="{
                'account-type-displaysection-button': nameActionInline
              }"
              [setSdAccessibility]="{
                role: 'button',
                ariaLabel: nameActionAlt
              }"
              [tooltips]="nameActionAlt"
              (click)="
                !nameActionInline
                  ? nameActionClicked.emit(true)
                  : (displaySection = !displaySection);
                $event.stopPropagation();
                $event.preventDefault()
              "
              (keydown.enter)="
                !nameActionInline
                  ? nameActionClicked.emit(true)
                  : (displaySection = !displaySection);
                $event.stopPropagation();
                $event.preventDefault()
              "
              [color]="nameActionColor ?? 'var(--tertiaryAccentColor)'"
              [height]="1"
              [src]="
                !nameActionInline
                  ? nameActionSrc
                  : !displaySection
                    ? nameActionSrc
                    : nameActionSecondSrc
              "
              [width]="1"></app-sd-svg-a11y>
          </div>
          <div
            class="account-type-item-tag"
            (click)="itemClicked.emit(true)"
            (keydown)="itemClicked.emit(true)">
            <div
              class="account-type-item-tag-container"
              *ngIf="tag?.length > 0">
              {{ tag }}
            </div>
          </div>
          <div
            class="account-type-item-description"
            (click)="itemClicked.emit(true)"
            (keydown.enter)="itemClicked.emit(true)">
            {{ description }}
            <app-sd-svg-a11y
              *ngIf="
                templateStyle !== 'none' && descriptionActionSrc?.length > 0
              "
              class="row_actions"
              [setSdAccessibility]="{
                role: 'button',
                ariaLabel: descriptionActionAlt
              }"
              [tooltips]="descriptionActionAlt"
              (click)="
                descriptionActionClicked.emit(true);
                $event.stopPropagation();
                $event.preventDefault()
              "
              (keydown.enter)="
                descriptionActionClicked.emit(true);
                $event.stopPropagation();
                $event.preventDefault()
              "
              [color]="descriptionActionColor ?? 'var(--tertiaryAccentColor)'"
              [height]="1"
              [src]="descriptionActionSrc"
              [width]="1"></app-sd-svg-a11y>
          </div>
          <div
            class="account-type-item-action"
            *ngIf="templateStyle === 'action' && !spinner">
            <app-sd-svg-a11y
              class="row_actions"
              [setSdAccessibility]="{
                role: 'button',
                ariaLabel: actionAlt
              }"
              (click)="actionClicked.emit(true)"
              (keydown.enter)="actionClicked.emit(true)"
              [color]="actionColor ?? 'var(--tertiaryAccentColor)'"
              [height]="1.25"
              [src]="actionSrc"
              [width]="1.25" />
          </div>
          <div
            class="account-type-item-menu"
            *ngIf="templateStyle === 'menu' && !spinner">
            <app-sd-link-menu-a11y
              [icon]="
                menu?.icon ??
                environment.cdn +
                  ('globalparams_horizontalactionmenuicon' | dictionary | async)
              "
              [iconColor]="menu?.iconColor ?? SdColorPalette.primaryAccentColor"
              [squareBackground]="menu?.squareBackground ?? true"
              [border]="menu?.border ?? false"
              [disableRipple]="menu?.disableRipple"
              [tooltip]="menu?.tooltip"
              [accessibility]="{
                ariaLabel: menu?.ariaLabel,
                tabIndex: -1
              }"
              [buttonHeight]="menu?.buttonHeight ?? 1.5"
              [buttonWidth]="menu?.buttonWidth ?? 2"
              [setMenuData]="menu?.menuData"
              (emitterClicked)="emitterClicked.emit($event)" />
          </div>
          <div
            class="account-type-item-action"
            *ngIf="templateStyle === 'actionTemplateRef' && !spinner">
            <ng-container *ngTemplateOutlet="actionTemplateRef" />
          </div>
          <div *ngIf="displaySection" class="team-network-list-item-members">
            <!--            <app-team-network-list-item-member-a11y-->
            <!--              (deleteMember)="deleteMember.emit(member)"-->
            <!--              *ngFor="let member of teamMembers; let i = index"
                          (keyup)="announce($event, i)"-->
            <!--              [member]="member"-->
            <!--              [onRecordList]="true" />-->
          </div>
          <div
            class="account-type-item-empty"
            *ngIf="templateStyle === 'menu' && !spinner"></div>
          <div class="account-type-item-spinner" *ngIf="spinner">
            <app-sd-progress-spinner-a11y />
          </div>
        </div>
      </ng-container>
    </app-sd-div-container-a11y>
  `,
  styleUrls: ['./account-type-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountTypeItemComponent {
  protected readonly environment = environment;
  protected readonly SdColorPalette = SdColorPalette;
  private readonly announcer = inject(LiveAnnouncer);
  private readonly store = inject(Store<AppState>);
  displaySection = false;
  @Input() name: string;
  @Input() nameDescription: string;
  @Input() nameActionSrc: string;
  @Input() nameActionSecondSrc: string;
  @Input() nameActionAlt: string;
  @Input() nameActionColor: string;
  @Input() nameActionInline: boolean;
  @Output() nameActionClicked = new EventEmitter<boolean>();
  @Input() tag: string;
  @Input() ariaLabel: string;
  @Input() tooltip: string;
  @Input() avatarSrc: string;
  @Input() description: string;
  @Input() descriptionActionSrc: string;
  @Input() descriptionActionAlt: string;
  @Input() descriptionActionColor: string;
  @Input() teamMembers: Network[];
  @Output() descriptionActionClicked = new EventEmitter<boolean>();
  @Output() deleteMember = new EventEmitter<Network>();
  @Input() templateStyle: 'menu' | 'action' | 'none' | 'actionTemplateRef' =
    'none';
  @Input() actionTemplateRef: TemplateRef<any>;
  @Input() actionSrc: string;
  @Input() actionAlt: string;
  @Input() actionColor: string;
  @Input() menu: {
    icon: string;
    iconColor: string;
    squareBackground: boolean;
    border: boolean;
    disableRipple: boolean;
    tooltip: string;
    ariaLabel: string;
    buttonHeight: number;
    buttonWidth: number;
    menuData: SdLinkMenuItem[];
  };
  @Input() spinner: boolean;
  @Input() clickable = true;
  @Output() emitterClicked = new EventEmitter<SdLinkMenuItem>();
  @Output() itemClicked = new EventEmitter<boolean>();
  @Output() actionClicked = new EventEmitter<boolean>();

  announce(event: KeyboardEvent, index: number): void {
    if (event.key === 'Tab') {
      const dictionary = this.store.selectSignal(dictionarySelector)();
      this.announcer
        .announce(
          `${index + 1} ${dictionary.globalparams_resultof} ${
            this.teamMembers?.length
          }`
        )
        .then();
    }
  }
}
