export enum DictionaryRoleEntity {
  record = 'record',
  sidedrawer = 'sidedrawer',
  tile = 'tile',
  infoRequest = 'info-request',
}

export class DictionaryRole {
  constructor(
    public entity: DictionaryRoleEntity,
    public iconUrl: string,
    public roleDescription: string,
    public roleId: string,
    public roleLongName: string,
    public roleName: string,
    public roleGroupId: string
  ) {}
}
