import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { SignatureDto } from '../models/signature.dto';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CreateSignatureFromFileResponseDto } from '../models/create-signature-from-file-response.dto';
import { FileUploadResponseModel } from '../../files/models/file-upload-response.model';
import { FileUploadStatusType } from '../../files/models/file-upload-status-type.model';
import { DocusignEnvelope } from '../models/docusign-envelope.model';
import { PaginatorApiResponse } from '../../core/models/paginator-api-response.dto';
import { SignatureProvider } from '../models/signature-provider.model';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { authHeadersSelector } from 'src/app/auth/store/auth.selectors';

@Injectable()
export class SignatureService {
  signatureApi = environment.signatureApi;
  blocksApi = environment.blocksApi;

  constructor(
    private readonly http: HttpClient,
    private readonly store: Store<AppState>
  ) {}

  getRecordEnvelopes(
    sideDrawerId: string,
    recordId: string
  ): Observable<PaginatorApiResponse<DocusignEnvelope>> {
    const url =
      this.signatureApi +
      `sidedrawer/sidedrawer-id/${sideDrawerId}/records/record-id/${recordId}/envelopes`;
    return this.http.get<PaginatorApiResponse<DocusignEnvelope>>(url, {
      headers: this.store.selectSignal(authHeadersSelector)(),
    });
  }

  getRecordEnvelopeDetails(
    sideDrawerId: string,
    recordId: string,
    envelopeId: string
  ): Observable<DocusignEnvelope> {
    const url =
      this.signatureApi +
      `sidedrawer/sidedrawer-id/${sideDrawerId}/records/record-id/${recordId}/envelopes/envelope-id/${envelopeId}`;
    return this.http.get<DocusignEnvelope>(url, {
      headers: this.store.selectSignal(authHeadersSelector)(),
    });
  }

  downloadFileFromEnvelope(
    sideDrawerId: string,
    provider: SignatureProvider,
    envelopeId: string,
    documentId: string
  ): Observable<{ status: string; loaded?: number; file?: Blob }> {
    const url =
      this.blocksApi +
      `signatures/sidedrawer/sidedrawer-id/${sideDrawerId}/provider/${provider}/envelopes/envelope-id/${envelopeId}/documents/document-id/${documentId}`;
    return this.http
      .get<Blob>(url, {
        headers: this.store.selectSignal(authHeadersSelector)(),
        responseType: 'blob' as 'json',
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        map(event => {
          switch (event.type) {
            case HttpEventType.DownloadProgress:
              return { status: 'progress', loaded: event.loaded };
            case HttpEventType.Response:
              return { status: 'complete', file: event.body };
            default:
              return { status: `Unhandled event: ${event.type}` };
          }
        })
      );
  }

  createSignatureFromFile(
    sideDrawerId: string,
    file: File,
    dto: SignatureDto,
    provider: SignatureProvider
  ): Observable<FileUploadResponseModel<CreateSignatureFromFileResponseDto>> {
    const url =
      this.blocksApi +
      `signatures/sidedrawer/sidedrawer-id/${sideDrawerId}/provider/${provider}/envelopes`;
    const fData = new FormData();
    fData.append('files', file);
    fData.append('signatureDto', JSON.stringify(dto));
    return this.http
      .request<CreateSignatureFromFileResponseDto>('POST', url, {
        body: fData,
        headers: {
          Authorization:
            this.store.selectSignal(authHeadersSelector)().Authorization,
        },
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        map(event => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              return {
                status: FileUploadStatusType.uploading,
                progress: Math.round((100 * event.loaded) / event.total),
                response: null,
              };
            case HttpEventType.Response:
              return {
                status: FileUploadStatusType.success,
                progress: 100,
                response: event.body,
              };
            default:
              return {
                status: FileUploadStatusType.fail,
                progress: 0,
                response: null,
              };
          }
        })
      );
  }
}
