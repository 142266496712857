import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AppState } from '../../../reducers';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import {
  envelopesIdsSelector,
  signatureGettingInformationSelector,
  signatureHasMoreSelector,
  signatureTotalCountSelector,
} from '../../store/signature.selector';
import { map } from 'rxjs/operators';
import { SignatureActions } from '../../store/signature.actions';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { SdRecordTabHeaderComponent } from '../../../shared/sd-record-tab-header/sd-record-tab-header.component';
import { DictionaryPipeModule } from '../../../dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { SdLinkButtonA11yModule } from '../../../shared/sd-link-button-a11y/sd-link-button-a11y.module';
import { EnvelopeListItemComponent } from '../../shared/envelope-list-item/envelope-list-item.component';
import { SdLinkButtonA11yTemplates } from 'src/app/shared/sd-link-button-a11y/model/sd-link-button-a11y.enum';
import { SdEmptyTemplateComponent } from 'src/app/shared/templates/sd-empty-template/components/sd-empty-template/sd-empty-template.component';
import { SdProgressBarA11yComponent } from 'src/app/shared/sd-progress-bar-a11y/components/sd-progress-bar-a11y/sd-progress-bar-a11y.component';

@Component({
  selector: 'app-record-form-signature-tab',
  standalone: true,
  imports: [
    AsyncPipe,
    NgIf,
    SdProgressBarA11yComponent,
    SdRecordTabHeaderComponent,
    DictionaryPipeModule,
    SdLinkButtonA11yModule,
    NgForOf,
    EnvelopeListItemComponent,
    SdEmptyTemplateComponent,
  ],
  templateUrl: './record-form-signature-tab.component.html',
  styleUrls: ['./record-form-signature-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecordFormSignatureTabComponent {
  vm$: Observable<{
    envelopeIds: string[];
    gettingInformation: boolean;
    totalCount: number;
    hasMore: boolean;
  }> = combineLatest([
    this.store.select(envelopesIdsSelector).pipe(map(list => list as string[])),
    this.store.select(signatureGettingInformationSelector),
    this.store.select(signatureTotalCountSelector),
    this.store.select(signatureHasMoreSelector),
  ]).pipe(
    map(([envelopeIds, gettingInformation, totalCount, hasMore]) => ({
      envelopeIds,
      gettingInformation,
      totalCount,
      hasMore,
    }))
  );
  SdLinkButtonA11yTemplates = SdLinkButtonA11yTemplates;

  constructor(private readonly store: Store<AppState>) {
    store.dispatch(SignatureActions.envelopesRequested());
  }

  trackByEnvelope(index: number, envelopeId: string) {
    return envelopeId;
  }

  onViewMore(event): void {
    event.preventDefault();
    this.store.dispatch(SignatureActions.envelopesNextPageRequested());
  }
}
