export enum SettingsRoutesEnum {
  root = 'settings',
  main = '',
  personalInformation = 'personal-information',
  security = 'security',
  tos = 'tos',
  privacyPolicies = 'privacy-policies',
  resetPassword = 'reset-password',
  paymentMethods = 'payment-methods',
  licenses = 'licenses',
  support = 'support',
}

export function getSettingsRoute(route: SettingsRoutesEnum): string {
  return route.length > 0
    ? SettingsRoutesEnum.root + '/' + route
    : SettingsRoutesEnum.root;
}
