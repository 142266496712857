import { NgModule } from '@angular/core';
import { AsyncPipe, CommonModule } from '@angular/common';
import { CountrySelectorA11yComponent } from './country-selector-a11y.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SdAutocompleteA11yModule } from 'src/app/shared/sd-autocomplete-a11y/sd-autocomplete-a11y.module';
import { DictionaryPipeModule } from 'src/app/dictionary/pipes/dictionary-pipe/dictionary-pipe.module';

@NgModule({
  declarations: [CountrySelectorA11yComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    SdAutocompleteA11yModule,
    DictionaryPipeModule,
    AsyncPipe,
  ],
  exports: [CountrySelectorA11yComponent],
})
export class CountrySelectorA11yModule {}
