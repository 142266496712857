<ng-template
  #defaultTabs
  [ngIf]="template === sdTabsTemplateA11yEnum.defaultTabs">
  <mat-tab-group>
    <mat-tab *ngFor="let option of options; index as i" [label]="option">
      <ng-template #defaultTemplate let-option> {{ option }} </ng-template>
      <ng-container
        [ngTemplateOutletContext]="{ $implicit: option, index: i }"
        [ngTemplateOutlet]="templateRef[i] || defaultTemplate">
      </ng-container>
    </mat-tab>
  </mat-tab-group>
</ng-template>

<ng-template
  #tabsWithRouterLinks
  [ngIf]="template === sdTabsTemplateA11yEnum.tabsWithRouterLinks">
  <nav [tabPanel]="tabPanel" mat-tab-nav-bar>
    <a
      #rla="routerLinkActive"
      *ngFor="let link of links"
      [active]="rla.isActive"
      [ngClass]="rla.isActive ? 'sd-tab-active' : 'sd-tab-inactive'"
      [routerLink]="link.url"
      mat-tab-link
      routerLinkActive="'sd-tab-active'">
      {{ link.label }}
    </a>
  </nav>
  <mat-tab-nav-panel #tabPanel>
    <div class="tabs-router-outlet-container">
      <router-outlet></router-outlet>
    </div>
  </mat-tab-nav-panel>
</ng-template>
