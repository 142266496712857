export class Locale {
  constructor(
    public localeId: string,
    public localeName: string,
    public countryName: string,
    public countryCode: string,
    public published: boolean,
    public localeDefault: boolean,
    public languageDefault: boolean
  ) {}

  static getLocaleId(locale: Locale): string {
    return locale.localeId.replace(
      locale.countryCode.toLowerCase(),
      locale.countryCode
    );
  }
}
