import { ErrorType } from 'src/app/reminders/models/enums/error-type.enum';
import { SfrError } from 'src/app/reminders/models/sfr-error.model';
import {
  NetworksCrudActions,
  NetworksCrudActionsTypes,
} from './networks-crud.actions';

export const featureName = 'networks-crud';

export interface NetworkCrudState {
  creatingNetwork: boolean;
  errors: Map<string | ErrorType, SfrError>;
}

export const initialNetWorkCrudState: NetworkCrudState = {
  creatingNetwork: false,
  errors: null,
};

export function networkCrudReducer(
  state: NetworkCrudState = initialNetWorkCrudState,
  action: NetworksCrudActions
): NetworkCrudState {
  switch (action.type) {
    case NetworksCrudActionsTypes.NetworksCrudCreateSpinnerChange:
      return {
        ...state,
        creatingNetwork: action.payload.state,
      };
    case NetworksCrudActionsTypes.NetworksCrudAddError:
      return {
        ...state,
        errors: action.payload.errors,
      };
    default:
      return state;
  }
}
