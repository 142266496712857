export enum MyAccountRoutes {
  main = '',
  root = 'my-account',
  personalInformation = 'personal-information',
  security = 'security',
  biometricsSettings = 'biometrics-settings',
  tos = 'tos',
  privacyPolicies = 'privacy-policies',
  resetPassword = 'reset-password',
  paymentMethods = 'payment-methods',
  licenses = 'licenses',
  support = 'support',
}
