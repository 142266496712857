import { IdpMfa } from './idp-mfa.model';

export enum MfaMode {
  sometimes = 'sometimes',
  everyTime = 'everyTime',
  never = 'never',
}

export const toMfaMode = (idpMfa: IdpMfa) => {
  if (idpMfa === IdpMfa.always) {
    return MfaMode.everyTime;
  }
  if (idpMfa === IdpMfa.sometimes) {
    return MfaMode.sometimes;
  }
  if (idpMfa === IdpMfa.never) {
    return MfaMode.never;
  }
};

export const toIdpMfa = (mfaMode: MfaMode) => {
  if (mfaMode === MfaMode.everyTime) {
    return IdpMfa.always;
  }
  if (mfaMode === MfaMode.sometimes) {
    return IdpMfa.sometimes;
  }
  if (mfaMode === MfaMode.never) {
    return IdpMfa.never;
  }
};
