import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, finalize, map, mergeMap, take, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { StartFlowEffects } from 'src/app/start-flow/store/start-flow.effects';
import { MatDialog } from '@angular/material/dialog';

import { select, Store } from '@ngrx/store';
import { AccountService } from 'src/app/account/services/account.service';
import { DictionaryService } from 'src/app/dictionary/services/dictionary.service';
import { HomeService } from 'src/app/home/services/home.service';
import { SidedrawerService } from 'src/app/sidedrawer/services/sidedrawer.service';
import { AppState } from 'src/app/reducers';
import { forkJoin, of } from 'rxjs';
import { accountSelector } from 'src/app/account/store/account.selector';

import { StartFlowStepKeys } from 'src/app/start-flow/models/start-flow-step-keys.model';
import { StartFlowStepStatus } from 'src/app/start-flow/models/start-flow-step-status.model';
import {
  CoreActionsTypes,
  SecurityCompleted,
} from 'src/app/core/store/core.actions';
import { SideDrawerNetworksStartFlowRequested } from 'src/app/sidedrawer-networks/store/side-drawer-networks-requests.actions';
import { BiometricsActions } from '../../biometrics/store/biometrics.store';
import {
  StartFlowInvitationUsed,
  StartFlowStepUpdated,
} from 'src/app/start-flow/store/start-flow.actions';
import { startFlowInvitationCodeSelector } from 'src/app/start-flow/store/start-flow.selectors';
import { CoreRoutes } from 'src/app/core/routes/core.routes';
import { MobileAccountRoutesEnum } from '../../account/routes/mobile-account-routes.enum';
import { BiometricsService } from '../../biometrics/services/biometrics.service';
import { PreferencesService } from '../../biometrics/services/preferences.service';
import { ConfigService } from '../../../../core/services/config.service';

@Injectable()
export class MobileStartFlowEffects extends StartFlowEffects {
  biometricsService = inject(BiometricsService);
  preferencesService = inject(PreferencesService);

  securityComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SecurityCompleted>(CoreActionsTypes.SecurityCompleted),
        mergeMap(() =>
          forkJoin([
            this.biometricsService.checkBiometrics(),
            this.preferencesService.haveAlreadyLoggedInDevice(),
          ]).pipe(
            map(([{ isAvailable }, { value }]) => {
              if (isAvailable && !value) {
                this.router.navigateByUrl(
                  `/${CoreRoutes.root}/${MobileAccountRoutesEnum.root}/${MobileAccountRoutesEnum.activateBiometricsFirstTime}`
                );
                return;
              }
              this.store.dispatch(
                BiometricsActions.completedBiometricsOnRegister()
              );
            }),
            finalize(() =>
              this.preferencesService
                .setFirstDeviceLogin()
                .subscribe()
                .unsubscribe()
            )
          )
        )
      ),

    { dispatch: false }
  );

  biometricsStartingCompleted$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BiometricsActions.completedBiometricsOnRegister),
        tap(() => {
          this.store.dispatch(
            new StartFlowStepUpdated({
              key: StartFlowStepKeys.securityStep,
              step: { status: StartFlowStepStatus.complete },
            })
          );
        }),
        tap(() => {
          this.store.dispatch(
            new StartFlowStepUpdated({
              key: StartFlowStepKeys.sendInvitationStep,
              step: { status: StartFlowStepStatus.processing },
            })
          );
        }),
        mergeMap(() =>
          forkJoin([
            this.store.pipe(select(startFlowInvitationCodeSelector), take(1)),
            this.store.pipe(select(accountSelector), take(1)),
          ]).pipe(
            mergeMap(([invitationCode, account]) => {
              if (!invitationCode) {
                this.store.dispatch(
                  new StartFlowStepUpdated({
                    key: StartFlowStepKeys.sendInvitationStep,
                    step: { status: StartFlowStepStatus.complete },
                  })
                );
                this.store.dispatch(new SideDrawerNetworksStartFlowRequested());
                return of(null);
              }
              return this.accountService
                .sendInvitationCode(account, invitationCode)
                .pipe(
                  catchError(() => of(null)),
                  tap(() => {
                    this.store.dispatch(new StartFlowInvitationUsed());
                    this.store.dispatch(
                      new StartFlowStepUpdated({
                        key: StartFlowStepKeys.sendInvitationStep,
                        step: { status: StartFlowStepStatus.complete },
                      })
                    );
                    this.store.dispatch(
                      new SideDrawerNetworksStartFlowRequested()
                    );
                  }),
                );
            })
          )
        )
      ),
    { dispatch: false }
  );

  constructor(
    protected readonly actions$: Actions,
    protected readonly store: Store<AppState>,
    protected readonly router: Router,
    protected readonly homeService: HomeService,
    protected readonly accountService: AccountService,
    protected readonly dictionaryService: DictionaryService,
    protected readonly configService: ConfigService,
    protected readonly sidedrawerService: SidedrawerService,
    protected readonly dialog: MatDialog
  ) {
    super(
      actions$,
      store,
      router,
      homeService,
      accountService,
      dictionaryService,
      configService,
      sidedrawerService,
      dialog
    );
  }
}
