import { Action } from '@ngrx/store';
import { ErrorType } from 'src/app/reminders/models/enums/error-type.enum';
import { SfrError } from 'src/app/reminders/models/sfr-error.model';
import { CreateRecordNetworkDto } from '../../models/create-record-network.dto';

export enum NetworksCrudActionsTypes {
  NetworksCrudCreateRequested = '[Effect] Networks Crud Create Requested',
  NetworksCrudCreateSuccess = '[Effect] Networks Crud Create Success',
  NetworksCrudCreateSpinnerChange = '[Effect] Networks Crud Create Spinner Change',
  NetworksCrudAddError = '[Effect] Networks Crud Add Error',
  NetworksCrudSetErrors = '[Effect] Networks Crud Set Errors',
}

export class NetworksCrudCreateRequested implements Action {
  readonly type = NetworksCrudActionsTypes.NetworksCrudCreateRequested;

  constructor(
    public payload: {
      sidedrawerId: string;
      recordId: string;
      network: CreateRecordNetworkDto;
    }
  ) {}
}

export class NetworksCrudCreateSuccess implements Action {
  readonly type = NetworksCrudActionsTypes.NetworksCrudCreateSuccess;
}

export class NetworksCrudCreateSpinnerChange implements Action {
  readonly type = NetworksCrudActionsTypes.NetworksCrudCreateSpinnerChange;

  constructor(public payload: { state: boolean }) {}
}

export class NetworksCrudAddError implements Action {
  readonly type = NetworksCrudActionsTypes.NetworksCrudAddError;

  constructor(public payload: { errors: Map<string | ErrorType, SfrError> }) {}
}

export class NetworksCrudSetErrors implements Action {
  readonly type = NetworksCrudActionsTypes.NetworksCrudSetErrors;

  constructor(public payload: { errors: Map<string | ErrorType, SfrError> }) {}
}

export type NetworksCrudActions =
  | NetworksCrudCreateRequested
  | NetworksCrudCreateSuccess
  | NetworksCrudCreateSpinnerChange
  | NetworksCrudAddError
  | NetworksCrudSetErrors;
