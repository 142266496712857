export enum MenuItemType {
  delete = 'delete',
  share = 'share',
  create = 'create',
  download = 'download',
  support = 'support',
  view = 'view',
  edit = 'edit',
  reminders = 'reminders',
  files = 'files',
  copy = 'copy',
  play = 'play',
  pause = 'pause',
  docusign = 'docusign',
  relation = 'relation',
  rename = 'rename',
  editSideDrawerName = 'editSideDrawerName',
  editFileName = 'editFileName',
  assignlicenses = 'assignlicenses',
  addremovelicenses = 'addremovelicenses',
  buylicenses = 'buylicenses',
  moveFile = 'moveFile',
  copyFile = 'copyFile',
  manage = 'manage',
  copyRecord = 'copyRecord',
  moveRecord = 'moveRecord',
  upload = 'upload',
}

export interface SdImage {
  name: string;
  source: string;
}
