import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { debounceTime, delay, filter, map, take, tap } from 'rxjs/operators';
import { SideDrawerNetwork } from '../../../sidedrawer-networks/models/side-drawer-network.model';
import { environment } from '../../../../environments/environment';
import { SdLinkMenuType } from '../../../shared/sd-link-menu-a11y/models/enums/sd-link-menu-type-enum';
import { SideDrawerRequested } from '../../store/sidedrawer.actions';
import { sideDrawerNetworkListSortedSelector } from '../../../sidedrawer-networks/store/side-drawer-network-list.selectors';
import {
  SideDrawerNetworksInfiniteListNextPageRequested,
  SideDrawerNetworksInfiniteListRequested,
} from '../../../sidedrawer-networks/store/side-drawer-networks-requests-infinite-list.actions';
import {
  gettingSideDrawerNetworksInfiniteListSelector,
  sideDrawerNetworksInfiniteListHasMoreSelector,
} from '../../../sidedrawer-networks/store/side-drawer-networks-requests-infinite-list.selectors';
import { SdLinkMenuButtonType } from '../../../shared/sd-link-menu-a11y/models/enums/sd-link-menu-button-type-enum';
import { combineLatest, EMPTY, Observable, of, Subscription } from 'rxjs';
import { searchSidedrawerListFilterSelector } from 'src/app/search/store/search-sidedrawer-list.selector';
import { searchSideDrawerRequestListHasMoreSelector } from 'src/app/search/store/search-sidedrawer-requests-list.selector';
import {
  SearchSideDrawerListActionsTypes,
  SearchSideDrawerListNextPageRequested,
  SearchSideDrawerListPageLoaded,
  SearchSideDrawerListRequested,
  SearchSideDrawerListReset,
} from 'src/app/search/store/search-sidedrawer-list.actions';
import { searchingSelector } from 'src/app/search/store/search-bar.selectors';
import { HasMoreModel } from 'src/app/shared/sd-link-menu-a11y/models/enums/sd-has-more.model';
import { HasMoreEnum } from 'src/app/shared/sd-link-menu-a11y/models/enums/sd-has-more.enum';
import { SdColorPalette } from '../../../core/models/enums/sd-color-palette-enum';
import { SdLinkMenuA11yModule } from '../../../shared/sd-link-menu-a11y/sd-link-menu-a11y.module';
import {
  AsyncPipe,
  JsonPipe,
  NgClass,
  NgFor,
  NgIf,
  NgStyle,
} from '@angular/common';
import { DictionaryPipeModule } from '../../../dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SdSidedrawerMenuA11yTemplates } from '../../models/sd-sidedrawer-menu-a11y-templates.enum';
import { SdEmptyTemplateComponent } from 'src/app/shared/templates/sd-empty-template/components/sd-empty-template/sd-empty-template.component';
import { SdSideDrawerItemA11yComponent } from 'src/app/shared/sd-side-drawer-item-a11y/sd-side-drawer-item-a11y.component';
import { SdProgressBarA11yComponent } from 'src/app/shared/sd-progress-bar-a11y/components/sd-progress-bar-a11y/sd-progress-bar-a11y.component';
import { SdSvgA11yModule } from 'src/app/shared/sd-svg-a11y/sd-svg-a11y.module';
import { SdInputA11yComponent } from 'src/app/shared/sd-input-a11y/components/sd-input-a11y/sd-input-a11y.component';
import { SdInputA11yTemplates } from 'src/app/shared/sd-input-a11y/models/sd-input-a11y-templates.enum';
import { UntypedFormControl } from '@angular/forms';
import { Actions, ofType } from '@ngrx/effects';
import { AccessibilityHelper } from 'src/app/core/helpers/accessibility.helper';
import { SdAccessibilitySetting } from 'src/app/core/models/sd-accessibility-model';
import { SdIconButtonA11yComponent } from 'src/app/shared/sd-icon-button-a11y/components/sd-icon-button-a11y/sd-icon-button-a11y.component';
import { SidedrawerRoles } from 'src/app/core/roles/sidedrawer.roles';
import { SdLinkMenuA11yComponent } from 'src/app/shared/sd-link-menu-a11y/components/sd-link-menu-a11y/sd-link-menu-a11y.component';
import { MainHeaderDisplayNamePipe } from 'src/app/home/pipes/main-header-display-name.pipe';

@Component({
  selector: 'app-sidedrawer-menu-a11y',
  template: `
    <!-- TODO ACCESSIBILITY AND DICTIONARY -->
    <ng-template [ngIf]="template === sdSidedrawerMenuA11yTemplates.default">
      <app-sd-link-menu-a11y
        #sdLinkMenu
        (loadMore)="onLoadMore($event)"
        (menuOpenedEvent)="onMenuOpened()"
        (switchSideDrawer)="onSwitchSideDrawer($event)"
        [buttonType]="sdLinkMenuButtonType.icon"
        [emptystate]="'sidedrawermenu_emptystate' | dictionary | async"
        [iconColor]="sdColorPalette.primaryColor"
        [icon]="cdn + ('sidedrawermenu_menuicon' | dictionary | async)"
        [tooltip]="'sidedrawermenu_tooltip' | dictionary | async"
        [menuDataSd]="sdNetworksFiltered$ | async"
        [menuType]="sdLinkMenuType.sideDrawerMenu"
        [placeholder]="'sidedrawermenu_searchplaceholder' | dictionary | async"
        [primary]="primary"
        [setHasMoreModel]="hasMoreModel"
        [setProgress]="
          (progressSpinner$ | async) || (searchingSelector$ | async)
        "
        [squareBackground]="true"
        class="header-manage-sidedrawer-button" />
    </ng-template>

    <ng-template [ngIf]="template === sdSidedrawerMenuA11yTemplates.mainHeader">
      <app-sd-link-menu-a11y
        #sdLinkMenu
        (loadMore)="onLoadMore($event)"
        (keyup)="sdLinkMenu.onFocusIcon()"
        (menuOpenedEvent)="onMenuOpened()"
        (menuClosedEvent)="onMenuClosed()"
        (switchSideDrawer)="onSwitchSideDrawer($event)"
        [buttonType]="sdLinkMenuButtonType.custom"
        [customContentForSdMenu]="templateRef"
        [emptystate]="'sidedrawermenu_emptystate' | dictionary | async"
        [iconColor]="sdColorPalette.primaryColor"
        [icon]="cdn + ('sidedrawermenu_menuicon' | dictionary | async)"
        [tooltip]="'sidedrawermenu_tooltip' | dictionary | async"
        [menuDataSd]="sdNetworksFiltered$ | async"
        [menuType]="sdLinkMenuType.sideDrawerMenu"
        [placeholder]="'sidedrawermenu_searchplaceholder' | dictionary | async"
        [primary]="primary"
        [setHasMoreModel]="hasMoreModel"
        [setProgress]="
          (progressSpinner$ | async) || (searchingSelector$ | async)
        "
        [squareBackground]="true"
        class="header-manage-sidedrawer-button" />
    </ng-template>

    <ng-template [ngIf]="template === sdSidedrawerMenuA11yTemplates.inline">
      <div>
        <div class="sd-link-menu-search-section">
          <div
            (click)="$event.stopPropagation()"
            (keyup)="onKeyUp($event)"
            class="sd-nav-var-search-input">
            <app-sd-svg-a11y
              [color]="sdColorPalette.secondaryAccentColor"
              [height]="1.125"
              [setSdAccessibility]="{
                tabIndex: -1,
                role: 'img',
                ariaLabel:
                  'sidedrawermenu_searchplaceholder' | dictionary | async
              }"
              [src]="cdn + ('globalparams_searchicon' | dictionary | async)"
              [width]="1.125"></app-sd-svg-a11y>
            <app-sd-input-a11y
              [attr.aria-label]="sdAccessibility?.ariaLabel"
              [id]="sdAccessibility?.id"
              (focusin)="showResults = true; onMenuOpened()"
              [autocomplete]="false"
              [controller]="searchController"
              [placeholder]="placeholder"
              [setSdAccessibility]="{
                ariaLabel: 'placeholder',
                id: 'sd-link-menu-a11y-app-sd-input-a11y'
              }"
              [sdAccessibilityForSuffix]="{ role: 'img', ariaLabel: '' }"
              [template]="sdInputA11yTemplates.inputForSearch"
              [type]="'text'">
            </app-sd-input-a11y>
            <app-sd-icon-button-a11y
              *ngIf="showResults"
              (btnClicked)="resetSearchBar()"
              (keydown.enter)="resetSearchBar()"
              [iconColor]="'var(--secondaryAccentColor)'"
              [iconHeight]="1"
              [iconWidth]="1"
              [buttonHeight]="1"
              [buttonWidth]="1"
              [icon]="cdn + ('globalparams_closeicon' | dictionary | async)"
              [setSdAccessibility]="{
                ariaLabel: 'globalparams_reset' | dictionary | async,
                role: 'button'
              }" />
          </div>
          <app-sd-progress-bar-a11y
            [ngStyle]="{
              position: 'relative',
              top: '0',
              left: '0',
              width: '100%',
              'z-index': '9999'
            }"
            [setIsLoading]="
              (progressSpinner$ | async) || (searchingSelector$ | async)
            " />
          <div class="sd-menu" *ngIf="showResults">
            <div
              *ngFor="
                let item of sdNetworksFiltered$ | async;
                let i = index;
                trackBy: itemSideDrawerTrackBy
              ">
              <a
                [tabindex]="0"
                (keydown.enter)="selectSideDrawer.emit(item)"
                (click)="selectSideDrawer.emit(item); showResults = false"
                class="sd-menu-item">
                <app-sd-side-drawer-item-a11y [otherSideDrawer]="item" />
              </a>
              <div
                (click)="onLoadMore(hasMoreModel); $event.stopPropagation()"
                (keydown.enter)="
                  onLoadMore(hasMoreModel); $event.stopPropagation()
                "
                *ngIf="
                  hasMoreModel.hasMore &&
                  i === (sdNetworksFiltered$ | async).length - 1
                "
                class="load-more-link"
                [ngClass]="{
                  disabled:
                    (progressSpinner$ | async) || (searchingSelector$ | async)
                }">
                {{ 'globalparams_viewmore' | dictionary | async }}
              </div>
              <div #anchor></div>
            </div>

            <div
              *ngIf="
                (!(sdNetworksFiltered$ | async) ||
                  (sdNetworksFiltered$ | async)?.length === 0) &&
                !((progressSpinner$ | async) || (searchingSelector$ | async))
              "
              class="search-result-empty-state"
              mat-menu-item>
              <app-sd-empty-template
                [emptyStateInline]="true"
                [emptyStateString]="emptystate"></app-sd-empty-template>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  `,
  styleUrls: ['./sidedrawer-menu-a11y.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SdLinkMenuA11yModule,
    AsyncPipe,
    DictionaryPipeModule,
    MatTooltipModule,
    NgIf,
    NgFor,
    SdEmptyTemplateComponent,
    SdSideDrawerItemA11yComponent,
    SdProgressBarA11yComponent,
    NgStyle,
    SdSvgA11yModule,
    SdInputA11yComponent,
    NgClass,
    SdIconButtonA11yComponent,
    JsonPipe,
    MainHeaderDisplayNamePipe,
  ],
})
export class SidedrawerMenuA11yComponent implements OnInit, OnDestroy {
  @Input() filter = '';
  @Input() primary: boolean;
  @Input() placeholder: string;
  @Input() emptystate: string;
  @Input() template = SdSidedrawerMenuA11yTemplates.default;
  @Input() templateRef: TemplateRef<unknown>;
  @Input() set setSdAccessibility(value: SdAccessibilitySetting) {
    this.sdAccessibility = AccessibilityHelper.setDefaultAccessibility(value);
  }
  @Input() filterOwnerEditor = false;
  @Input() set setShowResult(value: boolean) {
    this.showResults = value;
  }
  @Input() filingCabinetMode = true;

  sdAccessibility: SdAccessibilitySetting;
  @Output() selectSideDrawer = new EventEmitter<SideDrawerNetwork>();
  @Output() loadMore = new EventEmitter<HasMoreModel>();
  @Output() menuOpened = new EventEmitter<boolean>();
  @ViewChild(SdLinkMenuA11yComponent) sdLinkMenu: SdLinkMenuA11yComponent;

  showResults = false;
  cdn = environment.cdn;
  sdLinkMenuType = SdLinkMenuType;
  hasMoreModel: HasMoreModel;
  hasMoreEnum = HasMoreEnum;
  sdColorPalette = SdColorPalette;
  sdSidedrawerMenuA11yTemplates = SdSidedrawerMenuA11yTemplates;
  progressSpinner$ = this.store.select(
    gettingSideDrawerNetworksInfiniteListSelector
  );
  subscription = new Subscription();
  searchController = new UntypedFormControl(null);
  searchingSelector$ = this.store.select(searchingSelector);
  sdInputA11yTemplates = SdInputA11yTemplates;
  sdLinkMenuButtonType = SdLinkMenuButtonType;

  sdList: SideDrawerNetwork[] = [];
  sdNetworksFiltered$: Observable<SideDrawerNetwork[]> = combineLatest([
    this.store.pipe(select(sideDrawerNetworkListSortedSelector)),
    this.store.pipe(select(searchSidedrawerListFilterSelector)),
  ]).pipe(
    map(([sdNetworks, searchSidedrawerListFilter]) => {
      if (searchSidedrawerListFilter.trim() === '') {
        this.store
          .pipe(
            select(sideDrawerNetworksInfiniteListHasMoreSelector),
            take(1),
            tap(hasMore => {
              if (hasMore) {
                this.hasMoreModel = {
                  ...this.hasMoreModel,
                  hasMore: true,
                  type: this.hasMoreEnum.sidedrawerFromNetworks,
                };
              }
              if (!hasMore) {
                this.hasMoreModel = {
                  ...this.hasMoreModel,
                  hasMore: false,
                  type: this.hasMoreEnum.sidedrawerFromNetworks,
                };
              }
            })
          )
          .subscribe();

        if (this.filterOwnerEditor) {
          this.sdList = sdNetworks.filter(
            e =>
              e.sdRole === SidedrawerRoles.editor ||
              e.sdRole === SidedrawerRoles.owner
          );
          sdNetworks = sdNetworks.filter(sdNetwork => {
            return (
              sdNetwork.sdRole === SidedrawerRoles.editor ||
              sdNetwork.sdRole === SidedrawerRoles.owner
            );
          });
        }
        this.sdList = sdNetworks;
        return sdNetworks;
      }
      let sdNetworksFiltered = sdNetworks.filter(sdNetwork => {
        return sdNetwork.name
          .toLowerCase()
          .includes(searchSidedrawerListFilter.toLowerCase());
      });

      if (this.filterOwnerEditor) {
        sdNetworksFiltered = sdNetworksFiltered.filter(sdNetwork => {
          return (
            sdNetwork.sdRole === SidedrawerRoles.editor ||
            sdNetwork.sdRole === SidedrawerRoles.owner
          );
        });
      }

      this.store
        .pipe(
          select(searchSideDrawerRequestListHasMoreSelector),
          take(1),
          tap(hasMore => {
            if (hasMore) {
              this.hasMoreModel = {
                ...this.hasMoreModel,
                hasMore: true,
                type: this.hasMoreEnum.sidedrawerFromSearch,
              };
            }
            if (!hasMore) {
              this.hasMoreModel = {
                ...this.hasMoreModel,
                hasMore: false,
                type: this.hasMoreEnum.sidedrawerFromSearch,
              };
            }
          })
        )
        .subscribe();

      return sdNetworksFiltered;
    })
  );
  protected readonly SdColorPalette = SdColorPalette;

  constructor(
    private readonly store: Store<AppState>,
    private readonly actions$: Actions
  ) {}

  ngOnInit(): void {
    this.subscription.add(this.search$.subscribe());
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onSwitchSideDrawer(myOtherSd: SideDrawerNetwork): void {
    this.store.dispatch(new SideDrawerRequested({ sdId: myOtherSd?.id }));
  }

  onLoadMore(event: HasMoreModel): void {
    if (event.type === this.hasMoreEnum.sidedrawerFromNetworks) {
      this.store.dispatch(
        new SideDrawerNetworksInfiniteListNextPageRequested()
      );
    }
    if (event.type === this.hasMoreEnum.sidedrawerFromSearch) {
      this.store.dispatch(new SearchSideDrawerListNextPageRequested());
    }
  }

  onMenuOpened(): void {
    //this.store.dispatch(new SideDrawerNetworksInfiniteListRequested());
    this.menuOpened.emit(true);
  }

  onMenuClosed(): void {
    this.menuOpened.emit(false);
  }

  itemSideDrawerTrackBy(
    index: number,
    item: SideDrawerNetwork
  ): SideDrawerNetwork {
    return item;
  }
  actualPosition = -1;

  onKeyUp(event: KeyboardEvent): void {
    if (event.key !== 'ArrowDown' && event.key !== 'ArrowUp') {
      event.stopPropagation();
      event.preventDefault();
      return;
    }

    const links = document.querySelectorAll('a.sd-menu-item');

    if (this.actualPosition === 0 && event.key === 'ArrowUp') {
      this.actualPosition = -1;
      this.setFocusForSideDrawerMenu();
      return;
    }

    if (this.actualPosition === links.length - 1 && event.key === 'ArrowDown') {
      this.actualPosition = -1;
      this.setFocusForSideDrawerMenu();
      return;
    }

    if (event.key === 'ArrowDown') {
      this.actualPosition++;
    }

    if (event.key === 'ArrowUp') {
      this.actualPosition--;
    }

    const element = links[this.actualPosition] as HTMLAnchorElement;
    element?.focus();
  }

  private setFocusForSideDrawerMenu(): void {
    of(EMPTY)
      .pipe(
        take(1),
        delay(300),
        tap(() => {
          const element = document.getElementById(
            'sd-link-menu-a11y-app-sd-input-a11y'
          );
          if (element) {
            element?.focus();
          }
        })
      )
      .subscribe();
  }

  search$ = this.searchController.valueChanges.pipe(
    tap(value => {
      if (value?.length === 0) {
        this.store.dispatch(new SearchSideDrawerListReset());
        this.store.dispatch(new SideDrawerNetworksInfiniteListRequested());
      }
    }),
    debounceTime(1000),
    filter(value => value?.length > 0),
    map(() => {
      this.store.dispatch(
        new SearchSideDrawerListRequested({
          name: this.searchController.value,
        })
      );

      this.actions$
        .pipe(
          ofType(
            SearchSideDrawerListActionsTypes.SearchSideDrawerListPageLoaded
          ),
          take(1),
          tap((action: SearchSideDrawerListPageLoaded) => {
            if (
              action.type ===
              SearchSideDrawerListActionsTypes.SearchSideDrawerListPageLoaded
            ) {
              this.setFocusForSideDrawerMenu();
            }
          })
        )
        .subscribe();
    })
  );

  resetSearchBar(): void {
    this.searchController.setValue('');
    this.showResults = false;
  }
}
