export class Recurrent {
  constructor(
    public frequency?: string,
    public iteration?: number,
    public pattern?: string,
    public endDate?: string,
    public skipCondition?: string
  ) {
    this.skipCondition = 'complete';
  }
}
