import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import {
  DataBaseRegionsLoaded,
  DictionaryActionsTypes,
} from '../../dictionary/store/dictionary.actions';
import { forkJoin, of, switchMap, tap } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import {
  accountDataBaseRegionAsDataBaseRegionSelector,
  accountDataBaseRegionSelector,
  notAccountDataBaseRegionSelector,
} from '../../account/store/account.selector';
import { SideDrawerNetworkRequestType } from '../models/side-drawer-network-request-type.enum';
import {
  GettingSideDrawerNetworksRequestsChange,
  SideDrawerNetworksInitialPageLoaded,
} from './side-drawer-networks-requests.actions';
import {
  AccountActionsTypes,
  AccountLoaded,
} from '../../account/store/account.actions';
import { dataBaseRegionsSelector } from '../../dictionary/store/dictionary.selectors';
import {
  SideDrawerNetworkListActionsTypes,
  SideDrawerNetworkLoaded,
  SideDrawerNetworkRequested,
} from './side-drawer-network-list.actions';
import { sideDrawerNetworkListSelector } from './side-drawer-network-list.selectors';
import { SideDrawerNetwork } from '../models/side-drawer-network.model';
import { SideDrawerNetworksHelper } from '../helpers/side-drawer-networks.helper';
import { ErrorLoaded } from '../../core/store/core.actions';
import { PaginatorService } from '../../core/services/paginator.service';
import { SideDrawerNetworksInfiniteListOrderLoaded } from './side-drawer-networks-requests-infinite-list.actions';

@Injectable()
export class SideDrawerNetworksRequestsEffects {
  initSideDrawerNetworksRequests$ = createEffect(() =>
    forkJoin([
      this.actions$.pipe(
        ofType<DataBaseRegionsLoaded>(
          DictionaryActionsTypes.DataBaseRegionsLoaded
        ),
        take(1)
      ),
      this.actions$.pipe(
        ofType<AccountLoaded>(AccountActionsTypes.AccountLoaded),
        take(1)
      ),
    ]).pipe(
      switchMap(() =>
        forkJoin([
          this.store.pipe(
            select(accountDataBaseRegionAsDataBaseRegionSelector),
            take(1)
          ),
          this.store.pipe(select(notAccountDataBaseRegionSelector), take(1)),
          this.store.pipe(select(dataBaseRegionsSelector), take(1)),
        ])
      ),
      tap(([, , dataBaseRegions]) => {
        dataBaseRegions
          .filter(region => region?.countrycode?.length > 0)
          .map(region => ({
            owned: new SideDrawerNetworksInitialPageLoaded({
              result: {
                data: [],
                id: `${region?.databaseregion}-${SideDrawerNetworkRequestType.owned}`,
                dataBaseRegion: region?.databaseregion,
                requestType: SideDrawerNetworkRequestType.owned,
                filter: null,
                hasMore: false,
                nextPage: null,
                previousPage: null,
                totalCount: 0,
              },
            }),
            shared: new SideDrawerNetworksInitialPageLoaded({
              result: {
                data: [],
                id: `${region.databaseregion}-${SideDrawerNetworkRequestType.shared}`,
                dataBaseRegion: region.databaseregion,
                requestType: SideDrawerNetworkRequestType.shared,
                filter: null,
                hasMore: false,
                nextPage: null,
                previousPage: null,
                totalCount: 0,
              },
            }),
          }))
          .forEach(({ owned, shared }) => {
            this.store.dispatch(owned);
            this.store.dispatch(shared);
          });
      }),
      map(
        ([accountDataBaseRegion, notAccountDataBaseRegions]) =>
          new SideDrawerNetworksInfiniteListOrderLoaded({
            infiniteListOrder: accountDataBaseRegion
              ? [
                  `${accountDataBaseRegion.databaseregion}-${SideDrawerNetworkRequestType.owned}`,
                  ...notAccountDataBaseRegions.map(
                    region =>
                      `${region.databaseregion}-${SideDrawerNetworkRequestType.owned}`
                  ),
                  `${accountDataBaseRegion.databaseregion}-${SideDrawerNetworkRequestType.shared}`,
                  ...notAccountDataBaseRegions.map(
                    region =>
                      `${region.databaseregion}-${SideDrawerNetworkRequestType.shared}`
                  ),
                ]
              : [
                  ...notAccountDataBaseRegions.map(
                    region =>
                      `${region.databaseregion}-${SideDrawerNetworkRequestType.owned}`
                  ),
                  ...notAccountDataBaseRegions.map(
                    region =>
                      `${region.databaseregion}-${SideDrawerNetworkRequestType.shared}`
                  ),
                ],
          })
      )
    )
  );
  sideDrawerNetworkRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SideDrawerNetworkRequested>(
        SideDrawerNetworkListActionsTypes.SideDrawerNetworkRequested
      ),
      tap(() =>
        this.store.dispatch(
          new GettingSideDrawerNetworksRequestsChange({ state: true })
        )
      ),
      switchMap(action =>
        forkJoin([
          this.store.pipe(select(accountDataBaseRegionSelector), take(1)),
          of(action.payload).pipe(take(1)),
          this.store.pipe(select(sideDrawerNetworkListSelector), take(1)),
        ])
      ),
      switchMap(([accountDataBaseRegion, payload, sideDrawerNetworkList]) =>
        this.paginatorService // TODO see this...
          .getPaginatedResource<SideDrawerNetwork>(
            SideDrawerNetworksHelper.getSideDrawerNetworksResourceUrl(
              payload.requestType,
              {
                dataBaseRegion: payload.dataBaseRegion ?? accountDataBaseRegion,
                name: payload.name,
              }
            )
          )
          .pipe(
            map(response => {
              return new SideDrawerNetworkLoaded({
                networks: SideDrawerNetworksHelper.filterDuplicatedSdNetworks(
                  sideDrawerNetworkList,
                  response.data
                ),
              });
            }),
            catchError(error =>
              of(new ErrorLoaded({ httpError: error, display404: true }))
            )
          )
      ),
      tap(() =>
        this.store.dispatch(
          new GettingSideDrawerNetworksRequestsChange({ state: false })
        )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
    private readonly paginatorService: PaginatorService
  ) {}
}
