import { Directory, Filesystem, WriteFileResult } from '@capacitor/filesystem';
import { FilesHelper } from '../../../../files/helpers/files.helper';
import { FileType } from '../../../../files/models/file-type.enum';
import { FileItem } from '../../../../files/models/file-item.model';
import {DateHelper} from '../../../../core/helpers/date.helper';

export class MobileFilesHelper {
  public static async checkAndRequestPermissions() {
    try {
      const haveAlreadyPermissions = await Filesystem.checkPermissions();
      if (haveAlreadyPermissions.publicStorage !== 'granted') {
        const { publicStorage } = await Filesystem.requestPermissions();
        return publicStorage === 'granted';
      }
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }
  public static async saveFileInDevice(
    blob: Blob,
    fileItem: FileItem
  ): Promise<WriteFileResult> {
    if (await MobileFilesHelper.checkAndRequestPermissions()) {
      try {
        const { name } = FilesHelper.blobToFile(
          blob,
          fileItem.fileType === FileType.cloud
            ? FilesHelper.formatFileNameWithExtensionForDownLoad(
                fileItem.fileName
              )
            : FilesHelper.formatFileNameWithExtensionForDownLoad(
                FilesHelper.getFileNameWithExtension(
                  fileItem.uploadTitle  + '-' + DateHelper.getTimeStamp(),
                  fileItem
                )
              )
        );
        const textFromBlob = await FilesHelper.blobToBase64(blob);
        return await Filesystem.writeFile({
          path: name,
          data: textFromBlob,
          directory: Directory.Documents,
        });
      } catch (error) {
        console.error({ error });
        throw error;
      }
    } else {
      throw new Error('Permisions were not granted to device Storage');
    }
  }
}
