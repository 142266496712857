import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  FileHistoryActions,
  FileHistoryActionsTypes,
} from './file-history.actions';
import { FileType } from '../models/file-type.enum';
import { FileItem } from '../models/file-item.model';
import { FilesSortOption } from '../models/file-sort-option.model';

export interface FileHistoryState extends EntityState<FileItem> {
  gettingFiles: boolean;
  gettingSingleFiles: boolean;
  hasMore: boolean;
  nextPage: string;
  previousPage: string;
  totalCount: number;
  filter: {
    fileName?: string;
    uploadTitle?: string;
    fileType?: FileType;
  };
  sortBy: FilesSortOption;
  openFile: string;
}

export const fileHistoryAdapter: EntityAdapter<FileItem> =
  createEntityAdapter<FileItem>({
    selectId: fileItem => fileItem.id,
  });

export const initialFileHistoryState: FileHistoryState =
  fileHistoryAdapter.getInitialState({
    gettingFiles: false,
    gettingSingleFiles: false,
    hasMore: false,
    nextPage: null,
    previousPage: null,
    totalCount: null,
    filter: null,
    sortBy: FilesSortOption.lastModified,
    openFile: null,
  });

export function fileHistoryReducer(
  state = initialFileHistoryState,
  action: FileHistoryActions
): FileHistoryState {
  switch (action.type) {
    case FileHistoryActionsTypes.FileHistoryLoaded: {
      const { hasMore, nextPage, previousPage, totalCount, data } =
        action.payload.data;
      const filesAux = fileHistoryAdapter.upsertMany(data, state);
      return {
        ...state,
        ...filesAux,
        hasMore,
        nextPage,
        previousPage,
        totalCount,
      };
    }
    case FileHistoryActionsTypes.FileHistoryDeleted:
      return fileHistoryAdapter.removeOne(action.payload.id, state);
    case FileHistoryActionsTypes.FileHistoryClear:
      return fileHistoryAdapter.removeAll(state);
    case FileHistoryActionsTypes.GettingFilesChange:
      return {
        ...state,
        gettingFiles: action.payload.state,
      };
    case FileHistoryActionsTypes.GettingSingleFileHistoryChange:
      return {
        ...state,
        gettingSingleFiles: action.payload.state,
      };
    case FileHistoryActionsTypes.SingleFileHistoryLoaded:
      return fileHistoryAdapter.upsertOne(action.payload.fileItem, state);
    case FileHistoryActionsTypes.FilesFilterChange: {
      const { fileType, fileName, uploadTitle } = action.payload;
      return {
        ...state,
        filter: {
          ...state.filter,
          fileType,
          fileName,
          uploadTitle,
        },
      };
    }
    case FileHistoryActionsTypes.OpenFileHistory:
      return {
        ...state,
        openFile: action.payload.id,
      };
    case FileHistoryActionsTypes.CleanOpenFileHistory:
      return {
        ...state,
        openFile: null,
      };
    case FileHistoryActionsTypes.FilesViewSortByChange:
      return {
        ...state,
        sortBy: action.payload.option,
      };
    case FileHistoryActionsTypes.TotalCountFileHistoryUpdate:
      return {
        ...state,
        totalCount: action.payload.totalCount,
      };
    default:
      return state;
  }
}

export const { selectAll, selectEntities, selectTotal } =
  fileHistoryAdapter.getSelectors();
