import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CoreState, featureName } from './core.reducer';

export const coreSelector = createFeatureSelector<CoreState>(featureName);

export const currentBrandingSelector = createSelector(coreSelector, state =>
  state.currentBranding ? state.currentBranding : null
);

export const defaultBrandingSelector = createSelector(coreSelector, state =>
  state.defaultBranding ? state.defaultBranding : null
);

export const securityCompletedSelector = createSelector(
  coreSelector,
  state => state.securityCompleted
);

export const displayInformationSelector = createSelector(
  coreSelector,
  state => state.displayInfo
);

export const appSectionsSelector = createSelector(
  currentBrandingSelector,
  branding => (branding ? branding.appSections : null)
);

export const manageSubscriptionsEnabledSelector = createSelector(
  appSectionsSelector,
  appSections => !!appSections?.manageSubscriptions ?? false
);

export const paymentDetailsEnabledSelector = createSelector(
  appSectionsSelector,
  appSections => !!appSections?.paymentDetails ?? false
);

export const hideChatBubbleEnabledSelector = createSelector(
  currentBrandingSelector,
  appSections => !!appSections?.hideChatBubble ?? false
);

export const hideTeamMembersEnabledSelector = createSelector(
  currentBrandingSelector,
  appSections => !!appSections?.hideTeamMembers ?? false
);

export const tenantIdSelector = createSelector(
  defaultBrandingSelector,
  brand => brand.tenant
);

export const genericErrorSelector = createSelector(
  coreSelector,
  state => state.httpError
);

export const currentBrandingVCardSelector = createSelector(
  currentBrandingSelector,
  currentBranding => currentBranding?.vCard ?? null
);

export const gettingBrandingSelector = createSelector(
  coreSelector,
  state => state.gettingBranding
);

export const tenantRolesSelector = createSelector(
  coreSelector,
  state => state.tenantRoles
);

export const tenantRolesAsStringsSelector = createSelector(
  tenantRolesSelector,
  tenantRoles =>
    tenantRoles.reduce((acc, role) => [...acc, role.label, role.id], [])
);

export const tenantRoleSelectorByRoleLabel = (payload: { label: string }) =>
  createSelector(tenantRolesSelector, tenantRoles =>
    tenantRoles.find(tenantRole => tenantRole.label === payload.label)
  );

export const generalProgressBarSelector = createSelector(
  coreSelector,
  state => state.generalProgressBar
);
