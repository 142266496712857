import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ReactiveFormsModule,
  UntypedFormControl,
} from '@angular/forms';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { take } from 'rxjs/operators';
import { SdInputA11yTemplates } from '../../sd-input-a11y/models/sd-input-a11y-templates.enum';
import { SdAccessibilitySetting } from '../../../core/models/sd-accessibility-model';
import { AccessibilityHelper } from '../../../core/helpers/accessibility.helper';
import { SdColorPalette } from '../../../core/models/enums/sd-color-palette-enum';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SdIconButtonA11yComponent } from '../../sd-icon-button-a11y/components/sd-icon-button-a11y/sd-icon-button-a11y.component';
import { UtilsHelper } from 'src/app/core/helpers/utils.helper';
import { Locale } from 'src/app/dictionary/models/locale.model';
import { localeDefaultSelector } from 'src/app/dictionary/store/dictionary.selectors';
import { AppState } from '@auth0/auth0-angular';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-sd-text-area-a11y',
  templateUrl: './sd-text-area-a11y.component.html',
  styleUrls: ['./sd-text-area-a11y.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    SdIconButtonA11yComponent,
    MatFormFieldModule,
  ],
})
export class SdTextAreaA11yComponent implements AfterViewInit, OnInit {
  @Input() error: string;
  @Input() controller: UntypedFormControl | AbstractControl | any;
  @Input() placeholder: string;
  @Input() suffixText: string;
  @Input() suffixSvg: string;
  @Input() maxLength: number;
  @Input() suffixSvgColor: string;
  @Input() disableEnterKey = false;
  @Input() readonly = false;
  sdInputA11yTemplates = SdInputA11yTemplates;
  @Input() template = SdInputA11yTemplates.inputDefault;
  sdAccessibility: SdAccessibilitySetting;
  @Input() sdAccessibilityForSuffix: SdAccessibilitySetting;
  @Output() suffixClicked = new EventEmitter<boolean>();
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  sdColorPalette = SdColorPalette;
  localeDefault = this.store.selectSignal(localeDefaultSelector);
  constructor(
    private _ngZone: NgZone,
    private readonly store: Store<AppState>
  ) {}

  @Input() set setSdAccessibility(value: SdAccessibilitySetting) {
    this.sdAccessibility = AccessibilityHelper.setDefaultAccessibility(value);
  }

  ngOnInit(): void {
    if (typeof this.controller.value === 'object') {
      let valueFromLocale = this.controller?.value?.value;
      if (this.controller?.value?.languages) {
        valueFromLocale = UtilsHelper.getDisplayValueFromLocaleId(
          this.controller?.value?.languages,
          Locale.getLocaleId(this.localeDefault())
        );
      }
      this.controller.setValue(
        valueFromLocale ?? this.controller?.value?.value
      );
    }
  }

  ngAfterViewInit(): void {
    this.triggerResize();
  }

  triggerResize() {
    this._ngZone.onStable
      .pipe(take(1))
      .subscribe(() => this.autosize.resizeToFitContent(true));
  }

  onKeyPress(event: KeyboardEvent): void {
    if (!this.disableEnterKey) {
      return;
    }
    if (event.code === 'Enter') {
      event.preventDefault();
    }
  }
}
