<div *ngIf="vm$ | async as vm" class="record-form-reminders-tab">
  <app-sd-progress-bar-a11y [setIsLoading]="(vm$ | async).gettingInformation" />
  <div class="record-form-reminders-tab-list">
    <div class="section">
      <div class="left-column">
        <div
          [attr.aria-label]="
            ('recordformreminderstab_title' | dictionary | async) +
            ' ' +
            ('recordformreminderstab_remindertooltip' | dictionary | async)
          "
          class="reminders-list-header-title"
          role="heading" aria-level="3">
          {{ 'recordformreminderstab_title' | dictionary | async }}
        </div>
        <app-sd-tooltip-button-a11y
          [tooltip]="
            'recordformreminderstab_remindertooltip' | dictionary | async
          " />
      </div>
      <div class="right-column">
        <app-sd-icon-button-a11y
          (btnClicked)="onAddReminder()"
          [iconColor]="sdColorPalette.primaryColor"
          [iconHeight]="1.25"
          [iconWidth]="1.25"
          [icon]="cdn + ('globalparams_addicon' | dictionary | async)"
          [setSdAccessibility]="{
            ariaLabel:
              'recordformreminderstab_addreminderbuttontooltip'
              | dictionary
              | async,
            role: 'button'
          }"
          [squareBackground]="false"
          [tooltip]="
            'recordformreminderstab_addreminderbuttontooltip'
              | dictionary
              | async
          " />
      </div>
    </div>

    <app-reminders-list-a11y
      [dialogMode]="true"
      [emptyStateString]="'reminderslist_emptystate' | dictionary | async"
      [remindersListViewAll]="
        'recordsviewview_reminderslistviewall' | dictionary | async
      "
      [showHeader]="false"
      [viewAllReminderInline]="true"
      [viewOnly]="false">
    </app-reminders-list-a11y>

    <app-sd-flat-button-a11y
      (buttonClicked)="onAddReminder()"
      [primary]="true"
      [setSdAccessibility]="{
        ariaLabel:
          'recordformreminderstab_addreminderbutton' | dictionary | async
      }"
      class="add-reminder-button">
      {{ 'recordformreminderstab_addreminderbutton' | dictionary | async }}
    </app-sd-flat-button-a11y>
  </div>
</div>
