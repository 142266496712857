import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../../reducers';
import { RecordSubType } from '../../../../models/record-sub-type.model';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, take, tap } from 'rxjs/operators';
import { recordSubTypesSelector } from '../../../../store/records-list.selectors';
import { RecordsSubtypesLoaded } from '../../../../store/records-list.actions';
import { Dictionary } from '../../../../../dictionary/models/dictionary.model';
import { RecordSubtypeSpecificFieldsRequested } from '../../../../store/specific-fields.actions';
import { SdAccessibilitySetting } from 'src/app/core/models/sd-accessibility-model';
import { AccessibilityHelper } from 'src/app/core/helpers/accessibility.helper';
import { SdAutoCompleteA11yEnum } from 'src/app/shared/sd-autocomplete-a11y/model/sd-autocomplete-a11y.enum';
import { localeDefaultSelector } from 'src/app/dictionary/store/dictionary.selectors';
import { Locale } from 'src/app/dictionary/models/locale.model';
import { UtilsHelper } from 'src/app/core/helpers/utils.helper';

@Component({
  selector: 'app-record-subtype-autocomplete-a11y',
  templateUrl: './record-subtype-autocomplete-a11y.component.html',
  styleUrls: ['./record-subtype-autocomplete-a11y.component.scss'],
})
export class RecordSubtypeAutocompleteA11yComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() controller: UntypedFormControl;
  @Input() error: string;
  @Input() startValue: RecordSubType;
  @Input() recordSubTypeName: string; // check this...
  @Input() placeholder: string;
  @Input() openList = false;
  @Input() customTooltip: string;
  @Input() recordType: string;

  @ViewChild('inputElement') inputElement: ElementRef;
  sdAccessibility: SdAccessibilitySetting;
  @Output() subTypeSelected = new EventEmitter<RecordSubType>();
  subTypes$: Observable<RecordSubType[]>;
  subTypes: RecordSubType[] = [];
  @Input() typeSubtypesMap: Map<string, RecordsSubtypesLoaded>;
  dictionary$: Observable<Dictionary>;
  subscription = new Subscription();
  requestSpecificFields$ = new Subject<string>();
  sdAutoCompleteA11yEnum = SdAutoCompleteA11yEnum;
  localeId: string;

  constructor(private readonly store: Store<AppState>) {}

  @Input() set setSdAccessibility(value: SdAccessibilitySetting) {
    this.sdAccessibility = AccessibilityHelper.setDefaultAccessibility(value);
  }

  ngOnInit(): void {
    this.setLocaleDefault();
    this.setSubTypesFromInit();

    this.subscription.add(
      this.requestSpecificFields$
        .pipe(
          debounceTime(1000),
          tap(subTypeName => {
            this.requestSpecificFields(subTypeName);
          })
        )
        .subscribe()
    );
  }

  private setSubTypesFromInit(): void {
    if (this.typeSubtypesMap) {
      if (this.recordType) {
        this.subTypes = [];
        const recordSubTypeObject = this.typeSubtypesMap?.get(this.recordType);
        recordSubTypeObject?.payload?.data?.forEach(recordSubType => {
          if (recordSubType?.name !== 'other') {
            this.subTypes.push(recordSubType);
          }
        });
      }

      if (!this.recordType) {
        this.typeSubtypesMap.forEach(recordSubTypeObject => {
          recordSubTypeObject?.payload?.data?.forEach(recordSubType => {
            if (recordSubType?.name !== 'other') {
              this.subTypes.push(recordSubType);
            }
          });
        });
      }

      this.sortArrayRecordSubType();

      this.controller.setValue(
        !!this.controller.value && this.controller.value.length > 0
          ? this.controller.value
          : ''
      );
      // pre-populated from landing page or redirect to record home page.
      if (
        !this.controller.value &&
        this.recordSubTypeName &&
        this.subTypes.length > 0
      ) {
        const founded = this.subTypes.find(
          st =>
            st.name?.trim()?.toLocaleLowerCase() ===
            this.recordSubTypeName?.trim()?.toLocaleLowerCase()
        );
        this.controller.setValue(
          founded
            ? UtilsHelper.getDisplayValueFromLocaleId(
                founded?.displayValue,
                this.localeId
              )
            : this.recordSubTypeName
        );

        if (founded) {
          this.subTypeSelected.emit(founded);
        } else {
          this.subTypeSelected.emit(new RecordSubType('other'));
          this.requestSpecificFields$.next('other');
          return;
        }
      }

      if (this.openList) {
        this.showListOfSubtypes();
      }
    }

    if (!this.typeSubtypesMap) {
      this.subTypes$ = this.store.pipe(
        select(recordSubTypesSelector),
        tap(subTypes => {
          if (!!subTypes && subTypes.length > 0) {
            this.subTypes = [];
            subTypes.forEach(subType => {
              if (subType.name !== 'other') {
                this.subTypes.push(subType);
              }
            });
          }
          this.controller.setValue(
            !!this.controller.value && this.controller.value.length > 0
              ? this.controller.value
              : ''
          );

          if (
            !this.controller.value &&
            this.recordSubTypeName &&
            this.subTypes.length > 0
          ) {
            const founded = this.subTypes.find(
              st =>
                st.name?.trim()?.toLocaleLowerCase() ===
                this.recordSubTypeName?.trim()?.toLocaleLowerCase()
            );
            this.controller.setValue(
              founded
                ? UtilsHelper.getDisplayValueFromLocaleId(
                    founded?.displayValue,
                    this.localeId
                  )
                : this.recordSubTypeName
            );
            if (founded) {
              this.subTypeSelected.emit(founded);
            } else {
              this.subTypeSelected.emit(new RecordSubType('other'));
              this.requestSpecificFields$.next('other');
              return;
            }
          }
        })
      );
      this.subscription.add(this.subTypes$.subscribe());
    }
  }

  private setSubTypesFromFilter(): void {
    if (this.typeSubtypesMap) {
      if (this.recordType) {
        this.subTypes = [];
        const recordSubTypeObject = this.typeSubtypesMap?.get(this.recordType);
        recordSubTypeObject?.payload?.data?.forEach(recordSubType => {
          if (recordSubType?.name !== 'other') {
            this.subTypes.push(recordSubType);
          }
        });
      }

      if (!this.recordType) {
        this.typeSubtypesMap.forEach(recordSubTypeObject => {
          recordSubTypeObject?.payload?.data?.forEach(recordSubType => {
            if (recordSubType?.name !== 'other') {
              this.subTypes.push(recordSubType);
            }
          });
        });
      }

      this.sortArrayRecordSubType();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.startValue && !!changes.startValue.currentValue) {
      this.requestSpecificFields(this.startValue.name);
    }
    // NOTE for filter subtypes
    if (changes?.recordType?.currentValue) {
      this.setSubTypesFromFilter();
    }
  }

  filter(value: string): RecordSubType[] {
    if (!!value && value.length > 0) {
      const filterValue = value.toLowerCase();
      return this.subTypes.filter(option =>
        UtilsHelper.getDisplayValueFromLocaleId(
          option?.displayValue,
          this.localeId
        )
          .toLowerCase()
          .includes(filterValue)
      );
    } else {
      return this.subTypes;
    }
  }

  onSelectOption(option: RecordSubType): void {
    this.subTypeSelected.emit(option);
    this.requestSpecificFields$.next(option.name);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  requestSpecificFields(subtypeName: string): void {
    this.store.dispatch(
      new RecordSubtypeSpecificFieldsRequested({
        recordSubTypeName: subtypeName,
      })
    );
  }

  private sortArrayRecordSubType(): void {
    this.subTypes.sort((x: RecordSubType, y: RecordSubType): number => {
      if (x.name < y.name) {
        return -1;
      }
      if (x.name > y.name) {
        return 1;
      }
      return 0;
    });
  }

  private showListOfSubtypes(): void {
    setTimeout(() => {
      this.inputElement?.nativeElement?.focus();
      this.controller.setValue(' ');
      this.controller.setValue('');
    }, 200);
  }

  private setLocaleDefault(): void {
    this.store
      .pipe(
        select(localeDefaultSelector),
        take(1),
        tap(locale => {
          this.localeId = Locale.getLocaleId(locale);
        })
      )
      .subscribe();
  }
}
