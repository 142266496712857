export enum ZipStatus {
  confirmation = 'confirmation',
  information = 'information',
  downloading = 'downloading',
  zipping = 'zipping',
  complete = 'complete',
  canceled = 'canceled',
  completeWithErrors = 'completeWithErrors',
  fails = 'fails',
  noFiles = 'noFiles',
}
