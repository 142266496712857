import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  signal,
  WritableSignal,
} from '@angular/core';
import { NetworkV2 } from '../../models/network.v2.type';
import { NgForOf, NgIf } from '@angular/common';
import { ContributorType } from '../../models/contributor-type.model';
import { SdLinkMenuItem } from '../../../shared/sd-link-menu-a11y/models/sd-link-menu-item.model';
import { MenuItemType } from '../../../shared/sd-header-a11y/models/menu-item.type.enum';
import { SdLinkMenuLinkType } from '../../../shared/sd-link-menu-a11y/models/enums/sd-link-menu-link-type-enum';
import { NetworkEntity } from '../../models/network-entity.enum';
import { SdColorPalette } from '../../../core/models/enums/sd-color-palette-enum';
import { AccountTypeItemComponent } from '../../../shared/account-type-item/account-type-item.component';
import { AppState } from '../../../reducers';
import { Store } from '@ngrx/store';
import { dictionarySelector } from '../../../dictionary/store/dictionary.selectors';
import { environment } from '../../../../environments/environment';
import { hideTeamMembersEnabledSelector } from '../../../core/store/core.selectors';
import { TeamNetworkListItemMemberA11yComponent } from '../team-network-list-item-member-a11y/team-network-list-item-member-a11y.component';
import { GetRoleFromNetworkSignalPipe } from '../../../shared/sd-pipes/get-role-from-network-signal.pipe';
import { DictionarySignalPipe } from '../../../dictionary/pipes/dictionary-signal.pipe';
import { GetRelationshipFromNetworkSignalPipe } from '../../../shared/sd-pipes/get-relationship-from-network-signal.pipe';
import { NetworkListItemStore } from '../../../store/network-list-item.store';

@Component({
  selector: 'app-network-list-item',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    AccountTypeItemComponent,
    DictionarySignalPipe,
    GetRelationshipFromNetworkSignalPipe,
    GetRoleFromNetworkSignalPipe,
    TeamNetworkListItemMemberA11yComponent,
    NgForOf,
  ],
  providers: [NetworkListItemStore],
  styleUrls: ['./network-list-item.component.scss'],
  template: `
    <ng-container
      #teamTemplate
      *ngIf="
        _network()?.contributorType === ContributorType.team;
        else accountTemplate
      ">
      <app-account-type-item
        [clickable]="clickable"
        (emitterClicked)="eventsEnabled ? onEmitterClicked($event) : null"
        (descriptionActionClicked)="eventsEnabled ? onUpdateRelation() : null"
        (nameActionClicked)="_showTeamMembers.set(!_showTeamMembers())"
        (itemClicked)="onUpdate()"
        [ariaLabel]="
          _network()?.teamNetwork?.name +
          ('recordteamnetworklistitem_collaboratorslistteam'
            | dictionarySignal) +
          (_network()?.relation | getRelationshipFromNetworkSignal) +
          (_network() | getRoleFromNetworkSignal: _networkEntity())
        "
        [avatarSrc]="_network()?.teamNetwork?.logo"
        [description]="
          (_network()?.relation | getRelationshipFromNetworkSignal) +
          ' ' +
          ('recordteamnetworklistitem_collaboratorslistteam' | dictionarySignal)
        "
        [descriptionActionSrc]="
          cdn + ('globalparams_editicon' | dictionarySignal)
        "
        [descriptionActionColor]="SdColorPalette.primaryColor"
        [descriptionActionAlt]="
          'recordnetworklistitem_actionmenuoptionupdaterelation'
            | dictionarySignal
        "
        [menu]="{
          icon:
            cdn + ('globalparams_horizontalactionmenuicon' | dictionarySignal),
          iconColor: SdColorPalette.primaryAccentColor,
          squareBackground: true,
          border: false,
          disableRipple: true,
          ariaLabel:
            'recordteamnetworklistitem_actionmenutooltip' | dictionarySignal,
          tooltip:
            'recordteamnetworklistitem_actionmenutooltip' | dictionarySignal,
          buttonHeight: 1.5,
          buttonWidth: 2,
          menuData: _menuData()
        }"
        [name]="_network()?.teamNetwork?.name"
        [nameActionInline]="false"
        [nameActionSrc]="
          !hideTeamMembers()
            ? _showTeamMembers()
              ? cdn + ('globalparams_chevronupicon' | dictionarySignal)
              : cdn + ('globalparams_chevrondownicon' | dictionarySignal)
            : null
        "
        [spinner]="spinner()"
        [tag]="_network() | getRoleFromNetworkSignal: _networkEntity()"
        [templateStyle]="eventsEnabled ? template : 'none'" />
      <ng-container *ngIf="!hideTeamMembers() && _showTeamMembers()">
        <app-team-network-list-item-member-a11y
          *ngFor="let member of _network()?.members"
          [member]="member"
          [onRecordList]="true" />
      </ng-container>
    </ng-container>
    <ng-template #accountTemplate>
      <app-account-type-item
        [clickable]="clickable"
        (emitterClicked)="eventsEnabled ? onEmitterClicked($event) : null"
        (descriptionActionClicked)="eventsEnabled ? onUpdateRelation() : null"
        (itemClicked)="eventsEnabled ? onUpdate() : null"
        [ariaLabel]="
          (!!_network()?.contributor?.name &&
          _network()?.contributor?.name?.length > 0
            ? _network()?.contributor?.name
            : _network()?.contributor?.email) +
          (_network()?.contributorType === ContributorType.account
            ? ('recordnetworklistitem_collaboratorslistactive'
              | dictionarySignal)
            : ('recordnetworklistitem_collaboratorslistpending'
              | dictionarySignal)) +
          (_network()?.relation | getRelationshipFromNetworkSignal) +
          (!!_network()
            ? (_network() | getRoleFromNetworkSignal: _networkEntity())
            : '')
        "
        [avatarSrc]="_network()?.contributor?.profilePhoto"
        [description]="
          (_network()?.relation | getRelationshipFromNetworkSignal) +
          ' ' +
          (_network()?.contributor?.openId?.length > 0
            ? ('recordnetworklistitem_collaboratorslistactive'
              | dictionarySignal)
            : ('recordnetworklistitem_collaboratorslistpending'
              | dictionarySignal))
        "
        [descriptionActionSrc]="
          cdn + ('globalparams_editicon' | dictionarySignal)
        "
        [descriptionActionColor]="SdColorPalette.primaryColor"
        [descriptionActionAlt]="
          'recordnetworklistitem_actionmenuoptionupdaterelation'
            | dictionarySignal
        "
        [menu]="{
          icon:
            cdn + ('globalparams_horizontalactionmenuicon' | dictionarySignal),
          iconColor: SdColorPalette.primaryAccentColor,
          squareBackground: true,
          border: false,
          disableRipple: true,
          ariaLabel:
            'recordnetworklistitem_actionmenutooltip' | dictionarySignal,
          tooltip: 'recordnetworklistitem_actionmenutooltip' | dictionarySignal,
          buttonHeight: 1.5,
          buttonWidth: 2,
          menuData: _menuData()
        }"
        [name]="
          _network()?.contributor?.name?.length > 0
            ? _network()?.contributor?.name
            : _network()?.contributor?.email
        "
        [nameDescription]="
          _network()?.contributor?.name?.length > 0
            ? _network()?.contributor?.email
            : ''
        "
        [spinner]="spinner()"
        [tag]="
          !!_network()
            ? (_network() | getRoleFromNetworkSignal: _networkEntity())
            : ''
        "
        [templateStyle]="template" />
    </ng-template>
  `,
})
export class NetworkListItemComponent {
  private readonly store = inject(Store<AppState>);
  private readonly itemStore = inject(NetworkListItemStore);
  readonly cdn = environment.cdn;
  readonly ContributorType = ContributorType;
  readonly SdLinkMenuLinkType = SdLinkMenuLinkType;
  readonly MenuItemType = MenuItemType;
  readonly SdColorPalette = SdColorPalette;
  _network: WritableSignal<NetworkV2> = signal(null);
  _networkEntity: WritableSignal<NetworkEntity> = signal(null);
  hideTeamMembers = this.store.selectSignal(hideTeamMembersEnabledSelector);
  _showTeamMembers = signal(true);
  _menuData: WritableSignal<SdLinkMenuItem[]> = signal([
    {
      menuLinkText:
        this._network()?.contributorType === ContributorType.team
          ? this.store.selectSignal(dictionarySelector)()
              ?.recordteamnetworklistitem_actionmenuoptiondetails
          : this.store.selectSignal(dictionarySelector)()
              ?.recordnetworklistitem_actionmenuoptiondetails,
      visible: true,
      menuLinkType: SdLinkMenuLinkType.emitter,
      key: MenuItemType.edit,
    },
    {
      menuLinkText:
        this._network()?.contributorType === ContributorType.team
          ? this.store.selectSignal(dictionarySelector)()
              ?.recordteamnetworklistitem_actionmenuoptionupdaterelation
          : this.store.selectSignal(dictionarySelector)()
              ?.recordnetworklistitem_actionmenuoptionupdaterelation,
      visible: true,
      menuLinkType: SdLinkMenuLinkType.emitter,
      key: MenuItemType.relation,
    },
    {
      menuLinkText:
        this._network()?.contributorType === ContributorType.team
          ? this.store.selectSignal(dictionarySelector)()
              ?.recordteamnetworklistitem_actionmenuoptioncopy
          : this.store.selectSignal(dictionarySelector)()
              ?.recordnetworklistitem_actionmenuoptioncopy,
      visible: true,
      menuLinkType: SdLinkMenuLinkType.emitter,
      key: MenuItemType.copy,
    },
    {
      menuLinkText:
        this._network()?.contributorType === ContributorType.team
          ? this._networkEntity() === NetworkEntity.records
            ? this.store.selectSignal(dictionarySelector)()
                ?.recordteamnetworklistitem_actionmenuoptionremove
            : this._networkEntity() === NetworkEntity.sideDrawer
              ? this.store.selectSignal(dictionarySelector)()
                  ?.recordteamnetworklistitem_actionmenuoptionremove
              : this.store.selectSignal(dictionarySelector)()
                  ?.networklistitem_actionmenuoptionremoveallpermissions
          : this._networkEntity() === NetworkEntity.records
            ? this.store.selectSignal(dictionarySelector)()
                ?.recordnetworklistitem_actionmenuoptionremove
            : this._networkEntity() === NetworkEntity.sideDrawer
              ? this.store.selectSignal(dictionarySelector)()
                  ?.networklistitem_actionmenuoptionremove
              : this.store.selectSignal(dictionarySelector)()
                  ?.networklistitem_actionmenuoptionremoveallpermissions,
      visible: true,
      menuLinkType: SdLinkMenuLinkType.emitter,
      key: MenuItemType.delete,
    },
  ]);
  spinner = this.itemStore.spinner;

  @Input() set network(payload: {
    network: NetworkV2;
    networkEntity: NetworkEntity;
  }) {
    this._network.set(payload.network);
    this._networkEntity.set(payload.networkEntity);
  }

  @Input() eventsEnabled = true;
  @Input() clickable = true;
  @Input() template: 'menu' | 'action' | 'none' = 'menu';

  @Input() set handleMenuEvents(events: MenuItemType[]) {
    this._menuData.update(menuData =>
      Array.from(
        menuData.map(item => ({
          ...item,
          visible: events.some(event => event === item.key),
        }))
      )
    );
  }

  @Input() set customMenuData(menuData: SdLinkMenuItem[]) {
    this._menuData.set(menuData);
  }

  closeModalParent = false;
  @Input() set setCloseModalParent(value: boolean) {
    this.closeModalParent = value;
  }
  @Output() closeModalParentEmitter = new EventEmitter<boolean>();

  onUpdate(): void {
    if (this.template === 'none') {
      return;
    }

    this.itemStore.update({
      network: this._network(),
      networkEntity: this._networkEntity(),
    });

    if (this.closeModalParent) {
      this.closeModalParentEmitter.emit(true);
    }
  }

  onRemove(): void {
    this.itemStore.delete({
      network: this._network(),
      networkEntity: this._networkEntity(),
    });
  }

  onUpdateRelation(): void {
    this.itemStore.updateRelation({ network: this._network() });
  }

  onEmitterClicked(linkMenu: SdLinkMenuItem) {
    if (linkMenu.key === MenuItemType.relation) {
      this.onUpdateRelation();
    }
    if (linkMenu.key === MenuItemType.edit) {
      this.onUpdate();
    }
    if (linkMenu.key === MenuItemType.delete) {
      this.onRemove();
    }
    if (linkMenu.key === MenuItemType.copy) {
      this.itemStore.copyLink({ network: this._network() });
    }
  }
}
