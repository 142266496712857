import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { AccountService } from '../../account/services/account.service';
import { DictionaryService } from '../../dictionary/services/dictionary.service';
import {
  AccountCreated,
  CheckOwnedAndSharedSdCompleteStep,
  GetDictionaryWithBrowserLocale,
  GetSdHomeOriginSdIdFail,
  GetSettingsAndDictionary,
  LaunchStartFlow,
  SecurityStep,
  SetActiveSdAndRedirectToLanding,
  SetActiveSdAndRedirectToOrigin,
  SignUpSideDrawerCreated,
  SignUpSkipSideDrawerCreation,
  StartFlowActionsTypes,
  StartFlowCompleted,
  StartFlowConfigurationSetBrandsParams,
  StartFlowConfigurationSetFirstLogin,
  StartFlowConfigurationSetURLParams,
  StartFlowInvitationUsed,
  StartFlowStepUpdated,
} from './start-flow.actions';
import {catchError, map, mergeMap, switchMap, take, tap} from 'rxjs/operators';
import { ConfigService } from '../../core/services/config.service';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { StartFlowStepKeys } from '../models/start-flow-step-keys.model';
import { StartFlowStepStatus } from '../models/start-flow-step-status.model';
import { EMPTY, forkJoin, of } from 'rxjs';
import { RoutesHelper } from '../../core/helpers/routes.helper';
import {
  authUserSelector,
  isTenantUser,
} from '../../auth/store/auth.selectors';
import { AuthUser } from '../../auth/models/auth-user.model';
import { HttpErrorResponse } from '@angular/common/http';
import { CoreRoutes } from '../../core/routes/core.routes';
import {
  LanguagesLoaded,
  LocaleDefaultChange,
} from '../../dictionary/store/dictionary.actions';
import {
  BrandingConfigLoaded,
  CoreActionsTypes,
  DefaultBrandingLoaded,
  SecurityCompleted,
  TenantsRolesLoaded,
} from '../../core/store/core.actions';
import { BrandingConfig } from '../../core/models/branding-config.model';
import {
  AccountLoaded,
  AccountRequested,
  EnrollMFASilentlyRequested,
  SettingsLoaded,
} from '../../account/store/account.actions';
import {
  idpMfaByBrandCodesByBrandCodeSelector,
  languageSelector,
  localeDefaultSelector,
} from '../../dictionary/store/dictionary.selectors';
import { Language } from '../../dictionary/models/language.model';
import { DictionaryHelper } from '../../dictionary/helper/dictionary.helper';
import {
  AccountRoutesEnum,
  getAccountRoute,
} from '../../account/routes/account-routes.enum';
import {
  accountSelector,
  settingsSelector,
} from '../../account/store/account.selector';
import {
  startFlowBypassSdCreationSelector,
  startFlowExternalOriginSdIdSelector,
  startFlowInvitationCodeSelector,
  startFlowOriginSdIdSelector,
  startFlowOriginSelector,
} from './start-flow.selectors';
import { Injectable } from '@angular/core';
import { HomeService } from '../../home/services/home.service';
import { environment } from '../../../environments/environment';
import { SidedrawerService } from '../../sidedrawer/services/sidedrawer.service';
import { SetSideDrawerDefault } from '../../sidedrawer/store/sidedrawer.actions';
import { sideDrawerDefaultSelector } from '../../sidedrawer/store/sidedrawer.selector';
import { SidedrawerRoutesEnum } from '../../sidedrawer/routes/sidedrawer-routes.enum';
import { Locale } from '../../dictionary/models/locale.model';
import { HomeRoutes } from '../../home/routes/home.routes';
import { LandingRoutes } from '../../landing/routes/landing.routes';
import { SideDrawerNetworksHelper } from '../../sidedrawer-networks/helpers/side-drawer-networks.helper';
import { SideDrawerNetwork } from '../../sidedrawer-networks/models/side-drawer-network.model';
import { sideDrawerNetworkListSelector } from '../../sidedrawer-networks/store/side-drawer-network-list.selectors';
import { LicensesRoutes } from '../../licenses/routes/licenses.routes';
import { SettingsRoutesEnum } from '../../settings/routes/settings-routes.enum';
import {
  StartInactivityTime,
  StartRefreshTokenTime,
} from '../../auth/store/auth.actions';
import { SideDrawerNetworksStartFlowRequested } from '../../sidedrawer-networks/store/side-drawer-networks-requests.actions';
import { MyAccountRoutes } from '../../my-account-a11y/routes/my-account.routes';
import { ReEnrollMfaDialogComponent } from '../shared/re-enroll-mfa-dialog/re-enroll-mfa-dialog.component';
import { IdpMfa } from '../../account/models/idp-mfa.model';
import { MatDialog } from '@angular/material/dialog';
import { defaultBrandingSelector } from '../../core/store/core.selectors';
import { MfaMode } from '../../account/models/mfa-mode.model';

@Injectable()
export class StartFlowEffects {
  launchStartFlow$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<LaunchStartFlow>(StartFlowActionsTypes.LaunchStartFlow),
        tap(() => {
          const { invitation, origin } = RoutesHelper.getParams();
          const subDomain = RoutesHelper.getSubDomain();
          const errors = new Map<string, HttpErrorResponse>();
          this.store.dispatch(
            new StartFlowConfigurationSetURLParams({
              customBrand: !!subDomain,
              invitationCode: invitation ? invitation : null,
              origin,
            })
          );
          this.store.dispatch(
            new StartFlowStepUpdated({
              key: StartFlowStepKeys.getBrandingStep,
              step: { status: StartFlowStepStatus.processing },
            })
          );
          this.store.dispatch(
            new StartFlowStepUpdated({
              key: StartFlowStepKeys.getLanguagesStep,
              step: { status: StartFlowStepStatus.processing },
            })
          );
          this.store.dispatch(
            new StartFlowStepUpdated({
              key: StartFlowStepKeys.checkAccountStep,
              step: { status: StartFlowStepStatus.processing },
            })
          );
          const languages$ = this.dictionaryService.getLanguages().pipe(
            catchError(error => {
              errors.set('languages', error);
              return of(null);
            })
          );
          const brand$ = this.configService
            .getTenantConfiguration(subDomain)
            .pipe(
              catchError(error => {
                errors.set('brand', error);
                return of(null);
              })
            );
          const account$ = this.store.pipe(
            select(authUserSelector),
            take(1),
            mergeMap((user: AuthUser) =>
              this.accountService.getAccountByOpenId(user.sub).pipe(
                catchError(error => {
                  errors.set('account', error);
                  return of(null);
                })
              )
            )
          );
          const roles$ = this.configService.getTenantCustomRoles().pipe(
            catchError(() => {
              return of([]);
            })
          );
          forkJoin([languages$, account$, brand$, roles$])
            .pipe(
              tap(([languages, account, brand, tenantRoles]) => {
                this.store.dispatch(
                  new StartFlowStepUpdated({
                    key: StartFlowStepKeys.getBrandingStep,
                    step: { status: StartFlowStepStatus.complete },
                  })
                );
                this.store.dispatch(
                  new StartFlowStepUpdated({
                    key: StartFlowStepKeys.getLanguagesStep,
                    step: { status: StartFlowStepStatus.complete },
                  })
                );
                this.store.dispatch(
                  new StartFlowStepUpdated({
                    key: StartFlowStepKeys.checkAccountStep,
                    step: { status: StartFlowStepStatus.complete },
                  })
                );
                if (tenantRoles.length > 0) {
                  this.store.dispatch(new TenantsRolesLoaded({ tenantRoles }));
                }
                if (!languages) {
                  const queryParams = RoutesHelper.getParams(false);
                  this.router.navigate(
                    [`${CoreRoutes.root}/${CoreRoutes.error}/true`],
                    { queryParams }
                  );
                  return;
                }
                this.store.dispatch(new LanguagesLoaded({ languages }));
                if (!brand) {
                  this.store.dispatch(
                    new GetDictionaryWithBrowserLocale({
                      redirectToCoreError: true,
                    })
                  );
                  return;
                }
                const tenantConfig: BrandingConfig = brand;
                tenantConfig.isDefault = brand['default'];
                ConfigService.setTenantStyle(tenantConfig);
                this.store.dispatch(
                  new DefaultBrandingLoaded({ data: tenantConfig })
                );
                this.store.dispatch(
                  new BrandingConfigLoaded({ data: tenantConfig })
                );
                this.store.dispatch(
                  new StartFlowConfigurationSetBrandsParams({
                    forceSdCreation: tenantConfig.forceSDCreation,
                    bypassSdCreation: tenantConfig.bypassSDCreation,
                  })
                );
                if (!account && errors.get('account').status !== 404) {
                  this.store.dispatch(
                    new GetDictionaryWithBrowserLocale({
                      redirectToCoreError: true,
                    })
                  );
                  return;
                }
                if (!account && errors.get('account').status === 404) {
                  this.store.dispatch(
                    new GetDictionaryWithBrowserLocale({
                      redirectToCoreError: false,
                    })
                  );
                  return;
                }
                this.store.dispatch(new AccountLoaded({ data: account }));
                this.store.dispatch(new GetSettingsAndDictionary());
              })
            )
            .subscribe();
        })
      ),
    { dispatch: false }
  );

  getDictionaryWithBrowserLocale$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<GetDictionaryWithBrowserLocale>(
          StartFlowActionsTypes.GetDictionaryWithBrowserLocale
        ),
        tap(() => {
          this.store.dispatch(
            new StartFlowStepUpdated({
              key: StartFlowStepKeys.getDictionaryWithBrowserLocaleStep,
              step: { status: StartFlowStepStatus.processing },
            })
          );
        }),
        mergeMap(action =>
          this.store.pipe(
            select(languageSelector),
            take(1),
            mergeMap(languages => {
              const locale = Language.getBrowserLocale(languages);
              this.store.dispatch(new LocaleDefaultChange({ data: locale }));
              const queryParams = RoutesHelper.getParams(false);
              return this.dictionaryService
                .getDictionary(Locale.getLocaleId(locale))
                .pipe(
                  tap(() => {
                    this.store.dispatch(
                      new StartFlowStepUpdated({
                        key: StartFlowStepKeys.getDictionaryWithBrowserLocaleStep,
                        step: { status: StartFlowStepStatus.complete },
                      })
                    );
                  }),
                  tap({
                    next: response => {
                      DictionaryHelper.loadDictionaryStore(
                        response,
                        this.store
                      );
                      const { redirectToCoreError } = action.payload;
                      if (redirectToCoreError) {
                        this.router.navigate(
                          [`${CoreRoutes.root}/${CoreRoutes.error}`],
                          { queryParams }
                        );
                        return;
                      }
                      this.store.dispatch(
                        new StartFlowConfigurationSetFirstLogin({
                          firstLogIn: true,
                        })
                      );
                      this.router.navigate(
                        [getAccountRoute(AccountRoutesEnum.create)],
                        { queryParams }
                      );
                    },
                    error: () => {
                      this.router.navigate(
                        [`/${CoreRoutes.root}/${CoreRoutes.error}/true`],
                        { queryParams }
                      );
                      return;
                    },
                  })
                );
            })
          )
        )
      ),
    { dispatch: false }
  );

  accountCreated$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<AccountCreated>(StartFlowActionsTypes.AccountCreated),
        tap(action => {
          this.store.dispatch(
            new AccountRequested({ openId: action.payload.account.openId })
          );
          const queryParams = RoutesHelper.getParams(false);
          this.router.navigate([this.homeService.getHomeRoute()], {
            queryParams,
          });
          this.store.dispatch(new GetSettingsAndDictionary());
        })
      ),
    { dispatch: false }
  );

  getSettingsAndDictionary$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<GetSettingsAndDictionary>(
          StartFlowActionsTypes.GetSettingsAndDictionary
        ),
        tap(() => {
          this.store.dispatch(
            new StartFlowStepUpdated({
              key: StartFlowStepKeys.checkAccountSettingsStep,
              step: { status: StartFlowStepStatus.processing },
            })
          );
          this.store.dispatch(
            new StartFlowStepUpdated({
              key: StartFlowStepKeys.getDictionaryStep,
              step: { status: StartFlowStepStatus.processing },
            })
          );
          this.store.dispatch(new StartRefreshTokenTime());
          this.store.dispatch(new StartInactivityTime());
        }),
        mergeMap(() =>
          forkJoin([
            this.store.pipe(select(accountSelector), take(1)),
            this.store.pipe(select(languageSelector), take(1)),
            this.store.pipe(select(localeDefaultSelector), take(1)),
          ]).pipe(
            mergeMap(([account, languages, localeDefault]) => {
              return this.accountService.getAccountSettings(account.id).pipe(
                tap(() => {
                  this.store.dispatch(
                    new StartFlowStepUpdated({
                      key: StartFlowStepKeys.checkAccountSettingsStep,
                      step: { status: StartFlowStepStatus.complete },
                    })
                  );
                }),
                catchError(() => of(null)),
                mergeMap(settings => {
                  if (!settings) {
                    this.store.dispatch(
                      new GetDictionaryWithBrowserLocale({
                        redirectToCoreError: true,
                      })
                    );
                    return;
                  }
                  this.store.dispatch(new SettingsLoaded({ data: settings }));
                  const locale = Language.getUserDefaultLocale(
                    languages,
                    settings
                  );
                  if (localeDefault?.localeId === locale.localeId) {
                    this.store.dispatch(
                      new StartFlowStepUpdated({
                        key: StartFlowStepKeys.getDictionaryStep,
                        step: { status: StartFlowStepStatus.complete },
                      })
                    );
                    this.store.dispatch(new SecurityStep());
                    return of([]);
                  }
                  this.store.dispatch(
                    new LocaleDefaultChange({ data: locale })
                  );
                  return this.dictionaryService
                    .getDictionary(Locale.getLocaleId(locale))
                    .pipe(
                      tap(() => {
                        this.store.dispatch(
                          new StartFlowStepUpdated({
                            key: StartFlowStepKeys.getDictionaryStep,
                            step: { status: StartFlowStepStatus.complete },
                          })
                        );
                      }),
                      tap({
                        next: response => {
                          DictionaryHelper.loadDictionaryStore(
                            response,
                            this.store
                          );
                          this.store.dispatch(new SecurityStep());
                        },
                        error: () => {
                          const queryParams = RoutesHelper.getParams(false);
                          this.router.navigate(
                            [`/${CoreRoutes.root}/${CoreRoutes.error}/true`],
                            { queryParams }
                          );
                          return;
                        },
                      })
                    );
                })
              );
            })
          )
        )
      ),
    { dispatch: false }
  );

  securityStep$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SecurityStep>(StartFlowActionsTypes.SecurityStep),
        tap(() => {
          this.store.dispatch(
            new StartFlowStepUpdated({
              key: StartFlowStepKeys.securityStep,
              step: { status: StartFlowStepStatus.processing },
            })
          );
        }),
        switchMap(() =>
          this.store.select(defaultBrandingSelector).pipe(take(1))
        ),
        mergeMap((brand: BrandingConfig) =>
          forkJoin([
            this.store.pipe(select(accountSelector), take(1)),
            this.store.pipe(select(authUserSelector), take(1)),
            this.store
              .select(
                idpMfaByBrandCodesByBrandCodeSelector({
                  brandCode: brand.brandCode,
                })
              )
              .pipe(take(1)),
            this.store.select(settingsSelector).pipe(take(1)),
          ]).pipe(
            tap(([account, authUser, idpMfa, settings]) => {
              if (
                !account.openId.includes('auth0') ||
                authUser?.idpMfa === IdpMfa.sometimes ||
                authUser?.idpMfa === IdpMfa.never ||
                authUser?.idpMfa === IdpMfa.always
              ) {
                this.store.dispatch(new SecurityCompleted());
                return;
              }
              if (
                idpMfa === IdpMfa.never &&
                settings.mfaMode === MfaMode.never
              ) {
                this.store.dispatch(new EnrollMFASilentlyRequested());
                this.store.dispatch(new SecurityCompleted());
                return;
              }
              this.dialog
                .open(ReEnrollMfaDialogComponent, {
                  autoFocus: false,
                  disableClose: true,
                })
                .afterClosed()
                .subscribe();
            })
          )
        )
      ),
    { dispatch: false }
  );

  securityComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SecurityCompleted>(CoreActionsTypes.SecurityCompleted),
        tap(() => {
          this.store.dispatch(
            new StartFlowStepUpdated({
              key: StartFlowStepKeys.securityStep,
              step: { status: StartFlowStepStatus.complete },
            })
          );
        }),
        tap(() => {
          this.store.dispatch(
            new StartFlowStepUpdated({
              key: StartFlowStepKeys.sendInvitationStep,
              step: { status: StartFlowStepStatus.processing },
            })
          );
        }),
        mergeMap(() =>
          forkJoin([
            this.store.pipe(select(startFlowInvitationCodeSelector), take(1)),
            this.store.pipe(select(accountSelector), take(1)),
          ]).pipe(
            mergeMap(([invitationCode, account]) => {
              if (!invitationCode) {
                this.store.dispatch(
                  new StartFlowStepUpdated({
                    key: StartFlowStepKeys.sendInvitationStep,
                    step: { status: StartFlowStepStatus.complete },
                  })
                );
                this.store.dispatch(new SideDrawerNetworksStartFlowRequested());
                return of(null);
              }
              return this.accountService
                .sendInvitationCode(account, invitationCode)
                .pipe(
                  catchError(() => of(null)),
                  map(() => {
                      this.store.dispatch(new StartFlowInvitationUsed());
                      this.store.dispatch(
                        new StartFlowStepUpdated({
                          key: StartFlowStepKeys.sendInvitationStep,
                          step: {
                            status: StartFlowStepStatus.complete,
                          },
                        })
                      );
                      this.store.dispatch(
                        new SideDrawerNetworksStartFlowRequested()
                      );
                  }),
                );
            })
          )
        )
      ),
    { dispatch: false }
  );

  checkOwnedAndSharedSdCompleteStep$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<CheckOwnedAndSharedSdCompleteStep>(
          StartFlowActionsTypes.CheckOwnedAndSharedSdCompleteStep
        ),
        mergeMap(action =>
          forkJoin([
            this.store.pipe(select(sideDrawerNetworkListSelector), take(1)),
            this.store.pipe(select(startFlowBypassSdCreationSelector), take(1)),
            this.store.pipe(select(accountSelector), take(1)),
            this.store.pipe(select(startFlowOriginSelector), take(1)),
            this.store.pipe(
              select(startFlowExternalOriginSdIdSelector),
              take(1)
            ),
          ]).pipe(
            tap(([sds, bypassSDCreation, account, origin, externalSdId]) => {
              this.store.dispatch(
                new SetSideDrawerDefault({
                  data: SideDrawerNetworksHelper.getDefaultSideDrawer(
                    sds as SideDrawerNetwork[],
                    account
                  ),
                })
              );
              this.store.dispatch(
                new StartFlowStepUpdated({
                  key: StartFlowStepKeys.checkOwnedAndSharedSdStep,
                  step: { status: StartFlowStepStatus.complete },
                })
              );
              if (action.payload.error) {
                const queryParams = RoutesHelper.getParams(false);
                this.router.navigate(
                  [`/${CoreRoutes.root}/${CoreRoutes.error}`],
                  { queryParams }
                );
                return;
              }
              if (
                (!sds || (sds as SideDrawerNetwork[])?.length === 0) &&
                bypassSDCreation
              ) {
                this.store.dispatch(new SignUpSkipSideDrawerCreation());
                return;
              }
              if (
                (!sds || (sds as SideDrawerNetwork[])?.length === 0) &&
                origin?.includes(LicensesRoutes.createSideDrawer) &&
                origin?.includes(SettingsRoutesEnum.licenses)
              ) {
                this.store.dispatch(new SignUpSkipSideDrawerCreation());
                return;
              }
              if (
                (!sds || (sds as SideDrawerNetwork[])?.length === 0) &&
                !bypassSDCreation
              ) {
                this.router.navigate([
                  `/${CoreRoutes.root}/${SidedrawerRoutesEnum.root}/${SidedrawerRoutesEnum.create}`,
                ]);
                return;
              }
              if (origin?.includes(MyAccountRoutes.root)) {
                this.router.navigate([`${origin}`]);
                return;
              }

              if (!!externalSdId && sds?.some(sd => sd.id === externalSdId)) {
                console.log('externalSdId founded: ', externalSdId);
                //TODO set active sd and redirect to origin
                this.store.dispatch(new SetActiveSdAndRedirectToOrigin());
                return;
              }
              // TODO check this...
              this.store.dispatch(new SetActiveSdAndRedirectToLanding());
            })
          )
        )
      ),
    { dispatch: false }
  );

  signUpSideDrawerCreated$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SignUpSideDrawerCreated>(
          StartFlowActionsTypes.SignUpSideDrawerCreated
        ),
        tap(() => {
          this.store.dispatch(
            new StartFlowStepUpdated({
              key: StartFlowStepKeys.signUpCreateSdStep,
              step: { status: StartFlowStepStatus.processing },
            })
          );
        }),
        mergeMap(action =>
          this.store.pipe(select(localeDefaultSelector), take(1)).pipe(
            mergeMap(locale =>
              this.sidedrawerService
                .getSideDrawerHome(
                  action.payload.sidedrawerId,
                  Locale.getLocaleId(locale)
                )
                .pipe(
                  tap(newAction => {
                    this.store.dispatch(newAction);
                    this.store.dispatch(
                      new StartFlowStepUpdated({
                        key: StartFlowStepKeys.signUpCreateSdStep,
                        step: { status: StartFlowStepStatus.complete },
                      })
                    );
                    this.store.dispatch(
                      new SideDrawerNetworksStartFlowRequested()
                    );
                  })
                )
            )
          )
        )
      ),
    { dispatch: false }
  );

  signUpSkipSideDrawerCreation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SignUpSkipSideDrawerCreation>(
          StartFlowActionsTypes.SignUpSkipSideDrawerCreation
        ),
        tap(() => {
          this.store.dispatch(
            new StartFlowStepUpdated({
              key: StartFlowStepKeys.signUpCreateSdStep,
              step: { status: StartFlowStepStatus.complete },
            })
          );
          this.store.dispatch(new SetActiveSdAndRedirectToLanding());
        })
      ),
    { dispatch: false }
  );

  setActiveSdAndRedirectToLanding$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SetActiveSdAndRedirectToLanding>(
          StartFlowActionsTypes.SetActiveSdAndRedirectToLanding
        ),
        tap(() => {
          this.store.dispatch(
            new StartFlowStepUpdated({
              key: StartFlowStepKeys.setActiveSdAndRedirectToLandingStep,
              step: { status: StartFlowStepStatus.processing },
            })
          );
        }),
        mergeMap(() =>
          forkJoin([
            this.store.pipe(select(localeDefaultSelector), take(1)),
            this.store.pipe(select(startFlowOriginSdIdSelector), take(1)),
            this.store.pipe(select(startFlowOriginSelector), take(1)),
            this.store.pipe(select(isTenantUser), take(1)),
            this.store.pipe(select(sideDrawerDefaultSelector), take(1)),
          ]).pipe(
            mergeMap(
              ([locale, originSdId, origin, isTenantUser, sdDefault]) => {
                this.store.dispatch(
                  new StartFlowStepUpdated({
                    key: StartFlowStepKeys.setActiveSdAndRedirectToLandingStep,
                    step: { status: StartFlowStepStatus.complete },
                  })
                );
                if (!sdDefault && !originSdId && isTenantUser) {
                  location.href = `${environment.consoleUrl}`;
                  return of(null);
                }
                if (
                  !sdDefault &&
                  !originSdId &&
                  origin?.includes(LicensesRoutes.createSideDrawer) &&
                  origin?.includes(SettingsRoutesEnum.licenses)
                ) {
                  this.router.navigate([
                    `${CoreRoutes.root}/${HomeRoutes.root}/${SettingsRoutesEnum.root}/${SettingsRoutesEnum.licenses}/${LicensesRoutes.createSideDrawer}`,
                  ]);
                  this.store.dispatch(new StartFlowCompleted());
                  return of(null);
                }
                if (!sdDefault && !originSdId) {
                  this.router.navigate([
                    `${CoreRoutes.root}/${HomeRoutes.root}`,
                  ]);
                  this.store.dispatch(new StartFlowCompleted());
                  return of(null);
                }
                const sdId = originSdId ? originSdId : sdDefault.id;
                return this.sidedrawerService
                  .getSideDrawerHome(sdId, Locale.getLocaleId(locale))
                  .pipe(
                    catchError(() => of(null)),
                    tap(newAction => {
                      if (!newAction) {
                        if (originSdId) {
                          this.store.dispatch(new GetSdHomeOriginSdIdFail());
                          this.store.dispatch(
                            new SetActiveSdAndRedirectToLanding()
                          );
                          return of(null);
                        }
                        this.store.dispatch(
                          new SetSideDrawerDefault({ data: null })
                        );
                        this.store.dispatch(
                          new SetActiveSdAndRedirectToLanding()
                        );
                        return of(null);
                      }
                      this.store.dispatch(newAction);
                      this.store.dispatch(new StartFlowCompleted());
                      if (
                        origin?.includes(LicensesRoutes.createSideDrawer) &&
                        origin?.includes(SettingsRoutesEnum.root) &&
                        origin?.includes(SettingsRoutesEnum.licenses)
                      ) {
                        this.router.navigate([
                          `${CoreRoutes.root}/${HomeRoutes.root}/${
                            originSdId ?? sdDefault?.id
                          }/${SettingsRoutesEnum.root}/${
                            SettingsRoutesEnum.licenses
                          }/${LicensesRoutes.createSideDrawer}`,
                        ]);
                        return;
                      }

                      if (origin?.includes(CoreRoutes.sidedrawer)) {
                        this.router.navigate([`${origin}`]);
                        return;
                      }

                      if (originSdId) {
                        this.router.navigateByUrl(origin);
                        return;
                      }

                      this.router.navigate([
                        `/${CoreRoutes.root}/${HomeRoutes.root}/${sdDefault.id}/${LandingRoutes.root}`,
                      ]);
                    })
                  );
              }
            )
          )
        )
      ),
    { dispatch: false }
  );

  setActiveSdAndRedirectToOrigin$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SetActiveSdAndRedirectToOrigin>(
          StartFlowActionsTypes.SetActiveSdAndRedirectToOrigin
        ),
        tap(() => {
          this.store.dispatch(
            new StartFlowStepUpdated({
              key: StartFlowStepKeys.SetActiveSdAndRedirectToOriginStep,
              step: { status: StartFlowStepStatus.processing },
            })
          );
        }),
        mergeMap(() =>
          forkJoin([
            this.store.pipe(select(localeDefaultSelector), take(1)),
            this.store.pipe(select(startFlowOriginSelector), take(1)),
            this.store.pipe(select(isTenantUser), take(1)),
            this.store.pipe(select(sideDrawerDefaultSelector), take(1)),
            this.store.pipe(
              select(startFlowExternalOriginSdIdSelector),
              take(1)
            ),
          ]).pipe(
            mergeMap(
              ([locale, origin, isTenantUser, sdDefault, externalSdId]) => {
                this.store.dispatch(
                  new StartFlowStepUpdated({
                    key: StartFlowStepKeys.SetActiveSdAndRedirectToOriginStep,
                    step: { status: StartFlowStepStatus.complete },
                  })
                );
                if (!sdDefault && !externalSdId && isTenantUser) {
                  location.href = `${environment.consoleUrl}`;
                  return of(null);
                }

                const sdId = externalSdId ? externalSdId : sdDefault.id;
                return this.sidedrawerService
                  .getSideDrawerHome(sdId, Locale.getLocaleId(locale))
                  .pipe(
                    catchError(() => of(null)),
                    tap(newAction => {
                      if (!newAction) {
                        if (externalSdId) {
                          this.store.dispatch(new GetSdHomeOriginSdIdFail());
                          this.store.dispatch(
                            new SetActiveSdAndRedirectToOrigin()
                          );
                          return of(null);
                        }
                        this.store.dispatch(
                          new SetSideDrawerDefault({ data: null })
                        );
                        this.store.dispatch(
                          new SetActiveSdAndRedirectToOrigin()
                        );
                        return of(null);
                      }
                      this.store.dispatch(newAction);
                      this.store.dispatch(new StartFlowCompleted());

                      if (origin?.includes(CoreRoutes.sidedrawer)) {
                        this.router.navigate([origin]);
                        return;
                      }
                      this.router.navigate([
                        `/${CoreRoutes.root}/${HomeRoutes.root}/${sdDefault.id}/${LandingRoutes.root}`,
                      ]);
                    })
                  );
              }
            )
          )
        )
      ),
    { dispatch: false }
  );

  constructor(
    protected readonly actions$: Actions,
    protected readonly store: Store<AppState>,
    protected readonly router: Router,
    protected readonly homeService: HomeService,
    protected readonly accountService: AccountService,
    protected readonly dictionaryService: DictionaryService,
    protected readonly configService: ConfigService,
    protected readonly sidedrawerService: SidedrawerService,
    protected readonly dialog: MatDialog
  ) {}
}
