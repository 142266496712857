import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisplayTypePipe } from './display-type.pipe';

@NgModule({
  declarations: [DisplayTypePipe],
  imports: [CommonModule],
  exports: [DisplayTypePipe],
})
export class DisplayTypePipeModule {}
