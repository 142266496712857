<app-sd-dialog-template-a11y
  (closeButtonClicked)="onClose()"
  [type]="dialogTemplateTypes.Info">
  <ng-container dialog-header>
    <h2>
      {{
        ('deleterecorddialog_title' | dictionary | async) +
          ' ' +
          (!!data?.recordName ? data?.recordName : '')
      }}
    </h2>
  </ng-container>
  <ng-container dialog-body>
    <div
      [innerHTML]="'deleterecorddialog_body' | dictionary | async"
      class="base-dialog-content"></div>
  </ng-container>
  <ng-container dialog-footer>
    <app-sd-flat-button-a11y
      (buttonClicked)="onClose()"
      [primary]="false"
      class="secondary">
      {{ 'deleterecorddialog_secondarybutton' | dictionary | async }}
    </app-sd-flat-button-a11y>
    <app-sd-flat-button-a11y
      (buttonClicked)="onSubmit()"
      [primary]="true"
      class="primary">
      {{ 'deleterecorddialog_primarybutton' | dictionary | async }}
    </app-sd-flat-button-a11y>
  </ng-container>
</app-sd-dialog-template-a11y>
