import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NetworkService } from '../services/network.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  GettingMyTeams,
  MyTeamsListActionsTypes,
  MyTeamsLoaded,
  MyTeamsRequested,
} from './my-teams/my-teams-list.actions';

@Injectable()
export class NetworksEffects {
  myTeamsRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType<MyTeamsRequested>(MyTeamsListActionsTypes.MyTeamsRequested),
      tap(() =>
        this.store.dispatch(new GettingMyTeams({ gettingMyTeams: true }))
      ),
      mergeMap(() =>
        this.networkService.getUserTeams().pipe(
          catchError(() => of([])),
          map(teams => new MyTeamsLoaded({ teams })),
          tap(() =>
            this.store.dispatch(new GettingMyTeams({ gettingMyTeams: false }))
          )
        )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly networkService: NetworkService,
    private readonly store: Store<AppState>
  ) {}
}
