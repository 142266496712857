import { Actions, createEffect, ofType } from '@ngrx/effects';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import {
  tenantPlanListNotSelectedSelector,
  TenantPlansActions,
  tenantPlansNextPageSelector,
} from './tenant-plans.store';
import { forkJoin, of, switchMap } from 'rxjs';
import { activeSideDrawerSelector } from '../../sidedrawer/store/sidedrawer.selector';
import {catchError, map, mergeMap, take, tap} from 'rxjs/operators';
import {
  SetActiveSideDrawer,
  SidedrawerActionsTypes,
} from '../../sidedrawer/store/sidedrawer.actions';
import { PaginatorService } from '../../core/services/paginator.service';
import { Plan } from '../../plans/models/plan.model';
import { TenantPlansHelper } from '../helpers/tenant-plans.helper';
import { SetGeneralProgressBar } from 'src/app/core/store/core.actions';

export const tenantPlansRequested = createEffect(
  () => {
    const actions$ = inject(Actions);
    const store = inject(Store<AppState>);
    const service = inject(PaginatorService);
    return actions$.pipe(
      ofType(TenantPlansActions.requested),
      tap(() => store.dispatch(new SetGeneralProgressBar({ show: true }))),
      switchMap(() =>
        forkJoin([
          store.select(activeSideDrawerSelector).pipe(take(1)),
          store.select(tenantPlansNextPageSelector).pipe(take(1)),
        ])
      ),
      mergeMap(([activeSideDrawer, nextPage]) =>
        service
          .getPaginatedResource<Plan>(
            TenantPlansHelper.getPlansResourceUrl(activeSideDrawer.id, {
              nextPage: nextPage ?? null,
            })
          )
          .pipe(
            map(response => TenantPlansActions.loaded({ response })),
            catchError(httpError =>
              of(TenantPlansActions.error({ httpError }))
            ),
            tap(() =>
              store.dispatch(new SetGeneralProgressBar({ show: false }))
            )
          )
      )
    );
  },
  {
    functional: true,
  }
);
// TODO FEDE, PLEASE CHECK THIS
export const tenantLoaded = createEffect(
  () => {
    const actions$ = inject(Actions);
    const store = inject(Store<AppState>);
    return actions$.pipe(
      ofType(TenantPlansActions.loaded),
      switchMap(({ response }) =>
        forkJoin([
          of(response).pipe(take(1)),
          store.select(tenantPlanListNotSelectedSelector).pipe(take(1)),
        ])
      ),
      map(([{ hasMore }, plansNotSelected]) => {
        if (hasMore && plansNotSelected.length === 0) {
          store.dispatch(TenantPlansActions.requested());
        }
      })
    );
  },
  { functional: true, dispatch: false }
);

export const activeSideDrawerChange$ = createEffect(
  () => {
    const actions$ = inject(Actions);
    return actions$.pipe(
      ofType<SetActiveSideDrawer>(SidedrawerActionsTypes.SetActiveSideDrawer),
      map(() => TenantPlansActions.clear())
    );
  },
  {
    functional: true,
  }
);
