import { Action } from '@ngrx/store';
import { SideDrawerNetwork } from '../models/side-drawer-network.model';
import { SideDrawerNetworkRequestType } from '../models/side-drawer-network-request-type.enum';
import { Update } from '@ngrx/entity';

export enum SideDrawerNetworkListActionsTypes {
  SideDrawerNetworkRequested = '[Start Flow / Right Menu] SideDrawer Network Requested',
  SideDrawerNetworkLoaded = '[SideDrawerNetwork Effect] SideDrawer Network Loaded',
  SideDrawerNetworkUpdated = '[SideDrawerNetwork Effect] SideDrawer Network Updated',
  SideDrawerNetworkDeleted = '[SideDrawerNetwork Effect] SideDrawer Network Deleted',
  SideDrawerNetworkClearInformation = '[Right Menu] SideDrawer Network Clear Information',
}

export class SideDrawerNetworkRequested implements Action {
  readonly type = SideDrawerNetworkListActionsTypes.SideDrawerNetworkRequested;

  constructor(
    public payload: {
      requestType: SideDrawerNetworkRequestType;
      name?: string;
      dataBaseRegion?: string;
    }
  ) {}
}

export class SideDrawerNetworkLoaded implements Action {
  readonly type = SideDrawerNetworkListActionsTypes.SideDrawerNetworkLoaded;

  constructor(
    public payload: {
      networks: SideDrawerNetwork[];
    }
  ) {}
}

export class SideDrawerNetworkUpdated implements Action {
  readonly type = SideDrawerNetworkListActionsTypes.SideDrawerNetworkUpdated;

  constructor(public payload: { data: Update<SideDrawerNetwork> }) {}
}

export class SideDrawerNetworkDeleted implements Action {
  readonly type = SideDrawerNetworkListActionsTypes.SideDrawerNetworkDeleted;

  constructor(public payload: { id: string }) {}
}

export class SideDrawerNetworkClearInformation implements Action {
  readonly type =
    SideDrawerNetworkListActionsTypes.SideDrawerNetworkClearInformation;
}

export type SideDrawerNetworkListActions =
  | SideDrawerNetworkRequested
  | SideDrawerNetworkLoaded
  | SideDrawerNetworkUpdated
  | SideDrawerNetworkDeleted
  | SideDrawerNetworkClearInformation;
