import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { RelatedRecord } from '../models/related-record.model';
import {
  RelatedRecordActions,
  RelatedRecordActionTypes,
} from './related-records.actions';

export const featureName = 'related-records';

export interface RelatedRecordState extends EntityState<RelatedRecord> {
  gettingRelatedRecords: boolean;
}

export const adapter: EntityAdapter<RelatedRecord> =
  createEntityAdapter<RelatedRecord>({
    selectId: related => related.id,
  });

export const initialRelatedRecordState: RelatedRecordState =
  adapter.getInitialState({
    gettingRelatedRecords: false,
  });

export function relatedRecordsReducer(
  state = initialRelatedRecordState,
  action: RelatedRecordActions
): RelatedRecordState {
  switch (action.type) {
    case RelatedRecordActionTypes.RelatedRecordsLoaded:
      return adapter.setAll(action.payload.events, state);
    case RelatedRecordActionTypes.RelatedRecordAdded:
      return adapter.addOne(action.payload.event, state);
    case RelatedRecordActionTypes.RelatedRecordDeleted:
      return adapter.removeOne(action.payload.id, state);
    case RelatedRecordActionTypes.RelatedRecordsRemoved:
      return adapter.removeAll(state);
    case RelatedRecordActionTypes.GettingRelatedRecordsChange:
      return {
        ...state,
        gettingRelatedRecords: action.payload.gettingRelatedRecords,
      };
    default:
      return state;
  }
}

export const { selectAll } = adapter.getSelectors();
