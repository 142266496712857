import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  FormGroupDirective,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, map, take, tap } from 'rxjs/operators';
import { Locale } from 'src/app/dictionary/models/locale.model';
import {
  customFieldFormTypeByFormTypeSelector,
  localeDefaultSelector,
} from 'src/app/dictionary/store/dictionary.selectors';
import { AppState } from 'src/app/reducers';
import { environment } from 'src/environments/environment';
import { CustomField } from '../../../../models/custom-field.model';
import { SpecificFieldType } from '../../../../models/specific-field-type.model';
import { CustomFieldsChangeFormTypeDialogComponent } from '../../../custom-fields-change-form-type-dialog/custom-fields-change-form-type-dialog.component';
import { Country } from '../../../../../shared/sd-forms/models/country.model';
import { SdInputA11yTemplates } from '../../../../../shared/sd-input-a11y/models/sd-input-a11y-templates.enum';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-custom-fields-section-items',
  templateUrl: './custom-fields-section-items.component.html',
  styleUrls: ['./custom-fields-section-items.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class CustomFieldsSectionItemsComponent implements OnInit, OnDestroy {
  @Input() field: CustomField;
  @Input() isFieldTypeChange = false;
  @Input() key: string;
  @Input() viewOnly = false;
  @Output() valueChange = new EventEmitter<CustomField>();

  locale$: Observable<Locale>;
  subscription: Subscription = new Subscription();
  controller: AbstractControl;
  country: Country;
  countryName: string;
  specificFieldType = SpecificFieldType;
  cdn = environment.cdn;
  sdInputA11yTemplates = SdInputA11yTemplates;
  requiredFields = {
    street: false,
    apartment: false,
    postalCode: false,
    city: false,
    state: false,
    country: true,
  };

  constructor(
    private readonly store: Store<AppState>,
    private readonly dialog: MatDialog,
    private parentForm: FormGroupDirective
  ) {}

  ngOnInit(): void {
    this.locale$ = this.store.pipe(select(localeDefaultSelector));
    this.controller = this.parentForm.form.get(this.field?.id + '_' + this.key);
    this.subscription.add(
      this.controller.valueChanges
        .pipe(distinctUntilChanged())
        .subscribe(value => {
          const titleControl = this.parentForm.form.get(
            this.field?.id + '_title'
          );
          const title = titleControl.value;
          const detail = this.parentForm.form.get(
            this.field?.id + '_detail'
          ).value;

          if (title === '' && (detail === '' || detail === null)) {
            titleControl.clearValidators();
          } else {
            titleControl.setValidators(Validators.required);
          }

          titleControl.updateValueAndValidity();
          this.valueChange.emit({
            ...this.field,
            [this.key === 'title' ? 'label' : 'value']: value,
          });
        })
    );
    this.store
      .pipe(
        select(localeDefaultSelector),
        take(1),
        tap({
          next: ({ countryCode }) => {
            this.countryName = countryCode;
          },
        })
      )
      .subscribe();
  }

  openUrl(): void {
    if (!!this.controller.value && this.controller.value.length > 0) {
      const url = this.controller.value.includes('https://')
        ? this.controller.value
        : 'https://' + this.controller.value;
      window.open(url, '_blank');
    }
  }

  onDateChange(date: Date): void {
    this.field = {
      ...this.field,
      value: date,
    };
    this.valueChange.emit({ ...this.field, value: this.field.value });
  }

  getSvgIconPath(data: CustomField): Observable<string> {
    return this.store.pipe(
      select(
        customFieldFormTypeByFormTypeSelector({ formType: data.formType })
      ),
      map(ft => {
        return this.cdn + ft?.icon;
      })
    );
  }

  onClickFormTypeChange(): void {
    this.dialog
      .open(CustomFieldsChangeFormTypeDialogComponent, {
        hasBackdrop: true,
        disableClose: true,
        data: this.field,
        autoFocus: false,
      })
      .afterClosed()
      .subscribe((data: CustomField) => {
        if (data) {
          this.valueChange.emit({ ...data, formType: data.formType });
        }
      });
  }

  resetControl(): void {
    this.controller.reset();
    this.valueChange.emit({ ...this.field, value: '' });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  async copyClipboard(text?: string): Promise<void> {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  }

  getController(): UntypedFormControl {
    return <UntypedFormControl>this.controller;
  }
}
