import { inject, Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { Relation } from '../../records/models/relation.model';
import { relationshipSelector } from '../../dictionary/store/dictionary.selectors';

@Pipe({
  name: 'getRelationshipFromNetworkSignal',
  standalone: true,
})
export class GetRelationshipFromNetworkSignalPipe implements PipeTransform {
  private readonly store = inject(Store<AppState>);

  transform(relation: Relation): string {
    return Relation.getRelationString(
      relation,
      this.store.selectSignal(relationshipSelector)()
    );
  }
}
