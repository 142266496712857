<ng-template [ngIf]="template === recordListItemTemplate.default">
  <div
    class="records-list-item"
    (fileDropped)="dragAndDrop === true ? processFiles($event) : null"
    [id]="record?.id"
    appDragAndDrop>
    <div
      [id]="record?.id"
      [ngClass]="{
        'record-type-list-item-container-overlay': dragAndDrop === true,
        hidden: dragAndDrop === true
      }">
      <div class="plan-record-type-list-item-container-overlay-body"></div>
    </div>
    <div class="records-list-item-grid">
      <div class="records-list-item-image" tabindex="-1">
        <div class="records-list-item-image-container">
          <app-sd-svg-a11y
            [color]="'var(--primaryColor)'"
            [height]="1.3"
            [setSdAccessibility]="{
              tabIndex: -1
            }"
            [src]="
              record ? record?.recordType?.logo : (activeRecord$ | async)?.logo
            "
            [width]="1.3"></app-sd-svg-a11y>
        </div>
      </div>
      <div
        (click)="onRecordsDetails(menuItemType.view)"
        (keydown.enter)="onRecordsDetails(menuItemType.view)"
        [attr.aria-label]="
          record?.recordSubtypeOther?.length > 0
            ? record?.name +
              record?.recordSubtypeOther +
              ' ' +
              ('globalparams_lastmodifiedby' | dictionary | async) +
              ' ' +
              record?.lastModifiedBy +
              ' ' +
              (onOrAtString | dictionary | async) +
              ' ' +
              (record?.updatedAt | sdFormattedDate | async)
            : record?.name +
              record?.recordSubtype?.displayValue[0]?.value +
              ' ' +
              ('globalparams_lastmodifiedby' | dictionary | async) +
              ' ' +
              record?.lastModifiedBy +
              ' ' +
              (onOrAtString | dictionary | async) +
              ' ' +
              (record?.updatedAt | sdFormattedDate | async)
        "
        [tabIndex]="0"
        [attr.role]="'link'"
        class="records-list-item-title">
        <div class="records-list-item-name">
          <span>{{ record?.name }}</span>
          <app-sd-svg-a11y
            *ngIf="isSdOwnerOrSdEditor()"
            (click)="openRenameAndChangeTypeDialog(record, $event)"
            (keydown.enter)="openRenameAndChangeTypeDialog(record, $event)"
            [color]="'var(--primaryColor)'"
            [height]="1.125"
            [setSdAccessibility]="{
              role: 'button',
              ariaLabel:
                'recordrenameandchangetypedialog_title' | dictionary | async
            }"
            [src]="
              cdn +
              ('recordrenameandchangetypedialog_icon' | dictionary | async)
            "
            [tooltips]="
              'recordrenameandchangetypedialog_tooltip' | dictionary | async
            "
            [width]="1.125"
            class="row_actions"></app-sd-svg-a11y>
        </div>

        <div class="records-list-item-label" *ngIf="record?.recordSubtype">
          <span>{{
            record?.recordSubtypeOther?.length > 0
              ? record?.recordSubtypeOther
              : (record?.recordSubtype?.displayValue | displayValue | async)
          }}</span>
        </div>
      </div>

      <div
        [tabindex]="-1"
        (click)="onRecordsDetails(menuItemType.view)"
        (keydown)="onRecordsDetails(menuItemType.view)"
        class="records-list-item-time">
        <span class="last-modified">{{
          'globalparams_lastmodifiedby' | dictionary | async
        }}</span>
        <strong class="by">{{ record?.lastModifiedBy }}</strong>
        <!-- <br class="mobileSpacer" /> -->
        <span class="at">{{ onOrAtString | dictionary | async }}</span>
        <strong class="date">{{
          record?.updatedAt | sdFormattedDate | async
        }}</strong>
      </div>
      <div class="records-list-item-details">
        <div class="records-list-item-details-icon"></div>
        <div
          *ngIf="(spinner$ | async) === false"
          class="records-list-item-actions">
          <app-sd-link-menu-a11y
            (emitterClicked)="onEmitterClicked($event)"
            [accessibility]="{
              ariaLabel:
                record?.name +
                ' ' +
                ('recordslistitem_actionmenutooltip' | dictionary | async),
              tabIndex: -1
            }"
            [border]="false"
            [buttonHeight]="1.5"
            [buttonWidth]="2"
            [disableRipple]="true"
            [iconColor]="sdColorPalette.primaryAccentColor"
            [icon]="
              cdn +
              ('globalparams_horizontalactionmenuicon' | dictionary | async)
            "
            [setMenuData]="[
              {
                menuLinkText:
                  'recordslistitem_actionmenuoptionrename' | dictionary | async,
                visible: isSdOwnerOrSdEditor() ? true : false,
                menuLinkType: sdLinkMenuLinkType.emitter,
                key: menuItemType.rename
              },
              {
                menuLinkText:
                  'recordslistitem_actionmenuoptionview' | dictionary | async,
                visible: true,
                menuLinkType: sdLinkMenuLinkType.emitter,
                key: menuItemType.view
              },
              {
                menuLinkText:
                  'recordslistitem_actionmenuoptionfiles' | dictionary | async,
                visible: isSdOwnerOrSdEditor() ? true : false,
                menuLinkType: sdLinkMenuLinkType.emitter,
                key: menuItemType.files
              },
              {
                menuLinkText:
                  'recordslistitem_actionmenuoptionnetworks'
                  | dictionary
                  | async,
                visible: isSdOwnerOrSdEditor() ? true : false,
                menuLinkType: sdLinkMenuLinkType.emitter,
                key: menuItemType.share
              },
              {
                menuLinkText:
                  'recordslistitem_actionmenuoptiongeneralinfo'
                  | dictionary
                  | async,
                visible: isSdOwnerOrSdEditor() ? true : false,
                menuLinkType: sdLinkMenuLinkType.emitter,
                key: menuItemType.edit
              },
              {
                menuLinkText:
                  'recordslistitem_actionmenuoptionreminders'
                  | dictionary
                  | async,
                visible: isSdOwnerOrSdEditor() ? true : false,
                menuLinkType: sdLinkMenuLinkType.emitter,
                key: menuItemType.reminders
              },
              {
                menuLinkText:
                  'recordslistitem_actionmenumove' | dictionary | async,
                visible: isSdOwnerOrSdEditor() ? true : false,
                menuLinkType: sdLinkMenuLinkType.emitter,
                key: menuItemType.moveRecord
              },
              {
                menuLinkText:
                  'recordslistitem_actionmenuoptiondelete' | dictionary | async,
                visible: true,
                menuLinkType: sdLinkMenuLinkType.emitter,
                key: menuItemType.delete
              },
              {
                menuLinkText:
                  'recordslistitem_actionmenuoptioncopy' | dictionary | async,
                visible: true,
                menuLinkType: sdLinkMenuLinkType.emitter,
                key: menuItemType.copy
              }
            ]"
            [squareBackground]="true"
            [tooltip]="
              record?.name +
              ' ' +
              ('recordslistitem_actionmenutooltip' | dictionary | async)
            "
            [transparentBackground]="true">
          </app-sd-link-menu-a11y>
        </div>
        <div
          *ngIf="(spinner$ | async) === true"
          class="records-list-item-actions spinner">
          <app-sd-progress-spinner-a11y></app-sd-progress-spinner-a11y>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template [ngIf]="template === recordListItemTemplate.sumary">
  <div
    class="records-list-item"
    (fileDropped)="dragAndDrop === true ? processFiles($event) : null"
    [id]="record?.id"
    appDragAndDrop>
    <div
      [id]="record?.id"
      [ngClass]="{
        'record-type-list-item-container-overlay': dragAndDrop === true,
        hidden: dragAndDrop === true
      }">
      <div class="plan-record-type-list-item-container-overlay-body"></div>
    </div>
    <div class="records-list-item-grid">
      <div class="records-list-item-image" tabindex="-1">
        <div class="records-list-item-image-container">
          <app-sd-svg-a11y
            [color]="'var(--primaryColor)'"
            [height]="1.3"
            [setSdAccessibility]="{
              tabIndex: -1
            }"
            [src]="
              record ? record?.recordType?.logo : (activeRecord$ | async)?.logo
            "
            [width]="1.3"></app-sd-svg-a11y>
        </div>
      </div>
      <div
        (click)="onRecordsDetails(menuItemType.view)"
        (keydown.enter)="onRecordsDetails(menuItemType.view)"
        [attr.aria-label]="
          record?.recordSubtypeOther?.length > 0
            ? record?.name +
              record?.recordSubtypeOther +
              ' ' +
              ('globalparams_lastmodifiedby' | dictionary | async) +
              ' ' +
              record?.lastModifiedBy +
              ' ' +
              (onOrAtString | dictionary | async) +
              ' ' +
              (record?.updatedAt | sdFormattedDate | async)
            : record?.name +
              record?.recordSubtype?.displayValue[0]?.value +
              ' ' +
              ('globalparams_lastmodifiedby' | dictionary | async) +
              ' ' +
              record?.lastModifiedBy +
              ' ' +
              (onOrAtString | dictionary | async) +
              ' ' +
              (record?.updatedAt | sdFormattedDate | async)
        "
        [tabIndex]="0"
        [attr.role]="'link'"
        class="records-list-item-title">
        <div class="records-list-item-name">
          <span>{{ record?.name }}</span>
        </div>

        <div class="records-list-item-label" *ngIf="record?.recordSubtype">
          <span>{{
            record?.recordSubtypeOther?.length > 0
              ? record?.recordSubtypeOther
              : (record?.recordSubtype?.displayValue | displayValue | async)
          }}</span>
        </div>
      </div>

      <div
        [tabindex]="-1"
        (click)="onRecordsDetails(menuItemType.view)"
        (keydown)="onRecordsDetails(menuItemType.view)"
        class="records-list-item-time">
        <span class="last-modified">{{
          'globalparams_lastmodifiedby' | dictionary | async
        }}</span>
        <strong class="by">{{ record?.lastModifiedBy }}</strong>
        <!-- <br class="mobileSpacer" /> -->
        <span class="at">{{ onOrAtString | dictionary | async }}</span>
        <strong class="date">{{
          record?.updatedAt | sdFormattedDate | async
        }}</strong>
      </div>
      <div class="records-list-item-details">
        <div class="records-list-item-details-icon"></div>
        <div
          *ngIf="(spinner$ | async) === false"
          class="records-list-item-actions">
          <app-sd-link-menu-a11y
            (emitterClicked)="onEmitterClicked($event)"
            [accessibility]="{
              ariaLabel:
                record?.name +
                ' ' +
                ('recordslistitem_actionmenutooltip' | dictionary | async),
              tabIndex: -1
            }"
            [border]="false"
            [buttonHeight]="1.5"
            [buttonWidth]="2"
            [disableRipple]="true"
            [iconColor]="sdColorPalette.primaryAccentColor"
            [icon]="
              cdn +
              ('globalparams_horizontalactionmenuicon' | dictionary | async)
            "
            [setMenuData]="[
              {
                menuLinkText:
                  'recordslistitem_actionmenuoptionview' | dictionary | async,
                visible: true,
                menuLinkType: sdLinkMenuLinkType.emitter,
                key: menuItemType.view
              },
              {
                menuLinkText:
                  'recordslistitem_actionmenuoptionfiles' | dictionary | async,
                visible: isSdOwnerOrSdEditor() ? true : false,
                menuLinkType: sdLinkMenuLinkType.emitter,
                key: menuItemType.files
              },
              {
                menuLinkText:
                  'recordslistitem_actionmenuoptionnetworks'
                  | dictionary
                  | async,
                visible: isSdOwnerOrSdEditor() ? true : false,
                menuLinkType: sdLinkMenuLinkType.emitter,
                key: menuItemType.share
              },
              {
                menuLinkText:
                  'recordslistitem_actionmenuoptionreminders'
                  | dictionary
                  | async,
                visible: isSdOwnerOrSdEditor() ? true : false,
                menuLinkType: sdLinkMenuLinkType.emitter,
                key: menuItemType.reminders
              },
              {
                menuLinkText:
                  'recordslistitem_actionmenumove' | dictionary | async,
                visible: isSdOwnerOrSdEditor() ? true : false,
                menuLinkType: sdLinkMenuLinkType.emitter,
                key: menuItemType.moveRecord
              },

              {
                menuLinkText:
                  'recordslistitem_actionmenuoptioncopy' | dictionary | async,
                visible: true,
                menuLinkType: sdLinkMenuLinkType.emitter,
                key: menuItemType.copy
              }
            ]"
            [squareBackground]="true"
            [tooltip]="
              record?.name +
              ' ' +
              ('recordslistitem_actionmenutooltip' | dictionary | async)
            "
            [transparentBackground]="true">
          </app-sd-link-menu-a11y>
        </div>
        <div
          *ngIf="(spinner$ | async) === true"
          class="records-list-item-actions spinner">
          <app-sd-progress-spinner-a11y></app-sd-progress-spinner-a11y>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template [ngIf]="template === recordListItemTemplate.noaction">
  <div class="records-list-item viewOnlyList">
    <div class="records-list-item-grid viewOnly">
      <div class="records-list-item-image" tabindex="-1">
        <div class="records-list-item-image-container">
          <app-sd-svg-a11y
            [color]="'var(--primaryColor)'"
            [height]="1.3"
            [setSdAccessibility]="{
              tabIndex: -1
            }"
            [src]="
              record ? record?.recordType?.logo : (activeRecord$ | async)?.logo
            "
            [width]="1.3"></app-sd-svg-a11y>
        </div>
      </div>
      <div
        [attr.aria-label]="
          record?.recordSubtypeOther?.length > 0
            ? record?.name +
              record?.recordSubtypeOther +
              ' ' +
              ('globalparams_lastmodifiedby' | dictionary | async) +
              ' ' +
              record?.lastModifiedBy +
              ' ' +
              (onOrAtString | dictionary | async) +
              ' ' +
              (record?.updatedAt | sdFormattedDate | async)
            : record?.name +
              record?.recordSubtype?.displayValue[0]?.value +
              ' ' +
              ('globalparams_lastmodifiedby' | dictionary | async) +
              ' ' +
              record?.lastModifiedBy +
              ' ' +
              (onOrAtString | dictionary | async) +
              ' ' +
              (record?.updatedAt | sdFormattedDate | async)
        "
        [tabIndex]="0"
        [attr.role]="'link'"
        class="records-list-item-title">
        <div class="records-list-item-name">
          <span>{{ record?.name }}</span>
        </div>

        <div class="records-list-item-label" *ngIf="record?.recordSubtype">
          <span>{{
            record?.recordSubtypeOther?.length > 0
              ? record?.recordSubtypeOther
              : (record?.recordSubtype?.displayValue | displayValue | async)
          }}</span>
        </div>
      </div>

      <div [tabindex]="-1" class="records-list-item-time">
        <span class="last-modified">{{
          'globalparams_lastmodifiedby' | dictionary | async
        }}</span>
        <strong class="by">{{ record?.lastModifiedBy }}</strong>
        <!-- <br class="mobileSpacer" /> -->
        <span class="at">{{ onOrAtString | dictionary | async }}</span>
        <strong class="date">{{
          record?.updatedAt | sdFormattedDate | async
        }}</strong>
      </div>
    </div>
  </div>
</ng-template>

<ng-template [ngIf]="template === recordListItemTemplate.dummyrecord">
  <div class="records-list-item dummyrecord">
    <div class="records-list-item-grid viewOnly">
      <div class="records-list-item-image" tabindex="-1">
        <div class="records-list-item-image-container">
          <app-sd-svg-a11y
            [color]="'var(--tertiaryAccentColor)'"
            [height]="1.3"
            [setSdAccessibility]="{
              tabIndex: -1
            }"
            [src]="record?.recordType?.logo"
            [width]="1.3"></app-sd-svg-a11y>
        </div>
      </div>
      <div [tabIndex]="0" [attr.role]="'link'" class="records-list-item-title">
        <div class="records-list-item-name">
          <span>{{ record?.name }}</span>
        </div>

        <div class="records-list-item-label" *ngIf="record?.recordSubtype">
          <span>{{
            record?.recordSubtypeOther?.length > 0
              ? record?.recordSubtypeOther
              : (record?.recordSubtype?.displayValue | displayValue | async)
          }}</span>
        </div>
      </div>
      <div class="records-list-item-time"></div>
      <div class="records-list-item-details">
        <div class="records-list-item-details-icon"></div>
        <div
          *ngIf="
            !itemStore?.processing() &&
            itemStore?.processingRecordId() !== record.id
          "
          class="records-list-item-actions">
          <app-sd-link-menu-a11y
            (emitterClicked)="emiterClicked.emit($event)"
            [accessibility]="{
              ariaLabel:
                record?.name +
                ' ' +
                ('recordslistitem_actionmenutooltip' | dictionary | async),
              tabIndex: -1
            }"
            [border]="false"
            [buttonHeight]="1.5"
            [buttonWidth]="2"
            [disableRipple]="true"
            [iconColor]="sdColorPalette.primaryAccentColor"
            [icon]="
              cdn +
              ('globalparams_horizontalactionmenuicon' | dictionary | async)
            "
            [setMenuData]="[
              {
                menuLinkText:
                  'recordslistitem_actionmenuoptionuploadfiles'
                  | dictionary
                  | async,
                visible: isSdOwnerOrSdEditor() ? true : false,
                menuLinkType: sdLinkMenuLinkType.emitter,
                key: menuItemType.upload
              }
            ]"
            [squareBackground]="true"
            [tooltip]="
              record?.name +
              ' ' +
              ('recordslistitem_actionmenutooltip' | dictionary | async)
            "
            [transparentBackground]="true">
          </app-sd-link-menu-a11y>
        </div>
        <div
          *ngIf="
            itemStore?.processing() &&
            itemStore?.processingRecordId() === record.id
          "
          class="records-list-item-actions spinner">
          <app-sd-progress-spinner-a11y></app-sd-progress-spinner-a11y>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template [ngIf]="template === recordListItemTemplate.planItem">
  <div
    class="records-list-item planItem"
    (fileDropped)="dragAndDrop === true ? processFiles($event) : null"
    [id]="record?.id"
    appDragAndDrop>
    <div
      [id]="record?.id"
      [ngClass]="{
        'plan-record-type-list-item-container-overlay': dragAndDrop === true,
        hidden: dragAndDrop === true
      }">
      <div class="plan-record-type-list-item-container-overlay-body"></div>
    </div>
    <div class="records-list-item-grid">
      <div class="records-list-item-image" tabindex="-1">
        <div class="records-list-item-image-container">
          <app-sd-svg-a11y
            [color]="'var(--primaryColor)'"
            [height]="1.3"
            [setSdAccessibility]="{
              tabIndex: -1
            }"
            [src]="
              record ? record?.recordType?.logo : (activeRecord$ | async)?.logo
            "
            [width]="1.3"></app-sd-svg-a11y>
        </div>
      </div>
      <div
        (click)="onRecordsDetails(menuItemType.view)"
        (keydown.enter)="onRecordsDetails(menuItemType.view)"
        [attr.aria-label]="
          record?.recordSubtypeOther?.length > 0
            ? record?.name +
              record?.recordSubtypeOther +
              ' ' +
              ('globalparams_lastmodifiedby' | dictionary | async) +
              ' ' +
              record?.lastModifiedBy +
              ' ' +
              (onOrAtString | dictionary | async) +
              ' ' +
              (record?.updatedAt | sdFormattedDate | async)
            : record?.name +
              record?.recordSubtype?.displayValue[0]?.value +
              ' ' +
              ('globalparams_lastmodifiedby' | dictionary | async) +
              ' ' +
              record?.lastModifiedBy +
              ' ' +
              (onOrAtString | dictionary | async) +
              ' ' +
              (record?.updatedAt | sdFormattedDate | async)
        "
        [tabIndex]="0"
        [attr.role]="'link'"
        class="records-list-item-title">
        <div class="records-list-item-name">
          <span>{{ record?.name }}</span>
          <app-sd-svg-a11y
            *ngIf="isSdOwnerOrSdEditor()"
            (click)="emitRenameAndChangeTypeDialog(record, $event)"
            (keydown.enter)="emitRenameAndChangeTypeDialog(record, $event)"
            [color]="'var(--primaryColor)'"
            [height]="1.125"
            [setSdAccessibility]="{
              role: 'button',
              ariaLabel:
                'recordrenameandchangetypedialog_title' | dictionary | async
            }"
            [src]="
              cdn +
              ('recordrenameandchangetypedialog_icon' | dictionary | async)
            "
            [tooltips]="
              'recordrenameandchangetypedialog_tooltip' | dictionary | async
            "
            [width]="1.125"
            class="row_actions"></app-sd-svg-a11y>
        </div>

        <div class="records-list-item-label" *ngIf="record?.recordSubtype">
          <span>{{
            record?.recordSubtypeOther?.length > 0
              ? record?.recordSubtypeOther
              : (record?.recordSubtype?.displayValue | displayValue | async)
          }}</span>
        </div>
      </div>

      <div
        [tabindex]="-1"
        (click)="onRecordsDetails(menuItemType.view)"
        (keydown)="onRecordsDetails(menuItemType.view)"
        class="records-list-item-time">
        <span class="last-modified">{{
          record?.lastModifiedBy
            ? ('globalparams_lastmodifiedby' | dictionary | async)
            : ('globalparams_lastmodified' | dictionary | async)
        }}</span>
        <strong *ngIf="!!record?.lastModifiedBy" class="by">{{
          record?.lastModifiedBy
        }}</strong>
        <!-- <br class="mobileSpacer" /> -->
        <span *ngIf="!!onOrAtString && !!record?.updatedAt" class="at">{{
          onOrAtString | dictionary | async
        }}</span>
        <strong *ngIf="!!record?.updatedAt" class="date">{{
          record?.updatedAt | sdFormattedDate | async
        }}</strong>
      </div>
      <div class="records-list-item-details">
        <div class="records-list-item-details-icon"></div>
        <div
          *ngIf="
            !itemStore?.processing() ||
            (itemStore?.processing() &&
              itemStore?.processingRecordId() !== record.id)
          "
          class="records-list-item-actions">
          <app-sd-link-menu-a11y
            (emitterClicked)="emiterClicked.emit($event)"
            [accessibility]="{
              ariaLabel:
                record?.name +
                ' ' +
                ('recordslistitem_actionmenutooltip' | dictionary | async),
              tabIndex: -1
            }"
            [border]="false"
            [buttonHeight]="1.5"
            [buttonWidth]="2"
            [disableRipple]="true"
            [iconColor]="sdColorPalette.primaryAccentColor"
            [icon]="
              cdn +
              ('globalparams_horizontalactionmenuicon' | dictionary | async)
            "
            [setMenuData]="[
              {
                menuLinkText:
                  'recordslistitem_actionmenuoptionrename' | dictionary | async,
                visible: isSdOwnerOrSdEditor() ? true : false,
                menuLinkType: sdLinkMenuLinkType.emitter,
                key: menuItemType.rename
              },
              {
                menuLinkText:
                  'recordslistitem_actionmenuoptionview' | dictionary | async,
                visible: true,
                menuLinkType: sdLinkMenuLinkType.emitter,
                key: menuItemType.view
              },
              {
                menuLinkText:
                  'recordslistitem_actionmenuoptionupload' | dictionary | async,
                visible: true,
                menuLinkType: sdLinkMenuLinkType.emitter,
                key: menuItemType.upload
              },
              {
                menuLinkText:
                  'recordslistitem_actionmenuoptionunlink' | dictionary | async,
                visible: true,
                menuLinkType: sdLinkMenuLinkType.emitter,
                key: menuItemType.delete
              }
            ]"
            [squareBackground]="true"
            [tooltip]="
              record?.name +
              ' ' +
              ('recordslistitem_actionmenutooltip' | dictionary | async)
            "
            [transparentBackground]="true">
          </app-sd-link-menu-a11y>
        </div>
        <div
          *ngIf="
            itemStore?.processing() &&
            itemStore?.processingRecordId() === record.id
          "
          class="records-list-item-actions spinner">
          <app-sd-progress-spinner-a11y></app-sd-progress-spinner-a11y>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template [ngIf]="template === recordListItemTemplate.salesForceItem">
  <div
    class="records-list-item salesforceItem"
    (fileDropped)="dragAndDrop === true ? processFiles($event) : null"
    [id]="record?.id"
    appDragAndDrop>
    <div
      [id]="record?.id"
      [ngClass]="{
        'plan-record-type-list-item-container-overlay': dragAndDrop === true,
        hidden: dragAndDrop === true
      }">
      <div class="plan-record-type-list-item-container-overlay-body"></div>
    </div>
    <div class="records-list-item-grid">
      <div class="records-list-item-image" tabindex="-1">
        <div class="records-list-item-image-container">
          <app-sd-svg-a11y
            [color]="'var(--primaryColor)'"
            [height]="1.3"
            [setSdAccessibility]="{
              tabIndex: -1
            }"
            [src]="
              record ? record?.recordType?.logo : (activeRecord$ | async)?.logo
            "
            [width]="1.3"></app-sd-svg-a11y>
        </div>
      </div>
      <div
        [attr.aria-label]="
          record?.recordSubtypeOther?.length > 0
            ? record?.name +
              record?.recordSubtypeOther +
              ' ' +
              ('globalparams_lastmodifiedby' | dictionary | async) +
              ' ' +
              record?.lastModifiedBy +
              ' ' +
              (onOrAtString | dictionary | async) +
              ' ' +
              (record?.updatedAt | sdFormattedDate | async)
            : record?.name +
              record?.recordSubtype?.displayValue[0]?.value +
              ' ' +
              ('globalparams_lastmodifiedby' | dictionary | async) +
              ' ' +
              record?.lastModifiedBy +
              ' ' +
              (onOrAtString | dictionary | async) +
              ' ' +
              (record?.updatedAt | sdFormattedDate | async)
        "
        [tabIndex]="0"
        [attr.role]="'link'"
        class="records-list-item-title">
        <div class="records-list-item-name">
          <span>{{ record?.name }}</span>
        </div>

        <div class="records-list-item-label" *ngIf="record?.recordSubtype">
          <span>{{
            record?.recordSubtypeOther?.length > 0
              ? record?.recordSubtypeOther
              : (record?.recordSubtype?.displayValue | displayValue | async)
          }}</span>
        </div>
      </div>

      <div [tabindex]="-1" class="records-list-item-time">
        <span class="last-modified">{{
          record?.lastModifiedBy
            ? ('globalparams_lastmodifiedby' | dictionary | async)
            : ('globalparams_lastmodified' | dictionary | async)
        }}</span>
        <strong *ngIf="!!record?.lastModifiedBy" class="by">{{
          record?.lastModifiedBy
        }}</strong>
        <span *ngIf="!!onOrAtString && !!record?.updatedAt" class="at">{{
          onOrAtString | dictionary | async
        }}</span>
        <strong *ngIf="!!record?.updatedAt" class="date">{{
          record?.updatedAt | sdFormattedDate | async
        }}</strong>
      </div>
      <div class="records-list-item-details">
        <div class="records-list-item-details-icon"></div>
        <div
          *ngIf="
            !itemStore?.processing() ||
            (itemStore?.processing() &&
              itemStore?.processingRecordId() !== record.id)
          "
          class="records-list-item-actions">
          <app-sd-link-menu-a11y
            (emitterClicked)="emiterClicked.emit($event)"
            [accessibility]="{
              ariaLabel:
                record?.name +
                ' ' +
                ('recordslistitem_actionmenutooltip' | dictionary | async),
              tabIndex: -1
            }"
            [border]="false"
            [buttonHeight]="1.5"
            [buttonWidth]="2"
            [disableRipple]="true"
            [iconColor]="sdColorPalette.primaryAccentColor"
            [icon]="
              cdn +
              ('globalparams_horizontalactionmenuicon' | dictionary | async)
            "
            [setMenuData]="[
              {
                menuLinkText:
                  'recordslistitem_actionmenuoptionupload' | dictionary | async,
                visible: true,
                menuLinkType: sdLinkMenuLinkType.emitter,
                key: menuItemType.upload
              },
              {
                menuLinkText:
                  'recordslistitem_actionmenuoptionunlink' | dictionary | async,
                visible: true,
                menuLinkType: sdLinkMenuLinkType.emitter,
                key: menuItemType.delete
              }
            ]"
            [squareBackground]="true"
            [tooltip]="
              record?.name +
              ' ' +
              ('recordslistitem_actionmenutooltip' | dictionary | async)
            "
            [transparentBackground]="true">
          </app-sd-link-menu-a11y>
        </div>
        <div
          *ngIf="
            itemStore?.processing() &&
            itemStore?.processingRecordId() === record.id
          "
          class="records-list-item-actions spinner">
          <app-sd-progress-spinner-a11y></app-sd-progress-spinner-a11y>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template [ngIf]="template === recordListItemTemplate.recordHeader">
  <div class="records-list-item record-header" [id]="record?.id">
    <div class="records-list-item-grid">
      <div class="records-list-item-image" tabindex="-1">
        <div class="records-list-item-image-container">
          <app-sd-svg-a11y
            [color]="'var(--primaryColor)'"
            [height]="1.3"
            [setSdAccessibility]="{
              tabIndex: -1
            }"
            [src]="
              record ? record?.recordType?.logo : (activeRecord$ | async)?.logo
            "
            [width]="1.3"></app-sd-svg-a11y>
        </div>
      </div>
      <div
        [attr.aria-label]="
          record?.recordSubtypeOther?.length > 0
            ? record?.name +
              record?.recordSubtypeOther +
              ' ' +
              ('globalparams_lastmodifiedby' | dictionary | async) +
              ' ' +
              record?.lastModifiedBy +
              ' ' +
              (onOrAtString | dictionary | async) +
              ' ' +
              (record?.updatedAt | sdFormattedDate | async)
            : record?.name +
              record?.recordSubtype?.displayValue[0]?.value +
              ' ' +
              ('globalparams_lastmodifiedby' | dictionary | async) +
              ' ' +
              record?.lastModifiedBy +
              ' ' +
              (onOrAtString | dictionary | async) +
              ' ' +
              (record?.updatedAt | sdFormattedDate | async)
        "
        [tabIndex]="0"
        [attr.role]="'link'"
        class="records-list-item-title">
        <div class="records-list-item-name">
          <span>{{ record?.name }}</span>
          <app-sd-svg-a11y
            *ngIf="hasSdOrRecordEditPermission()"
            (click)="emitRenameAndChangeTypeDialog(record, $event)"
            (keydown.enter)="emitRenameAndChangeTypeDialog(record, $event)"
            [color]="'var(--primaryColor)'"
            [height]="1.125"
            [setSdAccessibility]="{
              role: 'button',
              ariaLabel:
                'recordrenameandchangetypedialog_title' | dictionary | async
            }"
            [src]="
              cdn +
              ('recordrenameandchangetypedialog_icon' | dictionary | async)
            "
            [tooltips]="
              'recordrenameandchangetypedialog_tooltip' | dictionary | async
            "
            [width]="1.125"
            class="row_actions"></app-sd-svg-a11y>
        </div>

        <div class="records-list-item-label" *ngIf="record?.recordSubtype">
          <span>{{
            record?.recordSubtypeOther?.length > 0
              ? record?.recordSubtypeOther
              : (record?.recordSubtype?.displayValue | displayValue | async)
          }}</span>
        </div>
      </div>

      <div [tabindex]="-1" class="records-list-item-time">
        <span class="last-modified">{{
          'globalparams_lastmodifiedby' | dictionary | async
        }}</span>
        <strong class="by">{{ record?.lastModifiedBy }}</strong>
        <!-- <br class="mobileSpacer" /> -->
        <span class="at">{{ onOrAtString | dictionary | async }}</span>
        <strong class="date">{{
          record?.updatedAt | sdFormattedDate | async
        }}</strong>
      </div>
      <div class="records-list-item-details">
        <div class="records-list-item-details-icon"></div>
        <div
          *ngIf="(spinner$ | async) === false"
          class="records-list-item-actions">
          <app-sd-link-menu-a11y
            (emitterClicked)="emiterClicked.emit($event)"
            [accessibility]="{
              ariaLabel:
                record?.name +
                ' ' +
                ('recordslistitem_actionmenutooltip' | dictionary | async),
              tabIndex: -1
            }"
            [border]="false"
            [buttonHeight]="1.5"
            [buttonWidth]="2"
            [disableRipple]="true"
            [iconColor]="sdColorPalette.primaryAccentColor"
            [icon]="
              cdn +
              ('globalparams_horizontalactionmenuicon' | dictionary | async)
            "
            [setMenuData]="
              menuTemplate ?? [
                {
                  menuLinkText:
                    'recordslistitem_actionmenuoptionrename'
                    | dictionary
                    | async,
                  visible: hasSdOrRecordEditPermission(),
                  menuLinkType: sdLinkMenuLinkType.emitter,
                  key: menuItemType.rename
                },
                {
                  menuLinkText:
                    'recordslistitem_actionmenumove' | dictionary | async,
                  visible: hasSdOrRecordEditPermission(),
                  menuLinkType: sdLinkMenuLinkType.emitter,
                  key: menuItemType.moveRecord
                },
                {
                  menuLinkText:
                    'recordslistitem_actionmenuoptiondelete'
                    | dictionary
                    | async,
                  visible: hasSdOrRecordEditPermission(),
                  menuLinkType: sdLinkMenuLinkType.emitter,
                  key: menuItemType.delete
                },
                {
                  menuLinkText:
                    'recordslistitem_actionmenuoptioncopy' | dictionary | async,
                  visible: true,
                  menuLinkType: sdLinkMenuLinkType.emitter,
                  key: menuItemType.copy
                }
              ]
            "
            [squareBackground]="true"
            [tooltip]="
              record?.name +
              ' ' +
              ('recordslistitem_actionmenutooltip' | dictionary | async)
            "
            [transparentBackground]="true">
          </app-sd-link-menu-a11y>
        </div>
        <div
          *ngIf="(spinner$ | async) === true"
          class="records-list-item-actions spinner">
          <app-sd-progress-spinner-a11y></app-sd-progress-spinner-a11y>
        </div>
      </div>
    </div>
  </div>
</ng-template>
