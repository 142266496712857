import { ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { combineLatest, Observable, switchMap } from 'rxjs';
import { SdRecordTabHeaderComponent } from '../../../shared/sd-record-tab-header/sd-record-tab-header.component';
import { SdFlatButtonA11yModule } from '../../../shared/sd-flat-button-a11y/sd-flat-button-a11y.module';
import { DictionaryPipeModule } from '../../../dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { Reminders } from '../../models/reminders.model';
import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import {
  getRemindersOrderedByStatusListSelector,
  remindersLoadingSelector,
} from '../../store/reminders-list.selectors';
import { map, take, tap } from 'rxjs/operators';
import { Record } from '../../../records/models/record.model';
import { RecordFormViewStore } from '../../../records/views/record-form-view/record-form-view.store';
import { RemindersFormDialogA11yComponent } from '../../shared/reminders-form-dialog-a11y/components/reminders-form-dialog-a11y/reminders-form-dialog-a11y.component';
import { ActivatedRoute } from '@angular/router';
import {
  ReminderGetReminderByIdRequested,
  ReminderGetReminderByIdSuccess,
  RemindersListActionsTypes,
} from '../../store/reminders-list.action';
import { Actions, ofType } from '@ngrx/effects';
import { RemindersListA11yModule } from '../../shared/reminders-list-a11y/reminders-list-a11y.module';
import { SdColorPalette } from 'src/app/core/models/enums/sd-color-palette-enum';
import { environment } from 'src/environments/environment';
import { ReminderStatus } from '../../models/enums/reminder-status.enum';
import { SdTooltipButtonA11yComponent } from 'src/app/shared/sd-tooltip-button-a11y/component/sd-tooltip-button-a11y/sd-tooltip-button-a11y.component';
import { MatDialog } from '@angular/material/dialog';
import { SdProgressBarA11yComponent } from 'src/app/shared/sd-progress-bar-a11y/components/sd-progress-bar-a11y/sd-progress-bar-a11y.component';
import { SdIconButtonA11yComponent } from 'src/app/shared/sd-icon-button-a11y/components/sd-icon-button-a11y/sd-icon-button-a11y.component';
import { activeRecordSelector } from '../../../records/store/records-list.selectors';
import { TypesOfReminders } from '../../models/enums/types-of-reminders.enum';

@Component({
  selector: 'app-record-form-reminders-tab',
  standalone: true,
  imports: [
    CommonModule,
    SdProgressBarA11yComponent,
    SdRecordTabHeaderComponent,
    SdFlatButtonA11yModule,
    DictionaryPipeModule,
    RemindersListA11yModule,
    SdTooltipButtonA11yComponent,
    SdIconButtonA11yComponent,
  ],
  templateUrl: './record-form-reminders-tab.component.html',
  styleUrls: ['./record-form-reminders-tab.component.scss'],
})
export class RecordFormRemindersTabComponent implements OnInit {
  protected readonly store = inject(Store<AppState>);
  protected readonly dialog = inject(MatDialog);
  protected readonly recordFormViewStore = inject(RecordFormViewStore);
  protected readonly changeDetectorRef = inject(ChangeDetectorRef);
  protected readonly router = inject(ActivatedRoute);
  protected readonly actions$ = inject(Actions);
  vm$: Observable<{
    gettingInformation: boolean;
    reminders: Reminders[];
  }> = combineLatest([
    this.store.select(remindersLoadingSelector),
    this.store.select(activeRecordSelector).pipe(
      switchMap(activeRecord =>
        this.store.select(
          getRemindersOrderedByStatusListSelector({
            typeOfReminder: TypesOfReminders.record,
            recordId: activeRecord?.id,
          })
        )
      )
    ),
  ]).pipe(
    map(([gettingInformation, reminders]) => ({
      gettingInformation,
      reminders,
    }))
  );
  sdColorPalette = SdColorPalette;
  cdn = environment.cdn;

  ngOnInit(): void {
    const { params } = this.router.snapshot;
    // TODO see this
    if (params?.reminderId) {
      this.getReminderById(params?.reminderId);
    }
  }

  onAddReminder(): void {
    this.recordFormViewStore.getOrCreateRecord(record =>
      this.openReminderDialog(record)
    );
  }

  openReminderDialog(record: Record, reminder?: Reminders): void {
    this.dialog
      .open(RemindersFormDialogA11yComponent, {
        autoFocus: false,
        panelClass: 'custom-dialog-container-for-reminders-form-dialog-a11y',
        data: {
          record,
          reminder,
          disabled: reminder?.status === ReminderStatus.Finished,
        },
      })
      .afterClosed()
      .pipe(
        tap(() => {
          this.changeDetectorRef.detectChanges();
        })
      )
      .subscribe();
  }

  private onEditReminderWorkflow(reminder: Reminders): void {
    this.recordFormViewStore.getOrCreateRecord(record =>
      this.openReminderDialog(record, reminder)
    );
  }

  private getReminderById(reminderId: string): void {
    this.store.dispatch(
      new ReminderGetReminderByIdRequested({ id: reminderId })
    );

    this.actions$
      .pipe(
        ofType(RemindersListActionsTypes.ReminderGetReminderByIdSuccess),
        take(1),
        tap((action: ReminderGetReminderByIdSuccess) => {
          const reminder = new Reminders(
            action.payload.reminder.name,
            action.payload.reminder.resource,
            action.payload.reminder.message,
            action.payload.reminder.schedule,
            action.payload.reminder.to,
            action.payload.reminder.id,
            action.payload.reminder.status
          );
          this.onEditReminderWorkflow(reminder);
        })
      )
      .subscribe();
  }
}
