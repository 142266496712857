<app-sd-dialog-template-a11y
  (closeButtonClicked)="onClose()"
  [type]="DialogTemplateTypes.Form">
  <ng-container dialog-header>
    <h2>
      {{ 'createrecordforfilesdialog_title' | dictionary | async }}
    </h2>
  </ng-container>
  <ng-container dialog-body>
    <div *ngIf="vm$ | async as vm"></div>
    <form
      [formGroup]="form"
      *ngIf="(vm$ | async).activeSidedrawer; else spinner">
      <div class="rename-dialog-body">
        <app-sd-selector-a11y
          *ngIf="(vm$ | async).sideDrawerOptions?.length > 0"
          [controller]="sideDrawerController"
          [error]="
            submitted ? ('createrecordforfilesdialog_sidedrawerplaceholder' | dictionary | async) + ' ' + ('globalparams_requirederror' | dictionary | async) : ''
          "
          [options]="(vm$ | async).sideDrawerOptions"
          [placeholder]="
            'createrecordforfilesdialog_sidedrawerplaceholder'
              | dictionary
              | async
          "
          [setAccessibility]="{
            id: 'sideDrawerController',
            ariaLabel:
              'createrecordforfilesdialog_sidedrawerplaceholder'
              | dictionary
              | async
          }"
          (selection)="onSideDrawerSelected($event)" />
        <div class="record-type-subtype">
          <app-record-type-autocomplete-a11y
            (typeSelected)="onRecordTypeSelected($event)"
            (typeSubtypesMapEmitter)="typeSubtypesMap = $event"
            [setController]="typeController"
            [error]="
              submitted
                ? ('createrecordforfilesdialog_typeplaceholder' | dictionary | async) + ' ' + ('globalparams_requirederror' | dictionary | async)
                : ''
            "
            [placeholder]="
              'createrecordforfilesdialog_typeplaceholder' | dictionary | async
            "
            [setActiveSideDrawer]="activeSideDrawer?.id"
            [setTemplate]="RecordTypeAutocompleteA11yTemplates.iconButton"
            [setRecordTypeName]="recordTypeName"
            [emitRecordSubTypes]="false"
            [setSdAccessibility]="{
              id: 'typeController'
            }" />
          <app-sd-input-a11y
            (suffixClicked)="nameController.reset()"
            [controller]="nameController"
            [error]="
              submitted
                ? ('createrecordforfilesdialog_nameplaceholder' | dictionary | async) + ' ' + ('globalparams_requirederror' | dictionary | async)
                : ''
            "
            [placeholder]="
              'createrecordforfilesdialog_nameplaceholder' | dictionary | async
            "
            [sdAccessibilityForSuffix]="{
              role: 'button',
              ariaLabel: 'globalparams_reset' | dictionary | async
            }"
            [setSdAccessibility]="{
              ariaLabel:
                'createrecordforfilesdialog_nameplaceholder'
                | dictionary
                | async,
              id: 'nameController'
            }"
            [suffixSvg]="cdn + ('globalparams_reseticon' | dictionary | async)"
            [template]="SdInputA11yTemplates.inputWithSuffix"
            [tooltip]="
              'createrecordforfilesdialog_nametooltip' | dictionary | async
            "
            [type]="'text'"
            class="personalized-name-input" />
        </div>
      </div>
    </form>
    <h3
      *ngIf="files?.length > 0"
      class="create-record-for-files-section-title"
      tabindex="0">
      {{ 'createrecordforfilesdialog_filelisttitle' | dictionary | async }}
    </h3>
    <div *ngIf="files?.length > 0">
      <app-queue-item
        (itemRemoved)="onRemoveItem(item?.id, item?.upload?.file?.name)"
        *ngFor="let item of files; index as i; trackBy: trackBy"
        [attr.aria-label]="item?.upload?.file?.name"
        [setItem]="item"
        [showProgress]="false"
        [viewFile]="item"
        tabindex="0" />
    </div>
  </ng-container>
  <ng-container dialog-footer>
    <app-sd-flat-button-a11y (buttonClicked)="onClose()" [primary]="false">
      {{ 'globalparams_cancel' | dictionary | async }}</app-sd-flat-button-a11y
    >
    <app-sd-flat-button-a11y
      (buttonClicked)="onConfirm()"
      [inactiveButtonClass]="true"
      [loading]="recordFormForFilesStore.requestInProgress$ | async"
      [primary]="form.valid && !isInvalidList">
      {{ 'globalparams_confirm' | dictionary | async }}</app-sd-flat-button-a11y
    >
  </ng-container>
</app-sd-dialog-template-a11y>

<ng-template #spinner>
  <div class="records-form-view-spinner">
    <app-sd-progress-spinner-a11y [diameter]="20" />
  </div>
</ng-template>
