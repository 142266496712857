import { createSelector } from '@ngrx/store';
import { filesStateSelector } from './files.selectors';
import { selectAll, selectEntities } from './file-history.reducer';
import { FilesHelper } from '../helpers/files.helper';
import { cloudFoldersFilesListSelector } from './cloud-folders-files.selectors';
import { FileType } from '../models/file-type.enum';

export const fileHistoryStateSelector = createSelector(
  filesStateSelector,
  state => state.fileHistory
);

export const fileHistoryListSelector = createSelector(
  fileHistoryStateSelector,
  selectAll
);

export const fileHistoryEntitiesSelector = createSelector(
  fileHistoryStateSelector,
  selectEntities
);

export const fileHistoryByIdSelector = (payload: { id: string }) =>
  createSelector(
    fileHistoryEntitiesSelector,
    entities => entities[`${payload.id}`]
  );

export const fileHistoryByFileName = (payload: { fileName: string }) =>
  createSelector(fileHistoryListSelector, files =>
    files.find(file => file?.fileName === payload.fileName)
  );

export const gettingFilesSelector = createSelector(
  fileHistoryStateSelector,
  state => state.gettingFiles
);

export const hasMoreFilesSelector = createSelector(
  fileHistoryStateSelector,
  state => state.hasMore
);

export const sortByFilesViewSelector = createSelector(
  fileHistoryStateSelector,
  state => state.sortBy
);

export const openFileSelector = createSelector(
  fileHistoryStateSelector,
  state => state.openFile
);

export const totalCountRecordFilesSelector = createSelector(
  fileHistoryStateSelector,
  state => state.totalCount
);

export const fileListSelector = createSelector(
  fileHistoryListSelector,
  cloudFoldersFilesListSelector,
  sortByFilesViewSelector,
  (files, cloudFoldersFiles, sortBy) =>
    FilesHelper.sortFilesByFilesSortOption(
      [...files, ...cloudFoldersFiles],
      sortBy
    )
);

export const cloudStorageFileListSelector = createSelector(
  fileListSelector,
  files =>
    files
      .filter(file => file.fileType === FileType.cloud)
      .map(file => file.cloudStorageFile)
);

export const fileListByRecordIdSelector = (payload: { recordId: string }) =>
  createSelector(fileListSelector, files =>
    files.filter(file => file.recordId === payload.recordId)
  );

export const fileListBySideDrawerIdSelector = (payload: {
  sideDrawerId: string;
}) =>
  createSelector(fileListSelector, files =>
    files.filter(file => file.sideDrawerId === payload.sideDrawerId)
  );
