import * as QueueEffects from 'src/app/queue/store/queue.effects';
import * as FilingCabinetEffects from 'src/app/filing-cabinet/store/filing-cabinet.effect';
import * as reducer from 'src/app/core/store/core.reducer';
import * as ReportsEffects from 'src/app/reports/store/reports.effects';
import * as timelineEffects from 'src/app/timeline/store/timeline.effects';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AccountService } from 'src/app/account/services/account.service';
import { PaginatorService } from 'src/app/core/services/paginator.service';
import { ConfigService } from 'src/app/core/services/config.service';
import { CoreEffects } from 'src/app/core/store/core.effects';
import { DragDropOverlaySnackBarContainer } from 'src/app/files/directives/drag-and-drop/directive/drag-and-drop/drag-drop-overlay-container';
import { FilingCabinetService } from 'src/app/filing-cabinet/services/filing-cabinet.service';
import { filingCabinetFeature } from 'src/app/filing-cabinet/store/filing-cabinet.store';
import { HomeService } from 'src/app/home/services/home.service';
import { IntegrationsService } from 'src/app/integrations/services/integrations.service';
import { LicensesService } from 'src/app/licenses/services/licenses.service';
import { NetworkService } from 'src/app/networks/services/network.service';
import { NetworksCrudEffects } from 'src/app/networks/store/networks-crud/networks-crud.effect';
import { NetworksEffects } from 'src/app/networks/store/networks.effects';
import { PaymentMethodsService } from 'src/app/payment-methods/services/payment-methods.service';
import { PlansService } from 'src/app/plans/services/plans.service';
import { queueFeature } from 'src/app/queue/store/queue.reducer';
import { RecordsService } from 'src/app/records/services/records.service';
import { RelatedRecordsService } from 'src/app/related-records/services/related-records.service';
import { RemindersService } from 'src/app/reminders/services/reminders.service';
import { ReportsService } from 'src/app/reports/services/reports.service';
import { reportsFeature } from 'src/app/reports/store/reports.store';
import { SdDatePickerA11yModule } from 'src/app/shared/sd-date-picker-a11y/sd-date-picker-a11y.module';
import { SidedrawerService } from 'src/app/sidedrawer/services/sidedrawer.service';
import { SidedrawerEffects } from 'src/app/sidedrawer/store/sidedrawer.effects';
import { SignatureService } from 'src/app/signature/services/signature.service';
import { SignatureEffects } from 'src/app/signature/store/signature.effects';

import {
  featureName as timelineFeatureName,
  timelineReducer,
} from 'src/app/timeline/store/timeline.reducer';

import {
  featureName as sdBackDropFeatureName,
  sdBackdropReducer,
} from 'src/app/home/shared/sd-backdrop/store/sd-backdrop.reducer';
import {
  featureName as sideDrawerFeatureName,
  sidedrawerReducer,
} from 'src/app/sidedrawer/store/sidedrawer.reducer';
import {
  featureName as signaturesFeatureName,
  signatureReducer,
} from 'src/app/signature/store/signature.reducer';

import {
  featureName as networksFeatureName,
  networksReducer,
} from 'src/app/networks/store/networks.reducer';
import { MobileCoreRoutingModule } from './mobile-core-routing.module';
import { FilesService } from '../../../files/services/files.service';

@NgModule({
  imports: [
    CommonModule,
    MobileCoreRoutingModule,
    StoreModule.forFeature(reducer.featureName, reducer.coreReducer),
    StoreModule.forFeature(sideDrawerFeatureName, sidedrawerReducer),
    StoreModule.forFeature(networksFeatureName, networksReducer),
    StoreModule.forFeature(queueFeature),
    StoreModule.forFeature(signaturesFeatureName, signatureReducer),
    StoreModule.forFeature(timelineFeatureName, timelineReducer),
    StoreModule.forFeature(sdBackDropFeatureName, sdBackdropReducer),
    StoreModule.forFeature(filingCabinetFeature),
    StoreModule.forFeature(reportsFeature),
    EffectsModule.forFeature([
      CoreEffects,
      QueueEffects,
      timelineEffects,
      NetworksEffects,
      NetworksCrudEffects,
      SidedrawerEffects,
      SignatureEffects,
      FilingCabinetEffects,
      ReportsEffects,
    ]),
    SdDatePickerA11yModule,
  ],
  providers: [
    AccountService,
    PaginatorService,
    ConfigService,
    DragDropOverlaySnackBarContainer,
    FilesService,
    HomeService,
    IntegrationsService,
    LicensesService,
    NetworkService,
    PaymentMethodsService,
    PlansService,
    RecordsService,
    RelatedRecordsService,
    RemindersService,
    SidedrawerService,
    SignatureService,
    FilingCabinetService,
    ReportsService,
  ],
})
export class MobileCoreModule {}
