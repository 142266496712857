import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SdLinkButtonA11yComponent } from './components/sd-link-button-a11y/sd-link-button-a11y.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [SdLinkButtonA11yComponent],
  imports: [CommonModule, RouterModule],
  exports: [SdLinkButtonA11yComponent],
})
export class SdLinkButtonA11yModule {}
