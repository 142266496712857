import { Actions, createEffect, ofType } from '@ngrx/effects';
import { inject } from '@angular/core';
import { FilingCabinetService } from '../services/filing-cabinet.service';
import {
  FilingCabinetActions,
  filingCabinetSideDrawerByIdSelector,
  filingCabinetSideDrawersLoadingSelector,
  filingCabinetSideDrawersWithDataSelector,
} from './filing-cabinet.store';
import {
  concat,
  exhaustMap,
  forkJoin,
  mergeMap,
  of,
  switchMap,
  takeUntil,
} from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ActionsSubject, Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import {
  ErrorLoaded,
  SideDrawerBrandingRequested,
} from '../../core/store/core.actions';
import { activeSideDrawerSelector } from '../../sidedrawer/store/sidedrawer.selector';
import { SidedrawerService } from '../../sidedrawer/services/sidedrawer.service';
import {
  localeDefaultSelector,
  sdLabelsByCobrandIdSelector,
} from '../../dictionary/store/dictionary.selectors';
import { Locale } from '../../dictionary/models/locale.model';
import { FilingCabinetSideDrawer } from '../model/filing-cabinet.model';
import {
  SetActiveSideDrawer,
  SidedrawerActionsTypes,
} from '../../sidedrawer/store/sidedrawer.actions';
import { SimpleFileRequestClearData } from '../../reminders/store/sfr.action';
import { PlanRequestedRemoved } from '../../plans/store/plans-list.actions';
import { RecordsInfoClean } from '../../records/store/records-list.actions';
import { ReminderRemovedAll } from '../../reminders/store/reminders-list.action';
import { TimelineActions } from '../../timeline/store/timeline.actions';
import { SearchRecordsListReset } from '../../search/store/search-record-list.actions';
import { SearchSideDrawerListReset } from '../../search/store/search-sidedrawer-list.actions';
import { SearchFileListReset } from '../../search/store/search-file-list.actions';
import { accountSelector } from '../../account/store/account.selector';
import { AccountBrandCodeAffiliationsUpdateRequested } from '../../account/store/account.actions';
import { NetworkPermanentActions } from '../../networks/store/network-permanent.store';

export const filingCabinetRequested$ = createEffect(
  () => {
    const actions$ = inject(Actions);
    const service = inject(FilingCabinetService);
    const store = inject(Store<AppState>);
    return actions$.pipe(
      ofType(FilingCabinetActions.requested),
      exhaustMap(({ sideDrawerId }) =>
        service.getFilingCabinetBySideDrawerId(sideDrawerId).pipe(
          catchError((httpError: HttpErrorResponse) => {
            store.dispatch(new ErrorLoaded({ httpError, display404: false }));
            return of([]);
          }),
          map(filingCabinets =>
            FilingCabinetActions.loaded({
              filingCabinet: filingCabinets?.[0],
            })
          )
        )
      )
    );
  },
  { functional: true }
);

export const filingCabinetLoaded$ = createEffect(
  () => {
    const actions$ = inject(Actions);
    const store = inject(Store<AppState>);
    return actions$.pipe(
      ofType(FilingCabinetActions.loaded),
      switchMap(({ filingCabinet }) =>
        forkJoin([
          of(filingCabinet),
          store.select(activeSideDrawerSelector).pipe(take(1)),
          store.select(localeDefaultSelector).pipe(take(1)),
        ])
      ),
      tap(([filingCabinet, activeSd, locale]) => {
        store.dispatch(
          new SideDrawerBrandingRequested({
            sdId: activeSd.id,
            localeDefault: Locale.getLocaleId(locale),
          })
        );
        if (filingCabinet?.id) {
          store.dispatch(
            FilingCabinetActions.filingCabinetSideDrawersRequested({
              sideDrawerId: activeSd.id,
              filingCabinetId: filingCabinet.id,
            })
          );
        }
      })
    );
  },
  { functional: true, dispatch: false }
);

export const filingCabinetSideDrawersRequested$ = createEffect(
  () => {
    const actions$ = inject(Actions);
    const service = inject(FilingCabinetService);
    const store = inject(Store<AppState>);
    return actions$.pipe(
      ofType(FilingCabinetActions.filingCabinetSideDrawersRequested),
      exhaustMap(({ sideDrawerId, filingCabinetId }) =>
        service.getFilingCabinetSideDrawers(sideDrawerId, filingCabinetId).pipe(
          catchError((httpError: HttpErrorResponse) => {
            store.dispatch(new ErrorLoaded({ httpError, display404: true }));
            return of([]);
          }),
          map((sideDrawers: FilingCabinetSideDrawer[]) =>
            FilingCabinetActions.filingCabinetSideDrawersLoaded({
              sideDrawers: sideDrawers.map(sd => ({
                ...sd,
                data: null,
                gettingHome: true,
              })),
            })
          )
        )
      )
    );
  },
  { functional: true }
);

export const filingCabinetSideDrawersLoaded$ = createEffect(
  () => {
    const actions$ = inject(Actions);
    const store = inject(Store<AppState>);
    return actions$.pipe(
      ofType(FilingCabinetActions.filingCabinetSideDrawersLoaded),
      switchMap(({ sideDrawers }) =>
        forkJoin([
          of(sideDrawers),
          store.select(activeSideDrawerSelector).pipe(take(1)),
          store.select(localeDefaultSelector).pipe(take(1)),
          store
            .select(
              sdLabelsByCobrandIdSelector({
                cobrandId: store.selectSignal(activeSideDrawerSelector)()
                  ?.cobrandId,
              })
            )
            .pipe(take(1)),
        ])
      ),
      map(([sideDrawers, activeSideDrawer, locale, label]) => {
        store.dispatch(
          FilingCabinetActions.filingCabinetSideDrawersHomeRequested({
            sideDrawers: sideDrawers
              .filter(sd => sd.sidedrawer !== activeSideDrawer.id)
              .map(sd => ({
                sideDrawerId: sd.sidedrawer,
                dataBaseRegion: sd.region,
              })),
            localeDefault: Locale.getLocaleId(locale),
          })
        );
        return FilingCabinetActions.filingCabinetSideDrawerHomeLoaded({
          id: activeSideDrawer.id,
          data: {
            ...activeSideDrawer,
            name: label?.sdLabel ?? activeSideDrawer.name,
            priority: label?.priority ?? 0,
            brandCode: label?.brandCode ?? activeSideDrawer.brandCode,
          },
        });
      })
    );
  },
  {
    functional: true,
  }
);

export const filingCabinetSideDrawerHomeRequested$ = createEffect(
  () => {
    const actions$ = inject(Actions);
    const actionsListener$ = inject(ActionsSubject);
    const service = inject(SidedrawerService);
    const store = inject(Store<AppState>);
    return actions$.pipe(
      ofType(FilingCabinetActions.filingCabinetSideDrawersHomeRequested),
      exhaustMap(({ sideDrawers, localeDefault }) =>
        concat(
          ...sideDrawers.map(({ sideDrawerId }) =>
            service.getSideDrawerHomeLite(sideDrawerId, localeDefault).pipe(
              takeUntil(
                actionsListener$.pipe(
                  ofType(FilingCabinetActions.filingCabinetClearAllData)
                )
              ),
              catchError(() => of(null)),
              switchMap(response =>
                store
                  .select(
                    sdLabelsByCobrandIdSelector({
                      cobrandId: response?.cobrandId,
                    })
                  )
                  .pipe(
                    take(1),
                    tap(label => {
                      if (!response) {
                        store.dispatch(
                          FilingCabinetActions.filingCabinetSideDrawerHomeLoaded(
                            {
                              id: sideDrawerId,
                              data: null,
                            }
                          )
                        );
                        return;
                      }
                      store.dispatch(
                        FilingCabinetActions.filingCabinetSideDrawerHomeLoaded({
                          id: sideDrawerId,
                          data: {
                            ...response,
                            name: label?.sdLabel ?? response.name,
                            priority: label?.priority ?? 0,
                            brandCode: label?.brandCode ?? response.brandCode,
                          },
                        })
                      );
                    })
                  )
              )
            )
          )
        ).pipe(
          takeUntil(
            actionsListener$.pipe(
              ofType(FilingCabinetActions.filingCabinetClearAllData)
            )
          )
        )
      )
    );
  },
  {
    functional: true,
    dispatch: false,
  }
);

export const filingCabinetSideDrawerHomeLoaded$ = createEffect(
  () => {
    const actions$ = inject(Actions);
    const store = inject(Store<AppState>);
    return actions$.pipe(
      ofType(FilingCabinetActions.filingCabinetSideDrawerHomeLoaded),
      mergeMap(() =>
        forkJoin([
          store.select(filingCabinetSideDrawersLoadingSelector).pipe(take(1)),
          store.select(filingCabinetSideDrawersWithDataSelector).pipe(take(1)),
          store.select(accountSelector).pipe(take(1)),
        ])
      ),
      tap(([loading, fcSds, account]) => {
        if (loading) {
          return;
        }
        const brandCodes = fcSds
          .map(sd => sd.data?.brandCode ?? '')
          .filter(
            brandCode =>
              brandCode.length > 0 &&
              !account.brandCodeAffiliations.some(aff => aff === brandCode)
          );
        if (brandCodes.length > 0) {
          store.dispatch(
            new AccountBrandCodeAffiliationsUpdateRequested({ brandCodes })
          );
        }
      })
    );
  },
  {
    functional: true,
    dispatch: false,
  }
);

export const setActiveSideDrawer$ = createEffect(
  () => {
    const actions$ = inject(Actions);
    const store = inject(Store<AppState>);
    return actions$.pipe(
      ofType<SetActiveSideDrawer>(SidedrawerActionsTypes.SetActiveSideDrawer),
      mergeMap(({ payload }) =>
        forkJoin([
          of(payload.data.id),
          store
            .select(
              filingCabinetSideDrawerByIdSelector({
                sideDrawerId: payload.data.id,
              })
            )
            .pipe(take(1)),
        ])
      ),
      tap(([sideDrawerId, filingCabinetSideDrawer]) => {
        if (filingCabinetSideDrawer) {
          return;
        }
        store.dispatch(FilingCabinetActions.filingCabinetClearAllData());
        store.dispatch(
          FilingCabinetActions.requested({ sideDrawerId: sideDrawerId })
        );
      })
    );
  },
  { functional: true, dispatch: false }
);

export const FilingCabinetUpdateRequested$ = createEffect(
  () => {
    const actions$ = inject(Actions);
    const store = inject(Store<AppState>);
    const service = inject(FilingCabinetService);
    return actions$.pipe(
      ofType(FilingCabinetActions.filingCabinetUpdateRequested),
      mergeMap(action =>
        service
          .updateFilingCabinet(
            action.filingCabinetId,
            action.filingCabinetNewName
          )
          .pipe(
            map(result => {
              return FilingCabinetActions.filingCabinetUpdateSuccess({
                filingCabinet: result,
              });
            }),
            catchError(error => {
              store.dispatch(
                FilingCabinetActions.filingCabinetUpdateError({ ...error })
              );
              return of(new ErrorLoaded({ httpError: { ...error } }));
            })
          )
      )
    );
  },
  { functional: true }
);

export const filingCabinetUpdateActiveSideDrawer$ = createEffect(
  () => {
    const actions$ = inject(Actions);
    const store = inject(Store<AppState>);
    return actions$.pipe(
      ofType(FilingCabinetActions.filingCabinetUpdateActiveSideDrawer),
      tap(({ sideDrawerId }) => {
        const activeSd = store.selectSignal(activeSideDrawerSelector)();
        const fcSd = store.selectSignal(
          filingCabinetSideDrawerByIdSelector({
            sideDrawerId,
          })
        )();
        if (fcSd?.data && activeSd.id !== sideDrawerId) {
          store.dispatch(new SetActiveSideDrawer({ data: fcSd.data }));
        }
      })
    );
  },
  { functional: true, dispatch: false }
);

export const filingCabinetClearAllData$ = createEffect(
  () => {
    const actions$ = inject(Actions);
    const store = inject(Store<AppState>);
    return actions$.pipe(
      ofType(FilingCabinetActions.filingCabinetClearAllData),
      tap(() => {
        store.dispatch(new SimpleFileRequestClearData());
        store.dispatch(new PlanRequestedRemoved());
        store.dispatch(new RecordsInfoClean());
        store.dispatch(new ReminderRemovedAll());
        store.dispatch(TimelineActions.clearEvents());
        store.dispatch(new SearchRecordsListReset());
        store.dispatch(new SearchSideDrawerListReset());
        store.dispatch(new SearchFileListReset());
        store.dispatch(
          NetworkPermanentActions.networksRequestedChange({ requested: false })
        );
      })
    );
  },
  { functional: true, dispatch: false }
);
