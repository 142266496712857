import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  CloudFoldersFilesActions,
  CloudFoldersFilesActionsTypes,
} from './cloud-folders-files.actions';
import { FileItem } from '../models/file-item.model';

export interface CloudFoldersFilesState extends EntityState<Partial<FileItem>> {
  gettingCloudFoldersFiles: boolean;
}

export const cloudFoldersFilesAdapter: EntityAdapter<Partial<FileItem>> =
  createEntityAdapter<Partial<FileItem>>({
    selectId: folderFile => folderFile.id,
  });

export const initialCloudFoldersFilesState: CloudFoldersFilesState =
  cloudFoldersFilesAdapter.getInitialState({
    gettingCloudFoldersFiles: false,
  });

export function cloudFoldersFilesReducer(
  state: CloudFoldersFilesState = initialCloudFoldersFilesState,
  action: CloudFoldersFilesActions
): CloudFoldersFilesState {
  switch (action.type) {
    case CloudFoldersFilesActionsTypes.GettingCloudFoldersFilesChange:
      return {
        ...state,
        gettingCloudFoldersFiles: action.payload.state,
      };
    case CloudFoldersFilesActionsTypes.CloudFoldersFilesLoaded:
      return cloudFoldersFilesAdapter.setAll(action.payload.fileItems, state);
    case CloudFoldersFilesActionsTypes.CloudFoldersFilesClear:
      return cloudFoldersFilesAdapter.removeAll(state);
    default:
      return state;
  }
}

export const { selectAll, selectEntities } =
  cloudFoldersFilesAdapter.getSelectors();
