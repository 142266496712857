import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { SdLinkMenuItem } from '../../models/sd-link-menu-item.model';
import { SdColorPalette } from 'src/app/core/models/enums/sd-color-palette-enum';
import { SdLinkMenuLinkType } from '../../models/enums/sd-link-menu-link-type-enum';
import { environment } from '../../../../../environments/environment';
import { SdTooltipButtonA11yComponent } from 'src/app/shared/sd-tooltip-button-a11y/component/sd-tooltip-button-a11y/sd-tooltip-button-a11y.component';

@Component({
  selector: 'app-sd-link-menu-item-a11y',
  templateUrl: './sd-link-menu-item-a11y.component.html',
  styleUrls: ['./sd-link-menu-item-a11y.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SdLinkMenuItemA11yComponent {
  @Input() item: SdLinkMenuItem;
  @Input() iconHeight = 1.25;
  @Input() iconWidth = 1.25;
  @ViewChild(SdTooltipButtonA11yComponent) sdTooltip: SdTooltipButtonA11yComponent;
  sdColorPalette = SdColorPalette;
  sdLinkMenuLinkType = SdLinkMenuLinkType;
  cdn = environment.cdn;
}
