import ConfettiGenerator from 'confetti-js';
import { delay, of, take, tap } from 'rxjs';
import { DisplayValue } from 'src/app/records/models/display-value.model';
import { environment } from '../../../environments/environment';
import { ClientNames } from '../../clients/client-names.enum';

export class UtilsHelper {
  static compareStrings(a: string, b: string): number {
    a = !!a && a.length > 0 ? a : '';
    b = !!b && b.length > 0 ? b : '';
    return a.localeCompare(b);
  }

  static compareNumbers(a: number, b: number): number {
    a = a ? a : 0;
    b = b ? b : 0;
    return a - b;
  }

  static hexToRgb(
    hex,
    alpha: number
  ): { r: number; g: number; b: number; a: number } {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
          a: alpha,
        }
      : null;
  }

  static getListFromEnum(
    enumToTransform
  ): Array<{ key: string; value: string }> {
    const keys = Object.keys(enumToTransform);
    return keys.map(k => {
      return { key: k, value: enumToTransform[k] };
    });
  }

  static mapToArray<T>(map: Map<string | number, T>): T[] {
    return Array.from(map, ([, value]) => ({ ...value }));
  }

  static arrayToMap<T>(key: string, array: T[]): Map<string | number, T> {
    const map = new Map<string | number, T>();
    array.forEach(element => map.set(element[key], element));
    return map;
  }

  static sort<T>(arr: T[], sortBy: string, asc = true): T[] {
    return arr.sort((a, b) =>
      asc ? a[sortBy] - b[sortBy] : b[sortBy] - a[sortBy]
    );
  }

  static removeDuplicatesFromArray<T>(elements: T[], key: string): T[] {
    const aux = new Map<string | number, T>();
    elements.forEach(element => {
      if (!aux.has(element[`${key}`])) {
        aux.set(element[`${key}`], element);
      }
    });
    return this.mapToArray(aux);
  }

  static renderConfetti(): void {
    const target = document.getElementById('confetti');

    if (target) {
      target.style.zIndex = '9999';
      const confetti = new ConfettiGenerator({
        target,
        max: 7000,
        size: 0.5,
        animate: true,
        props: ['square', 'triangle', 'line', 'circle'],
        colors: [
          [165, 104, 246],
          [230, 61, 135],
          [0, 199, 228],
          [253, 214, 126],
        ],
        clock: 120,
        rotate: true,
        start_from_edge: true,
        respawn: false,
      });
      confetti.render();

      of(null)
        .pipe(
          take(1),
          delay(1300),
          tap(() => {
            target.style.zIndex = '-1';
            confetti.clear();
          })
        )
        .subscribe();
    }
  }

  static reverseKeyObjects(object: object): object {
    return Object.keys(object)
      .reverse()
      .reduce((obj, key) => {
        obj[key] = object[key];
        return obj;
      }, {});
  }

  static apiVersion(apiUrl: string, version: number): string {
    return apiUrl.replace('v1', `v${version}`);
  }

  static onClickExternalLink(event: Event, url: string): void {
    event.preventDefault();
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.click();
  }

  static nestedArraysObjectsAreEqual(a: unknown, b: unknown): boolean {
    return JSON.stringify(a) === JSON.stringify(b);
  }

  static getDisplayValueFromLocaleId(
    displayValue: DisplayValue[],
    localeId: string
  ): string {
    return displayValue?.find(value => value.locale === localeId)?.value ?? '';
  }

  static areNullOrUndefinedOrEmpty(value: unknown): boolean {
    return value === null || value === undefined || value === '';
  }

  static isBrowserMobile(): boolean {
    if (environment.appName === ClientNames.mobile) {
      return false;
    }
    const ua = navigator.userAgent;
    if (ua?.includes('Mobi')) {
      return true;
    }
    const mobileRE =
      /(android|bb\d+|meego).+mobile|armv7l|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series[46]0|samsungbrowser.*mobile|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i;
    const notMobileRE = /CrOS/;
    const tabletRE = /android|ipad|playbook|silk/i;
    if (typeof ua !== 'string') return false;
    let result =
      (mobileRE.test(ua) && !notMobileRE.test(ua)) || tabletRE.test(ua);
    if (
      !result &&
      navigator &&
      navigator.maxTouchPoints > 1 &&
      ua.indexOf('Macintosh') !== -1 &&
      ua.indexOf('Safari') !== -1
    ) {
      result = true;
    }
    return result;
  }


}

export interface ISortable<T> {
  order: number;
  data: T;
}
