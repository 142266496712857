import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { Observable, throwError } from 'rxjs';
import {
  AccountFeaturesLoaded,
  LicenseActionsTypes,
  LicensesErrorChange,
  LicensesLoaded,
  LicensesSpinnerChange,
  SubscriptionPlansRequested,
} from '../store/license.actions';
import { catchError, map, take, tap } from 'rxjs/operators';
import { SubscriptionPlan } from '../models/subscription-plan.model';
import { CalculatorPlan } from '../models/calculator-plan.model';
import { SideDrawerHomeRequested } from '../../sidedrawer/store/sidedrawer.actions';
import { License } from '../models/license.model';
import { CreateSidedrawerFromSubscriptionDto } from '../models/create-sidedrawer-from-subscription-dto.model';
import { SubscriptionFeatures } from '../../sidedrawer/models/subscription-features.model';
import { DicPrice } from '../../dictionary/models/dic-price.model';
import { GetCustomerDto } from '../models/get-customer.dto';
import { Price } from '../models/price.model';
import { accountSelector } from '../../account/store/account.selector';
import { SideDrawerNetworkRequestType } from '../../sidedrawer-networks/models/side-drawer-network-request-type.enum';
import { SideDrawerNetworkRequested } from '../../sidedrawer-networks/store/side-drawer-network-list.actions';
import { authHeadersSelector } from 'src/app/auth/store/auth.selectors';

@Injectable()
export class LicensesService {
  private subscriptionApi = environment.subscriptionApi;

  constructor(
    private readonly http: HttpClient,
    private readonly store: Store<AppState>
  ) {}

  public static sortSubscriptionsByDictionaryPriceOrder(
    subscriptions: SubscriptionPlan[],
    prices: DicPrice[]
  ): SubscriptionPlan[] {
    try {
      return subscriptions.sort(
        (a, b) =>
          prices.find(price => price.plan_stripeid === a.plan.id)?.plan_order -
          prices.find(price => price.plan_stripeid === b.plan.id)?.plan_order
      );
    } catch (e) {
      console.warn(e);
      return subscriptions;
    }
  }

  getAllSubscriptionsPlans(): Observable<SubscriptionPlan[]> {
    return this.http.get<SubscriptionPlan[]>(
      this.subscriptionApi + `customers/subscriptions`,
      {
        headers: this.store.selectSignal(authHeadersSelector)(),
      }
    );
  }

  getAllPrices(brandCode: string): Observable<Price[]> {
    return this.http.get<Price[]>(
      this.subscriptionApi + `prices?audience=retail&brandCode=${brandCode}`,
      {
        headers: this.store.selectSignal(authHeadersSelector)(),
      }
    );
  }

  getAccountFeatures(customerId: string): Observable<AccountFeaturesLoaded> {
    return this.http
      .get<SubscriptionFeatures>(
        this.subscriptionApi + `features/customer-id/${customerId}`,
        {
          headers: this.store.selectSignal(authHeadersSelector)(),
        }
      )
      .pipe(
        map(response => {
          this.store.dispatch(new LicensesSpinnerChange({ state: false }));
          return new AccountFeaturesLoaded({ data: response });
        }),
        catchError(error => {
          this.store.dispatch(new LicensesSpinnerChange({ state: false }));
          this.store.dispatch(
            new LicensesErrorChange({
              error: {
                action: LicenseActionsTypes.AccountFeaturesRequested,
                httpError: error,
              },
            })
          );
          return throwError(error);
        })
      );
  }

  getSubscriptionPlanPrice(
    priceId: string,
    quantity: number
  ): Observable<CalculatorPlan> {
    return this.http.get<CalculatorPlan>(
      this.subscriptionApi +
        `prices/price-id/${priceId}/calculator?quantity=${quantity}`,
      {
        headers: this.store.selectSignal(authHeadersSelector)(),
      }
    );
  }

  createSubscription(
    customerId: string,
    planId: string,
    brandCode: string
  ): Observable<string> {
    return this.http
      .post<{ id: string }>(
        this.subscriptionApi + `subscriptions`,
        {
          customer: customerId,
          quantity: 0,
          plan: planId,
          brandCode,
        },
        {
          headers: this.store.selectSignal(authHeadersSelector)(),
        }
      )
      .pipe(map(response => response?.id));
  }

  updateSubscriptionQuantity(
    subscriptionId: string,
    quantity: number,
    increase = true,
    customerId: string,
    requestSubscriptions = true
  ): Observable<boolean> {
    return this.http
      .put(
        this.subscriptionApi +
          `subscriptions/subscription-id/${subscriptionId}/licenses/${
            increase ? 'increase' : 'decrease'
          }`,
        { quantity },
        {
          headers: this.store.selectSignal(authHeadersSelector)(),
        }
      )
      .pipe(
        map(() => {
          if (requestSubscriptions) {
            this.store.dispatch(new SubscriptionPlansRequested());
          }
          return true;
        })
      );
  }

  assignLicenseToSideDrawer(
    subscriptionId: string,
    sidedrawerId: string,
    localeDefault: string
  ): Observable<string> {
    return this.http
      .post<{ id: string }>(
        this.subscriptionApi +
          `subscriptions/subscription-id/${subscriptionId}/licenses`,
        {
          sidedrawerId,
        },
        {
          headers: this.store.selectSignal(authHeadersSelector)(),
        }
      )
      .pipe(
        map(response => {
          this.store.dispatch(
            new SideDrawerHomeRequested({ sdId: sidedrawerId, localeDefault })
          );
          return response?.id;
        })
      );
  }

  deleteSubscription(
    subscriptionId: string,
    customerId: string
  ): Observable<boolean> {
    return this.http
      .delete(
        this.subscriptionApi +
          `subscriptions/subscription-id/${subscriptionId}`,
        {
          headers: this.store.selectSignal(authHeadersSelector)(),
        }
      )
      .pipe(
        map(() => {
          this.store.dispatch(new SubscriptionPlansRequested());
          return true;
        })
      );
  }

  getLicensesFromSubscription(subscriptionId: string): Observable<License[]> {
    return this.http
      .get<License[]>(
        this.subscriptionApi +
          `subscriptions/subscription-id/${subscriptionId}/licenses`,
        {
          headers: this.store.selectSignal(authHeadersSelector)(),
        }
      )
      .pipe(
        tap(response => {
          this.store.dispatch(new LicensesLoaded({ data: response }));
        }),
        catchError(error => {
          this.store.dispatch(
            new LicensesErrorChange({
              error: {
                action: LicenseActionsTypes.LicensesRequested,
                httpError: error,
              },
            })
          );
          return throwError(error);
        })
      );
  }

  deAttachLicense(subscriptionId: string, licenseKey: string): Observable<any> {
    return this.http.request(
      'delete',
      this.subscriptionApi +
        `subscriptions/subscription-id/${subscriptionId}/licenses`,
      {
        headers: this.store.selectSignal(authHeadersSelector)(),
        body: {
          licenseKey,
        },
      }
    );
  }

  attachLicenseToSideDrawer(
    subscriptionId: string,
    sidedrawerId: string
  ): Promise<any> {
    return this.http
      .post(
        this.subscriptionApi +
          `subscriptions/subscription-id/${subscriptionId}/licenses`,
        {
          sidedrawerId,
        },
        {
          headers: this.store.selectSignal(authHeadersSelector)(),
        }
      )
      .toPromise();
  }

  createSideDrawerFromSubscription(
    subscriptionId: string,
    data: CreateSidedrawerFromSubscriptionDto
  ): Observable<boolean> {
    return this.http
      .post<boolean>(
        this.subscriptionApi +
          `subscriptions/subscription-id/${subscriptionId}/sidedrawer`,
        {
          ...data,
        },
        {
          headers: this.store.selectSignal(authHeadersSelector)(),
        }
      )
      .pipe(
        tap(() => {
          this.store
            .pipe(
              select(accountSelector),
              take(1),
              tap(account => {
                this.store.dispatch(
                  new SideDrawerNetworkRequested({
                    requestType:
                      data?.owner?.email === account.username
                        ? SideDrawerNetworkRequestType.owned
                        : SideDrawerNetworkRequestType.shared,
                    name: data?.name,
                  })
                );
              })
            )
            .subscribe();
        })
      );
  }

  getCustomerByCustomerId(customerId: string): Observable<GetCustomerDto> {
    return this.http.get<GetCustomerDto>(
      this.subscriptionApi + `customers/customer-id/${customerId}`,
      {
        headers: this.store.selectSignal(authHeadersSelector)(),
      }
    );
  }
}
