import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { DictionaryResponseDto } from '../models/dictionary-response.dto';
import { Language } from '../models/language.model';
import { GetLanguagesDto } from '../models/get-languages.dto';
import { map } from 'rxjs/operators';

@Injectable()
export class DictionaryService {
  private configApi = environment.configApi;

  constructor(private readonly http: HttpClient) {}

  getLanguages(): Observable<Language[]> {
    return this.http
      .get<GetLanguagesDto>(
        this.configApi + 'content/collections/locales/locale/en-CA'
      )
      .pipe(
        map(response => {
          const languages: Language[] = [];
          response.content.forEach(item => {
            const index = languages.findIndex(
              language => language.languageCode === item.languagecode
            );
            if (index !== -1) {
              languages[index] = {
                ...languages[index],
                locales: [
                  ...languages[index].locales,
                  {
                    localeId: item.localeid,
                    localeName: item.localename,
                    countryName: item.countryname,
                    countryCode: item.countrycode,
                    published: item.published,
                    localeDefault: item.localedefault,
                    languageDefault: item.languagedefault,
                  },
                ],
              };
              return;
            }
            languages.push({
              languageCode: item.languagecode,
              languageName: item.languagename,
              locales: [
                {
                  localeId: item.localeid,
                  localeName: item.localename,
                  countryName: item.countryname,
                  countryCode: item.countrycode,
                  published: item.published,
                  localeDefault: item.localedefault,
                  languageDefault: item.languagedefault,
                },
              ],
            });
          });
          return languages;
        })
      );
  }

  getDictionary(localeId: string): Observable<DictionaryResponseDto> {
    const dictionaryVersion = environment.dictionaryVersion;
    return this.http.get<DictionaryResponseDto>(
      this.configApi +
        `content/dictionaries/${dictionaryVersion}/locale/${localeId}`
    );
  }
}
