import { CoreRoutes } from '../../core/routes/core.routes';

export enum AccountRoutesEnum {
  root = 'account',
  main = '',
  create = 'create',
  tos = 'tos',
  privacyPolicies = 'privacy-policies',
}

export function getAccountRoute(route: AccountRoutesEnum): string {
  return (
    '/' +
    CoreRoutes.root +
    '/' +
    (route.length > 0
      ? AccountRoutesEnum.root + '/' + route
      : AccountRoutesEnum.root)
  );
}
