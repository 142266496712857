<div
  (click)="clicked.emit(true)"
  (keyup.enter)="clicked.emit(true)"
  [attr.aria-label]="text"
  role="button"
  class="integrations-section-item"
  tabindex="0">
  <img [alt]="text" [src]="disabled ? iconDisabled : iconEnabled" />
  <div class="integrations-section-item-text">
    {{ text }}
  </div>
</div>
