export enum StartFlowStepKeys {
  getBrandingStep = 'getBrandingStep',
  getLanguagesStep = 'getLanguagesStep',
  checkAccountStep = 'checkAccountStep',
  getDictionaryWithBrowserLocaleStep = 'getDictionaryWithBrowserLocaleStep',
  checkAccountSettingsStep = 'checkAccountSettingsStep',
  getDictionaryStep = 'getDictionaryStep',
  securityStep = 'securityStep',
  sendInvitationStep = 'sendInvitationStep',
  checkOwnedAndSharedSdStep = 'checkOwnedAndSharedSdStep',
  signUpCreateSdStep = 'signUpCreateSdStep',
  setActiveSdAndRedirectToLandingStep = 'setActiveSdAndRedirectToLandingStep',
  SetActiveSdAndRedirectToOriginStep = 'SetActiveSdAndRedirectToOriginStep',
}
