import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { SdColorPalette } from 'src/app/core/models/enums/sd-color-palette-enum';
import { SdAccessibilitySetting } from 'src/app/core/models/sd-accessibility-model';
import { MenuItemType } from 'src/app/shared/sd-header-a11y/models/menu-item.type.enum';
import { SdLinkMenuLinkType } from 'src/app/shared/sd-link-menu-a11y/models/enums/sd-link-menu-link-type-enum';
import { environment } from 'src/environments/environment';
import { SdLinkMenuItem } from '../../../sd-link-menu-a11y/models/sd-link-menu-item.model';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { SdSlideToggleTemplateA11y } from 'src/app/shared/sd-slide-toggle-a11y/model/sd-slide-toggle-template-a11y.enum';

@Component({
  selector: 'app-sd-content-header-a11y',
  templateUrl: './sd-content-header-a11y.component.html',
  styleUrls: ['./sd-content-header-a11y.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SdContentHeaderA11yComponent {
  @Input() title: string;
  @Input() buttonText: string;
  @Input() buttonCancelText: string;
  @Input() displayButton = true;
  @Input() spinner: boolean;
  @Input() spinnerProgress: number;
  @Input() buttonDisabled: boolean;
  @Input() primary: boolean;
  @Input() useAlertButton = false;
  @Input() sortButton = false;
  @Input() cancelButton = false;
  @Input() showExternalContent = false;
  @Input() inactiveButtonClass = false;
  @Input() showCopyLink = false;
  @Input() toggle = false;
  @Input() checked = false;
  @Input() toggleLabel = '';
  @Input() customMenuData: SdLinkMenuItem[];
  @Input() sdAccessibilityForToggle?: SdAccessibilitySetting;
  @Output() buttonClicked = new EventEmitter<boolean>();
  @Output() buttonCancelClicked = new EventEmitter<boolean>();
  @Output() sortButtonClicked = new EventEmitter<boolean>();
  @Output() copyLinkClicked = new EventEmitter<SdLinkMenuItem>();
  @Output() toggleChange = new EventEmitter<MatSlideToggleChange>();
  isSorted = false;
  sdAccessibility: SdAccessibilitySetting;
  sdAccessibilityTitle: SdAccessibilitySetting;
  cdn = environment.cdn;
  sdColorPalette = SdColorPalette;
  progressSpinnerMode: ProgressSpinnerMode = 'determinate';
  sdLinkMenuLinkType = SdLinkMenuLinkType;
  menuItemType = MenuItemType;
  sdSlideToggleTemplateA11y = SdSlideToggleTemplateA11y;

  @Input() set setAccessibilityForButton(value: SdAccessibilitySetting) {
    this.sdAccessibility = value;
  }

  @Input() set setAccessibilityForTitle(value: SdAccessibilitySetting) {
    this.sdAccessibilityTitle = value;
  }
}
