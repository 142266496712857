import { Action } from '@ngrx/store';
import { Settings } from '../models/settings.model';
import { Account } from '../models/account.model';
import { Team } from '../models/team.model';
import { AccountForm } from '../models/account-form.model';
import { HttpErrorResponse } from '@angular/common/http';
import { Locale } from '../../dictionary/models/locale.model';

export enum AccountActionsTypes {
  AccountRequested = '[] Account Requested',
  AccountLoaded = '[Account Service] Account Requested',
  AccountUpdated = '[Account Service] Account Loaded',
  SettingsRequested = '[] Settings Requested',
  SettingsLoaded = '[Account Service] Settings Loaded',
  TeamsLoaded = '[Account Service] Teams Loaded',
  CreateAccountRequested = '[Account create view] Create Account Requested',
  CreateAccountSpinnerChanged = '[Account effect] Create Account Spinner Changed',
  CreateAccountFails = '[Account effect] Create Account Fails',
  CreateAccountSucceed = '[Account effect] Create Account Succeed',
  AccountBrandCodeAffiliationsUpdateRequested = '[Account Service] Account Brand Code Affiliations Update Requested',
  EnrollMFASilentlyRequested = '[Start Flow] Enroll MFA Silently Requested',
  CheckUserNameAndRefreshToken = '[Account Effect] Check User Name And Refresh Token',
  ChangeLanguageRequested = '[Language Selector] Change Language Requested',
}

export class AccountRequested implements Action {
  readonly type = AccountActionsTypes.AccountRequested;

  constructor(public payload: { openId: string }) {}
}

export class AccountLoaded implements Action {
  readonly type = AccountActionsTypes.AccountLoaded;

  constructor(public payload: { data: Account }) {}
}

export class AccountUpdated implements Action {
  readonly type = AccountActionsTypes.AccountUpdated;

  constructor(public payload: { data: Account }) {}
}

export class SettingsRequested implements Action {
  readonly type = AccountActionsTypes.SettingsRequested;

  constructor(public payload: { accountId: string }) {}
}

export class SettingsLoaded implements Action {
  readonly type = AccountActionsTypes.SettingsLoaded;

  constructor(public payload: { data: Settings }) {}
}

export class TeamsLoaded implements Action {
  readonly type = AccountActionsTypes.TeamsLoaded;

  constructor(public payload: { data: Team[] }) {}
}

export class CreateAccountRequested implements Action {
  readonly type = AccountActionsTypes.CreateAccountRequested;

  constructor(
    public payload: {
      form: AccountForm;
      createAccountWithInvitationCodeFails?: boolean;
    }
  ) {}
}

export class CreateAccountSpinnerChanged implements Action {
  readonly type = AccountActionsTypes.CreateAccountSpinnerChanged;

  constructor(public payload: { state: boolean }) {}
}

export class CreateAccountFails implements Action {
  readonly type = AccountActionsTypes.CreateAccountFails;

  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class CreateAccountSucceed implements Action {
  readonly type = AccountActionsTypes.CreateAccountSucceed;

  constructor(
    public payload: {
      account: Account;
      invitationCode?: string;
      createAccountWithInvitationCodeFails?: boolean;
    }
  ) {}
}

export class AccountBrandCodeAffiliationsUpdateRequested implements Action {
  readonly type =
    AccountActionsTypes.AccountBrandCodeAffiliationsUpdateRequested;

  constructor(public payload: { brandCodes: string[] }) {}
}

export class EnrollMFASilentlyRequested implements Action {
  readonly type = AccountActionsTypes.EnrollMFASilentlyRequested;
}

export class CheckUserNameAndRefreshToken implements Action {
  readonly type = AccountActionsTypes.CheckUserNameAndRefreshToken;

  constructor(
    public payload: {
      account: Account;
      invitationCode?: string;
      createAccountWithInvitationCodeFails?: boolean;
    }
  ) {}
}
export class ChangeLanguageRequested implements Action {
  readonly type = AccountActionsTypes.ChangeLanguageRequested;

  constructor(
    public payload: {
      locale: Locale;
    }
  ) {}
}

export type AccountActions =
  | AccountRequested
  | AccountLoaded
  | AccountUpdated
  | SettingsRequested
  | SettingsLoaded
  | TeamsLoaded
  | CreateAccountRequested
  | CreateAccountSpinnerChanged
  | CreateAccountFails
  | CreateAccountSucceed
  | AccountBrandCodeAffiliationsUpdateRequested
  | EnrollMFASilentlyRequested
  | CheckUserNameAndRefreshToken
  | ChangeLanguageRequested;
