import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HelpVideos } from '../../../../../dictionary/models/help-videos.model';
import { NgForOf, NgIf } from '@angular/common';
import { VideoActiveItemComponent } from '../video-active-item/video-active-item.component';
import { VideoGridItemComponent } from '../video-grid-item/video-grid-item.component';

@Component({
  selector: 'app-help-support-videos-dialog-content',
  template: `
    <div class="videos-container" *ngIf="helpVideosList?.length > 0">
      <app-video-active-item *ngIf="!grid" [video]="activeVideo" />
      <div
        class="videos-carrousel"
        [class.videos-carrousel-no-grid-mode]="!grid">
        <app-video-grid-item
          *ngFor="let video of helpVideosList"
          (click)="changeGrid(video)"
          [name]="video?.name"
          [isActive]="activeVideo?.videoId === video?.videoId && !grid" />
      </div>
    </div>
  `,
  styleUrls: ['./help-support-videos-dialog-content.component.scss'],
  standalone: true,
  imports: [NgIf, VideoActiveItemComponent, VideoGridItemComponent, NgForOf],
})
export class HelpSupportVideosDialogContentComponent {
  @Input() helpVideosList: HelpVideos[];
  @Input() grid: boolean;
  @Input() activeVideo: HelpVideos;
  @Output() gridStatus = new EventEmitter<boolean>();

  changeGrid(video: HelpVideos): void {
    this.grid = false;
    this.activeVideo = video;
    this.gridStatus.emit(this.grid);
  }
}
