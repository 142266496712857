import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ReminderRequested } from '../models/reminders-requested.model';
import { Reminders } from '../models/reminders.model';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { authHeadersSelector } from 'src/app/auth/store/auth.selectors';

@Injectable()
export class RemindersService {
  private networksApi = environment.networksApi;

  constructor(
    private readonly http: HttpClient,
    private readonly store: Store<AppState>
  ) {}

  getReminders(resource: string): Observable<ReminderRequested[]> {
    const params = { resource };
    return this.http.get<ReminderRequested[]>(this.networksApi + 'reminders', {
      headers: this.store.selectSignal(authHeadersSelector)(),
      params,
    });
  }

  getOneReminder(reminderId: string): Observable<ReminderRequested> {
    const params = { reminderId };
    return this.http.get<ReminderRequested>(
      this.networksApi + `reminders/reminder-id/${reminderId}`,
      {
        headers: this.store.selectSignal(authHeadersSelector)(),
        params,
      }
    );
  }

  createReminder(reminder: Reminders): Observable<ReminderRequested> {
    return this.http.post<ReminderRequested>(
      this.networksApi + 'reminders',
      reminder,
      { headers: this.store.selectSignal(authHeadersSelector)() }
    );
  }

  deleteReminder(id: string): Observable<ReminderRequested> {
    return this.http.delete<ReminderRequested>(
      this.networksApi + `reminders/reminder-id/${id}`,
      { headers: this.store.selectSignal(authHeadersSelector)() }
    );
  }

  updateReminderStatus(
    id: string,
    status: string
  ): Observable<ReminderRequested> {
    return this.http.put<ReminderRequested>(
      this.networksApi + `reminders/reminder-id/${id}/status/${status}`,
      null,
      { headers: this.store.selectSignal(authHeadersSelector)() }
    );
  }

  updateReminder(
    id: string,
    reminder: Reminders
  ): Observable<ReminderRequested> {
    return this.http.put<ReminderRequested>(
      this.networksApi + `reminders/reminder-id/${id}`,
      reminder,
      { headers: this.store.selectSignal(authHeadersSelector)() }
    );
  }
}
