import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Input,
  OnDestroy,
  OnInit,
  Signal,
  computed,
  signal,
} from '@angular/core';
import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { DictionaryPipeModule } from 'src/app/dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { DragAndDropModule } from 'src/app/files/directives/drag-and-drop/drag-and-drop.module';
import { SdLinkButtonA11yModule } from 'src/app/shared/sd-link-button-a11y/sd-link-button-a11y.module';
import { SdProgressSpinnerA11yModule } from 'src/app/shared/sd-progress-spinner-a11y/sd-progress-spinner-a11y/sd-progress-spinner-a11y.module';
import { SdSortButtonA11yComponent } from 'src/app/shared/sd-sort-button-a11y/components/sd-sort-button-a11y/sd-sort-button-a11y.component';
import { SdTooltipButtonA11yComponent } from 'src/app/shared/sd-tooltip-button-a11y/component/sd-tooltip-button-a11y/sd-tooltip-button-a11y.component';
import { SdUploadButtonA11yComponent } from 'src/app/shared/sd-upload-button-a11y/sd-upload-button-a11y.component';
import { SdEmptyTemplateComponent } from 'src/app/shared/templates/sd-empty-template/components/sd-empty-template/sd-empty-template.component';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  EMPTY,
  Observable,
  Subject,
  debounceTime,
  map,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { QueueHelper } from 'src/app/queue/helpers/queue.helper';
import { QueueActions } from 'src/app/queue/store/queue.actions';
import {
  activeRecordSelector,
  filesSectionEnabledSelector,
  userCanOperateOnThisRecordSelector,
} from 'src/app/records/store/records-list.selectors';
import { RecordFormViewStore } from 'src/app/records/views/record-form-view/record-form-view.store';
import { AppState } from 'src/app/reducers';
import { SdLinkButtonA11yTemplates } from 'src/app/shared/sd-link-button-a11y/model/sd-link-button-a11y.enum';
import { activeSideDrawerSelector } from 'src/app/sidedrawer/store/sidedrawer.selector';
import { environment } from 'src/environments/environment';
import { FilesService } from '../../services/files.service';
import { CloudFoldersFilesRequested } from '../../store/cloud-folders-files.actions';
import { gettingCloudFoldersFilesSelector } from '../../store/cloud-folders-files.selectors';
import {
  FileHistoryClear,
  FileHistoryDeleted,
  FileHistoryLoaded,
  FileHistoryNextPageRequested,
  FileHistoryRequested,
  TotalCountFileHistoryUpdate,
} from '../../store/file-history.actions';
import {
  fileListByRecordIdSelector,
  gettingFilesSelector,
  hasMoreFilesSelector,
  totalCountRecordFilesSelector,
} from '../../store/file-history.selector';
import { Record } from 'src/app/records/models/record.model';
import { MatDialog } from '@angular/material/dialog';
import { FileHistorySectionItemComponent } from '../file-history-section-item/file-history-section-item.component';
import { FileHistorySortingDialogComponent } from '../file-history-sorting-dialog/file-history-sorting-dialog.component';
import { SdIconButtonA11yComponent } from 'src/app/shared/sd-icon-button-a11y/components/sd-icon-button-a11y/sd-icon-button-a11y.component';
import { SdCheckboxA11yModule } from 'src/app/shared/sd-checkbox-a11y/sd-checkbox-a11y.module';
import { FileItem } from '../../models/file-item.model';
import { FileHistoryManageStore } from '../../store/file-history-manage.store';
import {
  FileItemSpinnerUpdate,
  FileItemUpdate,
  NoPermissionsError,
} from '../../store/file-item.actions';
import { SdQueueItem } from 'src/app/queue/models/sd-queue-item.model';
import { FilesHelper } from '../../helpers/files.helper';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorLoaded } from 'src/app/core/store/core.actions';
import { fileItemEntitySelector } from '../../store/file-item.selectors';
import { saveAs as importedSaveAs } from 'file-saver';
import { FileType } from '../../models/file-type.enum';
import { SdFlatButtonA11yModule } from 'src/app/shared/sd-flat-button-a11y/sd-flat-button-a11y.module';
import { Actions, ofType } from '@ngrx/effects';
import { TypedAction } from '@ngrx/store/src/models';
import { SdSvgA11yModule } from 'src/app/shared/sd-svg-a11y/sd-svg-a11y.module';
import { SdColorPalette } from 'src/app/core/models/enums/sd-color-palette-enum';
import { animate, style, transition, trigger } from '@angular/animations';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ClientNames } from 'src/app/clients/client-names.enum';
import { MobileFilesHelper } from 'src/app/clients/mobile/files/helpers/mobile-files.helper';

@Component({
  selector: 'app-file-history-section',
  template: `<div
    (fileDropped)="
      forceDisableDragAndDrop === true ? null : onFilesLoaded($event)
    "
    *ngIf="filesSectionEnabledFromSignal()"
    [disableDragAndDrop]="
      (viewOnly && userCanOperate() === false) || forceDisableDragAndDrop
    "
    [id]="idForDragAndDrop"
    appDragAndDrop
    class="file-history-section-container">
    <div
      *ngIf="userCanOperate()"
      [id]="idForDragAndDrop"
      class="file-history-section-overlay hidden">
      <div class="file-history-section-overlay-body"></div>
    </div>
    <div class="section">
      <div class="left-column">
        <div
          [attr.aria-label]="
            ('filehistorysection_title' | dictionary | async) +
            ' ' +
            ('filehistorysection_tooltip' | dictionary | async)
          "
          class="file-history-section-header-title">
          {{ 'filehistorysection_title' | dictionary | async }}
          <span *ngIf="!!totalCountFilesSelector()">{{
            '(' + totalCountFilesSelector() + ')'
          }}</span>
        </div>
        <app-sd-tooltip-button-a11y
          [tooltip]="'filehistorysection_tooltip' | dictionary | async" />

        <div *ngIf="fileList()?.length > 0" class="app-sd-select-button-a11y">
          <app-sd-icon-button-a11y
            [ngClass]="{
              openedState: showHeaderFilesOptions()
            }"
            [openedState]="showHeaderFilesOptions()"
            [viewOnly]="disableHeaderOptions()"
            [setSdAccessibility]="{
              ariaLabel:
                'filehistorysection_headerfileoptionstooltip'
                | dictionary
                | async,
              role: 'button'
            }"
            (btnClicked)="
              showHeaderFilesOptions()
                ? hideHeaderFileOptions()
                : showHeaderFilesOptions.set(true)
            "
            [iconColor]="'var(--primaryColor)'"
            [squareBackground]="true"
            [iconHeight]="0.9"
            [iconWidth]="0.9"
            [tooltip]="
              'filehistorysection_headerfileoptionstooltip' | dictionary | async
            "
            [icon]="
              cdn +
              ('filehistorysection_headerfileoptionsicon' | dictionary | async)
            ">
          </app-sd-icon-button-a11y>
        </div>
        <div class="app-sd-sort-button-a11y">
          <app-sd-sort-button-a11y
            (sortButtonClicked)="onSort()"
            *ngIf="fileList()?.length > 0"
            [setSdAccessibility]="{
              ariaLabel: 'globalparams_sorted' | dictionary | async
            }" />
        </div>
      </div>
      <div class="right-column">
        <div *ngIf="fileList()?.length > 0" class="app-sd-select-button-a11y">
          <app-sd-icon-button-a11y
            [ngClass]="{
              openedState: showHeaderFilesOptions()
            }"
            [openedState]="showHeaderFilesOptions()"
            [viewOnly]="disableHeaderOptions()"
            [setSdAccessibility]="{
              ariaLabel:
                'filehistorysection_headerfileoptions' | dictionary | async,
              role: 'button'
            }"
            (btnClicked)="
              showHeaderFilesOptions()
                ? hideHeaderFileOptions()
                : showHeaderFilesOptions.set(true)
            "
            [iconColor]="'var(--primaryColor)'"
            [squareBackground]="true"
            [iconHeight]="0.9"
            [squareBackground]="true"
            [iconWidth]="0.9"
            [tooltip]="
              'filehistorysection_headerfileoptionstooltip' | dictionary | async
            "
            [icon]="
              cdn +
              ('filehistorysection_headerfileoptionsicon' | dictionary | async)
            ">
          </app-sd-icon-button-a11y>
        </div>
        <div class="app-sd-sort-button-a11y">
          <app-sd-sort-button-a11y
            (sortButtonClicked)="onSort()"
            *ngIf="fileList()?.length > 0"
            [setSdAccessibility]="{
              ariaLabel: 'globalparams_sorted' | dictionary | async
            }" />
        </div>
        <div
          *ngIf="showActionButtons && userCanOperate()"
          class="record-form-files-tab-buttons-section-main-button">
          <app-sd-upload-button-a11y
            (filesSelected)="onFilesLoaded($event)"
            *ngIf="!viewOnly"
            [buttonText]="
              'filehistorysection_anyfileupload' | dictionary | async
            "
            [icon]="
              cdn +
              ('filehistorysection_anyfileuploadicon' | dictionary | async)
            " />
        </div>
      </div>
    </div>
    <div class="file-history-section-content">
      <div
        *ngIf="
          (fileList() === null || fileList().length === 0) &&
          spinner() === false
        ">
        <app-sd-empty-template
          [emptyStateInline]="true"
          [emptyStateString]="
            'filehistorysection_emptystate' | dictionary | async
          " />
      </div>
      <div
        class="file-history-header-files-container"
        [ngClass]="{
          disableSection: disableHeaderOptions() === true
        }"
        *ngIf="showHeaderFilesOptions()"
        @myInsertRemoveTrigger>
        <div class="file-history-header-files-column1">
          <app-sd-checkbox-a11y
            class="file-history-header-files-option-checkbox"
            (valueChange)="selectedAllOption.set($event)"
            [value]="selectedAllOption()">
            {{ 'globalparams_selectall' | dictionary | async }}
          </app-sd-checkbox-a11y>

          <div
            *ngIf="numberOfFilesSelected() > 0"
            class="file-history-header-files-option-files-selected">
            {{
              numberOfFilesSelected() +
                ' ' +
                ('filehistorysection_filesselected' | dictionary | async)
            }}
          </div>

          <div class="file-history-header-files-option-download hide-mobile">
            <app-sd-svg-a11y
              (click)="downloadAllSelectedFiles()"
              [color]="sdColorPalette.primaryAccentColor"
              [src]="
                cdn +
                ('filehistorysection_downloadselectedfilesicon'
                  | dictionary
                  | async)
              "
              [width]="1.1"
              [height]="1.1"
              [setSdAccessibility]="{
                tabIndex: -1
              }">
            </app-sd-svg-a11y>
            <div
              (click)="downloadAllSelectedFiles()"
              (keyup)="downloadAllSelectedFiles()">
              {{
                'filehistorysection_downloadselectedfiles' | dictionary | async
              }}
            </div>
          </div>
          <div class="file-history-header-files-option-download hide-desktop">
            <app-sd-svg-a11y
              [tooltips]="
                'filehistorysection_downloadselectedfilestooltip'
                  | dictionary
                  | async
              "
              (click)="downloadAllSelectedFiles()"
              [color]="sdColorPalette.primaryAccentColor"
              [src]="
                cdn +
                ('filehistorysection_downloadselectedfilesicon'
                  | dictionary
                  | async)
              "
              [width]="1.1"
              [height]="1.1"
              [setSdAccessibility]="{
                tabIndex: -1
              }">
            </app-sd-svg-a11y>
          </div>

          <div class="file-history-header-files-option-download hide-mobile">
            <app-sd-svg-a11y
              (click)="deleteAllSelectedFiles()"
              [color]="sdColorPalette.primaryAccentColor"
              [src]="cdn + ('globalparams_deleteicon' | dictionary | async)"
              [width]="1.1"
              [height]="1.1"
              [setSdAccessibility]="{
                tabIndex: -1
              }">
            </app-sd-svg-a11y>
            <div
              (click)="deleteAllSelectedFiles()"
              (keyup)="deleteAllSelectedFiles()">
              {{
                'filehistorysection_deleteselectedfiles' | dictionary | async
              }}
            </div>
          </div>
          <div class="file-history-header-files-option-download hide-desktop">
            <app-sd-svg-a11y
              [tooltips]="
                'filehistorysection_deleteselectedfilestooltip'
                  | dictionary
                  | async
              "
              (click)="deleteAllSelectedFiles()"
              [color]="sdColorPalette.primaryAccentColor"
              [src]="cdn + ('globalparams_deleteicon' | dictionary | async)"
              [width]="1.1"
              [height]="1.1"
              [setSdAccessibility]="{
                tabIndex: -1
              }">
            </app-sd-svg-a11y>
          </div>
        </div>
        <div class="file-history-header-files-option-close">
          <app-sd-svg-a11y
            (click)="hideHeaderFileOptions()"
            [tooltips]="
              'filehistorysection_headerfilesoptionsclosetooltip'
                | dictionary
                | async
            "
            [color]="sdColorPalette.primaryAccentColor"
            [src]="
              cdn +
              ('filehistorysection_headerfilesoptionscloseicon'
                | dictionary
                | async)
            "
            [width]="1.1"
            [height]="1.1"
            [setSdAccessibility]="{
              tabIndex: -1
            }">
          </app-sd-svg-a11y>
        </div>
      </div>

      <app-file-history-section-item
        [ngClass]="{
          disableSection: disableHeaderOptions() === true
        }"
        *ngFor="
          let file of !showAll ? fileListShort() : fileList();
          trackBy: trackBy
        "
        [fileItem]="file"
        [showCheckbox]="showHeaderFilesOptions()"
        [viewOnly]="viewOnly"
        (valueChangeForOption)="onValueChangeForOption($event, file)" />

      <div
        *ngIf="fileList()?.length > 3 && !showAll"
        class="file-history-section-view-all">
        <app-sd-link-button-a11y
          (click)="onViewAll($event)"
          (keydown.enter)="onViewAll($event)"
          [ariaLabel]="'filehistorysection_viewallfiles' | dictionary | async"
          [href]="'#'"
          [template]="sdLinkButtonA11yEnum.linkNative">
          <span class="file-history-section-view-all-link-content" linkNative>
            {{ 'filehistorysection_viewallfiles' | dictionary | async }}
          </span>
        </app-sd-link-button-a11y>
      </div>
      <div
        *ngIf="filesSectionHasMoreFromSignal() && showAll"
        [ngClass]="{
          disableSection: disableHeaderOptions() === true
        }"
        class="file-history-section-view-all">
        <app-sd-link-button-a11y
          (click)="onLoadMore($event)"
          (keydown.enter)="onLoadMore($event)"
          [ariaLabel]="'globalparams_loadmore' | dictionary | async"
          [href]="'#'"
          [template]="sdLinkButtonA11yEnum.linkNative">
          <span class="file-history-section-view-all-link-content" linkNative>
            {{ 'globalparams_loadmore' | dictionary | async }}
          </span>
        </app-sd-link-button-a11y>
      </div>
    </div>
    <div *ngIf="spinner()" class="file-history-section-spinner">
      <app-sd-progress-spinner-a11y />
    </div>
  </div> `,
  styleUrls: ['./file-history-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FileHistorySectionItemComponent,
    SdTooltipButtonA11yComponent,
    DictionaryPipeModule,
    SdLinkButtonA11yModule,
    SdProgressSpinnerA11yModule,
    SdUploadButtonA11yComponent,
    DragAndDropModule,
    SdSortButtonA11yComponent,
    SdEmptyTemplateComponent,
    NgIf,
    AsyncPipe,
    NgFor,
    DictionaryPipeModule,
    SdIconButtonA11yComponent,
    SdCheckboxA11yModule,
    SdFlatButtonA11yModule,
    NgClass,
    SdSvgA11yModule,
  ],
  animations: [
    trigger('myInsertRemoveTrigger', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [animate('100ms', style({ opacity: 0 }))]),
    ]),
  ],
  providers: [RecordFormViewStore, FileHistoryManageStore],
})
export class FileHistorySectionComponent implements OnInit, OnDestroy {
  @Input() activeRecordId: string;
  @Input() viewOnly = false;
  @Input() showActionButtons = false;
  @Input() idForDragAndDrop: string;
  @Input() showAll = false;

  @Input() forceDisableDragAndDrop = false;

  filesSectionHasMoreFromSignal = computed(() => {
    return this.store.selectSignal(hasMoreFilesSelector)();
  });
  filesSectionEnabledFromSignal = computed(() => {
    return this.store.selectSignal(filesSectionEnabledSelector)();
  });
  spinner = computed(() => {
    const gettingFiles = this.store.selectSignal(gettingFilesSelector)();
    const gettingCloudFoldersFiles = this.store.selectSignal(
      gettingCloudFoldersFilesSelector
    )();
    return gettingFiles || gettingCloudFoldersFiles;
  });
  sdLinkButtonA11yEnum = SdLinkButtonA11yTemplates;
  cdn = environment.cdn;
  userCanOperate = this.store.selectSignal(userCanOperateOnThisRecordSelector);
  showHeaderFilesOptions = signal(false);
  selectedAllOption = signal(false);
  onValueChangeForOptionSignal = signal(false);
  fileList: Signal<FileItem[]> = computed(() => {
    const selectedAllOption = this.selectedAllOption();
    const activeRecord = this.store.selectSignal(activeRecordSelector)();
    const fileListByRecordId = JSON.parse(
      JSON.stringify(
        this.store.selectSignal(
          fileListByRecordIdSelector({ recordId: activeRecord?.id })
        )()
      )
    );
    if (selectedAllOption !== null) {
      fileListByRecordId.forEach(file => {
        file['selected'] = selectedAllOption;
      });
    }
    if (selectedAllOption === null) {
      fileListByRecordId.forEach((file, index) => {
        file['selected'] =
          this.filesListWithSelectedModel?.length > 0
            ? this.filesListWithSelectedModel[index].selected
            : false;
      });
    }
    this.filesListWithSelectedModel = JSON.parse(
      JSON.stringify(fileListByRecordId)
    );
    return JSON.parse(JSON.stringify(fileListByRecordId));
  });

  fileListShort: Signal<FileItem[]> = computed(() => {
    return this.fileList().slice(0, 3);
  });

  numberOfFilesSelected: Signal<number> = computed(() => {
    this.onValueChangeForOptionSignal();
    if (this.selectedAllOption() === true) {
      const filesTotalCount = this.store.selectSignal(
        totalCountRecordFilesSelector
      )();
      return filesTotalCount;
    }
    return this.fileList().filter(file => file?.selected === true).length;
  });
  filesListWithSelectedModel: FileItem[] = [];
  sdColorPalette = SdColorPalette;
  onClickHandlerForDownloadSelectedFiles$ = new Subject<void>();
  onClickHandlerForDeleteSelectedFiles$ = new Subject<void>();
  recordId: string;
  disableHeaderOptions = signal(false);
  totalCountFilesSelector = this.store.selectSignal(
    totalCountRecordFilesSelector
  );

  constructor(
    private readonly store: Store<AppState>,
    private readonly route: ActivatedRoute,
    private readonly filesService: FilesService,
    private readonly recordFormViewStore: RecordFormViewStore,
    private readonly fileHistoryManageStore: FileHistoryManageStore,
    private readonly dialog: MatDialog,
    private readonly actions: Actions,
    private readonly destroyRef: DestroyRef
  ) {
    this.onClickHandlerForDownloadSelectedFiles$
      .pipe(
        debounceTime(300),
        takeUntilDestroyed(this.destroyRef),
        switchMap(() => of(this.downloadAllSelectedFilesHandler()))
      )
      .subscribe();

    this.onClickHandlerForDeleteSelectedFiles$
      .pipe(
        debounceTime(300),
        takeUntilDestroyed(this.destroyRef),
        switchMap(() => of(this.deleteAllSelectedFilesHandler()))
      )
      .subscribe();
  }

  ngOnInit(): void {
    const activeSD = this.store.selectSignal(activeSideDrawerSelector)();
    const params = this.route.snapshot.params;
    this.recordId = params?.recordId ?? this.activeRecordId;
    if (!this.recordId) {
      return;
    }
    if (this.recordId) {
      this.getRecordFiles(activeSD, this.recordId);
    }
  }

  private getRecordFiles(activeSideDrawer, recordId): void {
    this.store.dispatch(
      new FileHistoryRequested({
        sideDrawerId: activeSideDrawer?.id,
        recordId: recordId,
      })
    );
    this.store.dispatch(
      new CloudFoldersFilesRequested({
        sideDrawerId: activeSideDrawer?.id,
        recordId: recordId,
      })
    );
  }

  ngOnDestroy(): void {
    this.store.dispatch(new FileHistoryClear());
  }

  onViewAll(event): void {
    event.preventDefault();
    this.showAll = true;
  }

  onLoadMore(event): void {
    event.preventDefault();
    this.store.dispatch(new FileHistoryNextPageRequested());
  }

  onFilesLoaded(fileList: FileList): void {
    if (this.viewOnly) {
      return;
    }
    this.recordFormViewStore.getOrCreateRecord((record: Record) => {
      QueueHelper.generateQueueItems$(
        fileList,
        record,
        this.store,
        this.filesService
      )
        .pipe(
          take(1),
          tap(queueItems => {
            queueItems.forEach(item =>
              this.store.dispatch(QueueActions.itemAdded({ item }))
            );
            this.store.dispatch(QueueActions.startProcess());
          })
        )
        .subscribe();
    });
  }

  onSort(): void {
    this.dialog
      .open(FileHistorySortingDialogComponent, { autoFocus: false })
      .afterClosed()
      .subscribe();
  }

  onValueChangeForOption(event: boolean, fileParam: FileItem): void {
    this.showHeaderFilesOptions.set(true);
    this.onValueChangeForOptionSignal.set(false);
    this.selectedAllOption.set(null);
    this.fileList().forEach(file => {
      if (file.id === fileParam.id) {
        file['selected'] = event;
        this.onValueChangeForOptionSignal.set(true);
        return;
      }
    });
  }

  downloadAllSelectedFiles(): void {
    this.onClickHandlerForDownloadSelectedFiles$.next();
  }

  deleteAllSelectedFiles(): void {
    this.onClickHandlerForDeleteSelectedFiles$.next();
  }

  downloadAllSelectedFilesHandler() {
    if (this.numberOfFilesSelected() <= 0) {
      return;
    }
    this.disableHeaderOptions.set(true);
    if (this.selectedAllOption() === true) {
      this.onValueChangeForOptionSignal.set(true);
      this.fileHistoryManageStore.clearFilesFromStore();
      this.fileHistoryManageStore.getAllFiles({
        sideDrawerId: this.store.selectSignal(activeSideDrawerSelector)().id,
        recordId: this.recordId,
        callback: dataFileResponse => {
          // TODO check error
          this.store.dispatch(
            new FileHistoryLoaded({ data: dataFileResponse })
          );
          this.handleLoadedFiles();
        },
      });
      return;
    }
    const files = this.fileList().filter(file => file.selected);
    const arrayOfSdQueueItemFiles = this.generateQueueItems(files);
    this.handleQueueProcess(arrayOfSdQueueItemFiles);
  }

  deleteAllSelectedFilesHandler() {
    if (this.numberOfFilesSelected() <= 0) {
      return;
    }

    this.fileHistoryManageStore.confirmDeleteSelectedFiles({
      title: 'filehistorysection_confirmdeletefilestitle',
      description: 'filehistorysection_confirmdeletefilesdescription',
      numberOfFilesSelected: '',
      labelOfFilesSelected: '',
      callback: response => {
        if (response === true) {
          this.disableHeaderOptions.set(true);
          if (this.selectedAllOption() === true) {
            this.onValueChangeForOptionSignal.set(true);
            this.fileHistoryManageStore.clearFilesFromStore();
            this.fileHistoryManageStore.getAllFiles({
              sideDrawerId: this.store.selectSignal(activeSideDrawerSelector)()
                .id,
              recordId: this.recordId,
              callback: dataFileResponse => {
                // TODO check error
                this.store.dispatch(
                  new FileHistoryLoaded({ data: dataFileResponse })
                );
                this.handleLoadedFilesForDelete();
              },
            });
            return;
          }
          const files = this.fileList().filter(file => file.selected);
          const arrayOfSdQueueItemFiles =
            this.generateQueueItemsForDelete(files);
          this.handleQueueProcess(arrayOfSdQueueItemFiles);
        }
      },
    });
  }

  private handleLoadedFiles(): void {
    const files = this.store.selectSignal(
      fileListByRecordIdSelector({
        recordId: this.recordId,
      })
    )();
    const arrayOfSdQueueItemFiles = this.generateQueueItems(files);
    this.handleQueueProcess(arrayOfSdQueueItemFiles);
  }

  private handleLoadedFilesForDelete(): void {
    const files = this.store.selectSignal(
      fileListByRecordIdSelector({
        recordId: this.recordId,
      })
    )();
    const arrayOfSdQueueItemFiles = this.generateQueueItemsForDelete(files);
    this.handleQueueProcess(arrayOfSdQueueItemFiles);
  }

  private handleQueueProcess(arrayOfSdQueueItemFiles: SdQueueItem[]): void {
    arrayOfSdQueueItemFiles?.forEach(item =>
      this.store.dispatch(QueueActions.itemAdded({ item }))
    );
    this.store.dispatch(QueueActions.startProcess());
    this.actions
      .pipe(
        ofType(QueueActions.processCompletes),
        take(1),
        switchMap(result => this.handleProcessCompletes(result)) // TODO add type of proccess
      )
      .subscribe();
  }

  private generateQueueItems(files: FileItem[]): SdQueueItem[] {
    if (files.length > 0) {
      const arrayOfSdQueueItemFiles: SdQueueItem[] = [];
      files.forEach(fileForDownload => {
        const sdQueueItem: SdQueueItem = {
          id: FilesHelper.generateRandomId(),
          progress: 0,
          state: 'pending',
          itemType: 'download',
          download: {
            fileItem: fileForDownload,
            filename: fileForDownload.caption ?? fileForDownload.fileName,
          },
          operation$: this.downloadFile(fileForDownload, file => {
            const blob = new Blob([file], { type: file.type });
            // TODO mobile support
            if (environment.appName === ClientNames.mobile) {
              MobileFilesHelper.saveFileInDevice(blob, fileForDownload);
              return;
            }

            importedSaveAs(
              blob,
              fileForDownload.fileType === FileType.cloud
                ? FilesHelper.formatFileNameWithExtensionForDownLoad(
                    fileForDownload.fileName
                  )
                : FilesHelper.formatFileNameWithExtensionForDownLoad(
                    FilesHelper.getFileNameWithExtension(
                      fileForDownload.uploadTitle,
                      fileForDownload
                    )
                  )
            );
          }),
        };
        arrayOfSdQueueItemFiles.push(sdQueueItem);
      });
      return arrayOfSdQueueItemFiles;
    }
  }

  private generateQueueItemsForDelete(files: FileItem[]): SdQueueItem[] {
    if (files.length > 0) {
      const arrayOfSdQueueItemFiles: SdQueueItem[] = [];
      files.forEach(fileForDelete => {
        const sdQueueItem: SdQueueItem = {
          id: FilesHelper.generateRandomId(),
          progress: 0,
          state: 'pending',
          itemType: 'deleteFile',
          deleteFile: {
            fileItem: fileForDelete,
            filename: fileForDelete.caption ?? fileForDelete.fileName,
          },
          operation$: this.deleteFile(fileForDelete), // TODO need callback???
        };
        arrayOfSdQueueItemFiles.push(sdQueueItem);
      });
      return arrayOfSdQueueItemFiles;
    }
  }

  private deleteFile(fileForDelete: FileItem): Observable<unknown> {
    return this.filesService.deleteFile(fileForDelete).pipe(
      map(() => {
        this.store.dispatch(
          new TotalCountFileHistoryUpdate({
            totalCount:
              this.store.selectSignal(totalCountRecordFilesSelector)() - 1,
          })
        );
        this.store.dispatch(new FileHistoryDeleted({ id: fileForDelete.id }));
      })
    );
  }

  private downloadFile(
    fileToDownload: FileItem,
    callback: (file: Blob) => void
  ): Observable<string | { status: string; loaded?: number; file?: Blob }> {
    const sideDrawer = this.store.selectSignal(activeSideDrawerSelector)();
    const fileStateItem = this.store.selectSignal(
      fileItemEntitySelector({ id: fileToDownload.id })
    )();
    return this.filesService.downloadFile(fileToDownload, sideDrawer.id).pipe(
      tap({
        next: (response: { loaded: number; status: string; file: Blob }) => {
          if (response.status === 'progress') {
            this.store.dispatch(
              new FileItemSpinnerUpdate({
                id: fileToDownload.id,
                progress: fileToDownload?.fileSize
                  ? (response.loaded / fileToDownload.fileSize) * 100
                  : FilesHelper.calculateProgress(response.loaded),
              })
            );
          }

          if (response.status === 'complete') {
            this.store.dispatch(
              new FileItemUpdate({
                fileItem: {
                  ...fileStateItem,
                  progress: 0,
                  file: response.file,
                },
              })
            );
            callback(response.file);
          }
        },
        error: (error: HttpErrorResponse) => {
          this.store.dispatch(
            new FileItemSpinnerUpdate({
              id: fileToDownload.id,
              progress: 0,
            })
          );
          if (error?.error?.statusCode === 403) {
            this.store.dispatch(new NoPermissionsError());
            return;
          }
          this.store.dispatch(
            new ErrorLoaded({
              httpError: { ...error },
              display404: true,
            })
          );
        },
      })
    );
  }

  private handleProcessCompletes(
    result: TypedAction<'[Queue] Process Completes'>
  ): Observable<unknown> {
    // TODO for mobile
    /*
    if (environment.appName === ClientNames.mobile) {
    this.store.dispatch(
              new SdSnackBarDisplaySnackBar({
                message: !success
                  ? this.store.selectSignal(dictionarySelector)()
                      ?.globalparams_downloadfails ??
                    'globalparams_downloadfails'
                  : this.store.selectSignal(dictionarySelector)()
                      ?.globalparams_downloadsuccess ??
                    'globalparams_downloadsuccess',
              })
            );

    }
    */
    this.disableHeaderOptions.set(false);
    this.selectedAllOption.set(false);
    this.showHeaderFilesOptions.set(false);
    return EMPTY;
  }

  trackBy(index: number, item: FileItem): string {
    return item.id;
  }

  hideHeaderFileOptions(): void {
    this.showHeaderFilesOptions.set(false);
    this.selectedAllOption.set(false);
  }
}
