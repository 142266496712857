import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SdProgressSpinnerA11yComponent } from './components/sd-progress-spinner-a11y.component';
import { DictionaryPipeModule } from 'src/app/dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [SdProgressSpinnerA11yComponent],
  imports: [CommonModule, MatProgressSpinnerModule, DictionaryPipeModule],
  exports: [SdProgressSpinnerA11yComponent],
})
export class SdProgressSpinnerA11yModule {}
