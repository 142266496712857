import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SdNetworkRelationshipFormA11yComponent } from './components/network-relationship-form/sd-network-relationship-form-a11y.component';
import { SdAutocompleteA11yModule } from '../sd-autocomplete-a11y/sd-autocomplete-a11y.module';
import { DictionaryPipeModule } from '../../dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [SdNetworkRelationshipFormA11yComponent],
  exports: [SdNetworkRelationshipFormA11yComponent],
  imports: [
    CommonModule,
    SdAutocompleteA11yModule,
    DictionaryPipeModule,
    ReactiveFormsModule,
  ],
})
export class SdNetworkRelationshipFormA11yModule {}
