import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription, forkJoin, map, take, tap } from 'rxjs';
import { DateHelper } from 'src/app/core/helpers/date.helper';
import { SdColorPalette } from 'src/app/core/models/enums/sd-color-palette-enum';
import { DictionaryPipeModule } from 'src/app/dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { userCanOperateOnThisRecordSelector } from 'src/app/records/store/records-list.selectors';
import { AppState } from 'src/app/reducers';
import { SdFormattedDatePipeModule } from 'src/app/shared/sd-formatted-date-pipe/sd-formatted-date-pipe.module';
import { MenuItemType } from 'src/app/shared/sd-header-a11y/models/menu-item.type.enum';
import { SdLinkMenuLinkType } from 'src/app/shared/sd-link-menu-a11y/models/enums/sd-link-menu-link-type-enum';
import { SdLinkMenuItem } from 'src/app/shared/sd-link-menu-a11y/models/sd-link-menu-item.model';
import { SdLinkMenuA11yModule } from 'src/app/shared/sd-link-menu-a11y/sd-link-menu-a11y.module';
import { SdProgressSpinnerA11yModule } from 'src/app/shared/sd-progress-spinner-a11y/sd-progress-spinner-a11y/sd-progress-spinner-a11y.module';
import { SdSnackBarModule } from 'src/app/shared/sd-snack-bar/sd-snack-bar.module';
import { SdSvgA11yModule } from 'src/app/shared/sd-svg-a11y/sd-svg-a11y.module';
import { docuSignAvailableSelector } from 'src/app/sidedrawer/store/sidedrawer.selector';
import { environment } from 'src/environments/environment';
import { FileItem } from '../../models/file-item.model';
import { FileType } from '../../models/file-type.enum';
import { DisplayTypePipeModule } from '../../pipes/display-type-pipe/display-type-pipe.module';
import { CleanOpenFileHistory } from '../../store/file-history.actions';
import { openFileSelector } from '../../store/file-history.selector';
import {
  CopyLinkRequested,
  copyMoveDialogRequested,
  DeleteFileConfirmationRequested,
  SaveFileLocallyRequested,
  SignFileRequested,
  ViewFileRequested,
} from '../../store/file-item.actions';
import {
  fileItemProgressSelector,
  fileItemProgressTypeSelector,
  fileItemSpinnerSelector,
} from '../../store/file-item.selectors';
import { DeleteFileDialogComponent } from '../delete-file-dialog/delete-file-dialog.component';
import { FileRenameDialogComponent } from '../file-rename-dialog/file-rename-dialog.component';
import { ImageViewerDialogComponent } from '../image-viewer-dialog/image-viewer-dialog.component';
import { PdftronViewerDialogComponent } from '../pdftron-viewer-dialog/pdftron-viewer-dialog/pdftron-viewer-dialog.component';
import { UnsupportedFeatureDialogComponent } from '../unsupported-feature-dialog/unsupported-feature-dialog.component';
import { VideoViewerDialogComponent } from '../video-viewer-dialog/video-viewer-dialog.component';
import { UtilsHelper } from '../../../core/helpers/utils.helper';
import { SdCheckboxA11yModule } from 'src/app/shared/sd-checkbox-a11y/sd-checkbox-a11y.module';
import { Dictionary } from 'src/app/dictionary/models/dictionary.model';
import {
  dictionarySelector,
  displayTypesSelector,
  localeDefaultSelector,
} from 'src/app/dictionary/store/dictionary.selectors';

@Component({
  selector: 'app-file-history-section-item',
  template: `<div *ngIf="!!item" class="file-history-section-item">
    <div
      class="file-history-section-item-grid"
      [ngClass]="{
        'file-history-section-item-grid': !showCheckbox,
        'file-history-section-item-grid-checkbox': showCheckbox
      }"
      tabindex="0">
      <ng-container *ngIf="showCheckbox">
        <app-sd-checkbox-a11y
          class="file-history-section-item-checkbox"
          (valueChange)="valueChangeForOption.emit($event)"
          [value]="item?.selected ?? false">
        </app-sd-checkbox-a11y>
      </ng-container>

      <div
        (click)="onIconClick(!item?.selected)"
        (keydown.enter)="onIconClick(!item?.selected)"
        [ngClass]="{ 'icon-selected': item?.selected }"
        class="file-history-section-item-image">
        <div
          class="file-history-section-item-image-container"
          [ngClass]="{
            'file-history-section-item-image-container': !item?.selected,
            'file-history-section-item-image-container-selected': item?.selected
          }">
          <div class="file-history-section-image-container">
            <!-- TODO dictionary for icon check svgs/check-regular.svg -->
            <app-sd-svg-a11y
              [setSdAccessibility]="{ tabIndex: -1 }"
              [color]="'var(--primaryColor)'"
              [height]="1.3"
              [width]="1.2"
              [src]="
                item?.selected
                  ? cdn + ('globalparams_checkicon' | dictionary | async)
                  : item.fileType === fileType.image
                    ? cdn + ('globalparams_imageicon' | dictionary | async)
                    : item.fileType === fileType.document
                      ? cdn + ('globalparams_documenticon' | dictionary | async)
                      : cdn +
                        ('globalparams_clouduploadicon' | dictionary | async)
              " />
          </div>
        </div>
      </div>

      <!-- TODO file name -->
      <div
        (click)="onDefaultAction()"
        (keydown.enter)="onDefaultAction()"
        class="file-history-section-item-title">
        <div class="file-list-item-container">
          <span class="file-list-item-name">
            {{
              item.fileType === fileType.cloud
                ? (item?.fileName | slice: 0 : 120)
                : (item?.uploadTitle | slice: 0 : 120)
            }}
          </span>
          <app-sd-svg-a11y
            (click)="renameFile(item, $event)"
            (keydown.enter)="renameFile(item, $event)"
            *ngIf="
              !viewOnly &&
              (canOperate$ | async) &&
              item.fileType !== fileType.cloud
            "
            [color]="'var(--primaryColor)'"
            [height]="1.125"
            [setSdAccessibility]="{
              role: 'button',
              ariaLabel:
                'filehistorysectionitem_editnametooltip' | dictionary | async
            }"
            [src]="cdn + ('globalparams_editicon' | dictionary | async)"
            [tooltips]="
              'filehistorysectionitem_editnametooltip' | dictionary | async
            "
            [width]="1.125"
            class="edit-button" />
        </div>
      </div>

      <!-- TODO check this -->
      <div
        *ngIf="item.displayType && item.displayType !== 'review'"
        class="file-history-section-item-display-type">
        <ng-container>
          <span
            *ngIf="!isSearchResult"
            class="reminder-list-item-info-display-type">
            {{ item.displayType | displayType | async }}
          </span>

          <div *ngIf="isSearchResult">{{ item.recordName }}</div>
        </ng-container>
      </div>

      <!-- TODO last modify -->
      <div
        (click)="onDefaultAction()"
        (keydown.enter)="onDefaultAction()"
        class="file-history-section-item-by">
        <span class="last-modified">{{
          'globalparams_uploadby' | dictionary | async
        }}</span>
        <strong class="by">{{
          item?.lastModifiedBy?.length > 0
            ? item?.lastModifiedBy
            : item?.uploader
        }}</strong>
        <span *ngIf="happensToday" class="last-modified">{{
          'globalparams_lastmodifiedat' | dictionary | async
        }}</span>
        <span *ngIf="!happensToday" class="last-modified">{{
          'globalparams_lastmodifiedon' | dictionary | async
        }}</span>
        <strong class="date">{{
          item?.updatedAt | sdFormattedDate | async
        }}</strong>
      </div>

      <!-- TODO menu -->
      <div
        *ngIf="(spinner$ | async) === false && showMenu"
        class="file-history-section-item-actions">
        <app-sd-link-menu-a11y
          (emitterClicked)="onEmitterClicked($event)"
          *ngIf="!isSearchResult"
          [accessibility]="{
            ariaLabel:
              'filehistorysectionitem_actionmenutooltip' | dictionary | async,
            tabIndex: -1
          }"
          [border]="false"
          [buttonHeight]="1.2"
          [buttonWidth]="2"
          [disableRipple]="true"
          [iconColor]="sdColorPalette.primaryAccentColor"
          [icon]="
            cdn + ('globalparams_horizontalactionmenuicon' | dictionary | async)
          "
          [setMenuData]="[
            {
              menuLinkText:
                'filehistorysectionitem_actionmenuoptioneditname'
                | dictionary
                | async,
              visible:
                !viewOnly &&
                (canOperate$ | async) &&
                item.fileType !== fileType.cloud,
              menuLinkType: sdLinkMenuLinkType.emitter,
              key: menuItemType.editFileName
            },
            {
              menuLinkText:
                'filehistorysectionitem_actionmenuoptionsign'
                | dictionary
                | async,
              visible: (docuSignAvailable$ | async) && (canOperate$ | async),
              menuLinkType: sdLinkMenuLinkType.emitter,
              key: menuItemType.docusign
            },
            {
              menuLinkText:
                'filehistorysectionitem_actionmenuoptionpreview'
                | dictionary
                | async,
              visible: true,
              menuLinkType: sdLinkMenuLinkType.emitter,
              key: menuItemType.view
            },
            {
              menuLinkText:
                'filehistorysectionitem_actionmenuoptiondownload'
                | dictionary
                | async,
              visible: true,
              menuLinkType: sdLinkMenuLinkType.emitter,
              key: menuItemType.download
            },
            {
              menuLinkText:
                'filehistorysectionitem_actionmenuoptionlink'
                | dictionary
                | async,
              visible: true,
              menuLinkType: sdLinkMenuLinkType.emitter,
              key: menuItemType.copy
            },
            {
              menuLinkText:
                'filehistorysectionitem_actionmenucopy' | dictionary | async,
              visible:
                !viewOnly &&
                (canOperate$ | async) &&
                item.fileType !== fileType.cloud,
              menuLinkType: sdLinkMenuLinkType.emitter,
              key: menuItemType.copyFile
            },
            {
              menuLinkText:
                'filehistorysectionitem_actionmenumove' | dictionary | async,
              visible:
                !viewOnly &&
                (canOperate$ | async) &&
                item.fileType !== fileType.cloud,
              menuLinkType: sdLinkMenuLinkType.emitter,
              key: menuItemType.moveFile
            },
            {
              menuLinkText:
                'filehistorysectionitem_actionmenuoptiondelete'
                | dictionary
                | async,
              visible:
                !viewOnly &&
                (canOperate$ | async) &&
                item.fileType !== fileType.cloud,
              menuLinkType: sdLinkMenuLinkType.emitter,
              key: menuItemType.delete
            }
          ]"
          [squareBackground]="true"
          [tooltip]="
            'filehistorysectionitem_actionmenutooltip' | dictionary | async
          "
          [transparentBackground]="true">
        </app-sd-link-menu-a11y>
      </div>

      <div
        *ngIf="(spinner$ | async) === true && viewOnly === false"
        class="file-history-section-item-actions spinner">
        <span *ngIf="(progress$ | async) !== -1" class="spinner-progress-text">
          {{ progress$ | async | number: '1.0-0' }}%</span
        >
        <app-sd-progress-spinner-a11y
          [mode]="fileItemProgressType$ | async"
          [setSpinnerProgress]="
            progress$ | async
          "></app-sd-progress-spinner-a11y>
      </div>
    </div>
  </div> `,
  styleUrls: ['./file-history-section-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ImageViewerDialogComponent,
    VideoViewerDialogComponent,
    UnsupportedFeatureDialogComponent,
    PdftronViewerDialogComponent,
    DeleteFileDialogComponent,
    SdSnackBarModule,
    DictionaryPipeModule,
    SdFormattedDatePipeModule,
    SdProgressSpinnerA11yModule,
    SdLinkMenuA11yModule,
    DisplayTypePipeModule,
    SdSvgA11yModule,
    MatDialogModule,
    SdCheckboxA11yModule,
  ],
})
export class FileHistorySectionItemComponent implements OnInit, OnDestroy {
  @Input() viewOnly = false;
  @Input() isSearchResult = false;
  @Input() canOpenFile = true;
  @Input() showMenu = true;
  @Input() showCheckbox = false;

  item: FileItem;
  fileType = FileType;
  cdn = environment.cdn;
  fileItemProgressType$: Observable<ProgressSpinnerMode>;
  progress$: Observable<number>;
  spinner$: Observable<boolean>;
  docuSignAvailable$: Observable<boolean> = this.store.select(
    docuSignAvailableSelector
  );
  canOperate$ = this.store.select(userCanOperateOnThisRecordSelector);
  happensToday: boolean;
  sdColorPalette = SdColorPalette;
  sdLinkMenuLinkType = SdLinkMenuLinkType;
  menuItemType = MenuItemType;
  dictionary$: Observable<Dictionary> = this.store.select(dictionarySelector);
  ariaLabel1: string;
  ariaLabel2: string;
  date: string;
  displayType: string;
  subscription = new Subscription();

  @Input() set fileItem(fileItem: FileItem) {
    //console.log('fileItem', fileItem);
    this.fileItemProgressType$ = this.store.select(
      fileItemProgressTypeSelector({ id: fileItem.id })
    );
    this.progress$ = this.store.select(
      fileItemProgressSelector({ id: fileItem.id })
    );
    this.spinner$ = this.store.select(
      fileItemSpinnerSelector({ id: fileItem.id })
    );
    this.item = fileItem;
    this.happensToday = DateHelper.happensToday(`${this.item.updatedAt}`);
  }

  @Output() valueChangeForOption = new EventEmitter<boolean>();

  constructor(
    private readonly store: Store<AppState>,
    private readonly dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.store
        .pipe(
          select(openFileSelector),
          take(1),
          tap(id => {
            if (id === this.item.id) {
              this.onViewFile();
              this.store.dispatch(new CleanOpenFileHistory());
            }
          })
        )
        .subscribe()
    );

    this.subscription.add(
      this.dictionary$
        .pipe(
          map(dictionary => {
            const name =
              this.item?.fileType === this.fileType.cloud
                ? this.item?.fileName
                : this.item?.uploadTitle;
            const uploader =
              this.item.lastModifiedBy?.length > 0
                ? this.item.lastModifiedBy
                : this.item.uploader;
            const by = this.happensToday
              ? dictionary.globalparams_lastmodifiedat
              : dictionary.globalparams_lastmodifiedon;
            this.ariaLabel1 = `${name}`;
            this.ariaLabel2 = ` ${dictionary.globalparams_uploadby} ${uploader} ${by} `;
          })
        )
        .subscribe()
    );

    this.subscription.add(
      this.transformDate(this.item?.updatedAt).subscribe(e => (this.date = e))
    );
    this.subscription.add(
      this.transformType(this.item.displayType).subscribe(
        e => (this.displayType = e)
      )
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onDefaultAction(): void {
    if (UtilsHelper.isBrowserMobile()) {
      this.store.dispatch(new SaveFileLocallyRequested({ id: this.item.id }));
      return;
    }
    this.onViewFile();
  }

  onViewFile(): void {
    if (this.canOpenFile) {
      this.store.dispatch(new ViewFileRequested({ id: this.item.id }));
    }
  }

  onEmitterClicked(linkMenu: SdLinkMenuItem) {
    if (linkMenu.key === MenuItemType.view) {
      this.onViewFile();
    }
    if (linkMenu.key === MenuItemType.download) {
      // TODO see this...
      this.store.dispatch(new SaveFileLocallyRequested({ id: this.item.id }));
    }
    if (linkMenu.key === MenuItemType.copy) {
      this.store.dispatch(new CopyLinkRequested({ id: this.item.id }));
    }
    if (linkMenu.key === MenuItemType.delete) {
      this.store.dispatch(
        new DeleteFileConfirmationRequested({
          id: this.item.id,
          fileItem: this.item,
        })
      );
    }
    if (linkMenu.key === MenuItemType.docusign) {
      this.store.dispatch(new SignFileRequested({ id: this.item.id }));
    }
    if (linkMenu.key === MenuItemType.editFileName) {
      this.renameFile(this.item);
    }
    if (linkMenu.key === MenuItemType.copyFile) {
      this.store.dispatch(
        new copyMoveDialogRequested({ fileItem: this.item, copyFile: true })
      );
    }
    if (linkMenu.key === MenuItemType.moveFile) {
      this.store.dispatch(
        new copyMoveDialogRequested({ fileItem: this.item, copyFile: false })
      );
    }
  }

  renameFile(file: FileItem, event?: Event): void {
    if (!file) {
      return;
    }
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.dialog
      .open(FileRenameDialogComponent, {
        data: {
          item: this.item,
          setActive: false,
        },
        autoFocus: false,
      })
      .afterClosed()
      .subscribe();
  }

  iconSelected = false;

  onIconClick(itemSelected: boolean): void {
    this.iconSelected = !this.iconSelected;

    this.valueChangeForOption.emit(itemSelected);
  }

  transformType(displayType: string): Observable<string> {
    return this.store.pipe(
      select(displayTypesSelector),
      map(_displayType => {
        return _displayType.find(c => c.displaytype_enumid === displayType)
          ?.displaytype_label;
      })
    );
  }

  transformDate(date: Date): Observable<string> {
    return forkJoin([
      this.store.pipe(select(dictionarySelector), take(1)),
      this.store.pipe(select(localeDefaultSelector), take(1)),
    ]).pipe(
      map(([dictionary, locale]) =>
        date ? DateHelper.formatDate(date.toString(), dictionary, locale) : ''
      )
    );
  }
}
