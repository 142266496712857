import { Action } from '@ngrx/store';
import { SideDrawerNetworksRequest } from 'src/app/sidedrawer-networks/models/side-drawer-network-request.model';
import { SideDrawerNetwork } from 'src/app/sidedrawer-networks/models/side-drawer-network.model';

export enum SearchSideDrawerListActionsTypes {
  SearchSideDrawerListRequested = '[Search Sidedrawer List Effect / Search Bar ] Search SideDrawer List Requested',
  SearchSideDrawerListLoaded = '[Search Sidedrawer List Effect] Search SideDrawer List Loaded',
  SearchSideDrawerListPageLoaded = '[Search Sidedrawer List Effect] Search SideDrawer List Page Loaded',
  SearchSideDrawerListNextPageRequested = '[Search Sidedrawer List Effect / Search Result] Search SideDrawer List Next Page Requested',
  SearchSideDrawerListReset = '[Search Sidedrawer List Effect / Search Bar] Search SideDrawer List Reset',
}

export class SearchSideDrawerListRequested implements Action {
  readonly type =
    SearchSideDrawerListActionsTypes.SearchSideDrawerListRequested;

  constructor(public payload?: { requestId?: string; name?: string }) {}
}

export class SearchSideDrawerListPageLoaded implements Action {
  readonly type =
    SearchSideDrawerListActionsTypes.SearchSideDrawerListPageLoaded;

  constructor(
    public payload: { results: SideDrawerNetworksRequest[]; filter?: string }
  ) {}
}

export class SearchSideDrawerListLoaded implements Action {
  readonly type = SearchSideDrawerListActionsTypes.SearchSideDrawerListLoaded;

  constructor(
    public payload: {
      networks: SideDrawerNetwork[];
      filter?: string;
    }
  ) {}
}

export class SearchSideDrawerListNextPageRequested implements Action {
  readonly type =
    SearchSideDrawerListActionsTypes.SearchSideDrawerListNextPageRequested;
}

export class SearchSideDrawerListReset implements Action {
  readonly type = SearchSideDrawerListActionsTypes.SearchSideDrawerListReset;
}

export type SearchSideDrawerListActions =
  | SearchSideDrawerListRequested
  | SearchSideDrawerListLoaded
  | SearchSideDrawerListPageLoaded
  | SearchSideDrawerListNextPageRequested
  | SearchSideDrawerListReset;
