import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { RecordType } from '../../../../models/record-type.model';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../../reducers';
import { HomeService } from '../../../../../home/services/home.service';
import { ActivatedRoute, Router } from '@angular/router';
import { recordTypesSelector } from '../../../../../sidedrawer/store/sidedrawer.selector';
import { SidedrawerRoutesEnum } from '../../../../../sidedrawer/routes/sidedrawer-routes.enum';
import { RecordsRoutes } from '../../../../routes/records.routes';
import { map, tap } from 'rxjs/operators';
import { displayInformationSelector } from '../../../../../core/store/core.selectors';
import { DisplayInfo } from '../../../../../core/models/display-info.model';
import { activeRecordTypeSelector } from '../../../../store/records-list.selectors';
import { environment } from 'src/environments/environment';
import { SdColorPalette } from 'src/app/core/models/enums/sd-color-palette-enum';

@Component({
  selector: 'app-records-type-switcher',
  templateUrl: './records-type-switcher.component.html',
  styleUrls: ['./records-type-switcher.component.scss'],
})
export class RecordsTypeSwitcherComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  recordTypes$: Observable<RecordType[]>;
  subscription = new Subscription();
  contentWidth$: Observable<number>;
  cdn = environment.cdn;
  colorPalette = SdColorPalette;

  constructor(
    private readonly store: Store<AppState>,
    private readonly homeService: HomeService,
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.recordTypes$ = this.store.pipe(select(recordTypesSelector));
    this.contentWidth$ = this.store.pipe(
      select(displayInformationSelector),
      map(displayInfo => DisplayInfo.recordGridContentWidth(displayInfo))
    );
  }

  isRecordTypeActive(tileName): Observable<boolean> {
    return this.store.pipe(
      select(activeRecordTypeSelector),
      map(rt => tileName === rt?.name)
    );
  }

  ngAfterViewInit(): void {
    this.subscription.add(
      combineLatest([this.route.params, this.recordTypes$, this.contentWidth$])
        .pipe(
          tap(([params, recordTypes, width]) => {
            if (!!params && !!params.recordTypeName) {
              const recordTypeName = params.recordTypeName;
              const index = recordTypes.findIndex(
                type => type.name === recordTypeName
              );
              if (index >= 0) {
                if ((index + 1) * 57 > width - 80) {
                  document.querySelector(
                    '.records-type-switcher-tiles'
                  ).scrollLeft += (index + 1) * 57 - (width - 80) + 30;
                }
              }
            }
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onSelectRecordType(type: RecordType): void {
    const url =
      this.homeService.getHomeRoute() +
      `${SidedrawerRoutesEnum.root}/${RecordsRoutes.root}/${type.name}/${RecordsRoutes.list}`;
    this.router.navigate([url]);
  }

  onLeft(): void {
    document.querySelector('.records-type-switcher-tiles').scrollLeft -= 100;
  }

  onRight(): void {
    document.querySelector('.records-type-switcher-tiles').scrollLeft += 100;
  }
}
