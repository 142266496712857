<div class="content">
  <mat-checkbox
    (change)="onChange()"
    (keydown.enter)="onChange()"
    [aria-label]="sdAccessibility?.ariaLabel"
    [checked]="value"
    [id]="sdAccessibility?.id"
    class="sd-checkbox"
    color="primary">
  </mat-checkbox>
  <div class="text">
    <ng-content></ng-content>
  </div>
</div>
