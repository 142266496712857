import { Action } from '@ngrx/store';
import { RecordListItem } from '../models/record-list-item.model';
import { Record } from '../models/record.model';
import { RecordType } from '../models/record-type.model';
import { RecordsSortOptions } from '../models/records-sort-options.model';
import { RecordSubType } from '../models/record-sub-type.model';
import { CloudProvider } from '../../integrations/models/cloud-provider.model';
import { RelatedRecord } from '../../related-records/models/related-record.model';
import { PaginatorApiResponse } from '../../core/models/paginator-api-response.dto';
import { Update } from '@ngrx/entity';
import { HttpErrorResponse } from '@angular/common/http';
import { Locale } from '../../dictionary/models/locale.model';
import { DialogRef } from '@angular/cdk/dialog';

export enum RecordsListActionsTypes {
  RecordsListRequested = '[] Records List Requested',
  RecordsListLoaded = '[Records Service] Records List Loaded',
  RecordDeleted = '[Records Service] Record Deleted',
  RecordDeleteRequested = '[Records Service] Record Deleted Requested',
  RecordsInfoClean = '[Right Menu Component] Records Information Cleaned',
  ActiveRecordRequested = '[] Active Record Requested',
  SetActiveRecord = '[Records Service] Set Active Record information',
  SetActiveRecordFail = '[Records Service] Set Active Record information Fail',
  ClearActiveRecord = '[Records Form View] Clear Active Record information',
  SetActiveRecordType = '[Records Main Template] Set Active Record Type',
  SetActiveRecordSubType = '[Records Form View] Set Active Record Sub Type',
  ClearActiveRecordSubType = '[Record Form View] Clear Active Record SubType',
  RecordsSpinnerChange = '[Records Service] Records spinner change',
  SortRecordsByChange = '[] Sort Records By change',
  RecordsSubtypesRequested = '[Record Subtype Autocomplete] Records SubTypes Requested',
  RecordsSubtypesLoaded = '[Records Service] Records SubTypes Loaded',
  ClearRecordsSubTypes = '[Record Subtype Autocomplete] Clear Records SubTypes',
  NewRecordRedirect = '[Records Upload Buttons & Collaborators Sections] Redirect to another page during record creation',
  ClearNewRecordRedirect = '[Record Form] Clear the Redirect information',
  NewRecordIntegrationDialog = '[Integration Sections] Open Integration Dialog with provider',
  ClearNewRecordIntegrationDialog = '[Record Form] Clear New Record Integration Dialog info',
  NewRecordRelatedRecord = '[Related Record Selector] New Record RelatedRecord',
  ClearNewRecordRelatedRecord = '[Record Form] Clear New Record RelatedRecord info',
  AllRecordsRequested = '[] All Records Requested',
  RecordsListNextPageRequested = '[] Records List Next Page Requested',
  RecordsListFilterChange = '[] Records List Filter Change',
  GettingActiveRecordChange = '[record form view] Getting Active Record Change',
  GettingActiveRecordSubTypesChange = '[record form view] Getting Active Record SubTypes Change',
  RecordChangeStatusRequested = '[Record Change Status] Record Change Status',
  RecordChangeStatus = '[Record Change Status Success] Record Change Status Success',
  RecordCopyLinkRequested = '[Record Copy Link] Record Copy Link',
  FilingCabinetAllLatestRecordsRequested = '[] Filing Cabinet All Latest Records Requested',
  CopyMoveRecordRequested = '[] Copy Move Record Requested',
  CopyMoveDialogRequested = '[] Copy Move Dialog Requested',
  CopyMoveRecordSuccess = '[] Copy Move Record Success',
  CopyMoveRecordUpdateRecord = '[] Copy Move Record Update Record',
  RemoveRecordFromStore = '[] Remove Record From Store',
  CopyMoveRecordRequestedLoading = '[] Copy Move Record Requested Loading',
  RecordRenameAndChangeTypeDialogRequested = '[] Record Rename And Change Type Dialog Requested',
  DeleteRecordDialogRequested = '[] Delete Record Dialog Requested',
}

export class RecordsListRequested implements Action {
  readonly type = RecordsListActionsTypes.RecordsListRequested;

  constructor(
    public payload: {
      sideDrawerId: string;
      locale: string;
      nextPage?: string;
      name?: string;
      recordTypeName?: string;
      recordTypeId?: string;
      recordSubtypeName?: string;
      recordSubtypeOther?: string;
      recordSubtypeId?: string;
      limit?: number;
    }
  ) {}
}

export class RecordsListLoaded implements Action {
  readonly type = RecordsListActionsTypes.RecordsListLoaded;

  constructor(
    public payload: {
      data: PaginatorApiResponse<RecordListItem>;
      lastPayload?: {
        filter?: {
          name?: string;
          recordTypeName?: string;
          recordTypeId?: string;
          recordSubtypeName?: string;
          recordSubtypeOther?: string;
          recordSubtypeId?: string;
        };
      };
    }
  ) {}
}

export class RecordDeleted implements Action {
  readonly type = RecordsListActionsTypes.RecordDeleted;

  constructor(public payload: { id: string }) {}
}

export class RecordDeleteRequested implements Action {
  readonly type = RecordsListActionsTypes.RecordDeleteRequested;

  constructor(
    public payload: {
      id: string;
      callback?: (result: boolean | HttpErrorResponse) => void;
    }
  ) {}
}

export class RecordsInfoClean implements Action {
  readonly type = RecordsListActionsTypes.RecordsInfoClean;
}

export class ActiveRecordRequested implements Action {
  readonly type = RecordsListActionsTypes.ActiveRecordRequested;

  constructor(
    public payload: {
      recordId: string;
      sideDrawerId?: string;
      callback?: (result: Record | HttpErrorResponse) => void;
    }
  ) {}
}

export class SetActiveRecordType implements Action {
  readonly type = RecordsListActionsTypes.SetActiveRecordType;

  constructor(public payload: { data: RecordType }) {}
}

export class SetActiveRecordFail implements Action {
  readonly type = RecordsListActionsTypes.SetActiveRecordFail;

  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class SetActiveRecordSubType implements Action {
  readonly type = RecordsListActionsTypes.SetActiveRecordSubType;

  constructor(public payload: { data: RecordSubType }) {}
}

export class SetActiveRecord implements Action {
  readonly type = RecordsListActionsTypes.SetActiveRecord;

  constructor(public payload: { data: Record }) {}
}

export class ClearActiveRecord implements Action {
  readonly type = RecordsListActionsTypes.ClearActiveRecord;
}

export class ClearActiveRecordSubType implements Action {
  readonly type = RecordsListActionsTypes.ClearActiveRecordSubType;
}

export class RecordsSpinnerChange implements Action {
  readonly type = RecordsListActionsTypes.RecordsSpinnerChange;

  constructor(public payload: { state: boolean }) {}
}

export class GettingActiveRecordChange implements Action {
  readonly type = RecordsListActionsTypes.GettingActiveRecordChange;

  constructor(public payload: { state: boolean }) {}
}

export class GettingActiveRecordSubTypesChange implements Action {
  readonly type = RecordsListActionsTypes.GettingActiveRecordSubTypesChange;

  constructor(public payload: { state: boolean }) {}
}

export class SortRecordsByChange implements Action {
  readonly type = RecordsListActionsTypes.SortRecordsByChange;

  constructor(public payload: { sort: RecordsSortOptions }) {}
}

export class RecordsSubtypesRequested implements Action {
  readonly type = RecordsListActionsTypes.RecordsSubtypesRequested;

  constructor(public payload: { recordTypeName: string }) {}
}

export class RecordsSubtypesLoaded implements Action {
  readonly type = RecordsListActionsTypes.RecordsSubtypesLoaded;

  constructor(public payload: { data: RecordSubType[] }) {}
}

export class ClearRecordsSubTypes implements Action {
  readonly type = RecordsListActionsTypes.ClearRecordsSubTypes;
}

export class NewRecordRedirect implements Action {
  readonly type = RecordsListActionsTypes.NewRecordRedirect;

  constructor(public payload: { url: string }) {}
}

export class ClearNewRecordRedirect implements Action {
  readonly type = RecordsListActionsTypes.ClearNewRecordRedirect;
}

export class NewRecordIntegrationDialog implements Action {
  readonly type = RecordsListActionsTypes.NewRecordIntegrationDialog;

  constructor(public payload: { provider: CloudProvider }) {}
}

export class ClearNewRecordIntegrationDialog implements Action {
  readonly type = RecordsListActionsTypes.ClearNewRecordIntegrationDialog;
}

export class NewRecordRelatedRecord implements Action {
  readonly type = RecordsListActionsTypes.NewRecordRelatedRecord;

  constructor(public payload: { relatedRecord: RelatedRecord }) {}
}

export class ClearNewRecordRelatedRecord implements Action {
  readonly type = RecordsListActionsTypes.ClearNewRecordRelatedRecord;
}

export class AllRecordsRequested implements Action {
  readonly type = RecordsListActionsTypes.AllRecordsRequested;

  constructor(
    public payload?: {
      data?: PaginatorApiResponse<RecordListItem>;
      callback?: any;
    }
  ) {}
}

export class RecordsListNextPageRequested implements Action {
  readonly type = RecordsListActionsTypes.RecordsListNextPageRequested;

  constructor(
    public payload: {
      limit?: number;
    }
  ) {}
}

export class CopyMoveRecordUpdateRecord implements Action {
  readonly type = RecordsListActionsTypes.CopyMoveRecordUpdateRecord;
  constructor(
    public payload: {
      sidedrawerId: string;
      recordId: string;
      record: Record;
    }
  ) {}
}

export class RecordsListFilterChange implements Action {
  readonly type = RecordsListActionsTypes.RecordsListFilterChange;

  constructor(
    public payload: {
      name?: string;
      recordTypeName?: string;
      recordTypeId?: string;
      recordSubtypeName?: string;
      recordSubtypeOther?: string;
      recordSubtypeId?: string;
    }
  ) {}
}

export class RecordChangeStatusRequested implements Action {
  readonly type = RecordsListActionsTypes.RecordChangeStatusRequested;

  constructor(
    public payload: {
      record: Record;
      status: string;
      sideDrawerId: string;
    }
  ) {}
}

export class RecordChangeStatus implements Action {
  readonly type = RecordsListActionsTypes.RecordChangeStatus;

  constructor(public payload: { record: Update<Record> }) {}
}

export class RecordCopyLinkRequested implements Action {
  readonly type = RecordsListActionsTypes.RecordCopyLinkRequested;

  constructor(public payload: { record: RecordListItem }) {}
}

export class CopyMoveRecordSuccess implements Action {
  readonly type = RecordsListActionsTypes.CopyMoveRecordSuccess;
  constructor(
    public payload: {
      operationType?: string;
      recordId?: string;
      record?: Record;
      destinationSidedrawerId?: string;
      cancelRedirect?: boolean;
      moveCopyResponse?: unknown;
    }
  ) {}
}

export class FilingCabinetAllLatestRecordsRequested implements Action {
  readonly type =
    RecordsListActionsTypes.FilingCabinetAllLatestRecordsRequested;
}

export class CopyMoveRecordDialogRequested implements Action {
  readonly type = RecordsListActionsTypes.CopyMoveDialogRequested;
  constructor(
    public payload: {
      copyRecord: boolean;
      record?: RecordListItem;
      sideDrawerId?: string;
      locale?: Locale;
      callback?: (result: {
        dialogRef: DialogRef;
        moveCopyResponse: RecordsListActionsTypes.CopyMoveRecordSuccess;
        operationType: string;
      }) => void;
    }
  ) {}
}

export class CopyMoveRecordRequested implements Action {
  readonly type = RecordsListActionsTypes.CopyMoveRecordRequested;
  constructor(
    public payload: {
      originSidedrawerId: string;
      originRecordId: string;
      destinationSidedrawerId: string;
      destinationRecordTypeName: string;
      destinationRecordSubtypeName: string;
      operationType: string;
      destinationRecordSubtypOther?: string;
      cancelRedirect?: boolean;
    }
  ) {}
}

export class RemoveRecordFromStore implements Action {
  readonly type = RecordsListActionsTypes.RemoveRecordFromStore;
  constructor(
    public payload: {
      recordId: string;
    }
  ) {}
}

export class CopyMoveRecordRequestedLoading implements Action {
  readonly type = RecordsListActionsTypes.CopyMoveRecordRequestedLoading;
  constructor(
    public payload: {
      loading: boolean;
    }
  ) {}
}

export class RecordRenameAndChangeTypeDialogRequested implements Action {
  readonly type =
    RecordsListActionsTypes.RecordRenameAndChangeTypeDialogRequested;
  constructor(
    public payload: {
      resourceType: string;
      record: Record;
      setActive: boolean;
    }
  ) {}
}

export class DeleteRecordDialogRequested implements Action {
  readonly type = RecordsListActionsTypes.DeleteRecordDialogRequested;
  constructor(
    public payload: {
      record: Record;
      callback: (result: boolean) => void;
    }
  ) {}
}

export type RecordsListActions =
  | RecordsListRequested
  | RecordsListLoaded
  | RecordDeleted
  | RecordDeleteRequested
  | RecordsInfoClean
  | ActiveRecordRequested
  | SetActiveRecord
  | SetActiveRecordFail
  | SetActiveRecordSubType
  | ClearActiveRecord
  | ClearActiveRecordSubType
  | SetActiveRecordType
  | RecordsSpinnerChange
  | GettingActiveRecordChange
  | GettingActiveRecordSubTypesChange
  | SortRecordsByChange
  | RecordsSubtypesRequested
  | RecordsSubtypesLoaded
  | ClearRecordsSubTypes
  | NewRecordRedirect
  | ClearNewRecordRedirect
  | NewRecordIntegrationDialog
  | ClearNewRecordIntegrationDialog
  | NewRecordRelatedRecord
  | AllRecordsRequested
  | RecordsListNextPageRequested
  | RecordsListFilterChange
  | ClearNewRecordRelatedRecord
  | RecordChangeStatusRequested
  | RecordChangeStatus
  | RecordCopyLinkRequested
  | FilingCabinetAllLatestRecordsRequested
  | CopyMoveRecordRequested
  | CopyMoveRecordDialogRequested
  | CopyMoveRecordSuccess
  | RemoveRecordFromStore
  | CopyMoveRecordUpdateRecord
  | CopyMoveRecordRequestedLoading
  | RecordRenameAndChangeTypeDialogRequested;
