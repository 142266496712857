import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import * as reducer from './store/dictionary.reducer';
import { EffectsModule } from '@ngrx/effects';
import { DictionaryEffects } from './store/dictionary.effects';
import { DictionaryService } from './services/dictionary.service';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  imports: [
    StoreModule.forFeature(reducer.featureName, reducer.dictionaryReducer),
    EffectsModule.forFeature([DictionaryEffects]),
    MatDialogModule,
  ],
  providers: [DictionaryService],
})
export class DictionaryModule {}
