import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, forkJoin, map, mergeMap, of, tap } from 'rxjs';
import { AppState } from 'src/app/reducers';
import { ErrorType } from 'src/app/reminders/models/enums/error-type.enum';
import { SfrError } from 'src/app/reminders/models/sfr-error.model';
import { NetworkService } from '../../services/network.service';
import {
  NetworksCrudActionsTypes,
  NetworksCrudAddError,
  NetworksCrudCreateRequested,
  NetworksCrudCreateSpinnerChange,
  NetworksCrudCreateSuccess,
} from './networks-crud.actions';
import { delay, take } from 'rxjs/operators';

@Injectable()
export class NetworksCrudEffects {
  networksCrudCreateRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<NetworksCrudCreateRequested>(
        NetworksCrudActionsTypes.NetworksCrudCreateRequested
      ),
      tap(() => {
        this.store.dispatch(
          new NetworksCrudCreateSpinnerChange({ state: true })
        );
      }),
      mergeMap(({ payload }) =>
        forkJoin([
          // this.store
          //   .select(
          //     networkByEmailHasSdPermissionSelector({
          //       email: payload?.network?.contributor?.email,
          //     })
          //   )
          //   .pipe(take(1)),
          of(payload).pipe(take(1)),
        ])
      ),
      mergeMap(([
        // hasSdRole,
                  payload
                ]) => {
        // if (hasSdRole) {
        //   return of(null).pipe(
        //     delay(10),
        //     map(() => {
        //       this.store.dispatch(
        //         new NetworksCrudCreateSpinnerChange({ state: false })
        //       );
        //       return new NetworksCrudCreateSuccess();
        //     })
        //   );
        // }
        return this.networkService
          .createRecordNetwork(
            payload.sidedrawerId,
            payload.recordId,
            payload.network
          )
          .pipe(
            map(() => {
              this.store.dispatch(
                new NetworksCrudCreateSpinnerChange({ state: false })
              );
              return new NetworksCrudCreateSuccess();
            }),
            catchError(error => {
              this.store.dispatch(
                new NetworksCrudCreateSpinnerChange({ state: false })
              );
              const errors = new Map<string | ErrorType, SfrError>().set(
                ErrorType.errorCollaboratorRecord,
                {
                  type: ErrorType.errorCollaboratorRecord,
                  entityId: null,
                  sdId: payload.sidedrawerId,
                  statusCode: error?.error?.statusCode,
                }
              );
              return of(new NetworksCrudAddError({ errors: errors }));
            })
          );
      })
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly networkService: NetworkService,
    private readonly store: Store<AppState>
  ) {}
}
