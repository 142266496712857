import { Component, Inject } from '@angular/core';
import { SdDialogTemplateA11yModule } from '../../../shared/templates/dialog-template-a11y/sd-dialog-template-a11y.module';
import { DictionaryPipeModule } from '../../../dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { DialogTemplateTypes } from '../../../shared/templates/enums/templates.enum';
import { HttpErrorResponse } from '@angular/common/http';
import { SdFlatButtonA11yModule } from '../../../shared/sd-flat-button-a11y/sd-flat-button-a11y.module';
import { AppState } from '../../../reducers';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { QueueItemComponent } from '../queue-item/queue-item.component';
import { SdQueueItem } from '../../models/sd-queue-item.model';
import { queueItemsByStateSelector } from '../../store/queue.selectors';
import { map } from 'rxjs/operators';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-queue-result-dialog',
  standalone: true,
  imports: [
    SdDialogTemplateA11yModule,
    DictionaryPipeModule,
    SdFlatButtonA11yModule,
    QueueItemComponent,
    NgIf,
    AsyncPipe,
    NgForOf,
  ],
  templateUrl: './queue-result-dialog.component.html',
  styleUrls: ['./queue-result-dialog.component.scss'],
})
export class QueueResultDialogComponent {
  dialogTemplateTypes = DialogTemplateTypes;
  queueItemsCompleted$: Observable<SdQueueItem[]> = this.store.select(
    queueItemsByStateSelector({ state: 'success' })
  );
  queueItemsFailed$: Observable<SdQueueItem[]> = this.store.select(
    queueItemsByStateSelector({ state: 'fail' })
  );
  vm$: Observable<{
    itemsCompleted: SdQueueItem[];
    itemsFailed: SdQueueItem[];
  }> = combineLatest([this.queueItemsCompleted$, this.queueItemsFailed$]).pipe(
    map(([itemsCompleted, itemsFailed]) => ({
      itemsCompleted,
      itemsFailed,
    }))
  );

  constructor(
    private readonly dialogRef: MatDialogRef<QueueResultDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { error: HttpErrorResponse },
    private readonly store: Store<AppState>
  ) {}

  onClose(): void {
    this.dialogRef.close();
  }

  trackBy(index: number, item: SdQueueItem): string {
    return item.id;
  }
}
