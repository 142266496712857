import { Locale } from './locale.model';
import { Settings } from '../../account/models/settings.model';

export class Language {
  constructor(
    public languageCode: string,
    public languageName: string,
    public locales: Locale[]
  ) {}

  public static getLocales(languages: Language[]): Locale[] {
    let locales = [];
    if (languages) {
      languages.forEach(language => {
        locales = [...locales, ...language.locales];
      });
    }
    return locales;
  }

  public static getLocaleDefault(language: Language): Locale {
    const localeLanguageDefault = language.locales.find(
      locale => locale.languageDefault === true
    );
    const localeDefault = language.locales.find(
      locale => locale.localeDefault === true
    );
    return localeLanguageDefault
      ? localeLanguageDefault
      : localeDefault
        ? localeDefault
        : language.locales[0];
  }

  public static getUserDefaultLocale(
    languages: Language[],
    settings: Settings
  ): Locale {
    const locales = Language.getLocales(languages);
    const locale = locales.find(
      loc =>
        loc.localeId.toLowerCase() === settings.preferredLanguage.toLowerCase()
    );
    return locale ? locale : locales[0];
  }

  public static getLocaleByLocaleId(
    languages: Language[],
    localeId: string
  ): Locale {
    const locales = Language.getLocales(languages);
    const locale = locales.find(
      loc => loc.localeId.toLowerCase() === localeId.toLowerCase()
    );
    return locale ? locale : locales[0];
  }
  public static getBrowserLocale(languages: Language[]): Locale {
    const browserLang = window.navigator.language.slice(0, 2);
    return Language.getLocalesByLanguageCode(languages, browserLang);
  }
  public static getLocalesByLanguageCode(languages: Language[], getLocalesByLanguageCode: string): Locale {
    const eng = languages.find(lang => lang.languageCode === 'en');
    let myLang = languages.find(lang => lang.languageCode === getLocalesByLanguageCode);
    myLang = myLang ? myLang : eng;
    myLang = new Language(
      myLang.languageCode,
      myLang.languageName,
      myLang.locales
    );
    return Language.getLocaleDefault(myLang);
  }
}
