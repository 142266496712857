import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SdColorPalette } from 'src/app/core/models/enums/sd-color-palette-enum';
import { environment } from 'src/environments/environment';
import { DialogTemplateTypes } from '../../enums/templates.enum';

@Component({
  selector: 'app-sd-dialog-template-a11y',
  templateUrl: './sd-dialog-template-a11y.component.html',
  styleUrls: ['./sd-dialog-template-a11y.component.scss'],
})
export class SdDialogTemplateA11yComponent {
  sdColorPalette = SdColorPalette;
  dialogTemplateTypes = DialogTemplateTypes;
  cdn = environment.cdn;
  @Input() showHeader = true;
  @Input() showBody = true;
  @Input() spinner = false;
  @Input() showFooter = true;
  @Input() type = this.dialogTemplateTypes.Material;
  @Output()
  closeButtonClicked = new EventEmitter<boolean>(null);
  @Input() customMaxWidth: string;
  @Input() role: string;
}
