import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  signal,
  ViewChild,
  WritableSignal,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import WebViewer, { Core, WebViewerInstance } from '@pdftron/webviewer';
import { catchError, map, Observable, of, Subscription, tap } from 'rxjs';
import { Account } from 'src/app/account/models/account.model';
import { accountSelector } from 'src/app/account/store/account.selector';
import { getRecordRole, RecordsRoles } from 'src/app/core/roles/records.roles';
import { currentBrandingSelector } from 'src/app/core/store/core.selectors';
import {
  displayTypeByEnumId,
  localeDefaultSelector,
} from 'src/app/dictionary/store/dictionary.selectors';
import { FilesHelper } from 'src/app/files/helpers/files.helper';
import { FileHistoryDisplayType } from 'src/app/files/models/file-history-display-type.model';
import { FileItem } from 'src/app/files/models/file-item.model';
import { FileType } from 'src/app/files/models/file-type.enum';
import { FileUpload } from 'src/app/files/models/file-upload.model';
import { FilesService } from 'src/app/files/services/files.service';
import { CloudFoldersFilesRequested } from 'src/app/files/store/cloud-folders-files.actions';
import {
  FileHistoryClear,
  FileHistoryRequested,
} from 'src/app/files/store/file-history.actions';
import { fileHistoryByFileName } from 'src/app/files/store/file-history.selector';
import { RecordsService } from 'src/app/records/services/records.service';
import { RecordChangeStatusRequested } from 'src/app/records/store/records-list.actions';
import {
  activeRecordRoleSelector,
  activeRecordSelector,
} from 'src/app/records/store/records-list.selectors';
import { AppState } from 'src/app/reducers';
import { SfrWorkflowStatus } from 'src/app/reminders/models/enums/sfr-workflow-status.enum';
import { DialogTemplateTypes } from 'src/app/shared/templates/enums/templates.enum';
import {
  activeSideDrawerLicensePrioritySelector,
  activeSideDrawerSelector,
} from 'src/app/sidedrawer/store/sidedrawer.selector';
import { environment } from 'src/environments/environment';
import { UploadFileErrorDialogComponent } from '../../upload-file-error-dialog/upload-file-error-dialog.component';
import { UploadFileSucceedDialogComponent } from '../../upload-file-succeed-dialog/upload-file-succeed-dialog.component';
import {
  APP_BASE_HREF,
  AsyncPipe,
  NgClass,
  NgIf,
  PlatformLocation,
} from '@angular/common';
import { DictionaryPipeModule } from 'src/app/dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { SdFlatButtonA11yModule } from 'src/app/shared/sd-flat-button-a11y/sd-flat-button-a11y.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIconModule } from '@angular/material/icon';
import { Record } from 'src/app/records/models/record.model';
import { StyleSheet } from 'src/app/core/models/style-sheet.model';
import { saveAs as importedSaveAs } from 'file-saver';
import { SdSvgA11yModule } from 'src/app/shared/sd-svg-a11y/sd-svg-a11y.module';
import { SdColorPalette } from 'src/app/core/models/enums/sd-color-palette-enum';
import { SdProgressBarA11yComponent } from '../../../../shared/sd-progress-bar-a11y/components/sd-progress-bar-a11y/sd-progress-bar-a11y.component';

@Component({
  selector: 'app-pdftron-viewer-dialog',
  template: `
    <div class="pdftron-viewer-dialog">
      <mat-progress-bar
        [ngClass]="
          uploadSpinner()
            ? 'primary-progress-bar'
            : 'primary-progress-bar invisible'
        "
        [value]="progress()"
        mode="determinate" />

      <div *ngIf="!data?.hideHeader" class="pdftron-viewer-dialog-header">
        <div class="pdftron-viewer-dialog-header-title">
          {{ data.fileItem.uploadTitle }}
        </div>

        <app-sd-svg-a11y
          (click)="onClose()"
          class="pdftron-viewer-dialog-header-button"
          (keydown.enter)="onClose()"
          [color]="sdColorPalette.primaryColor"
          [height]="1.5"
          [setSdAccessibility]="{
            role: 'button',
            ariaLabel: 'globalparams_close' | dictionary | async,
            tabIndex: 0
          }"
          [src]="cdn + ('globalparams_closeicon' | dictionary | async)"
          [width]="1.5"></app-sd-svg-a11y>
      </div>

      <div class="pdftron-viewer-dialog-content">
        <div *ngIf="spinner()" class="pdftron-viewer-dialog-spinner">
          <mat-spinner
            color="primary"
            diameter="20"
            mode="indeterminate"></mat-spinner>
        </div>

        <div
          *ngIf="loadError && spinner() === false"
          [innerHTML]="'pdftronviewerdialog_body' | dictionary | async"
          class="pdftron-viewer-dialog-spinner pdftron-viewer-dialog-error"></div>

        <div #viewer class="web-viewer"></div>
      </div>

      <div
        *ngIf="spinner() === false && !loadError"
        class="pdftron-viewer-dialog-footer">
        <app-sd-flat-button-a11y
          (buttonClicked)="onDownloadOriginal()"
          *ngIf="displayType().displaytype_showbuttondownloadoriginal"
          [loading]="downloadOriginalSpinner()"
          [primary]="false"
          >{{ displayType().displaytype_labelbuttondownloadoriginal }}
        </app-sd-flat-button-a11y>

        <app-sd-flat-button-a11y
          (buttonClicked)="onDownloadAsPDF()"
          *ngIf="displayType().displaytype_showbuttondownloadpdf"
          [loading]="downloadPDFSpinner()"
          [primary]="false"
          >{{ displayType().displaytype_labelbuttondownloadpdf }}
        </app-sd-flat-button-a11y>

        <app-sd-flat-button-a11y
          (buttonClicked)="onPrint()"
          *ngIf="displayType().displaytype_showbuttonprint"
          [primary]="false"
          >{{ displayType().displaytype_labelbuttonprint }}
        </app-sd-flat-button-a11y>

        <app-sd-flat-button-a11y
          (buttonClicked)="onUploadPDF()"
          *ngIf="displayType().displaytype_showbuttonsave"
          [disabled]="disableSignButton() | async"
          [loading]="uploadSpinner()"
          [primary]="true"
          >{{ displayType().displaytype_labelbuttonsave }}
        </app-sd-flat-button-a11y>
      </div>

      <div
        *ngIf="spinner() === false && loadError"
        class="pdftron-viewer-dialog-footer">
        <app-sd-flat-button-a11y (buttonClicked)="onClose()" [primary]="false"
          >{{ 'pdftronviewerdialog_close' | dictionary | async }}
        </app-sd-flat-button-a11y>
      </div>
    </div>
  `,
  styleUrls: ['./pdftron-viewer-dialog.component.scss'],
  standalone: true,
  imports: [
    SdProgressBarA11yComponent,
    AsyncPipe,
    DictionaryPipeModule,
    NgIf,
    SdFlatButtonA11yModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatIconModule,
    NgClass,
    SdSvgA11yModule,
  ],
  providers: [
    {
      provide: APP_BASE_HREF,
      useFactory: (s: PlatformLocation) => s.getBaseHrefFromDOM(),
      deps: [PlatformLocation],
    },
  ],
})
export class PdftronViewerDialogComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  dataSrc: WritableSignal<string>;
  spinner = signal(true);
  uploadSpinner = signal(false);
  downloadPDFSpinner = signal(false);
  downloadOriginalSpinner = signal(false);
  progress = signal(0);
  styleSheet: StyleSheet;
  @ViewChild('viewer', { static: false }) viewer: ElementRef;
  instance: WebViewerInstance;
  annotationManager: Core.AnnotationManager;
  displayType = this.store.selectSignal(
    displayTypeByEnumId({ enumId: this.data.fileItem.displayType })
  );
  currentUser = this.store.selectSignal(accountSelector);
  originalBlob: Blob;
  loadError = false;
  dialogTemplateTypes = DialogTemplateTypes;
  subscription = new Subscription();
  sdColorPalette = SdColorPalette;
  cdn = environment.cdn;

  constructor(
    private readonly dialogRef: MatDialogRef<PdftronViewerDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      fileItem: FileItem;
      file: File;
      extension: string;
      hideHeader?: boolean;
    },
    @Inject(APP_BASE_HREF) private _baseHref: string,
    private readonly dialog: MatDialog,
    protected readonly store: Store<AppState>,
    private readonly filesService: FilesService,
    private readonly recordsService: RecordsService,
    private readonly changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.styleSheet = this.store.selectSignal(
      currentBrandingSelector
    )()?.styleSheet;
  }

  ngAfterViewInit(): void {
    if (this.data.file) {
      this.originalBlob = new Blob([this.data.file], {
        type: this.data.file.type,
      });
      this.dataSrc = signal(window.URL.createObjectURL(this.originalBlob));
      this.initWebViewer();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  initWebViewer(): void {
    try {
      WebViewer(
        {
          path: `${this._baseHref === '/' ? '' : this._baseHref}assets/lib`,
          initialDoc: this.dataSrc(),
          extension: this.data.extension,
          css: `${location.origin + this._baseHref}assets/fonts/pdftron.css`,
          fullAPI: true,
          licenseKey: environment.pdfTronLicense,
          disableObjectURLBlobs: false,
          enableOfficeEditing: false,
        },
        this.viewer.nativeElement
      )
        .then(async (instance: WebViewerInstance) => {
          this.instance = instance;
          // en - fr only
          await this.instance.UI.setLanguage(
            this.store
              .selectSignal(localeDefaultSelector)()
              .localeId.includes('fr')
              ? 'fr'
              : 'en'
          );
          this.instance.Core.enableFullPDF();
          this.annotationManager = this.instance.Core.annotationManager;
          this.annotationManager.setCurrentUser(
            Account.fullName(this.currentUser())
          );
          if (this.data.extension === 'pdf') {
            this.instance.Core.disableEmbeddedJavaScript();
            const xfdfString =
              await this.instance.Core.annotationManager.exportAnnotations();
            const saveOptions = this.instance.Core.SaveOptions;
            const data = await this.instance.Core.documentViewer
              .getDocument()
              .getFileData({
                xfdfString,
                flags: saveOptions.LINEARIZED,
                downloadType: 'pdf',
                printDocument: true,
              });
            const arr = new Uint8Array(data);
            const blob = new Blob([arr], { type: 'application/pdf' });
            this.dataSrc.set(window.URL.createObjectURL(blob));
            await this.instance.UI.closeDocument();
            this.instance.UI.loadDocument(this.dataSrc(), {
              extension: this.data.extension,
              loadAnnotations: true,
            });
            this.annotationManager.setCurrentUser(
              Account.fullName(this.currentUser())
            );
          }
          // change Styles
          this.setStyles();
          // hide Insert toolbar groups.
          this.customizeViewerHeader();
          // change zoom level
          this.instance.UI.setFitMode(this.instance.UI.FitMode.FitWidth);
        })
        .catch(initWebViewerError => {
          console.warn({ initWebViewerError });
        });
    } catch (linearizePDF) {
      console.warn({ linearizePDF });
    }
  }

  setStyles(): void {
    const style = this.instance.UI.iframeWindow.document.documentElement.style;
    style.setProperty(`--blue-1`, this.styleSheet.headerBackground);
    style.setProperty(`--blue-2`, this.styleSheet.headerBackground);
    style.setProperty(`--blue-3`, this.styleSheet.tertiaryAccentColor);
    style.setProperty(`--blue-4`, this.styleSheet.tertiaryAccentColor);
    style.setProperty(`--blue-5`, this.styleSheet.primaryColor);
    style.setProperty(`--blue-6`, this.styleSheet.primaryAccentColor);
    style.setProperty(`--gray-0`, this.styleSheet.appBackground);
    style.setProperty(`--gray-1`, this.styleSheet.appBackground);
    style.setProperty(`--gray-2`, this.styleSheet.appBackground);
    style.setProperty(`--gray-3`, this.styleSheet.backgroundShade);
    style.setProperty(`--gray-4`, this.styleSheet.backgroundShade);
    style.setProperty(`--gray-5`, this.styleSheet.tertiaryAccentColor);
    style.setProperty(`--gray-6`, this.styleSheet.tertiaryAccentColor);
    style.setProperty(`--gray-7`, this.styleSheet.secondaryAccentColor);
    style.setProperty(`--gray-8`, this.styleSheet.secondaryAccentColor);
    style.setProperty(`--gray-9`, this.styleSheet.primaryAccentColor);
    style.setProperty(`--gray-10`, this.styleSheet.primaryAccentColor);
    style.setProperty(`--gray-11`, this.styleSheet.appCameraBackground);
    style.setProperty(`--gray-12`, this.styleSheet.appCameraBackground);
    style.setProperty(
      `--yellow-1`,
      this.styleSheet.primaryColorSemiTransparent
    );
    style.setProperty(`--red`, this.styleSheet.alertsErrors);
    style.setProperty(`font-family`, this.styleSheet.fontFamily);
  }

  customizeViewerHeader(): void {
    this.instance.UI.setSignatureFonts(() => ['Dancing Script']);
    this.instance.Core.documentViewer.addEventListener('documentLoaded', () => {
      try {
        const iframe = this.instance.UI.iframeWindow.document;
        const ribbons = iframe.querySelector('.ribbons');
        const viewGroup = iframe.querySelector(
          '[data-element="toolbarGroup-View"]'
        );
        const annotateGroup = iframe.querySelector(
          '[data-element="toolbarGroup-Annotate"]'
        );
        const shapesGroup = iframe.querySelector(
          '[data-element="toolbarGroup-Shapes"]'
        );
        const insertGroup = iframe.querySelector(
          '[data-element="toolbarGroup-Insert"]'
        );
        const editGroup = iframe.querySelector(
          '[data-element="toolbarGroup-Edit"]'
        );
        if (viewGroup) {
          viewGroup.textContent = this.displayType().displaytype_labelmenuview;
        }
        if (annotateGroup) {
          annotateGroup.textContent =
            this.displayType().displaytype_labelmenuannotate;
        }
        if (shapesGroup) {
          shapesGroup.textContent =
            this.displayType().displaytype_labelmenushapes;
        }
        if (insertGroup) {
          insertGroup.textContent =
            this.displayType().displaytype_labelmenuinsert;
        }
        if (editGroup) {
          editGroup.textContent = this.displayType().displaytype_labelmenuedit;
        }
        const ribbonsChildren = Object.values(ribbons.children);
        ribbons.innerHTML = '';
        const ribbonsOrder = [
          'toolbarGroup-View',
          'toolbarGroup-Annotate',
          'toolbarGroup-Insert',
          'toolbarGroup-Shapes',
          'toolbarGroup-View',
          'toolbarGroup-Edit',
        ];
        ribbonsOrder.forEach(group => {
          const groupElement = ribbonsChildren.find(
            el => (el as HTMLElement)?.dataset?.element === group
          );
          if (groupElement) {
            ribbons.appendChild(groupElement);
          }
        });
        (viewGroup as HTMLElement)?.click();
        const disableDataElements = ['menuButton', 'stampToolGroupButton'];
        if (!this.displayType().displaytype_showmenuview) {
          disableDataElements.push('toolbarGroup-View');
        }
        if (!this.displayType().displaytype_showmenuannotate) {
          disableDataElements.push('toolbarGroup-Annotate');
        }
        if (!this.displayType().displaytype_showmenuinsert) {
          disableDataElements.push('toolbarGroup-Insert');
        }
        if (!this.displayType().displaytype_showmenushapes) {
          disableDataElements.push('toolbarGroup-Shapes');
        }
        if (!this.displayType().displaytype_showmenuinsertstamp) {
          disableDataElements.push('rubberStampToolGroupButton');
        }
        if (!this.displayType().displaytype_showmenuinsertsignature) {
          disableDataElements.push('signatureToolGroupButton');
        }
        if (!this.displayType().displaytype_showmenuinsertattachment) {
          disableDataElements.push('fileAttachmentToolGroupButton');
        }
        if (!this.displayType().displaytype_showmenuinsertcallout) {
          disableDataElements.push('calloutToolGroupButton');
        }
        if (!this.displayType().displaytype_showmenuedit) {
          disableDataElements.push('toolbarGroup-Edit');
        }
        this.instance.UI.disableElements([...disableDataElements]);
        (Object.values(ribbons?.children)?.[0] as HTMLElement)?.click();
        if (!this.displayType().displaytype_addstampenvelopeid) {
          this.spinner.set(false);
          this.changeDetector.detectChanges();
          return;
        }
        this.setStampOnThePDF().then(() => {
          this.spinner.set(false);
          this.changeDetector.detectChanges();
        });
      } catch (customizeError) {
        console.warn({ customizeError });
        this.handleLoadError();
      }
    });

    this.instance.UI.addEventListener('loaderror', loadError => {
      console.warn({ loadError });
      this.handleLoadError();
    });
  }

  async setStampOnThePDF(): Promise<void> {
    const { documentViewer, PDFNet } = this.instance.Core;
    await PDFNet.initialize();
    const doc = await documentViewer.getDocument().getPDFDoc();
    if (!doc) {
      return;
    }
    // Run PDFNet methods with memory management
    await PDFNet.runWithCleanup(async () => {
      // lock the document before a write operation
      // runWithCleanup will auto unlock when complete
      await doc.lock();
      const s = await PDFNet.Stamper.create(
        PDFNet.Stamper.SizeType.e_relative_scale,
        0.35,
        0.5
      );
      // Image Stamp
      const img = await PDFNet.Image.createFromURL(
        doc,
        '/assets/img/env_id_background.png'
      );
      await s.setAsBackground(false);
      const pgSetImage = await PDFNet.PageSet.createRange(
        1,
        await doc.getPageCount()
      );
      await s.setAlignment(
        PDFNet.Stamper.HorizontalAlignment.e_horizontal_left,
        PDFNet.Stamper.VerticalAlignment.e_vertical_top
      );
      await s.stampImage(doc, img, pgSetImage);
      // Text Stamp
      await s.setAlignment(
        PDFNet.Stamper.HorizontalAlignment.e_horizontal_left,
        PDFNet.Stamper.VerticalAlignment.e_vertical_top
      );
      const font = await PDFNet.Font.create(
        doc,
        PDFNet.Font.StandardType1Font.e_helvetica
      );
      await s.setFont(font);
      const redColorPt = await PDFNet.ColorPt.init(0, 0, 0, 0);
      await s.setFontColor(redColorPt);
      await s.setTextAlignment(PDFNet.Stamper.TextAlignment.e_align_right);
      await s.setAsBackground(false);
      const pgSet = await PDFNet.PageSet.createRange(
        1,
        await doc.getPageCount()
      );
      await s.stampText(
        doc,
        `${this.displayType().displaytype_labelstampenvelopeid}${
          this.data.fileItem.envelopeId
        }`,
        pgSet
      );
    }, environment.pdfTronLicense);

    await PDFNet.finishOperation();

    // clear the cache (rendered) data with the newly updated document
    documentViewer.refreshAll();

    // Update viewer to render with the new document
    documentViewer.updateView([0], 0);

    // Refresh searchable and selectable text data with the new document
    documentViewer.getDocument().refreshTextData();
  }

  async onUploadPDF(): Promise<void> {
    this.uploadSpinner.set(true);
    const blob = await this.getBlobFromPDF();
    const newFileUpload = new FileUpload();
    newFileUpload.displayType = this.data.fileItem.displayType
      ? this.data.fileItem.displayType
      : FileHistoryDisplayType.review;
    const fileName = FilesHelper.getFileNameWithOutExtension(
      this.data.fileItem
    );
    const uploadTitle =
      newFileUpload.displayType !== FileHistoryDisplayType.sign
        ? fileName
        : fileName +
          ` - ${this.currentUser().firstName} ${this.currentUser().lastName}`;
    newFileUpload.id = 0;
    newFileUpload.file = FilesHelper.blobToFile(blob, uploadTitle + `.pdf`);
    newFileUpload.uploadTitle = uploadTitle;
    newFileUpload.fileType = FileType.document;
    this.uploadPDF(newFileUpload);
  }

  uploadPDF(fileUpload: FileUpload): void {
    const activeRecord = this.store.selectSignal(activeRecordSelector)();
    const activeSD = this.store.selectSignal(activeSideDrawerSelector)();
    const licensePriority = this.store.selectSignal(
      activeSideDrawerLicensePrioritySelector
    )();
    const roles = this.store.selectSignal(activeRecordRoleSelector)();
    this.progress.set(0);
    this.filesService
      .uploadFile(fileUpload, fileUpload.fileType, activeSD.id, activeRecord.id)
      .subscribe({
        next: (response: {
          message: number;
          status: string;
          fileItem?: FileItem;
        }) => {
          this.progress.set(response.message);
          if (
            response.status === 'complete' &&
            activeRecord.status === SfrWorkflowStatus.sfrNew
          ) {
            this.updateSfrRecordStatus(activeSD.id, activeRecord);
          }
          if (
            response.status === 'complete' &&
            fileUpload.displayType === FileHistoryDisplayType.sign
          ) {
            this.signFileHistory(activeSD.id, activeRecord.id, response.fileItem);
          }
          if (
            response.status === 'complete' &&
            fileUpload.displayType !== FileHistoryDisplayType.sign
          ) {
            this.updateRecordFileHistory(activeSD.id, activeRecord.id);
            this.subscription.add(
              this.dialog
                .open(UploadFileSucceedDialogComponent, {
                  autoFocus: false,
                  data: {
                    displayType: this.displayType(),
                  },
                })
                .afterClosed()
                .subscribe(() => {
                  this.dialogRef.close();
                })
            );
          }
        },
        error: error => {
          this.uploadSpinner.set(false);
          console.error({ error });
          this.updateRecordFileHistory(activeSD.id, activeRecord.id);
          if (error?.error?.statusCode === 403) {
            this.recordsService.displayErrorDialog(
              licensePriority,
              getRecordRole(roles as RecordsRoles[]),
              this.dialog,
              error
            );
            this.dialogRef.close();
            return;
          }
          this.subscription.add(
            this.dialog
              .open(UploadFileErrorDialogComponent, {
                autoFocus: false,
              })
              .afterClosed()
              .subscribe(() => {
                this.dialogRef.close();
              })
          );
        },
      });
  }

  updateRecordFileHistory(sideDrawerId: string, recordId: string): void {
    this.store.dispatch(new FileHistoryClear());
    this.store.dispatch(
      new FileHistoryRequested({
        sideDrawerId,
        recordId,
      })
    );
    this.store.dispatch(
      new CloudFoldersFilesRequested({
        sideDrawerId,
        recordId,
      })
    );
  }

  signFileHistory(
    sidedrawerId: string,
    recordId: string,
    newFile: FileItem
  ): void {
    const name = `${newFile.fileName}.${newFile.fileExtension}`;
    this.filesService
      .sealFile(sidedrawerId, recordId, name)
      .pipe(
        catchError(() => of(null)),
        tap({
          next: () => {
            this.updateRecordFileHistory(sidedrawerId, recordId);
            this.subscription.add(
              this.dialog
                .open(UploadFileSucceedDialogComponent, {
                  autoFocus: false,
                  data: {
                    data: {
                      displayType: this.displayType,
                    },
                  },
                })
                .afterClosed()
                .subscribe(() => {
                  this.dialogRef.close();
                })
            );
          },
          error: () => {
            this.uploadSpinner.set(false);
            this.updateRecordFileHistory(sidedrawerId, recordId);
            this.subscription.add(
              this.dialog
                .open(UploadFileErrorDialogComponent, { autoFocus: false })
                .afterClosed()
                .subscribe(() => {
                  this.dialogRef.close();
                })
            );
          },
        })
      )
      .subscribe();
  }

  async onDownloadAsPDF(): Promise<void> {
    this.downloadPDFSpinner.set(true);
    const blob = await this.getBlobFromPDF();
    importedSaveAs(
      blob,
      FilesHelper.getFileNameWithOutExtension(this.data.fileItem)
    );
    this.downloadPDFSpinner.set(false);
  }

  protected async getBlobFromPDF(): Promise<Blob> {
    const doc = this.instance.Core.documentViewer.getDocument();
    const annotationManager =
      this.instance.Core.documentViewer.getAnnotationManager();
    const xfdfString = await annotationManager.exportAnnotations();
    const saveOptions = this.instance.Core.SaveOptions;
    const data = await doc.getFileData({
      xfdfString,
      flags: saveOptions.LINEARIZED,
      downloadType: 'pdf',
    });
    const arr = new Uint8Array(data);
    return new Blob([arr], { type: 'application/pdf' });
  }

  async onDownloadOriginal(): Promise<void> {
    this.downloadOriginalSpinner.set(true);
    importedSaveAs(
      this.originalBlob,
      this.data.fileItem.fileType === FileType.cloud
        ? this.data.fileItem.fileName
        : FilesHelper.getFileNameWithExtension(
            this.data.fileItem.uploadTitle,
            this.data.fileItem
          )
    );
    this.downloadOriginalSpinner.set(false);
  }

  onPrint(): void {
    this.instance.UI.print();
  }

  onClose(): void {
    this.spinner.set(true);
    try {
      this.instance.UI.closeDocument()
        .then(() => {
          this.instance.Core.documentViewer.dispose();
          this.dialogRef.close();
        })
        .catch(() => {
          this.instance.Core.documentViewer.dispose();
          this.dialogRef.close();
        });
    } catch (e) {
      console.error({ e });
      this.dialogRef.close();
    }
  }

  handleLoadError(): void {
    this.spinner.set(false);
    this.loadError = true;
  }

  disableSignButton(): Observable<boolean> {
    if (this.data.fileItem.displayType === FileHistoryDisplayType.sign) {
      const uploadTitle =
        FilesHelper.getFileNameWithOutExtension(this.data.fileItem) +
        ` - ${this.currentUser().firstName} ${this.currentUser().lastName}`;
      return this.store.pipe(
        select(
          fileHistoryByFileName({
            fileName: FilesHelper.generateFileName(uploadTitle, false),
          })
        ),
        map(file => !!file)
      );
    }
    return of(false);
  }

  // TODO DUPLICATED CODE
  private updateSfrRecordStatus(sidedrawerId: string, record: Record): void {
    this.store.dispatch(
      new RecordChangeStatusRequested({
        record: record,
        sideDrawerId: sidedrawerId,
        status: SfrWorkflowStatus.sfrComplete,
      })
    );
  }
}
