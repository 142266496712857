import { Component } from '@angular/core';
import {
  AsyncPipe,
  NgClass,
  NgFor,
  NgIf,
  TitleCasePipe,
} from '@angular/common';
import { combineLatest, Observable, switchMap } from 'rxjs';
import { FileItem } from '../../models/file-item.model';
import { SdRecordTabHeaderComponent } from '../../../shared/sd-record-tab-header/sd-record-tab-header.component';
import { DictionaryPipeModule } from '../../../dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { Store } from '@ngrx/store';
import {
  fileListByRecordIdSelector,
  gettingFilesSelector,
  hasMoreFilesSelector,
} from '../../store/file-history.selector';
import { gettingCloudFoldersFilesSelector } from '../../store/cloud-folders-files.selectors';
import { AppState } from '../../../reducers';
import { isTenantUser } from '../../../auth/store/auth.selectors';
import { map, take, tap } from 'rxjs/operators';
import { RecordFormViewStore } from '../../../records/views/record-form-view/record-form-view.store';
import { SdLinkButtonA11yModule } from '../../../shared/sd-link-button-a11y/sd-link-button-a11y.module';
import { SdLinkButtonA11yTemplates } from '../../../shared/sd-link-button-a11y/model/sd-link-button-a11y.enum';
import { FileHistoryNextPageRequested } from '../../store/file-history.actions';
import { SdUploadButtonA11yComponent } from '../../../shared/sd-upload-button-a11y/sd-upload-button-a11y.component';
import { environment } from '../../../../environments/environment';
import { CloudProvider } from '../../../integrations/models/cloud-provider.model';
import { activeSideDrawerCloudIntegrations } from '../../../sidedrawer/store/sidedrawer.selector';
import { Record } from '../../../records/models/record.model';
import { QueueHelper } from '../../../queue/helpers/queue.helper';
import { QueueActions } from '../../../queue/store/queue.actions';
import { FilesService } from '../../services/files.service';
import { IntegrationsSectionItemComponent } from '../../../integrations/shared/integrations-section-item/integrations-section-item.component';
import { SyncFromCloudDialogComponent } from '../../../integrations/shared/sync-from-cloud-dialog/sync-from-cloud-dialog.component';
import { activeRecordSelector } from '../../../records/store/records-list.selectors';
import { ActivatedRoute } from '@angular/router';
import { DragAndDropModule } from '../../directives/drag-and-drop/drag-and-drop.module';
import { SdSortButtonA11yComponent } from 'src/app/shared/sd-sort-button-a11y/components/sd-sort-button-a11y/sd-sort-button-a11y.component';
import { SdEmptyTemplateComponent } from 'src/app/shared/templates/sd-empty-template/components/sd-empty-template/sd-empty-template.component';
import { SdProgressBarA11yComponent } from 'src/app/shared/sd-progress-bar-a11y/components/sd-progress-bar-a11y/sd-progress-bar-a11y.component';
import { FileHistorySectionItemComponent } from '../../shared/file-history-section-item/file-history-section-item.component';
import { FileHistorySortingDialogComponent } from '../../shared/file-history-sorting-dialog/file-history-sorting-dialog.component';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { FileHistorySectionComponent } from '../../shared/file-history-section/file-history-section.component';

@Component({
  selector: 'app-record-form-files-tab',
  standalone: true,
  imports: [
    SdProgressBarA11yComponent,
    SdRecordTabHeaderComponent,
    FileHistorySectionItemComponent,
    DictionaryPipeModule,
    SdLinkButtonA11yModule,
    SdUploadButtonA11yComponent,
    IntegrationsSectionItemComponent,
    SdSortButtonA11yComponent,
    DragAndDropModule,
    SdEmptyTemplateComponent,
    NgIf,
    NgClass,
    AsyncPipe,
    TitleCasePipe,
    MatDialogModule,
    NgFor,
    FileHistorySectionComponent,
  ],
  templateUrl: './record-form-files-tab.component.html',
  styleUrls: ['./record-form-files-tab.component.scss'],
})
export class RecordFormFilesTabComponent {
  vm$: Observable<{
    gettingInformation: boolean;
    fileItems: FileItem[];
    isTenantUser: boolean;
    hasMore: boolean;
    providers: CloudProvider[];
  }> = combineLatest([
    this.store.select(gettingFilesSelector),
    this.store.select(gettingCloudFoldersFilesSelector),
    this.store
      .select(activeRecordSelector)
      .pipe(
        switchMap(activeRecord =>
          this.store.select(
            fileListByRecordIdSelector({ recordId: activeRecord?.id })
          )
        )
      ),
    this.store.select(isTenantUser),
    this.store.select(hasMoreFilesSelector),
    this.store.select(activeSideDrawerCloudIntegrations),
  ]).pipe(
    map(
      ([
        gettingFiles,
        gettingCloudFolders,
        fileItems,
        isTenantUser,
        hasMore,
        providers,
      ]) => ({
        gettingInformation: gettingFiles || gettingCloudFolders,
        fileItems,
        isTenantUser,
        hasMore,
        providers,
      })
    )
  );
  SdLinkButtonA11yTemplates = SdLinkButtonA11yTemplates;
  cdn = environment.cdn;
  isSorted = false;
  idForDragAndDrop: string;
  readonly suffixForDragAndDrop =
    'record-form-files-tab-suffix-for-drag-and-drop';

  activeRecord = this.store.selectSignal(activeRecordSelector);
  paramRecordId = this.route.snapshot?.params?.recordId;

  constructor(
    private readonly store: Store<AppState>,
    private readonly dialog: MatDialog,
    private readonly recordFormViewStore: RecordFormViewStore,
    private readonly filesService: FilesService,
    private readonly route: ActivatedRoute
  ) {
    const { params } = this.route.snapshot;
    this.idForDragAndDrop = params?.recordId + this.suffixForDragAndDrop;
  }

  trackBy(index: number, item: FileItem): string {
    return item.id;
  }

  onLoadMore(event): void {
    event.preventDefault();
    this.store.dispatch(new FileHistoryNextPageRequested());
  }

  onDriveClicked(provider: string): void {
    this.recordFormViewStore.getOrCreateRecord((record: Record) => {
      this.dialog
        .open(SyncFromCloudDialogComponent, {
          autoFocus: 'button',
          data: {
            record,
            provider,
          },
        })
        .afterClosed()
        .pipe(
          tap(() => {
            // TODO: refresh the files list
          })
        )
        .subscribe();
    });
  }

  onFilesLoaded(fileList: FileList): void {
    this.recordFormViewStore.getOrCreateRecord((record: Record) => {
      QueueHelper.generateQueueItems$(
        fileList,
        record,
        this.store,
        this.filesService
      )
        .pipe(
          take(1),
          tap(queueItems => {
            queueItems.forEach(item =>
              this.store.dispatch(QueueActions.itemAdded({ item }))
            );
            this.store.dispatch(QueueActions.startProcess());
          })
        )
        .subscribe();
    });
  }

  onSort(): void {
    this.dialog
      .open(FileHistorySortingDialogComponent, { autoFocus: false })
      .afterClosed()
      .subscribe();
  }
}
