import { ActionReducerMap } from '@ngrx/store';
import { plansListReducer, PlansListState } from './plans-list.reducer';
import { plansViewsReducer, PlansViewsState } from './plans-views.reducer';

export const featureName = 'plans';

export interface PlansState {
  list: PlansListState;
  views: PlansViewsState;
}

export const plansReducer: ActionReducerMap<PlansState> = {
  list: plansListReducer,
  views: plansViewsReducer,
};
