import { PrimaryResidence } from './primary-residence.model';
import { Email } from './email.model';
import { Phone } from './phone.model';
import { Agreement } from './agreement.model';
import { Settings } from './settings.model';

export class Account {
  constructor(
    public username?: string,
    public firstName?: string,
    public lastName?: string,
    public profilePhoto?: string,
    public dateOfBirth?: string,
    public maritalStatus?: string,
    public primaryResidence?: PrimaryResidence,
    public gender?: string,
    public emails?: Email[],
    public phones?: Phone[],
    public settings?: Settings,
    public agreements?: Agreement[],
    public id?: string,
    public openId?: string,
    public customerId?: string,
    public defaultSidedrawer?: string,
    public dataBaseRegion?: string,
    public region?: string,
    public brandCodeAffiliations?: string[],
    public identityProvider?: string
  ) {}

  static fullName(account: Account): string {
    return `${account?.firstName} ${account?.lastName}`;
  }
}
