import { Action } from '@ngrx/store';
import { SideDrawerNetworksRequest } from '../models/side-drawer-network-request.model';

export enum SideDrawerNetworksRequestsInfiniteListActionsTypes {
  SideDrawerNetworksInfiniteListInitialPageLoaded = '[] SideDrawerNetworks Infinite List Initial Page Loaded',
  SideDrawerNetworksInfiniteListRequested = '[] SideDrawerNetworks Infinite List Requested',
  SideDrawerNetworksInfiniteListNextPageRequested = '[] SideDrawerNetworks Infinite List Next Page Requested',
  SideDrawerNetworksInfiniteListPageLoaded = '[SideDrawerNetworks Request Effects] SideDrawerNetworks Infinite List Page Loaded',
  SideDrawerNetworksInfiniteListOrderLoaded = '[SideDrawerNetworks Request Effects] SideDrawerNetworks Infinite List Order Loaded',
  GettingSideDrawerNetworksRequestsInfiniteListChange = '[SideDrawerNetworks Request Effects] Getting SideDrawer Networks Requests Infinite List Change',
}

export class SideDrawerNetworksInfiniteListInitialPageLoaded implements Action {
  readonly type =
    SideDrawerNetworksRequestsInfiniteListActionsTypes.SideDrawerNetworksInfiniteListInitialPageLoaded;

  constructor(public payload: { result: SideDrawerNetworksRequest }) {}
}

export class SideDrawerNetworksInfiniteListRequested implements Action {
  readonly type =
    SideDrawerNetworksRequestsInfiniteListActionsTypes.SideDrawerNetworksInfiniteListRequested;

  constructor(public payload?: { requestId?: string }) {}
}

export class SideDrawerNetworksInfiniteListNextPageRequested implements Action {
  readonly type =
    SideDrawerNetworksRequestsInfiniteListActionsTypes.SideDrawerNetworksInfiniteListNextPageRequested;
}

export class SideDrawerNetworksInfiniteListPageLoaded implements Action {
  readonly type =
    SideDrawerNetworksRequestsInfiniteListActionsTypes.SideDrawerNetworksInfiniteListPageLoaded;

  constructor(public payload: { result: SideDrawerNetworksRequest }) {}
}

export class SideDrawerNetworksInfiniteListOrderLoaded implements Action {
  readonly type =
    SideDrawerNetworksRequestsInfiniteListActionsTypes.SideDrawerNetworksInfiniteListOrderLoaded;

  constructor(public payload: { infiniteListOrder: string[] }) {}
}

export class GettingSideDrawerNetworksRequestsInfiniteListChange
  implements Action
{
  readonly type =
    SideDrawerNetworksRequestsInfiniteListActionsTypes.GettingSideDrawerNetworksRequestsInfiniteListChange;

  constructor(public payload: { state: boolean }) {}
}

export type SideDrawerNetworksRequestsInfiniteListActions =
  | SideDrawerNetworksInfiniteListInitialPageLoaded
  | SideDrawerNetworksInfiniteListRequested
  | SideDrawerNetworksInfiniteListNextPageRequested
  | SideDrawerNetworksInfiniteListPageLoaded
  | SideDrawerNetworksInfiniteListOrderLoaded
  | GettingSideDrawerNetworksRequestsInfiniteListChange;
