import { Action } from '@ngrx/store';
import { FileItem } from '../models/file-item.model';

export enum CloudFoldersFilesActionsTypes {
  CloudFoldersFilesRequested = '[] CloudFoldersFiles Requested',
  CloudFoldersFilesLoaded = '[CloudFoldersFiles Effect] CloudFoldersFiles Loaded',
  CloudFoldersFilesClear = '[] CloudFoldersFiles Clear',
  GettingCloudFoldersFilesChange = '[CloudFoldersFiles Effect] Getting CloudFoldersFiles Change',
}

export class CloudFoldersFilesRequested implements Action {
  readonly type = CloudFoldersFilesActionsTypes.CloudFoldersFilesRequested;

  constructor(public payload: { sideDrawerId: string; recordId: string }) {}
}

export class CloudFoldersFilesLoaded implements Action {
  readonly type = CloudFoldersFilesActionsTypes.CloudFoldersFilesLoaded;

  constructor(public payload: { fileItems: Partial<FileItem>[] }) {}
}

export class CloudFoldersFilesClear implements Action {
  readonly type = CloudFoldersFilesActionsTypes.CloudFoldersFilesClear;
}

export class GettingCloudFoldersFilesChange implements Action {
  readonly type = CloudFoldersFilesActionsTypes.GettingCloudFoldersFilesChange;

  constructor(public payload: { state: boolean }) {}
}

export type CloudFoldersFilesActions =
  | CloudFoldersFilesRequested
  | CloudFoldersFilesLoaded
  | CloudFoldersFilesClear
  | GettingCloudFoldersFilesChange;
