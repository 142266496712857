import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import * as moment from 'moment';
import { CommonModule } from '@angular/common';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { SdInputA11yTemplates } from 'src/app/shared/sd-input-a11y/models/sd-input-a11y-templates.enum';
import { SdTooltipButtonA11yComponent } from 'src/app/shared/sd-tooltip-button-a11y/component/sd-tooltip-button-a11y/sd-tooltip-button-a11y.component';
import { DictionaryPipeModule } from 'src/app/dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'app-sd-time-a11y',
  templateUrl: './sd-time-a11y.component.html',
  styleUrls: ['./sd-time-a11y.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  
  imports: [
    CommonModule,
    NgxMatTimepickerModule,
    ReactiveFormsModule,
    SdTooltipButtonA11yComponent,
    MatFormFieldModule,
    MatInputModule,
    DictionaryPipeModule,
  ],
})
export class SdTimeA11yComponent implements OnInit, AfterViewChecked {
  @Input() error: string;
  @Input() controller: UntypedFormControl;
  @Input() placeholder: string;
  @Input() tooltip: string;
  @Input() disabled = false;
  sdInputA11yTemplates = SdInputA11yTemplates;
  @Input() template = SdInputA11yTemplates.inputDefault;
  @Output() data = new EventEmitter<string>();
  defaultValue: string;

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.defaultValue = this.controller.value
      ? this.controller.value
      : moment().format('hh:mm A');
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.markForCheck();
  }
}
