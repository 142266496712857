import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SdLinkButtonA11yTemplates } from '../../model/sd-link-button-a11y.enum';

@Component({
  selector: 'app-sd-link-button-a11y',
  templateUrl: './sd-link-button-a11y.component.html',
  styleUrls: ['./sd-link-button-a11y.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SdLinkButtonA11yComponent {
  @Input() href: string;
  @Input() tabIndex: number;
  @Input() ariaLabel: string;
  @Input() template: SdLinkButtonA11yTemplates;
  @Input() target: string;
  sdLinkButtonA11yTemplates = SdLinkButtonA11yTemplates;
}
