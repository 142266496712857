<app-sd-dialog-template-a11y
  (closeButtonClicked)="onClose()"
  [role]="'alert'"
  [type]="dialogTemplateTypes.Info">
  <ng-container dialog-header>
    <h2 class="base-dialog-title">
      {{ 'inactivitytimedialog_errorexpiretitle' | dictionary | async }}
    </h2>
  </ng-container>
  <ng-container dialog-body>
    <div
      [innerHTML]="'inactivitytimedialog_errorexpirebody' | dictionary | async"
      class="base-dialog-content"></div>
  </ng-container>
  <ng-container dialog-footer>
    <app-sd-flat-button-a11y
      (buttonClicked)="onClose()"
      [primary]="false"
      [setSdAccessibility]="{
        ariaLabel: 'inactivitytimedialog_secondarybutton' | dictionary | async
      }"
      class="secondary">
      {{ 'inactivitytimedialog_secondarybutton' | dictionary | async }}
    </app-sd-flat-button-a11y>
    <app-sd-flat-button-a11y
      [setSdAccessibility]="{
        ariaLabel: 'inactivitytimedialog_primarybuton' | dictionary | async
      }"
      (buttonClicked)="onSubmit()"
      [primary]="true"
      class="primary">
      {{ 'inactivitytimedialog_primarybuton' | dictionary | async }}
    </app-sd-flat-button-a11y>
  </ng-container>
</app-sd-dialog-template-a11y>
