import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { FormControl, FormGroup } from '@angular/forms';
import { Option } from '../../../shared/sd-forms/models/option.model';
import { DialogTemplateTypes } from 'src/app/shared/templates/enums/templates.enum';
import { SdDialogTemplateA11yModule } from '../../../shared/templates/dialog-template-a11y/sd-dialog-template-a11y.module';
import { AsyncPipe, NgIf } from '@angular/common';
import { DictionaryPipeModule } from '../../../dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { SdAutocompleteA11yModule } from '../../../shared/sd-autocomplete-a11y/sd-autocomplete-a11y.module';
import { SdFlatButtonA11yModule } from '../../../shared/sd-flat-button-a11y/sd-flat-button-a11y.module';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { NetworkV2 } from '../../models/network.v2.type';
import { NetworkUpdateRelationStore } from '../../../store/network-update-relation.store';
import { gettingNetworkListSelector } from '../../store/network-list.store';

@Component({
  selector: 'app-network-relationship-dialog',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SdDialogTemplateA11yModule,
    NgIf,
    DictionaryPipeModule,
    AsyncPipe,
    SdAutocompleteA11yModule,
    SdFlatButtonA11yModule,
  ],
  providers: [NetworkUpdateRelationStore],
  styleUrls: ['./network-relationship-dialog.component.scss'],
  template: `
    <app-sd-dialog-template-a11y
      *ngIf="!displaySuccessOfFailOperation()"
      [spinner]="gettingNetworks()"
      (closeButtonClicked)="onClose()">
      <ng-container dialog-header>
        <h2>
          {{ 'networkrelationshipdialog_title' | dictionary | async }}
        </h2>
      </ng-container>
      <ng-container dialog-body>
        <div
          [innerHTML]="
            'networkrelationshipdialog_body' | dictionary | async
          "></div>

        <div class="network-relationship-dialog-autocomplete">
          <app-sd-autocomplete-a11y
            [setSdAccessibility]="{
              id: 'relationController'
            }"
            [controller]="relationController"
            [setOptions]="options()"
            [placeholder]="
              'networkrelationshipdialog_relationshipplaceholder'
                | dictionary
                | async
            " />
        </div>
      </ng-container>
      <ng-container dialog-footer>
        <app-sd-flat-button-a11y
          (buttonClicked)="onClose()"
          [primary]="false"
          [setSdAccessibility]="{
            ariaLabel:
              'networkrelationshipdialog_secondarybutton' | dictionary | async
          }">
          {{ 'networkrelationshipdialog_secondarybutton' | dictionary | async }}
        </app-sd-flat-button-a11y>

        <app-sd-flat-button-a11y
          (buttonClicked)="onSubmit()"
          [primary]="true"
          [loading]="processingOperations()"
          [inactiveButtonClass]="true"
          [setSdAccessibility]="{
            ariaLabel:
              'networkrelationshipdialog_primarybutton' | dictionary | async
          }">
          {{ 'networkrelationshipdialog_primarybutton' | dictionary | async }}
        </app-sd-flat-button-a11y>
      </ng-container>
    </app-sd-dialog-template-a11y>

    <app-sd-dialog-template-a11y
      *ngIf="displaySuccessOfFailOperation()"
      (closeButtonClicked)="onClose()">
      <ng-container dialog-header>
        <h2 #titleDialog>
          {{
            errors.length === 0
              ? ('networkrelationshipdialog_successtitle' | dictionary | async)
              : ('networkrelationshipdialog_errortitle' | dictionary | async)
          }}
        </h2>
      </ng-container>
      <ng-container dialog-body>
        <div class="base-dialog-content">
          {{
            errors.length === 0
              ? ('networkrelationshipdialog_successbody' | dictionary | async)
              : ('networkrelationshipdialog_errorbody' | dictionary | async)
          }}
        </div>
      </ng-container>
      <ng-container dialog-footer>
        <app-sd-flat-button-a11y
          (buttonClicked)="onClose()"
          *ngIf="errors.length === 0"
          [primary]="true"
          [setSdAccessibility]="{
            ariaLabel:
              'networkrelationshipdialog_successprimarybutton'
              | dictionary
              | async
          }">
          {{
            'networkrelationshipdialog_successprimarybutton'
              | dictionary
              | async
          }}
        </app-sd-flat-button-a11y>

        <app-sd-flat-button-a11y
          (buttonClicked)="onClose()"
          *ngIf="errors.length !== 0"
          [primary]="true"
          [setSdAccessibility]="{
            ariaLabel:
              'networkrelationshipdialog_errorprimarybutton'
              | dictionary
              | async
          }">
          {{
            'networkrelationshipdialog_errorprimarybutton' | dictionary | async
          }}
        </app-sd-flat-button-a11y>
      </ng-container>
    </app-sd-dialog-template-a11y>
  `,
})
export class NetworkRelationshipDialogComponent {
  relationController = new FormControl<string | Option>(null, []);
  @ViewChild('titleDialog') title!: ElementRef<HTMLInputElement>;
  DialogTemplateTypes = DialogTemplateTypes;
  isSubmitted = false;
  form: FormGroup = new FormGroup({
    relationController: this.relationController,
  });

  private readonly store = inject(Store<AppState>);
  private readonly dialogStore = inject(NetworkUpdateRelationStore);
  private readonly dialogRef = inject(
    MatDialogRef<NetworkRelationshipDialogComponent>
  );
  private readonly liveAnnouncer = inject(LiveAnnouncer);
  private readonly data: {
    network: NetworkV2;
  } = inject(MAT_DIALOG_DATA);
  options = this.dialogStore.options;
  gettingNetworks = this.store.selectSignal(gettingNetworkListSelector);
  processingOperations = this.dialogStore.processingOperations;
  displaySuccessOfFailOperation = this.dialogStore.operationsComplete;
  errors = this.dialogStore.errors;

  constructor() {
    this.dialogStore.requestsNetworks(this.data.network);
    this.relationController.setValue(
      this.dialogStore.getInitialRelation(this.data.network)
    );
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    this.dialogStore.updateRelation({
      relation: this.dialogStore.generateRelation(
        this.relationController.value
      ),
      networks: this.dialogStore.getNetworksToUpdate(this.data.network),
      callback: () => {
        setTimeout(() => {
          this.title?.nativeElement?.focus();
        }, 1000);
      },
    });
  }
}
