<ng-container *ngIf="vm$ | async as vm">
  <app-sd-dialog-template-a11y
    (closeButtonClicked)="onClose()"
    [showFooter]="!vm.gettingEnvelopeInformation">
    <ng-container dialog-header>
      <h2>
        {{
          ('signaturedetailsdialog_details' | dictionary | async) +
            ' ' +
            (vm.envelope.emailSubject ?? '')
        }}
      </h2>
    </ng-container>
    <ng-container dialog-body>
      <div
        *ngIf="!vm.gettingEnvelopeInformation; else loadingDetails"
        class="signature-details-body">
        <div class="signature-details-view-general-info">
          <h3>
            {{ 'signaturedetailsdialog_status' | dictionary | async }}
            <b>{{ vm.envelope.status }}</b>
          </h3>
          <h3>
            Last Modified:
            <b>{{
              vm.envelope.lastModifiedDateTime | dateLocale: 'LLL' | async
            }}</b>
          </h3>
        </div>

        <div class="signature-details-view-files">
          <h3>{{ 'signaturedetailsdialog_documents' | dictionary | async }}</h3>
          <div
            *ngFor="
              let document of vm.envelope.envelopeDocuments;
              index as i;
              trackBy: trackByDocuments
            "
            class="signature-details-view-file">
            <app-document-list-item [document]="document.documentId" />
          </div>
        </div>

        <div class="signature-details-view-signers">
          <h3>{{ 'signaturedetailsdialog_signers' | dictionary | async }}</h3>
          <app-account-type-item
            *ngFor="
              let signer of vm.envelope.recipients;
              index as i;
              trackBy: trackBySigners
            "
            [actionAlt]="signer.email"
            [clickable]="false"
            [description]="
              signer.name?.length > 0
                ? signer.email
                : ('networklistitem_collaboratorslistpending'
                  | dictionary
                  | async)
            "
            [name]="signer.name?.length > 0 ? signer.name : signer.email"
            [tag]="signer.status | titlecase"
            class="signature-details-view-signer" />
        </div>
      </div>
      <ng-template #loadingDetails>
        <div class="signature-details-view-spinner">
          <app-sd-progress-spinner-a11y />
        </div>
      </ng-template>
    </ng-container>
    <ng-container dialog-footer>
      <app-sd-flat-button-a11y (buttonClicked)="onClose()" [primary]="false">{{
        'globalparams_close' | dictionary | async
      }}</app-sd-flat-button-a11y>

      <app-sd-flat-button-a11y
        (buttonClicked)="onViewOnDocusign()"
        [primary]="true"
        >{{
          'signaturedetailsdialog_viewondocusign' | dictionary | async
        }}</app-sd-flat-button-a11y
      >
    </ng-container>
  </app-sd-dialog-template-a11y>
</ng-container>
