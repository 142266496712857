import { Action } from '@ngrx/store';

export enum NetworksQueueActionsTypes {
  GetNewNetworksRelations = '[Networks Queue] Get New Networks Relations when queue was requested',
}

export class GetNewNetworksRelations implements Action {
  readonly type = NetworksQueueActionsTypes.GetNewNetworksRelations;

  constructor(public payload: { state: boolean }) {}
}

export type NetworksQueueActions = GetNewNetworksRelations;
