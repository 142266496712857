import { RecordSubType } from './record-sub-type.model';
import { RecordType } from './record-type.model';
import { RecordDetails } from './record-details.model';
import { SidedrawerRoles } from '../../core/roles/sidedrawer.roles';
import { RecordsRoles } from '../../core/roles/records.roles';
import { KeyValue } from '@angular/common';

export class Record {
  constructor(
    public id?: string,
    public name?: string,
    public description?: string,
    public uniqueReference?: string,
    public storageLocation?: string,
    public recordSubtypeName?: string,
    public recordTypeName?: string,
    public recordSubtype?: RecordSubType,
    public recordType?: RecordType,
    public recordSubtypeOther?: string,
    public active?: boolean,
    public status?: any,
    public recordDetails?: RecordDetails,
    public userSidedrawerRole?: SidedrawerRoles[],
    public userRecordRole?: RecordsRoles[],
    public editable?: boolean,
    public externalKeys?: KeyValue<string, string>[],
    public lastModifiedBy?: string,
    public updatedAt?: Date,
    public sideDrawerId?: string
  ) {}

  public static isRecordValid(record: Record): boolean {
    return (
      !!record.recordType &&
      typeof record.recordType === 'object' &&
      !!record.recordSubtype &&
      typeof record.recordSubtype === 'object'
    );
  }
}
