import { inject, Injectable } from '@angular/core';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { forkJoin, Observable, of, switchMap, take, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { AccountService } from '../../../account/services/account.service';
import { accountSelector } from '../../../account/store/account.selector';
import { currentBrandingSelector } from '../../../core/store/core.selectors';
import { Mfa } from '../../../account/models/mfa.model';

export interface MfaState {
  mfaStatus: 'fails' | 'success' | 'idle' | 'processing';
  resendMfaStatus: 'fails' | 'success' | 'idle' | 'processing';
}

@Injectable()
export class MfaDialogStore extends ComponentStore<MfaState> {
  readonly state$ = this.select(state => state);

  readonly checkMfa = this.effect((code$: Observable<string>) => {
    const store = inject(Store<AppState>);
    const accountService = inject(AccountService);
    return code$.pipe(
      switchMap(code =>
        forkJoin([store.select(accountSelector).pipe(take(1)), of(code)])
      ),
      tap(() => {
        this.patchState({ resendMfaStatus: 'idle' });
        this.patchState({ mfaStatus: 'processing' });
      }),
      switchMap(([account, code]) =>
        accountService.checkMfaCode(account.id, code).pipe(
          tapResponse(
            () => {
              this.patchState({ mfaStatus: 'success' });
            },
            () => {
              this.patchState({ mfaStatus: 'fails' });
            }
          )
        )
      )
    );
  });

  readonly reSendMfa = this.effect((mfa$: Observable<Mfa>) => {
    const store = inject(Store<AppState>);
    const accountService = inject(AccountService);
    return mfa$.pipe(
      switchMap(mfa =>
        forkJoin([
          store.select(accountSelector).pipe(take(1)),
          store.select(currentBrandingSelector).pipe(take(1)),
          of(mfa),
        ])
      ),
      tap(() => this.patchState({ resendMfaStatus: 'processing' })),
      switchMap(([account, brand, mfa]) =>
        accountService.createMfaCode(account?.id, mfa, brand?.brandCode).pipe(
          tapResponse(
            () => {
              this.patchState({ mfaStatus: 'idle' });
              this.patchState({ resendMfaStatus: 'success' });
            },
            () => {
              this.patchState({ resendMfaStatus: 'fails' });
            }
          )
        )
      )
    );
  });

  constructor() {
    super({
      mfaStatus: 'idle',
      resendMfaStatus: 'idle',
    });
  }
}
