import { HomeRoutes } from 'src/app/home/routes/home.routes';
import { environment } from '../../../environments/environment';
import { MyQueryParamsModel } from '../models/my-query-params.model';
import { CoreRoutes } from '../routes/core.routes';

export class RoutesHelper {
  static readonly separatorPath = '/';

  public static getParams(withOrigin = true): MyQueryParamsModel {
    const queryParams = {};
    const urlParams = new URLSearchParams(window.location.search);
    const invitation = urlParams.get('invitation');
    const from = urlParams.get('from');
    const origin = urlParams.get('origin');
    const recordSubtype = urlParams.get('recordSubtype');
    const recordSubtypeOther = urlParams.get('recordSubtypeOther');
    const itemId = urlParams.get('itemId');
    const redirectTo = urlParams.get('redirectTo');
    const email = urlParams.get('email');
    // tslint:disable-next-line:variable-name
    const product_tour_id = urlParams.get('product_tour_id');
    if (!!invitation && invitation.length > 0) {
      // tslint:disable-next-line
      queryParams['invitation'] = invitation;
    }
    if (!!from && from.length > 0) {
      // tslint:disable-next-line
      queryParams['from'] = from;
    }
    if (!!origin && origin.length > 0 && withOrigin) {
      // tslint:disable-next-line
      queryParams['origin'] = origin;
    } else if (withOrigin) {
      // tslint:disable-next-line
      queryParams['origin'] = window.location.pathname;
    }
    if (!!recordSubtype && recordSubtype.length > 0) {
      // tslint:disable-next-line
      queryParams['recordSubtype'] = recordSubtype;
    }
    if (!!recordSubtypeOther && recordSubtypeOther.length > 0) {
      // tslint:disable-next-line
      queryParams['recordSubtypeOther'] = recordSubtypeOther;
    }
    if (!!itemId && itemId.length > 0) {
      // tslint:disable-next-line
      queryParams['itemId'] = itemId;
    }
    if (!!redirectTo && redirectTo.length > 0) {
      // tslint:disable-next-line
      queryParams['redirectTo'] = redirectTo;
    }
    if (!!product_tour_id && product_tour_id.length > 0) {
      // tslint:disable-next-line
      queryParams['product_tour_id'] = product_tour_id;
    }
    if (!!email && email.length > 0) {
      queryParams['email'] = email;
    }
    return queryParams;
  }

  public static getQuery(): string {
    const {
      invitation,
      from,
      origin,
      product_tour_id,
      recordSubtype,
      recordSubtypeOther,
      itemId,
      redirectTo,
      email,
    } = this.getParams();
    let query = '?';
    query =
      invitation != null && invitation.length > 0
        ? query + `invitation=${invitation}`
        : query;
    query = from != null && from.length > 0 ? query + `&from=${from}` : query;
    query =
      origin != null && origin.length > 0 ? query + `&origin=${origin}` : query;
    query =
      recordSubtype != null && recordSubtype.length > 0
        ? query + `&recordSubtype=${recordSubtype}`
        : query;
    query =
      recordSubtypeOther != null && recordSubtypeOther.length > 0
        ? query + `&recordSubtypeOther=${recordSubtypeOther}`
        : query;
    query =
      itemId != null && itemId.length > 0 ? query + `&itemId=${itemId}` : query;
    query =
      redirectTo != null && redirectTo.length > 0
        ? query + `&redirectTo=${redirectTo}`
        : query;
    query =
      product_tour_id != null && product_tour_id.length > 0
        ? query + `&product_tour_id=${product_tour_id}`
        : query;
    if (email?.length > 0) {
      query =
        email != null && email.length > 0
          ? query + `&email=${email.split(' ').join('..plus..')}`
          : query;
    }
    return query;
  }

  public static getSubDomain(): string {
    const subdomain = window.location.host.split('.')[1]
      ? window.location.host.split('.')[0]
      : null;
    if (!subdomain) {
      return null;
    }
    if (
      subdomain === 'sdrw-my-td' ||
      subdomain === 'sdrw-my-td-sbx' ||
      subdomain === 'sidedrawer-ng-dev' ||
      subdomain === 'sidedrawer-ng-uat' ||
      subdomain === 'sidedrawer-ng-nxt' ||
      subdomain === 'azure-my-dev' ||
      subdomain === 'my' ||
      subdomain === 'my-dev' ||
      subdomain === 'my-uat' ||
      subdomain === 'my-next' ||
      subdomain === 'my-sbx'
    ) {
      return null;
    }
    return subdomain;
  }

  public static removeQueryFromURL(url: string): string {
    if (!!url && url.length > 0) {
      const urlHelper = url.split('?');
      urlHelper.splice(-1, 1);
      return urlHelper.join('?');
    }
  }

  public static getApiName(url: string): string {
    if (url.includes(environment.configApi)) {
      return 'Config';
    }
    if (url.includes(environment.developmentApi)) {
      return 'Developer';
    }
    if (url.includes(environment.tenantApi)) {
      return 'Tenant';
    }
    if (url.includes(environment.inboxApi)) {
      return 'Inbox';
    }
    if (url.includes(environment.integrationApi)) {
      return 'Integration';
    }
    if (url.includes(environment.networksApi)) {
      return 'Network';
    }
    if (url.includes(environment.recordsApi)) {
      return 'Records';
    }
    if (url.includes(environment.subscriptionApi)) {
      return 'Subscription';
    }
    if (url.includes(environment.userApi)) {
      return 'User';
    }
    if (url.includes(environment.plansApi)) {
      return 'Plans';
    }
  }

  public static getBasePath(sideDrawerId: string): string {
    if (sideDrawerId) {
      return (
        this.separatorPath +
        CoreRoutes.root +
        this.separatorPath +
        HomeRoutes.root +
        this.separatorPath +
        sideDrawerId +
        this.separatorPath
      );
    }
    return (
      this.separatorPath +
      CoreRoutes.root +
      this.separatorPath +
      HomeRoutes.root +
      this.separatorPath
    );
  }
}
