import { Actions, createEffect, ofType } from '@ngrx/effects';
import { inject } from '@angular/core';

import { TenantService } from '../service/tenants.service';
import { TenantsActions, tenantsSelector } from './tenants.store';
import { exhaustMap, of, switchMap } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';

export const tenantsRequested$ = createEffect(
  () => {
    const actions$ = inject(Actions);
    const service = inject(TenantService);
    const store = inject(Store<AppState>);
    return actions$.pipe(
      ofType(TenantsActions.requested),
      switchMap(() => store.select(tenantsSelector)),
      exhaustMap(tenants => {
        return tenants?.length > 0 ? of(tenants) : service.getTenants();
      }),
      tap({
        next: tenants =>
          tenants.length === 1
            ? store.dispatch(
                TenantsActions.setActiveTenant({ tenant: tenants[0] })
              )
            : null,
      }),
      map(tenants => TenantsActions.loaded({ tenants })),
      catchError(httpError => of(TenantsActions.error({ httpError })))
    );
  },
  { functional: true }
);
