import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { Subscription } from 'rxjs';
import { helpVideosSelector } from '../../../dictionary/store/dictionary.selectors';
import { map } from 'rxjs/operators';
import { HelpSections } from '../../../dictionary/models/help-sections.model';
import { HelpVideos } from '../../../dictionary/models/help-videos.model';
import { environment } from '../../../../environments/environment';
import { AppState } from '../../../reducers';
import { DialogTemplateTypes } from 'src/app/shared/templates/enums/templates.enum';
import { SdLinkButtonA11yTemplates } from 'src/app/shared/sd-link-button-a11y/model/sd-link-button-a11y.enum';
import { UtilsHelper } from 'src/app/core/helpers/utils.helper';
import { SdDialogTemplateA11yModule } from '../../../shared/templates/dialog-template-a11y/sd-dialog-template-a11y.module';
import { HelpSupportVideosDialogContentComponent } from './components/help-support-videos-dialog-content/help-support-videos-dialog-content.component';
import { NgIf } from '@angular/common';
import { SdLinkButtonA11yModule } from '../../../shared/sd-link-button-a11y/sd-link-button-a11y.module';
import { SdTourButtonComponent } from '../../../shared/sd-tour-button/sd-tour-button.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-help-support-videos-dialog',
  template: `
    <app-sd-dialog-template-a11y
      (closeButtonClicked)="onClose()"
      [showFooter]="
        activeHelpSection?.linkURL !== '' ||
        activeHelpSection?.linkURL !== undefined
      ">
      <ng-container dialog-header>
        <div class="help-section-title-container">
          <h1>
            {{ activeHelpSection?.title }}
          </h1>
          <p class="page-video-description">
            {{ activeHelpSection?.description }}
          </p>
        </div>
      </ng-container>
      <ng-container dialog-body>
        <app-help-support-videos-dialog-content
          (gridStatus)="changeGridStatus($event)"
          [activeVideo]="activeVideo"
          [grid]="grid"
          [helpVideosList]="helpVideosList" />
      </ng-container>
      <ng-container *ngIf="activeHelpSection?.linkURL" dialog-footer>
        <app-sd-link-button-a11y
          (click)="navigateTo($event, activeHelpSection?.linkURL)"
          [template]="sdLinkButtonA11yTemplates.linkNative">
          <div linkNative>{{ activeHelpSection?.linkLabel }}</div>
        </app-sd-link-button-a11y>

        <app-tour-button
          (click)="onClose()"
          [tourId]="activeHelpSection?.tourId"
          [tourTooltip]="activeHelpSection?.tourLabel" />
      </ng-container>
    </app-sd-dialog-template-a11y>
  `,
  styleUrls: ['./help-support-videos-dialog.component.scss'],
  standalone: true,
  imports: [
    SdDialogTemplateA11yModule,
    HelpSupportVideosDialogContentComponent,
    NgIf,
    SdLinkButtonA11yModule,
    SdTourButtonComponent,
  ],
})
export class HelpSupportVideosDialogComponent implements OnInit, OnDestroy {
  dialogTemplateTypes = DialogTemplateTypes;
  helpVideosList: HelpVideos[];
  activeHelpSection: HelpSections;
  currentRoute: string;
  activeVideo: HelpVideos;
  subscription = new Subscription();
  cdn: string = environment.cdn;
  spinner = true;
  grid = false;
  sdLinkButtonA11yTemplates = SdLinkButtonA11yTemplates;

  constructor(
    private readonly store: Store<AppState>,
    private readonly dialogRef: MatDialogRef<HelpSupportVideosDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { activeHelpSection: HelpSections }
  ) {
    this.activeHelpSection = this.data.activeHelpSection;
  }

  ngOnInit(): void {
    this.subscription.add(
      this.store
        .pipe(select(helpVideosSelector))
        .pipe(
          map(helpVideos => {
            this.helpVideosList = helpVideos?.filter(
              v =>
                this.activeHelpSection?.videoIds?.includes(v?.videoId) &&
                v.published
            );
            this.activeVideo = this.helpVideosList[0];
          })
        )
        .subscribe()
    );
    this.spinner = false;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  navigateTo(event: Event, url: string): void {
    UtilsHelper.onClickExternalLink(event, url);
  }

  onClose(): void {
    this.dialogRef.close();
  }

  changeGridStatus(grid: boolean): void {
    this.grid = grid;
  }
}
