export enum NetworksRoutesEnum {
  root = 'networks',
  main = '',
  form = 'form',
}

export enum NetworksRoutesTabsEnum {
  sideDrawer = 'side-drawer',
  records = 'records',
  tiles = 'tiles',
  infoRequest = 'info-request',
}
