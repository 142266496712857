import { UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ComponentStore } from '@ngrx/component-store';
import { FileItem } from 'src/app/files/models/file-item.model';
import { FileRenameDialogComponent } from './file-rename-dialog.component';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, Observable, tap } from 'rxjs';
import { AppState } from 'src/app/reducers';
import { FilesService } from 'src/app/files/services/files.service';
import { ErrorLoaded } from 'src/app/core/store/core.actions';
import { FilesHelper } from 'src/app/files/helpers/files.helper';
import {
  FileHistoryClear,
  FileHistoryRequested,
} from 'src/app/files/store/file-history.actions';
import { CloudFoldersFilesRequested } from 'src/app/files/store/cloud-folders-files.actions';

export interface FileRenameState {
  gettingInformation: boolean;
  item: FileItem;
  form: UntypedFormGroup;
  dialogRef: MatDialogRef<FileRenameDialogComponent>;
}

export interface RenameFileModel {
  uploadTitle: string;
  correlationId?: string;
}

@Injectable()
export class FileRenameStore extends ComponentStore<FileRenameState> {
  readonly gettingInformation$ = this.select(state => state.gettingInformation);
  readonly item$ = this.select(state => state.item);
  readonly form$ = this.select(state => state.form);
  readonly dialog$ = this.select(state => state.dialogRef);

  readonly renameFile = this.effect((params$: Observable<FileRenameState>) =>
    params$.pipe(
      tap(() => this.patchState({ gettingInformation: true })),
      map(params => {
        const fileNameWithExtension = FilesHelper.getFileNameWithExtension(
          params.item.fileName,
          params.item
        );
        const newFileName = params.form?.controls['name']?.value;
        const correlationId = params.item.correlationId
          ? encodeURIComponent(params.item.correlationId)
          : '';
        const body: RenameFileModel = {
          uploadTitle: newFileName,
          correlationId,
        };

        if (params.item?.fileToken) {
          return this.filesService
            .renameFileWithToken(
              params.item.sideDrawerId,
              params.item.recordId,
              params.item,
              body
            )
            .subscribe({
              next: () => {
                this.store.dispatch(new FileHistoryClear());
                this.store.dispatch(
                  new FileHistoryRequested({
                    sideDrawerId: params.item.sideDrawerId,
                    recordId: params?.item.recordId,
                  })
                );
                this.store.dispatch(
                  new CloudFoldersFilesRequested({
                    sideDrawerId: params.item.sideDrawerId,
                    recordId: params.item.recordId,
                  })
                );
                this.patchState({ gettingInformation: false });
                this.dialogRef.close();
              },
              error: (error: HttpErrorResponse) => {
                this.patchState({ gettingInformation: false });
                this.dialogRef.close();
                this.store.dispatch(
                  new ErrorLoaded({ httpError: { ...error } })
                );
              },
            });
        }
        // NOTE for file legacy
        return this.filesService
          .renameFile(
            params.item.sideDrawerId,
            params.item.recordId,
            fileNameWithExtension,
            body
          )
          .subscribe({
            next: () => {
              this.store.dispatch(new FileHistoryClear());
              this.store.dispatch(
                new FileHistoryRequested({
                  sideDrawerId: params.item.sideDrawerId,
                  recordId: params?.item.recordId,
                })
              );
              this.store.dispatch(
                new CloudFoldersFilesRequested({
                  sideDrawerId: params.item.sideDrawerId,
                  recordId: params.item.recordId,
                })
              );
              this.patchState({ gettingInformation: false });
              this.dialogRef.close();
            },
            error: (error: HttpErrorResponse) => {
              this.patchState({ gettingInformation: false });
              this.dialogRef.close();
              this.store.dispatch(new ErrorLoaded({ httpError: { ...error } }));
            },
          });
      })
    )
  );

  constructor(
    private readonly filesService: FilesService,
    private readonly store: Store<AppState>
  ) {
    super({
      gettingInformation: false,
      item: null,
      form: null,
      dialogRef: null,
    });
  }

  get form(): UntypedFormGroup {
    return this.get().form;
  }

  get dialogRef(): MatDialogRef<FileRenameDialogComponent> {
    return this.get().dialogRef;
  }
}
