import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { DocusignEnvelope } from '../models/docusign-envelope.model';
import { createReducer, on } from '@ngrx/store';
import { SignatureActions } from './signature.actions';

export const featureName = 'signature';

export interface SignatureState extends EntityState<DocusignEnvelope> {
  gettingInformation: boolean;
  activeEnvelope: DocusignEnvelope | null;
  fileToSign: File | null;
  hasMore: boolean;
  nextPage?: string;
  previousPage?: string;
  totalCount?: number;
  checkDocumentsOnRecord: Map<string, boolean>;
  downloadingAndUploading: Map<string, boolean>;
}

export const signatureAdapter = createEntityAdapter<DocusignEnvelope>({
  selectId: envelope => envelope.envelopeId,
});

export const initialSignatureState: SignatureState =
  signatureAdapter.getInitialState({
    activeEnvelope: null,
    fileToSign: null,
    gettingInformation: false,
    hasMore: false,
    checkDocumentsOnRecord: new Map<string, boolean>(),
    downloadingAndUploading: new Map<string, boolean>(),
  });

export const signatureReducer = createReducer(
  initialSignatureState,
  on(SignatureActions.clearInformation, () => initialSignatureState),
  on(SignatureActions.setFileToSign, (state, { file }) => ({
    ...state,
    fileToSign: file,
  })),
  on(SignatureActions.envelopesLoaded, (state, { response }) => {
    const { data, hasMore, nextPage, previousPage, totalCount } = response;
    return signatureAdapter.upsertMany(data, {
      ...state,
      hasMore,
      nextPage,
      previousPage,
      totalCount,
    });
  }),
  on(
    SignatureActions.gettingEnvelopesChange,
    (state, { gettingInformation }) => ({ ...state, gettingInformation })
  ),
  on(SignatureActions.setActiveEnvelope, (state, { envelope }) => ({
    ...state,
    activeEnvelope: envelope,
  })),
  on(SignatureActions.clearActiveEnvelope, state => ({
    ...state,
    activeEnvelope: null,
  })),
  on(
    SignatureActions.envelopeCompleteInformationLoaded,
    (state, { envelope }) => signatureAdapter.updateOne(envelope, state)
  ),
  on(
    SignatureActions.checkDocumentsOnRecordChange,
    (state, { envelopeId, checkDocumentsOnRecord }) => {
      const gettingAndUploadingMap = new Map(state.checkDocumentsOnRecord);
      gettingAndUploadingMap.set(envelopeId, checkDocumentsOnRecord);
      return { ...state, gettingAndUploading: gettingAndUploadingMap };
    }
  ),
  on(
    SignatureActions.downloadingAndUploadingDocumentChange,
    (state, { envelopeId, documentId, downloadingAndUploading }) => {
      const downloadingAndUploadingMap = new Map(state.downloadingAndUploading);
      downloadingAndUploadingMap.set(
        `${envelopeId}-${documentId}`,
        downloadingAndUploading
      );
      return { ...state, downloadingAndUploading: downloadingAndUploadingMap };
    }
  )
);

export const { selectEntities, selectAll, selectIds } =
  signatureAdapter.getSelectors();
