import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DictionaryPipeModule } from 'src/app/dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { FileItem } from 'src/app/files/models/file-item.model';
import { SdFlatButtonA11yModule } from 'src/app/shared/sd-flat-button-a11y/sd-flat-button-a11y.module';
import { SdDialogTemplateA11yModule } from 'src/app/shared/templates/dialog-template-a11y/sd-dialog-template-a11y.module';
import { DialogTemplateTypes } from 'src/app/shared/templates/enums/templates.enum';
import { ConfirmDialogModel } from './model/confirm-dialog.enum';

@Component({
  selector: 'app-delete-file-dialog',
  template: `
    <ng-template
      #defaultDeleteFile
      [ngIf]="
        !data?.template ||
        data?.template === confirmDialogModel.defaultDeleteFile
      ">
      <app-sd-dialog-template-a11y
        (closeButtonClicked)="onClose()"
        [type]="dialogTemplateTypes.Info">
        <ng-container dialog-header>
          <h2 class="base-dialog-title">
            {{
              ('deletefiledialog_title' | dictionary | async) +
                ' ' +
                data?.fileItem?.uploadTitle ?? ''
            }}
          </h2>
        </ng-container>
        <ng-container dialog-body>
          <div
            [innerHTML]="'deletefiledialog_body' | dictionary | async"
            class="base-dialog-content"></div>
        </ng-container>
        <ng-container dialog-footer>
          <app-sd-flat-button-a11y
            (buttonClicked)="onClose()"
            [primary]="false"
            class="secondary">
            {{ 'deletefiledialog_secondarybutton' | dictionary | async }}
          </app-sd-flat-button-a11y>
          <app-sd-flat-button-a11y
            (buttonClicked)="onSubmit()"
            [primary]="true"
            class="primary">
            {{ 'deletefiledialog_primarybutton' | dictionary | async }}
          </app-sd-flat-button-a11y>
        </ng-container>
      </app-sd-dialog-template-a11y>
    </ng-template>

    <ng-template
      #generic
      [ngIf]="data?.template === confirmDialogModel.deleteMultipleFiles">
      <app-sd-dialog-template-a11y
        (closeButtonClicked)="onClose()"
        [type]="dialogTemplateTypes.Info">
        <ng-container dialog-header>
          <h2 class="base-dialog-title">
            {{ data?.title | dictionary | async }}
          </h2>
        </ng-container>
        <ng-container dialog-body>
          <div
            [innerHTML]="
              data?.numberOfFilesSelected +
              ' ' +
              (data?.labelOfFilesSelected | dictionary | async) +
              (data?.description | dictionary | async)
            "
            class="base-dialog-content"></div>
        </ng-container>
        <ng-container dialog-footer>
          <app-sd-flat-button-a11y
            (buttonClicked)="onClose()"
            [primary]="false"
            class="secondary">
            {{ 'deletefiledialog_secondarybutton' | dictionary | async }}
          </app-sd-flat-button-a11y>
          <app-sd-flat-button-a11y
            (buttonClicked)="onSubmit()"
            [primary]="true"
            class="primary">
            {{ 'deletefiledialog_primarybutton' | dictionary | async }}
          </app-sd-flat-button-a11y>
        </ng-container>
      </app-sd-dialog-template-a11y>
    </ng-template>
  `,
  styleUrls: ['./delete-file-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SdFlatButtonA11yModule,
    DictionaryPipeModule,
    AsyncPipe,
    SdDialogTemplateA11yModule,
    NgIf,
  ],
})
export class DeleteFileDialogComponent {
  dialogTemplateTypes = DialogTemplateTypes;
  confirmDialogModel = ConfirmDialogModel;

  constructor(
    private dialogRef: MatDialogRef<DeleteFileDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public readonly data: {
      fileItem?: FileItem;
      copyFile?: boolean;
      title?: string;
      description?: string;
      numberOfFilesSelected?: string;
      labelOfFilesSelected?: string;
      template?: ConfirmDialogModel;
    }
  ) {}

  onClose(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    this.dialogRef.close(true);
  }
}
