import { NetworkGroup } from '../../models/network-group.model';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { NetworkGroupsActions } from './network-groups.actions';

export interface NetworkGroupsState extends EntityState<NetworkGroup> {
  gettingInformation: boolean;
}

export const networkGroupsAdapter = createEntityAdapter<NetworkGroup>({
  selectId: group => group.id,
});

export const initialNetworkGroupsState: NetworkGroupsState =
  networkGroupsAdapter.getInitialState({
    gettingInformation: false,
  });

export const networkGroupsReducer = createReducer(
  initialNetworkGroupsState,
  on(NetworkGroupsActions.clean, () => initialNetworkGroupsState),
  on(
    NetworkGroupsActions.gettingInformationChanged,
    (state, { gettingInformation }) => ({ ...state, gettingInformation })
  ),
  on(NetworkGroupsActions.loaded, (state, { groups }) =>
    networkGroupsAdapter.upsertMany(groups, state)
  ),
  on(NetworkGroupsActions.added, (state, { group }) =>
    networkGroupsAdapter.upsertOne(group, state)
  ),
  on(NetworkGroupsActions.removed, (state, { id }) =>
    networkGroupsAdapter.removeOne(id, state)
  )
);

export const { selectAll, selectEntities } =
  networkGroupsAdapter.getSelectors();
