import { inject, Pipe, PipeTransform } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { filingCabinetSelector } from 'src/app/filing-cabinet/store/filing-cabinet.store';
import { combineLatest, map, Observable } from 'rxjs';
import { activeSideDrawerSelector } from 'src/app/sidedrawer/store/sidedrawer.selector';

@Pipe({
  name: 'mainHeaderDisplayName',
  standalone: true,
})
export class MainHeaderDisplayNamePipe implements PipeTransform {
  store = inject(Store<AppState>);
  transform(hideFilingCabinetName: boolean): Observable<string> {
    return combineLatest([
      this.store.pipe(select(activeSideDrawerSelector)),
      this.store.pipe(select(filingCabinetSelector)),
    ]).pipe(
      map(([activeSd, activeFc]) => {
        if (!activeFc) {
          return activeSd?.name;
        }
        // return hideFilingCabinetName ? activeSd?.name : activeFc?.name;
        return activeFc?.name;
      })
    );
  }
}
