import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CoreRoutes } from 'src/app/core/routes/core.routes';

const routes: Routes = [
  {
    path: CoreRoutes.main,
    loadChildren: () =>
      import('./templates/core-template/td-core-template.module').then(
        m => m.TdCoreTemplateModule
      ),
  },
  {
    path: CoreRoutes.error + '/:dictionaryFails',
    loadChildren: () =>
      import(
        '../../../core/views/sd-general-error-a11y/sd-general-error-a11y.module'
      ).then(m => m.SdGeneralErrorA11yModule),
  },
  {
    path: CoreRoutes.error,
    loadChildren: () =>
      import(
        '../../../core/views/sd-general-error-a11y/sd-general-error-a11y.module'
      ).then(m => m.SdGeneralErrorA11yModule),
    title: 'genericerrordialog_pagetitle',
  },
  {
    path: CoreRoutes.forbidden,
    loadComponent: () =>
      import(
        './views/td-forbidden-error-view/td-forbidden-error-view.component'
      ).then(m => m.TdForbiddenErrorViewComponent),
    title: 'tdforbiddenerrorview_pagetitle',
  },
  {
    path: CoreRoutes.noAccount,
    loadComponent: () =>
      import(
        './views/td-no-account-error-view/td-no-account-error-view.component'
      ).then(m => m.TdNoAccountErrorViewComponent),
    title: 'tdnoaccounterrorview_pagetitle',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TdCoreRoutingModule {}
