import { recordsListReducer, RecordsListState } from './records-list.reducer';
import {
  specificFieldsReducer,
  SpecificFieldsState,
} from './specific-fields.reducer';
import { ActionReducerMap } from '@ngrx/store';

export const featureName = 'records';

export interface RecordsState {
  recordsList: RecordsListState;
  specificFields: SpecificFieldsState;
}

export const recordsReducer: ActionReducerMap<RecordsState> = {
  recordsList: recordsListReducer,
  specificFields: specificFieldsReducer,
};
