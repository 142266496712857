import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../reducers';
import { ExpandSearchBar } from '../../../../search/store/search-bar.actions';
import { SdBackDropClicked } from '../../../../home/shared/sd-backdrop/store/sd-backdrop.actions';
import { SideDrawerNetwork } from 'src/app/sidedrawer-networks/models/side-drawer-network.model';
import { SideDrawerRequested } from 'src/app/sidedrawer/store/sidedrawer.actions';

@Component({
  selector: 'app-sd-search-sidedrawer-result-item-a11y',
  templateUrl: './sd-search-sidedrawer-result-item-a11y.component.html',
  styleUrls: ['./sd-search-sidedrawer-result-item-a11y.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SdSearchSideDrawerResultItemA11yComponent {
  item: SideDrawerNetwork;

  constructor(private readonly store: Store<AppState>) {}

  @Input() set setItem(item: SideDrawerNetwork) {
    this.item = item;
  }

  onSwitchSideDrawer(myOtherSd: SideDrawerNetwork): void {
    this.store.dispatch(new ExpandSearchBar({ expanded: false }));
    this.store.dispatch(new SdBackDropClicked());
    this.store.dispatch(new SideDrawerRequested({ sdId: myOtherSd?.id }));
  }
}
