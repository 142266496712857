import { SdCacheActions, SdCacheActionsTypes } from './sd-cache.actions';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { SdCacheElement } from '../models/sd-cache-element.model';

export const featureName = 'sd-cache';

export type SdCacheState = EntityState<SdCacheElement>;

export const sdCacheStateAdapter: EntityAdapter<SdCacheElement> =
  createEntityAdapter<SdCacheElement>({
    selectId: element => element.key,
  });

export const initialSdCacheState: SdCacheState =
  sdCacheStateAdapter.getInitialState();

export function sdCacheReducer(
  state: SdCacheState = initialSdCacheState,
  action: SdCacheActions
): SdCacheState {
  switch (action.type) {
    case SdCacheActionsTypes.SdCacheElementAdded: {
      return sdCacheStateAdapter.upsertOne(action.payload, state);
    }
    default:
      return state;
  }
}

export const { selectEntities } = sdCacheStateAdapter.getSelectors();
