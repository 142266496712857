import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthState, featureName } from './auth.reducer';
import { AuthUser } from '../models/auth-user.model';
import { UserRoles } from '../../core/roles/user.roles';

export const authStateSelector = createFeatureSelector<AuthState>(featureName);

export const tokenSelector = createSelector(authStateSelector, state =>
  !!state.token && state.token.length > 0 ? state.token : null
);

export const authUserSelector = createSelector(authStateSelector, state =>
  state.user ? state.user : null
);

export const authRolesSelector = createSelector(
  authUserSelector,
  (user: AuthUser) => user?.roles ?? []
);

export const isTenantUser = createSelector(
  authRolesSelector,
  roles => roles?.length > 0
);

export const isManagerSelector = createSelector(authRolesSelector, roles =>
  roles.some(role => role === UserRoles.manager)
);

export const isDeveloperSelector = createSelector(authRolesSelector, roles =>
  roles.some(role => role === UserRoles.developer)
);

export const isAdminSelector = createSelector(authRolesSelector, roles =>
  roles.some(role => role === UserRoles.admin)
);

export const authUserDataBaseRegionSelector = createSelector(
  authUserSelector,
  user => user?.region
);

export const authHeadersSelector = createSelector(tokenSelector, token => ({
  Authorization: 'Bearer ' + token,
  Accept: 'application/json',
  'Content-Type': 'application/json',
}));
