import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZipHeaderButtonA11yComponent } from './components/zip-header-button-a11y/zip-header-button-a11y.component';
import { ZipDialogModule } from '../zip-dialog/zip-dialog.module';
import { ZipModule } from '../../zip.module';
import { SdSvgA11yModule } from 'src/app/shared/sd-svg-a11y/sd-svg-a11y.module';
import { DictionaryPipeModule } from 'src/app/dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { SdIconButtonA11yComponent } from 'src/app/shared/sd-icon-button-a11y/components/sd-icon-button-a11y/sd-icon-button-a11y.component';

@NgModule({
  declarations: [ZipHeaderButtonA11yComponent],
  exports: [ZipHeaderButtonA11yComponent],
  imports: [
    CommonModule,
    ZipDialogModule,
    ZipModule,
    SdSvgA11yModule,
    SdIconButtonA11yComponent,
    DictionaryPipeModule,
  ],
})
export class ZipHeaderButtonModuleA11y {}
