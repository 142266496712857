<ng-template [ngIf]="template === sdInputA11yTemplates.inputDefault">
  <div class="container-main">
    <div class="sd-mat-timepicker-container">
      <div style="position: relative">
        <span class="label-container">
          <label for="time" class="label-input-time">{{ placeholder }}</label>
        </span>
        <ngx-mat-timepicker-field
          #inputElement
          (timeChanged)="data.emit($event)"
          [attr.aria-label]="
            'sdtime_selecthoursandminutesmessage' | dictionary | async"
          [controlOnly]="true"
          [defaultTime]="defaultValue"
          [disabled]="disabled"
          [formControl]="controller"
          [attr.aria-labelledby]="'time'"
          [format]="12"
          [title]="placeholder"
          id="time">
        </ngx-mat-timepicker-field>
        <div *ngIf="controller.invalid" class="error-container">
          <span class="font-error">{{ error }}</span>
        </div>
      </div>
      <div class="suffix-container">
        <app-sd-tooltip-button-a11y
          *ngIf="!!tooltip && tooltip.length > 0"
          [tooltip]="tooltip">
        </app-sd-tooltip-button-a11y>
      </div>
    </div>
  </div>
</ng-template>

<ng-template [ngIf]="template === sdInputA11yTemplates.inputForView">
  <div class="sd-time-custom">
    <mat-form-field [floatLabel]="'auto'" [hideRequiredMarker]="true">
      <span class="label-container">
        <label for="timeview" class="label-input-time">{{ placeholder }}</label>
      </span>
      <input
        [formControl]="controller"
        [attr.aria-label]="placeholder"
        id="timeview"
        matInput
        readonly
        [attr.aria-readonly]="disabled" />
    </mat-form-field>
  </div>
</ng-template>
