import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureName, selectAll, TimelineState } from './timeline.reducer';
import { UtilsHelper } from '../../core/helpers/utils.helper';

export const timelineSateSelector =
  createFeatureSelector<TimelineState>(featureName);
export const timelineListSelector = createSelector(
  timelineSateSelector,
  selectAll
);

export const timelineSortedListSelector = createSelector(
  timelineListSelector,
  list => list.sort((a, b) => UtilsHelper.compareStrings(b.id, a.id))
);

export const timelineRequestsInformationSelector = createSelector(
  timelineSateSelector,
  state => state.requestsInformation
);

export const timelineGettingInformationSelector = createSelector(
  timelineRequestsInformationSelector,
  requests =>
    Array.from(requests.values()).some(request => request.gettingInformation)
);

export const timelineRequestsInformationSelectorBySideDrawerId = (payload: {
  sideDrawerId: string;
}) =>
  createSelector(timelineSateSelector, state =>
    state.requestsInformation.get(payload.sideDrawerId)
  );

export const timelineHasMoreSelectorBySideDrawerId = (payload: {
  sideDrawerId: string;
}) =>
  createSelector(
    timelineRequestsInformationSelectorBySideDrawerId({
      sideDrawerId: payload.sideDrawerId,
    }),
    requestInformation => requestInformation.hasMore
  );

export const timelineNextPageSelectorBySideDrawerId = (payload: {
  sideDrawerId: string;
}) =>
  createSelector(
    timelineRequestsInformationSelectorBySideDrawerId({
      sideDrawerId: payload.sideDrawerId,
    }),
    requestInformation => requestInformation.nextPage
  );

export const timelineHasMoreSelector = createSelector(
  timelineRequestsInformationSelector,
  requestsInformation =>
    Array.from(requestsInformation.values()).some(request => request.hasMore)
);
