import { Action } from '@ngrx/store';
import { RelatedRecord } from '../models/related-record.model';
import { HttpErrorResponse } from '@angular/common/http';

export enum RelatedRecordActionTypes {
  RelatedRecordsRequested = '[] RelatedRecords Requested',
  RelatedRecordsLoaded = '[Related Records service] RelatedRecords Loaded',
  RelatedRecordAdded = '[] RelatedRecord added',
  RelatedRecordDeleted = '[] RelatedRecord deleted',
  RelatedRecordAddedRequested = '[] RelatedRecord added requested',
  RelatedRecordDeletedRequested = '[] RelatedRecord deleted requested',
  RelatedRecordsRemoved = '[] All RelatedRecords deleted',
  GettingRelatedRecordsChange = '[] Getting RelatedRecords Change',
}

export class RelatedRecordsRequested implements Action {
  readonly type = RelatedRecordActionTypes.RelatedRecordsRequested;

  constructor(public payload: { recordId: string }) {}
}

export class RelatedRecordsLoaded implements Action {
  readonly type = RelatedRecordActionTypes.RelatedRecordsLoaded;

  constructor(public payload: { events: RelatedRecord[] }) {}
}

export class RelatedRecordAdded implements Action {
  readonly type = RelatedRecordActionTypes.RelatedRecordAdded;

  constructor(public payload: { event: RelatedRecord }) {}
}

export class RelatedRecordDeleted implements Action {
  readonly type = RelatedRecordActionTypes.RelatedRecordDeleted;

  constructor(public payload: { id: string }) {}
}

export class RelatedRecordsRemoved implements Action {
  readonly type = RelatedRecordActionTypes.RelatedRecordsRemoved;
}

export class GettingRelatedRecordsChange implements Action {
  readonly type = RelatedRecordActionTypes.GettingRelatedRecordsChange;

  constructor(public payload: { gettingRelatedRecords: boolean }) {}
}

export class RelatedRecordAddedRequested implements Action {
  readonly type = RelatedRecordActionTypes.RelatedRecordAddedRequested;

  constructor(
    public payload: {
      relatedRecord: RelatedRecord;
      callback?: (response?: RelatedRecord | HttpErrorResponse) => void;
    }
  ) {}
}

export class RelatedRecordDeletedRequested implements Action {
  readonly type = RelatedRecordActionTypes.RelatedRecordDeletedRequested;

  constructor(
    public payload: {
      deleteRecordId: string;
      callback?: (error?: HttpErrorResponse) => void;
    }
  ) {}
}

export type RelatedRecordActions =
  | RelatedRecordsRequested
  | RelatedRecordsLoaded
  | RelatedRecordAdded
  | RelatedRecordDeleted
  | RelatedRecordsRemoved
  | GettingRelatedRecordsChange
  | RelatedRecordAddedRequested
  | RelatedRecordDeletedRequested;
