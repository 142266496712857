export enum RecordListItemTemplate {
  default = 'default',
  sumary = 'sumary',
  listItem = 'listItem',
  noaction = 'noaction',
  dummyrecord = 'dummyrecord',
  planItem = 'planItem',
  salesForceItem = 'salesForceItem',
  recordHeader = 'recordHeader',
}
