export enum SdColorPalette {
  accentBackground = 'var(--accentBackground)',
  alertsErrors = 'var(--alertsErrors)',
  appBackground = 'var(--appBackground)',
  appCameraBackground = 'var(--appCameraBackground)',
  backgroundShade = 'var(--backgroundShade)',
  checkbox = 'var(--checkbox)',
  headerBackground = 'var(--headerBackground)',
  inactiveButton = 'var(--inactiveButton)',
  primaryAccentColor = 'var(--primaryAccentColor)',
  primaryColor = 'var(--primaryColor)',
  primaryColorSemiTransparent = 'var(--primaryColorSemiTransparent)',
  progressBar = 'var(--progressBar)',
  secondaryAccentColor = 'var(--secondaryAccentColor)',
  successBar = 'var(--successBar)',
  tertiaryAccentColor = 'var(--tertiaryAccentColor)',
  webInitialBackground = 'var(--webInitialBackground)',
}
