import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisplayValuePipe } from './display-value.pipe';

@NgModule({
  declarations: [DisplayValuePipe],
  exports: [DisplayValuePipe],
  imports: [CommonModule],
})
export class DisplayValuePipeModule {}
