import { Route } from '@angular/router';
import { RecordFormViewComponent } from '../views/record-form-view/record-form-view.component';
import { RecordFormFilesTabComponent } from '../../files/views/record-form-files-tab/record-form-files-tab.component';
import { GeneralInformationTabComponent } from '../views/general-information-tab/general-information-tab.component';
import { RecordFormNetworksTabComponent } from '../../networks/views/record-form-networks-tab/record-form-networks-tab.component';
import { RecordFormRemindersTabComponent } from '../../reminders/views/record-form-reminders-tab/record-form-reminders-tab.component';
import { RecordFormRelatedRecordsTabComponent } from '../../related-records/views/record-form-related-records-tab/record-form-related-records-tab.component';
import { RecordFormSignatureTabComponent } from '../../signature/views/record-form-signature-tab/record-form-signature-tab.component';

export enum RecordsRoutes {
  root = 'records',
  main = '',
  list = 'list',
  form = 'form',
  view = 'view',
  search = 'search',
  new = 'new',
  generalTab = 'general',
  filesTab = 'files',
  remindersTab = 'reminders',
  collaboratorsTab = 'collaborators',
  relatedRecordsTab = 'related',
  signatureTab = 'signatures',
  copy = 'copy',
  move = 'move',
}

export const RECORD_FORM_ROUTES = [
  {
    path: RecordsRoutes.new,
    component: RecordFormViewComponent,
    loadChildren: () => RECORD_TABS_ROUTES,
  },
  {
    path: ':recordId',
    component: RecordFormViewComponent,
    loadChildren: () => RECORD_TABS_ROUTES,
  },
] as Route[];

export const RECORD_TABS_ROUTES = [
  {
    path: RecordsRoutes.filesTab,
    component: RecordFormFilesTabComponent,
  },
  {
    path: RecordsRoutes.generalTab,
    component: GeneralInformationTabComponent,
  },
  {
    path: RecordsRoutes.collaboratorsTab,
    component: RecordFormNetworksTabComponent,
  },
  {
    path: RecordsRoutes.remindersTab,
    component: RecordFormRemindersTabComponent,
  },
  {
    path: RecordsRoutes.remindersTab + '/:reminderId',
    component: RecordFormRemindersTabComponent,
  },
  {
    path: RecordsRoutes.relatedRecordsTab,
    component: RecordFormRelatedRecordsTabComponent,
  },
  {
    path: RecordsRoutes.signatureTab,
    component: RecordFormSignatureTabComponent,
  },
] as Route[];
