import { environment } from '../../../environments/environment';

export class DisplayInfo {
  constructor(
    public width?: number,
    public height?: number,
    public mobile?: boolean,
    public tablet?: boolean,
    public desktop?: boolean
  ) {}

  static isMobile(width: number): boolean {
    return width <= environment.small;
  }

  static isTablet(width: number): boolean {
    return width > environment.small && width <= environment.medium;
  }

  static isDesktop(width: number): boolean {
    return width > environment.medium;
  }

  static contentWidth(display: DisplayInfo): number {
    if (display.mobile) {
      return display.width - 100;
    }
    if (display.tablet) {
      const availableContent = display.width - 20;
      return availableContent > 1000 ? 1000 : availableContent;
    }
    if (display.desktop) {
      const availableContent = display.width - 20 - 200 - 280;
      return availableContent > 1000 ? 1000 : availableContent;
    }
  }

  static recordGridContentWidth(displayInfo: DisplayInfo): number {
    const tileWidth = 152;
    const gap = 16;
    const contentWidth = DisplayInfo.contentWidth(displayInfo);
    return Math.trunc(contentWidth / (tileWidth + gap)) * (tileWidth + gap);
  }
}
