import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AccessibilityHelper } from 'src/app/core/helpers/accessibility.helper';
import { SdColorPalette } from 'src/app/core/models/enums/sd-color-palette-enum';
import { SdAccessibilitySetting } from 'src/app/core/models/sd-accessibility-model';
import { environment } from 'src/environments/environment';
import { SdCheckButtonA11yEnum } from '../../model/sd-check-button-a11y.enum';

@Component({
  selector: 'app-sd-check-button-a11y',
  templateUrl: './sd-check-button-a11y.component.html',
  styleUrls: ['./sd-check-button-a11y.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SdCheckButtonA11yComponent {
  @Input() checked: boolean;
  @Input() value: string;
  @Input() img: string;
  @Input() svg: string;
  @Input() color: string;
  @Input() description: string;
  @Input() template = SdCheckButtonA11yEnum.default;
  cdn = environment.cdn;
  sdColorPalette = SdColorPalette;
  sdAccessibility: SdAccessibilitySetting;
  sdCheckButtonA11yEnum = SdCheckButtonA11yEnum;

  @Input() set setSdAccessibility(value: SdAccessibilitySetting) {
    this.sdAccessibility = AccessibilityHelper.setDefaultAccessibility(value);
  }
}
