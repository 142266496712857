import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SdSelectedItemA11yComponent } from './components/sd-selected-item-a11y/sd-selected-item-a11y.component';
import { SdSvgA11yModule } from '../sd-svg-a11y/sd-svg-a11y.module';

@NgModule({
  declarations: [SdSelectedItemA11yComponent],
  imports: [CommonModule, SdSvgA11yModule],
  exports: [SdSelectedItemA11yComponent],
})
export class SdSelectedItemA11yModule {}
