<app-sd-dialog-template-a11y
  (closeButtonClicked)="onClose()"
  [type]="dialogTemplateTypes.Info">
  <ng-container dialog-header>
    <h2>
      {{ 'recordspermissionviewerdialog_title' | dictionary | async }}
    </h2>
  </ng-container>
  <ng-container dialog-body>
    <div
      [innerHTML]="'recordspermissionviewerdialog_body' | dictionary | async"
      class="base-dialog-content"></div>
  </ng-container>
  <ng-container dialog-footer>
    <app-sd-flat-button-a11y (buttonClicked)="onClose()" [primary]="true">
      {{ 'recordspermissionviewerdialog_primarybutton' | dictionary | async }}
    </app-sd-flat-button-a11y>
  </ng-container>
</app-sd-dialog-template-a11y>
