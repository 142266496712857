import { UntypedFormGroup } from '@angular/forms';
import { SdValidationFormErrors } from 'src/app/account/models/sd-validation-form-errors.model';

export class SdFormHelper {
  static getErrorFormControl(
    form: UntypedFormGroup,
    controlName: string,
    typeValidationId: string,
    dictionaryKey: string
  ): SdValidationFormErrors {
    let errorValidation = null;
    if (form.get(controlName)?.hasError(typeValidationId)) {
      errorValidation = {
        [typeValidationId]: form.get(controlName).errors[typeValidationId],
        errorMessage: dictionaryKey,
      };
      return errorValidation;
    }
    return errorValidation;
  }
}
