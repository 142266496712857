import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DictionaryPipeModule } from 'src/app/dictionary/pipes/dictionary-pipe/dictionary-pipe.module';

import { SdCheckButtonA11yComponent } from './components/sd-check-button-a11y/sd-check-button-a11y.component';
import { SdSvgA11yModule } from '../sd-svg-a11y/sd-svg-a11y.module';

@NgModule({
  declarations: [SdCheckButtonA11yComponent],
  imports: [CommonModule, DictionaryPipeModule, SdSvgA11yModule],
  exports: [SdCheckButtonA11yComponent],
})
export class SdCheckButtonA11yModule {}
