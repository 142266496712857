import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AccessibilityHelper } from 'src/app/core/helpers/accessibility.helper';
import { SdAccessibilitySetting } from 'src/app/core/models/sd-accessibility-model';

@Component({
  selector: 'app-sd-div-container-a11y',
  templateUrl: './sd-div-container-a11y.component.html',
  styleUrls: ['./sd-div-container-a11y.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MatTooltipModule],
})
export class SdDivContainerA11yComponent {
  @Input() tooltip: string;
  sdAccessibility: SdAccessibilitySetting;

  @Input() set setSdAccessibility(value: SdAccessibilitySetting) {
    this.sdAccessibility = AccessibilityHelper.setDefaultAccessibility(value);
  }
}
