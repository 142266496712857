import { LiveAnnouncer } from '@angular/cdk/a11y';
import { select, Store } from '@ngrx/store';
import { delay, from, switchMap, take, tap } from 'rxjs';
import { SdValidationFormErrors } from 'src/app/account/models/sd-validation-form-errors.model';
import { dictionarySelector } from 'src/app/dictionary/store/dictionary.selectors';
import { AppState } from 'src/app/reducers';
import { SdAccessibilitySetting } from '../models/sd-accessibility-model';
import { map } from 'rxjs/operators';

export class AccessibilityHelper {
  static setDefaultAccessibility(
    sdConfigured: SdAccessibilitySetting
  ): SdAccessibilitySetting {
    let sdAccessibility: SdAccessibilitySetting;
    if (!sdConfigured || Object.keys(sdConfigured)?.length === 0) {
      sdAccessibility = {
        alt: '',
        ariaLabel: '',
        ariaLabelSuffix: '',
        ariaLabelledBy: '',
        imageName: '',
        name: '',
        role: '',
        tabIndex: null,
        liveAnnouncerText: '',
        id: '',
        ariaExpanded: false,
      };
      // TODO Dictionary global error for accessibility object not found
      console.warn('Accessibility object not found, please check.');
      return sdAccessibility;
    }

    if (sdConfigured) {
      sdAccessibility = {
        alt: sdConfigured.alt ? sdConfigured.alt : '',
        ariaLabel: sdConfigured.ariaLabel ? sdConfigured.ariaLabel : '',
        ariaLabelSuffix: sdConfigured.ariaLabelSuffix
          ? sdConfigured.ariaLabelSuffix
          : '',
        ariaLabelledBy: sdConfigured.ariaLabelledBy
          ? sdConfigured.ariaLabelledBy
          : '',
        imageName: sdConfigured.imageName ? sdConfigured.imageName : '',
        name: sdConfigured.name ? sdConfigured.name : '',
        role: sdConfigured.role ? sdConfigured.role : '',
        tabIndex: sdConfigured.tabIndex ? sdConfigured.tabIndex : null,
        ariaExpanded: sdConfigured.ariaExpanded ? sdConfigured.ariaExpanded : undefined,
        liveAnnouncerText: sdConfigured.liveAnnouncerText
          ? sdConfigured.liveAnnouncerText
          : '',
        id: sdConfigured.id ? sdConfigured.id : '',
      };
    }
    return sdAccessibility;
  }

  static announceMessage(
    store: Store<AppState>,
    errors: SdValidationFormErrors,
    liveAnnouncer: LiveAnnouncer,
    message?: string
  ): void {
    store
      .pipe(
        select(dictionarySelector),
        take(1),
        map(dictionary => {
          for (const key in errors) {
            if (Object.prototype.hasOwnProperty.call(errors, key)) {
              const element = errors[key];
              if (element) {
                message =
                  message +
                  ' ' +
                  (dictionary[element?.errorMessage] ?? element?.errorMessage) +
                  ' ';
              }
            }
          }
          return message;
        }),
        switchMap(message => from(liveAnnouncer.announce(message))),
        delay(100),
        tap(() => this.goToFirstError(errors))
      )
      .subscribe();
  }

  static announceOneMessage(
    store: Store<AppState>,
    liveAnnouncer: LiveAnnouncer,
    dictionaryKey: string,
    message?: string
  ): void {
    store
      .pipe(
        select(dictionarySelector),
        take(1),
        map(dictionary => {
          if (dictionary[dictionaryKey]) {
            return message
              ? dictionary[dictionaryKey] + ' ' + message
              : dictionary[dictionaryKey];
          }
          if (message) {
            return message;
          }
          return '';
        }),
        switchMap(fullMessage => from(liveAnnouncer.announce(fullMessage)))
      )
      .subscribe();
  }

  static goToFirstError(errors: SdValidationFormErrors): void {
    for (const key in errors) {
      if (Object.prototype.hasOwnProperty.call(errors, key)) {
        const element = errors[key];
        if (element) {
          this.goToElement(key);
          return;
        }
      }
    }
  }

  static goToElement(elementId: string): void {
    const element = document.getElementById(elementId);
    const elementInput = element?.querySelector('input');
    elementInput ? elementInput?.focus() : element?.focus();
  }
}
