<app-sd-dialog-template-a11y (closeButtonClicked)="onClose()">
  <ng-container dialog-header>
    <h2 *ngIf="!!data.errors && data.errors.size === 0">
      {{ 'remindersforminfodialog_successtitle' | dictionary | async }}
    </h2>

    <h2 *ngIf="!!errorReminder">
      {{ 'remindersforminfodialog_errorremindertitle' | dictionary | async }}
    </h2>
  </ng-container>
  <ng-container dialog-body>
    <div
      *ngIf="!!data.errors && data.errors.size === 0"
      [innerHTML]="'remindersforminfodialog_successbody' | dictionary | async"
      class="base-dialog-content"></div>

    <div
      *ngIf="!!errorReminder"
      [innerHTML]="
        'remindersforminfodialog_errorreminderbody' | dictionary | async
      "
      class="base-dialog-content"></div>

    <h3
      *ngIf="!!errorCollaboratorRecord"
      class="base-dialog-title">
      {{ 'remindersforminfodialog_errorsdroletitle' | dictionary | async }}
    </h3>

    <div
      *ngIf="!!errorCollaboratorRecord"
      [innerHTML]="
        'remindersforminfodialog_errorsdrolebody' | dictionary | async
      "
      class="base-dialog-content"></div>
  </ng-container>
  <ng-container dialog-footer>
    <app-sd-flat-button-a11y
      (buttonClicked)="onClose()"
      (keyup.enter)="onClose()"
      *ngIf="!!data.errors && data.errors.size === 0"
      [primary]="true">
      {{ 'remindersforminfodialog_successbutton' | dictionary | async }}
    </app-sd-flat-button-a11y>

    <app-sd-flat-button-a11y
      (buttonClicked)="onClose()"
      (keyup.enter)="onClose()"
      *ngIf="!!errorReminder && !errorCollaboratorRecord"
      [primary]="true">
      {{ 'remindersforminfodialog_errorreminderbutton' | dictionary | async }}
    </app-sd-flat-button-a11y>

    <app-sd-flat-button-a11y
      (buttonClicked)="onClose()"
      (keyup.enter)="onClose()"
      *ngIf="!!errorCollaboratorRecord && !errorReminder"
      [primary]="true">
      {{ 'remindersforminfodialog_errorsdrolebutton' | dictionary | async }}
    </app-sd-flat-button-a11y>

    <app-sd-flat-button-a11y
      (buttonClicked)="onClose()"
      (keyup.enter)="onClose()"
      *ngIf="!!errorCollaboratorRecord && !!errorReminder"
      [primary]="true">
      {{ 'globalparams_confirm' | dictionary | async }}
    </app-sd-flat-button-a11y>
  </ng-container>
</app-sd-dialog-template-a11y>
