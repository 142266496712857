import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  MyTeamsListActions,
  MyTeamsListActionsTypes,
} from './my-teams-list.actions';
import { Team } from '../../../account/models/team.model';

export interface MyTeamsListState extends EntityState<Team> {
  gettingTeams: boolean;
}

export const myTeamsListAdapter: EntityAdapter<Team> =
  createEntityAdapter<Team>({
    selectId: team => team.id,
  });

export const initialMyTeamsListState: MyTeamsListState =
  myTeamsListAdapter.getInitialState({
    gettingTeams: false,
  });

export function myTeamsListReducer(
  state = initialMyTeamsListState,
  action: MyTeamsListActions
): MyTeamsListState {
  switch (action.type) {
    case MyTeamsListActionsTypes.MyTeamsLoaded:
      return {
        ...state,
        ...myTeamsListAdapter.setAll(action.payload.teams, state),
      };
    case MyTeamsListActionsTypes.GettingMyTeams:
      return {
        ...state,
        gettingTeams: action.payload.gettingMyTeams,
      };
    default:
      return state;
  }
}

export const { selectAll } = myTeamsListAdapter.getSelectors();
