import { FileType } from '../models/file-type.enum';
import { UtilsHelper } from '../../core/helpers/utils.helper';
import { environment } from '../../../environments/environment';
import { DateHelper } from '../../core/helpers/date.helper';
import { FilesSortOption } from '../models/file-sort-option.model';
import { FileItem } from '../models/file-item.model';
import { forkJoin, Observable } from 'rxjs';
import { FileItemMetadata } from '../models/file-item-metadata.model';
import { select, Store } from '@ngrx/store';
import { authUserSelector } from '../../auth/store/auth.selectors';
import { map, take } from 'rxjs/operators';
import { accountSelector } from '../../account/store/account.selector';
import { FileItemHistoryActionTypes } from '../models/file-item-history-action-types.enum';
import { AppState } from '../../reducers';
import { FileItemHistory } from '../models/file-item-history.model';
import { PaginatorHelper } from '../../core/helpers/paginator.helper';

export class FilesHelper {
  public static getFileHistoryResourceUrl(
    sideDrawerId: string,
    recordId: string,
    options?: {
      nextPage?: string;
      fileName?: string;
      uploadTitle?: string;
      fileType?: FileType;
      envelopeId?: string;
      correlationId?: string;
      externalKey?: string;
      externalKeyValue?: string;
      sort?: 'ASC' | 'DESC';
    }
  ): string {
    let resourceUrl = UtilsHelper.apiVersion(environment.gatewayApi, 2);
    resourceUrl += `record-files/sidedrawer/sidedrawer-id/${sideDrawerId}/records/record-id/${recordId}/record-files?`;
    const {
      fileType,
      fileName,
      uploadTitle,
      nextPage,
      envelopeId,
      correlationId,
      externalKey,
      externalKeyValue,
      sort,
    } = options;
    if (nextPage?.length > 0) {
      resourceUrl += `startingAfter=${PaginatorHelper.cleanStartingAfterQueryParam(
        nextPage
      )}&`;
    }
    if (fileType?.length > 0) {
      resourceUrl += `fileType=${fileType?.toLowerCase()?.trim()}&`;
    }
    if (fileName?.length > 0) {
      resourceUrl += `fileName=${fileName?.toLowerCase()?.trim()}&`;
    }
    if (uploadTitle?.length > 0) {
      resourceUrl += `uploadTitle=${uploadTitle?.toLowerCase()?.trim()}&`;
    }
    if (envelopeId?.length > 0) {
      resourceUrl += `envelopeId=${envelopeId?.trim()}&`;
    }
    if (correlationId?.length > 0) {
      resourceUrl += `correlationId=${correlationId?.trim()}&`;
    }
    if (externalKey?.length > 0) {
      resourceUrl += `externalKey=${externalKey?.trim()}&`;
    }
    if (externalKeyValue?.length > 0) {
      resourceUrl += `externalKeyValue=${externalKeyValue?.trim()}&`;
    }
    if (sort?.length > 0) {
      resourceUrl += `sort=${sort?.trim()}&`;
    }
    return resourceUrl;
  }

  public static getFileResourceUrlBySideDrawerId(
    sideDrawerId: string,
    options?: {
      nextPage?: string;
      fileName?: string;
      uploadTitle?: string;
      fileType?: FileType;
    }
  ): string {
    let resourceUrl = UtilsHelper.apiVersion(environment.gatewayApi, 1);
    resourceUrl += `records/sidedrawer/sidedrawer-id/${sideDrawerId}/record-files?`;
    const { fileType, fileName, uploadTitle, nextPage } = options;
    if (nextPage?.length > 0) {
      resourceUrl += `startingAfter=${PaginatorHelper.cleanStartingAfterQueryParam(
        nextPage
      )}&`;
    }
    if (fileType?.length > 0) {
      resourceUrl += `fileType=${fileType?.toLowerCase()?.trim()}&`;
    }
    if (fileName?.length > 0) {
      resourceUrl += `fileName=${fileName?.toLowerCase()?.trim()}&`;
    }
    if (uploadTitle?.length > 0) {
      resourceUrl += `uploadTitle=${uploadTitle?.toLowerCase()?.trim()}`;
    }
    return resourceUrl;
  }

  public static getFileNameWithOutExtension(fileItem: FileItem): string {
    return fileItem.fileType === FileType.cloud
      ? fileItem.fileName
          ?.replace(
            `.${
              fileItem.fileName.split('.')[
                fileItem.fileName.split('.').length - 1
              ]
            }`,
            ''
          )
          ?.trim()
      : fileItem.uploadTitle;
  }

  public static getFileNameWithExtension(
    fileName: string,
    fileItem: FileItem
  ): string {
    if (fileItem?.fileToken) {
      return (fileName ?? fileItem?.fileName) + '.' + fileItem?.fileExtension;
    }
    // NOTE legacy support
    const extension =
      fileItem.fileUrl.split('.')[fileItem.fileUrl.split('.').length - 1];
    return fileName
      .substring(fileName.length - 4, fileName.length)
      .toLowerCase() === `.${extension.toLowerCase()}`
      ? fileName
      : `${fileName}.${extension.toLowerCase()}`;
  }

  public static blobToFile(blob: Blob, fileName: string): File {
    return new File([blob], fileName, { type: blob.type });
  }

  public static generateRandomId(): string {
    const envelopeId = [];
    const randomArray = window.crypto.getRandomValues(new Uint32Array(3));
    randomArray.forEach(randomNumber => {
      envelopeId.push(randomNumber.toString(16));
    });
    return envelopeId.join('-');
  }

  public static extensionMatch(
    extension: string,
    extensions: string[]
  ): boolean {
    let match = false;
    const extensionToCompare = extension.replace('.', '').toLowerCase().trim();
    extensions.forEach(ext => {
      const formattedExtension = ext.replace('.', '').toLowerCase().trim();
      if (formattedExtension === extensionToCompare) {
        match = true;
      }
    });
    return match;
  }

  public static generateFileName(name: string, hasExtension = true): string {
    let formattedName = '';
    if (hasExtension) {
      const nameAux = name?.split('.');
      nameAux?.splice(-1, 1);
      formattedName = nameAux.join('.');
    } else {
      formattedName = name;
    }
    formattedName = formattedName.split(' ').join('_');
    formattedName = formattedName.replace(/[^a-zA-Z0-9._%-]/g, '_');
    return formattedName.replace(/%/g, '_');
  }

  public static hasExtension(name: string): boolean {
    return name?.split('.').length > 1;
  }

  public static removeExtension(name: string): string {
    const nameAux = name?.split('.');
    nameAux?.splice(-1, 1);
    return nameAux.join('.');
  }

  public static generateFolderName(name: string): string {
    const aux = [];
    name
      .split(' ')
      .forEach(word =>
        aux.push(word.replace(/[^a-zA-Z0-9._%-]/g, '_').replace(/%/g, ''))
      );
    return aux.join(' ');
  }

  public static formatFileNameWithExtensionForDownLoad(
    fileName: string
  ): string {
    const nameAux = fileName.split('.');
    const extension = nameAux.splice(-1, 1);
    let formattedName = nameAux.join('.');
    formattedName = formattedName.replace(/[^ a-zA-Z0-9_-]/g, '_');
    return `${formattedName.substring(0, 120)}.${extension}`;
  }

  public static sortFilesByDate(list: FileItem[]): FileItem[] {
    try {
      if (!!list && list.length > 0) {
        const sortedList = [...list];
        return sortedList.sort((a, b) =>
          DateHelper.compareDates(b.updatedAt, a.updatedAt)
        );
      } else {
        return list;
      }
    } catch (e) {
      console.error(e);
      return list;
    }
  }

  public static calculateProgress(loaded: number): number {
    const emulatedFileSize = 200 * 1024 * 1024;
    const progress = (loaded / emulatedFileSize) * 100;
    return progress >= 90 ? 90 : progress;
  }

  public static generateFileNameWithExtension(fileItem: FileItem): string {
    if (fileItem.fileType === FileType.cloud) {
      return fileItem.fileName;
    }
    return this.getFileNameWithExtension(fileItem.uploadTitle, fileItem);
  }

  public static sortFilesByFilesSortOption(
    list: FileItem[],
    sortBy: FilesSortOption
  ): FileItem[] {
    try {
      if (!!list && list.length > 0) {
        const sortedList = [...list];
        switch (sortBy) {
          case FilesSortOption.lastModified:
            return sortedList.sort((a, b) =>
              DateHelper.compareDates(b.updatedAt, a.updatedAt)
            );
          case FilesSortOption.uploadedByAscending:
            return sortedList.sort((a, b) =>
              UtilsHelper.compareStrings(a.uploader, b.uploader)
            );
          case FilesSortOption.uploadedByDescending:
            return sortedList.sort((a, b) =>
              UtilsHelper.compareStrings(b.uploader, a.uploader)
            );
          case FilesSortOption.fileNameByAscending:
            return sortedList.sort((a, b) =>
              UtilsHelper.compareStrings(a.fileName, b.fileName)
            );
          case FilesSortOption.fileNameByDescending:
            return sortedList.sort((a, b) =>
              UtilsHelper.compareStrings(b.fileName, a.fileName)
            );
          case FilesSortOption.fileTypeByAscending:
            return sortedList.sort((a, b) =>
              UtilsHelper.compareStrings(a.fileType, b.fileType)
            );
          case FilesSortOption.fileTypeByDescending:
            return sortedList.sort((a, b) =>
              UtilsHelper.compareStrings(b.fileType, a.fileType)
            );
          default:
            return sortedList;
        }
      } else {
        return list;
      }
    } catch (e) {
      console.error(e);
      return list;
    }
  }

  public static getCloudStorageFileInformationFromUrl(
    fileItem: FileItem
  ): FileItem {
    if (fileItem.fileType !== FileType.cloud) {
      return fileItem;
    }
    try {
      const aux = fileItem.fileUrl.split('/');
      const provider = aux[aux.findIndex(value => value === 'provider') + 1];
      const driveId = aux[aux.findIndex(value => value === 'drive-id') + 1];
      const fileId = aux[aux.findIndex(value => value === 'file-id') + 1];
      return {
        ...fileItem,
        cloudStorageFile: {
          provider,
          driveId,
          fileId,
        },
      };
    } catch (e) {
      console.error(e);
      return fileItem;
    }
  }

  public static getFileItemMetadata(
    store: Store<AppState>,
    action: FileItemHistoryActionTypes,
    history: FileItemHistory[] = []
  ): Observable<FileItemMetadata> {
    return forkJoin([
      store.pipe(select(authUserSelector), take(1)),
      store.pipe(select(accountSelector), take(1)),
    ]).pipe(
      map(([authUser, account]) => ({
        history: [
          ...history,
          {
            action,
            actorName: `${account.firstName} ${account.lastName}`,
            actorEmailAddress: account.username,
            createdAt: new Date(),
            ipAddress: authUser.ip,
          },
        ],
      }))
    );
  }

  public static removeFileExtension(fileNameWithExtension: string): string {
    return fileNameWithExtension?.split('.').slice(0, -1).join('.');
  }

  public static getRecordIdFromFileUrl(file: FileItem): string {
    return file?.fileUrl?.split('/')[9];
  }

  public static filterBlockedFilesFromFileList(
    fileList: FileList,
    blockedFiles: string[],
    maxUpload: number
  ): FileList {
    const list = Array.from(fileList);
    const newList = [];
    list.forEach(e => {
      if (!FilesHelper.isFileInvalid(e, blockedFiles, maxUpload)) {
        newList.push(e);
      }
    });
    return Object.assign(newList);
  }

  public static filterDeletedFilesFromFileList(
    fileList: FileList,
    deletedFile: string
  ): FileList {
    const list = Array.from(fileList);
    const newList = list.filter(e => e.name !== deletedFile);
    return Object.assign(newList);
  }

  public static isFileInvalid(
    file: File,
    bloquedFormats: string[],
    maxUpload: number
  ): boolean {
    const extension = file.name.split('.')[file.name.split('.').length - 1];
    const forbiddenFile = FilesHelper.extensionMatch(extension, bloquedFormats);
    const sizeExceeded = file.size / 1048576 > maxUpload;
    return forbiddenFile || sizeExceeded;
  }

  public static blobToBase64(blob: Blob): Promise<string> {
    let reader: FileReader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onload = () => {
        resolve(reader.result as string);
      }
      reader.onerror = () => {
        reject("Error reading file.");
      }
      reader.readAsDataURL(blob);
    })
  }
}
