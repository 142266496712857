import { UtilsHelper } from '../../core/helpers/utils.helper';
import { environment } from '../../../environments/environment';
import { PaginatorHelper } from '../../core/helpers/paginator.helper';

export class TimelineHelper {
  public static getTimelineResourceUrl(
    sideDrawerId: string,
    locale: string,
    options?: {
      nextPage?: string;
      limit?: number;
    }
  ): string {
    let resourceUrl = UtilsHelper.apiVersion(environment.networksApi, 2);
    resourceUrl += `sidedrawer/sidedrawer-id/${sideDrawerId}/detailed-timeline?locale=${locale}`;
    if (!options) {
      return resourceUrl;
    }
    const { nextPage, limit } = options;
    if (nextPage?.length > 0) {
      resourceUrl += `&startingAfter=${PaginatorHelper.cleanStartingAfterQueryParam(
        nextPage
      )}`;
    }
    if (limit) {
      resourceUrl += `&limit=${limit}`;
    }
    return resourceUrl;
  }
}
