<app-sd-dialog-template-a11y
  (closeButtonClicked)="onClose()"
  [type]="DialogTemplateTypes.Form">
  <ng-container dialog-header>
    <h2>
      {{ 'recordrenameandchangetypedialog_title' | dictionary | async }}
    </h2>
  </ng-container>
  <ng-container dialog-body>
    <div class="rename-dialog-body">
      <app-sd-input-a11y
        (suffixClicked)="nameControl.reset()"
        [controller]="nameControl"
        [error]="
          isSubmitted
            ? ('recordformview_personalizednameplaceholder'
                | dictionary
                | async) +
              ' ' +
              ('globalparams_requirederror' | dictionary | async)
            : ''
        "
        [placeholder]="
          'recordformview_personalizednameplaceholder' | dictionary | async
        "
        [suffixSvg]="cdn + ('globalparams_reseticon' | dictionary | async)"
        [template]="SdInputA11yTemplates.inputWithSuffix"
        [sdAccessibilityForSuffix]="{
          ariaLabel: 'globalparams_delete' | dictionary | async
        }"
        [tooltip]="
          'recordformview_personalizednametooltip' | dictionary | async
        "
        [setSdAccessibility]="{
          id: 'nameControl',
          ariaLabel:
            'recordformview_personalizednameplaceholder' | dictionary | async
        }"
        [sdAccessibilityForTooltipsComponent]="{
          ariaLabel:
            'recordformview_personalizednametooltip' | dictionary | async,
          tabIndex: 0
        }"
        [type]="'text'"
        class="personalized-name-input" />

      <app-record-subtype-autocomplete-a11y
        (subTypeSelected)="onSubTypeChange($event)"
        [controller]="subTypeControl"
        [error]="
          isSubmitted
            ? ('createrecordforfilesdialog_subtypeplaceholder'
                | dictionary
                | async) +
              ' ' +
              subTypeControl.hasError('notFromSelection')
              ? ('createrecordforfilesdialog_subtypeplaceholder'
                  | dictionary
                  | async) +
                ' ' +
                ('globalparams_selectionerror' | dictionary | async)
              : ('createrecordforfilesdialog_subtypeplaceholder'
                  | dictionary
                  | async) +
                ' ' +
                ('globalparams_requirederror' | dictionary | async)
            : ''
        "
        [setSdAccessibility]="{
          id: 'subTypeControl',
          ariaLabel:
            'createrecordforfilesdialog_subtypeplaceholder' | dictionary | async
        }"
        [recordSubTypeName]="''"
        [startValue]="data?.record?.recordSubtype" />
    </div>
  </ng-container>
  <ng-container dialog-footer>
    <app-sd-flat-button-a11y (buttonClicked)="onClose()" [primary]="false">
      {{ 'globalparams_cancel' | dictionary | async }}</app-sd-flat-button-a11y
    >
    <app-sd-flat-button-a11y
      (buttonClicked)="onConfirm()"
      [inactiveButtonClass]="true"
      [loading]="recordRenameAndChangeTypeStore.gettingInformation$ | async"
      [primary]="form.valid && form.dirty">
      {{ 'globalparams_confirm' | dictionary | async }}</app-sd-flat-button-a11y
    >
  </ng-container>
</app-sd-dialog-template-a11y>
