<app-sd-dialog-template-a11y
  (closeButtonClicked)="onClose()"
  [type]="dialogTemplateTypes.Info">
  <ng-container dialog-header>
    <h2 id="sd-title-element">{{ getDialogTitle() }}</h2>
  </ng-container>

  <ng-container dialog-body>
    <div id="sd-content-download-element">
      <div
        *ngIf="(status$ | async) === zipStatus.confirmation"
        [innerHTML]="'zipdialog_downloadallfilesbody' | dictionary | async"
        class="base-dialog-content"></div>

      <div
        *ngIf="(status$ | async) === zipStatus.complete"
        [innerHTML]="'zipdialog_success' | dictionary | async"></div>

      <div
        *ngIf="(status$ | async) === zipStatus.completeWithErrors"
        [innerHTML]="'zipdialog_partialdownload' | dictionary | async"></div>

      <div
        *ngIf="(status$ | async) === zipStatus.canceled"
        [innerHTML]="'zipdialog_abort' | dictionary | async"></div>

      <div
        *ngIf="(status$ | async) === zipStatus.fails"
        [innerHTML]="'genericerrordialog_body' | dictionary | async"></div>

      <div
        *ngIf="(status$ | async) === zipStatus.noFiles"
        [innerHTML]="
          'zipdialog_partialdownloadnofiles' | dictionary | async
        "></div>

      <div
        *ngIf="
          (status$ | async) === zipStatus.information ||
          (status$ | async) === zipStatus.downloading ||
          (status$ | async) === zipStatus.zipping
        "
        class="processing-content">
        <div class="processing-content-title">
          {{
            'zipdialog_processingdownloadallfilesdescription'
              | dictionary
              | async
          }}
        </div>
        <div class="processing-content-sd">
          <span>{{
            'zipdialog_processingdownloadallfilesbodysidedrawer'
              | dictionary
              | async
          }}</span
          >{{ (zipSidedrawer$ | async)?.name }}
        </div>
        <div
          *ngIf="(status$ | async) === zipStatus.information"
          class="processing-content-status">
          {{
            'zipdialog_processingdownloadallfilesbodystatus1'
              | dictionary
              | async
          }}
        </div>
        <div
          *ngIf="(status$ | async) === zipStatus.downloading"
          class="processing-content-status">
          {{
            'zipdialog_processingdownloadallfilesbodystatus2'
              | dictionary
              | async
          }}
        </div>
        <div
          *ngIf="(status$ | async) === zipStatus.zipping"
          class="processing-content-status">
          {{
            'zipdialog_processingdownloadallfilesbodystatus3'
              | dictionary
              | async
          }}
        </div>
        <div
          *ngIf="(status$ | async) === zipStatus.downloading"
          class="processing-content-status processing-content-status-download"
          role="progressbar"
          tabindex="0">
          <span>{{
            'zipdialog_processingdownloadallfilesbodyitems' | dictionary | async
          }}</span>
          {{ completedItems$ | async }} / {{ totalItems$ | async }}
        </div>
        <div
          *ngIf="(status$ | async) === zipStatus.downloading"
          class="processing-content-status processing-content-status-download"
          role="progressbar"
          tabindex="0">
          <span>{{
            'zipdialog_processingdownloadallfilesprogress' | dictionary | async
          }}</span>
          {{ progress$ | async }}%
        </div>
      </div>

      <div
        *ngIf="
          (status$ | async) === zipStatus.completeWithErrors &&
          (errors$ | async)?.length > 0
        ">
        <ul>
          <li *ngFor="let error of errors$ | async" tabindex="0">
            {{ error }}
          </li>
        </ul>
      </div>

      <div
        *ngIf="
          (status$ | async) === zipStatus.fails ||
          (status$ | async) === zipStatus.completeWithErrors
        "></div>
    </div>
  </ng-container>

  <ng-container dialog-footer>
    <ng-container
      *ngIf="
        (status$ | async) === zipStatus.fails ||
        (status$ | async) === zipStatus.complete ||
        (status$ | async) === zipStatus.completeWithErrors ||
        (status$ | async) === zipStatus.canceled ||
        (status$ | async) === zipStatus.noFiles
      ">
      <app-sd-flat-button-a11y
        (buttonClicked)="onSuccess()"
        *ngIf="(status$ | async) === zipStatus.complete"
        [primary]="true"
        [setSdAccessibility]="{
          ariaLabel: 'sddownloadalert_successbutton' | dictionary | async
        }"
        class="primary">
        {{ 'zipdialog_successbutton' | dictionary | async }}
      </app-sd-flat-button-a11y>

      <app-sd-flat-button-a11y
        (buttonClicked)="onSuccess()"
        *ngIf="(status$ | async) === zipStatus.completeWithErrors"
        [primary]="true"
        [setSdAccessibility]="{
          ariaLabel: 'zipdialog_partialdownloadbutton' | dictionary | async
        }"
        class="primary">
        {{ 'zipdialog_partialdownloadbutton' | dictionary | async }}
      </app-sd-flat-button-a11y>

      <app-sd-flat-button-a11y
        (buttonClicked)="onReset()"
        *ngIf="(status$ | async) === zipStatus.canceled"
        [primary]="true"
        [setSdAccessibility]="{
          ariaLabel: 'zipdialog_abortbutton' | dictionary | async
        }"
        class="primary">
        {{ 'zipdialog_abortbutton' | dictionary | async }}
      </app-sd-flat-button-a11y>

      <app-sd-flat-button-a11y
        (buttonClicked)="onReset()"
        *ngIf="(status$ | async) === zipStatus.fails"
        [primary]="true"
        [setSdAccessibility]="{
          ariaLabel: 'zipdialog_partialdownloadbutton' | dictionary | async
        }"
        class="primary">
        {{ 'zipdialog_partialdownloadbutton' | dictionary | async }}
      </app-sd-flat-button-a11y>

      <app-sd-flat-button-a11y
        (buttonClicked)="onReset()"
        *ngIf="(status$ | async) === zipStatus.noFiles"
        [primary]="true"
        [setSdAccessibility]="{
          ariaLabel: 'zipdialog_partialdownloadbutton' | dictionary | async
        }"
        class="primary">
        {{ 'zipdialog_partialdownloadbutton' | dictionary | async }}
      </app-sd-flat-button-a11y>
    </ng-container>

    <ng-container
      *ngIf="
        !(
          (status$ | async) === zipStatus.fails ||
          (status$ | async) === zipStatus.complete ||
          (status$ | async) === zipStatus.completeWithErrors ||
          (status$ | async) === zipStatus.canceled ||
          (status$ | async) === zipStatus.noFiles
        )
      ">
      <app-sd-flat-button-a11y
        (buttonClicked)="onConfirm()"
        *ngIf="(status$ | async) === zipStatus.confirmation"
        [primary]="true"
        [setSdAccessibility]="{
          ariaLabel:
            'zipdialog_downloadallfilesprimarybutton' | dictionary | async
        }"
        class="primary">
        {{ 'zipdialog_downloadallfilesprimarybutton' | dictionary | async }}
      </app-sd-flat-button-a11y>

      <app-sd-flat-button-a11y
        (buttonClicked)="onCancel()"
        *ngIf="
          (status$ | async) === zipStatus.information ||
          (status$ | async) === zipStatus.downloading ||
          (status$ | async) === zipStatus.zipping
        "
        [primary]="true"
        [setSdAccessibility]="{
          ariaLabel:
            'zipdialog_processingdownloadallfilesprimarybutton'
            | dictionary
            | async
        }"
        class="primary">
        {{
          'zipdialog_processingdownloadallfilesprimarybutton'
            | dictionary
            | async
        }}
      </app-sd-flat-button-a11y>

      <app-sd-flat-button-a11y
        (buttonClicked)="onClose()"
        [primary]="false"
        [setSdAccessibility]="{
          ariaLabel:
            'zipdialog_downloadallfilessecondarybutton' | dictionary | async
        }"
        class="secondary">
        {{ 'zipdialog_downloadallfilessecondarybutton' | dictionary | async }}
      </app-sd-flat-button-a11y>

      <app-sd-flat-button-a11y
        (buttonClicked)="onClose()"
        *ngIf="
          (status$ | async) === zipStatus.information ||
          (status$ | async) === zipStatus.downloading ||
          (status$ | async) === zipStatus.zipping
        "
        [primary]="false"
        [setSdAccessibility]="{
          ariaLabel:
            'zipdialog_processingdownloadallfilessecondarybutton'
            | dictionary
            | async
        }"
        class="secondary">
        {{
          'zipdialog_processingdownloadallfilessecondarybutton'
            | dictionary
            | async
        }}
      </app-sd-flat-button-a11y>
    </ng-container>
  </ng-container>
</app-sd-dialog-template-a11y>
