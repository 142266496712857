import {
  createActionGroup,
  createFeature,
  createReducer,
  createSelector,
  emptyProps,
  on,
  props,
} from '@ngrx/store';
import { NetworkV2 } from '../models/network.v2.type';
import { createEntityAdapter } from '@ngrx/entity';
import { NetworkHelper } from '../helpers/network.helper';
import { Option } from '../../shared/sd-forms/models/option.model';
import { NetworksSelectorFilter } from '../models/networks-selector-filter.type';

export const NetworkPermanentActions = createActionGroup({
  source: 'network-permanent',
  events: {
    Loaded: props<{ networks: NetworkV2[] }>(),
    AllNetworksRequestedForReminders: emptyProps(),
    NetworksRequestedChange: props<{ requested: boolean }>(),
    FillingCabinetAllNetworksRequestedForReminders: emptyProps(),
  },
});

const adapter = createEntityAdapter<NetworkV2>({
  selectId: (network: NetworkV2) => network.id,
});

export const networkPermanentFeature = createFeature({
  name: 'network-permanent',
  reducer: createReducer(
    adapter.getInitialState({
      networksRequested: false,
    }),
    on(NetworkPermanentActions.loaded, (state, { networks }) =>
      adapter.upsertMany(
        NetworkHelper.filterInvalidNetworkV2Items(networks, true),
        state
      )
    ),
    on(
      NetworkPermanentActions.networksRequestedChange,
      (state, { requested }) => ({
        ...state,
        networksRequested: requested,
      })
    )
  ),
});

const { selectAll } = adapter.getSelectors();
export const networkPermanentStateSelector =
  networkPermanentFeature['selectNetwork-permanentState'];
export const networkPermanentNetworksRequestedSelector =
  networkPermanentFeature.selectNetworksRequested;
export const networkPermanentSelectAllSelector = createSelector(
  networkPermanentStateSelector,
  selectAll
);
export const networkPermanentElementByEmailSelector = (payload: {
  email: string;
}) =>
  createSelector(networkPermanentSelectAllSelector, networks =>
    NetworkHelper.networksSelectorFilter(networks, {
      asUsers: true,
      email: payload.email,
    })
  );
export const networkPermanentListSelector = (
  payload?: NetworksSelectorFilter
) =>
  createSelector(networkPermanentSelectAllSelector, networks =>
    NetworkHelper.networksSelectorFilter(networks, payload)
  );

export const networkPermanentListAsOptions = (
  payload?: NetworksSelectorFilter
) =>
  createSelector(networkPermanentListSelector(payload), networks =>
    NetworkHelper.networksSelectorFilter(networks, {
      asUsers: true,
      excludeTeams: payload?.excludeTeams,
    }).map(network => {
      return network.teamNetwork
        ? new Option(
            network.teamNetwork.teamId,
            network.teamNetwork.name,
            network.teamNetwork.logo
          )
        : new Option(
            network.contributor.openId ?? network.contributor.email,
            network.contributor.email,
            network.contributor.profilePhoto,
            network.contributor.name
          );
    })
  );
