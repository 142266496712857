import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { NetworkGroup } from '../../models/network-group.model';
import { CreateNetworkGroupDto } from '../../models/create-network-group.dto';

export const NetworkGroupsActions = createActionGroup({
  source: 'NetworkGroups',
  events: {
    requested: props<{ sideDrawerId: string }>(),
    loaded: props<{ groups: NetworkGroup[] }>(),
    clean: emptyProps(),
    added: props<{ group: NetworkGroup }>(),
    removed: props<{ id: string }>(),
    gettingInformationChanged: props<{ gettingInformation: boolean }>(),
    createRequested: props<{
      sideDrawerId: string;
      dto: CreateNetworkGroupDto;
    }>(),
  },
});
