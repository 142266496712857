import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SdFlatButtonA11yModule } from '../sd-flat-button-a11y/sd-flat-button-a11y.module';
import { SdCacheModule } from '../sd-cache/sd-cache.module';

@NgModule({
  imports: [CommonModule, RouterModule, SdFlatButtonA11yModule, SdCacheModule],
})
export class SdButtonsModule {}
