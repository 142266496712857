import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SdBreadcrumbsA11y } from '../model/sd-breadcrumbs-a11y.model';

@Injectable({ providedIn: 'root' })
export class SdBreadcrumbsA11yService {
  constructor(private readonly activatedRoute: ActivatedRoute) {}

  createBreadcrumbs(
    route = this.activatedRoute,
    url = '',
    breadcrumbs: SdBreadcrumbsA11y[] = []
  ): SdBreadcrumbsA11y[] {
    const children: ActivatedRoute[] = route.children;
    if (children?.length === 0 || !children) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.snapshot?.url
        .map(segment => {
          return segment.path;
        })
        .join('/');
      if (routeURL) {
        url += `/${routeURL}`;
      }

      const urlArray = child?.snapshot?.url;
      const title = child?.snapshot?.data?.breadcrumb;
      const ariaLabel = child?.snapshot?.data?.ariaLabel;
      const ariaCurrent = child?.snapshot?.data?.ariaCurrent;

      if (title && urlArray && urlArray.length > 0) {
        urlArray.forEach(labelItem => {
          const existsUrl = breadcrumbs.find(element => {
            if (element.url === url) {
              return true;
            }
          });

          if (!existsUrl) {
            breadcrumbs.push({
              label: title ? title : labelItem.path,
              path: labelItem.path,
              url: url,
              ariaCurrent: ariaCurrent ? ariaCurrent : 'page',
              ariaLabel: ariaLabel ? ariaLabel : '',
            });
          }
        });
      }

      return this.createBreadcrumbs(child, url, breadcrumbs);
    }
  }
}
