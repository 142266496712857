import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BrandingConfig } from '../models/branding-config.model';
import { TenantRole } from '../models/tenant-role.model';

@Injectable()
export class ConfigService {
  private tenantApi = environment.tenantApi;
  private configApi = environment.configApi;

  constructor(private readonly http: HttpClient) {}

  public static setTenantStyle(tenantConfig: BrandingConfig): void {
    if (tenantConfig?.styleSheet?.appBackground) {
      document.documentElement.style.setProperty(
        '--appBackground',
        tenantConfig.styleSheet.appBackground
      );
    }
    if (tenantConfig?.styleSheet?.primaryColor) {
      document.documentElement.style.setProperty(
        '--primaryColor',
        tenantConfig.styleSheet.primaryColor
      );
    }
    if (tenantConfig?.styleSheet?.primaryAccentColor) {
      document.documentElement.style.setProperty(
        '--primaryAccentColor',
        tenantConfig.styleSheet.primaryAccentColor
      );
    }
    if (tenantConfig?.styleSheet?.secondaryAccentColor) {
      document.documentElement.style.setProperty(
        '--secondaryAccentColor',
        tenantConfig.styleSheet.secondaryAccentColor
      );
    }
    if (tenantConfig?.styleSheet?.tertiaryAccentColor) {
      document.documentElement.style.setProperty(
        '--tertiaryAccentColor',
        tenantConfig.styleSheet.tertiaryAccentColor
      );
    }
    if (tenantConfig?.styleSheet?.backgroundShade) {
      document.documentElement.style.setProperty(
        '--backgroundShade',
        tenantConfig.styleSheet.backgroundShade
      );
    }
    if (tenantConfig?.styleSheet?.accentBackground) {
      document.documentElement.style.setProperty(
        '--accentBackground',
        tenantConfig.styleSheet.accentBackground
      );
    }
    if (tenantConfig?.styleSheet?.headerBackground) {
      document.documentElement.style.setProperty(
        '--headerBackground',
        tenantConfig.styleSheet.headerBackground
      );
    }
    if (tenantConfig?.styleSheet?.inactiveButton) {
      document.documentElement.style.setProperty(
        '--inactiveButton',
        tenantConfig.styleSheet.inactiveButton
      );
    }
    if (tenantConfig?.styleSheet?.alertsErrors) {
      document.documentElement.style.setProperty(
        '--alertsErrors',
        tenantConfig.styleSheet.alertsErrors
      );
    }
    if (tenantConfig?.styleSheet?.checkbox) {
      document.documentElement.style.setProperty(
        '--checkbox',
        tenantConfig.styleSheet.checkbox
      );
    }
    if (tenantConfig?.styleSheet?.progressBar) {
      document.documentElement.style.setProperty(
        '--progressBar',
        tenantConfig.styleSheet.progressBar
      );
    }
    if (tenantConfig?.styleSheet?.primaryColorSemiTransparent) {
      document.documentElement.style.setProperty(
        '--primaryColorSemiTransparent',
        tenantConfig.styleSheet.primaryColorSemiTransparent
      );
    }
    if (tenantConfig?.styleSheet?.appCameraBackground) {
      document.documentElement.style.setProperty(
        '--appCameraBackground',
        tenantConfig.styleSheet.appCameraBackground
      );
    }
    if (tenantConfig?.styleSheet?.successBar) {
      document.documentElement.style.setProperty(
        '--successBar',
        tenantConfig.styleSheet.successBar
      );
    }
    if (tenantConfig?.styleSheet?.headerBackground) {
      document.documentElement.style.setProperty(
        '--webInitialBackground',
        tenantConfig.styleSheet.headerBackground
      );
    }
    if (tenantConfig?.styleSheet?.fontFamily) {
      document.documentElement.style.setProperty(
        '--font01',
        tenantConfig.styleSheet.fontFamily
      );
    }
  }

  getTenantConfiguration(subdomain?: string): Observable<BrandingConfig> {
    subdomain =
      !!subdomain && subdomain.length > 0 ? subdomain : environment.brandCode;
    return this.http.get<BrandingConfig>(
      `${this.tenantApi}tenant/branding/brand-code/${subdomain}`
    );
  }

  getTenantCustomRoles(): Observable<TenantRole[]> {
    return this.http.get<TenantRole[]>(
      this.configApi + 'tenant-roles/all-assignments'
    );
  }
}
