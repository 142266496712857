import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SearchRoutes } from './routes/search.routes';

const routes: Routes = [
  {
    path: SearchRoutes.main,
    loadChildren: () =>
      import('./views/search-views.module').then(m => m.SearchViewsModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SearchRoutingModule {}
