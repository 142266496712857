import { Option } from '../../shared/sd-forms/models/option.model';
import { environment } from '../../../environments/environment';

export enum RecordsRoles {
  info = 'rec_info',
  viewer = 'rec_viewer',
  editor = 'rec_editor',
  owner = 'rec_owner',
}

export const getRecordRole = (roles: RecordsRoles[]): RecordsRoles => {
  if (!!roles && roles.length > 0) {
    return roles.find(role => role === RecordsRoles.owner)
      ? RecordsRoles.owner
      : roles.find(role => role === RecordsRoles.editor)
      ? RecordsRoles.editor
      : roles.find(role => role === RecordsRoles.viewer)
      ? RecordsRoles.viewer
      : RecordsRoles.info;
  } else {
    return RecordsRoles.info;
  }
};

export const compareRecordsRoles = (
  role1: RecordsRoles,
  role2: RecordsRoles
): RecordsRoles => {
  if (role1 === RecordsRoles.owner || role2 === RecordsRoles.owner) {
    return RecordsRoles.owner;
  }
  if (role1 === RecordsRoles.editor || role2 === RecordsRoles.editor) {
    return RecordsRoles.editor;
  }
  if (role1 === RecordsRoles.viewer || role2 === RecordsRoles.viewer) {
    return RecordsRoles.viewer;
  }
  if (role1 === RecordsRoles.info || role2 === RecordsRoles.info) {
    return RecordsRoles.info;
  }
};

export const getRecordsRolesOrdinalToSort = (role: string): number => {
  switch (role) {
    case RecordsRoles.owner:
      return 5;
    case RecordsRoles.editor:
      return 6;
    case RecordsRoles.viewer:
      return 7;
    case RecordsRoles.info:
      return 8;
    default:
      return 99;
  }
};

export const RecordRolesOptions: Option[] = [
  {
    key: RecordsRoles.editor,
    value: 'recordpermission_editor',
    avatar: environment.cdn + 'svgs/edit-regular.svg',
    description: 'recordpermission_editordescription',
  },
  {
    key: RecordsRoles.viewer,
    value: 'recordpermission_viewer',
    avatar: environment.cdn + 'svgs/glasses-regular.svg',
    description: 'recordpermission_viewerdescription',
  },
  {
    key: RecordsRoles.info,
    value: 'recordpermission_info',
    avatar: environment.cdn + 'svgs/ban-regular.svg',
    description: 'recordpermission_infodescription',
  },
];
