import { SearchBarActions, SearchbarActionsTypes } from './search-bar.actions';

export interface SearchBarState {
  expanded: boolean;
  searching: boolean;
  filter: string;
}

export const initialSearchBarState: SearchBarState = {
  expanded: false,
  searching: false,
  filter: null,
};

export function searchBarReducer(
  state: SearchBarState = initialSearchBarState,
  action: SearchBarActions
): SearchBarState {
  switch (action.type) {
    case SearchbarActionsTypes.ExpandSearchBar: {
      return {
        ...state,
        expanded: action.payload.expanded,
      };
    }
    case SearchbarActionsTypes.Searching: {
      return {
        ...state,
        searching: action.payload.searching,
      };
    }
    case SearchbarActionsTypes.SetSearchFilter: {
      return {
        ...state,
        filter: action.payload.filter,
      };
    }
    default:
      return state;
  }
}
