import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SdSvgA11yModule } from 'src/app/shared/sd-svg-a11y/sd-svg-a11y.module';
import { SdColorPalette } from 'src/app/core/models/enums/sd-color-palette-enum';
import { NgIf, NgStyle } from '@angular/common';

@Component({
  selector: 'app-sd-empty-template',
  standalone: true,
  imports: [SdSvgA11yModule, NgIf, NgStyle],
  templateUrl: './sd-empty-template.component.html',
  styleUrls: ['./sd-empty-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SdEmptyTemplateComponent {
  @Input() emptyStateInline = false;
  @Input() emptyStateString = '';
  @Input() emptyStateIcon: string;
  @Input() marginBottom: number;
  @Input() marginTop: number;
  @Input() marginLeft: number;
  sdColorPalette = SdColorPalette;
  readonly remUnit = 'rem';
}
