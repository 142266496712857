<app-sd-selector-a11y
  *ngIf="!!this.enumOptions && controller.value"
  [controller]="controller"
  [options]="this.enumOptions"
  [placeholder]="specificField?.displayValue[0]?.value"></app-sd-selector-a11y>

<app-sd-date-picker-a11y
  (dateChange)="onDateChange($event)"
  *ngIf="specificField.formType === specificFieldType.date"
  [controller]="controller"
  [locale]="locale$ | async"
  [placeholder]="specificField?.displayValue[0]?.value"
  [setSdAccessibilityForSuffix]="{
    role: 'button',
    ariaLabel: 'globalparams_selectdate' | dictionary | async
  }"
  [template]="sdInputA11yTemplates.inputDefault"></app-sd-date-picker-a11y>

<app-sd-input-a11y
  *ngIf="specificField.formType === specificFieldType.percentage"
  [controller]="controller"
  [matTextSuffix]="'%'"
  [placeholder]="specificField?.displayValue[0]?.value"
  [suffixSvgColor]=""
  [template]="sdInputA11yTemplates.inputWithSuffix"
  [type]="'text'"></app-sd-input-a11y>

<app-sd-input-a11y
  *ngIf="specificField.formType === specificFieldType.money"
  [controller]="controller"
  [mask]="'globalparams_numbermaskdecimal' | dictionary | async | sdMoneyMask"
  [placeholder]="specificField?.displayValue[0]?.value"
  [template]="sdInputA11yTemplates.inputWithSuffix"
  [thousandSeparator]="
    'globalparams_numbermaskdecimal' | dictionary | async | sdThousandSeparator
  "
  [type]="'text'"></app-sd-input-a11y>

<app-sd-input-a11y
  *ngIf="specificField.formType === specificFieldType.number"
  [controller]="controller"
  [placeholder]="specificField?.displayValue[0]?.value"
  [template]="sdInputA11yTemplates.inputWithSuffixWithoutMask"
  [type]="'number'"></app-sd-input-a11y>

<app-sd-input-a11y
  *ngIf="specificField.formType === specificFieldType.integer"
  [controller]="controller"
  [placeholder]="specificField?.displayValue[0]?.value"
  [template]="sdInputA11yTemplates.inputWithSuffixWithoutMask"
  [type]="'number'"></app-sd-input-a11y>

<app-sd-text-area-a11y
  *ngIf="specificField.formType === specificFieldType.rtf"
  [controller]="controller"
  [placeholder]="specificField?.displayValue[0]?.value"
  [sdAccessibilityForSuffix]="{
    ariaLabel: 'reset' | dictionary | async
  }"
  [template]="sdInputA11yTemplates.inputWithSuffix"></app-sd-text-area-a11y>

<app-sd-input-a11y
  (suffixClicked)="openUrl()"
  *ngIf="specificField.formType === specificFieldType.url"
  [controller]="controller"
  [placeholder]="specificField?.displayValue[0]?.value"
  [suffixSvgColor]=""
  [suffixSvg]="cdn + ('globalparams_externallinkicon' | dictionary | async)"
  [template]="sdInputA11yTemplates.inputWithSuffix"
  [type]="'text'"></app-sd-input-a11y>

<app-sd-input-a11y
  *ngIf="specificField.formType === specificFieldType.email"
  [controller]="controller"
  [placeholder]="specificField?.displayValue[0]?.value"
  [setSdAccessibility]="{
    ariaLabel: specificField?.displayValue[0]?.value
  }"
  [template]="sdInputA11yTemplates.inputDefault"
  [type]="'text'"></app-sd-input-a11y>

<app-sd-input-location-a11y
  *ngIf="specificField.formType === specificFieldType.address"
  [isFullLocationForm]="true"
  [placeOfResidenceController]="controller"
  [placeholders]="{ description: specificField?.displayValue[0]?.value }"
  [showStringAddress]="controller.value"
  [suffixFocusable]="true"
  [title]="specificField?.displayValue[0]?.value"></app-sd-input-location-a11y>

<app-sd-input-a11y
  *ngIf="specificField.formType === specificFieldType.string"
  [controller]="controller"
  [placeholder]="specificField?.displayValue[0]?.value"
  [setSdAccessibility]="{
    ariaLabel: specificField?.displayValue[0]?.value
  }"
  [template]="sdInputA11yTemplates.inputDefault"
  [type]="'text'"></app-sd-input-a11y>
