import { createSelector } from '@ngrx/store';
import { plansStateSelector } from './plans.selectors';
import { selectAll } from './plans-list.reducer';
import { ISvgPlanBadge } from '../models/plan-badge.model';
import { PlansService } from '../services/plans.service';

export const plansListStateSelector = createSelector(
  plansStateSelector,
  state => state.list
);

export const allPlanRequestedListWithOutFilterSelector = createSelector(
  plansListStateSelector,
  selectAll
);

export const allPlanRequestedListSelector = createSelector(
  allPlanRequestedListWithOutFilterSelector,
  plans => PlansService.filterPlansWithNoItems(plans)
);

export const planRequestedByIdSelector = (payload: { planId: string }) =>
  createSelector(allPlanRequestedListSelector, plans =>
    plans.find(item => item.id === payload.planId)
  );

export const planRequestedItemsByPlanIdSelector = (payload: {
  planId: string;
}) =>
  createSelector(
    planRequestedByIdSelector({ planId: payload.planId }),
    plan => plan?.items
  );

export const incompletePlansSelector = createSelector(
  allPlanRequestedListSelector,
  state =>
    state.reduce(
      (acc: ISvgPlanBadge, item) => {
        if (item.completedItems == 0) {
          acc.incomplete += 1;
        } else if (item.totalItems - item.completedItems > 0) {
          acc.inprogress += 1;
        }
        return acc;
      },
      { incomplete: 0, inprogress: 0 }
    )
);

export const itemRequestedByPlanIdSelector = (payload: {
  planId: string;
  itemId: string;
}) =>
  createSelector(planRequestedByIdSelector({ planId: payload.planId }), plan =>
    plan?.items?.find(item => item.id === payload.itemId)
  );

export const PlanRequestedAlreadyCompletedSelector = createSelector(
  plansListStateSelector,
  data => data.alreadyCompleted
);
