<div *ngIf="document$ | async as document" class="document-list-item">
  <div class="document-list-item-name">
    {{ document.name }}
  </div>
  <div
    *ngIf="(gettingInformation$ | async) === false; else downloadingDocument"
    class="document-list-item-actions">
    <app-sd-icon-button-a11y
      (btnClicked)="onView()"
      [iconColor]="'var(--tertiaryAccentColor)'"
      [iconHeight]="0.75"
      [iconWidth]="0.75"
      [icon]="
        cdn + ('documentlistitem_filehistorypreviewicon' | dictionary | async)
      "
      [tooltip]="
        'documentlistitem_filehistorypreviewlabel' | dictionary | async
      " />
    <app-sd-icon-button-a11y
      (btnClicked)="onDownload()"
      [iconColor]="'var(--tertiaryAccentColor)'"
      [iconHeight]="0.75"
      [iconWidth]="0.75"
      [icon]="cdn + ('documentlistitem_downloadicon' | dictionary | async)"
      [tooltip]="'documentlistitem_downloadlabel' | dictionary | async" />
  </div>
  <ng-template #downloadingDocument>
    <div class="document-list-item-actions-spinner">
      <app-sd-progress-spinner-a11y />
    </div>
  </ng-template>
</div>
