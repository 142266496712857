import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SdLinkMenuA11yModule } from '../../../shared/sd-link-menu-a11y/sd-link-menu-a11y.module';
import { SdProgressSpinnerA11yModule } from '../../../shared/sd-progress-spinner-a11y/sd-progress-spinner-a11y/sd-progress-spinner-a11y.module';
import { DictionaryPipeModule } from 'src/app/dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { RemindersListItemA11yModule } from '../reminders-list-item-a11y/reminders-list-item-a11y.module';
import { SdFlatButtonA11yModule } from '../../../shared/sd-flat-button-a11y/sd-flat-button-a11y.module';
import { SdLinkButtonA11yModule } from 'src/app/shared/sd-link-button-a11y/sd-link-button-a11y.module';
import { RemindersListA11yComponent } from './components/reminders-list-a11y/reminders-list-a11y.component';
import { SdTooltipButtonA11yComponent } from 'src/app/shared/sd-tooltip-button-a11y/component/sd-tooltip-button-a11y/sd-tooltip-button-a11y.component';
import { SdSvgA11yModule } from 'src/app/shared/sd-svg-a11y/sd-svg-a11y.module';
import { SdEmptyTemplateComponent } from 'src/app/shared/templates/sd-empty-template/components/sd-empty-template/sd-empty-template.component';
import { SdIconButtonA11yComponent } from 'src/app/shared/sd-icon-button-a11y/components/sd-icon-button-a11y/sd-icon-button-a11y.component';

@NgModule({
  declarations: [RemindersListA11yComponent],
  imports: [
    CommonModule,
    SdIconButtonA11yComponent,
    SdLinkMenuA11yModule,
    SdProgressSpinnerA11yModule,
    DictionaryPipeModule,
    RemindersListItemA11yModule,
    SdTooltipButtonA11yComponent,
    SdFlatButtonA11yModule,
    SdLinkButtonA11yModule,
    SdSvgA11yModule,
    SdEmptyTemplateComponent,
  ],
  exports: [RemindersListA11yComponent],
})
export class RemindersListA11yModule {}
