import { Dictionary } from '../../dictionary/models/dictionary.model';
import { Locale } from '../../dictionary/models/locale.model';
import * as moment from 'moment';

export class DateHelper {
  static readonly languageCodeSupportByApi = 'en';
  static formatDate(
    dateString: string,
    dictionary: Dictionary,
    locale: Locale
  ): string {
    const now = new Date();
    const date = new Date(dateString);
    const timeDifference = now.getTime() - date.getTime();
    const dayInMilliseconds = 86400000;
    const daysDifference = timeDifference / dayInMilliseconds;
    if (daysDifference < 1 && now.getDay() === date.getDay()) {
      return `${date.getHours()} : ${
        date.getMinutes() < 10 ? '0' : ''
      }${date.getMinutes()}`;
    } else if (
      (daysDifference < 1 && now.getDay() !== date.getDay()) ||
      daysDifference === 1
    ) {
      return dictionary?.globalparams_yesterday;
    } else if (daysDifference > 1 && daysDifference <= 7) {
      return this.getWeekDay(date, dictionary);
    } else {
      return this.formCustomDate(dateString, locale, 'LL');
    }
  }

  static formCustomDate(
    dateString: string,
    locale: Locale,
    localizeFormat = 'LL'
  ): string {
    if (!dateString) {
      return '';
    }
    moment.locale(Locale.getLocaleId(locale));
    return moment(new Date(dateString)).format(localizeFormat);
  }

  static getWeekDay(date: Date, dictionary: Dictionary): string {
    switch (date.getDay()) {
      case 1:
        return dictionary?.globalparams_monday;
      case 2:
        return dictionary?.globalparams_tuesday;
      case 3:
        return dictionary?.globalparams_wednesday;
      case 4:
        return dictionary?.globalparams_thursday;
      case 5:
        return dictionary?.globalparams_friday;
      case 6:
        return dictionary?.globalparams_saturday;
      case 7:
        return dictionary?.globalparams_sunday;
    }
  }

  static happensToday(dateString: string): boolean {
    const now = new Date();
    const date = new Date(dateString);
    const timeDifference = now.getTime() - date.getTime();
    const dayInMilliseconds = 86400000;
    const daysDifference = timeDifference / dayInMilliseconds;
    return daysDifference < 1 && now.getDay() === date.getDay();
  }

  static compareDates(dateStringA: Date, dateStringB: Date): number {
    const dateA = new Date(dateStringA);
    const dateB = new Date(dateStringB);
    return dateA.getTime() - dateB.getTime();
  }

  static compareDatesWithoutTime(dateStringA: Date, dateStringB: Date): number {
    const dateA = new Date(dateStringA);
    const dateB = new Date(dateStringB);
    dateA.setHours(0, 0, 0, 0);
    dateB.setHours(0, 0, 0, 0);
    return dateA.getTime() - dateB.getTime();
  }

  static convertDateToUTC(date: Date): Date {
    return new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes()
      )
    );
  }

  static converStringDateToUTC(dateString: string): Date {
    const date = new Date(dateString);
    return new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes()
      )
    );
  }

  static getCorrelationId(): string {
    return moment().format('YY MM DD HH mm ss').split(' ').join('');
  }

  static getTimeStamp(): string {
    return moment().format('YYYY-MM-DD_SSS');
  }

  static getTodayQuery(): string {
    const now = new Date();
    const month = now.getMonth() + 1;
    const year = now.getFullYear();
    const date = now.getDate();
    return `?today=${year * 10000 + month * 100 + date}`;
  }

  static getMonthsAgo(numberOfMonths: number): string {
    return moment()
      .subtract(numberOfMonths + 1, 'months')
      .format('MMM YY');
  }

  static getDaysOfWeeks(locale: Locale, longFormat = true): string[] {
    moment.locale(Locale.getLocaleId(locale));
    return longFormat ? moment.weekdays() : moment.weekdaysShort();
  }

  static getMonths(locale: Locale, longFormat = true): string[] {
    moment.locale(Locale.getLocaleId(locale));
    return longFormat ? moment.months() : moment.monthsShort();
  }

  static getDaysInMonth(locale: Locale, yyyMm = '2011-02'): number {
    moment.locale(Locale.getLocaleId(locale));
    return moment(yyyMm, 'YYYY-MM').daysInMonth();
  }

  static getFormattedDate(locale: Locale, format: string): string {
    moment.locale(Locale.getLocaleId(locale));
    return moment().format(format);
  }

  static getNameDayInEnglish(
    locale: Locale,
    nameDayInOtherLanguage: string
  ): string {
    moment.locale(Locale.getLocaleId(locale));
    const dayIndex = moment.weekdaysShort().indexOf(nameDayInOtherLanguage);
    moment.locale(this.languageCodeSupportByApi);
    const dayInEnglish = moment.weekdaysShort()[dayIndex];
    return dayInEnglish;
  }

  static getNamesDaysInEnglish(
    locale: Locale,
    namesDaysInOtherLanguage: string[]
  ): string[] {
    const namesDaysInEnglish = namesDaysInOtherLanguage.map(
      nameDayInOtherLanguage => {
        moment.locale(Locale.getLocaleId(locale));
        const dayIndex = moment.weekdaysShort().indexOf(nameDayInOtherLanguage);
        moment.locale(this.languageCodeSupportByApi);
        return moment.weekdaysShort()[dayIndex];
      }
    );
    return namesDaysInEnglish;
  }

  static getNamesDaysInOtherLanguage(
    locale: Locale,
    namesDaysInEnglish: string[]
  ): string[] {
    const namesDaysInOtherLanguage = namesDaysInEnglish.map(
      nameDayInEnglish => {
        moment.locale(this.languageCodeSupportByApi);
        const dayIndex = moment.weekdaysShort().indexOf(nameDayInEnglish);
        moment.locale(Locale.getLocaleId(locale));
        return moment.weekdaysShort()[dayIndex];
      }
    );
    return namesDaysInOtherLanguage;
  }
}
