import { Action } from '@ngrx/store';

export enum SdBackdropActionsTypes {
  DisplaySdBackDrop = '[] Display SD Backdrop',
  SdBackDropClicked = '[Sd Backdrop] SD Backdrop Clicked',
}

export class DisplaySdBackDrop implements Action {
  readonly type = SdBackdropActionsTypes.DisplaySdBackDrop;

  constructor(public payload: { grayed: boolean }) {}
}

export class SdBackDropClicked implements Action {
  readonly type = SdBackdropActionsTypes.SdBackDropClicked;
}

export type SdBackdropActions = DisplaySdBackDrop | SdBackDropClicked;
