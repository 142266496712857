import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  BrandingConfigLoaded,
  CoreActionsTypes,
  ErrorLoaded,
  GettingBrandingChange,
  RedirectoToUrl,
  RenderConfetti,
  SideDrawerBrandingRequested,
} from './core.actions';
import {
  exhaustMap,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { SidedrawerService } from '../../sidedrawer/services/sidedrawer.service';
import { UtilsHelper } from '../helpers/utils.helper';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import {
  defaultBrandingSelector,
  hideChatBubbleEnabledSelector,
} from './core.selectors';
import { environment } from '../../../environments/environment';
import { EMPTY, forkJoin, of } from 'rxjs';
import { GenericErrorDialogComponent } from '../../shared/sd-dialogs/generic-error-dialog/generic-error-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { BrandingConfig } from '../models/branding-config.model';
import { ConfigService } from '../services/config.service';
import { SideDrawerSpinnerChange } from '../../sidedrawer/store/sidedrawer.actions';
import { filingCabinetSelector } from '../../filing-cabinet/store/filing-cabinet.store';
import { Router } from '@angular/router';
import { RoutesHelper } from '../helpers/routes.helper';
import { activeSideDrawerSelector } from '../../sidedrawer/store/sidedrawer.selector';
import { SidedrawerRoutesEnum } from '../../sidedrawer/routes/sidedrawer-routes.enum';
import { RecordsPermissionViewerDialogComponent } from '../../records/shared/records-permission-viewer-dialog/components/records-permission-viewer-dialog/records-permission-viewer-dialog.component';
import { LandingRoutes } from 'src/app/landing/routes/landing.routes';

@Injectable()
export class CoreEffects {
  protected readonly actions$ = inject(Actions);
  protected readonly sideDrawerService = inject(SidedrawerService);
  protected readonly store = inject(Store<AppState>);
  protected readonly dialog = inject(MatDialog);
  protected readonly router = inject(Router);
  sideDrawerBrandingRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<SideDrawerBrandingRequested>(
        CoreActionsTypes.SideDrawerBrandingRequested
      ),
      switchMap(action =>
        forkJoin([
          of(action).pipe(take(1)),
          this.store.select(filingCabinetSelector).pipe(take(1)),
          this.store.select(defaultBrandingSelector).pipe(take(1)),
        ])
      ),
      exhaustMap(([action, filingCabinet, defaultBrand]) => {
        if (filingCabinet?.id) {
          this.store.dispatch(new SideDrawerSpinnerChange({ state: false }));
          this.store.dispatch(
            new GettingBrandingChange({ gettingBranding: false })
          );
          ConfigService.setTenantStyle(defaultBrand);
          return of(new BrandingConfigLoaded({ data: defaultBrand }));
        }
        this.store.dispatch(
          new GettingBrandingChange({ gettingBranding: true })
        );
        return this.sideDrawerService
          .getSideDrawerBranding(
            action.payload.sdId,
            action.payload.localeDefault,
            action.payload.styleMode
          )
          .pipe(
            tap({
              next: response => {
           
                const route = this.router?.url;
                const isSummaryView = route.includes(LandingRoutes.root);
          
                if (response?.hideSummary && isSummaryView) {
                  this.router
                    .navigateByUrl(
                      RoutesHelper.getBasePath(
                        this.store.selectSignal(activeSideDrawerSelector)()?.id
                      ) + SidedrawerRoutesEnum.root
                    )
                    .then(() => {
                      this.store.dispatch(
                        new GettingBrandingChange({ gettingBranding: false })
                      );
                    });
                  return;
                }
                this.store.dispatch(
                  new GettingBrandingChange({ gettingBranding: false })
                );
              },
              error: () =>
                this.store.dispatch(
                  new GettingBrandingChange({ gettingBranding: false })
                ),
            }),
            map(response => {
              const tenantConfig: BrandingConfig = response;
              // tslint:disable-next-line:no-string-literal
              tenantConfig.isDefault = response['default'];
              ConfigService.setTenantStyle(tenantConfig);
              this.store.dispatch(
                new SideDrawerSpinnerChange({ state: false })
              );
              return new BrandingConfigLoaded({ data: tenantConfig });
            })
          );
      })
    );
  });

  renderConfetti$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<RenderConfetti>(CoreActionsTypes.RenderConfetti),
        tap(() => {
          UtilsHelper.renderConfetti();
        })
      );
    },
    { dispatch: false }
  );

  brandConfigLoaded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<BrandingConfigLoaded>(CoreActionsTypes.BrandingConfigLoaded),
        mergeMap(() =>
          this.store.pipe(
            select(hideChatBubbleEnabledSelector),
            take(1),
            tap(hideChatBubble => {
              if (window.Intercom) {
                window.Intercom('update', {
                  app_id: environment.intercomAppId,
                  hide_default_launcher: hideChatBubble,
                });
              }
            })
          )
        )
      ),
    { dispatch: false }
  );

  genericError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ErrorLoaded>(CoreActionsTypes.ErrorLoaded),
        switchMap(action => {
          const { httpError, display404 } = action.payload;
          if (!httpError) {
            return EMPTY;
          }
          if (httpError?.status === 403) {
            return this.dialog
              .open(RecordsPermissionViewerDialogComponent, {
                autoFocus: false,
                data: { error: httpError },
              })
              .afterClosed();
          }
          if (display404 || httpError?.status !== 404) {
            return this.dialog
              .open(GenericErrorDialogComponent, {
                autoFocus: false,
                data: { error: httpError },
              })
              .afterClosed();
          }
          return EMPTY;
        })
      ),
    { dispatch: false }
  );

  RedirectoToUrl$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<RedirectoToUrl>(CoreActionsTypes.RedirectoToUrl),
        tap(action => {
          this.router.navigate([action.payload.url], {
            queryParams: action.payload.params,
          });
        })
      ),
    { dispatch: false }
  );

  constructor() {}
}
