import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { delay, take, tap } from 'rxjs/operators';
import { AlertTypes } from 'src/app/shared/sd-alerts/models/alert-types.enum';
import { AppState } from '../../../reducers';
import { dictionarySelector } from '../../../dictionary/store/dictionary.selectors';
import { LogOut } from '../../../auth/store/auth.actions';
import { SdValidationFormErrors } from 'src/app/account/models/sd-validation-form-errors.model';
import { SdFormHelper } from '../../../core/helpers/sd-form.helper';
import { AccessibilityHelper } from '../../../core/helpers/accessibility.helper';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MFADialogData } from 'src/app/start-flow/models/start-flow-MFA-dialog-data';
import { DialogTemplateTypes } from 'src/app/shared/templates/enums/templates.enum';
import { SdDialogTemplateA11yModule } from '../../../shared/templates/dialog-template-a11y/sd-dialog-template-a11y.module';
import { DictionaryPipeModule } from '../../../dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { AsyncPipe, NgIf } from '@angular/common';
import { SdAlertBoxA11yModule } from '../../../shared/sd-alert-box-a11y/sd-alert-box-a11y.module';
import { SdFlatButtonA11yModule } from '../../../shared/sd-flat-button-a11y/sd-flat-button-a11y.module';
import { MfaDialogStore, MfaState } from './mfa-dialog.store';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SdInputA11yComponent } from 'src/app/shared/sd-input-a11y/components/sd-input-a11y/sd-input-a11y.component';

@Component({
  selector: 'app-mfa-dialog',
  template: `
    <app-sd-dialog-template-a11y
      (closeButtonClicked)="onClose()"
      *ngIf="vm$ | async as vm">
      <ng-container dialog-header>
        <h2 id="sd-title-element">
          {{ 'mfadialog_title' | dictionary | async }}
        </h2>
      </ng-container>
      <ng-container dialog-body>
        <div #codeAlert>
          <app-sd-alert-box-a11y
            [message]="errorMessage$ | async"
            [type]="errorAlertType"
            [setSdAccessibility]="{
              ariaLabel: 'errorMessage',
              id: 'codeAlert'
            }" />
        </div>

        <div class="base-dialog-content">
          <p>{{ 'mfadialog_description' | dictionary | async }}</p>
          <app-sd-input-a11y
            [controller]="codeControl"
            [placeholder]="'mfadialog_codeplaceholder' | dictionary | async"
            [error]="
              isSubmitted
                ? ('globalparams_requirederror' | dictionary | async)
                : ''
            "
            type="text"
            [setSdAccessibility]="{
              ariaLabel: 'mfadialog_codeplaceholder' | dictionary | async,
              id: 'codeControl'
            }"></app-sd-input-a11y>
        </div>
      </ng-container>
      <ng-container dialog-footer>
        <app-sd-flat-button-a11y
          (buttonClicked)="onResend()"
          [loading]="vm.resendMfaStatus === 'processing'"
          [primary]="false"
          [setSdAccessibility]="{
            ariaLabel: 'mfadialog_secondarybutton' | dictionary | async
          }">
          {{ 'mfadialog_secondarybutton' | dictionary | async }}
        </app-sd-flat-button-a11y>
        <app-sd-flat-button-a11y
          (buttonClicked)="onConfirm()"
          [loading]="vm.mfaStatus === 'processing'"
          [primary]="codeControl.valid"
          [inactiveButtonClass]="true"
          [setSdAccessibility]="{
            ariaLabel: 'mfadialog_primarybutton' | dictionary | async
          }">
          {{ 'mfadialog_primarybutton' | dictionary | async }}
        </app-sd-flat-button-a11y>
      </ng-container>
    </app-sd-dialog-template-a11y>
  `,
  styleUrls: ['./mfa-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SdDialogTemplateA11yModule,
    DictionaryPipeModule,
    AsyncPipe,
    SdAlertBoxA11yModule,
    SdInputA11yComponent,
    SdFlatButtonA11yModule,
    NgIf,
  ],
  providers: [MfaDialogStore],
})
export class MfaDialogComponent {
  codeControl = new UntypedFormControl(null, [Validators.required]);
  errorMessage$: Observable<string>;
  errorAlertType = AlertTypes.error;
  mfaForm: UntypedFormGroup = new UntypedFormGroup({
    codeController: this.codeControl,
  });
  @ViewChild('codeAlert', { static: false }) codeAlert: ElementRef;
  errors: SdValidationFormErrors;
  isSubmitted: boolean;
  vm$: Observable<MfaState> = this.mfaDialogStore$.state$.pipe(
    tap(state => {
      this.errorMessage$ = of(null);
      if (state.mfaStatus === 'success') {
        this.dialogRef.close(true);
        return;
      }
      if (state.mfaStatus === 'fails' || state.resendMfaStatus === 'fails') {
        this.getErrorAndFocus();
        return;
      }
    })
  );
  dialogTemplateTypes = DialogTemplateTypes;

  constructor(
    private readonly dialogRef: MatDialogRef<MfaDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: MFADialogData,
    private readonly store: Store<AppState>,
    private readonly liveAnnouncer: LiveAnnouncer,
    private readonly mfaDialogStore$: MfaDialogStore
  ) {}

  onClose(): void {
    if (this.data.closable) {
      this.dialogRef.close();
    } else {
      this.store.dispatch(new LogOut());
      this.dialogRef.close(false);
    }
  }

  onConfirm(): void {
    this.isSubmitted = true;
    this.mfaForm.markAllAsTouched();
    this.errorMessage$ = of(null);
    if (this.mfaForm.invalid) {
      this.errors = {
        codeControl: SdFormHelper.getErrorFormControl(
          this.mfaForm,
          'codeController',
          'required',
          'mfadialog_errorbody'
        ),
      };
      AccessibilityHelper.announceMessage(
        this.store,
        this.errors,
        this.liveAnnouncer,
        '' // TODO Dictionary add global params for form errors and indicate user focus
      );
      return;
    }
    this.errors = {};
    this.mfaDialogStore$.checkMfa(this.codeControl.value);
  }

  onResend(): void {
    this.errorMessage$ = of(null);
    this.mfaDialogStore$.reSendMfa(this.data.mfa);
  }

  private getErrorAndFocus(): void {
    this.store
      .select(dictionarySelector)
      .pipe(
        take(1),
        tap(dictionary => {
          this.errorMessage$ = of(dictionary.mfadialog_errorbody);
        }),
        delay(200),
        tap(() => {
          AccessibilityHelper.goToElement('codeAlert');
        })
      )
      .subscribe();
  }
}
