import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output,} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {
  SdDivContainerA11yComponent
} from '../../../shared/sd-div-container-a11y/component/sd-div-container-a11y/sd-div-container-a11y.component';
import {AsyncPipe, NgClass, NgIf} from '@angular/common';
import {SdSvgA11yModule} from '../../../shared/sd-svg-a11y/sd-svg-a11y.module';
import {DictionaryPipeModule} from 'src/app/dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import {ContributorV2} from '../../models/contributor.v2.type';

@Component({
  selector: 'app-team-network-list-item-member-a11y',
  template: `
    <app-sd-div-container-a11y [tooltip]="member?.email">
      <ng-container dialog-body>
        <div
          [ngClass]="onRecordList ? 'on-record-list' : null"
          class="team-network-list-item-member"
          [attr.aria-label]="member?.name"
          tabindex="0">
          <div class="team-network-list-item-member-avatar">
            <img
              *ngIf="member?.profilePhoto?.length > 0"
              [alt]="member?.name"
              [src]="member?.profilePhoto" />
            <div *ngIf="!member?.profilePhoto" class="circle-avatar">
              {{ member?.name[0]?.toUpperCase() }}
            </div>
          </div>

          <div class="team-network-list-item-member-name">
            <div class="team-network-list-item-member-name-container">
              {{ member.name }}
            </div>
          </div>
          <div
            *ngIf="allowDeleteTeamMember"
            class="team-network-list-item-member-actions">
            <app-sd-svg-a11y
              [setSdAccessibility]="{
                tabIndex: 0,
                ariaLabel: 'globalparams_delete' | dictionary | async
              }"
              (click)="deleteMember.emit(true)"
              (keydown.enter)="deleteMember.emit(true)"
              [color]="'var(--tertiaryAccentColor)'"
              [height]="1.125"
              [src]="cdn + ('globalparams_deleteicon' | dictionary | async)"
              [width]="1.25" />
          </div>

          <div class="team-network-list-item-member-space"></div>
        </div>
      </ng-container>
    </app-sd-div-container-a11y>
  `,
  styleUrls: ['./team-network-list-item-member-a11y.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SdDivContainerA11yComponent,
    NgClass,
    NgIf,
    SdSvgA11yModule,
    DictionaryPipeModule,
    AsyncPipe,
  ],
})
export class TeamNetworkListItemMemberA11yComponent {
  @Input() member: ContributorV2;
  @Input() onRecordList = false;
  @Input() allowDeleteTeamMember = false;
  @Output() deleteMember = new EventEmitter<boolean>();
  cdn = environment.cdn;
}
