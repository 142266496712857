import { Component, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { activeSideDrawerRoleSelector } from '../../../sidedrawer/store/sidedrawer.selector';
import {
  activeRecordRoleSelector,
  activeRecordSelector,
  gettingRecordsSelector,
  sortedRecordListSelector,
} from '../../../records/store/records-list.selectors';
import { map } from 'rxjs/operators';
import { RoleHelper } from '../../../core/roles/role.helper';
import {
  getSideDrawerRole,
  SidedrawerRoles,
} from '../../../core/roles/sidedrawer.roles';
import { getRecordRole, RecordsRoles } from '../../../core/roles/records.roles';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { Record } from '../../../records/models/record.model';
import { SdAutocompleteA11yModule } from '../../../shared/sd-autocomplete-a11y/sd-autocomplete-a11y.module';
import { UntypedFormControl } from '@angular/forms';
import { SdAutoCompleteA11yEnum } from '../../../shared/sd-autocomplete-a11y/model/sd-autocomplete-a11y.enum';
import { environment } from '../../../../environments/environment';
import { DictionaryPipeModule } from '../../../dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { allRelatedRecordsSelector } from '../../store/related-records.selectors';
import { RecordsSortOptions } from '../../../records/models/records-sort-options.model';
import { RelatedRecordAddedRequested } from '../../store/related-records.actions';
import { RecordFormViewStore } from '../../../records/views/record-form-view/record-form-view.store';
import { SdRecordTabHeaderComponent } from '../../../shared/sd-record-tab-header/sd-record-tab-header.component';
import { RecordsListFilterChange } from 'src/app/records/store/records-list.actions';
import { RelatedRecordsTagsA11yComponent } from '../../shared/related-records-tags-a11y/components/related-records-tags-a11y.component';
import { SdTooltipButtonA11yComponent } from 'src/app/shared/sd-tooltip-button-a11y/component/sd-tooltip-button-a11y/sd-tooltip-button-a11y.component';
import { SdProgressBarA11yComponent } from 'src/app/shared/sd-progress-bar-a11y/components/sd-progress-bar-a11y/sd-progress-bar-a11y.component';

@Component({
  selector: 'app-record-form-related-records-tab',
  standalone: true,
  imports: [
    AsyncPipe,
    NgIf,
    SdAutocompleteA11yModule,
    DictionaryPipeModule,
    RelatedRecordsTagsA11yComponent,
    NgForOf,
    SdTooltipButtonA11yComponent,
    SdProgressBarA11yComponent,
    SdRecordTabHeaderComponent,
  ],
  templateUrl: './record-form-related-records-tab.component.html',
  styleUrls: ['./record-form-related-records-tab.component.scss'],
})
export class RecordFormRelatedRecordsTabComponent implements OnInit {
  hasPermission$: Observable<boolean> = combineLatest([
    this.store.select(activeSideDrawerRoleSelector),
    this.store.select(activeRecordRoleSelector),
  ]).pipe(
    map(([sdRole, recRole]) => {
      const role = RoleHelper.getHigherRoleForRecord(
        getSideDrawerRole(sdRole),
        getRecordRole(recRole as RecordsRoles[])
      );
      switch (role) {
        case RecordsRoles.info:
        case RecordsRoles.viewer:
        case SidedrawerRoles.info:
          return false;
        default:
          return true;
      }
    })
  );
  controller = new UntypedFormControl('');
  autocompleteTemplate = SdAutoCompleteA11yEnum;
  cdn = environment.cdn;
  spinner = false;

  vm$: Observable<{
    hasPermission: boolean;
    gettingRecords: boolean;
    records: Record[];
    relatedRecords: Record[];
    recordId: string;
  }> = combineLatest([
    this.hasPermission$,
    this.store.select(activeRecordSelector),
    this.store.select(allRelatedRecordsSelector),
    this.store.select(
      sortedRecordListSelector({ sortBy: RecordsSortOptions.lastModified })
    ),
    this.store.select(gettingRecordsSelector),
  ]).pipe(
    map(
      ([
        hasPermission,
        activeRecord,
        relatedRecords,
        records,
        gettingRecords,
      ]) => {
        // NOTE return the records that are not in the related records list
        const allNotRelatedRecords = records.filter(
          record =>
            !relatedRecords.some(rr => rr.id === record.id) &&
            record.id !== activeRecord?.id
        );
        return {
          hasPermission: hasPermission,
          gettingRecords: gettingRecords,
          records: allNotRelatedRecords,
          relatedRecords: relatedRecords,
          recordId: activeRecord?.id,
        };
      }
    )
  );

  constructor(
    private readonly store: Store<AppState>,
    private readonly recordFormViewStore: RecordFormViewStore
  ) {}

  ngOnInit(): void {
    this.store.dispatch(new RecordsListFilterChange({ recordTypeName: '' }));
  }

  onSelectRecord(selectedRecord: Record): void {
    this.recordFormViewStore.getOrCreateRecord(() =>
      this.createRelatedRecord(selectedRecord)
    );
  }

  createRelatedRecord(selectedRecord: Record): void {
    this.spinner = true;
    this.store.dispatch(
      new RelatedRecordAddedRequested({
        relatedRecord: selectedRecord,
        callback: () => {
          this.spinner = false;
          this.controller.setValue('');
          this.controller.enable();
        },
      })
    );
  }
}
