import { createSelector } from '@ngrx/store';
import { selectAll, selectEntities } from './records-list.reducer';
import { RecordListItem } from '../models/record-list-item.model';
import { RecordsSortOptions } from '../models/records-sort-options.model';
import { RecordsHelper } from '../helpers/records.helper';
import {
  activeSideDrawerRoleSelector,
  activeSideDrawerSelector,
} from '../../sidedrawer/store/sidedrawer.selector';
import { RoleHelper } from '../../core/roles/role.helper';
import {
  getSideDrawerRole,
  SidedrawerRoles,
} from '../../core/roles/sidedrawer.roles';
import { getRecordRole, RecordsRoles } from '../../core/roles/records.roles';
import { RecordType } from '../models/record-type.model';
import { recordsStateSelector } from './records.selectors';
import {
  dictionaryRolesListSelector,
  localeDefaultSelector,
} from '../../dictionary/store/dictionary.selectors';
import { filingCabinetSelector } from '../../filing-cabinet/store/filing-cabinet.store';
import { DateHelper } from '../../core/helpers/date.helper';

export const recordsListStateSelector = createSelector(
  recordsStateSelector,
  state => state.recordsList
);

export const recordsListSelector = createSelector(
  recordsListStateSelector,
  selectAll
);

export const sortRecordsBySelector = createSelector(
  recordsListStateSelector,
  state => state.sortRecordsBy
);

export const recordsListHasMoreSelector = createSelector(
  recordsListStateSelector,
  state => state.hasMore
);

export const sortedRecordListSelector = (payload?: {
  sortBy?: RecordsSortOptions;
}) =>
  createSelector(
    recordsListSelector,
    sortRecordsBySelector,
    (records: RecordListItem[], sortBy: RecordsSortOptions) =>
      !!records && records.length > 0
        ? RecordsHelper.sortRecords(records, payload?.sortBy ?? sortBy)
        : []
  );

export const sortedRecordListSelectorByRecordType = (payload?: {
  recordType?: RecordType;
  sortBy?: RecordsSortOptions;
}) =>
  createSelector(
    recordsListSelector,
    sortRecordsBySelector,
    (records: RecordListItem[], sortBy: RecordsSortOptions) => {
      if (!!records && records.length > 0) {
        const filteredRecords = records.filter(
          recordItem => recordItem.recordType.name === payload?.recordType?.name
        );
        return RecordsHelper.sortRecords(
          filteredRecords,
          payload?.sortBy ?? sortBy
        );
      }
      return [];
    }
  );

export const recordsEntitySelector = createSelector(
  recordsListStateSelector,
  selectEntities
);

export const recordByRecordIdSelector = (payload: { recordId: string }) =>
  createSelector(
    recordsEntitySelector,
    entities => entities[`${payload.recordId}`]
  );

export const activeRecordSelector = createSelector(
  recordsListStateSelector,
  state => state.activeRecord
);

export const activeRecordTypeSelector = createSelector(
  recordsListStateSelector,
  state => state.activeRecordType
);

export const activeRecordSubTypeSelector = createSelector(
  recordsListStateSelector,
  state => state.activeRecordSubType
);

export const recordsListFilteredByRecordTypeNameSelector = createSelector(
  recordsListSelector,
  activeRecordTypeSelector,
  sortRecordsBySelector,
  activeSideDrawerSelector,
  (
    records: RecordListItem[],
    recordType: RecordType,
    sortBy: RecordsSortOptions,
    activeSideDrawer
  ) =>
    !!records && records.length > 0
      ? RecordsHelper.sortRecords(
          recordType?.name
            ? records.filter(
                recordItem =>
                  recordItem?.recordType?.name === recordType?.name &&
                  recordItem?.sideDrawerId === activeSideDrawer?.id
              )
            : records,
          sortBy
        )
      : []
);

export const gettingRecordsSelector = createSelector(
  recordsListStateSelector,
  state => state.gettingRecords
);

export const gettingActiveRecordSelector = createSelector(
  recordsListStateSelector,
  state => state.gettingActiveRecord
);
export const gettingActiveRecordSubTypesSelector = createSelector(
  recordsListStateSelector,
  state => state.gettingActiveSubTypes
);

export const recordSubTypesSelector = createSelector(
  recordsListStateSelector,
  state => state.activeSubTypes
);

export const rawActiveRecordRoleSelector = createSelector(
  activeRecordSelector,
  state => [
    ...(state?.userRecordRole ?? []),
    ...(state?.userSidedrawerRole ?? []),
  ]
);

export const activeRecordRoleSelector = createSelector(
  rawActiveRecordRoleSelector,
  dictionaryRolesListSelector,
  (roles, dicRoles) =>
    roles?.map(
      role =>
        (dicRoles?.find(dicRole => dicRole.roleId === role)?.roleGroupId as
          | SidedrawerRoles
          | RecordsRoles) ?? role
    )
);

export const activeRecordSectionsSelector = createSelector(
  recordsListStateSelector,
  state =>
    !!state &&
    !!state.activeRecordSubType &&
    !!state.activeRecordSubType.recordsSections
      ? state.activeRecordSubType.recordsSections
      : null
);

export const descriptionSectionEnabledSelector = createSelector(
  activeRecordSectionsSelector,
  sections =>
    !!sections && sections?.sectionDescription !== undefined
      ? sections.sectionDescription
      : true
);

export const filesSectionEnabledSelector = createSelector(
  activeRecordSectionsSelector,
  sections =>
    !!sections && sections?.sectionFiles !== undefined
      ? sections.sectionFiles
      : true
);

export const relatedRecordsSectionEnabledSelector = createSelector(
  activeRecordSectionsSelector,
  sections =>
    !!sections && sections?.sectionLinkedRecords !== undefined
      ? sections.sectionLinkedRecords
      : true
);

export const collaboratorsSectionEnabledSelector = createSelector(
  activeRecordSectionsSelector,
  sections =>
    !!sections && sections?.sectionCollaborators !== undefined
      ? sections.sectionCollaborators
      : true
);

export const specificFieldsSectionEnabledSelector = createSelector(
  activeRecordSectionsSelector,
  sections =>
    !!sections && sections?.sectionSpecificFields !== undefined
      ? sections.sectionSpecificFields
      : true
);

export const customFieldsSectionEnabledSelector = createSelector(
  activeRecordSectionsSelector,
  sections =>
    !!sections && sections?.sectionCustomFields !== undefined
      ? sections.sectionCustomFields
      : true
);

export const remindersSectionEnabledSelector = createSelector(
  activeRecordSectionsSelector,
  sections =>
    !!sections && sections?.sectionReminders !== undefined
      ? sections.sectionReminders
      : true
);

export const signaturesSectionEnabledSelector = createSelector(
  activeRecordSectionsSelector,
  sections =>
    !!sections && sections?.sectionSignatures !== undefined
      ? sections.sectionSignatures
      : true
);

export const generalInfoSectionEnabledSelector = createSelector(
  descriptionSectionEnabledSelector,
  specificFieldsSectionEnabledSelector,
  customFieldsSectionEnabledSelector,
  (description, specificFields, customFields) =>
    description || specificFields || customFields
);

export const recordDetailsCustomFieldsSelector = createSelector(
  activeRecordSelector,
  state =>
    !!state &&
    !!state.recordDetails?.customFields &&
    state.recordDetails.customFields.length > 0
      ? state.recordDetails.customFields
      : null
);

export const userHasViewerOrLessPermissions = createSelector(
  activeSideDrawerRoleSelector,
  activeRecordRoleSelector,
  (sdRole, recRole) => {
    const role = RoleHelper.getHigherRoleForRecord(
      getSideDrawerRole(sdRole),
      getRecordRole(recRole as RecordsRoles[])
    );
    switch (role) {
      case RecordsRoles.viewer:
      case RecordsRoles.info:
      case SidedrawerRoles.info:
        return true;
      default:
        return false;
    }
  }
);

export const userCanOperateOnThisRecordSelector = createSelector(
  activeSideDrawerRoleSelector,
  activeRecordRoleSelector,
  (sdRole, recRole) => {
    const role = RoleHelper.getHigherRoleForRecord(
      getSideDrawerRole(sdRole),
      getRecordRole(recRole as RecordsRoles[])
    );
    switch (role) {
      case RecordsRoles.viewer:
      case SidedrawerRoles.viewer:
      case RecordsRoles.info:
      case SidedrawerRoles.info:
        return false;
      default:
        return true;
    }
  }
);

export const recordListFilterSelector = createSelector(
  recordsListStateSelector,
  state => state.filter
);

export const recordCopyMoveLoaderSelector = createSelector(
  recordsListStateSelector,
  state => state.copyMoving
);

export const recordPersonalizedNameUsingFcNameOrSdName = (payload: {
  recordSubTypeName: string;
}) =>
  createSelector(
    activeSideDrawerSelector,
    filingCabinetSelector,
    localeDefaultSelector,
    (sd, fc, locale) =>
      payload?.recordSubTypeName?.length > 0
        ? `${fc?.name ?? sd?.name} - ${
            payload.recordSubTypeName
          } - ${DateHelper.getFormattedDate(locale, 'YYYY-MM-DD')}`
        : `${fc?.name ?? sd?.name} - ${DateHelper.getFormattedDate(
            locale,
            'YYYY-MM-DD'
          )}`
  );
