import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import {
  catchError,
  exhaustMap,
  finalize,
  map,
  mergeMap,
  take,
  tap,
} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  FilingCabinetAllRemindersRequested,
  ReminderAddError,
  ReminderChangeStatus,
  ReminderChangeStatusRequested,
  ReminderCopyLinkRequested,
  ReminderCreated,
  ReminderCreateRequested,
  ReminderDeleted,
  ReminderDeleteRequested,
  ReminderGetReminderByIdRequested,
  ReminderGetReminderByIdSuccess,
  RemindersListActionsTypes,
  RemindersLoaded,
  ReminderSpinnerChange,
  RemindersRequested,
  RemindersWithNoResourceRequested,
  ReminderUpdated,
  ReminderUpdateRequested,
} from './reminders-list.action';
import { RemindersService } from '../services/reminders.service';
import { concat, forkJoin, of, switchMap, takeUntil } from 'rxjs';
import { Reminders } from '../models/reminders.model';
import { ReminderRequested } from '../models/reminders-requested.model';
import { Update } from '@ngrx/entity';
import { ErrorType } from '../models/enums/error-type.enum';
import { SfrError } from '../models/sfr-error.model';
import {
  activeSideDrawerSelector,
  basePathSelector,
} from '../../sidedrawer/store/sidedrawer.selector';
import { ErrorLoaded, RedirectoToUrl } from 'src/app/core/store/core.actions';
import { dictionarySelector } from 'src/app/dictionary/store/dictionary.selectors';
import { RecordsRoutes } from 'src/app/records/routes/records.routes';
import { SdSnackBarDisplaySnackBar } from 'src/app/shared/sd-snack-bar/store/sd-snack-bar.actions';
import { SidedrawerRoutesEnum } from 'src/app/sidedrawer/routes/sidedrawer-routes.enum';
import { RecordsService } from 'src/app/records/services/records.service';
import { RemindersRoutes } from '../routes/reminders.routes';
import { Record } from 'src/app/records/models/record.model';
import { TypesOfReminders } from '../models/enums/types-of-reminders.enum';
import {
  FilingCabinetActions,
  filingCabinetSideDrawersSelector,
} from '../../filing-cabinet/store/filing-cabinet.store';
import { HttpErrorResponse } from '@angular/common/http';
import { RoutesHelper } from 'src/app/core/helpers/routes.helper';
import { LandingRoutes } from 'src/app/landing/routes/landing.routes';

@Injectable()
export class RemindersListEffects {
  remindersWithNoResourceRequested = createEffect(() =>
    this.actions$.pipe(
      ofType<RemindersWithNoResourceRequested>(
        RemindersListActionsTypes.RemindersWithNoResourceRequested
      ),
      switchMap(action =>
        forkJoin([
          this.store.pipe(select(activeSideDrawerSelector), take(1)),
          of(action.payload),
        ])
      ),
      map(([activeSideDrawer, payload]) => {
        return new RemindersRequested({
          resource: payload
            ? `sidedrawer/${activeSideDrawer.id}/record/${payload.recordId}`
            : `sidedrawer/${activeSideDrawer.id}`,
          sideDrawerId: activeSideDrawer.id,
        });
      })
    )
  );
  remindersRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<RemindersRequested>(RemindersListActionsTypes.RemindersRequested),
      tap(() =>
        this.store.dispatch(new ReminderSpinnerChange({ state: true }))
      ),
      mergeMap(action =>
        this.reminderService.getReminders(action.payload.resource).pipe(
          catchError(() => {
            this.store.dispatch(new ReminderSpinnerChange({ state: false }));
            return of([]);
          }),
          map(data =>
            data.map(
              (d: ReminderRequested) =>
                new Reminders(
                  d.name,
                  d.resource,
                  d.message,
                  d.schedule,
                  d.to,
                  d.id,
                  d.status,
                  action.payload.sideDrawerId
                )
            )
          ),
          map(reminders => {
            this.store.dispatch(new ReminderSpinnerChange({ state: false }));
            return new RemindersLoaded({ reminders });
          }),
          finalize(() => {
            this.store.dispatch(new ReminderSpinnerChange({ state: false }));
          })
        )
      )
    );
  });

  afterDeletedReminder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<ReminderDeleteRequested>(
        RemindersListActionsTypes.ReminderDeleteRequested
      ),
      mergeMap(action =>
        this.reminderService.deleteReminder(action.payload.id).pipe(
          catchError(() => {
            // TODO handle this error...
            return of(null);
          }),
          map(() => new ReminderDeleted({ id: action.payload.id }))
        )
      )
    );
  });

  updateStatusForReminder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<ReminderChangeStatusRequested>(
        RemindersListActionsTypes.ReminderChangeStatusRequested
      ),
      mergeMap(action =>
        this.reminderService
          .updateReminderStatus(
            action.payload.id.toString(),
            action.payload.status.toString()
          )
          .pipe(
            catchError(() => {
              // TODO handle this error...
              return of(null);
            }),
            map((reminderUpdated: ReminderRequested) => {
              const updatedReminder: Update<Reminders> = {
                id: action.payload.id,
                changes: {
                  ...action.payload.reminder,
                  status: reminderUpdated?.status
                    ? reminderUpdated.status
                    : action.payload.reminder.status,
                },
              };

              return new ReminderChangeStatus({
                reminder: updatedReminder,
              });
            })
          )
      )
    );
  });

  reminderCreateRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<ReminderCreateRequested>(
        RemindersListActionsTypes.ReminderCreateRequested
      ),
      mergeMap(action =>
        this.reminderService.createReminder(action.payload.reminder).pipe(
          map(
            data =>
              new Reminders(
                data.name,
                data.resource,
                data.message,
                data.schedule,
                data.to,
                data.id || data['_id'],
                data.status
              )
          ),
          map(reminder => new ReminderCreated({ reminder })),
          catchError((error: HttpErrorResponse) => {
            const errors = new Map<string | ErrorType, SfrError>().set(
              ErrorType.errorReminder,
              {
                type: ErrorType.errorReminder,
                entityId: action.payload.reminder.id,
                statusCode: error.status.toString(),
              }
            );
            return of(new ReminderAddError({ errors: errors }));
          })
        )
      )
    );
  });

  reminderUpdateRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<ReminderUpdateRequested>(
        RemindersListActionsTypes.ReminderUpdateRequested
      ),
      mergeMap(action =>
        this.reminderService
          .updateReminder(
            action.payload.reminder.id.toString(),
            action.payload.reminder.changes
          )
          .pipe(
            map(() => {
              return new ReminderUpdated({
                reminder: action.payload.reminder,
              });
            }),
            catchError((error: HttpErrorResponse) => {
              const errors = new Map<string | ErrorType, SfrError>().set(
                ErrorType.errorReminder,
                {
                  type: ErrorType.errorReminder,
                  entityId: action.payload.reminder.id.toString(),
                  statusCode: error.status.toString(),
                }
              );
              return of(new ReminderAddError({ errors: errors }));
            })
          )
      )
    );
  });

  reminderGetReminderByIdRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<ReminderGetReminderByIdRequested>(
        RemindersListActionsTypes.ReminderGetReminderByIdRequested
      ),
      mergeMap(action =>
        this.reminderService.getOneReminder(action.payload.id).pipe(
          map(data => {
            const reminder = new Reminders(
              data.name,
              data.resource,
              data.message,
              data.schedule,
              data.to,
              data.id || data['_id'],
              data.status
            );

            this.store.dispatch(new ReminderSpinnerChange({ state: false }));
            return new ReminderGetReminderByIdSuccess({ reminder });
          }),
          catchError(error => {
            this.store.dispatch(new ReminderSpinnerChange({ state: false }));
            const basePath = this.store.selectSignal(basePathSelector)();
            this.store.dispatch(
              new RedirectoToUrl({ url: basePath + LandingRoutes.root })
            );
            return of(new ErrorLoaded({ httpError: { ...error.httpError } }));
          })
        )
      )
    );
  });

  reminderCopyLinkRequested$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReminderCopyLinkRequested>(
          RemindersListActionsTypes.ReminderCopyLinkRequested
        ),
        switchMap(action =>
          forkJoin([
            of(action?.payload?.sideDrawerId).pipe(take(1)),
            of(action?.payload?.reminder).pipe(take(1)),
            of(RoutesHelper.getBasePath(action?.payload?.sideDrawerId)),
          ])
        ),
        switchMap(([sideDrawerId, reminder, basePath]) => {
          const reminderType = this.parseReminderResource(reminder.resource);
          const reminderEntity = this.getReminderEntity(reminderType);
          if (reminderEntity === TypesOfReminders.record) {
            return this.recordsService
              .getRecordInformation(sideDrawerId, reminderType?.record, false)
              .pipe(
                map(record => {
                  return this.generateCopyRoute(
                    reminder,
                    basePath,
                    reminderEntity,
                    record
                  );
                }),
                switchMap(url => {
                  navigator.clipboard.writeText(url);
                  return this.store.pipe(select(dictionarySelector), take(1));
                }),
                map(dictionary => {
                  this.store.dispatch(
                    new SdSnackBarDisplaySnackBar({
                      message: dictionary?.globalparams_copiedtoclipboard,
                    })
                  );
                }),
                catchError(error =>
                  of(
                    new SdSnackBarDisplaySnackBar({
                      message: error?.toString(),
                    })
                  )
                )
              );
          }

          if (
            reminderEntity === TypesOfReminders.sideDrawer ||
            reminderEntity === TypesOfReminders.planRequest
          ) {
            const url = this.generateCopyRoute(
              reminder,
              basePath,
              reminderEntity
            );
            navigator.clipboard.writeText(url);

            return this.store.pipe(
              select(dictionarySelector),
              take(1),
              map(dictionary => {
                this.store.dispatch(
                  new SdSnackBarDisplaySnackBar({
                    message: dictionary?.globalparams_copiedtoclipboard,
                  })
                );
              })
            );
          }
        })
      ),
    { dispatch: false }
  );

  filingCabinetAllRemindersRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FilingCabinetAllRemindersRequested>(
        RemindersListActionsTypes.FilingCabinetAllRemindersRequested
      ),
      tap(() =>
        this.store.dispatch(new ReminderSpinnerChange({ state: true }))
      ),
      switchMap(() =>
        forkJoin([
          this.store.select(activeSideDrawerSelector).pipe(take(1)),
          this.store.select(filingCabinetSideDrawersSelector).pipe(take(1)),
        ])
      ),
      map(([activeSd, fcSds]) => ({
        sideDrawersIds:
          fcSds?.size > 0
            ? Array.from(fcSds.values()).map(fcSd => fcSd.sidedrawer)
            : [activeSd.id],
      })),
      exhaustMap(({ sideDrawersIds }) =>
        concat(
          ...sideDrawersIds.map(sdId =>
            this.reminderService.getReminders(`sidedrawer/${sdId}`).pipe(
              catchError((error: HttpErrorResponse) => {
                if (error.status !== 403 && error.status !== 401) {
                  this.store.dispatch(
                    new ErrorLoaded({
                      httpError: { ...error },
                      display404: false,
                    })
                  );
                }
                return of(null);
              }),
              tap(data => {
                if (data) {
                  this.store.dispatch(
                    new RemindersLoaded({
                      reminders: data.map(
                        (d: ReminderRequested) =>
                          new Reminders(
                            d.name,
                            d.resource,
                            d.message,
                            d.schedule,
                            d.to,
                            d.id,
                            d.status,
                            sdId
                          )
                      ),
                    })
                  );
                }
              })
            )
          )
        ).pipe(
          map(() => new ReminderSpinnerChange({ state: false })),
          takeUntil(
            this.actionsListener$.pipe(
              ofType(FilingCabinetActions.filingCabinetClearAllData)
            )
          )
        )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly actionsListener$: ActionsSubject,
    private readonly reminderService: RemindersService,
    private readonly store: Store<AppState>,
    private readonly recordsService: RecordsService
  ) {}

  parseReminderResource(resource: string): ReminderEntity {
    const _resource = resource;
    const resourceParts = _resource.split('/');
    const reminderEntity: ReminderEntity = {};
    for (let i = 0; i < resourceParts.length; i += 2) {
      const property = resourceParts[i];
      const value = resourceParts[i + 1];
      reminderEntity[property] = value;
    }
    return reminderEntity;
  }

  getReminderEntity(object: ReminderEntity): TypesOfReminders {
    if ('record' in object) {
      return TypesOfReminders.record;
    }
    if ('sidedrawer' in object && !('plan-request' in object)) {
      return TypesOfReminders.sideDrawer;
    }
    if ('plan-request' in object) {
      return TypesOfReminders.planRequest;
    }
  }

  generateCopyRoute(
    reminder: Reminders,
    basePath: string,
    reminderEntity: TypesOfReminders,
    record?: Record
  ): string {
    if (record) {
      return (
        window.location.origin +
        basePath +
        `${SidedrawerRoutesEnum.root}/${RecordsRoutes.root}/${record?.recordType?.name}/${RecordsRoutes.form}/${record.id}/${RecordsRoutes.remindersTab}/${reminder.id}`
      );
    }
    if (!record) {
      return (
        window.location.origin +
        basePath +
        `${RemindersRoutes.root}/${RemindersRoutes.reminder}/${reminder.id}`
      );
    }
  }
}

export interface ReminderEntity {
  record?: string;
  sidedrawer?: string;
  'plan-request'?: string;
}
