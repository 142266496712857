import {
  createActionGroup,
  createFeature,
  createReducer,
  createSelector,
  on,
  props,
} from '@ngrx/store';
import { ReportType } from '../models/report-type.model';
import { CreateReportDto } from '../models/create-report.dto';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';

export const ReportsActions = createActionGroup({
  source: 'Reports',
  events: {
    ReportTypesRequested: props<{ sideDrawerId: string }>(),
    ReportTypesLoaded: props<{ reportTypes: ReportType[] }>(),
    ReportTypeStatusRequested: props<{
      sideDrawerId: string;
      reportId: string;
      callback?: (status: string) => void;
    }>(),
    ReportTypeStatusLoaded: props<{ status: string }>(),
    GenerateReportRequested: props<{
      sideDrawerId: string;
      dto: CreateReportDto;
      callback?: (reportId: string) => void;
    }>(),
    GenerateReportSucceed: props<{ reportId: string }>(),
    GenerateReportFailed: props<{ httpError: HttpErrorResponse }>(),
  },
});

export interface ReportsState extends EntityState<ReportType> {
  gettingReportTypes: boolean;
  gettingReportTypeStatus: boolean;
  generatingReport: boolean;
}

export const reportsAdapter = createEntityAdapter<ReportType>({
  selectId: reportType => reportType.reportTypeId,
});

const initialState: ReportsState = reportsAdapter.getInitialState({
  gettingReportTypes: false,
  gettingReportTypeStatus: false,
  generatingReport: false,
});

export const reportsFeature = createFeature({
  name: 'reports',
  reducer: createReducer(
    initialState,
    on(ReportsActions.reportTypesRequested, state => ({
      ...state,
      gettingReportTypes: true,
    })),
    on(ReportsActions.reportTypesLoaded, (state, { reportTypes }) => ({
      ...reportsAdapter.upsertMany(reportTypes, state),
      gettingReportTypes: true,
    })),
    on(ReportsActions.generateReportRequested, state => ({
      ...state,
      generatingReport: true,
    })),
    on(
      ReportsActions.generateReportSucceed,
      ReportsActions.generateReportFailed,
      state => ({
        ...state,
        generatingReport: false,
      })
    ),
    on(ReportsActions.reportTypeStatusRequested, state => ({
      ...state,
      gettingReportTypeStatus: true,
    })),
    on(ReportsActions.reportTypeStatusLoaded, state => ({
      ...state,
      gettingReportTypeStatus: false,
    }))
  ),
});

export const gettingReportTypesSelector =
  reportsFeature.selectGettingReportTypes;

export const gettingReportTypeStatusSelector =
  reportsFeature.selectGettingReportTypeStatus;

export const generatingReportSelector = reportsFeature.selectGeneratingReport;

export const reportsTypesListSelector = createSelector(
  reportsFeature.selectReportsState,
  reportsAdapter.getSelectors().selectAll
);
export const reportsTypesEntitiesSelector = createSelector(
  reportsFeature.selectReportsState,
  reportsAdapter.getSelectors().selectEntities
);
export const reportsTypeByIdSelector = (payload: { reportId: string }) =>
  createSelector(
    reportsTypesEntitiesSelector,
    entities => entities[payload.reportId]
  );

export const reportsTypesBySideDrawerIdAndLocaleSelector = (payload: {
  sideDrawerId: string;
  locale: string;
}) =>
  createSelector(reportsTypesListSelector, reports =>
    reports.filter(
      report =>
        report.sideDrawerId === payload.sideDrawerId &&
        report.locale === payload.locale
    )
  );

export const reportTypeForPlanRequestBySideDrawerIdAndLocaleSelector =
  (payload: { sideDrawerId: string; locale: string }) =>
    createSelector(
      reportsTypesBySideDrawerIdAndLocaleSelector(payload),
      reports =>
        reports.find(
          report => report.name === environment.defaultPlanRequestReportName
        )
    );
