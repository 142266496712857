import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appSdAllowEnterTab]',
  standalone: true,
})
export class SdAllowEnterTabDirective {
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.key !== 'Enter' && event.key !== 'Tab') {
      event.preventDefault();
    }
  }
}
