import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  SdSnackBarActionsTypes,
  SdSnackBarCloseSnackBar,
  SdSnackBarDisplaySnackBar,
} from './sd-snack-bar.actions';
import { SdSnackBarComponent } from '../components/sd-snack-bar/sd-snack-bar.component';
import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { tap } from 'rxjs';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';

@Injectable()
export class SdSnackBarEffects {
  private snackBarRef: MatSnackBarRef<SdSnackBarComponent>;

  SdSnackBarDisplaySnackBar$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SdSnackBarDisplaySnackBar>(
          SdSnackBarActionsTypes.SdSnackBarDisplaySnackBar
        ),
        tap(({ payload }) => {
          if (this.snackBarRef) {
            this.snackBarRef.dismiss();
          }
          this.snackBarRef = this.snackBar.openFromComponent(
            SdSnackBarComponent,
            {
              data: payload,
              duration: payload.duration ?? 2000,
              panelClass: 'sd-nack-bar',
            }
          );
        })
      ),
    { dispatch: false }
  );

  SdSnackBarCloseSnackBar$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SdSnackBarCloseSnackBar>(
          SdSnackBarActionsTypes.SdSnackBarCloseSnackBar
        ),
        tap(() => {
          if (this.snackBarRef) {
            this.snackBarRef.dismiss();
          }
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly store: Store<AppState>,
    private readonly snackBar: MatSnackBar,
    private readonly actions$: Actions
  ) {}
}
