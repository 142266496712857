<div [attr.aria-label]="value" class="sd-selected-item-container" tabindex="0">
  <img
    *ngIf="!!avatar && avatar.length > 0"
    [alt]="sdAccessibility?.alt ?? value"
    [src]="avatar"
    class="sd-selected-item-image" />
  <div class="sd-selected-item-text">{{ value }}</div>
  <!-- //TODO accessibility -->
  <!-- //TODO Dictionary for item selected -->
  <button
    (click)="contactRemoved(value); removeButtonClicked.emit(true)"
    *ngIf="removable"
    [attr.aria-label]="
      sdAccessibility?.ariaLabel
        ? sdAccessibility?.ariaLabel + ', item selected ' + value
        : ''
    "
    [attr.role]="
      sdAccessibility?.role
        ? sdAccessibility?.role
        : sdAccessibilityTags?.roleButton
    "
    class="sd-selected-item-text-button"
    mat-icon-button>
    <app-sd-svg-a11y
      [color]="iconColor ? iconColor : sdColorPalette.secondaryAccentColor"
      [height]="!!iconHeight ? iconHeight : 0.938"
      [setSdAccessibility]="{
        tabIndex: sdAccessibilityForSvg?.tabIndex
      }"
      [src]="icon"
      [width]="!!iconWidth ? iconWidth : 0.938"></app-sd-svg-a11y>
  </button>
</div>
