<ng-template [ngIf]="template === sdInputA11yTemplates.inputDefault">
  <mat-form-field [hideRequiredMarker]="true">
    <input
      #inputElement
      [formControl]="controller"
      [placeholder]="placeholder"
      [type]="type"
      matInput />

    <div class="suffix-container" matSuffix>
      <div *ngIf="!!suffixText && suffixText.length > 0">
        <span>{{ suffixText }}</span>
      </div>
      <app-sd-icon-button-a11y
        (btnClicked)="onTogglePassword()"
        *ngIf="!readonly"
        [iconColor]="'var(--secondaryAccentColor)'"
        [iconHeight]="1.25"
        [iconWidth]="1.25"
        [icon]="cdn + ('globalparams_showpasswordicon' | dictionary | async)"
        [setSdAccessibility]="{
          tabIndex: 0,
          ariaLabel: 'globalparams_showpassword' | dictionary | async,
          role: 'button'
        }"
        class="mr-0 showpasswordicon"></app-sd-icon-button-a11y>

      <app-sd-icon-button-a11y
        (btnClicked)="onSuffix()"
        *ngIf="!readonly"
        [iconColor]="'var(--secondaryAccentColor)'"
        [iconHeight]="1.25"
        [iconWidth]="1.25"
        [icon]="cdn + ('globalparams_copyicon' | dictionary | async)"
        [setSdAccessibility]="{
          tabIndex: 0,
          ariaLabel: 'globalparams_copypassword' | dictionary | async
        }"></app-sd-icon-button-a11y>

      <app-sd-icon-button-a11y
        *ngIf="!!tooltip && tooltip.length > 0"
        [tooltip]="tooltip"></app-sd-icon-button-a11y>
    </div>
    <mat-error *ngIf="controller.invalid">
      <span>{{ error }}</span>
    </mat-error>
  </mat-form-field>
</ng-template>

<ng-template [ngIf]="template === sdInputA11yTemplates.inputForView">
  <div class="inputForView-container">
    <input
      #inputElement
      [attr.aria-label]="sdAccessibility?.ariaLabel"
      [formControl]="controller"
      [id]="sdAccessibility?.id"
      [readonly]="true"
      [type]="type"
      class="inputForView" />
    <div class="suffix-container" matSuffix>
      <div *ngIf="!!suffixText && suffixText.length > 0">
        <span>{{ suffixText }}</span>
      </div>
      <app-sd-icon-button-a11y
        (btnClicked)="onTogglePassword()"
        *ngIf="!readonly"
        [iconColor]="'var(--secondaryAccentColor)'"
        [iconHeight]="1"
        [iconWidth]="1"
        [icon]="cdn + ('globalparams_showpasswordicon' | dictionary | async)"
        [setSdAccessibility]="{
          tabIndex: 0,
          ariaLabel: 'globalparams_showpassword' | dictionary | async,
          role: 'button'
        }"
        class="mr-0 showpasswordicon"></app-sd-icon-button-a11y>

      <app-sd-icon-button-a11y
        (btnClicked)="onSuffix()"
        *ngIf="!readonly"
        [iconColor]="'var(--secondaryAccentColor)'"
        [iconHeight]="1"
        [iconWidth]="1"
        [icon]="cdn + ('globalparams_copyicon' | dictionary | async)"
        [setSdAccessibility]="{
          tabIndex: 0,
          ariaLabel: 'globalparams_copypassword' | dictionary | async
        }"></app-sd-icon-button-a11y>

      <app-sd-icon-button-a11y
        *ngIf="!!tooltip && tooltip.length > 0"
        [tooltip]="tooltip"></app-sd-icon-button-a11y>
    </div>
  </div>
</ng-template>
