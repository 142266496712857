import { Account as AccountModel } from '../models/account.model';
import { Settings } from '../models/settings.model';
import { AccountActions, AccountActionsTypes } from './account.actions';
import { Team } from '../models/team.model';

export const featureName = 'account';

export interface AccountState {
  account: AccountModel;
  settings: Settings;
  teams: Team[];
  creatingAccount: boolean;
  updatingSettings: boolean;
}

export const initialAccountState: AccountState = {
  account: null,
  settings: null,
  teams: [],
  creatingAccount: false,
  updatingSettings: false,
};

export function accountReducer(
  state: AccountState = initialAccountState,
  action: AccountActions
): AccountState {
  switch (action.type) {
    case AccountActionsTypes.AccountLoaded:
    case AccountActionsTypes.AccountUpdated:
      return {
        ...state,
        account: action.payload.data,
      };
    case AccountActionsTypes.SettingsLoaded:
      return {
        ...state,
        settings: action.payload.data,
      };
    case AccountActionsTypes.TeamsLoaded:
      return {
        ...state,
        teams: action.payload.data,
      };
    case AccountActionsTypes.CreateAccountSpinnerChanged:
      return {
        ...state,
        creatingAccount: action.payload.state,
      };
    default:
      return state;
  }
}
