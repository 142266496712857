import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZipDialogComponent } from './components/zip-dialog/zip-dialog.component';
import { SdDialogTemplateA11yModule } from 'src/app/shared/templates/dialog-template-a11y/sd-dialog-template-a11y.module';
import { DictionaryPipeModule } from 'src/app/dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { SdFlatButtonA11yModule } from 'src/app/shared/sd-flat-button-a11y/sd-flat-button-a11y.module';

@NgModule({
  declarations: [ZipDialogComponent],
  imports: [
    CommonModule,
    SdDialogTemplateA11yModule,
    DictionaryPipeModule,
    SdFlatButtonA11yModule,
  ],
})
export class ZipDialogModule {}
