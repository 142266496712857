import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { SideDrawerNetwork } from '../../sidedrawer-networks/models/side-drawer-network.model';
import { environment } from '../../../environments/environment';
import { SidedrawerRoles } from '../../core/roles/sidedrawer.roles';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { currentBrandingSelector } from '../../core/store/core.selectors';
import { map } from 'rxjs/operators';
import { AsyncPipe, NgIf } from '@angular/common';
import { SdSvgA11yModule } from '../sd-svg-a11y/sd-svg-a11y.module';
import { RoleToIconPipe } from '../sd-pipes/role-to-icon.pipe';

@Component({
  selector: 'app-sd-side-drawer-item-a11y',
  template: `
    <div class="sidedrawer-container-expanded">
      <img
        *ngIf="!!otherSideDrawer.sdPhoto && otherSideDrawer.sdPhoto.length > 0"
        [alt]="otherSideDrawer?.name"
        [src]="otherSideDrawer?.sdPhoto" />
      <div *ngIf="!otherSideDrawer.sdPhoto" class="circle-avatar">
        {{
          !!otherSideDrawer
            ? !!otherSideDrawer?.name && otherSideDrawer?.name.length > 0
              ? otherSideDrawer?.name[0]?.toUpperCase()
              : ''
            : ''
        }}
      </div>

      <div class="sidedrawer-name">{{ otherSideDrawer?.name }}</div>

      <app-sd-svg-a11y
        *ngIf="
          otherSideDrawer?.sdRole === owner ||
          otherSideDrawer?.sdRole === editor ||
          otherSideDrawer?.sdRole === viewer ||
          otherSideDrawer?.sdRole === info
        "
        [color]="primaryColor$ | async"
        [height]="0.938"
        [src]="cdn + (otherSideDrawer.sdRole | roleToIcon | async)"
        [width]="0.938"
        class="sidedrawer-permission-icon"
        [setSdAccessibility]="{
          tabIndex: -1
        }" />
    </div>
  `,
  styleUrls: ['./sd-side-drawer-item-a11y.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, SdSvgA11yModule, AsyncPipe, RoleToIconPipe],
})
export class SdSideDrawerItemA11yComponent implements OnInit {
  @Input() expanded: boolean;
  @Input() verticalPadding = 10;
  @Input() horizontalPadding = 10;
  @Input() otherSideDrawer: SideDrawerNetwork;
  cdn = environment.cdn;
  primaryColor$: Observable<string>;
  owner = SidedrawerRoles.owner;
  editor = SidedrawerRoles.editor;
  viewer = SidedrawerRoles.viewer;
  info = SidedrawerRoles.info;

  constructor(private readonly store: Store<AppState>) {}

  ngOnInit(): void {
    this.primaryColor$ = this.store.pipe(
      select(currentBrandingSelector),
      map(branding => branding?.styleSheet?.primaryColor)
    );
  }
}
