import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { UntypedFormControl } from '@angular/forms';
import { SdAccessibilitySetting } from 'src/app/core/models/sd-accessibility-model';
import { AccessibilityHelper } from 'src/app/core/helpers/accessibility.helper';
import { SdSlideToggleTemplateA11y } from '../../model/sd-slide-toggle-template-a11y.enum';
import { SdColorPalette } from 'src/app/core/models/enums/sd-color-palette-enum';
import { ThemePalette } from '@angular/material/core';
import { LiveAnnouncer } from '@angular/cdk/a11y';

@Component({
  selector: 'app-sd-slide-toggle-a11y',
  template: `
    <ng-template
      #slideToggleWithController
      [ngIf]="
        template === sdSlideToggleTemplateA11y.slideToggletWithController
      ">
      <mat-slide-toggle
        tabindex="0"
        #tooltip="matTooltip"
        [matTooltip]="sdAccessibility?.ariaLabel"
        (focus)="tooltip?.show()"
        (focusout)="tooltip.hide()"
        class="sd-mat-slide-toggle"
        [name]="name"
        [color]="color"
        [disabled]="disabled"
        (change)="toggleChanged($event)"
        [formControl]="controller"
        [aria-label]="sdAccessibility?.ariaLabel"
        [checked]="checked">
        <div class="sd-mat-slide-toggle-content">
          <ng-content></ng-content>
        </div>
      </mat-slide-toggle>
    </ng-template>

    <ng-template
      #slideToggleWithoutController
      [ngIf]="
        template === sdSlideToggleTemplateA11y.slideToggletWithoutController
      ">
      <mat-slide-toggle
        tabindex="0"
        #tooltip="matTooltip"
        [matTooltip]="sdAccessibility?.ariaLabel"
        (focus)="tooltip?.show()"
        (focusout)="tooltip.hide()"
        class="sd-mat-slide-toggle"
        [color]="color"
        [name]="name"
        [disabled]="disabled"
        (change)="toggleChanged($event)"
        [aria-label]="sdAccessibility?.ariaLabel"
        [checked]="checked">
        <div class="sd-mat-slide-toggle-content">
          <ng-content></ng-content>
        </div>
      </mat-slide-toggle>
    </ng-template>
  `,
  styleUrls: ['./sd-slide-toggle-a11y.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SdSlideToggleA11yComponent {
  @Input() disabled = false;
  @Input() name: string;
  @Input() controller: UntypedFormControl;
  sdAccessibility: SdAccessibilitySetting;
  @Input() checked = false;
  @Output() toggleChange = new EventEmitter<MatSlideToggleChange>();
  sdSlideToggleTemplateA11y = SdSlideToggleTemplateA11y;
  @Input() template = SdSlideToggleTemplateA11y.slideToggletWithController;
  sdColorPalette = SdColorPalette;
  color: ThemePalette = 'warn';

  @Input() set setSdAccessibility(value: SdAccessibilitySetting) {
    this.sdAccessibility = AccessibilityHelper.setDefaultAccessibility(value);
  }

  constructor(private readonly announcer: LiveAnnouncer) {}

  toggleChanged(event): void {
    this.announcer.announce(`${this.sdAccessibility?.ariaLabel}`);
    console.log(this.sdAccessibility.ariaLabel)
    this.toggleChange.emit(event);
  }
}
