import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SdSlideToggleA11yComponent } from './components/sd-slide-toggle-a11y/sd-slide-toggle-a11y.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [SdSlideToggleA11yComponent],
  imports: [CommonModule, MatSlideToggleModule, ReactiveFormsModule, MatTooltipModule],
  exports: [SdSlideToggleA11yComponent],
})
export class SdSlideToggleA11yModule {}
