<div
  [ngStyle]="{ width: (contentWidth$ | async) + 'px' }"
  class="records-type-switcher-container">
  <div
    [ngClass]="
      (recordTypes$ | async)?.length * 57 - 5 > (contentWidth$ | async)
        ? 'records-type-switcher-button'
        : 'hidden'
    ">
    <app-sd-icon-button-a11y
      (btnClicked)="onLeft()"
      [setSdAccessibility]="{
        role: 'button',
        ariaLabel: 'globalparams_chevronleft' | dictionary | async
      }"
      [iconColor]="colorPalette.primaryColor"
      [iconHeight]="1.5"
      [iconWidth]="1.5"
      [icon]="cdn + ('globalparams_chevronlefticon' | dictionary | async)">
    </app-sd-icon-button-a11y>
  </div>
  <div
    [attr.aria-label]="'recordstypeswitcher_menutitle' | dictionary | async"
    [ngStyle]="{
      'justify-content':
        (recordTypes$ | async)?.length * 57 - 5 > (contentWidth$ | async)
          ? 'flex-start'
          : 'center'
    }"
    class="records-type-switcher-tiles">
    <app-records-type-switcher-tile
      *ngFor="let tile of recordTypes$ | async"
      (click)="onSelectRecordType(tile)"
      (keydown.enter)="onSelectRecordType(tile)"
      [active]="isRecordTypeActive(tile.name) | async"
      [recordType]="tile"></app-records-type-switcher-tile>
  </div>
  <div
    [ngClass]="
      (recordTypes$ | async)?.length * 57 - 5 > (contentWidth$ | async)
        ? 'records-type-switcher-button'
        : 'hidden'
    ">
    <app-sd-icon-button-a11y
      (btnClicked)="onRight()"
      [iconColor]="colorPalette.primaryColor"
      [iconHeight]="1.5"
      [iconWidth]="1.5"
      [icon]="cdn + ('globalparams_chevronrighticon' | dictionary | async)"
      [setSdAccessibility]="{
        role: 'button',
        ariaLabel: 'globalparams_chevronright' | dictionary | async
      }">
    </app-sd-icon-button-a11y>
  </div>
</div>
