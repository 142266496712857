import { UntypedFormControl } from '@angular/forms';

export class SdValidators {
  static emailValidator(control: UntypedFormControl): { [s: string]: boolean } {
    if (control.value === '') {
      return null;
    } else if (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/.test(
        control.value
      )
    ) {
      return null;
    } else {
      return { emailFormat: true };
    }
  }

  static emptyCharacters(control: UntypedFormControl): {
    [s: string]: boolean;
  } {
    if (
      !!control.value &&
      control.value !== '' &&
      control.value.trim().length === 0
    ) {
      return { emptyCharacters: true };
    } else {
      return null;
    }
  }

  static checkFileSize(file: File, maxMB: number): boolean {
    return file.size / 1048576 > maxMB;
  }
}
