import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { NewNetworkRow } from '../../models/new-network-row.model';
import { MatDialog } from '@angular/material/dialog';
import { NetworkPermissionsRowSettingsDialogA11yComponent } from '../network-permissions-row-settings-dialog-a11y/components/network-permissions-row-settings-dialog-a11y/network-permissions-row-settings-dialog-a11y.component';
import {
  RecordsNetworksModel,
  SideDrawerNetworksModel,
} from '../../views/networks-form-view/networks-form-view.store';
import { FormGroup } from '@angular/forms';

export interface NetworksFormRowState {
  network: NewNetworkRow;
  form: FormGroup;
}

@Injectable()
export class NetworksFormRowStore extends ComponentStore<NetworksFormRowState> {
  constructor(private readonly dialog: MatDialog) {
    super({ network: null, form: null });
  }

  openDialogForAdvanceSettings(
    permission: RecordsNetworksModel | SideDrawerNetworksModel | NewNetworkRow,
    recordId?: string,
    sideDrawerId?: string
  ) {
    const dialogRef = this.dialog.open(
      NetworkPermissionsRowSettingsDialogA11yComponent,
      {
        data: {
          permission: permission,
          recordId: recordId ?? null,
          sideDrawerId: sideDrawerId ?? null,
        },
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.form.controls['expiryDate']?.setValue(result.expiryDate);

        // TODO check if this is needed

        if (result?.recordId) {
          /* this.onRecordsPermissionsChanged(
            result.recordId,
            result.recordRole,
            result.expiryDate
          ); */
        }

        if (result?.sideDrawerId) {
          /* this.onSideDrawerPermissionsChanged(
            result.sideDrawerId,
            result.sideDrawerRole,
            result.expiryDate
          ); */
        }
      }
    });
  }

  get form() {
    return this.get().form;
  }
}
