import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/reducers';
import { SdDialogTemplateA11yModule } from 'src/app/shared/templates/dialog-template-a11y/sd-dialog-template-a11y.module';
import { DialogTemplateTypes } from 'src/app/shared/templates/enums/templates.enum';
import { DictionaryPipeModule } from 'src/app/dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { environment } from 'src/environments/environment';
import { SdFlatButtonA11yModule } from 'src/app/shared/sd-flat-button-a11y/sd-flat-button-a11y.module';
import { SdInputA11yTemplates } from 'src/app/shared/sd-input-a11y/models/sd-input-a11y-templates.enum';
import { FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { SdValidators } from 'src/app/shared/sd-forms/models/sd.validators';
import {
  SidedrawerActionsTypes,
  SideDrawerUpdateError,
  SideDrawerUpdateRequested,
  SideDrawerUpdateSuccess,
} from 'src/app/sidedrawer/store/sidedrawer.actions';
import { Actions, ofType } from '@ngrx/effects';
import { Observable, take, tap } from 'rxjs';
import { SidedrawerRenameDialogA11yData } from './model/sidedrawer-rename-dialog-a11y.model';
import { updateSpinnerSelector } from 'src/app/sidedrawer/store/sidedrawer.selector';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SdInputA11yComponent } from 'src/app/shared/sd-input-a11y/components/sd-input-a11y/sd-input-a11y.component';
import { SdFormHelper } from 'src/app/core/helpers/sd-form.helper';
import { SdValidationFormErrors } from 'src/app/account/models/sd-validation-form-errors.model';
import { AccessibilityHelper } from 'src/app/core/helpers/accessibility.helper';
import { LiveAnnouncer } from '@angular/cdk/a11y';

@Component({
  selector: 'app-sidedrawer-rename-dialog-a11y',
  template: `
    <!-- // TODO SDD-5052 Dictionary -->
    <app-sd-dialog-template-a11y
      (closeButtonClicked)="onClose()"
      [type]="dialogTemplateTypes.Form">
      <ng-container dialog-header>
        <h2>
          {{ 'sidedrawerrenamedialog_title' | dictionary | async }}
        </h2>
      </ng-container>
      <ng-container dialog-body>
        <div class="sidedrawer-rename-dialog-body">
          <app-sd-input-a11y
            (suffixClicked)="sidedrawerNameController.reset()"
            [suffixSvg]="cdn + ('globalparams_reseticon' | dictionary | async)"
            [controller]="sidedrawerNameController"
            [error]="
              isSubmitted
                ? ('globalparams_requirederror' | dictionary | async) ?? ''
                : ''
            "
            [placeholder]="
              'sidedrawerrenamedialog_sidedrawernameplaceholder'
                | dictionary
                | async
            "
            [sdAccessibilityForSuffix]="{
              ariaLabel:
                'sidedrawerrenamedialog_resetname' | dictionary | async,
              role: 'button'
            }"
            [template]="sdInputA11yTemplates.inputWithSuffix"
            [tooltip]="
              'sidedrawerrenamedialog_sidedrawernametooltip'
                | dictionary
                | async
            "
            [setSdAccessibility]="{
              ariaLabel:
                'sidedrawerrenamedialog_sidedrawernameplaceholder'
                | dictionary
                | async,
              id: 'sidedrawerNameController'
            }"
            [type]="'text'"
            class="personalized-name-input" />
        </div>
      </ng-container>
      <ng-container dialog-footer>
        <app-sd-flat-button-a11y (buttonClicked)="onClose()" [primary]="false">
          {{
            'globalparams_cancel' | dictionary | async
          }}</app-sd-flat-button-a11y
        >
        <app-sd-flat-button-a11y
          (buttonClicked)="onConfirm()"
          [loading]="spinner$ | async"
          [primary]="form.valid && form.dirty"
          [inactiveButtonClass]="true">
          {{
            'globalparams_confirm' | dictionary | async
          }}</app-sd-flat-button-a11y
        >
      </ng-container>
    </app-sd-dialog-template-a11y>
  `,
  styleUrls: ['./sidedrawer-rename-dialog-a11y.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    SdDialogTemplateA11yModule,
    SdFlatButtonA11yModule,
    DictionaryPipeModule,
    SdInputA11yComponent,
  ],
})
export class SidedrawerRenameDialogA11yComponent
  implements OnInit, AfterViewChecked
{
  dialogTemplateTypes = DialogTemplateTypes;
  sdInputA11yTemplates = SdInputA11yTemplates;
  cdn: string = environment.cdn;
  spinner$: Observable<boolean> = this.store.select(updateSpinnerSelector);
  errors: SdValidationFormErrors;
  sidedrawerNameController = new UntypedFormControl(null, [
    Validators.required,
    SdValidators.emptyCharacters.bind(this),
    Validators.maxLength(60),
  ]);
  form: FormGroup = new FormGroup({
    sidedrawerNameController: this.sidedrawerNameController,
  });
  isSubmitted = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: SidedrawerRenameDialogA11yData,
    private readonly store: Store<AppState>,
    private readonly matdialogRef: MatDialogRef<SidedrawerRenameDialogA11yComponent>,
    private readonly actions$: Actions,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly liveAnnouncer: LiveAnnouncer
  ) {}

  ngOnInit(): void {
    this.sidedrawerNameController.setValue(this.data.sidedrawer.name);
  }

  ngAfterViewChecked(): void {
    this.changeDetector.markForCheck();
  }

  onClose(): void {
    this.matdialogRef.close();
  }

  onConfirm(): void {
    this.isSubmitted = true;
    this.form.markAllAsTouched();
    this.setErrorForm();
    if (this.form.invalid) {
      AccessibilityHelper.announceMessage(
        this.store,
        this.errors,
        this.liveAnnouncer,
        '' // TODO Dictionary add global params for form errors and indicate user focus
      );
      return;
    }
    this.form.valid && this.form.dirty ? this.updateSideDrawer() : null;
  }

  updateSideDrawer() {
    this.store.dispatch(
      new SideDrawerUpdateRequested({
        sideDrawer: this.data.sidedrawer,
        sdNewName: this.sidedrawerNameController.value,
      })
    );
    this.actions$
      .pipe(
        ofType(
          SidedrawerActionsTypes.SideDrawerUpdateSuccess,
          SidedrawerActionsTypes.SideDrawerUpdateError
        ),
        take(1),
        tap((action: SideDrawerUpdateSuccess | SideDrawerUpdateError) => {
          if (action.type === SidedrawerActionsTypes.SideDrawerUpdateSuccess) {
            this.form.markAsPristine();
            this.onClose();
          }
        })
      )
      .subscribe();
  }

  private setErrorForm(): void {
    this.errors = {
      sidedrawerNameController: SdFormHelper.getErrorFormControl(
        this.form,
        'sidedrawerNameController',
        'required',
        'globalparams_requirederror'
      ),
    };
  }
}
