import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { PlanRequested } from '../models/plan-requested.model';
import { take, tap } from 'rxjs/operators';
import {
  PlanRequestedItemsLoaded,
  PlanRequestedRequested,
} from '../store/plans-list.actions';
import { PlansViewsSpinnerChange } from '../store/plans-views.actions';
import { PlanRequestedItem } from '../models/plan-requested-item.model';
import { Update } from '@ngrx/entity';
import { activeSideDrawerSelector } from '../../sidedrawer/store/sidedrawer.selector';
import { localeDefaultSelector } from '../../dictionary/store/dictionary.selectors';
import { plansSortPlanListBySelector } from '../store/plans-views.selectors';
import { Locale } from '../../dictionary/models/locale.model';
import { planRequestedByIdSelector } from '../store/plans-list.selectors';
import { authHeadersSelector } from '../../auth/store/auth.selectors';

@Injectable()
export class PlansService {
  private plansApi = environment.plansApi;

  constructor(
    private readonly store: Store<AppState>,
    private readonly http: HttpClient
  ) {}

  public static filterPlansWithNoItems(
    plans: PlanRequested[]
  ): PlanRequested[] {
    if (!!plans && plans.length > 0) {
      return this.sortPlans(
        plans.filter(plan => plan.totalItems > 0 || plan.optionalItems > 0)
      );
    }
    return [];
  }

  private static sortPlans(plans: PlanRequested[]): PlanRequested[] {
    try {
      return plans.sort((a, b) => a.orderId - b.orderId);
    } catch (e) {
      console.warn(e);
      return plans;
    }
  }

  private static sortItems(items: PlanRequestedItem[]): PlanRequestedItem[] {
    try {
      return items.sort((a, b) => a.orderId - b.orderId);
    } catch (e) {
      console.warn(e);
      return items;
    }
  }

  sortItemsForPlan(items: PlanRequestedItem[]): PlanRequestedItem[] {
    return PlansService.sortItems(items);
  }

  getAllPlans(
    sidedrawerId: string,
    locale: string
  ): Observable<PlanRequested[]> {
    this.store.dispatch(new PlansViewsSpinnerChange({ state: true }));
    return this.http.get<PlanRequested[]>(
      this.plansApi +
        `sidedrawer/sidedrawer-id/${sidedrawerId}/plan-requests?locale=${locale}`,
      { headers: this.store.selectSignal(authHeadersSelector)() }
    );
  }

  getPlanItems(
    sidedrawerId: string,
    plan: PlanRequested,
    locale: string
  ): Observable<PlanRequestedItem[]> {
    return this.http
      .get<PlanRequestedItem[]>(
        this.plansApi +
          // `sidedrawer/sidedrawer-id/${sidedrawerId}/plan-requests/plan-request-id/${plan.id}/items`,
          `sidedrawer/sidedrawer-id/${sidedrawerId}/plan-requests/plan-request-id/${plan.id}/items?locale=${locale}`,
        { headers: this.store.selectSignal(authHeadersSelector)() }
      )
      .pipe(
        tap(items => {
          if (!!items && items.length > 0) {
            this.store
              .pipe(
                select(planRequestedByIdSelector({ planId: plan.id })),
                take(1),
                tap(currentPlan => {
                  const sortedItems = [...items];
                  const changes: PlanRequested = {
                    ...currentPlan,
                    items: PlansService.sortItems(sortedItems),
                  };
                  const updatedPlan: Update<PlanRequested> = {
                    id: currentPlan.id,
                    changes,
                  };
                  this.store.dispatch(
                    new PlanRequestedItemsLoaded({ item: updatedPlan })
                  );
                })
              )
              .subscribe();
          }
        })
      );
  }

  getPlanItemsV2(
    sidedrawerId: string,
    planId: string,
    locale: string
  ): Observable<PlanRequestedItem[]> {
    return this.http.get<PlanRequestedItem[]>(
      this.plansApi +
        `sidedrawer/sidedrawer-id/${sidedrawerId}/plan-requests/plan-request-id/${planId}/items?locale=${locale}`,
      { headers: this.store.selectSignal(authHeadersSelector)() }
    );
    /*       .pipe(
        tap(items => {
          if (!!items && items.length > 0) {
            this.store
              .pipe(
                select(planRequestedByIdSelector({ planId: planId })),
                take(1),
                tap(currentPlan => {
                  const sortedItems = [...items];
                  const changes: PlanRequested = {
                    ...currentPlan,
                    items: PlansService.sortItems(sortedItems),
                  };
                  const updatedPlan: Update<PlanRequested> = {
                    id: currentPlan.id,
                    changes,
                  };
                  this.store.dispatch(
                    new PlanRequestedItemsLoaded({ item: updatedPlan })
                  );
                })
              )
              .subscribe();
          }
        })
      ); */
  }

  addPlanItemRecord(
    sidedrawerId: string,
    planId: string,
    itemId: string,
    recordId: string
  ): Observable<any> {
    return this.http.post(
      this.plansApi +
        `sidedrawer/sidedrawer-id/${sidedrawerId}/plan-requests/plan-request-id/${planId}/items/item-id/${itemId}`,
      { recordId },
      { headers: this.store.selectSignal(authHeadersSelector)() }
    );
  }

  addPlanItemValue(
    sidedrawerId: string,
    planId: string,
    itemId: string,
    fieldValue: any
  ): Observable<any> {
    return this.http.post(
      this.plansApi +
        `sidedrawer/sidedrawer-id/${sidedrawerId}/plan-requests/plan-request-id/${planId}/items/item-id/${itemId}`,
      { fieldValue },
      { headers: this.store.selectSignal(authHeadersSelector)() }
    );
  }

  updatePlanItemValue(
    sidedrawerId: string,
    planId: string,
    itemId: string,
    fieldValue: any
  ): Observable<any> {
    return this.http.put(
      this.plansApi +
        `sidedrawer/sidedrawer-id/${sidedrawerId}/plan-requests/plan-request-id/${planId}/items/item-id/${itemId}`,
      { fieldValue },
      { headers: this.store.selectSignal(authHeadersSelector)() }
    );
  }

  removePlanItemRecord(
    sidedrawerId: string,
    planId: string,
    itemId: string,
    recordId: string
  ): Observable<any> {
    return this.http.request(
      'DELETE',
      this.plansApi +
        `sidedrawer/sidedrawer-id/${sidedrawerId}/plan-requests/plan-request-id/${planId}/items/item-id/${itemId}`,
      {
        body: { recordId },
        headers: this.store.selectSignal(authHeadersSelector)(),
      }
    );
  }

  removePlanItemValue(
    sidedrawerId: string,
    planId: string,
    itemId: string,
    fieldValue: any
  ): Observable<any> {
    return this.http.request(
      'DELETE',
      this.plansApi +
        `sidedrawer/sidedrawer-id/${sidedrawerId}/plan-requests/plan-request-id/${planId}/items/item-id/${itemId}`,
      {
        body: { fieldValue },
        headers: this.store.selectSignal(authHeadersSelector)(),
      }
    );
  }

  refreshPlanList(): void {
    forkJoin([
      this.store.pipe(select(activeSideDrawerSelector), take(1)),
      this.store.pipe(select(localeDefaultSelector), take(1)),
      this.store.pipe(select(plansSortPlanListBySelector), take(1)),
    ])
      .pipe(
        tap(([activeSD, locale, sortBy]) => {
          this.store.dispatch(
            new PlanRequestedRequested({
              sidedrawerId: activeSD.id,
              locale: Locale.getLocaleId(locale),
              sortBy: sortBy,
              upsertMany: true,
            })
          );
        })
      )
      .subscribe();
  }

  applyPlanToSidedrawer(
    sideDrawerId: string,
    planId: string
  ): Observable<{ id: string }> {
    return this.http.post<{ id: string }>(
      this.plansApi + `sidedrawer/sidedrawer-id/${sideDrawerId}/plan-requests`,
      { planId },
      { headers: this.store.selectSignal(authHeadersSelector)() }
    );
  }

  removePlanFromSidedrawer(
    sideDrawerId: string,
    planRequestId: string
  ): Observable<{ id: string }> {
    return this.http.delete<{ id: string }>(
      this.plansApi + `sidedrawer/sidedrawer-id/${sideDrawerId}/plan-requests/plan-request-id/${planRequestId}`,
      { headers: this.store.selectSignal(authHeadersSelector)() }
    );
  }
}
