<ng-template [ngIf]="template === zipHeaderButtonTemplate.default">
  <app-sd-icon-button-a11y
    (btnClicked)="onClick()"
    [iconColor]="primaryColor$ | async"
    [iconHeight]="1.25"
    [iconWidth]="1.25"
    [icon]="
      (status$ | async) === zipStatus.confirmation ||
      (status$ | async) === zipStatus.canceled
        ? cdn + ('globalparams_clouddownloadicon' | dictionary | async)
        : (status$ | async) === zipStatus.complete
        ? cdn + ('globalparams_checkcircleicon' | dictionary | async)
        : (status$ | async) === zipStatus.completeWithErrors ||
          (status$ | async) === zipStatus.fails ||
          (status$ | async) === zipStatus.noFiles
        ? cdn + ('globalparams_exclamationcircleicon' | dictionary | async)
        : ''
    "
    [spinnerMode]="
      (status$ | async) === zipStatus.downloading
        ? 'determinate'
        : 'indeterminate'
    "
    [spinnerProgress]="progress$ | async"
    [spinner]="
      (status$ | async) === zipStatus.information ||
      (status$ | async) === zipStatus.downloading ||
      (status$ | async) === zipStatus.zipping
    "
    [squareBackground]="true"
    [tooltip]="'zipheaderbutton_downloadallfiles' | dictionary | async"
    class="sd-icon-button">
  </app-sd-icon-button-a11y>
</ng-template>

<ng-template
  [ngIf]="template === zipHeaderButtonTemplate.withOutView"></ng-template>
