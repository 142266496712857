import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  signal,
} from '@angular/core';
import { Observable, Subscription, switchMap } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../reducers';
import {
  activeMyOtherSideDrawerSelector,
  activeSideDrawerSelector,
  isSdOwnerOrSdEditorSelector,
} from '../../../../sidedrawer/store/sidedrawer.selector';
import {
  getSidedrawerRoute,
  SidedrawerRoutesEnum,
} from '../../../../sidedrawer/routes/sidedrawer-routes.enum';
import { SidedrawerRoles } from '../../../../core/roles/sidedrawer.roles';
import { environment } from '../../../../../environments/environment';
import { NetworkType } from '../../../../networks/models/network-type.model';
import { SdLinkMenuItem } from '../../../sd-link-menu-a11y/models/sd-link-menu-item.model';
import { SdLinkMenuLinkType } from '../../../sd-link-menu-a11y/models/enums/sd-link-menu-link-type-enum';
import { HelpSectionsRequested } from '../../../../dictionary/store/dictionary.actions';
import { ZipHeaderButtonTemplateA11y } from 'src/app/zip/shared/zip-header-button-a11y/model/enum/zip-header-button-a11y-template.enum';
import { SdBackDropClicked } from 'src/app/home/shared/sd-backdrop/store/sd-backdrop.actions';
import { ExpandSearchBar } from 'src/app/search/store/search-bar.actions';
import { ShareResourceDialogComponent } from '../../../../networks/shared/share-resource-dialog/share-resource-dialog.component';
import { NetworkResourceType } from '../../../../networks/models/network-resource-type.model';
import { take, tap } from 'rxjs/operators';
import { MenuItemType, SdImage } from '../../models/menu-item.type.enum';
import { SdColorPalette } from 'src/app/core/models/enums/sd-color-palette-enum';
import { SideDrawerNetwork } from 'src/app/sidedrawer-networks/models/side-drawer-network.model';
import { Router } from '@angular/router';
import { RecordsRoutes } from 'src/app/records/routes/records.routes';
import { CreateRecordForFilesDialogComponent } from 'src/app/records/shared/create-record-for-files-dialog/create-record-for-files-dialog.component';
import { SidedrawerRenameDialogA11yComponent } from 'src/app/sidedrawer/shared/sidedrawer-rename-dialog-a11y/sidedrawer-rename-dialog-a11y.component';
import { SideDrawerCopyLinkRequested } from '../../../../sidedrawer/store/sidedrawer.actions';
import { MatDialog } from '@angular/material/dialog';
import { filingCabinetSelector } from 'src/app/filing-cabinet/store/filing-cabinet.store';
import { toSignal } from '@angular/core/rxjs-interop';
import { RoutesHelper } from 'src/app/core/helpers/routes.helper';
import { SdSidedrawerMenuA11yTemplates } from 'src/app/sidedrawer/models/sd-sidedrawer-menu-a11y-templates.enum';

@Component({
  selector: 'app-sd-header-a11y',
  templateUrl: './sd-header-a11y.component.html',
  styleUrls: ['./sd-header-a11y.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SdHeaderA11yComponent
  implements OnInit, OnDestroy, AfterViewChecked
{
  @Input() title: string;
  @Input() displayLeftSection = true;
  @Input() displayRightSection = true;
  @Input() basePath: string;
  @Input() currentRoute: string;
  @Input() image?: SdImage;
  @Input() hideEditSdButton = false;
  @Input() filingCabinetMode = true;
  sdSidedrawerMenuA11yTemplates = SdSidedrawerMenuA11yTemplates;
  isSdOwnerOrSdEditor$: Observable<boolean> = this.store.select(
    isSdOwnerOrSdEditorSelector
  );
  editor = SidedrawerRoles.editor;
  owner = SidedrawerRoles.owner;
  cdn = environment.cdn;
  sdColorPalette = SdColorPalette;
  activeMyOtherSideDrawer$: Observable<SideDrawerNetwork>;
  consoleUrl = environment.consoleUrl;
  appName: string =
    environment.dictionaryVersion != 'default' ? 'console' : 'my';
  sdLinkMenuLinkType = SdLinkMenuLinkType;
  networkType = NetworkType;
  sidedrawerRoutes = SidedrawerRoutesEnum;
  getSidedrawerRoute = getSidedrawerRoute;
  menuItemType = MenuItemType;
  startDialog = { download: false };
  zipHeaderButtonTemplate = ZipHeaderButtonTemplateA11y;
  hideUploadButton = signal(false);
  hideFilingCabinetName = signal(false);
  subscription = new Subscription();
  filingCabinet = toSignal(this.store.pipe(select(filingCabinetSelector)));
  isMenuOpened = signal(false);
  constructor(
    protected readonly dialog: MatDialog,
    protected readonly store: Store<AppState>,
    protected readonly router: Router
  ) {
    this.activeMyOtherSideDrawer$ = this.store.pipe(
      select(activeMyOtherSideDrawerSelector)
    );
  }

  ngOnInit(): void {
    if (!this.basePath) {
      this.store
        .pipe(
          select(activeSideDrawerSelector),
          take(1),
          tap(activeSD => {
            this.basePath = RoutesHelper.getBasePath(activeSD?.id);
          })
        )
        .subscribe();
    }
  }

  ngAfterViewChecked(): void {
    this.checkRoutesForElementsView();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onEmitterClicked(linkMenu: SdLinkMenuItem) {
    switch (linkMenu.key) {
      case MenuItemType.support:
        this.store.dispatch(
          new HelpSectionsRequested({
            url: this.currentRoute,
            appName: this.appName,
          })
        );
        break;
      case MenuItemType.download:
        this.startDialog = { download: true };
        break;
      case MenuItemType.share:
        this.openManageNetworkDialog();
        break;
      case MenuItemType.editSideDrawerName:
        this.openRenameSideDrawerDialog();
        break;
      case MenuItemType.copy:
        this.store.dispatch(new SideDrawerCopyLinkRequested());
        break;
      default:
        break;
    }
  }

  hideBackDrop() {
    this.store.dispatch(new SdBackDropClicked());
    this.store.dispatch(new ExpandSearchBar({ expanded: false }));
  }

  openManageNetworkDialog(): void {
    this.store
      .pipe(
        select(activeSideDrawerSelector),
        take(1),
        switchMap(sd =>
          this.dialog
            .open(ShareResourceDialogComponent, {
              data: {
                resourceType: NetworkResourceType.sideDrawer,
                sideDrawerId: sd.id,
                resourceName: sd.name,
              },
              autoFocus: false,
              panelClass: 'custom-share-resource-dialog',
            })
            .afterClosed()
        )
      )
      .subscribe();
  }

  processFiles(fileList: FileList): void {
    this.dialog
      .open(CreateRecordForFilesDialogComponent, {
        data: {
          fileList: fileList,
        },
        autoFocus: false,
      })
      .afterClosed()
      .subscribe();
  }

  openRenameSideDrawerDialog(): void {
    this.store
      .pipe(
        select(activeSideDrawerSelector),
        take(1),
        tap(activeSidedrawer => {
          this.dialog
            .open(SidedrawerRenameDialogA11yComponent, {
              data: {
                sidedrawer: activeSidedrawer,
              },
              autoFocus: false,
            })
            .afterClosed()
            .subscribe();
        })
      )
      .subscribe();
  }

  // Deleted condition !filingCabinet() || hideFilingCabinetName() on edit button
  // openRenameFilingCabinetDialog(): void {
  //   this.store
  //     .pipe(
  //       select(filingCabinetSelector),
  //       take(1),
  //       tap(filingCabinet => {
  //         this.dialog
  //           .open(FilingCabinetRenameDialogA11yComponent, {
  //             data: {
  //               filingCabinet: filingCabinet,
  //             },
  //             autoFocus: false,
  //           })
  //           .afterClosed()
  //           .subscribe();
  //       })
  //     )
  //     .subscribe();
  // }

  private checkRoutesForElementsView(): void {
    this.hideUploadButton.set(this.checkIfRecordsRoute(this.router.url));
    this.hideFilingCabinetName.set(this.checkIfRecordsRoute(this.router.url));
    const eventRoute = this.router.events.subscribe((event: unknown) => {
      this.hideUploadButton.set(
        this.checkIfRecordsRoute(event['routerEvent']?.url)
      );
      this.hideFilingCabinetName.set(
        this.checkIfNoFilingCabinetRoute(event['routerEvent']?.url)
      );
    });
    this.subscription.add(eventRoute);
  }

  private checkIfRecordsRoute(url: string): boolean {
    return (
      url?.includes(RecordsRoutes.root) &&
      (url?.includes(RecordsRoutes.view) || url?.includes(RecordsRoutes.form))
    );
  }

  private checkIfNoFilingCabinetRoute(url: string): boolean {
    return (
      (url?.includes(RecordsRoutes.root) &&
        (url?.includes(RecordsRoutes.view) ||
          url?.includes(RecordsRoutes.form) ||
          url?.includes(RecordsRoutes.list))) ||
      url?.includes(SidedrawerRoutesEnum.manage)
    );
  }

  onMenuOpenCloseEvent(event: boolean) {
    this.isMenuOpened.set(event);
  }
}
