import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { SideDrawerNetwork } from 'src/app/sidedrawer-networks/models/side-drawer-network.model';
import {
  SearchSideDrawerListActions,
  SearchSideDrawerListActionsTypes,
} from './search-sidedrawer-list.actions';

export interface SearchSideDrawerListState
  extends EntityState<SideDrawerNetwork> {
  filter: string;
}

export const sideDrawerListAdapter: EntityAdapter<SideDrawerNetwork> =
  createEntityAdapter<SideDrawerNetwork>({
    selectId: sdNetwork => sdNetwork.id,
  });

export const initialSideDrawerListState: SearchSideDrawerListState =
  sideDrawerListAdapter.getInitialState({
    filter: '',
  });

export const { selectAll, selectEntities } =
  sideDrawerListAdapter.getSelectors();

export function sideDrawerListReducer(
  state: SearchSideDrawerListState = initialSideDrawerListState,
  action: SearchSideDrawerListActions
): SearchSideDrawerListState {
  switch (action.type) {
    case SearchSideDrawerListActionsTypes.SearchSideDrawerListLoaded: {
      return sideDrawerListAdapter.upsertMany(action.payload.networks, {
        ...state,
        filter: action.payload.filter,
      });
    }
    case SearchSideDrawerListActionsTypes.SearchSideDrawerListReset: {
      return {
        ...state,
        ...sideDrawerListAdapter.removeAll({ ...state, filter: '' }),
      };
    }
    default:
      return state;
  }
}
