import { Component, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { AppState } from 'src/app/reducers';
import { SdValidators } from 'src/app/shared/sd-forms/models/sd.validators';
import { SdInputA11yTemplates } from 'src/app/shared/sd-input-a11y/models/sd-input-a11y-templates.enum';
import { DialogTemplateTypes } from 'src/app/shared/templates/enums/templates.enum';
import { environment } from 'src/environments/environment';
import { activeSideDrawerSelector } from 'src/app/sidedrawer/store/sidedrawer.selector';
import {
  ClearActiveRecord,
  RecordsSubtypesLoaded,
} from 'src/app/records/store/records-list.actions';
import { Record } from 'src/app/records/models/record.model';
import { FilesService } from 'src/app/files/services/files.service';
import {
  combineLatest,
  map,
  Observable,
  of,
  Subscription,
  take,
  tap,
} from 'rxjs';
import { QueueHelper } from 'src/app/queue/helpers/queue.helper';
import { QueueActions } from 'src/app/queue/store/queue.actions';
import { SdInputA11yComponent } from 'src/app/shared/sd-input-a11y/components/sd-input-a11y/sd-input-a11y.component';
import { RecordFormForFilesStore } from './create-record-for-files.dialog.store';
import { SdValidationFormErrors } from 'src/app/account/models/sd-validation-form-errors.model';
import { AccessibilityHelper } from 'src/app/core/helpers/accessibility.helper';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { localeDefaultSelector } from 'src/app/dictionary/store/dictionary.selectors';
import { SideDrawer } from 'src/app/sidedrawer/models/side-drawer.model';
import { Locale } from 'src/app/dictionary/models/locale.model';
import { SdQueueItem } from '../../../queue/models/sd-queue-item.model';
import { FilesHelper } from 'src/app/files/helpers/files.helper';
import { RecordSubtypeAutocompleteA11yComponent } from '../record-subtype-autocomplete-a11y/components/record-subtype-autocomplete-a11y/record-subtype-autocomplete-a11y.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SdDialogTemplateA11yModule } from '../../../shared/templates/dialog-template-a11y/sd-dialog-template-a11y.module';
import { DictionaryPipeModule } from '../../../dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { RecordSubtypeAutocompleteA11yModule } from '../record-subtype-autocomplete-a11y/record-subtype-autocomplete-a11y.module';
import { RecordTypeAutocompleteA11yComponent } from '../record-type-autocomplete-a11y/components/record-type-autocomplete/record-type-autocomplete-a11y.component';
import { QueueItemComponent } from '../../../queue/shared/queue-item/queue-item.component';
import { SdFlatButtonA11yModule } from '../../../shared/sd-flat-button-a11y/sd-flat-button-a11y.module';
import { SdSelectorA11Module } from '../../../shared/sd-selector-a11y/sd-selector-a11y.module';
import {
  filingCabinetSideDrawerByIdSelector,
  filingCabinetSideDrawersWithDataForSdOwnerAndSdEditorAsOptionsSelector,
} from '../../../filing-cabinet/store/filing-cabinet.store';
import { Option } from '../../../shared/sd-forms/models/option.model';
import { RecordDetails } from '../../models/record-details.model';
import { SdProgressSpinnerA11yModule } from 'src/app/shared/sd-progress-spinner-a11y/sd-progress-spinner-a11y/sd-progress-spinner-a11y.module';
import { RecordTypeAutocompleteA11yTemplates } from '../record-type-autocomplete-a11y/model/record-type-autocomplete-a11y.model';
import { SdFormHelper } from 'src/app/core/helpers/sd-form.helper';

@Component({
  selector: 'app-create-record-for-files-dialog',
  templateUrl: './create-record-for-files-dialog.component.html',
  styleUrls: ['./create-record-for-files-dialog.component.scss'],
  standalone: true,
  providers: [RecordFormForFilesStore],
  imports: [
    SdDialogTemplateA11yModule,
    DictionaryPipeModule,
    AsyncPipe,
    RecordSubtypeAutocompleteA11yModule,
    ReactiveFormsModule,
    NgIf,
    RecordTypeAutocompleteA11yComponent,
    SdInputA11yComponent,
    QueueItemComponent,
    NgForOf,
    SdFlatButtonA11yModule,
    SdProgressSpinnerA11yModule,
    SdSelectorA11Module,
  ],
})
export class CreateRecordForFilesDialogComponent implements OnInit, OnDestroy {
  protected data: {
    fileList: FileList;
  } = inject(MAT_DIALOG_DATA);
  protected readonly recordFormForFilesStore = inject(RecordFormForFilesStore);
  protected readonly store = inject(Store<AppState>);
  protected readonly filesService = inject(FilesService);
  protected readonly liveAnnouncer = inject(LiveAnnouncer);
  protected readonly dialogRef = inject(
    MatDialogRef<CreateRecordForFilesDialogComponent>
  );
  @ViewChild(SdInputA11yComponent) nameInput: SdInputA11yComponent;
  @ViewChild(RecordSubtypeAutocompleteA11yComponent)
  subTypeInput: RecordSubtypeAutocompleteA11yComponent;
  isInvalidList = false;
  DialogTemplateTypes = DialogTemplateTypes;
  form: UntypedFormGroup;
  sideDrawerController = new FormControl<string>(null, []);
  nameController = new FormControl<string>(null, [
    Validators.required,
    SdValidators.emptyCharacters.bind(this),
  ]);
  typeController = new FormControl<string>(null, [
    Validators.required,
    SdValidators.emptyCharacters.bind(this),
  ]);
  RecordTypeAutocompleteA11yTemplates = RecordTypeAutocompleteA11yTemplates;
  SdInputA11yTemplates = SdInputA11yTemplates;
  cdn = environment.cdn;
  recordTypeName: string;
  files: SdQueueItem[] = [];
  submitted = false;
  validationErrors: SdValidationFormErrors;
  typeSubtypesMap: Map<string, RecordsSubtypesLoaded> = new Map<
    string,
    RecordsSubtypesLoaded
  >();
  subscription = new Subscription();
  activeSideDrawer: SideDrawer;
  localeDefault: Locale;
  blockedFormats: string[] = [];
  maxUpload: number;
  fileList: FileList;
  options: Option[];
  vm$: Observable<{
    activeSidedrawer: SideDrawer;
    sideDrawerOptions: Option[];
    locale: Locale;
    queueItems: SdQueueItem[];
    blockedFormats: string[];
  }> = combineLatest([
    this.store.select(activeSideDrawerSelector),
    this.store.select(
      filingCabinetSideDrawersWithDataForSdOwnerAndSdEditorAsOptionsSelector
    ),
    this.store.pipe(select(localeDefaultSelector), take(1)),
    QueueHelper.generateAllQueueItems$(this.data.fileList, this.store).pipe(
      take(1)
    ),
    QueueHelper.getBlockedFormats(this.store),
  ]).pipe(
    map(([activeSd, sdOptions, locale, queueItems, blockedFormats]) => ({
      activeSidedrawer: activeSd,
      sideDrawerOptions: sdOptions,
      locale: locale,
      queueItems: queueItems,
      blockedFormats: blockedFormats,
    })),
    tap(e => {
      this.activeSideDrawer = e.activeSidedrawer;
      this.localeDefault = e.locale;
      this.options = e.sideDrawerOptions;
      if (this.options?.length > 0) {
        this.sideDrawerController.setValidators(Validators.required);
        this.sideDrawerController.setValue(this.activeSideDrawer.id);
      }
      this.setFormGroup();
      this.files = e.queueItems;
      this.isInvalidList =
        this.files?.filter(f => f.error === null)?.length === 0;
      this.store.dispatch(new ClearActiveRecord());
      this.blockedFormats = e.blockedFormats;
      this.maxUpload = parseInt(
        this.activeSideDrawer?.subscriptionFeatures?.[
          'sidedrawer.maxUploadMBs'
        ],
        10
      );
      this.recordTypeName = this.activeSideDrawer.recordsType?.[0]?.name;
      this.typeController.setValue(this.recordTypeName);
    })
  );

  ngOnInit(): void {
    this.fileList = this.data.fileList;
    this.setPersonalizedNameAndMessage();
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ClearActiveRecord());
    this.subscription.unsubscribe();
  }

  onClose(): void {
    this.dialogRef.close();
  }

  loadQueue(record: Record): void {
    const listWithoutBlockedFiles = FilesHelper.filterBlockedFilesFromFileList(
      this.fileList,
      this.blockedFormats,
      this.maxUpload
    );
    QueueHelper.generateQueueItems$(
      listWithoutBlockedFiles,
      { ...record, id: record.id },
      this.store,
      this.filesService
    )
      .pipe(
        take(1),
        tap(queueItems => {
          queueItems.forEach(item => {
            this.store.dispatch(QueueActions.itemAdded({ item }));
          });
          this.store.dispatch(QueueActions.startProcess());
        })
      )
      .subscribe();
  }

  trackBy(index: number, item: SdQueueItem): string {
    return item.id;
  }

  onRemoveItem(id: string, name: string): void {
    this.files = this.files?.filter(file => file.id !== id);
    this.isInvalidList =
      this.files?.filter(f => f.error === null)?.length === 0;
    this.fileList = FilesHelper.filterDeletedFilesFromFileList(
      this.fileList,
      name
    );
    if (this.files?.length === 0) {
      this.dialogRef.close();
    }
  }

  onSideDrawerSelected(sideDrawerId: string | Option): void {
    this.store
      .select(
        filingCabinetSideDrawerByIdSelector({
          sideDrawerId: sideDrawerId as string,
        })
      )
      .pipe(
        take(1),
        map(fcSd => fcSd.data),
        tap(sd => {
          this.activeSideDrawer = sd;
          this.typeController.reset();
          this.onRecordTypeSelected(sd.recordsType?.[0]?.name);
          this.maxUpload = parseInt(
            sd?.subscriptionFeatures?.['sidedrawer.maxUploadMBs'],
            10
          );
          this.store.dispatch(new ClearActiveRecord());
        })
      )
      .subscribe();
  }

  onRecordTypeSelected(recordTypeName: string): void {
    this.recordTypeName = recordTypeName;
    this.typeController.setValue(this.recordTypeName);
  }

  onConfirm(): void {
    this.form?.markAllAsTouched();
    this.setErrorsForms();
    this.submitted = true;
    if (this.form?.invalid || this.isInvalidList) {
      AccessibilityHelper.announceMessage(
        this.store,
        this.validationErrors,
        this.liveAnnouncer,
        '' // TODO Dictionary add global params for form errors and indicate user focus
      );
      return;
    }
    this.setNewRecordDto();
    if (this.form?.valid) {
      this.createRecord();
    }
  }

  setFormGroup(): void {
    this.form = new FormGroup({
      sideDrawerController: this.sideDrawerController,
      nameController: this.nameController,
      typeController: this.typeController,
    });
  }

  setPersonalizedNameAndMessage(): void {
    const fileName = this.data?.fileList[0]?.name;
    const uploadTitle = FilesHelper.hasExtension(fileName)
      ? FilesHelper.removeExtension(fileName)
      : fileName;
    this.nameController?.setValue(uploadTitle);
  }

  private setNewRecordDto(): void {
    const newRecord = new Record();
    newRecord.recordDetails = {} as RecordDetails;
    newRecord.recordTypeName = this.typeController.value;
    newRecord.recordSubtypeName = 'other';
    newRecord.name = this.nameController.value;
    this.recordFormForFilesStore.patchState({
      record: newRecord,
    });
  }

  private createRecord(): void {
    this.recordFormForFilesStore.setSideDrawerInformation(
      this.activeSideDrawer.id
    );
    this.recordFormForFilesStore.createRecord(
      of((record, error) => {
        if (record) {
          this.loadQueue(record);
          this.dialogRef.close();
        }
        if (!record && error) {
          this.typeController.setValue(null);
        }
      })
    );
  }

  private setErrorsForms(): void {
    this.validationErrors = {
      sideDrawerController: SdFormHelper.getErrorFormControl(
        this.form,
        'sideDrawerController',
        'required',
        'globalparams_requirederror'
      ),
      nameController: SdFormHelper.getErrorFormControl(
        this.form,
        'nameController',
        'required',
        'globalparams_requirederror'
      ),
      typeController: SdFormHelper.getErrorFormControl(
        this.form,
        'typeController',
        'required',
        'globalparams_requirederror'
      ),
    };
  }
}
