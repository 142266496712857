<div *ngIf="loading" class="sd-flat-button-spinner">
  <mat-progress-spinner
    diameter="20"
    mode="indeterminate"></mat-progress-spinner>
</div>
<div [ngClass]="{ 'button-container': focus === true && !loading }">
  <button
    #matTooltip="matTooltip"
    (click)="!this.disabled ? buttonClicked.emit(true) : null"
    (mouseleave)="!!tooltip ? matTooltip.hide() : ''"
    (focus)="focus = true"
    (focusout)="focus = false"
    *ngIf="!loading"
    [attr.aria-label]="sdAccessibility?.ariaLabel"
    [attr.aria-disabled]="disabled"
    [disableRipple]="disableRipple"
    [disabled]="disabled"
    [matTooltip]="!!tooltip ? tooltip : ''"
    [ngClass]="
      primary
        ? 'sd-flat-button-primary'
        : inactiveButtonClass
          ? 'sd-flat-button-inactiveButton'
          : danger
            ? 'sd-flat-button-danger'
            : transparent
              ? 'sd-flat-button-transparent'
              : 'sd-flat-button-secondary'
    "
    [tabIndex]="sdAccessibility?.tabIndex"
    mat-flat-button>
    <div class="wrapper-flex">
      <app-sd-svg-a11y
        *ngIf="!!icon"
        [color]="
          primary ? sdColorPalette.appBackground : sdColorPalette.primaryColor
        "
        [height]="1"
        [setSdAccessibility]="{
          tabIndex: -1
        }"
        [src]="icon"
        [width]="1"></app-sd-svg-a11y>
      <span class="sd-flat-button-text">
        <ng-content></ng-content>
      </span>
    </div>
  </button>
</div>
