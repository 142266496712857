<app-sd-dialog-template-a11y
  (closeButtonClicked)="onClose()"
  *ngIf="vm$ | async as vm">
  <ng-container dialog-header>
    <h2>
      {{ 'queueresultdialog_title' | dictionary | async }}
    </h2>
  </ng-container>
  <ng-container class="dialog-body" dialog-body>
    <div>
      <div *ngIf="vm.itemsFailed.length > 0">
        <h3>
          {{ 'queueresultdialog_faildescription' | dictionary | async }}
        </h3>
        <app-queue-item
          *ngFor="let item of vm.itemsFailed; index as i; trackBy: trackBy"
          [attr.aria-label]="item?.upload?.file.name + item?.state"
          [itemId]="item.id"
          [showProgress]="false"></app-queue-item>
      </div>

      <div *ngIf="vm.itemsCompleted.length > 0">
        <h3 *ngIf="vm.itemsFailed.length === 0" tabindex="0">
          {{ 'queueresultdialog_successdescription' | dictionary | async }}
        </h3>
        <app-queue-item
          *ngFor="let item of vm.itemsCompleted; index as i; trackBy: trackBy"
          [attr.aria-label]="item?.upload?.file.name + item?.state"
          [itemId]="item.id"
          [showProgress]="false"></app-queue-item>
      </div>
    </div>
  </ng-container>
  <ng-container dialog-footer>
    <app-sd-flat-button-a11y
      (buttonClicked)="onClose()"
      [primary]="true"
      [setSdAccessibility]="{
        ariaLabel: 'globalparams_close' | dictionary | async
      }">
      {{ 'globalparams_close' | dictionary | async }}
    </app-sd-flat-button-a11y>
  </ng-container>
</app-sd-dialog-template-a11y>
