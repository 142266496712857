import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';

import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomFieldFormType } from 'src/app/dictionary/models/custom-field-form-type.model';
import { customFieldsFormTypesSelector } from 'src/app/dictionary/store/dictionary.selectors';
import { AppState } from 'src/app/reducers';
import { SdCheckButtonA11yEnum } from 'src/app/shared/sd-check-button-a11y/model/sd-check-button-a11y.enum';
import { DialogTemplateTypes } from 'src/app/shared/templates/enums/templates.enum';
import { environment } from 'src/environments/environment';
import { CustomField } from '../../models/custom-field.model';
import { SpecificFieldType } from '../../models/specific-field-type.model';
import { SdDialogTemplateA11yModule } from '../../../shared/templates/dialog-template-a11y/sd-dialog-template-a11y.module';
import { DictionaryPipeModule } from '../../../dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { SdCheckButtonA11yModule } from '../../../shared/sd-check-button-a11y/sd-check-button-a11y.module';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-custom-fields-change-form-type-dialog',
  templateUrl: './custom-fields-change-form-type-dialog.component.html',
  styleUrls: ['./custom-fields-change-form-type-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SdDialogTemplateA11yModule,
    DictionaryPipeModule,
    AsyncPipe,
    NgIf,
    SdCheckButtonA11yModule,
    NgForOf,
  ],
})
export class CustomFieldsChangeFormTypeDialogComponent implements OnInit {
  customFieldFormType$: Observable<CustomFieldFormType[]>;
  cdn = environment.cdn;
  sdCheckButtonA11yEnum = SdCheckButtonA11yEnum;
  dialogTemplateTypes = DialogTemplateTypes;

  constructor(
    private readonly dialogRef: MatDialogRef<CustomFieldsChangeFormTypeDialogComponent>,
    private readonly store: Store<AppState>,
    @Inject(MAT_DIALOG_DATA) public data: CustomField
  ) {}

  ngOnInit(): void {
    this.customFieldFormType$ = this.store.pipe(
      select(customFieldsFormTypesSelector),
      map(fts => fts.filter(c => c.type !== SpecificFieldType.enum))
    );
  }

  onClickHandle(formType: string) {
    this.dialogRef.close({ ...this.data, formType });
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
