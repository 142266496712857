export interface SfMessage {
  event: SfMessageEvent;
  data: {
    recordId?: string;
    sideDrawerId?: string;
    fileId?: string;
    reminderId?: string;
    planId?: string;
    queue?: unknown;
  };
  payload: unknown;
  error: {
    message: string;
    errors: unknown;
  };
}

export enum SfMessageEvent {
  recordCreated = 'recordCreated',
  recordUpdated = 'recordUpdated',
  planDetailsInfoRequest = 'planDetailsInfoRequest',
  simpleFileRequest = 'simpleFileRequest',
  reminder = 'reminder',
  reminderDetailsRequest = 'reminderDetailsRequest',
  filePreview = 'filePreview',
  genericError = 'genericError',
  createReminderRecordRequest = 'createReminderRecordRequest',
  errorReminder = 'errorReminder',
  errorCreatingSFR = 'errorCreatingSFR',
  pdfTronClose = 'pdfTronClose',
  imageViewerClose = 'imageViewerClose',
  videoViewerClose = 'videoViewerClose',
  moveCopyRecord = 'moveCopyRecord',
  moveCopyRecordFile = 'moveCopyRecordFile',
  queueProcessCompletes = 'queueProcessCompletes',
  queueProcessCompletesWithErrors = 'queueProcessCompletesWithErrors',
}
