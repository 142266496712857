<div
  [attr.aria-label]="
    reminderItem?.name + ariaLabelSentence + reminderItem?.status
  "
  [ngClass]="{ finished: reminderItem?.status === reminderStatus.Finished }"
  class="reminder-list-item-info">
  <div class="reminder-list-item-info-users">
    <div class="reminder-list-item-info-users-container">
      <div
        *ngFor="
          let opt of reminderItem.to | getReminderUser: options;
          let i = index
        "
        class="reminder-list-item-avatar">
        <img
          *ngIf="opt?.avatar?.length > 0"
          [alt]="opt.description"
          [ngClass]="i === 0 ? 'circle-avatar' : 'circle-avatar margin-left'"
          [src]="opt?.avatar" />
        <div
          *ngIf="!opt?.avatar"
          [ngClass]="i === 0 ? 'circle-avatar' : 'margin-left-no-text'">
          {{
            i !== 1
              ? opt?.value[0]?.toUpperCase()
              : opt?.value.includes('@')
                ? opt?.value[0]?.toUpperCase()
                : opt?.value?.toUpperCase()
          }}
        </div>
      </div>
    </div>
  </div>
  <div
    [attr.role]="'link'"
    [tabindex]="0"
    [attr.aria-label]="
      reminderItem.name + ' ' + reminderItem?.status + ' ' + ariaLabelSentence
    "
    (keyup)="announce($event)"
    (click)="viewOnly === false || !userCanOperate() ? onDetails() : null"
    (keydown.enter)="
      viewOnly === false || !userCanOperate() ? onDetails() : null
    "
    class="reminder-list-item-info-title">
    {{ reminderItem?.name }}
  </div>
  <div class="reminder-list-item-status">
    <ng-container
      *ngFor="
        let reminderStatusFromCollectionItem of reminderStatusFromCollection
      ">
      <span
        *ngIf="reminderItem?.status === reminderStatusFromCollectionItem.status"
        class="reminder-list-item-info-status">
        {{ reminderStatusFromCollectionItem.statusLabel }}
      </span>
    </ng-container>
  </div>
  <div
    (click)="viewOnly === false || !userCanOperate() ? onDetails() : null"
    (keydown)="viewOnly === false || !userCanOperate() ? onDetails() : null"
    [innerHTML]="getReminderSentence()"
    class="reminder-list-item-info-sentence"></div>
  <div class="reminder-list-item-info-actions" *ngIf="userCanOperate()">
    <span style="display: flex">
      <app-sd-link-menu-a11y
        (emitterClicked)="onEmitterClicked($event)"
        *ngIf="!loading"
        [accessibility]="{
          ariaLabel: 'reminderslistitem_actionmenutooltip' | dictionary | async
        }"
        [border]="false"
        [buttonHeight]="1"
        [disableRipple]="true"
        [iconColor]="
          reminderItem?.status !== reminderStatus.Finished
            ? sdColorPalette.primaryAccentColor
            : sdColorPalette.tertiaryAccentColor
        "
        [icon]="
          cdn + ('globalparams_horizontalactionmenuicon' | dictionary | async)
        "
        [setMenuData]="[
          {
            menuLinkText: reminderStatePaused.statusActionLabel,
            visible: reminderItem?.status === reminderStatus.Active,
            menuLinkType: sdLinkMenuLinkType.emitter,
            key: menuItemType.pause
          },
          {
            menuLinkText: reminderStateActive.statusActionLabel,
            visible: reminderItem?.status === reminderStatus.Paused,
            menuLinkType: sdLinkMenuLinkType.emitter,
            key: menuItemType.play
          },
          {
            menuLinkText:
              'reminderslistitem_actionmenuoptionview' | dictionary | async,
            visible: true,
            menuLinkType: sdLinkMenuLinkType.emitter,
            key: menuItemType.view
          },
          {
            menuLinkText:
              'reminderslistitem_actionmenuoptioncopy' | dictionary | async,
            visible: true,
            menuLinkType: sdLinkMenuLinkType.emitter,
            key: menuItemType.copy
          },
          {
            menuLinkText:
              'reminderslistitem_actionmenuoptiondelete' | dictionary | async,
            menuLinkType: sdLinkMenuLinkType.emitter,
            key: menuItemType.delete
          }
        ]"
        [squareBackground]="true"
        [tooltip]="'reminderslistitem_actionmenutooltip' | dictionary | async"
        [transparentBackground]="true">
      </app-sd-link-menu-a11y>

      <div *ngIf="loading" class="actions-container spinner spinner-center">
        <app-sd-progress-spinner-a11y></app-sd-progress-spinner-a11y>
      </div>
    </span>
  </div>
</div>
