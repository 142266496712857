import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import {
  catchError,
  debounceTime,
  map,
  mergeMap,
  retry,
  startWith,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import {
  combineLatest,
  EMPTY,
  from,
  fromEvent,
  of,
  Subscription,
  timer,
} from 'rxjs';
import {
  AuthActionsTypes,
  AuthorizeUser,
  AuthUserUpdate,
  DisplayInactiveTimeWarning,
  LogOut,
  RefreshTokenRequired,
  StartInactivityTime,
  StartRefreshTokenTime,
  TokenUpdate,
} from 'src/app/auth/store/auth.actions';

import { MobileRoutesHelper } from '../../core/helpers/mobile-routes.helper';
import { Router } from '@angular/router';
import { CoreRoutes } from 'src/app/core/routes/core.routes';

import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/reducers';
import { MatDialog } from '@angular/material/dialog';
import { InactivityTimeDialogComponent } from 'src/app/auth/shared/inactivity-time-dialog/components/inactivity-time-dialog/inactivity-time-dialog.component';
import { MobileAuthService } from '../services/mobile-auth.service';
import { AuthUser } from '../../../../auth/models/auth-user.model';

@Injectable()
export class MobileAuthEffects {
  protected readonly actions$ = inject(Actions);
  protected readonly mobileAuthService = inject(MobileAuthService);
  protected readonly store = inject(Store<AppState>);
  protected readonly router = inject(Router);
  protected readonly dialog = inject(MatDialog);

  authorizeUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<AuthorizeUser>(AuthActionsTypes.AuthorizeUser),
        switchMap(() => this.mobileAuthService.loginWithRedirect()),
        catchError(error => {
          console.log('error', error);
          const queryParams = MobileRoutesHelper.getParams(false);
          this.router.navigate(
            [`${CoreRoutes.root}/${CoreRoutes.error}/true`],
            { queryParams }
          );
          return of(EMPTY);
        })
      ),
    { dispatch: false }
  );

  logout$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<LogOut>(AuthActionsTypes.Logout),
        mergeMap(() => this.mobileAuthService.logOut())
      );
    },
    { dispatch: false }
  );
  tokenUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType<TokenUpdate>(AuthActionsTypes.TokenUpdate),
      switchMap(() => this.mobileAuthService.getAuth0User().pipe(take(1))),
      map(user => new AuthUserUpdate({ user: AuthUser.fromAuth0(user) }))
    )
  );
  displayInactiveTimeWarning = createEffect(() =>
    this.actions$.pipe(
      ofType<DisplayInactiveTimeWarning>(
        AuthActionsTypes.DisplayInactiveTimeWarning
      ),
      switchMap(() =>
        this.dialog
          .open(InactivityTimeDialogComponent, {
            autoFocus: false,
            closeOnNavigation: false,
            disableClose: true,
          })
          .afterClosed()
      ),
      map(() => new StartInactivityTime())
    )
  );
  startRefreshTokenTime$ = createEffect(() =>
    this.actions$.pipe(
      ofType<StartRefreshTokenTime>(AuthActionsTypes.StartRefreshTokenTime),
      debounceTime(1000 * environment.refreshTokenTime),
      switchMap(() =>
        this.mobileAuthService.getTokenSilently().pipe(
          take(1),
          retry({
            count: 10,
            delay: (_, retryCount) => timer(retryCount * 1000),
          })
        )
      ),
      map(token => {
        this.store.dispatch(new TokenUpdate({ token }));
        return new StartRefreshTokenTime();
      }),
      catchError(error => {
        console.error({
          error,
          message: 'getTokenSilently fails on auth effect',
        });
        return of(new LogOut());
      })
    )
  );
  refreshTokenRequired$ = createEffect(() =>
    this.actions$.pipe(
      ofType<RefreshTokenRequired>(AuthActionsTypes.RefreshTokenRequired),
      switchMap(() =>
        this.mobileAuthService.getTokenSilently().pipe(
          take(1),
          retry({
            count: 10,
            delay: (_, retryCount) => timer(retryCount * 1000),
          })
        )
      ),
      map(token => new TokenUpdate({ token }))
    )
  );
  private inactivitySubscription: Subscription;
  private inactivityTime$ = combineLatest([
    fromEvent(document, 'tap').pipe(startWith(null)),
    fromEvent(document, 'touchend').pipe(startWith(null)),
    fromEvent(document, 'touchmove').pipe(startWith(null)),
  ]).pipe(
    debounceTime(1000 * environment.inactivityTime),
    map(() => {
      this.store.dispatch(new DisplayInactiveTimeWarning());
      this.inactivitySubscription.unsubscribe();
    })
  );
  startInactivityTime$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<StartInactivityTime>(AuthActionsTypes.StartInactivityTime),
        tap(() => {
          if (this.inactivitySubscription) {
            this.inactivitySubscription.unsubscribe();
          }
          this.inactivitySubscription = new Subscription();
          this.inactivitySubscription.add(this.inactivityTime$.subscribe());
        })
      ),
    { dispatch: false }
  );
}
