import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { take, tap } from 'rxjs';
import { RecordsRoutes } from 'src/app/records/routes/records.routes';
import { ActiveRecordRequested } from 'src/app/records/store/records-list.actions';
import { AppState } from 'src/app/reducers';
import { RelatedRecord } from 'src/app/related-records/models/related-record.model';
import {
  RelatedRecordDeletedRequested,
  RelatedRecordsRemoved,
} from 'src/app/related-records/store/related-records.actions';
import { SidedrawerRoutesEnum } from 'src/app/sidedrawer/routes/sidedrawer-routes.enum';
import { environment } from '../../../../../environments/environment';
import { basePathSelector } from 'src/app/sidedrawer/store/sidedrawer.selector';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { DictionaryPipeModule } from 'src/app/dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { SdProgressSpinnerA11yModule } from 'src/app/shared/sd-progress-spinner-a11y/sd-progress-spinner-a11y/sd-progress-spinner-a11y.module';
import { SdSvgA11yModule } from 'src/app/shared/sd-svg-a11y/sd-svg-a11y.module';
import { SdFormattedDatePipeModule } from 'src/app/shared/sd-formatted-date-pipe/sd-formatted-date-pipe.module';
import { DateHelper } from 'src/app/core/helpers/date.helper';
import { SdColorPalette } from 'src/app/core/models/enums/sd-color-palette-enum';
import { MenuItemType } from 'src/app/shared/sd-header-a11y/models/menu-item.type.enum';
import { SdLinkMenuLinkType } from 'src/app/shared/sd-link-menu-a11y/models/enums/sd-link-menu-link-type-enum';
import { SdLinkMenuA11yModule } from 'src/app/shared/sd-link-menu-a11y/sd-link-menu-a11y.module';
import { SdLinkMenuItem } from 'src/app/shared/sd-link-menu-a11y/models/sd-link-menu-item.model';
import { SdIconButtonA11yComponent } from 'src/app/shared/sd-icon-button-a11y/components/sd-icon-button-a11y/sd-icon-button-a11y.component';
import { userCanOperateOnThisRecordSelector } from 'src/app/records/store/records-list.selectors';

@Component({
  selector: 'app-related-records-tags-a11y',
  templateUrl: './related-records-tags-a11y.component.html',
  styleUrls: ['./related-records-tags-a11y.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    SdProgressSpinnerA11yModule,
    SdIconButtonA11yComponent,
    DictionaryPipeModule,
    SdSvgA11yModule,
    SdFormattedDatePipeModule,
    SdLinkMenuA11yModule,
  ],
})
export class RelatedRecordsTagsA11yComponent {
  relatedRecord: RelatedRecord;
  @Input() activeRecordId: string;
  @Output() deleteClicked = new EventEmitter<boolean>();
  @Input() viewOnly = false;
  @Input() itemWithLink = true;
  cdn = environment.cdn;
  spinner = false;
  happensToday: boolean;
  updatedAt: Date;
  lastModifiedBy: string;
  sdColorPalette = SdColorPalette;
  sdLinkMenuLinkType = SdLinkMenuLinkType;
  menuItemType = MenuItemType;
  userCanOperate = this.store.selectSignal(userCanOperateOnThisRecordSelector);
  constructor(
    private readonly store: Store<AppState>,
    private readonly router: Router
  ) {}

  @Input() set setRelatedRecord(record: RelatedRecord) {
    this.relatedRecord = record;
    this.setRecordDataForSentence(record);
  }

  redirectToRecord(): void {
    this.spinner = true;
    this.store.dispatch(
      new ActiveRecordRequested({
        recordId: this.relatedRecord.id,
        callback: result => {
          this.spinner = false;
          if (result instanceof HttpErrorResponse) {
            return;
          }
          this.store.dispatch(new RelatedRecordsRemoved());

          this.store
            .pipe(
              select(basePathSelector),
              take(1),
              tap(basePath => {
                const url =
                  basePath +
                  `${SidedrawerRoutesEnum.root}/${RecordsRoutes.root}/${result.id}`;
                this.router.navigate([url]);
              })
            )
            .subscribe();
        },
      })
    );
  }

  onDelete(): void {
    this.spinner = true;
    this.store.dispatch(
      new RelatedRecordDeletedRequested({
        deleteRecordId: this.relatedRecord.id,
        callback: () => {
          this.spinner = false;
        },
      })
    );
  }

  onEmitterClicked(linkMenu: SdLinkMenuItem) {
    if (linkMenu.key === MenuItemType.delete) {
      this.onDelete();
    }
  }

  onClickOverOptionMenu(event) {
    event.stopPropagation();
    event.preventDefault();
  }

  private setRecordDataForSentence(record: RelatedRecord): void {
    this.happensToday = DateHelper.happensToday(`${record.updatedAt}`);
  }
}
