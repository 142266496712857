<!-- TODO for aria label -->
<nav aria-label="Breadcrumb" class="sd-breadcrumbs-container" role="navigation">
  <ol>
    <li *ngIf="!isMyAccount">
      <a
        tabindex="0"
        id="breadcrumb"
        [routerLink]="
          !!(activeSideDrawer$ | async)
            ? (basePath$ | async) + (homePath$ | async)
            : (basePath$ | async)
        ">
        <span class="sd-breadcrumbs-active-sidedrawer">{{
          'sdbreadcrumbs_homebreadcrumb' | dictionary | async
        }}</span>
      </a>
    </li>
    <li *ngIf="isMyAccount">
      <a [routerLink]="'/core/my-account'" id="breadcrumb">
        <span class="sd-breadcrumbs-active-sidedrawer">{{
          'sdbreadcrumbs_homebreadcrumb' | dictionary | async
        }}</span>
      </a>
    </li>
    <ng-container *ngFor="let item of breadCrumbs$ | async; let i = index">
      <li>
        <a
          *ngIf="item.url"
          [attr.aria-current]="
            i === (breadCrumbs$ | async).length - 1 ? 'page' : ''
          "
          [attr.aria-label]="item.ariaLabel"
          [ngClass]="
            i < (breadCrumbs$ | async).length - 1
              ? 'sd-breadcrumb-span'
              : 'sd-breadcrumb-span-last'
          "
          [routerLink]="[
            !!item.urlRelative ? (basePath$ | async) + item.url : item.url
          ]">
          <span>{{ item.label | dictionary | async }}</span>
        </a>
        <a
          tabindex="0"
          *ngIf="!item.url"
          [attr.aria-current]="
            i === (breadCrumbs$ | async).length - 1 ? 'page' : ''
          "
          [attr.aria-label]="item.ariaLabel"
          [ngClass]="
            i < (breadCrumbs$ | async).length - 1
              ? 'sd-breadcrumb-span'
              : 'sd-breadcrumb-span-last'
          "
          [ngStyle]="{ cursor: 'default' }">
          <span>{{ item.label | dictionary | async }}</span>
        </a>
      </li>
    </ng-container>
  </ol>
</nav>
