<ng-template #default [ngIf]="sdCheckButtonA11yEnum.default === template">
  <!-- TODO dictionary for selected option -->
  <li
    [attr.aria-label]="
      checked
        ? sdAccessibility?.ariaLabel + ('selected ' | dictionary | async)
        : sdAccessibility?.ariaLabel
    "
    [tabindex]="0"
    [ngClass]="
      checked
        ? 'sd-check-button-container checked'
        : 'sd-check-button-container'
    "
    [attr.aria-current]="checked"
 >
    <div
      [ngClass]="checked ? 'sd-check-button checked' : 'sd-check-button'"
      class="sd-check-button"
     >
      <div class="sd-check-button-value">
        {{ value }}
      </div>
      <div [ngClass]="checked ? 'sd-check-button-check' : 'invisible'">
        <app-sd-svg-a11y
          [color]="sdColorPalette.primaryColor"
          [height]="1.25"
          [setSdAccessibility]="{
            tabIndex: -1
          }"
          [src]="cdn + ('globalparams_checkicon' | dictionary | async)"
          [width]="1.25">
        </app-sd-svg-a11y>
      </div>
    </div>

    <p
      *ngIf="description"
      [ngClass]="checked ? 'checked' : ''"
      class="sd-check-button-description">
      {{ description }}
    </p>
  </li>
</ng-template>

<ng-template
  #templateWithPrefixWithSvg
  [ngIf]="sdCheckButtonA11yEnum.templateWithPrefixWithSvg === template">
  <!-- TODO dictionary for selected option -->
  <div
    [tabindex]="0"
    [attr.aria-label]="
      checked
        ? sdAccessibility?.ariaLabel + ('selected ' | dictionary | async)
        : sdAccessibility?.ariaLabel
    "
    [attr.aria-current]="checked"
    [ngClass]="
      checked
        ? 'sd-check-button-container checked'
        : 'sd-check-button-container'
    ">
    <div
      [ngClass]="checked ? 'sd-check-button checked' : 'sd-check-button'"
      class="sd-check-button">
      <div class="sd-check-button-value">
        <app-sd-svg-a11y
          *ngIf="!!svg"
          [color]="color"
          [height]="1.25"
          [setSdAccessibility]="{
            tabIndex: -1
          }"
          [src]="svg"
          [width]="1.25"></app-sd-svg-a11y>
        {{ value }}
      </div>
      <div [ngClass]="checked ? 'sd-check-button-check' : 'invisible'">
        <app-sd-svg-a11y
          [color]="sdColorPalette.primaryColor"
          [height]="1.25"
          [setSdAccessibility]="{
            tabIndex: -1
          }"
          [src]="cdn + ('globalparams_checkicon' | dictionary | async)"
          [width]="1.25">
        </app-sd-svg-a11y>
      </div>
    </div>

    <p
      *ngIf="description"
      [ngClass]="checked ? 'checked' : ''"
      class="sd-check-button-description">
      {{ description }}
    </p>
  </div>
</ng-template>

<ng-template
  #templateWithPrefixWithImg
  [ngIf]="sdCheckButtonA11yEnum.templateWithPrefixWithImg === template">
  <!-- TODO dictionary for selected option -->
  <li
      [attr.aria-label]="
        checked
          ? sdAccessibility?.ariaLabel + ('selected ' | dictionary | async)
          : sdAccessibility?.ariaLabel
      "
      [attr.aria-current]="checked"
      [ngClass]="
        checked
          ? 'sd-check-button-container checked'
          : 'sd-check-button-container'
      ">
      <div
        [ngClass]="checked ? 'sd-check-button checked' : 'sd-check-button'"
        class="sd-check-button">
        <div class="sd-check-button-value">
          <img *ngIf="!!img" [alt]="value" [src]="img" />
          {{ value }}
        </div>
        <div [ngClass]="checked ? 'sd-check-button-check' : 'invisible'">
          <app-sd-svg-a11y
            [color]="sdColorPalette.primaryColor"
            [height]="1.25"
            [setSdAccessibility]="{
              tabIndex: -1
            }"
            [src]="cdn + ('globalparams_checkicon' | dictionary | async)"
            [width]="1.25">
          </app-sd-svg-a11y>
        </div>
      </div>

      <p
        *ngIf="description"
        [ngClass]="checked ? 'checked' : ''"
        class="sd-check-button-description">
        {{ description }}
      </p>
  </li>
</ng-template>
