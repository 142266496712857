import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PaginatorService } from '../../core/services/paginator.service';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import {
  AddFileToQueue,
  FileDownloadUpdate,
  ZipActionsTypes,
  ZipAllFilesRequested,
  ZipAllRecordsLoaded,
  ZipAllRecordsRequested,
  ZipCanceled,
  ZipCompletes,
  ZipConfirmDownload,
  ZipDownloadFile,
  ZipDownloadFileError,
  ZipFails,
  ZipGenerateZipFile,
  ZipNoFiles,
  ZipRecordFilesLoaded,
  ZipRecordFilesRequested,
  ZipRecordsLoaded,
  ZipStatusUpdate,
} from './zip.actions';
import {
  catchError,
  last,
  map,
  mergeMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { forkJoin, from, of } from 'rxjs';
import {
  zipQueueSelector,
  zipRecordListItemByIdSelector,
  zipRecordListSelector,
  zipSideDrawerSelector,
  zipStatusSelector,
} from './zip.selectors';
import { localeDefaultSelector } from '../../dictionary/store/dictionary.selectors';
import { RecordListItem } from '../../records/models/record-list-item.model';
import { RecordsHelper } from '../../records/helpers/records.helper';
import { Locale } from '../../dictionary/models/locale.model';
import { FileItem } from '../../files/models/file-item.model';
import { FilesHelper } from '../../files/helpers/files.helper';
import { ZipQueueFile } from '../models/zip-queue-file.model';
import { ZipQueueStatus } from '../models/zip-queue-status.model';
import { ZipStatus } from '../models/zip-status.model';
import { FilesService } from '../../files/services/files.service';
import { UtilsHelper } from '../../core/helpers/utils.helper';
import * as JSZip from 'jszip';
import { saveAs as importedSaveAs } from 'file-saver';

@Injectable()
export class ZipEffects {
  zipConfirmDownload$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ZipConfirmDownload>(ZipActionsTypes.ZipConfirmDownload),
      map(() => new ZipAllRecordsRequested())
    )
  );

  zipAllRecordsRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ZipAllRecordsRequested>(ZipActionsTypes.ZipAllRecordsRequested),
      mergeMap(action =>
        forkJoin([
          this.store.pipe(select(zipSideDrawerSelector), take(1)),
          this.store.pipe(select(localeDefaultSelector), take(1)),
        ]).pipe(
          mergeMap(([sideDrawer, locale]) => {
            if (!action?.payload?.data || action?.payload?.data?.hasMore) {
              return this.paginatorService
                .getPaginatedResource<RecordListItem>(
                  RecordsHelper.getRecordsResourceUrl(
                    sideDrawer.id,
                    Locale.getLocaleId(locale),
                    {
                      nextPage: action?.payload?.data?.nextPage,
                    }
                  )
                )
                .pipe(
                  takeUntil(
                    this.actionsListener$.pipe(
                      ofType<ZipCanceled>(ZipActionsTypes.ZipCanceled)
                    )
                  ),
                  tap(response =>
                    this.store.dispatch(
                      new ZipRecordsLoaded({ records: response.data })
                    )
                  ),
                  map(
                    response => new ZipAllRecordsRequested({ data: response })
                  ),
                  catchError(() => of(new ZipNoFiles()))
                );
            }
            return of(new ZipAllRecordsLoaded());
          })
        )
      )
    )
  );

  zipAllRecordsLoaded$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ZipAllRecordsLoaded>(ZipActionsTypes.ZipAllRecordsLoaded),
      mergeMap(() =>
        this.store.pipe(
          select(zipRecordListSelector),
          take(1),
          map(recordsList =>
            recordsList.size === 0
              ? new ZipNoFiles()
              : new ZipAllFilesRequested()
          )
        )
      )
    )
  );

  zipAllFilesRequested$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ZipAllFilesRequested>(ZipActionsTypes.ZipAllFilesRequested),
        mergeMap(() =>
          this.store.pipe(
            select(zipRecordListSelector),
            take(1),
            tap(recordsList => {
              recordsList.forEach((item, key) =>
                this.store.dispatch(
                  new ZipRecordFilesRequested({ recordId: key })
                )
              );
            })
          )
        )
      ),
    { dispatch: false }
  );

  zipRecordFilesRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ZipRecordFilesRequested>(ZipActionsTypes.ZipRecordFilesRequested),
      mergeMap(action =>
        forkJoin([
          this.store.pipe(select(zipSideDrawerSelector), take(1)),
          this.store.pipe(
            select(
              zipRecordListItemByIdSelector({ id: action.payload.recordId })
            ),
            take(1)
          ),
        ]).pipe(
          mergeMap(([sd, item]) => {
            if (!action?.payload?.data || action?.payload?.data?.hasMore) {
              return this.paginatorService
                .getPaginatedResource<FileItem>(
                  FilesHelper.getFileHistoryResourceUrl(
                    sd.id,
                    action.payload.recordId,
                    {
                      nextPage: action?.payload?.data?.nextPage,
                    }
                  )
                )
                .pipe(
                  takeUntil(
                    this.actionsListener$.pipe(
                      ofType<ZipCanceled>(ZipActionsTypes.ZipCanceled)
                    )
                  ),
                  tap(response => {
                    response.data.forEach(fileItem =>
                      this.store.dispatch(
                        new AddFileToQueue({
                          item: new ZipQueueFile(
                            ZipQueueStatus.pending,
                            { ...fileItem, recordId: item.record.id },
                            0,
                            item?.record?.recordType?.displayValue[0]?.value,
                            item?.record?.name
                          ),
                        })
                      )
                    );
                  }),
                  map(
                    response =>
                      new ZipRecordFilesRequested({
                        recordId: action.payload.recordId,
                        data: response,
                      })
                  ),
                  catchError(() =>
                    of(
                      new ZipDownloadFileError({
                        error: `${item?.record?.recordType?.displayValue[0]?.value} / ${item?.record?.name}`,
                      })
                    )
                  )
                );
            }
            return of(
              new ZipRecordFilesLoaded({ recordId: action.payload.recordId })
            );
          })
        )
      )
    )
  );

  zipRecordFilesLoaded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ZipRecordFilesLoaded>(ZipActionsTypes.ZipRecordFilesLoaded),
        mergeMap(() =>
          forkJoin([
            this.store.pipe(select(zipRecordListSelector), take(1)),
            this.store.pipe(select(zipQueueSelector), take(1)),
          ]).pipe(
            tap(([recordsList, queue]) => {
              const complete = !UtilsHelper.mapToArray<{
                record: RecordListItem;
                getRecordsFilesComplete: boolean;
              }>(recordsList).some(item => !item.getRecordsFilesComplete);
              if (!complete) {
                return;
              }
              if (queue.length > 0) {
                this.store.dispatch(
                  new ZipStatusUpdate({ status: ZipStatus.downloading })
                );
                this.store.dispatch(new ZipDownloadFile());
                return;
              }
              this.store.dispatch(new ZipNoFiles());
            })
          )
        )
      ),
    { dispatch: false }
  );

  zipDownloadFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ZipDownloadFile>(ZipActionsTypes.ZipDownloadFile),
      mergeMap(() =>
        forkJoin([
          this.store.pipe(select(zipQueueSelector), take(1)),
          this.store.pipe(select(zipSideDrawerSelector), take(1)),
          this.store.pipe(select(zipStatusSelector), take(1)),
        ]).pipe(
          mergeMap(([queue, sd, status]) => {
            if (status === ZipStatus.canceled) {
              return of(new ZipCanceled());
            }
            const index = queue.findIndex(
              item => item?.status === ZipQueueStatus.pending
            );
            if (index < 0) {
              this.store.dispatch(
                new ZipStatusUpdate({ status: ZipStatus.zipping })
              );
              return of(new ZipGenerateZipFile());
            }
            const fileItem = queue[index].fileItem;
            this.store.dispatch(
              new FileDownloadUpdate({
                index,
                item: {
                  ...queue[index],
                  status: ZipQueueStatus.downloading,
                },
              })
            );
            return this.filesService.downloadFile(fileItem, sd.id).pipe(
              takeUntil(
                this.actionsListener$.pipe(
                  ofType<ZipCanceled>(ZipActionsTypes.ZipCanceled)
                )
              ),
              tap(
                (response: { loaded: number; status: string; file: Blob }) => {
                  if (response.status === 'progress') {
                    const progress = fileItem?.fileSize
                      ? (response.loaded / fileItem.fileSize) * 100
                      : FilesHelper.calculateProgress(response.loaded);
                    this.store.dispatch(
                      new FileDownloadUpdate({
                        index,
                        item: {
                          ...queue[index],
                          progress,
                        },
                      })
                    );
                  }
                }
              ),
              last(),
              map(response => {
                this.store.dispatch(
                  new FileDownloadUpdate({
                    index,
                    item: {
                      ...queue[index],
                      status: ZipQueueStatus.complete,
                      file: new File(
                        [response.file],
                        FilesHelper.formatFileNameWithExtensionForDownLoad(
                          FilesHelper.generateFileNameWithExtension(
                            queue[index].fileItem
                          )
                        )
                      ),
                    },
                  })
                );
                return new ZipDownloadFile();
              }),
              catchError(() => {
                this.store.dispatch(
                  new ZipDownloadFileError({
                    error: `${queue[index].recordTypeName} / ${queue[index].recordName}`,
                  })
                );
                this.store.dispatch(
                  new FileDownloadUpdate({
                    index,
                    item: {
                      ...queue[index],
                      status: ZipQueueStatus.fail,
                    },
                  })
                );
                return of(new ZipDownloadFile());
              })
            );
          })
        )
      )
    )
  );

  zipGenerateZipFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ZipGenerateZipFile>(ZipActionsTypes.ZipGenerateZipFile),
      mergeMap(() =>
        forkJoin([
          this.store.pipe(select(zipQueueSelector), take(1)),
          this.store.pipe(select(zipSideDrawerSelector), take(1)),
        ]).pipe(
          mergeMap(([queue, sd]) => {
            const zip = new JSZip();
            for (const queueItem of queue) {
              if (queueItem.status === ZipQueueStatus.complete) {
                zip.file(
                  `${FilesHelper.generateFolderName(
                    queueItem.recordTypeName
                  )}/${FilesHelper.generateFolderName(queueItem.recordName)}/${
                    queueItem.file.name
                  }`,
                  queueItem.file
                );
              }
            }
            return from(zip.generateAsync({ type: 'blob' })).pipe(
              tap((content: Blob) =>
                importedSaveAs(
                  content,
                  FilesHelper.formatFileNameWithExtensionForDownLoad(
                    `${sd.name}.zip`
                  )
                )
              ),
              map(() => new ZipCompletes()),
              catchError(() => of(new ZipFails()))
            );
          })
        )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly actionsListener$: ActionsSubject,
    private readonly filesService: FilesService,
    private readonly paginatorService: PaginatorService,
    private readonly store: Store<AppState>
  ) {}
}
