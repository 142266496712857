import { Action } from '@ngrx/store';
import { PaginatorApiResponse } from '../../core/models/paginator-api-response.dto';
import { RecordListItem } from '../../records/models/record-list-item.model';

export enum SearchRecordsListActionsTypes {
  SearchRecordsListRequested = '[Search Effect] Search Records List Requested',
  SearchRecordsListRequestedForAppend = '[Search Effect] Search Records List Requested For Append',
  SearchRecordsListLoaded = '[Search Effect] Search Records List Loaded',
  SearchRecordsListLoadedForAppend = '[Search Effect] Search Records List Loaded For Append',
  SearchRecordsListReset = '[Search Effect] Search Records List Reset Requested by the user',
  SearchRecordListDeleted = '[Search Effect] Remove a Record from the Search List',
}

export class SearchRecordsListRequested implements Action {
  readonly type = SearchRecordsListActionsTypes.SearchRecordsListRequested;

  constructor(
    public payload: {
      sideDrawerId: string;
      locale: string;
      uniqueReference?: string;
      nextPage?: string;
      name?: string;
    }
  ) {}
}

export class SearchRecordsListRequestedForAppend implements Action {
  readonly type =
    SearchRecordsListActionsTypes.SearchRecordsListRequestedForAppend;

  constructor(
    public payload: {
      sideDrawerId: string;
      locale: string;
      uniqueReference?: string;
      nextPage?: string;
      name?: string;
    }
  ) {}
}

export class SearchRecordsListLoaded implements Action {
  readonly type = SearchRecordsListActionsTypes.SearchRecordsListLoaded;

  constructor(
    public payload: {
      data: PaginatorApiResponse<RecordListItem>;
      sideDrawerId: string;
      filter?: string;
    }
  ) {}
}

export class SearchRecordsListLoadedForAppend implements Action {
  readonly type =
    SearchRecordsListActionsTypes.SearchRecordsListLoadedForAppend;

  constructor(
    public payload: {
      data: PaginatorApiResponse<RecordListItem>;
      sideDrawerId: string;
    }
  ) {}
}

export class SearchRecordsListReset implements Action {
  readonly type = SearchRecordsListActionsTypes.SearchRecordsListReset;
}

export class SearchRecordListDeleted implements Action {
  readonly type = SearchRecordsListActionsTypes.SearchRecordListDeleted;

  constructor(public payload: { id: string }) {}
}

export type SearchRecordsListActions =
  | SearchRecordsListRequested
  | SearchRecordsListLoaded
  | SearchRecordsListRequestedForAppend
  | SearchRecordsListLoadedForAppend
  | SearchRecordsListReset
  | SearchRecordListDeleted;
