import { Action } from '@ngrx/store';
import { PaymentMethod } from '../models/payment-method.model';
import { ErrorView } from '../../core/models/error-view.model';

export enum PaymentActionsTypes {
  PaymentMethodsRequested = '[Payments main view] Payment Methods Requested',
  PaymentMethodsLoaded = '[Payment Service] Payment Methods Loaded',
  PaymentMethodDeleted = '[Delete Payment - Dialog] Payment Method deleted',
  PaymentMethodClean = '[LogOut] All Payment Methods deleted',
  PaymentMethodSpinnerChange = '[Payment Service] Payment Method spinner change',
  PaymentMethodErrorChange = '[Payment Service] Payment Method Error change',
  PaymentMethodCleanError = '[Payments main view] Clean Payment Method Errors',
}

export class PaymentMethodsRequested implements Action {
  readonly type = PaymentActionsTypes.PaymentMethodsRequested;
}

export class PaymentMethodsLoaded implements Action {
  readonly type = PaymentActionsTypes.PaymentMethodsLoaded;

  constructor(public payload: { data: PaymentMethod[] }) {}
}

export class PaymentMethodDeleted implements Action {
  readonly type = PaymentActionsTypes.PaymentMethodDeleted;

  constructor(public payload: { id: string }) {}
}

export class PaymentMethodClean implements Action {
  readonly type = PaymentActionsTypes.PaymentMethodClean;
}

export class PaymentMethodSpinnerChange implements Action {
  readonly type = PaymentActionsTypes.PaymentMethodSpinnerChange;

  constructor(public payload: { state: boolean }) {}
}

export class PaymentMethodErrorChange implements Action {
  readonly type = PaymentActionsTypes.PaymentMethodErrorChange;

  constructor(public payload: { error: ErrorView }) {}
}

export class PaymentMethodCleanError implements Action {
  readonly type = PaymentActionsTypes.PaymentMethodCleanError;
}

export type PaymentActions =
  | PaymentMethodsRequested
  | PaymentMethodsLoaded
  | PaymentMethodDeleted
  | PaymentMethodClean
  | PaymentMethodSpinnerChange
  | PaymentMethodErrorChange
  | PaymentMethodCleanError;
