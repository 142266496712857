import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SdLinkMenuA11yModule } from '../../../shared/sd-link-menu-a11y/sd-link-menu-a11y.module';
import { SdProgressSpinnerA11yModule } from '../../../shared/sd-progress-spinner-a11y/sd-progress-spinner-a11y/sd-progress-spinner-a11y.module';
import { DictionaryPipeModule } from 'src/app/dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { SdRemindersListItemA11yComponent } from './components/sd-reminders-list-item-a11y/sd-reminders-list-item-a11y.component';
import { AvatarBackgroundColorPipe } from '../../../shared/sd-pipes/avatar-background-color/avatar-background-color.pipe';
import { GetReminderUserPipe } from '../../pipes/get-reminder-user.pipe';
import { SdIconButtonA11yComponent } from 'src/app/shared/sd-icon-button-a11y/components/sd-icon-button-a11y/sd-icon-button-a11y.component';

@NgModule({
  declarations: [SdRemindersListItemA11yComponent],
  imports: [
    CommonModule,
    SdIconButtonA11yComponent,
    SdLinkMenuA11yModule,
    SdProgressSpinnerA11yModule,
    DictionaryPipeModule,
    AvatarBackgroundColorPipe,
    GetReminderUserPipe,
  ],
  exports: [SdRemindersListItemA11yComponent],
})
export class RemindersListItemA11yModule {}
