<div class="sd-content-header">
  <div class="sd-content-header-buttons">
    <div class="sd-content-header-title-container">
      <h2 *ngIf="!!title" class="sd-content-header-title">
        {{ title }}
      </h2>
      <div *ngIf="showCopyLink" class="actions-buttons-coumn">
        <app-sd-link-menu-a11y
          (click)="$event.stopPropagation()"
          (emitterClicked)="copyLinkClicked.emit($event)"
          (keydown)="$event.stopPropagation()"
          (keyup)="$event.stopPropagation()"
          [accessibility]="{
            ariaLabel:
              ('sdcontentheader_actionmenutooltip' | dictionary | async),
            tabIndex: -1
          }"
          [border]="true"
          [disableRipple]="true"
          [iconColor]="sdColorPalette.primaryAccentColor"
          [icon]="
            cdn + ('globalparams_verticalactionmenuicon' | dictionary | async)
          "
          [setMenuData]="
            customMenuData
              ? customMenuData
              : [
                  {
                    menuLinkText:
                      'sdcontentheader_actionmenuoptionshare'
                      | dictionary
                      | async,
                    visible: true,
                    menuLinkType: sdLinkMenuLinkType.emitter,
                    key: menuItemType.share
                  },
                  {
                    menuLinkText:
                      'sdcontentheader_actionmenuoptionlink'
                      | dictionary
                      | async,
                    visible: true,
                    menuLinkType: sdLinkMenuLinkType.emitter,
                    key: menuItemType.copy
                  }
                ]
          "
          [squareBackground]="true"
          [tooltip]="'sdcontentheader_actionmenutooltip' | dictionary | async"
          [transparentBackground]="true" />
      </div>
    </div>
    <div *ngIf="toggle" class="sd-header-flex-container">
      <p>{{ toggleLabel }}</p>
      <app-sd-slide-toggle-a11y
        (toggleChange)="toggleChange.emit($event)"
        [checked]="checked"
        [setSdAccessibility]="{
          tabIndex: 0,
          ariaLabel: sdAccessibilityForToggle?.ariaLabel
        }"
        [template]="sdSlideToggleTemplateA11y.slideToggletWithoutController"
        color="primary" />
    </div>

    <div *ngIf="sortButton">
      <app-sd-sort-button-a11y
        (sortButtonClicked)="sortButtonClicked.emit(true); isSorted = !isSorted"
        [setSdAccessibility]="{ ariaLabel: 'globalparams_sorted' | dictionary | async }"></app-sd-sort-button-a11y>
    </div>

    <div *ngIf="displayButton" class="sd-content-header-main-button-container">
      <div
        [ngClass]="
          !spinner
            ? 'sd-content-header-main-button'
            : 'sd-content-header-main-button-hidden'
        ">
        <app-sd-flat-button-a11y
          (buttonClicked)="buttonCancelClicked.emit(true)"
          *ngIf="cancelButton"
          [inactiveButtonClass]="false"
          [primary]="false"
          [setSdAccessibility]="{
            ariaLabel: buttonCancelText
          }"
          class="mr-5 cancel-button"
          >{{ buttonCancelText }}</app-sd-flat-button-a11y
        >

        <app-sd-flat-button-a11y
          (buttonClicked)="buttonClicked.emit(true)"
          *ngIf="!useAlertButton"
          [disabled]="buttonDisabled"
          [inactiveButtonClass]="inactiveButtonClass"
          [primary]="primary"
          [setSdAccessibility]="{
            ariaLabel: sdAccessibility?.ariaLabel
          }"
          >{{ buttonText }}</app-sd-flat-button-a11y
        >

        <app-sd-flat-button-a11y
          (buttonClicked)="buttonClicked.emit(true)"
          *ngIf="useAlertButton"
          [danger]="true"
          [disabled]="buttonDisabled"
          [setSdAccessibility]="{
            ariaLabel: buttonText
          }"
          >{{ buttonText }}</app-sd-flat-button-a11y
        >
      </div>

      <div [ngClass]="spinner ? 'sd-content-header-main-spinner' : 'hidden'">
        <span
          *ngIf="!!spinnerProgress"
          class="sd-content-header-main-spinner-progress"
          >{{ spinnerProgress | number: '1.0-0' }}%</span
        >
        <app-sd-progress-spinner-a11y
          [mode]="progressSpinnerMode"
          [setSpinnerProgress]="spinnerProgress"></app-sd-progress-spinner-a11y>
      </div>
    </div>

    <div *ngIf="showExternalContent" class="buttons-content">
      <ng-content select="[action-buttons]"></ng-content>
    </div>
  </div>
</div>
