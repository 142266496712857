import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GetNetworksResponseDto } from '../models/get-networks-response.dto';
import { Team } from '../../account/models/team.model';
import { CreateSidedrawerNetworkDto } from '../models/create-sidedrawer-network.dto';
import { UpdateSideDrawerNetworkDto } from '../models/update-side-drawer-network.dto';
import { map } from 'rxjs/operators';
import { UpdateRecordNetworkDto } from '../models/update-record-network.dto';
import { GetTeamsNetworkResponseDto } from '../models/get-teams-network-response.dto';
import { TransferOwnershipDto } from '../models/transfer-ownership.dto';
import { Relation } from '../../records/models/relation.model';
import { NetworkGroup } from '../models/network-group.model';
import { CreateNetworkGroupDto } from '../models/create-network-group.dto';
import { NetworkProgressResponse } from '../models/network-progress-response.model';
import { NetworkClass } from '../models/network.model';
import { CreateRecordNetworkDto } from '../models/create-record-network.dto';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { authHeadersSelector } from 'src/app/auth/store/auth.selectors';

@Injectable()
export class NetworkService {
  private networksApi = environment.networksApi;

  constructor(
    private readonly http: HttpClient,
    private readonly store: Store<AppState>
  ) {}

  getCollaboratorsNetworks(
    sidedrawerId: string,
    recordId?: string
  ): Observable<GetNetworksResponseDto[]> {
    const params = recordId ? { sidedrawerId, recordId } : { sidedrawerId };
    return this.http.get<GetNetworksResponseDto[]>(
      this.networksApi + 'network/collaborators',
      {
        headers: this.store.selectSignal(authHeadersSelector)(),
        params,
      }
    );
  }

  getInvitationsNetworks(
    sidedrawerId: string,
    recordId?: string
  ): Observable<GetNetworksResponseDto[]> {
    const params = recordId ? { sidedrawerId, recordId } : { sidedrawerId };
    return this.http.get<GetNetworksResponseDto[]>(
      this.networksApi + 'network/invitations',
      {
        headers: this.store.selectSignal(authHeadersSelector)(),
        params,
      }
    );
  }

  getTeamsNetworks(
    sidedrawerId: string,
    recordId?: string
  ): Observable<GetTeamsNetworkResponseDto[]> {
    const params = recordId ? { sidedrawerId, recordId } : { sidedrawerId };
    return this.http.get<GetTeamsNetworkResponseDto[]>(
      this.networksApi + 'network/teams',
      {
        headers: this.store.selectSignal(authHeadersSelector)(),
        params,
      }
    );
  }

  getUserTeams(): Observable<Team[]> {
    return this.http
      .get<Team[]>(this.networksApi + 'network/my-teams', {
        headers: this.store.selectSignal(authHeadersSelector)(),
      })
      .pipe(map(teams => teams?.filter(team => team?.active)));
  }

  deleteSideDrawerNetwork(sidedrawerId: string, networkId: string) {
    return this.http.delete(
      this.networksApi +
        `sidedrawer/sidedrawer-id/${sidedrawerId}/network/network-id/${networkId}`,
      { headers: this.store.selectSignal(authHeadersSelector)() }
    );
  }

  deleteRecordNetwork(
    sidedrawerId: string,
    recordId: string,
    networkId: string
  ) {
    return this.http.delete(
      this.networksApi +
        `sidedrawer/sidedrawer-id/${sidedrawerId}/record/record-id/${recordId}/network/network-id/${networkId}`,
      { headers: this.store.selectSignal(authHeadersSelector)() }
    );
  }

  createRecordNetwork(
    sidedrawerId: string,
    recordId: string,
    dto: CreateRecordNetworkDto | CreateSidedrawerNetworkDto
  ): Observable<{ id: string }> {
    if (!dto.relation) {
      dto.relation = Relation.getDefaultRelation();
    }
    return this.http.post<{ id: string }>(
      this.networksApi +
        `sidedrawer/sidedrawer-id/${sidedrawerId}/record/record-id/${recordId}/network`,
      { ...dto },
      { headers: this.store.selectSignal(authHeadersSelector)() }
    );
  }

  createRecordNetworkForQueue(
    sidedrawerId: string,
    recordId: string,
    dto: CreateRecordNetworkDto
  ): Observable<NetworkProgressResponse> {
    if (!dto.relation) {
      dto.relation = Relation.getDefaultRelation();
    }

    let url = '';
    if (recordId) {
      url =
        this.networksApi +
        `sidedrawer/sidedrawer-id/${sidedrawerId}/record/record-id/${recordId}/network`;
    }

    if (!recordId) {
      url =
        this.networksApi + `sidedrawer/sidedrawer-id/${sidedrawerId}/network`;
    }

    const fData = { ...dto };

    return this.http
      .request<NetworkClass>('POST', url, {
        body: fData,
        headers: {
          Authorization:
            this.store.selectSignal(authHeadersSelector)().Authorization,
        },
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        map(event => {
          switch (event.type) {
            case HttpEventType.UploadProgress: {
              return new NetworkProgressResponse(
                'progress',
                Math.round((100 * event.loaded) / event.total)
              );
            }
            case HttpEventType.Response: {
              const networkItemClass = new NetworkClass({
                ...event.body,
              });
              return new NetworkProgressResponse(
                'complete',
                100,
                {...networkItemClass, rawResponse: {...event.body}}
              );
            }
            default: {
              return new NetworkProgressResponse('progress', 0);
            }
          }
        })
      );
  }

  updateSideDrawerNetwork(
    sidedrawerId: string,
    networkId: string,
    dto: UpdateSideDrawerNetworkDto
  ): Observable<boolean> {
    if (!dto.relation) {
      dto.relation = Relation.getDefaultRelation();
    }
    return this.http
      .put(
        this.networksApi +
          `sidedrawer/sidedrawer-id/${sidedrawerId}/network/network-id/${networkId}`,
        { ...dto },
        { headers: this.store.selectSignal(authHeadersSelector)() }
      )
      .pipe(map(() => true));
  }

  updateRecordNetwork(
    sidedrawerId: string,
    recordId: string,
    networkId: string,
    dto: UpdateRecordNetworkDto
  ): Observable<boolean> {
    if (!dto.relation) {
      dto.relation = Relation.getDefaultRelation();
    }
    return this.http
      .put(
        this.networksApi +
          `sidedrawer/sidedrawer-id/${sidedrawerId}/record/record-id/${recordId}/network/network-id/${networkId}`,
        { ...dto },
        { headers: this.store.selectSignal(authHeadersSelector)() }
      )
      .pipe(map(() => true));
  }

  transferSideDrawerOwnership(
    sidedrawerId: string,
    dto: TransferOwnershipDto
  ): Observable<boolean> {
    if (!dto.relation) {
      dto.relation = Relation.getDefaultRelation();
    }
    return this.http
      .post(
        this.networksApi + `sidedrawer/sidedrawer-id/${sidedrawerId}/transfer`,
        dto,
        { headers: this.store.selectSignal(authHeadersSelector)() }
      )
      .pipe(map(() => true));
  }

  getNetworkGroups(sideDrawerId: string): Observable<NetworkGroup[]> {
    return this.http.get<NetworkGroup[]>(
      this.networksApi +
        `networks/sidedrawer/sidedrawer-id/${sideDrawerId}/groups`,
      { headers: this.store.selectSignal(authHeadersSelector)() }
    );
  }

  createNetworkGroups(
    sideDrawerId: string,
    dto: CreateNetworkGroupDto
  ): Observable<[]> {
    return this.http.post<[]>(
      this.networksApi +
        `networks/sidedrawer/sidedrawer-id/${sideDrawerId}/groups`,
      dto,
      { headers: this.store.selectSignal(authHeadersSelector)() }
    );
  }

  deleteNetworkGroups(
    sideDrawerId: string,
    networkId: string
  ): Observable<unknown> {
    return this.http.delete<unknown>(
      this.networksApi +
        `networks/sidedrawer/sidedrawer-id/${sideDrawerId}/network/network-id/${networkId}/groups`,
      { headers: this.store.selectSignal(authHeadersSelector)() }
    );
  }
}
