import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DriveItem } from '../../models/drive-item.model';
import { environment } from '../../../../environments/environment';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { SdSvgA11yModule } from '../../../shared/sd-svg-a11y/sd-svg-a11y.module';
import { MatIconModule } from '@angular/material/icon';
import { DictionaryPipeModule } from '../../../dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-sync-from-cloud-item',
  templateUrl: './sync-from-cloud-item.component.html',
  styleUrls: ['./sync-from-cloud-item.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    SdSvgA11yModule,
    NgIf,
    MatButtonModule,
    MatIconModule,
    DictionaryPipeModule,
    AsyncPipe,
  ],
})
export class SyncFromCloudItemComponent {
  @Input() driveItem: DriveItem;
  @Input() selected: boolean;
  @Input() disabled: boolean;
  @Output() folderClicked = new EventEmitter<boolean>();
  @Output() checkboxClicked = new EventEmitter<boolean>();
  cdn = environment.cdn;
  primaryColor = getComputedStyle(document.documentElement).getPropertyValue(
    '--primaryColor'
  );
}
