import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { SdInputA11yTemplates } from '../../sd-input-a11y/models/sd-input-a11y-templates.enum';
import { SdAccessibilitySetting } from '../../../core/models/sd-accessibility-model';
import { AccessibilityHelper } from '../../../core/helpers/accessibility.helper';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-sd-password-a11y',
  templateUrl: './sd-password-a11y.component.html',
  styleUrls: ['./sd-password-a11y.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SdPasswordA11yComponent implements OnInit, OnChanges {
  @Input() error: string;
  @Input() controller: UntypedFormControl | AbstractControl | any;
  @Input() placeholder: string;
  @Input() suffixText: string;
  @Input() suffix: string;
  @Input() suffixIsClickable: boolean;
  @Input() suffixIconHasPrimaryColor: boolean;
  @Input() focused: boolean;
  @Input() tooltip: string;
  @Input() readonly = false;
  @Output() suffixClicked = new EventEmitter<string>();
  @Input() template = SdInputA11yTemplates.inputDefault;
  sdInputA11yTemplates = SdInputA11yTemplates;
  displaySuffix = false;
  @ViewChild('inputElement', { static: true }) inputElement: ElementRef;
  type = 'password';
  suffixPassword = 'visibility';
  sdAccessibility: SdAccessibilitySetting;
  cdn = environment.cdn;

  @Input() set setSdAccessibility(value: SdAccessibilitySetting) {
    this.sdAccessibility = AccessibilityHelper.setDefaultAccessibility(value);
  }

  ngOnInit(): void {
    this.displaySuffix = !!this.suffix && this.suffix.length > 0;
    if (this.focused) {
      this.inputElement.nativeElement.focus();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.focused && changes.focused.currentValue) {
      this.inputElement.nativeElement.focus();
    }
  }

  onSuffix(): void {
    if (this.suffixIsClickable) {
      this.suffixClicked.emit(this.controller?.value ?? '');
    }
  }

  onTogglePassword() {
    if (this.type === 'password') {
      this.type = 'text';
      this.suffixPassword = 'visibility_off';
    } else {
      this.type = 'password';
      this.suffixPassword = 'visibility';
    }
  }
}
