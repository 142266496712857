import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { featureName, zipReducer } from './store/zip.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ZipEffects } from './store/zip.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(featureName, zipReducer),
    EffectsModule.forFeature([ZipEffects]),
  ],
})
export class ZipModule {}
