import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { SidedrawerService } from '../services/sidedrawer.service';
import {
  AddOwnerToSideDrawerError,
  AddOwnerToSideDrawerRequested,
  AddOwnerToSideDrawerSuccess,
  DeleteSideDrawerError,
  DeleteSideDrawerSuccess,
  SetActiveSideDrawer,
  SidedrawerActionsTypes,
  SideDrawerCopyLinkRequested,
  SideDrawerCreateRequested,
  SideDrawerCreateSpinnerChange,
  SideDrawerCreateSuccess,
  SideDrawerDeleteRequested,
  SideDrawerDeleteSuccess,
  SideDrawerHomeOnBackgroundLoaded,
  SideDrawerHomeOnBackgroundRequested,
  SideDrawerHomeRequested,
  SideDrawerLoaded,
  SideDrawerRequested,
  SideDrawerSpinnerChange,
  SideDrawerTransferRequested,
  SideDrawerTransferSpinnerChange,
  SideDrawerTransferSuccess,
  SideDrawerUpdatedChange,
  SideDrawerUpdateError,
  SideDrawerUpdateRequested,
  SideDrawerUpdateSuccess,
  UpdateActiveSideDrawer,
} from './sidedrawer.actions';
import {
  catchError,
  exhaustMap,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { CoreRoutes, getCoreRoute } from '../../core/routes/core.routes';
import { SidedrawerRoutesEnum } from '../routes/sidedrawer-routes.enum';
import { HomeService } from '../../home/services/home.service';
import { RoutesHelper } from '../../core/helpers/routes.helper';
import { select, Store } from '@ngrx/store';
import { AppState } from 'src/app/reducers';
import { getSideDrawerRolesOrdinalToSort } from 'src/app/core/roles/sidedrawer.roles';
import { SideDrawerNetwork } from '../../sidedrawer-networks/models/side-drawer-network.model';
import { ISortable, UtilsHelper } from 'src/app/core/helpers/utils.helper';
import {
  SideDrawerNetworkClearInformation,
  SideDrawerNetworkUpdated,
} from '../../sidedrawer-networks/store/side-drawer-network-list.actions';
import { SignUpSideDrawerCreated } from 'src/app/start-flow/store/start-flow.actions';
import { ErrorLoaded } from 'src/app/core/store/core.actions';
import { authUserDataBaseRegionSelector } from 'src/app/auth/store/auth.selectors';
import { currentBrandingSelector } from 'src/app/core/store/core.selectors';
import { forkJoin, from, of } from 'rxjs';
import { accountSelector } from 'src/app/account/store/account.selector';
import { SideDrawer } from '../models/side-drawer.model';
import {
  dictionarySelector,
  localeDefaultSelector,
} from '../../dictionary/store/dictionary.selectors';
import { Locale } from '../../dictionary/models/locale.model';
import { LandingRoutes } from '../../landing/routes/landing.routes';
import {
  activeSideDrawerSelector,
  basePathSelector,
} from './sidedrawer.selector';
import { NetworkService } from '../../networks/services/network.service';
import { RecordsInfoClean } from '../../records/store/records-list.actions';
import {
  sideDrawerNetworkByIdSelector,
  sideDrawerNetworkListSelector,
} from 'src/app/sidedrawer-networks/store/side-drawer-network-list.selectors';
import { SideDrawerNetworksInfiniteListRequested } from 'src/app/sidedrawer-networks/store/side-drawer-networks-requests-infinite-list.actions';
import { ForbiddenSidedrawerErrorDialogA11yComponent } from '../shared/forbidden-sidedrawer-error-dialog/forbidden-sidedrawer-error-dialog.component';
import { TimelineActions } from '../../timeline/store/timeline.actions';
import { SearchRecordsListReset } from 'src/app/search/store/search-record-list.actions';
import { SearchSideDrawerListReset } from 'src/app/search/store/search-sidedrawer-list.actions';
import { SearchFileListReset } from 'src/app/search/store/search-file-list.actions';
import { SdSnackBarDisplaySnackBar } from '../../shared/sd-snack-bar/store/sd-snack-bar.actions';
import { MatDialog } from '@angular/material/dialog';
import {
  FilingCabinetActions,
  filingCabinetSideDrawerByIdSelector,
  filingCabinetSideDrawersWithDataSelector,
} from '../../filing-cabinet/store/filing-cabinet.store';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class SidedrawerEffects {
  sideDrawerHomeRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<SideDrawerHomeRequested>(
        SidedrawerActionsTypes.SideDrawerHomeRequested
      ),
      mergeMap(action =>
        this.sidedrawerService
          .getSideDrawerHome(action.payload.sdId, action.payload.localeDefault)
          .pipe(
            tap({
              error: () => {
                if (this.router.url === '/core/home') {
                  const queryParams = RoutesHelper.getParams(false);
                  this.router.navigate([getCoreRoute(CoreRoutes.error)], {
                    queryParams,
                  });
                } else {
                  this.router.navigate([this.homeService.getHomeRoute()]);
                }
              },
            })
          )
      )
    );
  });

  sideDrawerHomeOnTheBackgroundRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<SideDrawerHomeOnBackgroundRequested>(
        SidedrawerActionsTypes.SideDrawerHomeOnBackgroundRequested
      ),
      mergeMap(action =>
        this.sidedrawerService
          .getSideDrawerHomeOnTheBackground(
            action.payload.sdId,
            action.payload.localeDefault
          )
          .pipe(
            map(sd => {
              return new SideDrawerHomeOnBackgroundLoaded({ data: sd });
            }),
            catchError((httpError: HttpErrorResponse) =>
              of(new ErrorLoaded({ httpError, display404: true }))
            )
          )
      )
    );
  });

  sideDrawerHomeOnBackgroundLoaded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SideDrawerHomeOnBackgroundLoaded>(
          SidedrawerActionsTypes.SideDrawerHomeOnBackgroundLoaded
        ),
        switchMap(action =>
          forkJoin([
            this.store.select(activeSideDrawerSelector).pipe(take(1)),
            this.store
              .select(
                filingCabinetSideDrawerByIdSelector({
                  sideDrawerId: action.payload.data.id,
                })
              )
              .pipe(take(1)),
            of(action.payload.data),
          ])
        ),
        map(([activeSideDrawer, fcSd, sdUpdated]) => {
          if (fcSd) {
            this.store.dispatch(
              FilingCabinetActions.filingCabinetSideDrawerHomeLoaded({
                id: sdUpdated.id,
                data: sdUpdated,
              })
            );
          }
          if (activeSideDrawer.id === sdUpdated.id) {
            this.store.dispatch(
              new UpdateActiveSideDrawer({ data: sdUpdated })
            );
          }
        })
      ),
    { dispatch: false }
  );

  afterDeleteSideDrawerSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<DeleteSideDrawerSuccess>(
        SidedrawerActionsTypes.DeleteSideDrawerSuccess
      ),
      mergeMap(({ payload }) => {
        const { data, localeDefault } = payload;
        if (data.length) {
          const sortedMyOtherSideDrawers = data.map<
            ISortable<SideDrawerNetwork>
          >(x => ({
            order: getSideDrawerRolesOrdinalToSort(x.sdRole),
            data: x,
          }));
          UtilsHelper.sort<ISortable<SideDrawerNetwork>>(
            sortedMyOtherSideDrawers,
            'order'
          );
          return this.sidedrawerService
            .getSideDrawerHome(
              sortedMyOtherSideDrawers[0].data.id,
              localeDefault
            )
            .pipe(
              tap(action => {
                this.store.dispatch(action);
                const url =
                  this.homeService.getHomeRoute() + SidedrawerRoutesEnum.root;
                this.router.navigate([url]);
                this.store.dispatch(new SideDrawerNetworkClearInformation());
                this.store.dispatch(
                  new SideDrawerNetworksInfiniteListRequested()
                );
              })
            );
        }
        this.store.dispatch(new SetActiveSideDrawer({ data: null }));
        this.router.navigate([
          `/${CoreRoutes.root}/${SidedrawerRoutesEnum.root}/${SidedrawerRoutesEnum.create}`,
        ]);
        this.store.dispatch(new SideDrawerNetworkClearInformation());
        this.store.dispatch(new SideDrawerNetworksInfiniteListRequested());
      })
    );
  });

  SideDrawerCreateRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<SideDrawerCreateRequested>(
        SidedrawerActionsTypes.SideDrawerCreateRequested
      ),
      tap(() =>
        this.store.dispatch(
          new SideDrawerCreateSpinnerChange({ creatingSideDrawer: true })
        )
      ),
      mergeMap(action =>
        forkJoin([
          this.store.pipe(select(authUserDataBaseRegionSelector), take(1)),
          this.store.pipe(select(currentBrandingSelector), take(1)),
          this.store.pipe(select(accountSelector), take(1)),
        ]).pipe(
          mergeMap(([region, currentBranding, account]) => {
            const sideDrawer: SideDrawer = {
              ...action.payload?.sideDrawer,
              firstName: account?.firstName,
              lastName: account?.lastName,
              brandCode: currentBranding?.brandCode,
              dataBaseRegion: region,
            };
            return this.sidedrawerService.createSidedrawer(sideDrawer).pipe(
              map(response => {
                this.store.dispatch(
                  new SignUpSideDrawerCreated({ sidedrawerId: response?.id })
                );
                return new SideDrawerCreateSuccess();
              }),
              catchError(error => {
                this.store.dispatch(
                  new SideDrawerCreateSpinnerChange({
                    creatingSideDrawer: false,
                  })
                );
                return of(new ErrorLoaded({ httpError: { ...error } }));
              })
            );
          })
        )
      )
    );
  });

  SideDrawerRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<SideDrawerRequested>(SidedrawerActionsTypes.SideDrawerRequested),
      tap(() =>
        this.store.dispatch(new SideDrawerSpinnerChange({ state: true }))
      ),
      switchMap(({ payload }) =>
        forkJoin([
          of(payload.sdId),
          this.store.select(localeDefaultSelector).pipe(take(1)),
          this.store.select(activeSideDrawerSelector).pipe(take(1)),
          this.store
            .select(filingCabinetSideDrawersWithDataSelector)
            .pipe(take(1)),
        ])
      ),
      mergeMap(([sdId, locale, activeSd, fcSds]) => {
        localStorage.setItem('lastSidedrawerId', sdId);
        if (sdId === activeSd?.id) {
          return of(new SideDrawerSpinnerChange({ state: false }));
        }
        if (fcSds.some(sd => sd.sidedrawer === sdId)) {
          this.store.dispatch(new SideDrawerSpinnerChange({ state: false }));
          const url = RoutesHelper.getBasePath(sdId) + LandingRoutes.root;
          this.router.navigate([url]);
          return of(
            new SetActiveSideDrawer({
              data: fcSds.find(sd => sd.sidedrawer === sdId)?.data,
            })
          );
        }
        return this.sidedrawerService
          .getSideDrawerHome(sdId, Locale.getLocaleId(locale))
          .pipe(
            map(response => {
              //TODO refactor service for return SD Model
              this.store.dispatch(
                new SetActiveSideDrawer({
                  data: response.payload.data,
                })
              );
              const url = this.homeService.getHomeRoute() + LandingRoutes.root;
              this.router.navigate([url]);
              return new SideDrawerLoaded();
            }),
            tap(() => {
              this.store.dispatch(new SearchRecordsListReset());
              this.store.dispatch(new SearchSideDrawerListReset());
              this.store.dispatch(new SearchFileListReset());
            }),
            catchError(error => {
              this.store.dispatch(
                new SideDrawerSpinnerChange({ state: false })
              );
              if (error.error.statusCode === 403) {
                this.dialog
                  .open(ForbiddenSidedrawerErrorDialogA11yComponent, {
                    autoFocus: false,
                  })
                  .afterClosed()
                  .subscribe();
              } else {
                this.store.dispatch(
                  new ErrorLoaded({ httpError: { ...error } })
                );
              }
              return of();
            })
          );
      })
    );
  });

  SideDrawerTransferRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<SideDrawerTransferRequested>(
        SidedrawerActionsTypes.SideDrawerTransferRequested
      ),
      tap(() =>
        this.store.dispatch(
          new SideDrawerTransferSpinnerChange({ transferringSideDrawer: true })
        )
      ),
      switchMap(action =>
        forkJoin([
          this.store.select(activeSideDrawerSelector).pipe(take(1)),
          of(action.payload.sideDrawer).pipe(take(1)),
        ])
      ),
      exhaustMap(([activeSideDrawer, sideDrawer]) =>
        this.networkService
          .transferSideDrawerOwnership(activeSideDrawer.id, sideDrawer)
          .pipe(
            map(() => {
              return new SideDrawerTransferSuccess({
                sdId: activeSideDrawer.id,
              });
            }),
            catchError(error => {
              this.store.dispatch(
                new SideDrawerTransferSpinnerChange({
                  transferringSideDrawer: false,
                })
              );
              return of(new ErrorLoaded({ httpError: { ...error } }));
            })
          )
      )
    );
  });

  AfterSideDrawerTransferRequested$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<SideDrawerTransferSuccess>(
          SidedrawerActionsTypes.SideDrawerTransferSuccess
        ),
        mergeMap(action =>
          this.store.select(localeDefaultSelector).pipe(
            mergeMap(locale =>
              this.sidedrawerService
                .getSideDrawerHome(
                  action?.payload?.sdId,
                  Locale.getLocaleId(locale)
                )
                .pipe(
                  map((serviceAction: SetActiveSideDrawer) => {
                    this.store.dispatch(serviceAction);
                    this.store.dispatch(
                      new SideDrawerNetworkClearInformation()
                    );
                    this.store.dispatch(
                      new SideDrawerNetworksInfiniteListRequested()
                    );

                    this.store.dispatch(TimelineActions.clearEvents());
                    this.store.dispatch(new RecordsInfoClean());
                    const url =
                      this.homeService.getHomeRoute() +
                      SidedrawerRoutesEnum.root;
                    this.router.navigate([url]);
                  }),
                  catchError(error => {
                    if (error.error.statusCode === 403) {
                      this.dialog
                        .open(ForbiddenSidedrawerErrorDialogA11yComponent, {
                          autoFocus: false,
                        })
                        .afterClosed()
                        .subscribe();
                    } else {
                      return of(new ErrorLoaded({ httpError: { ...error } }));
                    }
                  }),
                  tap(() =>
                    this.store.dispatch(
                      new SideDrawerTransferSpinnerChange({
                        transferringSideDrawer: false,
                      })
                    )
                  )
                )
            )
          )
        )
      );
    },
    { dispatch: false }
  );

  sideDrawerUpdateRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<SideDrawerUpdateRequested>(
        SidedrawerActionsTypes.SideDrawerUpdateRequested
      ),
      tap(() =>
        this.store.dispatch(new SideDrawerUpdatedChange({ updating: true }))
      ),
      mergeMap(action =>
        this.store.select(localeDefaultSelector).pipe(
          mergeMap(locale =>
            this.sidedrawerService
              .updateSideDrawer(
                action.payload.sideDrawer,
                Locale.getLocaleId(locale),
                action.payload.sdNewName,
                action.payload.sdActiveEmail,
                action.payload.sdNotificationActive
              )
              .pipe(
                map(() => {
                  this.store
                    .pipe(
                      select(
                        sideDrawerNetworkByIdSelector({
                          id: action.payload.sideDrawer.id,
                        })
                      ),
                      take(1),
                      tap(sdNetwork => {
                        this.store.dispatch(
                          new SideDrawerNetworkUpdated({
                            data: {
                              id: action.payload.sideDrawer.id,
                              changes: {
                                ...sdNetwork,
                                name: action.payload.sdNewName,
                              },
                            },
                          })
                        );
                      })
                    )
                    .subscribe();

                  return new SideDrawerUpdateSuccess();
                }),
                catchError(error => {
                  this.store.dispatch(new SideDrawerUpdateError({ ...error }));
                  return of(new ErrorLoaded({ httpError: { ...error } }));
                }),
                tap(() =>
                  this.store.dispatch(
                    new SideDrawerUpdatedChange({ updating: false })
                  )
                )
              )
          )
        )
      )
    );
  });

  sideDrawerDeleteRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<SideDrawerDeleteRequested>(
        SidedrawerActionsTypes.SideDrawerDeleteRequested
      ),
      tap(() =>
        this.store.dispatch(new SideDrawerSpinnerChange({ state: true }))
      ),
      mergeMap(action =>
        this.store.select(localeDefaultSelector).pipe(
          mergeMap(locale =>
            this.sidedrawerService
              .deleteSidedrawer(action.payload.sideDrawer?.id)
              .pipe(
                map(() => {
                  this.store
                    .select(sideDrawerNetworkListSelector)
                    .pipe(
                      take(1),
                      tap(myOtherSideDrawers => {
                        this.store.dispatch(
                          new DeleteSideDrawerSuccess({
                            data: myOtherSideDrawers?.filter(
                              x => x.id !== action.payload?.sideDrawer?.id
                            ),
                            localeDefault: Locale.getLocaleId(locale),
                          })
                        );
                      })
                    )
                    .subscribe();
                  return new SideDrawerDeleteSuccess();
                }),
                catchError(error => {
                  new ErrorLoaded({ httpError: { ...error } });
                  return of(new DeleteSideDrawerError());
                }),
                tap(() =>
                  this.store.dispatch(
                    new SideDrawerSpinnerChange({ state: false })
                  )
                )
              )
          )
        )
      )
    );
  });

  addOwnerToSidedrawerRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<AddOwnerToSideDrawerRequested>(
        SidedrawerActionsTypes.AddOwnerToSideDrawerRequested
      ),
      tap(() =>
        this.store.dispatch(new SideDrawerSpinnerChange({ state: true }))
      ),
      mergeMap(action =>
        this.store.select(localeDefaultSelector).pipe(
          mergeMap(locale =>
            this.sidedrawerService
              .addOwnerToSideDrawer(
                action.payload.sideDrawerId,
                action.payload.sideDrawerNetwork
              )
              .pipe(
                map(() => {
                  this.store.dispatch(
                    new SideDrawerSpinnerChange({ state: true })
                  );
                  this.store.dispatch(
                    new SideDrawerHomeRequested({
                      sdId: action.payload.sideDrawerId,
                      localeDefault: Locale.getLocaleId(locale),
                    })
                  );
                  return new AddOwnerToSideDrawerSuccess();
                }),
                catchError(error => {
                  new ErrorLoaded({ httpError: { ...error } });
                  this.store.dispatch(
                    new SideDrawerSpinnerChange({ state: false })
                  );
                  return of(new AddOwnerToSideDrawerError());
                })
              )
          )
        )
      )
    );
  });

  sideDrawerCopyLinkRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SideDrawerCopyLinkRequested>(
        SidedrawerActionsTypes.SideDrawerCopyLinkRequested
      ),
      switchMap(() => this.store.pipe(select(basePathSelector), take(1))),
      map(basePath => window.location.origin + basePath + LandingRoutes.root),
      switchMap(url => from(navigator.clipboard.writeText(url))),
      switchMap(() => this.store.pipe(select(dictionarySelector), take(1))),
      map(
        dictionary =>
          new SdSnackBarDisplaySnackBar({
            message: dictionary?.globalparams_copiedtoclipboard,
          })
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly sidedrawerService: SidedrawerService,
    private readonly homeService: HomeService,
    private readonly networkService: NetworkService,
    private readonly router: Router,
    private readonly store: Store<AppState>,
    private readonly dialog: MatDialog
  ) {}
}
