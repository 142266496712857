import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InactivityTimeDialogComponent } from './components/inactivity-time-dialog/inactivity-time-dialog.component';
import { DictionaryPipeModule } from '../../../dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { SdDialogTemplateA11yModule } from 'src/app/shared/templates/dialog-template-a11y/sd-dialog-template-a11y.module';
import { SdFlatButtonA11yModule } from 'src/app/shared/sd-flat-button-a11y/sd-flat-button-a11y.module';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [InactivityTimeDialogComponent],
  exports: [InactivityTimeDialogComponent],
  imports: [
    CommonModule,
    DictionaryPipeModule,
    MatDialogModule,
    SdDialogTemplateA11yModule,
    SdFlatButtonA11yModule,
  ],
})
export class InactivityTimeDialogModule {}
