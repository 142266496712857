import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { SpecificField } from '../models/specific-field.model';
import {
  SpecificFieldsActions,
  SpecificFieldsActionsTypes,
} from './specific-fields.actions';

export interface SpecificFieldsState extends EntityState<SpecificField> {
  gettingSpecificFields: boolean;
}

export const specificFieldsAdapter: EntityAdapter<SpecificField> =
  createEntityAdapter<SpecificField>({
    selectId: specificField => specificField.fieldName,
  });

export const initialSpecificFieldsState: SpecificFieldsState =
  specificFieldsAdapter.getInitialState({
    gettingSpecificFields: false,
  });

export function specificFieldsReducer(
  state: SpecificFieldsState = initialSpecificFieldsState,
  action: SpecificFieldsActions
): SpecificFieldsState {
  switch (action.type) {
    case SpecificFieldsActionsTypes.GettingRecordSubTypeSpecificFields:
      return {
        ...state,
        gettingSpecificFields: action.payload.state,
      };
    case SpecificFieldsActionsTypes.RecordSubtypeSpecificFieldsLoaded:
      return specificFieldsAdapter.setAll(action.payload.specificFields, state);
    case SpecificFieldsActionsTypes.ClearRecordSubTypeSpecificFields:
      return specificFieldsAdapter.removeAll(state);
    default:
      return state;
  }
}

export const { selectAll } = specificFieldsAdapter.getSelectors();
