import {
  createActionGroup,
  createFeature,
  createReducer,
  createSelector,
  emptyProps,
  on,
  props,
} from '@ngrx/store';
import { Plan } from '../../plans/models/plan.model';
import { HttpErrorResponse } from '@angular/common/http';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { PaginatorApiResponse } from '../../core/models/paginator-api-response.dto';
import { activeSideDrawerSelector } from '../../sidedrawer/store/sidedrawer.selector';
import { allPlanRequestedListWithOutFilterSelector } from '../../plans/store/plans-list.selectors';

export const TenantPlansActions = createActionGroup({
  source: 'tenant-plans',
  events: {
    Requested: emptyProps(),
    Loaded: props<{ response: PaginatorApiResponse<Plan> }>(),
    Clear: emptyProps(),
    Error: props<{ httpError: HttpErrorResponse }>(),
  },
});

interface TenantPlansState extends EntityState<Plan> {
  gettingTenantPlans: boolean;
  httpError: HttpErrorResponse;
  hasMore: boolean;
  nextPage: string;
  previousPage: string;
  totalCount: number;
}

const adapter = createEntityAdapter<Plan>({
  selectId: (plan: Plan) => plan._id,
});

const initialState: TenantPlansState = adapter.getInitialState({
  gettingTenantPlans: false,
  httpError: null,
  hasMore: false,
  nextPage: null,
  previousPage: null,
  totalCount: null,
});

export const tenantPlansFeature = createFeature({
  name: 'tenant-plans',
  reducer: createReducer(
    initialState,
    on(TenantPlansActions.requested, state => ({
      ...state,
      gettingTenantPlans: true,
    })),
    on(TenantPlansActions.loaded, (state, { response }) => ({
      ...adapter.upsertMany(response.data, state),
      gettingTenantPlans: false,
      hasMore: response.hasMore,
      nextPage: response.nextPage,
      previousPage: response.previousPage,
      totalCount: response.totalCount,
    })),
    on(TenantPlansActions.error, (state, { httpError }) => ({
      ...state,
      gettingTenantPlans: false,
      httpError,
    })),
    on(TenantPlansActions.clear, () => initialState)
  ),
});

const { selectAll } = adapter.getSelectors();

export const tenantPlanStateSelector =
  tenantPlansFeature['selectTenant-plansState'];
export const tenantPlanListSelector = createSelector(
  tenantPlanStateSelector,
  selectAll
);
export const tenantPlanListFilteredByActiveSideDrawerTypeSelector =
  createSelector(
    tenantPlanListSelector,
    activeSideDrawerSelector,
    (plans, sideDrawer) =>
      sideDrawer.type === 'other'
        ? plans.filter(plan => plan.typeOtherName === sideDrawer.typeOtherName)
        : plans.filter(plan => plan.type === sideDrawer.type)
  );

export const tenantPlanListNotSelectedSelector = createSelector(
  tenantPlanListFilteredByActiveSideDrawerTypeSelector,
  allPlanRequestedListWithOutFilterSelector,
  (plans, infoRequests) =>
    plans.filter(
      plan => !infoRequests.some(request => request.plan.id === plan._id)
    )
);
export const gettingTenantPlansSelector =
  tenantPlansFeature.selectGettingTenantPlans;
export const tenantPlansHasMoreSelector = tenantPlansFeature.selectHasMore;
export const tenantPlansNextPageSelector = tenantPlansFeature.selectNextPage;
