import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { SdAccessibilitySetting } from '../../core/models/sd-accessibility-model';
import { AccessibilityHelper } from '../../core/helpers/accessibility.helper';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { SdSvgA11yModule } from '../sd-svg-a11y/sd-svg-a11y.module';
import { SdProgressSpinnerA11yModule } from '../sd-progress-spinner-a11y/sd-progress-spinner-a11y/sd-progress-spinner-a11y.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ReactiveFormsModule } from '@angular/forms';
import { DictionaryPipeModule } from 'src/app/dictionary/pipes/dictionary-pipe/dictionary-pipe.module';

@Component({
  selector: 'app-sd-upload-button-a11y',
  standalone: true,
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatTooltipModule,
    SdSvgA11yModule,
    SdProgressSpinnerA11yModule,
    ReactiveFormsModule,
    DictionaryPipeModule,
  ],
  templateUrl: './sd-upload-button-a11y.component.html',
  styleUrls: ['./sd-upload-button-a11y.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SdUploadButtonA11yComponent {
  @Input() buttonText: string;
  @Input() tooltip: string;
  @Input() icon: string;
  @Input() iconAlt: string;
  @Input() disabled = false;
  @Input() loading = false;
  @Input() withIcon = true;
  @Output() filesSelected = new EventEmitter<FileList>();
  sdAccessibility: SdAccessibilitySetting;
  focus = false;

  @Input() set uploadEvent(value: { upload: boolean }) {
    if (value?.upload === true) {
      this.fileInput.nativeElement.click();
    }
  }

  @ViewChild('fileInput') fileInput: ElementRef;

  @Input() set setSdAccessibility(value: SdAccessibilitySetting) {
    this.sdAccessibility = AccessibilityHelper.setDefaultAccessibility(value);
  }

  protected emitFiles(fileList: FileList): void {
    this.filesSelected.emit({ ...fileList, length: fileList.length });
    (
      document.querySelector('.sd-upload-button-a11y-input') as HTMLInputElement
    ).value = null;
  }
}
