import {
  createActionGroup,
  createFeature,
  createReducer,
  createSelector,
  emptyProps,
  on,
  props,
} from '@ngrx/store';
import { PaginatorApiResponse } from '../../core/models/paginator-api-response.dto';
import { HttpErrorResponse } from '@angular/common/http';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { NetworkHelper } from '../helpers/network.helper';
import { NetworkV2 } from '../models/network.v2.type';
import { NetworkResourceOptions } from '../models/network-resource-options.type';
import { NetworksSelectorFilter } from '../models/networks-selector-filter.type';

export const NetworkListActions = createActionGroup({
  source: 'network-list',
  events: {
    Requested: props<{
      sideDrawerId: string;
      options?: NetworkResourceOptions;
      allPageRequested?: boolean;
      clearRequests?: boolean;
    }>(),
    MultipleOperationsRequested: props<{
      operations: {
        sideDrawerId: string;
        options?: NetworkResourceOptions;
        allPageRequested?: boolean;
      }[];
      clearRequests?: boolean;
    }>(),
    Loaded: props<{
      response: PaginatorApiResponse<NetworkV2>;
      sideDrawerId: string;
      options?: NetworkResourceOptions;
    }>(),
    Update: props<{ network: NetworkV2 }>(),
    Delete: props<{
      networkId?: string;
      sideDrawerId?: string;
      recordId?: string;
      teamId?: string;
      email?: string;
    }>(),
    Clear: emptyProps(),
    NextPageRequested: emptyProps(),
    Error: props<{ sideDrawerId: string; httpError: HttpErrorResponse }>(),
  },
});

interface NetworkListState extends EntityState<NetworkV2> {
  requests: Map<
    string,
    {
      sideDrawerId?: string;
      gettingNetworks?: boolean;
      hasMore?: boolean;
      nextPage?: string;
      previousPage?: string;
      totalCount?: number;
      httpError?: HttpErrorResponse;
      options?: NetworkResourceOptions;
      allPageRequested?: boolean;
    }
  >;
}

const adapter = createEntityAdapter<NetworkV2>({
  selectId: (network: NetworkV2) => network.id,
});

const initialState: NetworkListState = adapter.getInitialState({
  requests: new Map(),
});

export const networkListFeature = createFeature({
  name: 'network-list',
  reducer: createReducer(
    initialState,
    on(
      NetworkListActions.requested,
      (state, { sideDrawerId, options, allPageRequested, clearRequests }) => {
        const aux = new Map(state.requests);
        const request = clearRequests ? {} : aux.get(sideDrawerId) ?? {};
        aux.set(sideDrawerId, {
          ...request,
          sideDrawerId,
          gettingNetworks: true,
          options,
          httpError: null,
          allPageRequested: allPageRequested ?? false,
        });
        return {
          ...state,
          requests: aux,
        };
      }
    ),
    on(
      NetworkListActions.multipleOperationsRequested,
      (state, { operations, clearRequests }) => {
        const aux = new Map(state.requests);
        operations.forEach(operation => {
          const { sideDrawerId, options, allPageRequested } = operation;
          const request = clearRequests ? {} : aux.get(sideDrawerId) ?? {};
          aux.set(sideDrawerId, {
            ...request,
            sideDrawerId,
            gettingNetworks: true,
            options,
            httpError: null,
            allPageRequested: allPageRequested ?? false,
          });
        });
        return {
          ...state,
          requests: aux,
        };
      }
    ),
    on(
      NetworkListActions.loaded,
      (state, { response, sideDrawerId, options }) => {
        const aux = new Map(state.requests);
        aux.set(sideDrawerId, {
          gettingNetworks: false,
          sideDrawerId,
          hasMore: response.hasMore,
          nextPage: response.nextPage,
          previousPage: response.previousPage,
          totalCount: response.totalCount,
          options,
          httpError: null,
          allPageRequested: aux.get(sideDrawerId)?.allPageRequested ?? false,
        });
        return {
          ...adapter.upsertMany(
            NetworkHelper.filterInvalidNetworkV2Items(response.data, true),
            state
          ),
          requests: aux,
        };
      }
    ),
    on(NetworkListActions.error, (state, { httpError, sideDrawerId }) => {
      const aux = new Map(state.requests);
      const request = aux.get(sideDrawerId) ?? {};
      aux.set(sideDrawerId, {
        ...request,
        sideDrawerId,
        gettingNetworks: false,
        hasMore: false,
        nextPage: null,
        httpError,
      });
      return {
        ...state,
        requests: aux,
      };
    }),
    on(NetworkListActions.update, (state, { network }) => ({
      ...state,
      ...adapter.upsertOne(network, state),
    })),
    on(
      NetworkListActions.delete,
      (state, { networkId, sideDrawerId, recordId, teamId, email }) => {
        if (networkId) {
          return {
            ...state,
            ...adapter.removeOne(networkId, state),
          };
        }
        if (sideDrawerId) {
          return {
            ...state,
            ...adapter.removeMany(
              Object.values(state.entities)
                ?.filter(network => network.sidedrawer === sideDrawerId)
                .map(networks => networks.id),
              state
            ),
          };
        }
        if (recordId) {
          return {
            ...state,
            ...adapter.removeMany(
              Object.values(state.entities)
                ?.filter(network => network.record === recordId)
                .map(networks => networks.id),
              state
            ),
          };
        }
        if (teamId) {
          return {
            ...state,
            ...adapter.removeMany(
              Object.values(state.entities)
                ?.filter(network => network.teamNetwork?.teamId === teamId)
                .map(networks => networks.id),
              state
            ),
          };
        }
        if (email) {
          return {
            ...state,
            ...adapter.removeMany(
              Object.values(state.entities)
                ?.filter(network => network.contributor?.email === email)
                .map(networks => networks.id),
              state
            ),
          };
        }
        return state;
      }
    ),
    on(NetworkListActions.clear, () => initialState)
  ),
});

const { selectAll, selectEntities } = adapter.getSelectors();

export const networkListStateSelector =
  networkListFeature['selectNetwork-listState'];
export const networkListSelectAllSelector = createSelector(
  networkListStateSelector,
  selectAll
);
export const networkListEntitiesSelector = createSelector(
  networkListStateSelector,
  selectEntities
);
export const networkListRequestsSelector = networkListFeature.selectRequests;
export const networkListSelector = (payload?: NetworksSelectorFilter) =>
  createSelector(networkListSelectAllSelector, networks =>
    NetworkHelper.networksSelectorFilter(networks, payload)
  );
export const gettingNetworkListSelector = createSelector(
  networkListRequestsSelector,
  requests =>
    Array.from(requests.values())?.some(request => request.gettingNetworks)
);
export const networkListHasMoreSelector = createSelector(
  networkListRequestsSelector,
  requests => Array.from(requests.values())?.some(request => request.hasMore)
);
export const networkListNetworkByNetworkIdSelector = (payload: {
  networkId: string;
}) =>
  createSelector(
    networkListEntitiesSelector,
    entities => entities[`${payload.networkId}`]
  );
