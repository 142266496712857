import { Network } from '../models/network.model';
import { NetworkType } from '../models/network-type.model';
import { NetworkDto } from '../models/network.dto';
import { TeamNetwork } from '../models/team-network.model';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { Observable, take, tap } from 'rxjs';
import { Relation } from '../../records/models/relation.model';
import { UtilsHelper } from '../../core/helpers/utils.helper';
import {
  getRecordsRolesOrdinalToSort,
  RecordsRoles,
} from '../../core/roles/records.roles';
import {
  getSideDrawerRolesOrdinalToSort,
  SidedrawerRoles,
} from '../../core/roles/sidedrawer.roles';
import { ContributorType } from '../models/contributor-type.model';
import { NetworkService } from '../services/network.service';
import { Contributor } from '../../records/models/contributor.model';
import { SdQueueItem } from '../../queue/models/sd-queue-item.model';
import { FilesHelper } from '../../files/helpers/files.helper';
import { CreateSidedrawerNetworkDto } from '../models/create-sidedrawer-network.dto';
import { Team } from '../../account/models/team.model';
import { Option } from '../../shared/sd-forms/models/option.model';
import { myTeamsByTeamIdSelector } from '../store/my-teams/my-teams-list.selectors';
import { SideDrawerNetworksModel } from '../views/networks-form-view/networks-form-view.store';
import { environment } from '../../../environments/environment';
import { PaginatorHelper } from '../../core/helpers/paginator.helper';
import { NetworkV2 } from '../models/network.v2.type';
import {
  activeSideDrawerDataBaseRegionAsDataBaseRegionSelector,
  activeSideDrawerSelector,
  notActiveSideDrawerDataBaseRegionSelector,
} from '../../sidedrawer/store/sidedrawer.selector';
import { filingCabinetSideDrawersWithDataSelector } from '../../filing-cabinet/store/filing-cabinet.store';
import { NetworkResourceOptions } from '../models/network-resource-options.type';
import { NetworksSelectorFilter } from '../models/networks-selector-filter.type';
import { networkListSelector } from '../store/network-list.store';

export class NetworkHelper {
  public static isRecordNetwork(
    network: Network | NetworkDto | NetworkV2
  ): boolean {
    return !!network?.record && !!network?.recordRole;
  }

  public static isTeamNetwork(network: NetworkDto): boolean {
    return !!network?.teamNetwork?.id;
  }

  public static relationDiffers(a: Relation, b: Relation): boolean {
    return (
      a?.profession !== b?.profession ||
      a?.personal !== b?.personal ||
      a?.personalOther !== b?.personalOther ||
      a?.professionOther !== b?.professionOther
    );
  }

  public static getExistingNetworksByNetworkTypeAndKey(
    networkType: NetworkType,
    key: string,
    store: Store<AppState>
  ): Observable<NetworkV2[]> {
    if (networkType === NetworkType.team) {
      return store.select(
        networkListSelector({
          teamId: key,
        })
      );
    }

    if (networkType === NetworkType.collaborator) {
      return store.select(
        networkListSelector({
          openId: key,
        })
      );
    }

    return store.select(
      networkListSelector({
        email: key,
      })
    );
  }

  public static generateSideDrawerRoleOperations(
    networks: Network[] | TeamNetwork[],
    sideDrawerId: string | null, // TODO see this...
    networkService: NetworkService,
    sideDrawerPermissions: SideDrawerNetworksModel[],
    contributor: Contributor,
    relation: Relation,
    contributorType: ContributorType,
    team?: Team
  ): SdQueueItem[] {
    if (
      !contributor ||
      (!contributor.email && !contributor.openId && !contributor.teamId)
    ) {
      return [];
    }
    const networksWithRoleSidedrawer: (TeamNetwork | Network)[] = [];
    networks.forEach((network: Network | TeamNetwork) => {
      if (!network.sidedrawerRole) {
        return;
      }
      networksWithRoleSidedrawer.push(network);
    });

    if (networksWithRoleSidedrawer.length === 0 && !sideDrawerPermissions) {
      return [];
    }
    const aux: SdQueueItem[] = [];

    sideDrawerPermissions.forEach(
      ({ sideDrawerId, sideDrawerRole, expiryDate }) => {
        const createSidedrawerNetworkDto: CreateSidedrawerNetworkDto = {
          sidedrawerRole: sideDrawerRole,
          relation,
          contributor,
          contributorType,
          expiryDate: expiryDate ?? null,
        };

        if (networksWithRoleSidedrawer.length === 0 && sideDrawerRole) {
          aux.push({
            id: FilesHelper.generateRandomId(),
            operation$: networkService.createRecordNetworkForQueue(
              sideDrawerId,
              null,
              createSidedrawerNetworkDto
            ),
            progress: 0,
            state: 'pending',
            itemType: 'network',
            network: {
              network: team ? team : createSidedrawerNetworkDto,
              uploadName: team
                ? team?.name
                : contributor?.firstName
                  ? contributor?.firstName +
                    ' ' +
                    (contributor?.lastName ? contributor?.lastName : '')
                  : contributor?.email,
              permission: new Option(sideDrawerRole, sideDrawerRole),
              type: contributorType,
            },
          });
          return;
        }
        networksWithRoleSidedrawer.forEach(network => {
          if (!sideDrawerRole) {
            aux.push({
              id: FilesHelper.generateRandomId(),
              operation$: networkService.deleteSideDrawerNetwork(
                sideDrawerId,
                network.id
              ),
              progress: 0,
              state: 'pending',
              itemType: 'network',
              network: {
                network: team ? team : createSidedrawerNetworkDto,
                uploadName: team
                  ? team?.name
                  : contributor?.firstName
                    ? contributor?.firstName +
                      ' ' +
                      (contributor?.lastName ? contributor?.lastName : '')
                    : contributor?.email,
                permission: new Option(
                  network.sidedrawerRole,
                  network.sidedrawerRole
                ),
                type: contributorType,
              },
            });
            return;
          }

          let sideDrawerRoleChanged = false;
          if (sideDrawerRole && sideDrawerRole !== network.sidedrawerRole) {
            sideDrawerRoleChanged = true;
          }
          let expiryDateChanged = false;
          if (
            sideDrawerRole &&
            expiryDate !== network.expiryDate &&
            !(
              UtilsHelper.areNullOrUndefinedOrEmpty(network.expiryDate) &&
              UtilsHelper.areNullOrUndefinedOrEmpty(expiryDate)
            )
          ) {
            expiryDateChanged = true;
          }

          if (sideDrawerRoleChanged || expiryDateChanged) {
            aux.push({
              id: FilesHelper.generateRandomId(),
              operation$: networkService.updateSideDrawerNetwork(
                sideDrawerId,
                network.id,
                {
                  relation: relation ? relation : network?.relation,
                  sidedrawerRole: sideDrawerRole,
                  expiryDate: expiryDate ?? null,
                }
              ),
              progress: 0,
              state: 'pending',
              itemType: 'network',
              network: {
                network: team ? team : createSidedrawerNetworkDto,
                uploadName: team
                  ? team?.name
                  : contributor?.firstName
                    ? contributor?.firstName +
                      ' ' +
                      (contributor?.lastName ? contributor?.lastName : '')
                    : contributor?.email,
                permission: new Option(sideDrawerRole, sideDrawerRole),
                type: contributorType,
              },
            });
          }
        });
      }
    );
    return aux;
  }

  public static generateRecordRoleOperations(
    networks: (Network | TeamNetwork)[],
    recordsNetworks: {
      recordId: string;
      recordRole: RecordsRoles | string;
      expiryDate?: Date;
    }[],
    sideDrawerId: string,
    networkService: NetworkService,
    contributor: Contributor,
    relation: Relation,
    contributorType: ContributorType,
    team?: Team
  ): SdQueueItem[] {
    if (!contributor) {
      return [];
    }
    if (recordsNetworks.length === 0) {
      return [];
    }
    const aux: SdQueueItem[] = [];
    recordsNetworks.forEach(({ recordId, recordRole, expiryDate }) => {
      const currentNetworks = networks.filter(
        network => network.record === recordId
      );
      if (currentNetworks.length === 0 && !!recordRole) {
        aux.push({
          id: FilesHelper.generateRandomId(),
          operation$: networkService.createRecordNetworkForQueue(
            sideDrawerId,
            recordId,
            {
              recordRole,
              relation,
              contributor,
              contributorType,
              expiryDate: expiryDate ?? null,
            }
          ),
          progress: 0,
          state: 'pending',
          itemType: 'network',
          network: {
            network: team
              ? team
              : {
                  recordRole,
                  relation,
                  contributor,
                  contributorType,
                },
            uploadName: team
              ? team?.name
              : contributor?.firstName
                ? contributor?.firstName +
                  ' ' +
                  (contributor?.lastName ? contributor?.lastName : '')
                : contributor?.email,
            permission: new Option(recordRole, recordRole),
            type: contributorType,
          },
        });
        return;
      }
      if (!recordRole) {
        currentNetworks.forEach(network => {
          aux.push({
            id: FilesHelper.generateRandomId(),
            operation$: networkService.deleteRecordNetwork(
              sideDrawerId,
              network.record,
              network.id
            ),
            progress: 0,
            state: 'pending',
            itemType: 'network',
            network: {
              network: team
                ? team
                : { ...network, relation, contributor, contributorType },
              uploadName: team
                ? team.name
                : contributor?.firstName
                  ? contributor?.firstName +
                    ' ' +
                    (contributor?.lastName ? contributor?.lastName : '')
                  : contributor?.email,
              permission: new Option(network?.recordRole, network?.recordRole),
              type: contributorType,
            },
          });
        });
        return;
      }
      currentNetworks.forEach(network => {
        let recordRoleChanged = false;
        if (recordRole && recordRole !== network.recordRole) {
          recordRoleChanged = true;
        }

        let expiryDateChanged = false;
        if (
          recordRole &&
          expiryDate !== network.expiryDate &&
          !(
            UtilsHelper.areNullOrUndefinedOrEmpty(network.expiryDate) &&
            UtilsHelper.areNullOrUndefinedOrEmpty(expiryDate)
          )
        ) {
          expiryDateChanged = true;
        }

        if (recordRoleChanged || expiryDateChanged) {
          aux.push({
            id: FilesHelper.generateRandomId(),
            operation$: networkService.updateRecordNetwork(
              sideDrawerId,
              network.record,
              network.id,
              expiryDate
                ? {
                    relation: relation ? relation : network?.relation,
                    recordRole,
                    expiryDate: expiryDate ?? null,
                  }
                : {
                    relation: relation ? relation : network?.relation,
                    recordRole,
                    expiryDate: null,
                  }
            ),
            progress: 0,
            state: 'pending',
            itemType: 'network',
            network: {
              network: team
                ? team
                : {
                    recordRole,
                    relation,
                    contributor,
                    contributorType,
                  },
              uploadName: team
                ? team?.name
                : contributor?.firstName
                  ? contributor?.firstName +
                    ' ' +
                    (contributor?.lastName ? contributor?.lastName : '')
                  : contributor?.email,
              permission: new Option(recordRole, recordRole),
              type: contributorType,
            },
          });
        }
      });
    });
    return aux;
  }

  public static getTeamObject(teamId: string, store: Store<AppState>): Team {
    let teamSelected: Team;
    store
      .pipe(
        select(
          myTeamsByTeamIdSelector({
            teamId: teamId,
          })
        ),
        take(1),
        tap(myTeam => {
          teamSelected = myTeam;
        })
      )
      .subscribe();
    return teamSelected;
  }

  public static getNetworksResourceUrl(
    sideDrawerId: string,
    optionsObject?: {
      recordId?: string;
      nextPage?: string;
      limit?: number;
      contributorType?: ContributorType;
      contributorOpenId?: string;
      contributorEmail?: string;
      invitationCode?: string;
      teamId?: string;
      entity?: 'sidedrawer' | 'record';
      role?: string;
      name?: string; // if we search by name, we must add the region parameter
      region?: string;
    }
  ): string {
    const options = JSON.parse(JSON.stringify({ ...optionsObject }));
    // TODO this is a workaround to fix the issue with the email search. We need work this with backend team.
    if (options?.contributorEmail) {
      options.contributorEmail = decodeURIComponent(options.contributorEmail);
      options.contributorEmail = encodeURIComponent(options.contributorEmail);
    }
    let resourceUrl = UtilsHelper.apiVersion(environment.networksApi, 2);
    resourceUrl += options?.recordId
      ? `sidedrawer/sidedrawer-id/${sideDrawerId}/records/record-id/${options?.recordId}/networks?`
      : `sidedrawer/sidedrawer-id/${sideDrawerId}/networks?`;
    if (!options) {
      return resourceUrl;
    }
    if (options?.nextPage?.length > 0) {
      resourceUrl += `startingAfter=${PaginatorHelper.cleanStartingAfterQueryParam(
        options?.nextPage
      )}&`;
    }
    try {
      delete options.recordId;
      delete options.nextPage;
    } catch (e) {}

    Object.keys(options).forEach(key => {
      if (options[key]?.length > 0) {
        resourceUrl += `${key}=${options[key]}&`;
      }
    });
    return resourceUrl;
  }

  // TODO check this...
  public static mapNetworksV2AsUser(networks: NetworkV2[]): NetworkV2[] {
    const teamNetworks = networks.filter(
      net => net.contributorType === ContributorType.team
    );
    const teamMap = new Map<string, NetworkV2>();
    teamNetworks.forEach(teamNetwork => {
      if (teamMap.has(teamNetwork.teamNetwork.teamId)) {
        const previousTeam = teamMap.get(teamNetwork.teamNetwork.teamId);
        let relation = previousTeam.relation;
        let recordRole = previousTeam.recordRole;
        let sidedrawerRole = previousTeam.sidedrawerRole;
        if (
          !this.isRecordNetwork(teamNetwork) &&
          getSideDrawerRolesOrdinalToSort(teamNetwork.sidedrawerRole) <
            getSideDrawerRolesOrdinalToSort(previousTeam.sidedrawerRole)
        ) {
          relation = teamNetwork.relation;
          sidedrawerRole = teamNetwork.sidedrawerRole;
        }
        if (
          this.isRecordNetwork(teamNetwork) &&
          getRecordsRolesOrdinalToSort(teamNetwork.recordRole) <
            getRecordsRolesOrdinalToSort(previousTeam.recordRole)
        ) {
          relation = teamNetwork.relation;
          recordRole = teamNetwork.recordRole;
        }
        teamMap.set(teamNetwork.teamNetwork.teamId, {
          ...previousTeam,
          relation,
          recordRole,
          sidedrawerRole,
        });
        return;
      }
      teamMap.set(teamNetwork.teamNetwork.teamId, teamNetwork);
    });
    const notTeamNetworks = networks.filter(
      net => net.contributorType !== ContributorType.team
    );
    const notTeamMap = new Map<string, NetworkV2>();
    notTeamNetworks.forEach(network => {
      if (notTeamMap.has(network.contributor?.email)) {
        const currentNetwork = notTeamMap.get(network.contributor?.email);
        if (
          !this.isRecordNetwork(network) &&
          getSideDrawerRolesOrdinalToSort(network.sidedrawerRole) <
            getSideDrawerRolesOrdinalToSort(currentNetwork.sidedrawerRole)
        ) {
          notTeamMap.set(network.contributor?.email, network);
          return;
        }
        if (
          this.isRecordNetwork(network) &&
          getRecordsRolesOrdinalToSort(network.recordRole) <
            getRecordsRolesOrdinalToSort(currentNetwork.recordRole)
        ) {
          notTeamMap.set(network.contributor?.email, network);
          return;
        }
        return;
      }
      notTeamMap.set(network.contributor?.email, network);
    });
    const filteredNetworks = [
      ...UtilsHelper.mapToArray(teamMap),
      ...UtilsHelper.mapToArray(notTeamMap),
    ];
    return filteredNetworks.sort((a, b) =>
      UtilsHelper.compareStrings(b.id, a.id)
    );
  }

  static getInitialNetworkSearchRequests(
    store: Store<AppState>,
    filter: string
  ): {
    requestId: string;
    sideDrawerId: string;
    options?: Partial<NetworkResourceOptions>;
    gettingNetworks: boolean;
  }[] {
    const activeSideDrawerRegion = store.selectSignal(
      activeSideDrawerDataBaseRegionAsDataBaseRegionSelector
    )();
    const notActiveSideDrawerRegions =
      store.selectSignal(notActiveSideDrawerDataBaseRegionSelector)() ?? [];
    const regions = activeSideDrawerRegion
      ? [activeSideDrawerRegion, ...notActiveSideDrawerRegions]
      : [...notActiveSideDrawerRegions];
    const sideDrawers =
      store.selectSignal(filingCabinetSideDrawersWithDataSelector)()?.length > 0
        ? [
            ...store
              .selectSignal(filingCabinetSideDrawersWithDataSelector)()
              ?.map(fcSd => fcSd.data),
          ]
        : store.selectSignal(activeSideDrawerSelector)()
          ? [store.selectSignal(activeSideDrawerSelector)()]
          : [];
    const requests = [];
    sideDrawers.forEach(sideDrawer => {
      requests.push({
        requestId: sideDrawer.id + '-email',
        sideDrawerId: sideDrawer.id,
        options: {
          contributorEmail: filter,
        },
        gettingNetworks: true,
      });
      regions.forEach(region => {
        requests.push({
          requestId: sideDrawer.id + '-name-' + region.databaseregion,
          sideDrawerId: sideDrawer.id,
          options: {
            region: region.databaseregion,
            name: filter,
          },
          gettingNetworks: true,
        });
      });
    });
    return requests;
  }

  // TODO see this...
  public static networksSelectorFilter(
    networks: NetworkV2[],
    payload?: NetworksSelectorFilter
  ): NetworkV2[] {
    if (!payload) {
      return networks;
    }
    const {
      asUsers,
      contributorType,
      recordId,
      sideDrawerId,
      sideDrawerRole,
      recordRole,
      email,
      teamId,
      openId,
      excludeTeams,
    } = payload;
    const filter: Partial<NetworkV2> = {};
    if (recordId) {
      filter.record = recordId;
    }
    if (sideDrawerId) {
      filter.sidedrawer = sideDrawerId;
    }
    if (contributorType) {
      filter.contributorType = contributorType;
    }
    if (sideDrawerRole) {
      filter.sidedrawerRole = sideDrawerRole as SidedrawerRoles;
    }
    if (recordRole) {
      filter.recordRole = recordRole as RecordsRoles;
    }
    let filteredNetworks = networks.filter(network =>
      Object.keys(filter).every(key => network[key] === filter[key])
    );
    if (teamId) {
      filteredNetworks = filteredNetworks.filter(
        network => network.teamNetwork?.teamId === teamId
      );
    }
    if (email) {
      filteredNetworks = filteredNetworks.filter(
        network => network.contributor?.email === email
      );
    }

    // TODO see this...
    if (openId) {
      filteredNetworks = filteredNetworks.filter(
        network => network.contributor?.openId === openId
      );
    }

    if (excludeTeams) {
      filteredNetworks = filteredNetworks.filter(
        network => network.contributorType !== ContributorType.team
      );
    }

    return asUsers
      ? NetworkHelper.mapNetworksV2AsUser(filteredNetworks)
      : filteredNetworks;
  }

  public static filterInvalidNetworkV2Items(
    networks: NetworkV2[],
    filterSdOwner?: boolean
  ): NetworkV2[] {
    if (!networks || networks.length === 0) {
      return [];
    }
    return networks?.filter(network => {
      if (filterSdOwner && network.sidedrawerRole === SidedrawerRoles.owner) {
        return false;
      }
      if (
        network.contributorType === ContributorType.team &&
        network.teamNetwork?.teamId
      ) {
        return true;
      }
      if (network.contributor?.email) {
        return true;
      }
      return false;
    });
  }
}
