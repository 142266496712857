<div
  *ngIf="
    ((loading$ | async) === false &&
      (currentBranding$ | async)?.simplifiedRecordView === false) ||
    ((currentBranding$ | async)?.simplifiedRecordView === true &&
      reminders?.length > 0)
  "
  class="reminders-list">
  <div *ngIf="showHeader" class="section">
    <div class="left-column">
      <div
        role="heading" aria-level="3"
        [attr.aria-label]="remindersListTitle + ' ' + remindersListTooltip"
        class="reminders-list-header-title">
        {{ remindersListTitle }}
      </div>
      <app-sd-tooltip-button-a11y
        [setSdAccessibility]="accessibilityForIcon"
        [tooltip]="remindersListTooltip" />
    </div>
    <div class="right-column" *ngIf="userCanOperate()">
      <app-sd-icon-button-a11y
        (btnClicked)="onAddReminder()"
        *ngIf="!viewOnly"
        [iconColor]="sdColorPalette.primaryColor"
        [iconHeight]="1.25"
        [iconWidth]="1.25"
        [icon]="cdn + ('globalparams_addicon' | dictionary | async)"
        [setSdAccessibility]="{
          ariaLabel: remindersListAddButtonTooltip,
          role: 'button'
        }"
        [squareBackground]="false"
        [tooltip]="remindersListAddButtonTooltip">
      </app-sd-icon-button-a11y>
    </div>
  </div>

  <ng-container *ngIf="reminders$ | async as reminders">
    <div *ngIf="reminders?.length > 0">
      <app-reminders-list-item-a11y
        *ngFor="
          let item of !viewAllReminder
            ? reminders?.slice(0, reminderListSlideNumber)
            : reminders;
          index as i;
          trackBy: remindersTrackBy
        "
        [detailUrl]="addNewUrl"
        [dialogMode]="dialogMode"
        [options]="options$ | async"
        [reminderItem]="item"
        [viewAllReminder]="viewAllReminder"
        [viewOnly]="viewOnly">
      </app-reminders-list-item-a11y>
      <div
        *ngIf="!viewAllReminder && reminders?.length > reminderListSlideNumber"
        class="reminders-list-view-all">
        <app-sd-link-button-a11y
          *ngIf="!viewAllReminderInline"
          [ariaLabel]="remindersListViewAll"
          [href]="seeAllReminders()"
          [tabIndex]="!viewAllReminderInline ? 0 : -1"
          [template]="sdLinkButtonA11yEnum.linkWithRouterOutlet">
          <span
            class="reminders-list-view-all-link-content"
            linkWithRouterOutlet>
            {{ remindersListViewAll }}
          </span>
        </app-sd-link-button-a11y>

        <app-sd-link-button-a11y
          (click)="seeAllRemindersInline($event)"
          *ngIf="viewAllReminderInline"
          [ariaLabel]="remindersListViewAll"
          [href]="'#'"
          [tabIndex]="viewAllReminderInline ? 0 : -1"
          [template]="sdLinkButtonA11yEnum.linkNative">
          <span class="reminders-list-view-all-link-content" linkNative>
            {{ remindersListViewAll }}
          </span>
        </app-sd-link-button-a11y>
      </div>
    </div>

    <div *ngIf="(loading$ | async) !== true && reminders?.length === 0">
      <app-sd-empty-template
        [emptyStateIcon]="
          cdn + ('reminderslist_emptystateicon' | dictionary | async)
        "
        [emptyStateInline]="emptyStateInline"
        [emptyStateString]="emptyStateString">
      </app-sd-empty-template>
    </div>
  </ng-container>
</div>
<div
  *ngIf="showSpinner && (loading$ | async)"
  class="reminders-list-view-spinner">
  <app-sd-progress-spinner-a11y></app-sd-progress-spinner-a11y>
</div>
