<ng-container *ngIf="item.menuLinkType === sdLinkMenuLinkType.external">
  <a>
    {{ item.menuLinkText }}
  </a>
</ng-container>

<ng-container
  *ngIf="
    item.menuLinkType === sdLinkMenuLinkType.internal || !item.menuLinkType
  ">
  <a>
    <div>{{ item.menuLinkText }}</div>
  </a>
</ng-container>

<ng-container *ngIf="item.menuLinkType === sdLinkMenuLinkType.emitter">
  <div class="menu-icon-container">
    <app-sd-svg-a11y
      *ngIf="item?.menuLinkIcon"
      [color]="'var(--secondaryAccentColor)'"
      [height]="iconHeight"
      [src]="item?.menuLinkIcon"
      [width]="iconWidth"></app-sd-svg-a11y>
    <a>
      <div>{{ item.menuLinkText }}</div>
    </a>  
    <app-sd-tooltip-button-a11y
      #sdTooltip
      *ngIf="!!item.menuLinkTooltip && item.menuLinkTooltip.length > 0"
      [small]="true"
      [tooltip]="item.menuLinkTooltip">
    </app-sd-tooltip-button-a11y>
  </div>
</ng-container>
