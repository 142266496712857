import {
  ChangeDetectionStrategy,
  Component,
  Input,
  signal,
  Signal,
  ViewChild,
  WritableSignal,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { AccessibilityHelper } from 'src/app/core/helpers/accessibility.helper';
import { DateHelper } from 'src/app/core/helpers/date.helper';
import { SdAccessibilitySetting } from 'src/app/core/models/sd-accessibility-model';
import { AppState } from 'src/app/reducers';
import { SdCacheNewImageRequested } from '../../../sd-cache/store/sd-cache.actions';
import { sdCacheElementSelector } from '../../../sd-cache/store/sd-cache.selectors';
import { MatTooltip } from '@angular/material/tooltip';
import { LiveAnnouncer } from '@angular/cdk/a11y';

@Component({
  selector: 'app-sd-svg-a11y',
  template: `
    <div
      id="id ?? sdAccessibility()?.id"
      [attr.role]="sdAccessibility()?.role ?? 'img'"
      #matTooltip="matTooltip"
      (mouseleave)="matTooltip.hide()"
      *ngIf="imageSrc()"
      [attr.aria-label]="sdAccessibility()?.ariaLabel"
      [attr.aria-expanded]="sdAccessibility()?.ariaExpanded ?? undefined"
      [attr.role]="sdAccessibility()?.role ?? 'img'"
      [matBadgeHidden]="!showBadge"
      [matBadgeSize]="badgeSize"
      [matBadge]="badge"
      [matTooltip]="tooltips"
      (focus)="onfocus()"
      [ngClass]="{ expand: badge > 99, 'bg-only': bgOnly, readOnly: readOnly }"
      [ngStyle]="{
        position: 'relative',
        overflown: 'hidden',
        'background-color': backgroundColor,
        height: !!height ? height + 'rem' : 'auto',
        width: !!width ? width + 'rem' : 'auto'
      }"
      [tabindex]="sdAccessibility()?.tabIndex"
      class="sd-svg stretch-badge"
      matBadgeColor="warn">
      <div
        [tabindex]="-1"
        *ngIf="!!color"
        [ngStyle]="{
          opacity: invisible ? 0 : 1,
          position: 'absolute',
          top: 0,
          left: 0,
          'background-color': color,
          height: !!height ? height + 'rem' : 'auto',
          width: !!width ? width + 'rem' : 'auto',
          '-webkit-mask': 'url(' + imageSrc() + ') no-repeat center/contain',
          mask: 'url(' + imageSrc() + ') no-repeat center/contain'
        }"
        class="icon"></div>
    </div>
  `,
  styleUrls: ['./sd-svg-a11y.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SdSvgA11yComponent {
  @Input() color: string;
  //width and height in rem
  @Input() width: number;
  @Input() height: number;
  @Input() invisible: boolean;
  @Input() svgSource: boolean;
  // Badge inputs for svg icons.
  @Input() badge: number;
  @Input() showBadge: boolean;
  @Input() backgroundColor: string;
  @Input() bgOnly: boolean;
  @Input() badgeSize: 'small' | 'medium' | 'large' = 'small';
  @Input() tooltips: string;
  @Input() readOnly: boolean;
  imageSrc: Signal<string>;
  @Input() id = '';
  sdAccessibility: WritableSignal<SdAccessibilitySetting> = signal(null);

  constructor(
    private readonly store: Store<AppState>,
    private readonly announcer: LiveAnnouncer
  ) {}

  @Input() set setSdAccessibility(value: SdAccessibilitySetting) {
    this.sdAccessibility.set(
      AccessibilityHelper.setDefaultAccessibility(value)
    );
  }

  @Input() set src(src: string) {
    const srcQuery = src + DateHelper.getTodayQuery();
    this.store.dispatch(new SdCacheNewImageRequested({ src: srcQuery }));
    this.imageSrc = this.store.selectSignal(
      sdCacheElementSelector({ key: srcQuery })
    );
  }
  @ViewChild(MatTooltip) public matTooltip: MatTooltip;

  onfocus(): void {
    if (this.tooltips && this.svgSource) {
      this.announcer.announce(this.tooltips);
    }
  }
}
