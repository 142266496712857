import {ChangeDetectionStrategy, Component, Inject, signal, WritableSignal} from '@angular/core';
import { DialogTemplateTypes } from 'src/app/shared/templates/enums/templates.enum';
import { SdDialogTemplateA11yModule } from '../../../shared/templates/dialog-template-a11y/sd-dialog-template-a11y.module';
import { SdFlatButtonA11yModule } from '../../../shared/sd-flat-button-a11y/sd-flat-button-a11y.module';
import { AsyncPipe } from '@angular/common';
import { DictionaryDisplayType } from '../../../dictionary/models/dictionary-display-type.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-upload-file-succeed-dialog',
  template: `
    <app-sd-dialog-template-a11y
      (closeButtonClicked)="onClose()"
      [type]="dialogTemplateTypes.Info">
      <ng-container dialog-header>
        <h1>
          {{ displayType()?.displaytypealert_successuploadtitle ?? 'Upload Complete' }}
        </h1>
      </ng-container>
      <ng-container dialog-body>
        <div
          tabindex="0"
          [innerHTML]="displayType()?.displaytypealert_successuploadbody  ?? 'Your changes have been successfully uploaded'"
          class="template-description"></div>
      </ng-container>
      <ng-container dialog-footer>
        <app-sd-flat-button-a11y (buttonClicked)="onClose()" [primary]="true">
          {{ displayType()?.displaytypealert_successuploadbutton ?? 'OK' }}
        </app-sd-flat-button-a11y>
      </ng-container>
    </app-sd-dialog-template-a11y>
  `,
  styleUrls: ['./upload-file-succeed-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SdDialogTemplateA11yModule, SdFlatButtonA11yModule, AsyncPipe],
})
export class UploadFileSucceedDialogComponent {
  dialogTemplateTypes = DialogTemplateTypes;
  displayType: WritableSignal<DictionaryDisplayType>;

  constructor(
    private readonly dialogRef: MatDialogRef<UploadFileSucceedDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { displayType: DictionaryDisplayType }
  ) {
    this.displayType = signal(this.data.displayType);
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
