import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SdFormattedDatePipeModule } from '../sd-formatted-date-pipe/sd-formatted-date-pipe.module';
import { SdSearchSideDrawerResultItemA11yComponent } from './components/sd-search-result-item-a11y/sd-search-sidedrawer-result-item-a11y.component';
import { SdSideDrawerItemA11yComponent } from '../sd-side-drawer-item-a11y/sd-side-drawer-item-a11y.component';

@NgModule({
  declarations: [SdSearchSideDrawerResultItemA11yComponent],
  imports: [
    CommonModule,
    SdFormattedDatePipeModule,
    SdSideDrawerItemA11yComponent,
  ],
  exports: [SdSearchSideDrawerResultItemA11yComponent],
})
export class SdSearchSideDrawerResultItemA11yModule {}
