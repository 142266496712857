import { Component, computed, inject } from '@angular/core';
import { NgForOf, NgIf } from '@angular/common';
import { forkJoin, switchMap } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { NetworkType } from '../../models/network-type.model';
import { Store } from '@ngrx/store';
import { activeRecordSelector } from '../../../records/store/records-list.selectors';
import { take, tap } from 'rxjs/operators';
import { AppState } from '../../../reducers';
import { SdRecordTabHeaderComponent } from '../../../shared/sd-record-tab-header/sd-record-tab-header.component';
import { SdFlatButtonA11yModule } from '../../../shared/sd-flat-button-a11y/sd-flat-button-a11y.module';
import { RecordFormViewStore } from '../../../records/views/record-form-view/record-form-view.store';
import { ActiveRecordRequested } from '../../../records/store/records-list.actions';
import { RecordsRoutes } from '../../../records/routes/records.routes';
import { Router } from '@angular/router';
import { Record } from '../../../records/models/record.model';
import { ShareResourceDialogComponent } from '../../shared/share-resource-dialog/share-resource-dialog.component';
import { NetworkResourceType } from '../../models/network-resource-type.model';
import { SdColorPalette } from 'src/app/core/models/enums/sd-color-palette-enum';
import { activeSideDrawerSelector } from 'src/app/sidedrawer/store/sidedrawer.selector';
import { SdEmptyTemplateComponent } from 'src/app/shared/templates/sd-empty-template/components/sd-empty-template/sd-empty-template.component';
import { NetworkEntity } from '../../models/network-entity.enum';
import { MatDialog } from '@angular/material/dialog';
import { SdProgressBarA11yComponent } from 'src/app/shared/sd-progress-bar-a11y/components/sd-progress-bar-a11y/sd-progress-bar-a11y.component';
import { SdIconButtonA11yComponent } from 'src/app/shared/sd-icon-button-a11y/components/sd-icon-button-a11y/sd-icon-button-a11y.component';
import {
  gettingNetworkListSelector,
  NetworkListActions,
  networkListHasMoreSelector,
  networkListSelector,
} from '../../store/network-list.store';
import { DictionarySignalPipe } from '../../../dictionary/pipes/dictionary-signal.pipe';
import { NetworkV2 } from '../../models/network.v2.type';
import { NetworkListItemComponent } from '../../shared/network-list-item/network-list-item.component';
import { SdInfiniteListComponent } from '../../../shared/sd-infinite-list/sd-infinite-list.component';

@Component({
  selector: 'app-record-form-networks-tab',
  standalone: true,
  imports: [
    SdProgressBarA11yComponent,
    SdRecordTabHeaderComponent,
    SdIconButtonA11yComponent,
    DictionarySignalPipe,
    NgIf,
    SdInfiniteListComponent,
    NgForOf,
    NetworkListItemComponent,
    SdEmptyTemplateComponent,
    SdFlatButtonA11yModule,
  ],
  templateUrl: './record-form-networks-tab.component.html',
  styleUrls: ['./record-form-networks-tab.component.scss'],
})
export class RecordFormNetworksTabComponent {
  private store = inject(Store<AppState>);
  private readonly dialog = inject(MatDialog);
  private readonly recordFormViewStore = inject(RecordFormViewStore);
  private readonly router = inject(Router);
  protected readonly NetworkType = NetworkType;
  protected readonly cdn = environment.cdn;
  protected readonly SdColorPalette = SdColorPalette;
  protected readonly NetworkEntity = NetworkEntity;
  gettingNetworks = this.store.selectSignal(gettingNetworkListSelector);
  networks = computed(() => {
    const activeRecordId = this.store.selectSignal(activeRecordSelector)()?.id;
    if (!activeRecordId) {
      return [];
    }
    return this.store.selectSignal(
      networkListSelector({ recordId: activeRecordId })
    )();
  });
  hasMore = this.store.selectSignal(networkListHasMoreSelector);

  onAddCollaborator(): void {
    forkJoin([
      this.recordFormViewStore.confirmEnabled$.pipe(take(1)),
      this.store.select(activeRecordSelector).pipe(take(1)),
    ])
      .pipe(
        tap(([enabled, activeRecord]) => {
          if (activeRecord) {
            this.openCreateNetworkDialog(activeRecord);
            return;
          }
          if (!enabled) {
            this.recordFormViewStore.focusMandatoryInputs();
            return;
          }
          this.recordFormViewStore.createRecord(record => {
            this.store.dispatch(
              new ActiveRecordRequested({
                recordId: record.id,
              })
            );
            this.openCreateNetworkDialog(record);
            const url = this.router.url.replace(
              `${RecordsRoutes.new}`,
              record.id
            );
            this.router.navigateByUrl(url);
          });
        })
      )
      .subscribe();
  }

  openCreateNetworkDialog(record: Record): void {
    this.store
      .select(activeSideDrawerSelector)
      .pipe(
        take(1),
        switchMap(activeSidedrawer =>
          this.dialog
            .open(ShareResourceDialogComponent, {
              data: {
                resourceType: NetworkResourceType.record,
                recordId: record.id,
                resourceName: record.name,
                sideDrawerId: activeSidedrawer.id,
              },
              autoFocus: false,
              panelClass: 'custom-share-resource-dialog',
            })
            .afterClosed()
        )
      )
      .subscribe();
  }

  onNextPage(): void {
    this.store.dispatch(NetworkListActions.nextPageRequested());
  }

  trackBy(index: number, network: NetworkV2): string {
    return network.id;
  }
}
