import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { NetworkV2 } from '../networks/models/network.v2.type';
import { computed, inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../reducers';
import {
  relationshipSelector,
  relationshipTypesSelector,
} from '../dictionary/store/dictionary.selectors';
import { Option } from '../shared/sd-forms/models/option.model';
import { HttpErrorResponse } from '@angular/common/http';
import { Relation } from '../records/models/relation.model';
import { concat, mergeMap, Observable, of, switchMap } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NetworkService } from '../networks/services/network.service';
import { NetworkResourceOptions } from '../networks/models/network-resource-options.type';
import { ContributorType } from '../networks/models/contributor-type.model';
import {
  NetworkListActions,
  networkListSelector,
} from '../networks/store/network-list.store';
import { NetworkHelper } from '../networks/helpers/network.helper';
import { UtilsHelper } from '../core/helpers/utils.helper';

type NetworkUpdateRelationState = {
  operations: Map<
    string | number,
    {
      state: 'idle' | 'processing' | 'success' | 'error';
      httpError?: HttpErrorResponse;
      network: NetworkV2;
    }
  >;
};

@Injectable()
export class NetworkUpdateRelationStore extends ComponentStore<NetworkUpdateRelationState> {
  private readonly store = inject(Store<AppState>);
  private readonly service = inject(NetworkService);
  readonly operationsComplete = this.selectSignal(({ operations }) =>
    Array.from(operations.values())?.some(
      ({ state }) => state === 'success' || state === 'error'
    )
  );
  readonly processingOperations = this.selectSignal(({ operations }) =>
    Array.from(operations.values())?.some(({ state }) => state === 'processing')
  );
  readonly errors = this.selectSignal(({ operations }) =>
    Array.from(operations.values())
      ?.filter(({ state }) => state === 'error')
      ?.map(({ network }) => network)
  );
  readonly options = computed(() => {
    const relationships = this.store.selectSignal(relationshipSelector)();
    const relationshipTypes = this.store.selectSignal(
      relationshipTypesSelector
    )();
    return relationships
      .filter(
        relationship => relationship.relationship_relationshipid !== 'other'
      )
      .map(
        relationship =>
          new Option(
            relationship.relationship_relationshipid,
            relationship.relationship_relationshipname,
            null,
            relationshipTypes.find(
              type =>
                type.relationshiptype_groupid ===
                relationship.relationship_relationshipgroupid
            ).relationshiptype_groupname
          )
      );
  });

  getInitialRelation(network: NetworkV2): Option | string {
    const options = this.options();
    const { personal, profession, personalOther, professionOther } =
      network.relation;
    if (personal?.length > 0 && personal !== 'other') {
      return options.find(option => option.key === personal);
    }
    if (profession?.length > 0 && profession !== 'other') {
      return options.find(option => option.key === profession);
    }
    if (personalOther?.length > 0) {
      return personalOther;
    }
    if (professionOther?.length > 0) {
      return professionOther;
    }
  }

  requestsNetworks(network: NetworkV2): void {
    const options: NetworkResourceOptions = {};
    if (network.contributorType === ContributorType.team) {
      options.teamId = network.teamNetwork.teamId;
    } else {
      options.contributorEmail = network.contributor.email;
    }
    this.store.dispatch(
      NetworkListActions.requested({
        sideDrawerId: network.sidedrawer,
        allPageRequested: true,
        options,
        clearRequests: true,
      })
    );
  }

  generateRelation(value: string | Option): Relation {
    if (typeof value === 'string') {
      return {
        personalOther: value,
        personal: 'other',
      };
    }
    const { key } = value;
    const selected = this.store
      .selectSignal(relationshipSelector)()
      .find(rel => rel.relationship_relationshipid === key);
    const relation: Relation = {};
    relation[
      `${
        selected.relationship_relationshipgroupid === 'personal'
          ? 'personal'
          : 'profession'
      }`
    ] = key;
    return relation;
  }

  getNetworksToUpdate(network: NetworkV2): NetworkV2[] {
    const payload: { teamId?: string; email?: string } = {};
    if (network.contributorType === ContributorType.team) {
      payload.teamId = network.teamNetwork.teamId;
    } else {
      payload.email = network.contributor.email;
    }
    return this.store.selectSignal(networkListSelector(payload))();
  }

  updateRelation = this.effect(
    (
      trigger$: Observable<{
        relation: Relation;
        networks: NetworkV2[];
        callback?: () => void;
      }>
    ) =>
      trigger$.pipe(
        tap(({ networks }) => {
          const operations = UtilsHelper.arrayToMap<{
            state: 'idle' | 'processing' | 'success' | 'error';
            httpError?: HttpErrorResponse;
            network: NetworkV2;
          }>(
            'id',
            networks.map(network => ({ network, state: 'idle' }))
          );
          this.patchState({ operations });
        }),
        mergeMap(({ relation, networks, callback }) =>
          concat(
            ...networks.map(network =>
              of(true).pipe(
                tap(() => {
                  const aux = new Map(
                    this.selectSignal(({ operations }) => operations)()
                  );
                  aux.set(network.id, { state: 'processing', network });
                  this.patchState({ operations: aux });
                }),
                switchMap(() =>
                  NetworkHelper.isRecordNetwork(network)
                    ? this.service.updateRecordNetwork(
                        network.sidedrawer,
                        network.record,
                        network.id,
                        { relation, recordRole: network.recordRole }
                      )
                    : this.service.updateSideDrawerNetwork(
                        network.sidedrawer,
                        network.id,
                        {
                          relation,
                          sidedrawerRole: network.sidedrawerRole,
                        }
                      )
                ),
                tapResponse(
                  () => {
                    const aux = new Map(
                      this.selectSignal(({ operations }) => operations)()
                    );
                    aux.set(network.id, { state: 'success', network });
                    this.store.dispatch(
                      NetworkListActions.update({
                        network: { ...network, relation },
                      })
                    );
                    this.patchState({ operations: aux });
                  },
                  (httpError: HttpErrorResponse) => {
                    const aux = new Map(
                      this.selectSignal(({ operations }) => operations)()
                    );
                    aux.set(network.id, { state: 'error', network, httpError });
                    this.store.dispatch(
                      NetworkListActions.delete({ networkId: network.id })
                    );
                    this.patchState({ operations: aux });
                  }
                )
              )
            )
          ).pipe(tap(() => (callback ? callback() : null)))
        )
      )
  );

  constructor() {
    super({
      operations: new Map(),
    });
  }
}
