import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecordsListItemComponent } from './components/records-list-item/records-list-item.component';
import { DeleteRecordDialogModule } from '../delete-record-dialog/delete-record-dialog.module';
import { DictionaryPipeModule } from '../../../dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { SdFormattedDatePipeModule } from '../../../shared/sd-formatted-date-pipe/sd-formatted-date-pipe.module';
import { SdLinkMenuA11yModule } from 'src/app/shared/sd-link-menu-a11y/sd-link-menu-a11y.module';
import { SdProgressSpinnerA11yModule } from 'src/app/shared/sd-progress-spinner-a11y/sd-progress-spinner-a11y/sd-progress-spinner-a11y.module';
import { SdSvgA11yModule } from '../../../shared/sd-svg-a11y/sd-svg-a11y.module';
import { DisplayValuePipeModule } from '../../../shared/sd-pipes/display-value-pipe/display-value-pipe.module';
import { DragAndDropModule } from 'src/app/files/directives/drag-and-drop/drag-and-drop.module';

@NgModule({
  declarations: [RecordsListItemComponent],
  exports: [RecordsListItemComponent],
  imports: [
    CommonModule,
    SdProgressSpinnerA11yModule,
    DeleteRecordDialogModule,
    DictionaryPipeModule,
    SdFormattedDatePipeModule,
    SdLinkMenuA11yModule,
    SdSvgA11yModule,
    DisplayValuePipeModule,
    DragAndDropModule,
  ],
})
export class RecordsListItemModule {}
