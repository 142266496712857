<app-sd-autocomplete-a11y
  (optionRecordSubTypeSelected)="onSelectOption($event)"
  [controller]="controller"
  [customTooltip]="
    !!customTooltip
      ? customTooltip
      : ('recordformview_documenttypetooltip' | dictionary | async)
  "
  [error]="error"
  [placeholder]="
    !!placeholder
      ? placeholder
      : ('recordformview_documenttypeplaceholder' | dictionary | async)
  "
  [setOptionsRecordSubType]="subTypes"
  [setSdAccessibility]="{
    id: sdAccessibility?.id,
    ariaLabel: !!customTooltip
      ? customTooltip
      : ('recordformview_documenttypetooltip' | dictionary | async)
  }"
  [template]="sdAutoCompleteA11yEnum.recordSubType">
</app-sd-autocomplete-a11y>
