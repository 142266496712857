import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PaginatorApiResponse } from '../models/paginator-api-response.dto';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { authHeadersSelector } from '../../auth/store/auth.selectors';

@Injectable()
export class PaginatorService {
  constructor(
    private readonly http: HttpClient,
    private readonly store: Store<AppState>
  ) {}

  getPaginatedResource<T>(
    resourceUrl: string
  ): Observable<PaginatorApiResponse<T>> {
    return this.http.get<PaginatorApiResponse<T>>(resourceUrl, {
      headers: this.store.selectSignal(authHeadersSelector)(),
    });
  }
}
