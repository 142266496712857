import { createSelector } from '@ngrx/store';
import { sideDrawerNetworkStateSelector } from './side-drawer-network.selectors';
import {
  selectAll,
  selectEntities,
} from './side-drawer-networks-requests-infinite-list.reducer';

export const sideDrawerNetworksRequestsInfiniteListStateSelector =
  createSelector(sideDrawerNetworkStateSelector, state => state?.infiniteList);

export const gettingSideDrawerNetworksInfiniteListSelector = createSelector(
  sideDrawerNetworksRequestsInfiniteListStateSelector,
  state => state?.gettingSideDrawerNetworks
);

export const sideDrawerNetworksInfiniteListItemsPerPageSelector =
  createSelector(
    sideDrawerNetworksRequestsInfiniteListStateSelector,
    state => state.itemsPerPage
  );

export const sideDrawerNetworksInfiniteListOrderSelector = createSelector(
  sideDrawerNetworksRequestsInfiniteListStateSelector,
  state => state.infiniteListOrder
);

export const sideDrawerNetworksInfiniteListOrderNextIdSelector = (payload: {
  currentId: string;
}) =>
  createSelector(sideDrawerNetworksInfiniteListOrderSelector, ids => {
    const index = ids.indexOf(payload.currentId);
    return ids[index + 1];
  });

export const sideDrawerNetworksRequestInfiniteListListsSelector =
  createSelector(
    sideDrawerNetworksRequestsInfiniteListStateSelector,
    selectAll
  );

export const sideDrawerNetworksRequestInfiniteListEntitiesSelector =
  createSelector(
    sideDrawerNetworksRequestsInfiniteListStateSelector,
    selectEntities
  );

export const sideDrawerNetworksRequestInfiniteListEntityByIdSelector =
  (payload: { id: string }) =>
    createSelector(
      sideDrawerNetworksRequestInfiniteListEntitiesSelector,
      entities => entities[payload.id]
    );

export const sideDrawerNetworksInfiniteListLastRequestIdSelector =
  createSelector(
    sideDrawerNetworksRequestsInfiniteListStateSelector,
    state => state.lastRequestId
  );

export const sideDrawerNetworksInfiniteListHasMoreSelector = createSelector(
  sideDrawerNetworksRequestInfiniteListListsSelector,
  request => request.some(request => request.hasMore)
);
