import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { AccessibilityHelper } from 'src/app/core/helpers/accessibility.helper';
import { SdAccessibilitySetting } from 'src/app/core/models/sd-accessibility-model';
import { DictionaryPipeModule } from '../../../../dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { environment } from 'src/environments/environment';
import { MatTooltip, MatTooltipModule } from '@angular/material/tooltip';
import { LiveAnnouncer } from '@angular/cdk/a11y';

@Component({
  selector: 'app-sd-tooltip-button-a11y',
  template: `
    <div
      *ngIf="!!tooltip && tooltip.length > 0"
      [matTooltip]="tooltip"
      #matTooltip="matTooltip"
      (focus)="onFocusTooltip()"
      [attr.aria-label]="sdAccessibility?.ariaLabel ?? tooltip"
      (mouseleave)="!!tooltip ? matTooltip.hide() : ''"
      [ngClass]="small ? 'tooltip-container-small' : 'tooltip-container'"
      [ngStyle]="{
        '-webkit-mask':
          'url(' +
          cdn +
          ('globalparams_tooltipicon' | dictionary | async) +
          ')' +
          'no-repeat 50% 50%',
        mask:
          'url(' +
          cdn +
          ('globalparams_tooltipicon' | dictionary | async) +
          ')' +
          'no-repeat 50% 50%'
      }"
      [attr.role]="'tooltip'"
      tabindex="{{ sdAccessibility?.tabIndex ?? 0 }}"></div>
  `,
  styles: [
    `
      .tooltip-container {
        margin-left: 0.375em;
        background-color: var(--tertiaryAccentColor);
        width: 1.25rem;
        height: 1.25rem;
        cursor: pointer;
      }

      .tooltip-container-small {
        width: 1.05rem;
        height: 1.05rem;
        background-color: var(--tertiaryAccentColor);
      }
      .tooltip-container:hover {
        background-color: var(--tertiaryAccentColor);
        color: var(--appBackground)
      }
    `,
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatTooltipModule, DictionaryPipeModule],
})
export class SdTooltipButtonA11yComponent {
  cdn = environment.cdn;
  @Input() tooltip: string;
  @Input() small = false;
  sdAccessibility: SdAccessibilitySetting;
  @ViewChild(MatTooltip) matTooltip: MatTooltip;

  @Input() set setSdAccessibility(value: SdAccessibilitySetting) {
    this.sdAccessibility = AccessibilityHelper.setDefaultAccessibility(value);
  }
  constructor(public readonly liveAnnouncer: LiveAnnouncer) {}

  onFocusTooltip(): void {
    this.liveAnnouncer.announce(this.tooltip);
  }
}
