import { Action } from '@ngrx/store';
import { SideDrawer } from '../models/side-drawer.model';
import { SideDrawerNetwork } from '../../sidedrawer-networks/models/side-drawer-network.model';
import { TransferOwnershipDto } from '../../networks/models/transfer-ownership.dto';
import { HttpErrorResponse } from '@angular/common/http';
import { CreateSidedrawerNetworkDto } from 'src/app/networks/models/create-sidedrawer-network.dto';

export enum SidedrawerActionsTypes {
  SetActiveSideDrawer = '[SideDrawer Service] Set Active SideDrawer',
  UpdateActiveSideDrawer = '[SideDrawer Service] Update Active SideDrawer',
  SetSideDrawerDefault = '[SideDrawer Service] Set SideDrawer Default',
  SideDrawerHomeRequested = '[Home main view] SideDrawer Home requested',
  SideDrawerHomeOnBackgroundRequested = '[Api] SideDrawer Home On The Background requested',
  SideDrawerHomeOnBackgroundLoaded = '[Api] SideDrawer Home On The Background Loaded',
  SideDrawerSpinnerChange = '[SideDrawer Service] SideDrawer spinner change',
  SideDrawerErrorChange = '[SideDrawer Service] SideDrawer Error change',
  SideDrawerCleanError = '[SideDrawer main view] Clean SideDrawer Errors',
  DeleteSideDrawerSuccess = '[Manage Sidedrawer View Component] Delete SideDrawer Success',
  DeleteSideDrawerError = '[Manage Sidedrawer View Component] Delete SideDrawer Errors',
  SideDrawerCreateRequested = '[SideDrawer Create SideDrawer Signup] SideDrawer Create Requested',
  SideDrawerCreateSpinnerChange = '[SideDrawer Effect] SideDrawer Create Spinner Change',
  SideDrawerCreateSuccess = '[SideDrawer Effect] SideDrawer Create Success',
  SideDrawerRequested = '[SideDrawer Effect] SideDrawer requested ',
  SideDrawerLoaded = '[SideDrawer Effect] SideDrawer Loaded ',
  SideDrawerTransferRequested = '[SideDrawer Transfer] SideDrawer Transfer Requested',
  SideDrawerTransferSpinnerChange = '[SideDrawer Effect] SideDrawer Transfer Spinner Change',
  SideDrawerTransferSuccess = '[SideDrawer Effect] SideDrawer Transfer Success',
  SideDrawerUpdateRequested = '[SideDrawer Effect] SideDrawer Update Requested',
  SideDrawerUpdateSuccess = '[SideDrawer Effect] SideDrawer Update Success',
  SideDrawerUpdateError = '[SideDrawer Effect] SideDrawer Update Error',
  SideDrawerDeleteRequested = '[SideDrawer Effect] SideDrawer Delete Requested',
  SideDrawerDeleteSuccess = '[SideDrawer Effect] SideDrawer Delete Success',
  SideDrawerUpdatedChange = '[SideDrawer Service] SideDrawer update state change',
  AddOwnerToSideDrawerRequested = '[SideDrawer Effect] Add owner to SideDrawer Requested',
  AddOwnerToSideDrawerSuccess = '[SideDrawer Effect] Add owner to SideDrawer Success',
  AddOwnerToSideDrawerError = '[SideDrawer Effect] Add owner to SideDrawer With error',
  SideDrawerCopyLinkRequested = '[] Copy Active SideDrawer Link Requested',
}

export class SetActiveSideDrawer implements Action {
  readonly type = SidedrawerActionsTypes.SetActiveSideDrawer;

  constructor(public payload: { data: SideDrawer }) {}
}

export class UpdateActiveSideDrawer implements Action {
  readonly type = SidedrawerActionsTypes.UpdateActiveSideDrawer;

  constructor(public payload: { data: SideDrawer }) {}
}

export class SetSideDrawerDefault implements Action {
  readonly type = SidedrawerActionsTypes.SetSideDrawerDefault;

  constructor(public payload: { data: SideDrawerNetwork }) {}
}

export class SideDrawerHomeRequested implements Action {
  readonly type = SidedrawerActionsTypes.SideDrawerHomeRequested;

  constructor(public payload: { sdId: string; localeDefault: string }) {}
}

export class SideDrawerHomeOnBackgroundRequested implements Action {
  readonly type = SidedrawerActionsTypes.SideDrawerHomeOnBackgroundRequested;

  constructor(public payload: { sdId: string; localeDefault: string }) {}
}

export class SideDrawerHomeOnBackgroundLoaded implements Action {
  readonly type = SidedrawerActionsTypes.SideDrawerHomeOnBackgroundLoaded;

  constructor(public payload: { data: SideDrawer }) {}
}

export class SideDrawerSpinnerChange implements Action {
  readonly type = SidedrawerActionsTypes.SideDrawerSpinnerChange;

  constructor(public payload: { state: boolean }) {}
}

export class SideDrawerErrorChange implements Action {
  readonly type = SidedrawerActionsTypes.SideDrawerErrorChange;

  constructor(public payload: { actionName: string }) {}
}

export class SideDrawerCleanError implements Action {
  readonly type = SidedrawerActionsTypes.SideDrawerCleanError;
}

export class DeleteSideDrawerSuccess implements Action {
  readonly type = SidedrawerActionsTypes.DeleteSideDrawerSuccess;

  constructor(
    public payload: { data: SideDrawerNetwork[]; localeDefault: string }
  ) {}
}

export class DeleteSideDrawerError implements Action {
  readonly type = SidedrawerActionsTypes.DeleteSideDrawerError;
}

export class SideDrawerCreateRequested implements Action {
  readonly type = SidedrawerActionsTypes.SideDrawerCreateRequested;

  constructor(public payload: { sideDrawer: SideDrawer }) {}
}

export class SideDrawerCreateSuccess implements Action {
  readonly type = SidedrawerActionsTypes.SideDrawerCreateSuccess;
}

export class SideDrawerCreateSpinnerChange implements Action {
  readonly type = SidedrawerActionsTypes.SideDrawerCreateSpinnerChange;

  constructor(public payload: { creatingSideDrawer: boolean }) {}
}

export class SideDrawerRequested implements Action {
  readonly type = SidedrawerActionsTypes.SideDrawerRequested;

  constructor(public payload: { sdId: string }) {}
}

export class SideDrawerLoaded implements Action {
  readonly type = SidedrawerActionsTypes.SideDrawerLoaded;
}

export class SideDrawerUpdateRequested implements Action {
  readonly type = SidedrawerActionsTypes.SideDrawerUpdateRequested;

  constructor(
    public payload: {
      sideDrawer: SideDrawer;
      sdNewName?: string;
      sdActiveEmail?: string;
      sdNotificationActive?: boolean;
    }
  ) {}
}

export class SideDrawerUpdateSuccess implements Action {
  readonly type = SidedrawerActionsTypes.SideDrawerUpdateSuccess;
}

export class SideDrawerUpdateError implements Action {
  readonly type = SidedrawerActionsTypes.SideDrawerUpdateError;

  constructor(
    public payload: { httpError: HttpErrorResponse; display404?: boolean }
  ) {}
}

export class SideDrawerDeleteRequested implements Action {
  readonly type = SidedrawerActionsTypes.SideDrawerDeleteRequested;

  constructor(
    public payload: {
      sideDrawer: SideDrawer;
    }
  ) {}
}

export class SideDrawerDeleteSuccess implements Action {
  readonly type = SidedrawerActionsTypes.SideDrawerDeleteSuccess;
}

export class SideDrawerUpdatedChange implements Action {
  readonly type = SidedrawerActionsTypes.SideDrawerUpdatedChange;

  constructor(public payload: { updating: boolean }) {}
}

export class AddOwnerToSideDrawerRequested implements Action {
  readonly type = SidedrawerActionsTypes.AddOwnerToSideDrawerRequested;

  constructor(
    public payload: {
      sideDrawerId: string;
      sideDrawerNetwork: CreateSidedrawerNetworkDto;
    }
  ) {}
}

export class AddOwnerToSideDrawerSuccess implements Action {
  readonly type = SidedrawerActionsTypes.AddOwnerToSideDrawerSuccess;
}

export class AddOwnerToSideDrawerError implements Action {
  readonly type = SidedrawerActionsTypes.AddOwnerToSideDrawerError;
}

export class SideDrawerTransferRequested implements Action {
  readonly type = SidedrawerActionsTypes.SideDrawerTransferRequested;

  constructor(public payload: { sideDrawer: TransferOwnershipDto }) {}
}

export class SideDrawerTransferSuccess implements Action {
  readonly type = SidedrawerActionsTypes.SideDrawerTransferSuccess;

  constructor(public payload: { sdId: string }) {}
}

export class SideDrawerTransferSpinnerChange implements Action {
  readonly type = SidedrawerActionsTypes.SideDrawerTransferSpinnerChange;

  constructor(public payload: { transferringSideDrawer: boolean }) {}
}

export class SideDrawerCopyLinkRequested implements Action {
  readonly type = SidedrawerActionsTypes.SideDrawerCopyLinkRequested;
}

export type SidedrawerActions =
  | SetActiveSideDrawer
  | UpdateActiveSideDrawer
  | SetSideDrawerDefault
  | SideDrawerHomeRequested
  | SideDrawerSpinnerChange
  | SideDrawerErrorChange
  | SideDrawerCleanError
  | DeleteSideDrawerSuccess
  | DeleteSideDrawerError
  | SideDrawerCreateRequested
  | SideDrawerCreateSuccess
  | SideDrawerCreateSpinnerChange
  | SideDrawerRequested
  | SideDrawerLoaded
  | SideDrawerTransferRequested
  | SideDrawerTransferSuccess
  | SideDrawerTransferSpinnerChange
  | SideDrawerUpdateRequested
  | SideDrawerUpdateSuccess
  | SideDrawerUpdateError
  | SideDrawerDeleteSuccess
  | SideDrawerDeleteRequested
  | SideDrawerUpdatedChange
  | AddOwnerToSideDrawerRequested
  | AddOwnerToSideDrawerSuccess
  | AddOwnerToSideDrawerError
  | SideDrawerCopyLinkRequested
  | SideDrawerHomeOnBackgroundLoaded;
