import { Injectable } from '@angular/core';

import { from, Observable } from 'rxjs';
import { GetResult, Preferences } from '@capacitor/preferences';
import { BiometricSecurityType } from '../store/biometrics.store';

@Injectable()
export class PreferencesService {
  clearAllInformation(): Observable<void> {
    return from(Preferences.clear());
  }

  getBiometricsPreferences(): Observable<GetResult> {
    return from(
      Preferences.get({
        key: 'biometrics',
      })
    );
  }

  setBiometricsPreferences(
    biometricSecurity: BiometricSecurityType
  ): Observable<void> {
    return from(
      Preferences.set({
        key: 'biometrics',
        value: biometricSecurity,
      })
    );
  }

  haveAlreadyLoggedInDevice(): Observable<GetResult> {
    return from(
      Preferences.get({
        key: 'loggedInDevice',
      })
    );
  }

  setFirstDeviceLogin(): Observable<void> {
    return from(
      Preferences.set({
        key: 'loggedInDevice',
        value: 'true',
      })
    );
  }
}
