import { SafeResourceUrl } from '@angular/platform-browser';
import {
  PlansViewsActions,
  PlansViewsActionsTypes,
} from './plans-views.actions';
import { PlansSortOption } from '../models/plans-sort-option.model';
import { Observable } from 'rxjs';

export interface PlansViewsState {
  progressSpinner: boolean;
  error: string;
  sortPlanListBy: PlansSortOption;
  queue: Map<string, Observable<unknown>>;
  hideCompleted: boolean;
}

export const initialPlansViewsState: PlansViewsState = {
  progressSpinner: true,
  error: null,
  sortPlanListBy: PlansSortOption.lastModified,
  queue: new Map<string, Observable<unknown>>(),
  hideCompleted: true,
};

export function plansViewsReducer(
  state: PlansViewsState = initialPlansViewsState,
  action: PlansViewsActions
): PlansViewsState {
  switch (action.type) {
    case PlansViewsActionsTypes.PlansViewsCleanError:
      return {
        ...state,
        error: null,
      };
    case PlansViewsActionsTypes.PlansViewsErrorChange:
      return {
        ...state,
        error: action.payload.actionName,
      };
    case PlansViewsActionsTypes.PlansViewsSpinnerChange:
      return {
        ...state,
        progressSpinner: action.payload.state,
      };
    case PlansViewsActionsTypes.PlansViewsSortPlanListOptionChange:
      return {
        ...state,
        sortPlanListBy: action.payload.option,
      };
    case PlansViewsActionsTypes.PlansDetailsAddQueue: {
      const aux = state.queue;
      const { key, value } = action.payload;
      aux.set(key, value);
      return {
        ...state,
        queue: aux,
      };
    }
    case PlansViewsActionsTypes.PlansDetailsRemoveQueue: {
      const auxDelete = state.queue;
      auxDelete.delete(action.payload['key']);
      return {
        ...state,
        queue: auxDelete,
      };
    }
    case PlansViewsActionsTypes.PlansDetailsRemoveFirstElementQueue: {
      const auxDelete = state.queue;
      const iterator = auxDelete.entries();
      const first = iterator.next();
      if (!first.done) {
        auxDelete.delete(first.value[0]);
      }
      return {
        ...state,
        queue: auxDelete,
      };
    }
    case PlansViewsActionsTypes.PlansDetailsClearQueue:
      return {
        ...state,
        queue: new Map<string, Observable<unknown>>(),
      };
    case PlansViewsActionsTypes.PlansViewsDisplayCompletedChange:
      return {
        ...state,
        hideCompleted: action.payload.hideCompleted,
      };
    default:
      return state;
  }
}
