import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { EnvelopeListItemStore } from '../envelope-list-item/envelope-list-item.store';
import { map } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { DocusignEnvelope } from '../../models/docusign-envelope.model';
import { DocusignEnvelopeDocument } from '../../models/docusign-envelope-document.model';
import { DocusignEnvelopeRecipient } from '../../models/docusign-envelope-recipient.model';
import { SignatureActions } from '../../store/signature.actions';
import { SdDialogTemplateA11yModule } from '../../../shared/templates/dialog-template-a11y/sd-dialog-template-a11y.module';
import { SdFlatButtonA11yModule } from '../../../shared/sd-flat-button-a11y/sd-flat-button-a11y.module';
import { DictionaryPipeModule } from '../../../dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { AsyncPipe, NgForOf, NgIf, TitleCasePipe } from '@angular/common';
import { SdProgressSpinnerA11yModule } from '../../../shared/sd-progress-spinner-a11y/sd-progress-spinner-a11y/sd-progress-spinner-a11y.module';
import { DateLocalePipeModule } from '../../../shared/sd-pipes/date-locale-pipe/date-locale-pipe.module';
import { DocumentListItemComponent } from '../document-list-item/document-list-item.component';
import { AccountTypeItemComponent } from '../../../shared/account-type-item/account-type-item.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-signature-details-dialog',
  standalone: true,
  imports: [
    SdDialogTemplateA11yModule,
    SdFlatButtonA11yModule,
    DictionaryPipeModule,
    AsyncPipe,
    NgIf,
    SdProgressSpinnerA11yModule,
    DateLocalePipeModule,
    DocumentListItemComponent,
    NgForOf,
    AccountTypeItemComponent,
    TitleCasePipe,
  ],
  templateUrl: './signature-details-dialog.component.html',
  styleUrls: ['./signature-details-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignatureDetailsDialogComponent implements OnDestroy, OnInit {
  gettingEnvelopeInformation$: Observable<boolean>;
  envelope$: Observable<DocusignEnvelope>;
  envelopeListItemStore: EnvelopeListItemStore;

  vm$: Observable<{
    gettingEnvelopeInformation: boolean;
    envelope: DocusignEnvelope;
  }>;

  constructor(
    private readonly store: Store<AppState>,
    private readonly dialogRef: MatDialogRef<SignatureDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    private readonly data: {
      envelopeId: string;
      envelopeListItemStoreRef: EnvelopeListItemStore;
    },
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  onClose(): void {
    this.dialogRef.close(true);
  }

  trackByDocuments(index, item: DocusignEnvelopeDocument): string {
    return item.documentId;
  }

  trackBySigners(index, item: DocusignEnvelopeRecipient): string {
    return item.userId;
  }

  ngOnInit(): void {
    this.envelopeListItemStore = this.data.envelopeListItemStoreRef;
    this.gettingEnvelopeInformation$ =
      this.envelopeListItemStore.gettingInformation$;
    this.envelope$ = this.envelopeListItemStore.envelope$;
    this.vm$ = combineLatest([
      this.gettingEnvelopeInformation$,
      this.envelope$,
    ]).pipe(
      map(([gettingEnvelopeInformation, envelope]) => ({
        gettingEnvelopeInformation,
        envelope,
      }))
    );
    this.envelopeListItemStore.getEnvelopeInformation(this.data.envelopeId);
    this.changeDetectorRef.markForCheck();
  }

  ngOnDestroy(): void {
    this.store.dispatch(SignatureActions.clearActiveEnvelope());
  }

  onViewOnDocusign(): void {
    this.envelopeListItemStore.onViewEnvelopeOnDocusign();
  }
}
