import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SdBreadcrumbsA11yConfig } from '../shared/sd-breadcrumbs-a11y/model/sd-breadcrumbs-a11y.model';
import { RemindersFormRoutes } from './routes/reminders-form.routes';
import { RemindersRoutes } from './routes/reminders.routes';
import { ReminderTemplateComponent } from './templates/reminder-template/components/reminder-template/reminder-template.component';

const routes: Routes = [
  {
    path: RemindersFormRoutes.singleFileRequest,
    component: ReminderTemplateComponent,
    children: [
      {
        path: RemindersFormRoutes.singleFileRequestCreate,
        loadComponent: () =>
          import(
            './views/single-file-request-view-a11y/single-file-request-view-a11y.component'
          ).then(m => m.SingleFileRequestViewA11yComponent),
        data: <SdBreadcrumbsA11yConfig>{
          breadcrumb: 'singlefilerequestview_breadcrumb',
        },
        title: 'singlefilerequestview_pagetitle',
      },
      {
        path: RemindersRoutes.all,
        loadComponent: () =>
          import(
            './views/reminder-list-all-view-a11y/reminder-list-all-view-a11y.component'
          ),
        title: 'reminderlistallview_pagetitle',
        data: <SdBreadcrumbsA11yConfig>{
          breadcrumb: 'reminderslistallview_breadcrumb',
        },
      },
      // TODO you must create a new view for this route, one for my app and another for my
      {
        path: `${RemindersFormRoutes.reminder}`,
        loadComponent: () =>
          import('./views/reminder-view/reminder-view.component').then(
            m => m.ReminderViewComponent
          ),
        title: 'reminderview_pagetitle',
      },
      // TODO you must create a new view for this route, one for my app and another for my
      {
        path: `${RemindersFormRoutes.reminder}/:reminderId`,
        loadComponent: () =>
          import('./views/reminder-view/reminder-view.component').then(
            m => m.ReminderViewComponent
          ),
        title: 'reminderview_pagetitle',
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: RemindersRoutes.all,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RemindersRoutingModule {}
