<div class="sd-search-container">
  <div
    [ngStyle]="{
      '-webkit-mask':
        'url(https://cdn.sidedrawer.com/svgs/search-regular.svg' +
        todayQuery +
        ') no-repeat center/contain',
      mask:
        'url(https://cdn.sidedrawer.com/svgs/search-regular.svg' +
        todayQuery +
        ') no-repeat center/contain'
    }"
    class="icon"></div>

  <input
    #inputElement
    [formControl]="searchController"
    [placeholder]="placeholder"
    [type]="'text'"
    class="sd-search-input" />
</div>
