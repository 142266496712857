import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { debounceTime, Observable, Subject, switchMap } from 'rxjs';
import { PrimaryResidence } from 'src/app/account/models/primary-residence.model';
import { Dictionary } from 'src/app/dictionary/models/dictionary.model';
import { dictionarySelector } from 'src/app/dictionary/store/dictionary.selectors';
import { AppState } from 'src/app/reducers';
import { SdInputA11yTemplates } from 'src/app/shared/sd-input-a11y/models/sd-input-a11y-templates.enum';
import { environment } from 'src/environments/environment';
import { PlaceOfResidenceDialogA11yComponent } from './shared/sd-place-of-residence-dialog-a11y/sd-place-of-residence-dialog-a11y.component';
import { MatDialog } from '@angular/material/dialog';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-sd-input-location-a11y',
  templateUrl: './sd-input-location-a11y.component.html',
  styleUrls: ['./sd-input-location-a11y.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SdInputLocationA11yComponent implements OnInit, AfterViewChecked {
  @Input() primaryResidenceSelected: PrimaryResidence;
  @Input() isFullLocationForm = false;
  @Input() showStringAddress: string;
  @Input() suffixFocusable = false;
  @Input() requiredFields = {};
  @Input() placeholders = {};
  @Input() title?: string;
  @Input() inputForView = false;
  @Input() disableClick = false;
  @Input() inputInTable = false;

  @Output() primaryResidenceSelectedChange =
    new EventEmitter<PrimaryResidence>();
  @Input() placeOfResidenceController = new UntypedFormControl(null);
  form: UntypedFormGroup;
  cdn = environment.cdn;
  dictionary$: Observable<Dictionary>;
  sdInputA11yTemplates = SdInputA11yTemplates;
  onClickHandlerSubject$ = new Subject<void>();

  constructor(
    private readonly dialog: MatDialog,
    private readonly store: Store<AppState>,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly destroyRef: DestroyRef
  ) {
    this.onClickHandlerSubject$
      .pipe(
        debounceTime(300),
        takeUntilDestroyed(this.destroyRef),
        switchMap(() => {
          return this.openDialogForPlaceOfResidence();
        })
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.dictionary$ = this.store.pipe(select(dictionarySelector));
    this.setFormGroup();
  }

  ngAfterViewChecked(): void {
    this.changeDetector.markForCheck();
  }

  onPlaceOfResidence(): void {
    this.onClickHandlerSubject$.next();
  }

  private openDialogForPlaceOfResidence(): Observable<void> {
    const dialogRef = this.dialog
      .open(PlaceOfResidenceDialogA11yComponent, {
        data: {
          isFullForm: this.isFullLocationForm,
          requiredFields: this.requiredFields,
          primaryResidence: {
            ...this.primaryResidenceSelected,
          },
          state: this.primaryResidenceSelected?.provinceState,
          placeholders: this.placeholders,
        },
        autoFocus: false,
      })
      .afterClosed();

    dialogRef.subscribe((primaryResidence: PrimaryResidence) => {
      if (primaryResidence) {
        this.primaryResidenceSelected = primaryResidence;
        this.placeOfResidenceController.setValue(
          this.primaryResidenceSelected.toAddress()
        );
        this.primaryResidenceSelectedChange.emit(this.primaryResidenceSelected);
      }
    });

    return dialogRef;
  }

  private setFormGroup(): void {
    this.form = new UntypedFormGroup({
      placeOfResidenceController: this.placeOfResidenceController,
    });
    if (this.showStringAddress?.length > 0) {
      this.placeOfResidenceController.setValue(this.showStringAddress);
      this.primaryResidenceSelected = new PrimaryResidence();
      this.primaryResidenceSelected.streetName = this.showStringAddress;
      this.primaryResidenceSelected?.toAddress();
    }
    if (!this.showStringAddress || this.showStringAddress?.length === 0) {
      this.placeOfResidenceController.setValue(
        this.primaryResidenceSelected?.toAddress()
      );
    }
  }
}
