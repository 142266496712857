import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { DocusignEnvelope } from '../models/docusign-envelope.model';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'envelopeStatus',
  standalone: true,
})
export class EnvelopeStatusPipe implements PipeTransform {
  transform(envelope$: Observable<DocusignEnvelope>): Observable<string> {
    return envelope$.pipe(map(envelope => envelope.status));
  }
}
