export enum RecordsSortOptions {
  lastModified = 'lastModified',
  nameAscending = 'nameAscending',
  nameDescending = 'nameDescending',
  typeAscending = 'typeAscending',
  typeDescending = 'typeDescending',
  lastModifiedByAscending = 'lastModifiedByAscending',
  lastModifiedByDescending = 'lastModifiedByDescending',
  tileAscending = 'tileAscending',
  tileDescending = 'tileDescending',
  oldestFirst = 'oldestFirst',
  newestFirst = 'newestFirst',
}
