import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DictionaryPipeModule } from 'src/app/dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { Reminders } from 'src/app/reminders/models/reminders.model';
import { SdFlatButtonA11yModule } from 'src/app/shared/sd-flat-button-a11y/sd-flat-button-a11y.module';
import { SdDialogTemplateA11yModule } from 'src/app/shared/templates/dialog-template-a11y/sd-dialog-template-a11y.module';
import { DialogTemplateTypes } from 'src/app/shared/templates/enums/templates.enum';

@Component({
  selector: 'app-reminders-delete-dialog',
  templateUrl: './reminders-delete-dialog.component.html',
  styleUrls: ['./reminders-delete-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    SdDialogTemplateA11yModule,
    SdFlatButtonA11yModule,
    DictionaryPipeModule,
  ],
})
export class RemindersDeleteDialogComponent {
  dialogTemplateTypes = DialogTemplateTypes;

  constructor(
    private readonly dialogRef: MatDialogRef<RemindersDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: { reminder: Reminders }
  ) {}

  onClose(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    this.dialogRef.close(true);
  }
}
