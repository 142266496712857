import { Pipe, PipeTransform } from '@angular/core';
import { MoneyInputFormatHelper } from '../../core/helpers/money-input-format.helper';

@Pipe({
  name: 'sdThousandSeparator',
  standalone: true,
})
export class SdThousandSeparatorPipe implements PipeTransform {
  transform(value: string): string {
    return MoneyInputFormatHelper.getThousandSeparator(value);
  }
}
