import { Action } from '@ngrx/store';
import { SubscriptionPlan } from '../models/subscription-plan.model';
import { License } from '../models/license.model';
import { SubscriptionFeatures } from '../../sidedrawer/models/subscription-features.model';
import { ErrorView } from '../../core/models/error-view.model';
import { GetCustomerDto } from '../models/get-customer.dto';
import { Price } from '../models/price.model';

export enum LicenseActionsTypes {
  SubscriptionPlansRequested = '[Licenses main view] Subscription Plans Requested',
  SubscriptionPlansLoaded = '[Licenses Service] Subscription Plans Loaded',
  PricesLoaded = '[Licenses Effect] Prices Loaded',
  AccountFeaturesRequested = '[License Service] Account Features Requested',
  AccountFeaturesLoaded = '[License Service] Account Features Loaded',
  LicensesRequested = '[Licenses main view] Licenses Requested',
  LicensesLoaded = '[Licenses Service] Licenses Loaded',
  LicensesClean = '[Licenses Service] Licenses Clean',
  LicensesSpinnerChange = '[Licenses Service] Licenses spinner change',
  LicensesErrorChange = '[Licenses Service] Licenses Error change',
  LicensesCleanError = '[Licenses main view] Clean Licenses Errors',
  AssignedLicensesLoaded = '[Assign Licenses view] Assign Licenses Loaded',
  SideDrawerWithNoLicenseLoaded = '[Assign Licenses view] SideDrawer With No License Loaded',
  UnAssignLicense = '[Assign Licenses view] Un Assign License',
  RestoreAssignedLicense = '[Assign Licenses view] Restore Assigned License',
  AssignLicenseToSD = '[Assign Licenses view] Assign a License to a SideDrawer with no License',
  NotAssignLicenseToSD = '[Assign Licenses view] NOT Assign a License to a SideDrawer with no License',
  CurrentCurrencyChange = '[Licenses filter by currency dialog] Licenses current currency filter change',
  LicenseCustomerLoaded = '[Licenses Effect] Licenses Customer Loaded',
  LicenseCustomerRequested = '[] License Customer Requested',
  GettingCustomerChange = '[License Effect] Getting Customer Change',
}

export class SubscriptionPlansRequested implements Action {
  readonly type = LicenseActionsTypes.SubscriptionPlansRequested;
}

export class PricesLoaded implements Action {
  readonly type = LicenseActionsTypes.PricesLoaded;

  constructor(public payload: { prices: Price[] }) {}
}

export class SubscriptionPlansLoaded implements Action {
  readonly type = LicenseActionsTypes.SubscriptionPlansLoaded;

  constructor(public payload: { data: SubscriptionPlan[] }) {}
}

export class AccountFeaturesRequested implements Action {
  readonly type = LicenseActionsTypes.AccountFeaturesRequested;
}

export class AccountFeaturesLoaded implements Action {
  readonly type = LicenseActionsTypes.AccountFeaturesLoaded;

  constructor(public payload: { data: SubscriptionFeatures }) {}
}

export class LicensesSpinnerChange implements Action {
  readonly type = LicenseActionsTypes.LicensesSpinnerChange;

  constructor(public payload: { state: boolean }) {}
}

export class LicensesErrorChange implements Action {
  readonly type = LicenseActionsTypes.LicensesErrorChange;

  constructor(public payload: { error: ErrorView }) {}
}

export class LicensesCleanError implements Action {
  readonly type = LicenseActionsTypes.LicensesCleanError;
}

export class LicensesLoaded implements Action {
  readonly type = LicenseActionsTypes.LicensesLoaded;

  constructor(public payload: { data: License[] }) {}
}

export class LicensesClean implements Action {
  readonly type = LicenseActionsTypes.LicensesClean;
}

export class AssignedLicensesLoaded implements Action {
  readonly type = LicenseActionsTypes.AssignedLicensesLoaded;

  constructor(public payload: { data: License[] }) {}
}

export class SideDrawerWithNoLicenseLoaded implements Action {
  readonly type = LicenseActionsTypes.SideDrawerWithNoLicenseLoaded;

  constructor(public payload: { data: License[] }) {}
}

export class UnAssignLicense implements Action {
  readonly type = LicenseActionsTypes.UnAssignLicense;

  constructor(public payload: { data: License }) {}
}

export class RestoreAssignedLicense implements Action {
  readonly type = LicenseActionsTypes.RestoreAssignedLicense;

  constructor(public payload: { data: License }) {}
}

export class AssignLicenseToSD implements Action {
  readonly type = LicenseActionsTypes.AssignLicenseToSD;

  constructor(public payload: { data: License }) {}
}

export class NotAssignLicenseToSD implements Action {
  readonly type = LicenseActionsTypes.NotAssignLicenseToSD;

  constructor(public payload: { data: License }) {}
}

export class CurrentCurrencyChange implements Action {
  readonly type = LicenseActionsTypes.CurrentCurrencyChange;

  constructor(public payload: { data: string }) {}
}

export class LicenseCustomerLoaded implements Action {
  readonly type = LicenseActionsTypes.LicenseCustomerLoaded;

  constructor(public payload: { customer: GetCustomerDto }) {}
}

export class LicenseCustomerRequested implements Action {
  readonly type = LicenseActionsTypes.LicenseCustomerRequested;
}

export class GettingCustomerChange implements Action {
  readonly type = LicenseActionsTypes.GettingCustomerChange;

  constructor(public payload: { status: boolean }) {}
}

export type LicenseActions =
  | SubscriptionPlansRequested
  | SubscriptionPlansLoaded
  | PricesLoaded
  | AccountFeaturesRequested
  | AccountFeaturesLoaded
  | LicenseCustomerLoaded
  | LicenseCustomerRequested
  | GettingCustomerChange
  | LicensesSpinnerChange
  | LicensesErrorChange
  | LicensesCleanError
  | LicensesLoaded
  | LicensesClean
  | AssignedLicensesLoaded
  | SideDrawerWithNoLicenseLoaded
  | UnAssignLicense
  | RestoreAssignedLicense
  | AssignLicenseToSD
  | NotAssignLicenseToSD
  | CurrentCurrencyChange;
