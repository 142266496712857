import { Action } from '@ngrx/store';
import { FilesSortOption } from '../models/file-sort-option.model';
import { PaginatorApiResponse } from '../../core/models/paginator-api-response.dto';
import { FileItem } from '../models/file-item.model';
import { FileType } from '../models/file-type.enum';

export enum FileHistoryActionsTypes {
  FileHistoryRequested = '[File Section / All Files View] File History Requested',
  FileHistoryNextPageRequested = '[File Section / All Files View] File History Next Page Requested',
  FileHistoryLoaded = '[FileHistory Effect] File History Loaded',
  FileHistoryClear = '[Switch Record] Clear File History Information',
  FileHistoryDeleted = '[Delete File Dialog] File History Deleted',
  GettingFilesChange = '[FileHistory Effect] Getting Files Change',
  FilesFilterChange = '[All Files View] Files Filter Change',
  FilesViewSortByChange = '[File History Sorting Dialog] Files View Sort By Change',
  OpenFileHistory = '[File History Item] Set current file to open or download one',
  CleanOpenFileHistory = '[File History Item] Clean open file information',
  SingleFileHistoryRequested = '[All Files View / PDFTron Dialog] Single File History Requested',
  SingleFileHistoryLoaded = '[File History Item] Single File History Loaded',
  GettingSingleFileHistoryChange = '[File History Item] Getting Single File History Change',
  TotalCountFileHistoryUpdate = '[File History Effect] Total Count File History Update',
}

export class FileHistoryRequested implements Action {
  readonly type = FileHistoryActionsTypes.FileHistoryRequested;

  constructor(
    public payload: {
      sideDrawerId: string;
      recordId: string;
      nextPage?: string;
      fileName?: string;
      uploadTitle?: string;
      fileType?: FileType;
    }
  ) {}
}

export class FileHistoryNextPageRequested implements Action {
  readonly type = FileHistoryActionsTypes.FileHistoryNextPageRequested;
}

export class FileHistoryLoaded implements Action {
  readonly type = FileHistoryActionsTypes.FileHistoryLoaded;

  constructor(public payload: { data: PaginatorApiResponse<FileItem> }) {}
}

export class FileHistoryClear implements Action {
  readonly type = FileHistoryActionsTypes.FileHistoryClear;
}

export class FileHistoryDeleted implements Action {
  readonly type = FileHistoryActionsTypes.FileHistoryDeleted;

  constructor(public payload: { id: string }) {}
}

export class GettingFilesChange implements Action {
  readonly type = FileHistoryActionsTypes.GettingFilesChange;

  constructor(public payload: { state: boolean }) {}
}

export class FilesFilterChange implements Action {
  readonly type = FileHistoryActionsTypes.FilesFilterChange;

  constructor(
    public payload: {
      fileName?: string;
      uploadTitle?: string;
      fileType?: FileType;
    }
  ) {}
}

export class FilesViewSortByChange implements Action {
  readonly type = FileHistoryActionsTypes.FilesViewSortByChange;

  constructor(public payload: { option: FilesSortOption }) {}
}

export class OpenFileHistory implements Action {
  readonly type = FileHistoryActionsTypes.OpenFileHistory;

  constructor(public payload: { id: string }) {}
}

export class CleanOpenFileHistory implements Action {
  readonly type = FileHistoryActionsTypes.CleanOpenFileHistory;
}

export class SingleFileHistoryRequested implements Action {
  readonly type = FileHistoryActionsTypes.SingleFileHistoryRequested;

  constructor(
    public payload: {
      sideDrawerId: string;
      recordId: string;
      fileId: string;
      display404?: boolean;
    }
  ) {}
}

export class SingleFileHistoryLoaded implements Action {
  readonly type = FileHistoryActionsTypes.SingleFileHistoryLoaded;

  constructor(public payload: { fileItem: FileItem }) {}
}

export class GettingSingleFileHistoryChange implements Action {
  readonly type = FileHistoryActionsTypes.GettingSingleFileHistoryChange;

  constructor(public payload: { state: boolean }) {}
}

export class TotalCountFileHistoryUpdate implements Action {
  readonly type = FileHistoryActionsTypes.TotalCountFileHistoryUpdate;
  constructor(public payload: { totalCount: number }) {}
}

export type FileHistoryActions =
  | FileHistoryRequested
  | FileHistoryNextPageRequested
  | FileHistoryLoaded
  | FileHistoryClear
  | FileHistoryDeleted
  | GettingFilesChange
  | FilesFilterChange
  | FilesViewSortByChange
  | OpenFileHistory
  | CleanOpenFileHistory
  | SingleFileHistoryRequested
  | SingleFileHistoryLoaded
  | GettingSingleFileHistoryChange
  | TotalCountFileHistoryUpdate;
