<div class="sd-snack-bar">
  <span class="sd-snack-bar-message"> {{ data.message }}</span>
  <div *ngIf="data.action" class="mat-simple-snackbar-action">
    <app-sd-flat-button-a11y (buttonClicked)="onAction()" [primary]="true"
      ><span class="sd-snack-bar-action">{{
        data.action.text
      }}</span></app-sd-flat-button-a11y
    >
  </div>
</div>
