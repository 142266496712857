import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { Injectable } from '@angular/core';
import { Record } from '../../models/record.model';
import { RecordsService } from '../../services/records.service';
import { exhaustMap, forkJoin, Observable, of, switchMap } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import {
  activeRecordSelector,
  activeRecordSubTypeSelector,
  activeRecordTypeSelector,
} from '../../store/records-list.selectors';
import { map, take, tap } from 'rxjs/operators';
import { localeDefaultSelector } from '../../../dictionary/store/dictionary.selectors';
import { Locale } from '../../../dictionary/models/locale.model';
import { activeSideDrawerSelector } from '../../../sidedrawer/store/sidedrawer.selector';
import {
  ActiveRecordRequested,
  RecordsListRequested,
  SetActiveRecord,
  SetActiveRecordFail,
} from '../../store/records-list.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { SideDrawerHomeOnBackgroundRequested } from '../../../sidedrawer/store/sidedrawer.actions';
import { CustomField } from '../../models/custom-field.model';
import { UtilsHelper } from '../../../core/helpers/utils.helper';
import { RecordDetails } from '../../models/record-details.model';
import { RecordType } from '../../models/record-type.model';
import { RecordSubType } from '../../models/record-sub-type.model';
import { RecordsRoutes } from '../../routes/records.routes';
import { Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';

export interface RecordFormViewState {
  gettingInformation: boolean;
  name: string;
  recordSubType: string;
  storageLocation: string;
  description: string;
  recordDetails: RecordDetails;
  customFields: Map<string | number, CustomField>;
  focusMandatoryInputs: boolean;
  recordTypeName?: string;
  typeControllerForIntegration?: FormControl;
  formGroup?: FormGroup;
}

@Injectable()
export class RecordFormViewStore extends ComponentStore<RecordFormViewState> {
  readonly requestInProgress$ = this.select(state => state.gettingInformation);
  readonly name$ = this.select(state => state.name);
  readonly recordSubType$ = this.select(state => state.recordSubType);
  readonly storageLocation$ = this.select(state => state.storageLocation);
  readonly description$ = this.select(state => state.description);
  readonly customFields$ = this.select(state => state.customFields);
  readonly recordFormState = this.selectSignal(state => ({ ...state }));

  readonly confirmEnabled$ = this.select(state => {
    if (state.name?.trim()?.length === 0) {
      return false;
    }
    const recordTypeName =
      state.recordTypeName ??
      this.store.selectSignal(activeRecordTypeSelector)()?.name;
    if (recordTypeName?.trim()?.length === 0) {
      return false;
    }
    return !(
      [...state.customFields.values()].filter(
        cfo => cfo.label === '' && (cfo.value !== '' || cfo.value === null)
      ).length > 0
    );
  });
  readonly focusMandatoryInputs$ = this.select(
    state => state.focusMandatoryInputs
  );
  readonly formGroupValid = this.selectSignal(state => {
    return state.formGroup?.valid ?? false;
  });
  readonly createRecord = this.effect(
    (trigger$: Observable<(record: Record) => void>) =>
      trigger$.pipe(
        tap(() => this.patchState({ gettingInformation: true })),
        switchMap(callback =>
          forkJoin([
            this.store.select(activeSideDrawerSelector).pipe(take(1)),
            this.store.select(activeRecordTypeSelector).pipe(take(1)),
            this.store.select(activeRecordSubTypeSelector).pipe(take(1)),
            this.store.select(localeDefaultSelector).pipe(take(1)),
            this.select(state => state).pipe(take(1)),
            of(callback),
          ])
        ),
        map(
          ([
            activeSideDrawer,
            recordType,
            recordSubType,
            locale,
            state,
            callback,
          ]) => ({
            sideDrawerId: activeSideDrawer.id,
            record: this.generateRecordDto(state, recordType, recordSubType),
            locale: Locale.getLocaleId(locale),
            callback,
          })
        ),
        exhaustMap(({ sideDrawerId, record, locale, callback }) =>
          this.recordsService.createRecord1(sideDrawerId, record).pipe(
            tapResponse(
              ({ id }) => {
                this.patchState({ gettingInformation: false });
                const newRecord = { ...record, id };
                this.store.dispatch(new SetActiveRecord({ data: newRecord }));
                this.store.dispatch(
                  new RecordsListRequested({
                    sideDrawerId: sideDrawerId,
                    locale: locale,
                    recordTypeName: record.recordTypeName,
                  })
                );
                this.store.dispatch(
                  new SideDrawerHomeOnBackgroundRequested({
                    sdId: sideDrawerId,
                    localeDefault: locale,
                  })
                );
                if (callback) {
                  callback(newRecord);
                }
              },
              (error: HttpErrorResponse) => {
                this.patchState({ gettingInformation: false });
                this.store.dispatch(new SetActiveRecordFail({ error }));
              }
            )
          )
        )
      )
  );
  readonly updateRecord = this.effect(
    (trigger$: Observable<(record: Record) => void>) =>
      trigger$.pipe(
        tap(() => this.patchState({ gettingInformation: true })),
        switchMap(callback =>
          forkJoin([
            this.store.select(activeSideDrawerSelector).pipe(take(1)),
            this.store.select(activeRecordSelector).pipe(take(1)),
            this.store.select(activeRecordTypeSelector).pipe(take(1)),
            this.store.select(activeRecordSubTypeSelector).pipe(take(1)),
            this.store.select(localeDefaultSelector).pipe(take(1)),
            this.select(state => state).pipe(take(1)),
            of(callback),
          ])
        ),
        map(
          ([
            activeSideDrawer,
            record,
            recordType,
            recordSubType,
            locale,
            state,
            callback,
          ]) => ({
            sideDrawerId: activeSideDrawer.id,
            recordId: record.id,
            record: this.generateRecordDto(state, recordType, recordSubType),
            locale: Locale.getLocaleId(locale),
            callback,
          })
        ),
        exhaustMap(({ sideDrawerId, recordId, record, locale, callback }) =>
          this.recordsService
            .updateRecord2(sideDrawerId, recordId, record)
            .pipe(
              tapResponse(
                ({ id }) => {
                  this.patchState({ gettingInformation: false });
                  const newRecord = { ...record, id };
                  this.store.dispatch(new SetActiveRecord({ data: newRecord }));
                  this.store.dispatch(
                    new RecordsListRequested({
                      sideDrawerId: sideDrawerId,
                      locale: locale,
                      recordTypeName: record.recordTypeName,
                    })
                  );
                  this.store.dispatch(
                    new SideDrawerHomeOnBackgroundRequested({
                      sdId: sideDrawerId,
                      localeDefault: locale,
                    })
                  );
                  if (callback) {
                    callback(newRecord);
                  }
                },
                (error: HttpErrorResponse) => {
                  this.patchState({ gettingInformation: false });
                  this.store.dispatch(new SetActiveRecordFail({ error }));
                }
              )
            )
        )
      )
  );

  readonly updateRecordForIntegration = this.effect(
    (trigger$: Observable<(record: Record) => void>) =>
      trigger$.pipe(
        tap(() => this.patchState({ gettingInformation: true })),
        switchMap(callback =>
          forkJoin([
            this.store.select(activeSideDrawerSelector).pipe(take(1)),
            this.store.select(activeRecordSelector).pipe(take(1)),
            this.store.select(activeRecordTypeSelector).pipe(take(1)),
            this.store.select(activeRecordSubTypeSelector).pipe(take(1)),
            this.store.select(localeDefaultSelector).pipe(take(1)),
            this.select(state => state).pipe(take(1)),
            of(callback),
          ])
        ),
        map(
          ([
            activeSideDrawer,
            record,
            recordType,
            recordSubType,
            locale,
            state,
            callback,
          ]) => ({
            sideDrawerId: activeSideDrawer.id,
            recordId: record.id,
            record: this.generateRecordDto(
              state,
              recordType,
              recordSubType,
              record
            ),
            locale: Locale.getLocaleId(locale),
            callback,
          })
        ),
        exhaustMap(({ sideDrawerId, recordId, record, callback }) =>
          this.recordsService
            .updateRecord2(sideDrawerId, recordId, record)
            .pipe(
              tapResponse(
                ({ id }) => {
                  this.patchState({ gettingInformation: false });
                  const newRecord = { ...record, id };
                  if (callback) {
                    callback(newRecord);
                  }
                },
                (error: HttpErrorResponse) => {
                  this.patchState({ gettingInformation: false });
                  this.store.dispatch(new SetActiveRecordFail({ error }));
                }
              )
            )
        )
      )
  );

  readonly getOrCreateRecord = this.effect(
    (trigger$: Observable<(record: Record) => void>) =>
      trigger$.pipe(
        switchMap(callback =>
          forkJoin([
            this.confirmEnabled$.pipe(take(1)),
            this.store.select(activeRecordSelector).pipe(take(1)),
            of(callback).pipe(take(1)),
          ])
        ),
        tap(([enabled, activeRecord, callback]) => {
          if (!enabled && !activeRecord) {
            this.focusMandatoryInputs();
            return;
          }
          if (!activeRecord) {
            this.createRecord(record => {
              this.store.dispatch(
                new ActiveRecordRequested({
                  recordId: record.id,
                })
              );
              const url = this.router.url.replace(
                `${RecordsRoutes.new}`,
                record.id
              );
              this.router.navigateByUrl(url).then(() => callback(record));
            });
            return;
          }
          callback(activeRecord);
        })
      )
  );

  constructor(
    private readonly recordsService: RecordsService,
    private readonly store: Store<AppState>,
    private readonly router: Router
  ) {
    super({
      gettingInformation: false,
      name: null,
      recordSubType: null,
      storageLocation: null,
      description: null,
      recordDetails: {},
      customFields: UtilsHelper.arrayToMap('id', [new CustomField()]),
      focusMandatoryInputs: false,
      formGroup: null,
    });
  }

  setInformationFromRecord(record: Record): void {
    this.patchState({
      name: record.name,
      recordSubType:
        !!record.recordSubtypeOther && record.recordSubtypeOther.length > 0
          ? record.recordSubtypeOther
          : record.recordSubtype.displayValue[0].value,
      storageLocation: record.storageLocation,
      description: record.description,
      recordDetails: record.recordDetails,
      customFields: UtilsHelper.arrayToMap('id', [
        ...(record.recordDetails?.customFields ?? []),
      ]),
    });
    if (this.get().customFields.size === 0) {
      const customFieldsMapAux = new Map<string | number, CustomField>();
      const newCf = new CustomField();
      customFieldsMapAux.set(newCf.id, newCf);
      this.customFieldsSortItems(customFieldsMapAux);
    }
  }

  setName(name: string): void {
    this.patchState({ name: name ?? '' });
  }

  get getName(): string {
    return this.get().name;
  }

  get typeControllerForIntegration(): FormControl {
    return this.get().typeControllerForIntegration;
  }

  setRecordSubType(recordSubType: string): void {
    this.patchState({ recordSubType: recordSubType ?? '' });
  }

  setStorageLocation(storageLocation: string): void {
    this.patchState({ storageLocation: storageLocation ?? '' });
  }

  setDescription(description: string): void {
    this.patchState({ description: description ?? '' });
  }

  customFieldValuesChanged(event: CustomField): void {
    this.patchState(state => {
      const customFields = new Map(state.customFields);
      customFields.set(event.id, event);
      return {
        ...state,
        customFields,
      };
    });
  }

  customFieldDeleted(key: string): void {
    this.patchState(state => {
      const customFields = new Map(state.customFields);
      customFields.delete(key);
      return {
        ...state,
        customFields,
      };
    });
  }

  customFieldsSortItems(customFields: Map<string | number, CustomField>): void {
    this.patchState(state => {
      return {
        ...state,
        customFields,
      };
    });
  }

  customFieldAddItem(id: string): void {
    const customFieldsMapAux = new Map<string | number, CustomField>();
    this.get().customFields.forEach(cf => {
      customFieldsMapAux.set(cf.id, cf);
      if (cf.id === id) {
        const newCf = new CustomField();
        customFieldsMapAux.set(newCf.id, newCf);
      }
    });
    this.customFieldsSortItems(customFieldsMapAux);
  }

  specificFieldValueChanges(key: string, value: unknown): void {
    this.patchState(state => {
      return {
        ...state,
        recordDetails: {
          ...state.recordDetails,
          [key]: value,
        },
      };
    });
  }

  focusMandatoryInputs(): void {
    this.typeControllerForIntegration?.markAsTouched();
    this.patchState({ focusMandatoryInputs: true });
  }

  private generateRecordDto(
    state: RecordFormViewState,
    recordType: RecordType,
    recordSubType: RecordSubType,
    activeRecord?: Record
  ): Record {
    const dto: Record = {
      name: state.name?.length === 0 ? activeRecord?.name : state.name,
      recordTypeName: state.recordTypeName ?? recordType?.name,
      recordSubtypeName: recordSubType?.name ?? 'other',
      storageLocation: state.storageLocation ?? '',
      description: state.description ?? '',
      recordDetails: {
        ...(state.recordDetails ?? {}),
        customFields: UtilsHelper.mapToArray(state.customFields).filter(
          cf =>
            cf.label !== null &&
            cf.label?.trim().length > 0 &&
            cf.value !== null &&
            cf.value?.toString().trim().length > 0
        ),
      },
    };
    if (dto.recordSubtypeName === 'other') {
      dto.recordSubtypeOther = state.recordSubType;
    }
    return dto;
  }
}
