import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ErrorType } from '../models/enums/error-type.enum';
import { Reminders } from '../models/reminders.model';
import { SfrError } from '../models/sfr-error.model';
import {
  RemindersListActions,
  RemindersListActionsTypes,
} from './reminders-list.action';

export const featureName = 'reminders';

export interface RemindersListState extends EntityState<Reminders> {
  gettingReminders: boolean;
  errors: Map<string | ErrorType, SfrError>;
  activeReminder: Reminders;
}

export const reminderListAdapter: EntityAdapter<Reminders> =
  createEntityAdapter<Reminders>({
    selectId: reminder => reminder.id,
  });

export const initialRemindersListState: RemindersListState =
  reminderListAdapter.getInitialState({
    gettingReminders: false,
    errors: new Map(),
    activeReminder: null,
  });

export function RemindersListReducer(
  state: RemindersListState = initialRemindersListState,
  action: RemindersListActions
): RemindersListState {
  switch (action.type) {
    case RemindersListActionsTypes.RemindersLoaded:
      return reminderListAdapter.upsertMany(action.payload.reminders, state);
    case RemindersListActionsTypes.ReminderCreated:
      return reminderListAdapter.addOne(action.payload.reminder, state);
    case RemindersListActionsTypes.ReminderUpdated:
      return reminderListAdapter.updateOne(action.payload.reminder, state);
    case RemindersListActionsTypes.ReminderDeleted:
      return reminderListAdapter.removeOne(action.payload.id, state);
    case RemindersListActionsTypes.ReminderChangeStatus:
      return reminderListAdapter.updateOne(action.payload.reminder, state);
    case RemindersListActionsTypes.ReminderRemovedAll:
      return reminderListAdapter.removeAll(state);
    case RemindersListActionsTypes.ReminderAddError:
      return {
        ...state,
        errors: new Map([...state.errors, ...action.payload.errors]),
      };
    case RemindersListActionsTypes.ReminderSetErrors:
      return {
        ...state,
        errors: action.payload.errors,
      };
    case RemindersListActionsTypes.ReminderSpinnerChange:
      return {
        ...state,
        gettingReminders: action.payload.state,
      };
    case RemindersListActionsTypes.ReminderGetReminderByIdSuccess:
      return {
        ...state,
        activeReminder: action.payload.reminder,
      };
    default:
      return state;
  }
}

export const { selectAll } = reminderListAdapter.getSelectors();
