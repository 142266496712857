import { environment } from './../../../../../../environments/environment.sf';
import { UtilsHelper } from 'src/app/core/helpers/utils.helper';
import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { Locale } from 'src/app/dictionary/models/locale.model';
import { localeDefaultSelector } from 'src/app/dictionary/store/dictionary.selectors';
import { AppState } from 'src/app/reducers';
import { Option } from 'src/app/shared/sd-forms/models/option.model';
import { recordTypesSelector } from 'src/app/sidedrawer/store/sidedrawer.selector';
import { RecordType } from '../../../../models/record-type.model';
import { RecordsService } from '../../../../services/records.service';
import { RecordsSubtypesLoaded } from '../../../../store/records-list.actions';
import { CommonModule } from '@angular/common';
import { DictionaryPipeModule } from '../../../../../dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { SdSelectorA11Module } from 'src/app/shared/sd-selector-a11y/sd-selector-a11y.module';
import { SdAccessibilitySetting } from '../../../../../core/models/sd-accessibility-model';
import { AccessibilityHelper } from '../../../../../core/helpers/accessibility.helper';
import { SdTooltipButtonA11yComponent } from 'src/app/shared/sd-tooltip-button-a11y/component/sd-tooltip-button-a11y/sd-tooltip-button-a11y.component';
import { RecordTypeAutocompleteA11yTemplates } from '../../model/record-type-autocomplete-a11y.model';
import { SdLinkMenuA11yModule } from 'src/app/shared/sd-link-menu-a11y/sd-link-menu-a11y.module';
import { SdColorPalette } from 'src/app/core/models/enums/sd-color-palette-enum';
import { SdLinkMenuButtonType } from 'src/app/shared/sd-link-menu-a11y/models/enums/sd-link-menu-button-type-enum';
import { SdLinkMenuItem } from 'src/app/shared/sd-link-menu-a11y/models/sd-link-menu-item.model';
import { SdLinkMenuLinkType } from 'src/app/shared/sd-link-menu-a11y/models/enums/sd-link-menu-link-type-enum';
import { filingCabinetSDRecordTypeSelectorBySideDrawerId } from '../../../../../filing-cabinet/store/filing-cabinet.store';
import { SdSvgA11yModule } from 'src/app/shared/sd-svg-a11y/sd-svg-a11y.module';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-record-type-autocomplete-a11y',
  templateUrl: './record-type-autocomplete-a11y.component.html',
  styleUrls: ['./record-type-autocomplete-a11y.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    DictionaryPipeModule,
    SdSelectorA11Module,
    SdTooltipButtonA11yComponent,
    SdLinkMenuA11yModule,
    SdSvgA11yModule,
    MatTooltipModule,
  ],
})
export class RecordTypeAutocompleteA11yComponent
  implements OnInit, AfterViewChecked, OnDestroy
{
  controller: UntypedFormControl;
  @Input() emitRecordSubTypes = true;
  @Input() set setController(value: UntypedFormControl) {
    this.controller = value;
    this.controller.setValue(
      !!this.controller.value && this.controller.value.length > 0
        ? this.controller.value
        : ''
    );
  }

  @Input() startValue: RecordType;
  @Input() readOnly = false;

  @Input() set setRecordTypeName(value: string) {
    this.recordTypeName = value;
    this.iconType = this.getRecordTypeIcon(this.recordTypeName);
  }

  @Input() placeholder: string;
  @Input() error: string;
  @Output() typeSelected = new EventEmitter<string>();
  @Output() typeSubtypesMapEmitter = new EventEmitter<
    Map<string, RecordsSubtypesLoaded>
  >();
  recordTypeName: string;
  types: Option[] = [];
  subscription = new Subscription();
  typeSubtypesMap: Map<string, RecordsSubtypesLoaded> = new Map<
    string,
    RecordsSubtypesLoaded
  >();
  sdAccessibility: SdAccessibilitySetting;
  recordTypeAutocompleteA11yTemplates = RecordTypeAutocompleteA11yTemplates;
  template: RecordTypeAutocompleteA11yTemplates =
    RecordTypeAutocompleteA11yTemplates.default;
  localeId: string;

  @Input() set setTemplate(value: RecordTypeAutocompleteA11yTemplates) {
    this.template = value;
  }
  sdColorPalette = SdColorPalette;
  sdLinkMenuButtonType = SdLinkMenuButtonType;
  menuRecordTypeOptions: SdLinkMenuItem[] = [];
  iconType: string; // menuLinkIcon
  cdn = environment.cdn;

  constructor(
    private readonly store: Store<AppState>,
    private recordsService: RecordsService,
    private readonly changeDetector: ChangeDetectorRef
  ) {}

  @Input() set setSdAccessibility(value: SdAccessibilitySetting) {
    this.sdAccessibility = AccessibilityHelper.setDefaultAccessibility(value);
  }
  @Input() set setCustomRecordTypes(customRecordTypesWithSd: {
    sideDrawerId: string;
    customRecordTypes: RecordType[];
  }) {
    this.initRecordTypes(
      customRecordTypesWithSd.sideDrawerId,
      customRecordTypesWithSd.customRecordTypes
    );
  }

  @Input({ required: true }) set setActiveSideDrawer(sideDrawerId: string) {
    if (!sideDrawerId) {
      return;
    }
    this.initRecordTypes(sideDrawerId);
  }

  ngOnInit(): void {
    this.setLocaleDefault();
    this.subscription.add(
      this.controller.valueChanges
        .pipe(
          tap((type: string) => {
            if (this.controller.pristine) {
              return;
            }
            // TODO set type here
            this.iconType = this.getRecordTypeIcon(type);
            this.typeSelected.emit(type);
          })
        )
        .subscribe()
    );
  }

  initRecordTypes(
    sideDrawerId?: string,
    customRecordTypes?: RecordType[]
  ): void {
    forkJoin([
      this.store.select(localeDefaultSelector).pipe(take(1)),
      this.store.select(recordTypesSelector).pipe(take(1)),
      this.store
        .select(
          filingCabinetSDRecordTypeSelectorBySideDrawerId({ sideDrawerId })
        )
        .pipe(take(1)),
      of(customRecordTypes).pipe(take(1)),
    ])
      .pipe(
        tap(([locale, types, fcRecordTypes, customRecordTypes]) => {
          this.localeId = Locale.getLocaleId(locale);
          const localeId = Locale.getLocaleId(locale);
          const recordTypes =
            fcRecordTypes?.length > 0
              ? fcRecordTypes
              : customRecordTypes?.length > 0
              ? customRecordTypes
              : types;

          if (!!recordTypes && recordTypes.length > 0) {
            this.types = [];
            recordTypes.forEach(type => {
              if (type.name !== 'other') {
                const option: Option = {
                  key: type.name,
                  value: UtilsHelper.getDisplayValueFromLocaleId(
                    type?.displayValue,
                    localeId
                  ),
                };
                this.types.push(option);
              }
            });
            this.sortArrayRecordTypeOption();
            this.getmenuRecordTypeOptions(recordTypes);
          }
          this.controller?.setValue(
            !!this.controller?.value && this.controller?.value?.length > 0
              ? this.controller?.value
              : ''
          );

          const calls: Observable<RecordsSubtypesLoaded>[] = [];
          this.typeSubtypesMap = new Map();
          this.typeSubtypesMapEmitter.emit(this.typeSubtypesMap);
          recordTypes.forEach(type => {
            calls.push(
              this.recordsService
                .getRecordSubsTypes(type.name, Locale.getLocaleId(locale))
                .pipe(
                  map(response => {
                    return new RecordsSubtypesLoaded({ data: response }); // TODO refactor this using store model.
                  }),
                  tap(action => {
                    this.typeSubtypesMap.set(type.name, action);
                  })
                )
            );
          });
          if (this.emitRecordSubTypes) {
            forkJoin(calls).subscribe(() => {
              this.typeSubtypesMapEmitter.emit(this.typeSubtypesMap);
            });
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private sortArrayRecordTypeOption(): void {
    this.types.sort((x: Option, y: Option): number => {
      if (x.value < y.value) {
        return -1;
      }
      if (x.value > y.value) {
        return 1;
      }
      return 0;
    });
  }

  onRecordTypeSelected(sdLinkMenuItem: SdLinkMenuItem): void {
    this.iconType = sdLinkMenuItem.menuLinkIcon;
    this.typeSelected.emit(sdLinkMenuItem.key);
    this.menuRecordTypeOptions.forEach(option => {
      option.active = option.key === sdLinkMenuItem.key ? true : false;
    });
  }

  // TODO create a observable or pipe fotr this...
  getmenuRecordTypeOptions(recordsTypes: RecordType[]): void {
    this.menuRecordTypeOptions = recordsTypes.map(option => {
      const menuLink = new SdLinkMenuItem();
      menuLink.key = option?.name;

      menuLink.menuLinkText = UtilsHelper.getDisplayValueFromLocaleId(
        option?.displayValue,
        this.localeId
      );

      menuLink.menuLinkType = SdLinkMenuLinkType.emitter;
      menuLink.menuLinkIcon = option.logo;
      menuLink.visible = true;
      return menuLink;
    });
  }

  private getRecordTypeIcon(recordType: string): string {
    const recordTypeIcon = this.menuRecordTypeOptions.find(
      x => x.key === recordType
    );
    return recordTypeIcon?.menuLinkIcon;
  }

  private setLocaleDefault(): void {
    this.store
      .pipe(
        select(localeDefaultSelector),
        take(1),
        tap(locale => {
          this.localeId = Locale.getLocaleId(locale);
        })
      )
      .subscribe();
  }

  ngAfterViewChecked(): void {
    this.changeDetector.markForCheck();
  }
}
