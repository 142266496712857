<div class="envelope-list-item">
  <div
    (click)="onViewEnvelope()"
    (keydown)="onViewEnvelope()"
    class="envelope-list-item-title">
    <span>{{ (envelope$ | async).emailSubject }}</span>
    <b>({{ envelope$ | envelopeStatus | async }}) </b>
  </div>
  <div
    (click)="onViewEnvelope()"
    (keydown)="onViewEnvelope()"
    class="envelope-list-item-date">
    <span>{{ 'envelopelistitem_lastchange' | dictionary | async }}</span>
    <b>{{ envelope$ | envelopeDate | async | dateLocale: 'LLL' | async }}</b>
  </div>
  <div
    (click)="onViewEnvelope()"
    (keydown)="onViewEnvelope()"
    class="envelope-list-item-avatars">
    <div
      *ngFor="
        let avatar of envelope$
          | envelopeRecipients
          | emailsToItemListAvatar
          | async;
        index as i
      "
      class="envelope-list-item-avatars-avatar">
      <div
        [ngClass]="{ 'plus-avatar': avatar.includes('+') }"
        [style.background-color]="
          avatar.includes('+')
            ? 'var(--appBackground)'
            : (i | avatarBackgroundColor)
        "
        class="circle-avatar">
        {{ avatar }}
      </div>
    </div>
  </div>
  <div
    *ngIf="
      (gettingInformation$ | async) === false;
      else envelopeListItemSpinner
    "
    class="envelope-list-item-actions">
    <app-sd-link-menu-a11y
      (emitterClicked)="onEmitterClicked($event)"
      [accessibility]="{
        ariaLabel: 'envelopelistitem_actionsmenu' | dictionary | async,
        tabIndex: -1
      }"
      [border]="false"
      [buttonHeight]="1"
      [disableRipple]="true"
      [iconColor]="SdColorPalette.primaryAccentColor"
      [icon]="
        cdn + ('globalparams_horizontalactionmenuicon' | dictionary | async)
      "
      [setMenuData]="[
        {
          menuLinkText: 'envelopelistitem_viewdetails' | dictionary | async,
          visible: true,
          menuLinkType: SdLinkMenuLinkType.emitter,
          key: MenuItemType.view
        },
        {
          menuLinkText:
            'envelopelistitem_actionmenuoptioncopy' | dictionary | async,
          visible: true,
          menuLinkType: SdLinkMenuLinkType.emitter,
          key: MenuItemType.copy
        }
      ]"
      [squareBackground]="true"
      [tooltip]="'envelopelistitem_actionsmenu' | dictionary | async"
      [transparentBackground]="true" />
  </div>

  <ng-template #envelopeListItemSpinner>
    <div class="envelope-list-item-actions">
      <app-sd-progress-spinner-a11y />
    </div>
  </ng-template>
</div>
