import { HelpSections } from '../../dictionary/models/help-sections.model';

export class HelpAndSupportHelper {
  public static getHelpActiveSection(
    activeHelpSection: HelpSections,
    helpSections: HelpSections[],
    currentRoute: string,
    appName: string
  ): HelpSections {
    activeHelpSection = null;
    helpSections.forEach(helpSection => {
      if (
        this.checkHelpActiveSection(currentRoute, helpSection.uri) &&
        helpSection.appId?.toLowerCase() === appName?.toLowerCase()
      ) {
        activeHelpSection = helpSection;
      }
    });
    return activeHelpSection;
  }

  static checkHelpActiveSection(url, parser): boolean {
    if (!parser || !url) {
      return false;
    }
    const parserArr = parser.split('/');
    const urlArr = url.split('/');

    if (urlArr.length !== parserArr.length) {
      return false;
    }
    let equal = true;
    parserArr.forEach((p, i) => {
      if (p === '{{id}}' || p === '{{wildcard}}') {
        return;
      }
      if (p !== urlArr[i]) {
        equal = false;
      }
    });
    return equal;
  }
}
