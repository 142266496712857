<div *ngIf="vm$ | async as vm" class="record-form-related-records-tab">
  <app-sd-progress-bar-a11y [setIsLoading]="spinner" />

  <app-sd-autocomplete-a11y
    [setSdAccessibility]="{ ariaLabel: 'relatedrecordsdialog_recordplaceholder' | dictionary | async }"
    (optionRecordSelected)="onSelectRecord($event)"
    [controller]="controller"
    [loading]="vm.gettingRecords"
    [placeholder]="
      'relatedrecordsdialog_recordplaceholder' | dictionary | async
    "
    [setOptionsRecord]="vm.records"
    [template]="autocompleteTemplate.recordRelated" />

  <app-sd-record-tab-header
    [title]="'relatedrecordssection_title'"
    [tooltip]="'relatedrecordssection_tooltip'" />

  <div class="related-records-tags-container">
    <app-related-records-tags-a11y
      *ngFor="let tag of vm.relatedRecords"
      [activeRecordId]="vm.recordId"
      [setRelatedRecord]="tag"
      [viewOnly]="false" />
  </div>
</div>
