import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SignatureDto } from '../models/signature.dto';
import { HttpErrorResponse } from '@angular/common/http';
import { DocusignEnvelope } from '../models/docusign-envelope.model';
import { PaginatorApiResponse } from '../../core/models/paginator-api-response.dto';
import { Update } from '@ngrx/entity';
import { DocusignEnvelopeDocument } from '../models/docusign-envelope-document.model';

export const SignatureActions = createActionGroup({
  source: 'signature',
  events: {
    'Set File To Sign': props<{ file: File }>(),
    'Create Envelope from File Requested': props<{ dto: SignatureDto }>(),
    'Create Envelope from File Success': props<{
      redirectURL: string;
      recordUrl: string;
    }>(),
    'Create Envelope from File fails': props<{
      error: HttpErrorResponse;
      recordUrl: string;
    }>(),
    'Set Active Envelope': props<{ envelope: DocusignEnvelope }>(),
    'Clear Active Envelope': emptyProps(),
    'Getting Envelopes Change': props<{ gettingInformation: boolean }>(),
    'Envelopes Requested': emptyProps(),
    'Envelopes Next Page Requested': emptyProps(),
    'Envelopes Loaded': props<{
      response: PaginatorApiResponse<DocusignEnvelope>;
    }>(),
    'Envelope Complete Information Loaded': props<{
      envelope: Update<DocusignEnvelope>;
    }>(),
    'Check Documents On Record Change': props<{
      envelopeId: string;
      checkDocumentsOnRecord: boolean;
    }>(),
    'Download And Upload Document on Record': props<{
      envelopeId: string;
      recordId: string;
      sideDrawerId: string;
      document: DocusignEnvelopeDocument;
    }>(),
    'Downloading And Uploading Document Change': props<{
      envelopeId: string;
      documentId: string;
      downloadingAndUploading: boolean;
    }>(),
    'Clear Information': emptyProps(),
  },
});
