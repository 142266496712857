import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { Observable } from 'rxjs';
import { dictionaryRoleByRoleIdSelector } from '../../dictionary/store/dictionary.selectors';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'roleKeyToDictionaryRole',
  standalone: true,
})
export class RoleKeyToDictionaryRolePipe implements PipeTransform {
  constructor(private readonly store: Store<AppState>) {}

  transform(roleId: string): Observable<string> {
    return this.store
      .select(dictionaryRoleByRoleIdSelector({ roleId }))
      .pipe(map(dicRole => dicRole?.roleLongName ?? ''));
  }
}
