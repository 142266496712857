import { SdQueueItem } from '../models/sd-queue-item.model';
import { forkJoin, Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import {
  blockedFilesFormatSelector,
  imageFilesFormatSelector,
  videoFilesFormatSelector,
} from '../../dictionary/store/dictionary.selectors';
import { map, take } from 'rxjs/operators';
import { FilesHelper } from '../../files/helpers/files.helper';
import { FileType } from '../../files/models/file-type.enum';
import {
  activeSideDrawerMaxUploadMBsSelector,
  activeSideDrawerSelector,
} from '../../sidedrawer/store/sidedrawer.selector';
import { FilesService } from '../../files/services/files.service';
import { Record } from '../../records/models/record.model';
import { TagSimpleFileRequest } from '../../reminders/models/enums/tag-sfr.enum';
import { SfrWorkflowStatus } from '../../reminders/models/enums/sfr-workflow-status.enum';
import { RecordChangeStatusRequested } from '../../records/store/records-list.actions';

export class QueueHelper {
  public static readonly generateQueueItems$ = (
    fileList: FileList,
    record: Record,
    store: Store<AppState>,
    filesService: FilesService
  ): Observable<SdQueueItem[]> => {
    const blockedFileFormats$ = QueueHelper.getBlockedFormats(store);
    const imageFileFormats$ = QueueHelper.getImageFileFormats(store);
    const videoFileFormats$ = QueueHelper.getVideoFileFormats(store);
    return forkJoin([
      blockedFileFormats$.pipe(take(1)),
      imageFileFormats$.pipe(take(1)),
      videoFileFormats$.pipe(take(1)),
      store.pipe(select(activeSideDrawerMaxUploadMBsSelector), take(1)),
      store.pipe(select(activeSideDrawerSelector), take(1)),
    ]).pipe(
      map(
        ([
          blockedFileFormats,
          imageFileFormats,
          videoFileFormats,
          maxUploadMBs,
          activeSideDrawer,
        ]) => {
          const files = Object.values(fileList);
          return files
            .filter(
              file =>
                !!file.name &&
                file.name.length > 0 &&
                file.name.split('.').length > 1
            )
            .map((file: File, index: number) => {
              const extension =
                file.name.split('.')[file.name.split('.').length - 1];
              const forbiddenFile = FilesHelper.extensionMatch(
                extension,
                blockedFileFormats
              );
              const sizeExceeded =
                file.size === 0 || file.size / 1048576 > maxUploadMBs;
              const upload = {
                file,
                uploadTitle: FilesHelper.hasExtension(file.name)
                  ? FilesHelper.removeExtension(file.name)
                  : file.name,
                fileType:
                  FilesHelper.extensionMatch(extension, imageFileFormats) ||
                  FilesHelper.extensionMatch(extension, videoFileFormats)
                    ? FileType.image
                    : FileType.document,
                displayType: 'review',
              };
              const updateSFR =
                index === 0 &&
                record.uniqueReference ===
                  TagSimpleFileRequest.simpleFileRequest &&
                record.status === SfrWorkflowStatus.sfrNew;
              return {
                id: FilesHelper.generateRandomId(),
                operation$: filesService.uploadFile(
                  upload,
                  upload.fileType,
                  activeSideDrawer.id,
                  record.id
                ),
                progress: forbiddenFile || sizeExceeded ? 100 : 0,
                state: forbiddenFile || sizeExceeded ? 'fail' : 'pending',
                itemType: 'file',
                upload,
                error:
                  forbiddenFile || sizeExceeded
                    ? `${
                        forbiddenFile
                          ? 'forbidden_file_error'
                          : 'file_size_error'
                      }`
                    : null,
                callback: updateSFR
                  ? () => {
                      store.dispatch(
                        new RecordChangeStatusRequested({
                          record,
                          sideDrawerId: activeSideDrawer.id,
                          status: SfrWorkflowStatus.sfrComplete,
                        })
                      );
                    }
                  : null,
              };
            });
        }
      )
    );
  };

  public static readonly generateAllQueueItems$ = (
    fileList: FileList,
    store: Store<AppState>
  ): Observable<SdQueueItem[]> => {
    const blockedFileFormats$ = QueueHelper.getBlockedFormats(store);
    const imageFileFormats$ = QueueHelper.getImageFileFormats(store);
    const videoFileFormats$ = QueueHelper.getVideoFileFormats(store);
    return forkJoin([
      blockedFileFormats$.pipe(take(1)),
      imageFileFormats$.pipe(take(1)),
      videoFileFormats$.pipe(take(1)),
      store.pipe(select(activeSideDrawerMaxUploadMBsSelector), take(1)),
    ]).pipe(
      map(
        ([
          blockedFileFormats,
          imageFileFormats,
          videoFileFormats,
          maxUploadMBs,
        ]) => {
          const files = Object.values(fileList);
          return files
            .filter(
              file =>
                !!file.name &&
                file.name.length > 0 &&
                file.name.split('.').length > 1
            )
            .map((file: File, index: number) => {
              const extension =
                file.name.split('.')[file.name.split('.').length - 1];
              const forbiddenFile = FilesHelper.extensionMatch(
                extension,
                blockedFileFormats
              );
              const sizeExceeded =
                file.size === 0 || file.size / 1048576 > maxUploadMBs;
              const upload = {
                file,
                uploadTitle: file.name,
                fileType:
                  FilesHelper.extensionMatch(extension, imageFileFormats) ||
                  FilesHelper.extensionMatch(extension, videoFileFormats)
                    ? FileType.image
                    : FileType.document,
                displayType: 'review',
              };
              return {
                id: FilesHelper.generateRandomId(),
                operation$: null,
                progress: null,
                state: 'pending',
                itemType: 'file',
                upload,
                error:
                  forbiddenFile || sizeExceeded
                    ? `${
                        forbiddenFile
                          ? 'forbidden_file_error'
                          : 'file_size_error'
                      }`
                    : null,
              };
            });
        }
      )
    );
  };

  public static readonly addOperationsToQueueItems = (
    queueItems: SdQueueItem[],
    record: Record,
    store: Store<AppState>,
    filesService: FilesService
  ): SdQueueItem[] => {
    const sdId = store.selectSignal(activeSideDrawerSelector)()?.id;
    return queueItems.map(item => ({
      ...item,
      operation$: filesService.uploadFile(
        item.upload,
        item.upload.fileType,
        sdId,
        record.id
      ),
    }));
  };

  public static getBlockedFormats(store: Store<AppState>) {
    return store.pipe(
      select(blockedFilesFormatSelector),
      map(blockedFileFormats => {
        const formats = [];
        blockedFileFormats.forEach(format => {
          formats.push(format.fileformat_enumid);
        });
        return formats;
      })
    );
  }

  private static getVideoFileFormats(store: Store<AppState>) {
    return store.pipe(
      select(videoFilesFormatSelector),
      map(fileFormats => {
        const formats = [];
        fileFormats.forEach(format => {
          formats.push(format.videoformat_enumid);
        });
        return formats;
      })
    );
  }

  private static getImageFileFormats(store: Store<AppState>) {
    return store.pipe(
      select(imageFilesFormatSelector),
      map(fileFormats => {
        const formats = [];
        fileFormats.forEach(format => {
          formats.push(format.imageformat_enumid);
        });
        return formats;
      })
    );
  }
}
