import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { AccountService } from '../../../account/services/account.service';
import { EMPTY, forkJoin, of, retry, switchMap, tap } from 'rxjs';
import { defaultBrandingSelector } from '../../../core/store/core.selectors';
import { catchError, map, take } from 'rxjs/operators';
import {
  accountSelector,
  settingsSelector,
} from '../../../account/store/account.selector';
import { Mfa } from '../../../account/models/mfa.model';
import { HttpErrorResponse } from '@angular/common/http';
import { MfaDialogComponent } from '../mfa-dialog/mfa-dialog.component';
import { Account } from '../../../account/models/account.model';
import { Settings } from '../../../account/models/settings.model';
import { MfaMode } from '../../../account/models/mfa-mode.model';
import { IdpMfa } from '../../../account/models/idp-mfa.model';
import { MatDialog } from '@angular/material/dialog';
import { idpMfaByBrandCodesByBrandCodeSelector } from 'src/app/dictionary/store/dictionary.selectors';

export interface ReEnrollMfaState {
  status:
    | 'idle'
    | 'success'
    | 'updateSettingsFails'
    | 'processing'
    | 'mfaFails';
}

@Injectable()
export class ReEnrollMfaStore extends ComponentStore<ReEnrollMfaState> {
  readonly status$ = this.select(state => state.status);

  readonly reEnrollMfa = this.effect(trigger$ => {
    const store = inject(Store<AppState>);
    const accountService = inject(AccountService);
    const dialog = inject(MatDialog);
    return trigger$.pipe(
      switchMap(() =>
        forkJoin([
          store.select(defaultBrandingSelector).pipe(take(1)),
          store.select(accountSelector).pipe(take(1)),
          store.select(settingsSelector).pipe(take(1)),
        ])
      ),
      tap(() => this.patchState({ status: 'processing' })),
      switchMap(([branding, account, settings]) => {
        const mfa = new Mfa(
          'email',
          'mfaEmail',
          settings.communicationLanguage,
          {
            ...settings,
            mfaDisabled: false,
          }
        );
        return accountService
          .createMfaCode(account?.id, mfa, branding?.brandCode)
          .pipe(
            map(() => ({ mfa, account })),
            retry(3),
            catchError((error: HttpErrorResponse) => of(error))
          );
      }),
      switchMap(
        (response: { mfa: Mfa; account: Account } | HttpErrorResponse) => {
          if (response instanceof HttpErrorResponse) {
            this.patchState({ status: 'mfaFails' });
            return EMPTY;
          }
          const { mfa, account } = response;
          return dialog
            .open(MfaDialogComponent, {
              data: {
                closable: false,
                mfa,
                account,
              },
              autoFocus: false,
              disableClose: true,
            })
            .afterClosed()
            .pipe(
              map(success => ({ success, account, settings: mfa.settings }))
            );
        }
      ),
      switchMap(result => {
        const { success, account, settings } = result;
        if (success) {
          return store.select(defaultBrandingSelector).pipe(
            take(1),
            switchMap(brand =>
              store
                .select(
                  idpMfaByBrandCodesByBrandCodeSelector({
                    brandCode: brand.brandCode,
                  })
                )
                .pipe(take(1))
            ),
            switchMap(idpMfa =>
              accountService
                .updateAccountSettingsV2(account, {
                  ...settings,
                  mfaMode: MfaMode.never,
                  idpMfa: this.compareMfa(settings.mfaMode, idpMfa),
                  mfaDisabled: true,
                })
                .pipe(
                  tapResponse(
                    () => {
                      this.patchState({ status: 'success' });
                      location.reload();
                    },
                    () => {
                      this.patchState({ status: 'updateSettingsFails' });
                    }
                  )
                )
            )
          );
        }
        this.patchState({ status: 'mfaFails' });
        return EMPTY;
      })
    );
  });

  readonly enrollMfa = this.effect(trigger$ => {
    const store = inject(Store<AppState>);
    const accountService = inject(AccountService);
    return trigger$.pipe(
      switchMap(() => store.select(defaultBrandingSelector).pipe(take(1))),
      switchMap(defaultBranding =>
        forkJoin([
          store
            .select(
              idpMfaByBrandCodesByBrandCodeSelector({
                brandCode: defaultBranding.brandCode,
              })
            )
            .pipe(take(1)),
          store.select(accountSelector).pipe(take(1)),
          store.select(settingsSelector).pipe(take(1)),
        ])
      ),
      tap(() => this.patchState({ status: 'processing' })),
      switchMap(([idpMfa, account, settings]) => {
        return accountService.updateAccountSettingsV2(account, {
          ...settings,
          mfaMode: MfaMode.never,
          idpMfa: idpMfa,
          mfaDisabled: true,
        });
      }),
      tapResponse(
        () => {
          this.patchState({ status: 'success' });
          location.reload();
        },
        () => {
          this.patchState({ status: 'updateSettingsFails' });
        }
      )
    );
  });

  readonly enrollOrReEnroll = this.effect(trigger$ => {
    const store = inject(Store<AppState>);
    return trigger$.pipe(
      switchMap(() => store.select(settingsSelector).pipe(take(1))),
      tap((settings: Settings) => {
        if (
          settings?.mfaMode === MfaMode.everyTime ||
          settings?.mfaMode === MfaMode.sometimes
        ) {
          this.reEnrollMfa();
          return;
        }
        this.enrollMfa();
      })
    );
  });

  constructor() {
    super({ status: 'idle' });
  }

  private compareMfa(mfaMode: MfaMode, idpMfa: IdpMfa): IdpMfa {
    switch (mfaMode) {
      case MfaMode.never:
        return idpMfa;
      case MfaMode.sometimes:
        if (idpMfa === IdpMfa.always) {
          return idpMfa;
        }
        return IdpMfa.sometimes;
      case MfaMode.everyTime:
        return IdpMfa.always;
    }
  }
}
