export class SdLinkMenuItem {
  constructor(
    public menuLinkText?: string,
    public menuLinkIcon?: string,
    public menuLinkUrl?: string,
    public visible?: boolean,
    public menuLinkType?: string,
    public key?: string,
    public menuLinkTooltip?: string,
    public active?: boolean
  ) {}
}
