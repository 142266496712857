import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SdFlatButtonA11yComponent } from './components/sd-flat-button-a11y/sd-flat-button-a11y.component';
import { SdSvgA11yModule } from '../sd-svg-a11y/sd-svg-a11y.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [SdFlatButtonA11yComponent],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    SdSvgA11yModule,
    MatTooltipModule,
    MatButtonModule,
  ],
  exports: [SdFlatButtonA11yComponent],
})
export class SdFlatButtonA11yModule {}
