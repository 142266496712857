import { Pipe, PipeTransform } from '@angular/core';
import { DisplayValue } from '../../../records/models/display-value.model';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { localeDefaultSelector } from '../../../dictionary/store/dictionary.selectors';
import { map } from 'rxjs/operators';
import { Locale } from '../../../dictionary/models/locale.model';
import { AppState } from '../../../reducers';

@Pipe({
  name: 'displayValue',
})
export class DisplayValuePipe implements PipeTransform {
  constructor(private readonly store: Store<AppState>) {}

  transform(values: DisplayValue[]): Observable<string> {
    return this.store.pipe(
      select(localeDefaultSelector),
      map(locale => Locale.getLocaleId(locale)),
      map(localeId => {
        return (
          values?.find(
            value =>
              value.locale.toLocaleLowerCase() === localeId.toLocaleLowerCase()
          )?.value ?? ''
        );
      })
    );
  }
}
