import { ZipStatus } from '../models/zip-status.model';
import { ZipActions, ZipActionsTypes } from './zip.actions';
import { SideDrawer } from '../../sidedrawer/models/side-drawer.model';
import { ZipQueueFile } from '../models/zip-queue-file.model';
import { RecordListItem } from '../../records/models/record-list-item.model';

export const featureName = 'zip';

export interface ZipState {
  status: ZipStatus;
  alwaysDisplayZipButton: boolean;
  sidedrawer: SideDrawer;
  recordsList: Map<
    string,
    { record: RecordListItem; getRecordsFilesComplete: boolean }
  >;
  queue: ZipQueueFile[];
  errors: string[];
}

export const initialZipState: ZipState = {
  status: ZipStatus.confirmation,
  alwaysDisplayZipButton: false,
  sidedrawer: null,
  recordsList: new Map<
    string,
    { record: RecordListItem; getRecordsFilesComplete: boolean }
  >(),
  queue: [],
  errors: [],
};

export function zipReducer(
  state: ZipState = initialZipState,
  action: ZipActions
): ZipState {
  const recordsAux = state.recordsList;
  const updatedQueue = [...state.queue];
  switch (action.type) {
    case ZipActionsTypes.ZipConfirmDownload:
      return {
        ...state,
        status: ZipStatus.information,
        alwaysDisplayZipButton: true,
        sidedrawer: action.payload.sidedrawer,
        queue: [],
        errors: [],
        recordsList: new Map<
          string,
          { record: RecordListItem; getRecordsFilesComplete: boolean }
        >(),
      };
    case ZipActionsTypes.ZipCompletes:
      return {
        ...state,
        alwaysDisplayZipButton: false,
        status:
          state.errors.length > 0
            ? ZipStatus.completeWithErrors
            : ZipStatus.complete,
        sidedrawer: null,
        recordsList: new Map<
          string,
          { record: RecordListItem; getRecordsFilesComplete: boolean }
        >(),
      };
    case ZipActionsTypes.ZipCanceled:
      return {
        ...state,
        alwaysDisplayZipButton: false,
        status: ZipStatus.canceled,
        sidedrawer: null,
        queue: [],
        errors: [],
        recordsList: new Map<
          string,
          { record: RecordListItem; getRecordsFilesComplete: boolean }
        >(),
      };
    case ZipActionsTypes.ZipStatusUpdate:
      return {
        ...state,
        status: action.payload.status,
      };
    case ZipActionsTypes.FileDownloadUpdate:
      updatedQueue[action.payload.index] = action.payload.item;
      return {
        ...state,
        queue: [...updatedQueue],
      };
    case ZipActionsTypes.AddFileToQueue:
      return {
        ...state,
        queue: [...state.queue, action.payload.item],
      };
    case ZipActionsTypes.ZipRecordsLoaded:
      action.payload.records.forEach(record =>
        recordsAux.set(record.id, { record, getRecordsFilesComplete: false })
      );
      return {
        ...state,
        recordsList: recordsAux,
      };
    case ZipActionsTypes.ZipRecordFilesLoaded:
      recordsAux.set(action.payload.recordId, {
        ...recordsAux.get(action.payload.recordId),
        getRecordsFilesComplete: true,
      });
      return {
        ...state,
        recordsList: recordsAux,
      };
    case ZipActionsTypes.ZipDownloadFileError:
      return {
        ...state,
        errors: [...state.errors, action.payload.error],
      };
    case ZipActionsTypes.ZipFails:
      return {
        ...state,
        status: ZipStatus.fails,
      };
    case ZipActionsTypes.ZipNoFiles:
      return {
        ...state,
        status: ZipStatus.noFiles,
      };
    case ZipActionsTypes.ZipReset:
      return initialZipState;
    default:
      return state;
  }
}
