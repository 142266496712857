import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { featureName, searchReducer } from './store/search.reducer';
import { EffectsModule } from '@ngrx/effects';
import { SearchRecordsListEffects } from './store/search-record-list.effects';
import { SearchRoutingModule } from './search-routing.module';
import { SearchSideDrawerListEffects } from './store/search-sidedrawer-list.effects';
import { SearchFileListEffects } from './store/search-file-list.effects';

@NgModule({
  imports: [
    SearchRoutingModule,
    StoreModule.forFeature(featureName, searchReducer),
    EffectsModule.forFeature([
      SearchRecordsListEffects,
      SearchSideDrawerListEffects,
      SearchFileListEffects,
    ]),
  ],
})
export class SearchModule {}
