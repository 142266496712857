import { Action } from '@ngrx/store';
import { SdCacheElement } from '../models/sd-cache-element.model';

export enum SdCacheActionsTypes {
  SdCacheElementAdded = '[SdSVG / Others] Sd Cache element Added',
  SdCacheNewImageRequested = '[SdSVG] Sd Cache New Image Requested',
}

export class SdCacheElementAdded implements Action {
  readonly type = SdCacheActionsTypes.SdCacheElementAdded;

  constructor(public payload: SdCacheElement) {}
}

export class SdCacheNewImageRequested implements Action {
  readonly type = SdCacheActionsTypes.SdCacheNewImageRequested;

  constructor(public payload: { src: string }) {}
}

export type SdCacheActions = SdCacheElementAdded | SdCacheNewImageRequested;
