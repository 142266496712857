<app-sd-dialog-template-a11y (closeButtonClicked)="onClose()">
  <ng-container dialog-header>
    <h2>
      {{
        data.isFullForm
          ? ('sdplaceofresidence_addressformtitle' | dictionary | async)
          : ('sdplaceofresidence_title' | dictionary | async)
      }}
    </h2>
  </ng-container>
  <ng-container dialog-body>
    <div
      class="template-description"
      *ngIf="!environment.appName.includes('td')">
      {{
        (data?.placeholders?.description
          ? data?.placeholders?.description
          : 'sdplaceofresidence_description'
        )
          | dictionary
          | async
      }}
    </div>
    <ng-template #fullForm [ngIf]="data?.isFullForm === true">
      <form [formGroup]="placeOfResidenceForm">
        <div class="content">
          <div
            *ngIf="
              data?.placeholders?.googleplacedescription &&
              !environment.appName.includes('td')
            "
            class="description">
            {{
              (data?.placeholders?.googleplacedescription
                ? data?.placeholders?.googleplacedescription
                : 'sdplaceofresidence_googleplacedescription'
              )
                | dictionary
                | async
            }}
          </div>
          <mat-form-field
            [hideRequiredMarker]="true"
            *ngIf="!environment.appName.includes('td')">
            <mat-label>{{
              (data?.placeholders?.googleplaceplaceholder
                ? data?.placeholders?.googleplaceplaceholder
                : 'sdplaceofresidence_googleplacefieldplaceholder'
              )
                | dictionary
                | async
            }}</mat-label>
            <input
              (onAddressChange)="handleAddressChange($event)"
              [formControl]="searchController"
              [placeholder]="
                (data?.placeholders?.googleplaceplaceholder
                  ? data?.placeholders?.googleplaceplaceholder
                  : 'sdplaceofresidence_googleplacefieldplaceholder'
                )
                  | dictionary
                  | async
              "
              matInput
              #placesRef="ngx-places"
              ngx-gp-autocomplete />
          </mat-form-field>

          <div class="description">
            {{
              (data?.placeholders?.manualentrydescription
                ? data?.placeholders?.manualentrydescription
                : 'sdplaceofresidence_manualentrydescriptionplaceholder'
              )
                | dictionary
                | async
            }}
          </div>

          <app-sd-input-a11y
            [controller]="streetController"
            [error]="
              (isSubmitted ? 'globalparams_requirederror' : '')
                | dictionary
                | async
            "
            [placeholder]="
              (data?.placeholders?.street
                ? data?.placeholders?.street
                : 'sdplaceofresidence_manualentrystreetaddressplaceholder'
              )
                | dictionary
                | async
            "
            [setSdAccessibility]="{
              ariaLabel:
                (data?.placeholders?.street
                  ? data?.placeholders?.street
                  : 'sdplaceofresidence_manualentrystreetaddressplaceholder'
                )
                | dictionary
                | async
            }" />

          <app-sd-input-a11y
            [controller]="apartmentController"
            [placeholder]="
              (data?.placeholders?.street2
                ? data?.placeholders?.street2
                : 'sdplaceofresidence_manualentryaddress2placeholder'
              )
                | dictionary
                | async
            "
            [setSdAccessibility]="{
              ariaLabel:
                (data?.placeholders?.street2
                  ? data?.placeholders?.street2
                  : 'sdplaceofresidence_manualentryaddress2placeholder'
                )
                | dictionary
                | async
            }" />

          <app-sd-input-a11y
            [autocomplete]="false"
            [controller]="cityController"
            [error]="
              (isSubmitted ? 'globalparams_requirederror' : '')
                | dictionary
                | async
            "
            [placeholder]="
              (data?.placeholders?.city
                ? data?.placeholders?.city
                : 'sdplaceofresidence_cityplaceholder'
              )
                | dictionary
                | async
            "
            [setSdAccessibility]="{
              ariaLabel:
                (data?.placeholders?.city
                  ? data?.placeholders?.city
                  : 'sdplaceofresidence_cityplaceholder'
                )
                | dictionary
                | async
            }"
            [type]="'text'" />

          <app-sd-input-a11y
            [autocomplete]="false"
            [controller]="stateController"
            [error]="
              (isSubmitted ? 'globalparams_requirederror' : '')
                | dictionary
                | async
            "
            [placeholder]="
              (data?.placeholders?.state
                ? data?.placeholders?.state
                : 'sdplaceofresidence_stateplaceholder'
              )
                | dictionary
                | async
            "
            [setSdAccessibility]="{
              ariaLabel:
                (data?.placeholders?.state
                  ? data?.placeholders?.state
                  : 'sdplaceofresidence_stateplaceholder'
                )
                | dictionary
                | async
            }"
            [type]="'text'" />

          <app-country-selector-a11y
            (countrySelected)="onCountrySelected($event)"
            [controller]="countryController"
            [error]="
              (isSubmitted ? 'globalparams_requirederror' : '')
                | dictionary
                | async
            "
            [placeholder]="
              (data?.placeholders?.country
                ? data?.placeholders?.country
                : 'sdplaceofresidence_countryplaceholder'
              )
                | dictionary
                | async
            "
            [required]="true"
            [setSdAccessibilityForImg]="{
              alt:
                (data?.placeholders?.country
                  ? data?.placeholders?.country
                  : 'sdplaceofresidence_countryplaceholder'
                )
                | dictionary
                | async
            }"
            [setSdAccessibility]="{
              ariaLabel:
                (data?.placeholders?.country
                  ? data?.placeholders?.country
                  : 'sdplaceofresidence_countryplaceholder'
                )
                | dictionary
                | async,
              id: 'countryController'
            }"
            [startValue]="data?.primaryResidence?.country" />
        </div>

        <app-sd-input-a11y
          [controller]="postalZipController"
          [error]="
            (isSubmitted ? 'globalparams_requirederror' : '')
              | dictionary
              | async
          "
          [placeholder]="
            (data?.placeholders?.postalCode
              ? data?.placeholders?.postalCode
              : 'sdplaceofresidence_manualentrypostalcodeplaceholder'
            )
              | dictionary
              | async
          "
          [setSdAccessibility]="{
            ariaLabel:
              (data?.placeholders?.postalCode
                ? data?.placeholders?.postalCode
                : 'sdplaceofresidence_manualentrypostalcodeplaceholder'
              )
              | dictionary
              | async
          }" />
      </form>
    </ng-template>

    <ng-template #partialForm [ngIf]="data?.isFullForm === false">
      <form [formGroup]="placeOfResidenceForm">
        <div class="content">
          <app-sd-input-a11y
            [autocomplete]="false"
            [controller]="cityController"
            [placeholder]="
              (data?.placeholders?.city
                ? data?.placeholders?.city
                : 'sdplaceofresidence_cityplaceholder'
              )
                | dictionary
                | async
            "
            [setSdAccessibility]="{
              ariaLabel:
                (data?.placeholders?.city
                  ? data?.placeholders?.city
                  : 'sdplaceofresidence_cityplaceholder'
                )
                | dictionary
                | async
            }"
            [type]="'text'" />

          <app-sd-input-a11y
            [autocomplete]="false"
            [controller]="stateController"
            [placeholder]="
              (data?.placeholders?.state
                ? data?.placeholders?.state
                : 'sdplaceofresidence_stateplaceholder'
              )
                | dictionary
                | async
            "
            [setSdAccessibility]="{
              ariaLabel:
                (data?.placeholders?.state
                  ? data?.placeholders?.state
                  : 'sdplaceofresidence_stateplaceholder'
                )
                | dictionary
                | async
            }"
            [type]="'text'" />

          <app-country-selector-a11y
            (countrySelected)="onCountrySelected($event)"
            [controller]="countryController"
            [error]="
              (isSubmitted ? 'globalparams_requirederror' : '')
                | dictionary
                | async
            "
            [placeholder]="
              (data?.placeholders?.country
                ? data?.placeholders?.country
                : 'sdplaceofresidence_countryplaceholder'
              )
                | dictionary
                | async
            "
            [required]="true"
            [setSdAccessibilityForImg]="{
              alt:
                (data?.placeholders?.country
                  ? data?.placeholders?.country
                  : 'sdplaceofresidence_countryplaceholder'
                )
                | dictionary
                | async
            }"
            [setSdAccessibility]="{
              ariaLabel:
                (data?.placeholders?.country
                  ? data?.placeholders?.country
                  : 'sdplaceofresidence_countryplaceholder'
                )
                | dictionary
                | async,
              id: 'countryController'
            }"
            [startValue]="data?.primaryResidence?.country" />
        </div>
      </form>
    </ng-template>
  </ng-container>

  <ng-container dialog-footer>
    <app-sd-flat-button-a11y
      (buttonClicked)="onClose()"
      [primary]="false"
      [setSdAccessibility]="{
        ariaLabel: 'globalparams_cancel' | dictionary | async
      }">
      {{ 'globalparams_cancel' | dictionary | async }}
    </app-sd-flat-button-a11y>
    <app-sd-flat-button-a11y
      (buttonClicked)="onSubmit()"
      [inactiveButtonClass]="true"
      [primary]="primaryIfValid()"
      [setSdAccessibility]="{
        ariaLabel:
          (data?.isFullForm
            ? 'sdplaceofresidence_manualentrysubmit'
            : 'globalparams_confirm'
          )
          | dictionary
          | async
      }">
      {{
        (data?.isFullForm
          ? 'sdplaceofresidence_manualentrysubmit'
          : 'globalparams_confirm'
        )
          | dictionary
          | async
      }}
    </app-sd-flat-button-a11y>
  </ng-container>
</app-sd-dialog-template-a11y>
