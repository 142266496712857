<div class="sd-record-tab-header-section">
  <div class="sd-record-tab-header-section-left-column">
    <h3
      [attr.aria-label]="
        ariaLabel
          ? (ariaLabel | dictionary | async)
          : (title | dictionary | async) + ' ' + (tooltip | dictionary | async)
      "
      class="sd-record-tab-header-section-header-title">
      {{ title | dictionary | async }}
    </h3>

    <app-sd-tooltip-button-a11y
      [tooltip]="tooltip | dictionary | async" />
  </div>
  <div
    *ngIf="actionsTemplate"
    class="sd-record-tab-header-section-right-column">
    <ng-container [ngTemplateOutlet]="actionsTemplate" />
  </div>
</div>
