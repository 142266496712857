import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs';
import { SdBreadcrumbsA11yService } from '../services/sd-breadcrumbs-a11y-service';
import {
  SdBreadCrumbsA11yActionsTypes,
  SetBreadCrumbs,
  SetBreadCrumbsWithRouteStrategy,
} from './sd-breadcrumbs-a11y.actions';

@Injectable()
export class SdBreadCrumbsA11yEffects {
  setBreadCrumbsWithRouteStrategy$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SetBreadCrumbsWithRouteStrategy>(
        SdBreadCrumbsA11yActionsTypes.SetBreadCrumbsWithRouteStrategy
      ),
      map(
        () => new SetBreadCrumbs(this.sdBreadCrumbsService.createBreadcrumbs())
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly sdBreadCrumbsService: SdBreadcrumbsA11yService
  ) {}
}
