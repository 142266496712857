import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DialogTemplateTypes } from 'src/app/shared/templates/enums/templates.enum';
import { SdDialogTemplateA11yModule } from '../../../shared/templates/dialog-template-a11y/sd-dialog-template-a11y.module';
import { AsyncPipe } from '@angular/common';
import { DictionaryPipeModule } from '../../../dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { SdFlatButtonA11yModule } from '../../../shared/sd-flat-button-a11y/sd-flat-button-a11y.module';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-forbidden-sidedrawer-error-dialog',
  template: `
    <app-sd-dialog-template-a11y
      (closeButtonClicked)="onClose()"
      [type]="dialogTemplateTypes.Info">
      <ng-container dialog-header>
        <h2>
          {{ 'forbiddensidedrawerdialog_title' | dictionary | async }}
        </h2>
      </ng-container>
      <ng-container dialog-body>
        <div
          [innerHTML]="'forbiddensidedrawerdialog_body' | dictionary | async"
          class="base-dialog-content"></div>
      </ng-container>
      <ng-container dialog-footer>
        <app-sd-flat-button-a11y
          (buttonClicked)="onClose()"
          [primary]="true"
          [setSdAccessibility]="{
            ariaLabel:
              'forbiddensidedrawerdialog_primaybutton' | dictionary | async
          }"
          class="primary">
          {{ 'forbiddensidedrawerdialog_primaybutton' | dictionary | async }}
        </app-sd-flat-button-a11y>
      </ng-container>
    </app-sd-dialog-template-a11y>
  `,
  styleUrls: ['./forbidden-sidedrawer-error-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SdDialogTemplateA11yModule,
    AsyncPipe,
    DictionaryPipeModule,
    SdFlatButtonA11yModule,
  ],
})
export class ForbiddenSidedrawerErrorDialogA11yComponent {
  dialogTemplateTypes = DialogTemplateTypes;

  constructor(
    private readonly dialogRef: MatDialogRef<ForbiddenSidedrawerErrorDialogA11yComponent>
  ) {}

  onClose(): void {
    this.dialogRef.close();
  }
}
