export class MoneyInputFormatHelper {
  // value could be theses values => #.###,00 #,###.00 # ###.00
  static getMoneyMask(value: string) {
    try {
      if (value) {
        const splittedValue = value.split('#');
        const lastPartValueSplitted = splittedValue[splittedValue.length - 1]; // example ',00'
        const decimalSeparator = lastPartValueSplitted[0]; // example ','
        const totalDecimalLength =
          lastPartValueSplitted.split(decimalSeparator)[1].length; // example 00 ['', '00']
        return `separator${decimalSeparator}${totalDecimalLength}`;
      }
    } catch (e) {
      return 'separator';
    }
  }

  // value could be theses values => #.###,00 #,###.00 # ###.00
  static getThousandSeparator(value: string) {
    try {
      if (value) {
        return value.split('#')[1];
      }
    } catch (e) {
      return ' ';
    }
  }
}
