import { createSelector } from '@ngrx/store';
import { selectAll } from './search-sidedrawer-list.reducer';
import { searchStateSelector } from './search.selectors';

export const searchSidedrawerListStateSelector = createSelector(
  searchStateSelector,
  state => state.sidedrawer
);

export const searchSidedrawerListSelector = createSelector(
  searchSidedrawerListStateSelector,
  selectAll
);

export const searchSidedrawerListFilterSelector = createSelector(
  searchSidedrawerListStateSelector,
  state => state.filter || ''
);

export const searchSidedrawerListFilteredByFilterSelector = createSelector(
  searchSidedrawerListStateSelector,
  state => {
    return Object.values(state.entities).filter(sdNetwork =>
      sdNetwork.name.toLowerCase().includes(state.filter.toLowerCase())
    );
  }
);
