import { createSelector } from '@ngrx/store';
import { selectAll } from './search-sidedrawer-requests-list.reducer';
import { searchStateSelector } from './search.selectors';

export const searchSidedrawerRequestListStateSelector = createSelector(
  searchStateSelector,
  state => state.requestsForSideDrawer
);

export const searchSidedrawerRequestListSelector = createSelector(
  searchSidedrawerRequestListStateSelector,
  selectAll
);

export const searchSidedrawerRequestListItemsPerPageSelector = createSelector(
  searchSidedrawerRequestListStateSelector,
  state => state.itemsPerPage
);

export const searchSideDrawerRequestListHasMoreSelector = createSelector(
  searchSidedrawerRequestListSelector,
  request => request.some(request => request.hasMore)
);
