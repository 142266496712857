import { Pipe, PipeTransform } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { displayTypesSelector } from 'src/app/dictionary/store/dictionary.selectors';
import { AppState } from 'src/app/reducers';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'displayType',
})
export class DisplayTypePipe implements PipeTransform {
  constructor(private readonly store: Store<AppState>) {}

  transform(displayType: string): Observable<string> {
    return this.store.pipe(
      select(displayTypesSelector),
      map(_displayType => {
        return _displayType.find(c => c.displaytype_enumid === displayType)
          ?.displaytype_label;
      })
    );
  }
}
