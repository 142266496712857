import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { RecordListItem } from 'src/app/records/models/record-list-item.model';
import { RecordsSortOptions } from 'src/app/records/models/records-sort-options.model';
import {
  SearchRecordsListActions,
  SearchRecordsListActionsTypes,
} from './search-record-list.actions';

export interface SearchRecordListState extends EntityState<RecordListItem> {
  sortRecordsBy: RecordsSortOptions;
  requests: Map<
    string,
    {
      gettingRecords: boolean;
      hasMore: boolean;
      nextPage: string;
      previousPage: string;
      totalCount: number;
    }
  >;
  filter: {
    name?: string;
    recordTypeName?: string;
    recordTypeId?: string;
    recordSubtypeName?: string;
    recordSubtypeOther?: string;
    recordSubtypeId?: string;
  };
}

export const searchRecordsListAdapter: EntityAdapter<RecordListItem> =
  createEntityAdapter<RecordListItem>({
    selectId: record => record.id,
  });

export const initialRecordListState: SearchRecordListState =
  searchRecordsListAdapter.getInitialState({
    sortRecordsBy: RecordsSortOptions.nameAscending,
    error: null,
    requests: new Map(),
    filter: null,
  });

export function recordListReducer(
  state: SearchRecordListState = initialRecordListState,
  action: SearchRecordsListActions
): SearchRecordListState {
  switch (action.type) {
    case SearchRecordsListActionsTypes.SearchRecordsListRequested:
    case SearchRecordsListActionsTypes.SearchRecordsListRequestedForAppend: {
      const aux = new Map(state.requests);
      const request = aux.get(action.payload.sideDrawerId);
      aux.set(action.payload.sideDrawerId, {
        ...request,
        gettingRecords: true,
      });
      return { ...state, requests: aux };
    }
    case SearchRecordsListActionsTypes.SearchRecordsListLoaded: {
      const { data, totalCount, previousPage, nextPage, hasMore } =
        action.payload.data;
      const aux = new Map(state.requests);
      aux.set(action.payload.sideDrawerId, {
        totalCount,
        previousPage,
        nextPage,
        hasMore,
        gettingRecords: false,
      });
      return {
        ...state,
        ...searchRecordsListAdapter.upsertMany(data, state),
        requests: aux,
        filter: { ...state.filter, name: action.payload.filter },
      };
    }
    case SearchRecordsListActionsTypes.SearchRecordsListLoadedForAppend: {
      const { data, totalCount, previousPage, nextPage, hasMore } =
        action.payload.data;
      const aux = new Map(state.requests);
      aux.set(action.payload.sideDrawerId, {
        totalCount,
        previousPage,
        nextPage,
        hasMore,
        gettingRecords: false,
      });
      return {
        ...state,
        ...searchRecordsListAdapter.addMany(data, state),
        requests: aux,
      };
    }
    case SearchRecordsListActionsTypes.SearchRecordsListReset: {
      return {
        ...state,
        ...searchRecordsListAdapter.removeAll(state),
        requests: new Map(),
        filter: null,
      };
    }
    case SearchRecordsListActionsTypes.SearchRecordListDeleted: {
      return searchRecordsListAdapter.removeOne(action.payload.id, state);
    }
    default:
      return state;
  }
}

export const { selectAll, selectEntities } =
  searchRecordsListAdapter.getSelectors();
