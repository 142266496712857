import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { currentBrandingSelector } from '../../core/store/core.selectors';
import { map } from 'rxjs/operators';
import { Dictionary } from '../../dictionary/models/dictionary.model';
import { dictionarySelector } from '../../dictionary/store/dictionary.selectors';
import { AsyncPipe, NgClass } from '@angular/common';
import { SdFlatButtonA11yModule } from '../sd-flat-button-a11y/sd-flat-button-a11y.module';
import { DictionaryPipeModule } from 'src/app/dictionary/pipes/dictionary-pipe/dictionary-pipe.module';

@Component({
  selector: 'app-tour-button',
  template: `
    <div
      [ngClass]="!(!!tourId && tourId.length > 0) ? 'invisible' : null"
      class="tour-link-button">
      <app-sd-flat-button-a11y
        (buttonClicked)="onShowTour()"
        [disabled]="false"
        [icon]="icon"
        [primary]="false"
        [tooltip]="tourTooltip"
        >{{ 'globalparams_tour' | dictionary | async }}</app-sd-flat-button-a11y
      >
    </div>
  `,
  styleUrls: ['./sd-tour-button.component.scss'],
  standalone: true,
  imports: [NgClass, SdFlatButtonA11yModule, AsyncPipe, DictionaryPipeModule],
})
export class SdTourButtonComponent implements OnInit {
  @Input() tourTooltip: string;
  @Input() tourId: string;
  cdn = environment.cdn;
  primaryColor$: Observable<string>;
  icon = `${environment.cdn}svgs/compass-regular.svg`;
  dictionary$: Observable<Dictionary>;

  constructor(private readonly store: Store<AppState>) {}

  ngOnInit(): void {
    this.dictionary$ = this.store.pipe(select(dictionarySelector));
    this.primaryColor$ = this.store.pipe(
      select(currentBrandingSelector),
      map(branding => branding?.styleSheet?.primaryColor)
    );
  }

  onShowTour(): void {
    Intercom('startTour', parseInt(this.tourId, 10));
  }
}
