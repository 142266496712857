import { NgModule } from '@angular/core';
import { RemindersRoutingModule } from './reminders-routing.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { RemindersListEffects } from './store/reminders-list.effects';
import { ReminderTemplateModule } from './templates/reminder-template/reminder-template.module';
import { SimpleFileRequestEffects } from './store/sfr.effect';
import { featureName, remindersReducer } from './store/reminder.reducer';

@NgModule({
  imports: [
    RemindersRoutingModule,
    StoreModule.forFeature(featureName, remindersReducer),
    EffectsModule.forFeature([RemindersListEffects, SimpleFileRequestEffects]),
    ReminderTemplateModule,
  ],
})
export class RemindersModule {}
