import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-sd-content-template',
  template: `
    <div *ngIf="!spinner" class="sd-content-template">
      <div class="sd-content-template-header">
        <ng-content select="[header]" />
      </div>

      <div
        [ngClass]="{ 'box-style': boxStyle }"
        [ngStyle]="{
          'min-height': withRecordsTilesBar
            ? 'calc(100vh - 192px - 52px - 10px)'
            : 'calc(100vh - 192px)'
        }"
        class="sd-content-template-content">
        <ng-content select="[content]" />
      </div>
    </div>

    <div *ngIf="spinner" class="sd-content-template-spinner">
      <app-sd-progress-spinner-a11y />
    </div>
  `,
  styleUrls: ['./sd-content-template.component.scss'],
})
export class SdContentTemplateComponent {
  @Input() boxStyle: boolean;
  @Input() withRecordsTilesBar: boolean;
  @Input() spinner: boolean;
}
