<div
  *ngIf="!!message && message.length > 0"
  [attr.aria-label]="sdAccessibility?.ariaLabel"
  [ngClass]="getClass()"
  [ngStyle]="{
    'padding-top': !!verticalPadding ? verticalPadding + 'px' : '.75rem',
    'padding-bottom': !!verticalPadding ? verticalPadding + 'px' : '.75rem',
    'padding-left': !!horizontalPadding ? horizontalPadding + 'px' : '1.25rem',
    'padding-right': !!horizontalPadding ? horizontalPadding + 'px' : '1.25rem'
  }"
  aria-label="sdAccessibility?.ariaLabel">
  <div
    [id]="sdAccessibility?.id"
    [innerHTML]="message"
    class="alert-content"
    tabindex="0"></div>
</div>
