import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { AccessibilityHelper } from 'src/app/core/helpers/accessibility.helper';
import { SdColorPalette } from 'src/app/core/models/enums/sd-color-palette-enum';
import { SdAccessibilitySetting } from 'src/app/core/models/sd-accessibility-model';

@Component({
  selector: 'app-sd-flat-button-a11y',
  templateUrl: './sd-flat-button-a11y.component.html',
  styleUrls: ['./sd-flat-button-a11y.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SdFlatButtonA11yComponent {
  @Input() primary: boolean;
  @Input() inactiveButtonClass: boolean;
  @Input() disableRipple = false;
  @Input() transparent: boolean;
  @Input() disabled: boolean;
  @Input() loading: boolean;
  @Input() icon: string;
  @Input() iconAlt: string;
  @Input() tooltip: string;
  @Input() danger = false;
  @Output() buttonClicked = new EventEmitter<boolean>();
  sdColorPalette = SdColorPalette;
  sdAccessibility: SdAccessibilitySetting;
  focus = false;

  @Input() set setSdAccessibility(value: SdAccessibilitySetting) {
    this.sdAccessibility = AccessibilityHelper.setDefaultAccessibility(value);
  }
}
