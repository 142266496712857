import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import {
  featureName as startFlowFeatureName,
  startFlowReducer,
} from './store/start-flow.reducer';
import { EffectsModule } from '@ngrx/effects';
import { StartFlowEffects } from './store/start-flow.effects';
import { DictionaryModule } from '../dictionary/dictionary.module';
import { SidedrawerNetworksModule } from '../sidedrawer-networks/sidedrawer-networks.module';
import {
  accountReducer,
  featureName as accountFeatureName,
} from '../account/store/account.reducer';
import { AccountEffects } from '../account/store/account.effects';
import { CoreModule } from '../core/core.module';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(startFlowFeatureName, startFlowReducer),
    StoreModule.forFeature(accountFeatureName, accountReducer),
    EffectsModule.forFeature([StartFlowEffects, AccountEffects]),
    DictionaryModule,
    SidedrawerNetworksModule,
    MatDialogModule,
    CoreModule,
  ],
})
export class StartFlowModule {}
