import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';

import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { SdColorPalette } from '../../../core/models/enums/sd-color-palette-enum';
import { DialogTemplateTypes } from 'src/app/shared/templates/enums/templates.enum';
import { SdDialogTemplateA11yModule } from '../../../shared/templates/dialog-template-a11y/sd-dialog-template-a11y.module';
import { DictionaryPipeModule } from '../../../dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { AsyncPipe } from '@angular/common';
import { SdFlatButtonA11yModule } from '../../../shared/sd-flat-button-a11y/sd-flat-button-a11y.module';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-create-account-error-dialog',
  template: `
    <app-sd-dialog-template-a11y
      (closeButtonClicked)="onClose()"
      [type]="dialogTemplateTypes.Info">
      <ng-container dialog-header>
        <h2>
          {{
            (data?.error?.error?.message === 'error_creating_account'
              ? 'createaccounterrordialog_duplicateaccounterrortitle'
              : 'genericerrordialog_title'
            )
              | dictionary
              | async
          }}
        </h2>
      </ng-container>
      <ng-container dialog-body>
        <div
          [innerHTML]="
            (data?.error?.error?.message === 'error_creating_account'
              ? 'createaccounterrordialog_duplicateaccounterrorbody'
              : 'genericerrordialog_body'
            )
              | dictionary
              | async
          "
          class="template-description"></div>
      </ng-container>
      <ng-container dialog-footer>
        <app-sd-flat-button-a11y
          (buttonClicked)="onClose()"
          [primary]="true"
          [setSdAccessibility]="{
            ariaLabel:
              (data?.error?.error?.message === 'error_creating_account'
                ? 'createaccounterrordialog_duplicateaccounterrorprimarybutton'
                : 'genericerrordialog_primarybutton'
              )
              | dictionary
              | async
          }">
          {{
            (data?.error?.error?.message === 'error_creating_account'
              ? 'createaccounterrordialog_duplicateaccounterrorprimarybutton'
              : 'genericerrordialog_primarybutton'
            )
              | dictionary
              | async
          }}
        </app-sd-flat-button-a11y>
      </ng-container>
    </app-sd-dialog-template-a11y>
  `,
  styleUrls: ['./create-account-error-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SdDialogTemplateA11yModule,
    DictionaryPipeModule,
    AsyncPipe,
    SdFlatButtonA11yModule,
  ],
})
export class CreateAccountErrorDialogComponent {
  cdn = environment.cdn;
  sdColorPalette = SdColorPalette;
  dialogTemplateTypes = DialogTemplateTypes;

  constructor(
    private readonly dialogRef: MatDialogRef<CreateAccountErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { error: HttpErrorResponse }
  ) {}

  onClose(): void {
    this.dialogRef.close();
  }
}
