import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { FileItem } from 'src/app/files/models/file-item.model';
import { FilesSortOption } from 'src/app/files/models/file-sort-option.model';
import { FileType } from 'src/app/files/models/file-type.enum';
import {
  SearchFileListActions,
  SearchFileListActionsTypes,
} from './search-file-list.actions';

export interface SearchFileListState extends EntityState<FileItem> {
  gettingFiles: boolean;
  gettingSingleFiles: boolean;
  hasMore: boolean;
  nextPage: string;
  previousPage: string;
  totalCount: number;
  filter: {
    fileName?: string;
    uploadTitle?: string;
    fileType?: FileType;
  };
  sortBy: FilesSortOption;
  openFile: string;
}

export const searchFileListAdapter: EntityAdapter<FileItem> =
  createEntityAdapter<FileItem>({
    selectId: fileItem => fileItem.id,
  });

export const initialFileListState: SearchFileListState =
  searchFileListAdapter.getInitialState({
    gettingFiles: false,
    gettingSingleFiles: false,
    hasMore: false,
    nextPage: null,
    previousPage: null,
    totalCount: null,
    filter: null,
    sortBy: FilesSortOption.lastModified,
    openFile: null,
  });

export function fileListReducer(
  state = initialFileListState,
  action: SearchFileListActions
): SearchFileListState {
  switch (action.type) {
    case SearchFileListActionsTypes.SearchFileListLoaded: {
      const { hasMore, nextPage, previousPage, totalCount, data } =
        action.payload.data;
      return {
        ...state,
        ...searchFileListAdapter.upsertMany(data, state),
        hasMore,
        nextPage,
        previousPage,
        totalCount,
        filter: {
          ...state.filter,
          fileName: action.payload.filter,
          uploadTitle: action.payload.filter,
        },
      };
    }
    case SearchFileListActionsTypes.SearchFileListReset: {
      return {
        ...state,
        filter: null,
        ...searchFileListAdapter.removeAll(state),
        gettingSingleFiles: false,
        hasMore: false,
        nextPage: null,
        previousPage: null,
        totalCount: null,
        sortBy: FilesSortOption.lastModified,
        openFile: null,
      };
    }
    case SearchFileListActionsTypes.SearchFileDeleted:
      return searchFileListAdapter.removeOne(action.payload.id, state);
    default:
      return state;
  }
}

export const { selectAll, selectEntities, selectTotal } =
  searchFileListAdapter.getSelectors();
