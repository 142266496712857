import { filesStateSelector } from './files.selectors';
import { createSelector } from '@ngrx/store';
import { selectEntities } from './file-item.reducer';

export const fileItemStateSelector = createSelector(
  filesStateSelector,
  state => state.fileItems
);

export const fileStateItemEntitiesSelector = createSelector(
  fileItemStateSelector,
  selectEntities
);

export const fileItemEntitySelector = (payload: { id: string }) =>
  createSelector(
    fileStateItemEntitiesSelector,
    entities => entities[payload.id]
  );

export const fileItemProgressSelector = (payload: { id: string }) =>
  createSelector(
    fileItemEntitySelector(payload),
    fileStateItem => fileStateItem?.progress ?? 0
  );

export const fileItemProgressTypeSelector = (payload: { id: string }) =>
  createSelector(fileItemProgressSelector(payload), progress =>
    progress === -1 ? 'indeterminate' : 'determinate'
  );

export const fileItemSpinnerSelector = (payload: { id: string }) =>
  createSelector(fileItemProgressSelector(payload), progress => progress !== 0);
