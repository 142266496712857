import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragAndDropDirective } from './directive/drag-and-drop/drag-and-drop.directive';
import { OverlayContainer } from '@angular/cdk/overlay';
import { DragDropOverlaySnackBarContainer } from './directive/drag-and-drop/drag-drop-overlay-container';
import { RecordsUploadButtonsSectionSnackbarComponent } from './components/records-upload-buttons-section-snackbar/records-upload-buttons-section-snackbar.component';
import { SdSvgA11yModule } from 'src/app/shared/sd-svg-a11y/sd-svg-a11y.module';
import { DictionaryPipeModule } from 'src/app/dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  declarations: [
    DragAndDropDirective,
    RecordsUploadButtonsSectionSnackbarComponent,
  ],
  exports: [DragAndDropDirective, RecordsUploadButtonsSectionSnackbarComponent],
  imports: [
    CommonModule,
    SdSvgA11yModule,
    MatSnackBarModule,
    DictionaryPipeModule,
  ], // TODO see this
  providers: [
    {
      provide: OverlayContainer,
      useExisting: DragDropOverlaySnackBarContainer,
    },
  ],
})
export class DragAndDropModule {}
