import { Schedule } from './schedule.model';
import { To } from './to.model';
import {ReminderMessageType} from './reminder-message-type.model';

export class Reminders {
  constructor(
    public name?: string,
    public resource?: string,
    public message?: string,
    public schedule?: Schedule,
    public to?: To,
    public id?: string,
    public status?: string,
    public sideDrawerId?: string,
    public messageType?: ReminderMessageType
  ) {
    if (!status) {
      status = 'pending';
    }
  }
}
