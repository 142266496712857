import {Actions, createEffect, ofType} from '@ngrx/effects';
import {inject} from '@angular/core';
import {ActionsSubject, select, Store} from '@ngrx/store';
import {AppState} from '../../reducers';
import {TimelineActions} from './timeline.actions';
import {catchError, exhaustMap, map, mergeMap, switchMap, take, tap,} from 'rxjs/operators';
import {concat, forkJoin, of, takeUntil} from 'rxjs';
import {activeSideDrawerSelector} from '../../sidedrawer/store/sidedrawer.selector';
import {localeDefaultSelector} from '../../dictionary/store/dictionary.selectors';
import {Locale} from '../../dictionary/models/locale.model';
import {HttpErrorResponse} from '@angular/common/http';
import {ErrorLoaded} from '../../core/store/core.actions';
import {timelineHasMoreSelectorBySideDrawerId, timelineNextPageSelectorBySideDrawerId,} from './timeline.selectors';
import {FilingCabinetActions, filingCabinetSideDrawersSelector,} from '../../filing-cabinet/store/filing-cabinet.store';
import {PaginatorService} from '../../core/services/paginator.service';
import {TimelineEvent} from '../models/timeline-event.model';
import {TimelineHelper} from '../helper/timeline.helper';
import {PaginatorApiResponse} from '../../core/models/paginator-api-response.dto';

export const timelineEventsRequested$ = createEffect(
  () => {
    const actions$ = inject(Actions);
    const store$ = inject(Store<AppState>);
    const service = inject(PaginatorService);
    return actions$.pipe(
      ofType(TimelineActions.eventsRequested),
      switchMap(({ nextPage, sideDrawerId }) =>
        forkJoin([
          of(sideDrawerId).pipe(take(1)),
          of(nextPage).pipe(take(1)),
          store$.pipe(select(localeDefaultSelector), take(1)),
        ])
      ),
      mergeMap(([sideDrawerId, nextPage, localeDefault]) =>
        service
          .getPaginatedResource<TimelineEvent>(
            TimelineHelper.getTimelineResourceUrl(
              sideDrawerId,
              Locale.getLocaleId(localeDefault),
              { nextPage }
            )
          )
          .pipe(
            map(response =>
              TimelineActions.pageLoaded({ response, sideDrawerId })
            ),
            catchError((error: HttpErrorResponse) =>
              of(new ErrorLoaded({ httpError: error, display404: false }))
            )
          )
      )
    );
  },
  { functional: true }
);

export const eventsNextPageRequested$ = createEffect(
  () => {
    const actions$ = inject(Actions);
    const store$ = inject(Store<AppState>);
    return actions$.pipe(
      ofType(TimelineActions.eventsNextPageRequested),
      switchMap(() =>
        forkJoin([
          store$.select(activeSideDrawerSelector).pipe(take(1)),
          store$.select(filingCabinetSideDrawersSelector).pipe(take(1)),
        ])
      ),
      map(([activeSd, fcSds]) => {
        if (fcSds?.size > 0) {
          Array.from(fcSds.values()).forEach(sd => {
            const hasMore = store$.selectSignal(
              timelineHasMoreSelectorBySideDrawerId({
                sideDrawerId: sd.sidedrawer,
              })
            )();
            if (hasMore) {
              store$.dispatch(
                TimelineActions.eventsRequested({
                  sideDrawerId: sd.sidedrawer,
                  nextPage: store$.selectSignal(
                    timelineNextPageSelectorBySideDrawerId({
                      sideDrawerId: sd.sidedrawer,
                    })
                  )(),
                })
              );
            }
          });
          return;
        }
        const hasMore = store$.selectSignal(
          timelineHasMoreSelectorBySideDrawerId({
            sideDrawerId: activeSd.id,
          })
        )();
        if (hasMore) {
          store$.dispatch(
            TimelineActions.eventsRequested({
              sideDrawerId: activeSd.id,
              nextPage: store$.selectSignal(
                timelineNextPageSelectorBySideDrawerId({
                  sideDrawerId: activeSd.id,
                })
              )(),
            })
          );
        }
      })
    );
  },
  { functional: true, dispatch: false }
);

export const refreshInformation$ = createEffect(
  () => {
    const actions$ = inject(Actions);
    const store$ = inject(Store<AppState>);
    return actions$.pipe(
      ofType(TimelineActions.refreshInformation),
      tap(() => store$.dispatch(TimelineActions.clearEvents())),
      map(() => TimelineActions.fCEventsRequested())
    );
  },
  { functional: true }
);

export const fCEventsRequested$ = createEffect(
  () => {
    const actions$ = inject(Actions);
    const actionsListener$ = inject(ActionsSubject);
    const store = inject(Store<AppState>);
    const service = inject(PaginatorService);
    return actions$.pipe(
      ofType(TimelineActions.fCEventsRequested),
      switchMap(() =>
        forkJoin([
          store.select(activeSideDrawerSelector).pipe(take(1)),
          store.select(filingCabinetSideDrawersSelector).pipe(take(1)),
          store.select(localeDefaultSelector).pipe(take(1)),
        ])
      ),
      map(([activeSd, fcSds, locale]) => ({
        sideDrawerIds:
          fcSds?.size > 0
            ? Array.from(fcSds?.values())?.map(fcSd => fcSd.sidedrawer)
            : [activeSd.id],
        locale,
      })),
      exhaustMap(({ sideDrawerIds, locale }) =>
        concat(
          ...sideDrawerIds.map(sideDrawerId =>
            service
              .getPaginatedResource<TimelineEvent>(
                TimelineHelper.getTimelineResourceUrl(
                  sideDrawerId,
                  Locale.getLocaleId(locale)
                )
              )
              .pipe(
                catchError((error: HttpErrorResponse) => {
                  if (
                    error.status !== 401 &&
                    error.status !== 404 &&
                    error.status !== 403
                  ) {
                    store.dispatch(
                      new ErrorLoaded({ httpError: error, display404: false })
                    );
                  }
                  return of(null);
                }),
                tap((response: PaginatorApiResponse<TimelineEvent> | null) => {
                  if (response) {
                    store.dispatch(
                      TimelineActions.pageLoaded({ response, sideDrawerId })
                    );
                  }
                })
              )
          )
        ).pipe(
          takeUntil(
            actionsListener$.pipe(
              ofType(FilingCabinetActions.filingCabinetClearAllData)
            )
          )
        )
      )
    );
  },
  { functional: true, dispatch: false }
);
