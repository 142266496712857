import {
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  inject,
  Input,
  Output,
  Signal,
  signal,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { SdColorPalette } from 'src/app/core/models/enums/sd-color-palette-enum';
import { AppState } from 'src/app/reducers';
import { SdLinkButtonA11yTemplates } from 'src/app/shared/sd-link-button-a11y/model/sd-link-button-a11y.enum';
import { environment } from 'src/environments/environment';
import { NgForOf, NgIf } from '@angular/common';
import { SdProgressSpinnerA11yModule } from 'src/app/shared/sd-progress-spinner-a11y/sd-progress-spinner-a11y/sd-progress-spinner-a11y.module';
import { SdTooltipButtonA11yComponent } from 'src/app/shared/sd-tooltip-button-a11y/component/sd-tooltip-button-a11y/sd-tooltip-button-a11y.component';
import { NetworkEntity } from '../../models/network-entity.enum';
import {
  gettingNetworkListSelector,
  NetworkListActions,
  networkListHasMoreSelector,
  networkListSelector,
} from '../../store/network-list.store';
import { isSdOwnerSdEditorSdViewerSelector } from '../../../sidedrawer/store/sidedrawer.selector';
import { NetworkV2 } from '../../models/network.v2.type';
import { DictionarySignalPipe } from '../../../dictionary/pipes/dictionary-signal.pipe';
import { NetworkListItemComponent } from '../network-list-item/network-list-item.component';
import { SdInfiniteListComponent } from '../../../shared/sd-infinite-list/sd-infinite-list.component';

@Component({
  selector: 'app-sidedrawer-network-list-a11y',
  templateUrl: './sidedrawer-network-list-a11y.component.html',
  styleUrls: ['./sidedrawer-network-list-a11y.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    DictionarySignalPipe,
    SdTooltipButtonA11yComponent,
    SdProgressSpinnerA11yModule,
    NgForOf,
    SdInfiniteListComponent,
    NetworkListItemComponent,
  ],
})
export class SidedrawerNetworkListA11yComponent {
  private readonly store = inject(Store<AppState>);
  protected readonly cdn = environment.cdn;
  protected readonly SdLinkButtonA11yTemplates = SdLinkButtonA11yTemplates;
  protected readonly SdColorPalette = SdColorPalette;
  protected readonly NetworkEntity = NetworkEntity;
  viewOnly = signal(false);
  showAddButton = signal(true);
  gettingInformation = this.store.selectSignal(gettingNetworkListSelector);
  hasSdPermissions = this.store.selectSignal(isSdOwnerSdEditorSdViewerSelector);
  networks: Signal<NetworkV2[]> = computed(() =>
    this.store
      .selectSignal(
        networkListSelector({
          asUsers: true,
          sideDrawerId: this._sidedrawerId(),
        })
      )()
      ?.filter(network => network.sidedrawerRole?.length > 0)
  );
  hasMore = this.store.selectSignal(networkListHasMoreSelector);
  _sidedrawerId = signal(null);

  @Input() set sidedrawerId(sideDrawerId: string) {
    this._sidedrawerId.set(sideDrawerId);
    if (this.hasSdPermissions()) {
      this.store.dispatch(NetworkListActions.delete({ sideDrawerId }));
      this.store.dispatch(
        NetworkListActions.requested({
          sideDrawerId,
          clearRequests: true,
          options: {
            entity: 'sidedrawer',
          },
        })
      );
    }
  }

  @Input() set setViewOnly(value: boolean) {
    this.viewOnly.set(value);
  }

  @Input() set setShowAddButton(value: boolean) {
    this.showAddButton.set(value);
  }

  closeModalParent = false;
  @Input() set setCloseModalParent(value: boolean) {
    this.closeModalParent = value;
  }

  @Output() closeModalParentEmitter = new EventEmitter<boolean>();

  onNextPage(): void {
    this.store.dispatch(NetworkListActions.nextPageRequested());
  }

  trackBy(index: number, network: NetworkV2): string {
    return network.id;
  }
}
