import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { dictionarySelector } from '../store/dictionary.selectors';
import { Dictionary } from '../models/dictionary.model';
import { environment } from '../../../environments/environment';

@Pipe({
  name: 'dictionarySignal',
  standalone: true,
})
export class DictionarySignalPipe implements PipeTransform {
  constructor(private readonly store: Store<AppState>) {}

  transform(key: string): string {
    const dictionary = this.store.selectSignal(dictionarySelector)();
    const dicAux = new Dictionary();
    if (
      !Object.keys(dicAux).some(k => k === key) &&
      environment?.dictionaryAlert
    ) {
      alert(`Missing key in dictionary: ${key}`);
    }
    return dictionary?.[key] ?? key;
  }
}
