import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureName, ZipState } from './zip.reducer';
import { ZipQueueStatus } from '../models/zip-queue-status.model';

export const zipStateSelector = createFeatureSelector<ZipState>(featureName);

export const zipStatusSelector = createSelector(
  zipStateSelector,
  state => state.status
);

export const zipQueueSelector = createSelector(
  zipStateSelector,
  state => state.queue
);

export const zipCurrentFileSelector = createSelector(zipQueueSelector, queue =>
  queue.find(item => item?.status === ZipQueueStatus.downloading)
);

export const zipCurrentFileProgressSelector = createSelector(
  zipCurrentFileSelector,
  item => item?.progress
);

export const zipCompletedItemsSelector = createSelector(
  zipQueueSelector,
  queue =>
    queue.filter(item => item?.status === ZipQueueStatus.complete)?.length
);

export const zipTotalItemsSelector = createSelector(
  zipQueueSelector,
  queue => queue.length
);

export const zipProgressSelector = createSelector(zipQueueSelector, queue => {
  const completedItems = queue.filter(
    item => item?.status === ZipQueueStatus.complete
  ).length;
  const totalItems = queue.length;
  const currentFileProgress = queue.find(
    item => item?.status === ZipQueueStatus.downloading
  )?.progress
    ? queue.find(item => item.status === ZipQueueStatus.downloading)?.progress
    : 0;
  return Math.round(
    ((completedItems * 100 + currentFileProgress) / (totalItems * 100)) * 100
  );
});

export const zipSideDrawerSelector = createSelector(
  zipStateSelector,
  state => state.sidedrawer
);

export const zipErrorsSelector = createSelector(
  zipStateSelector,
  state => state.errors
);

export const zipRecordListSelector = createSelector(
  zipStateSelector,
  status => status.recordsList
);

export const zipRecordListItemByIdSelector = (payload: { id: string }) =>
  createSelector(zipRecordListSelector, list => list.get(payload.id));
