import { StartFlowEffects } from '../../../../start-flow/store/start-flow.effects';
import { createEffect, ofType } from '@ngrx/effects';
import {
  GetDictionaryWithBrowserLocale,
  StartFlowActionsTypes,
  StartFlowConfigurationSetFirstLogin,
  StartFlowStepUpdated,
} from '../../../../start-flow/store/start-flow.actions';
import { mergeMap, take, tap } from 'rxjs/operators';
import { StartFlowStepKeys } from '../../../../start-flow/models/start-flow-step-keys.model';
import { StartFlowStepStatus } from '../../../../start-flow/models/start-flow-step-status.model';
import { languageSelector } from '../../../../dictionary/store/dictionary.selectors';
import { Language } from '../../../../dictionary/models/language.model';
import { LocaleDefaultChange } from '../../../../dictionary/store/dictionary.actions';
import { RoutesHelper } from '../../../../core/helpers/routes.helper';
import { Locale } from '../../../../dictionary/models/locale.model';
import { DictionaryHelper } from '../../../../dictionary/helper/dictionary.helper';
import { CoreRoutes } from '../../../../core/routes/core.routes';
import { forkJoin, of } from 'rxjs';
import {
  authRolesSelector,
  authUserSelector,
} from '../../../../auth/store/auth.selectors';
import { CreateAccountRequested } from '../../../../account/store/account.actions';
import { PrimaryResidence } from '../../../../account/models/primary-residence.model';
import { tenantRolesAsStringsSelector } from '../../../../core/store/core.selectors';
import { Injectable } from '@angular/core';
import { Country } from '../../../../shared/sd-forms/models/country.model';

@Injectable()
export class TdStartFlowEffects extends StartFlowEffects {
  getDictionaryWithBrowserLocale$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<GetDictionaryWithBrowserLocale>(
          StartFlowActionsTypes.GetDictionaryWithBrowserLocale
        ),
        tap(() => {
          this.store.dispatch(
            new StartFlowStepUpdated({
              key: StartFlowStepKeys.getDictionaryWithBrowserLocaleStep,
              step: { status: StartFlowStepStatus.processing },
            })
          );
        }),
        mergeMap(action =>
          forkJoin([
            of(action),
            this.store.select(languageSelector).pipe(take(1)),
            this.store.select(authRolesSelector).pipe(take(1)),
            this.store.select(authUserSelector).pipe(take(1)),
            this.store.select(tenantRolesAsStringsSelector).pipe(take(1)),
          ])
        ),
        mergeMap(([action, languages, roles, authUser, tenantRoles]) => {
          const locale = Language.getBrowserLocale(languages);
          this.store.dispatch(new LocaleDefaultChange({ data: locale }));
          const queryParams = RoutesHelper.getParams(false);
          return this.dictionaryService
            .getDictionary(Locale.getLocaleId(locale))
            .pipe(
              tap({
                next: response => {
                  DictionaryHelper.loadDictionaryStore(response, this.store);
                  const { redirectToCoreError } = action.payload;
                  if (redirectToCoreError) {
                    this.router.navigate(
                      [`/${CoreRoutes.root}/${CoreRoutes.error}`],
                      { queryParams }
                    );
                    return;
                  }
                  if (roles.length === 0) {
                    this.router.navigate(
                      [`${CoreRoutes.root}/${CoreRoutes.forbidden}`],
                      { queryParams }
                    );
                    return;
                  }
                  this.store.dispatch(
                    new StartFlowConfigurationSetFirstLogin({
                      firstLogIn: true,
                    })
                  );
                  if (
                    roles.some(role =>
                      tenantRoles.some(tenantRole => tenantRole === role)
                    )
                  ) {
                    const primaryResidenceSelected = new PrimaryResidence();
                    primaryResidenceSelected.country =
                      Country.getCountryByCountryCode(locale.countryCode)
                        ?.countryName ?? locale.countryCode;
                    this.store.dispatch(
                      new CreateAccountRequested({
                        form: {
                          locale: locale,
                          firstName: authUser.givenName,
                          lastName: authUser.familyName,
                          legalsChecked: true,
                          primaryResidenceSelected,
                        },
                      })
                    );
                    return;
                  }
                  this.router.navigate(
                    [`/${CoreRoutes.root}/${CoreRoutes.noAccount}`],
                    { queryParams }
                  );
                },
                error: () => {
                  this.router.navigate(
                    [`/${CoreRoutes.root}/${CoreRoutes.error}/true`],
                    { queryParams }
                  );
                  return;
                },
              })
            );
        })
      ),
    { dispatch: false }
  );
}
