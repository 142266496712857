import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { PlanRequested } from '../models/plan-requested.model';
import {
  PlanRequestedListActions,
  PlanRequestedListActionTypes,
} from './plans-list.actions';
import { DateHelper } from '../../core/helpers/date.helper';
import { PlansSortOption } from '../models/plans-sort-option.model';

export interface PlansListState extends EntityState<PlanRequested> {
  alreadyCompleted?: boolean;
}

export const planRequestedListAdapter: EntityAdapter<PlanRequested> =
  createEntityAdapter<PlanRequested>({
    selectId: planRequested => planRequested.id,
  });

export const initialPlanRequestedListState: PlansListState =
  planRequestedListAdapter.getInitialState();

export function plansListReducer(
  state: PlansListState = initialPlanRequestedListState,
  action: PlanRequestedListActions
): PlansListState {
  switch (action.type) {
    case PlanRequestedListActionTypes.PlanRequestedLoaded:
      return planRequestedListAdapter.setAll(
        sortPlans(action.payload.plans, action.payload.sortBy),
        state
      );
    case PlanRequestedListActionTypes.PlanRequestedLoadedUpdated:
      return planRequestedListAdapter.upsertMany(
        sortPlans(action.payload.plans, action.payload.sortBy),
        state
      );
    case PlanRequestedListActionTypes.PlanRequestedAdded:
      return planRequestedListAdapter.addOne(action.payload.item, state);
    case PlanRequestedListActionTypes.PlanRequestedUpdated:
    case PlanRequestedListActionTypes.PlanRequestedItemsLoaded:
      return planRequestedListAdapter.updateOne(action.payload.item, state);
    case PlanRequestedListActionTypes.PlanRequestedDeleted:
      return planRequestedListAdapter.removeOne(action.payload.id, state);
    case PlanRequestedListActionTypes.PlanRequestedRemoved:
      return planRequestedListAdapter.removeAll(state);
    case PlanRequestedListActionTypes.PlanRequestedAlreadyCompleted:
      return {
        ...state,
        alreadyCompleted: action.payload.alreadyCompleted,
      };
    default:
      return state;
  }
}

export const { selectAll } = planRequestedListAdapter.getSelectors();

const sortPlans = (
  list: PlanRequested[],
  sortBy: PlansSortOption
): PlanRequested[] => {
  try {
    if (!!list && list.length > 0) {
      const sortedList = [...list];
      switch (sortBy) {
        case PlansSortOption.lastModified:
          return sortedList.sort((a, b) =>
            DateHelper.compareDates(b.updatedAt, a.updatedAt)
          );
        case PlansSortOption.nameAscending:
          return sortedList.sort((a, b) =>
            a.plan.name[0].value.localeCompare(b.plan.name[0].value)
          );
        case PlansSortOption.nameDescending:
          return sortedList.sort((a, b) =>
            b.plan.name[0].value.localeCompare(a.plan.name[0].value)
          );
        case PlansSortOption.completionAscending:
          return sortedList.sort(
            (a, b) =>
              a.completedItems / a.totalItems - b.completedItems / b.totalItems
          );
        case PlansSortOption.completionDescending:
          return sortedList.sort(
            (a, b) =>
              b.completedItems / b.totalItems - a.completedItems / a.totalItems
          );
        case PlansSortOption.lastModifiedByAscending:
          return sortedList.sort((a, b) =>
            a.lastModifiedBy.localeCompare(b.lastModifiedBy)
          );
        case PlansSortOption.lastModifiedByDescending:
          return sortedList.sort((a, b) =>
            b.lastModifiedBy.localeCompare(a.lastModifiedBy)
          );
        default:
          return sortedList;
      }
    } else {
      return list;
    }
  } catch (e) {
    console.error(e);
    return list;
  }
};
