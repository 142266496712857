import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import {
  catchError,
  forkJoin,
  map,
  mergeMap,
  of,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { PaginatorService } from 'src/app/core/services/paginator.service';
import { ErrorLoaded } from 'src/app/core/store/core.actions';
import { FilesHelper } from 'src/app/files/helpers/files.helper';
import { FileItem } from 'src/app/files/models/file-item.model';
import { FilesService } from 'src/app/files/services/files.service';
import {
  FileHistoryActionsTypes,
  FileHistoryDeleted,
  GettingSingleFileHistoryChange,
  SingleFileHistoryLoaded,
  SingleFileHistoryRequested,
} from 'src/app/files/store/file-history.actions';
import { AppState } from 'src/app/reducers';
import { activeSideDrawerSelector } from 'src/app/sidedrawer/store/sidedrawer.selector';
import {
  SearchFileDeleted,
  SearchFileListActionsTypes,
  SearchFileListLoaded,
  SearchFileListNextPageRequested,
  SearchFileListRequested,
  SearchFileListReset,
} from './search-file-list.actions';
import { Searching } from './search-bar.actions';
import { searchFilesListStateSelector } from './search-file-list.selector';

@Injectable()
export class SearchFileListEffects {
  fileListRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<SearchFileListRequested>(
        SearchFileListActionsTypes.SearchFileListRequested
      ),
      tap(() => this.store.dispatch(new Searching({ searching: true }))),
      mergeMap(action =>
        this.paginatorService
          .getPaginatedResource<FileItem>(
            FilesHelper.getFileResourceUrlBySideDrawerId(
              action.payload.sideDrawerId,
              {
                nextPage: action.payload.nextPage,
                uploadTitle: action.payload.uploadTitle,
              }
            )
          )
          .pipe(
            takeUntil(
              this.actionsListener$.pipe(
                ofType<SearchFileListReset>(
                  SearchFileListActionsTypes.SearchFileListReset
                ),
                tap(() => {
                  this.store.dispatch(new Searching({ searching: false }));
                })
              )
            ),
            map(response => ({
              ...response,
              data: response.data.map(fileItem => {
                return fileItem.fileToken
                  ? {
                      ...fileItem,
                      sideDrawerId: action.payload.sideDrawerId,
                      recordId: fileItem.record,
                    }
                  : FilesHelper.getCloudStorageFileInformationFromUrl({
                      ...fileItem,
                      sideDrawerId: action.payload.sideDrawerId,
                      recordId: FilesHelper.getRecordIdFromFileUrl(fileItem),
                    });
              }),
            })),
            map(response => {
              return new SearchFileListLoaded({
                data: response,
                filter: action.payload?.fileName,
              });
            }),
            tap(() => this.store.dispatch(new Searching({ searching: false }))),
            catchError(error => {
              return of(new ErrorLoaded({ httpError: { ...error.httpError } }));
            })
          )
      )
    );
  });

  fileListNextPageRequested$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SearchFileListNextPageRequested>(
          SearchFileListActionsTypes.SearchFileListNextPageRequested
        ),
        mergeMap(() =>
          forkJoin([
            this.store.pipe(select(searchFilesListStateSelector), take(1)),
            this.store.pipe(select(activeSideDrawerSelector), take(1)),
          ]).pipe(
            tap(([searchFileState, activeSD]) => {
              const { filter, nextPage, hasMore, gettingFiles } =
                searchFileState;
              if (!hasMore || gettingFiles) {
                return;
              }
              this.store.dispatch(
                new SearchFileListRequested({
                  sideDrawerId: activeSD.id,
                  nextPage,
                  fileName: filter?.fileName,
                  uploadTitle: filter?.uploadTitle,
                })
              );
            })
          )
        )
      ),
    { dispatch: false }
  );

  singleFileHistoryRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SingleFileHistoryRequested>(
        FileHistoryActionsTypes.SingleFileHistoryRequested
      ),
      tap(() =>
        this.store.dispatch(new GettingSingleFileHistoryChange({ state: true }))
      ),
      mergeMap(action =>
        this.filesService
          .getFileItem(
            action.payload.sideDrawerId,
            action.payload.recordId,
            action.payload.fileId
          )
          .pipe(
            tap(() =>
              this.store.dispatch(
                new GettingSingleFileHistoryChange({ state: false })
              )
            ),
            map(fileItem => new SingleFileHistoryLoaded({ fileItem })),
            catchError(e =>
              of(
                new ErrorLoaded({
                  httpError: e,
                  display404: action.payload.display404,
                })
              )
            )
          )
      )
    )
  );

  fileHistoryDeleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FileHistoryDeleted>(FileHistoryActionsTypes.FileHistoryDeleted),
      map(({ payload }) => new SearchFileDeleted({ id: payload.id }))
    )
  );

  constructor(
    private readonly paginatorService: PaginatorService,
    private readonly filesService: FilesService,
    private readonly store: Store<AppState>,
    private readonly actions$: Actions,
    private readonly actionsListener$: ActionsSubject
  ) {}
}
