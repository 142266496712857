import {
  SdBackdropActions,
  SdBackdropActionsTypes,
} from './sd-backdrop.actions';

export const featureName = 'sd-backdrop';

export interface SdBackdropState {
  displayBackdrop: boolean;
  grayed: boolean;
}

export const initialSdBackdropState: SdBackdropState = {
  displayBackdrop: false,
  grayed: false,
};

export function sdBackdropReducer(
  state: SdBackdropState = initialSdBackdropState,
  action: SdBackdropActions
): SdBackdropState {
  switch (action.type) {
    case SdBackdropActionsTypes.DisplaySdBackDrop:
      return {
        ...state,
        displayBackdrop: true,
        grayed: action.payload.grayed,
      };
    case SdBackdropActionsTypes.SdBackDropClicked:
      return initialSdBackdropState;
    default:
      return state;
  }
}
