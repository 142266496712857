import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DictionaryState, featureName } from './dictionary.reducer';
import { SubscriptionStatus } from '../models/subscription-status.model';
import { DictionaryDisplayType } from '../models/dictionary-display-type.model';
import { DictionaryRoleEntity } from '../models/dictionary-role.model';
import { IdpMfa } from '../../account/models/idp-mfa.model';
import { TdVcard, TdVcardClass } from '../models/td.vcard';
import { Language } from '../models/language.model';

export const dictionaryStateSelector =
  createFeatureSelector<DictionaryState>(featureName);

export const languageSelector = createSelector(
  dictionaryStateSelector,
  state =>
    state.languages?.filter(language =>
      language.locales.some(locale => locale.published)
    ) ?? []
);

export const languageByLanguageCodeSelector = (payload: {
  languageCode: string;
}) =>
  createSelector(languageSelector, languages =>
    languages.find(language => language.languageCode === payload.languageCode)
  );

export const languageByLocaleIdSelector = (payload: { localeId: string }) =>
  createSelector(languageSelector, languages =>
    languages.find(language =>
      language.locales.some(locale => locale.localeId === payload.localeId)
    )
  );
export const localesSelector = createSelector(languageSelector, languages =>
  Language.getLocales(languages)
);

export const localeByLocaleIdSelector = (payload: { localeId: string }) =>
  createSelector(localesSelector, locales =>
    locales.find(locale => locale.localeId === payload.localeId)
  );

export const localeDefaultSelector = createSelector(
  dictionaryStateSelector,
  state => (state.localeDefault ? state.localeDefault : null)
);

export const dictionarySelector = createSelector(
  dictionaryStateSelector,
  state => (state.dictionary ? state.dictionary : null)
);

export const sdTypesSelector = createSelector(dictionaryStateSelector, state =>
  state.sideDrawerTypes
    ? state.sideDrawerTypes.filter(type => type.sdtype_enumid !== 'other')
    : null
);

export const sdTypeOtherSelector = createSelector(
  dictionaryStateSelector,
  state =>
    state.sideDrawerTypes
      ? state.sideDrawerTypes.find(type => type.sdtype_enumid === 'other')
      : null
);

export const tosSelector = createSelector(dictionaryStateSelector, state =>
  !!state.tos && !!state.tos[0] ? state.tos[0] : null
);

export const privacyPoliciesSelector = createSelector(
  dictionaryStateSelector,
  state =>
    !!state.privacyPolicies && !!state.privacyPolicies[0]
      ? state.privacyPolicies[0]
      : null
);

export const plansSelector = createSelector(dictionaryStateSelector, state =>
  !!state.plans && state.plans.length > 0 ? state.plans : null
);

export const plansSelectorByPlanId = (payload: { planId: string }) =>
  createSelector(plansSelector, plans =>
    !!plans && plans.length > 0
      ? plans.find(plan => plan.plan_stripeid === payload.planId)
      : null
  );

export const supportSelector = createSelector(dictionaryStateSelector, state =>
  !!state.supports && !!state.supports[0] ? state.supports[0] : null
);

export const relationshipSelector = createSelector(
  dictionaryStateSelector,
  state => state.relationships
);

export const relationshipTypesSelector = createSelector(
  dictionaryStateSelector,
  state => state.relationshipTypes
);

export const blockedFilesFormatSelector = createSelector(
  dictionaryStateSelector,
  state => state.blockedFileFormats
);

export const imageFilesFormatSelector = createSelector(
  dictionaryStateSelector,
  state => state.imageFileFormats
);

export const videoFilesFormatSelector = createSelector(
  dictionaryStateSelector,
  state => state.videoFileFormats
);

export const helpSectionsSelector = createSelector(
  dictionaryStateSelector,
  state =>
    !!state.helpSections && state.helpSections.length > 0
      ? state.helpSections
      : null
);

export const helpVideosSelector = createSelector(
  dictionaryStateSelector,
  state =>
    !!state.helpVideos && state.helpVideos.length > 0 ? state.helpVideos : null
);

export const subscriptionsStatusesSelector = createSelector(
  dictionaryStateSelector,
  state => state.subscriptionsStatuses
);

export const subscriptionsStatusByKeyIdSelector = (payload: {
  keyId: string;
}) =>
  createSelector(
    subscriptionsStatusesSelector,
    (subscriptionsStatuses: SubscriptionStatus[]) =>
      subscriptionsStatuses.find(
        item => item.subsstatus_keyid === payload.keyId
      )
  );

export const displayTypesSelector = createSelector(
  dictionaryStateSelector,
  state => state.displayTypes
);

export const displayTypesSelectablesSelector = createSelector(
  displayTypesSelector,
  dTypes => dTypes.filter(dt => dt.displaytype_selectable)
);

export const displayTypeByEnumId = (payload: { enumId: string }) =>
  createSelector(
    displayTypesSelector,
    (displayTypes: DictionaryDisplayType[]) => {
      if (
        displayTypes.find(type => type.displaytype_enumid === payload.enumId)
      ) {
        return displayTypes.find(
          type => type.displaytype_enumid === payload.enumId
        );
      }
      if (displayTypes.find(type => type.displaytype_enumid === 'preview')) {
        return displayTypes.find(type => type.displaytype_enumid === 'preview');
      }
      return displayTypes[0];
    }
  );

export const dicCurrenciesSelector = createSelector(
  dictionaryStateSelector,
  state =>
    state.currencies
      ? state.currencies.filter(currency => currency.enabled)
      : null
);

export const currencySelectorByCurrency = (payload: { currency: string }) =>
  createSelector(
    dicCurrenciesSelector,
    currencies => currencies?.find(cu => cu.currency === payload.currency)
  );

export const customFieldsFormTypesSelector = createSelector(
  dictionaryStateSelector,
  state => (state.customFieldFormTypes ? state.customFieldFormTypes : null)
);

export const customFieldFormTypeByFormTypeSelector = (payload: {
  formType: string;
}) =>
  createSelector(customFieldsFormTypesSelector, customFieldItemsFormTypes => {
    return customFieldItemsFormTypes?.find(ft => ft.type === payload.formType);
  });

export const dataBaseRegionsSelector = createSelector(
  dictionaryStateSelector,
  state => state.dataBaseRegions
);

export const dataBaseRegionsWithCountryCodeSelector = createSelector(
  dataBaseRegionsSelector,
  dataBaseRegions => dataBaseRegions.filter(region =>
    region.countrycode?.length > 0)
);

export const defaultDataBaseRegionSelector = createSelector(
  dataBaseRegionsSelector,
  regions => regions.find(region => region.isdefault)
);

export const frequencySelector = createSelector(
  dictionaryStateSelector,
  state => (state.frequencies ? state.frequencies : null)
);

export const reminderStatusSelector = createSelector(
  dictionaryStateSelector,
  state => (state.reminderStatus ? state.reminderStatus : null)
);

export const searchFilterTypeSelector = createSelector(
  dictionaryStateSelector,
  state =>
    !!state.sdSearchEntities && state.sdSearchEntities.length > 0
      ? state.sdSearchEntities
      : null
);

export const dataBaseRegionByCountryCode = (payload: { countryCode: string }) =>
  createSelector(
    dataBaseRegionsSelector,
    defaultDataBaseRegionSelector,
    (regions, defaultRegion) => {
      const region = regions.find(
        reg =>
          reg.countrycode?.trim()?.toLowerCase() ===
          payload.countryCode?.trim()?.toLowerCase()
      );
      return region ?? defaultRegion;
    }
  );

export const dictionaryRolesListSelector = createSelector(
  dictionaryStateSelector,
  state => state.dictionaryRoles
);

export const dictionaryRolesListByEntitySelector = (payload: {
  entity: DictionaryRoleEntity;
}) =>
  createSelector(dictionaryRolesListSelector, list =>
    list.filter(role => role.entity === payload.entity)
  );

export const dictionaryRolesRoleGroupIdsByEntitySelector = (payload: {
  entity: DictionaryRoleEntity;
}) =>
  createSelector(
    dictionaryRolesListByEntitySelector({ entity: payload.entity }),
    list =>
      list.reduce((acc, role) => {
        if (!acc.some(key => key === role.roleGroupId)) {
          acc.push(role.roleGroupId);
        }
        return acc;
      }, [])
  );

export const dictionaryRolesByRoleGroupIdSelector = (payload: {
  roleGroupId: string;
}) =>
  createSelector(dictionaryRolesListSelector, list =>
    list.filter(role => role.roleGroupId === payload.roleGroupId)
  );

export const dictionaryRoleByRoleIdSelector = (payload: { roleId: string }) =>
  createSelector(dictionaryRolesListSelector, list =>
    list.find(role => role.roleId === payload.roleId)
  );

export const idpMfaByBrandCodesSelector = createSelector(
  dictionaryStateSelector,
  state => state.idpMfaByBrandCodes
);

export const menuByIdpSelector = createSelector(
  dictionaryStateSelector,
  state => state.menubyidp
);

export const menuByIdpByOpenIdSelector = (payload: { openId: string }) =>
  createSelector(menuByIdpSelector, menus =>
    menus.find(menu => payload.openId.startsWith(menu.idProvider))
  );

export const idpMfaByBrandCodesByBrandCodeSelector = (payload: {
  brandCode: string;
}) =>
  createSelector(
    idpMfaByBrandCodesSelector,
    idpMfaConfigurations =>
      idpMfaConfigurations?.find(ic => ic.brandCode === payload.brandCode)
        ?.idpMfa ?? IdpMfa.never
  );

export const gettingDictionarySelector = createSelector(
  dictionaryStateSelector,
  state => state.gettingDictionary
);

export const tdVcardSelector = createSelector(
  dictionaryStateSelector,
  state => state?.tdVCard
);

export const tdVcardSelectorMapped = createSelector(
  dictionaryStateSelector,
  state => {
    const arr: TdVcard[] = [];
    state.tdVCard?.map(tdVcard => {
      arr.push(TdVcardClass.fromDictionary(tdVcard));
    });
    return arr;
  }
);

export const tdVcardByAdvisorEntityIdSelector = (payload: {
  advisorEntityId: string;
}) =>
  createSelector(
    tdVcardSelectorMapped,
    tdVCards =>
      tdVCards?.find(
        tdVcard => tdVcard.advisorCode === payload.advisorEntityId
      ) ?? null
  );

export const sdLabelsSelector = createSelector(
  dictionaryStateSelector,
  state => state.sdLabels
);

export const sdLabelsByCobrandIdSelector = (payload: { cobrandId: string }) =>
  createSelector(
    sdLabelsSelector,
    sdLabels =>
      sdLabels?.find(sdLabel => sdLabel.cobrandId === payload.cobrandId)
  );

export const helpArticlesSelector = createSelector(
  dictionaryStateSelector,
  state => state.helpArticles?.filter(article => article.published) ?? []
);
export const helpArticleByNameSelector = (payload: { name: string }) =>
  createSelector(
    helpArticlesSelector,
    helpArticles => helpArticles?.find(article => article.name === payload.name)
  );
