import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SdAutocompleteA11yComponent } from './components/sd-autocomplete-a11y/sd-autocomplete-a11y.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ReactiveFormsModule } from '@angular/forms';
import { SdProgressSpinnerA11yModule } from '../sd-progress-spinner-a11y/sd-progress-spinner-a11y/sd-progress-spinner-a11y.module';
import { SdSvgA11yModule } from '../sd-svg-a11y/sd-svg-a11y.module';
import { DictionaryPipeModule } from 'src/app/dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { SdFormattedDatePipeModule } from '../sd-formatted-date-pipe/sd-formatted-date-pipe.module';
import { SdTooltipButtonA11yComponent } from '../sd-tooltip-button-a11y/component/sd-tooltip-button-a11y/sd-tooltip-button-a11y.component';
import { SdLinkButtonA11yModule } from '../sd-link-button-a11y/sd-link-button-a11y.module';
import { SdInfiniteListComponent } from '../sd-infinite-list/sd-infinite-list.component';
import { MatInputModule } from '@angular/material/input';
import { SdIconButtonA11yComponent } from '../sd-icon-button-a11y/components/sd-icon-button-a11y/sd-icon-button-a11y.component';
import { SdAllowEnterTabDirective } from '../sd-directives/sd-allowEnterTabDirective/sd-allowEntertabDirective.directive';
import { DisplayValuePipeModule } from '../sd-pipes/display-value-pipe/display-value-pipe.module';

@NgModule({
  declarations: [SdAutocompleteA11yComponent],
  imports: [
    CommonModule,
    MatInputModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    SdTooltipButtonA11yComponent,
    SdIconButtonA11yComponent,
    SdProgressSpinnerA11yModule,
    SdSvgA11yModule,
    DictionaryPipeModule,
    SdFormattedDatePipeModule,
    SdLinkButtonA11yModule,
    SdInfiniteListComponent,
    SdAllowEnterTabDirective,
    DisplayValuePipeModule,
  ],
  exports: [SdAutocompleteA11yComponent],
})
export class SdAutocompleteA11yModule {}
