import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { map, Observable, startWith, Subscription, tap } from 'rxjs';
import { SdAccessibilitySetting } from 'src/app/core/models/sd-accessibility-model';
import { RecordSubType } from 'src/app/records/models/record-sub-type.model';
import { Record } from 'src/app/records/models/record.model';
import { Option } from 'src/app/shared/sd-forms/models/option.model';
import { AccessibilityHelper } from '../../../../core/helpers/accessibility.helper';
import { SdAutoCompleteA11yEnum } from '../../model/sd-autocomplete-a11y.enum';
import { AppState } from '../../../../reducers';
import { select, Store } from '@ngrx/store';
import { RecordsListFilterChange } from '../../../../records/store/records-list.actions';
import { debounceTime, take } from 'rxjs/operators';
import {
  recordListFilterSelector,
  recordsListHasMoreSelector,
} from 'src/app/records/store/records-list.selectors';
import { DateHelper } from 'src/app/core/helpers/date.helper';
import { RelatedRecord } from 'src/app/related-records/models/related-record.model';
import { SdLinkButtonA11yTemplates } from 'src/app/shared/sd-link-button-a11y/model/sd-link-button-a11y.enum';
import {
  dictionarySelector,
  localeDefaultSelector,
} from 'src/app/dictionary/store/dictionary.selectors';
import { Locale } from 'src/app/dictionary/models/locale.model';
import { UtilsHelper } from 'src/app/core/helpers/utils.helper';
import { Country } from 'src/app/shared/sd-forms/models/country.model';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Dictionary } from 'src/app/dictionary/models/dictionary.model';

@Component({
  selector: 'app-sd-autocomplete-a11y',
  templateUrl: './sd-autocomplete-a11y.component.html',
  styleUrls: ['./sd-autocomplete-a11y.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SdAutocompleteA11yComponent
  implements OnInit, OnChanges, AfterViewChecked
{
  @Input() template = SdAutoCompleteA11yEnum.default;
  @Input() readonly = false;
  sdAutoCompleteA11yEnum = SdAutoCompleteA11yEnum;
  options: Option[] = [];
  localeId: string;
  optionsRecordSubType: RecordSubType[] = [];
  optionsRecord: RelatedRecord[] = [];
  optionsCountry: Country[] = [];

  @Input() loading = false;
  @Input() controller: UntypedFormControl;
  @Input() placeholder: string;
  @Input() error: string;
  @Input() keyMode = false;
  @Input() customTooltip: string;
  @Input() iconButtonSvg: string;
  @Input() spinner = false;
  @Input() required: boolean = false;

  happensToday: boolean;
  updatedAt: Date;
  lastModifiedBy: string;
  @Input() infiniteScroll = true;
  @Input() hasMore = false;
  sdAccessibility: SdAccessibilitySetting;
  filteredOptions: Observable<Option[]>;
  filteredOptionsRecordSubType: Observable<RecordSubType[]>;
  filteredOptionsRecord$: Observable<RelatedRecord[]>;
  filteredOptionsCountry$: Observable<Country[]>;

  @Output() optionRecordSubTypeSelected = new EventEmitter<RecordSubType>();
  @Output() optionRecordSelected = new EventEmitter<Record>();
  @Output() optionSelected = new EventEmitter<Option>();
  @Output() recordsNextPage = new EventEmitter<boolean>();
  @Output() optionCountrySelected = new EventEmitter<Country>();

  subscription = new Subscription();
  sdLinkButtonA11yTemplates = SdLinkButtonA11yTemplates;
  hasMoreRecords$ = this.store.pipe(select(recordsListHasMoreSelector));
  dictionary$: Observable<Dictionary> = this.store.select(dictionarySelector);
  resultString = '';

  currentFlag = '';
  constructor(
    private readonly store: Store<AppState>,
    private readonly detectChanges: ChangeDetectorRef,
    private readonly liveAnnounce: LiveAnnouncer
  ) {}

  @Input() set setOptions(options: Option[]) {
    this.options = options.filter(option => !!option?.key && !!option?.value);

    if (this.template === SdAutoCompleteA11yEnum.default) {
      this.filteredOptions = this.controller?.valueChanges.pipe(
        startWith(
          `${
            !!this.controller.value && this.controller.value.length > 0
              ? this.controller.value
              : ''
          }`
        ),
        map(value => (typeof value === 'string' ? value : value?.value)),
        map(value => (value ? this._filter(value) : this.options?.slice()))
      );
    }
  }

  @Input() set setOptionsRecordSubType(value: RecordSubType[]) {
    this.optionsRecordSubType = value;
    if (this.template === SdAutoCompleteA11yEnum.recordSubType) {
      if (this.optionsRecordSubType && this.optionsRecordSubType?.length > 0) {
        this.setValueChangeForFilteredOptionsRecordSubType();
      }
      if (this.optionsRecordSubType?.length === 0) {
        this.setValueChangeForFilteredOptionsRecordSubType();
      }
    }
  }

  @Input() set setOptionsCountry(value: Country[]) {
    this.optionsCountry = value;
    if (this.template === SdAutoCompleteA11yEnum.country) {
      this.filteredOptionsCountry$ = this.controller?.valueChanges.pipe(
        startWith(
          `${
            !!this.controller.value && this.controller.value.length > 0
              ? this.controller.value
              : ''
          }`
        ),
        map(value => (typeof value === 'string' ? value : value?.value)),
        map(value =>
          value ? this._filterCountry(value) : this.optionsCountry?.slice()
        ),
        tap(countries => {
          if (countries.length === 1) {
            this.currentFlag =
              '/assets/flags/' +
              countries[0].countryCode.toLowerCase() +
              '.svg';
          } else {
            this.currentFlag = '';
          }
        })
      );
    }
  }

  @Input() set setOptionsRecord(records: RelatedRecord[]) {
    this.optionsRecord = records;

    if (this.template === SdAutoCompleteA11yEnum.recordRelated) {
      this.filteredOptionsRecord$ = this.controller?.valueChanges.pipe(
        startWith(
          `${
            !!this.controller.value && this.controller.value.length > 0
              ? this.controller.value
              : ''
          }`
        ),
        debounceTime(1000),
        map(value => {
          return this._filterRecord(value);
        })
      );
    }
  }

  @Input() set setSdAccessibility(value: SdAccessibilitySetting) {
    this.sdAccessibility = AccessibilityHelper.setDefaultAccessibility(value);
  }

  ngAfterViewChecked(): void {
    this.detectChanges.markForCheck();
  }

  ngOnInit(): void {
    this.subscription.add(
      this.dictionary$
        .pipe(
          map(dictionary => {
            this.resultString = dictionary.globalparams_resultof;
          })
        )
        .subscribe()
    );
    this.setLocaleDefault();
    if (this.template === SdAutoCompleteA11yEnum.default) {
      this.filteredOptions = this.controller.valueChanges.pipe(
        startWith(
          `${
            !!this.controller.value && this.controller.value.length > 0
              ? this.controller.value
              : ''
          }`
        ),
        map(value => (typeof value === 'string' ? value : value?.value)),
        map(value => (value ? this._filter(value) : this.options?.slice()))
      );
    }
    if (this.template === SdAutoCompleteA11yEnum.recordRelated) {
      this.filteredOptionsRecord$ = this.controller.valueChanges.pipe(
        startWith(
          `${
            !!this.controller.value && this.controller.value.length > 0
              ? this.controller.value
              : ''
          }`
        ),
        debounceTime(1000),
        map(value => this._filterRecord(value))
      );
    }
    if (this.template === SdAutoCompleteA11yEnum.recordSubType) {
      if (this.optionsRecordSubType && this.optionsRecordSubType?.length > 0) {
        this.setValueChangeForFilteredOptionsRecordSubType();
      }
      if (this.optionsRecordSubType?.length === 0) {
        this.setValueChangeForFilteredOptionsRecordSubType();
      }
    }
  }

  ngOnChanges(): void {
    this.filteredOptions = this.controller.valueChanges.pipe(
      startWith(
        `${
          !!this.controller.value && this.controller.value.length > 0
            ? this.controller.value
            : ''
        }`
      ),
      map(value => (typeof value === 'string' ? value : value?.value)),
      map(value => (value ? this._filter(value) : this.options?.slice()))
    );
  }

  displayFn(option): string {
    return option && option.value
      ? option.value
      : option?.length > 0
        ? option
        : '';
  }

  onSelectOptionRecordSubType(optionRecordSubType: RecordSubType): void {
    if (
      UtilsHelper.getDisplayValueFromLocaleId(
        optionRecordSubType?.displayValue,
        this.localeId
      ).toLowerCase() === this.controller.value.toLowerCase()
    ) {
      this.optionRecordSubTypeSelected.emit(optionRecordSubType);
    } else {
      this.optionRecordSubTypeSelected.emit(new RecordSubType('other'));
    }
  }

  onSelectOptionRecordOnKeyDown(optionRecord?: Record): void {
    if (!optionRecord && this.controller.value?.length > 0) {
      this.optionsRecord.map(option => {
        if (option.name.toLowerCase() === this.controller.value.toLowerCase()) {
          this.onSelectOptionRecord(option);
        }
      });
    }
  }

  onSelectOptionRecord(optionRecord: Record): void {
    this.optionRecordSelected.emit(optionRecord);
  }

  onSelectOptionCollaborator(optionCollaborator: Option): void {
    this.optionSelected.emit(optionCollaborator);
  }

  onSelectOptionCollaboratorOnKeyDown(optionCollaborator?: Option): void {
    if (!optionCollaborator && this.controller.value?.value?.length > 0) {
      this.options.map(option => {
        if (
          option.value.toLowerCase() ===
          this.controller.value?.value?.toLowerCase()
        ) {
          this.onSelectOptionCollaborator(option);
        }
      });
    }
  }

  recordTrackBy(id: number, item: Record) {
    return item;
  }

  setRecordDataForSentence(record: RelatedRecord): boolean {
    return DateHelper.happensToday(`${record.updatedAt}`);
  }

  onRecordsNextPage(): void {
    this.recordsNextPage.emit(true);
  }

  private _filter(value: string): Option[] {
    const filterValue = value.toLowerCase();
    const result = this.options.filter(
      option =>
        option.value.toLowerCase().includes(filterValue) ||
        option.description?.toLowerCase()?.includes(filterValue)
    );

    this.liveAnnounce.announce(
      `${result?.length} ${this.resultString} ${this.options?.length}`
    );
    return result;
  }

  private _filterCountry(value: string): Country[] {
    const filterValue = value.toLowerCase();
    return this.optionsCountry.filter(option =>
      option.countryName.toLowerCase().includes(filterValue)
    );
  }

  private _filterRecordSubType(value: string): RecordSubType[] {
    if (!!value && value.length > 0) {
      const filterValue = value.toLowerCase();
      return this.optionsRecordSubType.filter(option =>
        UtilsHelper.getDisplayValueFromLocaleId(
          option?.displayValue,
          this.localeId
        )
          .toLowerCase()
          .includes(filterValue)
      );
    } else {
      return this.optionsRecordSubType;
    }
  }

  private _filterRecord(value: string): RelatedRecord[] {
    const filterValue = value?.toLowerCase().trim();
    const cloneArrayToShow = JSON.parse(
      JSON.stringify([...this.optionsRecord])
    );
    if (filterValue?.length > 0) {
      this.store
        .pipe(
          select(recordListFilterSelector),
          take(1),
          tap(oldFilter => {
            if (oldFilter?.name !== value) {
              this.store.dispatch(
                new RecordsListFilterChange({ name: value, recordTypeName: '' })
              );
            }
          })
        )
        .subscribe();
      const result = this.options.filter(option =>
        option.value.toLowerCase().includes(filterValue)
      );
      this.liveAnnounce.announce(
        `${result?.length} ${this.resultString} ${this.optionsRecord?.length}`
      );
      return cloneArrayToShow.filter(optionRecord =>
        optionRecord.name.toLowerCase().includes(filterValue)
      );
    }
    this.liveAnnounce.announce(
      `${cloneArrayToShow?.length} ${this.resultString} ${cloneArrayToShow?.length}`
    );
    return cloneArrayToShow;
  }

  private setValueChangeForFilteredOptionsRecordSubType(): void {
    this.filteredOptionsRecordSubType = this.controller.valueChanges.pipe(
      startWith(
        `${
          !!this.controller.value && this.controller.value.length > 0
            ? this.controller.value
            : ''
        }`
      ),
      map(value => (typeof value === 'string' ? value : value?.value)),
      map(value =>
        value
          ? this._filterRecordSubType(value)
          : this.optionsRecordSubType?.slice()
      ),
      tap(optionsRecordSubTypes => {
        if (this.controller.pristine) {
          return;
        }
        if (
          UtilsHelper.getDisplayValueFromLocaleId(
            optionsRecordSubTypes[0]?.displayValue,
            this.localeId
          )?.toLowerCase() === this.controller.value?.toLowerCase()
        ) {
          this.optionRecordSubTypeSelected.emit(optionsRecordSubTypes[0]);
        } else {
          this.optionRecordSubTypeSelected.emit(new RecordSubType('other'));
        }
      })
    );
  }

  private setLocaleDefault(): void {
    this.store
      .pipe(
        select(localeDefaultSelector),
        take(1),
        tap(locale => {
          this.localeId = Locale.getLocaleId(locale);
        })
      )
      .subscribe();
  }
}
