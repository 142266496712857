import { SdSearchSideDrawerResultItemA11yModule } from './../../../../../shared/sd-search-sidedrawer-result-item-a11y/sd-search-sidedrawer-result-item-a11y.module';
import { AppState } from 'src/app/reducers';
import { select, Store } from '@ngrx/store';
import { Component, DestroyRef, Inject, inject, OnInit } from '@angular/core';
import { AsyncPipe, NgIf } from '@angular/common';
import { SdDatePickerA11yModule } from 'src/app/shared/sd-date-picker-a11y/sd-date-picker-a11y.module';
import { DictionaryPipeModule } from 'src/app/dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { UntypedFormControl } from '@angular/forms';
import { Locale } from 'src/app/dictionary/models/locale.model';
import { Observable, Subscription } from 'rxjs';
import { localeDefaultSelector } from 'src/app/dictionary/store/dictionary.selectors';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  RecordsNetworksModel,
  SideDrawerNetworksModel,
} from 'src/app/networks/views/networks-form-view/networks-form-view.store';
import { SdDialogTemplateA11yModule } from 'src/app/shared/templates/dialog-template-a11y/sd-dialog-template-a11y.module';
import { SdFlatButtonA11yModule } from 'src/app/shared/sd-flat-button-a11y/sd-flat-button-a11y.module';
import { DialogTemplateTypes } from 'src/app/shared/templates/enums/templates.enum';
import * as moment from 'moment';
import { RecordsListItemModule } from 'src/app/records/shared/records-list-item/records-list-item.module';
import { recordByRecordIdSelector } from 'src/app/records/store/records-list.selectors';
import { sideDrawerNetworkByIdSelector } from 'src/app/sidedrawer-networks/store/side-drawer-network-list.selectors';
import { NewNetworkRow } from 'src/app/networks/models/new-network-row.model';
import { RecordListItemTemplate } from 'src/app/records/models/record-list-item-template.model';

@Component({
  selector: 'app-network-permissions-row-settings-dialog-a11y',
  standalone: true,
  imports: [
    SdDatePickerA11yModule,
    DictionaryPipeModule,
    SdDialogTemplateA11yModule,
    SdFlatButtonA11yModule,
    RecordsListItemModule,
    NgIf,
    AsyncPipe,
    SdSearchSideDrawerResultItemA11yModule,
  ],
  template: `
    <app-sd-dialog-template-a11y
      (closeButtonClicked)="onClose()"
      [type]="dialogTemplateTypes.Info">
      <ng-container dialog-header>
        <!-- TODO Dictionary for expiration date title -->
        <h2>
          {{ 'networkpermissionsrowsettingsdialog_title' | dictionary | async }}
        </h2>
      </ng-container>
      <ng-container dialog-body>
        <div class="network-permissions-row-settings-dialog-container">
          <app-records-list-item
            *ngIf="recordListItem$ | async"
            [recordItem]="recordListItem$ | async"
            [template]="recordListItemTemplate.noaction" />

          <div *ngIf="sideDrawerItem$ | async as sideDrawerItem">
            <app-sd-search-sidedrawer-result-item-a11y
              [setItem]="
                sideDrawerItem
              "></app-sd-search-sidedrawer-result-item-a11y>
          </div>

          <!-- TODO Dictionary, add terms to model -->
          <app-sd-date-picker-a11y
            [setSdAccessibility]="{
              ariaLabel:
                'networkpermissionsrowsettingsdialog_expirationdateplaceholder'
                | dictionary
                | async
            }"
            [setSdAccessibilityForIconAllowsDeleteSelectedValue]="{
              tabIndex: 0,
              ariaLabel:
                'networkpermissionsrowsettingsdialog_expirationdateremove'
                | dictionary
                | async
            }"
            [controller]="expirationDateController"
            [locale]="locale$ | async"
            [placeholder]="
              'networkpermissionsrowsettingsdialog_expirationdateplaceholder'
                | dictionary
                | async
            "
            [error]="('networkpermissionsrowsettingsdialog_expirationdateplaceholder' | dictionary | async) + ' ' +
              'networkpermissionsrowsettingsdialog_expirationdateerror'
                | dictionary
                | async
            "
            [allowsDeleteSelectedValue]="true">
          </app-sd-date-picker-a11y>
        </div>
      </ng-container>

      <ng-container dialog-footer>
        <app-sd-flat-button-a11y
          [setSdAccessibility]="{
            ariaLabel: 'globalparams_cancel' | dictionary | async
          }"
          (buttonClicked)="onClose()"
          [primary]="false">
          {{ 'globalparams_cancel' | dictionary | async }}
        </app-sd-flat-button-a11y>
        <app-sd-flat-button-a11y
          [setSdAccessibility]="{
            ariaLabel: 'globalparams_confirm' | dictionary | async
          }"
          (buttonClicked)="onConfirm()"
          [primary]="true">
          {{ 'globalparams_confirm' | dictionary | async }}
        </app-sd-flat-button-a11y>
      </ng-container>
    </app-sd-dialog-template-a11y>
  `,
  styleUrls: ['./network-permissions-row-settings-dialog-a11y.component.scss'],
})
export class NetworkPermissionsRowSettingsDialogA11yComponent
  implements OnInit
{
  expirationDateController = new UntypedFormControl(null);
  locale$: Observable<Locale> = this.store.pipe(select(localeDefaultSelector));
  subscription = new Subscription();
  dialogTemplateTypes = DialogTemplateTypes;
  destroyRef = inject(DestroyRef);
  recordListItem$ = this.store.pipe(
    select(recordByRecordIdSelector({ recordId: this.data?.recordId }))
  );

  sideDrawerItem$ = this.store.pipe(
    select(sideDrawerNetworkByIdSelector({ id: this.data?.sideDrawerId }))
  );
  recordListItemTemplate = RecordListItemTemplate;
  constructor(
    private readonly store: Store<AppState>,
    public dialogRef: MatDialogRef<NetworkPermissionsRowSettingsDialogA11yComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      permission:
        | RecordsNetworksModel
        | SideDrawerNetworksModel
        | NewNetworkRow;
      recordId: string;
      sideDrawerId: string;
    }
  ) {}

  ngOnInit(): void {
    this.expirationDateController.setValue(
      this.data.permission?.expiryDate ?? new Date()
    );
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onConfirm(): void {
    this.dialogRef.close({
      ...this.data.permission,
      expiryDate: this.expirationDateController.value
        ? moment(this.expirationDateController.value).utc().toISOString()
        : null,
    });
  }
}
