import { CommonModule } from '@angular/common';
import { DictionaryPipeModule } from 'src/app/dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RecordSubtypeAutocompleteA11yComponent } from './components/record-subtype-autocomplete-a11y/record-subtype-autocomplete-a11y.component';
import { SdAutocompleteA11yModule } from 'src/app/shared/sd-autocomplete-a11y/sd-autocomplete-a11y.module';
import { SdTooltipButtonA11yComponent } from 'src/app/shared/sd-tooltip-button-a11y/component/sd-tooltip-button-a11y/sd-tooltip-button-a11y.component';
import { SdInputA11yComponent } from 'src/app/shared/sd-input-a11y/components/sd-input-a11y/sd-input-a11y.component';
import { SdIconButtonA11yComponent } from 'src/app/shared/sd-icon-button-a11y/components/sd-icon-button-a11y/sd-icon-button-a11y.component';

@NgModule({
  declarations: [RecordSubtypeAutocompleteA11yComponent],
  imports: [
    CommonModule,
    SdIconButtonA11yComponent,
    SdTooltipButtonA11yComponent,
    DictionaryPipeModule,
    SdInputA11yComponent,
    ReactiveFormsModule,
    SdAutocompleteA11yModule,
  ],
  exports: [RecordSubtypeAutocompleteA11yComponent],
})
export class RecordSubtypeAutocompleteA11yModule {}
