import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  signal,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  ActivatedRoute,
  PRIMARY_OUTLET,
  Router,
  UrlSegment,
  UrlSegmentGroup,
  UrlTree,
} from '@angular/router';
import { select, Store } from '@ngrx/store';
import { asapScheduler, combineLatest, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, map, take, tap } from 'rxjs/operators';
import { DateHelper } from 'src/app/core/helpers/date.helper';
import { Locale } from 'src/app/dictionary/models/locale.model';
import { localeDefaultSelector } from 'src/app/dictionary/store/dictionary.selectors';
import { activeRecordSelector } from 'src/app/records/store/records-list.selectors';
import { AppState } from 'src/app/reducers';
import { Reminders } from 'src/app/reminders/models/reminders.model';
import { ReminderSpinnerChange } from 'src/app/reminders/store/reminders-list.action';
import {
  remindersErrorsSelector,
  remindersLoadingSelector,
} from 'src/app/reminders/store/reminders-list.selectors';
import { Option } from 'src/app/shared/sd-forms/models/option.model';
import {
  activeSideDrawerSelector,
  sideDrawerSpinnerSelector,
  basePathSelector,
} from 'src/app/sidedrawer/store/sidedrawer.selector';
import * as moment from 'moment';
import { Frequency } from 'src/app/reminders/models/enums/frequency.enum';
import { environment } from 'src/environments/environment';
import { ReminderHelper } from 'src/app/reminders/helpers/reminder-helper';
import { animate, style, transition, trigger } from '@angular/animations';
import { ErrorType } from 'src/app/reminders/models/enums/error-type.enum';
import { ReminderShortDictionary } from 'src/app/reminders/models/reminder-short-dictionary.model';
import { SfrError } from 'src/app/reminders/models/sfr-error.model';
import { SdValidators } from 'src/app/shared/sd-forms/models/sd.validators';
import { DateTimeRangeFormatted } from 'src/app/reminders/models/dateTimeRangeFormatted.model';
import { Record } from 'src/app/records/models/record.model';
import { ReminderFrom } from 'src/app/reminders/models/enums/reminder-from.enum';
import { CommonModule } from '@angular/common';
import { SdContentHeaderA11yModule } from 'src/app/shared/sd-content-header-a11y/sd-content-header-a11y.module';
import { SdTimeA11yComponent } from 'src/app/shared/sd-forms/components/sd-time-a11y/sd-time-a11y.component';
import { SdMainContentTemplateA11yModule } from 'src/app/shared/templates/sd-main-content-template-a11y/sd-main-content-template-a11y.module';
import { AccessibilityHelper } from 'src/app/core/helpers/accessibility.helper';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { SdValidationFormErrors } from 'src/app/account/models/sd-validation-form-errors.model';
import { DictionaryPipeModule } from 'src/app/dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { SdAutocompleteA11yModule } from 'src/app/shared/sd-autocomplete-a11y/sd-autocomplete-a11y.module';
import { SdDatePickerA11yModule } from 'src/app/shared/sd-date-picker-a11y/sd-date-picker-a11y.module';
import { SdFlatButtonA11yModule } from 'src/app/shared/sd-flat-button-a11y/sd-flat-button-a11y.module';
import { SdInputA11yTemplates } from 'src/app/shared/sd-input-a11y/models/sd-input-a11y-templates.enum';
import { SdProgressSpinnerA11yModule } from 'src/app/shared/sd-progress-spinner-a11y/sd-progress-spinner-a11y/sd-progress-spinner-a11y.module';
import { SdSelectorA11Module } from 'src/app/shared/sd-selector-a11y/sd-selector-a11y.module';
import { SdSlideToggleTemplateA11y } from 'src/app/shared/sd-slide-toggle-a11y/model/sd-slide-toggle-template-a11y.enum';
import { SdSlideToggleA11yModule } from 'src/app/shared/sd-slide-toggle-a11y/sd-slide-toggle-a11y.module';
import { RemindersFormRecurrenceA11yComponent } from '../../../reminders-form-recurrence-a11y/components/reminders-form-recurrence-a11y/reminders-form-recurrence-a11y.component';
import { RemindersFormUserListA11yComponent } from '../../../reminders-form-user-list-a11y/components/reminders-form-user-list-a11y/reminders-form-user-list-a11y.component';
import { RemindersFormStore } from './reminders-form-a11y.store';
import { SideDrawer } from 'src/app/sidedrawer/models/side-drawer.model';
import { SdTooltipButtonA11yComponent } from 'src/app/shared/sd-tooltip-button-a11y/component/sd-tooltip-button-a11y/sd-tooltip-button-a11y.component';
import { SdInputA11yComponent } from 'src/app/shared/sd-input-a11y/components/sd-input-a11y/sd-input-a11y.component';
import { SdTextAreaA11yComponent } from 'src/app/shared/sd-text-area-a11y/components/sd-text-area-a11y.component';
import { SdAutoCompleteA11yEnum } from 'src/app/shared/sd-autocomplete-a11y/model/sd-autocomplete-a11y.enum';
import {
  filingCabinetLoadingSelector,
  filingCabinetSelector,
  filingCabinetSideDrawersLoadingSelector,
  filingCabinetSideDrawersWithDataForSdOwnerAndSdEditorAsOptionsSelector,
} from 'src/app/filing-cabinet/store/filing-cabinet.store';
import {
  SfMessage,
  SfMessageEvent,
} from 'src/app/clients/sf/models/sf-message.model';
import { DictionarySignalPipe } from '../../../../../dictionary/pipes/dictionary-signal.pipe';
import { NetworkAutocompleteComponent } from '../../../../../networks/shared/network-autocomplete/network-autocomplete.component';
import {
  NetworkSearchActions,
  networkSearchFilterSelector,
} from '../../../../../networks/store/network-search.store';
import {
  NetworkPermanentActions,
  networkPermanentListAsOptions,
} from 'src/app/networks/store/network-permanent.store';

@Component({
  selector: 'app-reminders-form-a11y',
  templateUrl: './reminders-form-a11y.component.html',
  styleUrls: ['./reminders-form-a11y.component.scss'],
  animations: [
    trigger('myInsertRemoveTrigger', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [animate('100ms', style({ opacity: 0 }))]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    DictionaryPipeModule,
    SdTimeA11yComponent,
    SdMainContentTemplateA11yModule,
    SdContentHeaderA11yModule,
    RemindersFormRecurrenceA11yComponent,
    SdInputA11yComponent,
    SdFlatButtonA11yModule,
    SdAutocompleteA11yModule,
    RemindersFormUserListA11yComponent,
    SdTextAreaA11yComponent,
    SdDatePickerA11yModule,
    SdSlideToggleA11yModule,
    SdTooltipButtonA11yComponent,
    SdSelectorA11Module,
    SdProgressSpinnerA11yModule,
    DictionarySignalPipe,
    NetworkAutocompleteComponent,
  ],
  providers: [RemindersFormStore],
})
export class RemindersFormA11yComponent implements OnInit, OnDestroy {
  options$: Observable<Option[]>;
  filteredOptions$: Observable<Option[]>;
  vm$: Observable<{ loading: boolean }>;
  @Output() saveEmitter = new EventEmitter<boolean>();
  resource: string;
  options: Option[] = [];
  form: UntypedFormGroup;
  minDate: Date;
  startDateController = new UntypedFormControl(null, [
    Validators.required,
    ReminderHelper.dateValidatorReminder,
  ]);
  timeController = new UntypedFormControl(null);

  endDateController = new UntypedFormControl(null, [
    ReminderHelper.dateValidatorReminder,
  ]);
  recurrenceController = new UntypedFormControl(
    Frequency.DoesNotRepeat,
    Validators.required
  );
  protected messageController = new UntypedFormControl(null, [
    Validators.required,
    SdValidators.emptyCharacters.bind(this),
  ]);
  nameController = new UntypedFormControl(null, [
    Validators.required,
    SdValidators.emptyCharacters.bind(this),
  ]);

  sideDrawerController = new UntypedFormControl(null);
  sideDrawerNameController = new UntypedFormControl(null);
  sideDrawerOptions$ = combineLatest([
    this.store.select(
      filingCabinetSideDrawersWithDataForSdOwnerAndSdEditorAsOptionsSelector
    ),
    this.store.select(activeSideDrawerSelector),
  ]).pipe(
    map(([options, activeSd]) =>
      options?.length > 0
        ? options
        : [new Option(activeSd.id, activeSd.name, activeSd.profilePhoto)]
    )
  );

  everyController = new UntypedFormControl(null, [
    Validators.min(1),
    Validators.max(999),
    Validators.maxLength(3),
    Validators.minLength(1),
  ]);
  emailController = new UntypedFormControl(null);
  patternController = new UntypedFormControl(null);
  monthsController = new UntypedFormControl(null);
  contactsSelected: Option[] = [];
  localeDefault: Locale;
  currentRecurrenceType: string;
  protected reminder: Reminders = new Reminders();
  subscription = new Subscription();
  cdn = environment.cdn;
  errorMessage: string;
  recurrenceOptions: Option[] = [];
  monthSelectionOption: Option;
  minDateForEndDate: Date;
  reminderRecurrence: Reminders = new Reminders();
  sentence: string;
  months: Option[] = [];
  queryParamName: string;
  queryParamRecordId: string;
  queryParamType: string;
  activeRecordName: string;
  reminderController = new UntypedFormControl(null);
  activeReminder = true;
  showErrorForInvalidEmailController: boolean;
  errors = new Map<string | ErrorType, SfrError>();
  reminderShortDictionary: ReminderShortDictionary =
    new ReminderShortDictionary();
  dateTimeRangeFormatted: DateTimeRangeFormatted = {
    endDateFormatted: '',
    startDateFormatted: '',
    startDateFormattedLocal: '',
    endDateFormattedLocal: '',
  };
  activeRecord: Record;
  fromRoute: ReminderFrom;
  reminderId: string;
  ReminderFrom = ReminderFrom;
  submitted = false;
  isDialog: boolean;
  validationErrors: SdValidationFormErrors;
  sdInputA11yTemplates = SdInputA11yTemplates;
  sdSlideToggleTemplateA11y = SdSlideToggleTemplateA11y;
  @Input() showHeader = true;
  @Input() showFooter = true;

  reminderFromDialog: Reminders;
  showStartTime = signal(false);

  @Input() set setReminderFromDialog(value: Reminders) {
    this.reminderFromDialog = value;
  }

  activeSidedrawer$: Observable<SideDrawer> = this.store.select(
    activeSideDrawerSelector
  );
  activeRecord$: Observable<Record> = this.store.select(activeRecordSelector);
  disabled = false;
  @Output() isProcessComplete = new EventEmitter<SfMessage>(null);

  @Input() set cancelRedirect(value: boolean) {
    this.remindersFormStore.patchState({
      cancelRedirect: value,
    });
  }

  constructor(
    protected store: Store<AppState>,
    private router: Router,
    public readonly remindersFormStore: RemindersFormStore,
    private readonly route: ActivatedRoute,
    public readonly liveAnnouncer: LiveAnnouncer,
    protected changeDetector: ChangeDetectorRef
  ) {}

  @Input() set setIsDialog(value: boolean) {
    this.isDialog = value;
    asapScheduler.schedule(() =>
      this.remindersFormStore.patchState({ isDialog: this.isDialog })
    );
  }

  @Input() set setDisabled(value: boolean) {
    this.disabled = value;
  }

  @Input() cancelAddCollaborator = false;
  sdAutoCompleteA11yEnum = SdAutoCompleteA11yEnum;
  filingCabinet = this.store.selectSignal(filingCabinetSelector);

  ngOnInit(): void {
    this.store.dispatch(NetworkSearchActions.clear());
    this.store.dispatch(
      NetworkPermanentActions.fillingCabinetAllNetworksRequestedForReminders()
    );

    const initReminders$ = combineLatest([
      this.store.select(sideDrawerSpinnerSelector),
    ]).pipe(
      tap(([gettingActiveSideDrawer]) => {
        if (gettingActiveSideDrawer) {
          return;
        }
        this.store.dispatch(
          NetworkPermanentActions.allNetworksRequestedForReminders()
        );
      })
    );
    this.subscription.add(initReminders$.subscribe());

    this.setActiveReminderComponent();
    this.setFormGroup();

    this.subscription.add(
      combineLatest([
        this.remindersFormStore.finished$,
        this.remindersFormStore.reminderResponse$,
        this.store.pipe(select(remindersErrorsSelector)),
      ])
        .pipe(
          tap(([finished, reminder, errors]) => {
            if (finished && reminder) {
              const sfMessage: SfMessage = {
                event: SfMessageEvent.reminder,
                data: {
                  reminderId: reminder.id,
                  recordId: this.activeRecord?.id,
                  sideDrawerId: this.sideDrawerController.value,
                },
                payload: reminder,
                error: null,
              };
              this.isProcessComplete.emit(sfMessage);
              return;
            }

            if (errors?.size > 0) {
              const sfMessage: SfMessage = {
                event: SfMessageEvent.createReminderRecordRequest,
                data: {
                  reminderId: null,
                  recordId: this.activeRecord?.id,
                  sideDrawerId: this.sideDrawerController.value,
                },
                payload: null,
                error: {
                  message: SfMessageEvent.errorReminder,
                  errors: errors,
                },
              };
              this.isProcessComplete.emit(sfMessage);
              return;
            }
          })
        )
        .subscribe()
    );

    this.setQueryParamsFromSimpleFileRequestRedirect();
    ReminderHelper.setFromDictionaryForReminder(
      this.store,
      this.reminderShortDictionary,
      this.recurrenceOptions
    );
    asapScheduler.schedule(() =>
      this.remindersFormStore.patchState({
        reminderShortDictionary: this.reminderShortDictionary,
      })
    );
    this.setStarDateAndMinValue();
    this.vm$ = combineLatest([
      this.store.select(remindersLoadingSelector),
      this.store.select(filingCabinetLoadingSelector),
      this.store.select(filingCabinetSideDrawersLoadingSelector),
      this.store.select(activeSideDrawerSelector),
      this.store.select(filingCabinetSelector),
    ]).pipe(
      distinctUntilChanged(),
      map(
        ([
          gettingReminders,
          gettingFilingCabinetInformation,
          gettingFilingCabinetHomeInformation,
          activeSD,
          filingCabinet,
        ]) => {
          if (!activeSD) {
            return { loading: true };
          }
          if (!filingCabinet?.id) {
            return {
              loading: gettingReminders || gettingFilingCabinetInformation,
            };
          }
          return {
            loading:
              gettingReminders ||
              gettingFilingCabinetInformation ||
              gettingFilingCabinetHomeInformation,
          };
        }
      ),
      tap(({ loading }) => {
        if (loading) {
          return;
        }
        this.getParamReminderIdAndSetThis(
          this.reminderFromDialog,
          (reminder: Reminders) => {
            this.setCollaborators();
          }
        );
      })
    );
    this.setResourceApi();
    this.setLocaleDefault();
    this.valueChangesForRecurrenceController();
    this.emailControllerOnChanges();
    this.timeControllerOnChanges();

    const activeSidedrawerId = this.store.selectSignal(
      activeSideDrawerSelector
    )()?.id;
    this.sideDrawerController.setValue(activeSidedrawerId);
  }

  sideDrawerControllerOnChanges() {
    this.subscription.add(
      this.form.get('sideDrawerController').valueChanges.subscribe(() => {
        if (!this.reminder?.resource) {
          const resource = `sidedrawer/${this.sideDrawerController.value}`;
          asapScheduler.schedule(() =>
            this.remindersFormStore.patchState({
              resource: resource,
            })
          );
          this.setCollaborators(this.sideDrawerController.value);
        }

        if (this.reminder?.resource) {
          const resourceRegexp = /^sidedrawer\/([^/]+)(?:\/record\/([^/]+))?$/;
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const [, sidedrawerId, recordId] = resourceRegexp.exec(
            this.reminder.resource
          ) || [null, null, null];
          let resource = '';
          if (recordId) {
            resource = `sidedrawer/${this.sideDrawerController.value}/record/${recordId}`;
          }

          if (!recordId) {
            resource = `sidedrawer/${this.sideDrawerController.value}`;
          }

          asapScheduler.schedule(() =>
            this.remindersFormStore.patchState({
              resource: resource,
            })
          );
        }
      })
    );
  }

  protected setSideDrawerFromPathResource(): void {
    const sideDrawerIdFromReminder = this.reminder.resource.split('/')[1];
    this.sideDrawerOptions$
      .pipe(
        take(1),
        tap(options => {
          const sideDrawer = options.find(
            o => o.key === sideDrawerIdFromReminder
          );
          this.sideDrawerController.setValue(sideDrawer?.key);
          this.sideDrawerNameController.setValue(sideDrawer?.value);
        })
      )
      .subscribe();
  }

  onSave(): void {
    this.form.markAllAsTouched();

    this.errorMessage = null;
    this.submitted = true;
    this.validationErrors = ReminderHelper.checkFormStateForReminder(
      this.form,
      this.contactsSelected
    );

    if (this.invalidForm()) {
      AccessibilityHelper.announceMessage(
        this.store,
        this.validationErrors,
        this.liveAnnouncer,
        '' // TODO Dictionary add global params for form errors and indicate user focus
      );
      return;
    }
    if (this.disabled) {
      return;
    }

    if (this.showStartTime() === false) {
      this.setStarDateAndMinValue();
      this.setTimeControllerValue();

      ReminderHelper.formatDateAndTime(
        this.timeController,
        this.startDateController,
        this.recurrenceController,
        this.endDateController,
        this.dateTimeRangeFormatted
      );
      asapScheduler.schedule(() =>
        this.remindersFormStore.patchState({
          dateTimeRangeFormatted: this.dateTimeRangeFormatted,
        })
      );
    }

    this.remindersFormStore.addOrUpdateReminder(errors => {
      if (!errors) {
        this.saveEmitter.emit(true);
      }
    });
  }

  fillControls(): void {
    this.showStartTime.set(true);
    this.startDateController.setValue(this.reminder.schedule.startDate);
    this.timeController.setValue(
      moment(this.reminder.schedule.startDate).format('hh:mm A')
    );
    this.setValidatorsTimeController();

    this.nameController.setValue(this.reminder.name);
    this.recurrenceController.setValue(
      this.reminder?.schedule?.recurrent?.frequency ?? Frequency.DoesNotRepeat
    );
    this.messageController.setValue(this.reminder.message);
    this.endDateController.setValue(this.reminder.schedule.recurrent.endDate);
    this.everyController.setValue(this.reminder.schedule.recurrent.iteration);
    this.patternController.setValue(this.reminder.schedule.recurrent.pattern);
    this.setSideDrawerFromPathResource();
  }

  AddUser(): void {
    if (!ReminderHelper.emailValidator(this.emailController.value)) {
      this.showErrorForInvalidEmailController = true;
      return;
    }
    if (this.contactsSelected.length === 20) {
      return;
    }
    const email =
      typeof this.emailController.value === 'object'
        ? this.emailController.value.value.trim()
        : this.emailController.value.trim();

    if (this.contactsSelected.filter(e => e.value === email).length > 0) {
      return;
    }

    const current = this.options.find(o => o.value === email);
    current
      ? (this.contactsSelected = [...this.contactsSelected, current])
      : (this.contactsSelected = [
          ...this.contactsSelected,
          new Option(email, email),
        ]);
    this.onFilteredOptionsChange(); // TODO see this...
    asapScheduler.schedule(() =>
      this.remindersFormStore.patchState({
        contactsSelected: this.contactsSelected,
      })
    );
    this.showErrorForInvalidEmailController = false;
    this.emailController = new UntypedFormControl(null);
  }

  onContactsChange(event: Option[]): void {
    this.contactsSelected = event;
    asapScheduler.schedule(() =>
      this.remindersFormStore.patchState({
        contactsSelected: this.contactsSelected,
      })
    );
    this.onFilteredOptionsChange(); // TODO see this...
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.store.dispatch(NetworkSearchActions.clear());
  }

  onMonthSelectionOption(monthSelectionOption): void {
    this.monthSelectionOption = monthSelectionOption;
    asapScheduler.schedule(() =>
      this.remindersFormStore.patchState({
        monthSelectionOption: this.monthSelectionOption,
      })
    );
  }

  onChangeRecurrence(): void {
    this.reminderRecurrence = null;
    this.reminderRecurrence = {
      schedule: {
        recurrent: {
          endDate:
            this.endDateController.value !== Frequency.DoesNotRepeat &&
            this.endDateController.value !== null
              ? this.dateTimeRangeFormatted.endDateFormattedLocal
              : null,
          frequency:
            this.recurrenceController.value !== Frequency.DoesNotRepeat
              ? this.recurrenceController.value
              : null,
          iteration: this.everyController.value
            ? Number(this.everyController.value)
            : 1,
          pattern: this.remindersFormStore.getPattern(false),
        },
        startDate: this.dateTimeRangeFormatted.startDateFormattedLocal,
      },
    };
    asapScheduler.schedule(() =>
      this.remindersFormStore.patchState({
        reminder: {
          ...this.reminder,
          schedule: this.reminderRecurrence.schedule,
        },
      })
    );
  }

  getReminderSentence(): string {
    if (this.showStartTime() === false) {
      this.setStarDateAndMinValue();
      this.setTimeControllerValue();
    }

    ReminderHelper.formatDateAndTime(
      this.timeController,
      this.startDateController,
      this.recurrenceController,
      this.endDateController,
      this.dateTimeRangeFormatted
    );
    asapScheduler.schedule(() =>
      this.remindersFormStore.patchState({
        dateTimeRangeFormatted: this.dateTimeRangeFormatted,
      })
    );
    this.onChangeRecurrence();
    this.sentence = ReminderHelper.getSentenceFromReminderItem(
      this.reminderRecurrence,
      this.reminderShortDictionary
    );
    return this.sentence;
  }

  onChangeRecurrenceValue(event): void {
    this.reminderController.setValue(
      event.checked
        ? (this.activeReminder = true)
        : (this.activeReminder = false)
    );
    asapScheduler.schedule(() =>
      this.remindersFormStore.patchState({
        activeReminder: this.activeReminder,
      })
    );
    if (this.activeReminder === false) {
      this.patternController.clearValidators();
      this.patternController.updateValueAndValidity();
      this.monthsController.clearValidators();
      this.monthsController.updateValueAndValidity();
      this.endDateController.clearValidators();
      this.endDateController.updateValueAndValidity();

      this.startDateController.clearValidators();
      this.startDateController.addValidators([Validators.required]);
      this.startDateController.updateValueAndValidity();
    }
    if (this.activeReminder === true) {
      ReminderHelper.verifyValidatorsAccordingToRecurrence(
        this.recurrenceController.value,
        this.patternController,
        this.monthsController,
        this.endDateController
      );
      this.startDateController.clearValidators();
      this.startDateController.addValidators([
        Validators.required,
        ReminderHelper.dateValidator,
      ]);
      this.startDateController.updateValueAndValidity();
    }
  }

  invalidForm(): boolean {
    return !this.form.valid || this.contactsSelected.length === 0;
  }

  protected contactsAssigned = false;

  protected setCollaborators(filterSideDrawerId?: string): void {
    const sideDrawerId =
      filterSideDrawerId ??
      this.store.selectSignal(activeSideDrawerSelector)()?.id;

    this.options$ = this.store.select(
      networkPermanentListAsOptions({
        sideDrawerId,
        asUsers: true,
        excludeTeams: true,
      })
    );

    this.subscription.add(
      this.options$
        .pipe(
          map(options => {
            const filtered = options.filter(
              option =>
                !this.contactsSelected?.some(
                  contactSelected => contactSelected.key === option.key
                ) &&
                !this.options.some(
                  optionInList => optionInList.key === option.key
                )
            );
            return filtered;
          }),
          map(options => {
            this.handleOptions(options);
          })
        )
        .subscribe()
    );
  }

  protected onFilteredOptionsChange(): void {
    this.filteredOptions$ = this.options$.pipe(
      map(options => {
        const filtered = options.filter(
          option => !this.contactsSelected?.some(c => c.key === option.key)
        );
        return filtered;
      })
    );
  }

  private handleOptions(options: Option[]): void {
    this.options = [...this.options, ...options];

    const fromSearch = this.store.selectSignal(networkSearchFilterSelector)();

    if (this.reminder?.id && !fromSearch) {
      this.contactsAssigned = true;
      this.assignContacts();
      this.setContactsToStore();
    }
    this.onFilteredOptionsChange();
  }

  private assignContacts(): void {
    const selectedContacts = this.options.filter(o =>
      this.reminder.to.openIds.includes(o.key)
    );
    const onlyStrEmails = this.reminder.to.emails.map(c => new Option(c, c));
    this.contactsSelected = [...selectedContacts, ...onlyStrEmails];
  }

  private setContactsToStore(): void {
    asapScheduler.schedule(() =>
      this.remindersFormStore.patchState({
        contactsSelected: this.contactsSelected,
      })
    );
  }

  private emailControllerOnChanges(): void {
    this.subscription.add(
      this.form.get('emailController').valueChanges.subscribe(() => {
        if (
          typeof this.emailController.value !== 'object' &&
          this.emailController.value.trim().length === 0
        ) {
          this.showErrorForInvalidEmailController = false;
        }
        if (
          typeof this.emailController.value === 'object' &&
          this.emailController.value?.value.trim().length === 0
        ) {
          this.showErrorForInvalidEmailController = false;
        }
      })
    );
  }

  private setActiveReminderComponent(): void {
    this.reminderController.setValue(true);
  }

  private setQueryParamsFromSimpleFileRequestRedirect(): void {
    this.route.queryParams
      .pipe(
        take(1),
        tap(params => {
          if (!!params && !!params?.recordId) {
            this.queryParamName = params.name;
            this.queryParamRecordId = params.recordId;
            this.queryParamType = params.type;
            this.fromRoute = ReminderFrom.fromSFR;
          }
        })
      )
      .subscribe();
  }

  private getParamReminderIdAndSetThis(
    reminderFromDialog?: Reminders,
    callBack?: (reminder: Reminders) => void
  ): void {
    if (reminderFromDialog) {
      asapScheduler.schedule(() =>
        this.store.dispatch(new ReminderSpinnerChange({ state: false }))
      );
      this.reminder = new Reminders(
        reminderFromDialog.name,
        reminderFromDialog.resource,
        reminderFromDialog.message,
        reminderFromDialog.schedule,
        reminderFromDialog.to,
        reminderFromDialog.id,
        reminderFromDialog.status
      );
      asapScheduler.schedule(() =>
        this.remindersFormStore.patchState({
          reminder: this.reminder,
        })
      );
      this.fillControls();

      if (callBack) {
        callBack(this.reminder);
      }

      return;
    }
    this.route.params
      .pipe(
        take(1),
        tap(params => {
          if (
            !!params.reminderId &&
            params.reminderId.length > 0 &&
            !reminderFromDialog
          ) {
            this.reminderId = params.reminderId;
            this.remindersFormStore.getReminderById({
              reminderId: this.reminderId,
              callback: (reminder: Reminders) => {
                if (!reminder) {
                  asapScheduler.schedule(() =>
                    this.store.dispatch(
                      new ReminderSpinnerChange({ state: false })
                    )
                  );
                  const basePath = this.store.selectSignal(basePathSelector);
                  this.router.navigate([basePath]);
                  return;
                }
                this.reminder = new Reminders(
                  reminder.name,
                  reminder.resource,
                  reminder.message,
                  reminder.schedule,
                  reminder.to,
                  reminder.id,
                  reminder.status
                );
                this.fillControls();

                if (callBack) {
                  callBack(this.reminder);
                }
              },
            });
          } else {
            this.timeController.setValue(
              moment().add({ minute: 5 }).format('hh:mm A')
            );
            asapScheduler.schedule(() =>
              this.store.dispatch(new ReminderSpinnerChange({ state: false }))
            );

            if (callBack) {
              callBack(this.reminder);
            }
          }
        })
      )
      .subscribe();
  }

  private valueChangesForRecurrenceController(): void {
    this.subscription.add(
      this.form.get('recurrenceController').valueChanges.subscribe(c => {
        this.currentRecurrenceType = c;
        this.patternController.setValue(null);
        this.everyController.setValue(null);
        this.monthsController.setValue(null);
        ReminderHelper.verifyValidatorsAccordingToRecurrence(
          c,
          this.patternController,
          this.monthsController,
          this.endDateController
        );
      })
    );
  }

  private setLocaleDefault(): void {
    this.store
      .pipe(
        select(localeDefaultSelector),
        take(1),
        tap(locale => {
          this.localeDefault = locale;

          this.remindersFormStore.patchState({
            localeDefault: this.localeDefault,
          });

          this.months = DateHelper.getMonths(this.localeDefault).map(
            (m, i) => new Option(i + 1 <= 9 ? `0${i + 1}` : `${i + 1}`, m)
          );
          asapScheduler.schedule(() =>
            this.remindersFormStore.patchState({
              months: this.months,
            })
          );
        })
      )
      .subscribe();
  }

  private setFormGroup(): void {
    this.form = new UntypedFormGroup({
      timeController: this.timeController,
      startDateController: this.startDateController,
      endDateController: this.endDateController,
      recurrenceController: this.recurrenceController,
      everyController: this.everyController,
      emailController: this.emailController,
      nameController: this.nameController,
      messageController: this.messageController,
      patternController: this.patternController,
      monthsController: this.monthsController,
      sideDrawerController: this.sideDrawerController,
      sideDrawerNameController: this.sideDrawerNameController,
    });
    asapScheduler.schedule(() =>
      this.remindersFormStore.patchState({
        form: this.form,
      })
    );
  }

  private setStarDateAndMinValue(): void {
    const date = new Date();
    date.setUTCDate(date.getUTCDate());
    this.minDate = date;
    this.startDateController.setValue(date);
  }

  private setResourceApi(): void {
    const tree: UrlTree = this.router.parseUrl(this.router.url);
    const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
    const s: UrlSegment[] = g.segments;
    if (s.filter(s => s.path === 'records' || s.path === 'record').length) {
      this.subscription.add(
        combineLatest([
          this.store.pipe(select(activeSideDrawerSelector)),
          this.store.pipe(select(activeRecordSelector)),
        ])
          .pipe()
          .subscribe(([activeSD, activeRecord]) => {
            if (activeSD && activeRecord) {
              document
                .querySelector('.records-main-template-content')
                ?.scroll(0, 0);
              this.resource = `sidedrawer/${activeSD.id}/record/${activeRecord.id}`;
              asapScheduler.schedule(() =>
                this.remindersFormStore.patchState({
                  resource: this.resource,
                })
              );
              this.activeRecordName = activeRecord.name;
              this.activeRecord = activeRecord;
              this.fromRoute = ReminderFrom.fromRecord;
              return;
            }
            if (activeSD && !activeRecord && !!this.queryParamRecordId) {
              document
                .querySelector('.records-main-template-content')
                ?.scroll(0, 0);
              this.resource = `sidedrawer/${activeSD.id}/record/${this.queryParamRecordId}`;
              asapScheduler.schedule(() =>
                this.remindersFormStore.patchState({
                  resource: this.resource,
                })
              );
              this.activeRecordName = this.queryParamName;
              return;
            }

            // TODO for integrations
            const { params } = this.route.snapshot;
            if (params?.recordId && params?.sideDrawerId) {
              this.resource = `sidedrawer/${params?.sideDrawerId}/record/${params?.recordId}`;
              asapScheduler.schedule(() =>
                this.remindersFormStore.patchState({
                  resource: this.resource,
                })
              );
              return;
            }
          })
      );
    } else {
      this.subscription.add(
        this.store
          .pipe(
            select(activeSideDrawerSelector),
            tap(sd => {
              if (this.sideDrawerNameController.value) {
                this.resource = `sidedrawer/${this.sideDrawerController.value}`;
              }
              if (!this.sideDrawerNameController.value) {
                this.resource = `sidedrawer/${sd.id}`;
              }

              asapScheduler.schedule(() =>
                this.remindersFormStore.patchState({
                  resource: this.resource,
                })
              );
              document.querySelector('.home-views-content')?.scroll(0, 0);
              this.activeRecordName = sd.name;
            })
          )
          .subscribe()
      );
      this.fromRoute = ReminderFrom.fromSideDrawer;
      this.sideDrawerControllerOnChanges(); // TODO see this...
    }
  }

  private timeControllerOnChanges(): void {
    this.subscription.add(
      this.form.get('timeController').valueChanges.subscribe(() => {
        if (this.form.get('timeController').valid) {
          ReminderHelper.formatDateAndTime(
            this.timeController,
            this.startDateController,
            this.recurrenceController,
            this.endDateController,
            this.dateTimeRangeFormatted
          );
        }
      })
    );
  }

  onChangeStartTime(event): void {
    if (event.checked === true) {
      this.showStartTime.set(false);
      this.setStarDateAndMinValue();
      this.setTimeControllerValue();
      this.timeController.clearValidators();
      this.endDateController.updateValueAndValidity();
      return;
    }

    if (event.checked === false) {
      this.setValidatorsTimeController();
      this.setTimeControllerValue();
      this.showStartTime.set(true);
    }
  }

  private setTimeControllerValue(): void {
    this.timeController.setValue(moment().add({ minute: 5 }).format('hh:mm A'));
  }

  protected setValidatorsTimeController(): void {
    this.timeController.addValidators([
      Validators.required,
      ReminderHelper.todayAfterSomeMinutes,
    ]);
    this.timeController.updateValueAndValidity();
  }
}
