import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { environment } from '../../../../../../environments/environment';
import { currentBrandingSelector } from '../../../../../core/store/core.selectors';
import { AppState } from '../../../../../reducers';
import { ZipStatus } from '../../../../models/zip-status.model';
import { ZipReset } from '../../../../store/zip.actions';
import {
  zipProgressSelector,
  zipStatusSelector,
} from '../../../../store/zip.selectors';
import { ZipDialogComponent } from '../../../zip-dialog/components/zip-dialog/zip-dialog.component';
import { ZipHeaderButtonTemplateA11y } from '../../model/enum/zip-header-button-a11y-template.enum';
import { MatDialog } from '@angular/material/dialog';
import { SideDrawer } from '../../../../../sidedrawer/models/side-drawer.model';

@Component({
  selector: 'app-zip-header-button-a11y',
  templateUrl: './zip-header-button-a11y.component.html',
  styleUrls: ['./zip-header-button-a11y.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZipHeaderButtonA11yComponent {
  cdn = environment.cdn;
  zipStatus = ZipStatus;
  zipHeaderButtonTemplate = ZipHeaderButtonTemplateA11y;
  @Input() template: ZipHeaderButtonTemplateA11y =
    this.zipHeaderButtonTemplate.default;
  @Input() sideDrawer: SideDrawer;

  startDialog: { download: boolean };
  progress$ = this.store.pipe(select(zipProgressSelector));
  status$ = this.store.pipe(select(zipStatusSelector));
  primaryColor$ = this.store.pipe(
    select(currentBrandingSelector),
    map(branding => branding?.styleSheet?.primaryColor)
  );

  constructor(
    private readonly store: Store<AppState>,
    private readonly dialog: MatDialog
  ) {}

  @Input() set setStartDialog(value: { download: boolean }) {
    this.startDialog = value;
    if (this.startDialog?.download) {
      this.onClick();
    }
  }

  onClick(): void {
    this.dialog
      .open(ZipDialogComponent, {
        autoFocus: false,
        data: { sideDrawer: this.sideDrawer },
      })
      .afterClosed()
      .pipe(
        switchMap(() => this.store.pipe(select(zipStatusSelector), take(1))),
        tap(status => {
          switch (status) {
            case ZipStatus.canceled:
            case ZipStatus.completeWithErrors:
            case ZipStatus.complete:
            case ZipStatus.fails:
            case ZipStatus.noFiles:
              this.store.dispatch(new ZipReset());
              break;
            default:
              break;
          }
        })
      )
      .subscribe();
  }
}
