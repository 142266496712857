<ng-template [ngIf]="template === sdInputA11yTemplates.inputDefault">
  <mat-form-field [hideRequiredMarker]="true">
    <mat-label>{{ placeholder }}</mat-label>
    <textarea
      [attr.aria-readonly]="readonly"
      #autosize="cdkTextareaAutosize"
      (keydown)="onKeyPress($event)"
      [attr.aria-label]="sdAccessibility?.ariaLabel"
      [formControl]="controller"
      [id]="sdAccessibility?.id"
      [maxlength]="maxLength"
      [readonly]="readonly"
      cdkAutosizeMaxRows="50"
      cdkAutosizeMinRows="1"
      cdkTextareaAutosize
      matInput></textarea>
    <mat-error *ngIf="controller.invalid">
      <span>{{ error }}</span>
    </mat-error>
  </mat-form-field>
</ng-template>
<ng-template [ngIf]="template === sdInputA11yTemplates.inputWithSuffix">
  <mat-form-field [hideRequiredMarker]="true">
    <mat-label>{{ placeholder }}</mat-label>
    <textarea
      [attr.aria-readonly]="readonly"
      #autosize="cdkTextareaAutosize"
      (keydown)="onKeyPress($event)"
      [attr.aria-label]="sdAccessibility?.ariaLabel"
      [formControl]="controller"
      [id]="sdAccessibility?.id"
      [maxlength]="maxLength"
      [readonly]="readonly"
      cdkAutosizeMaxRows="50"
      cdkAutosizeMinRows="1"
      cdkTextareaAutosize
      matInput></textarea>
    <div class="suffix-container" matSuffix>
      <div *ngIf="!!suffixText && suffixText.length > 0">
        <span>{{ suffixText }}</span>
      </div>

      <app-sd-icon-button-a11y
        (btnClicked)="suffixClicked.emit(true)"
        *ngIf="!!suffixSvg && suffixSvg.length"
        [iconColor]="
          !!suffixSvgColor ? suffixSvgColor : sdColorPalette.primaryAccentColor
        "
        [iconHeight]="1.25"
        [iconWidth]="1.25"
        [icon]="suffixSvg"
        [setSdAccessibility]="
          sdAccessibilityForSuffix
        "></app-sd-icon-button-a11y>
    </div>
    <mat-error *ngIf="controller.invalid">
      <span>{{ error }}</span>
    </mat-error>
  </mat-form-field>
</ng-template>
<ng-template [ngIf]="template === sdInputA11yTemplates.inputForView">
  <mat-label>{{ placeholder }}</mat-label>
  <textarea
    [attr.aria-readonly]="readonly"
    #autosize="cdkTextareaAutosize"
    [attr.aria-label]="sdAccessibility?.ariaLabel"
    [formControl]="controller"
    [id]="sdAccessibility?.id"
    [maxlength]="maxLength"
    [readonly]="true"
    cdkAutosizeMaxRows="50"
    cdkAutosizeMinRows="1"
    cdkTextareaAutosize
    class="inputForView"></textarea>
</ng-template>
