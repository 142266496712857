import { inject, Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import {
  dictionaryRoleByRoleIdSelector,
  dictionarySelector,
} from '../../dictionary/store/dictionary.selectors';
import { NetworkEntity } from '../../networks/models/network-entity.enum';
import { NetworkV2 } from '../../networks/models/network.v2.type';
import { networkListSelector } from '../../networks/store/network-list.store';
import { ContributorType } from '../../networks/models/contributor-type.model';

@Pipe({
  name: 'getRoleFromNetworkSignal',
  standalone: true,
})
export class GetRoleFromNetworkSignalPipe implements PipeTransform {
  private readonly store = inject(Store<AppState>);

  transform(network: NetworkV2, networkEntity: NetworkEntity): string {
    if (networkEntity === NetworkEntity.multiple) {
      const networks =
        network.contributorType === ContributorType.team
          ? this.store.selectSignal(
              networkListSelector({ teamId: network.teamNetwork?.teamId })
            )()
          : this.store.selectSignal(
              networkListSelector({
                email: network.contributor?.email,
              })
            )();
      if (networks.length > 1) {
        return (
          this.store.selectSignal(dictionarySelector)()
            ?.globalparams_multisdrolelabel ?? 'globalparams_multisdrolelabel'
        );
      }
      return this.store.selectSignal(
        dictionaryRoleByRoleIdSelector({
          roleId: network.sidedrawerRole ?? network.recordRole,
        })
      )()?.roleLongName;
    }
    return this.store.selectSignal(
      dictionaryRoleByRoleIdSelector({
        roleId:
          networkEntity === NetworkEntity.records
            ? network.recordRole
            : network.sidedrawerRole,
      })
    )()?.roleLongName;
  }
}
