<div>
  <div class="records-upload-buttons-section-snackbar">
    <div class="records-upload-buttons-section-snackbar-image">
      <app-sd-svg-a11y
        [color]="data.color"
        [height]="1.2"
        [src]="cdn + ('globalparams_clouduploadicon' | dictionary | async)"
        [width]="1.6"></app-sd-svg-a11y>
    </div>

    <div class="records-upload-buttons-section-snackbar-text">
      {{ data.title ?? ('globalparams_draganddrop' | dictionary | async) }}
    </div>
  </div>
</div>
