import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AccountState, featureName } from './account.reducer';
import { dataBaseRegionsSelector } from '../../dictionary/store/dictionary.selectors';
import { DataBaseRegion } from '../../dictionary/models/data-base-region.models';

export const accountStateSelector =
  createFeatureSelector<AccountState>(featureName);

export const accountSelector = createSelector(
  accountStateSelector,
  state => state?.account ?? null
);

export const settingsSelector = createSelector(accountStateSelector, state =>
  state.settings ? state.settings : null
);

export const teamsSelector = createSelector(accountStateSelector, state =>
  state.teams ? state.teams : null
);

export const accountOpenIdSelector = createSelector(
  accountSelector,
  account => account.openId ?? null
);

export const accountCustomerIdSelector = createSelector(
  accountSelector,
  account => account.customerId ?? null
);

export const userCurrencySelector = createSelector(
  settingsSelector,
  settings => settings.currency
);

export const creatingAccountSelector = createSelector(
  accountStateSelector,
  state => state.creatingAccount
);

export const updatingAccountSettingsSelector = createSelector(
  accountStateSelector,
  state => state.updatingSettings
);

export const accountDataBaseRegionSelector = createSelector(
  accountSelector,
  account => account?.region
);

export const accountDataBaseRegionAsDataBaseRegionSelector = createSelector(
  accountDataBaseRegionSelector,
  dataBaseRegionsSelector,
  (accountDataBaseRegion: string, dataBaseRegions: DataBaseRegion[]) =>
    dataBaseRegions?.find(
      region =>
        region.countrycode?.length > 0 &&
        region.databaseregion === accountDataBaseRegion
    )
);

export const notAccountDataBaseRegionSelector = createSelector(
  accountDataBaseRegionSelector,
  dataBaseRegionsSelector,
  (accountDataBaseRegion: string, dataBaseRegions: DataBaseRegion[]) =>
    dataBaseRegions?.filter(
      region =>
        region.countrycode?.length > 0 &&
        region.databaseregion !== accountDataBaseRegion
    )
);

export const accountBrandCodeAffiliationsSelector = createSelector(
  accountSelector,
  account => account?.brandCodeAffiliations ?? []
);
