import { createSelector } from '@ngrx/store';
import { recordsStateSelector } from './records.selectors';
import { selectAll } from './specific-fields.reducer';

export const specificFieldStateSelector = createSelector(
  recordsStateSelector,
  state => state.specificFields
);

export const specificFieldsListSelector = createSelector(
  specificFieldStateSelector,
  selectAll
);

export const gettingSpecificFieldsSelector = createSelector(
  specificFieldStateSelector,
  state => state.gettingSpecificFields
);
