import { Settings } from './settings.model';

export class Mfa {
  constructor(
    public type: string,
    public notificationType: string,
    public locale: string,
    public settings?: Settings
  ) {}
}
