import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DictionaryPipeModule } from '../../../dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { SdDialogTemplateA11yComponent } from './components/sd-dialog-template-a11y.component';
import { SdSvgA11yModule } from '../../sd-svg-a11y/sd-svg-a11y.module';
import { MatDialogModule } from '@angular/material/dialog';
import { SdProgressBarA11yComponent } from '../../sd-progress-bar-a11y/components/sd-progress-bar-a11y/sd-progress-bar-a11y.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {SdProgressSpinnerA11yModule} from '../../sd-progress-spinner-a11y/sd-progress-spinner-a11y/sd-progress-spinner-a11y.module';

@NgModule({
  declarations: [SdDialogTemplateA11yComponent],
    imports: [
        CommonModule,
        DictionaryPipeModule,
        SdProgressBarA11yComponent,
        MatProgressSpinnerModule,
        SdSvgA11yModule,
        MatDialogModule,
        SdProgressSpinnerA11yModule,
    ],
  exports: [SdDialogTemplateA11yComponent],
})
export class SdDialogTemplateA11yModule {}
