<ng-container
  *ngIf="sdLinkButtonA11yTemplates.linkFocusable === template"
  [ngTemplateOutlet]="">
  <a
    [attr.ariaLabel]="ariaLabel"
    [queryParamsHandling]="'preserve'"
    [routerLink]="href"
    [tabindex]="tabIndex">
    <ng-content select="[linkFocusable]"></ng-content>
  </a>
</ng-container>

<ng-container *ngIf="sdLinkButtonA11yTemplates.skipButton === template">
  <a
    [attr.ariaLabel]="ariaLabel"
    [tabindex]="tabIndex"
    class="skip-to-content-link">
    <ng-content select="[skipButton]"></ng-content>
  </a>
</ng-container>

<ng-container *ngIf="sdLinkButtonA11yTemplates.linkNative === template">
  <a [href]="href" [target]="target ?? '_self'">
    <ng-content select="[linkNative]"></ng-content>
  </a>
</ng-container>

<ng-container
  *ngIf="sdLinkButtonA11yTemplates.linkWithRouterOutlet === template">
  <a [queryParamsHandling]="'preserve'" [routerLink]="href">
    <ng-content select="[linkWithRouterOutlet]"></ng-content>
  </a>
</ng-container>
