<div *ngIf="vm$ | async as vm" class="record-form-files-tab">
  <app-sd-progress-bar-a11y [setIsLoading]="vm.gettingInformation" />

  <div class="record-form-files-tab-buttons-section">
    <div
      (fileDropped)="onFilesLoaded($event)"
      [id]="idForDragAndDrop"
      appDragAndDrop
      class="record-form-files-tab-buttons-section-main-button">
      <div
        [id]="idForDragAndDrop"
        class="record-form-files-tab-buttons-section-main-button-overlay hidden">
        <div
          class="record-form-files-tab-buttons-section-main-button-overlay-body"></div>
      </div>
      <app-sd-upload-button-a11y
        (filesSelected)="onFilesLoaded($event)"
        [buttonText]="'filehistorysection_anyfileupload' | dictionary | async"
        [icon]="
          cdn + ('filehistorysection_anyfileuploadicon' | dictionary | async)
        " />
    </div>

    <app-integrations-section-item
      (clicked)="onDriveClicked(provider)"
      *ngFor="let provider of vm.providers"
      [iconDisabled]="cdn + 'svgs/' + provider + '-off.png'"
      [iconEnabled]="cdn + 'svgs/' + provider + '-on.png'"
      [ngClass]="{ hidden: !vm.isTenantUser }"
      [text]="provider | dictionary | async | titlecase" />
  </div>
  <div class="app-file-history-section">
    <app-file-history-section
      [activeRecordId]="activeRecord()?.id ?? paramRecordId"
      [idForDragAndDrop]="idForDragAndDrop + 'file-history-section'"
      [forceDisableDragAndDrop]="true"
      [showActionButtons]="false"
      [viewOnly]="false"
      [showAll]="true" />
  </div>
</div>
