import { Action } from '@ngrx/store';
import { FileStateItem } from '../models/file-state-item.model';
import { FileItem } from '../models/file-item.model';
import { Locale } from '../../dictionary/models/locale.model';

export enum FileItemActionsTypes {
  CopyLinkRequested = '[File History Item] Copy Link Requested',
  DownloadFileRequested = '[File History Item] Download File Requested',
  SaveFileLocallyRequested = '[File History Item] Save File Locally Requested',
  FileItemSpinnerUpdate = '[File Item Effect] File Item Spinner Update',
  FileItemUpdate = '[File Item Effect] File Item Update',
  ViewFileRequested = '[File History Item] View File Requested',
  DeleteFileConfirmationRequested = '[File History Item] Delete File Confirmation Requested',
  DeleteFileRequested = '[File History Item] Delete File Requested',
  NoPermissionsError = '[File History Item] NoPermissionsError',
  OpenPDFTronViewerRequested = '[File History Item] Open PDFTron Viewer Requested',
  OpenImageViewerRequested = '[File History Item] Open Image Viewer Requested',
  OpenVideoViewerRequested = '[File History Item] Open Video Viewer Requested',
  UnsupportedFeature = '[File History Item] Unsupported Feature',
  FileItemsLoaded = '[File Item Effect] File Items Loaded',
  SignFileRequested = '[File History Item] Sign File Requested',
  copyMoveDialogRequested = '[File History Item] Open File Dialog Requested',
  copyMoveFileRequested = '[File History Item] Copy or Move File Requested',
  copyMoveFileSuccess = '[File History Item] Copy or Move File Success',
  FileSizeZeroWarning = '[File History Item] File Size Zero Warning',
}

export class CopyLinkRequested implements Action {
  readonly type = FileItemActionsTypes.CopyLinkRequested;

  constructor(public payload: { id: string }) {}
}

export class DownloadFileRequested implements Action {
  readonly type = FileItemActionsTypes.DownloadFileRequested;

  constructor(
    public payload: { id: string; callback: (file: Blob) => unknown }
  ) {}
}

export class SaveFileLocallyRequested implements Action {
  readonly type = FileItemActionsTypes.SaveFileLocallyRequested;

  constructor(public payload: { id: string }) {}
}

export class FileItemUpdate implements Action {
  readonly type = FileItemActionsTypes.FileItemUpdate;

  constructor(public payload: { fileItem: FileStateItem }) {}
}

export class FileItemSpinnerUpdate implements Action {
  readonly type = FileItemActionsTypes.FileItemSpinnerUpdate;

  constructor(public payload: { id: string; progress: number }) {}
}

export class ViewFileRequested implements Action {
  readonly type = FileItemActionsTypes.ViewFileRequested;

  constructor(public payload: { id: string; hideHeader?: boolean }) {}
}

export class DeleteFileConfirmationRequested implements Action {
  readonly type = FileItemActionsTypes.DeleteFileConfirmationRequested;

  constructor(public payload: { id: string; fileItem?: FileItem }) {}
}

export class DeleteFileRequested implements Action {
  readonly type = FileItemActionsTypes.DeleteFileRequested;

  constructor(public payload: { id: string }) {}
}

export class NoPermissionsError implements Action {
  readonly type = FileItemActionsTypes.NoPermissionsError;
}

export class OpenPDFTronViewerRequested implements Action {
  readonly type = FileItemActionsTypes.OpenPDFTronViewerRequested;

  constructor(
    public payload: {
      fileItem: FileItem;
      file: Blob;
      extension: string;
      hideHeader?: boolean;
    }
  ) {}
}

export class OpenImageViewerRequested implements Action {
  readonly type = FileItemActionsTypes.OpenImageViewerRequested;

  constructor(
    public payload: { fileItem: FileItem; file: Blob; hideHeader?: boolean }
  ) {}
}

export class OpenVideoViewerRequested implements Action {
  readonly type = FileItemActionsTypes.OpenVideoViewerRequested;

  constructor(
    public payload: { fileItem: FileItem; file: Blob; hideHeader?: boolean }
  ) {}
}

export class UnsupportedFeature implements Action {
  readonly type = FileItemActionsTypes.UnsupportedFeature;
}

export class FileItemsLoaded implements Action {
  readonly type = FileItemActionsTypes.FileItemsLoaded;

  constructor(public payload: { fileItems: FileStateItem[] }) {}
}

export class SignFileRequested implements Action {
  readonly type = FileItemActionsTypes.SignFileRequested;

  constructor(public payload: { id: string }) {}
}

export class copyMoveDialogRequested implements Action {
  readonly type = FileItemActionsTypes.copyMoveDialogRequested;
  constructor(
    public payload: {
      fileItem: FileItem;
      copyFile: boolean;
      sideDrawerId?: string;
      locale?: Locale;
    }
  ) {}
}

export class copyMoveFileRequested implements Action {
  readonly type = FileItemActionsTypes.copyMoveFileRequested;
  constructor(
    public payload: {
      fileItem: FileItem;
      originSidedrawerId: string;
      originRecordId: string;
      destinationSidedrawerId: string;
      destinationRecordId: string;
      operationType: string;
      fromView?: boolean;
    }
  ) {}
}

export class copyMoveFileSuccess implements Action {
  readonly type = FileItemActionsTypes.copyMoveFileSuccess;
  constructor(
    public payload: {
      operationType: string;
      fromView?: boolean;
      response?: unknown;
    }
  ) {}
}

export class FileSizeZeroWarning implements Action {
  readonly type = FileItemActionsTypes.FileSizeZeroWarning;
}

export type FileItemActions =
  | CopyLinkRequested
  | DownloadFileRequested
  | SaveFileLocallyRequested
  | FileItemUpdate
  | ViewFileRequested
  | DeleteFileConfirmationRequested
  | DeleteFileRequested
  | NoPermissionsError
  | OpenPDFTronViewerRequested
  | OpenImageViewerRequested
  | OpenVideoViewerRequested
  | UnsupportedFeature
  | FileItemsLoaded
  | SignFileRequested
  | copyMoveDialogRequested
  | copyMoveFileRequested
  | copyMoveFileSuccess
  | FileSizeZeroWarning;
