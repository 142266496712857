import {
  myTeamsListReducer,
  MyTeamsListState,
} from './my-teams/my-teams-list.reducer';
import {
  networkCrudReducer,
  NetworkCrudState,
} from './networks-crud/networks-crud.reducer';
import {
  networkGroupsReducer,
  NetworkGroupsState,
} from './network-groups/network-groups.reducer';
import {
  networksQueueReducer,
  NetworksQueueState,
} from './networks-queue/network-queue.reducer';
import { ActionReducerMap } from '@ngrx/store';

export const featureName = 'networks';

export interface NetworksState {
  myTeams: MyTeamsListState;
  networkCrudState: NetworkCrudState;
  groups: NetworkGroupsState;
  getNewNetworksRelations: NetworksQueueState;
}

export const networksReducer: ActionReducerMap<NetworksState> = {
  myTeams: myTeamsListReducer,
  networkCrudState: networkCrudReducer,
  groups: networkGroupsReducer,
  getNewNetworksRelations: networksQueueReducer,
};
