import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { FileStateItem } from '../models/file-state-item.model';
import { FileItemActions, FileItemActionsTypes } from './file-item.actions';

export interface FileItemState extends EntityState<FileStateItem> {}

export const fileItemStateAdapter: EntityAdapter<FileStateItem> =
  createEntityAdapter<FileStateItem>({
    selectId: item => item.id,
  });

export function fileItemReducer(
  state: EntityState<FileStateItem> = fileItemStateAdapter.getInitialState(),
  action: FileItemActions
): FileItemState {
  switch (action.type) {
    case FileItemActionsTypes.FileItemUpdate:
      return fileItemStateAdapter.upsertOne(action.payload.fileItem, state);
    case FileItemActionsTypes.FileItemsLoaded:
      return fileItemStateAdapter.upsertMany(action.payload.fileItems, state);
    default:
      return state;
  }
}

export const { selectEntities } = fileItemStateAdapter.getSelectors();
