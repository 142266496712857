export class ReminderShortDictionary {
  public constructor(
    public customMessageFromDictionary?: string,
    public frequencyFromDictionaryDaily?: string,
    public frequencyFromDictionaryMonthly?: string,
    public frequencyFromDictionaryWeekly?: string,
    public frequencyFromDictionaryYearly?: string,
    public frequencyRepeatOfDaily?: string,
    public frequencyRepeatOfMonthly?: string,
    public frequencyRepeatOfWeekly?: string,
    public frequencyRepeatOfYearly?: string,
    public frequencyRepeatOnFromDictionaryDaily?: string,
    public frequencyRepeatOnFromDictionaryMonthly?: string,
    public frequencyRepeatOnFromDictionaryWeekly?: string,
    public frequencyRepeatOnFromDictionaryYearly?: string,
    public frequencySentenceFromDictionaryDaily?: string,
    public frequencySentenceFromDictionaryMonthly?: string,
    public frequencySentenceFromDictionaryWeekly?: string,
    public frequencySentenceFromDictionaryYearly?: string,
    public frequencySentenceNoEndDateDaily?: string,
    public frequencySentenceNoEndDateMonthly?: string,
    public frequencySentenceNoEndDateWeekly?: string,
    public frequencySentenceNoEndDateYearly?: string,
    public frequencyValueFromDictionaryDaily?: string,
    public frequencyValueFromDictionaryMonthly?: string,
    public frequencyValueFromDictionaryWeekly?: string,
    public frequencyValueFromDictionaryYearly?: string,
    public remindersFormEndDateMandatoryMessage?: string,
    public remindersFormFieldMandatoryMessage?: string,
    public remindersFormErrorReminderBody?: string,
    public remindersFormStartTimeMandatoryMessage?: string,
    public sfrReminderCreationNoRepeatSentence?: string,
    public sfrReminderCreationRepeatEndSentence?: string,
    public sfrStartDateMandatoryMessage?: string
  ) {}
}
