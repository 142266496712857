import { ActionReducerMap } from '@ngrx/store';
import {
  RemindersListReducer,
  RemindersListState,
} from './reminders-list.reducer';
import {
  SimpleFileRequestListReducer,
  SimpleFileRequestListState,
} from './sfr.reducer';

export const featureName = 'reminders-module';

export interface RemindersState {
  reminders: RemindersListState;
  sfr: SimpleFileRequestListState;
}

export const remindersReducer: ActionReducerMap<RemindersState> = {
  reminders: RemindersListReducer,
  sfr: SimpleFileRequestListReducer,
};
