import { Action } from '@ngrx/store';
import { SideDrawerNetworkRequestFilter } from '../models/side-drawer-network-request-filter.model';
import { SideDrawerNetworksRequest } from '../models/side-drawer-network-request.model';

export enum SideDrawerNetworksRequestsActionsTypes {
  SideDrawerNetworksInitialPageLoaded = '[] SideDrawerNetworks Initial Page Loaded',
  SideDrawerNetworksSearchRequested = '[] SideDrawerNetworks Search Requested',
  SideDrawerNetworksSearchNextPageRequested = '[] SideDrawerNetworks Search Next Page Requested',
  SideDrawerNetworksSearchPageLoaded = '[SideDrawerNetworks Request Effects] SideDrawerNetworks Search Page Loaded',
  SideDrawerNetworksSearchClearFilter = '[SideDrawerNetworks Request Effects] SideDrawerNetworks Search Clear Filter',
  SideDrawerNetworksStartFlowRequested = '[Start Flow Effect] SideDrawerNetworks Start Flow Requested',
  SideDrawerNetworksStartFlowLoaded = '[SideDrawerNetworks Request Effects] SideDrawerNetworks Start Flow Loaded',
  GettingSideDrawerNetworksRequestsChange = '[SideDrawerNetworks Request Effects] Getting SideDrawer Networks Requests Change',
  SideDrawerNetworksAllNetworksRequested = '[] SideDrawerNetworks All Networks Requested',
  SideDrawerNetworksAllNetworksNextPageRequested = '[] SideDrawerNetworks All Networks Next Page Requested',
  SideDrawerNetworksAllNetworksPageLoaded = '[] SideDrawerNetworks All Networks Page Loaded',
}

export class SideDrawerNetworksInitialPageLoaded implements Action {
  readonly type =
    SideDrawerNetworksRequestsActionsTypes.SideDrawerNetworksInitialPageLoaded;

  constructor(public payload: { result: SideDrawerNetworksRequest }) {}
}

export class SideDrawerNetworksSearchRequested implements Action {
  readonly type =
    SideDrawerNetworksRequestsActionsTypes.SideDrawerNetworksSearchRequested;

  constructor(public payload: { filter: SideDrawerNetworkRequestFilter }) {}
}

export class SideDrawerNetworksSearchNextPageRequested implements Action {
  readonly type =
    SideDrawerNetworksRequestsActionsTypes.SideDrawerNetworksSearchNextPageRequested;
}

export class SideDrawerNetworksSearchPageLoaded implements Action {
  readonly type =
    SideDrawerNetworksRequestsActionsTypes.SideDrawerNetworksSearchPageLoaded;

  constructor(
    public payload: { results: SideDrawerNetworksRequest[]; filter?: string } // TODO improve this, work with filter
  ) {}
}

export class SideDrawerNetworksSearchClearFilter implements Action {
  readonly type =
    SideDrawerNetworksRequestsActionsTypes.SideDrawerNetworksSearchClearFilter;
}

export class SideDrawerNetworksStartFlowRequested implements Action {
  readonly type =
    SideDrawerNetworksRequestsActionsTypes.SideDrawerNetworksStartFlowRequested;

  constructor(public payload?: { otherRegions: boolean }) {}
}

export class SideDrawerNetworksStartFlowLoaded implements Action {
  readonly type =
    SideDrawerNetworksRequestsActionsTypes.SideDrawerNetworksStartFlowLoaded;

  constructor(public payload: { results: SideDrawerNetworksRequest[] }) {}
}

export class GettingSideDrawerNetworksRequestsChange implements Action {
  readonly type =
    SideDrawerNetworksRequestsActionsTypes.GettingSideDrawerNetworksRequestsChange;

  constructor(public payload: { state: boolean }) {}
}

export class SideDrawerNetworksAllNetworksRequested implements Action {
  readonly type =
    SideDrawerNetworksRequestsActionsTypes.SideDrawerNetworksAllNetworksRequested;
}

export class SideDrawerNetworksAllNetworksNextPageRequested implements Action {
  readonly type =
    SideDrawerNetworksRequestsActionsTypes.SideDrawerNetworksAllNetworksNextPageRequested;
}

export class SideDrawerNetworksAllNetworksPageLoaded implements Action {
  readonly type =
    SideDrawerNetworksRequestsActionsTypes.SideDrawerNetworksAllNetworksPageLoaded;

  constructor(public payload: { results: SideDrawerNetworksRequest[] }) {}
}

export type SideDrawerNetworksRequestsActions =
  | SideDrawerNetworksInitialPageLoaded
  | SideDrawerNetworksSearchRequested
  | SideDrawerNetworksSearchNextPageRequested
  | SideDrawerNetworksSearchPageLoaded
  | SideDrawerNetworksSearchClearFilter
  | SideDrawerNetworksStartFlowRequested
  | SideDrawerNetworksStartFlowLoaded
  | GettingSideDrawerNetworksRequestsChange
  | SideDrawerNetworksAllNetworksRequested
  | SideDrawerNetworksAllNetworksNextPageRequested
  | SideDrawerNetworksAllNetworksPageLoaded;
