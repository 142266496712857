import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { AccessibilityHelper } from 'src/app/core/helpers/accessibility.helper';
import { SdColorPalette } from 'src/app/core/models/enums/sd-color-palette-enum';
import { SdAccessibilitySetting } from 'src/app/core/models/sd-accessibility-model';
import { SdInputA11yTemplates } from '../../models/sd-input-a11y-templates.enum';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/reducers';
import { searchBarExpandedSelector } from '../../../../search/store/search-bar.selectors';
import { SdInputA11yLabelType } from '../../models/sd-input-a11y-label-type.enum';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { SdSvgA11yModule } from 'src/app/shared/sd-svg-a11y/sd-svg-a11y.module';
import { SdTooltipButtonA11yComponent } from 'src/app/shared/sd-tooltip-button-a11y/component/sd-tooltip-button-a11y/sd-tooltip-button-a11y.component';
import { FloatLabelType } from '@angular/material/form-field';
import { SdIconButtonA11yComponent } from 'src/app/shared/sd-icon-button-a11y/components/sd-icon-button-a11y/sd-icon-button-a11y.component';
import { SdProgressSpinnerA11yModule } from 'src/app/shared/sd-progress-spinner-a11y/sd-progress-spinner-a11y/sd-progress-spinner-a11y.module';

@Component({
  selector: 'app-sd-input-a11y',
  template: `<ng-template
      [ngIf]="template === sdInputA11yTemplates.inputDefault">
      <mat-form-field [floatLabel]="labelType" [hideRequiredMarker]="true">
        <mat-label>{{ placeholder }}</mat-label>
        <input
          #inputElement
          (change)="data.emit($event)"
          [attr.aria-label]="sdAccessibility?.ariaLabel"
          [attr.aria-disabled]="readonly"
          [attr.aria-readonly]="readonly"
          [autocomplete]="!!autocomplete ? 'on' : 'off'"
          [formControl]="controller"
          [id]="sdAccessibility?.id"
          [mask]="mask"
          [readonly]="readonly"
          [tabIndex]="sdAccessibility?.tabIndex"
          [type]="type"
          class="inputTest"
          matInput />
        <mat-error *ngIf="controller.invalid" role="alert">
          <span>{{ error }}</span>
        </mat-error>
      </mat-form-field>
    </ng-template>
    <ng-template [ngIf]="template === sdInputA11yTemplates.inputWithSuffix">
      <mat-form-field [hideRequiredMarker]="true">
        <mat-label>
          {{ placeholder }}
        </mat-label>
        <input
          #inputElement
          (change)="data.emit($event)"
          [attr.aria-label]="sdAccessibility?.ariaLabel"
          [autocomplete]="!!autocomplete ? 'on' : 'off'"
          [formControl]="controller"
          [id]="sdAccessibility?.id"
          [mask]="mask"
          [attr.aria-disabled]="readonly"
          [readonly]="readonly"
          [tabIndex]="sdAccessibility?.tabIndex"
          [thousandSeparator]="thousandSeparator"
          [type]="type"
          matInput />
        <div class="suffix-container" matSuffix>
          <span *ngIf="matTextSuffix" matTextSuffix>{{ matTextSuffix }}</span>
          <app-sd-icon-button-a11y
            (btnClicked)="suffixClicked.emit(true)"
            *ngIf="!!suffixSvg && suffixSvg.length > 0 && showChevron === false"
            [iconColor]="
              !!suffixSvgColor
                ? suffixSvgColor
                : sdColorPalette.primaryAccentColor
            "
            [iconHeight]="1.25"
            [iconWidth]="1.25"
            [icon]="suffixSvg"
            [viewOnly]="false"
            [setSdAccessibility]="sdAccessibilityForSuffix"
            matSuffix></app-sd-icon-button-a11y>
          <button
            (click)="suffixClicked.emit(true)"
            *ngIf="showChevron"
            aria-label="Select Custom field Type"
            class="button-chevron">
            <mat-icon
              [ngStyle]="{
                color:
                  suffixSvgColor.length > 0
                    ? suffixSvgColor
                    : 'var(--primaryAccentColor)'
              }">
              keyboard_arrow_down
            </mat-icon>
            <app-sd-svg-a11y
              *ngIf="!!suffixSvg && suffixSvg.length > 0"
              [color]="
                suffixSvgColor.length > 0
                  ? suffixSvgColor
                  : 'var(--primaryAccentColor)'
              "
              [height]="0.938"
              [setSdAccessibility]="{
                tabIndex: -1
              }"
              [src]="suffixSvg"
              [width]="0.938"></app-sd-svg-a11y>
          </button>
          <app-sd-tooltip-button-a11y
            *ngIf="!!tooltip && tooltip.length > 0"
            [setSdAccessibility]="sdAccessibilityForTooltipsComponent ?? { ariaLabel: tooltip }"
            [tooltip]="tooltip">
          </app-sd-tooltip-button-a11y>
        </div>

        <mat-error *ngIf="controller.invalid" role="alet">
          <span>{{ error }}</span>
        </mat-error>
      </mat-form-field>
    </ng-template>

    <ng-template
      [ngIf]="template === sdInputA11yTemplates.inputWithSuffixWithoutMask">
      <mat-form-field [hideRequiredMarker]="true">
        <mat-label>
          {{ placeholder }}
        </mat-label>
        <input
          [attr.aria-disabled]="readonly"
          #inputElement
          (change)="data.emit($event)"
          [attr.aria-label]="sdAccessibility?.ariaLabel"
          [autocomplete]="!!autocomplete ? 'on' : 'off'"
          [formControl]="controller"
          [id]="sdAccessibility?.id"
          [readonly]="readonly"
          [tabIndex]="sdAccessibility?.tabIndex"
          [type]="type"
          matInput />
        <div class="suffix-container" matSuffix>
          <span *ngIf="matTextSuffix" matTextSuffix>{{ matTextSuffix }}</span>
          <app-sd-icon-button-a11y
            (btnClicked)="suffixClicked.emit(true)"
            *ngIf="!!suffixSvg && suffixSvg.length > 0 && showChevron === false"
            [iconColor]="
              !!suffixSvgColor
                ? suffixSvgColor
                : sdColorPalette.primaryAccentColor
            "
            [iconHeight]="1.25"
            [iconWidth]="1.25"
            [icon]="suffixSvg"
            [viewOnly]="false"
            [setSdAccessibility]="sdAccessibilityForSuffix"
            matSuffix></app-sd-icon-button-a11y>
          <button
            (click)="suffixClicked.emit(true)"
            *ngIf="showChevron"
            aria-label="Select Custom field Type"
            class="button-chevron">
            <mat-icon
              [ngStyle]="{
                color:
                  suffixSvgColor.length > 0
                    ? suffixSvgColor
                    : 'var(--primaryAccentColor)'
              }">
              keyboard_arrow_down
            </mat-icon>
            <app-sd-svg-a11y
              *ngIf="!!suffixSvg && suffixSvg.length > 0"
              [color]="
                suffixSvgColor.length > 0
                  ? suffixSvgColor
                  : 'var(--primaryAccentColor)'
              "
              [height]="0.938"
              [setSdAccessibility]="{
                tabIndex: -1
              }"
              [src]="suffixSvg"
              [width]="0.938"></app-sd-svg-a11y>
          </button>
          <app-sd-tooltip-button-a11y
            *ngIf="!!tooltip && tooltip.length > 0"
            [setSdAccessibility]="sdAccessibilityForTooltipsComponent"
            [tooltip]="tooltip">
          </app-sd-tooltip-button-a11y>
        </div>

        <mat-error *ngIf="controller.invalid" aria-role="alert">
          <span>{{ error }}</span>
        </mat-error>
      </mat-form-field>
    </ng-template>

    <ng-template [ngIf]="template === sdInputA11yTemplates.inputForSearch">
      <div class="search-input-container">
        <input
          #sdNavBarSearchInput
          [attr.aria-label]="sdAccessibility?.ariaLabel"
          [autocomplete]="!!autocomplete ? 'on' : 'off'"
          [formControl]="controller"
          [id]="sdAccessibility?.id"
          [placeholder]="placeholder"
          [type]="type"
          [tabIndex]="sdAccessibility?.tabIndex"
          class="sd-search-input" />
        <div *ngIf="controller.invalid && (searchBarExpanded$ | async)">
          <mat-error aria-live="assertive">
            <span class="font-error">{{ error }}</span>
          </mat-error>
        </div>
      </div>
    </ng-template>

    <ng-template
      [ngIf]="template === sdInputA11yTemplates.inputForSearchStandAlone">
      <mat-form-field [floatLabel]="labelType" [hideRequiredMarker]="true">
        <mat-label>{{ placeholder }}</mat-label>
        <input
          #sdNavBarSearchInputStandAlone
          [attr.aria-label]="sdAccessibility?.ariaLabel"
          [attr.aria-disabled]="readonly"
          [attr.aria-readonly]="readonly"
          [autocomplete]="!!autocomplete ? 'on' : 'off'"
          [formControl]="controller"
          [id]="sdAccessibility?.id"
          [readonly]="readonly"
          [tabIndex]="sdAccessibility?.tabIndex"
          [type]="type"
          class="inputTest"
          matInput />

        <div class="suffix-container" matSuffix>
          <div *ngIf="loading">
            <app-sd-progress-spinner-a11y />
          </div>
        </div>

        <mat-error *ngIf="controller.invalid" role="alert">
          <span>{{ error }}</span>
        </mat-error>
      </mat-form-field>
    </ng-template>

    <ng-template [ngIf]="template === sdInputA11yTemplates.inputForView">
      <span *ngIf="matTextPrefix" class="preffix" matTextPrefix>{{
        matTextPrefix
      }}</span>

      <input
        #inputElement
        [attr.aria-label]="sdAccessibility?.ariaLabel"
        [formControl]="controller"
        [id]="sdAccessibility?.id"
        [mask]="mask"
        [readonly]="true"
        [thousandSeparator]="thousandSeparator"
        [type]="type"
        class="inputForView" />
      <div *ngIf="!matTextSuffix" class="suffix-container">
        <app-sd-icon-button-a11y
          (btnClicked)="suffixClicked.emit(true)"
          *ngIf="!!suffixSvg && suffixSvg.length > 0"
          [iconColor]="
            !!suffixSvgColor
              ? suffixSvgColor
              : sdColorPalette.secondaryAccentColor
          "
          [iconHeight]="1"
          [iconWidth]="1"
          [icon]="suffixSvg"
          [setSdAccessibility]="
            sdAccessibilityForSuffix
          "></app-sd-icon-button-a11y>
      </div>
      <span *ngIf="matTextSuffix" class="mat-text-suffix" matTextSuffix>{{
        matTextSuffix
      }}</span>
    </ng-template> `,
  styleUrls: ['./sd-input-a11y.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatProgressSpinnerModule,
    SdIconButtonA11yComponent,
    SdSvgA11yModule,
    SdTooltipButtonA11yComponent,
    NgxMaskDirective,
    NgxMaskPipe,
    SdProgressSpinnerA11yModule,
  ],
  providers: [provideNgxMask()],
})
export class SdInputA11yComponent implements AfterViewChecked, AfterViewInit {
  @Input() autocomplete = false;
  @Input() controller: UntypedFormControl;
  @Input() error: string;
  @Input() mask: string;
  @Input() placeholder: string;
  @Input() readonly = false;
  @Input() sdAccessibilityForSuffix: SdAccessibilitySetting;
  @Input() sdAccessibilityForTooltipsComponent: SdAccessibilitySetting;
  @Input() suffixSvg: string;
  @Input() suffixSvgColor: string;
  @Input() template = SdInputA11yTemplates.inputDefault;
  @Input() labelType = SdInputA11yLabelType.auto;
  @Input() suffixIsClickable = true;

  @Input() tooltip: string;
  @Input() type: string;
  @Input() matTextSuffix: string;
  @Input() matTextPrefix: string;
  @Input() thousandSeparator: string;
  @Input() showChevron = false;
  @Input() focused = false;
  @Input() loading = false;

  @Output() data = new EventEmitter<unknown>();
  @Output() suffixClicked = new EventEmitter<boolean>();
  @ViewChild('inputElement', { static: true }) inputElement: ElementRef;
  @ViewChild('sdNavBarSearchInput') sdNavBarSearchInput: ElementRef;
  sdInputA11yTemplates = SdInputA11yTemplates;
  sdColorPalette = SdColorPalette;
  sdAccessibility: SdAccessibilitySetting;
  searchBarExpanded$ = this.store.select(searchBarExpandedSelector);
  sdInputA11yLabelType: FloatLabelType;

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly store: Store<AppState>
  ) {}

  @Input() set setSdAccessibility(value: SdAccessibilitySetting) {
    this.sdAccessibility = AccessibilityHelper.setDefaultAccessibility(value);
  }

  ngAfterViewChecked(): void {
    this.changeDetector.markForCheck();
  }

  ngAfterViewInit(): void {
    if (
      this.focused &&
      this.template === this.sdInputA11yTemplates?.inputForSearch
    ) {
      this.sdNavBarSearchInput?.nativeElement?.focus();
    }
  }
}
