import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DateHelper } from '../../../../core/helpers/date.helper';
import { debounceTime, tap } from 'rxjs/operators';

@Component({
  selector: 'app-sd-search',
  templateUrl: './sd-search.component.html',
  styleUrls: ['./sd-search.component.scss'],
})
export class SdSearchComponent implements OnInit, OnDestroy, OnChanges {
  @Output() filter = new EventEmitter<string>();
  @Input() placeholder: string;
  @Input() searchController = new UntypedFormControl(null);
  @Input() focused: boolean;
  @Input() useDebounceTime = false;
  subscription = new Subscription();
  input;
  todayQuery = DateHelper.getTodayQuery();
  @ViewChild('inputElement', { static: true }) inputElement: ElementRef;

  ngOnInit(): void {
    this.input = document.querySelector('.sd-search-input');
    if (this.focused) {
      this.inputElement.nativeElement.focus();
    }
    const filter$ = this.searchController.valueChanges.pipe(
      debounceTime(this.useDebounceTime ? 1000 : 0),
      tap(value => {
        this.filter.emit(value);
      })
    );
    this.subscription.add(filter$.subscribe());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.focused && changes.focused.currentValue) {
      this.inputElement.nativeElement.focus();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
