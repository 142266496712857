import { Action } from '@ngrx/store';
import { PaginatorApiResponse } from 'src/app/core/models/paginator-api-response.dto';
import { FileItem } from 'src/app/files/models/file-item.model';
import { FileType } from 'src/app/files/models/file-type.enum';

export enum SearchFileListActionsTypes {
  SearchFileListRequested = '[Search Files] File List Requested',
  SearchFileListNextPageRequested = '[Search Files] File List Next Page Requested',
  SearchFileListLoaded = '[Search File Files] File List Loaded',
  SearchFileListReset = '[Search Files] Reset File List Information',
  GetRecordInformationFromRecordId = '[Search Files] Get Record Information From Record Id',
  SearchFileListSetRecordInformation = '[Search Files] Set Record Information',
  SearchFileDeleted = '[Search File Effect] Search File Deleted',
}

export class SearchFileListRequested implements Action {
  readonly type = SearchFileListActionsTypes.SearchFileListRequested;

  constructor(
    public payload: {
      sideDrawerId: string;
      nextPage?: string;
      fileName?: string;
      uploadTitle?: string;
      fileType?: FileType;
    }
  ) {}
}

export class SearchFileListNextPageRequested implements Action {
  readonly type = SearchFileListActionsTypes.SearchFileListNextPageRequested;
}

export class SearchFileListLoaded implements Action {
  readonly type = SearchFileListActionsTypes.SearchFileListLoaded;

  constructor(
    public payload: { data: PaginatorApiResponse<FileItem>; filter?: string }
  ) {}
}

export class SearchFileListReset implements Action {
  readonly type = SearchFileListActionsTypes.SearchFileListReset;
}

export class SearchFileListSetRecordInformation implements Action {
  readonly type = SearchFileListActionsTypes.SearchFileListSetRecordInformation;
}

export class GetRecordInformationFromRecordId implements Action {
  readonly type = SearchFileListActionsTypes.GetRecordInformationFromRecordId;

  constructor(public payload: { recordId?: string }) {}
}

export class SearchFileDeleted implements Action {
  readonly type = SearchFileListActionsTypes.SearchFileDeleted;

  constructor(public payload: { id: string }) {}
}

export type SearchFileListActions =
  | SearchFileListRequested
  | SearchFileListNextPageRequested
  | SearchFileListLoaded
  | SearchFileListReset
  | GetRecordInformationFromRecordId
  | SearchFileListSetRecordInformation
  | SearchFileDeleted;
