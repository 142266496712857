import { SideDrawerNetwork } from '../models/side-drawer-network.model';
import {
  compareSideDrawerRoles,
  SidedrawerRoles,
} from '../../core/roles/sidedrawer.roles';
import { RecordsRoles } from '../../core/roles/records.roles';
import { SideDrawerNetworkRequestType } from '../models/side-drawer-network-request-type.enum';
import { UtilsHelper } from '../../core/helpers/utils.helper';
import { environment } from '../../../environments/environment';
import { Account } from '../../account/models/account.model';
import { PaginatorHelper } from '../../core/helpers/paginator.helper';

export class SideDrawerNetworksHelper {
  public static getSideDrawerNetworksResourceUrl(
    requestType: SideDrawerNetworkRequestType,
    options?: {
      nextPage?: string;
      name?: string;
      type?: string;
      dataBaseRegion?: string;
    }
  ): string {
    let resourceUrl =
      UtilsHelper.apiVersion(environment.networksApi, 2) +
      `sidedrawer/${requestType}?`;
    if (!options) {
      return resourceUrl;
    }
    const { nextPage, name, type, dataBaseRegion } = options;
    if (nextPage?.length > 0) {
      resourceUrl += `&startingAfter=${PaginatorHelper.cleanStartingAfterQueryParam(
        nextPage
      )}&`;
    }
    if (name?.length > 0) {
      resourceUrl += `name=${name?.toLowerCase()?.trim()}&`;
    }
    if (type?.length > 0) {
      resourceUrl += `type=${type?.toLowerCase()?.trim()}&`;
    }
    if (dataBaseRegion !== null && dataBaseRegion !== undefined) {
      resourceUrl += `region=${dataBaseRegion}&`;
    }
    return resourceUrl;
  }

  static filterDuplicatedSdNetworks(
    existingData: SideDrawerNetwork[],
    newData: SideDrawerNetwork[]
  ): SideDrawerNetwork[] {
    if (!newData) {
      return existingData;
    }
    const current: SideDrawerNetwork[] = [];

    const existingDataNewCopy = JSON.parse(JSON.stringify([...existingData]));
    const newDataNewCopy = JSON.parse(JSON.stringify([...newData]));

    existingDataNewCopy.forEach(data => current.push({ ...data }));
    newDataNewCopy.forEach(newSdNetwork => {
      if (
        !current.some(
          existingSdNetwork => existingSdNetwork.id === newSdNetwork.id
        )
      ) {
        current.push(newSdNetwork);
        return;
      }
      const existingSdNetwork = current.find(
        sdNetwork => sdNetwork.id === newSdNetwork.id
      );
      const bestRole = compareSideDrawerRoles(
        existingSdNetwork?.sdRole,
        newSdNetwork?.sdRole
      );
      const index = current.findIndex(
        nSdNetwork => nSdNetwork.id === newSdNetwork.id
      );
      current[index].sdRole = bestRole;
    });
    return current;
  }

  static getDefaultSideDrawer(
    sdNetworks: SideDrawerNetwork[],
    account: Account
  ): SideDrawerNetwork {
    const lastSdId = localStorage.getItem('lastSidedrawerId');
    if (sdNetworks.some(net => net.default)) {
      return sdNetworks.find(net => net.default);
    }
    
    if (sdNetworks.some(net => net.id === lastSdId)) {
      return sdNetworks.find(net => net.id === lastSdId);
    }

    if (account?.defaultSidedrawer?.length > 0) {
      if (sdNetworks.some(net => net.id === account.defaultSidedrawer)) {
        return sdNetworks.find(net => net.id === account.defaultSidedrawer);
      }
    }
    if (sdNetworks.some(net => net.sdRole === SidedrawerRoles.owner)) {
      return sdNetworks.find(net => net.sdRole === SidedrawerRoles.owner);
    }
    if (sdNetworks.some(net => net.sdRole === SidedrawerRoles.editor)) {
      return sdNetworks.find(net => net.sdRole === SidedrawerRoles.editor);
    }
    if (sdNetworks.some(net => net.sdRole === SidedrawerRoles.viewer)) {
      return sdNetworks.find(net => net.sdRole === SidedrawerRoles.viewer);
    }
    if (sdNetworks.some(net => net.sdRole === RecordsRoles.editor)) {
      return sdNetworks.find(net => net.sdRole === RecordsRoles.editor);
    }
    if (sdNetworks.some(net => net.sdRole === RecordsRoles.viewer)) {
      return sdNetworks.find(net => net.sdRole === RecordsRoles.viewer);
    }
    return sdNetworks[0];
  }
}
