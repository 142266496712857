import { NgModule } from '@angular/core';
import { AuthRoutingModule } from './auth-routing.module';
import { StoreModule } from '@ngrx/store';
import { authReducer, featureName } from './store/auth.reducer';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from './store/auth.effects';
import { InactivityTimeDialogModule } from './shared/inactivity-time-dialog/inactivity-time-dialog.module';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './guards/auth.guard';
import { StartFlowModule } from '../start-flow/start-flow.module';

@NgModule({
  imports: [
    AuthRoutingModule,
    StoreModule.forFeature(featureName, authReducer),
    EffectsModule.forFeature([AuthEffects]),
    InactivityTimeDialogModule,
    StartFlowModule,
  ],
  providers: [AuthService, AuthGuard],
})
export class AuthModule {}
