import {
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  inject,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { dictionarySelector } from '../../../dictionary/store/dictionary.selectors';
import { DialogTemplateTypes } from 'src/app/shared/templates/enums/templates.enum';
import { SdDialogTemplateA11yModule } from '../../../shared/templates/dialog-template-a11y/sd-dialog-template-a11y.module';
import { NgIf } from '@angular/common';
import { SdFlatButtonA11yModule } from '../../../shared/sd-flat-button-a11y/sd-flat-button-a11y.module';
import { NetworkEntity } from '../../models/network-entity.enum';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NetworkDeleteStore } from '../../../store/network-delete.store';
import { NetworkV2 } from '../../models/network.v2.type';
import { gettingNetworkListSelector } from '../../store/network-list.store';
import { ContributorType } from '../../models/contributor-type.model';
import { DictionarySignalPipe } from '../../../dictionary/pipes/dictionary-signal.pipe';

@Component({
  selector: 'app-network-delete-dialog',
  styleUrls: ['./network-delete-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SdDialogTemplateA11yModule,
    NgIf,
    SdFlatButtonA11yModule,
    DictionarySignalPipe,
  ],
  providers: [NetworkDeleteStore],
  template: `
    <app-sd-dialog-template-a11y
      *ngIf="!displaySuccessOfFailOperation()"
      (closeButtonClicked)="onClose()"
      [type]="DialogTemplateTypes.Info"
      [spinner]="gettingNetworks()">
      <ng-container dialog-header>
        <h2>
          {{ titleAndDescription()?.title }}
        </h2>
      </ng-container>
      <ng-container dialog-body>
        <div
          [attr.aria-label]="titleAndDescription()?.description"
          [innerHTML]="titleAndDescription()?.description"
          class="base-dialog-content"></div>
      </ng-container>
      <ng-container dialog-footer>
        <app-sd-flat-button-a11y
          (buttonClicked)="onClose()"
          [disabled]="processingOperations()"
          [primary]="false"
          class="secondary"
          [setSdAccessibility]="{
            ariaLabel: 'globalparams_cancel' | dictionarySignal
          }">
          {{ 'globalparams_cancel' | dictionarySignal }}
        </app-sd-flat-button-a11y>
        <app-sd-flat-button-a11y
          (buttonClicked)="onSubmit()"
          [loading]="processingOperations()"
          [danger]="true"
          [setSdAccessibility]="{
            ariaLabel: 'globalparams_delete' | dictionarySignal
          }">
          {{ 'globalparams_delete' | dictionarySignal }}
        </app-sd-flat-button-a11y>
      </ng-container>
    </app-sd-dialog-template-a11y>

    <app-sd-dialog-template-a11y
      *ngIf="displaySuccessOfFailOperation()"
      (closeButtonClicked)="onClose()"
      [type]="DialogTemplateTypes.Info">
      <ng-container dialog-header>
        <h2 #titleDialog>
          {{
            errors.length === 0
              ? ('networkdeketedialog_successtitle' | dictionarySignal)
              : ('networkdeketedialog_errortitle' | dictionarySignal)
          }}
        </h2>
      </ng-container>
      <ng-container dialog-body>
        <div class="base-dialog-content">
          {{
            errors.length === 0
              ? ('networkdeketedialog_successdescription' | dictionarySignal)
              : ('networkdeketedialog_errordescription' | dictionarySignal)
          }}
        </div>
      </ng-container>
      <ng-container dialog-footer>
        <app-sd-flat-button-a11y
          (buttonClicked)="onClose(true)"
          *ngIf="errors.length === 0"
          [primary]="true"
          [setSdAccessibility]="{
            ariaLabel: 'globalparams_ok' | dictionarySignal
          }">
          {{ 'globalparams_ok' | dictionarySignal }}
        </app-sd-flat-button-a11y>

        <app-sd-flat-button-a11y
          (buttonClicked)="onClose(true)"
          *ngIf="errors.length !== 0"
          [primary]="true"
          [setSdAccessibility]="{
            ariaLabel: 'globalparams_ok' | dictionarySignal
          }">
          {{ 'globalparams_ok' | dictionarySignal }}
        </app-sd-flat-button-a11y>
      </ng-container>
    </app-sd-dialog-template-a11y>
  `,
})
export class NetworkDeleteDialogComponent {
  @ViewChild('titleDialog') title: ElementRef<HTMLInputElement>;
  protected readonly DialogTemplateTypes = DialogTemplateTypes;
  private readonly store = inject(Store<AppState>);
  private readonly dialogStore = inject(NetworkDeleteStore);
  private readonly dialogRef = inject(
    MatDialogRef<NetworkDeleteDialogComponent>
  );
  private readonly data: {
    network: NetworkV2;
    networkEntity?: NetworkEntity;
  } = inject(MAT_DIALOG_DATA);
  gettingNetworks = this.store.selectSignal(gettingNetworkListSelector);
  processingOperations = this.dialogStore.processingOperations;
  displaySuccessOfFailOperation = this.dialogStore.operationsComplete;
  errors = this.dialogStore.errors;
  titleAndDescription = computed(() => {
    const dictionary = this.store.selectSignal(dictionarySelector)();
    if (this.data.network.contributorType === ContributorType.team) {
      return {
        title:
          dictionary?.networkdeketedialog_deleteteamtitle +
          ' ' +
          this.data.network?.teamNetwork?.name,
        description: dictionary?.networkdeketedialog_deleteteamdescription,
      };
    }
    if (this.data.network.contributorType === ContributorType.invitation) {
      return {
        title:
          dictionary?.networkdeketedialog_deleteinvitationtitle +
          ' ' +
          this.data.network?.contributor?.email,
        description:
          dictionary?.networkdeketedialog_deleteinvitationdescription,
      };
    }
    return {
      title:
        dictionary?.networkdeketedialog_deleteinvitationtitle +
        ' ' +
        (this.data.network?.contributor?.name ??
          this.data.network?.contributor?.email),
      description: dictionary?.networkdeketedialog_deleteinvitationdescription,
    };
  });

  constructor() {
    this.dialogStore.requestsNetworks(
      this.data.network,
      this.data.networkEntity
    );
  }

  onClose(refreshInformation?: boolean): void {
    this.dialogRef.close(refreshInformation);
  }

  onSubmit(): void {
    this.dialogStore.deleteNetworks({
      networks: this.dialogStore.getNetworksToDelete(
        this.data.network,
        this.data.networkEntity
      ),
      callback: () => {
        setTimeout(() => {
          this.title?.nativeElement?.focus();
        }, 1000);
      },
    });
  }
}
