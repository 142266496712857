import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  RecordsListRequested,
  SetActiveRecord,
} from '../store/records-list.actions';
import { map, tap } from 'rxjs/operators';
import { SideDrawerHomeOnBackgroundRequested } from '../../sidedrawer/store/sidedrawer.actions';
import { Record } from '../models/record.model';
import { RecordSubType } from '../models/record-sub-type.model';
import { SpecificField } from '../models/specific-field.model';
import { SidedrawerRoles } from '../../core/roles/sidedrawer.roles';
import { RecordsNoLicenseDialogComponent } from '../shared/records-no-license-dialog/components/records-no-license-dialog/records-no-license-dialog.component';
import {
  getSettingsRoute,
  SettingsRoutesEnum,
} from '../../settings/routes/settings-routes.enum';
import { RecordsNoLicenseOtherSdDialogComponent } from '../shared/records-no-license-other-sd-dialog/components/records-no-license-other-sd-dialog/records-no-license-other-sd-dialog.component';
import { RecordsPermissionViewerDialogComponent } from '../shared/records-permission-viewer-dialog/components/records-permission-viewer-dialog/records-permission-viewer-dialog.component';
import { HomeService } from '../../home/services/home.service';
import { Router } from '@angular/router';
import { RecordsRoles } from '../../core/roles/records.roles';
import { ErrorLoaded } from '../../core/store/core.actions';
import { MatDialog } from '@angular/material/dialog';
import { UtilsHelper } from 'src/app/core/helpers/utils.helper';
import { authHeadersSelector } from 'src/app/auth/store/auth.selectors';

@Injectable()
export class RecordsService {
  private recordsApi = environment.recordsApi;

  constructor(
    private readonly store: Store<AppState>,
    private readonly http: HttpClient,
    private readonly homeService: HomeService,
    private readonly router: Router
  ) {}

  displayErrorDialog(
    licensePriority: string,
    role: SidedrawerRoles | RecordsRoles,
    dialog: MatDialog,
    error: HttpErrorResponse
  ): void {
    switch (role) {
      case SidedrawerRoles.owner:
      case RecordsRoles.owner:
        if (licensePriority === '1010') {
          dialog
            .open(RecordsNoLicenseDialogComponent, { autoFocus: false })
            .afterClosed()
            .subscribe(confirm => {
              if (confirm) {
                const url =
                  this.homeService.getHomeRoute() +
                  getSettingsRoute(SettingsRoutesEnum.licenses);
                this.router.navigateByUrl(url);
              }
            });
          return;
        }
        this.store.dispatch(new ErrorLoaded({ httpError: { ...error } }));
        break;
      case SidedrawerRoles.editor:
      case RecordsRoles.editor:
        if (licensePriority === '1010') {
          dialog
            .open(RecordsNoLicenseOtherSdDialogComponent, { autoFocus: false })
            .afterClosed()
            .subscribe(confirm => {
              if (confirm) {
                const url =
                  this.homeService.getHomeRoute() +
                  getSettingsRoute(SettingsRoutesEnum.licenses);
                this.router.navigateByUrl(url);
              }
            });
          return;
        }
        this.store.dispatch(new ErrorLoaded({ httpError: { ...error } }));
        break;
      case SidedrawerRoles.viewer:
      case RecordsRoles.viewer:
      case SidedrawerRoles.info:
      case RecordsRoles.info:
      default:
        dialog
          .open(RecordsPermissionViewerDialogComponent, { autoFocus: false })
          .afterClosed()
          .subscribe();
        break;
    }
  }

  deleteRecord(sidedrawerId: string, recordId: string): Observable<unknown> {
    return this.http.delete(
      this.recordsApi +
        `sidedrawer/sidedrawer-id/${sidedrawerId}/records/record-id/${recordId}`,
      { headers: this.store.selectSignal(authHeadersSelector)() }
    );
  }

  // TODO UPDATE THIS REDUX STYLE
  getRecordInformation(
    sidedrawerId: string,
    recordId: string,
    setActiveRecord = true
  ): Observable<Record> {
    const resourceUrl = UtilsHelper.apiVersion(environment.recordsApi, 2);
    return this.http
      .get<Record>(
        resourceUrl +
          `sidedrawer/sidedrawer-id/${sidedrawerId}/records/record-id/${recordId}`,
        { headers: this.store.selectSignal(authHeadersSelector)() }
      )
      .pipe(
        tap(response => {
          if (setActiveRecord) {
            this.store.dispatch(new SetActiveRecord({ data: response }));
          }
        })
      );
  }

  getRecordSubsTypes(
    recordTypeName: string,
    localeDefault: string,
    order = 'ASC'
  ): Observable<RecordSubType[]> {
    return this.http.get<RecordSubType[]>(
      this.recordsApi +
        `records-type/record-type-name/${recordTypeName}/record-subtype?locale=${localeDefault}&order=${order}`,
      { headers: this.store.selectSignal(authHeadersSelector)() }
    );
  }

  getRecordSubsTypeSpecificFields(
    recordTypeName: string,
    recordSubTypeName: string,
    localeDefault: string,
    order = 'ASC'
  ): Observable<SpecificField[]> {
    return this.http.get<SpecificField[]>(
      this.recordsApi +
        `records-type/record-type-name/${recordTypeName}/record-subtype/record-subtype-name/${recordSubTypeName}/specific-fields?locale=${localeDefault}&order=${order}`,
      { headers: this.store.selectSignal(authHeadersSelector)() }
    );
  }

  // TODO UPDATE THIS REDUX STYLE
  createRecord(
    sidedrawerId: string,
    record: Record,
    localeDefault: string
  ): Observable<string> {
    return this.http
      .post<{
        id: string;
      }>(
        this.recordsApi + `sidedrawer/sidedrawer-id/${sidedrawerId}/records`,
        { ...record },
        { headers: this.store.selectSignal(authHeadersSelector)() }
      )
      .pipe(
        map(response => {
          this.store.dispatch(
            new RecordsListRequested({
              sideDrawerId: sidedrawerId,
              locale: localeDefault,
              recordTypeName: record.recordTypeName,
            })
          );
          this.store.dispatch(
            new SideDrawerHomeOnBackgroundRequested({
              sdId: sidedrawerId,
              localeDefault,
            })
          );
          return response?.id;
        })
      );
  }

  // TODO UPDATE THIS REDUX STYLE
  updateRecord(
    sidedrawerId: string,
    recordId: string,
    record: Record,
    localeDefault: string
  ): Observable<boolean> {
    return this.http
      .put<{ id: string }>(
        this.recordsApi +
          `sidedrawer/sidedrawer-id/${sidedrawerId}/records/record-id/${recordId}`,
        {
          description: '',
          ...record,
        },
        { headers: this.store.selectSignal(authHeadersSelector)() }
      )
      .pipe(
        map(() => {
          this.store.dispatch(
            new RecordsListRequested({
              sideDrawerId: sidedrawerId,
              locale: localeDefault,
              recordTypeName: record.recordType?.name ?? record.recordTypeName,
            })
          );
          return true;
        })
      );
  }

  createRecord1(
    sidedrawerId: string,
    record: Record
  ): Observable<{ id: string }> {
    return this.http.post<{ id: string }>(
      this.recordsApi + `sidedrawer/sidedrawer-id/${sidedrawerId}/records`,
      { ...record },
      { headers: this.store.selectSignal(authHeadersSelector)() }
    );
  }

  updateRecord2(
    sidedrawerId: string,
    recordId: string,
    record: Record
  ): Observable<{ id: string }> {
    return this.http.put<{ id: string }>(
      this.recordsApi +
        `sidedrawer/sidedrawer-id/${sidedrawerId}/records/record-id/${recordId}`,
      record,
      { headers: this.store.selectSignal(authHeadersSelector)() }
    );
  }

  copyMoveRecord(
    originSidedrawerId: string,
    originRecordId: string,
    destinationSidedrawerId: string,
    destinationRecordTypeName: string,
    destinationRecordSubtypeName: string,
    operationType: string,
    destinationRecordSubtypOther?: string
  ): Observable<Record> {
    const resourceUrl =
      this.recordsApi +
      `sidedrawer/sidedrawer-id/${originSidedrawerId}/records/record-id/${originRecordId}/${operationType}`;
    const data: {
      destinationSidedrawerId: string;
      recordTypeName?: string;
      recordSubtypeName?: string;
      recordSubtypeOtherName?: string;
    } = {
      destinationSidedrawerId,
      recordTypeName: destinationRecordTypeName,
      recordSubtypeName: destinationRecordSubtypeName,
      recordSubtypeOtherName: destinationRecordSubtypOther,
    };
    return this.http.put<Record>(resourceUrl, data, {
      headers: {
        Authorization:
          this.store.selectSignal(authHeadersSelector)().Authorization,
      },
    });
  }
}
