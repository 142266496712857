import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogTemplateTypes } from 'src/app/shared/templates/enums/templates.enum';

@Component({
  selector: 'app-records-permission-viewer-dialog',
  templateUrl: './records-permission-viewer-dialog.component.html',
  styleUrls: ['./records-permission-viewer-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecordsPermissionViewerDialogComponent {
  dialogTemplateTypes = DialogTemplateTypes;

  constructor(
    private readonly dialogRef: MatDialogRef<RecordsPermissionViewerDialogComponent>
  ) {}

  onClose(): void {
    this.dialogRef.close();
  }
}
