import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogTemplateTypes } from 'src/app/shared/templates/enums/templates.enum';

@Component({
  selector: 'app-records-no-license-dialog',
  templateUrl: './records-no-license-dialog.component.html',
  styleUrls: ['./records-no-license-dialog.component.scss'],
})
export class RecordsNoLicenseDialogComponent {
  dialogTemplateTypes = DialogTemplateTypes;

  constructor(
    private readonly dialogRef: MatDialogRef<RecordsNoLicenseDialogComponent>
  ) {}

  onClose(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    this.dialogRef.close(true);
  }
}
