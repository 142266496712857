import { Action } from '@ngrx/store';
import { PaginatorApiResponse } from 'src/app/core/models/paginator-api-response.dto';
import { RecordListItem } from 'src/app/records/models/record-list-item.model';
import { SfrSortOption } from '../models/enums/sfr-sort-option.enum';

export enum SimpleFileRequestActionsTypes {
  SimpleFileRequestRequested = '[SFR Effect] SFR Requested',
  SimpleFileRequestLoaded = '[SFR Effect]  SFR Requested Loaded',
  SimpleFileRequestSpinnerChange = '[SFR Effect] SFR Spinner Change',
  SimpleFileRequestNextPageRequested = '[SFR Effect] SFR Next Page Requested',
  SimpleFileRequestRequestedForAppend = '[SFR Effect] SFR Requested for Append',
  SimpleFileRequestLoadedForAppend = '[SFR Effect]  SFR Requested Loaded for Append',
  SimpleFileRequestClearData = '[SFR Effect]  SFR Requested Clear Data',
  SimpleFileRequestDeleteRecord = '[SFR Effect]  SFR Requested Delete Record',
  FilingCabinetAllSimpleFileRequest = '[] Filing Cabinet All Simple File Request',
}

export class SimpleFileRequestRequested implements Action {
  readonly type = SimpleFileRequestActionsTypes.SimpleFileRequestRequested;

  constructor(
    public payload: {
      sideDrawerId: string;
      locale: string;
      nextPage?: string;
      name?: string;
      recordTypeName?: string;
      recordTypeId?: string;
      recordSubtypeName?: string;
      recordSubtypeOther?: string;
      recordSubtypeId?: string;
      uniqueReference?: string;
    }
  ) {}
}

export class SimpleFileRequestLoaded implements Action {
  readonly type = SimpleFileRequestActionsTypes.SimpleFileRequestLoaded;

  constructor(
    public payload: {
      sideDrawerId: string;
      data: PaginatorApiResponse<RecordListItem>;
      sortBy: SfrSortOption;
    }
  ) {}
}

export class SimpleFileRequestSpinnerChange implements Action {
  readonly type = SimpleFileRequestActionsTypes.SimpleFileRequestSpinnerChange;

  constructor(public payload: { spinner: boolean }) {}
}

export class SimpleFileRequestNextPageRequested implements Action {
  readonly type =
    SimpleFileRequestActionsTypes.SimpleFileRequestNextPageRequested;
}

export class SimpleFileRequestRequestedForAppend implements Action {
  readonly type =
    SimpleFileRequestActionsTypes.SimpleFileRequestRequestedForAppend;

  constructor(
    public payload: {
      sideDrawerId: string;
      locale: string;
      nextPage?: string;
      name?: string;
      recordTypeName?: string;
      recordTypeId?: string;
      recordSubtypeName?: string;
      recordSubtypeOther?: string;
      recordSubtypeId?: string;
      uniqueReference?: string;
    }
  ) {}
}

export class SimpleFileRequestLoadedForAppend implements Action {
  readonly type =
    SimpleFileRequestActionsTypes.SimpleFileRequestLoadedForAppend;

  constructor(
    public payload: {
      data: PaginatorApiResponse<RecordListItem>;
      sortBy: SfrSortOption;
      sideDrawerId: string;
    }
  ) {}
}
export class FilingCabinetAllSimpleFileRequest implements Action {
  readonly type =
    SimpleFileRequestActionsTypes.FilingCabinetAllSimpleFileRequest;
}
export class SimpleFileRequestClearData implements Action {
  readonly type = SimpleFileRequestActionsTypes.SimpleFileRequestClearData;
}
export class SimpleFileRequestDeleteRecord implements Action {
  readonly type = SimpleFileRequestActionsTypes.SimpleFileRequestDeleteRecord;
  constructor(public payload: { id: string }) {}
}

export type SimpleFileRequestActions =
  | SimpleFileRequestRequested
  | SimpleFileRequestLoaded
  | SimpleFileRequestSpinnerChange
  | SimpleFileRequestNextPageRequested
  | SimpleFileRequestRequestedForAppend
  | SimpleFileRequestLoadedForAppend
  | FilingCabinetAllSimpleFileRequest
  | SimpleFileRequestClearData
  | SimpleFileRequestDeleteRecord;
