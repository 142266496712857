import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DictionaryService } from '../services/dictionary.service';
import {
  DictionaryActionsTypes,
  DictionaryLoaded,
  DictionaryRequested,
  GettingDictionaryChanges,
  HelpSectionsRequested,
  LanguagesLoaded,
  LanguagesRequested,
  LocaleDefaultChange,
} from './dictionary.actions';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { CoreRoutes, getCoreRoute } from '../../core/routes/core.routes';
import { Router } from '@angular/router';
import { RoutesHelper } from '../../core/helpers/routes.helper';
import { combineLatest, of } from 'rxjs';
import { DictionaryHelper } from '../helper/dictionary.helper';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { HelpAndSupportHelper } from '../../core/helpers/help-and-support.helper';
import { helpSectionsSelector } from './dictionary.selectors';
import { HelpSupportVideosDialogComponent } from '../../home/shared/help-support-videos-dialog/help-support-videos-dialog.component';
import { dictionarySelector } from 'src/app/dictionary/store/dictionary.selectors';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class DictionaryEffects {
  requestLanguage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<LanguagesRequested>(DictionaryActionsTypes.LanguagesRequested),
      mergeMap(() =>
        this.dictionaryService.getLanguages().pipe(
          catchError(() => {
            const queryParams = RoutesHelper.getParams(false);
            this.router.navigate([getCoreRoute(CoreRoutes.error) + '/true'], {
              queryParams,
            });
            return of([]);
          }),
          map(languages => new LanguagesLoaded({ languages }))
        )
      )
    );
  });

  requestDictionary$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<DictionaryRequested>(DictionaryActionsTypes.DictionaryRequested),
      tap(() =>
        this.store.dispatch(
          new GettingDictionaryChanges({ gettingDictionary: true })
        )
      ),
      mergeMap(action =>
        this.dictionaryService.getDictionary(action.payload.localeId).pipe(
          catchError(() => {
            const queryParams = RoutesHelper.getParams(false);
            this.router.navigate([getCoreRoute(CoreRoutes.error) + '/true'], {
              queryParams,
            });
            return of(null);
          }),
          map(response => {
            if (response) {
              DictionaryHelper.loadDictionaryStore(response, this.store, false);
              this.store.dispatch(
                new GettingDictionaryChanges({ gettingDictionary: false })
              );
              return new DictionaryLoaded({
                data: response.content,
                completeDictionary: response,
              });
            }
          })
        )
      )
    );
  });

  localeDefault$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<LocaleDefaultChange>(DictionaryActionsTypes.LocaleDefaultChange),
        tap(action => {
          localStorage.setItem('localeId', action.payload?.data?.localeId);
          if (!action?.payload?.data?.localeId?.split('-')?.[0]) {
            return;
          }
          if (window.Intercom) {
            window.Intercom('update', {
              language_override: action.payload.data.localeId
                .split('-')[0]
                ?.toLowerCase(),
            });
          }
        })
      ),
    { dispatch: false }
  );

  helpSectionsRequested$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<HelpSectionsRequested>(
          DictionaryActionsTypes.HelpSectionsRequested
        ),
        mergeMap(action =>
          combineLatest([
            this.store.pipe(select(dictionarySelector)),
            this.store.pipe(select(helpSectionsSelector)),
          ]).pipe(
            mergeMap(([dictionary, helpSections]) => {
              const activeHelpSection =
                HelpAndSupportHelper.getHelpActiveSection(
                  null,
                  helpSections,
                  action?.payload?.url,
                  action?.payload?.appName
                );
              if (activeHelpSection && activeHelpSection?.published) {
                return this.dialog
                  .open(HelpSupportVideosDialogComponent, {
                    autoFocus: false,
                    data: { activeHelpSection: activeHelpSection },
                  })
                  .afterClosed();
              }
              //TODO liven announcer redirecting
              return of(
                window.open(dictionary?.globalparams_helpsectionlink, '_blank')
              );
            }),
            catchError(() => {
              return of([]);
            })
          )
        )
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly dictionaryService: DictionaryService,
    private readonly router: Router,
    private readonly store: Store<AppState>,
    private readonly dialog: MatDialog
  ) {}
}
