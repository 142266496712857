import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import {
  SetActiveSideDrawer,
  SideDrawerHomeRequested,
  SideDrawerSpinnerChange,
} from '../store/sidedrawer.actions';
import { catchError, map, take, tap } from 'rxjs/operators';
import { SideDrawer } from '../models/side-drawer.model';
import { BrandingConfig } from '../../core/models/branding-config.model';
import { RecordsInfoClean } from '../../records/store/records-list.actions';
import {
  PlanRequestedRemoved,
  PlanRequestedRequested,
} from '../../plans/store/plans-list.actions';
import { PlansSortOption } from '../../plans/models/plans-sort-option.model';
import { RecordType } from '../../records/models/record-type.model';
import { UtilsHelper } from '../../core/helpers/utils.helper';
import { isSdOwnerSelector } from '../store/sidedrawer.selector';
import { SideDrawerNetworkRequested } from '../../sidedrawer-networks/store/side-drawer-network-list.actions';
import { SideDrawerNetworkRequestType } from '../../sidedrawer-networks/models/side-drawer-network-request-type.enum';
import { CreateSidedrawerNetworkDto } from 'src/app/networks/models/create-sidedrawer-network.dto';
import { Account } from 'src/app/account/models/account.model';
import { TimelineActions } from '../../timeline/store/timeline.actions';
import { authHeadersSelector } from 'src/app/auth/store/auth.selectors';

@Injectable()
export class SidedrawerService {
  private recordsApi = environment.recordsApi;
  private networksApi = environment.networksApi;

  constructor(
    private readonly store: Store<AppState>,
    private readonly http: HttpClient
  ) {}

  private static removeDuplicatedRecordTypes(
    sidedrawer: SideDrawer
  ): SideDrawer {
    const aux = new Map<string, RecordType>();
    sidedrawer?.recordsType?.forEach(recordType => {
      if (!aux.has(recordType.name)) {
        aux.set(recordType.name, recordType);
      }
    });
    return {
      ...sidedrawer,
      recordsType: UtilsHelper.mapToArray(aux),
    };
  }

  createSidedrawer(newSidedrawer: SideDrawer): Observable<{ id: string }> {
    return this.http.post<{ id: string }>(
      this.recordsApi + 'sidedrawer',
      newSidedrawer,
      {
        headers: this.store.selectSignal(authHeadersSelector)(),
      }
    );
  }

  getSideDrawerHome(
    sdId: string,
    localeDefault: string
  ): Observable<SetActiveSideDrawer> {
    this.store.dispatch(new SideDrawerSpinnerChange({ state: true }));
    return this.http
      .get<SideDrawer>(
        this.recordsApi +
          `sidedrawer/sidedrawer-id/${sdId}/home?locale=${localeDefault}`,
        {
          headers: this.store.selectSignal(authHeadersSelector)(),
          observe: 'response',
        }
      )
      .pipe(
        map(response =>
          SidedrawerService.removeDuplicatedRecordTypes(response.body)
        ),
        map(sidedrawer => {
          this.store.dispatch(TimelineActions.clearEvents());
          this.store.dispatch(new RecordsInfoClean());
          this.store.dispatch(new PlanRequestedRemoved());
          this.store.dispatch(
            new PlanRequestedRequested({
              sidedrawerId: sdId,
              locale: localeDefault,
              sortBy: PlansSortOption.lastModified,
              upsertMany: true,
            })
          );
          this.store.dispatch(new SideDrawerSpinnerChange({ state: false }));
          return new SetActiveSideDrawer({ data: sidedrawer });
        })
      );
  }

  getSideDrawerBranding(
    sdId: string,
    localeDefault: string,
    styleMode = 'lightMode'
  ): Observable<BrandingConfig> {
    return this.http.get<BrandingConfig>(
      this.recordsApi +
        `sidedrawer/sidedrawer-id/${sdId}/branding?locale=${localeDefault}&darkMode=${styleMode}`,
      {
        headers: this.store.selectSignal(authHeadersSelector)(),
      }
    );
  }

  updateSideDrawer(
    sidedrawer: SideDrawer,
    localeDefault: string,
    name: string,
    email?: string,
    sidedrawerNotificationsDisabled?: boolean
  ): Observable<boolean> {
    let body: {
      name?: string;
      emailUsername?: string;
      notificationsDisabled?: boolean;
    } = { name, notificationsDisabled: sidedrawerNotificationsDisabled };

    if (!!email && email.length > 0) {
      body = { ...body, emailUsername: email };
    }
    return this.http
      .put<SideDrawer>(
        this.recordsApi + `sidedrawer/sidedrawer-id/${sidedrawer.id}`,
        { ...body },
        {
          headers: this.store.selectSignal(authHeadersSelector)(),
        }
      )
      .pipe(
        map(() => {
          this.store.dispatch(
            new SideDrawerHomeRequested({ sdId: sidedrawer.id, localeDefault })
          );
          this.store
            .pipe(
              select(isSdOwnerSelector),
              take(1),
              tap(isOwner => {
                this.store.dispatch(
                  new SideDrawerNetworkRequested({
                    requestType: isOwner
                      ? SideDrawerNetworkRequestType.owned
                      : SideDrawerNetworkRequestType.shared,
                    name,
                  })
                );
              })
            )
            .subscribe();
          return true;
        })
      );
  }

  checkAvailableEmail(email: string): Observable<boolean> {
    return this.http
      .get(this.recordsApi + `sidedrawer/check-email?emailUsername=${email}`, {
        headers: this.store.selectSignal(authHeadersSelector)(),
        observe: 'response',
        responseType: 'text',
      })
      .pipe(
        map(response => response.status === 200),
        catchError(error => {
          console.error(error);
          return of<boolean>(false);
        })
      );
  }

  getSideDrawerHomeOnTheBackground(
    sdId: string,
    localeDefault: string
  ): Observable<SideDrawer> {
    return this.http
      .get<SideDrawer>(
        this.recordsApi +
          `sidedrawer/sidedrawer-id/${sdId}/home?locale=${localeDefault}`,
        {
          headers: this.store.selectSignal(authHeadersSelector)(),
          observe: 'response',
        }
      )
      .pipe(
        map(response =>
          SidedrawerService.removeDuplicatedRecordTypes(response.body)
        )
      );
  }

  deleteSidedrawer(sidedrawerId: string): Observable<boolean> {
    return this.http
      .delete(`${this.recordsApi}sidedrawer/sidedrawer-id/${sidedrawerId}`, {
        headers: this.store.selectSignal(authHeadersSelector)(),
        observe: 'response',
      })
      .pipe(
        map(response => response.status === 200),
        catchError(error => {
          console.error(error);
          return of<boolean>(false);
        })
      );
  }

  addOwnerToSideDrawer(
    sideDrawerID: string,
    collaborator: CreateSidedrawerNetworkDto
  ): Observable<boolean> {
    return this.http
      .post(
        `${this.networksApi}sidedrawer/sidedrawer-id/${sideDrawerID}/network`,
        collaborator,
        {
          headers: this.store.selectSignal(authHeadersSelector)(),
        }
      )
      .pipe(map(() => true));
  }

  deleteOwnerSidedrawer(
    sideDrawerID: string,
    roleOwner: string,
    collaborator: Account
  ): Observable<boolean> {
    const bodyContent = JSON.stringify({
      sidedrawerRole: roleOwner,
      contributor: collaborator.openId,
      email: collaborator.username,
    });

    return this.http
      .delete(
        `${this.networksApi}sidedrawer/sidedrawer-id/${sideDrawerID}/network`,
        {
          headers: this.store.selectSignal(authHeadersSelector)(),
          observe: 'response',
          body: bodyContent,
        }
      )
      .pipe(
        map(response => response.status === 200),
        catchError(error => {
          console.error(error);
          return of<boolean>(false);
        })
      );
  }

  getSideDrawerHomeLite(
    sdId: string,
    localeDefault: string
  ): Observable<SideDrawer> {
    return this.http
      .get<SideDrawer>(
        this.recordsApi +
          `sidedrawer/sidedrawer-id/${sdId}/home?locale=${localeDefault}`,
        {
          headers: this.store.selectSignal(authHeadersSelector)(),
          observe: 'response',
        }
      )
      .pipe(
        map(response =>
          SidedrawerService.removeDuplicatedRecordTypes(response.body)
        )
      );
  }
}
