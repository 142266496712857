import { createSelector } from '@ngrx/store';
import { remindersStateSelector } from './reminder.selector';
import { selectAll } from './sfr.reducer';
import { ISvgPlanBadge } from '../../plans/models/plan-badge.model';
import { SfrWorkflowStatus } from '../models/enums/sfr-workflow-status.enum';

export const sfrStateSelector = createSelector(
  remindersStateSelector,
  state => state.sfr
);

export const sfrListSelector = createSelector(sfrStateSelector, selectAll);

export const getSFRListSelector = createSelector(sfrListSelector, state =>
  state && state.length ? state : []
);

export const sfrSpinnerSelector = createSelector(
  sfrStateSelector,
  state => state.gettingSFR
);

export const sfrRequestsInformationSelector = createSelector(
  sfrStateSelector,
  state => state.requestsInformation
);

export const sfrHasMoreSelector = createSelector(
  sfrRequestsInformationSelector,
  requestsInformation =>
    Array.from(requestsInformation.values()).some(value => value.hasMore)
);

export const sfrIncompleteSfrSelector = createSelector(
  sfrListSelector,
  records =>
    records?.filter(record => record?.status === SfrWorkflowStatus.sfrNew)?.length ?? 0
);
