<div *ngIf="recurrenceType" class="reminder-form-recurrence-main-container">
  <div
    *ngIf="recurrenceType === frequency.Daily"
    class="reminders-form-recurrence-item">
    <span class="reminders-form-recurrence-item-label-start" [tabindex]="0">
      {{ frequencyDescription }}</span
    >
    <div class="reminders-form-recurrence-daily-every-item">
      <app-sd-input-a11y
        (keydown)="numberOnly($event)"
        (keydown.arrowdown)="
          everyController.value > minEvery
            ? everyController.setValue(everyController.value - 1)
            : everyController.setValue(minEvery)
        "
        (keydown.arrowup)="
          everyController.value < maxEvery
            ? everyController.setValue(everyController.value + 1)
            : everyController.setValue(maxEvery)
        "
        [autocomplete]="false"
        [controller]="everyController"
        [error]="
          isSubmitted
            ? ('remindersformrecurrence_everydays' | dictionary | async) +
              ' ' +
              frequencyMandatoryMessage
            : ''
        "
        [mask]="'999'"
        [placeholder]="frequencylabel"
        [setSdAccessibility]="{
          ariaLabel: 'remindersformrecurrence_everydays' | dictionary | async,
          id: 'everyController'
        }"
        [type]="'text'">
      </app-sd-input-a11y>
    </div>
    <span class="reminders-form-recurrence-item-label" tabindex="0"
      >{{
        reminderFormRecurrenceShortDictionary.frequencyValueFromDictionaryDaily
      }}
    </span>
  </div>

  <div
    *ngIf="recurrenceType === frequency.Weekly"
    class="reminders-form-recurrence-weeks-days-container">
    <span class="reminders-form-recurrence-item-label-start" tabindex="0">
      {{
        reminderFormRecurrenceShortDictionary.frequencyRepeatOnFromDictionaryWeekly
      }}
    </span>
    <div class="reminders-form-recurrence-weeks-days-item">
      <app-sd-checkbox-a11y
        (valueChange)="d.value = $event; dayOfWeekChanged()"
        *ngFor="let d of daysOfWeeks"
        [id]="sdAccessibilityForPatternController?.id"
        [setSdAccessibility]="{
          ariaLabel: d.key,
          id: d.key
        }"
        [value]="!!d.value ? d.value : null"
        >{{ d.key }}
      </app-sd-checkbox-a11y>
    </div>
    <span class="reminders-form-recurrence-item-label-start" tabindex="0">
      {{ reminderFormRecurrenceShortDictionary.frequencyRepeatOfWeekly }}
    </span>
    <div
      *ngIf="isSubmitted && isInvalidPattern"
      class="error-text-pattern"
      id="reminder-form-recurrence-is-invalidPattern">
      {{ 'globalparams_requirederror' | dictionary | async }}
    </div>
  </div>

  <div
    *ngIf="recurrenceType === frequency.Weekly"
    class="reminders-form-recurrence-weeks-container">
    <span class="reminders-form-recurrence-item-label-start"
      >{{ frequencyDescription }}
    </span>
    <div class="reminders-form-recurrence-weeks-every-item">
      <app-sd-input-a11y
        (keydown)="numberOnly($event)"
        (keydown.arrowdown)="
          everyController.value > minEvery
            ? everyController.setValue(everyController.value - 1)
            : everyController.setValue(minEvery)
        "
        (keydown.arrowup)="
          everyController.value < maxEvery
            ? everyController.setValue(everyController.value + 1)
            : everyController.setValue(maxEvery)
        "
        [autocomplete]="false"
        [controller]="everyController"
        [error]="
          isSubmitted
            ? ('remindersformrecurrence_everyweeks' | dictionary | async) +
              ' ' +
              frequencyMandatoryMessage
            : ''
        "
        [mask]="'999'"
        [placeholder]="frequencylabel"
        [setSdAccessibility]="{
          ariaLabel: 'remindersformrecurrence_everyweeks' | dictionary | async,
          id: 'everyController'
        }"
        [type]="'text'"></app-sd-input-a11y>
    </div>
    <span class="reminders-form-recurrence-item-label" tabindex="0">
      {{
        reminderFormRecurrenceShortDictionary.frequencyValueFromDictionaryWeekly
      }}
    </span>
  </div>

  <div
    *ngIf="recurrenceType === frequency.Monthly"
    class="reminders-form-recurrence-monthly-container">
    <span class="reminders-form-recurrence-item-label-start" tabindex="0">
      {{
        reminderFormRecurrenceShortDictionary.frequencyRepeatOnFromDictionaryMonthly
      }}
    </span>
    <app-sd-selector-a11y
      [controller]="patternController"
      [error]="
        isSubmitted
          ? recurrenceDayLabel +
            ' ' +
            ('globalparams_requirederror' | dictionary | async)
          : ''
      "
      [options]="monthDays"
      [placeholder]="recurrenceDayLabel"
      [setAccessibility]="{
        id: sdAccessibilityForPatternController?.id
      }">
    </app-sd-selector-a11y>
    <span class="reminders-form-recurrence-item-label-start" tabindex="0">
      {{ reminderFormRecurrenceShortDictionary.frequencyRepeatOfMonthly }}
    </span>
  </div>

  <div
    *ngIf="recurrenceType === frequency.Monthly"
    class="reminders-form-recurrence-monthly-container">
    <span class="reminders-form-recurrence-item-label-start"
      >{{ frequencyDescription }}
    </span>
    <div class="reminders-form-recurrence-monthly-item">
      <app-sd-input-a11y
        (keydown)="numberOnly($event)"
        (keydown.arrowdown)="
          everyController.value > minEvery
            ? everyController.setValue(everyController.value - 1)
            : everyController.setValue(minEvery)
        "
        (keydown.arrowup)="
          everyController.value < maxEvery
            ? everyController.setValue(everyController.value + 1)
            : everyController.setValue(maxEvery)
        "
        [autocomplete]="false"
        [controller]="everyController"
        [error]="
          isSubmitted
            ? ('remindersformrecurrence_everymonths' | dictionary | async) +
              ' ' +
              frequencyMandatoryMessage
            : ''
        "
        [mask]="'999'"
        [placeholder]="frequencylabel"
        [setSdAccessibility]="{
          ariaLabel: 'remindersformrecurrence_everymonths' | dictionary | async,
          id: 'everyController'
        }"
        [type]="'text'"></app-sd-input-a11y>
    </div>
    <span class="reminders-form-recurrence-item-label-start" tabindex="0">
      {{
        reminderFormRecurrenceShortDictionary.frequencyValueFromDictionaryMonthly
      }}
    </span>
  </div>

  <div
    *ngIf="recurrenceType === frequency.Yearly"
    class="reminders-form-recurrence-Yearly-container">
    <span class="reminders-form-recurrence-item-label-start" [tabindex]="0">
      {{
        reminderFormRecurrenceShortDictionary.frequencyRepeatOnFromDictionaryYearly
      }}
    </span>
    <app-sd-selector-a11y
      (selection)="onSelectionMonth($event)"
      [controller]="monthsController"
      [error]="
        isSubmitted
          ? recurrenceMonthLabel +
            ' ' +
            ('globalparams_requirederror' | dictionary | async)
          : ''
      "
      [fullOutput]="true"
      [options]="months"
      [placeholder]="recurrenceMonthLabel"
      [setAccessibility]="{
        id: sdAccessibilityForMonthsController?.id
      }">
    </app-sd-selector-a11y>
    <app-sd-selector-a11y
      [controller]="patternController"
      [error]="
        isSubmitted
          ? recurrenceDayLabel +
            ' ' +
            ('globalparams_requirederror' | dictionary | async)
          : ''
      "
      [options]="monthDays"
      [placeholder]="recurrenceDayLabel"
      [setAccessibility]="{
        id: sdAccessibilityForPatternController?.id
      }">
    </app-sd-selector-a11y>
    <span class="reminders-form-recurrence-item-label-start" [tabindex]="0">
      {{ reminderFormRecurrenceShortDictionary.frequencyRepeatOfYearly }}
    </span>
  </div>
  <div
    *ngIf="recurrenceType === frequency.Yearly"
    class="reminders-form-recurrence-Yearly-container">
    <span class="reminders-form-recurrence-item-label-start" [tabindex]="0"
      >{{ frequencyDescription }}
    </span>
    <div class="reminders-form-recurrence-year-item">
      <app-sd-input-a11y
        (keydown)="numberOnly($event)"
        (keydown.arrowdown)="
          everyController.value > minEvery
            ? everyController.setValue(everyController.value - 1)
            : everyController.setValue(minEvery)
        "
        (keydown.arrowup)="
          everyController.value < maxEvery
            ? everyController.setValue(everyController.value + 1)
            : everyController.setValue(maxEvery)
        "
        [autocomplete]="false"
        [controller]="everyController"
        [error]="
          isSubmitted ? frequencylabel + ' ' + frequencyMandatoryMessage : ''
        "
        [mask]="'999'"
        [placeholder]="frequencylabel"
        [setSdAccessibility]="{
          ariaLabel: 'remindersformrecurrence_everyyears' | dictionary | async,
          id: 'everyController'
        }"
        [type]="'text'"></app-sd-input-a11y>
    </div>
    <span class="reminders-form-recurrence-item-label" [tabindex]="0">
      {{
        reminderFormRecurrenceShortDictionary.frequencyValueFromDictionaryYearly
      }}
    </span>
  </div>
</div>
