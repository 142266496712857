import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SdDatePickerA11yComponent } from './components/sd-date-picker-a11y.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { SdSvgA11yModule } from '../sd-svg-a11y/sd-svg-a11y.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { DictionaryPipeModule } from 'src/app/dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { MatInputModule } from '@angular/material/input';
import { SdIconButtonA11yComponent } from '../sd-icon-button-a11y/components/sd-icon-button-a11y/sd-icon-button-a11y.component';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
  declarations: [SdDatePickerA11yComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatIconModule,
    SdIconButtonA11yComponent,
    SdSvgA11yModule,
    ReactiveFormsModule,
    MatInputModule,
    MatNativeDateModule,
    DictionaryPipeModule,
  ],
  exports: [SdDatePickerA11yComponent],
})
export class SdDatePickerA11yModule {}
