import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { AsyncPipe, NgIf } from '@angular/common';
import { SdDialogTemplateA11yModule } from '../../../shared/templates/dialog-template-a11y/sd-dialog-template-a11y.module';
import { DictionaryPipeModule } from '../../../dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { ReEnrollMfaStore } from './re-enroll-mfa.store';
import { SdFlatButtonA11yModule } from '../../../shared/sd-flat-button-a11y/sd-flat-button-a11y.module';
import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { LogOut } from '../../../auth/store/auth.actions';

@Component({
  selector: 'app-re-enroll-mfa-dialog',
  standalone: true,
  imports: [
    SdDialogTemplateA11yModule,
    AsyncPipe,
    DictionaryPipeModule,
    SdFlatButtonA11yModule,
    NgIf,
  ],
  template: `
    <app-sd-dialog-template-a11y
      *ngIf="status$ | async as status"
      (closeButtonClicked)="onClose()">
      <ng-container dialog-header>
        <h2
          id="sd-title-element"
          *ngIf="!(status === 'updateSettingsFails' || status === 'mfaFails')">
          {{ 'reenrollmfadialog_title' | dictionary | async }}
        </h2>
        <h2
          id="sd-title-element"
          *ngIf="status === 'updateSettingsFails' || status === 'mfaFails'">
          {{ 'reenrollmfadialog_errortitle' | dictionary | async }}
        </h2>
      </ng-container>

      <ng-container dialog-body>
        <p *ngIf="!(status === 'updateSettingsFails' || status === 'mfaFails')">
          {{ 'reenrollmfadialog_description' | dictionary | async }}
        </p>

        <p *ngIf="status === 'updateSettingsFails' || status === 'mfaFails'">
          {{ 'reenrollmfadialog_errorbody' | dictionary | async }}
        </p>
      </ng-container>

      <ng-container dialog-footer>
        <app-sd-flat-button-a11y
          (buttonClicked)="onConfirm()"
          [loading]="status === 'processing'"
          [primary]="true"
          [inactiveButtonClass]="true"
          [setSdAccessibility]="{
            ariaLabel:
              (status === 'updateSettingsFails' || status === 'mfaFails'
                ? 'reenrollmfadialog_errorprimarybutton'
                : 'reenrollmfadialog_primarybutton'
              )
              | dictionary
              | async
          }">
          {{
            (status === 'updateSettingsFails' || status === 'mfaFails'
              ? 'reenrollmfadialog_errorprimarybutton'
              : 'reenrollmfadialog_primarybutton'
            )
              | dictionary
              | async
          }}
        </app-sd-flat-button-a11y>
      </ng-container>
    </app-sd-dialog-template-a11y>
  `,
  styleUrls: ['./re-enroll-mfa-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ReEnrollMfaStore],
})
export class ReEnrollMfaDialogComponent {
  private readonly reEnRollStore = inject(ReEnrollMfaStore);
  private readonly store = inject(Store<AppState>);
  status$ = this.reEnRollStore.status$;

  onConfirm(): void {
    this.reEnRollStore.enrollOrReEnroll();
  }

  onClose(): void {
    this.store.dispatch(new LogOut());
  }
}
