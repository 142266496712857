import { ZipQueueStatus } from './zip-queue-status.model';
import { FileItem } from '../../files/models/file-item.model';

export class ZipQueueFile {
  constructor(
    public status: ZipQueueStatus,
    public fileItem: FileItem,
    public progress: number,
    public recordTypeName: string,
    public recordName: string,
    public file?: File
  ) {}
}
