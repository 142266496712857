import { RecordListItem } from '../models/record-list-item.model';
import { RecordsSortOptions } from '../models/records-sort-options.model';
import { DateHelper } from '../../core/helpers/date.helper';
import { UtilsHelper } from '../../core/helpers/utils.helper';
import { environment } from '../../../environments/environment';
import { PaginatorHelper } from '../../core/helpers/paginator.helper';

export class RecordsHelper {
  public static sortRecords = (
    list: RecordListItem[],
    sortBy: RecordsSortOptions
  ): RecordListItem[] => {
    try {
      if (!!list && list.length > 0) {
        const sortedList = [...list];
        switch (sortBy) {
          case RecordsSortOptions.oldestFirst:
            return sortedList.sort((a, b) => a.id.localeCompare(b.id));

          case RecordsSortOptions.newestFirst:
            return sortedList.sort((a, b) => b.id.localeCompare(a.id));

          case RecordsSortOptions.lastModified:
            return sortedList.sort((a, b) =>
              DateHelper.compareDates(b.updatedAt, a.updatedAt)
            );
          case RecordsSortOptions.nameAscending:
            return sortedList.sort((a, b) => a.name.localeCompare(b.name));
          case RecordsSortOptions.nameDescending:
            return sortedList.sort((a, b) => b.name.localeCompare(a.name));
          case RecordsSortOptions.typeAscending:
            return sortedList.sort((a, b) => {
              const subtypeA =
                a.recordSubtype.name === 'other'
                  ? a.recordSubtypeOther
                  : a.recordSubtype.name;
              const subtypeB =
                b.recordSubtype.name === 'other'
                  ? b.recordSubtypeOther
                  : b.recordSubtype.name;
              return subtypeA.localeCompare(subtypeB);
            });
          case RecordsSortOptions.typeDescending:
            return sortedList.sort((a, b) => {
              const subtypeA =
                a.recordSubtype.name === 'other'
                  ? a.recordSubtypeOther
                  : a.recordSubtype.name;
              const subtypeB =
                b.recordSubtype.name === 'other'
                  ? b.recordSubtypeOther
                  : b.recordSubtype.name;
              return subtypeB.localeCompare(subtypeA);
            });
          case RecordsSortOptions.lastModifiedByAscending:
            return sortedList.sort((a, b) =>
              a.lastModifiedBy.localeCompare(b.lastModifiedBy)
            );
          case RecordsSortOptions.lastModifiedByDescending:
            return sortedList.sort((a, b) =>
              b.lastModifiedBy.localeCompare(a.lastModifiedBy)
            );
          case RecordsSortOptions.tileAscending:
            return sortedList.sort((a, b) =>
              a.recordType.name.localeCompare(b.recordType.name)
            );
          case RecordsSortOptions.tileDescending:
            return sortedList.sort((a, b) =>
              b.recordType.name.localeCompare(a.recordType.name)
            );
          default:
            return sortedList;
        }
      } else {
        return list;
      }
    } catch (e) {
      console.error(e);
      return list;
    }
  };

  public static getRecordsResourceUrl(
    sideDrawerId: string,
    locale: string,
    options?: {
      nextPage?: string;
      name?: string;
      recordTypeName?: string;
      recordTypeId?: string;
      recordSubtypeName?: string;
      recordSubtypeOther?: string;
      recordSubtypeId?: string;
      uniqueReference?: string;
      limit?: number;
    }
  ): string {
    let resourceUrl = UtilsHelper.apiVersion(environment.recordsApi, 2);
    resourceUrl += `sidedrawer/sidedrawer-id/${sideDrawerId}/records?`;
    if (!options) {
      return resourceUrl;
    }
    const {
      name,
      recordTypeName,
      recordTypeId,
      recordSubtypeName,
      recordSubtypeOther,
      recordSubtypeId,
      nextPage,
      uniqueReference,
      limit,
    } = options;
    if (nextPage?.length > 0) {
      resourceUrl += `startingAfter=${PaginatorHelper.cleanStartingAfterQueryParam(
        nextPage
      )}&`;
    }
    if (locale?.length > 0) {
      resourceUrl += `locale=${locale?.trim()}&`;
    }
    if (name?.length > 0) {
      resourceUrl += `name=${name?.trim()}&`;
    }
    if (recordTypeName?.length > 0) {
      resourceUrl += `recordTypeName=${recordTypeName?.trim()}&`;
    }
    if (recordTypeId?.length > 0) {
      resourceUrl += `recordTypeId=${recordTypeId?.trim()}&`;
    }
    if (recordSubtypeName?.length > 0) {
      resourceUrl += `recordSubtypeName=${recordSubtypeName?.trim()}&`;
    }
    if (recordSubtypeOther?.length > 0) {
      resourceUrl += `recordSubtypeOther=${recordSubtypeOther?.trim()}&`;
    }
    if (recordSubtypeId?.length > 0) {
      resourceUrl += `recordSubtypeId=${recordSubtypeId?.trim()}&`;
    }
    if (uniqueReference?.length > 0) {
      resourceUrl += `uniqueReference=${uniqueReference?.trim()}&`;
    }

    if (limit) {
      resourceUrl += `limit=${limit}&`;
    }
    return resourceUrl;
  }
}
