import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecordsTypeSwitcherComponent } from './components/records-type-switcher/records-type-switcher.component';
import { RecordsTypeSwitcherTileComponent } from './components/records-type-switcher-tile/records-type-switcher-tile.component';
import { SdButtonsModule } from '../../../shared/sd-buttons/sd-buttons.module';
import { SdSvgA11yModule } from 'src/app/shared/sd-svg-a11y/sd-svg-a11y.module';
import { DictionaryPipeModule } from '../../../dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { DisplayValuePipeModule } from 'src/app/shared/sd-pipes/display-value-pipe/display-value-pipe.module';
import { SdIconButtonA11yComponent } from 'src/app/shared/sd-icon-button-a11y/components/sd-icon-button-a11y/sd-icon-button-a11y.component';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    RecordsTypeSwitcherComponent,
    RecordsTypeSwitcherTileComponent,
  ],
  exports: [RecordsTypeSwitcherComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatTooltipModule,
    SdButtonsModule,
    SdIconButtonA11yComponent,
    SdSvgA11yModule,
    DictionaryPipeModule,
    DisplayValuePipeModule,
  ],
})
export class RecordsTypeSwitcherModule {}
