import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import {
  Auth0Client,
  createAuth0Client,
  RedirectLoginResult,
  User as Auth0User,
} from '@auth0/auth0-spa-js';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { RoutesHelper } from '../../core/helpers/routes.helper';
import { AuthorizeUser, LogIn, LogOut } from '../store/auth.actions';
import { AuthUser } from '../models/auth-user.model';
import { LaunchStartFlow } from '../../start-flow/store/start-flow.actions';
import { Router } from '@angular/router';

@Injectable()
export class AuthService {
  private auth0: Auth0Client;

  constructor(
    private readonly http: HttpClient,
    private readonly store: Store<AppState>,
    private readonly router: Router
  ) {}

  static getRedirectURL(withQuery = true): string {
    console.log(window.location.origin +
        environment.redirectUri +
        (withQuery ? RoutesHelper.getQuery() : ''))
    return (
      window.location.origin +
      environment.redirectUri +
      (withQuery ? RoutesHelper.getQuery() : '')
    );
  }

  async initAuth0(): Promise<void> {
    this.auth0 = await createAuth0Client({
      domain: environment.domain,
      clientId: environment.clientID,
      useRefreshTokens: false,
      authorizationParams: {
        audience: environment.audience,
        scope: environment.scope,
        redirect_uri: AuthService.getRedirectURL(),
      },
    });
  }

  handleRedirect(): Promise<RedirectLoginResult> {
    return this.auth0.handleRedirectCallback();
  }

  logInWithRedirect(): Promise<void> {
    return this.auth0.loginWithRedirect();
  }

  async userIsAuthenticated(): Promise<boolean> {
    if (!this.auth0) {
      await this.initAuth0();
    }
    return this.auth0.isAuthenticated();
  }

  async getAuth0User(): Promise<Auth0User> {
    return this.auth0.getUser();
  }

  async getTokenSilently(): Promise<string> {
    return this.auth0.getTokenSilently({ cacheMode: 'off' });
  }

  logOut(): void {
    Intercom('shutdown');
    this.auth0
      .logout({
        clientId: environment.clientID,
        logoutParams: {
          returnTo: AuthService.getRedirectURL(false),
        },
      })
      .then();
  }

  async initCredentials(): Promise<void> {
    const urlParams = new URLSearchParams(window.location.search);
    const origin = urlParams.get('origin');
    const authenticated = await this.userIsAuthenticated();
    if (authenticated) {
      const user = await this.getAuth0User();
      const token = await this.getTokenSilently().catch(() => {
        this.store.dispatch(new LogOut());
        return null;
      });
      this.store.dispatch(new LogIn({ token, user: AuthUser.fromAuth0(user) }));
      const queryParams = RoutesHelper.getParams(false);
      const url =
        !!origin &&
        origin !== '/' &&
        origin !== '/auth/authorize' &&
        !origin.includes('/core/error') &&
        !origin.includes('/core/forbidden') &&
        !origin.includes('/core/no-account')
          ? origin.split('?')[0]
          : '/core/home/';
      await this.router.navigate([url], { queryParams });
      this.store.dispatch(new LaunchStartFlow());
      return;
    }
    const code = urlParams.get('code');
    const state = urlParams.get('state');
    if (!!code && code.length > 0 && !!state && state.length > 0) {
      await this.handleRedirect();
      this.store.dispatch(new AuthorizeUser());
      return;
    }
    await this.logInWithRedirect();
  }
}
