import { Actions, createEffect, ofType } from '@ngrx/effects';
import { inject } from '@angular/core';
import { ActionsSubject, Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import {
  NetworkSearchActions,
  networkSearchFilterSelector,
  networkSearchNextPageRequestsSelector,
  networkSearchRequestsSelector,
} from './network-search.store';
import { PaginatorService } from '../../core/services/paginator.service';
import { concat, mergeMap, of, takeUntil } from 'rxjs';
import { NetworkHelper } from '../helpers/network.helper';
import { catchError, map } from 'rxjs/operators';
import { NetworkV2 } from '../models/network.v2.type';

export const networkSearchFilterChange$ = createEffect(
  () => {
    const store = inject(Store<AppState>);
    return inject(Actions).pipe(
      ofType(NetworkSearchActions.filterChanged),
      map(({ filter }) => {
        if (filter?.trim()?.length > 0) {
          const requests = NetworkHelper.getInitialNetworkSearchRequests(
            store,
            filter
          );
          requests.forEach(request => {
            store.dispatch(
              NetworkSearchActions.loaded({
                response: {
                  data: [],
                  hasMore: true,
                },
                ...request,
              })
            );
          });
          return NetworkSearchActions.requested();
        }
        return NetworkSearchActions.clear();
      })
    );
  },
  { functional: true }
);

export const networkSearchRequested$ = createEffect(
  () => {
    const store = inject(Store<AppState>);
    const service = inject(PaginatorService);
    const actionsSubject = inject(ActionsSubject);
    return inject(Actions).pipe(
      ofType(NetworkSearchActions.requested),
      map(() => ({
        filter: store.selectSignal(networkSearchFilterSelector)(),
        requests: store.selectSignal(networkSearchRequestsSelector)(),
      })),
      mergeMap(() => {
        const filter = store.selectSignal(networkSearchFilterSelector)();
        const requests = store.selectSignal(networkSearchRequestsSelector)();

        // TODO see this..
        requests.forEach(request => {
          if ('gettingNetworks' in request) {
            request.gettingNetworks = true;
          }
        });

        if (filter?.trim()?.length > 0) {
          return concat(
            ...Array.from(requests.values()).map(request =>
              service
                .getPaginatedResource<NetworkV2>(
                  NetworkHelper.getNetworksResourceUrl(
                    request.sideDrawerId,
                    request.options
                  )
                )
                .pipe(
                  catchError(() =>
                    of({
                      data: [],
                      hasMore: false,
                      nextPage: null,
                      totalCount: 0,
                      requestId: request.requestId,
                      sideDrawerId: request.sideDrawerId,
                      options: request.options,
                    })
                  ),
                  map(response =>
                    NetworkSearchActions.loaded({
                      response,
                      requestId: request.requestId,
                      sideDrawerId: request.sideDrawerId,
                      options: request.options,
                    })
                  )
                )
            )
          ).pipe(
            takeUntil(
              actionsSubject.pipe(
                ofType(
                  NetworkSearchActions.filterChanged,
                  NetworkSearchActions.clear
                )
              )
            )
          );
        }
        return of(NetworkSearchActions.clear);
      })
    );
  },
  { functional: true }
);

export const networkSearchNextPageRequested$ = createEffect(
  () => {
    const store = inject(Store<AppState>);
    const service = inject(PaginatorService);
    const actionsSubject = inject(ActionsSubject);
    return inject(Actions).pipe(
      ofType(NetworkSearchActions.nextPageRequested),
      mergeMap(() =>
        concat(
          ...store
            .selectSignal(networkSearchNextPageRequestsSelector)()
            ?.map(request =>
              service
                .getPaginatedResource<NetworkV2>(
                  NetworkHelper.getNetworksResourceUrl(request.sideDrawerId, {
                    ...request.options,
                    nextPage: request.nextPage,
                  })
                )
                .pipe(
                  catchError(() =>
                    of({
                      data: [],
                      hasMore: false,
                      nextPage: null,
                      totalCount: 0,
                      requestId: request.requestId,
                      sideDrawerId: request.sideDrawerId,
                      options: request.options,
                    })
                  ),
                  map(response =>
                    NetworkSearchActions.loaded({
                      response,
                      requestId: request.requestId,
                      sideDrawerId: request.sideDrawerId,
                      options: request.options,
                    })
                  )
                )
            )
        ).pipe(
          takeUntil(
            actionsSubject.pipe(
              ofType(
                NetworkSearchActions.filterChanged,
                NetworkSearchActions.clear
              )
            )
          )
        )
      )
    );
  },
  { functional: true }
);
