import { createSelector } from '@ngrx/store';
import {
  queueStateSelector,
  selectAll,
  selectEntities,
  selectIds,
  selectTotal,
} from './queue.reducer';

export const queueListSelector = createSelector(queueStateSelector, selectAll);
export const queueIdsSelector = createSelector(queueStateSelector, selectIds);
export const queueTotalItemsSelector = createSelector(
  queueStateSelector,
  selectTotal
);
export const queueEntitiesSelector = createSelector(
  queueStateSelector,
  selectEntities
);

export const queueEntityByIdSelector = (payload: { id: string }) =>
  createSelector(queueEntitiesSelector, entities => entities[payload.id]);

export const queueCurrentItemSelector = createSelector(
  queueListSelector,
  list => list.find(item => item.state === 'processing')
);

export const queueNextItemSelector = createSelector(queueListSelector, list =>
  list.find(item => item.state === 'pending')
);

export const queuePendingItemsSelector = createSelector(
  queueListSelector,
  list => list.filter(item => item.state === 'pending')
);

export const queueCompletedItemsSelector = createSelector(
  queueListSelector,
  list => list.filter(item => item.state === 'success' || item.state === 'fail')
);

export const queueProgressSelector = createSelector(queueListSelector, list =>
  list.length === 0
    ? 0
    : list.reduce(
        (acc, item) => acc + (item.progress > 100 ? 100 : item.progress),
        0
      ) / list.length
);

export const queueItemsByStateSelector = (payload: {
  state: 'pending' | 'processing' | 'success' | 'fail';
}) =>
  createSelector(queueListSelector, list =>
    list.filter(item => item.state === payload.state)
  );
