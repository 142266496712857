import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltip, MatTooltipModule } from '@angular/material/tooltip';
import { AccessibilityHelper } from 'src/app/core/helpers/accessibility.helper';
import { SdColorPalette } from 'src/app/core/models/enums/sd-color-palette-enum';
import { SdAccessibilitySetting } from 'src/app/core/models/sd-accessibility-model';
import { SdSvgA11yModule } from 'src/app/shared/sd-svg-a11y/sd-svg-a11y.module';

@Component({
  selector: 'app-sd-icon-button-a11y',
  templateUrl: './sd-icon-button-a11y.component.html',
  styleUrls: ['./sd-icon-button-a11y.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatTooltipModule,
    MatButtonModule,
    SdSvgA11yModule,
    MatProgressSpinnerModule,
  ],
})
export class SdIconButtonA11yComponent {
  @Input() primary: boolean;
  @Input() openedState: boolean;
  @Input() disabled: boolean;
  @Input() squareBackground = false;
  @Input() icon: string;
  @Input() iconColor: string;
  @Input() iconHeight: number;
  @Input() iconWidth: number;
  @Input() buttonHeight = 2.25;
  @Input() buttonWidth = 2.25;
  @Input() tooltip: string;
  @Input() disableRipple = false;
  @Input() spinner: boolean;
  @Input() transparentBackground: boolean;
  @Input() spinnerMode: 'determinate' | 'indeterminate' = 'indeterminate';
  @Input() spinnerProgress = 0;
  @Input() viewOnly = false;
  @ViewChild(MatTooltip) public matTooltip: MatTooltip;

  sdColorPalette = SdColorPalette;

  sdAccessibility: SdAccessibilitySetting;
  @Output() btnClicked = new EventEmitter<boolean>();

  @Input() set setSdAccessibility(value: SdAccessibilitySetting) {
    this.sdAccessibility = AccessibilityHelper.setDefaultAccessibility(value);
  }
}
