// tslint:disable:variable-name
export class Dictionary {
  globalparams_addicon: string = null;
  globalparams_back: string = null;
  globalparams_calendaricon: string = null;
  globalparams_cancel: string = null;
  globalparams_checkcircleicon: string = null;
  globalparams_checkicon: string = null;
  globalparams_chevrondownicon: string = null;
  globalparams_chevronlefticon: string = null;
  globalparams_chevronrighticon: string = null;
  globalparams_chevronupicon: string = null;
  globalparams_clearall: string = null;
  globalparams_close: string = null;
  globalparams_closedialog: string = null;
  globalparams_closeicon: string = null;
  globalparams_clouddownloadicon: string = null;
  globalparams_clouduploadicon: string = null;
  globalparams_confirm: string = null;
  globalparams_contactadded: string = null;
  globalparams_contributortypeaccount: string = null;
  globalparams_contributortypeaccounticon: string = null;
  globalparams_contributortypeteam: string = null;
  globalparams_contributortypeteamicon: string = null;
  globalparams_copiedtoclipboard: string = null;
  globalparams_copyicon: string = null;
  globalparams_copypassword: string = null;
  globalparams_creditcard: string = null;
  globalparams_creditcardicon: string = null;
  globalparams_currencysymbol: string = null;
  globalparams_delete: string = null;
  globalparams_deleteicon: string = null;
  globalparams_delivered: string = null;
  globalparams_documenticon: string = null;
  globalparams_draganddrop: string = null;
  globalparams_draganddropitemicon: string = null;
  globalparams_editicon: string = null;
  globalparams_exclamationcircleicon: string = null;
  globalparams_externallinkicon: string = null;
  globalparams_fileicon: string = null;
  globalparams_foldericon: string = null;
  globalparams_friday: string = null;
  globalparams_gotoappiconoff: string = null;
  globalparams_gotoappiconon: string = null;
  globalparams_gotoapptitle: string = null;
  globalparams_gotoconsoleiconoff: string = null;
  globalparams_gotoconsoletitle: string = null;
  globalparams_gotomyaccounticonoff: string = null;
  globalparams_gotomyaccounticonon: string = null;
  globalparams_gotomyaccounttitle: string = null;
  globalparams_gotourl: string = null;
  globalparams_helpsectionlink: string = null;
  globalparams_horizontalactionmenuicon: string = null;
  globalparams_imageicon: string = null;
  globalparams_inforequesticon: string = null;
  globalparams_invalidemailerror: string = null;
  globalparams_lastmodifiedat: string = null;
  globalparams_lastmodifiedby: string = null;
  globalparams_lastmodified: string = null;
  globalparams_lastmodifiedon: string = null;
  globalparams_loading: string = null;
  globalparams_loadingmore: string = null;
  globalparams_loadmore: string = null;
  globalparams_logouticon: string = null;
  globalparams_logouttitle: string = null;
  globalparams_mainmenuicon: string = null;
  globalparams_mapmarkericon: string = null;
  globalparams_maxlengtherror: string = null;
  globalparams_menuopen: string = null;
  globalparams_minlengtherror: string = null;
  globalparams_monday: string = null;
  globalparams_month: string = null;
  globalparams_next: string = null;
  globalparams_nosorted: string = null;
  globalparams_numbermaskdecimal: string = null;
  globalparams_ok: string = null;
  globalparams_refresh: string = null;
  globalparams_requirederror: string = null;
  globalparams_reset: string = null;
  globalparams_reseticon: string = null;
  globalparams_saturday: string = null;
  globalparams_searchemptystate: string = null;
  globalparams_searchemptystateicon: string = null;
  globalparams_searchicon: string = null;
  globalparams_selectdate: string = null;
  globalparams_showpassword: string = null;
  globalparams_showpasswordicon: string = null;
  globalparams_sidedrawericon: string = null;
  globalparams_signouticon: string = null;
  globalparams_sorted: string = null;
  globalparams_sorticon: string = null;
  globalparams_sunday: string = null;
  globalparams_thursday: string = null;
  globalparams_tooltipicon: string = null;
  globalparams_tour: string = null;
  globalparams_tuesday: string = null;
  globalparams_update: string = null;
  globalparams_uploadby: string = null;
  globalparams_verticalactionmenuicon: string = null;
  globalparams_viewmore: string = null;
  globalparams_wednesday: string = null;
  globalparams_year: string = null;
  globalparams_yesterday: string = null;
  globalparams_currentpage: string = null;
  // ---------------------------------------
  accountcreateform_firstnameplaceholder: string = null;
  accountcreateform_languageplaceholder: string = null;
  accountcreateform_lastnameplaceholder: string = null;
  accountcreateform_legaldisclaimer1: string = null;
  accountcreateform_legaldisclaimer2: string = null;
  accountcreateform_legaldisclaimer3: string = null;
  accountcreateform_legaldisclaimer4: string = null;
  accountcreateform_legaldisclaimerpp: string = null;
  accountcreateform_legaldisclaimertos: string = null;
  accountcreateform_legaldisclaimeragreement: string = null;
  accountppview_pagetitle: string = null;
  accounttosview_pagetitle: string = null;
  addnewcardform_addnewcardcvcplaceholder: string = null;
  addnewcardform_addnewcardexpiryerror: string = null;
  addnewcardform_addnewcardnameerror: string = null;
  addnewcardform_addnewcardnameplaceholder: string = null;
  addnewcardform_addnewcardnumbererror: string = null;
  addnewcardform_addnewcardnumberplaceholder: string = null;
  addnewcardform_addnewcardsetdefault: string = null;
  addnewcardform_addnewcardsubmitbutton: string = null;
  addnewcardform_addnewcardtitle: string = null;
  addnewcardview_breadcrumb: string = null;
  addremovelicensesdialog_addonelicense: string = null;
  addremovelicensesdialog_addtenlicenses: string = null;
  addremovelicensesdialog_countcontroller: string = null;
  addremovelicensesdialog_currentcost: string = null;
  addremovelicensesdialog_currentcostper: string = null;
  addremovelicensesdialog_newavailable: string = null;
  addremovelicensesdialog_newcost: string = null;
  addremovelicensesdialog_primarybutton: string = null;
  addremovelicensesdialog_removetenlicenses: string = null;
  addressform_manualentrysubmit: string = null;
  assignlicensesview_sdlistunassignedemptymessage: string = null;
  assignnewsidedrawerview_description: string = null;
  assignnewsidedrawerview_emailplaceholder: string = null;
  assignnewsidedrawerview_firstnameplaceholder: string = null;
  assignnewsidedrawerview_lastnameplaceholder: string = null;
  assignnewsidedrawerview_licensetitle: string = null;
  assignnewsidedrawerview_sdname: string = null;
  assignnewsidedrawerview_sdnametitle: string = null;
  assignnewsidedrawerview_sdowner: string = null;
  assignnewsidedrawerview_sdtypes: string = null;
  assignnewsidedrawerview_submitbutton: string = null;
  assignnewsidedrawerview_title: string = null;
  assignnewsidedrawerview_sdownerassigned: string = null;
  assignnewsidedrawerview_sdownerselfowned: string = null;
  createaccounterrordialog_duplicateaccounterrorbody: string = null;
  createaccounterrordialog_duplicateaccounterrorprimarybutton: string = null;
  createaccounterrordialog_duplicateaccounterrortitle: string = null;
  createaccountview_pagetitle: string = null;
  createaccountview_primarybutton: string = null;
  createaccountview_title: string = null;
  createcoownerdialog_description: string = null;
  createcoownerdialog_emailplaceholder: string = null;
  createcoownerdialog_firstnameplaceholder: string = null;
  createcoownerdialog_lastnameplaceholder: string = null;
  createcoownerdialog_primarybutton: string = null;
  createcoownerdialog_title: string = null;
  createcoownerunavailabledialog_body: string = null;
  createcoownerunavailabledialog_primarybutton: string = null;
  createcoownerunavailabledialog_secondarybutton: string = null;
  createcoownerunavailabledialog_title: string = null;
  createrecordforfilesdialog_filelisttitle: string = null;
  createrecordforfilesdialog_nameplaceholder: string = null;
  createrecordforfilesdialog_nametooltip: string = null;
  createrecordforfilesdialog_subtypeplaceholder: string = null;
  createrecordforfilesdialog_subtypetooltip: string = null;
  createrecordforfilesdialog_title: string = null;
  createrecordforfilesdialog_typeplaceholder: string = null;
  createrecordforfilesdialog_typetooltip: string = null;
  createsidedrawersignupform_sdnameplaceholder: string = null;
  createsidedrawersignupform_sdtypestitle: string = null;
  createsidedrawersignupview_pagetitle: string = null;
  createsidedrawersignupview_primarybutton: string = null;
  createsidedrawersignupview_secondarybutton: string = null;
  createsidedrawersignupview_title: string = null;
  createsidedrawerwithlicenceview_breadcrumb: string = null;
  createsidedrawerwithlicenseview_addpaymentmethod: string = null;
  createsidedrawerwithlicenseview_changecurrency: string = null;
  createsidedrawerwithlicenseview_lastnameplaceholder: string = null;
  createsidedrawerwithlicenseview_legal: string = null;
  createsidedrawerwithlicenseview_paymentmethodtitle: string = null;
  createsidedrawerwithlicenseview_sdowner: string = null;
  createsidedrawerwithlicenseview_sdtypes: string = null;
  customfieldschangeformtypedialog_title: string = null;
  customfieldssectionitems_addressplaceholder: string = null;
  customfieldssectionitems_customfielddetailsplaceholder: string = null;
  customfieldssectionitems_customfieldtitleerror: string = null;
  customfieldssectionitems_customfieldtitleplaceholder: string = null;
  deletefiledialog_body: string = null;
  deletefiledialog_primarybutton: string = null;
  deletefiledialog_secondarybutton: string = null;
  deletefiledialog_title: string = null;
  deletesidedrawerdialog_body: string = null;
  deletesidedrawerdialog_primarybutton: string = null;
  deletesidedrawerdialog_secondarybutton: string = null;
  deletesidedrawerdialog_title: string = null;
  deletesidedrawerunavailable_primarybutton: string = null;
  deletesidedrawerunavailable_secondarybutton: string = null;
  deletesidedrawerunavailabledialog_body: string = null;
  deletesidedrawerunavailabledialog_title: string = null;
  deletesubscriptiondialog_body: string = null;
  deletesubscriptiondialog_primarybutton: string = null;
  deletesubscriptiondialog_secondarybutton: string = null;
  deletesubscriptiondialog_title: string = null;
  documentlistitem_downloadicon: string = null;
  documentlistitem_downloadlabel: string = null;
  documentlistitem_filehistorypreviewicon: string = null;
  documentlistitem_filehistorypreviewlabel: string = null;
  envelopelist_nosignaturerequest: string = null;
  envelopelist_title: string = null;
  envelopelist_tooltip: string = null;
  envelopelistitem_actionsmenu: string = null;
  envelopelistitem_lastchange: string = null;
  envelopelistitem_viewdetails: string = null;
  filehistorysection_anyfileupload: string = null;
  filehistorysection_anyfileuploadicon: string = null;
  filehistorysection_title: string = null;
  filehistorysection_tooltip: string = null;
  filehistorysection_viewallfiles: string = null;
  filehistorysectionitem_actionmenuoptiondelete: string = null;
  filehistorysectionitem_actionmenuoptionsign: string = null;
  filehistorysectionitem_actionmenuoptiondownload: string = null;
  filehistorysectionitem_actionmenuoptionlink: string = null;
  filehistorysectionitem_actionmenuoptionpreview: string = null;
  filehistorysectionitem_actionmenutooltip: string = null;
  filehistorysortingdialog_sortbyfilenameaz: string = null;
  filehistorysortingdialog_sortbyfilenameza: string = null;
  filehistorysortingdialog_sortbyfiletypeaz: string = null;
  filehistorysortingdialog_sortbyfiletypeza: string = null;
  filehistorysortingdialog_sortbylastmodified: string = null;
  filehistorysortingdialog_sortbyuploadedbyaz: string = null;
  filehistorysortingdialog_sortbyuploadedbyza: string = null;
  filehistorysortingdialog_title: string = null;
  forbiddensidedrawerdialog_body: string = null;
  forbiddensidedrawerdialog_primaybutton: string = null;
  forbiddensidedrawerdialog_title: string = null;
  generalinformationtab_descriptionplaceholder: string = null;
  generalinformationtab_storagelocationplaceholder: string = null;
  generalinformationtab_storagelocationtooltip: string = null;
  generalinformationtab_submitbutton: string = null;
  generalinfosection_edittooltip: string = null;
  generalinfosection_title: string = null;
  generalinfosection_tooltip: string = null;
  genericerrordialog_body: string = null;
  genericerrordialog_primarybutton: string = null;
  genericerrordialog_title: string = null;
  homemaintemplate_anyfileupload: string = null;
  imageviewerdialog_primarybutton: string = null;
  inactivitytimedialog_errorexpirebody: string = null;
  inactivitytimedialog_errorexpiretitle: string = null;
  inactivitytimedialog_primarybuton: string = null;
  inactivitytimedialog_secondarybutton: string = null;
  landingmaininforequestlist_beginnow: string = null;
  landingmaininforequestlist_complete: string = null;
  landingmaininforequestlist_planrequestviewall: string = null;
  landingmainview_description: string = null;
  landingmainview_planrequesticon: string = null;
  landingmainview_planrequesttitle: string = null;
  landingmainview_planrequesttooltip: string = null;
  landingmainview_timelineicon: string = null;
  landingmainview_timelinetitle: string = null;
  landingmainview_timelinetooltip: string = null;
  landingmainview_title: string = null;
  landingtimelinemainview_timelineviewall: string = null;
  landingview_breadcrumb: string = null;
  landingview_pagetitle: string = null;
  licenselistview_changecurrency: string = null;
  licenselistview_title: string = null;
  licensesfilterbycurrencydialog_description: string = null;
  licensesfilterbycurrencydialog_title: string = null;
  licenseslist_listitem: string = null;
  licenseslist_otherplantitle: string = null;
  licenseslist_otherplantooltip: string = null;
  licenseslist_personalplantitle: string = null;
  licenseslist_personalplantooltip: string = null;
  licenseslist_tenantplanstitle: string = null;
  licenseslist_tenantplanstooltip: string = null;
  licenseslistitem_actionmenuoptionaddremove: string = null;
  licenseslistitem_actionmenutooltip: string = null;
  licenseslistitem_actionmenuoptionassign: string = null;
  licenseslistitem_actionmenuoptioncreatesd: string = null;
  licenseslistitem_assignedlicenses: string = null;
  licenseslistitem_availablelicenses: string = null;
  licenseslistitem_close: string = null;
  licenseslistitem_detailstring4: string = null;
  licenseslistitem_managelicensestitle: string = null;
  licenseslistitem_open: string = null;
  licenseslistitem_totallicenses: string = null;
  licenseslistview_breadcrumb: string = null;
  licenseslistview_pagetitle: string = null;
  managerecordlist_sortbylastmodified: string = null;
  managerecordlist_sortbylastmodifiedbyaz: string = null;
  managerecordlist_sortbylastmodifiedbyza: string = null;
  managerecordlist_sortbyrecordnameaz: string = null;
  managerecordlist_sortbyrecordnameza: string = null;
  managerecordlist_sortbyrecordtypeaz: string = null;
  managerecordlist_sortbyrecordtypeza: string = null;
  managerecordviewfiles_sortbyfilenameaz: string = null;
  managerecordviewfiles_sortbyfilenameza: string = null;
  managerecordviewfiles_sortbyfiletypeaz: string = null;
  managerecordviewfiles_sortbyfiletypeza: string = null;
  managerecordviewfiles_sortbylastmodified: string = null;
  managerecordviewfiles_sortbyuploadedbyaz: string = null;
  managerecordviewfiles_sortbyuploadedbyza: string = null;
  managereminder_custommessagesentence: string = null;
  managereminder_norepeatsentence: string = null;
  managereminder_repeatendsentence: string = null;
  managereminder_startdatemandatorymessage: string = null;
  mfadialog_codeplaceholder: string = null;
  mfadialog_description: string = null;
  mfadialog_errorbody: string = null;
  mfadialog_primarybutton: string = null;
  mfadialog_secondarybutton: string = null;
  mfadialog_title: string = null;
  missingpaymentmethoddialog_body: string = null;
  missingpaymentmethoddialog_primarybutton: string = null;
  missingpaymentmethoddialog_title: string = null;
  myaccountforgotpassworddialog_body: string = null;
  myaccountforgotpassworddialog_primarybutton: string = null;
  myaccountforgotpassworddialog_title: string = null;
  myaccountmainmenu_dashboardiconoff: string = null;
  myaccountmainmenu_logouttitle: string = null;
  myaccountmainmenu_paymentmethodiconoff: string = null;
  myaccountmainmenu_paymentmethodiconon: string = null;
  myaccountmainmenu_paymentmethodtitle: string = null;
  myaccountmainmenu_personalinformationiconoff: string = null;
  myaccountmainmenu_personalinformationiconon: string = null;
  myaccountmainmenu_personalinformationtitle: string = null;
  myaccountmainmenu_resetpasswordiconoff: string = null;
  myaccountmainmenu_resetpasswordiconon: string = null;
  myaccountmainmenu_resetpasswordtitle: string = null;
  myaccountmainmenu_securitysettingsiconnon: string = null;
  myaccountmainmenu_securitysettingsiconoff: string = null;
  myaccountmainmenu_securitysettingstitle: string = null;
  myaccountmainmenu_subscriptionsiconoff: string = null;
  myaccountmainmenu_subscriptionsiconon: string = null;
  myaccountmainmenu_subscriptionstitle: string = null;
  myaccountpersonalinformationview_breadcrumb: string = null;
  myaccountpersonalinformationview_pagetitle: string = null;
  myaccountresetpasswordform_description: string = null;
  myaccountresetpasswordform_emaildescription: string = null;
  myaccountresetpasswordform_emailplaceholder: string = null;
  myaccountresetpasswordform_submitbutton: string = null;
  myaccountresetpasswordform_title: string = null;
  myaccountresetpasswordview_breadcrumb: string = null;
  myaccountresetpasswordview_pagetitle: string = null;
  myaccountsdnavbar_menutitle: string = null;
  myaccountsecurityform_description: string = null;
  myaccountsecurityform_everytimedescription: string = null;
  myaccountsecurityform_everytimetitle: string = null;
  myaccountsecurityform_level: string = null;
  myaccountsecurityform_levelerror: string = null;
  myaccountsecurityform_neverdescription: string = null;
  myaccountsecurityform_nevertitle: string = null;
  myaccountsecurityform_securityoptions: string = null;
  myaccountsecurityform_skipbutton: string = null;
  myaccountsecurityform_sometimesdescription: string = null;
  myaccountsecurityform_sometimestitle: string = null;
  myaccountsecurityform_submitbutton: string = null;
  myaccountsecurityform_title: string = null;
  myaccountsecurityview_breadcrumb: string = null;
  myaccountsecurityview_pagetitle: string = null;
  myaccounttemplate_skiptocontext: string = null;
  myaccounttemplate_title: string = null;
  mymainmenu_dashboard: string = null;
  mymainmenu_dashboardiconoff: string = null;
  mymainmenu_dashboardiconon: string = null;
  mymainmenu_network: string = null;
  mymainmenu_networkiconoff: string = null;
  mymainmenu_networkiconon: string = null;
  mymainmenu_planrequest: string = null;
  mymainmenu_planrequesticonoff: string = null;
  mymainmenu_planrequesticonon: string = null;
  mymainmenu_plusicon: string = null;
  mymainmenu_records: string = null;
  mymainmenu_recordsiconoff: string = null;
  mymainmenu_recordsiconon: string = null;
  mymainmenu_reminders: string = null;
  mymainmenu_remindersiconoff: string = null;
  mymainmenu_remindersiconon: string = null;
  mymainmenu_respondinforequest: string = null;
  mymainmenu_sharefile: string = null;
  mymainmenu_shortcuts: string = null;
  mymainmenu_signouticon: string = null;
  mymainmenu_simplefilerequest: string = null;
  mymainmenu_uploadfile: string = null;
  network_tour: string = null;
  networkdeketedialog_deletecollaboratordescription: string = null;
  networkdeketedialog_deletecollaboratortitle: string = null;
  networkdeketedialog_deleteinvitationdescription: string = null;
  networkdeketedialog_deleteinvitationtitle: string = null;
  networkdeketedialog_deleteteamdescription: string = null;
  networkdeketedialog_deleteteammemberdescription: string = null;
  networkdeketedialog_deleteteammembertitle: string = null;
  networkdeketedialog_deleteteamtitle: string = null;
  networkdeketedialog_errordescription: string = null;
  networkdeketedialog_errortitle: string = null;
  networkdeketedialog_successdescription: string = null;
  networkdeketedialog_successtitle: string = null;
  networkformcontributorform_contributoractionsmenu: string = null;
  networkformcontributorform_emailrequired: string = null;
  networkformrow_contributortypemenutooltip: string = null;
  networkformrow_permissionsmenutooltip: string = null;
  networkformview_collaboratoremailplaceholder: string = null;
  networkformview_collaboratorfullnameplaceholder: string = null;
  networkformview_collaboratorrelationshipplaceholder: string = null;
  networkformview_collaboratorteamplaceholder: string = null;
  networkformview_createcollaboratordescription: string = null;
  networkformview_createcollaboratortitle: string = null;
  networkformview_createteamdescription: string = null;
  networkformview_createteamtitle: string = null;
  networkformview_recordstabname: string = null;
  networkformview_setrolesdescription: string = null;
  networkformview_sidedrawertabname: string = null;
  networkformview_teamrelationshipplaceholder: string = null;
  networkformview_updatecollaboratordescription: string = null;
  networkformview_updatecollaboratortitle: string = null;
  networkformview_updateteamdescription: string = null;
  networkformview_updateteamtitle: string = null;
  networklistitem_actionmenuoptiondetails: string = null;
  networklistitem_actionmenuoptionremove: string = null;
  networklistitem_actionmenuoptionupdaterelation: string = null;
  networklistitem_actionmenutooltip: string = null;
  networklistitem_collaboratorslistactive: string = null;
  networklistitem_collaboratorslistpending: string = null;
  networklistview_addbutton: string = null;
  networklistview_emptysearchresult: string = null;
  networklistview_emptystate: string = null;
  networklistview_nopermissionmessage: string = null;
  networklistview_title: string = null;
  networkmaintemplate_breadcrumb: string = null;
  networkmaintemplate_collaboratortour: string = null;
  networkmaintemplate_collaboratortourlink: string = null;
  networkmaintemplate_pagetitle: string = null;
  networkmaintemplate_tour: string = null;
  networkmaintemplate_tourlink: string = null;
  networkpermissionsrow_actionmenutooltip: string = null;
  networksformsidedrawerstab_description: string = null;
  networksformsidedrawerstab_rowlabel: string = null;
  networkslistview_emptystateicon: string = null;
  nosidedrawerview_breadcrumb: string = null;
  nosidedrawerview_emptybutton: string = null;
  nosidedrawerview_emptyicon: string = null;
  nosidedrawerview_emptystate: string = null;
  nosidedrawerview_pagetitle: string = null;
  nosidedrawerview_title: string = null;
  paymentmethodsview_addnewcardbutton: string = null;
  paymentmethodsview_breadcrumb: string = null;
  paymentmethodsview_pagetitle: string = null;
  paymentmethodsview_title: string = null;
  pdfauditsummarypage_imagelogo: string = null;
  pdfauditsummarypage_signed: string = null;
  pdfauditsummarypage_title: string = null;
  pdfauditsummarypage_updated: string = null;
  pdfauditsummarypage_uploaded: string = null;
  pdftronviewerdialog_body: string = null;
  pdftronviewerdialog_close: string = null;
  personalinformationform_activatenotification: string = null;
  personalinformationform_activatenotifications: string = null;
  personalinformationform_addressplaceholder: string = null;
  personalinformationform_dateofbirthplaceholder: string = null;
  personalinformationform_emailplaceholder: string = null;
  personalinformationform_firstnameplaceholder: string = null;
  personalinformationform_lastnameplaceholder: string = null;
  personalinformationform_localeplaceholder: string = null;
  personalinformationform_notificationsdescription: string = null;
  personalinformationform_submitbutton: string = null;
  personalinformationform_title: string = null;
  personalinformationform_tooltip: string = null;
  plandetailsassociatepdfrecordsdialog_downloadicon: string = null;
  plandetailsassociatepdfrecordsdialog_fileplaceholder: string = null;
  plandetailsassociatepdfrecordsdialog_recordsdetails: string = null;
  plandetailsassociatepdfrecordsdialog_recordstitle: string = null;
  plandetailsassociatepdfrecordsdialog_recordtagsplaceholder: string = null;
  plandetailsassociatepdfrecordsdialog_submit: string = null;
  plandetailsassociatepdfrecordsdialog_tagerror: string = null;
  plandetailsassociatepdfrecordsdialog_title: string = null;
  planfieldtypelistitem_completed: string = null;
  planfieldtypelistitem_datafieldplaceholder: string = null;
  planfieldtypelistitem_reset: string = null;
  planfieldtypelistitem_selectdate: string = null;
  planlistitem_beginnow: string = null;
  planlistitem_complete: string = null;
  planlistitem_lasmodifiedat: string = null;
  planlistitem_lasmodifiedby: string = null;
  planlistitem_lastmodifiedon: string = null;
  planrecordstag_actionmenudelete: string = null;
  planrecordstag_actionmenutooltip: string = null;
  planrecordtypelistitem_addrecord: string = null;
  planrecordtypelistitem_anyfileupload: string = null;
  planrecordtypelistitem_clouduploadregicon: string = null;
  planrecordtypelistitem_completed: string = null;
  planrecordtypelistitem_relatedrecordplaceholder: string = null;
  planrecordtypelistitem_relatedrecordunavailable: string = null;
  planrecordtypelistitemrecordtag_actionmenudelete: string = null;
  planrecordtypelistitemrecordtag_actionmenutooltip: string = null;
  planrequestlist_sortbylastmodified: string = null;
  planrequestlist_sortbylastmodifiedbyaz: string = null;
  planrequestlist_sortbylastmodifiedbyza: string = null;
  planrequestlist_sortbyplancompletionaz: string = null;
  planrequestlist_sortbyplancompletionza: string = null;
  planrequestlist_sortbyplannameaz: string = null;
  planrequestlist_sortbyplannameza: string = null;
  plansdetailsview_error: string = null;
  plansdetailsview_print: string = null;
  plansdetailsview_submit: string = null;
  plansmaintemplate_managetour: string = null;
  plansmaintemplate_managetourlink: string = null;
  plansmaintemplate_tour: string = null;
  plansmaintemplate_tourlink: string = null;
  plansmainview_breadcrumb: string = null;
  plansmainview_emptystate: string = null;
  plansmainview_emptystateicon: string = null;
  plansmainview_loadmore: string = null;
  plansmainview_pagetitle: string = null;
  plansmainview_title: string = null;
  queueitem_updateaccounticon: string = null;
  queueitem_uploadfileerrorlabel: string = null;
  queueitem_uploadfileicon: string = null;
  queueitem_updateteamicon: string = null;
  queueresultdialog_faildescription: string = null;
  queueresultdialog_successdescription: string = null;
  queueresultdialog_title: string = null;
  queuewindow_title: string = null;
  recordformnetworkstab_addbutton: string = null;
  recordformnetworkstab_addbuttontooltip: string = null;
  recordformnetworkstab_emptystate: string = null;
  recordformnetworkstab_title: string = null;
  recordformnetworkstab_tooltip: string = null;
  recordformreminderstab_addreminderbutton: string = null;
  recordformreminderstab_remindertooltip: string = null;
  recordformreminderstab_title: string = null;
  recordformsignaturetab_signatures: string = null;
  recordformsignaturetab_tooltip: string = null;
  recordformview_breadcrumbcreaterecord: string = null;
  recordformview_breadcrumbeditrecord: string = null;
  recordformview_collaboratorstabname: string = null;
  recordformview_documenttypeplaceholder: string = null;
  recordformview_documenttypetooltip: string = null;
  recordformview_filetabname: string = null;
  recordformview_generalinfotabname: string = null;
  recordformview_personalizednameplaceholder: string = null;
  recordformview_personalizednametooltip: string = null;
  recordformview_relatedrecordstabname: string = null;
  recordformview_reminderstabname: string = null;
  recordformview_signaturestabname: string = null;
  recordformview_submitbutton: string = null;
  recordlistview_breadcrumb: string = null;
  recordnetworklist_addbuttontooltip: string = null;
  recordnetworklist_emptystate: string = null;
  recordnetworklist_title: string = null;
  recordnetworklist_tooltip: string = null;
  recordnetworklist_viewallcollaborators: string = null;
  recordnetworklistitem_actionmenuoptiondetails: string = null;
  recordnetworklistitem_actionmenuoptionremove: string = null;
  recordnetworklistitem_actionmenuoptionupdaterelation: string = null;
  recordnetworklistitem_actionmenutooltip: string = null;
  recordnetworklistitem_collaboratorslistactive: string = null;
  recordnetworklistitem_collaboratorslistpending: string = null;
  recordrenameandchangetypedialog_icon: string = null;
  recordrenameandchangetypedialog_title: string = null;
  recordrenameandchangetypedialog_tooltip: string = null;
  recordslistitem_actionmenuoptiondelete: string = null;
  recordslistitem_actionmenuoptionfiles: string = null;
  recordslistitem_actionmenuoptiongeneralinfo: string = null;
  recordslistitem_actionmenuoptionnetworks: string = null;
  recordslistitem_actionmenuoptionreminders: string = null;
  recordslistitem_actionmenuoptionrename: string = null;
  recordslistitem_actionmenuoptionview: string = null;
  recordslistitem_actionmenutooltip: string = null;
  recordslistview_addbutton: string = null;
  recordsviewview_breadcrumb: string = null;
  recordsviewview_editbutton: string = null;
  recordteamnetworklistitem_actionmenuoptiondetails: string = null;
  recordteamnetworklistitem_actionmenuoptionremove: string = null;
  recordteamnetworklistitem_actionmenuoptionupdaterelation: string = null;
  recordteamnetworklistitem_actionmenutooltip: string = null;
  recordteamnetworklistitem_collaboratorslistteam: string = null;
  relatedrecordsdialog_recordplaceholder: string = null;
  relatedrecordsdialog_title: string = null;
  relatedrecordsdialog_tooltip: string = null;
  relatedrecordssection_addbuttontooltip: string = null;
  relatedrecordssection_title: string = null;
  relatedrecordssection_tooltip: string = null;
  relatedrecordstags_actionmenudelete: string = null;
  relatedrecordstags_actionmenutooltip: string = null;
  reminderlistallview_addreminderbutton: string = null;
  reminderlistallview_pagetitle: string = null;
  reminderlistallview_remindertooltip: string = null;
  reminderlistallview_title: string = null;
  remindersform_addbutton: string = null;
  remindersform_addrecurrencydescription: string = null;
  remindersform_addrecurrencydescriptiontooltip: string = null;
  remindersform_contactdescription: string = null;
  remindersform_custommessagedescription: string = null;
  remindersform_custommessageplaceholder: string = null;
  remindersform_custommessagesentence: string = null;
  remindersform_emailplaceholder: string = null;
  remindersform_emailtooltip: string = null;
  remindersform_emptystate: string = null;
  remindersform_enddatedescription: string = null;
  remindersform_enddatemandatorymessage: string = null;
  remindersform_enddateplaceholder: string = null;
  remindersform_errorreminderbody: string = null;
  remindersform_fieldmaxlengthvalidationmessage: string = null;
  remindersform_fieldmaxvalidationmessage: string = null;
  remindersform_fieldminlengthvalidationmessage: string = null;
  remindersform_fieldminvalidationmessage: string = null;
  remindersform_frequencydescription: string = null;
  remindersform_frequencymandatorymessage: string = null;
  remindersform_frequencyplaceholder: string = null;
  remindersform_norepeatsentence: string = null;
  remindersform_primarybutton: string = null;
  remindersform_recurrencedayplaceholder: string = null;
  remindersform_recurrencedescription: string = null;
  remindersform_recurrencemonthplaceholder: string = null;
  remindersform_recurrenceplaceholder: string = null;
  remindersform_remindernameplaceholder: string = null;
  remindersform_remindernametooltip: string = null;
  remindersform_removeenddate: string = null;
  remindersform_repeatendsentence: string = null;
  remindersform_startdatedescription: string = null;
  remindersform_startdatemandatorymessage: string = null;
  remindersform_startdateplaceholder: string = null;
  remindersform_starttimemandatorymessage: string = null;
  remindersform_title: string = null;
  remindersformdialog_title: string = null;
  remindersforminfodialog_errorreminderbody: string = null;
  remindersforminfodialog_errorreminderbutton: string = null;
  remindersforminfodialog_errorremindertitle: string = null;
  remindersforminfodialog_errorsdrolebody: string = null;
  remindersforminfodialog_errorsdrolebutton: string = null;
  remindersforminfodialog_errorsdroletitle: string = null;
  remindersforminfodialog_successbody: string = null;
  remindersforminfodialog_successbutton: string = null;
  remindersforminfodialog_successtitle: string = null;
  remindersformrecurrence_custommessagesentence: string = null;
  remindersformrecurrence_enddatemandatorymessage: string = null;
  remindersformrecurrence_everydays: string = null;
  remindersformrecurrence_everymonths: string = null;
  remindersformrecurrence_everyweeks: string = null;
  remindersformrecurrence_everyyears: string = null;
  remindersformrecurrence_norepeatsentence: string = null;
  remindersformrecurrence_repeatendsentence: string = null;
  remindersformrecurrence_startdatemandatorymessage: string = null;
  reminderslist_emptystate: string = null;
  reminderslist_emptystateicon: string = null;
  reminderslistitem_actionmenuoptiondelete: string = null;
  reminderslistitem_actionmenuoptionview: string = null;
  reminderslistitem_actionmenutooltip: string = null;
  reminderslistitem_custommessagesentence: string = null;
  reminderslistitem_enddatemandatorymessage: string = null;
  reminderslistitem_fieldmandatorymessage: string = null;
  reminderslistitem_norepeatsentence: string = null;
  reminderslistitem_repeatendsentence: string = null;
  reminderslistitem_startdatemandatorymessage: string = null;
  requestsignatureformdialog_addmessage: string = null;
  requestsignatureformdialog_addsigners: string = null;
  requestsignatureformdialog_configureandsend: string = null;
  requestsignatureformdialog_emailplaceholder: string = null;
  requestsignatureformdialog_emailtooltipcustom: string = null;
  requestsignatureformdialog_fullnameplaceholder: string = null;
  requestsignatureformdialog_messageplaceholder: string = null;
  sdbreadcrumbs_homebreadcrumb: string = null;
  sdheader_actionmenuoptiondownload: string = null;
  sdheader_actionmenuoptionmanagesd: string = null;
  sdheader_actionmenuoptionrenamesidedrawer: string = null;
  sdheader_actionmenuoptionshare: string = null;
  sdheader_actionmenuoptiontimeline: string = null;
  sdheader_actionmenutooltip: string = null;
  sdheader_renamesidedrawericon: string = null;
  sdheader_renamesidedrawertooltip: string = null;
  sdheader_sdmenuicon: string = null;
  sdheader_uploadbutton: string = null;
  sdheader_clouduploadbuttonicon: string = null;
  sdheader_uploadbuttontooltip: string = null;
  sdinputlocation_placeholder: string = null;
  sdmanagesidedrawer_addcoownerbutton: string = null;
  sdmanagesidedrawer_addcoownerdescription: string = null;
  sdmanagesidedrawer_addcoownertitle: string = null;
  sdmanagesidedrawer_breadcrumb: string = null;
  sdmanagesidedrawer_dangerzonedescription: string = null;
  sdmanagesidedrawer_dangerzonetitle: string = null;
  sdmanagesidedrawer_dangerzonetooltip: string = null;
  sdmanagesidedrawer_deletesdbutton: string = null;
  sdmanagesidedrawer_deletesddescription: string = null;
  sdmanagesidedrawer_deletesdtitle: string = null;
  sdmanagesidedrawer_notificationsdescription: string = null;
  sdmanagesidedrawer_notificationstooltip: string = null;
  sdmanagesidedrawer_submitbutton: string = null;
  sdmanagesidedrawer_title: string = null;
  sdmanagesidedrawer_transfersdbutton: string = null;
  sdmanagesidedrawer_transfersddescription: string = null;
  sdmanagesidedrawer_transfersdtitle: string = null;
  sdnavbar_actionmenuoptionabout: string = null;
  sdnavbar_actionmenuoptionhelpcenter: string = null;
  sdnavbar_actionmenuoptionpp: string = null;
  sdnavbar_actionmenuoptionsupport: string = null;
  sdnavbar_actionmenuoptiontos: string = null;
  sdnavbar_helpandsupportmenuicon: string = null;
  sdnavbar_helpandsupportmenutooltip: string = null;
  sdnavbar_mainmenu: string = null;
  sdnavbarsearch_icon: string = null;
  sdnavbarsearch_placeholder: string = null;
  sdnavbarsearchtypeselector_icon: string = null;
  sdnavbarsearchtypeselector_tootip: string = null;
  sdownersconfirmdeletedialog_body: string = null;
  sdownersconfirmdeletedialog_primarybutton: string = null;
  sdownersconfirmdeletedialog_secondarybutton: string = null;
  sdownersconfirmdeletedialog_title: string = null;
  sdplaceofresidence_addressformtitle: string = null;
  sdplaceofresidence_country: string = null;
  sdplaceofresidence_description: string = null;
  sdplaceofresidence_googleplacefieldplaceholder: string = null;
  sdplaceofresidence_manualentryaddress2placeholder: string = null;
  sdplaceofresidence_manualentrydescriptionplaceholder: string = null;
  sdplaceofresidence_manualentrypostalcodeplaceholder: string = null;
  sdplaceofresidence_manualentrystreetaddressplaceholder: string = null;
  sdplaceofresidence_manualentrysubmit: string = null;
  sdplaceofresidence_title: string = null;
  sdrecordtilebutton_actionmenuoptionshare: string = null;
  sdrecordtilebutton_actionmenutooltip: string = null;
  sdtime_selecthoursandminutesmessage: string = null;
  sdvcarddropdown_close: string = null;
  sdvcarddropdown_expand: string = null;
  securitysettingsdialog_primarybutton: string = null;
  securitysettingsdialog_secondarybutton: string = null;
  securitysettingsdialog_title: string = null;
  selectablelicenselist_personalavailableplanstitle: string = null;
  selectablelicenselist_personalavailableplanstitletooltip: string = null;
  selectablelicenselist_personalsubscriptionstitle: string = null;
  selectablelicenselist_personalsubscriptionstitletooltip: string = null;
  selectablelicenselist_tenantsubscriptionstitle: string = null;
  selectablelicenselist_tenantsubscriptionstitletooltip: string = null;
  selectablelicenselistitem_assignedlicenses: string = null;
  selectablelicenselistitem_availablelicenses: string = null;
  selectablelicenselistitem_detailstring4: string = null;
  selectablelicenselistitem_personaldetails: string = null;
  selectablelicenselistitem_totallicenses: string = null;
  settingsppview_breadcrumb: string = null;
  settingsppview_pagetitle: string = null;
  settingstosview_breadcrumb: string = null;
  settingstosview_pagetitle: string = null;
  setupmfa_everytimedescription: string = null;
  setupmfa_everytimetitle: string = null;
  setupmfa_neverdescription: string = null;
  setupmfa_nevertitle: string = null;
  setupmfa_sometimesdescription: string = null;
  setupmfa_sometimestitle: string = null;
  sfr_enddatemandatorymessage: string = null;
  sfr_fieldmandatorymessage: string = null;
  sfr_startdatemandatorymessage: string = null;
  sfrremindercreation_custommessagesentence: string = null;
  sfrremindercreation_norepeatsentence: string = null;
  sfrremindercreation_repeatendsentence: string = null;
  shareresourcedialog_contributortypeplaceholder: string = null;
  shareresourcedialog_description: string = null;
  shareresourcedialog_emailplaceholder: string = null;
  shareresourcedialog_fullnameplaceholder: string = null;
  shareresourcedialog_permissionplaceholder: string = null;
  shareresourcedialog_relationshipplaceholder: string = null;
  shareresourcedialog_teamplaceholder: string = null;
  shareresourcedialog_title: string = null;
  sidedrawerlicenseitem_sdlistreserved: string = null;
  sidedrawermainview_breadcrumb: string = null;
  sidedrawermainview_pagetitle: string = null;
  sidedrawermainview_title: string = null;
  sidedrawermenu_emptystate: string = null;
  sidedrawermenu_menuicon: string = null;
  sidedrawermenu_searchplaceholder: string = null;
  sidedrawermenu_tooltip: string = null;
  sidedrawernetworklist_nocollaboratorsmessage: string = null;
  sidedrawernetworklist_title: string = null;
  sidedrawernetworklist_tooltip: string = null;
  sidedrawerrenamedialog_sidedrawernameplaceholder: string = null;
  sidedrawerrenamedialog_sidedrawernametooltip: string = null;
  sidedrawerrenamedialog_title: string = null;
  signaturedetailsdialog_details: string = null;
  signaturedetailsdialog_documents: string = null;
  signaturedetailsdialog_lastmodified: string = null;
  signaturedetailsdialog_signers: string = null;
  signaturedetailsdialog_status: string = null;
  signaturedetailsdialog_viewondocusign: string = null;
  singlefilerequestform_addbutton: string = null;
  singlefilerequestform_addrecurrencydescription: string = null;
  singlefilerequestform_addrecurrencydescriptiontooltip: string = null;
  singlefilerequestform_custommessagesentence: string = null;
  singlefilerequestform_description: string = null;
  singlefilerequestform_emailplaceholder: string = null;
  singlefilerequestform_emailtooltip: string = null;
  singlefilerequestform_emptystate: string = null;
  singlefilerequestform_enddatedescription: string = null;
  singlefilerequestform_enddatemandatorymessage: string = null;
  singlefilerequestform_enddateplaceholder: string = null;
  singlefilerequestform_fieldmaxlengthvalidationmessage: string = null;
  singlefilerequestform_fieldmaxvalidationmessage: string = null;
  singlefilerequestform_fieldminlengthvalidationmessage: string = null;
  singlefilerequestform_fieldminvalidationmessage: string = null;
  singlefilerequestform_frequencydescription: string = null;
  singlefilerequestform_frequencymandatorymessage: string = null;
  singlefilerequestform_frequencyplaceholder: string = null;
  singlefilerequestform_networkcreationdescription: string = null;
  singlefilerequestform_norepeatsentence: string = null;
  singlefilerequestform_primarybutton: string = null;
  singlefilerequestform_recordnameplaceholder: string = null;
  singlefilerequestform_recordnametooltip: string = null;
  singlefilerequestform_recordsubtypeplaceholder: string = null;
  singlefilerequestform_recordsubtypetooltip: string = null;
  singlefilerequestform_recordtypeplaceholder: string = null;
  singlefilerequestform_recurrencedayplaceholder: string = null;
  singlefilerequestform_recurrencedescription: string = null;
  singlefilerequestform_recurrencemonthplaceholder: string = null;
  singlefilerequestform_recurrenceplaceholder: string = null;
  singlefilerequestform_remindercreationcustommessageplaceholder: string = null;
  singlefilerequestform_remindercreationdescription: string = null;
  singlefilerequestform_removeenddate: string = null;
  singlefilerequestform_repeatendsentence: string = null;
  singlefilerequestform_startdatedescription: string = null;
  singlefilerequestform_startdatemandatorymessage: string = null;
  singlefilerequestform_startdateplaceholder: string = null;
  singlefilerequestform_starttimemandatorymessage: string = null;
  singlefilerequestinfodialog_errorreminderbody: string = null;
  singlefilerequestinfodialog_errorreminderbutton: string = null;
  singlefilerequestinfodialog_errorremindertitle: string = null;
  singlefilerequestinfodialog_errorsdrolebody: string = null;
  singlefilerequestinfodialog_errorsdroletitle: string = null;
  singlefilerequestinfodialog_successbody: string = null;
  singlefilerequestinfodialog_successbutton: string = null;
  singlefilerequestinfodialog_successtitle: string = null;
  singlefilerequestview_breadcrumb: string = null;
  singlefilerequestview_pagetitle: string = null;
  singlefilerequestview_title: string = null;
  sortplansdialog_sortbylasmodified: string = null;
  sortplansdialog_sortbylastmodifiedbyaz: string = null;
  sortplansdialog_sortbylastmodifiedbyza: string = null;
  sortplansdialog_sortbyplancompletionaz: string = null;
  sortplansdialog_sortbyplancompletionza: string = null;
  sortplansdialog_sortbyplannameaz: string = null;
  sortplansdialog_sortbyplannameza: string = null;
  sortrecordsdialog_sortbylastmodified: string = null;
  sortrecordsdialog_sortbylastmodifiedbyaz: string = null;
  sortrecordsdialog_sortbylastmodifiedbyza: string = null;
  sortrecordsdialog_sortbyrecordnameaz: string = null;
  sortrecordsdialog_sortbyrecordnameza: string = null;
  sortrecordsdialog_sortbyrecordtypeaz: string = null;
  sortrecordsdialog_sortbyrecordtypeza: string = null;
  sortrecordsdialog_title: string = null;
  teamnetworklistitem_actionmenuoptiondetails: string = null;
  teamnetworklistitem_actionmenuoptionremove: string = null;
  teamnetworklistitem_actionmenuoptionupdaterelation: string = null;
  teamnetworklistitem_actionmenutooltip: string = null;
  teamnetworklistitem_collaboratorslistteam: string = null;
  timelinelistview_breadcrumb: string = null;
  timelinelistview_nopermission: string = null;
  timelinelistview_pagetitle: string = null;
  timelinelistview_title: string = null;
  timelinelistview_viewmore: string = null;
  timelinenotificationitem_clickable: string = null;
  timelinenotificationitem_listitem: string = null;
  timelinerecordnotfounddialog_body: string = null;
  timelinerecordnotfounddialog_primarybutton: string = null;
  timelinerecordnotfounddialog_title: string = null;
  transferownershipalertdialog_body: string = null;
  transferownershipalertdialog_primarybutton: string = null;
  transferownershipalertdialog_secondarybutton: string = null;
  transferownershipalertdialog_title: string = null;
  unsupportedfeaturedialog_body: string = null;
  unsupportedfeaturedialog_primarybutton: string = null;
  unsupportedfeaturedialog_title: string = null;
  updatesubscriptionsstatusdialog_chargecarderrorbody: string = null;
  updatesubscriptionsstatusdialog_chargecarderrorbutton: string = null;
  updatesubscriptionsstatusdialog_chargecarderrortitle: string = null;
  updatesubscriptionsstatusdialog_creationerrorbody: string = null;
  updatesubscriptionsstatusdialog_creationerrorbutton: string = null;
  updatesubscriptionsstatusdialog_creationerrortitle: string = null;
  updatesubscriptionsstatusdialog_currencyerrorbody: string = null;
  updatesubscriptionsstatusdialog_currencyerrorbutton: string = null;
  updatesubscriptionsstatusdialog_currencyerrortitle: string = null;
  updatesubscriptionsstatusdialog_revokeerrorbody: string = null;
  updatesubscriptionsstatusdialog_revokeerrorbutton: string = null;
  updatesubscriptionsstatusdialog_revokeerrortitle: string = null;
  updatesubscriptionsstatusdialog_successbody: string = null;
  updatesubscriptionsstatusdialog_successbutton: string = null;
  updatesubscriptionsstatusdialog_successtitle: string = null;
  uploadfileerrordialog_body: string = null;
  uploadfileerrordialog_primarybutton: string = null;
  uploadfileerrordialog_title: string = null;
  videoviewerdialog_body: string = null;
  videoviewerdialog_primarybutton: string = null;
  zipdialog_abort: string = null;
  zipdialog_abortbutton: string = null;
  zipdialog_aborttitle: string = null;
  zipdialog_downloadallfilesbody: string = null;
  zipdialog_downloadallfilesprimarybutton: string = null;
  zipdialog_downloadallfilessecondarybutton: string = null;
  zipdialog_downloadallfilestitle: string = null;
  zipdialog_partialdownload: string = null;
  zipdialog_partialdownloadbutton: string = null;
  zipdialog_partialdownloadnofiles: string = null;
  zipdialog_partialdownloadtitle: string = null;
  zipdialog_processingdownloadallfilesbodyitems: string = null;
  zipdialog_processingdownloadallfilesbodysidedrawer: string = null;
  zipdialog_processingdownloadallfilesbodystatus1: string = null;
  zipdialog_processingdownloadallfilesbodystatus2: string = null;
  zipdialog_processingdownloadallfilesbodystatus3: string = null;
  zipdialog_processingdownloadallfilesprimarybutton: string = null;
  zipdialog_processingdownloadallfilesprogress: string = null;
  zipdialog_processingdownloadallfilessecondarybutton: string = null;
  zipdialog_processingdownloadallfilestitle: string = null;
  zipdialog_success: string = null;
  zipdialog_successbutton: string = null;
  zipdialog_successtitle: string = null;
  zipheaderbutton_downloadallfiles: string = null;
  relatedrecordssection_emptystate: string = null;
  landingmaininforequestlist_emptystate: string = null;
  recordslistview_emptystate: string = null;
  recordformfilestab_emptystate: string = null;
  filehistorysection_emptystate: string = null;
  landingtimelinemainview_emptystate: string = null;
  timelinelistview_emptystate: string = null;
  paymentmethodsview_emptystate: string = null;
  recordformsignaturetab_emptystate: string = null;
  reminderdeletedialog_title: string = null;
  reminderdeletedialog_body: string = null;
  reminderdeletedialog_secondarybutton: string = null;
  reminderdeletedialog_primarybutton: string = null;
  // TODO NEW VARS
  globalparams_warningicon: string = null;
  creditcard_deletebutton: string = null;
  creditcard_favtooltip: string = null;
  creditcard_holdername: string = null;
  creditcard_expirydate: string = null;
  globalparams_subscriptionsicon: string = null;
  creditcard_faviconoff: string = null;
  creditcard_faviconon: string = null;
  reminderslistallview_breadcrumb: string = null;
  networksformview_createpagetitle: string = null;
  networksformview_createbreadcrumb: string = null;
  networkformview_updatepagetitle: string = null;
  networkformview_updatebreadcrumb: string = null;
  networksformrecordstab_description: string = null;
  globalparams_selectall: string = null;
  recordsviewview_pagetitle: string = null;
  recordsformview_pagetitle: string = null;
  landingmainview_recordstitle: string = null;
  landingmainview_recordsicon: string = null;
  landingmainview_recordstooltip: string = null;
  landingmainrecordslist_viewall: string = null;
  landingmainrecordslist_emptystate: string = null;
  recordsviewview_reminderslisttitle: string = null;
  recordsviewview_reminderslisttooltip: string = null;
  recordsviewview_reminderslistaddbuttontooltip: string = null;
  recordsviewview_reminderslistviewall: string = null;
  recordformreminderstab_addreminderbuttontooltip: string = null;
  sortplansdialog_title: string = null;
  comingsoondialog_title: string = null;
  comingsoondialog_body: string = null;
  comingsoondialog_primarybutton: string = null;
  addremovelicensesdialog_title: string = null;
  addremovelicensesdialog_minusicon: string = null;
  addremovelicensesdialog_plusicon: string = null;
  addremovelicensesdialog_addtenlicense: string = null;
  plandetailsview_breadcrumb: string = null;
  plandetailsview_pagetitle: string = null;
  plansdetailscontentheader_actionmenutooltip: string = null;
  plansdetailscontentheader_actionmenuoptionshare: string = null;
  searchresultview_title: string = null;
  searchresultview_breadcrumb: string = null;
  searchresultview_pagetitle: string = null;
  recordspermissionviewerdialog_title: string = null;
  recordspermissionviewerdialog_body: string = null;
  recordspermissionviewerdialog_primarybutton: string = null;
  globalparams_defaultvcardimage: string = null;
  singlefilerequestview_addbutton: string = null;
  deletecreditcarddialog_title: string = null;
  deletecreditcarddialog_body: string = null;
  deletecreditcarddialog_primaryrybutton: string = null;
  deletecreditcarddialog_secondarybutton: string = null;
  addnewcardform_addnewcardcvcerror: string = null;
  addnewcardform_addnewcardexpirationdateerror: string = null;
  sdplaceofresidence_stateplaceholder: string = null;
  sdplaceofresidence_cityplaceholder: string = null;
  sdplaceofresidence_countryplaceholder: string = null;
  addnewcardform_description: string = null;
  addnewcardform_googleplacefieldplaceholder: string = null;
  addnewcardform_streetplaceholder: string = null;
  addnewcardform_street2placeholder: string = null;
  addnewcardform_postalcodeplaceholder: string = null;
  addnewcardform_cityplaceholder: string = null;
  addnewcardform_stateplaceholder: string = null;
  addnewcardform_contryplaceholder: string = null;
  accountcreateform_cityplaceholder: string = null;
  accountcreateform_stateplaceholder: string = null;
  accountcreateform_countryplaceholder: string = null;
  personalinformationform_cityplaceholder: string = null;
  personalinformationform_countryplaceholder: string = null;
  personalinformationform_stateplaceholder: string = null;
  personalinformationform_streetplaceholder: string = null;
  personalinformationform_street2placeholder: string = null;
  personalinformationform_postalcodeplaceholder: string = null;
  personalinformationform_description: string = null;
  personalinformationform_googleplaceplaceholder: string = null;
  sdfillabletablecolumncell_fulladdress: string = null;
  planfieldtypelistitem_description: string = null;
  customfieldssectionitems_description: string = null;
  sdfillabletable_addemptyrow: string = null;
  deleterecorddialog_title: string = null;
  deleterecorddialog_body: string = null;
  deleterecorddialog_primarybutton: string = null;
  deleterecorddialog_secondarybutton: string = null;
  customfieldssectionitems_manualentrydescription: string = null;
  customfieldssectionitems_googleplaceplaceholder: string = null;
  globalparams_multisdrolelabel: string = null;
  sdplaceofresidence_googleplacedescription: string = null;
  networkrelationshipdialog_title: string = null;
  networkrelationshipdialog_body: string = null;
  networkrelationshipdialog_relationshipplaceholder: string = null;
  networkrelationshipdialog_secondarybutton: string = null;
  networkrelationshipdialog_primarybutton: string = null;
  networkrelationshipdialog_successtitle: string = null;
  networkrelationshipdialog_successbody: string = null;
  networkrelationshipdialog_successprimarybutton: string = null;
  networkrelationshipdialog_errortitle: string = null;
  networkrelationshipdialog_errorbody: string = null;
  networkrelationshipdialog_errorprimarybutton: string = null;
  syncfromclouddialog_description: string = null;
  syncfromclouddialog_primarybutton: string = null;
  networklistitem_actionmenuoptionremoveallpermissions: string = null;
  sdheader_actionmenuoptionlink: string = null;
  plansdetailscontentheader_actionmenuoptionlink: string = null;
  sdrecordtilebutton_actionmenuoptionlink: string = null;
  recorditemheader_actionmenuoptioncopy: string = null;
  recorditemheader_actionmenutooltip: string = null;
  filehistorysectionitem_editnametooltip: string = null;
  filerenamedialog_title: string = null;
  filerenamedialog_personalizednameplaceholder: string = null;
  filerenamedialog_personalizednametooltip: string = null;
  filehistorysectionitem_actionmenuoptioneditname: string = null;
  zipdialog_processingdownloadallfilesdescription: string = null;
  licensesassignsidedrawerdialog_title: string = null;
  licensesassignsidedrawerdialog_description: string = null;
  licensesassignsidedrawerdialog_legal: string = null;
  licensesassignsidedrawerdialog_sdlist: string = null;
  licensesassignsidedrawerdialog_sdlistassigned: string = null;
  licensesassignsidedrawerdialog_emptystate: string = null;
  licensesassignsidedrawerdialog_sdselected: string = null;
  licensesassignsidedrawerdialog_sdlistunassigned: string = null;
  licensesassignsidedrawerdialog_assignlicense: string = null;
  licensesassignsidedrawerdialog_restorelicense: string = null;
  licensesassignsidedrawerdialog_assignerror: string = null;
  licensesassignsidedrawerdialog_submitbutton: string = null;
  licensescreatesidedrawerdialog_title: string = null;
  licensescreatesidedrawerdialog_description: string = null;
  licensescreatesidedrawerdialog_sdnametitle: string = null;
  licensescreatesidedrawerdialog_sdnameplaceholder: string = null;
  licensescreatesidedrawerdialog_sponsorsddescription: string = null;
  licensescreatesidedrawerdialog_sponsorsdtooltip: string = null;
  licensescreatesidedrawerdialog_emailplaceholder: string = null;
  licensescreatesidedrawerdialog_emailtooltip: string = null;
  licensescreatesidedrawerdialog_fullnameplaceholder: string = null;
  licensescreatesidedrawerdialog_submitbutton: string = null;
  transferownershipview_title: string = null;
  transferownershipview_description: string = null;
  transferownershipview_contactemail: string = null;
  transferownershipview_contactfirstname: string = null;
  transferownershipview_contactlastname: string = null;
  transferownershipview_inheritsettingsdescription: string = null;
  transferownershipview_inheritsettingsrelationship: string = null;
  transferownershipview_submitbutton: string = null;
  transferownershipview_breadcrumb: string = null;
  addnewcardform_addnewcardmonthplaceholder: string = null;
  addnewcardform_addnewcardyearplaceholder: string = null;
  globalparams_indicatoricon: string = null;
  licenseslistitem_actionmenuoptionbuy: string = null;
  addremovelicensesdialog_description: string = null;
  licenseslistitem_nolicenseavailable: string = null;
  licenseslistitem_buynow: string = null;
  recordnetworklistitem_actionmenuoptioncopy: string = null;
  recordteamnetworklistitem_actionmenuoptioncopy: string = null;
  envelopelistitem_actionmenuoptioncopy: string = null;
  reminderslistitem_actionmenuoptioncopy: string = null;
  planlistitem_actionmenutooltip: string = null;
  planlistitem_actionmenuoptionlink: string = null;
  recordslistitem_actionmenuoptioncopy: string = null;
  sdcontentheader_actionmenuoptionshare: string = null;
  sdcontentheader_actionmenuoptionlink: string = null;
  sdcontentheader_actionmenutooltip: string = null;
  planlistitem_actionmenuoptionview: string = null;
  planlistitem_actionmenuoptionshare: string = null;
  reenrollmfadialog_title: string = null;
  reenrollmfadialog_errortitle: string = null;
  reenrollmfadialog_description: string = null;
  reenrollmfadialog_errorbody: string = null;
  reenrollmfadialog_errorprimarybutton: string = null;
  reenrollmfadialog_primarybutton: string = null;
  // TODO for network permissions
  networkpermissionsrowsettingsdialog_title = null;
  networkpermissionsrowsettingsdialog_expirationdateplaceholder = null;
  networkpermissionsrowsettingsdialog_expirationdateremove = null;
  networkpermissionsrowsettingsdialog_expirationdateerror = null;
  globalparams_calendarcheckicon = null;
  globalparams_calendarplusicon = null;
  copymovefiledialog_copytitle: string = null;
  copymovefiledialog_movetitle: string = null;
  copymovefiledialog_copyorigindescription: string = null;
  copymovefiledialog_moveorigindescription: string = null;
  copymovefiledialog_copydestinationdescription: string = null;
  copymovefiledialog_movedestinationdescription: string = null;
  copymovefiledialog_recordplaceholder: string = null;
  copymovefiledialog_toggledescription: string = null;
  copymovefiledialog_toggletooltip: string = null;
  copymovefilesuccessdialog_copytitle: string = null;
  copymovefilesuccessdialog_movetitle: string = null;
  copymovefilesuccessdialog_copydescription: string = null;
  copymovefilesuccessdialog_movedescription: string = null;
  filehistorysectionitem_actionmenucopy: string = null;
  filehistorysectionitem_actionmenumove: string = null;
  globalparams_search: string = null;
  globalparams_download: string = null;
  networkformrow_delete: string = null;
  // SF APP
  sfforbiddenview_title: string = null;
  sfforbiddenview_body: string = null;
  sfnotfoundview_title: string = null;
  sfnotfoundview_body: string = null;
  generalinfosection_emptystate: string = null;
  globalparams_chevronup: string = null;
  globalparams_chevrondown: string = null;
  globalparams_chevronleft: string = null;
  globalparams_chevronright: string = null;
  sddatepicker_opencalendar: string = null;
  sddatepicker_close: string = null;
  sdvcarddropdowndialog_close: string = null;
  networkformrow_addcontributor: string = null;
  networkformrow_chevronoptionalup: string = null;
  networkformrow_chevronoptionaldown: string = null;
  syncfromclouddialog_previousfolder: string = null;
  sidedrawerrenamedialog_resetname: string = null;
  copymoverecordsuccessdialog_copytitle: string = null;
  copymoverecordsuccessdialog_movetitle: string = null;
  copymoverecordsuccessdialog_copydescription: string = null;
  copymoverecordsuccessdialog_movedescription: string = null;
  copymoverecorddialog_toggletooltip: string = null;
  copymoverecorddialog_toggledescription: string = null;
  copymoverecorddialog_recordsubtypeplaceholder: string = null;
  copymoverecorddialog_recordtypeplaceholder: string = null;
  copymoverecorddialog_emptystate: string = null;
  copymoverecorddialog_searchplaceholder: string = null;
  copymoverecorddialog_movedestinationdescription: string = null;
  copymoverecorddialog_copydestinationdescription: string = null;
  copymoverecorddialog_copydestinationdescriptiontooltip: string = null;
  copymoverecorddialog_movedestinationdescriptiontooltip: string = null;
  copymoverecorddialog_moveorigindescription: string = null;
  copymoverecorddialog_copyorigindescription: string = null;
  planlistitem_beginnowdescription: string = null;
  sdnavbarsearch_closesearch: string = null;
  recordstypeswitcher_menutitle: string = null;
  queuewindow_minimize: string = null;
  queuewindow_maximaze: string = null;
  globalparams_document: string = null;
  tdfooter_privacyandsecurity: string = null;
  tdfooter_termsandconditions: string = null;
  tdfooter_accessibility: string = null;
  tdfooter_about: string = null;
  tdnavbar_title: string = null;
  tdnavbar_about: string = null;
  tdnavbar_faq: string = null;
  tdnavbar_faqdescription: string = null;
  filesizezerowarningdialog_title: string = null;
  filesizezerowarningdialog_body: string = null;
  filesizezerowarningdialog_primarybutton: string = null;
  tdnavbar_learn: string = null;
  recordspermissionalert_errorlicenselimittitle: string = null;
  recordspermissionalert_errorlicenselimitbody: string = null;
  recordspermissionalert_errorlicenselimitbutton: string = null;
  recordspermissionalert_errornolicensetitle: string = null;
  recordspermissionalert_errornolicensebody: string = null;
  recordspermissionalert_errornolicensebutton: string = null;
  //new toggle for sfr and plans
  landingblocklist_showcompletedplanstooltip: string = null;
  plansmainview_showcompletedplanstooltip: string = null;
  landingmainview_planrequestallcompletedmessage: string = null;
  plansmainview_showcompletedlabel: string = null;
  reminderview_breadcrumb: string = null;
  remindersform_sidedrawerplaceholder = null;
  reminderview_pagetitle: string = null;
  tdforbiddenerrorview_title: string = null;
  tdforbiddenerrorview_body: string = null;
  tdforbiddenerrorview_primarybutton: string = null;
  tdforbiddenerrorview_pagetitle: string = null;
  tdnoaccounterrorview_title: string = null;
  tdnoaccounterrorview_body: string = null;
  tdnoaccounterrorview_primarybutton: string = null;
  tdnoaccounterrorview_pagetitle: string = null;
  inforequestreportdialog_title: string = null;
  inforequestreportdialog_typeplaceholder: string = null;
  inforequestreportdialog_nameplaceholder: string = null;
  inforequestreportdialog_nametooltip: string = null;
  tdvcarddropdowncontainer_title: string = null;
  globalparams_teamcontroller: string = null;
  globalparams_timecontroller: string = null;
  globalparams_emailcontroller: string = null;
  globalparams_subtypecontroller: string = null;
  globalparams_enddatecontroller: string = null;
  globalparams_resetselection: string = null;
  globalparams_selectionerror: string = null;
  sdprogressspinner_progress: string = null;
  sdprogressbar_progress: string = null;
  globalparams_resultof: string = null;
  tdfooter_privacyandsecuritylink: string = null;
  tdfooter_termsandconditionslink: string = null;
  tdfooter_accessibilitylink: string = null;
  tdfooter_aboutlink: string = null;
  singlefilerequestform_addstarttimedescription: string = null; // TODO dictionary
  singlefilerequestform_addstarttimedescriptiontooltip: string = null; // TODO dictionary
  remindersform_addstarttimedescription: string = null; // TODO dictionary
  remindersform_addstarttimedescriptiontooltip: string = null; // TODO dictionary
  //Mobile
  mobilemyaccounttemplate_skiptocontext: string = null;
  mobileactivebiometricsview_title: string = null;
  mobileactivebiometricsview_description: string = null;
  mobileactivebiometricsview_primarybutton: string = null;
  mobileactivebiometricsview_secondarybutton: string = null;
  mobilemyaccounttemplate_title: string = null;
  mobilewebfeatureview_emptystate: string = null;
  activeRecordTypeName: string = null;
  mobileawebfeatureview_icon: string = null;
  mobileawebfeatureview_iconlabel: string = null;
  mobileawebfeatureview_title: string = null;
  mobileawebfeatureview_description: string = null;
  mobileawebfeatureview_primarybutton: string = null;
  forceupdate_iconlabel: string = null;
  forceupdate_icon: string = null;
  forceupdate_title: string = null;
  forceupdate_description: string = null;
  forceupdate_primarybutton: string = null;
  forceupdate_secondarybutton: string = null;
  globalparams_biometricsicon: string = null;
  globalparams_downloadsuccess: string = null;
  globalparams_downloadfails: string = null;
  myaccountmainmenu_biometricsiconon: string = null;
  myaccountmainmenu_biometricsiconoff: string = null;
  mobileactivebiometricsview_icon: string = null;
  myaccountmainmenu_biometricssettingstitle: string = null;
  myaccountbiometricsview_breadcrumb: string = null;
  myaccountbiometricsview_pagetitle: string = null;
  myaccountbiometricsform_title: string = null;
  myaccountbiometricsform_description: string = null;
  myaccountbiometricsform_conditiondescription: string = null;
  myaccountbiometricsform_emptystate: string = null;
  recordslistitem_actionmenuoptionunlink: string = null; // TODO add to dictionary
  recordslistitem_actionmenuoptionupload: string = null; // TODO add to dictionary
  // APPLY IR AND CONTENT PACKAGE
  tenantsfeaturesmaintemplate_emptystate: string = null;
  tenantsfeaturesmaintemplate_emptystateicon: string = null;
  mymainmenu_applyinforequest: string = null;
  mymainmenu_applycontentpackage: string = null;
  applyinforequestview_pagetittle: string = null;
  applyinforequestview_breadcrumb: string = null;
  applyinforequestview_title: string = null;
  applyinforequestview_selectplandescription: string = null;
  applyinforequestview_selectplantooltip: string = null;
  applyinforequestview_selectplanplaceholder: string = null;
  applyinforequestview_listdescription: string = null;
  applyinforequestview_listtooltip: string = null;
  applycontentpackageview_pagetittle: string = null;
  applycontentpackageview_breadcrumb: string = null;
  tenantselector_placeholder: string = null;
  planlistitem_actionmenuoptiondelete: string = null;
  removeinforequestdialog_title: string = null;
  removeinforequestdialog_body: string = null;
  removeinforequestdialog_secondarybutton: string = null;
  removeinforequestdialog_primarybutton: string = null;
  sharefileview_pagetittle: string = null;
  sharefileview_breadcrumb: string = null;
  sharefileview_title: string = null;
  sharefileview_networktitle: string = null;
  sharefileview_networkcustomtooltip: string = null;
  sharefileview_networkplaceholder: string = null;
  sharefileview_networkaddbuttonlabel: string = null;
  sharefileview_remindermessageplaceholder: string = null;
  sharefileview_reminderaddstarttimedescription: string = null;
  sharefileview_reminderaddstarttimedescriptiontooltip: string = null;
  sharefileview_reminderstartdateplaceholder: string = null;
  sharefileview_reminderstartdatedescription: string = null;
  sharefileview_recordsubtypetooltip: string = null;
  sharefileview_recordsubtypeplaceholder: string = null;
  sharefileview_personalizednameplaceholder: string = null;
  sharefileview_personalizednametooltip: string = null;
  applyinforequestview_actionmenuoptiondelete: string = null;
  forbiddenview_title: string = null;
  forbiddenview_body: string = null;
  applyinforequestview_emptystateicon: string = null;
  applyinforequestview_emptystate: string = null;

  //A11y
  recordtypeautocomplete_recordtypelabel: string = null;
  recordstilegrid_filtertilestogglelabel: string = null;
  sortrecordsdialog_sortbynewestfirst: string = null;
  sortrecordsdialog_sortbyoldestfirst: string = null;
  filehistorysection_filesselected: string = null;
  filehistorysection_downloadselectedfiles: string = null;
  filehistorysection_headerfilesoptionscloseicon: string = null;
  filehistorysection_downloadselectedfilesicon: string = null;
  filehistorysection_headerfileoptionstooltip: string = null;
  filehistorysection_headerfileoptionsicon: string = null;
  filehistorysection_downloadselectedfilestooltip: string = null;
  filehistorysection_headerfilesoptionsclosetooltip: string = null;
  // TODO dictioanry add for delete selected files
  filehistorysection_deleteselectedfiles: string = null;
  filehistorysection_deleteselectedfilestooltip: string = null;
  filehistorysection_confirmdeletefilestitle: string = null;
  filehistorysection_confirmdeletefilesdescription: string = null;
  queueitem_downloadfileicon: string = null;
  queueitem_deletefileicon: string = null;
}
