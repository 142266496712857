import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { Observable } from 'rxjs';
import {
  PaymentMethodSpinnerChange,
  PaymentMethodsRequested,
} from '../store/payment.actions';
import { PaymentMethod } from '../models/payment-method.model';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { PaymentMethodDto } from '../models/payment-method.dto';
import { authHeadersSelector } from 'src/app/auth/store/auth.selectors';

@Injectable()
export class PaymentMethodsService {
  private subscriptionApi = environment.subscriptionApi;

  constructor(
    private readonly http: HttpClient,
    private readonly store: Store<AppState>
  ) {}

  getAllPaymentMethods(customerId: string): Observable<PaymentMethod[]> {
    return this.http.get<PaymentMethod[]>(
      this.subscriptionApi +
        `customers/customer-id/${customerId}/payment-methods`,
      {
        headers: this.store.selectSignal(authHeadersSelector)(),
      }
    );
  }

  setPaymentMethod(
    customerId: string,
    paymentMethodId: string
  ): Observable<boolean> {
    this.store.dispatch(new PaymentMethodSpinnerChange({ state: true }));
    return this.http
      .put<any>(
        this.subscriptionApi +
          `customers/customer-id/${customerId}/payment-methods/payment-method-id/${paymentMethodId}`,
        {},
        {
          headers: this.store.selectSignal(authHeadersSelector)(),
        }
      )
      .pipe(
        map(() => {
          this.store.dispatch(new PaymentMethodsRequested());
          return true;
        })
      );
  }

  deletePaymentMethod(
    customerId: string,
    paymentMethodId: string
  ): Observable<boolean> {
    this.store.dispatch(new PaymentMethodSpinnerChange({ state: true }));
    return this.http
      .delete<any>(
        this.subscriptionApi +
          `customers/customer-id/${customerId}/payment-methods/payment-method-id/${paymentMethodId}`,
        {
          headers: this.store.selectSignal(authHeadersSelector)(),
        }
      )
      .pipe(
        map(() => {
          this.store.dispatch(new PaymentMethodsRequested());
          return true;
        })
      );
  }

  addPaymentMethod(
    customerId: string,
    paymentMethod: PaymentMethodDto
  ): Observable<unknown> {
    return this.http.post(
      this.subscriptionApi +
        `customers/customer-id/${customerId}/payment-methods`,
      { ...paymentMethod },
      {
        headers: this.store.selectSignal(authHeadersSelector)(),
      }
    );
  }

  createCustomerId(name: string): Observable<{ id: string }> {
    return this.http.post<{ id: string }>(
      this.subscriptionApi + `customers`,
      { name },
      {
        headers: this.store.selectSignal(authHeadersSelector)(),
      }
    );
  }
}
