import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { SdColorPalette } from '../../../../core/models/enums/sd-color-palette-enum';
import { SdAccessibilitySetting } from '../../../../core/models/sd-accessibility-model';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { SdLinkMenuItem } from '../../models/sd-link-menu-item.model';
import { SdLinkMenuButtonType } from '../../models/enums/sd-link-menu-button-type-enum';
import { SdLinkMenuLinkType } from '../../models/enums/sd-link-menu-link-type-enum';
import { environment } from '../../../../../environments/environment';
import { SideDrawerNetwork } from '../../../../sidedrawer-networks/models/side-drawer-network.model';
import { SdLinkMenuType } from '../../models/enums/sd-link-menu-type-enum';
import { SdInputA11yTemplates } from 'src/app/shared/sd-input-a11y/models/sd-input-a11y-templates.enum';
import { UntypedFormControl } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { AppState } from 'src/app/reducers';
import {
  debounceTime,
  delay,
  EMPTY,
  filter,
  map,
  of,
  Subscription,
  take,
  tap,
} from 'rxjs';
import {
  SearchSideDrawerListActionsTypes,
  SearchSideDrawerListPageLoaded,
  SearchSideDrawerListRequested,
  SearchSideDrawerListReset,
} from 'src/app/search/store/search-sidedrawer-list.actions';
import { SideDrawerNetworksInfiniteListRequested } from 'src/app/sidedrawer-networks/store/side-drawer-networks-requests-infinite-list.actions';
import { HasMoreModel } from '../../models/enums/sd-has-more.model';
import { HasMoreEnum } from '../../models/enums/sd-has-more.enum';
import { Actions, ofType } from '@ngrx/effects';
import { dictionarySelector } from 'src/app/dictionary/store/dictionary.selectors';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { SdIconButtonA11yComponent } from 'src/app/shared/sd-icon-button-a11y/components/sd-icon-button-a11y/sd-icon-button-a11y.component';
import { MatTooltip } from '@angular/material/tooltip';
import { SdSvgA11yComponent } from 'src/app/shared/sd-svg-a11y/components/sd-svg-a11y/sd-svg-a11y.component';
import { SdLinkMenuItemA11yComponent } from '../../shared/sd-link-menu-item-a11y/sd-link-menu-item-a11y.component';

@Component({
  selector: 'app-sd-link-menu-a11y',
  templateUrl: './sd-link-menu-a11y.component.html',
  styleUrls: ['./sd-link-menu-a11y.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SdLinkMenuA11yComponent implements OnInit, OnDestroy {
  @Input() primary: boolean;
  @Input() squareBackground = false;
  @Input() icon: string;
  @Input() iconColor: string;
  @Input() menuTitle: string;
  @Input() tooltip: string;
  @Input() buttonHeight = 2.25;
  @Input() buttonWidth = 2.25;
  @Input() customContentForSdMenu: TemplateRef<unknown>;
  setAccessibility: SdAccessibilitySetting;
  @Input() set accessibility(value: SdAccessibilitySetting) {
    this.setAccessibility = value;
  }
  menuData: SdLinkMenuItem[];
  globalParamMenuOpen: string;
  globalParamLoadingMore: string;
  @Input() menuDataSd: SideDrawerNetwork[];
  @Input() buttonType: SdLinkMenuButtonType = SdLinkMenuButtonType.icon;
  @Input() menuType: SdLinkMenuType = SdLinkMenuType.default;
  @Input() buttonText: string;
  @Input() menuText: string;
  @Input() menuIcon: string;
  @Input() iconWidth = 1;
  @Input() iconHeight = 1;
  @Input() placeholder: string;
  @Input() emptystate: string;
  @Input() border = true;
  @Input() disableRipple = false;
  @Input() transparentBackground: boolean;
  @Input() readOnly: boolean;
  @Input() mainMenu?: boolean;
  @Output() emitterClicked = new EventEmitter<SdLinkMenuItem>();
  @Output() clickOverOptionMenu = new EventEmitter<Event>();
  @Output() switchSideDrawer = new EventEmitter<SideDrawerNetwork>();
  @Output() loadMore = new EventEmitter<HasMoreModel>();
  @Output() menuOpenedEvent = new EventEmitter<boolean>();
  @Output() menuClosedEvent = new EventEmitter<boolean>();
  hasMoreModel: HasMoreModel = {
    hasMore: false,
    type: HasMoreEnum.element,
  };
  progress: boolean;
  subscription = new Subscription();
  sdColorPalette = SdColorPalette;
  sdLinkMenuButtonType = SdLinkMenuButtonType;
  sdLinkMenuLinkType = SdLinkMenuLinkType;
  sdLinkMenuType = SdLinkMenuType;
  cdn = environment.cdn;
  @ViewChild(MatMenuTrigger) menu: MatMenuTrigger;
  @ViewChild(MatTooltip) matTooltip: MatTooltip;
  @ViewChild(SdIconButtonA11yComponent) sdiconbutton: SdIconButtonA11yComponent;
  @ViewChild(SdSvgA11yComponent) svgComponent: SdSvgA11yComponent;
  @ViewChild(SdLinkMenuItemA11yComponent)
  sdLinkMenuItem: SdLinkMenuItemA11yComponent;
  sdInputA11yTemplates = SdInputA11yTemplates;
  searchController = new UntypedFormControl(null);
  searchResultLength: number;
  selected = false;
  search$ = this.searchController.valueChanges.pipe(
    tap(value => {
      if (value?.length === 0) {
        this.store.dispatch(new SearchSideDrawerListReset());
        this.store.dispatch(new SideDrawerNetworksInfiniteListRequested());
      }
    }),
    debounceTime(1000),
    filter(value => value?.length > 0),
    map(() => {
      this.store.dispatch(
        new SearchSideDrawerListRequested({
          name: this.searchController.value,
        })
      );

      this.actions$
        .pipe(
          ofType(
            SearchSideDrawerListActionsTypes.SearchSideDrawerListPageLoaded
          ),
          take(1),
          tap((action: SearchSideDrawerListPageLoaded) => {
            if (
              action.type ===
              SearchSideDrawerListActionsTypes.SearchSideDrawerListPageLoaded
            ) {
              this.searchResultLength =
                action?.payload?.results[0]?.data?.length;
              const resultMessage = `${this.searchResultLength} results were found`;
              this.liveAnnouncer.announce(resultMessage);
              this.setFocusForSideDrawerMenu();
            }
          })
        )
        .subscribe();
    })
  );

  constructor(
    private readonly store: Store<AppState>,
    private readonly liveAnnouncer: LiveAnnouncer,
    private readonly actions$: Actions
  ) {}

  @Input() set setMenuData(value: SdLinkMenuItem[]) {
    this.menuData = value;
  }

  @Input() set setHasMoreModel(value: HasMoreModel) {
    this.hasMoreModel = value;
  }

  @Input() set setProgress(value: boolean) {
    this.progress = value;
  }

  ngOnInit(): void {
    this.subscription.add(this.search$.subscribe());
    this.store
      .pipe(
        select(dictionarySelector),
        take(1),
        tap(dictionary => {
          this.globalParamMenuOpen = dictionary?.globalparams_menuopen;
          this.globalParamLoadingMore = dictionary?.globalparams_loadingmore;
        })
      )
      .subscribe();
  }

  private setFocusForSideDrawerMenu(): void {
    of(EMPTY)
      .pipe(
        take(1),
        delay(300),
        tap(() => {
          const element = document.getElementById(
            'sd-link-menu-a11y-app-sd-input-a11y'
          );
          if (element) {
            element.focus();
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  menuOpened(event?: MatMenu): void {
    if (this.menuType === SdLinkMenuType.sideDrawerMenu) {
      event.panelClass = 'sd-link-menu-a11y-mat-menu-panel';
      this.clearAndResetSdSearch();
      this.setFocusForSideDrawerMenu();
    }
    
    this.liveAnnouncer.announce(this.globalParamMenuOpen);
  }

  loadingMore(): void {
    this.liveAnnouncer.announce(this.globalParamLoadingMore);
  }

  onClosed() {
    if (this.menuType === SdLinkMenuType.sideDrawerMenu) {
      this.clearAndResetSdSearch();
    }
  }

  actualPosition = -1;
  onKeyUp(event: KeyboardEvent): void {
    if (event.key !== 'ArrowDown' && event.key !== 'ArrowUp') {
      if (event.key === 'Enter' && this.actualPosition !== -1) {
        const item = this.menuDataSd[this.actualPosition];
        this.switchSideDrawer.emit(item);
      }
      event.stopPropagation();
      event.preventDefault();
      if (event.key === 'Enter' && this.actualPosition !== 0) {
        if (this.actualPosition > 0) {
          const item = this.menuDataSd[this.actualPosition - 1];
          this.onKeydownEnter(item);
        }
      }
      return;
    }

    const links = document.querySelectorAll('a.sd-menu-item');
    if (this.actualPosition === 0 && event.key === 'ArrowUp') {
      this.actualPosition = -1;
      this.setFocusForSideDrawerMenu();
      return;
    }

    if (this.actualPosition === links.length - 1 && event.key === 'ArrowDown') {
      this.actualPosition = -1;
      this.setFocusForSideDrawerMenu();
      return;
    }

    if (event.key === 'ArrowDown') {
      this.actualPosition++;
      const message = `${this.menuDataSd[this.actualPosition]?.name}
      item ${this.actualPosition + 1} of ${this.menuDataSd.length}`;
      this.liveAnnouncer.announce(message);
    }

    if (event.key === 'ArrowUp') {
      this.actualPosition--;
      const message = `${this.menuDataSd[this.actualPosition]?.name}
      item ${this.actualPosition + 1} of ${this.menuDataSd.length}`;
      this.liveAnnouncer.announce(message);
    }
    const element = links[this.actualPosition] as HTMLAnchorElement;
    links?.forEach(link => {
      link.parentElement.classList.remove('sd-menu-item-selected');
    });
    if (element) {
      element.parentElement.classList.add('sd-menu-item-selected');
    }
    element?.focus();
    if (this.actualPosition > 8) {
      element?.scrollIntoView();
    }
  }

  onEmitterItemAndEvent(event: Event, item): void {
    event.preventDefault();
    this.emitterClicked.emit(item);
    this.clickOverOptionMenu.emit(event);
    this.menu.closeMenu();
  }

  itemSideDrawerTrackBy(
    index: number,
    item: SideDrawerNetwork
  ): SideDrawerNetwork {
    return item;
  }

  private clearAndResetSdSearch(): void {
    this.searchController.reset();
    this.store.dispatch(new SearchSideDrawerListReset());
  }
  public onFocusSvg(): void {
    this.svgComponent.matTooltip.show();
  }

  onFocusIcon(): void {
    this.sdiconbutton.matTooltip.show();
  }

  onKeydownEnter(item?: any): void {
    if (item) {
      this.switchSideDrawer.emit(item);
      this.actualPosition = 0;
      this.menu.closeMenu();
    }
  }

  onClick(event, item: SdLinkMenuItem): void {
    if (this.mainMenu) {
      this.setFocusOnHeaderOrBreadcrumbs(item);
    }
    if (item?.menuLinkType === this.sdLinkMenuLinkType.emitter || item?.menuLinkType === this.sdLinkMenuLinkType.internal) {
      this.onEmitterItemAndEvent(event, item);
      return;
    }
    this.clickOverOptionMenu.emit(event)
  }

  setFocusOnHeaderOrBreadcrumbs(item: SdLinkMenuItem): void {
    this.liveAnnouncer.announce(item?.menuLinkText + ' ' + 'current page');
    const element =
      document.getElementById('edit-button') ??
      document.getElementById('upload-button');
    if (element) {
      element.focus();
      return;
    }
    const firstElementFromBreadcrumb = document.getElementById('breadcrumb');
    firstElementFromBreadcrumb?.focus();
  }
}
