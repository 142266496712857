<div *ngIf="vm$ | async as vm" class="network-form-row">
  <div class="network-form-row-plus-btn-left">
    <app-sd-icon-button-a11y
      [setSdAccessibility]="{
        ariaLabel: 'networkformrow_addcontributor' | dictionary | async,
        role: 'button'
      }"
      (btnClicked)="createRow.emit()"
      [iconColor]="'var(--primaryColor)'"
      [iconHeight]="1.25"
      [iconWidth]="1.25"
      [icon]="cdn + ('globalparams_addicon' | dictionary | async)">
    </app-sd-icon-button-a11y>
  </div>
  <div class="network-form-row-contributor">
    <div>
      <app-sd-link-menu-a11y
        (emitterClicked)="onContributorEmitterClicked($event)"
        [accessibility]="{
          ariaLabel:
            'networkformrow_contributortypemenutooltip' | dictionary | async,
          tabIndex: -1
        }"
        [border]="false"
        [disableRipple]="true"
        [iconColor]="sdColorPalette.primaryAccentColor"
        [iconHeight]="1.25"
        [iconWidth]="1.25"
        [icon]="
          contributorOptionSelected
            ? contributorOptionSelected.avatar
            : vm.contributorOptions[0].avatar
        "
        [setMenuData]="menuContributorOptions"
        [squareBackground]="true"
        [tooltip]="
          'networkformrow_contributortypemenutooltip' | dictionary | async
        "
        [transparentBackground]="true">
      </app-sd-link-menu-a11y>
    </div>
  </div>
  <div class="network-form-row-main-selector">
    <app-network-autocomplete
      *ngIf="contributorController.value === ContributorType.account"
      [controller]="emailController"
      [error]="
        isSubmitted && emailController.hasError('required')
          ? placeHolders?.email +
            ' ' +
            ('globalparams_requirederror' | dictionary | async)
          : emailController['emailFormat']
            ? placeHolders.email +
              ' ' +
              ('globalparams_invalidemailerror' | dictionary | async)
            : ''
      "
      [placeholder]="placeHolders?.email"
      [options]="vm.accountOptions"
      [setSdAccessibility]="{
        ariaLabel: placeHolders?.email,
        id: 'emailController'
      }"
      class="input" />

    <app-sd-selector-a11y
      (selection)="onTeamSelectionChange($event)"
      *ngIf="contributorController.value === ContributorType.team"
      [controller]="teamController"
      [error]="
        isSubmitted
          ? placeHolders?.team +
            ' ' +
            ('globalparams_requirederror' | dictionary | async)
          : ''
      "
      [fullOutput]="true"
      [options]="vm.teamOptions"
      [placeholder]="placeHolders?.team"
      [roundedAvatar]="false"
      [setAccessibility]="{
        ariaLabel: placeHolders?.team,
        id: 'teamController'
      }">
    </app-sd-selector-a11y>
  </div>

  <div class="network-form-row-permission">
    <app-sd-link-menu-a11y
      (emitterClicked)="onPermissionEmitterClicked($event)"
      [accessibility]="{
        ariaLabel: 'networkformrow_permissionsmenutooltip' | dictionary | async,
        tabIndex: -1
      }"
      [border]="false"
      [disableRipple]="true"
      [iconColor]="sdColorPalette.primaryAccentColor"
      [iconHeight]="1.25"
      [iconWidth]="1.25"
      [icon]="
        permissionsOptionSelected
          ? permissionsOptionSelected.avatar
          : vm.permissionsOptions[0].avatar
      "
      [setMenuData]="menuPermissionsOptions"
      [squareBackground]="true"
      [tooltip]="'networkformrow_permissionsmenutooltip' | dictionary | async"
      [transparentBackground]="true">
    </app-sd-link-menu-a11y>
  </div>

  <!-- TODO calendar for expiration date -->
  <div class="network-form-row-calendar">
    <!-- NOTE for record -->
    <app-sd-icon-button-a11y
      *ngIf="entity === DictionaryRoleEntity.record"
      (btnClicked)="onExpireDateClicked()"
      [buttonHeight]="2.5"
      [buttonWidth]="2.5"
      [iconColor]="
        !!expiryDateController.value
          ? sdColorPalette.primaryColor
          : sdColorPalette.secondaryAccentColor
      "
      [iconHeight]="1.25"
      [iconWidth]="1.25"
      [tooltip]="network?.expiryDate ? network?.expiryDate + '' : ''"
      [icon]="
        !!expiryDateController.value
          ? cdn + ('globalparams_calendarcheckicon' | dictionary | async)
          : cdn + ('globalparams_calendarplusicon' | dictionary | async)
      "
      [setSdAccessibility]="{
        tabIndex: 0,
        ariaLabel: 'networkpermissionsrow_calendarbutton' | dictionary | async
      }" />

    <!-- NOTE for sidedrawer -->
    <app-sd-icon-button-a11y
      *ngIf="entity === DictionaryRoleEntity.sidedrawer"
      (btnClicked)="onExpireDateForSideDrawerClicked()"
      [buttonHeight]="2.5"
      [buttonWidth]="2.5"
      [iconColor]="
        !!expiryDateController.value
          ? sdColorPalette.primaryColor
          : sdColorPalette.secondaryAccentColor
      "
      [iconHeight]="1.25"
      [iconWidth]="1.25"
      [tooltip]="network?.expiryDate ? network?.expiryDate + '' : ''"
      [icon]="
        !!expiryDateController.value
          ? cdn + ('globalparams_calendarcheckicon' | dictionary | async)
          : cdn + ('globalparams_calendarplusicon' | dictionary | async)
      "
      [setSdAccessibility]="{
        tabIndex: 0,
        ariaLabel: 'networkpermissionsrow_calendarbutton' | dictionary | async
      }" />
  </div>

  <div class="network-form-row-optionals">
    <app-sd-icon-button-a11y
      [setSdAccessibility]="{
        ariaLabel: showOptionalFields
          ? ('networkformrow_chevronoptionalup' | dictionary | async)
          : ('networkformrow_chevronoptionaldown' | dictionary | async)
      }"
      (btnClicked)="showOptionalFields = !showOptionalFields"
      [iconColor]="'var(--primaryColor)'"
      [iconHeight]="1.1"
      [iconWidth]="1.1"
      [icon]="
        showOptionalFields
          ? cdn + ('globalparams_chevronupicon' | dictionary | async)
          : cdn + ('globalparams_chevrondownicon' | dictionary | async)
      ">
    </app-sd-icon-button-a11y>
  </div>

  <div class="network-form-row-full">
    <div
      *ngIf="
        showOptionalFields &&
        contributorController.value === ContributorType.account
      "
      class="network-form-row-full-name">
      <app-sd-input-a11y
        [controller]="fullNameController"
        [placeholder]="placeHolders?.fullName"
        [setSdAccessibility]="{
          ariaLabel: placeHolders?.fullName,
          id: 'fullNameController'
        }" />
    </div>
    <div *ngIf="showOptionalFields" class="network-form-row-relation">
      <app-sd-network-relationship-form-a11y
        [isSubmitted]="isSubmitted"
        (relationChange)="onRelationChange($event)"
        [placeholder]="placeHolders.relationship" />
    </div>
  </div>
  <div class="network-form-row-delete">
    <app-sd-icon-button-a11y
      (btnClicked)="deleteRow.emit()"
      [disabled]="vm.deleteDisabled"
      [iconColor]="'var(--primaryAccentColor)'"
      [iconHeight]="1.25"
      [iconWidth]="1.25"
      [setSdAccessibility]="{
        ariaLabel: 'networkformrow_delete',
        role: 'button'
      }"
      [icon]="cdn + ('globalparams_deleteicon' | dictionary | async)">
    </app-sd-icon-button-a11y>
  </div>
</div>
