import { Action } from '@ngrx/store';
import { PlansSortOption } from '../models/plans-sort-option.model';
import { Observable } from 'rxjs';

export enum PlansViewsActionsTypes {
  PlansViewsSpinnerChange = '[] PlansViews Spinner Change',
  PlansViewsErrorChange = '[] PlansViews Error Change',
  PlansViewsCleanError = '[] PlansViews Clean Error',
  PlansViewsSortPlanListOptionChange = '[] PlansViews Sort Plan List Option Change',
  PlansDetailsAddQueue = '[]  PlansDetails Add to Queue',
  PlansDetailsClearQueue = '[]  PlansDetails Clear Queue',
  PlansDetailsRemoveQueue = '[]  PlansDetails Remove from Queue',
  PlansDetailsRemoveFirstElementQueue = '[]  PlansDetails Remove first element from Queue',
  PlansViewsDisplayCompletedChange = '[] Plans Views Display Completed Change',
}

export class PlansViewsSpinnerChange implements Action {
  readonly type = PlansViewsActionsTypes.PlansViewsSpinnerChange;

  constructor(public payload: { state: boolean }) {}
}

export class PlansViewsErrorChange implements Action {
  readonly type = PlansViewsActionsTypes.PlansViewsErrorChange;

  constructor(public payload: { actionName: string }) {}
}

export class PlansViewsCleanError implements Action {
  readonly type = PlansViewsActionsTypes.PlansViewsCleanError;
}

export class PlansViewsSortPlanListOptionChange implements Action {
  readonly type = PlansViewsActionsTypes.PlansViewsSortPlanListOptionChange;

  constructor(public payload: { option: PlansSortOption }) {}
}

export class PlansDetailsAddQueue implements Action {
  readonly type = PlansViewsActionsTypes.PlansDetailsAddQueue;

  constructor(public payload: { key: string; value: Observable<unknown> }) {}
}

export class PlansDetailsRemoveQueue implements Action {
  readonly type = PlansViewsActionsTypes.PlansDetailsRemoveQueue;

  constructor(public payload: { key: string }) {}
}

export class PlansDetailsRemoveFirstElementQueue implements Action {
  readonly type = PlansViewsActionsTypes.PlansDetailsRemoveFirstElementQueue;
}

export class PlansDetailsClearQueue implements Action {
  readonly type = PlansViewsActionsTypes.PlansDetailsClearQueue;
}

export class PlansViewsDisplayCompletedChange implements Action {
  readonly type = PlansViewsActionsTypes.PlansViewsDisplayCompletedChange;
  constructor(public payload: { hideCompleted: boolean }) {}
}

export type PlansViewsActions =
  | PlansViewsSpinnerChange
  | PlansViewsErrorChange
  | PlansViewsCleanError
  | PlansViewsSortPlanListOptionChange
  | PlansDetailsAddQueue
  | PlansDetailsClearQueue
  | PlansDetailsRemoveQueue
  | PlansDetailsRemoveFirstElementQueue
  | PlansViewsDisplayCompletedChange;
