<div
  [attr.role]="'alert'"
  [attr.aria-busy]="true"
  aria-valuemax="100"
  aria-valuemin="0">
  <mat-progress-spinner
    [diameter]="envConfigDiameter ?? diameter"
    [strokeWidth]="envConfigStroke ?? strokeWidth"
    [value]="spinnerProgress"
    [attr.aria-label]="
      mode === 'indeterminate'
        ? (sdAccessibility?.ariaLabel ?? '') +
          ' ' +
          ('sdprogressspinner_progress' | dictionary | async)
        : (sdAccessibility?.ariaLabel ?? '') +
          ' ' +
          ('sdprogressspinner_progress' | dictionary | async) +
          ' ' +
          spinnerProgress +
          ' ' +
          '%'
    "
    [mode]="mode"></mat-progress-spinner>
</div>
