<div class="network-relationship-form">
  <form [formGroup]="relationForm">
    <app-sd-autocomplete-a11y
      [controller]="relationController"
      [placeholder]="placeholder"
      [setOptions]="options$ | async"
      [error]="
        isSubmitted ? placeholder + ' ' + ('globalparams_requirederror' | dictionary | async) : ''
      "
      [setSdAccessibility]="{
        ariaLabel: placeholder,
        id: 'relation'
      }"></app-sd-autocomplete-a11y>
  </form>
</div>
