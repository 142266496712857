import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureName, IntegrationState } from './integration.reducer';

export const integrationStateSelector =
  createFeatureSelector<IntegrationState>(featureName);

export const filesToAddSelector = createSelector(
  integrationStateSelector,
  state => state.filesToAdd
);

export const filesToRemoveSelector = createSelector(
  integrationStateSelector,
  state => state.filesToRemove
);

export const foldersToAddSelector = createSelector(
  integrationStateSelector,
  state => state.foldersToAdd
);

export const foldersToRemoveSelector = createSelector(
  integrationStateSelector,
  state => state.foldersToRemove
);
