<ng-template #default [ngIf]="template === sdAutoCompleteA11yEnum.default">
  <mat-form-field [hideRequiredMarker]="true">
    <mat-label>{{ placeholder }}</mat-label>
    <input
      [attr.aria-label]="sdAccessibility?.ariaLabel"
      [attr.aria-disabled]="readonly"
      [attr.aria-readonly]="readonly"
      [formControl]="controller"
      [id]="sdAccessibility?.id"
      [matAutocomplete]="auto"
      [readonly]="readonly"
      matInput
      type="text" />

    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
      <mat-option
        (click)="optionSelected.emit(option)"
        *ngFor="let option of filteredOptions | async"
        [disabled]="option?.disabled"
        [value]="keyMode ? option?.key : option">
        <div class="sd-autocomplete-option">
          <img
            *ngIf="!!option?.avatar && option?.avatar.length > 0"
            [alt]="option?.value"
            [src]="option?.avatar"
            class="sd-autocomplete-option-image" />
          <div *ngIf="!option?.avatar" class="circle-avatar">
            {{ option?.value[0]?.toUpperCase() }}
          </div>
          <div *ngIf="!option?.description" class="sd-autocomplete-option-text">
            <mat-label>{{ option?.value }}</mat-label>
          </div>

          <div
            *ngIf="option?.description"
            class="sd-autocomplete-option-text-container">
            <div
              [attr.aria-label]="option?.value + option?.description"
              [attr.data-description]="option?.description"
              aria-label="option?.value + option?.description"
              class="sd-autocomplete-option-text test">
              <mat-label>{{ option?.value }}</mat-label>
            </div>
          </div>
        </div>
      </mat-option>
    </mat-autocomplete>

    <div class="suffix-container-custom" matSuffix>
      <div *ngIf="spinner || loading" class="suffix-container">
        <app-sd-progress-spinner-a11y></app-sd-progress-spinner-a11y>
      </div>
      <app-sd-tooltip-button-a11y
        *ngIf="!!customTooltip"
        [tooltip]="customTooltip">
      </app-sd-tooltip-button-a11y>
    </div>

    <mat-error *ngIf="controller.invalid">
      <span>{{ error }}</span>
    </mat-error>
  </mat-form-field>
</ng-template>

<ng-template
  #recordSubType
  [ngIf]="template === sdAutoCompleteA11yEnum.recordSubType">
  <mat-form-field [hideRequiredMarker]="true">
    <mat-label>{{ placeholder }}</mat-label>
    <input
      [attr.aria-label]="sdAccessibility?.ariaLabel"
      [attr.aria-readonly]="readonly"
      [formControl]="controller"
      [id]="sdAccessibility?.id"
      [matAutocomplete]="auto"
      [readonly]="readonly"
      matInput
      type="text" />
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
      <mat-option
        (click)="onSelectOptionRecordSubType(option)"
        *ngFor="let option of filteredOptionsRecordSubType | async"
        [value]="option?.displayValue[0]?.value">
        {{ option?.displayValue[0]?.value }}
      </mat-option>
    </mat-autocomplete>
    <app-sd-tooltip-button-a11y
      *ngIf="!!customTooltip"
      [tooltip]="customTooltip"
      matSuffix>
    </app-sd-tooltip-button-a11y>
    <mat-error *ngIf="controller.invalid">
      <span>{{ error }}</span>
    </mat-error>
  </mat-form-field>
</ng-template>

<ng-template
  #recordRealated
  [ngIf]="template === sdAutoCompleteA11yEnum.recordRelated">
  <mat-form-field [hideRequiredMarker]="true">
    <mat-label>{{ placeholder }}</mat-label>
    <input
      [attr.aria-readonly]="readonly"
      [attr.aria-label]="sdAccessibility?.ariaLabel"
      (keydown.enter)="onSelectOptionRecordOnKeyDown()"
      [formControl]="controller"
      [id]="sdAccessibility?.id"
      [matAutocomplete]="auto"
      [readonly]="readonly"
      aria-label="Tags"
      matInput
      type="text" />
    <div
      *ngIf="!!controller.value && controller.value.length > 0"
      class="prefix-container"
      matPrefix></div>
    <div *ngIf="spinner || loading" class="suffix-container" matSuffix>
      <app-sd-progress-spinner-a11y></app-sd-progress-spinner-a11y>
    </div>
    <mat-autocomplete
      #auto="matAutocomplete"
      class="related-record-autocomplete">
      <div>
        <ng-container
          *ngFor="
            let option of filteredOptionsRecord$ | async;
            index as i;
            trackBy: recordTrackBy
          ">
          <mat-option
            (click)="onSelectOptionRecord(option)"
            (keydown.enter)="onSelectOptionRecord(option)"
            [value]="option?.name"
            class="related-record-option">
            <div class="related-records-option">
              <div class="related-records-result-left-container">
                <div class="related-records-option-image">
                  <div class="related-records-option-image-container">
                    <app-sd-svg-a11y
                      [color]="'var(--primaryColor)'"
                      [height]="1"
                      [src]="
                        option?.recordType
                          ? option?.recordType?.logo
                          : iconButtonSvg
                      "
                      [width]="1"></app-sd-svg-a11y>
                  </div>
                </div>
                <div>
                  <h3>{{ option?.name }}</h3>
                  <div class="related-records-list-item-time">
                    <span class="last-modified">{{
                      'globalparams_lastmodifiedby' | dictionary | async
                    }}</span>
                    <strong class="by">{{ option?.lastModifiedBy }}</strong>

                    <span *ngIf="setRecordDataForSentence(option)" class="at">{{
                      'globalparams_lastmodifiedat' | dictionary | async
                    }}</span>
                    <span
                      *ngIf="!setRecordDataForSentence(option)"
                      class="at"
                      >{{
                        'globalparams_lastmodifiedon' | dictionary | async
                      }}</span
                    >
                    <strong class="date">{{
                      option?.updatedAt | sdFormattedDate | async
                    }}</strong>
                  </div>
                </div>
              </div>
              <div class="related-records-list-item-label">
                <span>{{
                  option?.recordSubtypeOther?.length > 0
                    ? option?.recordSubtypeOther
                    : (option?.recordSubtype?.displayValue
                      | displayValue
                      | async)
                }}</span>
              </div>
            </div>
          </mat-option>
        </ng-container>
        <div *ngIf="hasMoreRecords$ | async" class="related-record-footer">
          <app-sd-link-button-a11y
            (click)="onRecordsNextPage()"
            (keydown.enter)="onRecordsNextPage()"
            [tabIndex]="0"
            [template]="sdLinkButtonA11yTemplates.linkFocusable"
            ><span linkFocusable>{{
              'globalparams_loadmore' | dictionary | async
            }}</span></app-sd-link-button-a11y
          >
          <div *ngIf="loading">
            <app-sd-progress-spinner-a11y></app-sd-progress-spinner-a11y>
          </div>
        </div>
      </div>
    </mat-autocomplete>
    <mat-error *ngIf="controller.invalid">
      <span>{{ error }}</span>
    </mat-error>
  </mat-form-field>
</ng-template>

<ng-template
  #inputDisabled
  [ngIf]="template === sdAutoCompleteA11yEnum.inputDisabled">
  <mat-form-field [hideRequiredMarker]="true">
    <mat-label>{{ placeholder }}</mat-label>
    <input
      (keydown.enter)="onSelectOptionCollaboratorOnKeyDown()"
      [attr.aria-label]="sdAccessibility?.ariaLabel"
      [attr.aria-readonly]="readonly"
      [formControl]="controller"
      [id]="sdAccessibility?.id"
      [matAutocomplete]="auto"
      matInput
      type="text"
      appSdAllowEnterTab />
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
      <mat-option
        (click)="onSelectOptionCollaborator(option)"
        (keydown.enter)="onSelectOptionCollaborator(option)"
        *ngFor="let option of filteredOptions | async"
        [disabled]="option?.disabled"
        [value]="keyMode ? option?.key : option">
        <div class="sd-autocomplete-option">
          <img
            *ngIf="!!option?.avatar && option?.avatar.length > 0"
            [alt]="option?.value"
            [src]="option?.avatar"
            class="sd-autocomplete-option-image" />
          <div *ngIf="!option?.avatar" class="circle-avatar">
            {{ option?.value[0]?.toUpperCase() }}
          </div>
          <div *ngIf="!option?.description" class="sd-autocomplete-option-text">
            <mat-label>{{ option?.value }}</mat-label>
          </div>

          <div
            *ngIf="option?.description"
            class="sd-autocomplete-option-text-container">
            <div
              [attr.aria-label]="option?.value + option?.description"
              [attr.data-description]="option?.description"
              aria-label="option?.value + option?.description"
              class="sd-autocomplete-option-text test">
              <mat-label>{{ option?.value }}</mat-label>
            </div>
          </div>
        </div>
      </mat-option>
    </mat-autocomplete>
    <app-sd-tooltip-button-a11y
      *ngIf="!!customTooltip"
      [tooltip]="customTooltip"
      matSuffix>
    </app-sd-tooltip-button-a11y>
    <mat-error *ngIf="controller.invalid">
      <span>{{ error }}</span>
    </mat-error>
  </mat-form-field>
</ng-template>

<ng-template #country [ngIf]="template === sdAutoCompleteA11yEnum.country">
  <mat-form-field [hideRequiredMarker]="true">
    <mat-label>{{ placeholder }}</mat-label>
    <div class="country-selector-prefix" matPrefix>
      <img
        *ngIf="currentFlag.length > 0"
        [alt]="currentFlag"
        [src]="currentFlag"
        width="20" />
    </div>
    <input
      [attr.aria-readonly]="readonly"
      [attr.aria-label]="sdAccessibility?.ariaLabel"
      [formControl]="controller"
      [id]="sdAccessibility?.id"
      [matAutocomplete]="auto"
      [placeholder]="placeholder"
      matInput
      type="text" />
    <mat-autocomplete #auto="matAutocomplete">
      <!-- <mat-option
        *ngFor="let optionCountry of filteredOptionsCountry$ | async"
        [value]="optionCountry.countryName"
        class="country-selector-option">
        <img
          [alt]="optionCountry.countryCode"
          [src]="
            '/assets/flags/' + optionCountry.countryCode.toLowerCase() + '.svg'
          " />
        {{ optionCountry.countryName }}
      </mat-option> -->

      <mat-option
        (click)="optionCountrySelected.emit(option)"
        *ngFor="let option of filteredOptionsCountry$ | async"
        [value]="option?.countryName">
        <div class="sd-autocomplete-option country-selector">
          <img
            class="sd-autocomplete-option-flag"
            [alt]="option?.countryCode"
            [src]="
              '/assets/flags/' + option?.countryCode.toLowerCase() + '.svg'
            " />
          <div class="sd-autocomplete-option-text">
            <mat-label>{{ option?.countryName }}</mat-label>
          </div>
        </div>
      </mat-option>
    </mat-autocomplete>

    <mat-error *ngIf="controller.invalid">
      <span>{{ error }}</span>
    </mat-error>
  </mat-form-field>
</ng-template>

<ng-template
  #recordRealated
  [ngIf]="template === sdAutoCompleteA11yEnum.infiniteList">
  <mat-form-field [hideRequiredMarker]="true">
    <mat-label>{{ placeholder }}</mat-label>
    <input
      [attr.aria-readonly]="readonly"
      (keydown.enter)="onSelectOptionRecordOnKeyDown()"
      [formControl]="controller"
      [id]="sdAccessibility?.id"
      [matAutocomplete]="auto"
      [readonly]="readonly"
      aria-label="Tags"
      matInput
      type="text" />
    <div
      *ngIf="!!controller.value && controller.value.length > 0"
      class="prefix-container"
      matPrefix></div>
    <div *ngIf="spinner || loading" class="suffix-container" matSuffix>
      <app-sd-progress-spinner-a11y></app-sd-progress-spinner-a11y>
    </div>
    <mat-autocomplete
      #auto="matAutocomplete"
      class="related-record-autocomplete">
      <div>
        <ng-container
          *ngFor="
            let option of filteredOptions | async;
            index as i;
            trackBy: displayFn
          ">
          <mat-option
            (click)="optionSelected.emit(option)"
            (keydown.enter)="optionSelected.emit(option)"
            [disabled]="option?.disabled"
            [value]="keyMode ? option?.key : option?.value">
            <div class="sd-autocomplete-option">
              <img
                *ngIf="!!option?.avatar && option?.avatar.length > 0"
                [alt]="option?.value"
                [src]="option?.avatar"
                class="sd-autocomplete-option-image sd-autocomplete-option-not-rounded" />

              <div
                *ngIf="!option?.description"
                class="sd-autocomplete-option-text">
                <mat-label>{{ option?.value }}</mat-label>
              </div>

              <div
                *ngIf="option?.description"
                class="sd-autocomplete-option-text-container">
                <div
                  [attr.aria-label]="option?.value + option?.description"
                  [attr.data-description]="option?.description"
                  aria-label="option?.value + option?.description"
                  class="sd-autocomplete-option-text test">
                  <mat-label>{{ option?.value }}</mat-label>
                </div>
              </div>
            </div>
          </mat-option>
        </ng-container>
        <div *ngIf="hasMore" class="related-record-footer">
          <app-sd-link-button-a11y
            (click)="onRecordsNextPage()"
            (keydown.enter)="onRecordsNextPage()"
            [tabIndex]="0"
            [template]="sdLinkButtonA11yTemplates.linkFocusable"
            ><span linkFocusable>{{
              'globalparams_loadmore' | dictionary | async
            }}</span></app-sd-link-button-a11y
          >
          <div *ngIf="loading">
            <app-sd-progress-spinner-a11y />
          </div>
        </div>
      </div>
    </mat-autocomplete>
    <mat-error *ngIf="controller.invalid">
      <span>{{ error }}</span>
    </mat-error>
  </mat-form-field>
</ng-template>
