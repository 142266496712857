import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { environment } from 'src/environments/environment';
import { AccessibilityHelper } from 'src/app/core/helpers/accessibility.helper';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { SdValidationFormErrors } from 'src/app/account/models/sd-validation-form-errors.model';
import { SdFormHelper } from 'src/app/core/helpers/sd-form.helper';
import { SdColorPalette } from 'src/app/core/models/enums/sd-color-palette-enum';
import { Dictionary } from 'src/app/dictionary/models/dictionary.model';
import { Country } from 'src/app/shared/sd-forms/models/country.model';
import { AppState } from 'src/app/reducers';
import { PrimaryResidence } from 'src/app/account/models/primary-residence.model';
import { dictionarySelector } from 'src/app/dictionary/store/dictionary.selectors';
import { PlaceOfResidenceDialogData } from 'src/app/shared/sd-input-location-a11y/model/place-of-residence-dialog-data.model';
import { DialogTemplateTypes } from 'src/app/shared/templates/enums/templates.enum';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-sd-place-of-residence-dialog-a11y',
  templateUrl: './sd-place-of-residence-dialog-a11y.component.html',
  styleUrls: ['./sd-place-of-residence-dialog-a11y.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlaceOfResidenceDialogA11yComponent implements OnInit {
  dictionary$: Observable<Dictionary>;
  placeOfResidenceForm: UntypedFormGroup;
  cityController = new UntypedFormControl(null);
  stateController = new UntypedFormControl(null);
  countryController = new UntypedFormControl(null);
  searchController = new UntypedFormControl(null);
  streetController = new UntypedFormControl(null);
  apartmentController = new UntypedFormControl(null);
  postalZipController = new UntypedFormControl(null);
  countrySelected: Country;
  spinner: boolean;
  cdn = environment.cdn;
  errors: SdValidationFormErrors;
  sdColorPalette = SdColorPalette;
  isSubmitted: boolean;
  DialogTemplateTypes = DialogTemplateTypes;

  constructor(
    private dialogRef: MatDialogRef<PlaceOfResidenceDialogA11yComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PlaceOfResidenceDialogData,
    private readonly store: Store<AppState>,
    private liveAnnouncer: LiveAnnouncer
  ) {}

  ngOnInit(): void {
    this.dictionary$ = this.store.pipe(select(dictionarySelector));
    this.setForm();
  }

  onCountrySelected(event): void {
    this.countrySelected = event;
  }

  onSubmit(): void {
    this.isSubmitted = true;
    this.placeOfResidenceForm?.markAllAsTouched();
    if (!this.placeOfResidenceForm.valid) {
      this.setErrorForm();
      AccessibilityHelper.announceMessage(
        this.store,
        this.errors,
        this.liveAnnouncer,
        '' // TODO Dictionary add message for error and focus on error
      );
      return;
    }
    if (this.placeOfResidenceForm.valid) {
      this.setPrimaryResidenceDataOnCloseDialog();
    }
  }

  onClose(): void {
    this.dialogRef.close();
  }

  handleAddressChange(address: google.maps.places.PlaceResult): void {
    if (!!address && !!address.address_components) {
      const addressComponents = [...address.address_components];
      this.resetAllControllers();

      addressComponents.forEach(component => {
        if (component.types.includes('subpremise')) {
          this.apartmentController.setValue(component.long_name);
        }
        if (component.types.includes('locality')) {
          this.cityController.setValue(component.long_name);
        }
        if (component.types.includes('administrative_area_level_1')) {
          this.stateController.setValue(component.long_name);
        }
        if (component.types.includes('country')) {
          this.countrySelected = Country.getCountryByCountryCode(
            component.short_name
          );
          this.countryController.setValue(this.countrySelected.countryName);
        }
        if (component.types.includes('postal_code')) {
          this.postalZipController.setValue(component.long_name);
        }
      });
      this.streetController.setValue(
        this.getStreetValue(address.formatted_address)
      );
    }
  }

  getStreetValue(formatted_address: string): string {
    return formatted_address.split(',').shift().split('#').shift();
  }

  resetAllControllers(): void {
    this.streetController.reset();
    this.apartmentController.reset();
    this.cityController.reset();
    this.stateController.reset();
    this.postalZipController.reset();
    this.searchController.reset();
  }

  setRequiredFieldsForFullForm(): void {
    if (this.data && this.data.isFullForm) {
      if (this.data.requiredFields?.street) {
        this.streetController.setValidators([Validators.required]);
      }
      if (this.data.requiredFields?.apartment) {
        this.apartmentController.setValidators([Validators.required]);
      }
      if (this.data.requiredFields?.postalCode) {
        this.postalZipController.setValidators([Validators.required]);
      }
      if (this.data.requiredFields?.city) {
        this.cityController.setValidators([Validators.required]);
      }
      if (this.data.requiredFields?.state) {
        this.stateController.setValidators([Validators.required]);
      }
      if (this.data.requiredFields?.country) {
        this.countryController.setValidators([Validators.required]);
      }
    }
  }

  primaryIfValid(): boolean {
    return this.placeOfResidenceForm.valid;
  }

  private setErrorForm(): void {
    this.errors = {
      countryController: SdFormHelper.getErrorFormControl(
        this.placeOfResidenceForm,
        'countryController',
        'required',
        'globalparams_requirederror'
      ),
      streetController: SdFormHelper.getErrorFormControl(
        this.placeOfResidenceForm,
        'streetController',
        'required',
        'globalparams_requirederror'
      ),
      postalZipController: SdFormHelper.getErrorFormControl(
        this.placeOfResidenceForm,
        'postalZipController',
        'required',
        'globalparams_requirederror'
      ),
      stateController: SdFormHelper.getErrorFormControl(
        this.placeOfResidenceForm,
        'stateController',
        'required',
        'globalparams_requirederror'
      ),
    };
  }

  private setForm() {
    if (this.data.isFullForm) {
      this.placeOfResidenceForm = new UntypedFormGroup({
        cityController: this.cityController,
        stateController: this.stateController,
        countryController: this.countryController,
        streetController: this.streetController,
        apartmentController: this.apartmentController,
        postalZipController: this.postalZipController,
        searchController: this.searchController,
      });
      this.setRequiredFieldsForFullForm();
      if (this.data.primaryResidence) {
        this.setFullViewFormInitialValues();
      }
    }

    if (!this.data.isFullForm) {
      this.placeOfResidenceForm = new UntypedFormGroup({
        cityController: this.cityController,
        stateController: this.stateController,
        countryController: this.countryController,
      });
      this.setRequiredFieldsForForm();
      this.setFormInitialValues();
    }
    if (
      !!this.data.autoCompleteInitialValue &&
      this.data.autoCompleteInitialValue.length > 0
    ) {
      this.searchController.setValue(this.data.autoCompleteInitialValue);
    }
  }

  private setFormInitialValues(): void {
    this.cityController.setValue(this.data.primaryResidence.city);
    this.stateController.setValue(this.data.state);
  }

  private setRequiredFieldsForForm(): void {
    // TODO check this for required fields
    this.countryController.setValidators([Validators.required]);
  }

  private setFullViewFormInitialValues() {
    this.cityController.setValue(this.data?.primaryResidence?.city);
    this.stateController.setValue(this.data?.primaryResidence?.provinceState);
    this.countryController.setValue(
      this.data?.primaryResidence?.country
        ? this.data?.primaryResidence?.country
        : ''
    );
    this.postalZipController.setValue(this.data?.primaryResidence?.postalZip);
    this.streetController.setValue(this.data?.primaryResidence?.streetName);
    this.apartmentController.setValue(this.data?.primaryResidence?.unitNumber);
  }

  private setPrimaryResidenceDataOnCloseDialog() {
    const primaryResidence: PrimaryResidence = new PrimaryResidence();
    primaryResidence.streetName = this.streetController?.value?.trim() ?? null;
    primaryResidence.unitNumber =
      this.apartmentController?.value?.trim() ?? null;
    primaryResidence.city = this.cityController?.value?.trim() ?? null;
    primaryResidence.provinceState =
      this.stateController?.value?.trim() ?? null;
    primaryResidence.country =
      this.countrySelected?.countryName?.trim() ?? null;
    primaryResidence.postalZip =
      this.postalZipController?.value?.trim() ?? null;
    this.dialogRef.close(primaryResidence);
  }

  protected readonly environment = environment;
}
