import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { AsyncPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { EnvelopeListItemStore } from './envelope-list-item.store';
import { EnvelopeStatusPipe } from '../../pipes/envelope-status.pipe';
import { EnvelopeDatePipe } from '../../pipes/envelope-date.pipe';
import { DateLocalePipeModule } from '../../../shared/sd-pipes/date-locale-pipe/date-locale-pipe.module';
import { DictionaryPipeModule } from '../../../dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { environment } from '../../../../environments/environment';
import { EnvelopeRecipientsPipe } from '../../pipes/envelope-recipients.pipe';
import { AvatarBackgroundColorPipe } from '../../../shared/sd-pipes/avatar-background-color/avatar-background-color.pipe';
import { SdProgressSpinnerA11yModule } from '../../../shared/sd-progress-spinner-a11y/sd-progress-spinner-a11y/sd-progress-spinner-a11y.module';
import { SdLinkMenuA11yModule } from '../../../shared/sd-link-menu-a11y/sd-link-menu-a11y.module';
import { SdColorPalette } from '../../../core/models/enums/sd-color-palette-enum';
import { SdLinkMenuLinkType } from '../../../shared/sd-link-menu-a11y/models/enums/sd-link-menu-link-type-enum';
import { MenuItemType } from '../../../shared/sd-header-a11y/models/menu-item.type.enum';
import { SdLinkMenuItem } from 'src/app/shared/sd-link-menu-a11y/models/sd-link-menu-item.model';
import { EmailsToItemListAvatarPipe } from '../../pipes/emails-to-item-list-avatar.pipe';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-envelope-list-item',
  standalone: true,
  imports: [
    EnvelopeStatusPipe,
    EnvelopeDatePipe,
    DateLocalePipeModule,
    DictionaryPipeModule,
    EnvelopeRecipientsPipe,
    AvatarBackgroundColorPipe,
    SdProgressSpinnerA11yModule,
    SdLinkMenuA11yModule,
    AsyncPipe,
    NgForOf,
    NgIf,
    NgClass,
    EmailsToItemListAvatarPipe,
  ],
  templateUrl: './envelope-list-item.component.html',
  styleUrls: ['./envelope-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [EnvelopeListItemStore],
})
export class EnvelopeListItemComponent implements OnInit {
  envelope$ = this.envelopeListItemStore.envelope$;
  gettingInformation$ = this.envelopeListItemStore.gettingInformation$;
  cdn = environment.cdn;
  SdColorPalette = SdColorPalette;
  SdLinkMenuLinkType = SdLinkMenuLinkType;
  MenuItemType = MenuItemType;

  constructor(
    private readonly envelopeListItemStore: EnvelopeListItemStore,
    private readonly route: ActivatedRoute
  ) {}

  @Input() set envelopeId(id: string) {
    this.envelopeListItemStore.getEnvelopeInformation(id);
  }

  ngOnInit(): void {
    const envelopeId = this.route.snapshot?.queryParams?.viewEnvelopeRequested;
    if (envelopeId) {
      this.envelopeListItemStore.onViewEnvelopeDetailsOnInit(envelopeId);
    }
  }

  onViewEnvelope(): void {
    this.envelopeListItemStore.onViewEnvelopeDetails();
  }

  onCopyEnvelopeLink(): void {
    this.envelopeListItemStore.onCopyEnvelopeLink();
  }

  onEmitterClicked(linkMenu: SdLinkMenuItem): void {
    if (linkMenu.key === MenuItemType.view) {
      this.onViewEnvelope();
    }
    if (linkMenu.key === MenuItemType.copy) {
      this.onCopyEnvelopeLink();
    }
  }
}
