import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SdInputLocationA11yComponent } from './components/sd-input-location-a11y/sd-input-location-a11y.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CountrySelectorA11yModule } from '../sd-forms/components/country-selector-a11y/components/country-selector-a11y.module';
import { SdFlatButtonA11yModule } from '../sd-flat-button-a11y/sd-flat-button-a11y.module';
import { SdDialogTemplateA11yModule } from '../templates/dialog-template-a11y/sd-dialog-template-a11y.module';
import { PlaceOfResidenceDialogA11yComponent } from './components/sd-input-location-a11y/shared/sd-place-of-residence-dialog-a11y/sd-place-of-residence-dialog-a11y.component';
import { DictionaryPipeModule } from 'src/app/dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';
import { SdInputA11yComponent } from '../sd-input-a11y/components/sd-input-a11y/sd-input-a11y.component';
import { MatInputModule } from '@angular/material/input';
import { SdIconButtonA11yComponent } from '../sd-icon-button-a11y/components/sd-icon-button-a11y/sd-icon-button-a11y.component';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
  declarations: [
    SdInputLocationA11yComponent,
    PlaceOfResidenceDialogA11yComponent,
  ],
  imports: [
    CommonModule,
    SdInputA11yComponent,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    SdIconButtonA11yComponent,
    CountrySelectorA11yModule,
    SdFlatButtonA11yModule,
    MatFormFieldModule,
    SdDialogTemplateA11yModule,
    DictionaryPipeModule,
    NgxGpAutocompleteModule.forRoot({
      loaderOptions: {
        apiKey: 'AIzaSyAo2ePZHSl1znYra43fETtX4dvlkHTtw88',
        libraries: ['places'],
      },
    }),
  ],
  exports: [SdInputLocationA11yComponent],
})
export class SdInputLocationA11yModule {}
