import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AppState } from '../../reducers';
import { select, Store } from '@ngrx/store';
import { PaginatorService } from '../../core/services/paginator.service';
import {
  GettingSideDrawerNetworksRequestsChange,
  SideDrawerNetworksRequestsActionsTypes,
  SideDrawerNetworksStartFlowLoaded,
  SideDrawerNetworksStartFlowRequested,
} from './side-drawer-networks-requests.actions';
import { forkJoin, mergeMap, of, tap } from 'rxjs';
import {
  accountDataBaseRegionAsDataBaseRegionSelector,
  notAccountDataBaseRegionSelector,
} from '../../account/store/account.selector';
import { catchError, map, take } from 'rxjs/operators';
import { sideDrawerNetworksRequestByDataBaseRegionSelector } from './side-drawer-networks-requests.selectors';
import { SideDrawerNetwork } from '../models/side-drawer-network.model';
import { SideDrawerNetworksHelper } from '../helpers/side-drawer-networks.helper';
import { SideDrawerNetworksRequest } from '../models/side-drawer-network-request.model';
import {
  CheckOwnedAndSharedSdCompleteStep,
  StartFlowStepUpdated,
} from '../../start-flow/store/start-flow.actions';
import { StartFlowStepKeys } from '../../start-flow/models/start-flow-step-keys.model';
import { StartFlowStepStatus } from '../../start-flow/models/start-flow-step-status.model';
import { SideDrawerNetworkLoaded } from './side-drawer-network-list.actions';

@Injectable()
export class SideDrawerNetworksStartFlowEffects {
  otherRegions = false;
  SideDrawerNetworksStartFlowRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SideDrawerNetworksStartFlowRequested>(
        SideDrawerNetworksRequestsActionsTypes.SideDrawerNetworksStartFlowRequested
      ),
      tap(action => {
        this.store.dispatch(
          new GettingSideDrawerNetworksRequestsChange({ state: true })
        );
        if (action.payload?.otherRegions) {
          this.otherRegions = true;
        }
      }),
      tap(() => {
        this.store.dispatch(
          new StartFlowStepUpdated({
            key: StartFlowStepKeys.checkOwnedAndSharedSdStep,
            step: { status: StartFlowStepStatus.processing },
          })
        );
      }),
      mergeMap(action =>
        action.payload?.otherRegions
          ? this.store.pipe(select(notAccountDataBaseRegionSelector), take(1))
          : this.store.pipe(
              select(accountDataBaseRegionAsDataBaseRegionSelector),
              take(1),
              map(dataBaseRegion => [dataBaseRegion])
            )
      ),
      mergeMap(regions => {
        const observables = [];
        regions.forEach(region =>
          observables.push(
            this.store.pipe(
              select(
                sideDrawerNetworksRequestByDataBaseRegionSelector({
                  dataBaseRegion: region?.databaseregion,
                })
              ),
              take(1)
            )
          )
        );
        return observables.length === 0 ? of([]) : forkJoin(observables);
      }),
      map(requests =>
        requests.reduce((acc, request) => acc.concat(request), [])
      ),
      mergeMap((requests: SideDrawerNetworksRequest[]) =>
        (requests.length === 0
          ? of([])
          : forkJoin([
              ...requests.map(request =>
                this.paginatorService
                  .getPaginatedResource<SideDrawerNetwork>(
                    SideDrawerNetworksHelper.getSideDrawerNetworksResourceUrl(
                      request?.requestType,
                      {
                        dataBaseRegion: request?.dataBaseRegion,
                      }
                    )
                  )
                  .pipe(
                    tap(response =>
                      this.store.dispatch(
                        new SideDrawerNetworkLoaded({
                          networks: response?.data,
                        })
                      )
                    ),
                    map(response =>
                      SideDrawerNetworksRequest.fromPaginatedResponse(
                        response,
                        request?.dataBaseRegion,
                        request?.requestType
                      )
                    )
                  )
              ),
            ])
        ).pipe(
          tap(results =>
            this.store.dispatch(
              new SideDrawerNetworksStartFlowLoaded({ results })
            )
          ),
          map(results => {
            if (
              !results.some(result => result.data.length > 0) &&
              !this.otherRegions
            ) {
              return new SideDrawerNetworksStartFlowRequested({
                otherRegions: true,
              });
            }
            return new CheckOwnedAndSharedSdCompleteStep({ error: false });
          }),
          catchError(() =>
            of(new CheckOwnedAndSharedSdCompleteStep({ error: true }))
          ),
          tap(() =>
            this.store.dispatch(
              new GettingSideDrawerNetworksRequestsChange({ state: false })
            )
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private paginatorService: PaginatorService
  ) {}
}
