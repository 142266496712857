import {
  Directive,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';

import { RecordsUploadButtonsSectionSnackbarComponent } from '../../components/records-upload-buttons-section-snackbar/records-upload-buttons-section-snackbar.component';
import { DragDropOverlaySnackBarContainer } from './drag-drop-overlay-container';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';

@Directive({
  selector: '[appDragAndDrop]',
})
export class DragAndDropDirective implements OnDestroy {
  @Output() fileDropped = new EventEmitter<any>();
  @HostBinding('class.file-over') fileOver: boolean;
  @Input() id: string;
  @Input() primaryColor = 'var(--primaryColor)';
  @Input() fileUploadDescription: string;
  @Input() disableDragAndDrop = false;
  enterTarget: any;
  snackBarRef: MatSnackBarRef<any>;

  constructor(
    private _snackBar: MatSnackBar,
    private appOverlayContainer: DragDropOverlaySnackBarContainer
  ) {}

  @HostListener('dragenter', ['$event']) onDragEnter(evt): void {
    if (this.disableDragAndDrop) {
      return;
    }
    if (evt.dataTransfer.types.includes('Files')) {
      evt.preventDefault();
      evt.stopPropagation();
      this.enterTarget = evt.target;
      document.getElementById(this.id)?.classList.remove('hidden');
      if (!this.snackBarRef) {
        setTimeout(() => {
          this.appOverlayContainer.appendToCustomWrapper(
            document.getElementById(this.id)
          );

          this.snackBarRef = this._snackBar.openFromComponent(
            RecordsUploadButtonsSectionSnackbarComponent,
            {
              data: {
                title: this.fileUploadDescription,
                color: this.primaryColor,
              },
              panelClass: ['record-upload-snackbar'],
              verticalPosition: 'top',
              horizontalPosition: 'center',
            }
          );
        }, 100);
      }
    }
  }

  // Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = true;
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event'])
  public onDragLeave(evt): void {
    if (
      evt.dataTransfer.types.includes('Files') &&
      this.enterTarget == evt.target
    ) {
      evt.preventDefault();
      evt.stopPropagation();
      this.clearStyles();
    }
  }

  // Drop listener
  @HostListener('drop', ['$event'])
  public onDrop(evt): void {
    evt.preventDefault();
    evt.stopPropagation();

    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.fileDropped.emit(files);
    }
    this.clearStyles();
  }

  clearStyles(): void {
    this.fileOver = false;
    document.getElementById(this.id)?.classList.add('hidden');
    if (this.snackBarRef) {
      this.snackBarRef.dismiss();
      this.snackBarRef = null;
      setTimeout(() => {
        this.appOverlayContainer.removeFromCustomWrapper();
      }, 100);
    }
  }

  ngOnDestroy(): void {
    this.clearStyles();
  }
}
