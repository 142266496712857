import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SdBreadcrumbsA11yComponent } from './components/sd-breadcrumbs-a11y/sd-breadcrumbs-a11y.component';
import { RouterModule } from '@angular/router';
import { DictionaryPipeModule } from 'src/app/dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { StoreModule } from '@ngrx/store';
import {
  featureNameSdBreadcrumbsA11y,
  sdBreadcrumbsA11yReducer,
} from './store/sd-breadcrumbs-a11y.reducer';
import { EffectsModule } from '@ngrx/effects';
import { SdBreadCrumbsA11yEffects } from './store/sd-breadcrumbs-a11y.effects';

@NgModule({
  declarations: [SdBreadcrumbsA11yComponent],
  imports: [
    CommonModule,
    RouterModule,
    DictionaryPipeModule,
    StoreModule.forFeature(
      featureNameSdBreadcrumbsA11y,
      sdBreadcrumbsA11yReducer
    ),
    EffectsModule.forFeature([SdBreadCrumbsA11yEffects]),
  ],
  exports: [SdBreadcrumbsA11yComponent],
})
export class SdBreadcrumbsA11yModule {}
