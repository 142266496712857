import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../reducers';

import { CloudProvider } from '../../models/cloud-provider.model';
import { IntegrationsService } from '../../services/integrations.service';
import { forkJoin, Observable } from 'rxjs';
import { Dictionary } from '../../../dictionary/models/dictionary.model';
import { dictionarySelector } from '../../../dictionary/store/dictionary.selectors';
import { activeSideDrawerSelector } from '../../../sidedrawer/store/sidedrawer.selector';
import { map, take, tap } from 'rxjs/operators';
import { DriveItem } from '../../models/drive-item.model';
import { environment } from '../../../../environments/environment';
import {
  filesToAddSelector,
  filesToRemoveSelector,
  foldersToAddSelector,
  foldersToRemoveSelector,
} from '../../store/integration.selectors';
import {
  ClearDriveInformation,
  DriveFileItemToAddAdded,
  DriveFileItemToAddRemoved,
  DriveFileItemToRemoveAdded,
  DriveFileItemToRemoveRemoved,
  DriveFolderItemToAddAdded,
  DriveFolderItemToAddRemoved,
  DriveFolderItemToRemoveAdded,
  DriveFolderItemToRemoveRemoved,
} from '../../store/integration.actions';
import { cloudStorageFileListSelector } from '../../../files/store/file-history.selector';
import { FileHistoryRequested } from '../../../files/store/file-history.actions';
import { CloudFoldersFilesRequested } from '../../../files/store/cloud-folders-files.actions';
import { Record } from 'src/app/records/models/record.model';
import { TagSimpleFileRequest } from 'src/app/reminders/models/enums/tag-sfr.enum';
import { SfrWorkflowStatus } from 'src/app/reminders/models/enums/sfr-workflow-status.enum';
import { RecordChangeStatusRequested } from 'src/app/records/store/records-list.actions';
import { CloudElement } from '../../models/cloud-element.model';
import { MatIconModule } from '@angular/material/icon';
import { AsyncPipe, NgIf } from '@angular/common';
import { SdProgressSpinnerA11yModule } from '../../../shared/sd-progress-spinner-a11y/sd-progress-spinner-a11y/sd-progress-spinner-a11y.module';
import {
  CdkFixedSizeVirtualScroll,
  CdkVirtualForOf,
  CdkVirtualScrollViewport,
} from '@angular/cdk/scrolling';
import { SyncFromCloudItemComponent } from '../sync-from-cloud-item/sync-from-cloud-item.component';
import { SdFlatButtonA11yModule } from '../../../shared/sd-flat-button-a11y/sd-flat-button-a11y.module';
import { DictionaryPipeModule } from '../../../dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { SdDialogTemplateA11yModule } from 'src/app/shared/templates/dialog-template-a11y/sd-dialog-template-a11y.module';
import { DialogTemplateTypes } from 'src/app/shared/templates/enums/templates.enum';

@Component({
  selector: 'app-sync-from-cloud-dialog',
  templateUrl: './sync-from-cloud-dialog.component.html',
  styleUrls: ['./sync-from-cloud-dialog.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    NgIf,
    SdProgressSpinnerA11yModule,
    CdkVirtualScrollViewport,
    CdkFixedSizeVirtualScroll,
    SyncFromCloudItemComponent,
    CdkVirtualForOf,
    AsyncPipe,
    SdFlatButtonA11yModule,
    DictionaryPipeModule,
    SdDialogTemplateA11yModule,
  ],
})
export class SyncFromCloudDialogComponent implements OnInit, OnDestroy {
  dictionary$: Observable<Dictionary>;
  sidedrawerId: string;
  spinner = true;
  folders: DriveItem[];
  cdn = environment.cdn;
  providers = CloudProvider;
  buttonSpinner = false;
  filesToAdd$: Observable<DriveItem[]>;
  filesToRemove$: Observable<DriveItem[]>;
  foldersToAdd$: Observable<DriveItem[]>;
  foldersToRemove$: Observable<DriveItem[]>;
  level: DriveItem[] = [];
  buttonDisabled = true;
  dialogTemplateTypes = DialogTemplateTypes;

  constructor(
    private readonly store: Store<AppState>,
    private readonly dialogRef: MatDialogRef<SyncFromCloudDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      record: Record;
      provider: CloudProvider;
    },
    private readonly integrationsService: IntegrationsService
  ) {}

  ngOnInit(): void {
    this.dictionary$ = this.store.pipe(select(dictionarySelector));
    this.filesToAdd$ = this.store.pipe(select(filesToAddSelector), take(1));
    this.filesToRemove$ = this.store.pipe(
      select(filesToRemoveSelector),
      take(1)
    );
    this.foldersToAdd$ = this.store.pipe(select(foldersToAddSelector), take(1));
    this.foldersToRemove$ = this.store.pipe(
      select(foldersToRemoveSelector),
      take(1)
    );
    this.store
      .pipe(
        select(activeSideDrawerSelector),
        take(1),
        tap(sd => {
          this.sidedrawerId = sd.id;
          this.integrationsService
            .getDrives(this.data.provider, this.sidedrawerId)
            .subscribe({
              next: folders => {
                this.folders = folders;
                this.spinner = false;
              },
              error: error => {
                this.dialogRef.close(error);
              },
            });
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ClearDriveInformation());
  }

  onFolderClicked(driveItem: DriveItem): void {
    if (this.level.length === 0) {
      this.getRootContent(driveItem);
    } else {
      this.getContentFolder(driveItem);
    }
  }

  getDriveContent(): void {
    this.level = [];
    this.spinner = true;
    this.integrationsService
      .getDrives(this.data.provider, this.sidedrawerId)
      .subscribe({
        next: folders => {
          this.folders = folders;
          this.spinner = false;
        },
        error: error => {
          this.dialogRef.close(error);
        },
      });
  }

  getRootContent(driveItem: DriveItem, setLevel = true): void {
    if (setLevel) {
      this.level.push(driveItem);
    }
    this.spinner = true;
    this.integrationsService
      .getRootContent(this.data.provider, this.sidedrawerId, driveItem.id)
      .subscribe({
        next: folders => {
          this.folders = folders;
          this.spinner = false;
        },
        error: error => {
          this.dialogRef.close(error);
        },
      });
  }

  getContentFolder(driveItem: DriveItem, setLevel = true): void {
    if (setLevel) {
      this.level.push(driveItem);
    }
    this.spinner = true;
    this.integrationsService
      .getContentFolder(
        this.data.provider,
        this.sidedrawerId,
        this.level[0].id,
        driveItem.id
      )
      .subscribe({
        next: folders => {
          this.folders = folders;
          this.spinner = false;
        },
        error: error => {
          this.dialogRef.close(error);
        },
      });
  }

  getPreviousFolder(): void {
    const previousFolder = this.level[this.level.length - 2];
    this.level.splice(this.level.length - 1, 1);
    if (this.level.length === 1) {
      this.getRootContent(previousFolder, false);
    } else {
      this.getContentFolder(previousFolder, false);
    }
  }

  isSelected(driveItem: DriveItem): Observable<boolean> {
    return forkJoin([
      this.isSynchronized(driveItem),
      this.isDriveToAdd(driveItem),
      this.isDriveToRemove(driveItem),
    ]).pipe(
      map(([isSynchronized, isDriveToAdd, isDriveToRemove]) => {
        if (isSynchronized) {
          return !isDriveToRemove;
        } else {
          return isDriveToAdd;
        }
      })
    );
  }

  onSelect(driveItem: DriveItem): void {
    forkJoin([
      this.isSynchronized(driveItem),
      this.isDriveToAdd(driveItem),
      this.isDriveToRemove(driveItem),
    ])
      .pipe(
        map(([isSynchronized, isDriveToAdd, isDriveToRemove]) => {
          if (driveItem.type === 'file') {
            if (isSynchronized) {
              isDriveToRemove
                ? this.store.dispatch(
                    new DriveFileItemToRemoveRemoved({ id: driveItem.id })
                  )
                : this.store.dispatch(
                    new DriveFileItemToRemoveAdded({ item: driveItem })
                  );
            } else {
              isDriveToAdd
                ? this.store.dispatch(
                    new DriveFileItemToAddRemoved({ id: driveItem.id })
                  )
                : this.store.dispatch(
                    new DriveFileItemToAddAdded({ item: driveItem })
                  );
            }
          }
          if (driveItem.type === CloudElement.folder) {
            if (isSynchronized) {
              isDriveToRemove
                ? this.store.dispatch(
                    new DriveFolderItemToRemoveRemoved({ id: driveItem.id })
                  )
                : this.store.dispatch(
                    new DriveFolderItemToRemoveAdded({ item: driveItem })
                  );
            } else {
              isDriveToAdd
                ? this.store.dispatch(
                    new DriveFolderItemToAddRemoved({ id: driveItem.id })
                  )
                : this.store.dispatch(
                    new DriveFolderItemToAddAdded({ item: driveItem })
                  );
            }
          }
        })
      )
      .subscribe();
  }

  isSynchronized(driveItem: DriveItem): Observable<boolean> {
    return this.store.pipe(
      select(cloudStorageFileListSelector),
      take(1),
      map(cloudFiles => {
        if (driveItem.type === CloudElement.file) {
          return cloudFiles.some(file => file.fileId === driveItem.id);
        }
        if (driveItem.type === CloudElement.folder) {
          return cloudFiles.some(folder => folder.folderId === driveItem.id);
        }
      })
    );
  }

  isDriveToAdd(driveItem: DriveItem): Observable<boolean> {
    return forkJoin([this.filesToAdd$, this.foldersToAdd$]).pipe(
      map(([files, folders]) => {
        if (driveItem.type === CloudElement.file) {
          return !!files.find(file => file.id === driveItem.id);
        }
        if (driveItem.type === CloudElement.folder) {
          return !!folders.find(folder => folder.id === driveItem.id);
        }
      })
    );
  }

  isDriveToRemove(driveItem: DriveItem): Observable<boolean> {
    return forkJoin([this.filesToRemove$, this.foldersToRemove$]).pipe(
      map(([files, folders]) => {
        if (driveItem.type === CloudElement.file) {
          return !!files.find(file => file.id === driveItem.id);
        }
        if (driveItem.type === CloudElement.folder) {
          return !!folders.find(folder => folder.id === driveItem.id);
        }
      })
    );
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onSync(): void {
    this.buttonSpinner = true;
    const observables = {};
    let assignFileToRecordRequested: boolean;

    forkJoin([
      this.filesToAdd$.pipe(
        tap(files => {
          files.forEach(file => {
            assignFileToRecordRequested = true;
            observables[`${file.id}`] =
              this.integrationsService.assignFileToRecord(
                this.data.provider,
                this.sidedrawerId,
                this.data.record.id,
                this.level[0].id,
                file.id
              );
          });
        })
      ),
      this.filesToRemove$.pipe(
        tap(files => {
          files.forEach(file => {
            observables[`${file.id}`] =
              this.integrationsService.removeFileToRecord(
                this.data.provider,
                this.sidedrawerId,
                this.data.record.id,
                this.level[0].id,
                file.id
              );
          });
        })
      ),
      this.foldersToAdd$.pipe(
        tap(folders => {
          folders.forEach(folder => {
            observables[`${folder.id}`] =
              this.integrationsService.assignFolderToRecord(
                this.data.provider,
                this.sidedrawerId,
                this.data?.record?.id,
                this.level[0].id,
                folder.id
              );
          });
        })
      ),
      this.foldersToRemove$.pipe(
        tap(folders => {
          folders.forEach(folder => {
            observables[`${folder.id}`] =
              this.integrationsService.removeFolderToRecord(
                this.data.provider,
                this.sidedrawerId,
                this.data.record.id,
                this.level[0].id,
                folder.id
              );
          });
        })
      ),
    ]).subscribe();
    forkJoin(Object.values(observables))
      .pipe(
        tap({
          next: () => {
            this.buttonSpinner = false;
            this.store.dispatch(
              new FileHistoryRequested({
                sideDrawerId: this.sidedrawerId,
                recordId: this.data.record.id,
              })
            );
            this.store.dispatch(
              new CloudFoldersFilesRequested({
                sideDrawerId: this.sidedrawerId,
                recordId: this.data.record.id,
              })
            );
            if (
              !!assignFileToRecordRequested &&
              this.isSfrRecordValidWorkflow()
            ) {
              this.updateSfrRecordStatus(this.sidedrawerId, this.data.record);
            }
            this.dialogRef.close();
          },
          error: error => {
            this.buttonSpinner = false;
            this.dialogRef.close(error);
          },
        })
      )
      .subscribe();
  }

  syncButtonState(): Observable<boolean> {
    return forkJoin([
      this.filesToRemove$,
      this.foldersToRemove$,
      this.filesToAdd$,
      this.foldersToAdd$,
    ]).pipe(
      map(([filesToRemove, foldersToRemove, filesToAdd, foldersToAdd]) => {
        if (
          filesToRemove.length === 0 &&
          foldersToRemove.length === 0 &&
          filesToAdd.length === 0 &&
          foldersToAdd.length === 0
        ) {
          return true;
        }
        if (
          filesToRemove.length > 0 ||
          foldersToRemove.length > 0 ||
          filesToAdd.length > 0 ||
          foldersToAdd.length > 0
        ) {
          return false;
        }
      })
    );
  }

  private updateSfrRecordStatus(sidedrawerId: string, record: Record): void {
    this.store.dispatch(
      new RecordChangeStatusRequested({
        record: record,
        sideDrawerId: sidedrawerId,
        status: SfrWorkflowStatus.sfrComplete,
      })
    );
  }

  private isSfrRecordValidWorkflow(): boolean {
    return (
      this.data.record.uniqueReference ===
        TagSimpleFileRequest.simpleFileRequest &&
      this.data.record.status === SfrWorkflowStatus.sfrNew
    );
  }
}
