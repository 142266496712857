import { Action } from '@ngrx/store';
import { ZipStatus } from '../models/zip-status.model';
import { SideDrawer } from '../../sidedrawer/models/side-drawer.model';
import { ZipQueueFile } from '../models/zip-queue-file.model';
import { PaginatorApiResponse } from '../../core/models/paginator-api-response.dto';
import { RecordListItem } from '../../records/models/record-list-item.model';
import { FileItem } from '../../files/models/file-item.model';

export enum ZipActionsTypes {
  ZipConfirmDownload = '[Zip Dialog] User confirmed to download all files into a Zip',
  ZipStatusUpdate = '[Zip Service] Zip status update',
  ZipCompletes = '[Zip Service] Zip completed',
  ZipCanceled = '[Zip Dialog] Zip canceled by the user',
  ZipFails = '[Zip Dialog] Zip creation fails',
  ZipNoFiles = '[Zip Service] There are no Files to Zip',
  ZipReset = '[Zip Service] Zip reset to initial values',
  AddFileToQueue = '[Zip Service] Add File to Queue',
  FileDownloadUpdate = '[Zip Service] Zip Download Update',
  ZipDownloadFileError = '[Zip Service] Download file error',
  ZipAllRecordsRequested = '[Zip Service] Request all records information of the given SD',
  ZipAllRecordsLoaded = '[Zip Service] Zip All Records information loaded',
  ZipRecordsLoaded = '[Zip Service] Zip Records information loaded',
  ZipAllFilesRequested = '[Zip Service] Request all files from all records',
  ZipRecordFilesRequested = '[Zip Service] Request all files from a single Record',
  ZipRecordFilesLoaded = '[Zip Service] All files from a single Record Loaded',
  ZipDownloadFile = '[Zip Service] Download a Single File from the Queue',
  ZipGenerateZipFile = '[Zip Service] Generate the Zip File with all the downloaded files',
}

export class ZipConfirmDownload implements Action {
  readonly type = ZipActionsTypes.ZipConfirmDownload;

  constructor(public payload: { sidedrawer: SideDrawer }) {}
}

export class ZipStatusUpdate implements Action {
  readonly type = ZipActionsTypes.ZipStatusUpdate;

  constructor(public payload: { status: ZipStatus }) {}
}

export class ZipCompletes implements Action {
  readonly type = ZipActionsTypes.ZipCompletes;
}

export class ZipCanceled implements Action {
  readonly type = ZipActionsTypes.ZipCanceled;
}

export class AddFileToQueue implements Action {
  readonly type = ZipActionsTypes.AddFileToQueue;

  constructor(public payload: { item: ZipQueueFile }) {}
}

export class FileDownloadUpdate implements Action {
  readonly type = ZipActionsTypes.FileDownloadUpdate;

  constructor(public payload: { item: ZipQueueFile; index: number }) {}
}

export class ZipRecordsLoaded implements Action {
  readonly type = ZipActionsTypes.ZipRecordsLoaded;

  constructor(public payload: { records: RecordListItem[] }) {}
}

export class ZipDownloadFileError implements Action {
  readonly type = ZipActionsTypes.ZipDownloadFileError;

  constructor(public payload: { error: string }) {}
}

export class ZipFails implements Action {
  readonly type = ZipActionsTypes.ZipFails;
}

export class ZipReset implements Action {
  readonly type = ZipActionsTypes.ZipReset;
}

export class ZipNoFiles implements Action {
  readonly type = ZipActionsTypes.ZipNoFiles;
}

export class ZipAllRecordsRequested implements Action {
  readonly type = ZipActionsTypes.ZipAllRecordsRequested;

  constructor(
    public payload?: { data: PaginatorApiResponse<RecordListItem> }
  ) {}
}

export class ZipAllRecordsLoaded implements Action {
  readonly type = ZipActionsTypes.ZipAllRecordsLoaded;
}

export class ZipAllFilesRequested implements Action {
  readonly type = ZipActionsTypes.ZipAllFilesRequested;
}

export class ZipRecordFilesRequested implements Action {
  readonly type = ZipActionsTypes.ZipRecordFilesRequested;

  constructor(
    public payload: { recordId: string; data?: PaginatorApiResponse<FileItem> }
  ) {}
}

export class ZipRecordFilesLoaded implements Action {
  readonly type = ZipActionsTypes.ZipRecordFilesLoaded;

  constructor(public payload: { recordId: string }) {}
}

export class ZipDownloadFile implements Action {
  readonly type = ZipActionsTypes.ZipDownloadFile;
}

export class ZipGenerateZipFile implements Action {
  readonly type = ZipActionsTypes.ZipGenerateZipFile;
}

export type ZipActions =
  | ZipConfirmDownload
  | ZipStatusUpdate
  | ZipCompletes
  | ZipCanceled
  | ZipFails
  | ZipNoFiles
  | ZipReset
  | AddFileToQueue
  | FileDownloadUpdate
  | ZipDownloadFileError
  | ZipAllRecordsRequested
  | ZipAllRecordsLoaded
  | ZipRecordsLoaded
  | ZipAllFilesRequested
  | ZipRecordFilesRequested
  | ZipRecordFilesLoaded
  | ZipDownloadFile
  | ZipGenerateZipFile;
