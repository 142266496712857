<div *ngIf="vm$ | async as vm" class="record-form-signature-tab">
  <app-sd-progress-bar-a11y [setIsLoading]="vm.gettingInformation" />
  <div class="section">
    <div class="left-column">
      <app-sd-record-tab-header
        [title]="'recordformsignaturetab_signatures' | dictionary | async"
        [tooltip]="'recordformsignaturetab_tooltip' | dictionary | async"
        class="app-sd-record-tab-header" />
    </div>
  </div>
  <div
    *ngIf="vm.envelopeIds.length > 0; else noEnvelopes"
    class="record-form-files-tab-list">
    <app-envelope-list-item
      *ngFor="
        let envelopeId of vm.envelopeIds;
        index as i;
        trackBy: trackByEnvelope
      "
      [envelopeId]="envelopeId" />
  </div>

  <app-sd-link-button-a11y
    (click)="onViewMore($event)"
    (keydown.enter)="onViewMore($event)"
    *ngIf="vm.hasMore"
    [ariaLabel]="'globalparams_viewmore' | dictionary | async"
    [href]="'#'"
    [template]="SdLinkButtonA11yTemplates.linkNative">
    <span class="file-history-section-view-all-link-content" linkNative>
      {{ 'globalparams_loadmore' | dictionary | async }}
    </span>
  </app-sd-link-button-a11y>

  <ng-template #noEnvelopes>
    <app-sd-empty-template
      [emptyStateInline]="true"
      [emptyStateString]="
        'recordformsignaturetab_emptystate' | dictionary | async
      "
      [marginBottom]="1.25"></app-sd-empty-template>
  </ng-template>
</div>
