import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthModule as AuthModuleMobile } from '@auth0/auth0-angular';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { metaReducers, reducers } from 'src/app/reducers';
import { environment } from 'src/environments/environment';
import { MobileAppComponent } from './mobile-app.component';

import config from 'capacitor.config';
import { MobileAppRoutingModule } from './mobile-app-routing.module';
import { MobileAuthModule } from './auth/mobile-auth.module';
import { IonicModule } from '@ionic/angular';
import { A11yModule } from '@angular/cdk/a11y';

export const callbackUri = `${config.appId}://${environment.domain}/capacitor/${config.appId}/callback`;

@NgModule({
  declarations: [MobileAppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    MobileAppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    A11yModule,
    EffectsModule.forRoot([]),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    environment.reduxToolsEnabled
      ? StoreDevtoolsModule.instrument({
          maxAge: environment.reduxMaxAge,
          logOnly: environment.production,
          serialize: true,
        })
      : [],
    MobileAuthModule,
    AuthModuleMobile.forRoot({
      domain: environment.domain,
      clientId: environment.clientID,
      useRefreshTokens: true,
      useRefreshTokensFallback: false,
      authorizationParams: {
        redirect_uri: callbackUri,
        audience: environment.audience,
        scope: environment.scope,
      },
      cacheLocation: 'localstorage',
    }),
    StoreRouterConnectingModule.forRoot(),
  ],
  bootstrap: [MobileAppComponent],
})
export class MobileAppModule {}
