import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { SpecificField } from '../../../../models/specific-field.model';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../../reducers';
import { Observable, Subscription } from 'rxjs';
import { localeDefaultSelector } from '../../../../../dictionary/store/dictionary.selectors';
import { activeRecordSelector } from '../../../../store/records-list.selectors';
import { take, tap } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
import { SpecificFieldType } from '../../../../models/specific-field-type.model';
import { Option } from '../../../../../shared/sd-forms/models/option.model';
import { Locale } from '../../../../../dictionary/models/locale.model';
import { environment } from '../../../../../../environments/environment';
// tslint:disable-next-line:max-line-length
import { PrimaryResidence } from '../../../../../account/models/primary-residence.model';
import { SdInputA11yTemplates } from '../../../../../shared/sd-input-a11y/models/sd-input-a11y-templates.enum';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-specific-fields-section-item',
  templateUrl: './specific-fields-section-item.component.html',
  styleUrls: ['./specific-fields-section-item.component.scss'],
})
export class SpecificFieldsSectionItemComponent implements OnInit, OnDestroy {
  @Input() specificField: SpecificField;
  @Input() viewOnly = false;
  @Output() valueChange = new EventEmitter<{ key: string; value: any }>();
  subscription = new Subscription();
  locale$: Observable<Locale> = this.store.pipe(select(localeDefaultSelector));
  controller = new UntypedFormControl(null);
  specificFieldType = SpecificFieldType;
  enumOptions: Option[];
  cdn = environment.cdn;
  sdInputA11yTemplates = SdInputA11yTemplates;
  primaryResidence: PrimaryResidence;

  constructor(
    private readonly store: Store<AppState>,
    private readonly dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.store
      .pipe(
        select(activeRecordSelector),
        take(1),
        tap(record => {
          if (
            !!record &&
            !!record.recordDetails &&
            !!record.recordDetails[`${this.specificField.fieldName}`]
          ) {
            this.controller.setValue(
              record.recordDetails[`${this.specificField.fieldName}`]
            );
          }
        })
      )
      .subscribe();
    this.getEnumOptions();
    this.subscription.add(
      this.controller.valueChanges.subscribe(value => {
        this.valueChange.emit({
          key: this.specificField.fieldName,
          value,
        });
      })
    );
  }

  onDateChange(date: Date): void {
    this.valueChange.emit({
      key: this.specificField.fieldName,
      value: date,
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getEnumOptions(): void {
    if (this.specificField.formType.includes(this.specificFieldType.enum)) {
      try {
        const enumArray = this.specificField.formType
          .replace('enum:', '')
          .split(',');
        this.enumOptions = [
          ...enumArray.map(option => new Option(option, option)),
        ];
      } catch (e) {
        console.error(e);
      }
    }
  }

  openUrl(): void {
    if (!!this.controller.value && this.controller.value.length > 0) {
      const url = this.controller.value.includes('https://www')
        ? this.controller.value
        : 'https://' + this.controller.value;
      window.open(url, '_blank');
    }
  }
}
