import {
  compareRecordsRoles,
  getRecordsRolesOrdinalToSort,
  RecordsRoles,
} from './records.roles';
import { Option } from '../../shared/sd-forms/models/option.model';
import { environment } from '../../../environments/environment';

export enum SidedrawerRoles {
  info = 'sd_info',
  viewer = 'sd_viewer',
  editor = 'sd_editor',
  owner = 'sd_owner',
}

export const getSideDrawerRole = (
  roles: SidedrawerRoles[]
): SidedrawerRoles => {
  if (!!roles && roles.length > 0) {
    return roles.find(role => role === SidedrawerRoles.owner)
      ? SidedrawerRoles.owner
      : roles.find(role => role === SidedrawerRoles.editor)
      ? SidedrawerRoles.editor
      : roles.find(role => role === SidedrawerRoles.viewer)
      ? SidedrawerRoles.viewer
      : SidedrawerRoles.info;
  } else {
    return SidedrawerRoles.info;
  }
};

export const compareSideDrawerRoles = (
  role1: SidedrawerRoles | RecordsRoles,
  role2: SidedrawerRoles | RecordsRoles
): SidedrawerRoles | RecordsRoles => {
  if (role1 === SidedrawerRoles.owner || role2 === SidedrawerRoles.owner) {
    return SidedrawerRoles.owner;
  }
  if (role1 === SidedrawerRoles.editor || role2 === SidedrawerRoles.editor) {
    return SidedrawerRoles.editor;
  }
  if (role1 === SidedrawerRoles.viewer || role2 === SidedrawerRoles.viewer) {
    return SidedrawerRoles.viewer;
  }
  if (role1 === SidedrawerRoles.info || role2 === SidedrawerRoles.info) {
    return SidedrawerRoles.info;
  }
  return compareRecordsRoles(role1, role2);
};

export const getSideDrawerRolesOrdinalToSort = (role: string): number => {
  switch (role) {
    case SidedrawerRoles.owner:
      return 1;
    case SidedrawerRoles.editor:
      return 2;
    case SidedrawerRoles.viewer:
      return 3;
    case SidedrawerRoles.info:
      return 4;
    default:
      return getRecordsRolesOrdinalToSort(role);
  }
};

export const SidedrawerRolesOptions: Option[] = [
  {
    key: SidedrawerRoles.owner,
    value: 'sdpermission_owner',
    avatar: environment.cdn + 'svgs/award-light.svg',
    description: 'sdpermission_ownerdescription',
  },
  {
    key: SidedrawerRoles.editor,
    value: 'sdpermission_editor',
    avatar: environment.cdn + 'svgs/edit-regular.svg',
    description: 'sdpermission_editordescription',
  },
  {
    key: SidedrawerRoles.viewer,
    value: 'sdpermission_viewer',
    avatar: environment.cdn + 'svgs/glasses-regular.svg',
    description: 'sdpermission_viewerdescription',
  },
  {
    key: SidedrawerRoles.info,
    value: 'sdpermission_info',
    avatar: environment.cdn + 'svgs/ban-regular.svg',
    description: 'sdpermission_infodescription',
  },
];
