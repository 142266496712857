<div *ngIf="item$ | async as item" class="queue-item">
  <div *ngIf="item.itemType === 'file'" class="queue-item-file-type">
    <div class="queue-item-file-type-upload-icon">
      <app-sd-svg-a11y
        [color]="'var(--primaryColor)'"
        [height]="1.6"
        [setSdAccessibility]="{ tabIndex: -1 }"
        [src]="cdn + ('queueitem_uploadfileicon' | dictionary | async)"
        [width]="1.6" />
    </div>
    <div class="queue-item-file-type-title">
      {{ item.upload.file.name }}
    </div>
    <div class="queue-item-file-type-subtitle">
      {{ item.upload.file.size | fileSize }}
    </div>
    <div *ngIf="showProgress" class="queue-item-file-type-progress">
      {{ item.progress > 100 ? 100 : item.progress }}%
    </div>
    <div
      (click)="
        item.state === 'pending' && removable ? itemRemoved.emit(true) : null
      "
      (keyup)="
        item.state === 'pending' && removable ? itemRemoved.emit(true) : null
      "
      [ngClass]="{
        invisible: item.state !== 'pending' ? false : !removable
      }"
      [ngStyle]="{
        cursor: item.state === 'pending' && removable ? 'pointer' : 'initial'
      }"
      [attr.role]="item.state === 'pending' && removable ? 'button' : 'image'"
      class="queue-item-file-type-icon">
      <app-sd-svg-a11y
        [color]="
          item.state === 'processing' || item.state === 'pending'
            ? 'var(--primaryAccentColor)'
            : item.state === 'success'
              ? 'var(--successBar)'
              : 'var(--secondaryAccentColor)'
        "
        [height]="1.2"
        [setSdAccessibility]="{ tabIndex: -1 }"
        [src]="
          item.state === 'success'
            ? cdn + ('globalparams_checkicon' | dictionary | async)
            : item.state === 'fail'
              ? cdn + ('globalparams_warningicon' | dictionary | async)
              : cdn + ('globalparams_deleteicon' | dictionary | async)
        "
        [width]="1.2" />
    </div>

    <div *ngIf="showProgress" class="queue-item-file-type-progress-bar">
      <app-sd-progress-bar-a11y
        [mode]="item.state !== 'pending' ? 'determinate' : 'indeterminate'"
        [progressBarType]="
          item.state === 'processing' || item.state === 'pending'
            ? 'primary'
            : item.state === 'success'
              ? 'success'
              : 'danger'
        "
        [progress]="item.progress"
        [setIsLoading]="true" />
    </div>
  </div>

  <div *ngIf="viewFile && item.error" class="font-error message">
    {{ 'queueitem_uploadfileerrorlabel' | dictionary | async }}
    {{ error | dictionary | async }}
  </div>

  <ng-template #networkType [ngIf]="item.itemType === 'network'">
    <div class="queue-item-file-type" tabindex="0">
      <div class="queue-item-file-type-upload-icon">
        <app-sd-svg-a11y
          [color]="'var(--primaryColor)'"
          [height]="1.25"
          [setSdAccessibility]="{ tabIndex: -1 }"
          [src]="
            item.network?.type === contributorType.team
              ? cdn + ('queueitem_updateteamicon' | dictionary | async)
              : cdn + ('queueitem_updateaccounticon' | dictionary | async)
          "
          [width]="1.25" />
      </div>
      <div class="queue-item-file-type-title">
        {{ item.network?.uploadName }}
      </div>
      <div class="queue-item-file-type-subtitle">
        {{ item.network?.permission.value | roleKeyToDictionaryRole | async }}
      </div>
      <div *ngIf="showProgress" class="queue-item-file-type-progress">
        {{ item.progress }}%
      </div>
      <div class="queue-item-file-type-icon">
        <app-sd-svg-a11y
          [color]="
            item.state === 'processing' || item.state === 'pending'
              ? 'var(--primaryAccentColor)'
              : item.state === 'success'
                ? 'var(--successBar)'
                : 'var(--alertsErrors)'
          "
          [height]="1.2"
          [setSdAccessibility]="{ tabIndex: -1 }"
          [src]="
            item.state === 'success'
              ? cdn + ('globalparams_checkicon' | dictionary | async)
              : item.state === 'fail'
                ? cdn + ('globalparams_warningicon' | dictionary | async)
                : ''
          "
          [width]="1.2" />
      </div>
      <div *ngIf="showProgress" class="queue-item-file-type-progress-bar">
        <app-sd-progress-bar-a11y
          [mode]="item.state !== 'pending' ? 'determinate' : 'indeterminate'"
          [progressBarType]="
            item.state === 'processing' || item.state === 'pending'
              ? 'primary'
              : item.state === 'success'
                ? 'success'
                : 'danger'
          "
          [progress]="item.progress"
          [setIsLoading]="true" />
      </div>
    </div>
  </ng-template>

  <ng-template #downloadType [ngIf]="item.itemType === 'download'">
    <div class="queue-item-file-type">
      <div class="queue-item-file-type-upload-icon">
        <app-sd-svg-a11y
          [color]="'var(--primaryColor)'"
          [height]="1.6"
          [setSdAccessibility]="{ tabIndex: -1 }"
          [src]="
            ('queueitem_downloadfileicon' | dictionary | async) ===
            'queueitem_downloadfileicon'
              ? cdn + ('svgs/file-download-light.svg' | dictionary | async)
              : cdn + ('queueitem_downloadfileicon' | dictionary | async)
          "
          [width]="1.6" />
      </div>
      <div class="queue-item-file-type-title">
        {{ item.download.filename }}
      </div>
      <div
        *ngIf="item.download.fileItem?.fileSize"
        class="queue-item-file-type-subtitle">
        {{ item.download.fileItem.fileSize | fileSize }}
      </div>
      <div *ngIf="showProgress" class="queue-item-file-type-progress">
        {{ item.progress > 100 ? 100 : item.progress }}%
      </div>
      <div
        (click)="
          item.state === 'pending' && removable ? itemRemoved.emit(true) : null
        "
        (keyup)="
          item.state === 'pending' && removable ? itemRemoved.emit(true) : null
        "
        [ngClass]="{
          invisible: item.state !== 'pending' ? false : !removable
        }"
        [ngStyle]="{
          cursor: item.state === 'pending' && removable ? 'pointer' : 'initial'
        }"
        [attr.role]="item.state === 'pending' && removable ? 'button' : 'image'"
        class="queue-item-file-type-icon">
        <app-sd-svg-a11y
          [color]="
            item.state === 'processing' || item.state === 'pending'
              ? 'var(--primaryAccentColor)'
              : item.state === 'success'
                ? 'var(--successBar)'
                : 'var(--secondaryAccentColor)'
          "
          [height]="1.2"
          [setSdAccessibility]="{ tabIndex: -1 }"
          [src]="
            item.state === 'success'
              ? cdn + ('globalparams_checkicon' | dictionary | async)
              : item.state === 'fail'
                ? cdn + ('globalparams_warningicon' | dictionary | async)
                : cdn + ('globalparams_deleteicon' | dictionary | async)
          "
          [width]="1.2" />
      </div>

      <div *ngIf="showProgress" class="queue-item-file-type-progress-bar">
        <app-sd-progress-bar-a11y
          [mode]="item.state !== 'pending' ? 'determinate' : 'indeterminate'"
          [progressBarType]="
            item.state === 'processing' || item.state === 'pending'
              ? 'primary'
              : item.state === 'success'
                ? 'success'
                : 'danger'
          "
          [progress]="item.progress"
          [setIsLoading]="true" />
      </div>
    </div>
  </ng-template>

  <ng-template #deleteType [ngIf]="item.itemType === 'deleteFile'">
    <div class="queue-item-file-type">
      <div class="queue-item-file-type-upload-icon">
        <app-sd-svg-a11y
          [color]="'var(--primaryColor)'"
          [height]="1.6"
          [setSdAccessibility]="{ tabIndex: -1 }"
          [src]="cdn + ('queueitem_deletefileicon' | dictionary | async)"
          [width]="1.6" />
      </div>
      <div class="queue-item-file-type-title">
        {{ item?.deleteFile?.filename }}
      </div>
      <div
        *ngIf="item?.deleteFile?.fileItem?.fileSize"
        class="queue-item-file-type-subtitle">
        {{ item?.deleteFile?.fileItem.fileSize | fileSize }}
      </div>
      <div *ngIf="showProgress" class="queue-item-file-type-progress">
        {{ item.progress > 100 ? 100 : item.progress }}%
      </div>
      <div
        (click)="
          item.state === 'pending' && removable ? itemRemoved.emit(true) : null
        "
        (keyup)="
          item.state === 'pending' && removable ? itemRemoved.emit(true) : null
        "
        [ngClass]="{
          invisible: item.state !== 'pending' ? false : !removable
        }"
        [ngStyle]="{
          cursor: item.state === 'pending' && removable ? 'pointer' : 'initial'
        }"
        [attr.role]="item.state === 'pending' && removable ? 'button' : 'image'"
        class="queue-item-file-type-icon">
        <app-sd-svg-a11y
          [color]="
            item.state === 'processing' || item.state === 'pending'
              ? 'var(--primaryAccentColor)'
              : item.state === 'success'
                ? 'var(--successBar)'
                : 'var(--secondaryAccentColor)'
          "
          [height]="1.2"
          [setSdAccessibility]="{ tabIndex: -1 }"
          [src]="
            item.state === 'success'
              ? cdn + ('globalparams_checkicon' | dictionary | async)
              : item.state === 'fail'
                ? cdn + ('globalparams_warningicon' | dictionary | async)
                : cdn + ('globalparams_deleteicon' | dictionary | async)
          "
          [width]="1.2" />
      </div>

      <div *ngIf="showProgress" class="queue-item-file-type-progress-bar">
        <app-sd-progress-bar-a11y
          [mode]="item.state !== 'pending' ? 'determinate' : 'indeterminate'"
          [progressBarType]="
            item.state === 'processing' || item.state === 'pending'
              ? 'primary'
              : item.state === 'success'
                ? 'success'
                : 'danger'
          "
          [progress]="item.progress"
          [setIsLoading]="true" />
      </div>
    </div>
  </ng-template>
</div>
