import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ActionsSubject, Store } from '@ngrx/store';
import { catchError, map, mergeMap, of, takeUntil, tap } from 'rxjs';
import { PaginatorService } from 'src/app/core/services/paginator.service';
import { ErrorLoaded } from 'src/app/core/store/core.actions';
import { RecordsHelper } from 'src/app/records/helpers/records.helper';
import { RecordListItem } from 'src/app/records/models/record-list-item.model';
import { AppState } from 'src/app/reducers';
import {
  SearchRecordListDeleted,
  SearchRecordsListActionsTypes,
  SearchRecordsListLoaded,
  SearchRecordsListLoadedForAppend,
  SearchRecordsListRequested,
  SearchRecordsListRequestedForAppend,
  SearchRecordsListReset,
} from './search-record-list.actions';
import { Searching } from './search-bar.actions';
import {
  RecordDeleted,
  RecordsListActionsTypes,
} from '../../records/store/records-list.actions';

@Injectable()
export class SearchRecordsListEffects {
  recordListRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<SearchRecordsListRequested>(
        SearchRecordsListActionsTypes.SearchRecordsListRequested
      ),
      tap(() => this.store.dispatch(new Searching({ searching: true }))),
      mergeMap(action =>
        this.paginatorService
          .getPaginatedResource<RecordListItem>(
            RecordsHelper.getRecordsResourceUrl(
              action.payload.sideDrawerId,
              action.payload.locale,
              {
                nextPage: action.payload.nextPage,
                name: action.payload.name,
                uniqueReference: action.payload.uniqueReference ?? '',
              }
            )
          )
          .pipe(
            takeUntil(
              this.actionsListener$.pipe(
                ofType<SearchRecordsListReset>(
                  SearchRecordsListActionsTypes.SearchRecordsListReset
                ),
                tap(() => {
                  this.store.dispatch(new Searching({ searching: false }));
                })
              )
            ),
            map(response => {
              return new SearchRecordsListLoaded({
                data: {
                  ...response,
                  data: response.data.map(rec => ({
                    ...rec,
                    sideDrawerId: action.payload.sideDrawerId,
                  })),
                },
                sideDrawerId: action.payload.sideDrawerId,
                filter: action.payload?.name,
              });
            }),
            tap(() => this.store.dispatch(new Searching({ searching: false }))),
            catchError(error => {
              return of(new ErrorLoaded({ httpError: { ...error.httpError } }));
            })
          )
      )
    );
  });

  recordListRequestedForAppend$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<SearchRecordsListRequestedForAppend>(
        SearchRecordsListActionsTypes.SearchRecordsListRequestedForAppend
      ),
      tap(() => this.store.dispatch(new Searching({ searching: true }))),
      mergeMap(action =>
        this.paginatorService
          .getPaginatedResource<RecordListItem>(
            RecordsHelper.getRecordsResourceUrl(
              action.payload.sideDrawerId,
              action.payload.locale,
              {
                nextPage: action.payload.nextPage,
                name: action.payload.name,
                uniqueReference: action.payload.uniqueReference ?? '',
              }
            )
          )
          .pipe(
            map(response => {
              return new SearchRecordsListLoadedForAppend({
                data: {
                  ...response,
                  data: response.data.map(rec => ({
                    ...rec,
                    sideDrawerId: action.payload.sideDrawerId,
                  })),
                },
                sideDrawerId: action.payload.sideDrawerId,
              });
            }),
            tap(() => this.store.dispatch(new Searching({ searching: false }))),
            catchError(error => {
              return of(new ErrorLoaded({ httpError: { ...error.httpError } }));
            })
          )
      )
    );
  });

  recordDeleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType<RecordDeleted>(RecordsListActionsTypes.RecordDeleted),
      map(({ payload }) => new SearchRecordListDeleted({ id: payload.id }))
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly actionsListener$: ActionsSubject,
    private readonly paginatorService: PaginatorService,
    private readonly store: Store<AppState>
  ) {}
}
