import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, combineLatest, Observable, takeWhile } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FileItem } from '../models/file-item.model';
import { FileUpload } from '../models/file-upload.model';
import { FileType } from '../models/file-type.enum';
import { FilesHelper } from '../helpers/files.helper';
import { CloudFolderFileDto } from '../models/cloud-folder-file.dto';
import { PaginatorApiResponse } from '../../core/models/paginator-api-response.dto';
import { UtilsHelper } from '../../core/helpers/utils.helper';
import { FileProgressResponse } from '../../queue/models/file-progress-response.model';
import { KeyValue } from '@angular/common';
import * as SdSdk from '@sidedrawer/sdk';
import {
  FileUploadOptions,
  FileUploadParams,
} from '@sidedrawer/sdk/dist/modules/Files';
import { RenameFileModel } from '../shared/file-rename-dialog/file-rename-dialog.store';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import {
  authHeadersSelector,
  tokenSelector,
} from 'src/app/auth/store/auth.selectors';
import { FileDownloadProgressResponse } from 'src/app/queue/models/file-download-progress-response.model';

@Injectable()
export class FilesService {
  protected gatewayApi = environment.gatewayApi;
  protected integrationApi = environment.integrationApi;

  constructor(
    protected readonly http: HttpClient,
    protected readonly store: Store<AppState>
  ) {}

  deleteFile(fileItem: FileItem): Observable<boolean> {
    if (fileItem.fileToken) {
      return this.http
        .delete(
          `${UtilsHelper.apiVersion(
            this.gatewayApi,
            2
          )}record-files/sidedrawer/sidedrawer-id/${
            fileItem.sideDrawerId
          }/records/record-id/${fileItem.recordId}/record-files/${
            fileItem.fileToken
          }`,
          { headers: this.store.selectSignal(authHeadersSelector)() }
        )
        .pipe(
          map(() => {
            return true;
          })
        );
    }
    const fileURL = fileItem.fileUrl.includes('/api/v1/record-files')
      ? fileItem.fileUrl.replace('/api/v1/', this.gatewayApi)
      : fileItem.fileUrl.replace('/api/v1/', this.gatewayApi + 'record-files/');
    return this.http
      .delete(fileURL, {
        headers: this.store.selectSignal(authHeadersSelector)(),
      })
      .pipe(
        map(() => {
          return true;
        })
      );
  }

  downloadFile(
    fileItem: FileItem,
    sidedrawerId: string
  ): Observable<
    | string
    | { status: string; loaded?: number; file?: Blob }
    | FileDownloadProgressResponse
  > {
    if (fileItem.fileToken) {
      return this.downloadWithSdk(fileItem, sidedrawerId);
    }
    const url = fileItem.cloudStorage
      ? fileItem.fileUrl.replace('/api/v1/', this.integrationApi)
      : fileItem.fileUrl.includes('/api/v1/record-files')
        ? fileItem.fileUrl.replace('/api/v1/', this.gatewayApi)
        : fileItem.fileUrl.replace(
            '/api/v1/',
            this.gatewayApi + 'record-files/'
          );
    const fileURL =
      url + (fileItem.cloudStorage ? `?sidedrawerId=${sidedrawerId}` : '');
    return this.http
      .get<Blob>(fileURL, {
        headers: this.store.selectSignal(authHeadersSelector)(),
        responseType: 'blob' as 'json',
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        map(event => {
          switch (event.type) {
            case HttpEventType.DownloadProgress:
              //return { status: 'progress', loaded: event.loaded };
              return new FileDownloadProgressResponse('progress', event.loaded);
            case HttpEventType.Response:
              //return { status: 'complete', file: event.body };
              return new FileDownloadProgressResponse(
                'complete',
                null,
                event.body
              );
            default:
              return `Unhandled event: ${event.type}`;
          }
        })
      );
  }

  uploadFile(
    fileUpload: FileUpload,
    type: FileType,
    sideDrawerId: string,
    recordId: string,
    externalKeys?: KeyValue<string, string>[]
  ): Observable<FileProgressResponse> {
    const progressSubscriber$ = new BehaviorSubject<number>(0);
    const sdk = this.generateSdSdk();
    const params: FileUploadParams & Partial<FileUploadOptions> = {
      sidedrawerId: sideDrawerId,
      recordId: recordId,
      file: fileUpload.file,
      fileName: FilesHelper.generateFileName(fileUpload.uploadTitle, false),
      fileExtension: encodeURIComponent(
        fileUpload.file.name.split('.')[
          fileUpload.file.name.split('.').length - 1
        ]
      ),
      uploadTitle: fileUpload.uploadTitle,
      fileType: type as 'image' | 'document' | 'cloud',
      progressSubscriber$,
      maxRetries: 3,
      maxConcurrency: 3,
    };
    if (externalKeys) {
      params.externalKeys = externalKeys;
    }
    if (fileUpload.envelopeId) {
      params.envelopeId = fileUpload.envelopeId;
    }
    if (fileUpload.correlationId) {
      params.correlationId = fileUpload.correlationId;
    }
    if (fileUpload.displayType) {
      params.displayType = fileUpload.displayType;
    }
    return combineLatest([
      sdk.files.upload(params).pipe(startWith(null)),
      progressSubscriber$,
    ]).pipe(
      takeWhile(([response]) => response === null, true),
      map(([response, progress]) => {
        if (!response) {
          return new FileProgressResponse('progress', Math.round(progress));
        }
        // @ts-ignore
        return new FileProgressResponse('complete', 100, {
          ...response,
          id: response['_id'] || response['id'],
          sideDrawerId,
          recordId,
          fileType: response.fileType as FileType,
        });
      })
    );
  }

  sealFile(
    sidedrawerId: string,
    recordId: string,
    fileNameWithExtension: string
  ): Observable<unknown> {
    return this.http.put(
      this.gatewayApi +
        `record-files/sidedrawer/sidedrawer-id/${sidedrawerId}/records/record-id/${recordId}/record-files/${fileNameWithExtension}/seal`,
      {},
      {
        headers: {
          Authorization:
            this.store.selectSignal(authHeadersSelector)().Authorization,
        },
      }
    );
  }

  getCloudFoldersFiles(
    sideDrawerId: string,
    recordId: string
  ): Observable<PaginatorApiResponse<CloudFolderFileDto>> {
    let resourceUrl = UtilsHelper.apiVersion(environment.gatewayApi, 2);
    resourceUrl += `record-files/sidedrawer/sidedrawer-id/${sideDrawerId}/records/record-id/${recordId}/cloud-folder-record-files?`;
    return this.http
      .get<PaginatorApiResponse<CloudFolderFileDto>>(resourceUrl, {
        headers: {
          Authorization:
            this.store.selectSignal(authHeadersSelector)().Authorization,
        },
      })
      .pipe(
        map(response => ({
          ...response,
          data: response.data.map(item => ({
            ...item,
            sideDrawerId,
            recordId,
          })),
        }))
      );
  }

  getFileItem(
    sideDrawerId: string,
    recordId: string,
    fileId: string
  ): Observable<FileItem> {
    return this.http
      .get<FileItem>(
        this.gatewayApi +
          `record-files/sidedrawer/sidedrawer-id/${sideDrawerId}/records/record-id/${recordId}/record-files/recordfile-id/${fileId}`,
        {
          headers: {
            Authorization:
              this.store.selectSignal(authHeadersSelector)().Authorization,
          },
        }
      )
      .pipe(map(fileItem => ({ ...fileItem, sideDrawerId, recordId })));
  }

  renameFile(
    sidedrawerId: string,
    recordId: string,
    fileNameWithExtension: string,
    body: RenameFileModel
  ): Observable<unknown> {
    return this.http.put(
      this.gatewayApi +
        `record-files/sidedrawer/sidedrawer-id/${sidedrawerId}/records/record-id/${recordId}/record-files/${fileNameWithExtension}`,
      { ...body },
      {
        headers: {
          Authorization:
            this.store.selectSignal(authHeadersSelector)().Authorization,
        },
      }
    );
  }

  renameFileWithToken(
    sidedrawerId: string,
    recordId: string,
    fileItem: FileItem,
    body: RenameFileModel
  ): Observable<unknown> {
    const resourceUrl = UtilsHelper.apiVersion(environment.gatewayApi, 2);
    return this.http.put(
      resourceUrl +
        `record-files/sidedrawer/sidedrawer-id/${sidedrawerId}/records/record-id/${recordId}/record-files/${fileItem.fileToken}`,
      { ...body },
      {
        headers: {
          Authorization:
            this.store.selectSignal(authHeadersSelector)().Authorization,
        },
      }
    );
  }

  copyMoveFile(
    originSidedrawerId: string,
    originRecordId: string,
    destinationSidedrawerId: string,
    destinationRecordId: string,
    fileItem: FileItem,
    operationType: string
  ): Observable<unknown> {
    let resourceUrl = UtilsHelper.apiVersion(environment.gatewayApi, 2);
    resourceUrl += `record-files/sidedrawer/sidedrawer-id/${originSidedrawerId}/records/record-id/${originRecordId}/record-files/${operationType}`;
    const data: {
      destinationSidedrawerId: string;
      destinationRecordId: string;
      fileToken?: string;
      fileName?: string;
    } = {
      destinationSidedrawerId,
      destinationRecordId,
    };
    if (fileItem?.fileToken) {
      data.fileToken = fileItem?.fileToken;
    }
    if (!fileItem?.fileToken) {
      data.fileName = FilesHelper.getFileNameWithExtension(
        fileItem?.fileName,
        fileItem
      );
    }
    return this.http.put(resourceUrl, data, {
      headers: {
        Authorization:
          this.store.selectSignal(authHeadersSelector)().Authorization,
      },
    });
  }

  downloadWithSdk(
    fileItem: FileItem,
    sidedrawerId: string
  ): Observable<
    | string
    | { status: string; loaded?: number; file?: Blob }
    | FileDownloadProgressResponse
  > {
    const sdk = this.generateSdSdk().files;
    const progressSubscriber$ = new BehaviorSubject<number>(0);
    const download$ = sdk.download({
      sidedrawerId,
      recordId: fileItem.recordId,
      fileToken: fileItem.fileToken,
      progressSubscriber$,
    });
    return combineLatest([
      download$.pipe(startWith(null)),
      progressSubscriber$,
    ]).pipe(
      takeWhile(([response]) => response === null, true),
      map(([response, progress]) => {
        if (!response) {
          return new FileDownloadProgressResponse('progress', progress);
        }
        return new FileDownloadProgressResponse(
          'complete',
          100,
          response as Blob
        );
      })
    );
  }

  protected generateSdSdk() {
    return new SdSdk.SideDrawer({
      accessToken: this.store.selectSignal(tokenSelector)(),
      baseUrl: environment.gatewayApi.replace('/api/v1/', ''),
    });
  }
}
