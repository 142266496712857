import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PaginatorService } from '../../core/services/paginator.service';
import { forkJoin, of, switchMap, tap } from 'rxjs';
import { catchError, map, mergeMap, take } from 'rxjs/operators';
import { ErrorLoaded } from 'src/app/core/store/core.actions';
import { SideDrawerNetworksHelper } from 'src/app/sidedrawer-networks/helpers/side-drawer-networks.helper';
import { SideDrawerNetworksRequest } from 'src/app/sidedrawer-networks/models/side-drawer-network-request.model';
import { SideDrawerNetwork } from 'src/app/sidedrawer-networks/models/side-drawer-network.model';
import {
  SearchSideDrawerListActionsTypes,
  SearchSideDrawerListLoaded,
  SearchSideDrawerListNextPageRequested,
  SearchSideDrawerListPageLoaded,
  SearchSideDrawerListRequested,
  SearchSideDrawerListReset,
} from './search-sidedrawer-list.actions';
import { Searching } from './search-bar.actions';
import {
  SideDrawerNetworksInitialPageLoaded,
  SideDrawerNetworksRequestsActionsTypes,
} from 'src/app/sidedrawer-networks/store/side-drawer-networks-requests.actions';
import {
  SearchSideDrawerRequestListInitialPageLoaded,
  SearchSideDrawerRequestListPageLoaded,
} from './search-sidedrawer-requests-list.actions';
import {
  searchSidedrawerRequestListItemsPerPageSelector,
  searchSidedrawerRequestListSelector,
} from './search-sidedrawer-requests-list.selector';
import { searchSidedrawerListSelector } from './search-sidedrawer-list.selector';
import { SideDrawerNetworkRequestFilter } from 'src/app/sidedrawer-networks/models/side-drawer-network-request-filter.model';
import { dataBaseRegionsSelector } from 'src/app/dictionary/store/dictionary.selectors';
import { SideDrawerNetworkRequestType } from 'src/app/sidedrawer-networks/models/side-drawer-network-request-type.enum';
import { SideDrawerNetworkLoaded } from 'src/app/sidedrawer-networks/store/side-drawer-network-list.actions';

@Injectable()
export class SearchSideDrawerListEffects {
  searchSideDrawerListRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SearchSideDrawerListRequested>(
        SearchSideDrawerListActionsTypes.SearchSideDrawerListRequested
      ),
      tap(() => this.store.dispatch(new Searching({ searching: true }))),
      switchMap(action =>
        forkJoin([
          this.store.pipe(select(searchSidedrawerRequestListSelector), take(1)),
          of(action),
        ])
      ),
      switchMap(([requests, action]) =>
        forkJoin(
          requests.map(request =>
            this.paginatorService
              .getPaginatedResource<SideDrawerNetwork>(
                SideDrawerNetworksHelper.getSideDrawerNetworksResourceUrl(
                  request?.requestType,
                  {
                    dataBaseRegion: request?.dataBaseRegion,
                    nextPage: request?.nextPage ?? null,
                    name: action?.payload?.name ?? '',
                  }
                )
              )
              .pipe(
                map(response => {
                  const filter: SideDrawerNetworkRequestFilter = {
                    name: action?.payload?.name ?? '',
                  };
                  return SideDrawerNetworksRequest.fromPaginatedResponse(
                    response,
                    request?.dataBaseRegion,
                    request?.requestType,
                    filter
                  );
                })
              )
          )
        ).pipe(
          map(results => {
            results.forEach(result => {
              this.store.dispatch(
                new SearchSideDrawerRequestListPageLoaded({
                  result: result,
                })
              );
            });
            return new SearchSideDrawerListPageLoaded({
              results,
              filter: action.payload.name,
            });
          }),
          catchError(error =>
            of(new ErrorLoaded({ httpError: error, display404: true }))
          ),
          tap(() => this.store.dispatch(new Searching({ searching: false })))
        )
      )
    )
  );

  SearchSideDrawerListPageLoaded$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SearchSideDrawerListPageLoaded>(
        SearchSideDrawerListActionsTypes.SearchSideDrawerListPageLoaded
      ),
      switchMap(action =>
        forkJoin([
          this.store.pipe(select(searchSidedrawerListSelector), take(1)),
          this.store.pipe(
            select(searchSidedrawerRequestListItemsPerPageSelector),
            take(1)
          ),
          of(action).pipe(take(1)),
        ])
      ),
      map(([sdNetworks, itemsPerPage, action]) => {
        const sideDrawerNetworks =
          SideDrawerNetworksHelper.filterDuplicatedSdNetworks(
            sdNetworks,
            action.payload.results.reduce(
              (acc, result) => [...acc, ...result.data],
              []
            )
          );

        if (sideDrawerNetworks?.length < itemsPerPage) {
          this.store.dispatch(new SearchSideDrawerListNextPageRequested());
        }

        this.store.dispatch(
          new SideDrawerNetworkLoaded({ networks: sideDrawerNetworks })
        );

        return new SearchSideDrawerListLoaded({
          networks: sideDrawerNetworks,
          filter: action.payload?.filter ?? '',
        });
      })
    )
  );

  SearchSideDrawerListNextPageRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SearchSideDrawerListNextPageRequested>(
        SearchSideDrawerListActionsTypes.SearchSideDrawerListNextPageRequested
      ),
      switchMap(() =>
        forkJoin([
          this.store.pipe(select(searchSidedrawerRequestListSelector), take(1)),
        ])
      ),
      map(([requests]) => {
        requests.forEach(request => {
          if (request?.hasMore) {
            this.store.dispatch(
              new SearchSideDrawerListRequested({
                name: request.filter?.name,
              })
            );
          }
        });
        return new Searching({ searching: false });
      })
    )
  );

  SideDrawerNetworksInitialPageLoaded$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SideDrawerNetworksInitialPageLoaded>(
        SideDrawerNetworksRequestsActionsTypes.SideDrawerNetworksInitialPageLoaded
      ),
      map(
        action =>
          new SearchSideDrawerRequestListInitialPageLoaded({
            result: { ...action.payload.result },
          })
      )
    )
  );

  SearchSideDrawerListReset$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SearchSideDrawerListReset>(
          SearchSideDrawerListActionsTypes.SearchSideDrawerListReset
        ),
        mergeMap(() =>
          this.store.pipe(select(dataBaseRegionsSelector)).pipe(
            tap(dataBaseRegions => {
              const arrayActions = dataBaseRegions
                .filter(region => region.countrycode?.length > 0)
                .map(region => ({
                  owned: new SearchSideDrawerRequestListInitialPageLoaded({
                    result: {
                      data: [],
                      id: `${region.databaseregion}-${SideDrawerNetworkRequestType.owned}`,
                      dataBaseRegion: region.databaseregion,
                      requestType: SideDrawerNetworkRequestType.owned,
                      filter: null,
                      hasMore: false,
                      nextPage: null,
                      previousPage: null,
                      totalCount: 0,
                    },
                  }),
                  shared: new SearchSideDrawerRequestListInitialPageLoaded({
                    result: {
                      data: [],
                      id: `${region.databaseregion}-${SideDrawerNetworkRequestType.shared}`,
                      dataBaseRegion: region.databaseregion,
                      requestType: SideDrawerNetworkRequestType.shared,
                      filter: null,
                      hasMore: false,
                      nextPage: null,
                      previousPage: null,
                      totalCount: 0,
                    },
                  }),
                }));

              arrayActions.forEach(({ owned, shared }) => {
                this.store.dispatch(owned);
                this.store.dispatch(shared);
              });
            })
          )
        )
      ),
    { dispatch: false }
  );

  constructor(
    private readonly store: Store<AppState>,
    private readonly actions$: Actions,
    private readonly paginatorService: PaginatorService
  ) {}
}
