import { createSelector } from '@ngrx/store';
import { searchStateSelector } from './search.selectors';

export const searchBarStateSelector = createSelector(
  searchStateSelector,
  state => state?.searchbar
);

export const searchBarExpandedSelector = createSelector(
  searchBarStateSelector,
  state => state?.expanded
);

export const searchingSelector = createSelector(
  searchBarStateSelector,
  state => state?.searching
);

export const searchFilterSelector = createSelector(
  searchBarStateSelector,
  state => state?.filter
);
