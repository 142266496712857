import { Actions, createEffect, ofType } from '@ngrx/effects';
import { inject } from '@angular/core';
import { ReportsService } from '../services/reports.service';
import { ReportsActions } from './reports.store';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorLoaded } from '../../core/store/core.actions';
import { AppState } from '../../reducers';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';

export const reportsTypeRequested$ = createEffect(
  () => {
    const store = inject(Store<AppState>);
    const service = inject(ReportsService);
    const actions = inject(Actions);
    return actions.pipe(
      ofType(ReportsActions.reportTypesRequested),
      mergeMap(({ sideDrawerId }) =>
        service.getSideDrawerReportTypes(sideDrawerId).pipe(
          map(reports => reports.map(report => ({ ...report, sideDrawerId }))),
          catchError(error => of(error))
        )
      ),
      map(response => {
        if (response instanceof HttpErrorResponse) {
          store.dispatch(
            new ErrorLoaded({ httpError: response, display404: false })
          );
          return ReportsActions.reportTypesLoaded({ reportTypes: [] });
        }
        return ReportsActions.reportTypesLoaded({
          reportTypes: response,
        });
      })
    );
  },
  { functional: true }
);

export const reportsTypeStatusRequested$ = createEffect(
  () => {
    const store = inject(Store<AppState>);
    const service = inject(ReportsService);
    const actions = inject(Actions);
    return actions.pipe(
      ofType(ReportsActions.reportTypeStatusRequested),
      mergeMap(({ sideDrawerId, reportId, callback }) =>
        service.getSideDrawerReportTypeStatus(sideDrawerId, reportId).pipe(
          tap({
            next: ({ status }) => {
              if (callback) {
                callback(status);
              }
            },
          }),
          catchError(error => of(error))
        )
      ),
      map((response: string | HttpErrorResponse) => {
        if (response instanceof HttpErrorResponse) {
          store.dispatch(
            new ErrorLoaded({ httpError: response, display404: true })
          );
          return ReportsActions.reportTypeStatusLoaded({ status: 'error' });
        }
        return ReportsActions.reportTypeStatusLoaded({ status: response });
      })
    );
  },
  { functional: true }
);

export const generateReportRequested$ = createEffect(
  () => {
    const service = inject(ReportsService);
    const actions = inject(Actions);
    return actions.pipe(
      ofType(ReportsActions.generateReportRequested),
      mergeMap(({ sideDrawerId, dto, callback }) =>
        service.createReport(sideDrawerId, dto).pipe(
          tap({
            next: response => {
              if (callback) {
                callback(response._id);
              }
            },
          }),
          catchError(error => of(error))
        )
      ),
      map((response: { id: string } | HttpErrorResponse) => {
        if (response instanceof HttpErrorResponse) {
          return ReportsActions.generateReportFailed({ httpError: response });
        }
        return ReportsActions.generateReportSucceed({ reportId: response?.id });
      })
    );
  },
  { functional: true }
);
