<app-sd-dialog-template-a11y
  (closeButtonClicked)="onClose()"
  [showFooter]="false">
  <ng-container dialog-header>
    <h2>
      {{
        ('remindersformdialog_title' | dictionary | async) +
          (!!data?.record
            ? data.record?.name
            : !!(activeRecord$ | async)
              ? (activeRecord$ | async)?.name
              : !!(activeSidedrawer$ | async)
                ? (activeSidedrawer$ | async)?.name
                : '')
      }}
    </h2>
  </ng-container>
  <ng-container dialog-body>
    <app-reminders-form-a11y
      (saveEmitter)="$event ? onClose() : null"
      [setReminderFromDialog]="data?.reminder"
      [setDisabled]="data?.disabled"
      [setIsDialog]="true"
      [showHeader]="false">
    </app-reminders-form-a11y>
  </ng-container>
</app-sd-dialog-template-a11y>
