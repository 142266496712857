import { Pipe, PipeTransform } from '@angular/core';
import { To } from 'src/app/reminders/models/to.model';
import { Option } from 'src/app/shared/sd-forms/models/option.model';

@Pipe({
  name: 'getReminderUser',
  standalone: true,
})
export class GetReminderUserPipe implements PipeTransform {
  transform(reminderTo: To, options: Option[]): Option[] {
    const users = [
      ...options.filter(op => reminderTo.openIds?.includes(op.key)),
      ...reminderTo.emails.map(e => new Option(e, e)),
    ];
    if (users.length > 1 && users.length !== 2) {
      return [
        ...users.slice(0, 1),
        new Option(
          `+${(users.length - 1).toString()}`,
          `+${(users.length - 1).toString()}`
        ),
      ];
    }
    return users;
  }
}
