import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  AsyncPipe,
  NgClass,
  NgIf,
  NgStyle,
  TitleCasePipe,
} from '@angular/common';
import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { Observable, of } from 'rxjs';
import { SdQueueItem } from '../../models/sd-queue-item.model';
import { queueEntityByIdSelector } from '../../store/queue.selectors';
import { environment } from '../../../../environments/environment';
import { SdSvgA11yModule } from '../../../shared/sd-svg-a11y/sd-svg-a11y.module';
import { FileSizePipe } from '../../../shared/sd-pipes/file-size.pipe';
import { DictionaryPipeModule } from 'src/app/dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { ContributorType } from 'src/app/networks/models/contributor-type.model';
import { RoleKeyToDictionaryRolePipe } from '../../../shared/sd-pipes/role-key-to-dictionary-role.pipe';
import { SdProgressBarA11yComponent } from 'src/app/shared/sd-progress-bar-a11y/components/sd-progress-bar-a11y/sd-progress-bar-a11y.component';

@Component({
  selector: 'app-queue-item',
  standalone: true,
  styleUrls: ['./queue-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    SdSvgA11yModule,
    SdProgressBarA11yComponent,
    FileSizePipe,
    DictionaryPipeModule,
    NgIf,
    AsyncPipe,
    NgClass,
    TitleCasePipe,
    RoleKeyToDictionaryRolePipe,
    NgStyle,
  ],
  templateUrl: './queue-item.component.html',
})
export class QueueItemComponent {
  item$: Observable<SdQueueItem>;
  @Input() showProgress = true;
  @Input() removable = false;
  @Input() viewFile?: SdQueueItem;
  cdn = environment.cdn;
  error: string;
  contributorType = ContributorType;
  @Output() itemRemoved = new EventEmitter<boolean>();

  constructor(private readonly store: Store<AppState>) {}

  @Input() set itemId(id: string | number) {
    if (this.viewFile) {
      this.item$ = of(this.viewFile);
      if (typeof this.viewFile.error !== 'object') {
        this.error = this.viewFile?.error;
      }
      return;
    }
    this.item$ = this.store.select(
      queueEntityByIdSelector({ id: id?.toString() })
    );
  }

  @Input() set setItem(item: SdQueueItem) {
    this.item$ = of(item);
  }
}
