<div
  (fileDropped)="processFiles($event)"
  *ngIf="hideUploadButton() !== true && (isSdOwnerOrSdEditor$ | async) === true"
  [id]="currentRoute"
  appDragAndDrop
  class="header-container">
  <div [id]="currentRoute" class="header-container-overlay hidden">
    <div class="header-container-overlay-body"></div>
  </div>
  <ng-container *ngTemplateOutlet="templateHeader"></ng-container>
</div>

<div
  *ngIf="hideUploadButton() === true || (isSdOwnerOrSdEditor$ | async) !== true"
  class="header-container">
  <ng-container *ngTemplateOutlet="templateHeader"></ng-container>
</div>

<ng-template #templateHeader>
  <header class="header" role="none">
    <div class="header-left-column">
      <span class="title-column">
        <ng-template #templateForSdMenu>
          <img
            *ngIf="
              image &&
              image?.source &&
              (!filingCabinet() || hideFilingCabinetName())
            "
            [alt]="image?.name"
            [src]="image?.source" />
          <div
            *ngIf="
              image &&
              !image?.source &&
              (activeMyOtherSideDrawer$ | async) &&
              (!filingCabinet() || hideFilingCabinetName())
            "
            class="circle-avatar">
            {{
              !!(activeMyOtherSideDrawer$ | async)
                ? !!(activeMyOtherSideDrawer$ | async)?.name &&
                  (activeMyOtherSideDrawer$ | async)?.name.length > 0
                  ? (activeMyOtherSideDrawer$ | async)?.name[0]?.toUpperCase()
                  : ''
                : ''
            }}
          </div>
          <div class="sd-header-flex-container">
            <h2
              (focus)="hideBackDrop()"
              [attr.aria-label]="title"
              class="main-title truncate"
              tabindex="0">
              {{
                !(filingCabinet() && filingCabinetMode)
                  ? title
                  : (hideFilingCabinetName() | mainHeaderDisplayName | async)
              }}
            </h2>
            <app-sd-svg-a11y
              [color]="sdColorPalette.secondaryAccentColor"
              [height]="0.8"
              [src]="
                !isMenuOpened()
                  ? cdn + ('globalparams_chevrondownicon' | dictionary | async)
                  : cdn + ('globalparams_chevronupicon' | dictionary | async)
              "
              [width]="0.8"
              class="main-title-actions"></app-sd-svg-a11y>
          </div>
        </ng-template>
        <app-sidedrawer-menu-a11y
          (menuOpened)="onMenuOpenCloseEvent($event)"
          [primary]="false"
          [template]="sdSidedrawerMenuA11yTemplates.mainHeader"
          [templateRef]="templateForSdMenu" />
      </span>
      <div *ngIf="displayLeftSection" class="actions-buttons-column">
        <app-sd-link-menu-a11y
          *ngIf="!filingCabinet()"
          (emitterClicked)="onEmitterClicked($event)"
          [accessibility]="{
            ariaLabel:
              title + ' ' + ('sdheader_actionmenutooltip' | dictionary | async),
            tabIndex: -1
          }"
          [icon]="cdn + ('sdheader_sdmenuicon' | dictionary | async)"
          [setMenuData]="[
            {
              menuLinkText:
                'sdheader_actionmenuoptionrenamesidedrawer'
                | dictionary
                | async,
              visible: (isSdOwnerOrSdEditor$ | async),
              menuLinkType: sdLinkMenuLinkType.emitter,
              key: menuItemType.editSideDrawerName
            },
            {
              menuLinkText:
                'sdheader_actionmenuoptionshare' | dictionary | async,
              visible: true,
              menuLinkType: sdLinkMenuLinkType.emitter,
              key: menuItemType.share
            },
            {
              menuLinkText:
                'sdheader_actionmenuoptionmanagesd' | dictionary | async,
              menuLinkUrl:
                basePath + getSidedrawerRoute(sidedrawerRoutes.manage),

              menuLinkType: sdLinkMenuLinkType.internal,
              visible: isSdOwnerOrSdEditor$ | async
            },
            {
              menuLinkText:
                'sdheader_actionmenuoptiontimeline' | dictionary | async,
              menuLinkUrl: basePath + '/timeline',
              menuLinkType: sdLinkMenuLinkType.internal,
              visible: isSdOwnerOrSdEditor$ | async
            },
            {
              menuLinkText:
                'sdheader_actionmenuoptiondownload' | dictionary | async,
              visible: true,
              menuLinkType: sdLinkMenuLinkType.emitter,
              key: menuItemType.download
            },
            {
              menuLinkText:
                'sdheader_actionmenuoptionlink' | dictionary | async,
              visible: true,
              menuLinkType: sdLinkMenuLinkType.emitter,
              key: menuItemType.copy
            }
          ]"
          [squareBackground]="true"
          [buttonHeight]="3"
          [buttonWidth]="2"
          [tooltip]="
            title + ' ' + ('sdheader_actionmenutooltip' | dictionary | async)
          ">
        </app-sd-link-menu-a11y>
      </div>
    </div>

    <div *ngIf="displayRightSection" class="header-right-column">
      <app-sd-upload-button-a11y
        (filesSelected)="processFiles($event)"
        *ngIf="
          hideUploadButton() !== true && (isSdOwnerOrSdEditor$ | async) === true
        "
        [buttonText]="'sdheader_uploadbutton' | dictionary | async"
        [iconAlt]="'sdheader_uploadbutton' | dictionary | async"
        [icon]="cdn + ('sdheader_clouduploadbuttonicon' | dictionary | async)"
        [tooltip]="'sdheader_uploadbuttontooltip' | dictionary | async"
        class="header-right-column-upload-button" />
    </div>
  </header>
</ng-template>

<app-zip-header-button-a11y
  [setStartDialog]="startDialog"
  [template]="zipHeaderButtonTemplate.default"
  class="hidden" />
