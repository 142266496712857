import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SdPasswordA11yComponent } from './components/sd-password-a11y.component';
import { ReactiveFormsModule } from '@angular/forms';
import { DictionaryPipeModule } from 'src/app/dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SdIconButtonA11yComponent } from '../sd-icon-button-a11y/components/sd-icon-button-a11y/sd-icon-button-a11y.component';

@NgModule({
  declarations: [SdPasswordA11yComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    SdIconButtonA11yComponent,
    ReactiveFormsModule,
    MatInputModule,
    DictionaryPipeModule,
  ],
  exports: [SdPasswordA11yComponent],
})
export class SdPasswordA11yModule {}
