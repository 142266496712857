import { Action } from '@ngrx/store';

export enum SearchbarActionsTypes {
  ExpandSearchBar = '[] Change Search bar Display',
  Searching = '[] Change Search bar Searching',
  SetSearchFilter = '[] Set Filter of the search bar',
}

export class ExpandSearchBar implements Action {
  readonly type = SearchbarActionsTypes.ExpandSearchBar;

  constructor(
    public payload: {
      expanded: boolean;
    }
  ) {}
}

export class Searching implements Action {
  readonly type = SearchbarActionsTypes.Searching;

  constructor(
    public payload: {
      searching: boolean;
    }
  ) {}
}

export class SetSearchFilter implements Action {
  readonly type = SearchbarActionsTypes.SetSearchFilter;

  constructor(
    public payload: {
      filter: string;
    }
  ) {}
}

export type SearchBarActions = ExpandSearchBar | Searching | SetSearchFilter;
