import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  FilingCabinet,
  FilingCabinetSideDrawer,
} from '../model/filing-cabinet.model';
import { environment } from '../../../environments/environment';
import { authHeadersSelector } from '../../auth/store/auth.selectors';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';

@Injectable()
export class FilingCabinetService {
  private readonly http = inject(HttpClient);
  private readonly store = inject(Store<AppState>);
  getFilingCabinetBySideDrawerId(
    sideDrawerId: string
  ): Observable<FilingCabinet[]> {
    return this.http.get<FilingCabinet[]>(
      `${environment.gatewayApi}records/sidedrawer/sidedrawer/sidedrawer-id/${sideDrawerId}/filing-cabinets`,
      {
        headers: this.store.selectSignal(authHeadersSelector)(),
      }
    );
  }

  getFilingCabinetSideDrawers(
    sideDrawerId: string,
    filingCabinetId: string
  ): Observable<FilingCabinetSideDrawer[]> {
    return this.http.get<FilingCabinetSideDrawer[]>(
      `${environment.gatewayApi}records/sidedrawer/sidedrawer/sidedrawer-id/${sideDrawerId}/filing-cabinets/fling-cabinet-id/${filingCabinetId}/sidedrawers`,
      {
        headers: this.store.selectSignal(authHeadersSelector)(),
      }
    );
  }

  updateFilingCabinet(
    filingCabinetId: string,
    filingCabinetNewName: string
  ): Observable<FilingCabinet> {
    return this.http.put<FilingCabinet>(
      `${environment.gatewayApi}filing-cabinets/filing-cabinet/filing-cabinet-id/${filingCabinetId}`,
      {
        name: filingCabinetNewName,
      },
      {
        headers: this.store.selectSignal(authHeadersSelector)(),
      }
    );
  }
}
