export enum CoreRoutes {
  root = 'core',
  main = '',
  error = 'error',
  forbidden = 'forbidden',
  noAccount = 'no-account',
  sidedrawer = 'sidedrawer',
}

export function getCoreRoute(route: CoreRoutes): string {
  return route.length > 0 ? CoreRoutes.root + '/' + route : CoreRoutes.root;
}
