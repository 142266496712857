import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { dictionarySelector } from '../../store/dictionary.selectors';
import { map, tap } from 'rxjs/operators';
import { Dictionary } from '../../models/dictionary.model';
import { environment } from '../../../../environments/environment';

@Pipe({
  name: 'dictionary',
})
export class DictionaryPipe implements PipeTransform {
  constructor(private readonly store: Store<AppState>) {}

  transform(key: string): Observable<string> {
    return this.store.pipe(
      select(dictionarySelector),
      tap(() => {
        const dicAux = new Dictionary();
        if (
          !Object.keys(dicAux).some(k => k === key) &&
          environment?.dictionaryAlert
        ) {
          alert(`Missing key in dictionary: ${key}`);
        }
      }),
      map(dictionary => dictionary?.[key] ?? key)
    );
  }
}
