<div
  [ngClass]="{ readOnly: inputForView }"
  (click)="!inputForView ? onPlaceOfResidence() : null"
  (keyup)="
    $event.code === 'Space' || $event.code === 'Enter'
      ? !inputForView
        ? onPlaceOfResidence()
        : null
      : null
  "
  class="place-of-residence-input-container">
  <div class="place-of-residence-input">
    <app-sd-input-a11y
      [ngClass]="{
        inputForView: inputForView
      }"
      [controller]="placeOfResidenceController"
      [placeholder]="
        title
          ? title
          : !inputInTable
            ? ('sdinputlocation_placeholder' | dictionary | async)
            : ''
      "
      [readonly]="true"
      [sdAccessibilityForSuffix]="{
        ariaLabel: ('sdinputlocation_placeholder' | dictionary | async),
        role: 'button'
      }"
      [suffixSvg]="cdn + ('globalparams_mapmarkericon' | dictionary | async)"
      [template]="
        inputForView
          ? sdInputA11yTemplates.inputForView
          : sdInputA11yTemplates.inputWithSuffix
      "
      [type]="'text'" />
  </div>
</div>
