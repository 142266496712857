import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import {
  SdCacheActionsTypes,
  SdCacheElementAdded,
  SdCacheNewImageRequested,
} from './sd-cache.actions';
import { of } from 'rxjs';
import { sdCacheElementSelector } from './sd-cache.selectors';
import { map, mergeMap, take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SdCacheEffects {
  SdCacheNewImageRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SdCacheNewImageRequested>(
        SdCacheActionsTypes.SdCacheNewImageRequested
      ),
      mergeMap(action =>
        this.store.pipe(
          select(sdCacheElementSelector({ key: action.payload.src })),
          take(1),
          map(windowUrl => ({ windowUrl, src: action.payload.src }))
        )
      ),
      mergeMap(({ windowUrl, src }) => {
        if (windowUrl) {
          return of(
            new SdCacheElementAdded({
              key: src,
              windowUrl,
            })
          );
        }
        return this.http
          .get(src, {
            responseType: 'blob' as 'json',
          })
          .pipe(
            map(
              (file: Blob | MediaSource) =>
                new SdCacheElementAdded({
                  key: src,
                  windowUrl: window.URL.createObjectURL(file),
                })
            )
          );
      })
    )
  );

  constructor(
    private readonly http: HttpClient,
    private readonly actions$: Actions,
    private readonly store: Store<AppState>
  ) {}
}
