import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Dictionary } from '../../../dictionary/models/dictionary.model';
import { select, Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';
import { DictionaryPipeModule } from 'src/app/dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { dictionarySelector } from 'src/app/dictionary/store/dictionary.selectors';
import { AppState } from 'src/app/reducers';
import { SdCheckButtonA11yModule } from 'src/app/shared/sd-check-button-a11y/sd-check-button-a11y.module';
import { SdProgressSpinnerA11yModule } from 'src/app/shared/sd-progress-spinner-a11y/sd-progress-spinner-a11y/sd-progress-spinner-a11y.module';
import { SdRadioGroupInput } from 'src/app/shared/sd-radio-group-a11y/models/sd-radio-group-input.model';
import { SdDialogTemplateA11yModule } from 'src/app/shared/templates/dialog-template-a11y/sd-dialog-template-a11y.module';
import { DialogTemplateTypes } from 'src/app/shared/templates/enums/templates.enum';
import { FilesSortOption } from '../../models/file-sort-option.model';
import { FilesViewSortByChange } from '../../store/file-history.actions';
import { sortByFilesViewSelector } from '../../store/file-history.selector';
import { LiveAnnouncer } from '@angular/cdk/a11y';

@Component({
  selector: 'app-file-history-sorting-dialog',
  template: `<app-sd-dialog-template-a11y
    (closeButtonClicked)="onClose()"
    [showFooter]="false">
    >
    <ng-container dialog-header>
      <h2>
        {{ 'filehistorysortingdialog_title' | dictionary | async }}
      </h2>
    </ng-container>
    <ng-container dialog-body>
      <ul>
        <li>
          <app-sd-check-button-a11y
            (click)="onSortChange(option.key)"
            (keydown.enter)="onSortChange(option.key)"
            *ngFor="let option of options$ | async"
            [checked]="option.key === (sortFilesBy$ | async)"
            [setSdAccessibility]="{
              ariaLabel: option?.key,
              tabIndex: 0
            }"
            [value]="option.title"></app-sd-check-button-a11y>
        </li>
      </ul>
      <div *ngIf="(dictionary$ | async) === null">
        <app-sd-progress-spinner-a11y
          [diameter]="20"></app-sd-progress-spinner-a11y>
      </div>
    </ng-container>
  </app-sd-dialog-template-a11y> `,
  styleUrls: ['./file-history-sorting-dialog.component.scss'],
  standalone: true,
  imports: [
    DictionaryPipeModule,
    SdDialogTemplateA11yModule,
    SdCheckButtonA11yModule,
    SdProgressSpinnerA11yModule,
    DictionaryPipeModule,
    NgIf,
    AsyncPipe,
    NgFor,
  ],
})
export class FileHistorySortingDialogComponent implements OnInit {
  dictionary$: Observable<Dictionary>;
  options$: Observable<SdRadioGroupInput[]>;
  sortFilesBy$: Observable<FilesSortOption>;
  dialogTemplateTypes = DialogTemplateTypes;

  constructor(
    private readonly store: Store<AppState>,
    private readonly dialogRef: MatDialogRef<FileHistorySortingDialogComponent>,
    private readonly liveannouncer: LiveAnnouncer
  ) {}

  ngOnInit(): void {
    this.dictionary$ = this.store.pipe(select(dictionarySelector));
    this.sortFilesBy$ = this.store.pipe(select(sortByFilesViewSelector));
    this.options$ = this.store.pipe(
      select(dictionarySelector),
      map(dictionary => {
        const options = [];
        options.push(
          new SdRadioGroupInput(
            FilesSortOption.lastModified,
            dictionary?.filehistorysortingdialog_sortbylastmodified,
            null
          )
        );
        options.push(
          new SdRadioGroupInput(
            FilesSortOption.uploadedByAscending,
            dictionary?.filehistorysortingdialog_sortbyuploadedbyaz,
            null
          )
        );
        options.push(
          new SdRadioGroupInput(
            FilesSortOption.uploadedByDescending,
            dictionary?.filehistorysortingdialog_sortbyuploadedbyza,
            null
          )
        );
        options.push(
          new SdRadioGroupInput(
            FilesSortOption.fileNameByAscending,
            dictionary?.filehistorysortingdialog_sortbyfilenameaz,
            null
          )
        );
        options.push(
          new SdRadioGroupInput(
            FilesSortOption.fileNameByDescending,
            dictionary?.filehistorysortingdialog_sortbyfilenameza,
            null
          )
        );
        return options;
      })
    );
  }

  onSortChange(sortBy: FilesSortOption | any): void {
    this.store.dispatch(new FilesViewSortByChange({ option: sortBy }));
    this.dialogRef.close();
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
