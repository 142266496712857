import { BrandingConfig } from '../models/branding-config.model';
import { CoreActions, CoreActionsTypes } from './core.actions';
import { DisplayInfo } from '../models/display-info.model';
import { ErrorView } from '../models/error-view.model';
import { TenantRole } from '../models/tenant-role.model';

export const featureName = 'core';

export interface CoreState {
  currentBranding: BrandingConfig;
  defaultBranding: BrandingConfig;
  securityCompleted: boolean;
  displayInfo: DisplayInfo;
  httpError: ErrorView;
  gettingBranding: boolean;
  tenantRoles: TenantRole[];
  generalProgressBar: boolean;
}

export const initialCoreState: CoreState = {
  currentBranding: null,
  defaultBranding: null,
  securityCompleted: false,
  displayInfo: new DisplayInfo(),
  httpError: null,
  gettingBranding: true,
  tenantRoles: [],
  generalProgressBar: false,
};

export function coreReducer(
  state: CoreState = initialCoreState,
  action: CoreActions
): CoreState {
  switch (action.type) {
    case CoreActionsTypes.BrandingConfigLoaded:
      return {
        ...state,
        currentBranding: action.payload.data,
      };
    case CoreActionsTypes.DefaultBrandingLoaded:
      return {
        ...state,
        defaultBranding: action.payload.data,
      };
    case CoreActionsTypes.SecurityCompleted:
      return {
        ...state,
        securityCompleted: true,
      };
    case CoreActionsTypes.MobileDevice:
      return {
        ...state,
        displayInfo: {
          ...state.displayInfo,
          mobile: true,
        },
      };
    case CoreActionsTypes.ResizeEvent:
      return {
        ...state,
        displayInfo: {
          ...state.displayInfo,
          width: action.payload.width,
          height: action.payload.height,
          mobile: DisplayInfo.isMobile(action.payload.width),
          tablet: DisplayInfo.isTablet(action.payload.width),
          desktop: DisplayInfo.isDesktop(action.payload.width),
        },
      };
    case CoreActionsTypes.ErrorLoaded: {
      const errorView = new ErrorView();
      errorView.display404 = action.payload.display404;
      errorView.httpError = action.payload.httpError;
      return {
        ...state,
        httpError: errorView,
      };
    }
    case CoreActionsTypes.GettingBrandingChange:
      return {
        ...state,
        gettingBranding: action.payload.gettingBranding,
      };
    case CoreActionsTypes.TenantsRolesLoaded:
      return {
        ...state,
        tenantRoles: action.payload.tenantRoles,
      };
    case CoreActionsTypes.setGeneralProgressBar:
      return {
        ...state,
        generalProgressBar: action.payload.show,
      };
    default:
      return state;
  }
}
