import { DictionaryResponseDto } from '../models/dictionary-response.dto';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import {
  BlockedFileFormatsLoaded,
  CurrenciesLoaded,
  CustomFieldFormTypesLoaded,
  DataBaseRegionsLoaded,
  DictionaryLoaded,
  DictionaryPricesLoaded,
  DictionaryRolesLoaded,
  DisplayTypesLoaded,
  FrequenciesLoaded,
  HelpArticlesLoaded,
  HelpSectionsLoaded,
  HelpVideosLoaded,
  IdpMfaByBrandCodesLoaded,
  ImageFileFormatsLoaded,
  MenubyidpLoaded,
  PrivacyPoliciesLoaded,
  RelationshipsLoaded,
  RelationshipTypesLoaded,
  ReminderStatusLoaded,
  SdLabelsLoaded,
  SdSearchEntitiesLoaded,
  SideDrawerTypesLoaded,
  SubscriptionsStatusLoaded,
  SupportLoaded,
  TdVCardLoaded,
  TosLoaded,
  VideoFileFormatsLoaded,
} from '../store/dictionary.actions';

export class DictionaryHelper {
  public static loadDictionaryStore(
    response: DictionaryResponseDto,
    store: Store<AppState>,
    dispatchDictionaryLoaded = true
  ): void {
    store.dispatch(
      new RelationshipTypesLoaded({
        data: response.collections.relationshiptypes,
      })
    );
    store.dispatch(
      new RelationshipsLoaded({ data: response.collections.relationships })
    );
    store.dispatch(
      new DictionaryPricesLoaded({ data: response.collections.plans })
    );
    store.dispatch(
      new SideDrawerTypesLoaded({ data: response.collections.sdtypes })
    );
    store.dispatch(
      new BlockedFileFormatsLoaded({
        data: response.collections.blockedfileformats,
      })
    );
    store.dispatch(
      new ImageFileFormatsLoaded({ data: response.collections.imageformats })
    );
    store.dispatch(
      new VideoFileFormatsLoaded({ data: response.collections.videoformats })
    );
    store.dispatch(new TosLoaded({ data: response.collections.tos }));
    store.dispatch(
      new PrivacyPoliciesLoaded({ data: response.collections.privacypolicy })
    );
    store.dispatch(new SupportLoaded({ data: response.collections.support }));
    store.dispatch(
      new SubscriptionsStatusLoaded({
        data: response.collections.subscriptionstatuses,
      })
    );
    store.dispatch(
      new DisplayTypesLoaded({ data: response.collections.displaytypes })
    );
    store.dispatch(
      new CurrenciesLoaded({ data: response.collections.currencies })
    );
    store.dispatch(
      new CustomFieldFormTypesLoaded({
        data: response.collections.customfieldformtype,
      })
    );
    store.dispatch(
      new DataBaseRegionsLoaded({ data: response.collections.databaseregions })
    );

    store.dispatch(
      new FrequenciesLoaded({ frequencies: response.collections.frequencies })
    );

    store.dispatch(
      new HelpSectionsLoaded({ data: response.collections.helpsections })
    );

    store.dispatch(
      new HelpVideosLoaded({ data: response.collections.helpvideos })
    );

    store.dispatch(
      new SdSearchEntitiesLoaded({
        data: response.collections.sdsearchentities,
      })
    );

    store.dispatch(
      new ReminderStatusLoaded({
        reminderStatus: response.collections.reminderstatus,
      })
    );

    store.dispatch(
      new DictionaryRolesLoaded({
        data: response.collections.sdroles,
      })
    );

    store.dispatch(
      new IdpMfaByBrandCodesLoaded({
        data: response.collections.idpMfaByBrandCode,
      })
    );

    store.dispatch(
      new MenubyidpLoaded({
        data: response.collections.menubyidp,
      })
    );

    store.dispatch(
      new TdVCardLoaded({
        data: response.collections.advisors,
      })
    );

    store.dispatch(
      new SdLabelsLoaded({
        data: response.collections.sdlabels,
      })
    );

    store.dispatch(
      new HelpArticlesLoaded({
        data: response.collections.helparticles,
      })
    );

    if (dispatchDictionaryLoaded) {
      store.dispatch(
        new DictionaryLoaded({
          data: response.content,
          completeDictionary: response,
        })
      );
    }
  }
}
