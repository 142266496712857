import { MfaMode } from './mfa-mode.model';
import { IdpMfa } from './idp-mfa.model';

export class Settings {
  constructor(
    public notificationMethod?: string,
    public communicationLanguage?: string,
    public country?: string,
    public preferredLanguage?: string,
    public mfaDisabled?: boolean,
    public mfaMode?: MfaMode,
    public idpMfa?: IdpMfa,
    public currency?: string,
    public account?: string,
    public createdAt?: string,
    public id?: string,
    public notificationsDisabled?: boolean
  ) {}
}
