import { createSelector } from '@ngrx/store';
import { sideDrawerNetworkStateSelector } from './side-drawer-network.selectors';
import {
  selectAll,
  selectEntities,
} from './side-drawer-networks-requests.reducer';
import { sideDrawerNetworkListSortedSelector } from './side-drawer-network-list.selectors';

export const sideDrawerNetworksRequestsStateSelector = createSelector(
  sideDrawerNetworkStateSelector,
  state => state.requests
);

export const gettingSideDrawerNetworksSelector = createSelector(
  sideDrawerNetworksRequestsStateSelector,
  state => state.gettingSideDrawerNetworks
);

export const sideDrawerNetworksItemsPerPageSelector = createSelector(
  sideDrawerNetworksRequestsStateSelector,
  state => state.itemsPerPage
);

export const sideDrawerNetworksGlobalFilterSelector = createSelector(
  sideDrawerNetworksRequestsStateSelector,
  state => state.filter
);

export const sideDrawerNetworksLastRequestIdSelector = createSelector(
  sideDrawerNetworksRequestsStateSelector,
  state => state.lastRequestId
);

export const sideDrawerNetworksRequestListsSelector = createSelector(
  sideDrawerNetworksRequestsStateSelector,
  selectAll
);

export const sideDrawerNetworksRequestEntitiesSelector = createSelector(
  sideDrawerNetworksRequestsStateSelector,
  selectEntities
);

export const sideDrawerNetworksRequestEntityByIdSelector = (payload: {
  id: string;
}) =>
  createSelector(
    sideDrawerNetworksRequestEntitiesSelector,
    entities => entities[payload.id]
  );

export const sideDrawerNetworksSearchResultsSelector = createSelector(
  sideDrawerNetworkListSortedSelector,
  sideDrawerNetworksGlobalFilterSelector,
  (sdNetworks, filter) =>
    filter?.name?.length > 0
      ? sdNetworks?.filter(sdNetwork =>
          sdNetwork.name.toLowerCase().includes(filter.name.toLowerCase())
        )
      : sdNetworks
);

export const sideDrawerNetworksRequestByDataBaseRegionSelector = (payload: {
  dataBaseRegion: string;
}) =>
  createSelector(sideDrawerNetworksRequestListsSelector, requests =>
    requests.filter(
      request => request.dataBaseRegion === payload.dataBaseRegion
    )
  );
