import { Action } from '@ngrx/store';
import { Dictionary } from '../models/dictionary.model';
import { DicPrice } from '../models/dic-price.model';
import { SubscriptionStatus } from '../models/subscription-status.model';
import { BlockedFileFormat } from '../models/blocked-file-format.model';
import { SidedrawerType } from '../models/sidedrawer-type.model';
import { RelationshipType } from '../models/relationship-type';
import { Relationship } from '../models/relationship.model';
import { Tos } from '../models/tos.model';
import { PrivacyPolicy } from '../models/privacy-policy.model';
import { Support } from '../models/support.model';
import { Language } from '../models/language.model';
import { Locale } from '../models/locale.model';
import { DictionaryResponseDto } from '../models/dictionary-response.dto';
import { ImageFileFormat } from '../models/image-file-format.model';
import { VideoFileFormat } from '../models/video-file-format.model';
import { Currency } from '../models/currency.model';
import { DictionaryDisplayType } from '../models/dictionary-display-type.model';
import { CustomFieldFormType } from '../models/custom-field-form-type.model';
import { DataBaseRegion } from '../models/data-base-region.models';
import { HelpSections } from '../models/help-sections.model';
import { HelpVideos } from '../models/help-videos.model';
import { DictionaryRole } from '../models/dictionary-role.model';
import { SdSearchEntity } from '../models/sd-search-entity.model';
import { IdpMfaByBrandCode } from '../models/idp-mfa-by-brand-code.model';
import { MenuByIdp } from '../models/menu-by-idp.model';
import { TdVcard } from '../models/td.vcard';
import { SdLabel } from '../models/sd-label.model';
import { HelpArticle } from '../models/help-article.model';

export enum DictionaryActionsTypes {
  LanguagesRequested = '[App Component] Languages Requested',
  LanguagesLoaded = '[Dictionary Service] Languages Loaded',
  LocaleDefaultChange = 'Locale Default Change',
  DictionaryRequested = '[App Component] Dictionary Requested',
  DictionaryLoaded = '[Dictionary Service] Dictionary Loaded',
  FrequenciesLoaded = '[Dictionary Service] Frequencies Loaded',
  ReminderStatusLoaded = '[Dictionary Service] Reminder Status Loaded', // TODO here
  DictionaryPricesLoaded = '[Dictionary Service] Prices Loaded',
  SubscriptionsStatusLoaded = '[Dictionary Service] Subscriptions Status Loaded',
  BlockedFileFormatsLoaded = '[Dictionary Service] Blocked File Formats Loaded',
  ImageFileFormatsLoaded = '[Dictionary Service] Image File Formats Loaded',
  VideoFileFormatsLoaded = '[Dictionary Service] Video File Formats Loaded',
  SideDrawerTypesLoaded = '[Dictionary Service] SideDrawer Types Loaded',
  RelationshipTypesLoaded = '[Dictionary Service] Relationship Types Loaded',
  RelationshipsLoaded = '[Dictionary Service] Relationships Loaded',
  TosLoaded = '[Dictionary Service] Tos Loaded',
  PrivacyPoliciesLoaded = '[Dictionary Service] Privacy Policies Loaded',
  SupportLoaded = '[Dictionary Service] Support Loaded',
  DisplayTypesLoaded = '[Dictionary Service] Display Types Loaded',
  CurrenciesLoaded = '[Dictionary Service] Currencies Loaded',
  CustomFieldFormTypesLoaded = '[Dictionary Service] Plan Item Form Types Loaded',
  SdDashLinkLoaded = '[Dictionary Service] Sd Dash Links Loaded',
  DataBaseRegionsLoaded = '[Dictionary Service] Data Base Regions Loaded',
  HelpSectionsLoaded = '[Dictionary Service] Help Sections Loaded',
  HelpVideosLoaded = '[Dictionary Service] Help Videos Loaded',
  HelpSectionsRequested = '[Dictionary Service] Help Videos Requested',
  DictionaryRolesLoaded = '[Dictionary Service] Dictionary Roles Loaded',
  SdSearchEntitiesLoaded = '[Dictionary Service] Search Filter Type Loaded',
  IdpMfaByBrandCodesLoaded = '[Dictionary Service] Idp Mfa By Brand Codes Loaded',
  MenubyidpLoaded = '[Dictionary Service] Menu By Idp Loaded',
  GettingDictionaryChanges = '[Dictionary Effect] Getting Dictionary Changes',
  TdVCardLoaded = '[Dictionary Service] TD VCard Loaded',
  SdLabelsLoaded = '[Dictionary Service] SD Labels Loaded',
  HelpArticlesLoaded = '[Dictionary Service] Help Articles Loaded',
}

export class LanguagesRequested implements Action {
  readonly type = DictionaryActionsTypes.LanguagesRequested;
}

export class LanguagesLoaded implements Action {
  readonly type = DictionaryActionsTypes.LanguagesLoaded;

  constructor(public payload: { languages: Language[] }) {}
}

export class LocaleDefaultChange implements Action {
  readonly type = DictionaryActionsTypes.LocaleDefaultChange;

  constructor(public payload: { data: Locale }) {}
}

export class DictionaryRequested implements Action {
  readonly type = DictionaryActionsTypes.DictionaryRequested;

  constructor(public payload: { localeId: string }) {}
}

export class FrequenciesLoaded implements Action {
  readonly type = DictionaryActionsTypes.FrequenciesLoaded;

  constructor(public payload: { frequencies: any[] }) {}
}

export class ReminderStatusLoaded implements Action {
  readonly type = DictionaryActionsTypes.ReminderStatusLoaded;

  constructor(public payload: { reminderStatus: any[] }) {}
}

export class DictionaryLoaded implements Action {
  readonly type = DictionaryActionsTypes.DictionaryLoaded;

  constructor(
    public payload: {
      data: Dictionary;
      completeDictionary: DictionaryResponseDto;
    }
  ) {}
}

export class DictionaryPricesLoaded implements Action {
  readonly type = DictionaryActionsTypes.DictionaryPricesLoaded;

  constructor(public payload: { data: DicPrice[] }) {}
}

export class SubscriptionsStatusLoaded implements Action {
  readonly type = DictionaryActionsTypes.SubscriptionsStatusLoaded;

  constructor(public payload: { data: SubscriptionStatus[] }) {}
}

export class BlockedFileFormatsLoaded implements Action {
  readonly type = DictionaryActionsTypes.BlockedFileFormatsLoaded;

  constructor(public payload: { data: BlockedFileFormat[] }) {}
}

export class ImageFileFormatsLoaded implements Action {
  readonly type = DictionaryActionsTypes.ImageFileFormatsLoaded;

  constructor(public payload: { data: ImageFileFormat[] }) {}
}

export class VideoFileFormatsLoaded implements Action {
  readonly type = DictionaryActionsTypes.VideoFileFormatsLoaded;

  constructor(public payload: { data: VideoFileFormat[] }) {}
}

export class SideDrawerTypesLoaded implements Action {
  readonly type = DictionaryActionsTypes.SideDrawerTypesLoaded;

  constructor(public payload: { data: SidedrawerType[] }) {}
}

export class RelationshipTypesLoaded implements Action {
  readonly type = DictionaryActionsTypes.RelationshipTypesLoaded;

  constructor(public payload: { data: RelationshipType[] }) {}
}

export class RelationshipsLoaded implements Action {
  readonly type = DictionaryActionsTypes.RelationshipsLoaded;

  constructor(public payload: { data: Relationship[] }) {}
}

export class TosLoaded implements Action {
  readonly type = DictionaryActionsTypes.TosLoaded;

  constructor(public payload: { data: Tos[] }) {}
}

export class PrivacyPoliciesLoaded implements Action {
  readonly type = DictionaryActionsTypes.PrivacyPoliciesLoaded;

  constructor(public payload: { data: PrivacyPolicy[] }) {}
}

export class SupportLoaded implements Action {
  readonly type = DictionaryActionsTypes.SupportLoaded;

  constructor(public payload: { data: Support[] }) {}
}

export class CurrenciesLoaded implements Action {
  readonly type = DictionaryActionsTypes.CurrenciesLoaded;

  constructor(public payload: { data: Currency[] }) {}
}

export class CustomFieldFormTypesLoaded implements Action {
  readonly type = DictionaryActionsTypes.CustomFieldFormTypesLoaded;

  constructor(public payload: { data: CustomFieldFormType[] }) {}
}

export class DisplayTypesLoaded implements Action {
  readonly type = DictionaryActionsTypes.DisplayTypesLoaded;

  constructor(public payload: { data: DictionaryDisplayType[] }) {}
}

export class DataBaseRegionsLoaded implements Action {
  readonly type = DictionaryActionsTypes.DataBaseRegionsLoaded;

  constructor(public payload: { data: DataBaseRegion[] }) {}
}

export class HelpSectionsLoaded implements Action {
  readonly type = DictionaryActionsTypes.HelpSectionsLoaded;

  constructor(public payload: { data: HelpSections[] }) {}
}

export class HelpVideosLoaded implements Action {
  readonly type = DictionaryActionsTypes.HelpVideosLoaded;

  constructor(public payload: { data: HelpVideos[] }) {}
}

export class DictionaryRolesLoaded implements Action {
  readonly type = DictionaryActionsTypes.DictionaryRolesLoaded;

  constructor(public payload: { data: DictionaryRole[] }) {}
}

export class HelpSectionsRequested implements Action {
  readonly type = DictionaryActionsTypes.HelpSectionsRequested;

  constructor(public payload: { url: string; appName: string }) {}
}

export class SdSearchEntitiesLoaded implements Action {
  readonly type = DictionaryActionsTypes.SdSearchEntitiesLoaded;

  constructor(public payload: { data: SdSearchEntity[] }) {}
}

export class IdpMfaByBrandCodesLoaded implements Action {
  readonly type = DictionaryActionsTypes.IdpMfaByBrandCodesLoaded;

  constructor(public payload: { data: IdpMfaByBrandCode[] }) {}
}

export class GettingDictionaryChanges implements Action {
  readonly type = DictionaryActionsTypes.GettingDictionaryChanges;

  constructor(public payload: { gettingDictionary: boolean }) {}
}

export class MenubyidpLoaded implements Action {
  readonly type = DictionaryActionsTypes.MenubyidpLoaded;

  constructor(public payload: { data: MenuByIdp[] }) {}
}

export class TdVCardLoaded implements Action {
  readonly type = DictionaryActionsTypes.TdVCardLoaded;

  constructor(public payload: { data: TdVcard[] }) {}
}
export class SdLabelsLoaded implements Action {
  readonly type = DictionaryActionsTypes.SdLabelsLoaded;

  constructor(public payload: { data: SdLabel[] }) {}
}
export class HelpArticlesLoaded implements Action {
  readonly type = DictionaryActionsTypes.HelpArticlesLoaded;

  constructor(public payload: { data: HelpArticle[] }) {}
}

export type DictionaryActions =
  | ReminderStatusLoaded
  | FrequenciesLoaded
  | DictionaryRequested
  | DictionaryLoaded
  | DictionaryPricesLoaded
  | SubscriptionsStatusLoaded
  | BlockedFileFormatsLoaded
  | ImageFileFormatsLoaded
  | VideoFileFormatsLoaded
  | SideDrawerTypesLoaded
  | RelationshipTypesLoaded
  | RelationshipsLoaded
  | TosLoaded
  | PrivacyPoliciesLoaded
  | SupportLoaded
  | CurrenciesLoaded
  | CustomFieldFormTypesLoaded
  | DisplayTypesLoaded
  | LanguagesRequested
  | LanguagesLoaded
  | DataBaseRegionsLoaded
  | LocaleDefaultChange
  | HelpSectionsLoaded
  | HelpVideosLoaded
  | HelpSectionsRequested
  | DictionaryRolesLoaded
  | SdSearchEntitiesLoaded
  | IdpMfaByBrandCodesLoaded
  | MenubyidpLoaded
  | GettingDictionaryChanges
  | TdVCardLoaded
  | SdLabelsLoaded
  | HelpArticlesLoaded;
