<mat-form-field [hideRequiredMarker]="true">
  <mat-label>{{ placeholder }}</mat-label>
  <mat-select
    [formControl]="controller"
    [id]="sdAccessibility?.id"
    [ngClass]="!!withoutThis ? 'without-this' : ''">
    <mat-option
      (onSelectionChange)="
        $event.isUserInput
          ? fullOutput === false
            ? selection.emit(option?.value['key'] ?? option?.key)
            : selection.emit(option)
          : ''
      "
      *ngFor="let option of options"
      [disabled]="option?.disabled"
      [value]="option?.value['key'] ?? option?.key">
      <div class="sd-selector-option">
        <img
          *ngIf="!!option?.avatar && option?.avatar.length > 0"
          [alt]="option?.value"
          [ngClass]="{ 'rounded-avatar': roundedAvatar }"
          [src]="option?.avatar"
          class="sd-selector-image" />
        <div class="sd-selector-text">
          {{
            option?.value['languages']
              ? (option?.value['languages'] | displayValue | async)
              : option.value
          }}
        </div>
      </div>
    </mat-option>
  </mat-select>
  <mat-error *ngIf="controller.invalid">
    <span>{{ error }}</span>
  </mat-error>
  <ng-content></ng-content>
</mat-form-field>
