import {
  createActionGroup,
  createFeature,
  createReducer,
  createSelector,
  emptyProps,
  on,
  props,
} from '@ngrx/store';
import {
  FilingCabinet,
  FilingCabinetSideDrawer,
} from '../model/filing-cabinet.model';
import { SideDrawer } from '../../sidedrawer/models/side-drawer.model';
import { UtilsHelper } from '../../core/helpers/utils.helper';
import { SidedrawerRoles } from '../../core/roles/sidedrawer.roles';
import { Option } from '../../shared/sd-forms/models/option.model';
import { dictionaryRolesListSelector } from '../../dictionary/store/dictionary.selectors';

export const FilingCabinetActions = createActionGroup({
  source: 'FilingCabinet',
  events: {
    Requested: props<{ sideDrawerId: string }>(),
    Loaded: props<{ filingCabinet: FilingCabinet }>(),
    FilingCabinetSideDrawersRequested: props<{
      filingCabinetId: string;
      sideDrawerId: string;
    }>(),
    FilingCabinetSideDrawersLoaded: props<{
      sideDrawers: FilingCabinetSideDrawer[];
    }>(),
    FilingCabinetSideDrawersHomeRequested: props<{
      sideDrawers: { sideDrawerId: string; dataBaseRegion: string }[];
      localeDefault: string;
    }>(),
    FilingCabinetSideDrawerHomeLoaded: props<{
      id: string;
      data: SideDrawer;
    }>(),
    FilingCabinetUpdateRequested: props<{
      filingCabinetId: string;
      filingCabinetNewName: string;
    }>(),
    FilingCabinetUpdateSuccess: props<{ filingCabinet: FilingCabinet }>(),
    FilingCabinetUpdateError: props<{ error: string }>(),
    FilingCabinetUpdateActiveSideDrawer: props<{ sideDrawerId: string }>(),
    FilingCabinetClearAllData: emptyProps(),
  },
});

interface FilingCabinetState {
  gettingFilingCabinet: boolean;
  gettingFilingCabinetSideDrawers: boolean;
  filingCabinet: FilingCabinet;
}

const initialState: FilingCabinetState = {
  gettingFilingCabinet: false,
  gettingFilingCabinetSideDrawers: false,
  filingCabinet: null,
};

export const filingCabinetFeature = createFeature({
  name: 'filingCabinet',
  reducer: createReducer(
    initialState,
    on(FilingCabinetActions.requested, state => ({
      ...state,
      gettingFilingCabinet: true,
    })),
    on(FilingCabinetActions.loaded, (state, { filingCabinet }) => ({
      ...state,
      gettingFilingCabinet: false,
      filingCabinet,
    })),
    on(FilingCabinetActions.filingCabinetSideDrawersRequested, state => ({
      ...state,
      gettingFilingCabinetSideDrawers: true,
    })),
    on(
      FilingCabinetActions.filingCabinetSideDrawersLoaded,
      (state, { sideDrawers }) => ({
        ...state,
        gettingFilingCabinetSideDrawers: false,
        filingCabinet: {
          ...state.filingCabinet,
          sideDrawers: UtilsHelper.arrayToMap('sidedrawer', sideDrawers),
        },
      })
    ),
    on(FilingCabinetActions.filingCabinetUpdateRequested, state => ({
      ...state,
      gettingFilingCabinet: true,
    })),
    on(
      FilingCabinetActions.filingCabinetUpdateSuccess,
      (state, filingCabinet) => ({
        ...state,
        filingCabinet: {
          ...state.filingCabinet,
          name: filingCabinet.filingCabinet.name,
        },
        gettingFilingCabinet: false,
      })
    ),
    on(FilingCabinetActions.filingCabinetUpdateError, state => ({
      ...state,
      gettingFilingCabinet: false,
    })),
    on(
      FilingCabinetActions.filingCabinetSideDrawerHomeLoaded,
      (state, { id, data }) => {
        const sideDrawerToUpdate = state.filingCabinet?.sideDrawers?.get(id);
        const aux = new Map(state.filingCabinet.sideDrawers);
        aux.set(id, {
          ...sideDrawerToUpdate,
          gettingHome: false,
          data,
        });
        return {
          ...state,
          filingCabinet: {
            ...state.filingCabinet,
            sideDrawers: aux,
          },
        };
      }
    ),
    on(FilingCabinetActions.filingCabinetClearAllData, () => initialState)
  ),
});

export const filingCabinetSelector = filingCabinetFeature.selectFilingCabinet;
export const gettingFilingCabinetSelector =
  filingCabinetFeature.selectGettingFilingCabinet;

export const gettingFilingCabinetSideDrawersSelector =
  filingCabinetFeature.selectGettingFilingCabinetSideDrawers;
export const filingCabinetSideDrawersSelector = createSelector(
  filingCabinetSelector,
  filingCabinet => filingCabinet?.sideDrawers
);

export const filingCabinetSideDrawerByIdSelector = (payload: {
  sideDrawerId: string;
}) =>
  createSelector(filingCabinetSideDrawersSelector, sideDrawers =>
    sideDrawers?.get(payload.sideDrawerId)
  );

export const filingCabinetLoadingSelector = createSelector(
  gettingFilingCabinetSelector,
  gettingFilingCabinetSideDrawersSelector,
  (gettingFilingCabinet, gettingFilingCabinetSideDrawers) =>
    gettingFilingCabinet || gettingFilingCabinetSideDrawers
);

export const isFilingCabinetSelector = createSelector(
  filingCabinetSideDrawersSelector,
  fcSds => {
    return fcSds?.size > 0;
  }
);

export const filingCabinetSideDrawersWithDataSelector = createSelector(
  filingCabinetSideDrawersSelector,
  fcSds =>
    (fcSds ? UtilsHelper.mapToArray(fcSds).filter(sd => sd.data) : []).sort(
      (a, b) => (b.data?.priority ?? 0) - (a.data?.priority ?? 0)
    )
);

export const filingCabinetSideDrawersWithDataForSdOwnerAndSdEditorAsOptionsSelector =
  createSelector(
    filingCabinetSideDrawersWithDataSelector,
    dictionaryRolesListSelector,
    (fcSds, dicRoles) =>
      fcSds
        ?.map(fcSd => fcSd.data)
        ?.filter(sd =>
          sd?.userSidedrawerRole
            ?.map(
              role =>
                (dicRoles?.find(dicRole => dicRole.roleId === role)
                  ?.roleGroupId as SidedrawerRoles) ?? role
            )
            ?.some(
              role =>
                role === SidedrawerRoles.editor ||
                role === SidedrawerRoles.owner
            )
        )
        ?.sort((a, b) => (b?.priority ?? 0) - (a?.priority ?? 0))
        ?.map(sd => new Option(sd.id, sd.name, sd.profilePhoto))
  );

export const filingCabinetSideDrawersLoadingSelector = createSelector(
  filingCabinetSideDrawersSelector,
  fcSds =>
    fcSds?.size > 0
      ? UtilsHelper.mapToArray(fcSds).some(sd => sd.gettingHome)
      : false
);

export const filingCabinetSDRecordTypeSelectorBySideDrawerId = (payload: {
  sideDrawerId: string;
}) =>
  createSelector(filingCabinetSideDrawerByIdSelector(payload), fcSd =>
    fcSd?.data?.recordsType?.length > 0 ? fcSd?.data?.recordsType : []
  );
