import { SidedrawerRoles } from './sidedrawer.roles';
import { RecordsRoles } from './records.roles';
import { TeamNetwork } from '../../networks/models/team-network.model';
import { Network } from '../../networks/models/network.model';
import { environment } from '../../../environments/environment';

export class RoleHelper {
  static getHigherRoleForRecord(
    sdRole: SidedrawerRoles,
    recRole: SidedrawerRoles | RecordsRoles
  ): SidedrawerRoles | RecordsRoles {
    if (sdRole === SidedrawerRoles.owner) {
      return SidedrawerRoles.owner;
    }
    if (recRole === RecordsRoles.owner) {
      return RecordsRoles.owner;
    }
    if (sdRole === SidedrawerRoles.editor) {
      return SidedrawerRoles.editor;
    }
    if (recRole === RecordsRoles.editor) {
      return RecordsRoles.editor;
    }
    if (sdRole === SidedrawerRoles.viewer) {
      return SidedrawerRoles.viewer;
    }
    if (recRole === RecordsRoles.viewer) {
      return RecordsRoles.viewer;
    }
    if (sdRole === SidedrawerRoles.info) {
      return SidedrawerRoles.info;
    }
    if (recRole === RecordsRoles.info) {
      return RecordsRoles.info;
    }
    return RecordsRoles.info;
  }

  static getRoleIcon(network: TeamNetwork | Network): string {
    const cdn = environment.cdn;
    const role =
      !!network.sidedrawerRole && network.sidedrawerRole.length > 0
        ? network.sidedrawerRole
        : network.recordRole;
    switch (role) {
      case SidedrawerRoles.owner:
        return `${cdn}svgs/award-light.svg`;
      case SidedrawerRoles.editor:
      case RecordsRoles.editor:
        return `${cdn}svgs/edit-regular.svg`;
      case SidedrawerRoles.viewer:
      case RecordsRoles.viewer:
        return `${cdn}svgs/glasses-regular.svg`;
      case SidedrawerRoles.info:
      case RecordsRoles.info:
        return `${cdn}svgs/ban-regular.svg`;
      default:
        return '';
    }
  }
}
