import { ClientNames } from '../app/clients/client-names.enum';

export const environment = {
  production: false,
  // Development ----------------------------------------------
  reduxToolsEnabled: true,
  reduxMaxAge: 100,
  dictionaryAlert: false,
  appName: ClientNames.sf,
  brandCode: 'sidedrawer',
  defaultPageTitle: 'SideDrawer',
  keycloakDisabled: true,
  entityAppRoleName: 'webApp',
  defaultPlanRequestReportName: 'plan_request',
  // APIS -----------------------------------------------------------
  configApi: 'https://api-sbx.sidedrawersbx.com/api/v1/configs/',
  developmentApi: 'https://api-sbx.sidedrawersbx.com/api/v1/developers/',
  tenantApi: 'https://api-sbx.sidedrawersbx.com/api/v1/tenants/',
  inboxApi: 'https://api-sbx.sidedrawersbx.com/api/v1/inbox/',
  integrationApi: 'https://api-sbx.sidedrawersbx.com/api/v1/integrations/',
  signatureApi: 'https://api-sbx.sidedrawersbx.com/api/v1/signatures/',
  networksApi: 'https://api-sbx.sidedrawersbx.com/api/v1/networks/',
  recordsApi: 'https://api-sbx.sidedrawersbx.com/api/v1/records/',
  gatewayApi: 'https://api-sbx.sidedrawersbx.com/api/v1/',
  subscriptionApi: 'https://api-sbx.sidedrawersbx.com/api/v1/subscriptions/',
  userApi: 'https://api-sbx.sidedrawersbx.com/api/v1/users/',
  plansApi: 'https://api-sbx.sidedrawersbx.com/api/v1/planrequests/',
  blocksApi: 'https://blocks-gateway-api-sbx.sidedrawersbx.com/api/v1/',
  reportsApi: 'https://api-sbx.sidedrawersbx.com/api/v1/reports/',
  // AUTH0 DEFAULT-----------------------------------------------------------
  audience: 'https://user-api-sbx.sidedrawersbx.com',
  clientID: 'otLQoMCToYDHzwGEoJqzgSJKfGZvUXM8',
  connection: 'Username-Password-Authentication',
  domain: 'auth-sbx.sidedrawersbx.com',
  redirectUri: '/auth/authorize',
  scope: 'openid profile email offline_access',
  // CDN -----------------------------------------------------------
  cdn: 'https://cdn.sidedrawerdev.com/',
  // Versions --------------------------------------------------
  device: 'Web',
  version: '3.3.11',
  dictionaryVersion: 'webapp_20230601',
  // Application ---------------------------------------------------
  small: 600,
  medium: 1025,
  // Token & Session
  inactivityTime: 900,
  inactivityDialogTime: 120,
  refreshTokenTime: 180,
  // Intercom -----------------------------------------------
  intercomAppId: 'eufoidmt',
  intercomHashUrl: 'https://intercom.sidedrawersbx.com/hash',
  // PDFTron
  pdfTronLicense:
    'SideDrawer Inc :OEM:SideDrawer Web Application::B+:AMS(20270427):4064CCFCE6CFC55AE048B353187F610F600DD26EE0FD704D292A020EF652FA31F5C7',
  // Redirects ----------------------------------------------
  consoleUrl: 'https://console-dev.sidedrawersbx.com',
  // Docusign ----------------------------------------------
  docusignUrl: 'https://appdemo.docusign.com/',
  // Mobile --------------------------------
  googlePlayUrl:
    'https://play.google.com/store/apps/details?id=com.app.sidedrawer',
  appStoreUrl:
    'https://play.google.com/store/apps/details?id=com.app.sidedrawer',
  appPauseApp: 180,
};
