import { NgModule } from '@angular/core';
import { MobileAuthRoutingModule } from './mobile-auth-routing.module';
import { StoreModule } from '@ngrx/store';
import { authReducer, featureName } from 'src/app/auth/store/auth.reducer';
import { EffectsModule } from '@ngrx/effects';
import { MobileAuthEffects } from './store/mobile-auth.effects';
import { InactivityTimeDialogModule } from 'src/app/auth/shared/inactivity-time-dialog/inactivity-time-dialog.module';
import { MobileAuthService } from './services/mobile-auth.service';
import { biometricsFeature } from '../biometrics/store/biometrics.store';
import * as BiometricsEffects from '../biometrics/store/biometrics.effects';
import { BiometricsService } from '../biometrics/services/biometrics.service';
import { PreferencesService } from '../biometrics/services/preferences.service';
import { MobileStartFlowModule } from '../start-flow/mobile-start-flow.module';
import { versionReviewFeature } from '../version-review/store/version-review.store';
import { VersionReviewService } from '../version-review/services/version-review.service';
import * as VersionReviewEffects from '../version-review/store/version-review.effects';
import {AuthGuard} from '../../../auth/guards/auth.guard';

@NgModule({
  imports: [
    MobileAuthRoutingModule,
    StoreModule.forFeature(featureName, authReducer),
    StoreModule.forFeature(biometricsFeature),
    StoreModule.forFeature(versionReviewFeature),
    EffectsModule.forFeature([
      MobileAuthEffects,
      BiometricsEffects,
      VersionReviewEffects,
    ]),
    InactivityTimeDialogModule,
    MobileStartFlowModule,
  ],
  providers: [
    MobileAuthService,
    BiometricsService,
    PreferencesService,
    VersionReviewService,
    AuthGuard,
  ],
})
export class MobileAuthModule {}
