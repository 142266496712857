import { Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-video-grid-item',
  template: `
    <div [ngClass]="{ active: isActive }" class="video-grid-item-container">
      <div class="text-container">
        <span class="text">{{ name }}</span>
      </div>
    </div>
  `,
  styleUrls: ['./video-grid-item.component.scss'],
  standalone: true,
  imports: [NgClass],
})
export class VideoGridItemComponent {
  @Input() name: string;
  @Input() icon: string;
  @Input() isActive = false;
}
