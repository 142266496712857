import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { environment } from '../../../../environments/environment';
import { SdSelectorA11Module } from '../../../shared/sd-selector-a11y/sd-selector-a11y.module';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SdValidators } from '../../../shared/sd-forms/models/sd.validators';
import { ShareResourceDialogStore } from '../share-resource-dialog/share-resource-dialog.store';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { combineLatest, Observable } from 'rxjs';
import { ContributorType } from '../../models/contributor-type.model';
import { map, startWith, take, tap } from 'rxjs/operators';
import { Option } from '../../../shared/sd-selector-a11y/models/option.model';
import { Relation } from '../../../records/models/relation.model';
import { RecordsRoles } from '../../../core/roles/records.roles';
import { SidedrawerRoles } from '../../../core/roles/sidedrawer.roles';
import { SdAutocompleteA11yModule } from '../../../shared/sd-autocomplete-a11y/sd-autocomplete-a11y.module';
import { SdNetworkRelationshipFormA11yModule } from '../../../shared/sd-network-relationship-form-a11y/sd-network-relationship-form-a11y.module';
import { SdLinkMenuA11yModule } from 'src/app/shared/sd-link-menu-a11y/sd-link-menu-a11y.module';
import { SdLinkMenuLinkType } from 'src/app/shared/sd-link-menu-a11y/models/enums/sd-link-menu-link-type-enum';
import { DictionaryPipeModule } from 'src/app/dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { MenuItemType } from 'src/app/shared/sd-header-a11y/models/menu-item.type.enum';
import { SdColorPalette } from 'src/app/core/models/enums/sd-color-palette-enum';
import { SdLinkMenuItem } from 'src/app/shared/sd-link-menu-a11y/models/sd-link-menu-item.model';
import { NetworkResourceType } from '../../models/network-resource-type.model';
import { NetworkPermissionsRowComponent } from '../network-permissions-table-a11y/components/network-permissions-row/network-permissions-row.component';
import { DictionaryRoleEntity } from 'src/app/dictionary/models/dictionary-role.model';
import { AppState } from 'src/app/reducers';
import { Store } from '@ngrx/store';
import { dictionaryRolesListByEntitySelector } from 'src/app/dictionary/store/dictionary.selectors';
import { NewNetworkRow } from '../../models/new-network-row.model';
import { MatDialogModule } from '@angular/material/dialog';
import { NetworksFormRowStore } from './network-form-row.store';
import { SdInputA11yComponent } from 'src/app/shared/sd-input-a11y/components/sd-input-a11y/sd-input-a11y.component';
import { SdIconButtonA11yComponent } from 'src/app/shared/sd-icon-button-a11y/components/sd-icon-button-a11y/sd-icon-button-a11y.component';
import { NetworkAutocompleteComponent } from '../network-autocomplete/network-autocomplete.component';

@Component({
  selector: 'app-network-form-row',
  standalone: true,
  imports: [
    SdSelectorA11Module,
    AsyncPipe,
    NgIf,
    SdAutocompleteA11yModule,
    SdInputA11yComponent,
    SdNetworkRelationshipFormA11yModule,
    SdIconButtonA11yComponent,
    SdLinkMenuA11yModule,
    DictionaryPipeModule,
    NetworkPermissionsRowComponent,
    NgClass,
    MatDialogModule,
    NetworkAutocompleteComponent,
  ],
  templateUrl: './network-form-row.component.html',
  styleUrls: ['./network-form-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NetworksFormRowStore],
})
export class NetworkFormRowComponent implements OnInit {
  network: NewNetworkRow;
  @Input() set setNetwork(value: NewNetworkRow) {
    this.network = value;
  }
  @Input() recordId: string;
  @Input() sideDrawerId: string;
  @Input() placeHolders: {
    contributorType: string;
    email: string;
    permission: string;
    team: string;
    fullName: string;
    relationship: string;
  };
  @Output() deleteRow = new EventEmitter<number>();
  @Output() createRow = new EventEmitter<number>();
  @Input() isSubmitted = false;
  @Output() updateRow = new EventEmitter<
    Partial<{
      email: string;
      team: string;
      contributor: ContributorType;
      fullName: string;
      relation: Relation;
      permission: Option;
      expiryDate: Date;
    }>
  >();
  contributorOptions$ = this.dialogStore.contributorTypeOptions$;
  accountOptions: Option[];
  accountOptions$ = this.dialogStore.accountOptions$.pipe(
    tap(accounts => {
      this.accountOptions = accounts;
    })
  );
  entity = DictionaryRoleEntity.sidedrawer;

  resourceType$ = this.dialogStore.resourceType$
    .pipe(
      take(1),
      tap(resourceType => {
        switch (resourceType) {
          case NetworkResourceType.record:
            this.entity = DictionaryRoleEntity.record;
            break;
          case NetworkResourceType.sideDrawer:
            this.entity = DictionaryRoleEntity.sidedrawer;
            break;
          default:
            this.entity = DictionaryRoleEntity.record;
            break;
        }
      })
    )
    .subscribe();

  teamOptions$ = this.dialogStore.teamOptions$;
  cdn = environment.cdn;
  emailController = new FormControl<string>(null, [
    Validators.required,
    SdValidators.emailValidator.bind(this),
  ]);
  DictionaryRoleEntity = DictionaryRoleEntity;
  teamController = new FormControl<string>(null, [Validators.required]);
  teamOptionSelected: Option;
  fullNameController = new FormControl<string>(null);
  relationController = new FormControl<Relation>(null);
  permissionController = new FormControl<RecordsRoles | SidedrawerRoles>(
    this.dialogStore.resourceType === NetworkResourceType.record
      ? RecordsRoles.editor
      : SidedrawerRoles.editor
  );
  contributorController = new FormControl<ContributorType>(
    ContributorType.account
  );

  expiryDateController = new FormControl<Date>(null);

  form = new FormGroup({
    email: this.emailController,
    team: this.teamController,
    contributor: this.contributorController,
    fullName: this.fullNameController,
    relation: this.relationController,
    permission: this.permissionController,
    expiryDate: this.expiryDateController, // TODO see this...
  });
  ContributorType = ContributorType;
  showOptionalFields = false;
  sdLinkMenuLinkType = SdLinkMenuLinkType;
  menuItemType = MenuItemType;
  sdColorPalette = SdColorPalette;
  menuContributorOptions: SdLinkMenuItem[] = [];
  menuPermissionsOptions: SdLinkMenuItem[] = [];
  contributorOptionSelected: Option;
  permissionsOptionSelected: Option;
  permissionsOptions$ = this.store
    .select(
      dictionaryRolesListByEntitySelector({
        entity: this.entity,
      })
    )
    .pipe(
      map(permissions => {
        return permissions.map(permission => {
          return {
            value: permission.roleName,
            key: permission.roleId,
            avatar: this.cdn + permission.iconUrl,
            description: permission.roleDescription,
          } as Option;
        });
      }),
      tap(permissions => {
        this.permissionsOptionSelected = permissions[0];
      })
    );

  form$ = this.form.valueChanges.pipe(
    startWith(this.form.value),
    tap(value => {
      console.log({ value });
      // NOTE this is for NetworkHelper.mapNetworksV2AsUser(filteredNetworks)
      if (this.isObjectAndNotNull(this.emailController.value)) {
        this.setEmailControllerFromObject(value);
      }

      if (
        value.contributor === ContributorType.account &&
        this.emailController.valid &&
        this.form.dirty &&
        !this.accountOptions.some(
          option => option.disabled && option.value === value.email
        )
      ) {
        this.updateRow.emit({
          ...value,
          team: null,
          permission: this.permissionsOptionSelected,
        });
      }

      if (
        (value.contributor === ContributorType.account &&
          this.emailController.invalid) ||
        this.accountOptions.some(
          option => option.disabled && option.value === value.email
        )
      ) {
        this.updateRow.emit({
          ...value,
          team: null,
          email: null,
          permission: null,
        });
      }

      if (
        value.contributor === ContributorType.team &&
        this.teamController.valid
      ) {
        this.updateRow.emit({
          ...value,
          email: null,
          team: this.teamOptionSelected.key,
          permission: this.permissionsOptionSelected,
        });
      }
    })
  );
  vm$: Observable<{
    contributorOptions: Option[];
    accountOptions: Option[];
    teamOptions: Option[];
    permissionsOptions: Option[];
    formValue: Partial<{
      email: string;
      team: string;
      contributor: ContributorType;
      fullName: string;
      relation: Relation;
      permission: RecordsRoles | SidedrawerRoles;
      expiryDate: Date;
    }>;
    deleteDisabled: boolean;
  }> = combineLatest([
    this.contributorOptions$,
    this.accountOptions$,
    this.teamOptions$,
    this.permissionsOptions$,
    this.form$,
    this.dialogStore.rows$.pipe(map(rows => rows.size === 1)),
  ]).pipe(
    map(
      ([
        contributorOptions,
        accountOptions,
        teamOptions,
        permissionsOptions,
        formValue,
        deleteDisabled,
      ]) => ({
        contributorOptions,
        accountOptions,
        teamOptions,
        permissionsOptions,
        formValue,
        deleteDisabled,
      })
    ),
    tap(({ contributorOptions, permissionsOptions }) => {
      this.menuContributorOptions = contributorOptions.map(option => {
        const menuLink = new SdLinkMenuItem();
        menuLink.key = option.key;
        menuLink.menuLinkText = option.value;
        menuLink.menuLinkType = SdLinkMenuLinkType.emitter;
        menuLink.menuLinkIcon = option.avatar;
        return menuLink;
      });

      this.menuPermissionsOptions = permissionsOptions.map(option => {
        const menuLink = new SdLinkMenuItem();
        menuLink.key = option.key;
        menuLink.menuLinkText = option.value;
        menuLink.menuLinkType = SdLinkMenuLinkType.emitter;
        menuLink.menuLinkIcon = option.avatar;
        menuLink.menuLinkTooltip = option.description;
        return menuLink;
      });
    })
  );

  constructor(
    private readonly dialogStore: ShareResourceDialogStore,
    private readonly store: Store<AppState>,
    private readonly networksFormRowStore: NetworksFormRowStore
  ) {}

  ngOnInit(): void {
    this.networksFormRowStore.patchState({
      network: this.network,
      form: this.form,
    });
  }

  private isObjectAndNotNull(value: any): boolean {
    return typeof value === 'object' && value !== null;
  }

  private setEmailControllerFromObject(value: any): void {
    this.emailController.setValue(value?.email['value'], {
      emitEvent: false,
    });
    value.email = this.emailController.value;
  }

  onRelationChange(relation: Relation) {
    this.relationController.setValue(relation);
  }

  onTeamSelectionChange(team: Option | string) {
    if (typeof team === 'object') {
      this.teamOptionSelected = team;
    }
  }

  onContributorEmitterClicked(linkMenu: SdLinkMenuItem): void {
    this.contributorOptions$
      .pipe(
        take(1),
        map(options => {
          this.contributorOptionSelected = options.find(
            option => option.key === linkMenu.key
          );
        })
      )
      .subscribe();
    if (linkMenu.key === ContributorType.account) {
      this.contributorController.setValue(ContributorType.account);
    }
    if (linkMenu.key === ContributorType.team) {
      this.contributorController.setValue(ContributorType.team);
    }
  }

  onPermissionEmitterClicked(linkMenu: SdLinkMenuItem): void {
    this.permissionsOptions$
      .pipe(
        take(1),
        map(options => {
          this.permissionsOptionSelected = options.find(
            option => option.key === linkMenu.key
          );
        })
      )
      .subscribe();

    if (this.dialogStore.resourceType === NetworkResourceType.sideDrawer) {
      if (linkMenu.key === SidedrawerRoles.editor) {
        this.permissionController.setValue(SidedrawerRoles.editor);
      }
      if (linkMenu.key === SidedrawerRoles.viewer) {
        this.permissionController.setValue(SidedrawerRoles.viewer);
      }
      if (linkMenu.key === SidedrawerRoles.owner) {
        this.permissionController.setValue(SidedrawerRoles.owner);
      }
      if (linkMenu.key === SidedrawerRoles.info) {
        this.permissionController.setValue(SidedrawerRoles.info);
      }
    }

    if (this.dialogStore.resourceType === NetworkResourceType.record) {
      if (linkMenu.key === RecordsRoles.editor) {
        this.permissionController.setValue(RecordsRoles.editor);
      }
      if (linkMenu.key === RecordsRoles.viewer) {
        this.permissionController.setValue(RecordsRoles.viewer);
      }
      if (linkMenu.key === RecordsRoles.owner) {
        this.permissionController.setValue(RecordsRoles.owner);
      }
      if (linkMenu.key === RecordsRoles.info) {
        this.permissionController.setValue(RecordsRoles.info);
      }
    }
  }

  onExpireDateClicked() {
    this.networksFormRowStore.openDialogForAdvanceSettings(
      this.network,
      this.recordId,
      null
    );
  }

  onExpireDateForSideDrawerClicked() {
    this.networksFormRowStore.openDialogForAdvanceSettings(
      this.network,
      null,
      this.sideDrawerId
    );
  }
}
