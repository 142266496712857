import { inject, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { HttpClient } from '@angular/common/http';
import { authHeadersSelector } from '../../auth/store/auth.selectors';
import { Tenant } from '../model/tenant.model';
import { Observable } from 'rxjs';

@Injectable()
export class TenantService {
  private readonly tenantApi = environment.tenantApi;
  private readonly store = inject(Store<AppState>);
  private readonly http = inject(HttpClient);

  getTenants(): Observable<Tenant[]> {
    return this.http.get<Tenant[]>(this.tenantApi + `tenant/shared`, {
      headers: this.store.selectSignal(authHeadersSelector)(),
    });
  }
}
