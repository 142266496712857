import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { CountrySelectorComponent } from './components/country-selector/country-selector.component';
import { SdSelectorComponent } from './components/sd-selector/sd-selector.component';
import { SdLocaleSelectorComponent } from './components/sd-locale-selector/sd-locale-selector.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { SdSearchComponent } from './components/sd-search/sd-search.component';
import { SdButtonsModule } from '../sd-buttons/sd-buttons.module';
import {
  IConfig,
  NgxMaskDirective,
  NgxMaskPipe,
  provideNgxMask,
} from 'ngx-mask';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

export const options: Partial<null | IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    CountrySelectorComponent,
    SdSelectorComponent,
    SdLocaleSelectorComponent,
    SdSearchComponent,
  ],
  exports: [
    CountrySelectorComponent,
    SdSelectorComponent,
    SdLocaleSelectorComponent,
    SdSearchComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatCheckboxModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule,
    SdButtonsModule,
    MatProgressSpinnerModule,
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  providers: [provideNgxMask()],
})
export class SdFormsModule {}
