import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SdCheckboxA11yComponent } from './components/sd-checkbox-a11y/sd-checkbox-a11y.component';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  declarations: [SdCheckboxA11yComponent],
  imports: [CommonModule, MatCheckboxModule],
  exports: [SdCheckboxA11yComponent],
})
export class SdCheckboxA11yModule {}
