import { Actions, createEffect, ofType } from '@ngrx/effects';
import { inject } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { QueueActions } from './queue.actions';
import {
  catchError,
  last,
  map,
  mergeMap,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import {
  queueCurrentItemSelector,
  queueItemsByStateSelector,
  queueNextItemSelector,
  queueTotalItemsSelector,
} from './queue.selectors';
import { EMPTY, Observable, forkJoin, of } from 'rxjs';
import { FileProgressResponse } from '../models/file-progress-response.model';
import { HttpErrorResponse } from '@angular/common/http';
import { QueueResultDialogComponent } from '../shared/queue-result-dialog/queue-result-dialog.component';
import {
  queueStatusSelector,
  queueWindowsStatusSelector,
} from './queue.reducer';
import { environment } from '../../../environments/environment';
import { SingleFileHistoryLoaded } from '../../files/store/file-history.actions';
import { NetworkProgressResponse } from 'src/app/networks/models/network-progress-response.model';
import { GetNewNetworksRelations } from 'src/app/networks/store/networks-queue/network-queue-actions';
import { gettingNetworksQueueSelector } from 'src/app/networks/store/networks-queue/networks-queue-selectors';
import { MyTeamsRequested } from 'src/app/networks/store/my-teams/my-teams-list.actions';
import { MatDialog } from '@angular/material/dialog';
import { accountSelector } from '../../account/store/account.selector';
import { FileDownloadProgressResponse } from '../models/file-download-progress-response.model';
import { NetworkListActions } from 'src/app/networks/store/network-list.store';
import { activeSideDrawerSelector } from 'src/app/sidedrawer/store/sidedrawer.selector';

export const queueStartProcess$ = createEffect(
  () => {
    const actions$ = inject(Actions);
    const store = inject(Store<AppState>);
    return actions$.pipe(
      ofType(QueueActions.startProcess),
      switchMap(() =>
        forkJoin([store.pipe(select(queueStatusSelector), take(1))])
      ),
      map(([status]) => {
        if (status !== 'idle') {
          return;
        }
        if (status === 'idle') {
          store.dispatch(QueueActions.statusChange({ status: 'processing' }));
          store.dispatch(
            QueueActions.widowStatusChange({ windowStatus: 'minimized' })
          );
          return store.dispatch(QueueActions.processNextItem());
        }
      })
    );
  },
  { functional: true, dispatch: false }
);

export const queueNextItem$ = createEffect(
  () => {
    const actions$ = inject(Actions);
    const store: Store<AppState> = inject(Store<AppState>);
    return actions$.pipe(
      ofType(QueueActions.processNextItem),
      switchMap(() =>
        forkJoin([store.pipe(select(queueNextItemSelector), take(1))])
      ),
      mergeMap(([item]) =>
        item
          ? item.operation$.pipe(
              takeUntil(
                actions$.pipe(
                  ofType(QueueActions.itemRemoved),
                  map(action => action.id === item.id)
                )
              ),
              tap(response => {
                if (
                  response instanceof FileProgressResponse &&
                  response?.status === 'progress' &&
                  response?.message
                ) {
                  store.dispatch(
                    QueueActions.itemUpdate({
                      item: {
                        id: item.id,
                        changes: {
                          ...item,
                          progress: response?.message,
                          state: 'processing',
                        },
                      },
                    })
                  );
                }
              }),
              last(),
              tap({
                next: (
                  response:
                    | FileProgressResponse
                    | NetworkProgressResponse
                    | Observable<
                        | string
                        | { status: string; loaded?: number; file?: Blob }
                      >
                    | FileDownloadProgressResponse
                ) => {
                  if (
                    response instanceof FileProgressResponse &&
                    response.fileItem
                  ) {
                    store.dispatch(
                      new SingleFileHistoryLoaded({
                        fileItem: {
                          ...response.fileItem,
                          lastModifiedBy: `${
                            store.selectSignal(accountSelector)().firstName
                          } ${store.selectSignal(accountSelector)().lastName}`,
                          updatedAt: new Date(),
                          cloudStorage: false,
                        },
                      })
                    );
                  }
                  if (
                    response instanceof NetworkProgressResponse &&
                    response.networkItem
                  ) {
                    store.dispatch(
                      new GetNewNetworksRelations({ state: true })
                    );
                  }

                  if (response instanceof FileDownloadProgressResponse) {
                    // NOTE 'response instanceof FileDownloadProgressResponse'
                  }

                  if (item.callback) {
                    item.callback(response);
                  }
                },
              }),
              map(() =>
                QueueActions.itemCompletes({
                  item: {
                    id: item.id,
                    changes: { ...item, progress: 100, state: 'success' },
                  },
                })
              ),
              catchError((error: HttpErrorResponse) =>
                of(
                  QueueActions.itemCompletes({
                    item: {
                      id: item.id,
                      changes: {
                        ...item,
                        progress: 100,
                        state: 'fail',
                        error: error?.message,
                      },
                    },
                  })
                )
              )
            )
          : of(QueueActions.processCompletes())
      )
    );
  },
  { functional: true }
);

export const queueItemCompletes$ = createEffect(
  () => {
    const actions$ = inject(Actions);
    const store = inject(Store<AppState>);
    return actions$.pipe(
      ofType(QueueActions.itemCompletes),
      switchMap(() =>
        forkJoin([store.pipe(select(queueNextItemSelector), take(1))])
      ),
      map(([item]) =>
        item ? QueueActions.processNextItem() : QueueActions.processCompletes()
      )
    );
  },
  { functional: true }
);

// TODO working on this. When the queue is completed by networks items.
export const getNewNetworksRelations$ = createEffect(
  () => {
    const actions$ = inject(Actions);
    const store = inject(Store<AppState>);
    return actions$.pipe(
      ofType(QueueActions.processCompletes),
      tap(() => {
        const getNewNetworksRelations = store.selectSignal(
          gettingNetworksQueueSelector
        )();

        if (getNewNetworksRelations) {
          store.dispatch(new MyTeamsRequested());
          store.dispatch(new GetNewNetworksRelations({ state: false }));
          store.dispatch(
            NetworkListActions.requested({
              sideDrawerId: store.selectSignal(activeSideDrawerSelector)()?.id,
            })
          );
        }
      })
    );
  },
  { functional: true, dispatch: false }
);

export const queueProcessCompletes$ = createEffect(
  () => {
    const actions$ = inject(Actions);
    const store = inject(Store<AppState>);
    const dialog = inject(MatDialog);
    return actions$.pipe(
      ofType(QueueActions.processCompletes),
      switchMap(() =>
        forkJoin([
          store.pipe(select(queueTotalItemsSelector), take(1)),
          store.pipe(select(queueCurrentItemSelector), take(1)),
          store.pipe(
            select(queueItemsByStateSelector({ state: 'fail' })),
            take(1),
            map(failedItems => failedItems?.length ?? 0)
          ),
        ])
      ),
      switchMap(([total, processingItem, failedItems]) =>
        !processingItem
          ? total !== 0 && failedItems !== 0
            ? dialog
                .open(QueueResultDialogComponent, {
                  autoFocus: false,
                })
                .afterClosed()
                .pipe(map(() => QueueActions.resetProcess()))
            : of(QueueActions.resetProcess())
          : EMPTY
      )
    );
  },
  { functional: true }
);

export const queueItemRemoved$ = createEffect(
  () => {
    const actions$ = inject(Actions);
    const store = inject(Store<AppState>);
    return actions$.pipe(
      ofType(QueueActions.itemRemoved),
      switchMap(() =>
        forkJoin([
          store.pipe(select(queueTotalItemsSelector), take(1)),
          store.pipe(select(queueCurrentItemSelector), take(1)),
          store.pipe(select(queueWindowsStatusSelector), take(1)),
        ])
      ),
      map(([total, currentItem, windowsStatus]) => {
        if (total === 0) {
          return QueueActions.resetProcess();
        }
        if (!currentItem) {
          return QueueActions.processNextItem();
        }
        return QueueActions.widowStatusChange({ windowStatus: windowsStatus });
      })
    );
  },
  { functional: true }
);

export const queueWindowsStatusChange$ = createEffect(
  () => {
    const actions$ = inject(Actions);
    const store = inject(Store<AppState>);
    return actions$.pipe(
      ofType(QueueActions.widowStatusChange, QueueActions.resetProcess),
      switchMap(() =>
        forkJoin([store.pipe(select(queueWindowsStatusSelector), take(1))])
      ),
      tap(([windowStatus]) => {
        let verticalPadding = 20;
        if (windowStatus === 'maximized') {
          verticalPadding = 340 + 56 + 20;
        }
        if (windowStatus === 'minimized') {
          verticalPadding = 56 + 20;
        }
        if (window.Intercom) {
          window.Intercom('update', {
            app_id: environment.intercomAppId,
            horizontal_padding: 20,
            vertical_padding: verticalPadding,
          });
        }
      })
    );
  },
  {
    functional: true,
    dispatch: false,
  }
);
