export class To {
  constructor(
    public openIds?: string[],
    public emails?: string[]
  ) {
    if (!openIds) {
      openIds = [];
    }
    if (!emails) {
      emails = [];
    }
  }
}
