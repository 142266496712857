<div
  [attr.role]="role ?? 'dialog'"
  [ngClass]="{
    'max-width-none': type === dialogTemplateTypes.Form,
    'max-width-default': type === dialogTemplateTypes.Info && !customMaxWidth
  }"
  [ngStyle]="{
    'max-width':
      type === dialogTemplateTypes.Info && customMaxWidth
        ? customMaxWidth
        : 'none'
  }"
  class="sd-dialog-template">
  <header *ngIf="showHeader" class="sd-dialog-template-header" mat-dialog-title>
    <ng-content select="[dialog-header]"></ng-content>
    <!-- TODO ADD DICTIONARY FOR CLOSE BUTTON -->
    <app-sd-svg-a11y
      (click)="closeButtonClicked.emit(true)"
      (keydown.enter)="closeButtonClicked.emit(true)"
      [color]="sdColorPalette.primaryColor"
      [height]="1.5"
      [setSdAccessibility]="{
        role: 'button',
        ariaLabel: 'globalparams_close' | dictionary | async
      }"
      [src]="cdn + ('globalparams_closeicon' | dictionary | async)"
      [width]="1.5"></app-sd-svg-a11y>
  </header>
  <div *ngIf="showHeader" class="spacer border"></div>
  <main *ngIf="showBody" class="sd-dialog-template-body">
    <mat-dialog-content *ngIf="!spinner">
      <ng-content select="[dialog-body]"></ng-content>
    </mat-dialog-content>
    <mat-dialog-content *ngIf="spinner" class="sd-dialog-template-body-spinner">
      <app-sd-progress-spinner-a11y />
    </mat-dialog-content>
  </main>
  <div *ngIf="showFooter && !spinner" class="spacer border"></div>
  <div *ngIf="showFooter && !spinner" class="sd-dialog-template-footer">
    <mat-dialog-actions align="end">
      <ng-content select="[dialog-footer]"></ng-content>
    </mat-dialog-actions>
  </div>
</div>
