import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { DateHelper } from 'src/app/core/helpers/date.helper';
import { PaginatorApiResponse } from 'src/app/core/models/paginator-api-response.dto';
import { RecordListItem } from 'src/app/records/models/record-list-item.model';
import { Record } from 'src/app/records/models/record.model';
import { SfrSortOption } from '../models/enums/sfr-sort-option.enum';
import {
  SimpleFileRequestActions,
  SimpleFileRequestActionsTypes,
  SimpleFileRequestDeleteRecord,
} from './sfr.action';

export const featureName = 'simple-file-request';

export interface SimpleFileRequestListState extends EntityState<Record> {
  gettingSFR: boolean;
  requestsInformation: Map<
    string,
    {
      hasMore: boolean;
      nextPage: string;
      previousPage: string;
      totalCount: number;
    }
  >;
}

export const simpleFileRequestListAdapter: EntityAdapter<RecordListItem> =
  createEntityAdapter<Record>({
    selectId: simpleFileRequest => simpleFileRequest.id,
  });

export const initialSimpleFileRequestListState: SimpleFileRequestListState =
  simpleFileRequestListAdapter.getInitialState({
    gettingSFR: false,
    requestsInformation: new Map(),
  });

export function SimpleFileRequestListReducer(
  state: SimpleFileRequestListState = initialSimpleFileRequestListState,
  action: SimpleFileRequestActions
): SimpleFileRequestListState {
  switch (action.type) {
    case SimpleFileRequestActionsTypes.SimpleFileRequestLoaded: {
      const aux = new Map(state.requestsInformation);
      aux.set(action.payload.sideDrawerId, {
        totalCount: action.payload.data.totalCount,
        previousPage: action.payload.data.previousPage,
        nextPage: action.payload.data.nextPage,
        hasMore: action.payload.data.hasMore,
      });
      return {
        ...state,
        ...simpleFileRequestListAdapter.setAll(
          sortSFRList(action.payload.data, action.payload.sortBy),
          state
        ),
        requestsInformation: aux,
      };
    }
    case SimpleFileRequestActionsTypes.SimpleFileRequestLoadedForAppend: {
      const aux = new Map(state.requestsInformation);
      aux.set(action.payload.sideDrawerId, {
        totalCount: action.payload.data.totalCount,
        previousPage: action.payload.data.previousPage,
        nextPage: action.payload.data.nextPage,
        hasMore: action.payload.data.hasMore,
      });
      return {
        ...state,
        ...simpleFileRequestListAdapter.upsertMany(
          sortSFRList(action.payload.data, action.payload.sortBy),
          state
        ),
        requestsInformation: aux,
      };
    }
    case SimpleFileRequestActionsTypes.SimpleFileRequestSpinnerChange:
      return {
        ...state,
        gettingSFR: action.payload.spinner,
      };
    case SimpleFileRequestActionsTypes.SimpleFileRequestDeleteRecord:
      return simpleFileRequestListAdapter.removeOne(action.payload.id, state);
    case SimpleFileRequestActionsTypes.SimpleFileRequestClearData:
      return initialSimpleFileRequestListState;
    default:
      return state;
  }
}

export const { selectAll } = simpleFileRequestListAdapter.getSelectors();

const sortSFRList = (
  list: PaginatorApiResponse<RecordListItem>,
  sortBy: SfrSortOption
): RecordListItem[] => {
  try {
    if (!!list && list.data.length > 1) {
      const sortedList = [...list.data];
      switch (sortBy) {
        case SfrSortOption.lastModified:
          return sortedList.sort((a, b) =>
            DateHelper.compareDates(b.updatedAt, a.updatedAt)
          );
        case SfrSortOption.nameAscending:
          return sortedList.sort((a, b) => a.name.localeCompare(b.name));
        case SfrSortOption.nameDescending:
          return sortedList.sort((a, b) => b.name.localeCompare(a.name));
        case SfrSortOption.lastModifiedByAscending:
          return sortedList.sort((a, b) =>
            a.lastModifiedBy.localeCompare(b.lastModifiedBy)
          );
        case SfrSortOption.lastModifiedByDescending:
          return sortedList.sort((a, b) =>
            b.lastModifiedBy.localeCompare(a.lastModifiedBy)
          );
        default:
          return sortedList;
      }
    } else {
      return list.data;
    }
  } catch (e) {
    return list.data;
  }
};
