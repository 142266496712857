import { Relation } from '../../records/models/relation.model';
import { ContributorType } from './contributor-type.model';
import { SidedrawerRoles } from '../../core/roles/sidedrawer.roles';
import { RecordsRoles } from '../../core/roles/records.roles';
import { NetworkType } from './network-type.model';
import { Contributor } from '../../records/models/contributor.model';

export interface Network {
  id: string;
  relation: Relation;
  sidedrawer: string;
  sidedrawerRole?: SidedrawerRoles;
  record?: string;
  recordRole?: RecordsRoles;
  name?: string;
  email?: string;
  openId?: string;
  phoneNumber?: string;
  profilePhoto?: string;
  contributorType: ContributorType;
  networkType: NetworkType;
  expiryDate?: Date;
  contributor?: Contributor;
}

export class NetworkClass implements Network {
  id: string;
  relation: Relation;
  sidedrawer: string;
  sidedrawerRole?: SidedrawerRoles;
  record?: string;
  recordRole?: RecordsRoles;
  name?: string;
  email?: string;
  openId?: string;
  phoneNumber?: string;
  profilePhoto?: string;
  contributorType: ContributorType;
  networkType: NetworkType;
  expiryDate?: Date;

  constructor(network: Network) {
    this.id = network.id;
    this.relation = network.relation;
    this.sidedrawer = network.sidedrawer;
    this.sidedrawerRole = network.sidedrawerRole;
    this.record = network.record;
    this.recordRole = network.recordRole;
    this.name = network.name;
    this.email = network.email;
    this.openId = network.openId;
    this.phoneNumber = network.phoneNumber;
    this.profilePhoto = network.profilePhoto;
    this.contributorType = network.contributorType;
    this.networkType = network.networkType;
    this.expiryDate = network?.expiryDate;
  }
}
