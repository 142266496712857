import { createSelector } from '@ngrx/store';
import { selectAll, selectEntities } from './side-drawer-network-list.reducer';
import { getSideDrawerRolesOrdinalToSort } from '../../core/roles/sidedrawer.roles';
import { UtilsHelper } from '../../core/helpers/utils.helper';
import { sideDrawerNetworkStateSelector } from './side-drawer-network.selectors';

export const sideDrawerNetworkListStateSelector = createSelector(
  sideDrawerNetworkStateSelector,
  state => state.list
);

export const sideDrawerNetworkListSelector = createSelector(
  sideDrawerNetworkListStateSelector,
  selectAll
);

export const sideDrawerNetworkEntitiesSelector = createSelector(
  sideDrawerNetworkListStateSelector,
  selectEntities
);

export const sideDrawerNetworkByIdSelector = (payload: { id: string }) =>
  createSelector(
    sideDrawerNetworkEntitiesSelector,
    entities => entities[`${payload.id}`]
  );

export const sideDrawerNetworkListSortedSelector = createSelector(
  sideDrawerNetworkListSelector,
  sdNetworks =>
    sdNetworks.sort((a, b) =>
      UtilsHelper.compareNumbers(
        getSideDrawerRolesOrdinalToSort(a.sdRole),
        getSideDrawerRolesOrdinalToSort(b.sdRole)
      )
    )
);
