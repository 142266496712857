import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-integrations-section-item',
  templateUrl: './integrations-section-item.component.html',
  styleUrls: ['./integrations-section-item.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntegrationsSectionItemComponent {
  @Input() iconEnabled: string;
  @Input() iconDisabled: string;
  @Input() text: string;
  @Input() disabled: boolean;
  @Output() clicked = new EventEmitter<boolean>();
}
