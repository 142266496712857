import { Component, Inject } from '@angular/core';
import { SdDialogTemplateA11yModule } from 'src/app/shared/templates/dialog-template-a11y/sd-dialog-template-a11y.module';
import { SdFlatButtonA11yModule } from 'src/app/shared/sd-flat-button-a11y/sd-flat-button-a11y.module';
import { DialogTemplateTypes } from 'src/app/shared/templates/enums/templates.enum';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { SdValidators } from 'src/app/shared/sd-forms/models/sd.validators';
import { DictionaryPipeModule } from 'src/app/dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { SdInputA11yTemplates } from 'src/app/shared/sd-input-a11y/models/sd-input-a11y-templates.enum';
import { environment } from 'src/environments/environment';
import { FileRenameStore } from './file-rename-dialog.store';
import { FileItem } from 'src/app/files/models/file-item.model';
import { of } from 'rxjs';
import { SdFormHelper } from 'src/app/core/helpers/sd-form.helper';
import { SdValidationFormErrors } from 'src/app/account/models/sd-validation-form-errors.model';
import { AccessibilityHelper } from 'src/app/core/helpers/accessibility.helper';
import { AppState } from 'src/app/reducers';
import { Store } from '@ngrx/store';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { AsyncPipe } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SdInputA11yComponent } from 'src/app/shared/sd-input-a11y/components/sd-input-a11y/sd-input-a11y.component';

@Component({
  selector: 'app-file-rename-dialog',
  styleUrls: ['./file-rename-dialog.component.scss'],
  standalone: true,
  imports: [
    SdDialogTemplateA11yModule,
    SdInputA11yComponent,
    SdFlatButtonA11yModule,
    DictionaryPipeModule,
    AsyncPipe,
  ],
  providers: [FileRenameStore],
  template: `
    <app-sd-dialog-template-a11y
      (closeButtonClicked)="onClose()"
      [type]="DialogTemplateTypes.Form"
      class="dialog">
      <ng-container dialog-header>
        <h2>
          {{ 'filerenamedialog_title' | dictionary | async }}
        </h2>
      </ng-container>
      <ng-container dialog-body>
        <div class="rename-dialog-body">
          <app-sd-input-a11y
            (suffixClicked)="nameControl.reset()"
            [suffixSvg]="cdn + ('globalparams_reseticon' | dictionary | async)"
            [controller]="nameControl"
            [setSdAccessibility]="{
              ariaLabel:
                'filerenamedialog_personalizednameplaceholder'
                | dictionary
                | async,
              id: 'name'
            }"
            [error]="
              isSubmitted
                ? ('filerenamedialog_personalizednameplaceholder' | dictionary | async) + ' ' + (getErrorMessage(nameControl) | dictionary | async) ?? ''
                : ''
            "
            [placeholder]="
              'filerenamedialog_personalizednameplaceholder'
                | dictionary
                | async
            "
            [template]="SdInputA11yTemplates.inputWithSuffix"
            [tooltip]="
              'filerenamedialog_personalizednametooltip' | dictionary | async
            "
            [sdAccessibilityForSuffix]="{
              ariaLabel: 'globalparams_reset' | dictionary | async,
              role: 'button'
            }"
            class="personalized-name-input" />
        </div>
      </ng-container>
      <ng-container dialog-footer>
        <app-sd-flat-button-a11y (buttonClicked)="onClose()" [primary]="false">
          {{
            'globalparams_cancel' | dictionary | async
          }}</app-sd-flat-button-a11y
        >
        <app-sd-flat-button-a11y
          [loading]="fileRenameStore.gettingInformation$ | async"
          (buttonClicked)="onConfirm()"
          [primary]="form.valid && form.dirty"
          [inactiveButtonClass]="true">
          {{
            'globalparams_confirm' | dictionary | async
          }}</app-sd-flat-button-a11y
        >
      </ng-container>
    </app-sd-dialog-template-a11y>
  `,
})
export class FileRenameDialogComponent {
  DialogTemplateTypes = DialogTemplateTypes;
  nameControl = new FormControl<string>(null, [
    Validators.required,
    SdValidators.emptyCharacters.bind(this),
    Validators.maxLength(120),
  ]);
  SdInputA11yTemplates = SdInputA11yTemplates;
  cdn = environment.cdn;
  form: UntypedFormGroup;
  isSubmitted = false;
  errors: SdValidationFormErrors;

  public constructor(
    public fileRenameStore: FileRenameStore,
    private readonly store: Store<AppState>,
    private readonly liveannouncer: LiveAnnouncer,
    private readonly dialogRef: MatDialogRef<FileRenameDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      item: FileItem;
    }
  ) {
    this.setFormGroup();
  }

  onConfirm(): void {
    this.isSubmitted = true;
    this.setErrorsForms();
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      AccessibilityHelper.announceMessage(
        this.store,
        this.errors,
        this.liveannouncer,
        ''
      );
      return;
    }
    this.fileRenameStore.renameFile(
      of({
        item: this.data.item,
        form: this.form,
        gettingInformation: true,
        dialogRef: this.dialogRef,
      })
    );
  }

  onClose(): void {
    this.dialogRef?.close();
  }

  getErrorMessage(control: AbstractControl): string {
    return control.errors?.maxlength
      ? 'globalparams_maxlengtherror'
      : 'globalparams_requirederror';
  }

  private setFormGroup(): void {
    this.form = new FormGroup({
      name: this.nameControl,
    });
    this.fileRenameStore.patchState({
      item: this.data.item,
    });
    this.fileRenameStore.patchState({
      dialogRef: this.dialogRef,
    });
    this.form.get('name').setValue(this.data?.item?.uploadTitle);
  }

  private setErrorsForms(): void {
    this.errors = {
      name:
        SdFormHelper.getErrorFormControl(
          this.form,
          'name',
          'required',
          'globalparams_requirederror'
        ) ??
        SdFormHelper.getErrorFormControl(
          this.form,
          'name',
          'maxlength',
          'globalparams_maxlengtherror'
        ),
    };
  }
}
