import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureName, SdCacheState, selectEntities } from './sd-cache.reducer';

export const sdCacheStateSelector =
  createFeatureSelector<SdCacheState>(featureName);

export const sdCacheEntitiesSelector = createSelector(
  sdCacheStateSelector,
  selectEntities
);

export const sdCacheElementSelector = (payload: { key: string }) =>
  createSelector(
    sdCacheEntitiesSelector,
    entities => entities[payload.key]?.windowUrl
  );
