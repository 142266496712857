import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-records-upload-buttons-section-snackbar',
  templateUrl: './records-upload-buttons-section-snackbar.component.html',
  styleUrls: ['./records-upload-buttons-section-snackbar.component.scss'],
})
export class RecordsUploadButtonsSectionSnackbarComponent {
  cdn = environment.cdn;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: { title: string; color: string }
  ) {}
}
