import { Component, Input, TemplateRef } from '@angular/core';
import { DictionaryPipeModule } from '../../dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { AsyncPipe, NgIf, NgTemplateOutlet } from '@angular/common';
import { SdTooltipButtonA11yComponent } from '../sd-tooltip-button-a11y/component/sd-tooltip-button-a11y/sd-tooltip-button-a11y.component';

@Component({
  selector: 'app-sd-record-tab-header',
  standalone: true,
  imports: [
    DictionaryPipeModule,
    AsyncPipe,
    SdTooltipButtonA11yComponent,
    NgTemplateOutlet,
    NgIf,
  ],
  templateUrl: './sd-record-tab-header.component.html',
  styleUrls: ['./sd-record-tab-header.component.scss'],
})
export class SdRecordTabHeaderComponent {
  @Input() ariaLabel?: string;
  @Input() title: string;
  @Input() tooltip: string;
  @Input() actionsTemplate: TemplateRef<unknown>;
}
