<div *ngIf="hasSdPermissions()" class="record-network-list">
  <div class="record-network-list-header">
    <div
      [attr.aria-label]="
        ('sidedrawernetworklist_title' | dictionarySignal) +
        ' ' +
        ('sidedrawernetworklist_tooltip' | dictionarySignal)
      "
      class="record-network-list-header-title"
      role="heading"
      aria-level="3">
      {{ 'sidedrawernetworklist_title' | dictionarySignal }}

      <app-sd-tooltip-button-a11y
        [tooltip]="'sidedrawernetworklist_tooltip' | dictionarySignal" />
    </div>
  </div>

  <ng-container>
    <div *ngIf="gettingInformation()" class="record-network-list-no-spinner">
      <app-sd-progress-spinner-a11y />
    </div>

    <div
      *ngIf="!gettingInformation() && networks()?.length === 0"
      class="record-network-list-no-collaborators">
      <div class="record-network-list-no-collaborators-message">
        {{ 'sidedrawernetworklist_nocollaboratorsmessage' | dictionarySignal }}
      </div>
    </div>

    <div *ngIf="networks()?.length > 0" class="record-network-list-content">
      <app-sd-infinite-list
        (loadMoreClicked)="onNextPage()"
        (loadMoreReached)="onNextPage()"
        [hasMore]="hasMore()"
        [loading]="networks()?.length > 0 && gettingInformation()">
        <ng-container *ngFor="let network of networks(); trackBy: trackBy">
          <app-network-list-item
            [setCloseModalParent]="closeModalParent"
            (closeModalParentEmitter)="closeModalParentEmitter.emit($event)"
            [network]="{
              network: network,
              networkEntity: NetworkEntity.sideDrawer
            }" />
        </ng-container>
      </app-sd-infinite-list>
    </div>
  </ng-container>
</div>
