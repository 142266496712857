import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/reducers';
import { ErrorType } from 'src/app/reminders/models/enums/error-type.enum';
import { SfrError } from 'src/app/reminders/models/sfr-error.model';
import { CommonModule } from '@angular/common';
import { DictionaryPipeModule } from 'src/app/dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { SdFlatButtonA11yModule } from 'src/app/shared/sd-flat-button-a11y/sd-flat-button-a11y.module';
import { SdDialogTemplateA11yModule } from 'src/app/shared/templates/dialog-template-a11y/sd-dialog-template-a11y.module';
import { DialogTemplateTypes } from 'src/app/shared/templates/enums/templates.enum';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-reminders-form-info-dialog',
  templateUrl: './reminders-form-info-dialog.component.html',
  styleUrls: ['./reminders-form-info-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    SdDialogTemplateA11yModule,
    SdFlatButtonA11yModule,
    DictionaryPipeModule,
  ],
})
export class RemindersFormInfoDialogComponent implements OnInit {
  errorType: ErrorType;
  errorReminder: boolean;
  errorCollaboratorRecord: boolean;
  dialogTemplateTypes = DialogTemplateTypes;

  constructor(
    private readonly store: Store<AppState>,
    private readonly dialogRef: MatDialogRef<RemindersFormInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { errors: Map<string, SfrError> }
  ) {}

  ngOnInit(): void {
    this.data.errors.forEach((error, key) => {
      if (key === ErrorType.errorReminder) {
        this.errorReminder = true;
      }
      if (key === ErrorType.errorCollaboratorRecord) {
        this.errorCollaboratorRecord = true;
      }
    });
  }

  onClose(): void {
    if (!!this.data.errors && this.data.errors.size > 0) {
      this.dialogRef.close(true);
      return;
    }
    if (!!this.data.errors && this.data.errors.size === 0) {
      this.dialogRef.close(false);
      return;
    }
  }
}
