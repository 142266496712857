import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CoreRoutes } from './routes/core.routes';

const routes: Routes = [
  {
    path: CoreRoutes.main,
    loadChildren: () =>
      import('./templates/core-template/core-template.module').then(
        m => m.CoreTemplateModule
      ),
  },
  {
    path: CoreRoutes.error + '/:dictionaryFails',
    loadChildren: () =>
      import('./views/sd-general-error-a11y/sd-general-error-a11y.module').then(
        m => m.SdGeneralErrorA11yModule
      ),
  },
  {
    path: CoreRoutes.error,
    loadChildren: () =>
      import('./views/sd-general-error-a11y/sd-general-error-a11y.module').then(
        m => m.SdGeneralErrorA11yModule
      ),
  },
  {
    path: CoreRoutes.forbidden,
    loadComponent: () =>
      import('./views/forbidden-view/forbidden.view').then(
        m => m.ForbiddenView
      ),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CoreRoutingModule {}
