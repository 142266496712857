import { createSelector } from '@ngrx/store';
import { filesStateSelector } from './files.selectors';
import { selectAll, selectEntities } from './cloud-folders-files.reducer';

export const cloudFoldersFilesStateSelector = createSelector(
  filesStateSelector,
  state => state.cloudFoldersFiles
);

export const cloudFoldersFilesListSelector = createSelector(
  cloudFoldersFilesStateSelector,
  selectAll
);

export const gettingCloudFoldersFilesSelector = createSelector(
  cloudFoldersFilesStateSelector,
  state => state.gettingCloudFoldersFiles
);

export const cloudFoldersFilesEntitiesSelector = createSelector(
  cloudFoldersFilesStateSelector,
  selectEntities
);

export const cloudFoldersFilesEntityByIdSelector = (payload: { id: string }) =>
  createSelector(
    cloudFoldersFilesEntitiesSelector,
    entities => entities[payload.id]
  );
