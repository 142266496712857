export enum SidedrawerRoutesEnum {
  root = 'my-sd',
  main = '',
  create = 'create',
  manage = 'manage',
  collaborator = 'collaborator',
  transfer = 'transfer',
  reminders = 'reminders',
  settings = 'my-account-settings',
  sidedrawer = 'sidedrawer',
}

export function getSidedrawerRoute(route: SidedrawerRoutesEnum): string {
  return route.length > 0
    ? SidedrawerRoutesEnum.root + '/' + route
    : SidedrawerRoutesEnum.root;
}
