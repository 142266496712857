<div class="sync-from-cloud-dialog">
  <button
    (click)="onClose()"
    (keyup.enter)="onClose()"
    class="sync-from-cloud-dialog-close-button"
    [attr.aria-label]="'globalparams_close' | dictionary | async"
    mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>

  <div class="sync-from-cloud-dialog-title">
    <button
      [attr.aria-label]="data.provider"
      (click)="getDriveContent()"
      class="sync-from-cloud-dialog-title-image-button"
      mat-button>
      <img
        [alt]="data.provider"
        [src]="cdn + 'svgs/' + data.provider + '-on.png'"
        class="sync-from-cloud-dialog-title-image" />
    </button>

    <mat-icon>arrow_forward_ios</mat-icon>

    <button
      [attr.aria-label]="
        'syncfromclouddialog_previousfolder' | dictionary | async
      "
      (click)="getPreviousFolder()"
      (keyup.enter)="onClose()"
      *ngIf="level.length > 1"
      class="sync-from-cloud-dialog-title-image-button"
      mat-button>
      ...
    </button>

    <mat-icon *ngIf="level.length > 1">arrow_forward_ios</mat-icon>

    <div *ngIf="level.length > 0" class="sync-from-cloud-dialog-title-name">
      {{ level[level.length - 1]?.name }}
    </div>
  </div>

  <div *ngIf="spinner" class="sync-from-cloud-dialog-spinner">
    <app-sd-progress-spinner-a11y />
  </div>
  <div *ngIf="!spinner" class="sync-from-cloud-dialog-body">
    <cdk-virtual-scroll-viewport
      class="sync-from-cloud-dialog-body-scroller"
      itemSize="54">
      <app-sync-from-cloud-item
        (checkboxClicked)="onSelect(item)"
        (folderClicked)="onFolderClicked(item)"
        *cdkVirtualFor="let item of folders"
        [disabled]="buttonSpinner"
        [driveItem]="item"
        [selected]="isSelected(item) | async" />
    </cdk-virtual-scroll-viewport>

    <div class="sync-from-cloud-dialog-body-details">
      {{ 'syncfromclouddialog_description' | dictionary | async }}
    </div>
    <div class="sync-from-cloud-dialog-body-button">
      <app-sd-flat-button-a11y
        (buttonClicked)="onSync()"
        [disabled]="syncButtonState() | async"
        [loading]="buttonSpinner"
        [primary]="true"
        >{{
          'syncfromclouddialog_primarybutton' | dictionary | async
        }}</app-sd-flat-button-a11y
      >
    </div>
  </div>
</div>
