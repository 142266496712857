import { uploadFilesReducer, UploadFilesState } from './upload-files.reducer';
import { fileHistoryReducer, FileHistoryState } from './file-history.reducer';
import { ActionReducerMap } from '@ngrx/store';
import {
  cloudFoldersFilesReducer,
  CloudFoldersFilesState,
} from './cloud-folders-files.reducer';
import { fileItemReducer, FileItemState } from './file-item.reducer';

export const featureName = 'files';

export interface FilesState {
  uploadFiles: UploadFilesState;
  fileHistory: FileHistoryState;
  cloudFoldersFiles: CloudFoldersFilesState;
  fileItems: FileItemState;
}

export const filesReducer: ActionReducerMap<FilesState> = {
  uploadFiles: uploadFilesReducer,
  fileHistory: fileHistoryReducer,
  cloudFoldersFiles: cloudFoldersFilesReducer,
  fileItems: fileItemReducer,
};
