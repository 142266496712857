import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AlertTypes } from 'src/app/shared/sd-alerts/models/alert-types.enum';
import { UntypedFormControl } from '@angular/forms';
import { SdAccessibilitySetting } from '../../../../core/models/sd-accessibility-model';
import { AccessibilityHelper } from '../../../../core/helpers/accessibility.helper';

@Component({
  selector: 'app-sd-alert-box-a11y',
  templateUrl: './sd-alert-box-a11y.component.html',
  styleUrls: ['./sd-alert-box-a11y.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SdAlertBoxA11yComponent {
  @Input() message: string;
  @Input() type: AlertTypes = AlertTypes.info;
  @Input() horizontalPadding: number;
  @Input() verticalPadding: number;
  @Input() controller: UntypedFormControl;
  sdAccessibility: SdAccessibilitySetting;

  @Input() set setSdAccessibility(value: SdAccessibilitySetting) {
    this.sdAccessibility = AccessibilityHelper.setDefaultAccessibility(value);
  }

  // TODO add accessibility, initial focus, etc.

  getClass(): string {
    switch (this.type) {
      case AlertTypes.info:
        return 'alert alert-info';
      case AlertTypes.error:
        return 'alert alert-error';
      case AlertTypes.success:
        return 'alert alert-success';
      case AlertTypes.warning:
        return 'alert alert-warning';
    }
  }
}
