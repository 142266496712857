import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';

import { NetworkResourceType } from '../../models/network-resource-type.model';
import { ShareResourceDialogStore } from './share-resource-dialog.store';
import { map } from 'rxjs/operators';
import { UtilsHelper } from '../../../core/helpers/utils.helper';
import { NewNetworkRow } from '../../models/new-network-row.model';
import { NetworkFormRowComponent } from '../netowrk-form-row/network-form-row.component';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { SdDialogTemplateA11yModule } from '../../../shared/templates/dialog-template-a11y/sd-dialog-template-a11y.module';
import { DialogTemplateTypes } from '../../../shared/templates/enums/templates.enum';
import { DictionaryPipeModule } from '../../../dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { SdFlatButtonA11yModule } from '../../../shared/sd-flat-button-a11y/sd-flat-button-a11y.module';
import { ContributorType } from '../../models/contributor-type.model';
import { Relation } from '../../../records/models/relation.model';
import { Option } from 'src/app/shared/sd-forms/models/option.model';
import { SidedrawerNetworkListA11yComponent } from '../sidedrawer-network-list-a11y/sidedrawer-network-list-a11y.component';
import { RecordNetworkListA11yComponent } from '../record-network-list-a11y/record-network-list-a11y.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-share-resource-dialog',
  standalone: true,
  imports: [
    NetworkFormRowComponent,
    AsyncPipe,
    NgForOf,
    SdDialogTemplateA11yModule,
    DictionaryPipeModule,
    SdFlatButtonA11yModule,
    NgIf,
    SidedrawerNetworkListA11yComponent,
    RecordNetworkListA11yComponent,
  ],
  templateUrl: './share-resource-dialog.component.html',
  styleUrls: ['./share-resource-dialog.component.scss'],
  providers: [ShareResourceDialogStore],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareResourceDialogComponent {
  rows$ = this.dialogStore.rows$.pipe(
    map(rows =>
      UtilsHelper.mapToArray(rows).sort((a, b) =>
        UtilsHelper.compareNumbers(a.id, b.id)
      )
    )
  );
  DialogTemplateTypes = DialogTemplateTypes;
  networkResourceType = NetworkResourceType;
  isSubmitted = false;

  constructor(
    public readonly dialogStore: ShareResourceDialogStore,
    private readonly dialogRef: MatDialogRef<ShareResourceDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      resourceType: NetworkResourceType;
      resourceName: string;
      sideDrawerId?: string;
      recordId?: string;
      recordTypeName?: string;
      infoRequestId?: string;
    }
  ) {
    dialogStore.getInitialInformation(data);
  }

  onCreateRow(id: number): void {
    this.dialogStore.createRow(id);
  }

  onDeleteRow(id: number): void {
    this.dialogStore.deleteRow(id);
  }

  onUpdateRow(
    id: number,
    data: Partial<{
      email: string;
      team: string;
      contributor: ContributorType;
      fullName: string;
      relation: Relation;
      permission: Option;
      expiryDate: Date;
    }>
  ): void {
    const {
      email,
      contributor,
      relation,
      permission,
      fullName,
      team,
      expiryDate,
    } = data;

    this.dialogStore.updateRow({
      id,
      email,
      contributorType: contributor,
      teamId: team,
      fullName,
      relation,
      permission,
      expiryDate,
    });
  }

  trackBy(index: number, item: NewNetworkRow): number {
    return item.id;
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onConfirm(): void {
    this.isSubmitted = true;
    this.dialogStore.addRowsToQueue({
      callback: (rows, status) => {
        if (status) {
          this.dialogRef.close();
        }
      },
    });
  }
}
