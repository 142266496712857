import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { PlanRequested } from '../models/plan-requested.model';
import { PlansSortOption } from '../models/plans-sort-option.model';

export enum PlanRequestedListActionTypes {
  PlanRequestedRequested = '[] PlanRequested Requested',
  PlanRequestedLoaded = '[PlanRequested service] PlanRequested Loaded',
  PlanRequestedLoadedUpdated = '[PlanRequested service] PlanRequested Loaded Updated',
  PlanRequestedAdded = '[] PlanRequested item added',
  PlanRequestedUpdated = '[] PlanRequested item updated',
  PlanRequestedDeleted = '[] PlanRequested item deleted',
  PlanRequestedRemoved = '[] All PlanRequested deleted',
  PlanRequestedItemsRequested = '[] Plan Requested Items requested',
  PlanRequestedItemsLoaded = '[] Plan Requested Items loaded',
  PlanRequestedAlreadyCompleted = '[] Plan Requested Already Completed',
  PlanRequestedCopyLinkRequested = '[] Plan Requested Copy Link Requested',
  FilingCabinetAllPlansRequested = '[] Filing Cabinet All Plans Requested',
}

export class PlanRequestedRequested implements Action {
  readonly type = PlanRequestedListActionTypes.PlanRequestedRequested;

  constructor(
    public payload: {
      sidedrawerId: string;
      locale: string;
      sortBy: PlansSortOption;
      upsertMany?: boolean;
    }
  ) {}
}

export class PlanRequestedLoaded implements Action {
  readonly type = PlanRequestedListActionTypes.PlanRequestedLoaded;

  constructor(
    public payload: { plans: PlanRequested[]; sortBy: PlansSortOption }
  ) {}
}

export class PlanRequestedLoadedUpdated implements Action {
  readonly type = PlanRequestedListActionTypes.PlanRequestedLoadedUpdated;

  constructor(
    public payload: { plans: PlanRequested[]; sortBy: PlansSortOption }
  ) {}
}

export class PlanRequestedAdded implements Action {
  readonly type = PlanRequestedListActionTypes.PlanRequestedAdded;

  constructor(public payload: { item: PlanRequested }) {}
}

export class PlanRequestedUpdated implements Action {
  readonly type = PlanRequestedListActionTypes.PlanRequestedUpdated;

  constructor(public payload: { item: Update<PlanRequested> }) {}
}

export class PlanRequestedDeleted implements Action {
  readonly type = PlanRequestedListActionTypes.PlanRequestedDeleted;

  constructor(public payload: { id: string }) {}
}

export class PlanRequestedRemoved implements Action {
  readonly type = PlanRequestedListActionTypes.PlanRequestedRemoved;
}

export class PlanRequestedItemsRequested implements Action {
  readonly type = PlanRequestedListActionTypes.PlanRequestedItemsRequested;

  constructor(
    public payload: { sidedrawerId: string; planId: string; locale: string }
  ) {}
}

export class PlanRequestedItemsLoaded implements Action {
  readonly type = PlanRequestedListActionTypes.PlanRequestedItemsLoaded;

  constructor(public payload: { item: Update<PlanRequested> }) {}
}

export class PlanRequestedAlreadyCompleted implements Action {
  readonly type = PlanRequestedListActionTypes.PlanRequestedAlreadyCompleted;

  constructor(public payload: { alreadyCompleted: boolean }) {}
}

export class PlanRequestedCopyLinkRequested implements Action {
  readonly type = PlanRequestedListActionTypes.PlanRequestedCopyLinkRequested;

  constructor(public payload: { infoRequestId: string }) {}
}
export class FilingCabinetAllPlansRequested implements Action {
  readonly type = PlanRequestedListActionTypes.FilingCabinetAllPlansRequested;
}

export type PlanRequestedListActions =
  | PlanRequestedRequested
  | PlanRequestedLoaded
  | PlanRequestedLoadedUpdated
  | PlanRequestedAdded
  | PlanRequestedUpdated
  | PlanRequestedDeleted
  | PlanRequestedRemoved
  | PlanRequestedItemsRequested
  | PlanRequestedItemsLoaded
  | PlanRequestedAlreadyCompleted
  | PlanRequestedCopyLinkRequested
  | FilingCabinetAllPlansRequested;
