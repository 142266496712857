import { LiveAnnouncer } from '@angular/cdk/a11y';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { AccessibilityHelper } from 'src/app/core/helpers/accessibility.helper';
import { SdAccessibilityTags } from 'src/app/core/models/enums/sd-accessibility-enum';
import { SdColorPalette } from 'src/app/core/models/enums/sd-color-palette-enum';
import { SdAccessibilitySetting } from 'src/app/core/models/sd-accessibility-model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sd-selected-item-a11y',
  templateUrl: './sd-selected-item-a11y.component.html',
  styleUrls: ['./sd-selected-item-a11y.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SdSelectedItemA11yComponent {
  @Input() value: string;
  @Input() avatar: string;
  @Input() removable = true;
  @Input() icon: string;
  @Input() iconColor: string;
  @Input() iconHeight: number;
  @Input() iconWidth: number;
  sdColorPalette = SdColorPalette;
  cdn = environment.cdn;

  sdAccessibility: SdAccessibilitySetting;
  sdAccessibilityForSvg: SdAccessibilitySetting;
  sdAccessibilityTags = SdAccessibilityTags;
  @Output() removeButtonClicked = new EventEmitter<boolean>();

  constructor(private readonly liveAnnouncer: LiveAnnouncer) {}

  @Input() set setSdAccessibility(value: SdAccessibilitySetting) {
    this.sdAccessibility = AccessibilityHelper.setDefaultAccessibility(value);
  }

  @Input() set setSdAccessibilityForSvg(value: SdAccessibilitySetting) {
    this.sdAccessibilityForSvg =
      AccessibilityHelper.setDefaultAccessibility(value);
  }

  // TODO dictionary and accessibility for item removed
  contactRemoved(contactDescription: string): void {
    this.liveAnnouncer.announce('Item removed: ' + contactDescription);
  }
}
