import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { take, tap } from 'rxjs';
import { AccessibilityHelper } from 'src/app/core/helpers/accessibility.helper';
import { DateHelper } from 'src/app/core/helpers/date.helper';
import { SdAccessibilitySetting } from 'src/app/core/models/sd-accessibility-model';
import { Locale } from 'src/app/dictionary/models/locale.model';
import { DictionaryPipeModule } from 'src/app/dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import {
  dictionarySelector,
  frequencySelector,
} from 'src/app/dictionary/store/dictionary.selectors';
import { AppState } from 'src/app/reducers';
import { Frequency } from 'src/app/reminders/models/enums/frequency.enum';
import { ReminderFormRecurrenceShortDictionary } from 'src/app/reminders/models/reminder-form-recurrence-short-dictionary.model';
import { SdCheckboxA11yModule } from 'src/app/shared/sd-checkbox-a11y/sd-checkbox-a11y.module';
import { Option } from 'src/app/shared/sd-forms/models/option.model';
import { SdInputA11yComponent } from 'src/app/shared/sd-input-a11y/components/sd-input-a11y/sd-input-a11y.component';
import { SdSelectorA11Module } from 'src/app/shared/sd-selector-a11y/sd-selector-a11y.module';

@Component({
  selector: 'app-reminders-form-recurrence-a11y',
  templateUrl: './reminders-form-recurrence-a11y.component.html',
  styleUrls: ['./reminders-form-recurrence-a11y.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    SdInputA11yComponent,
    SdCheckboxA11yModule,
    SdSelectorA11Module,
    DictionaryPipeModule,
  ],
})
export class RemindersFormRecurrenceA11yComponent implements OnInit, OnChanges {
  @Input() recurrenceType: string;
  @Input() everyController: UntypedFormControl;
  @Input() patternController: UntypedFormControl;
  @Input() monthsController: UntypedFormControl;
  @Input() locale: Locale;
  @Input() recurrenceDayLabel: string;
  @Input() recurrenceMonthLabel: string;
  @Input() frequencyDescription: string;
  @Input() frequencylabel: string;
  @Input() frequencyMandatoryMessage: string;
  @Input() dateInput: Date | string;
  @Input() isSubmitted = false;
  @Output() monthSelectionOption = new EventEmitter<Option>();

  sdAccessibilityForPatternController: SdAccessibilitySetting;
  sdAccessibilityForMonthsController: SdAccessibilitySetting;
  isInvalidPattern = false;
  daysOfWeeks: { key: string; value: boolean; idEnglish?: string }[] = [];
  months: Option[] = [];
  monthDays: Option[] = [];
  reminderFormRecurrenceShortDictionary: ReminderFormRecurrenceShortDictionary =
    new ReminderFormRecurrenceShortDictionary();
  readonly maxEvery = 999;
  readonly minEvery = 1;
  frequency = Frequency;

  constructor(private store: Store<AppState>) {}

  @Input() set setSdAccessibilityForPatternController(
    value: SdAccessibilitySetting
  ) {
    this.sdAccessibilityForPatternController =
      AccessibilityHelper.setDefaultAccessibility(value);
  }

  @Input() set setSdAccessibilityForMonthsController(
    value: SdAccessibilitySetting
  ) {
    this.sdAccessibilityForMonthsController =
      AccessibilityHelper.setDefaultAccessibility(value);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['recurrenceType']?.currentValue === Frequency.Yearly) {
      this.setMonthDays();
    }
    if (changes['recurrenceType']?.currentValue === Frequency.Monthly) {
      this.setMonthDays();
    }
    if (changes['recurrenceType']?.currentValue === Frequency.Weekly) {
      this.setDaysOfWeeks();
    }
  }

  ngOnInit(): void {
    this.setFromDictionary();
    this.initDataSources();
  }

  onSelectionMonth(monthSelectionOption: unknown): void {
    if (monthSelectionOption instanceof Option) {
      this.setMonthDays(monthSelectionOption?.key);
      this.monthSelectionOption.emit(monthSelectionOption);
      return;
    }
  }

  dayOfWeekChanged(): void {
    const pattern = this.daysOfWeeks
      .filter(d => d.value)
      .map(f => f.key)
      .join(', ');
    this.isInvalidPattern = !pattern;
    this.patternController.setValue(pattern);
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    return !(charCode > 31 && (charCode < 48 || charCode > 57));
  }

  private initDataSources(): void {
    this.months = DateHelper.getMonths(this.locale).map(
      (m, i) => new Option(i + 1 <= 9 ? `0${i + 1}` : `${i + 1}`, m)
    );
    this.setMonthDays();
    if (this.recurrenceType === Frequency.Yearly) {
      const selectedMonthDays = this.patternController?.value?.split('/') ?? [];
      if (selectedMonthDays.length) {
        const [monthSelected, daySelected] = selectedMonthDays;
        const month = this.months.find(m => m.key === monthSelected);
        this.monthsController.setValue(month.key);
        this.patternController.setValue(daySelected);
      }
    }
  }

  private setMonthDays(month?: string): void {
    let year = null;
    let monthDefault = null;
    if (this.dateInput) {
      year = moment(this.dateInput).format('YYYY');
      monthDefault = moment(this.dateInput).format('MM');
    }
    if (!this.dateInput) {
      year = moment().format('YYYY');
      monthDefault = moment().format('MM');
    }
    const yearAndMonth = year + '-' + (month ? month : monthDefault);
    this.monthDays = [
      ...Array(DateHelper.getDaysInMonth(this.locale, yearAndMonth)).keys(),
    ].map(i =>
      (i += 1) <= 9 ? new Option(`0${i}`, `0${i}`) : new Option(`${i}`, `${i}`)
    );
  }

  private setDaysOfWeeks(): void {
    const selectedDaysOfWeeks = this.patternController?.value?.split(',') ?? [];
    this.daysOfWeeks = DateHelper.getDaysOfWeeks(this.locale, false).map(
      dow => ({
        key: dow,
        value: selectedDaysOfWeeks.filter((selectedDayOfWeek: string) => {
          const result =
            selectedDayOfWeek.trim() ===
            DateHelper.getNameDayInEnglish(this.locale, dow.trim());
          return result;
        }).length,
        idEnglish: DateHelper.getNameDayInEnglish(this.locale, dow),
      })
    );
    this.dayOfWeekChanged();
  }

  private setFromDictionary(): void {
    this.store
      .pipe(
        select(dictionarySelector),
        take(1),
        tap(dictionary => {
          this.reminderFormRecurrenceShortDictionary.sfrEndDateMandatoryMessage =
            dictionary.remindersformrecurrence_enddatemandatorymessage;
          this.reminderFormRecurrenceShortDictionary.sfrStartDateMandatoryMessage =
            dictionary.remindersformrecurrence_startdatemandatorymessage;
          this.reminderFormRecurrenceShortDictionary.sfrFieldMandatoryMessage =
            dictionary.globalparams_requirederror;
          this.reminderFormRecurrenceShortDictionary.customMessageFromDictionary =
            dictionary.remindersformrecurrence_custommessagesentence;
          this.reminderFormRecurrenceShortDictionary.sfrReminderCreationNoRepeatSentence =
            dictionary.remindersformrecurrence_norepeatsentence;
          this.reminderFormRecurrenceShortDictionary.sfrReminderCreationRepeatEndSentence =
            dictionary.remindersformrecurrence_repeatendsentence;
        })
      )
      .subscribe();

    this.store
      .pipe(
        select(frequencySelector),
        take(1),
        tap(frequencies => {
          if (frequencies) {
            frequencies.forEach(element => {
              switch (element?.frequency) {
                case String(Frequency.Daily).toLowerCase():
                  this.reminderFormRecurrenceShortDictionary.frequencyFromDictionaryDaily =
                    element?.frequencyLabel;
                  this.reminderFormRecurrenceShortDictionary.frequencyValueFromDictionaryDaily =
                    element?.frequencyValue;
                  this.reminderFormRecurrenceShortDictionary.frequencySentenceFromDictionaryDaily =
                    element?.frequencySentence;
                  this.reminderFormRecurrenceShortDictionary.frequencyRepeatOnFromDictionaryDaily =
                    element?.frequencyRepeatOn;
                  this.reminderFormRecurrenceShortDictionary.frequencyRepeatOfDaily =
                    element?.frequencyRepeatOf;
                  this.reminderFormRecurrenceShortDictionary.frequencySentenceNoEndDateDaily =
                    element?.frequencySentenceNoEndDate;
                  break;

                case String(Frequency.Weekly).toLowerCase():
                  this.reminderFormRecurrenceShortDictionary.frequencyFromDictionaryWeekly =
                    element?.frequencyLabel;
                  this.reminderFormRecurrenceShortDictionary.frequencyValueFromDictionaryWeekly =
                    element?.frequencyValue;
                  this.reminderFormRecurrenceShortDictionary.frequencySentenceFromDictionaryWeekly =
                    element?.frequencySentence;
                  this.reminderFormRecurrenceShortDictionary.frequencyRepeatOnFromDictionaryWeekly =
                    element?.frequencyRepeatOn;
                  this.reminderFormRecurrenceShortDictionary.frequencyRepeatOfWeekly =
                    element?.frequencyRepeatOf;
                  this.reminderFormRecurrenceShortDictionary.frequencySentenceNoEndDateWeekly =
                    element?.frequencySentenceNoEndDate;
                  break;

                case String(Frequency.Monthly).toLowerCase():
                  this.reminderFormRecurrenceShortDictionary.frequencyFromDictionaryMonthly =
                    element?.frequencyLabel;
                  this.reminderFormRecurrenceShortDictionary.frequencyValueFromDictionaryMonthly =
                    element?.frequencyValue;
                  this.reminderFormRecurrenceShortDictionary.frequencySentenceFromDictionaryMonthly =
                    element?.frequencySentence;
                  this.reminderFormRecurrenceShortDictionary.frequencyRepeatOnFromDictionaryMonthly =
                    element?.frequencyRepeatOn;
                  this.reminderFormRecurrenceShortDictionary.frequencyRepeatOfMonthly =
                    element?.frequencyRepeatOf;
                  this.reminderFormRecurrenceShortDictionary.frequencySentenceNoEndDateMonthly =
                    element?.frequencySentenceNoEndDate;
                  break;

                case String(Frequency.Yearly).toLowerCase():
                  this.reminderFormRecurrenceShortDictionary.frequencyFromDictionaryYearly =
                    element?.frequencyLabel;
                  this.reminderFormRecurrenceShortDictionary.frequencyValueFromDictionaryYearly =
                    element?.frequencyValue;
                  this.reminderFormRecurrenceShortDictionary.frequencySentenceFromDictionaryYearly =
                    element?.frequencySentence;
                  this.reminderFormRecurrenceShortDictionary.frequencyRepeatOnFromDictionaryYearly =
                    element?.frequencyRepeatOn;
                  this.reminderFormRecurrenceShortDictionary.frequencyRepeatOfYearly =
                    element?.frequencyRepeatOf;
                  this.reminderFormRecurrenceShortDictionary.frequencySentenceNoEndDateYearly =
                    element?.frequencySentenceNoEndDate;
                  break;

                default:
                  break;
              }
            });
          }
        })
      )
      .subscribe();
  }
}
