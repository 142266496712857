import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { SideDrawerNetworksRequest } from '../models/side-drawer-network-request.model';
import {
  SideDrawerNetworksRequestsInfiniteListActions,
  SideDrawerNetworksRequestsInfiniteListActionsTypes,
} from './side-drawer-networks-requests-infinite-list.actions';

export interface SideDrawerNetworksRequestsInfiniteListState
  extends EntityState<SideDrawerNetworksRequest> {
  gettingSideDrawerNetworks: boolean;
  itemsPerPage: number;
  infiniteListOrder: string[];
  lastRequestId: string;
}

export const sideDrawerNetworksRequestsInfiniteListAdapter: EntityAdapter<SideDrawerNetworksRequest> =
  createEntityAdapter<SideDrawerNetworksRequest>({
    selectId: sdNetworkRequest => sdNetworkRequest.id,
  });

export const initialSideDrawerNetworksRequestsInfiniteListState: SideDrawerNetworksRequestsInfiniteListState =
  sideDrawerNetworksRequestsInfiniteListAdapter.getInitialState({
    gettingSideDrawerNetworks: false,
    filter: null,
    itemsPerPage: 20,
    infiniteListOrder: [],
    lastRequestId: null,
  });

export function sideDrawerNetworksRequestsInfiniteListReducer(
  state: SideDrawerNetworksRequestsInfiniteListState = initialSideDrawerNetworksRequestsInfiniteListState,
  action: SideDrawerNetworksRequestsInfiniteListActions
): SideDrawerNetworksRequestsInfiniteListState {
  switch (action.type) {
    case SideDrawerNetworksRequestsInfiniteListActionsTypes.SideDrawerNetworksInfiniteListInitialPageLoaded:
    case SideDrawerNetworksRequestsInfiniteListActionsTypes.SideDrawerNetworksInfiniteListPageLoaded:
      return {
        ...sideDrawerNetworksRequestsInfiniteListAdapter.upsertOne(
          action.payload.result,
          state
        ),
        lastRequestId: action.payload.result.id,
      };
    case SideDrawerNetworksRequestsInfiniteListActionsTypes.GettingSideDrawerNetworksRequestsInfiniteListChange:
      return {
        ...state,
        gettingSideDrawerNetworks: action.payload.state,
      };
    case SideDrawerNetworksRequestsInfiniteListActionsTypes.SideDrawerNetworksInfiniteListOrderLoaded:
      return {
        ...state,
        infiniteListOrder: action.payload.infiniteListOrder,
      };
    default:
      return state;
  }
}

export const { selectAll, selectEntities } =
  sideDrawerNetworksRequestsInfiniteListAdapter.getSelectors();
