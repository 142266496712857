import { createSelector } from '@ngrx/store';
import { networkStateSelector } from '../networks.selectors';
import { selectAll } from './my-teams-list.reducer';

export const myTeamsListStateSelector = createSelector(
  networkStateSelector,
  state => state.myTeams
);

export const myTeamsListSelector = createSelector(
  myTeamsListStateSelector,
  selectAll
);

export const myTeamsByTeamIdSelector = (payload: { teamId: string }) =>
  createSelector(myTeamsListSelector, myTeams =>
    myTeams.find(myTeam => myTeam.id === payload.teamId)
  );
