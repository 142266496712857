import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import {
  featureName,
  sideDrawerNetworkReducer,
} from './store/side-drawer-network.reducer';
import { EffectsModule } from '@ngrx/effects';
import { SideDrawerNetworksRequestsInfiniteListEffects } from './store/side-drawer-networks-requests-infinite-list.effects';
import { SideDrawerNetworksRequestsEffects } from './store/side-drawer-networks-requests.effects';
import { SideDrawerNetworksStartFlowEffects } from './store/side-drawer-networks-start-flow.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(featureName, sideDrawerNetworkReducer),
    EffectsModule.forFeature([
      SideDrawerNetworksRequestsInfiniteListEffects,
      SideDrawerNetworksRequestsEffects,
      SideDrawerNetworksStartFlowEffects,
    ]),
  ],
})
export class SidedrawerNetworksModule {}
