import { PaginatorApiResponse } from '../../core/models/paginator-api-response.dto';
import { SideDrawerNetwork } from './side-drawer-network.model';
import { SideDrawerNetworkRequestType } from './side-drawer-network-request-type.enum';
import { SideDrawerNetworkRequestFilter } from './side-drawer-network-request-filter.model';

export interface SideDrawerNetworksRequestModel
  extends PaginatorApiResponse<SideDrawerNetwork> {
  id: string;
  filter?: SideDrawerNetworkRequestFilter;
  dataBaseRegion: string;
  requestType: SideDrawerNetworkRequestType;
}

export class SideDrawerNetworksRequest
  implements SideDrawerNetworksRequestModel
{
  id: string;

  constructor(
    public dataBaseRegion: string,
    public requestType: SideDrawerNetworkRequestType,
    public data: SideDrawerNetwork[],
    public hasMore: boolean,
    public nextPage: string,
    public previousPage: string,
    public totalCount: number,
    public filter?: SideDrawerNetworkRequestFilter
  ) {
    this.id = `${dataBaseRegion}-${requestType}`;
  }

  public static fromPaginatedResponse(
    response: PaginatorApiResponse<SideDrawerNetwork>,
    dataBaseRegion: string,
    requestType: SideDrawerNetworkRequestType,
    filter?: SideDrawerNetworkRequestFilter
  ): SideDrawerNetworksRequest {
    return new SideDrawerNetworksRequest(
      dataBaseRegion,
      requestType,
      response.data,
      response.hasMore,
      response.nextPage,
      response.previousPage,
      response.totalCount,
      filter ?? null
    );
  }
}
