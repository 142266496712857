<ng-template
  #default
  [ngIf]="recordTypeAutocompleteA11yTemplates.default === template">
  <div class="record-type-autocomplete-container">
    <div class="record-type-autocomplete-sd-selector">
      <app-sd-selector-a11y
        [attr.aria-label]="placeholder"
        [controller]="controller"
        [error]="error"
        [options]="types"
        [placeholder]="placeholder"
        id="setSdAccessibility?.id">
      </app-sd-selector-a11y>
    </div>
  </div>
</ng-template>

<ng-template
  #iconButton
  [ngIf]="recordTypeAutocompleteA11yTemplates.iconButton === template">
  <app-sd-link-menu-a11y
    [accessibility]="{
      ariaLabel: sdAccessibility?.ariaLabel ?? ('sharefileview_recordsubtypetooltip' | dictionary | async)
    }"
    [ngClass]="{ readOnly: readOnly }"
    [buttonType]="sdLinkMenuButtonType.icon"
    class="type"
    [icon]="
      !!iconType
        ? iconType
        : cdn + ('globalparams_horizontalactionmenuicon' | dictionary | async)
    "
    [iconColor]="
      readOnly
        ? sdColorPalette.tertiaryAccentColor
        : sdColorPalette.primaryAccentColor
    "
    [squareBackground]="true"
    [iconHeight]="1.25"
    [readOnly]="readOnly"
    [iconWidth]="1.25"
    [transparentBackground]="true"
    [disableRipple]="true"
    [setMenuData]="menuRecordTypeOptions"
    (emitterClicked)="onRecordTypeSelected($event)">
  </app-sd-link-menu-a11y>
</ng-template>

<ng-template
  #carrousel
  [ngIf]="recordTypeAutocompleteA11yTemplates.carrousel === template">
  <div class="menu-icon-container" [attr.role]="'menuitem'">
    <div
      class="carrousel-tile"
      *ngFor="let item of menuRecordTypeOptions"
      #tooltip="matTooltip"
      (mouseleave)="tooltip.hide()"
      [matTooltip]="item?.menuLinkText"
      (click)="onRecordTypeSelected(item)"
      (keydown.enter)="onRecordTypeSelected(item)"
      [ngClass]="{ active: item.active }">
      <app-sd-svg-a11y
        *ngIf="item?.menuLinkIcon"
        [color]="
          item.active ? 'var(--appBackground)' : 'var(--secondaryAccentColor)'
        "
        [height]="1.5"
        [src]="item?.menuLinkIcon"
        [width]="1.5"></app-sd-svg-a11y>
    </div>
  </div>
</ng-template>
