import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { HelpVideos } from '../../../../../dictionary/models/help-videos.model';
import { SdProgressSpinnerA11yModule } from '../../../../../shared/sd-progress-spinner-a11y/sd-progress-spinner-a11y/sd-progress-spinner-a11y.module';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-video-active-item',
  template: `
    <div *ngIf="spinner" class="image-viewer-spinner">
      <app-sd-progress-spinner-a11y />
    </div>

    <iframe
      #iframe
      [src]="safeUrl"
      allow="autoplay; fullscreen"
      allowfullscreen
      height="360"
      width="640">
    </iframe>
  `,
  styleUrls: ['./video-active-item.component.scss'],
  standalone: true,
  imports: [SdProgressSpinnerA11yModule, NgIf],
})
export class VideoActiveItemComponent
  implements OnInit, OnChanges, AfterViewInit
{
  @Input() video: HelpVideos;
  safeUrl: SafeUrl;
  spinner = true;
  @ViewChild('iframe') iframe: ElementRef<HTMLElement>;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.makeSafeUrl(this.video?.url);
  }

  ngAfterViewInit(): void {
    this.iframe.nativeElement.style.display = 'none';
    this.iframe.nativeElement.onload = () => {
      this.spinner = false;
      this.iframe.nativeElement.style.display = 'block';
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.video = changes?.video?.currentValue;
    this.makeSafeUrl(this.video?.url);
  }

  makeSafeUrl(url: string): void {
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
