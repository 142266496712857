<app-sd-content-template
  [boxStyle]="true"
  [spinner]="spinner$ | async"
  [withRecordsTilesBar]="displayRecordsTileSwitcher$ | async">
  <ng-container header>
    <div class="sd-container-fixed">
      <app-sd-header-a11y
        [basePath]="basePath$ | async"
        [currentRoute]="currentRoute"
        [displayLeftSection]="true"
        [image]="{
          name: (activeMyOtherSideDrawer$ | async)?.name,
          source: (activeMyOtherSideDrawer$ | async)?.sdPhoto
        }"
        [title]="(activeSidedrawer$ | async).name" />
    </div>
  </ng-container>
  <ng-container content>
    <router-outlet></router-outlet>
  </ng-container>
</app-sd-content-template>
