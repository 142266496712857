import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { featureName, sdCacheReducer } from './store/sd-cache.reducer';
import { EffectsModule } from '@ngrx/effects';
import { SdCacheEffects } from './store/sd-cache.effects';

@NgModule({
  imports: [
    StoreModule.forFeature(featureName, sdCacheReducer),
    EffectsModule.forFeature([SdCacheEffects]),
  ],
})
export class SdCacheModule {}
