import { Injectable } from '@angular/core';
import { SignatureService } from '../services/signature.service';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SignatureActions } from './signature.actions';
import {
  catchError,
  last,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { EMPTY, forkJoin, of } from 'rxjs';
import { activeSideDrawerSelector } from '../../sidedrawer/store/sidedrawer.selector';
import { ErrorLoaded } from '../../core/store/core.actions';
import { Router } from '@angular/router';
import {
  signatureDownloadingAndUploadingByEnvelopeIdAndDocumentIdSelector,
  signatureFileToSignSelector,
  signatureGettingDocumentsByEnvelopeIdSelector,
} from './signature.selector';
import { FilesHelper } from '../../files/helpers/files.helper';
import { PaginatorService } from '../../core/services/paginator.service';
import { FileItem } from '../../files/models/file-item.model';
import { FilesService } from '../../files/services/files.service';
import { FileHistoryDisplayType } from '../../files/models/file-history-display-type.model';
import { FileType } from '../../files/models/file-type.enum';
import { DocusignEnvelope } from '../models/docusign-envelope.model';
import { DateHelper } from '../../core/helpers/date.helper';
import { activeRecordSelector } from '../../records/store/records-list.selectors';
import {
  RecordsListActionsTypes,
  SetActiveRecord,
} from '../../records/store/records-list.actions';
import { SdQueueItem } from '../../queue/models/sd-queue-item.model';
import { QueueActions } from '../../queue/store/queue.actions';
import { SignatureProvider } from '../models/signature-provider.model';

@Injectable()
export class SignatureEffects {
  envelopesRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignatureActions.envelopesRequested),
      tap(() =>
        this.store.dispatch(
          SignatureActions.gettingEnvelopesChange({ gettingInformation: true })
        )
      ),
      switchMap(() =>
        forkJoin([
          this.store.pipe(select(activeSideDrawerSelector), take(1)),
          this.store.pipe(select(activeRecordSelector), take(1)),
        ])
      ),
      switchMap(([activeSideDrawer, activeRecord]) =>
        !activeSideDrawer || !activeRecord
          ? of(
              SignatureActions.gettingEnvelopesChange({
                gettingInformation: false,
              })
            )
          : this.signatureService
              .getRecordEnvelopes(activeSideDrawer.id, activeRecord.id)
              .pipe(
                map(response => ({
                  ...response,
                  data: response.data.sort((a, b) =>
                    DateHelper.compareDates(
                      b.lastModifiedDateTime,
                      a.lastModifiedDateTime
                    )
                  ),
                })),
                map(response => SignatureActions.envelopesLoaded({ response })),
                catchError(() =>
                  of(
                    SignatureActions.gettingEnvelopesChange({
                      gettingInformation: false,
                    })
                  )
                )
              )
      ),
      tap(() =>
        this.store.dispatch(
          SignatureActions.gettingEnvelopesChange({ gettingInformation: false })
        )
      )
    )
  );

  createEnvelopeFromFileRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignatureActions.createEnvelopeFromFileRequested),
      switchMap(({ dto }) =>
        forkJoin([
          this.store.pipe(select(activeSideDrawerSelector), take(1)),
          this.store.pipe(select(activeRecordSelector), take(1)),
          this.store.pipe(select(signatureFileToSignSelector), take(1)),
          of(dto).pipe(take(1)),
        ])
      ),
      switchMap(([activeSideDrawer, activeRecord, file, dto]) =>
        this.signatureService
          .createSignatureFromFile(
            activeSideDrawer.id,
            file,
            {
              ...dto,
              signers: dto.signers.map((signer, index) => ({
                ...signer,
                signerRole: `Signer ${index + 1}`,
                routingOrder: 0,
                recipientId: index + 1,
              })),
              redirectURL: dto.redirectURL,
              recordId: activeRecord.id,
            },
            SignatureProvider.docusign
          )
          .pipe(
            last(),
            map(response =>
              SignatureActions.createEnvelopeFromFileSuccess({
                redirectURL: response.response.redirectUrl,
                recordUrl: dto.redirectURL.replace(
                  `${window.location.origin}/`,
                  ''
                ),
              })
            ),
            catchError(error =>
              of(
                SignatureActions.createEnvelopeFromFileFails({
                  error,
                  recordUrl: dto.redirectURL.replace(
                    `${window.location.origin}/`,
                    ''
                  ),
                })
              )
            )
          )
      )
    )
  );

  createEnvelopeFromFileSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SignatureActions.createEnvelopeFromFileSuccess),
        tap(({ redirectURL, recordUrl }) => {
          window.open(redirectURL, '_blank');
          this.router.navigateByUrl(recordUrl);
        })
      ),
    { dispatch: false }
  );

  createEnvelopeFromFileFails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignatureActions.createEnvelopeFromFileFails),
      map(({ error, recordUrl }) => {
        this.router.navigateByUrl(recordUrl);
        return new ErrorLoaded({ httpError: error, display404: true });
      })
    )
  );

  setActiveRecord$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SetActiveRecord>(RecordsListActionsTypes.SetActiveRecord),
      map(() => SignatureActions.clearInformation())
    )
  );

  envelopeCompleteInformationLoaded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignatureActions.envelopeCompleteInformationLoaded),
      mergeMap(({ envelope }) =>
        forkJoin([
          this.store.pipe(select(activeSideDrawerSelector), take(1)),
          this.store.pipe(select(activeRecordSelector), take(1)),
          of(envelope).pipe(take(1)),
          this.store.pipe(
            select(
              signatureGettingDocumentsByEnvelopeIdSelector({
                envelopeId: envelope.id as string,
              })
            ),
            take(1)
          ),
        ])
      ),
      tap(([, , envelope, checkDocumentsOnRecord]) => {
        if (!checkDocumentsOnRecord) {
          this.store.dispatch(
            SignatureActions.checkDocumentsOnRecordChange({
              envelopeId: envelope.id as string,
              checkDocumentsOnRecord: true,
            })
          );
        }
      }),
      mergeMap(
        ([activeSideDrawer, activeRecord, envelope, checkDocumentsOnRecord]) =>
          checkDocumentsOnRecord ||
          (envelope.changes as DocusignEnvelope).status !== 'completed'
            ? of(
                SignatureActions.checkDocumentsOnRecordChange({
                  envelopeId: envelope.id as string,
                  checkDocumentsOnRecord:
                    (envelope.changes as DocusignEnvelope).status ===
                    'completed',
                })
              )
            : forkJoin(
                envelope.changes.envelopeDocuments
                  .filter(document => document.documentId !== 'certificate')
                  .map(document =>
                    this.paginatorService
                      .getPaginatedResource<FileItem>(
                        FilesHelper.getFileHistoryResourceUrl(
                          activeSideDrawer.id,
                          activeRecord.id,
                          {
                            envelopeId: envelope.id as string,
                            correlationId: document.documentIdGuid,
                          }
                        )
                      )
                      .pipe(
                        tap(response => {
                          if (response.data.length === 0) {
                            this.store.dispatch(
                              SignatureActions.downloadAndUploadDocumentOnRecord(
                                {
                                  envelopeId: envelope.id as string,
                                  document,
                                  recordId: activeRecord.id,
                                  sideDrawerId: activeSideDrawer.id,
                                }
                              )
                            );
                          }
                        })
                      )
                  )
              ).pipe(
                map(() =>
                  SignatureActions.checkDocumentsOnRecordChange({
                    envelopeId: envelope.id as string,
                    checkDocumentsOnRecord: false,
                  })
                )
              )
      )
    )
  );

  downloadAndUploadDocumentOnRecord$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SignatureActions.downloadAndUploadDocumentOnRecord),
        mergeMap(({ envelopeId, document, recordId, sideDrawerId }) =>
          forkJoin([
            this.store.pipe(
              select(
                signatureDownloadingAndUploadingByEnvelopeIdAndDocumentIdSelector(
                  { envelopeId, documentId: document.documentId }
                )
              ),
              take(1)
            ),
            of({ envelopeId, document, recordId, sideDrawerId }).pipe(take(1)),
          ])
        ),
        mergeMap(([working, info]) => {
          if (working) {
            return EMPTY;
          }
          const { sideDrawerId, recordId, envelopeId, document } = info;
          this.store.dispatch(
            SignatureActions.downloadingAndUploadingDocumentChange({
              downloadingAndUploading: true,
              envelopeId,
              documentId: document.documentId,
            })
          );
          return this.signatureService
            .downloadFileFromEnvelope(
              sideDrawerId,
              SignatureProvider.docusign,
              envelopeId,
              document.documentId
            )
            .pipe(
              last(),
              tap(response => {
                const upload = {
                  file: FilesHelper.blobToFile(
                    response.file,
                    document.name + `.pdf`
                  ),
                  envelopeId,
                  uploadTitle: document.name + ' (Executed via DocuSign)',
                  displayType: FileHistoryDisplayType.sealed,
                  fileType: FileType.document,
                  correlationId: document.documentIdGuid,
                };
                const operation$ = this.filesService.uploadFile(
                  upload,
                  upload.fileType,
                  sideDrawerId,
                  recordId
                );
                const item: SdQueueItem = {
                  id: FilesHelper.generateRandomId(),
                  operation$,
                  progress: 0,
                  state: 'pending',
                  itemType: 'file',
                  upload,
                  error: null,
                  callback: null,
                };
                this.store.dispatch(
                  SignatureActions.downloadingAndUploadingDocumentChange({
                    downloadingAndUploading: false,
                    envelopeId,
                    documentId: document.documentId,
                  })
                );
                this.store.dispatch(QueueActions.itemAdded({ item }));
                this.store.dispatch(QueueActions.startProcess());
              })
            );
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly paginatorService: PaginatorService,
    private readonly signatureService: SignatureService,
    private readonly filesService: FilesService,
    private readonly store: Store<AppState>
  ) {}
}
