import { RecordSubType } from 'src/app/records/models/record-sub-type.model';
import { RecordType } from 'src/app/records/models/record-type.model';

export class RelatedRecord {
  constructor(
    public id?: string,
    public name?: string,
    public recordSubtypeOther?: string,
    public recordSubtype?: RecordSubType,
    public recordType?: RecordType,
    public updatedAt?: Date,
    public lastModifiedBy?: string
  ) {}
}
