import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { RelatedRecord } from '../models/related-record.model';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { authHeadersSelector } from 'src/app/auth/store/auth.selectors';

@Injectable()
export class RelatedRecordsService {
  private recordsApi = environment.recordsApi;

  constructor(
    private readonly http: HttpClient,
    private readonly store: Store<AppState>
  ) {}

  getRelatedRecords(
    sidedrawerId: string,
    recordId: string
  ): Observable<RelatedRecord[]> {
    return this.http
      .get<unknown[]>(
        this.recordsApi +
          `sidedrawer/sidedrawer-id/${sidedrawerId}/records/record-id/${recordId}/related`,
        { headers: this.store.selectSignal(authHeadersSelector)() }
      )
      .pipe(
        map((relatedRecords: unknown[]) => {
          return relatedRecords.map((relatedRecord: unknown) => {
            return new RelatedRecord(
              relatedRecord['id'],
              relatedRecord['name'],
              relatedRecord['recordSubtypeOther'],
              relatedRecord['recordSubType'],
              relatedRecord['recordType'],
              relatedRecord['updatedAt'],
              relatedRecord['lastModifiedBy']
            );
          });
        })
      );
  }

  createNewRelatedRecord(
    sidedrawerId: string,
    recordId: string,
    newRelatedRecord: RelatedRecord
  ): Observable<RelatedRecord> {
    return this.http.post<RelatedRecord>(
      this.recordsApi +
        `sidedrawer/sidedrawer-id/${sidedrawerId}/records/record-id/${recordId}/related`,
      { recordId: newRelatedRecord.id },
      { headers: this.store.selectSignal(authHeadersSelector)() }
    );
  }

  deleteRelatedRecord(
    sidedrawerId: string,
    recordId: string,
    deleteRecordId
  ): Observable<unknown> {
    return this.http.request(
      'delete',
      this.recordsApi +
        `sidedrawer/sidedrawer-id/${sidedrawerId}/records/record-id/${recordId}/related`,
      {
        headers: this.store.selectSignal(authHeadersSelector)(),
        body: {
          recordId: deleteRecordId,
        },
      }
    );
  }
}
