import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SdContentTemplateModule } from '../../../shared/templates/sd-content-template/sd-content-template.module';
import { ReminderTemplateComponent } from './components/reminder-template/reminder-template.component';
import { SdHeaderA11yModule } from 'src/app/shared/sd-header-a11y/sd-header-a11y.module';
import { SdBreadcrumbsA11yModule } from 'src/app/shared/sd-breadcrumbs-a11y/sd-breadcrumbs-a11y.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MainHeaderDisplayNamePipe } from 'src/app/home/pipes/main-header-display-name.pipe';

@NgModule({
  declarations: [ReminderTemplateComponent],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    RouterModule,
    SdContentTemplateModule,
    SdHeaderA11yModule,
    SdBreadcrumbsA11yModule,
    MainHeaderDisplayNamePipe,
  ],
  exports: [ReminderTemplateComponent],
})
export class ReminderTemplateModule {}
