import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, Subscription, switchMap } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { Locale } from 'src/app/dictionary/models/locale.model';
import {
  ActivatedRoute,
  Params,
  PRIMARY_OUTLET,
  Router,
  UrlSegment,
  UrlSegmentGroup,
  UrlTree,
} from '@angular/router';
import { Option } from 'src/app/shared/sd-forms/models/option.model';
import {
  activeSideDrawerSelector,
  sideDrawerSpinnerSelector,
} from 'src/app/sidedrawer/store/sidedrawer.selector';
import { RemindersRoutes } from 'src/app/reminders/routes/reminders.routes';
import { AppState } from 'src/app/reducers';
import { Reminders } from 'src/app/reminders/models/reminders.model';
import {
  FilingCabinetAllRemindersRequested,
  RemindersRequested,
} from 'src/app/reminders/store/reminders-list.action';
import {
  getRemindersOrderedByStatusListSelector,
  remindersLoadingSelector,
} from 'src/app/reminders/store/reminders-list.selectors';
import { localeDefaultSelector } from 'src/app/dictionary/store/dictionary.selectors';
import { environment } from 'src/environments/environment';
import { SdColorPalette } from 'src/app/core/models/enums/sd-color-palette-enum';
import { SdAccessibilitySetting } from 'src/app/core/models/sd-accessibility-model';
import { SdLinkButtonA11yTemplates } from 'src/app/shared/sd-link-button-a11y/model/sd-link-button-a11y.enum';
import { RemindersFormDialogA11yComponent } from 'src/app/reminders/shared/reminders-form-dialog-a11y/components/reminders-form-dialog-a11y/reminders-form-dialog-a11y.component';
import {
  activeRecordSelector,
  userCanOperateOnThisRecordSelector,
} from 'src/app/records/store/records-list.selectors';
import { TypesOfReminders } from 'src/app/reminders/models/enums/types-of-reminders.enum';
import { ReminderStatus } from 'src/app/reminders/models/enums/reminder-status.enum';
import { MatDialog } from '@angular/material/dialog';
import { filingCabinetLoadingSelector } from '../../../../../filing-cabinet/store/filing-cabinet.store';
import { currentBrandingSelector } from 'src/app/core/store/core.selectors';
import { BrandingConfig } from 'src/app/core/models/branding-config.model';
import {
  NetworkPermanentActions,
  networkPermanentListAsOptions,
} from '../../../../../networks/store/network-permanent.store';

@Component({
  selector: 'app-reminders-list-a11y',
  templateUrl: './reminders-list-a11y.component.html',
  styleUrls: ['./reminders-list-a11y.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RemindersListA11yComponent implements OnInit, OnDestroy {
  @Input() addNewUrl: string;

  @Input() emptyStateString: string;
  @Input() remindersListTitle: string;
  @Input() remindersListTooltip: string;
  @Input() remindersListAddButtonTooltip: string;
  @Input() remindersListViewAll: string;

  @Input() viewOnly = false;
  @Input() viewAllReminder = false;
  @Input() viewAllReminderInline = false;
  @Input() dialogMode = false;
  @Input() showHeader = true;
  @Input() typeOfReminder: TypesOfReminders = TypesOfReminders.record;
  @Input() emptyStateInline = true;
  @Input() showSpinner = true;
  accessibilityForIcon: SdAccessibilitySetting;
  options$: Observable<Option[]>;
  loading$: Observable<boolean>;
  localeDefault: Locale;
  reminders$: Observable<Reminders[]>;
  reminders: Reminders[] = [];
  subscription = new Subscription();
  cdn = environment.cdn;
  sdColorPalette = SdColorPalette;
  sdLinkButtonA11yEnum = SdLinkButtonA11yTemplates;
  readonly reminderListSlideNumber: number = 10;
  params: Params;
  isSimplifiedRecordViewAndEmpty = false;
  currentBranding$: Observable<BrandingConfig> = this.store.pipe(
    select(currentBrandingSelector)
  );
  userCanOperate = this.store.selectSignal(userCanOperateOnThisRecordSelector);

  constructor(
    protected store: Store<AppState>,
    private router: Router,
    protected readonly dialog: MatDialog,
    private readonly activatedRoute: ActivatedRoute
  ) {}

  @Input() set setAccessibilityForIcon(value: SdAccessibilitySetting) {
    this.accessibilityForIcon = value;
  }

  ngOnInit(): void {
    const { queryParams } = this.activatedRoute.snapshot;
    this.params = queryParams;
    this.initComponent();
  }

  initComponent(): void {
    this.reminders$ = this.store.pipe(
      select(activeRecordSelector),
      switchMap(activeRecord =>
        activeRecord
          ? this.store.select(
              getRemindersOrderedByStatusListSelector({
                typeOfReminder: TypesOfReminders.record,
                recordId: activeRecord.id,
              })
            )
          : this.store.select(getRemindersOrderedByStatusListSelector())
      ),
      tap(reminders => {
        if (this.params.reminderId) {
          reminders.map(reminder => {
            if (reminder.id === this.params.reminderId) {
              this.dialog
                .open(RemindersFormDialogA11yComponent, {
                  autoFocus: false,
                  panelClass:
                    'custom-dialog-container-for-reminders-form-dialog-a11y',
                  data: {
                    reminder: reminder,
                    disabled: reminder.status === ReminderStatus.Finished,
                  },
                })
                .afterClosed()
                .subscribe();
            }
          });
        }
      })
    );

    if (!this.addNewUrl) {
      this.addNewUrl = this.router.url.split('all').shift();
    }
    this.loading$ = this.store.pipe(select(remindersLoadingSelector));
    this.getResourceAndRetrieveAllReminders();
    this.store
      .pipe(
        select(localeDefaultSelector),
        take(1),
        tap(locale => {
          this.localeDefault = locale;
        })
      )
      .subscribe();
    this.options$ = this.store.select(networkPermanentListAsOptions());
  }

  onAddReminder(): void {
    if (this.dialogMode) {
      this.dialog
        .open(RemindersFormDialogA11yComponent, {
          autoFocus: false,
          panelClass: 'custom-dialog-container-for-reminders-form-dialog-a11y',
          data: { data: { id: '' } },
        })
        .afterClosed()
        .subscribe(() => {
          this.initComponent();
        });
      return;
    }
    // TODO working on
    this.router.navigateByUrl(
      !this.viewAllReminder ? this.addNewUrl : `${this.addNewUrl}?all=true`
    );
  }

  seeAllReminders(): string {
    return `${this.router.url}/${RemindersRoutes.root}/${RemindersRoutes.all}`;
  }

  seeAllRemindersInline(event): void {
    event.preventDefault();
    this.viewAllReminder = true;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  remindersTrackBy(index: number, item: Reminders): string {
    return item.id;
  }

  private getResourceAndRetrieveAllReminders(): void {
    const tree: UrlTree = this.router.parseUrl(this.router.url);
    const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
    const s: UrlSegment[] = g.segments;

    let allNetworksRequested = false;

    if (s.filter(s => s.path === 'records').length) {
      this.subscription.add(
        combineLatest([
          this.store.pipe(select(activeSideDrawerSelector)),
          this.store.pipe(select(activeRecordSelector)),
        ]).subscribe(([activeSD, activeRecord]) => {
          if (activeSD && activeRecord) {
            const resource = `sidedrawer/${activeSD.id}/record/${activeRecord.id}`;
            this.store.dispatch(
              new RemindersRequested({ resource, sideDrawerId: activeSD.id })
            );

            if (!allNetworksRequested) {
              this.store.dispatch(
                NetworkPermanentActions.allNetworksRequestedForReminders()
              );
              allNetworksRequested = true;
            }
          }
        })
      );
      return;
    }

    const initReminders$ = combineLatest([
      this.store.select(filingCabinetLoadingSelector),
      this.store.select(sideDrawerSpinnerSelector),
    ]).pipe(
      tap(([gettingFilingCabinet, gettingActiveSideDrawer]) => {
        if (gettingFilingCabinet || gettingActiveSideDrawer) {
          return;
        }

        if (!allNetworksRequested) {
          this.store.dispatch(
            NetworkPermanentActions.allNetworksRequestedForReminders()
          );
          allNetworksRequested = true;
        }

        this.store.dispatch(new FilingCabinetAllRemindersRequested());
      })
    );
    this.subscription.add(initReminders$.subscribe());
  }
}
