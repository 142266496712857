import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SdAlertBoxA11yComponent } from './components/sd-alert-box-a11y/sd-alert-box-a11y.component';

@NgModule({
  declarations: [SdAlertBoxA11yComponent],
  imports: [CommonModule],
  exports: [SdAlertBoxA11yComponent],
})
export class SdAlertBoxA11yModule {}
