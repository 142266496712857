import { Component, Input } from '@angular/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { Store } from '@ngrx/store';
import { Observable, map } from 'rxjs';
import { AccessibilityHelper } from 'src/app/core/helpers/accessibility.helper';
import { SdAccessibilitySetting } from 'src/app/core/models/sd-accessibility-model';
import { Dictionary } from 'src/app/dictionary/models/dictionary.model';
import { dictionarySelector } from 'src/app/dictionary/store/dictionary.selectors';
import { sdConfig } from 'src/sd-config/sd-config';

@Component({
  selector: 'app-sd-progress-spinner-a11y',
  templateUrl: './sd-progress-spinner-a11y.component.html',
  styleUrls: ['./sd-progress-spinner-a11y.component.scss'],
})
export class SdProgressSpinnerA11yComponent {
  envConfigStroke =
    sdConfig.sharedComponentsConfig?.sdProgressSpinnerA11yComponent?.stroke;
  envConfigDiameter =
    sdConfig.sharedComponentsConfig?.sdProgressSpinnerA11yComponent?.diameter;

  @Input() diameter = 20;
  @Input() strokeWidth = 2;
  @Input() mode: ProgressSpinnerMode = 'indeterminate';
  spinnerProgress: number;


  @Input() set setSpinnerProgress(value: number) {
    this.spinnerProgress = value;
  }
  sdAccessibility: SdAccessibilitySetting;
  @Input() set setSdAccessibility(value: SdAccessibilitySetting) {
    this.sdAccessibility = AccessibilityHelper.setDefaultAccessibility(value);
  }
}
