import { Action } from '@ngrx/store';
import { SdBreadcrumbsA11y } from '../model/sd-breadcrumbs-a11y.model';

export enum SdBreadCrumbsA11yActionsTypes {
  SetBreadCrumbs = '[SdBreadCrumbsA11y] SetBreadCrumbs',
  SetBreadCrumbsWithRouteStrategy = '[SdBreadCrumbsA11y] SetBreadCrumbsWithRouteStrategy',
}

export class SetBreadCrumbs implements Action {
  readonly type = SdBreadCrumbsA11yActionsTypes.SetBreadCrumbs;

  constructor(public payload: SdBreadcrumbsA11y[]) {}
}

export class SetBreadCrumbsWithRouteStrategy implements Action {
  readonly type = SdBreadCrumbsA11yActionsTypes.SetBreadCrumbsWithRouteStrategy;
}

export type SdBreadCrumbsA11yActions =
  | SetBreadCrumbs
  | SetBreadCrumbsWithRouteStrategy;
