import { Actions, createEffect, ofType } from '@ngrx/effects';
import { inject } from '@angular/core';
import { PaginatorService } from '../../core/services/paginator.service';
import {
  NetworkListActions,
  networkListRequestsSelector,
} from './network-list.store';
import { concat, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { NetworkHelper } from '../helpers/network.helper';
import { NetworkV2 } from '../models/network.v2.type';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';

export const networkListRequested$ = createEffect(
  () => {
    const store = inject(Store<AppState>);
    const service = inject(PaginatorService);
    return inject(Actions).pipe(
      ofType(NetworkListActions.requested),
      switchMap(({ sideDrawerId, options, allPageRequested }) =>
        service
          .getPaginatedResource<NetworkV2>(
            NetworkHelper.getNetworksResourceUrl(sideDrawerId, options)
          )
          .pipe(
            map(response =>
              NetworkListActions.loaded({ response, sideDrawerId, options })
            ),
            tap(response => {
              if (allPageRequested && response.response?.hasMore === true) {
                return store.dispatch(NetworkListActions.nextPageRequested());
              }
              return null;
            }),
            catchError(httpError =>
              of(NetworkListActions.error({ sideDrawerId, httpError }))
            )
          )
      )
    );
  },
  {
    functional: true,
  }
);

export const networkListMultipleOperationsRequested$ = createEffect(
  () => {
    const store = inject(Store<AppState>);
    const service = inject(PaginatorService);
    return inject(Actions).pipe(
      ofType(NetworkListActions.multipleOperationsRequested),
      switchMap(({ operations }) =>
        //mergeMap(({ operations }) =>
        concat(
          ...operations.map(({ sideDrawerId, options, allPageRequested }) =>
            service
              .getPaginatedResource<NetworkV2>(
                NetworkHelper.getNetworksResourceUrl(sideDrawerId, options)
              )
              .pipe(
                map(response => {
                  return NetworkListActions.loaded({
                    response,
                    sideDrawerId,
                    options,
                  });
                }),
                tap(response => {
                  if (allPageRequested && response.response?.hasMore === true) {
                    return store.dispatch(
                      NetworkListActions.nextPageRequested()
                    );
                  }
                  return null;
                }),
                catchError(httpError =>
                  of(NetworkListActions.error({ sideDrawerId, httpError }))
                )
              )
          )
        )
      )
    );
  },
  { functional: true }
);

export const networkListNextPageRequested$ = createEffect(
  () => {
    const store = inject(Store<AppState>);
    return inject(Actions).pipe(
      ofType(NetworkListActions.nextPageRequested),
      map(() => {
        const requests = store.selectSignal(networkListRequestsSelector)();
        Array.from(requests.values())
          ?.filter(request => request.hasMore)
          .forEach(request => {
            if (request.nextPage?.length > 0) {
              store.dispatch(
                NetworkListActions.requested({
                  sideDrawerId: request.sideDrawerId,
                  options: {
                    ...(request.options ?? {}),
                    nextPage: request.nextPage,
                  },
                  allPageRequested: request.allPageRequested ?? false,
                })
              );
            }
          });
      })
    );
  },
  { functional: true, dispatch: false }
);
