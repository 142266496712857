<div class="record-form-networks-tab">
  <app-sd-progress-bar-a11y [setIsLoading]="gettingNetworks()" />

  <app-sd-record-tab-header
    [actionsTemplate]="actions"
    [title]="'recordformnetworkstab_title'"
    [tooltip]="'recordformnetworkstab_tooltip'"
    class="app-sd-record-tab-header" />

  <ng-template #actions>
    <app-sd-icon-button-a11y
      (btnClicked)="onAddCollaborator()"
      [iconColor]="SdColorPalette.primaryColor"
      [iconHeight]="1.25"
      [iconWidth]="1.25"
      [icon]="cdn + ('globalparams_addicon' | dictionarySignal)"
      [setSdAccessibility]="{
        ariaLabel: 'recordformnetworkstab_addbuttontooltip' | dictionarySignal,
        role: 'button'
      }"
      [squareBackground]="false"
      [tooltip]="'recordformnetworkstab_addbuttontooltip' | dictionarySignal">
    </app-sd-icon-button-a11y>
  </ng-template>

  <div
    *ngIf="networks().length > 0; else noCollaborators"
    class="record-form-networks-tab-list">
    <app-sd-infinite-list
      [hasMore]="hasMore()"
      [loading]="networks()?.length > 0 && gettingNetworks()"
      (loadMoreClicked)="onNextPage()"
      (loadMoreReached)="onNextPage()">
      <ng-container
        *ngFor="let network of networks(); index as i; trackBy: trackBy">
        <app-network-list-item
          [network]="{
            network: network,
            networkEntity: NetworkEntity.records
          }" />
      </ng-container>
    </app-sd-infinite-list>
  </div>

  <ng-template #noCollaborators>
    <app-sd-empty-template
      [emptyStateInline]="true"
      [emptyStateString]="
        'recordformnetworkstab_emptystate' | dictionarySignal
      " />
  </ng-template>

  <div class="record-form-networks-tab-action-buttons-mobile">
    <app-sd-flat-button-a11y
      (buttonClicked)="onAddCollaborator()"
      [primary]="true"
      [setSdAccessibility]="{
        ariaLabel: 'recordformnetworkstab_addbutton' | dictionarySignal
      }"
      >{{ 'recordformnetworkstab_addbutton' | dictionarySignal }}
    </app-sd-flat-button-a11y>
  </div>
</div>
