import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { SdLinkButtonA11yTemplates } from '../sd-link-button-a11y/model/sd-link-button-a11y.enum';
import { AsyncPipe, NgClass, NgIf, NgStyle } from '@angular/common';
import { DictionaryPipeModule } from '../../dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { SdLinkButtonA11yModule } from '../sd-link-button-a11y/sd-link-button-a11y.module';
import { SdProgressSpinnerA11yModule } from '../sd-progress-spinner-a11y/sd-progress-spinner-a11y/sd-progress-spinner-a11y.module';

@Component({
  selector: 'app-sd-infinite-list',
  standalone: true,
  imports: [
    AsyncPipe,
    DictionaryPipeModule,
    NgIf,
    SdLinkButtonA11yModule,
    SdProgressSpinnerA11yModule,
    NgClass,
    NgStyle,
  ],
  template: `
    <ng-content />
    <div
      class="sd-infinite-list-loading-link"
      [ngStyle]="{ 'padding-left': paddingLeft ? paddingLeft + 'rem' : 0 }">
      <app-sd-link-button-a11y
        #loadMore
        [template]="sdLinkButtonA11yTemplates.linkFocusable"
        [tabIndex]="0"
        (click)="loadMoreClicked.emit(true)"
        (keydown.enter)="loadMoreClicked.emit(true)"
        [ngClass]="{ hidden: !hasMore || loading }"
        ><span linkFocusable>{{
          'globalparams_loadmore' | dictionary | async
        }}</span></app-sd-link-button-a11y
      >
    </div>
    <div class="sd-infinite-list-loading" *ngIf="loading">
      <app-sd-progress-spinner-a11y />
    </div>
  `,
  styleUrls: ['./sd-infinite-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SdInfiniteListComponent implements AfterViewInit, OnDestroy {
  @Input() hasMore = false;
  @Input() loading = false;
  @Input() paddingLeft: number;
  @Output() loadMoreClicked = new EventEmitter<boolean>();
  @Output() loadMoreReached = new EventEmitter<boolean>();
  @ViewChild('loadMore', { read: ElementRef, static: true })
  loadMoreElement: ElementRef;
  protected sdLinkButtonA11yTemplates = SdLinkButtonA11yTemplates;
  protected io = new IntersectionObserver(
    entries => {
      if (
        this.hasMore &&
        !this.loading &&
        (entries?.[0]?.intersectionRatio >= 0.25 ||
          entries?.[0]?.isIntersecting)
      ) {
        this.loadMoreReached.emit(true);
      }
    },
    {
      threshold: [0.25],
      root: null,
      rootMargin: '0px',
    }
  );

  ngAfterViewInit(): void {
    this.io.observe(this.loadMoreElement.nativeElement);
  }

  ngOnDestroy(): void {
    this.io.unobserve(this.loadMoreElement.nativeElement);
  }
}
