import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureName, StartFlowState } from './start-flow.reducer';
import { HomeRoutes } from '../../home/routes/home.routes';
import { SettingsRoutesEnum } from '../../settings/routes/settings-routes.enum';

export const startFlowStateSelector =
  createFeatureSelector<StartFlowState>(featureName);

export const startFlowFistLogInSelector = createSelector(
  startFlowStateSelector,
  state => state.firstLogIn
);

export const startFlowInvitationCodeSelector = createSelector(
  startFlowStateSelector,
  state => state.invitationCode
);

export const startFlowOriginSelector = createSelector(
  startFlowStateSelector,
  state => state.origin
);

export const startFlowOriginSdIdSelector = createSelector(
  startFlowOriginSelector,
  origin => {
    const sdId = origin?.split(`/${HomeRoutes.root}/`)?.[1]?.split('/')?.[0];
    return sdId !== SettingsRoutesEnum.root ? sdId : null;
  }
);

export const startFlowExternalOriginSdIdSelector = createSelector(
  startFlowOriginSelector,
  origin => {
    const sdId = origin?.split(`/${HomeRoutes.root}/`)?.[1]?.split('/')?.[1];
    return sdId;
  }
);

export const startFlowBypassSdCreationSelector = createSelector(
  startFlowStateSelector,
  state => state.bypassSdCreation
);

export const startFlowForceSdCreationSelector = createSelector(
  startFlowStateSelector,
  state => state.forceSdCreation
);

export const startFlowCompleteSelector = createSelector(
  startFlowStateSelector,
  state => state.complete
);

export const startFlowCustomBrandSelector = createSelector(
  startFlowStateSelector,
  state => state.customBrand
);
