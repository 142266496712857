import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SdSnackBarComponent } from './components/sd-snack-bar/sd-snack-bar.component';
import { EffectsModule } from '@ngrx/effects';
import { SdSnackBarEffects } from './store/sd-snack-bar.effects';
import { SdFlatButtonA11yModule } from '../sd-flat-button-a11y/sd-flat-button-a11y.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  declarations: [SdSnackBarComponent],
  imports: [
    CommonModule,
    MatSnackBarModule,
    EffectsModule.forFeature([SdSnackBarEffects]),
    SdFlatButtonA11yModule,
  ],
  exports: [SdSnackBarComponent],
})
export class SdSnackBarModule {}
