import { Component, Input, TemplateRef } from '@angular/core';
import { TabsRoutesLinks } from 'src/app/records/models/tabs-routes-links.model';
import { SdTabsTemplateA11yEnum } from '../../model/sd-tabs-template-a11y.enum';

@Component({
  selector: 'app-sd-tabs-template-a11y',
  templateUrl: './sd-tabs-template-a11y.component.html',
  styleUrls: ['./sd-tabs-template-a11y.component.scss'],
})
export class SdTabsTemplateA11yComponent {
  @Input() template: SdTabsTemplateA11yEnum =
    SdTabsTemplateA11yEnum.defaultTabs;

  sdTabsTemplateA11yEnum = SdTabsTemplateA11yEnum;

  @Input() options: string[];
  @Input() templateRef: TemplateRef<unknown>[];
  @Input() links: TabsRoutesLinks[] = [];
}
