export const environment = {
  production: false,
  // Development ----------------------------------------------
  reduxToolsEnabled: true,
  reduxMaxAge: 100,
  dictionaryAlert: false,
  appName: 'td',
  brandCode: 'sidedrawer',
  defaultPageTitle: 'TD Digital Vault',
  keycloakDisabled: false,
  entityAppRoleName: 'webApp',
  defaultPlanRequestReportName: 'plan_request',
  // APIS -----------------------------------------------------------
  configApi: 'https://td-api-sbx.sdrwsbx.co/api/v1/configs/',
  developmentApi: 'https://td-api-sbx.sdrwsbx.co/api/v1/developers/',
  tenantApi: 'https://td-api-sbx.sdrwsbx.co/api/v1/tenants/',
  inboxApi: 'https://td-api-sbx.sdrwsbx.co/api/v1/inbox/',
  integrationApi: 'https://td-api-sbx.sdrwsbx.co/api/v1/integrations/',
  signatureApi: 'https://td-api-sbx.sdrwsbx.co/api/v1/signatures/',
  networksApi: 'https://td-api-sbx.sdrwsbx.co/api/v1/networks/',
  recordsApi: 'https://td-api-sbx.sdrwsbx.co/api/v1/records/',
  gatewayApi: 'https://td-api-sbx.sdrwsbx.co/api/v1/',
  subscriptionApi: 'https://td-api-sbx.sdrwsbx.co/api/v1/subscriptions/',
  userApi: 'https://td-api-sbx.sdrwsbx.co/api/v1/users/',
  plansApi: 'https://td-api-sbx.sdrwsbx.co/api/v1/planrequests/',
  blocksApi: 'https://td-api-sbx.sdrwsbx.co/api/v1/blocks/',
  reportsApi: 'https://td-api-sbx.sdrwsbx.co/api/v1/reports/',
  smartFormsRequestsApi: '',
  // AUTH0 DEFAULT-----------------------------------------------------------
  audience: 'https://user-api-sbx.sidedrawersbx.com',
  clientID: 'my-app-sbx',
  connection: 'Username-Password-Authentication',
  domain: 'https://auth-pat.digitalvault.td.com/realms/tddv-sbx',
  redirectUri: '/auth/authorize',
  scope: 'profile email roles web-origins',
  // CDN -----------------------------------------------------------
  cdn: 'https://cdn-pat.digitalvault.td.com/',
  // Versions --------------------------------------------------
  device: 'Web',
  version: '3.3.11',
  dictionaryVersion: 'webapp_20230601',
  // Application ---------------------------------------------------
  small: 600,
  medium: 1025,
  // Token & Session
  inactivityTime: 900,
  inactivityDialogTime: 120,
  refreshTokenTime: 300,
  // Intercom -----------------------------------------------
  intercomAppId: 'eufoidmt',
  intercomHashUrl: 'https://intercom.sdrwsbx.co/hash',
  // PDFTron
  pdfTronLicense: 'SideDrawer Inc :OEM:SideDrawer Web Application::B+:AMS(20270427):4064CCFCE6CFC55AE048B353187F610F600DD26EE0FD704D292A020EF652FA31F5C7',
  // Redirects ----------------------------------------------
  consoleUrl: 'https://console-pat.digitalvault.td.com',
  // Docusign ----------------------------------------------
  docusignUrl: 'https://appdemo.docusign.com/',
  // Mobile -------------------------
  googlePlayUrl:
    'https://play.google.com/store/apps/details?id=com.app.sidedrawer',
  appStoreUrl: 'https://apps.apple.com/ca/app/sidedrawer/id1451170345',
  appPauseApp: 180,
};
