<app-sd-dialog-template-a11y
  (closeButtonClicked)="onClose()"
  [type]="dialogTemplateTypes.Info">
  <ng-container dialog-header>
    <h2>
      {{ 'recordspermissionalert_errornolicensetitle' | dictionary | async }}
    </h2>
  </ng-container>
  <ng-container dialog-body>
    <div
      [innerHTML]="
        'recordspermissionalert_errornolicensebody' | dictionary | async
      "
      class="base-dialog-content"></div>
  </ng-container>
  <ng-container dialog-footer>
    <app-sd-flat-button-a11y
      (buttonClicked)="onSubmit()"
      [primary]="true"
      class="primary">
      {{ 'recordspermissionalert_errornolicensebutton' | dictionary | async }}
    </app-sd-flat-button-a11y>
  </ng-container>
</app-sd-dialog-template-a11y>
