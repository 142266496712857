import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { debounceTime, of, Subject, switchMap } from 'rxjs';
import { AccessibilityHelper } from 'src/app/core/helpers/accessibility.helper';
import { SdAccessibilitySetting } from 'src/app/core/models/sd-accessibility-model';
import { DictionaryPipeModule } from 'src/app/dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { SdIconButtonA11yComponent } from 'src/app/shared/sd-icon-button-a11y/components/sd-icon-button-a11y/sd-icon-button-a11y.component';
import { SdSvgA11yModule } from 'src/app/shared/sd-svg-a11y/sd-svg-a11y.module';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sd-sort-button-a11y',
  template: `
    <app-sd-icon-button-a11y
      (btnClicked)="onClick()"
      [iconColor]="'var(--primaryColor)'"
      [iconHeight]="0.9"
      [squareBackground]="true"
      [iconWidth]="0.9"
      [icon]="cdn + ('globalparams_sorticon' | dictionary | async)"
      [setSdAccessibility]="{
        ariaLabel: sdAccessibility?.ariaLabel,
        role: 'button'
      }" />
  `,
  styleUrls: ['./sd-sort-button-a11y.component.scss'],
  standalone: true,
  imports: [
    SdSvgA11yModule,
    SdIconButtonA11yComponent,
    DictionaryPipeModule,
    AsyncPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SdSortButtonA11yComponent {
  @Output() sortButtonClicked = new EventEmitter<boolean>(false);
  sdAccessibility: SdAccessibilitySetting;
  cdn = environment.cdn;

  @Input() set setSdAccessibility(value: SdAccessibilitySetting) {
    this.sdAccessibility = AccessibilityHelper.setDefaultAccessibility(value);
  }
  onClickHandlerSubject$ = new Subject<void>();

  constructor(private readonly destroyRef: DestroyRef) {
    this.onClickHandlerSubject$
      .pipe(
        debounceTime(300),
        takeUntilDestroyed(this.destroyRef),
        switchMap(() => of(this.sortButtonClicked.emit(true)))
      )
      .subscribe();
  }

  onClick(): void {
    this.onClickHandlerSubject$.next();
  }
}
