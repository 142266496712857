<div
  *ngIf="
    collaboratorsSectionEnabled() &&
    !isViewer() &&
    !isSimplifiedRecordViewAndEmpty()
  "
  class="record-network-list">
  <div class="record-network-list-header">
    <div
      [attr.aria-label]="
        ('recordnetworklist_title' | dictionarySignal) +
        ' ' +
        ('recordnetworklist_tooltip' | dictionarySignal)
      "
      class="record-network-list-header-title"
      role="heading"
      aria-level="3">
      {{ 'recordnetworklist_title' | dictionarySignal }}

      <app-sd-tooltip-button-a11y
        [tooltip]="'recordnetworklist_tooltip' | dictionarySignal" />
    </div>
    <div
      *ngIf="showAddButton() && userCanOperate()"
      class="record-network-list-header-action-buttons">
      <app-sd-icon-button-a11y
        (btnClicked)="onAddCollaborator()"
        [iconColor]="SdColorPalette.primaryColor"
        [iconHeight]="1.25"
        [iconWidth]="1.25"
        [icon]="cdn + ('globalparams_addicon' | dictionarySignal)"
        [setSdAccessibility]="{
          ariaLabel: 'recordnetworklist_addbuttontooltip' | dictionarySignal,
          role: 'button'
        }"
        [squareBackground]="false"
        [tooltip]="'recordnetworklist_addbuttontooltip' | dictionarySignal" />
    </div>
  </div>

  <ng-container>
    <div *ngIf="gettingInformation()" class="record-network-list-no-spinner">
      <app-sd-progress-spinner-a11y />
    </div>

    <div *ngIf="!gettingInformation() && networks()?.length === 0">
      <app-sd-empty-template
        [emptyStateInline]="true"
        [emptyStateString]="
          'recordnetworklist_emptystate' | dictionarySignal
        " />
    </div>

    <div *ngIf="networks()?.length > 0" class="record-network-list-content">
      <app-sd-infinite-list
        [hasMore]="hasMore()"
        [loading]="networks()?.length > 0 && gettingInformation()"
        (loadMoreClicked)="onNextPage()"
        (loadMoreReached)="onNextPage()">
        <ng-container
          *ngFor="
            let network of !showAll() ? networks()?.slice(0, 3) : networks();
            trackBy: trackBy
          ">
          <app-network-list-item
            [network]="{
              network: network,
              networkEntity: NetworkEntity.records
            }"
            [eventsEnabled]="userCanOperate()"
            [handleMenuEvents]="menuEvents()"
            [setCloseModalParent]="closeModalParent"
            (closeModalParentEmitter)="closeModalParentEmitter.emit($event)" />
        </ng-container>
      </app-sd-infinite-list>
    </div>

    <div
      *ngIf="networks()?.length > 3 && !showAll()"
      class="record-network-list-view-all">
      <app-sd-link-button-a11y
        (click)="getViewAllUrl($event)"
        [ariaLabel]="
          'recordnetworklist_viewallcollaborators' | dictionarySignal
        "
        [href]="'#'"
        [template]="SdLinkButtonA11yTemplates.linkNative">
        <span class="record-network-list-view-all-link-content" linkNative>
          {{ 'recordnetworklist_viewallcollaborators' | dictionarySignal }}
        </span>
      </app-sd-link-button-a11y>
    </div>
  </ng-container>
</div>
