import {
  createActionGroup,
  createFeature,
  createReducer,
  createSelector,
  emptyProps,
  on,
  props,
} from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { PaginatorApiResponse } from '../../core/models/paginator-api-response.dto';
import { NetworkV2 } from '../models/network.v2.type';
import { NetworkResourceOptions } from '../models/network-resource-options.type';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { NetworksSelectorFilter } from '../models/networks-selector-filter.type';
import { NetworkHelper } from '../helpers/network.helper';
import { Option } from '../../shared/sd-forms/models/option.model';

export const NetworkSearchActions = createActionGroup({
  source: 'network-search',
  events: {
    FilterChanged: props<{ filter: string }>(),
    Loaded: props<{
      response: PaginatorApiResponse<NetworkV2>;
      requestId: string;
      sideDrawerId: string;
      options?: NetworkResourceOptions;
    }>(),
    Clear: emptyProps(),
    NextPageRequested: emptyProps(),
    Requested: emptyProps(),
    Error: props<{ requestId: string; httpError: HttpErrorResponse }>(),
  },
});

interface NetworkSearchState extends EntityState<NetworkV2> {
  requests: Map<
    string,
    {
      requestId?: string;
      sideDrawerId?: string;
      hasMore?: boolean;
      nextPage?: string;
      previousPage?: string;
      totalCount?: number;
      httpError?: HttpErrorResponse;
      options?: NetworkResourceOptions;
      gettingNetworks?: boolean;
    }
  >;
  filter: string;
}

const adapter = createEntityAdapter<NetworkV2>({
  selectId: (network: NetworkV2) => network.id,
});

const initialState: NetworkSearchState = adapter.getInitialState({
  requests: new Map(),
  filter: null,
});

export const networkSearchFeature = createFeature({
  name: 'network-search',
  reducer: createReducer(
    initialState,
    on(NetworkSearchActions.filterChanged, (state, { filter }) => ({
      ...state,
      ...adapter.removeAll(state),
      requests: new Map(),
      filter,
    })),
    on(
      NetworkSearchActions.loaded,
      (state, { response, requestId, sideDrawerId, options }) => {
        const aux = new Map(state.requests);
        aux.set(requestId, {
          requestId,
          sideDrawerId,
          hasMore: response.hasMore,
          nextPage: response.nextPage,
          previousPage: response.previousPage,
          totalCount: response.totalCount,
          options,
          httpError: null,
          gettingNetworks: false,
        });
        return {
          ...adapter.upsertMany(
            NetworkHelper.filterInvalidNetworkV2Items(response.data, true),
            state
          ),
          requests: aux,
        };
      }
    ),
    on(NetworkSearchActions.error, (state, { httpError, requestId }) => {
      const aux = new Map(state.requests);
      const request = aux.get(requestId) ?? {};
      aux.set(requestId, {
        ...request,
        requestId,
        hasMore: false,
        nextPage: null,
        gettingNetworks: false,
        httpError,
      });
      return {
        ...state,
        requests: aux,
      };
    }),
    on(NetworkSearchActions.nextPageRequested, state => {
      const aux = new Map(state.requests);
      Array.from(aux.values())
        ?.filter(request => request.nextPage?.trim()?.length > 0)
        .forEach(request =>
          aux.set(request.requestId, { ...request, gettingNetworks: true })
        );
      return {
        ...state,
        requests: aux,
      };
    }),
    on(NetworkSearchActions.clear, () => initialState)
  ),
});

const { selectAll } = adapter.getSelectors();

export const networkSearchStateSelector =
  networkSearchFeature['selectNetwork-searchState'];
export const networkSearchSelectAllSelector = createSelector(
  networkSearchStateSelector,
  selectAll
);
export const networkSearchRequestsSelector =
  networkSearchFeature.selectRequests;
export const networkSearchFilterSelector = networkSearchFeature.selectFilter;
export const networkSearchListSelector = (payload?: NetworksSelectorFilter) =>
  createSelector(networkSearchSelectAllSelector, networks =>
    NetworkHelper.networksSelectorFilter(networks, payload)
  );
export const networkSearchListAsOptionsSelector = (payload?: {
  excludeTeams: boolean;
}) =>
  createSelector(networkSearchListSelector({ asUsers: true }), networks =>
    networks
      .filter(network => (payload?.excludeTeams ? !network.teamNetwork : true))
      .map(network =>
        network.teamNetwork
          ? new Option(
              network.teamNetwork.teamId,
              network.teamNetwork.name,
              network.teamNetwork.logo
            )
          : new Option(
              network.contributor.openId ?? network.contributor.email,
              network.contributor.email,
              network.contributor.profilePhoto,
              network.contributor.name
            )
      )
  );
export const networkSearchListAsOptionsFilteredSelector = createSelector(
  networkSearchListAsOptionsSelector(),
  networkSearchFilterSelector,
  (options, filter) =>
    filter
      ? options.filter(
          option =>
            option.key?.includes(filter) ||
            option.value?.includes(filter) ||
            option.description?.includes(filter)
        )
      : []
);
export const networkSearchListFilteredSelector = createSelector(
  networkSearchListSelector({ asUsers: true }),
  networkSearchFilterSelector,
  (networks, filter) =>
    filter
      ? networks.filter(
          network =>
            network.teamNetwork?.name
              ?.toLowerCase()
              ?.includes(filter?.toLowerCase()) ||
            network.contributor?.name
              ?.toLowerCase()
              ?.includes(filter?.toLowerCase()) ||
            network.contributor?.email
              ?.toLowerCase()
              ?.includes(filter?.toLowerCase())
        )
      : []
);
export const searchingNetworkSelector = createSelector(
  networkSearchRequestsSelector,
  requests =>
    Array.from(requests.values())?.some(request => request.gettingNetworks)
);
export const networkSearchHasMoreSelector = createSelector(
  networkSearchRequestsSelector,
  requests => Array.from(requests.values())?.some(request => request.hasMore)
);
export const networkSearchNextPageRequestsSelector = createSelector(
  networkSearchRequestsSelector,
  requests =>
    Array.from(requests.values())?.filter(
      request => request.nextPage?.trim()?.length > 0
    )
);
