import {
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { combineLatest, map, Observable, of, take, tap } from 'rxjs';
import { DateHelper } from 'src/app/core/helpers/date.helper';
import { SdFormHelper } from 'src/app/core/helpers/sd-form.helper';
import { UtilsHelper } from 'src/app/core/helpers/utils.helper';
import {
  dictionarySelector,
  frequencySelector,
} from 'src/app/dictionary/store/dictionary.selectors';
import { Network } from 'src/app/networks/models/network.model';
import { TeamNetwork } from 'src/app/networks/models/team-network.model';
import { AppState } from 'src/app/reducers';
import { Option } from 'src/app/shared/sd-forms/models/option.model';
import { DateTimeRangeFormatted } from '../models/dateTimeRangeFormatted.model';
import { Frequency } from '../models/enums/frequency.enum';
import { ReminderShortDictionary } from '../models/reminder-short-dictionary.model';
import { Reminders } from '../models/reminders.model';
import { SfrShortDictionary } from '../models/sfr-short-dictionary.model';
import { activeSideDrawerSelector } from 'src/app/sidedrawer/store/sidedrawer.selector';

export class ReminderHelper {
  static capitalize(s): string {
    if (typeof s !== 'string') {
      return '';
    }
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  static emailValidator(value: string | Option): boolean {
    const regexp0 =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/;

    if (typeof value === 'object') {
      if (value?.value === '') {
        return false;
      }
      return regexp0.test(value?.value);
    }

    if (typeof value !== 'object') {
      if (value === '') {
        return false;
      }
      return regexp0.test(value);
    }
  }

  static dateValidator(control: UntypedFormControl): { [s: string]: boolean } {
    const startDate =
      control?.parent?.get('startDateController')?.value ?? null;
    const endDate = control?.parent?.get('endDateController')?.value ?? null;
    if (startDate && endDate) {
      const endDateMoment = moment(endDate, 'MM:DD:YYYY');
      const startDateMoment = moment(startDate, 'MM:DD:YYYY').startOf('day');
      // NOTE Difference in number of days
      const diff = moment
        .duration(endDateMoment.diff(startDateMoment))
        .asDays();
      if (diff < 2) {
        return {
          invalidDate: true,
        };
      }
    }
    return null;
  }

  static dateValidatorReminder(control: UntypedFormControl): {
    [s: string]: boolean;
  } {
    const startDate =
      control?.parent?.get('startDateController')?.value ?? null;
    const endDate = control?.parent?.get('endDateController')?.value ?? null;
    if (startDate && endDate) {
      const diff = DateHelper.compareDates(endDate, startDate);
      if (diff <= 0) {
        return {
          invalidDate: true,
        };
      }
    }
    return null;
  }

  static todayAfterSomeMinutes(control: UntypedFormControl): {
    [s: string]: boolean;
  } {
    const minutes = 3;
    const timeControllerValue =
      control?.parent?.get('timeController')?.value ?? null;
    const startDateControllerValue =
      control?.parent?.get('startDateController')?.value ?? null;

    if (startDateControllerValue && timeControllerValue) {
      const startTime = moment(timeControllerValue, 'hh:mm A');
      const time = moment(startTime, 'hh:mm A');

      const startDateFormatted = moment(
        moment(startDateControllerValue).set({
          minute: time.get('minutes'),
          hour: time.get('hour'),
        })
      );

      const nowDateFormatted = moment(
        moment().add({
          minute: minutes,
        })
      );

      if (startDateFormatted <= nowDateFormatted) {
        return {
          invalidTime: true,
        };
      }
    }
    return null;
  }

  static removeTimeFromDateString(
    type = 'startDate',
    reminderItem: Reminders
  ): string {
    return type === 'startDate'
      ? reminderItem?.schedule?.startDate?.split('T')[0]
      : reminderItem?.schedule?.recurrent?.endDate?.split('T')[0];
  }

  static verifyValidatorsAccordingToRecurrence(
    recurrenceString: string,
    patternController: UntypedFormControl,
    monthsController: UntypedFormControl,
    endDateController?: UntypedFormControl
  ): void {
    if (
      recurrenceString === Frequency.Daily ||
      recurrenceString === Frequency.Weekly ||
      recurrenceString === Frequency.Monthly ||
      recurrenceString === Frequency.Yearly
    ) {
      endDateController?.addValidators([Validators.required]);
      endDateController?.updateValueAndValidity();
    }

    if (
      recurrenceString === Frequency.Daily ||
      recurrenceString === Frequency.DoesNotRepeat
    ) {
      patternController.clearValidators();
      patternController.updateValueAndValidity();
    }
    if (
      recurrenceString === Frequency.Weekly ||
      recurrenceString === Frequency.Monthly ||
      recurrenceString === Frequency.Yearly
    ) {
      patternController.setValidators([Validators.required]);
      patternController.updateValueAndValidity();
      patternController.markAsTouched();
    }

    if (recurrenceString === Frequency.Weekly) {
      monthsController.clearValidators();
      monthsController.updateValueAndValidity();
    }

    if (recurrenceString === Frequency.Yearly) {
      monthsController.setValidators([Validators.required]);
      monthsController.updateValueAndValidity();
    }
    if (recurrenceString !== Frequency.Yearly) {
      monthsController.clearValidators();
      monthsController.updateValueAndValidity();
      monthsController.markAsTouched();
    }
  }

  static setTemplateSentences(
    templateSentence: string,
    recurrence: string,
    expression = /{{startDate}}|{{frequency}}|{{frequencynumber}}/gi,
    reminderItem: Reminders,
    sfrShortDictionary: SfrShortDictionary | ReminderShortDictionary
  ): string {
    return templateSentence?.replace(expression, (element: any) => {
      if (String(element).toLowerCase() === '{{startDate}}'.toLowerCase()) {
        return ReminderHelper.removeTimeFromDateString(
          'startDate',
          reminderItem
        );
      }
      if (String(element).toLowerCase() === '{{frequency}}'.toLowerCase()) {
        switch (recurrence) {
          case Frequency.Daily:
            return sfrShortDictionary.frequencyFromDictionaryDaily;
          case Frequency.Weekly:
            return sfrShortDictionary.frequencyFromDictionaryWeekly;
          case Frequency.Monthly:
            return sfrShortDictionary.frequencyFromDictionaryMonthly;
          case Frequency.Yearly:
            return sfrShortDictionary.frequencyFromDictionaryYearly;
          default:
            break;
        }
      }
      if (
        String(element).toLowerCase() === '{{frequencynumber}}'.toLowerCase()
      ) {
        switch (recurrence) {
          case Frequency.Daily:
            return !!reminderItem?.schedule.recurrent.iteration &&
              reminderItem?.schedule.recurrent.iteration > 0
              ? reminderItem?.schedule.recurrent.iteration
              : '...';
          case Frequency.Weekly:
            return !!reminderItem?.schedule.recurrent.iteration &&
              reminderItem?.schedule.recurrent.iteration > 0
              ? reminderItem?.schedule.recurrent.iteration
              : '...';
          case Frequency.Monthly:
            return !!reminderItem?.schedule.recurrent.iteration &&
              reminderItem?.schedule.recurrent.iteration > 0
              ? reminderItem?.schedule.recurrent.iteration
              : '...';
          case Frequency.Yearly:
            return !!reminderItem?.schedule.recurrent.iteration &&
              reminderItem?.schedule.recurrent.iteration > 0
              ? reminderItem?.schedule.recurrent.iteration
              : '...';
          default:
            break;
        }
      }
      if (String(element).toLowerCase() === '{{weekdayarray}}'.toLowerCase()) {
        return reminderItem.schedule.recurrent.pattern
          ? reminderItem.schedule.recurrent.pattern
          : '...';
      }
      if (
        String(element).toLowerCase() === '{{dayofmonth}}'.toLowerCase() &&
        recurrence === Frequency.Monthly
      ) {
        return reminderItem.schedule.recurrent.pattern
          ? reminderItem.schedule.recurrent.pattern
          : '...';
      }
      if (
        String(element).toLowerCase() === '{{dayofyear}}'.toLowerCase() &&
        recurrence === Frequency.Yearly
      ) {
        return !!reminderItem.schedule.recurrent.pattern &&
          !reminderItem.schedule.recurrent.pattern.startsWith('null') &&
          !reminderItem.schedule.recurrent.pattern.endsWith('null') &&
          !reminderItem.schedule.recurrent.pattern.startsWith('undefined')
          ? reminderItem.schedule.recurrent.pattern
          : '...';
      }
      if (String(element).toLowerCase() === '{{endDate}}'.toLowerCase()) {
        return ReminderHelper.removeTimeFromDateString('endDate', reminderItem);
      }
      return element;
    });
  }

  static formatDateAndTime(
    timeController: UntypedFormControl,
    startDateController: UntypedFormControl,
    recurrenceController: UntypedFormControl,
    endDateController: UntypedFormControl,
    dateTimeRangeFormatted: DateTimeRangeFormatted
  ): void | boolean {
    let startTime = null;
    let time = null;
    let lastTimeString = null;
    let lastTime = null;
    if (timeController.value) {
      startTime = moment(timeController.value, 'hh:mm A');
      time = moment(startTime, 'hh:mm A');
      lastTimeString = moment(timeController.value, 'hh:mm A');
      lastTime = moment(lastTimeString, 'hh:mm A');
    }
    if (!timeController.value) {
      startTime = moment().format('hh:mm A');
      time = moment(startTime, 'hh:mm A');
      lastTimeString = moment().format('hh:mm A');
      lastTime = moment(lastTimeString, 'hh:mm A');
    }
    dateTimeRangeFormatted.startDateFormatted = moment(
      moment(startDateController.value).set({
        minute: time.get('minutes'),
        hour: time.get('hour'),
      })
    )
      .utc()
      .toISOString();

    dateTimeRangeFormatted.startDateFormattedLocal = moment(
      moment(startDateController.value).set({
        minute: time.get('minutes'),
        hour: time.get('hour'),
      })
    )
      .local()
      .format('YYYY-MM-DD');

    if (
      recurrenceController.value === Frequency.DoesNotRepeat ||
      !endDateController.value
    ) {
      return true;
    }
    const hasEndDate = endDateController.value !== null;
    if (hasEndDate) {
      dateTimeRangeFormatted.endDateFormatted = moment(
        moment(endDateController.value).set({
          minute: lastTime.get('minutes'),
          hour: lastTime.get('hour'),
        })
      )
        .utc()
        .toISOString();

      dateTimeRangeFormatted.endDateFormattedLocal = moment(
        moment(endDateController.value).set({
          minute: lastTime.get('minutes'),
          hour: lastTime.get('hour'),
        })
      )
        .local()
        .format('YYYY-MM-DD');
    }
  }

  static getSentenceFromReminderItem(
    reminderItem: Reminders,
    shortDictionary: SfrShortDictionary | ReminderShortDictionary
  ): string {
    const hasEndDate = reminderItem?.schedule?.recurrent?.endDate;
    let sentence = '';
    if (reminderItem?.schedule?.recurrent?.frequency === null) {
      sentence = ReminderHelper.setTemplateSentences(
        shortDictionary.sfrReminderCreationNoRepeatSentence,
        null,
        /{{startDate}}|{{frequency}}|{{frequencynumber}}/gi,
        reminderItem,
        shortDictionary
      );
    }
    if (reminderItem?.schedule?.recurrent?.frequency === Frequency.Daily) {
      sentence = ReminderHelper.setTemplateSentences(
        shortDictionary.frequencySentenceNoEndDateDaily,
        Frequency.Daily,
        /{{startDate}}|{{frequency}}|{{frequencynumber}}/gi,
        reminderItem,
        shortDictionary
      );
    }
    if (reminderItem?.schedule?.recurrent?.frequency === Frequency.Weekly) {
      sentence = ReminderHelper.setTemplateSentences(
        shortDictionary.frequencySentenceNoEndDateWeekly,
        Frequency.Weekly,
        /{{startDate}}|{{frequency}}|{{frequencynumber}}|{{weekdayarray}}/gi,
        reminderItem,
        shortDictionary
      );
    }
    if (reminderItem?.schedule?.recurrent?.frequency === Frequency.Monthly) {
      sentence = ReminderHelper.setTemplateSentences(
        shortDictionary.frequencySentenceNoEndDateMonthly,
        Frequency.Monthly,
        /{{startDate}}|{{frequency}}|{{frequencynumber}}|{{weekdayarray}}|{{monthname}}|{{dayofmonth}}/gi,
        reminderItem,
        shortDictionary
      );
    }
    if (reminderItem?.schedule?.recurrent?.frequency === Frequency.Yearly) {
      sentence = ReminderHelper.setTemplateSentences(
        shortDictionary.frequencySentenceNoEndDateYearly,
        Frequency.Yearly,
        /{{startDate}}|{{frequency}}|{{frequencynumber}}|{{weekdayarray}}|{{dayofyear}}|{{dayofmonth}}/gi,
        reminderItem,
        shortDictionary
      );
    }
    if (hasEndDate) {
      sentence += ReminderHelper.setTemplateSentences(
        shortDictionary.sfrReminderCreationRepeatEndSentence,
        null,
        /{{startDate}}|{{frequency}}|{{frequencynumber}}|{{endDate}}/gi,
        reminderItem,
        shortDictionary
      );
    }
    return sentence;
  }

  static setFromDictionaryForReminder(
    store: Store<AppState>,
    reminderShortDictionary: ReminderShortDictionary,
    recurrenceOptions: Option[]
  ): void {
    store
      .pipe(
        select(dictionarySelector),
        take(1),
        tap(dictionary => {
          reminderShortDictionary.remindersFormEndDateMandatoryMessage =
            dictionary.remindersform_enddatemandatorymessage;
          reminderShortDictionary.remindersFormFieldMandatoryMessage =
            dictionary.globalparams_requirederror;
          reminderShortDictionary.customMessageFromDictionary =
            dictionary.remindersform_custommessagesentence;
          reminderShortDictionary.sfrReminderCreationNoRepeatSentence =
            dictionary.remindersform_norepeatsentence;
          reminderShortDictionary.sfrReminderCreationRepeatEndSentence =
            dictionary.remindersform_repeatendsentence;
          reminderShortDictionary.sfrStartDateMandatoryMessage =
            dictionary.remindersform_startdatemandatorymessage;
          reminderShortDictionary.remindersFormErrorReminderBody =
            dictionary.remindersform_errorreminderbody;
          reminderShortDictionary.remindersFormStartTimeMandatoryMessage =
            dictionary.remindersform_starttimemandatorymessage;
        })
      )
      .subscribe();
    store
      .pipe(
        select(frequencySelector),
        take(1),
        tap(frequencies => {
          if (frequencies) {
            frequencies.forEach(element => {
              switch (element?.frequency) {
                case String(Frequency.Daily).toLowerCase():
                  recurrenceOptions.push(
                    new Option(
                      ReminderHelper.capitalize(element?.frequency),
                      element?.frequencyLabel
                    )
                  );
                  reminderShortDictionary.frequencyFromDictionaryDaily =
                    element?.frequencyLabel;
                  reminderShortDictionary.frequencyValueFromDictionaryDaily =
                    element?.frequencyValue;
                  reminderShortDictionary.frequencySentenceFromDictionaryDaily =
                    element?.frequencySentence;
                  reminderShortDictionary.frequencyRepeatOnFromDictionaryDaily =
                    element?.frequencyRepeatOn;
                  reminderShortDictionary.frequencyRepeatOfDaily =
                    element?.frequencyRepeatOf;
                  reminderShortDictionary.frequencySentenceNoEndDateDaily =
                    element?.frequencySentenceNoEndDate;
                  break;
                case String(Frequency.Weekly).toLowerCase():
                  recurrenceOptions.push(
                    new Option(
                      ReminderHelper.capitalize(element?.frequency),
                      element?.frequencyLabel
                    )
                  );
                  reminderShortDictionary.frequencyFromDictionaryWeekly =
                    element?.frequencyLabel;
                  reminderShortDictionary.frequencyValueFromDictionaryWeekly =
                    element?.frequencyValue;
                  reminderShortDictionary.frequencySentenceFromDictionaryWeekly =
                    element?.frequencySentence;
                  reminderShortDictionary.frequencyRepeatOnFromDictionaryWeekly =
                    element?.frequencyRepeatOn;
                  reminderShortDictionary.frequencyRepeatOfWeekly =
                    element?.frequencyRepeatOf;
                  reminderShortDictionary.frequencySentenceNoEndDateWeekly =
                    element?.frequencySentenceNoEndDate;
                  break;
                case String(Frequency.Monthly).toLowerCase():
                  recurrenceOptions.push(
                    new Option(
                      ReminderHelper.capitalize(element?.frequency),
                      element?.frequencyLabel
                    )
                  );
                  reminderShortDictionary.frequencyFromDictionaryMonthly =
                    element?.frequencyLabel;
                  reminderShortDictionary.frequencyValueFromDictionaryMonthly =
                    element?.frequencyValue;
                  reminderShortDictionary.frequencySentenceFromDictionaryMonthly =
                    element?.frequencySentence;
                  reminderShortDictionary.frequencyRepeatOnFromDictionaryMonthly =
                    element?.frequencyRepeatOn;
                  reminderShortDictionary.frequencyRepeatOfMonthly =
                    element?.frequencyRepeatOf;
                  reminderShortDictionary.frequencySentenceNoEndDateMonthly =
                    element?.frequencySentenceNoEndDate;
                  break;
                case String(Frequency.Yearly).toLowerCase():
                  recurrenceOptions.push(
                    new Option(
                      ReminderHelper.capitalize(element?.frequency),
                      element?.frequencyLabel
                    )
                  );
                  reminderShortDictionary.frequencyFromDictionaryYearly =
                    element?.frequencyLabel;
                  reminderShortDictionary.frequencyValueFromDictionaryYearly =
                    element?.frequencyValue;
                  reminderShortDictionary.frequencySentenceFromDictionaryYearly =
                    element?.frequencySentence;
                  reminderShortDictionary.frequencyRepeatOnFromDictionaryYearly =
                    element?.frequencyRepeatOn;
                  reminderShortDictionary.frequencyRepeatOfYearly =
                    element?.frequencyRepeatOf;
                  reminderShortDictionary.frequencySentenceNoEndDateYearly =
                    element?.frequencySentenceNoEndDate;
                  break;
                default:
                  break;
              }
            });
          }
        })
      )
      .subscribe();
  }

  static checkFormStateForReminder(
    form: UntypedFormGroup,
    contactsSelected: Option[] = []
  ): ValidationErrors {
    let validationErrors: ValidationErrors;
    validationErrors = {
      name: SdFormHelper.getErrorFormControl(
        form,
        'nameController',
        'required',
        'globalparams_requirederror'
      ),
      message: SdFormHelper.getErrorFormControl(
        form,
        'messageController',
        'required',
        'globalparams_requirederror'
      ),
      recurrence: SdFormHelper.getErrorFormControl(
        form,
        'recurrenceController',
        'required',
        'globalparams_requirederror'
      ),
      everyController:
        SdFormHelper.getErrorFormControl(
          form,
          'everyController',
          'min',
          'remindersform_fieldminvalidationmessage'
        ) ??
        SdFormHelper.getErrorFormControl(
          form,
          'everyController',
          'max',
          'remindersform_fieldmaxvalidationmessage'
        ) ??
        SdFormHelper.getErrorFormControl(
          form,
          'everyController',
          'maxLength',
          'remindersform_fieldmaxlengthvalidationmessage'
        ) ??
        SdFormHelper.getErrorFormControl(
          form,
          'everyController',
          'minLength',
          'remindersform_fieldminlengthvalidationmessage'
        ),
      patternController: SdFormHelper.getErrorFormControl(
        form,
        'patternController',
        'required',
        'globalparams_requirederror'
      ),
      monthsController: SdFormHelper.getErrorFormControl(
        form,
        'monthsController',
        'required',
        'globalparams_requirederror'
      ),
      time:
        SdFormHelper.getErrorFormControl(
          form,
          'timeController',
          'required',
          'globalparams_requirederror'
        ) ??
        SdFormHelper.getErrorFormControl(
          form,
          'timeController',
          'invalidTime',
          'globalparams_requirederror'
        ),
      startDate:
        SdFormHelper.getErrorFormControl(
          form,
          'startDateController',
          'required',
          'globalparams_requirederror'
        ) ??
        SdFormHelper.getErrorFormControl(
          form,
          'startDateController',
          'invalidDate',
          'remindersform_startdatemandatorymessage'
        ),
      endDate:
        SdFormHelper.getErrorFormControl(
          form,
          'endDateController',
          'invalidDate',
          'remindersform_enddatemandatorymessage'
        ) ??
        SdFormHelper.getErrorFormControl(
          form,
          'endDateController',
          'required',
          'globalparams_requirederror'
        ),
    };

    if (contactsSelected?.length === 0) {
      const newValidationError: ValidationErrors = {
        required: true,
        errorMessage: 'singlefilerequestform_emptystate',
      };
      validationErrors = {
        ...validationErrors,
        emails: newValidationError,
      };
    }
    return validationErrors;
  }
  public static getInitialTime(): string {
    return moment().add({ minute: 5 }).format('hh:mm A');
  }
  public static getInitialDate(): Date {
    return moment().add({ minute: 5 }).toDate();
  }
  public static formatStartDate(rawDate: Date, rawTime: string): string {
    const startTime = moment(rawTime, 'hh:mm A');
    const time = moment(startTime, 'hh:mm A');
    return moment(
      moment(rawDate).set({
        minute: time.get('minutes'),
        hour: time.get('hour'),
      })
    )
      .utc()
      .toISOString();
  }
  public static getMinDate(): Date {
    const date = new Date();
    date.setUTCDate(date.getUTCDate());
    return date;
  }
}
