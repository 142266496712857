import { FileUpload } from '../models/file-upload.model';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  UploadFilesActions,
  UploadFilesActionsTypes,
} from './upload-files.actions';

export interface UploadFilesState extends EntityState<FileUpload> {
  uploadFilesFormErrors: any;
}

export const fileUploadEntityAdapter: EntityAdapter<FileUpload> =
  createEntityAdapter<FileUpload>({
    selectId: fileUpload => fileUpload.id,
  });

export const initialUploadFileState: UploadFilesState =
  fileUploadEntityAdapter.getInitialState({
    uploadFilesFormErrors: {},
  });

export function uploadFilesReducer(
  state = initialUploadFileState,
  action: UploadFilesActions
): UploadFilesState {
  switch (action.type) {
    case UploadFilesActionsTypes.UploadFilesLoaded:
      return fileUploadEntityAdapter.upsertMany(action.payload.data, state);
    case UploadFilesActionsTypes.UploadFileAdded:
      return fileUploadEntityAdapter.addOne(action.payload.data, state);
    case UploadFilesActionsTypes.UploadFileUpdated:
      return fileUploadEntityAdapter.updateOne(action.payload.data, state);
    case UploadFilesActionsTypes.UploadFileDeleted:
      return fileUploadEntityAdapter.removeOne(action.payload.id, state);
    case UploadFilesActionsTypes.UploadFilesClear:
      return fileUploadEntityAdapter.removeAll(state);
    case UploadFilesActionsTypes.UploadFilesFormErrorAdded: {
      const addFileErrors = { ...state.uploadFilesFormErrors };
      addFileErrors[`${action.payload.id}`] = action.payload.id;
      return {
        ...state,
        uploadFilesFormErrors: { ...addFileErrors },
      };
    }
    case UploadFilesActionsTypes.UploadFilesFormErrorRemoved: {
      const removeFileErrors = { ...state.uploadFilesFormErrors };
      delete removeFileErrors[`${action.payload.id}`];
      return {
        ...state,
        uploadFilesFormErrors: { ...removeFileErrors },
      };
    }
    default:
      return state;
  }
}

export const { selectAll } = fileUploadEntityAdapter.getSelectors();
