import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecordFormViewStore } from '../record-form-view/record-form-view.store';
import { combineLatest, forkJoin, Observable, of, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import {
  activeRecordSelector,
  customFieldsSectionEnabledSelector,
  descriptionSectionEnabledSelector,
  specificFieldsSectionEnabledSelector,
} from '../../store/records-list.selectors';
import { AppState } from '../../../reducers';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { CustomField } from '../../models/custom-field.model';
import { map, take, tap } from 'rxjs/operators';
import { CustomFieldsSectionItemsModule } from '../../shared/custom-fields-section-items/custom-fields-section-items.module';
import { DictionaryPipeModule } from '../../../dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { environment } from '../../../../environments/environment';
import { SpecificField } from '../../models/specific-field.model';
import { specificFieldsListSelector } from '../../store/specific-fields.selectors';
import { SpecificFieldType } from '../../models/specific-field-type.model';
import { SpecificFieldsSectionItemModule } from '../../shared/specific-fields-section-item/specific-fields-section-item.module';
import { Router } from '@angular/router';
import { PlansRoutes } from 'src/app/plans/routes/plans.routes';
import { RecordsRoutes } from '../../routes/records.routes';
import { SdFlatButtonA11yModule } from '../../../shared/sd-flat-button-a11y/sd-flat-button-a11y.module';
import { Record } from '../../models/record.model';
import {
  CdkDrag,
  CdkDragDrop,
  CdkDropList,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { UtilsHelper } from '../../../core/helpers/utils.helper';
import { SdSvgA11yModule } from '../../../shared/sd-svg-a11y/sd-svg-a11y.module';
import { SdInputA11yComponent } from 'src/app/shared/sd-input-a11y/components/sd-input-a11y/sd-input-a11y.component';
import { SdIconButtonA11yComponent } from 'src/app/shared/sd-icon-button-a11y/components/sd-icon-button-a11y/sd-icon-button-a11y.component';
import { SdTextAreaA11yComponent } from 'src/app/shared/sd-text-area-a11y/components/sd-text-area-a11y.component';

@Component({
  selector: 'app-general-information-tab',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CustomFieldsSectionItemsModule,
    SdIconButtonA11yComponent,
    DictionaryPipeModule,
    SdInputA11yComponent,
    SdTextAreaA11yComponent,
    SpecificFieldsSectionItemModule,
    SdFlatButtonA11yModule,
    CdkDropList,
    CdkDrag,
    SdSvgA11yModule,
  ],
  templateUrl: './general-information-tab.component.html',
  styleUrls: ['./general-information-tab.component.scss'],
})
export class GeneralInformationTabComponent implements OnInit, OnDestroy {
  customFieldsSectionEnabled$: Observable<boolean> = this.store.select(
    customFieldsSectionEnabledSelector
  );
  descriptionSectionEnabled$: Observable<boolean> = this.store.select(
    descriptionSectionEnabledSelector
  );
  specificFieldsSectionEnabled$: Observable<boolean> = this.store.select(
    specificFieldsSectionEnabledSelector
  );
  activeRecord$: Observable<Record> = this.store.select(activeRecordSelector);

  descriptionControl = new FormControl<string>(null);
  storageLocationControl = new FormControl<string>(null);
  recordInfoForm = new FormGroup({
    description: this.descriptionControl,
    storageLocation: this.storageLocationControl,
  });
  recordInfoForm$ = this.recordInfoForm.valueChanges.pipe(
    tap(({ description, storageLocation }) => {
      this.recordFormViewStore.setStorageLocation(storageLocation);
      this.recordFormViewStore.setDescription(description);
    })
  );
  customFieldsForm = new UntypedFormGroup({});
  customFields$: Observable<CustomField[]> =
    this.recordFormViewStore.customFields$.pipe(
      tap(customFields => {
        const formGroup = {};
        customFields.forEach(cf => {
          formGroup[cf.id + '_title'] = new UntypedFormControl(cf.label);
          formGroup[cf.id + '_detail'] = new UntypedFormControl(cf.value);
        });
        this.customFieldsForm = new UntypedFormGroup(formGroup);
      }),
      map(cf => UtilsHelper.mapToArray<CustomField>(cf))
    );
  specificFields$: Observable<SpecificField[]> = this.store.pipe(
    select(specificFieldsListSelector),
    map(
      specificFields =>
        specificFields?.filter(
          sp => !sp.formType.includes(SpecificFieldType.enum)
        )
    )
  );
  vm$: Observable<{
    customFieldsSectionEnabled: boolean;
    descriptionSectionEnabled: boolean;
    specificFieldsSectionEnabled: boolean;
    customFields: CustomField[];
    specificFields: SpecificField[];
  }> = combineLatest([
    this.customFieldsSectionEnabled$,
    this.descriptionSectionEnabled$,
    this.specificFieldsSectionEnabled$,
    this.customFields$,
    this.specificFields$,
  ]).pipe(
    map(
      ([
        customFieldsSectionEnabled,
        descriptionSectionEnabled,
        specificFieldsSectionEnabled,
        customFields,
        specificFields,
      ]) => ({
        customFieldsSectionEnabled,
        descriptionSectionEnabled,
        specificFieldsSectionEnabled,
        customFields,
        specificFields,
      })
    )
  );
  cdn = environment.cdn;
  recordId = false;
  customFieldsAux: CustomField[] = [];
  subscription = new Subscription();

  constructor(
    protected readonly recordFormViewStore: RecordFormViewStore,
    protected readonly store: Store<AppState>,
    protected readonly router: Router
  ) {}

  ngOnInit(): void {
    forkJoin([
      this.recordFormViewStore.description$.pipe(take(1)),
      this.recordFormViewStore.storageLocation$.pipe(take(1)),
    ])
      .pipe(
        tap(([description, storageLocation]) => {
          this.descriptionControl.setValue(description);
          this.storageLocationControl.setValue(storageLocation);
        })
      )
      .subscribe();
    this.subscription.add(this.recordInfoForm$.subscribe());
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onCustomFieldValueChanges(event: CustomField): void {
    this.recordFormViewStore.customFieldValuesChanged(event);
  }

  onDeleteCustomField(id: string): void {
    this.recordFormViewStore.customFieldDeleted(id);
  }

  onAddCustomField(id): void {
    this.recordFormViewStore.customFieldAddItem(id);
  }

  onCustomFieldDrop(event: CdkDragDrop<CustomField[]>): void {
    this.customFieldsAux = event.container.data;
    moveItemInArray(
      this.customFieldsAux,
      event.previousIndex,
      event.currentIndex
    );
    const customFieldsMapAux = new Map<string | number, CustomField>();
    this.customFieldsAux.forEach(cf => {
      customFieldsMapAux.set(cf.id, cf);
    });
    this.recordFormViewStore.customFieldsSortItems(customFieldsMapAux);
  }

  onSpecificFieldValueChanges(event: { key: string; value: unknown }): void {
    this.recordFormViewStore.specificFieldValueChanges(event.key, event.value);
  }

  trackByCustomFields(index: number, item: CustomField): string {
    return item.id as string;
  }

  trackBySpecificField(index: number): number {
    return index;
  }

  onConfirm(): void {
    this.recordFormViewStore.updateRecord(
      of(() => {
        this.onBack();
      })
    );
  }

  onCancel(): void {
    const fromPlans = this.router.url
      .split(`/${RecordsRoutes.root}/`)[0]
      .includes(PlansRoutes.root);
    const url = fromPlans
      ? this.router.url.split(`/${RecordsRoutes.root}/`)[0]
      : this.router.url.split(`/${RecordsRoutes.form}/`)[0] +
        `/${RecordsRoutes.list}`;
    this.router.navigateByUrl(url);
  }

  onBack(): void {
    let id = this.router.url.split(`/${RecordsRoutes.form}/`)[1];
    id = id.split(`/`)[0];
    const fromPlans = this.router.url
      .split(`/${RecordsRoutes.root}/`)[0]
      .includes(PlansRoutes.root);
    const url = fromPlans
      ? this.router.url.split(`/${RecordsRoutes.root}/`)[0]
      : this.router.url.split(`/${RecordsRoutes.form}/`)[0] +
        `/${RecordsRoutes.view}/${id}`;
    this.router.navigateByUrl(url);
  }
}
