import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  featureName,
  selectAll,
  selectEntities,
  selectIds,
  SignatureState,
} from './signature.reducer';

export const signatureStateSelector =
  createFeatureSelector<SignatureState>(featureName);

export const envelopesListSelector = createSelector(
  signatureStateSelector,
  selectAll
);

export const envelopesEntitiesSelector = createSelector(
  signatureStateSelector,
  selectEntities
);

export const envelopeByEnvelopeIdSelector = (payload: { envelopeId: string }) =>
  createSelector(
    envelopesEntitiesSelector,
    entities => entities[payload.envelopeId] ?? null
  );

export const envelopesIdsSelector = createSelector(
  signatureStateSelector,
  selectIds
);

export const signatureHasMoreSelector = createSelector(
  signatureStateSelector,
  state => state.hasMore
);

export const signatureNextPageSelector = createSelector(
  signatureStateSelector,
  state => state.nextPage
);

export const signaturePreviousPageSelector = createSelector(
  signatureStateSelector,
  state => state.previousPage
);

export const signatureTotalCountSelector = createSelector(
  signatureStateSelector,
  state => state.totalCount
);

export const signatureGettingInformationSelector = createSelector(
  signatureStateSelector,
  state => state.gettingInformation
);

export const signatureFileToSignSelector = createSelector(
  signatureStateSelector,
  state => state.fileToSign
);

export const signatureActiveEnvelopeSelector = createSelector(
  signatureStateSelector,
  state => state.activeEnvelope
);

export const signatureActiveEnvelopeDocumentsSelector = (payload: {
  documentId: string;
}) =>
  createSelector(
    signatureActiveEnvelopeSelector,
    envelope =>
      envelope?.envelopeDocuments.find(
        document => document.documentId === payload.documentId
      )
  );

export const signatureGettingDocumentsByEnvelopeIdSelector = (payload: {
  envelopeId: string;
}) =>
  createSelector(
    signatureStateSelector,
    state => state.checkDocumentsOnRecord.get(payload.envelopeId) ?? false
  );

export const signatureDownloadingAndUploadingByEnvelopeIdAndDocumentIdSelector =
  (payload: { envelopeId: string; documentId: string }) =>
    createSelector(
      signatureStateSelector,
      state =>
        state.downloadingAndUploading.get(
          `${payload.envelopeId}-${payload.documentId}`
        ) ?? false
    );
