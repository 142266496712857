import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { take, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Observable, Subscription, switchMap } from 'rxjs';
import { ErrorLoaded } from 'src/app/core/store/core.actions';
import { Reminders } from 'src/app/reminders/models/reminders.model';
import { Dictionary } from 'src/app/dictionary/models/dictionary.model';
import { Locale } from 'src/app/dictionary/models/locale.model';
import { AppState } from 'src/app/reducers';
import { RemindersService } from 'src/app/reminders/services/reminders.service';
import {
  dictionarySelector,
  frequencySelector,
  localeDefaultSelector,
  reminderStatusSelector,
} from 'src/app/dictionary/store/dictionary.selectors';
import { DateHelper } from 'src/app/core/helpers/date.helper';
import { environment } from 'src/environments/environment';
import {
  ReminderChangeStatusRequested,
  ReminderCopyLinkRequested,
  ReminderDeleted,
  RemindersListActionsTypes,
} from 'src/app/reminders/store/reminders-list.action';
import { Option } from 'src/app/shared/sd-forms/models/option.model';
import * as moment from 'moment';
import { Frequency } from 'src/app/reminders/models/enums/frequency.enum';
import { ReminderStatus } from 'src/app/reminders/models/enums/reminder-status.enum';
import { ReminderStatusDTO } from 'src/app/reminders/models/reminder-status-dto';
import { ReminderShortDictionary } from 'src/app/reminders/models/reminder-short-dictionary.model';
import { ReminderHelper } from 'src/app/reminders/helpers/reminder-helper';
import { SdLinkMenuLinkType } from 'src/app/shared/sd-link-menu-a11y/models/enums/sd-link-menu-link-type-enum';
import { SdColorPalette } from 'src/app/core/models/enums/sd-color-palette-enum';
import { MenuItemType } from 'src/app/shared/sd-header-a11y/models/menu-item.type.enum';
import { SdLinkMenuItem } from 'src/app/shared/sd-link-menu-a11y/models/sd-link-menu-item.model';
import { RemindersDeleteDialogComponent } from 'src/app/reminders/shared/reminders-delete-dialog/components/reminders-delete-dialog/reminders-delete-dialog.component';
import {
  activeRecordSelector,
  userCanOperateOnThisRecordSelector,
} from 'src/app/records/store/records-list.selectors';
import { RemindersFormDialogA11yComponent } from '../../../reminders-form-dialog-a11y/components/reminders-form-dialog-a11y/reminders-form-dialog-a11y.component';
import { Actions, ofType } from '@ngrx/effects';
import { MatDialog } from '@angular/material/dialog';
import { RoutesHelper } from '../../../../../core/helpers/routes.helper';
import { FilingCabinetActions } from '../../../../../filing-cabinet/store/filing-cabinet.store';
import { activeSideDrawerSelector } from 'src/app/sidedrawer/store/sidedrawer.selector';
import { LiveAnnouncer } from '@angular/cdk/a11y';

@Component({
  selector: 'app-reminders-list-item-a11y',
  templateUrl: './sd-reminders-list-item-a11y.component.html',
  styleUrls: ['./sd-reminders-list-item-a11y.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SdRemindersListItemA11yComponent implements OnInit, OnDestroy {
  @Input() reminderItem: Reminders;
  @Input() options: Option[] = [];
  @Input() viewOnly = false;
  isViewAllReminder = false;
  dictionary: Dictionary;
  defaultLocale: Locale;
  loading = false;
  localeSubscription = new Subscription();
  cdn = environment.cdn;
  sentence: string;
  @Input() detailUrl: string;
  @Input() dialogMode = false;
  @Input() noRedirect = false;
  @Output() detailsClicked = new EventEmitter<boolean>();
  reminderStatus = ReminderStatus;
  reminderShortDictionary: ReminderShortDictionary =
    new ReminderShortDictionary();
  dictionary$: Observable<Dictionary>;
  reminderStateActive: ReminderStatusDTO;
  reminderStatePaused: ReminderStatusDTO;
  reminderStatusFromCollection: ReminderStatusDTO[];
  sdLinkMenuLinkType = SdLinkMenuLinkType;
  menuItemType = MenuItemType;
  sdColorPalette = SdColorPalette;
  ariaLabelSentence = '';
  userCanOperate = this.store.selectSignal(userCanOperateOnThisRecordSelector);
  constructor(
    protected readonly store: Store<AppState>,
    protected readonly dialog: MatDialog,
    private readonly reminderService: RemindersService,
    protected readonly router: Router,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly actions$: Actions,
    private readonly announcer: LiveAnnouncer
  ) {}

  @Input() set viewAllReminder(value: boolean) {
    this.isViewAllReminder = value;
  }

  ngOnInit(): void {
    this.dictionary$ = this.store.pipe(
      select(dictionarySelector),
      tap(dic => {
        if (dic && !this.dictionary) {
          this.dictionary = dic;
        }
      })
    );
    this.setFromDictionary();
    if (!this.detailUrl) {
      this.detailUrl = `${this.router.url.split('all').shift()}/${
        this.reminderItem.id
      }`;
    }
    this.localeSubscription.add(
      this.store
        .pipe(
          select(localeDefaultSelector),
          tap(loc => {
            if (loc) {
              this.defaultLocale = loc;
              this.localeSubscription.unsubscribe();
            }
          })
        )
        .subscribe()
    );
  }

  onDelete(): void {
    this.loading = true;
    this.dialog
      .open(RemindersDeleteDialogComponent, {
        autoFocus: false,
        data: {
          reminder: this.reminderItem,
        },
      })
      .afterClosed()
      .subscribe(confirm => {
        if (confirm) {
          this.reminderService.deleteReminder(this.reminderItem.id).subscribe({
            next: () => {
              this.store.dispatch(
                new ReminderDeleted({ id: this.reminderItem.id })
              );
              this.loading = false;
              this.changeDetectorRef.detectChanges();
            },
            error: error => {
              this.loading = false;
              this.changeDetectorRef.detectChanges();
              this.store.dispatch(
                new ErrorLoaded({ httpError: { ...error }, display404: true })
              );
            },
          });
        } else {
          this.loading = false;
          this.changeDetectorRef.detectChanges();
        }
      });
  }

  onChangeStatus(state, newState): void {
    // TODO use observable for this
    this.loading = true;
    this.store.dispatch(
      new ReminderChangeStatusRequested({
        reminder: this.reminderItem,
        id: this.reminderItem.id,
        status: newState,
      })
    );

    this.actions$
      .pipe(
        ofType(RemindersListActionsTypes.ReminderChangeStatus),
        take(1),
        tap(() => {
          this.loading = false;
          this.changeDetectorRef.detectChanges();
        })
      )
      .subscribe();
  }

  getDate(): string {
    let dateToShow = ` (${DateHelper.formCustomDate(
      this.roundDateAndTime(this.reminderItem.schedule.startDate),
      this.defaultLocale
    )}`;
    if (this.reminderItem?.schedule?.recurrent?.endDate) {
      dateToShow += ` - ${DateHelper.formCustomDate(
        this.roundDateAndTime(this.reminderItem?.schedule?.recurrent?.endDate),
        this.defaultLocale
      )}`;
    }
    dateToShow += ')';
    return dateToShow;
  }

  onDetails(): void {
    this.detailsClicked.emit(true);
    if (this.dialogMode) {
      this.store
        .pipe(
          select(activeRecordSelector),
          take(1),
          switchMap(record =>
            this.dialog
              .open(RemindersFormDialogA11yComponent, {
                autoFocus: false,
                panelClass:
                  'custom-dialog-container-for-reminders-form-dialog-a11y',
                data: {
                  record: record,
                  reminder: this.reminderItem,
                  disabled:
                    this.reminderItem.status === ReminderStatus.Finished,
                },
              })
              .afterClosed()
          )
        )
        .subscribe();
      return;
    }
    this.redirectToRemindersView();
  }

  redirectToRemindersView(): void {
    this.store.dispatch(
      FilingCabinetActions.filingCabinetUpdateActiveSideDrawer({
        sideDrawerId: this.reminderItem.sideDrawerId,
      })
    );
    const url = !this.isViewAllReminder
      ? `${RoutesHelper.getBasePath(this.reminderItem.sideDrawerId)}${
          this.detailUrl
        }/${this.reminderItem.id}`
      : `${RoutesHelper.getBasePath(this.reminderItem.sideDrawerId)}${
          this.detailUrl
        }/${this.reminderItem.id}?all=true`;
    this.router.navigateByUrl(url);
  }

  roundDateAndTime(date: string): string {
    const timezoneOffset: number = new Date().getTimezoneOffset();
    return moment(date)
      .set({ minute: timezoneOffset * -1 })
      .utc()
      .toISOString();
  }

  ngOnDestroy(): void {
    this.localeSubscription.unsubscribe();
  }

  getBackgroundColor(index: number): string {
    return ['#94008d', '#49e6f5', 'orange', 'grey'][index];
  }

  removeTimeFromDateString(type = 'startDate'): string {
    return type === 'startDate'
      ? this.reminderItem?.schedule.startDate.split('T')[0]
      : this.reminderItem?.schedule?.recurrent?.endDate.split('T')[0];
  }

  getReminderSentence(): string {
    const hasEndDate = this.reminderItem?.schedule?.recurrent?.endDate;
    const startDate = moment(this.reminderItem.schedule.startDate)
      .local()
      .format('YYYY-MM-DD');
    let endDate = null;
    if (hasEndDate) {
      endDate = moment(hasEndDate).local().format('YYYY-MM-DD');
    }
    const reminderItem: Reminders = {
      ...this.reminderItem,
      schedule: {
        ...this.reminderItem.schedule,
        startDate: startDate,
        recurrent: {
          ...this.reminderItem.schedule.recurrent,
          endDate: endDate,
          pattern:
            this.reminderItem.schedule.recurrent.pattern &&
            this.reminderItem.schedule.recurrent.frequency === Frequency.Weekly
              ? DateHelper.getNamesDaysInOtherLanguage(
                  this.defaultLocale,
                  this.reminderItem.schedule.recurrent.pattern?.split(',')
                ).join(', ')
              : this.reminderItem.schedule.recurrent.pattern,
        },
      },
    };

    this.setSentenceByFrequency(reminderItem, hasEndDate);
    return this.sentence;
  }

  onEmitterClicked(linkMenu: SdLinkMenuItem) {
    if (linkMenu.key === MenuItemType.view) {
      this.onDetails();
    }
    if (linkMenu.key === MenuItemType.delete) {
      this.onDelete();
    }
    if (linkMenu.key === MenuItemType.pause) {
      this.onChangeStatus(
        this.reminderItem?.status,
        this.reminderStatus.Paused
      );
    }
    if (linkMenu.key === MenuItemType.play) {
      this.onChangeStatus(
        this.reminderItem?.status,
        this.reminderStatus.Active
      );
    }
    if (linkMenu.key === MenuItemType.copy) {
      this.store
        .pipe(
          select(activeSideDrawerSelector),
          take(1),
          tap(activeSidedrawer => {
            const sideDrawerId =
              this.getSideDrawerIdFromReminderResource(
                this.reminderItem?.resource
              ) ?? activeSidedrawer?.id;
            this.store.dispatch(
              new ReminderCopyLinkRequested({
                reminder: this.reminderItem,
                sideDrawerId,
              })
            );
          })
        )
        .subscribe();
    }
  }

  private getSideDrawerIdFromReminderResource(resource: string): string {
    const resourceRegexp = /^sidedrawer\/([^/]+)(?:\/record\/([^/]+))?$/;
    const [, sidedrawerId] = resourceRegexp.exec(resource) || [
      null,
      null,
      null,
    ];
    return sidedrawerId;
  }

  private setSentenceByFrequency(reminderItem: Reminders, hasEndDate: string) {
    if (this.reminderItem?.schedule?.recurrent?.frequency === null) {
      this.sentence = ReminderHelper.setTemplateSentences(
        this.reminderShortDictionary.sfrReminderCreationNoRepeatSentence,
        null,
        /{{startDate}}|{{frequency}}|{{frequencynumber}}/gi,
        reminderItem,
        this.reminderShortDictionary
      );
    }
    if (this.reminderItem?.schedule?.recurrent?.frequency === Frequency.Daily) {
      this.sentence = ReminderHelper.setTemplateSentences(
        this.reminderShortDictionary.frequencySentenceNoEndDateDaily,
        Frequency.Daily,
        /{{startDate}}|{{frequency}}|{{frequencynumber}}/gi,
        reminderItem,
        this.reminderShortDictionary
      );
    }
    if (
      this.reminderItem?.schedule?.recurrent?.frequency === Frequency.Weekly
    ) {
      this.sentence = ReminderHelper.setTemplateSentences(
        this.reminderShortDictionary.frequencySentenceNoEndDateWeekly,
        Frequency.Weekly,
        /{{startDate}}|{{frequency}}|{{frequencynumber}}|{{weekdayarray}}/gi,
        reminderItem,
        this.reminderShortDictionary
      );
    }
    if (
      this.reminderItem?.schedule?.recurrent?.frequency === Frequency.Monthly
    ) {
      this.sentence = ReminderHelper.setTemplateSentences(
        this.reminderShortDictionary.frequencySentenceNoEndDateMonthly,
        Frequency.Monthly,
        /{{startDate}}|{{frequency}}|{{frequencynumber}}|{{weekdayarray}}|{{monthname}}|{{dayofmonth}}/gi,
        reminderItem,
        this.reminderShortDictionary
      );
    }
    if (
      this.reminderItem?.schedule?.recurrent?.frequency === Frequency.Yearly
    ) {
      this.sentence = ReminderHelper.setTemplateSentences(
        this.reminderShortDictionary.frequencySentenceNoEndDateYearly,
        Frequency.Yearly,
        /{{startDate}}|{{frequency}}|{{frequencynumber}}|{{weekdayarray}}|{{dayofyear}}|{{dayofmonth}}/gi,
        reminderItem,
        this.reminderShortDictionary
      );
    }
    if (hasEndDate) {
      this.sentence += ReminderHelper.setTemplateSentences(
        this.reminderShortDictionary.sfrReminderCreationRepeatEndSentence,
        null,
        /{{startDate}}|{{frequency}}|{{frequencynumber}}|{{endDate}}/gi,
        reminderItem,
        this.reminderShortDictionary
      );
    }
    const htmlRegexG = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g;
    this.ariaLabelSentence = this.sentence.replace(htmlRegexG, '');
  }

  private setFromDictionary(): void {
    this.store
      .pipe(
        select(dictionarySelector),
        take(1),
        tap(dictionary => {
          this.reminderShortDictionary.customMessageFromDictionary =
            dictionary.reminderslistitem_custommessagesentence;
          this.reminderShortDictionary.sfrReminderCreationNoRepeatSentence =
            dictionary.reminderslistitem_norepeatsentence;
          this.reminderShortDictionary.sfrReminderCreationRepeatEndSentence =
            dictionary.reminderslistitem_repeatendsentence;
          this.reminderShortDictionary.remindersFormEndDateMandatoryMessage =
            dictionary.reminderslistitem_enddatemandatorymessage;
          this.reminderShortDictionary.sfrStartDateMandatoryMessage =
            dictionary.reminderslistitem_startdatemandatorymessage;
          this.reminderShortDictionary.remindersFormFieldMandatoryMessage =
            dictionary.reminderslistitem_fieldmandatorymessage;
        })
      )
      .subscribe();

    this.store
      .pipe(
        select(frequencySelector),
        take(1),
        tap(frequencies => {
          if (frequencies) {
            frequencies.forEach(element => {
              switch (element?.frequency) {
                case String(Frequency.Daily).toLowerCase():
                  this.reminderShortDictionary.frequencyFromDictionaryDaily =
                    element?.frequencyLabel;
                  this.reminderShortDictionary.frequencyValueFromDictionaryDaily =
                    element?.frequencyValue;
                  this.reminderShortDictionary.frequencySentenceFromDictionaryDaily =
                    element?.frequencySentence;
                  this.reminderShortDictionary.frequencyRepeatOnFromDictionaryDaily =
                    element?.frequencyRepeatOn;
                  this.reminderShortDictionary.frequencyRepeatOfDaily =
                    element?.frequencyRepeatOf;
                  this.reminderShortDictionary.frequencySentenceNoEndDateDaily =
                    element?.frequencySentenceNoEndDate;
                  break;

                case String(Frequency.Weekly).toLowerCase():
                  this.reminderShortDictionary.frequencyFromDictionaryWeekly =
                    element?.frequencyLabel;
                  this.reminderShortDictionary.frequencyValueFromDictionaryWeekly =
                    element?.frequencyValue;
                  this.reminderShortDictionary.frequencySentenceFromDictionaryWeekly =
                    element?.frequencySentence;
                  this.reminderShortDictionary.frequencyRepeatOnFromDictionaryWeekly =
                    element?.frequencyRepeatOn;
                  this.reminderShortDictionary.frequencyRepeatOfWeekly =
                    element?.frequencyRepeatOf;
                  this.reminderShortDictionary.frequencySentenceNoEndDateWeekly =
                    element?.frequencySentenceNoEndDate;
                  break;

                case String(Frequency.Monthly).toLowerCase():
                  this.reminderShortDictionary.frequencyFromDictionaryMonthly =
                    element?.frequencyLabel;
                  this.reminderShortDictionary.frequencyValueFromDictionaryMonthly =
                    element?.frequencyValue;
                  this.reminderShortDictionary.frequencySentenceFromDictionaryMonthly =
                    element?.frequencySentence;
                  this.reminderShortDictionary.frequencyRepeatOnFromDictionaryMonthly =
                    element?.frequencyRepeatOn;
                  this.reminderShortDictionary.frequencyRepeatOfMonthly =
                    element?.frequencyRepeatOf;
                  this.reminderShortDictionary.frequencySentenceNoEndDateMonthly =
                    element?.frequencySentenceNoEndDate;
                  break;

                case String(Frequency.Yearly).toLowerCase():
                  this.reminderShortDictionary.frequencyFromDictionaryYearly =
                    element?.frequencyLabel;
                  this.reminderShortDictionary.frequencyValueFromDictionaryYearly =
                    element?.frequencyValue;
                  this.reminderShortDictionary.frequencySentenceFromDictionaryYearly =
                    element?.frequencySentence;
                  this.reminderShortDictionary.frequencyRepeatOnFromDictionaryYearly =
                    element?.frequencyRepeatOn;
                  this.reminderShortDictionary.frequencyRepeatOfYearly =
                    element?.frequencyRepeatOf;
                  this.reminderShortDictionary.frequencySentenceNoEndDateYearly =
                    element?.frequencySentenceNoEndDate;
                  break;

                default:
                  break;
              }
            });
          }
        })
      )
      .subscribe();

    this.store
      .pipe(
        select(reminderStatusSelector),
        take(1),
        tap((reminderStatus: ReminderStatusDTO[]) => {
          if (reminderStatus) {
            this.reminderStatusFromCollection = reminderStatus;

            reminderStatus.forEach(reminderState => {
              switch (reminderState.status) {
                case String(ReminderStatus.Active):
                  this.reminderStateActive = reminderState;
                  break;

                case String(ReminderStatus.Paused):
                  this.reminderStatePaused = reminderState;
                  break;

                default:
                  break;
              }
            });
          }
        })
      )
      .subscribe();
  }
  
  announce(event: KeyboardEvent): void {
    if (event.key === 'Tab') {
      this.announcer.announce(`${this.reminderItem.name} ${this.reminderItem?.status} ${this.ariaLabelSentence}`);
    }
  }
}
