import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { TimelineEvent } from '../models/timeline-event.model';
import { createReducer, on } from '@ngrx/store';
import { TimelineActions } from './timeline.actions';

export const featureName = 'timeline';

export interface TimelineState extends EntityState<TimelineEvent> {
  requestsInformation: Map<
    string,
    {
      totalCount?: number;
      nextPage?: string;
      hasMore?: boolean;
      gettingInformation: boolean;
    }
  >;
}

export const timelineAdapter: EntityAdapter<TimelineEvent> =
  createEntityAdapter<TimelineEvent>({
    selectId: timelineEvent => timelineEvent.id,
  });

export const initialTimelineState: TimelineState =
  timelineAdapter.getInitialState({
    requestsInformation: new Map(),
  });

export const timelineReducer = createReducer(
  initialTimelineState,
  on(TimelineActions.clearEvents, () => initialTimelineState),
  on(TimelineActions.eventsRequested, (state, { sideDrawerId }) => {
    const aux = new Map(state.requestsInformation);
    const auxRequest = aux.get(sideDrawerId);
    aux.set(sideDrawerId, {
      totalCount: auxRequest?.totalCount ?? null,
      nextPage: auxRequest?.nextPage ?? null,
      hasMore: auxRequest?.hasMore ?? null,
      gettingInformation: true,
    });
    return {
      ...state,
      requestsInformation: aux,
    };
  }),
  on(TimelineActions.pageLoaded, (state, { response, sideDrawerId }) => {
    const aux = new Map(state.requestsInformation);
    aux.set(sideDrawerId, {
      totalCount: response.totalCount,
      nextPage: response.nextPage,
      hasMore: response.hasMore,
      gettingInformation: false,
    });
    return timelineAdapter.upsertMany(
      response.data?.map(event => ({
        ...event,
        sidedrawerId: event.sidedrawerId ?? sideDrawerId,
      })),
      {
        ...state,
        requestsInformation: aux,
      }
    );
  })
);

export const { selectAll } = timelineAdapter.getSelectors();
