import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { SideDrawerNetworksRequest } from '../models/side-drawer-network-request.model';
import {
  SideDrawerNetworksRequestsActions,
  SideDrawerNetworksRequestsActionsTypes,
} from './side-drawer-networks-requests.actions';
import { SideDrawerNetworkRequestFilter } from '../models/side-drawer-network-request-filter.model';

export interface SideDrawerNetworksRequestsState
  extends EntityState<SideDrawerNetworksRequest> {
  gettingSideDrawerNetworks: boolean;
  filter: SideDrawerNetworkRequestFilter;
  itemsPerPage: number;
  lastRequestId: string;
}

export const sideDrawerNetworksRequestsAdapter: EntityAdapter<SideDrawerNetworksRequest> =
  createEntityAdapter<SideDrawerNetworksRequest>({
    selectId: sdNetworkRequest => sdNetworkRequest.id,
  });

export const initialSideDrawerNetworksRequestsState: SideDrawerNetworksRequestsState =
  sideDrawerNetworksRequestsAdapter.getInitialState({
    gettingSideDrawerNetworks: false,
    filter: null,
    itemsPerPage: 20,
    infiniteListOrder: [],
    lastRequestId: null,
  });

export function sideDrawerNetworksRequestsReducer(
  state: SideDrawerNetworksRequestsState = initialSideDrawerNetworksRequestsState,
  action: SideDrawerNetworksRequestsActions
): SideDrawerNetworksRequestsState {
  switch (action.type) {
    case SideDrawerNetworksRequestsActionsTypes.SideDrawerNetworksInitialPageLoaded:
      return {
        ...sideDrawerNetworksRequestsAdapter.upsertOne(
          action.payload.result,
          state
        ),
        lastRequestId: action.payload.result.id,
      };
    case SideDrawerNetworksRequestsActionsTypes.SideDrawerNetworksStartFlowLoaded:
    case SideDrawerNetworksRequestsActionsTypes.SideDrawerNetworksSearchPageLoaded:
      return sideDrawerNetworksRequestsAdapter.upsertMany(
        action.payload.results,
        state
      );
    case SideDrawerNetworksRequestsActionsTypes.GettingSideDrawerNetworksRequestsChange:
      return {
        ...state,
        gettingSideDrawerNetworks: action.payload.state,
      };
    case SideDrawerNetworksRequestsActionsTypes.SideDrawerNetworksSearchRequested:
      return {
        ...state,
        filter: action.payload.filter,
      };
    case SideDrawerNetworksRequestsActionsTypes.SideDrawerNetworksSearchClearFilter:
      return {
        ...state,
        filter: null,
      };
    default:
      return state;
  }
}

export const { selectAll, selectEntities } =
  sideDrawerNetworksRequestsAdapter.getSelectors();
