import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { activeSideDrawerSelector } from '../../sidedrawer/store/sidedrawer.selector';

@Injectable()
export class HomeService {
  constructor(private readonly store: Store<AppState>) {}

  getHomeRoute(): string {
    const activeSideDrawer = this.store.selectSignal(
      activeSideDrawerSelector
    )();
    return !!activeSideDrawer && !!activeSideDrawer?.id
      ? `/core/home/${activeSideDrawer.id}/`
      : '/core/home/';
  }
}
