<div
  *ngIf="menuIcon"
  class="sd-link-menu-menuIcon"
  [attr.aria-label]="tooltip"
  (keydown.enter)="menuOpened(menuRef); menuOpenedEvent.emit(true)"
  (focus)="onFocusSvg()">
  <app-sd-svg-a11y
    #svgComponent
    [setSdAccessibility]="{ role: 'menu' }"
    (menuOpened)="menuOpened(menuRef); menuOpenedEvent.emit(true)"
    [color]="'var(--secondaryAccentColor)'"
    [height]="1.25"
    [setSdAccessibility]="{ tabIndex: -1 }"
    [matMenuTriggerData]="menuData"
    [matMenuTriggerFor]="menuRef"
    [matMenuTriggerRestoreFocus]="true"
    [src]="menuIcon"
    [width]="1.25"
    [readOnly]="readOnly"></app-sd-svg-a11y>
</div>

<button
  *ngIf="buttonType === sdLinkMenuButtonType.text"
  class="sd-link-menu-menuText"
  [attr.aria-label]="menuText"
  (menuOpened)="menuOpened(menuRef); menuOpenedEvent.emit(true)"
  [matMenuTriggerData]="menuData"
  [matMenuTriggerFor]="menuRef"
  [matMenuTriggerRestoreFocus]="true"
  class="sd-transparent-button">
  <span>{{ menuText }}</span>
</button>

<button
  (focusout)="sdiconbutton.matTooltip.hide()"
  (keyup)="sdiconbutton.matTooltip.show()"
  [disabled]="readOnly"
  (focus)="sdiconbutton.matTooltip.show()"
  (focusout)="sdiconbutton.matTooltip.hide()"
  *ngIf="buttonType === sdLinkMenuButtonType.icon"
  (menuOpened)="menuOpened(menuRef); menuOpenedEvent.emit(true)"
  (keydown.enter)="menuOpened(menuRef); menuOpenedEvent.emit(true)"
  [matMenuTriggerData]="menuData"
  [matMenuTriggerFor]="menuRef"
  [attr.aria-label]="tooltip"
  [matMenuTriggerRestoreFocus]="true"
  class="sd-transparent-button">
  <app-sd-icon-button-a11y
    #sdiconbutton
    [viewOnly]="true"
    [buttonHeight]="buttonHeight"
    [buttonWidth]="buttonWidth"
    [disableRipple]="disableRipple"
    [iconColor]="iconColor"
    [iconHeight]="iconHeight"
    [iconWidth]="iconWidth"
    [icon]="icon"
    [ngClass]="{
      'no-border': !border,
      transparent: transparentBackground,
      rounded: !squareBackground
    }"
    [primary]="primary"
    [setSdAccessibility]="{ ariaLabel: buttonText }"
    [squareBackground]="squareBackground"
    [tooltip]="tooltip"
    [transparentBackground]="transparentBackground"
    class="sd-icon-button">
    {{ buttonText }}
  </app-sd-icon-button-a11y>
</button>

<div *ngIf="buttonType === sdLinkMenuButtonType.flat">
  <div
    [attr.role]="'menu'"
    (menuOpened)="menuOpened(menuRef); menuOpenedEvent.emit(true)"
    [matMenuTriggerData]="menuData"
    [matMenuTriggerFor]="menuRef"
    [matMenuTriggerRestoreFocus]="true"
    class="sd-transparent-button">
    <app-sd-flat-button-a11y
      [icon]="icon"
      [primary]="primary"
      [setSdAccessibility]="{
        ariaLabel: buttonText,
        tabIndex: accessibility?.tabIndex
      }"
      [tooltip]="tooltip"
      >{{ buttonText }}
    </app-sd-flat-button-a11y>
  </div>
</div>

<div *ngIf="buttonType === sdLinkMenuButtonType.custom">
  <div
    [attr.role]="'menu'"
    (menuOpened)="menuOpened(menuRef); menuOpenedEvent.emit(true)"
    (menuClosed)="menuClosedEvent.emit(true)"
    [matMenuTriggerData]="menuData"
    [matMenuTriggerFor]="menuRef"
    [matMenuTriggerRestoreFocus]="true"
    class="sd-custom-menu-button">
    <ng-container *ngTemplateOutlet="customContentForSdMenu" />
  </div>
</div>

<mat-menu
  [attr.role]="'menu'"
  #menuRef="matMenu"
  (closed)="onClosed()"
  [ngClass]="{
    matMenuPrimary: primary,
    matMenu: true
  }"
  aria-label="'sidedrawermenu_tooltip' | dictionary | async">
  <app-sd-progress-bar-a11y
    *ngIf="menuType === sdLinkMenuType.sideDrawerMenu"
    [ngStyle]="{
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      'z-index': '9999'
    }"
    [setIsLoading]="progress" />
  <div
    *ngIf="menuType === sdLinkMenuType.sideDrawerMenu"
    [ngStyle]="{
      overflow: 'auto',
      'max-height': 'calc(100vh - 100px)',
      'padding-bottom': '1.35rem'
    }">
    <h3
      (click)="$event.stopPropagation()"
      (keyup)="$event.stopPropagation()"
      *ngIf="menuTitle && menuType !== sdLinkMenuType.sideDrawerMenu"
      [attr.aria-label]="menuTitle"
      class="sd-link-menu-title"
      tabindex="-1">
      {{ menuTitle }}
    </h3>

    <div class="sd-link-menu-search-section">
      <div
        (click)="$event.stopPropagation()"
        (keyup)="onKeyUp($event)"
        class="sd-nav-var-search-input"
        mat-menu-item>
        <app-sd-svg-a11y
          [color]="sdColorPalette.secondaryAccentColor"
          [height]="1.125"
          [setSdAccessibility]="{
            tabIndex: -1,
            role: 'img',
            ariaLabel: 'sidedrawermenu_searchplaceholder' | dictionary | async
          }"
          [src]="cdn + ('globalparams_searchicon' | dictionary | async)"
          [width]="1.125"></app-sd-svg-a11y>
        <app-sd-input-a11y
          [autocomplete]="false"
          [controller]="searchController"
          [placeholder]="placeholder | dictionary | async"
          [setSdAccessibility]="{
            id: 'sd-link-menu-a11y-app-sd-input-a11y',
            tabIndex: -1
          }"
          [sdAccessibilityForSuffix]="{ role: 'img', ariaLabel: '' }"
          [template]="sdInputA11yTemplates.inputForSearch"
          [type]="'text'">
        </app-sd-input-a11y>
      </div>

      <div class="sd-menu">
        <div
          id="search-{{ i }}"
          *ngFor="
            let item of menuDataSd;
            let i = index;
            trackBy: itemSideDrawerTrackBy
          ">
          <a
            [attr.role]="'link'"
            (click)="switchSideDrawer.emit(item)"
            (keydown.enter)="switchSideDrawer.emit(item)"
            class="sd-menu-item">
            <app-sd-side-drawer-item-a11y [otherSideDrawer]="item" />
          </a>
          <div
            (click)="loadMore.emit(hasMoreModel); $event.stopPropagation()"
            (keydown.enter)="
              loadMore.emit(hasMoreModel);
              $event.stopPropagation();
              loadingMore()
            "
            *ngIf="hasMoreModel.hasMore && i === menuDataSd.length - 1"
            class="load-more-link">
            {{ 'globalparams_viewmore' | dictionary | async }}
          </div>
          <div #anchor></div>
        </div>

        <div
          *ngIf="
            (!menuDataSd || menuDataSd?.length === 0) &&
            !progress &&
            menuType === sdLinkMenuType.sideDrawerMenu
          "
          class="search-result-empty-state"
          mat-menu-item>
          <app-sd-empty-template
            [emptyStateInline]="true"
            [emptyStateString]="emptystate"></app-sd-empty-template>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="menuType === sdLinkMenuType.default"
    [attr.aria-label]="setAccessibility?.ariaLabel">
    <div *ngFor="let item of menuData">
      <a
        (click)="clickOverOptionMenu.emit($event)"
        (click)="onClick($event, item)"
        (keyup)="sdLinkMenuItem.sdTooltip?.matTooltip?.show()"
        (focusout)="sdLinkMenuItem?.sdTooltip?.matTooltip?.hide()"
        *ngIf="
          (item?.visible ||
            item?.visible === null ||
            item?.visible === undefined) &&
          item?.menuLinkType === sdLinkMenuLinkType.internal
        "
        [routerLink]="item.menuLinkUrl"
        [attr.role]="'link'"
        mat-menu-item>
        <app-sd-link-menu-item-a11y
          #sdLinkMenuItem
          [item]="item"></app-sd-link-menu-item-a11y>
      </a>
      <a
        (click)="onClick($event, item)"
        [attr.role]="'link'"
        (keyup)="sdLinkMenuItem.sdTooltip?.matTooltip?.show()"
        (focusout)="sdLinkMenuItem.sdTooltip.matTooltip.hide()"
        *ngIf="
          (item?.visible ||
            item?.visible === null ||
            item?.visible === undefined) &&
          item?.menuLinkType === sdLinkMenuLinkType.external
        "
        [attr.aria-label]="item.menuLinkText + '(opens in a new tab)'"
        [href]="item.menuLinkUrl"
        mat-menu-item
        target="_blank">
        <app-sd-link-menu-item-a11y
          #sdLinkMenuItem
          [item]="item"></app-sd-link-menu-item-a11y>
      </a>
      <a
        (click)="onClick($event, item)"
        (keydown.enter)="onClick($event, item)"
        [attr.role]="'link'"
        (focusout)="sdLinkMenuItem?.sdTooltip?.matTooltip?.hide()"
        (keyup)="sdLinkMenuItem?.sdTooltip?.matTooltip?.show()"
        *ngIf="
          (item?.visible ||
            item?.visible === null ||
            item?.visible === undefined) &&
          item?.menuLinkType === sdLinkMenuLinkType.emitter
        "
        mat-menu-item>
        <app-sd-link-menu-item-a11y
          #sdLinkMenuItem
          [item]="item"></app-sd-link-menu-item-a11y>
      </a>
    </div>
  </div>
</mat-menu>
