import { RecordListItem } from '../models/record-list-item.model';
import { Record } from '../models/record.model';
import {
  RecordsListActions,
  RecordsListActionsTypes,
} from './records-list.actions';
import { RecordType } from '../models/record-type.model';
import { RecordsSortOptions } from '../models/records-sort-options.model';
import { RecordSubType } from '../models/record-sub-type.model';
import { CloudProvider } from '../../integrations/models/cloud-provider.model';
import { RelatedRecord } from '../../related-records/models/related-record.model';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export interface RecordsListState extends EntityState<RecordListItem> {
  activeRecord: Record;
  activeRecordType: RecordType;
  activeSubTypes: RecordSubType[];
  activeRecordSubType: RecordSubType;
  sortRecordsBy: RecordsSortOptions;
  gettingRecords: boolean;
  gettingActiveRecord: boolean;
  gettingActiveSubTypes: boolean;
  newRecordRedirect: string;
  newRecordIntegrationDialog: CloudProvider;
  newRecordRelatedRecord: RelatedRecord;
  hasMore: boolean;
  nextPage: string;
  previousPage: string;
  totalCount: number;
  filter: {
    name?: string;
    recordTypeName?: string;
    recordTypeId?: string;
    recordSubtypeName?: string;
    recordSubtypeOther?: string;
    recordSubtypeId?: string;
  };
  copyMoving?: boolean;
}

export const recordsListAdapter: EntityAdapter<RecordListItem> =
  createEntityAdapter<RecordListItem>({
    selectId: record => record.id,
  });

export const initialRecordsState: RecordsListState =
  recordsListAdapter.getInitialState({
    activeRecord: null,
    activeRecordType: null,
    activeSubTypes: null,
    activeRecordSubType: null,
    sortRecordsBy: RecordsSortOptions.newestFirst,
    error: null,
    gettingRecords: false,
    gettingActiveRecord: false,
    gettingActiveSubTypes: false,
    newRecordRedirect: null,
    newRecordIntegrationDialog: null,
    newRecordRelatedRecord: null,
    hasMore: false,
    nextPage: null,
    previousPage: null,
    totalCount: null,
    filter: null,
    copyMoving: false,
  });

export function recordsListReducer(
  state: RecordsListState = initialRecordsState,
  action: RecordsListActions
): RecordsListState {
  switch (action.type) {
    case RecordsListActionsTypes.RecordsListLoaded: {
      const { data, totalCount, previousPage, nextPage, hasMore } =
        action.payload.data;
      return {
        ...state,
        ...recordsListAdapter.upsertMany(data, state),
        totalCount,
        previousPage,
        nextPage,
        hasMore,
        filter: {
          ...state.filter,
          ...action.payload.lastPayload?.filter,
        },
      };
    }
    case RecordsListActionsTypes.RecordDeleted:
      return recordsListAdapter.removeOne(action.payload.id, state);
    case RecordsListActionsTypes.RecordsInfoClean: {
      const aux = recordsListAdapter.removeAll(state);
      return {
        ...state,
        ...aux,
        activeRecord: null,
        activeRecordType: null,
        activeSubTypes: null,
        gettingRecords: false,
        gettingActiveRecord: false,
        newRecordRedirect: null,
        newRecordIntegrationDialog: null,
        newRecordRelatedRecord: null,
      };
    }
    case RecordsListActionsTypes.RecordsListFilterChange:
      return {
        ...state,
        filter: {
          ...action.payload,
        },
      };
    case RecordsListActionsTypes.SetActiveRecord:
      return {
        ...state,
        activeRecord: action.payload.data,
      };
    case RecordsListActionsTypes.ClearActiveRecord:
      return {
        ...state,
        activeRecord: null,
        filter: null,
      };
    case RecordsListActionsTypes.SetActiveRecordType:
      return {
        ...state,
        activeRecordType: action.payload.data,
      };
    case RecordsListActionsTypes.SetActiveRecordSubType:
      return {
        ...state,
        activeRecordSubType: action.payload.data,
      };
    case RecordsListActionsTypes.RecordsSpinnerChange:
      return {
        ...state,
        gettingRecords: action.payload.state,
      };
    case RecordsListActionsTypes.GettingActiveRecordChange:
      return {
        ...state,
        gettingActiveRecord: action.payload.state,
      };
    case RecordsListActionsTypes.GettingActiveRecordSubTypesChange:
      return {
        ...state,
        gettingActiveSubTypes: action.payload.state,
      };
    case RecordsListActionsTypes.SortRecordsByChange:
      return {
        ...state,
        sortRecordsBy: action.payload.sort,
      };
    case RecordsListActionsTypes.RecordsSubtypesLoaded:
      return {
        ...state,
        activeSubTypes: action.payload.data,
      };
    case RecordsListActionsTypes.ClearRecordsSubTypes:
      return {
        ...state,
        activeSubTypes: null,
      };
    case RecordsListActionsTypes.ClearActiveRecordSubType:
      return {
        ...state,
        activeRecordSubType: null,
      };
    case RecordsListActionsTypes.NewRecordRedirect:
      return {
        ...state,
        newRecordRedirect: action.payload.url,
      };
    case RecordsListActionsTypes.ClearNewRecordRedirect:
      return {
        ...state,
        newRecordRedirect: null,
      };
    case RecordsListActionsTypes.NewRecordIntegrationDialog:
      return {
        ...state,
        newRecordIntegrationDialog: action.payload.provider,
      };
    case RecordsListActionsTypes.ClearNewRecordIntegrationDialog:
      return {
        ...state,
        newRecordIntegrationDialog: null,
      };
    case RecordsListActionsTypes.NewRecordRelatedRecord:
      return {
        ...state,
        newRecordRelatedRecord: action.payload.relatedRecord,
      };
    case RecordsListActionsTypes.ClearNewRecordRelatedRecord:
      return {
        ...state,
        newRecordRelatedRecord: null,
      };
    case RecordsListActionsTypes.RecordChangeStatus:
      return recordsListAdapter.updateOne(action.payload.record, state);
    default:
      return state;
    case RecordsListActionsTypes.RemoveRecordFromStore:
      return recordsListAdapter.removeOne(action.payload.recordId, state);
    case RecordsListActionsTypes.CopyMoveRecordRequestedLoading:
      return {
        ...state,
        copyMoving: action.payload.loading,
      };
  }
}

export const { selectAll, selectEntities } = recordsListAdapter.getSelectors();
