import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { PaginatorApiResponse } from '../../core/models/paginator-api-response.dto';
import { TimelineEvent } from '../models/timeline-event.model';

export const TimelineActions = createActionGroup({
  source: 'Timeline',
  events: {
    'Page Loaded': props<{
      response: PaginatorApiResponse<TimelineEvent>;
      sideDrawerId: string;
    }>(),
    'Clear Events': emptyProps(),
    'Events Requested': props<{
      sideDrawerId: string;
      nextPage: string | null;
    }>(),
    'Events Next Page Requested': emptyProps(),
    'Refresh Information': emptyProps(),
    'FC Events Requested': emptyProps(),
  },
});
