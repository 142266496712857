import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DialogTemplateTypes } from 'src/app/shared/templates/enums/templates.enum';
import { SdDialogTemplateA11yModule } from '../../../shared/templates/dialog-template-a11y/sd-dialog-template-a11y.module';
import { DictionaryPipeModule } from '../../../dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { AsyncPipe } from '@angular/common';
import { SdFlatButtonA11yModule } from '../../../shared/sd-flat-button-a11y/sd-flat-button-a11y.module';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-upload-file-error-dialog',
  template: `
    <app-sd-dialog-template-a11y
      (closeButtonClicked)="onClose()"
      [type]="dialogTemplateTypes.Info">
      <ng-container dialog-header>
        <h2>
          {{ 'uploadfileerrordialog_title' | dictionary | async }}
        </h2>
      </ng-container>
      <ng-container dialog-body>
        <div
          tabindex="0"
          [innerHTML]="'uploadfileerrordialog_body' | dictionary | async"
          class="template-description"></div>
      </ng-container>
      <ng-container dialog-footer>
        <app-sd-flat-button-a11y (buttonClicked)="onClose()" [primary]="true">
          {{ 'uploadfileerrordialog_primarybutton' | dictionary | async }}
        </app-sd-flat-button-a11y>
      </ng-container>
    </app-sd-dialog-template-a11y>
  `,
  styleUrls: ['./upload-file-error-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SdDialogTemplateA11yModule,
    DictionaryPipeModule,
    AsyncPipe,
    SdFlatButtonA11yModule,
  ],
})
export class UploadFileErrorDialogComponent {
  dialogTemplateTypes = DialogTemplateTypes;

  constructor(
    private readonly dialogRef: MatDialogRef<UploadFileErrorDialogComponent>
  ) {}

  onClose(): void {
    this.dialogRef.close();
  }
}
