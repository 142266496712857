<div *ngIf="vm$ | async as vm" class="general-information-tab">
  <div *ngIf="vm.customFieldsSectionEnabled" class="custom-fields-section">
    <form
      (cdkDropListDropped)="onCustomFieldDrop($event)"
      *ngIf="customFieldsForm"
      [cdkDropListData]="vm.customFields"
      [formGroup]="customFieldsForm"
      cdkDropList>
      <div
        *ngFor="
          let field of vm.customFields;
          let i = index;
          trackBy: trackByCustomFields
        "
        cdkDrag
        class="custom-field-section-items">
        <app-sd-svg-a11y
          [color]="'var(--tertiaryAccentColor)'"
          [height]="1.25"
          [setSdAccessibility]="{
            role: 'img',
            tabIndex: -1,
            ariaLabel:
              'generalinformationtab_sortcustomfieldarialabel'
              | dictionary
              | async
          }"
          [src]="
            cdn + ('globalparams_draganddropitemicon' | dictionary | async)
          "
          [width]="1.25"
          class="custom-field-section-items-drag-icon" />
        <app-sd-icon-button-a11y
          (btnClicked)="onAddCustomField(field.id)"
          [iconColor]="'var(--tertiaryAccentColor)'"
          [iconHeight]="1.25"
          [iconWidth]="1.25"
          [icon]="cdn + ('globalparams_addicon' | dictionary | async)"
          [setSdAccessibility]="{
            role: 'button',
            ariaLabel:
              'generalinformationtab_addcustomfieldarialabel'
              | dictionary
              | async
          }"
          class="custom-field-section-items-button-add-custom-field" />
        <app-custom-fields-section-items
          (valueChange)="onCustomFieldValueChanges($event)"
          [field]="field"
          [isFieldTypeChange]="true"
          [key]="'title'"
          [ngClass]="{
            'password-align': field.formType === 'password',
            'date-align': field.formType === 'date',
            percentage: field.formType === 'percentage'
          }"
          class="custom-item-field-name custom-item-field-name-area" />
        <div class="custom-item-field-metadata">
          <app-custom-fields-section-items
            (valueChange)="onCustomFieldValueChanges($event)"
            [field]="field"
            [isFieldTypeChange]="false"
            [key]="'detail'"
            [ngClass]="{
              percentage: field.formType === 'percentage'
            }" />

          <div class="custom-fields-section-delete-row-actions">
            <app-sd-icon-button-a11y
              (btnClicked)="onDeleteCustomField(field.id)"
              [disabled]="vm.customFields.length === 1"
              [iconColor]="'var(--tertiaryAccentColor)'"
              [iconHeight]="1.25"
              [iconWidth]="1.25"
              [icon]="cdn + ('globalparams_deleteicon' | dictionary | async)"
              [setSdAccessibility]="{
                ariaLabel:
                  ('generalinformationtab_deletecustomfieldarialabel'
                  | dictionary
                  | async) + ' ' + (i + 1) + ' ' + 'of' + (vm.customFields.length)
              }" />
          </div>
        </div>
      </div>
    </form>
  </div>
  <app-sd-input-a11y
    *ngIf="vm.descriptionSectionEnabled"
    [controller]="storageLocationControl"
    [placeholder]="
      'generalinformationtab_storagelocationplaceholder' | dictionary | async
    "
    [tooltip]="
      'generalinformationtab_storagelocationtooltip' | dictionary | async
    "
    [type]="'text'"
    class="storage-location-input" />

  <app-sd-text-area-a11y
    *ngIf="vm.descriptionSectionEnabled"
    [maxLength]="200"
    [controller]="descriptionControl"
    [placeholder]="
      'generalinformationtab_descriptionplaceholder' | dictionary | async
    "
    class="description-input" />

  <div *ngIf="vm.specificFieldsSectionEnabled" class="specific-fields-section">
    <div
      *ngFor="
        let field of specificFields$ | async;
        index as i;
        trackBy: trackBySpecificField
      "
      class="specific-field-section-list-flex-item">
      <app-specific-fields-section-item
        (valueChange)="onSpecificFieldValueChanges($event)"
        [specificField]="field"
        [viewOnly]="false" />
    </div>
  </div>
</div>
