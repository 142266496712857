import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpecificFieldsSectionItemComponent } from './components/specific-fields-section-item/specific-fields-section-item.component';
import { SdSelectorA11Module } from '../../../shared/sd-selector-a11y/sd-selector-a11y.module';
import { SdInputLocationA11yModule } from '../../../shared/sd-input-location-a11y/sd-input.location-a11y.module';
import { SdDatePickerA11yModule } from '../../../shared/sd-date-picker-a11y/sd-date-picker-a11y.module';
import { DictionaryPipeModule } from '../../../dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { SdMoneyMaskPipe } from '../../../shared/sd-pipes/sd-money-mask.pipe';
import { SdThousandSeparatorPipe } from '../../../shared/sd-pipes/sd-thousand-separetor.pipe';
import { SdInputA11yComponent } from 'src/app/shared/sd-input-a11y/components/sd-input-a11y/sd-input-a11y.component';
import { SdTextAreaA11yComponent } from 'src/app/shared/sd-text-area-a11y/components/sd-text-area-a11y.component';

@NgModule({
  declarations: [SpecificFieldsSectionItemComponent],
  exports: [SpecificFieldsSectionItemComponent],
  imports: [
    CommonModule,
    SdSelectorA11Module,
    SdInputA11yComponent,
    SdTextAreaA11yComponent,
    SdInputLocationA11yModule,
    SdDatePickerA11yModule,
    DictionaryPipeModule,
    SdMoneyMaskPipe,
    SdThousandSeparatorPipe,
  ],
})
export class SpecificFieldsSectionItemModule {}
