import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { Locale } from 'src/app/dictionary/models/locale.model';
import { environment } from 'src/environments/environment';
import { SdInputA11yTemplates } from '../../sd-input-a11y/models/sd-input-a11y-templates.enum';
import { SdAccessibilitySetting } from '../../../core/models/sd-accessibility-model';
import { AccessibilityHelper } from '../../../core/helpers/accessibility.helper';

@Component({
  selector: 'app-sd-date-picker-a11y',
  templateUrl: './sd-date-picker-a11y.component.html',
  styleUrls: ['./sd-date-picker-a11y.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SdDatePickerA11yComponent implements OnInit {
  @Input() placeholder: string;
  @Input() controller: UntypedFormControl | any; // TODO see this...
  @Input() error: string;
  @Input() startValue: string;
  @Input() locale: Locale;
  @Input() suffixText: string;
  @Input() suffix: string;
  @Input() suffixIsClickable: boolean;
  @Input() suffixIconHasPrimaryColor: boolean;
  @Input() suffixIsImage = true;
  @Input() allowsDeleteSelectedValue = false;
  @Input() viewOnly = false;
  @Output() suffixClicked = new EventEmitter<boolean>();
  @Input() template = SdInputA11yTemplates.inputDefault;
  sdInputA11yTemplates = SdInputA11yTemplates;
  @Input() minDate = new Date(
    new Date().setFullYear(new Date().getFullYear() - 1000)
  );
  @Input() maxDate = new Date(
    new Date().setFullYear(new Date().getFullYear() + 1000)
  );
  sdAccessibility: SdAccessibilitySetting;
  sdAccessibilityForSuffix: SdAccessibilitySetting;
  sdAccessibilityForIconAllowsDeleteSelectedValue: SdAccessibilitySetting;
  @Output() dateChange = new EventEmitter<Date>();
  cdn = environment.cdn;
  primaryAccentColor = getComputedStyle(
    document.documentElement
  ).getPropertyValue('--primaryAccentColor');
  secondaryAccentColor = getComputedStyle(
    document.documentElement
  ).getPropertyValue('--secondaryAccentColor');

  constructor(private readonly dateAdapter: DateAdapter<any>) {}

  @Input() set setSdAccessibility(value: SdAccessibilitySetting) {
    this.sdAccessibility = AccessibilityHelper.setDefaultAccessibility(value);
  }

  @Input() set setSdAccessibilityForSuffix(value: SdAccessibilitySetting) {
    this.sdAccessibilityForSuffix =
      AccessibilityHelper.setDefaultAccessibility(value);
  }

  @Input() set setSdAccessibilityForIconAllowsDeleteSelectedValue(
    value: SdAccessibilitySetting
  ) {
    value.role = 'button';
    this.sdAccessibilityForIconAllowsDeleteSelectedValue =
      AccessibilityHelper.setDefaultAccessibility(value);
  }

  ngOnInit(): void {
    this.dateAdapter.setLocale(this.locale?.localeId.split('-')[0]);
  }

  onDateChange(event: Date): void {
    this.dateChange.emit(event);
  }

  onSuffix(): void {
    if (this.suffixIsClickable) {
      this.suffixClicked.emit(true);
    }
  }

  clearDate(event: Event): void {
    event.preventDefault();
    this.controller.setValue(null);
  }
}
