import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { DocusignEnvelopeDocument } from '../../models/docusign-envelope-document.model';
import { AsyncPipe, DecimalPipe, NgIf } from '@angular/common';
import { DocumentListItemStore } from './document-list-item.store';
import { AppState } from '../../../reducers';
import { Store } from '@ngrx/store';
import { signatureActiveEnvelopeDocumentsSelector } from '../../store/signature.selector';
import { DictionaryPipeModule } from '../../../dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { environment } from '../../../../environments/environment';
import { SdProgressSpinnerA11yModule } from '../../../shared/sd-progress-spinner-a11y/sd-progress-spinner-a11y/sd-progress-spinner-a11y.module';
import { SdIconButtonA11yComponent } from 'src/app/shared/sd-icon-button-a11y/components/sd-icon-button-a11y/sd-icon-button-a11y.component';

@Component({
  selector: 'app-document-list-item',
  standalone: true,
  imports: [
    NgIf,
    AsyncPipe,
    DictionaryPipeModule,
    DecimalPipe,
    SdProgressSpinnerA11yModule,
    SdIconButtonA11yComponent,
  ],
  templateUrl: './document-list-item.component.html',
  styleUrls: ['./document-list-item.component.scss'],
  providers: [DocumentListItemStore],
})
export class DocumentListItemComponent {
  document$: Observable<DocusignEnvelopeDocument>;
  gettingInformation$: Observable<boolean> =
    this.documentStore.gettingInformation$;
  documentId: string;
  cdn = environment.cdn;

  constructor(
    private readonly store: Store<AppState>,
    private readonly documentStore: DocumentListItemStore
  ) {}

  @Input() set document(documentId: string) {
    this.documentId = documentId;
    this.document$ = this.store.select(
      signatureActiveEnvelopeDocumentsSelector({ documentId })
    );
  }

  onDownload(): void {
    this.documentStore.downloadDocument(this.documentId);
  }

  onView(): void {
    this.documentStore.viewDocument(this.documentId);
  }
}
