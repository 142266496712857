import { Component } from '@angular/core';

@Component({
  selector: 'app-sd-main-content-template-a11y',
  template: `<!-- progress outside -->
    <div class="sd-main-content-template">
      <section class="sd-main-content-template-body">
        <ng-content select="[section-body]"></ng-content>
      </section>
      <section class="sd-main-content-template-actions">
        <ng-content select="[section-actions]"></ng-content>
      </section>
    </div> `,
  styleUrls: ['./sd-main-content-template-a11y.component.scss'],
})
export class SdMainContentTemplateA11yComponent {}
