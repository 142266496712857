<ng-template [ngIf]="template === sdInputA11yTemplates.inputDefault">
  <mat-form-field
    (click)="picker.open()"
    (keydown.enter)="picker.open()"
    [hideRequiredMarker]="true">
    <mat-label>{{ placeholder }}</mat-label>
    <input
      [attr.aria-readonly]="viewOnly"
      (dateChange)="onDateChange($event.value)"
      [attr.aria-label]="sdAccessibility?.ariaLabel"
      [formControl]="controller"
      [id]="sdAccessibility?.id"
      [matDatepicker]="picker"
      [max]="maxDate"
      [min]="minDate"
      matInput
      readonly />
    <div class="suffix-container" matSuffix>
      <div *ngIf="!!suffixText && suffixText.length > 0">
        <span>{{ suffixText }}</span>
      </div>

      <app-sd-svg-a11y
        (click)="clearDate($event)"
        (keydown.enter)="clearDate($event)"
        *ngIf="allowsDeleteSelectedValue"
        [color]="'var(--primaryAccentColor)'"
        [height]="0.938"
        [setSdAccessibility]="sdAccessibilityForIconAllowsDeleteSelectedValue"
        [src]="cdn + ('globalparams_reseticon' | dictionary | async)"
        [width]="0.938" />

      <!-- TODO: add dictionary -->
      <app-sd-icon-button-a11y
        (btnClicked)="picker.open()"
        *ngIf="suffixIsImage"
        [iconColor]="primaryAccentColor"
        [iconHeight]="1.25"
        [iconWidth]="1.25"
        [icon]="cdn + ('globalparams_calendaricon' | dictionary | async)"
        [setSdAccessibility]="{
          ariaLabel: 'sddatepicker_opencalendar' | dictionary | async
        }" />

      <app-sd-icon-button-a11y
        (click)="onSuffix()"
        (keydown)="onSuffix()"
        *ngIf="!!suffix && suffix.length > 0 && !suffixIsImage"
        [iconHeight]="1.25"
        [iconWidth]="1.25"
        [icon]="suffix"
        [setSdAccessibility]="{
          ariaLabel: 'sddatepicker_opencalendar' | dictionary | async
        }"
        [primary]="suffixIconHasPrimaryColor" />
    </div>
    <mat-datepicker #picker />
    <mat-error *ngIf="controller.invalid">
      <span>{{ error }}</span>
    </mat-error>
  </mat-form-field>
</ng-template>
<ng-template [ngIf]="template === sdInputA11yTemplates.inputForView">
  <div class="inputForView-container">
    <input
      [attr.aria-readonly]="viewOnly"
      [attr.aria-label]="sdAccessibility?.ariaLabel"
      [formControl]="controller"
      [id]="sdAccessibility?.id"
      [matDatepicker]="picker"
      [max]="maxDate"
      [min]="minDate"
      class="inputForView"
      matInput
      readonly />
    <div class="suffix-container" matSuffix>
      <div *ngIf="!!suffixText && suffixText.length > 0">
        <span>{{ suffixText }}</span>
      </div>

      <app-sd-svg-a11y
        *ngIf="allowsDeleteSelectedValue"
        [color]="'var(--secondaryAccentColor)'"
        [height]="0.938"
        [setSdAccessibility]="sdAccessibilityForIconAllowsDeleteSelectedValue"
        [src]="cdn + ('globalparams_reseticon' | dictionary | async)"
        [width]="0.938" />

      <app-sd-icon-button-a11y
        *ngIf="suffixIsImage"
        [iconColor]="'var(--secondaryAccentColor)'"
        [iconHeight]="1"
        [iconWidth]="1"
        [icon]="cdn + ('globalparams_calendaricon' | dictionary | async)"
        [setSdAccessibility]="sdAccessibilityForSuffix" />

      <app-sd-icon-button-a11y
        *ngIf="!!suffix && suffix.length > 0 && !suffixIsImage"
        [iconHeight]="1"
        [iconColor]="'var(--secondaryAccentColor)'"
        [iconWidth]="1"
        [icon]="suffix"
        [setSdAccessibility]="sdAccessibilityForSuffix" />
    </div>
  </div>
  <mat-datepicker #picker />
</ng-template>

<ng-template [ngIf]="template === sdInputA11yTemplates.inputForMaterialView">
  <!-- TODO dictionary for timepicker -->
  <!-- TODO Add dictionary -->
  <mat-form-field [floatLabel]="'auto'" [hideRequiredMarker]="true">
    <mat-label>{{ placeholder }}</mat-label>
    <input
      [attr.aria-readonly]="viewOnly"
      [attr.aria-label]="sdAccessibility?.ariaLabel"
      [formControl]="controller"
      [id]="sdAccessibility?.id"
      [matDatepicker]="picker"
      [max]="maxDate"
      [min]="minDate"
      [placeholder]="placeholder"
      matInput
      reasync-from-cloud-dialog-bodydonly />
    <mat-datepicker #picker />
  </mat-form-field>
</ng-template>
