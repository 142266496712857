import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { CloudProvider } from '../models/cloud-provider.model';
import { Observable } from 'rxjs';
import { DriveItem } from '../models/drive-item.model';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { authHeadersSelector } from '../../auth/store/auth.selectors';

@Injectable()
export class IntegrationsService {
  private integrationApi = environment.integrationApi;

  constructor(
    private readonly http: HttpClient,
    private readonly store: Store<AppState>
  ) {}

  getDrives(
    provider: CloudProvider,
    sidedrawerId: string
  ): Observable<DriveItem[]> {
    return this.http.get<DriveItem[]>(
      this.integrationApi +
        `integrations/cloud-storage/provider/${provider}/drive?sidedrawerId=${sidedrawerId}`,
      { headers: this.store.selectSignal(authHeadersSelector)() }
    );
  }

  getRootContent(
    provider: CloudProvider,
    sidedrawerId: string,
    driveId: string
  ): Observable<DriveItem[]> {
    return this.http.get<DriveItem[]>(
      this.integrationApi +
        `integrations/cloud-storage/provider/${provider}/drive/drive-id/${driveId}?sidedrawerId=${sidedrawerId}`,
      { headers: this.store.selectSignal(authHeadersSelector)() }
    );
  }

  getContentFolder(
    provider: CloudProvider,
    sidedrawerId: string,
    driveId: string,
    folderId: string
  ): Observable<DriveItem[]> {
    return this.http.get<DriveItem[]>(
      this.integrationApi +
        `integrations/cloud-storage/provider/${provider}/drive/drive-id/${driveId}/folder/folder-id/${folderId}?sidedrawerId=${sidedrawerId}`,
      { headers: this.store.selectSignal(authHeadersSelector)() }
    );
  }

  assignFileToRecord(
    provider: CloudProvider,
    sidedrawerId: string,
    recordId: string,
    driveId: string,
    fileId: string
  ): Observable<boolean> {
    return this.http
      .post(
        this.integrationApi +
          `integrations/cloud-storage/provider/${provider}/drive/drive-id/${driveId}/file/file-id/${fileId}`,
        {
          sidedrawerId,
          recordId,
        },
        { headers: this.store.selectSignal(authHeadersSelector)() }
      )
      .pipe(map(() => true));
  }

  assignFolderToRecord(
    provider: CloudProvider,
    sidedrawerId: string,
    recordId: string,
    driveId: string,
    folderId: string
  ): Observable<boolean> {
    return this.http
      .post(
        this.integrationApi +
          `integrations/cloud-storage/provider/${provider}/drive/drive-id/${driveId}/folder/folder-id/${folderId}`,
        {
          sidedrawerId,
          recordId,
        },
        { headers: this.store.selectSignal(authHeadersSelector)() }
      )
      .pipe(map(() => true));
  }

  removeFileToRecord(
    provider: CloudProvider,
    sidedrawerId: string,
    recordId: string,
    driveId: string,
    fileId: string
  ): Observable<boolean> {
    return this.http
      .request(
        'delete',
        this.integrationApi +
          `integrations/cloud-storage/provider/${provider}/drive/drive-id/${driveId}/file/file-id/${fileId}`,
        {
          headers: this.store.selectSignal(authHeadersSelector)(),
          body: {
            sidedrawerId,
            recordId,
          },
        }
      )
      .pipe(map(() => true));
  }

  removeFolderToRecord(
    provider: CloudProvider,
    sidedrawerId: string,
    recordId: string,
    driveId: string,
    folderId: string
  ): Observable<boolean> {
    return this.http
      .request(
        'delete',
        this.integrationApi +
          `integrations/cloud-storage/provider/${provider}/drive/drive-id/${driveId}/folder/folder-id/${folderId}`,
        {
          headers: this.store.selectSignal(authHeadersSelector)(),
          body: {
            sidedrawerId,
            recordId,
          },
        }
      )
      .pipe(map(() => true));
  }
}
