import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { AccessibilityHelper } from 'src/app/core/helpers/accessibility.helper';
import { SdAccessibilitySetting } from 'src/app/core/models/sd-accessibility-model';
import { DictionaryPipeModule } from 'src/app/dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { Option } from 'src/app/shared/sd-forms/models/option.model';
import { SdSelectedItemA11yModule } from 'src/app/shared/sd-selected-item-a11y/sd-selected-item-a11y.module';
import { environment } from 'src/environments/environment';
import { AccountTypeItemComponent } from '../../../../../shared/account-type-item/account-type-item.component';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Observable, map } from 'rxjs';
import { dictionarySelector } from 'src/app/dictionary/store/dictionary.selectors';
import { Dictionary } from 'src/app/dictionary/models/dictionary.model';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-reminders-form-user-list-a11y',
  template: `
  <div *ngIf="dictionary$ | async"></div>
    <app-account-type-item
      (actionClicked)="!disabled ? onDelete(item.key) : null"
      *ngFor="let item of contacts; let i = index"
      (keyup)="announce($event, i)"
      [actionAlt]="'globalparams_delete' | dictionary | async"
      [actionSrc]="cdn + ('globalparams_deleteicon' | dictionary | async)"
      [avatarSrc]="item?.avatar"
      [clickable]="!disabled"
      [description]="
        item?.description?.length > 0
          ? item?.value
          : ('networklistitem_collaboratorslistpending' | dictionary | async)
      "
      [name]="item?.description?.length > 0 ? item?.description : item?.value"
      [templateStyle]="!disabled && contacts.length !== 1 ? 'action' : 'none'"
      class="reminder-form-user-list" />

    <div
      *ngIf="contacts?.length === 0"
      class="reminder-list-no-collaborators-message">
      {{ emptyStateString }}
    </div>
  `,
  styleUrls: ['./reminders-form-user-list-a11y.component.scss'],
  standalone: true,
  imports: [
    SdSelectedItemA11yModule,
    DictionaryPipeModule,
    AccountTypeItemComponent,
    NgForOf,
    NgIf,
    AsyncPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RemindersFormUserListA11yComponent {
  contacts: Option[] = [];
  @Input() disabled = false;
  @Input() emptyStateString: string;
  @Output() contactsChange: EventEmitter<Option[]> = new EventEmitter();
  accessibility: SdAccessibilitySetting;
  cdn = environment.cdn;
  focused = false;

  @Input() set setContacts(value: Option[]) {
    this.contacts = value as Option[];
  }

  @Input() set sdAccessibility(value: SdAccessibilitySetting) {
    this.accessibility = AccessibilityHelper.setDefaultAccessibility(value);
  }

  dictionary$: Observable<Dictionary> = this.store.select(dictionarySelector);

  constructor(private readonly announcer: LiveAnnouncer, private readonly store: Store) {}

  onDelete(key: string): void {
    if (this.contacts.length === 1) {
      return;
    }
    this.contacts = this.contacts.filter(d => d.key !== key);
    this.contactsChange.emit(this.contacts);
  }

  announce(event: KeyboardEvent, index: number): void {
    this.dictionary$.pipe(
      map(
        dictionary => {
          if (event.key === 'Tab') {
            this.announcer.announce(`${index + 1} ${dictionary.globalparams_resultof} ${this.contacts?.length}`);
          }
        }
      )
    ).subscribe();
  }
}
