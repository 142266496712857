import { Pipe, PipeTransform } from '@angular/core';
import { MoneyInputFormatHelper } from '../../core/helpers/money-input-format.helper';

@Pipe({
  name: 'sdMoneyMask',
  standalone: true,
})
export class SdMoneyMaskPipe implements PipeTransform {
  transform(value: string): string {
    return MoneyInputFormatHelper.getMoneyMask(value);
  }
}
