import { createSelector } from '@ngrx/store';
import { remindersStateSelector } from './reminder.selector';
import { selectAll } from './reminders-list.reducer';
import { TypesOfReminders } from '../models/enums/types-of-reminders.enum';

export const reminderStateSelector = createSelector(
  remindersStateSelector,
  state => state.reminders
);

const remindersListSelector = createSelector(reminderStateSelector, selectAll);

export const getRemindersListSelector = createSelector(
  remindersListSelector,
  state => (state && state.length ? state : [])
);

export const getRemindersOrderedByStatusListSelector = (payload?: {
  typeOfReminder?: TypesOfReminders;
  recordId?: string;
  sideDrawerId?: string;
}) =>
  createSelector(remindersListSelector, reminders => {
    let remindersFiltered = reminders;
    switch (payload?.typeOfReminder) {
      case TypesOfReminders.sideDrawer:
        remindersFiltered = reminders.filter(
          reminder =>
            !reminder.resource.includes('record') &&
            reminder.resource.includes(payload.sideDrawerId)
        );
        break;
      case TypesOfReminders.record:
        remindersFiltered = reminders.filter(
          reminder =>
            reminder.resource.includes('record') &&
            reminder.resource.includes(payload.recordId)
        );
        break;
    }

    const statusOrder = ['active', 'paused', 'pending', 'finished'];
    return remindersFiltered.sort(
      (a, b) =>
        statusOrder.indexOf(a.status.toLowerCase()) -
        statusOrder.indexOf(b.status.toLowerCase())
    );
  });

export const remindersLoadingSelector = createSelector(
  reminderStateSelector,
  state => state.gettingReminders
);

export const reminderEntitiesSelector = createSelector(
  reminderStateSelector,
  state => state.entities
);

export const reminderEntityByIdSelector = (payload: { reminderId: string }) =>
  createSelector(remindersListSelector, remindersArray =>
    remindersArray.find(reminder => reminder.id === payload.reminderId)
  );

export const remindersErrorsSelector = createSelector(
  reminderStateSelector,
  state => state.errors
);
