import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
} from '@angular/core';
import { Dictionary } from '../../../../../dictionary/models/dictionary.model';
import { Action, select, Store } from '@ngrx/store';
import { AppState } from '../../../../../reducers';
import { dictionarySelector } from '../../../../../dictionary/store/dictionary.selectors';
import { ZipStatus } from '../../../../models/zip-status.model';
import {
  zipCompletedItemsSelector,
  zipErrorsSelector,
  zipProgressSelector,
  zipSideDrawerSelector,
  zipStatusSelector,
  zipTotalItemsSelector,
} from '../../../../store/zip.selectors';
import { delay, take, tap } from 'rxjs/operators';
import { activeSideDrawerSelector } from '../../../../../sidedrawer/store/sidedrawer.selector';
import {
  ZipCanceled,
  ZipConfirmDownload,
  ZipReset,
} from '../../../../store/zip.actions';
import { EMPTY, of } from 'rxjs';
import { DialogTemplateTypes } from 'src/app/shared/templates/enums/templates.enum';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SideDrawer } from '../../../../../sidedrawer/models/side-drawer.model';

@Component({
  selector: 'app-zip-dialog',
  templateUrl: './zip-dialog.component.html',
  styleUrls: ['./zip-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZipDialogComponent implements AfterViewChecked {
  dictionary: Dictionary;
  status: ZipStatus;
  zipStatus = ZipStatus;
  dictionary$ = this.store
    .pipe(
      select(dictionarySelector),
      take(1),
      tap(dictionary => {
        this.dictionary = dictionary;
      })
    )
    .subscribe();
  status$ = this.store.pipe(
    select(zipStatusSelector),
    tap(status => {
      this.status = status;
    })
  );
  progress$ = this.store.pipe(select(zipProgressSelector));
  zipSidedrawer$ = this.store.pipe(select(zipSideDrawerSelector));
  completedItems$ = this.store.pipe(select(zipCompletedItemsSelector));
  totalItems$ = this.store.pipe(select(zipTotalItemsSelector));
  errors$ = this.store.pipe(select(zipErrorsSelector));
  dialogTemplateTypes = DialogTemplateTypes;

  constructor(
    private readonly store: Store<AppState>,
    private readonly dialogRef: MatDialogRef<ZipDialogComponent>,
    private readonly changeDetector: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA)
    private readonly data?: { sideDrawer: SideDrawer }
  ) {}

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }

  onClose(): void {
    switch (this.status) {
      case ZipStatus.canceled:
      case ZipStatus.completeWithErrors:
      case ZipStatus.complete:
      case ZipStatus.fails:
      case ZipStatus.noFiles:
        this.store.dispatch(new ZipReset());
        this.dialogRef.close();
        break;
      default:
        this.dialogRef.close();
        break;
    }
  }

  onConfirm(): void {
    this.store
      .pipe(
        select(activeSideDrawerSelector),
        take(1),
        tap(activeSD => {
          this.store.dispatch(
            new ZipConfirmDownload({
              sidedrawer: this.data?.sideDrawer ?? activeSD,
            })
          );
        }),
        delay(800),
        tap(() => {
          this.goToElement('sd-content-download-element');
        })
      )
      .subscribe();
  }

  onCancel(): void {
    this.delayAndFocus(new ZipCanceled(), 800, () => {
      this.goToElement('sd-content-download-element');
    });
  }

  onReset(): void {
    this.delayAndFocus(new ZipReset(), 800, () => {
      this.goToElement('sd-content-download-element');
    });
  }

  onSuccess(): void {
    this.delayAndFocus(new ZipReset(), 100, () => {
      this.goToElement('sd-content-download-element');
      this.onClose();
    });
  }

  getDialogTitle(): string {
    switch (this.status) {
      case ZipStatus.confirmation:
        return this.dictionary?.zipdialog_downloadallfilestitle;
      case ZipStatus.downloading:
      case ZipStatus.zipping:
      case ZipStatus.information:
        return this.dictionary?.zipdialog_processingdownloadallfilestitle;
      case ZipStatus.canceled:
        return this.dictionary?.zipdialog_aborttitle;
      case ZipStatus.complete:
        return this.dictionary?.zipdialog_successtitle;
      case ZipStatus.completeWithErrors:
      case ZipStatus.fails:
      case ZipStatus.noFiles:
        return this.dictionary?.zipdialog_partialdownloadtitle;
      default:
        return this.dictionary?.zipdialog_downloadallfilestitle;
    }
  }

  private delayAndFocus(
    action: Action,
    delayTime: number,
    callback: () => void
  ): void {
    of(EMPTY)
      .pipe(
        tap(() => {
          this.store.dispatch(action);
        }),
        take(1),
        delay(delayTime),
        tap(() => {
          callback();
        })
      )
      .subscribe();
  }

  private goToElement(id: string): void {
    const element = document.getElementById(id);
    if (element) {
      element.focus();
    }
  }
}
