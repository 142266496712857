import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { SideDrawerNetwork } from '../models/side-drawer-network.model';
import {
  SideDrawerNetworkListActions,
  SideDrawerNetworkListActionsTypes,
} from './side-drawer-network-list.actions';

export interface SideDrawerNetworkListState
  extends EntityState<SideDrawerNetwork> {}

export const sideDrawerNetworkListAdapter: EntityAdapter<SideDrawerNetwork> =
  createEntityAdapter<SideDrawerNetwork>({
    selectId: sdNetwork => sdNetwork.id,
  });

export const initialSideDrawerNetworkState: SideDrawerNetworkListState =
  sideDrawerNetworkListAdapter.getInitialState();

export function sideDrawerNetworkListReducer(
  state: SideDrawerNetworkListState = initialSideDrawerNetworkState,
  action: SideDrawerNetworkListActions
): SideDrawerNetworkListState {
  switch (action.type) {
    case SideDrawerNetworkListActionsTypes.SideDrawerNetworkClearInformation:
      return initialSideDrawerNetworkState;
    case SideDrawerNetworkListActionsTypes.SideDrawerNetworkLoaded: {
      return sideDrawerNetworkListAdapter.upsertMany(
        action.payload.networks,
        state
      );
    }
    case SideDrawerNetworkListActionsTypes.SideDrawerNetworkUpdated:
      return sideDrawerNetworkListAdapter.updateOne(action.payload.data, state);
    case SideDrawerNetworkListActionsTypes.SideDrawerNetworkDeleted:
      return sideDrawerNetworkListAdapter.removeOne(action.payload.id, state);
    default:
      return state;
  }
}

export const { selectAll, selectEntities } =
  sideDrawerNetworkListAdapter.getSelectors();
