import { FileUploadStatusType } from './file-upload-status-type.model';
import { FileType } from './file-type.enum';

export class FileUpload {
  constructor(
    public id?: number,
    public uploadTitle?: string,
    public file?: File,
    public progress?: number,
    public status?: FileUploadStatusType,
    public fileType?: FileType,
    public displayType?: string,
    public envelopeId?: string,
    public correlationId?: string
  ) {}
}
