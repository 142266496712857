import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { RecordType } from '../../../../models/record-type.model';

@Component({
  selector: 'app-records-type-switcher-tile',
  templateUrl: './records-type-switcher-tile.component.html',
  styleUrls: ['./records-type-switcher-tile.component.scss'],
})
export class RecordsTypeSwitcherTileComponent implements OnChanges {
  @Input() recordType: RecordType;
  @Input() active: boolean;
  reset = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes && !!changes.active) {
      this.reset = true;
      setTimeout(() => {
        this.reset = false;
      }, 1);
    }
  }
}
