import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { SignatureService } from '../../services/signature.service';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { inject, Injectable } from '@angular/core';
import { forkJoin, Observable, of, switchMap } from 'rxjs';
import { last, take, tap } from 'rxjs/operators';
import { activeSideDrawerSelector } from '../../../sidedrawer/store/sidedrawer.selector';
import {
  signatureActiveEnvelopeDocumentsSelector,
  signatureActiveEnvelopeSelector,
} from '../../store/signature.selector';
import { FilesHelper } from '../../../files/helpers/files.helper';
import { ErrorLoaded } from '../../../core/store/core.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { saveAs as importedSaveAs } from 'file-saver';
import { FileItem } from '../../../files/models/file-item.model';
import { FileHistoryDisplayType } from '../../../files/models/file-history-display-type.model';
import { FileType } from '../../../files/models/file-type.enum';
import { DocusignEnvelopeDocument } from '../../models/docusign-envelope-document.model';
import { SignatureProvider } from '../../models/signature-provider.model';
import { PdftronViewerDialogComponent } from 'src/app/files/shared/pdftron-viewer-dialog/pdftron-viewer-dialog/pdftron-viewer-dialog.component';
import { MatDialog } from '@angular/material/dialog';

export interface DocumentListItemState {
  gettingInformation: boolean;
  progress: number;
  file: File | null;
}

@Injectable()
export class DocumentListItemStore extends ComponentStore<DocumentListItemState> {
  readonly gettingInformation$ = this.select(state => state.gettingInformation);
  readonly progress$ = this.select(state => state.progress);
  readonly file$ = this.select(state => state.file);
  private readonly dialog = inject(MatDialog);
  private readonly download$ = this.effect(
    (
      trigger$: Observable<{
        documentId: string;
        callback: (file: File, document: DocusignEnvelopeDocument) => void;
      }>
    ) => {
      return trigger$.pipe(
        tap(() => this.patchState({ gettingInformation: true })),
        switchMap(({ documentId, callback }) =>
          forkJoin([
            this.store.pipe(select(activeSideDrawerSelector), take(1)),
            this.store.pipe(select(signatureActiveEnvelopeSelector), take(1)),
            this.store.pipe(
              select(signatureActiveEnvelopeDocumentsSelector({ documentId })),
              take(1)
            ),
            of(callback).pipe(take(1)),
            this.file$.pipe(take(1)),
          ])
        ),
        switchMap(
          ([activeSideDrawer, activeEnvelope, document, callback, file]) =>
            file
              ? of(callback(file, document)).pipe(
                  tap(() => this.patchState({ gettingInformation: false }))
                )
              : this.signatureService
                  .downloadFileFromEnvelope(
                    activeSideDrawer.id,
                    SignatureProvider.docusign,
                    activeEnvelope.envelopeId,
                    document.documentId
                  )
                  .pipe(
                    tap(response => {
                      if (response?.status === 'progress') {
                        this.patchState({ progress: response.loaded });
                      }
                    }),
                    last(),
                    tapResponse(
                      response => {
                        const file = FilesHelper.blobToFile(
                          response.file,
                          document.name
                        );
                        this.patchState({
                          progress: 100,
                          file,
                          gettingInformation: false,
                        });
                        callback(file, document);
                      },
                      (httpError: HttpErrorResponse) => {
                        this.store.dispatch(
                          new ErrorLoaded({ httpError, display404: true })
                        );
                        this.patchState({
                          progress: 0,
                          gettingInformation: false,
                        });
                      }
                    )
                  )
        )
      );
    }
  );

  constructor(
    private readonly signatureService: SignatureService,
    private readonly store: Store<AppState>
  ) {
    super({
      gettingInformation: false,
      progress: 0,
      file: null,
    });
  }

  saveFileLocally(file: File): void {
    const blob = new Blob([file], { type: file.type });
    importedSaveAs(blob, file.name);
  }

  openPdftronViewer(file: File, document: DocusignEnvelopeDocument): void {
    const fileItem: FileItem = {
      displayType: FileHistoryDisplayType.sealed,
      fileType: FileType.cloud,
      active: true,
      fileName: document.name + '.pdf',
      sealed: true,
      uploadTitle: document.name,
    };
    const extension = 'pdf';
    this.dialog
      .open(PdftronViewerDialogComponent, {
        autoFocus: false,
        data: {
          fileItem,
          file,
          extension,
        },
        panelClass: 'no-padding-dialog',
        maxHeight: '95vh',
        maxWidth: '95vw',
        height: '95vh',
        width: '95vw',
        disableClose: true,
      })
      .afterClosed()
      .subscribe();
  }

  readonly viewDocument = (documentId: string) =>
    this.download$(
      of({
        documentId,
        callback: (file, document) => this.openPdftronViewer(file, document),
      })
    );

  readonly downloadDocument = (documentId: string) =>
    this.download$(
      of({
        documentId,
        callback: file => this.saveFileLocally(file),
      })
    );
}
