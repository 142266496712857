import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { SdBreadcrumbsA11yModule } from '../../../shared/sd-breadcrumbs-a11y/sd-breadcrumbs-a11y.module';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of } from 'rxjs';
import { SdBreadcrumbsA11y } from '../../../shared/sd-breadcrumbs-a11y/model/sd-breadcrumbs-a11y.model';
import {
  activeRecordSelector,
  activeRecordTypeSelector,
  collaboratorsSectionEnabledSelector,
  filesSectionEnabledSelector,
  generalInfoSectionEnabledSelector,
  gettingActiveRecordSelector,
  gettingActiveRecordSubTypesSelector,
  relatedRecordsSectionEnabledSelector,
  remindersSectionEnabledSelector,
  signaturesSectionEnabledSelector,
  userHasViewerOrLessPermissions,
} from '../../store/records-list.selectors';

import { SidedrawerRoutesEnum } from '../../../sidedrawer/routes/sidedrawer-routes.enum';
import { RecordsRoutes } from '../../routes/records.routes';
import { map, startWith, take, tap } from 'rxjs/operators';
import { SdProgressSpinnerA11yModule } from '../../../shared/sd-progress-spinner-a11y/sd-progress-spinner-a11y/sd-progress-spinner-a11y.module';
import { Record } from '../../models/record.model';
import { RecordType } from '../../models/record-type.model';
import { DictionaryPipeModule } from '../../../dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SdValidators } from '../../../shared/sd-forms/models/sd.validators';
import { RecordFormViewStore } from './record-form-view.store';
import { SdFormsModule } from '../../../shared/sd-forms/sd-forms.module';
import { RecordSubType } from '../../models/record-sub-type.model';
import { SdTabsTemplateA11yModule } from '../../../shared/templates/sd-tabs-template-a11y/sd-tabs-template-a11y.module';
import { SdTabsTemplateA11yEnum } from '../../../shared/templates/sd-tabs-template-a11y/model/sd-tabs-template-a11y.enum';
import { TabsRoutesLinks } from '../../models/tabs-routes-links.model';
import { dictionarySelector } from '../../../dictionary/store/dictionary.selectors';
import { ActivatedRoute, Router } from '@angular/router';
import { SdInputA11yTemplates } from 'src/app/shared/sd-input-a11y/models/sd-input-a11y-templates.enum';
import {
  ActiveRecordRequested,
  ClearActiveRecord,
  CopyMoveRecordDialogRequested,
  DeleteRecordDialogRequested,
  RecordCopyLinkRequested,
  RecordDeleteRequested,
  RecordRenameAndChangeTypeDialogRequested,
  RecordsListActionsTypes,
  SetActiveRecordSubType,
} from '../../store/records-list.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { PlansRoutes } from '../../../plans/routes/plans.routes';
import {
  activeSideDrawerSelector,
  basePathSelector,
  docuSignAvailableSelector,
} from '../../../sidedrawer/store/sidedrawer.selector';
import {
  RelatedRecordsRemoved,
  RelatedRecordsRequested,
} from '../../../related-records/store/related-records.actions';
import { SdInputA11yComponent } from '../../../shared/sd-input-a11y/components/sd-input-a11y/sd-input-a11y.component';
import { myTeamsListSelector } from '../../../networks/store/my-teams/my-teams-list.selectors';
import { MyTeamsRequested } from '../../../networks/store/my-teams/my-teams-list.actions';
import { SdFlatButtonA11yModule } from '../../../shared/sd-flat-button-a11y/sd-flat-button-a11y.module';
import { SdMainContentTemplateA11yModule } from '../../../shared/templates/sd-main-content-template-a11y/sd-main-content-template-a11y.module';
import { RemindersWithNoResourceRequested } from '../../../reminders/store/reminders-list.action';
import {
  FileHistoryRequested,
  TotalCountFileHistoryUpdate,
} from '../../../files/store/file-history.actions';
import { CloudFoldersFilesRequested } from '../../../files/store/cloud-folders-files.actions';
import { RecordSubtypeAutocompleteA11yModule } from '../../shared/record-subtype-autocomplete-a11y/record-subtype-autocomplete-a11y.module';
import { SdSvgA11yModule } from '../../../shared/sd-svg-a11y/sd-svg-a11y.module';
import { NetworkResourceType } from 'src/app/networks/models/network-resource-type.model';
import { SdFormattedDatePipeModule } from '../../../shared/sd-formatted-date-pipe/sd-formatted-date-pipe.module';
import { environment } from 'src/environments/environment';
import { RecordSubtypeSpecificFieldsRequested } from '../../store/specific-fields.actions';
import { RecordsTypeSwitcherModule } from '../../shared/records-type-switcher/records-type-switcher.module';
import { MenuItemType } from 'src/app/shared/sd-header-a11y/models/menu-item.type.enum';
import { SdLinkMenuItem } from 'src/app/shared/sd-link-menu-a11y/models/sd-link-menu-item.model';
import { RecordSubtypeAutocompleteA11yComponent } from '../../shared/record-subtype-autocomplete-a11y/components/record-subtype-autocomplete-a11y/record-subtype-autocomplete-a11y.component';
import { RecordsListItemModule } from '../../shared/records-list-item/records-list-item.module';
import { RecordListItemTemplate } from '../../models/record-list-item-template.model';
import { Actions } from '@ngrx/effects';
import { NetworkListActions } from '../../../networks/store/network-list.store';

@Component({
  selector: 'app-record-form-view',
  standalone: true,
  imports: [
    CommonModule,
    SdBreadcrumbsA11yModule,
    SdProgressSpinnerA11yModule,
    DictionaryPipeModule,
    SdFormsModule,
    SdTabsTemplateA11yModule,
    SdInputA11yComponent,
    SdFlatButtonA11yModule,
    SdMainContentTemplateA11yModule,
    RecordSubtypeAutocompleteA11yModule,
    SdSvgA11yModule,
    SdFormattedDatePipeModule,
    RecordsTypeSwitcherModule,
    RecordsListItemModule,
  ],
  templateUrl: './record-form-view.component.html',
  styleUrls: ['./record-form-view.component.scss'],
  providers: [RecordFormViewStore],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecordFormViewComponent implements OnInit, AfterViewChecked {
  public readonly recordFormViewStore = inject(RecordFormViewStore);
  protected readonly changeDetectorRef = inject(ChangeDetectorRef);
  protected readonly route = inject(ActivatedRoute);
  protected readonly router = inject(Router);
  protected readonly store = inject(Store<AppState>);
  protected readonly actions$ = inject(Actions);

  record: Record;
  spinner$ = new BehaviorSubject<boolean>(false);
  recordListItemTemplate = RecordListItemTemplate;
  nameControl = new FormControl<string>(null, [
    Validators.required,
    SdValidators.emptyCharacters.bind(this),
  ]);
  subTypeControl = new FormControl<string>(null);
  form = new FormGroup({
    name: this.nameControl,
    subType: this.subTypeControl,
  });
  form$: Observable<Partial<{ name: string; subType: string }>> =
    this.form.valueChanges.pipe(
      startWith(this.form.value),
      tap(({ name, subType }) => {
        this.recordFormViewStore.setName(name);
        this.recordFormViewStore.setRecordSubType(subType);
      })
    );
  tabsMode = SdTabsTemplateA11yEnum;
  SdInputA11yTemplates = SdInputA11yTemplates;
  @ViewChild(SdInputA11yComponent) nameInput: SdInputA11yComponent;
  @ViewChild(RecordSubtypeAutocompleteA11yComponent)
  subTypeInput: RecordSubtypeAutocompleteA11yComponent;
  focusMandatoryInputs$ = this.recordFormViewStore.focusMandatoryInputs$.pipe(
    tap(focus => {
      if (focus) {
        this.nameControl.markAsTouched();
        this.subTypeControl.markAsTouched();
        if (this.subTypeControl.invalid) {
          this.subTypeInput?.inputElement?.nativeElement?.focus();
          return;
        }
        if (this.nameControl.invalid) {
          this.nameInput?.inputElement?.nativeElement?.focus();
        }
      }
    })
  );
  cdn = environment.cdn;
  loading$: Observable<boolean> = combineLatest([
    this.store.select(gettingActiveRecordSelector),
    this.store.select(gettingActiveRecordSubTypesSelector),
  ]).pipe(
    map(
      ([gettingActiveRecord, gettingActiveRecordSubTypes]) =>
        gettingActiveRecord || gettingActiveRecordSubTypes
    )
  );
  activeRecordType$: Observable<RecordType> = this.store.select(
    activeRecordTypeSelector
  );
  userIsViewer$ = this.store.select(userHasViewerOrLessPermissions);
  activeRecord$: Observable<Record> = this.store.pipe(
    select(activeRecordSelector),
    tap(record => {
      this.record = record;
    })
  );
  links$: Observable<TabsRoutesLinks[]> = combineLatest([
    this.store.select(dictionarySelector),
    this.store.select(activeRecordSelector),
    this.store.select(relatedRecordsSectionEnabledSelector),
    this.store.select(collaboratorsSectionEnabledSelector),
    this.store.select(remindersSectionEnabledSelector),
    this.store.select(signaturesSectionEnabledSelector),
    this.store.select(filesSectionEnabledSelector),
    this.store.select(generalInfoSectionEnabledSelector),
    this.store.select(docuSignAvailableSelector),
  ]).pipe(
    map(
      ([
        dictionary,
        activeRecord,
        relatedRecordsEnabled,
        collaboratorsEnabled,
        remindersSectionEnabled,
        signaturesSectionEnabled,
        filesSectionEnabled,
        generalInfoSectionEnabled,
        docuSignAvailable,
      ]) => {
        const aux = activeRecord?.id
          ? this.router.url.includes(activeRecord?.id)
            ? activeRecord?.id
            : RecordsRoutes.new
          : RecordsRoutes.new;
        const url = this.router.url.split(`${aux}/`)[0] + `${aux}/`;
        let links = [];
        if (filesSectionEnabled) {
          links = [
            ...links,
            {
              label: dictionary?.recordformview_filetabname,
              url: url + RecordsRoutes.filesTab,
            },
          ];
        }
        if (generalInfoSectionEnabled) {
          links = [
            ...links,
            {
              label: dictionary?.recordformview_generalinfotabname,
              url: url + RecordsRoutes.generalTab,
            },
          ];
        }
        if (collaboratorsEnabled) {
          links = [
            ...links,
            {
              label: dictionary?.recordformview_collaboratorstabname,
              url: url + RecordsRoutes.collaboratorsTab,
            },
          ];
        }
        if (remindersSectionEnabled) {
          links = [
            ...links,
            {
              label: dictionary?.recordformview_reminderstabname,
              url: url + RecordsRoutes.remindersTab,
            },
          ];
        }
        if (docuSignAvailable && signaturesSectionEnabled) {
          links = [
            ...links,
            {
              label: dictionary?.recordformview_signaturestabname,
              url: url + RecordsRoutes.signatureTab,
            },
          ];
        }
        if (relatedRecordsEnabled) {
          links = [
            ...links,
            {
              label: dictionary?.recordformview_relatedrecordstabname,
              url: url + RecordsRoutes.relatedRecordsTab,
            },
          ];
        }
        return links;
      }
    )
  );
  vm$: Observable<{
    breadCrumbs: SdBreadcrumbsA11y[];
    loading: boolean;
    activeRecord: Record;
    activeRecordType: RecordType;
    links: TabsRoutesLinks[];
    confirmEnabled: boolean;
    requestInProgress: boolean;
  }> = combineLatest([
    this.activeRecord$,
    this.activeRecordType$,
    this.loading$,
    this.links$,
    this.recordFormViewStore.confirmEnabled$,
    this.form$,
    this.recordFormViewStore.requestInProgress$,
    this.focusMandatoryInputs$,
  ]).pipe(
    map(
      ([
        activeRecord,
        activeRecordType,
        loading,
        links,
        confirmEnabled,
        ,
        requestInProgress,
        ,
      ]) => ({
        loading,
        activeRecord,
        activeRecordType,
        //TODO Add dictionary
        breadCrumbs: this.route.snapshot?.params?.recordId
          ? [
              {
                label: 'sidedrawermainview_breadcrumb',
                url: SidedrawerRoutesEnum.root,
                urlRelative: true,
              },
              {
                label: this.store.selectSignal(activeSideDrawerSelector)().name,
              },
              {
                label: activeRecordType?.displayValue[0]?.value,
                url:
                  SidedrawerRoutesEnum.root +
                  '/' +
                  RecordsRoutes.root +
                  '/' +
                  activeRecordType?.name +
                  '/' +
                  RecordsRoutes.list,
                urlRelative: true,
              },
              {
                label: 'recordsviewview_breadcrumb',
                url:
                  SidedrawerRoutesEnum.root +
                  '/' +
                  RecordsRoutes.root +
                  '/' +
                  activeRecordType?.name +
                  '/' +
                  RecordsRoutes.view +
                  '/' +
                  activeRecord?.id,
                urlRelative: true,
              },
              {
                label: 'recordformview_breadcrumbeditrecord',
              },
            ]
          : [
              {
                label: 'sidedrawermainview_breadcrumb',
                url: SidedrawerRoutesEnum.root,
                urlRelative: true,
              },
              {
                label: this.store.selectSignal(activeSideDrawerSelector)().name,
              },
              {
                label: activeRecordType?.displayValue[0]?.value,
                url:
                  SidedrawerRoutesEnum.root +
                  '/' +
                  RecordsRoutes.root +
                  '/' +
                  activeRecordType?.name +
                  '/' +
                  RecordsRoutes.list,
                urlRelative: true,
              },
              {
                label: 'recordformview_breadcrumbcreaterecord',
              },
            ],
        links,
        confirmEnabled,
        requestInProgress,
      })
    )
  );
  isSubmitted = false;

  onSubTypeChange(subType: RecordSubType): void {
    this.store.dispatch(new SetActiveRecordSubType({ data: subType }));
    if (
      this.nameControl.untouched ||
      !this.nameControl.value ||
      this.nameControl.value?.trim()?.length === 0
    ) {
      this.nameControl.setValue(
        `${this.store.selectSignal(activeSideDrawerSelector)().name} ${
          this.subTypeControl.value
        }`
      );
      this.nameControl.markAsUntouched();
    }
  }

  ngOnInit(): void {
    this.store.dispatch(new ClearActiveRecord());
    this.store.dispatch(new TotalCountFileHistoryUpdate({ totalCount: 0 }));
    const { params } = this.route.snapshot;
    if (params?.recordId) {
      this.store.dispatch(
        new ActiveRecordRequested({
          recordId: params.recordId,
          callback: result => {
            if (result instanceof HttpErrorResponse) {
              this.onCancel();
              return;
            }
            this.store.dispatch(
              new RelatedRecordsRequested({ recordId: params.recordId })
            );
            this.store.dispatch(
              NetworkListActions.requested({
                sideDrawerId: this.store.selectSignal(
                  activeSideDrawerSelector
                )()?.id,
                options: {
                  recordId: params?.recordId,
                },
                clearRequests: true,
              })
            );
            this.store.dispatch(
              new RemindersWithNoResourceRequested({
                recordId: params?.recordId,
              })
            );
            forkJoin([
              this.store.pipe(select(activeSideDrawerSelector), take(1)),
            ])
              .pipe(
                tap(([activeSD]) => {
                  this.store.dispatch(
                    new FileHistoryRequested({
                      sideDrawerId: activeSD?.id,
                      recordId: params?.recordId,
                    })
                  );
                  this.store.dispatch(
                    new CloudFoldersFilesRequested({
                      sideDrawerId: activeSD?.id,
                      recordId: params?.recordId,
                    })
                  );
                })
              )
              .subscribe();
            this.store.dispatch(
              new RecordSubtypeSpecificFieldsRequested({
                recordSubTypeName: result.recordSubtype?.name,
              })
            );
            this.recordFormViewStore.setInformationFromRecord(result);
            this.initFields();
          },
        })
      );
      this.store
        .pipe(
          select(myTeamsListSelector),
          take(1),
          map(teams => teams?.length === 0),
          tap(requestTeams =>
            requestTeams ? this.store.dispatch(new MyTeamsRequested()) : null
          )
        )
        .subscribe();

      return;
    }
    this.store.dispatch(new RelatedRecordsRemoved());
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.markForCheck();
  }

  initFields(): void {
    forkJoin([
      this.recordFormViewStore.name$.pipe(take(1)),
      this.recordFormViewStore.recordSubType$.pipe(take(1)),
      this.store.pipe(select(activeRecordSelector), take(1)),
    ])
      .pipe(
        tap(([name, subType, activeRecord]) => {
          this.nameControl.setValue(name);
          this.subTypeControl.setValue(subType);
          this.nameControl.markAsTouched();
          this.store.dispatch(
            new SetActiveRecordSubType({
              data: activeRecord.recordSubtype,
            })
          );
        })
      )
      .subscribe();
  }

  onConfirm(): void {
    this.isSubmitted = true;
    this.form?.markAllAsTouched();
    if (this.form.invalid === true) {
      return;
    }

    const { params } = this.route.snapshot;
    if (params?.recordId) {
      this.recordFormViewStore.updateRecord(
        of(() => {
          this.onBack(params?.recordId);
        })
      );
      return;
    }
    this.recordFormViewStore.createRecord(
      of(record => {
        this.onBack(record.id);
      })
    );
  }

  onCancel(): void {
    const fromPlans = this.router.url
      .split(`/${RecordsRoutes.root}/`)[0]
      .includes(PlansRoutes.root);
    const url = fromPlans
      ? this.router.url.split(`/${RecordsRoutes.root}/`)[0]
      : this.router.url.split(`/${RecordsRoutes.form}/`)[0] +
        `/${RecordsRoutes.list}`;
    this.router.navigateByUrl(url);
  }

  onBack(recordId: string): void {
    this.store
      .pipe(
        select(basePathSelector),
        take(1),
        tap(basePath => {
          const url =
            basePath +
            `${SidedrawerRoutesEnum.root}/${RecordsRoutes.root}/${recordId}`;
          this.router.navigateByUrl(url);
        })
      )
      .subscribe();
  }

  openRenameAndChangeTypeDialog(record: Record, event?: Event): void {
    if (!record) {
      return;
    }
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.store.dispatch(
      new RecordRenameAndChangeTypeDialogRequested({
        resourceType: NetworkResourceType.record,
        record,
        setActive: true,
      })
    );
  }

  onEmitterClicked(linkMenu: SdLinkMenuItem) {
    if (linkMenu.key === MenuItemType.copy) {
      this.store.dispatch(
        new RecordCopyLinkRequested({
          record: this.record,
        })
      );
    }

    if (linkMenu.key === MenuItemType.moveRecord) {
      this.onCopyMoveRecord(false);
    }

    if (linkMenu.key === MenuItemType.rename) {
      this.openRenameAndChangeTypeDialog(this.record);
    }

    if (linkMenu.key === MenuItemType.delete) {
      this.onDelete(this.record);
    }
  }

  onDelete(record: Record): void {
    this.spinner$.next(true);
    this.store.dispatch(
      new DeleteRecordDialogRequested({
        record,
        callback: result => {
          if (result) {
            this.store.dispatch(
              new RecordDeleteRequested({
                id: record?.id,
                callback: () => {
                  const listUrl =
                    this.store.selectSignal(basePathSelector)() +
                    `${SidedrawerRoutesEnum.root}/${RecordsRoutes.root}/${record?.recordTypeName}/${RecordsRoutes.list}`;
                  this.router.navigateByUrl(listUrl);
                },
              })
            );
            return;
          }
          this.spinner$.next(false);
        },
      })
    );
  }

  onCopyMoveRecord(copyRecord: boolean): void {
    this.store.dispatch(
      new CopyMoveRecordDialogRequested({
        copyRecord,
        record: this.record,
        callback: result => {
          if (
            result?.moveCopyResponse ===
            RecordsListActionsTypes.CopyMoveRecordSuccess
          ) {
            if (result.operationType === 'move') {
              const listUrl =
                this.store.selectSignal(basePathSelector)() +
                `${SidedrawerRoutesEnum.root}/${RecordsRoutes.root}/${this.store.selectSignal(activeRecordTypeSelector)().name}/${RecordsRoutes.list}`;
              this.router.navigateByUrl(listUrl);
            }
          }
        },
      })
    );
  }
}
