import {
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  inject,
  Input,
  Output,
  Signal,
  signal,
  WritableSignal,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { NetworkType } from '../../models/network-type.model';
import {
  activeRecordSelector,
  collaboratorsSectionEnabledSelector,
  userCanOperateOnThisRecordSelector,
  userHasViewerOrLessPermissions,
} from '../../../records/store/records-list.selectors';
import { environment } from '../../../../environments/environment';
import { SdLinkButtonA11yTemplates } from '../../../shared/sd-link-button-a11y/model/sd-link-button-a11y.enum';
import { ShareResourceDialogComponent } from '../share-resource-dialog/share-resource-dialog.component';
import { NetworkResourceType } from '../../models/network-resource-type.model';
import { SdColorPalette } from 'src/app/core/models/enums/sd-color-palette-enum';
import { activeSideDrawerSelector } from 'src/app/sidedrawer/store/sidedrawer.selector';
import { NgForOf, NgIf } from '@angular/common';
import { SdProgressSpinnerA11yModule } from '../../../shared/sd-progress-spinner-a11y/sd-progress-spinner-a11y/sd-progress-spinner-a11y.module';
import { SdLinkButtonA11yModule } from '../../../shared/sd-link-button-a11y/sd-link-button-a11y.module';
import { SdTooltipButtonA11yComponent } from 'src/app/shared/sd-tooltip-button-a11y/component/sd-tooltip-button-a11y/sd-tooltip-button-a11y.component';
import { SdEmptyTemplateComponent } from 'src/app/shared/templates/sd-empty-template/components/sd-empty-template/sd-empty-template.component';
import { NetworkEntity } from '../../models/network-entity.enum';
import { MatDialog } from '@angular/material/dialog';
import { SdIconButtonA11yComponent } from 'src/app/shared/sd-icon-button-a11y/components/sd-icon-button-a11y/sd-icon-button-a11y.component';
import { currentBrandingSelector } from 'src/app/core/store/core.selectors';
import {
  gettingNetworkListSelector,
  NetworkListActions,
  networkListHasMoreSelector,
  networkListSelector,
} from '../../store/network-list.store';
import { NetworkV2 } from '../../models/network.v2.type';
import { DictionarySignalPipe } from '../../../dictionary/pipes/dictionary-signal.pipe';
import { NetworkListItemComponent } from '../network-list-item/network-list-item.component';
import { MenuItemType } from '../../../shared/sd-header-a11y/models/menu-item.type.enum';
import { SdInfiniteListComponent } from '../../../shared/sd-infinite-list/sd-infinite-list.component';

@Component({
  selector: 'app-record-network-list-a11y',
  templateUrl: './record-network-list-a11y.component.html',
  styleUrls: ['./record-network-list-a11y.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    DictionarySignalPipe,
    SdTooltipButtonA11yComponent,
    SdIconButtonA11yComponent,
    SdProgressSpinnerA11yModule,
    SdEmptyTemplateComponent,
    NgForOf,
    NetworkListItemComponent,
    SdLinkButtonA11yModule,
    SdInfiniteListComponent,
  ],
})
export class RecordNetworkListA11yComponent {
  private readonly store = inject(Store<AppState>);
  private readonly dialog = inject(MatDialog);
  protected readonly NetworkType = NetworkType;
  protected readonly cdn = environment.cdn;
  protected readonly SdLinkButtonA11yTemplates = SdLinkButtonA11yTemplates;
  protected readonly SdColorPalette = SdColorPalette;
  protected readonly NetworkEntity = NetworkEntity;

  recordId: WritableSignal<string> = signal(null);
  viewOnly = signal(false);
  showAddButton = signal(true);
  showAll = signal(false);

  collaboratorsSectionEnabled = this.store.selectSignal(
    collaboratorsSectionEnabledSelector
  );
  isViewer = this.store.selectSignal(userHasViewerOrLessPermissions);
  networks: Signal<NetworkV2[]>;
  gettingInformation = this.store.selectSignal(gettingNetworkListSelector);
  hasMore = this.store.selectSignal(networkListHasMoreSelector);
  isSimplifiedRecordViewAndEmpty = computed(() => {
    const simplifiedRecordView =
      this.store.selectSignal(currentBrandingSelector)()
        ?.simplifiedRecordView ?? false;
    const networks = this.networks() ?? [];
    return simplifiedRecordView && networks.length === 0;
  });
  userCanOperate = this.store.selectSignal(userCanOperateOnThisRecordSelector);
  menuEvents = computed(() => {
    const userCanOperate = this.store.selectSignal(
      userCanOperateOnThisRecordSelector
    )();
    if (userCanOperate) {
      return [
        MenuItemType.edit,
        MenuItemType.relation,
        MenuItemType.copy,
        MenuItemType.delete,
      ];
    }
    return [MenuItemType.copy];
  });

  @Input() set setRecordId(value: string) {
    if (this.recordId() !== value) {
      this.recordId.set(value);
      this.store.dispatch(NetworkListActions.delete({ recordId: value }));
      this.store.dispatch(
        NetworkListActions.requested({
          sideDrawerId: this.store.selectSignal(activeSideDrawerSelector)()?.id,
          options: {
            recordId: value,
          },
          clearRequests: true,
        })
      );
      this.networks = this.store.selectSignal(
        networkListSelector({
          recordId: value,
          asUsers: true,
        })
      );
    }
  }

  @Input() set setViewOnly(value: boolean) {
    this.viewOnly.set(value);
  }

  @Input() set setShowAddButton(value: boolean) {
    this.showAddButton.set(value);
  }

  @Input() set setShowAll(value: boolean) {
    this.showAll.set(value);
  }

  closeModalParent = false;
  @Input() set setCloseModalParent(value: boolean) {
    this.closeModalParent = value;
  }

  @Output() closeModalParentEmitter = new EventEmitter<boolean>();

  onAddCollaborator(): void {
    this.dialog
      .open(ShareResourceDialogComponent, {
        data: {
          resourceType: NetworkResourceType.record,
          recordId: this.store.selectSignal(activeRecordSelector)()?.id,
          resourceName: this.store.selectSignal(activeRecordSelector)()?.name,
          sideDrawerId: this.store.selectSignal(activeSideDrawerSelector)()?.id,
        },
        autoFocus: false,
        panelClass: 'custom-share-resource-dialog',
      })
      .afterClosed()
      .subscribe();
  }

  getViewAllUrl(event: MouseEvent): void {
    event.preventDefault();
    this.showAll.set(true);
  }

  onNextPage(): void {
    this.store.dispatch(NetworkListActions.nextPageRequested());
  }

  trackBy(index: number, network: NetworkV2): string {
    return network.id;
  }
}
