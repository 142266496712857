import { Action } from '@ngrx/store';
import { Team } from '../../../account/models/team.model';

export enum MyTeamsListActionsTypes {
  MyTeamsRequested = '[Network list view] - My Teams Requested',
  MyTeamsLoaded = '[Network list view] - My Teams Loaded',
  GettingMyTeams = '[Network list view] - Getting My Teams',
}

export class MyTeamsRequested implements Action {
  readonly type = MyTeamsListActionsTypes.MyTeamsRequested;
}

export class MyTeamsLoaded implements Action {
  readonly type = MyTeamsListActionsTypes.MyTeamsLoaded;

  constructor(public payload: { teams: Team[] }) {}
}

export class GettingMyTeams implements Action {
  readonly type = MyTeamsListActionsTypes.GettingMyTeams;

  constructor(public payload: { gettingMyTeams: boolean }) {}
}

export type MyTeamsListActions =
  | MyTeamsRequested
  | MyTeamsLoaded
  | GettingMyTeams;
