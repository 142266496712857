<app-sd-dialog-template-a11y
  (closeButtonClicked)="onClose()"
  [showFooter]="false">
  <ng-container dialog-header>
    <h2>
      {{ 'customfieldschangeformtypedialog_title' | dictionary | async }}
    </h2>
  </ng-container>
  <ng-container dialog-body>
    <div
      *ngIf="customFieldFormType$ | async as customFieldFormTypes"
      class="base-dialog-content sort-records-dialog-content">
            <app-sd-check-button-a11y
            (click)="onClickHandle(option.type)"
            (keydown.enter)="onClickHandle(option.type)"
            *ngFor="let option of customFieldFormTypes"
            [checked]="option.type === data.formType"
            [color]="'var(--primaryColor)'"
            [description]="option.description"
            [setSdAccessibility]="{
              ariaLabel: option.title + option.description
            }"
            [svg]="cdn + option.icon"
            [template]="sdCheckButtonA11yEnum.templateWithPrefixWithSvg"
            [value]="option.title"></app-sd-check-button-a11y>
    </div>
  </ng-container>
</app-sd-dialog-template-a11y>
