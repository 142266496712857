import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { Observable, of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../../reducers';
import { Router } from '@angular/router';
import { map, startWith } from 'rxjs/operators';
import { RecordsRoutes } from '../../../../../records/routes/records.routes';
import { SideDrawer } from 'src/app/sidedrawer/models/side-drawer.model';
import {
  activeMyOtherSideDrawerSelector,
  activeSideDrawerSelector,
  basePathSelector,
  sideDrawerSpinnerSelector,
} from 'src/app/sidedrawer/store/sidedrawer.selector';
import { SideDrawerNetwork } from 'src/app/sidedrawer-networks/models/side-drawer-network.model';

@Component({
  selector: 'app-reminder-template',
  templateUrl: './reminder-template.component.html',
  styleUrls: ['./reminder-template.component.scss'],
})
export class ReminderTemplateComponent implements OnInit, AfterViewChecked {
  displayRecordsTileSwitcher$: Observable<boolean>;
  spinner$: Observable<boolean> = this.store.pipe(
    select(sideDrawerSpinnerSelector)
  );
  activeSidedrawer$: Observable<SideDrawer> = this.store.pipe(
    select(activeSideDrawerSelector)
  );
  basePath$ = this.store.pipe(select(basePathSelector));
  activeMyOtherSideDrawer$: Observable<SideDrawerNetwork> = this.store.pipe(
    select(activeMyOtherSideDrawerSelector)
  );

  currentRoute: string;

  constructor(
    private readonly store: Store<AppState>,
    private readonly router: Router,
    private readonly changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.currentRoute = this.router.url;
    this.displayRecordsTileSwitcher$ = this.router.events.pipe(
      startWith(() => of(this.router.url.includes(RecordsRoutes.root))),
      map(() => this.router.url.includes(RecordsRoutes.root))
    );
  }

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }
}
