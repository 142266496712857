<app-sd-input-a11y
  (suffixClicked)="onClickFormTypeChange()"
  *ngIf="isFieldTypeChange"
  [controller]="getController()"
  [error]="
    ('customfieldssectionitems_customfieldtitleplaceholder') + ' ' + ('customfieldssectionitems_customfieldtitleerror' | dictionary | async)
  "
  [placeholder]="
    'customfieldssectionitems_customfieldtitleplaceholder' | dictionary | async
  "
  [setSdAccessibility]="{
    ariaLabel:
      'customfieldssectionitems_customfieldtitleplaceholder'
      | dictionary
      | async
  }"
  [showChevron]="true"
  [suffixSvgColor]="''"
  [suffixSvg]="getSvgIconPath(field) | async"
  [template]="sdInputA11yTemplates.inputWithSuffix"
  [type]="'text'"></app-sd-input-a11y>
<app-sd-date-picker-a11y
  (dateChange)="onDateChange($event)"
  *ngIf="field.formType === specificFieldType.date && !isFieldTypeChange"
  [controller]="controller"
  [locale]="locale$ | async"
  [placeholder]="
    'customfieldssectionitems_customfielddetailsplaceholder'
      | dictionary
      | async
  "
  [setSdAccessibilityForSuffix]="{
    ariaLabel: 'globalparams_selectdate' | dictionary | async,
    role: 'button'
  }"
  [template]="sdInputA11yTemplates.inputDefault"></app-sd-date-picker-a11y>

<app-sd-input-a11y
  (suffixClicked)="resetControl()"
  *ngIf="field.formType === specificFieldType.string && !isFieldTypeChange"
  [controller]="getController()"
  [placeholder]="
    'customfieldssectionitems_customfielddetailsplaceholder'
      | dictionary
      | async
  "
  [sdAccessibilityForSuffix]="{
    ariaLabel: 'globalparams_reset' | dictionary | async
  }"
  [setSdAccessibility]="{
    ariaLabel:
      'customfieldssectionitems_customfielddetailsplaceholder'
      | dictionary
      | async
  }"
  [suffixSvgColor]=""
  [suffixSvg]="cdn + ('globalparams_reseticon' | dictionary | async)"
  [template]="sdInputA11yTemplates.inputWithSuffix"
  [type]="'text'"></app-sd-input-a11y>

<app-sd-password-a11y
  (suffixClicked)="copyClipboard($event)"
  *ngIf="field.formType === specificFieldType.password && !isFieldTypeChange"
  [controller]="controller"
  [placeholder]="
    'customfieldssectionitems_customfielddetailsplaceholder'
      | dictionary
      | async
  "
  [suffixIconHasPrimaryColor]="false"
  [suffixIsClickable]="true"
  [suffix]="'file_copy'"
  [template]="sdInputA11yTemplates.inputDefault">
</app-sd-password-a11y>

<app-sd-input-a11y
  (suffixClicked)="resetControl()"
  *ngIf="field.formType === specificFieldType.percentage && !isFieldTypeChange"
  [controller]="getController()"
  [matTextSuffix]="'%'"
  [placeholder]="
    'customfieldssectionitems_customfielddetailsplaceholder'
      | dictionary
      | async
  "
  [sdAccessibilityForSuffix]="{
    ariaLabel: 'globalparams_reset' | dictionary | async
  }"
  [setSdAccessibility]="{
    ariaLabel:
      'customfieldssectionitems_customfielddetailsplaceholder'
      | dictionary
      | async
  }"
  [suffixSvgColor]=""
  [suffixSvg]="cdn + ('globalparams_reseticon' | dictionary | async)"
  [template]="sdInputA11yTemplates.inputWithSuffix"
  [type]="'text'"></app-sd-input-a11y>

<app-sd-input-a11y
  (suffixClicked)="resetControl()"
  *ngIf="field.formType === specificFieldType.money && !isFieldTypeChange"
  [controller]="getController()"
  [mask]="'globalparams_numbermaskdecimal' | dictionary | async | sdMoneyMask"
  [matTextPrefix]="'globalparams_currencysymbol' | dictionary | async"
  [placeholder]="
    'customfieldssectionitems_customfielddetailsplaceholder'
      | dictionary
      | async
  "
  [sdAccessibilityForSuffix]="{
    ariaLabel: 'globalparams_reset' | dictionary | async
  }"
  [suffixSvgColor]=""
  [suffixSvg]="cdn + ('gglobalparams_reseticon' | dictionary | async)"
  [template]="sdInputA11yTemplates.inputWithSuffix"
  [thousandSeparator]="
    'globalparams_numbermaskdecimal' | dictionary | async | sdThousandSeparator
  "
  [type]="'text'"></app-sd-input-a11y>

<app-sd-input-a11y
  (suffixClicked)="resetControl()"
  *ngIf="field.formType === specificFieldType.number && !isFieldTypeChange"
  [controller]="getController()"
  [placeholder]="
    'customfieldssectionitems_customfielddetailsplaceholder'
      | dictionary
      | async
  "
  [sdAccessibilityForSuffix]="{
    ariaLabel: 'globalparams_reset' | dictionary | async
  }"
  [suffixSvgColor]=""
  [suffixSvg]="cdn + ('globalparams_reseticon' | dictionary | async)"
  [template]="sdInputA11yTemplates.inputWithSuffixWithoutMask"
  [type]="'number'"></app-sd-input-a11y>

<app-sd-input-a11y
  (suffixClicked)="resetControl()"
  *ngIf="field.formType === specificFieldType.integer && !isFieldTypeChange"
  [controller]="getController()"
  [placeholder]="
    'customfieldssectionitems_customfielddetailsplaceholder'
      | dictionary
      | async
  "
  [sdAccessibilityForSuffix]="{
    ariaLabel: 'globalparams_reset' | dictionary | async
  }"
  [suffixSvgColor]=""
  [suffixSvg]="cdn + ('globalparams_reseticon' | dictionary | async)"
  [template]="sdInputA11yTemplates.inputWithSuffixWithoutMask"
  [type]="'number'"></app-sd-input-a11y>

<app-sd-text-area-a11y
  (suffixClicked)="resetControl()"
  *ngIf="field.formType === specificFieldType.rtf && !isFieldTypeChange"
  [controller]="controller"
  [placeholder]="
    'customfieldssectionitems_customfielddetailsplaceholder'
      | dictionary
      | async
  "
  [sdAccessibilityForSuffix]="{
    ariaLabel: 'globalparams_reset' | dictionary | async
  }"
  [suffixSvgColor]=""
  [suffixSvg]="cdn + ('globalparams_reseticon' | dictionary | async)"
  [template]="sdInputA11yTemplates.inputWithSuffix"></app-sd-text-area-a11y>

<app-sd-input-a11y
  (suffixClicked)="openUrl()"
  *ngIf="field.formType === specificFieldType.url && !isFieldTypeChange"
  [controller]="getController()"
  [placeholder]="
    'customfieldssectionitems_customfielddetailsplaceholder'
      | dictionary
      | async
  "
  [sdAccessibilityForSuffix]="{
    ariaLabel: 'globalparams_gotourl' | dictionary | async
  }"
  [suffixSvgColor]=""
  [suffixSvg]="cdn + ('globalparams_externallinkicon' | dictionary | async)"
  [template]="sdInputA11yTemplates.inputWithSuffix"
  [type]="'text'"></app-sd-input-a11y>

<app-sd-input-a11y
  (suffixClicked)="resetControl()"
  *ngIf="field.formType === specificFieldType.email && !isFieldTypeChange"
  [controller]="getController()"
  [placeholder]="
    'customfieldssectionitems_customfielddetailsplaceholder'
      | dictionary
      | async
  "
  [sdAccessibilityForSuffix]="{
    ariaLabel: 'globalparams_reset' | dictionary | async
  }"
  [suffixSvgColor]=""
  [suffixSvg]="cdn + ('globalparams_reseticon' | dictionary | async)"
  [template]="sdInputA11yTemplates.inputWithSuffix"
  [type]="'text'"></app-sd-input-a11y>

<app-sd-input-location-a11y
  *ngIf="field.formType === specificFieldType.address && !isFieldTypeChange"
  [isFullLocationForm]="true"
  [placeOfResidenceController]="getController()"
  [placeholders]="{
    description: 'customfieldssectionitems_description',
    manualentrydescription: 'customfieldssectionitems_manualentrydescription',
    googleplaceplaceholder: 'customfieldssectionitems_googleplaceplaceholder'
  }"
  [requiredFields]="requiredFields"
  [showStringAddress]="field.value?.toString()"
  [suffixFocusable]="false"
  [title]="
    'customfieldssectionitems_addressplaceholder' | dictionary | async
  "></app-sd-input-location-a11y>
