import { Action } from '@ngrx/store';
import { StartFlowStep } from '../models/start-flow-step.model';
import { StartFlowStepKeys } from '../models/start-flow-step-keys.model';
import { Account } from '../../account/models/account.model';
import { PaginatorApiResponse } from '../../core/models/paginator-api-response.dto';
import { SideDrawerNetwork } from '../../sidedrawer-networks/models/side-drawer-network.model';

export enum StartFlowActionsTypes {
  LaunchStartFlow = '[Core Template Component] Launch Start Flow',
  RestartStartFlow = '[Create Account Form Component] Restart Start Flow',
  StartFlowStepUpdated = '[Start Flow Effects] Start Flow Step Updated',
  StartFlowConfigurationSetFirstLogin = '[Start Flow Effects] Start Flow Configuration Set First Login',
  StartFlowConfigurationSetBrandsParams = '[Start Flow Effects] Start Flow Configuration Set Brand Params',
  StartFlowConfigurationSetURLParams = '[Start Flow Effects] Start Flow Configuration Set URL Params',
  StartFlowCompleted = '[Start Flow Effects] Start Flow Completed',
  StartFlowInvitationUsed = '[Start Flow Effects / Create Account Form] Start Flow Invitation Code Used',
  GetDictionaryWithBrowserLocale = '[Start Flow Effects] Get Dictionary using the browser locale',
  AccountCreated = '[Create Account Form] Account Created',
  GetSettingsAndDictionary = '[Start Flow Effects] Get Users Settings And Dictionary',
  SecurityStep = '[Start Flow Effects] Security Step',
  CheckOwnedSdStep = '[Start Flow Effects] Check Owned SideDrawers Step',
  CheckSharedSdStep = '[Start Flow Effects] Check Shared SideDrawers Step',
  CheckOwnedAndSharedSdCompleteStep = '[Start Flow Effects] Check Owned and Shared SideDrawers Complete Step',
  SignUpSkipSideDrawerCreation = '[Create SideDrawer SignUp] Skip SideDrawer Creation',
  SignUpSideDrawerCreated = '[Create SideDrawer SignUp] SideDrawer Created',
  SetActiveSdAndRedirectToLanding = '[Start Flow Effects] Set Active Sd And Redirect To Landing',
  GetSdHomeOriginSdIdFail = '[Start Flow Effects] Get Sd Home Origin Sd Id Fail',
  SetActiveSdAndRedirectToOrigin = '[Start Flow Effects] Set Active Sd And Redirect To Origin',
}

export class LaunchStartFlow implements Action {
  readonly type = StartFlowActionsTypes.LaunchStartFlow;
}

export class RestartStartFlow implements Action {
  readonly type = StartFlowActionsTypes.RestartStartFlow;
}

export class StartFlowCompleted implements Action {
  readonly type = StartFlowActionsTypes.StartFlowCompleted;
}

export class StartFlowStepUpdated implements Action {
  readonly type = StartFlowActionsTypes.StartFlowStepUpdated;

  constructor(
    public payload: {
      key: StartFlowStepKeys;
      step: StartFlowStep;
    }
  ) {}
}

export class StartFlowConfigurationSetFirstLogin implements Action {
  readonly type = StartFlowActionsTypes.StartFlowConfigurationSetFirstLogin;

  constructor(
    public payload: {
      firstLogIn: boolean;
    }
  ) {}
}

export class StartFlowConfigurationSetBrandsParams implements Action {
  readonly type = StartFlowActionsTypes.StartFlowConfigurationSetBrandsParams;

  constructor(
    public payload: {
      bypassSdCreation: boolean;
      forceSdCreation: boolean;
    }
  ) {}
}

export class StartFlowConfigurationSetURLParams implements Action {
  readonly type = StartFlowActionsTypes.StartFlowConfigurationSetURLParams;

  constructor(
    public payload: {
      customBrand: boolean;
      invitationCode: string;
      origin: string;
    }
  ) {}
}

export class GetDictionaryWithBrowserLocale implements Action {
  readonly type = StartFlowActionsTypes.GetDictionaryWithBrowserLocale;

  constructor(
    public payload: {
      redirectToCoreError: boolean;
    }
  ) {}
}

export class AccountCreated implements Action {
  readonly type = StartFlowActionsTypes.AccountCreated;

  constructor(public payload: { account: Account }) {}
}

export class GetSettingsAndDictionary implements Action {
  readonly type = StartFlowActionsTypes.GetSettingsAndDictionary;
}

export class SecurityStep implements Action {
  readonly type = StartFlowActionsTypes.SecurityStep;
}

export class StartFlowInvitationUsed implements Action {
  readonly type = StartFlowActionsTypes.StartFlowInvitationUsed;
}

export class CheckOwnedSdStep implements Action {
  readonly type = StartFlowActionsTypes.CheckOwnedSdStep;
}

export class CheckSharedSdStep implements Action {
  readonly type = StartFlowActionsTypes.CheckSharedSdStep;

  constructor(
    public payload: {
      owned?: PaginatorApiResponse<SideDrawerNetwork>;
      error?: boolean;
    }
  ) {}
}

export class CheckOwnedAndSharedSdCompleteStep implements Action {
  readonly type = StartFlowActionsTypes.CheckOwnedAndSharedSdCompleteStep;

  constructor(public payload: { error?: boolean }) {}
}

export class SignUpSkipSideDrawerCreation implements Action {
  readonly type = StartFlowActionsTypes.SignUpSkipSideDrawerCreation;
}

export class SignUpSideDrawerCreated implements Action {
  readonly type = StartFlowActionsTypes.SignUpSideDrawerCreated;

  constructor(public payload: { sidedrawerId: string }) {}
}

export class SetActiveSdAndRedirectToLanding implements Action {
  readonly type = StartFlowActionsTypes.SetActiveSdAndRedirectToLanding;
}

export class SetActiveSdAndRedirectToOrigin implements Action {
  readonly type = StartFlowActionsTypes.SetActiveSdAndRedirectToOrigin;
}

export class GetSdHomeOriginSdIdFail implements Action {
  readonly type = StartFlowActionsTypes.GetSdHomeOriginSdIdFail;
}

export type StartFlowActions =
  | LaunchStartFlow
  | RestartStartFlow
  | StartFlowCompleted
  | StartFlowConfigurationSetFirstLogin
  | StartFlowConfigurationSetBrandsParams
  | StartFlowConfigurationSetURLParams
  | StartFlowStepUpdated
  | GetDictionaryWithBrowserLocale
  | AccountCreated
  | StartFlowInvitationUsed
  | GetSettingsAndDictionary
  | SecurityStep
  | CheckOwnedSdStep
  | CheckSharedSdStep
  | CheckOwnedAndSharedSdCompleteStep
  | SignUpSkipSideDrawerCreation
  | SignUpSideDrawerCreated
  | SetActiveSdAndRedirectToLanding
  | GetSdHomeOriginSdIdFail
  | SetActiveSdAndRedirectToOrigin;
