import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { SdAccessibilitySetting } from 'src/app/core/models/sd-accessibility-model';
import { Option } from '../models/option.model';

@Component({
  selector: 'app-sd-selector-a11y',
  templateUrl: './sd-selector-a11y.component.html',
  styleUrls: ['./sd-selector-a11y.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SdSelectorA11yComponent {
  @Input() options: Option[];
  @Input() placeholder: string;
  @Input() controller: UntypedFormControl;
  @Input() error: string;
  @Input() withoutThis: boolean;
  @Input() fullOutput = false;
  @Input() roundedAvatar = true;
  @Output() selection = new EventEmitter<string | Option>();
  sdAccessibility: SdAccessibilitySetting;

  @Input() set setAccessibility(value: SdAccessibilitySetting) {
    this.sdAccessibility = value;
  }
}
