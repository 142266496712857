import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { Injectable } from '@angular/core';
import { DocusignEnvelope } from '../../models/docusign-envelope.model';
import { SignatureService } from '../../services/signature.service';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { forkJoin, from, Observable, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import {
  activeSideDrawerSelector,
  basePathSelector,
} from '../../../sidedrawer/store/sidedrawer.selector';
import { ErrorLoaded } from '../../../core/store/core.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { SignatureRoutes } from '../../routes/signature.routes';
import { SignatureActions } from '../../store/signature.actions';
import { envelopeByEnvelopeIdSelector } from '../../store/signature.selector';
import { activeRecordSelector } from '../../../records/store/records-list.selectors';
import { SignatureDetailsDialogComponent } from '../signature-details-dialog/signature-details-dialog.component';
import { Actions, ofType } from '@ngrx/effects';
import { dictionarySelector } from 'src/app/dictionary/store/dictionary.selectors';
import { RecordsRoutes } from 'src/app/records/routes/records.routes';
import { SdSnackBarDisplaySnackBar } from 'src/app/shared/sd-snack-bar/store/sd-snack-bar.actions';
import { SidedrawerRoutesEnum } from 'src/app/sidedrawer/routes/sidedrawer-routes.enum';
import { MatDialog } from '@angular/material/dialog';
import { LiveAnnouncer } from '@angular/cdk/a11y';

export interface EnvelopeListState {
  envelope: DocusignEnvelope | null;
  gettingInformation: boolean;
}

@Injectable()
export class EnvelopeListItemStore extends ComponentStore<EnvelopeListState> {
  readonly envelope$ = this.select(state => state.envelope);
  readonly gettingInformation$ = this.select(state => state.gettingInformation);

  readonly getEnvelopeInformation = this.effect(
    (envelopeId$: Observable<string>) => {
      if (this.get().gettingInformation) {
        return of(null);
      }
      return envelopeId$.pipe(
        tap(() => this.patchState({ gettingInformation: true })),
        switchMap(envelopeId =>
          forkJoin([
            this.store.pipe(select(activeSideDrawerSelector), take(1)),
            this.store.pipe(select(activeRecordSelector), take(1)),
            of(envelopeId).pipe(take(1)),
            this.store.pipe(
              select(envelopeByEnvelopeIdSelector({ envelopeId })),
              take(1)
            ),
          ])
        ),
        tap(([, , , envelope]) => this.patchState({ envelope })),
        switchMap(([sideDrawer, record, envelopeId, envelope]) => {
          return this.signatureService
            .getRecordEnvelopeDetails(sideDrawer.id, record.id, envelopeId)
            .pipe(
              tapResponse(
                (newEnvelopeInformation: DocusignEnvelope) => {
                  const completeInfo = {
                    ...envelope,
                    ...newEnvelopeInformation,
                  };
                  this.patchState({ envelope: completeInfo });
                  this.store.dispatch(
                    SignatureActions.envelopeCompleteInformationLoaded({
                      envelope: {
                        id: envelope.envelopeId,
                        changes: {
                          ...completeInfo,
                        },
                      },
                    })
                  );
                  this.store.dispatch(
                    SignatureActions.setActiveEnvelope({
                      envelope: completeInfo,
                    })
                  );
                },
                (httpError: HttpErrorResponse) =>
                  this.store.dispatch(
                    new ErrorLoaded({ httpError, display404: true })
                  )
              )
            );
        }),
        tap(() => this.patchState({ gettingInformation: false }))
      );
    }
  );

  constructor(
    private readonly signatureService: SignatureService,
    private readonly store: Store<AppState>,
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly actions$: Actions,
    private readonly announcer: LiveAnnouncer,
  ) {
    super({
      gettingInformation: false,
      envelope: null,
    });
  }

  get envelope(): DocusignEnvelope {
    return this.get().envelope;
  }

  onViewEnvelopeOnDocusign(): void {
    this.envelope$
      .pipe(
        map(envelope => envelope?.envelopeId),
        take(1),
        tap(envelopeId => {
          window.open(
            environment.docusignUrl + `documents/details/${envelopeId}`,
            '_blank'
          );
        })
      )
      .subscribe();
  }

  onViewEnvelopeDetails(): void {
    this.envelope$
      .pipe(
        take(1),
        map(envelope => {
          this.openDetailsDialog(envelope.envelopeId);
        })
      )
      .subscribe();
  }

  onViewEnvelopeDetailsOnInit(envelopeId: string): void {
    forkJoin([
      this.store.pipe(
        select(envelopeByEnvelopeIdSelector({ envelopeId })),
        take(1)
      ),
      this.actions$.pipe(
        ofType(SignatureActions.envelopeCompleteInformationLoaded),
        take(1)
      ),
    ])
      .pipe(
        map(([envelope]) => {
          if (!!envelope && envelope.envelopeId === this.envelope.envelopeId) {
            this.openDetailsDialog(envelope.envelopeId);
          }
          // if (!envelope) {
          //   //TODO when the pagination is implemented this will work
          //   this.getEnvelopeInformation(envelopeId);
          //   this.openDetailsDialog(envelopeId);
          // }
        })
      )
      .subscribe();
  }

  openDetailsDialog(envelopeId: string): void {
    this.dialog
      .open(SignatureDetailsDialogComponent, {
        data: {
          envelopeId: envelopeId,
          envelopeListItemStoreRef: this,
        },
        autoFocus: false,
        panelClass: 'video-viewer-dialog-container',
      })
      .afterClosed();
  }

  onCopyEnvelopeLink(): void {
    this.envelope$
      .pipe(
        take(1),
        switchMap(envelope =>
          forkJoin([
            this.store.pipe(select(basePathSelector), take(1)),
            of(envelope).pipe(take(1)),
            this.store.pipe(select(activeRecordSelector), take(1)), // todo add record id in envelope
          ])
        ),
        map(
          ([basePath, envelope, record]) =>
            window.location.origin +
            basePath +
            `${SidedrawerRoutesEnum.root}/${RecordsRoutes.root}/${record?.recordType?.name}/${RecordsRoutes.form}/${record.id}/${RecordsRoutes.signatureTab}?viewEnvelopeRequested=${envelope?.envelopeId}`
        ),
        switchMap(url => from(navigator.clipboard.writeText(url))),
        switchMap(() => this.store.pipe(select(dictionarySelector), take(1))),
        tap(dictionary => this.announcer.announce(dictionary.globalparams_copiedtoclipboard)),
        map(
          dictionary => 
            new SdSnackBarDisplaySnackBar({
              message: dictionary?.globalparams_copiedtoclipboard,
            })
        ),
      )
      .subscribe();
  }

  onBack(): void {
    const splitBy = this.router.url.includes(
      `${SignatureRoutes.root}/${SignatureRoutes.list}`
    )
      ? `/${SignatureRoutes.details}/`
      : `/${SignatureRoutes.root}/`;
    this.router.navigateByUrl(this.router.url.split(splitBy)[0]);
  }
}
