import { AsyncPipe, NgForOf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { DictionaryPipeModule } from 'src/app/dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { NetworkResourceType } from 'src/app/networks/models/network-resource-type.model';
import { NewNetworkRow } from 'src/app/networks/models/new-network-row.model';
import { SdFlatButtonA11yModule } from 'src/app/shared/sd-flat-button-a11y/sd-flat-button-a11y.module';
import { SdDialogTemplateA11yModule } from 'src/app/shared/templates/dialog-template-a11y/sd-dialog-template-a11y.module';
import { DialogTemplateTypes } from 'src/app/shared/templates/enums/templates.enum';
import { Record } from 'src/app/records/models/record.model';
import { RecordSubtypeAutocompleteA11yModule } from '../record-subtype-autocomplete-a11y/record-subtype-autocomplete-a11y.module';
import {
  FormControl,
  FormGroup,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { SdValidators } from 'src/app/shared/sd-forms/models/sd.validators';
import { SdInputA11yTemplates } from 'src/app/shared/sd-input-a11y/models/sd-input-a11y-templates.enum';
import { Store } from '@ngrx/store';
import { RecordSubType } from '../../models/record-sub-type.model';
import { AppState } from 'src/app/reducers';
import { environment } from '../../../../environments/environment';
import { RecordRenameAndChangeTypeStore } from './record-rename-and-change-type-dialog.store';
import {
  RecordsSubtypesRequested,
  SetActiveRecord,
  SetActiveRecordSubType,
} from '../../store/records-list.actions';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SdInputA11yComponent } from 'src/app/shared/sd-input-a11y/components/sd-input-a11y/sd-input-a11y.component';
import { AccessibilityHelper } from 'src/app/core/helpers/accessibility.helper';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { SdFormHelper } from 'src/app/core/helpers/sd-form.helper';
import { sdLabelsByCobrandIdSelector } from '../../../dictionary/store/dictionary.selectors';
import { activeSideDrawerSelector } from '../../../sidedrawer/store/sidedrawer.selector';

@Component({
  selector: 'app-rename-and-change-type-dialog',
  standalone: true,
  imports: [
    AsyncPipe,
    NgForOf,
    SdDialogTemplateA11yModule,
    DictionaryPipeModule,
    SdFlatButtonA11yModule,
    SdInputA11yComponent,
    RecordSubtypeAutocompleteA11yModule,
  ],
  providers: [RecordRenameAndChangeTypeStore],
  templateUrl: './record-rename-and-change-type-dialog.component.html',
  styleUrls: ['./record-rename-and-change-type-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecordRenameAndChangeTypeDialogComponent implements OnInit {
  DialogTemplateTypes = DialogTemplateTypes;
  nameControl = new FormControl<string>(null, [
    Validators.required,
    SdValidators.emptyCharacters.bind(this),
  ]);
  subTypeControl = new FormControl<string>(null, [Validators.required]);
  form: UntypedFormGroup;
  SdInputA11yTemplates = SdInputA11yTemplates;
  cdn = environment.cdn;
  isSubmitted = false;
  errors: ValidationErrors;
  allowCustomRecordSubType = true;

  constructor(
    public readonly dialogRef: MatDialogRef<RecordRenameAndChangeTypeDialogComponent>,
    private readonly store: Store<AppState>,
    private readonly liveannouncer: LiveAnnouncer,
    public recordRenameAndChangeTypeStore: RecordRenameAndChangeTypeStore,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      resourceType: NetworkResourceType;
      record: Record;
      setActive: boolean;
    }
  ) {}

  ngOnInit(): void {
    this.store.dispatch(
      new RecordsSubtypesRequested({
        recordTypeName: this.data.record.recordType.name,
      })
    );
    this.recordRenameAndChangeTypeStore.patchState({
      record: this.data.record,
      subTypeSelected: this.data.record.recordSubtype,
    });
    this.setFormGroup();
    this.setFormValues();
    this.recordRenameAndChangeTypeStore.patchState({
      dialogRef: this.dialogRef,
    });
  }

  onSubTypeChange(subType: RecordSubType): void {
    if (
      this.store.selectSignal(
        sdLabelsByCobrandIdSelector({
          cobrandId: this.store.selectSignal(activeSideDrawerSelector)()
            .cobrandId,
        })
      )()?.forceRecordSubtype &&
      subType.name === 'other'
    ) {
      this.subTypeControl.setErrors({ notFromSelection: true });
    }
    this.recordRenameAndChangeTypeStore.patchState({
      subTypeSelected: subType,
    });
  }

  trackBy(index: number, item: NewNetworkRow): number {
    return item.id;
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onConfirm(): void {
    this.isSubmitted = true;
    this.setErrorsForms();
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      AccessibilityHelper.announceMessage(
        this.store,
        this.errors,
        this.liveannouncer,
        ''
      );
      return;
    }
    this.form.valid && this.form.dirty ? this.updateRecord() : null;
  }

  private setErrorsForms(): void {
    this.errors = {
      nameControl: SdFormHelper.getErrorFormControl(
        this.form,
        'nameControl',
        'required',
        'globalparams_requirederror'
      ),
      subTypeControl: SdFormHelper.getErrorFormControl(
        this.form,
        'subTypeControl',
        'required',
        this.subTypeControl.hasError('notFromSelection')
          ? 'globalparams_selectionerror'
          : 'globalparams_requirederror'
      ),
    };
  }

  updateRecord(): void {
    this.recordRenameAndChangeTypeStore.updateRecord({
      callback: (record: Record) => {
        if (this.data?.setActive) {
          this.setActiveRecordAndSubType(record);
        }
      },
    });
  }

  setActiveRecordAndSubType(record: Record): void {
    this.store.dispatch(
      new SetActiveRecord({
        data: record,
      })
    );
    this.store.dispatch(
      new SetActiveRecordSubType({
        data: record?.recordSubtype,
      })
    );
  }

  private setFormGroup(): void {
    this.form = new FormGroup({
      nameControl: this.nameControl,
      subTypeControl: this.subTypeControl,
    });
    this.recordRenameAndChangeTypeStore.patchState({
      form: this.form,
    });
  }

  private setFormValues(): void {
    this.nameControl.setValue(this.data?.record?.name);
    this.subTypeControl.setValue(
      !!this.data?.record?.recordSubtypeOther &&
        this.data?.record?.recordSubtypeOther.length > 0
        ? this.data?.record?.recordSubtypeOther
        : this.data?.record?.recordSubtype.displayValue[0].value
    );
  }
}
