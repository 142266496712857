<div class="reminders-form-view box-style" *ngIf="vm$ | async as vm">
  <ng-container *ngIf="!vm.loading">
    <div *ngIf="showHeader" class="reminders-form-view-header">
      <ng-content select="[headerForm]"></ng-content>
    </div>
    <div *ngIf="!vm.loading">
      <div class="reminders-form-view-container">
        <div class="reminders-form-view-description">
          {{ 'remindersform_title' | dictionary | async }}
        </div>
        <!-- TODO Dictionary -->
        <app-sd-selector-a11y
          *ngIf="
            fromRoute === ReminderFrom.fromSideDrawer &&
            !reminderFromDialog &&
            !!filingCabinet()
          "
          [controller]="sideDrawerController"
          [error]="
            submitted
              ? ('remindersform_sidedrawerplaceholder' | dictionary | async) +
                ' ' +
                ('globalparams_requirederror' | dictionary | async)
              : ''
          "
          [options]="sideDrawerOptions$ | async"
          [placeholder]="
            'remindersform_sidedrawerplaceholder' | dictionary | async
          " />
        <!-- TODO Dictionary -->
        <app-sd-input-a11y
          *ngIf="
            fromRoute === ReminderFrom.fromSideDrawer && !!reminderFromDialog
          "
          [readonly]="true"
          [controller]="sideDrawerNameController"
          [placeholder]="
            'remindersform_sidedrawerplaceholder' | dictionary | async
          " />

        <app-sd-input-a11y
          (suffixClicked)="!disabled ? nameController.reset() : null"
          [controller]="nameController"
          [error]="
            submitted
              ? ('remindersform_remindernameplaceholder' | dictionary | async) +
                ' ' +
                ('globalparams_requirederror' | dictionary | async)
              : ''
          "
          [placeholder]="
            'remindersform_remindernameplaceholder' | dictionary | async
          "
          [readonly]="disabled"
          [sdAccessibilityForSuffix]="{
            role: 'button',
            ariaLabel: 'globalparams_reset' | dictionary | async
          }"
          [setSdAccessibility]="{
            ariaLabel:
              'remindersform_remindernameplaceholder' | dictionary | async,
            id: 'name'
          }"
          [suffixSvg]="cdn + ('globalparams_reseticon' | dictionary | async)"
          [template]="sdInputA11yTemplates.inputWithSuffix"
          [tooltip]="'remindersform_remindernametooltip' | dictionary | async"
          [type]="'text'"
          class="personalized-name-input" />

        <div class="reminders-form-view-description">
          {{ 'remindersform_contactdescription' | dictionary | async }}
        </div>

        <div
          [ngClass]="{
            'reminders-form-view-contact-container': !cancelAddCollaborator,
            'reminders-form-view-contact-container-cancel':
              cancelAddCollaborator
          }">
          <app-network-autocomplete
            [template]="
              !cancelAddCollaborator
                ? sdAutoCompleteA11yEnum.default
                : sdAutoCompleteA11yEnum.inputDisabled
            "
            [required]="contactsSelected?.length === 0"
            (optionSelected)="AddUser()"
            [controller]="emailController"
            [customTooltip]="'remindersform_emailtooltip' | dictionary | async"
            [error]="
              submitted && contactsSelected?.length === 0
                ? ('remindersform_emailplaceholder' | dictionary | async) +
                  ' ' +
                  ('globalparams_requirederror' | dictionary | async)
                : ''
            "
            [placeholder]="
              'remindersform_emailplaceholder' | dictionary | async
            "
            [options]="filteredOptions$ | async"
            [setSdAccessibility]="{
              id: 'emails'
            }" />
          <div
            *ngIf="showErrorForInvalidEmailController"
            class="error-container mobile">
            <span class="font-error">{{
              'globalparams_invalidemailerror' | dictionary | async
            }}</span>
          </div>
          <app-sd-flat-button-a11y
            *ngIf="!cancelAddCollaborator"
            (buttonClicked)="disabled ? null : AddUser()"
            [primary]="true">
            {{ 'remindersform_addbutton' | dictionary | async }}
          </app-sd-flat-button-a11y>
          <div
            *ngIf="showErrorForInvalidEmailController"
            class="error-container desktop">
            <span class="font-error">{{
              'globalparams_invalidemailerror' | dictionary | async
            }}</span>
          </div>
        </div>
        <app-reminders-form-user-list-a11y
          (contactsChange)="onContactsChange($event)"
          [disabled]="disabled"
          [emptyStateString]="'remindersform_emptystate' | dictionary | async"
          [setContacts]="contactsSelected" />

        <div class="reminders-form-view-description">
          {{ 'remindersform_custommessagedescription' | dictionary | async }}
        </div>

        <app-sd-text-area-a11y
          [controller]="messageController"
          [error]="
            submitted
              ? ('remindersform_custommessageplaceholder'
                  | dictionary
                  | async) +
                ' ' +
                ('globalparams_requirederror' | dictionary | async)
              : ''
          "
          [placeholder]="
            'remindersform_custommessageplaceholder' | dictionary | async
          "
          [readonly]="disabled"
          [setSdAccessibility]="{
            id: 'message'
          }"
          class="description-input" />

        <!-- TODO dictionary add -->
        <div
          class="reminders-form-view-two-for-reminder-question-columns-container">
          <div>
            <app-sd-slide-toggle-a11y
              (toggleChange)="onChangeStartTime($event)"
              [checked]="!!reminder?.id ? false : true"
              [disabled]="disabled"
              [setSdAccessibility]="{
                ariaLabel:
                  'remindersform_addstarttimedescription' | dictionary | async
              }"
              [template]="
                sdSlideToggleTemplateA11y.slideToggletWithoutController
              "
              color="primary">
            </app-sd-slide-toggle-a11y>
          </div>
          <div class="reminders-form-view-display-flex">
            <div class="reminders-form-view-description-set-reminder">
              {{ 'remindersform_addstarttimedescription' | dictionary | async }}
            </div>
            <app-sd-tooltip-button-a11y
              [setSdAccessibility]="{
                ariaLabel:
                  'remindersform_addstarttimedescriptiontooltip'
                  | dictionary
                  | async
              }"
              [tooltip]="
                'remindersform_addstarttimedescriptiontooltip'
                  | dictionary
                  | async
              "
              class="reminders-form-view-tooltip">
            </app-sd-tooltip-button-a11y>
          </div>
        </div>

        <div
          *ngIf="showStartTime() === true"
          @myInsertRemoveTrigger
          class="reminders-form-view-two-for-start-date-columns-container">
          <div class="reminders-form-view-description">
            {{ 'remindersform_startdatedescription' | dictionary | async }}
          </div>

          <app-sd-date-picker-a11y
            [viewOnly]="disabled"
            [controller]="startDateController"
            [error]="
              ('remindersform_startdateplaceholder' | dictionary | async) +
              ' ' +
              remindersFormStore.getErrorMessageStartDate('startDateController')
            "
            [locale]="localeDefault"
            [minDate]="minDate"
            [placeholder]="
              'remindersform_startdateplaceholder' | dictionary | async
            "
            [setSdAccessibility]="{
              ariaLabel:
                'remindersform_startdateplaceholder' | dictionary | async,
              id: 'startDate'
            }"
            [template]="
              disabled
                ? sdInputA11yTemplates.inputForMaterialView
                : sdInputA11yTemplates.inputDefault
            " />

          <app-sd-time-a11y
            [controller]="timeController"
            [disabled]="disabled"
            [error]="
              'Time' +
              ' ' +
              remindersFormStore.getErrorMessageTime('timeController')
            "
            [template]="
              disabled
                ? sdInputA11yTemplates.inputForView
                : sdInputA11yTemplates.inputDefault
            " />
        </div>

        <div
          class="reminders-form-view-two-for-reminder-question-columns-container">
          <div>
            <app-sd-slide-toggle-a11y
              (toggleChange)="onChangeRecurrenceValue($event)"
              [checked]="true"
              [disabled]="disabled"
              [setSdAccessibility]="{
                ariaLabel:
                  'remindersform_addrecurrencydescription' | dictionary | async
              }"
              [template]="
                sdSlideToggleTemplateA11y.slideToggletWithoutController
              "
              color="primary" />
          </div>
          <div class="reminders-form-view-display-flex">
            <div class="reminders-form-view-description-set-reminder">
              {{
                'remindersform_addrecurrencydescription' | dictionary | async
              }}
            </div>

            <app-sd-tooltip-button-a11y
              [setSdAccessibility]="{
                ariaLabel:
                  'remindersform_addrecurrencydescriptiontooltip'
                  | dictionary
                  | async
              }"
              [tooltip]="
                'remindersform_addrecurrencydescriptiontooltip'
                  | dictionary
                  | async
              "
              class="reminders-form-view-tooltip" />
          </div>
        </div>

        <div *ngIf="reminderController.value === true" @myInsertRemoveTrigger>
          <div
            *ngIf="!disabled"
            class="reminders-form-view-reminders-element-container">
            <span class="item-label">{{
              'remindersform_recurrencedescription' | dictionary | async
            }}</span>

            <app-sd-selector-a11y
              [controller]="recurrenceController"
              [error]="
                submitted
                  ? ('remindersform_recurrenceplaceholder'
                      | dictionary
                      | async) +
                    ' ' +
                    ('globalparams_requirederror' | dictionary | async)
                  : ''
              "
              [options]="recurrenceOptions"
              [placeholder]="
                'remindersform_recurrenceplaceholder' | dictionary | async
              "
              [setAccessibility]="{
                id: 'recurrence'
              }" />
          </div>
        </div>
        <div *ngIf="reminderController.value === true" @myInsertRemoveTrigger>
          <div>
            <app-reminders-form-recurrence-a11y
              (monthSelectionOption)="onMonthSelectionOption($event)"
              *ngIf="!disabled"
              [dateInput]="startDateController.value"
              [everyController]="everyController"
              [frequencyDescription]="
                'remindersform_frequencydescription' | dictionary | async
              "
              [frequencyMandatoryMessage]="
                'remindersform_frequencymandatorymessage' | dictionary | async
              "
              [frequencylabel]="
                'remindersform_frequencyplaceholder' | dictionary | async
              "
              [isSubmitted]="submitted"
              [locale]="localeDefault"
              [monthsController]="monthsController"
              [patternController]="patternController"
              [recurrenceDayLabel]="
                'remindersform_recurrencedayplaceholder' | dictionary | async
              "
              [recurrenceMonthLabel]="
                'remindersform_recurrencemonthplaceholder' | dictionary | async
              "
              [recurrenceType]="currentRecurrenceType" />
          </div>

          <div
            *ngIf="!disabled"
            class="reminders-form-view-two-for-end-date-columns-container">
            <span class="reminders-form-recurrence-item-label-start">{{
              'remindersform_enddatedescription' | dictionary | async
            }}</span>
            <!-- TODO check this change: remindersFormStore.getErrorMessageEndDate('endDateController')-->
            <app-sd-date-picker-a11y
              [viewOnly]="disabled"
              [allowsDeleteSelectedValue]="true"
              [controller]="endDateController"
              [error]="
                submitted
                  ? ('remindersform_enddateplaceholder' | dictionary | async) +
                    ' ' +
                    remindersFormStore.getErrorMessageEndDate(
                      'endDateController'
                    )
                  : ''
              "
              [locale]="localeDefault"
              [minDate]="minDateForEndDate"
              [placeholder]="
                'remindersform_enddateplaceholder' | dictionary | async
              "
              [setSdAccessibilityForIconAllowsDeleteSelectedValue]="{
                role: 'button',
                tabIndex: 0,
                ariaLabel: 'remindersform_removeenddate' | dictionary | async
              }"
              [setSdAccessibility]="{
                id: 'endDate',
                ariaLabel:
                  'remindersform_enddateplaceholder' | dictionary | async
              }" />
          </div>

          <div class="reminders-form-view-description-reminder">
            <div
              [innerHTML]="getReminderSentence()"
              class="reminder-list-item-info-sentence"></div>
          </div>
        </div>

        <div *ngIf="isDialog" class="spacer border"></div>

        <div class="reminders-form-view-footer">
          <app-sd-flat-button-a11y
            (buttonClicked)="saveEmitter.emit(true)"
            *ngIf="isDialog"
            [primary]="false">
            {{ 'globalparams_close' | dictionary | async }}
          </app-sd-flat-button-a11y>
          <app-sd-flat-button-a11y
            (buttonClicked)="onSave()"
            [disabled]="disabled"
            [inactiveButtonClass]="true"
            [loading]="remindersFormStore.gettingInformation$ | async"
            [primary]="!invalidForm()"
            ngClass="isDialog : 'isDialog'">
            {{ 'remindersform_primarybutton' | dictionary | async }}
          </app-sd-flat-button-a11y>
        </div>
      </div>
    </div>
  </ng-container>

  <div *ngIf="vm.loading" class="reminders-form-view-spinner">
    <app-sd-progress-spinner-a11y />
  </div>
</div>
