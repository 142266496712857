<app-sd-dialog-template-a11y (closeButtonClicked)="onClose()">
  <ng-container dialog-header>
    <h2>
      {{ 'shareresourcedialog_title' | dictionary | async }}
      {{ data.resourceName }}
    </h2>
  </ng-container>
  <ng-container dialog-body>
    <div class="share-resource-dialog-body">
      <p>
        {{ 'shareresourcedialog_description' | dictionary | async }}
      </p>
      <app-network-form-row
        [isSubmitted]="isSubmitted"
        (createRow)="onCreateRow(row.id)"
        (deleteRow)="onDeleteRow(row.id)"
        (updateRow)="onUpdateRow(row.id, $event)"
        *ngFor="let row of rows$ | async; index as i; trackBy: trackBy"
        [id]="row.id"
        [setNetwork]="row"
        [recordId]="data?.recordId"
        [sideDrawerId]="data?.sideDrawerId"
        [placeHolders]="{
          contributorType:
            ('shareresourcedialog_contributortypeplaceholder'
            | dictionary
            | async),
          email: ('shareresourcedialog_emailplaceholder' | dictionary | async),
          permission:
            ('shareresourcedialog_permissionplaceholder' | dictionary | async),
          team: ('shareresourcedialog_teamplaceholder' | dictionary | async),
          fullName:
            ('shareresourcedialog_fullnameplaceholder' | dictionary | async),
          relationship:
            ('shareresourcedialog_relationshipplaceholder' | dictionary | async)
        }" />
    </div>
    <div
      *ngIf="data.resourceType === networkResourceType.record && data.recordId"
      class="app-collaborators-list">
      <app-record-network-list-a11y
        [setCloseModalParent]="true"
        (closeModalParentEmitter)="onClose()"
        [setRecordId]="data.recordId"
        [setShowAddButton]="false"
        [setShowAll]="true"
        [setViewOnly]="true" />
    </div>
    <div
      *ngIf="data.resourceType === networkResourceType.sideDrawer"
      class="app-collaborators-list">
      <app-sidedrawer-network-list-a11y
        [setCloseModalParent]="true"
        (closeModalParentEmitter)="onClose()"
        [setShowAddButton]="false"
        [setViewOnly]="true"
        [sidedrawerId]="data.sideDrawerId" />
    </div>
  </ng-container>

  <ng-container dialog-footer>
    <app-sd-flat-button-a11y (buttonClicked)="onClose()" [primary]="false">
      {{ 'globalparams_cancel' | dictionary | async }}</app-sd-flat-button-a11y
    >
    <app-sd-flat-button-a11y
      (buttonClicked)="onConfirm()"
      [inactiveButtonClass]="true"
      [primary]="dialogStore.status$ | async">
      {{ 'globalparams_confirm' | dictionary | async }}</app-sd-flat-button-a11y
    >
  </ng-container>
</app-sd-dialog-template-a11y>
