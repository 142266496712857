import { Component, Inject } from '@angular/core';
import { DialogTemplateTypes } from 'src/app/shared/templates/enums/templates.enum';
import { CommonModule } from '@angular/common';
import { SdDialogTemplateA11yModule } from '../../../../../shared/templates/dialog-template-a11y/sd-dialog-template-a11y.module';
import { RemindersFormA11yComponent } from '../../../reminders-form-a11y/components/reminders-form-a11y/reminders-form-a11y.component';
import { Record } from 'src/app/records/models/record.model';
import { DictionaryPipeModule } from '../../../../../dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { SdFlatButtonA11yModule } from '../../../../../shared/sd-flat-button-a11y/sd-flat-button-a11y.module';
import { Reminders } from '../../../../models/reminders.model';
import { Observable } from 'rxjs';
import { SideDrawer } from 'src/app/sidedrawer/models/side-drawer.model';
import { activeSideDrawerSelector } from 'src/app/sidedrawer/store/sidedrawer.selector';
import { select, Store } from '@ngrx/store';
import { AppState } from 'src/app/reducers';
import { activeRecordSelector } from 'src/app/records/store/records-list.selectors';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-reminders-form-dialog-a11y',
  templateUrl: './reminders-form-dialog-a11y.component.html',
  styleUrls: ['./reminders-form-dialog-a11y.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    SdDialogTemplateA11yModule,
    RemindersFormA11yComponent,
    DictionaryPipeModule,
    SdFlatButtonA11yModule,
  ],
})
export class RemindersFormDialogA11yComponent {
  dialogTemplateTypes = DialogTemplateTypes;
  activeSidedrawer$: Observable<SideDrawer> = this.store.pipe(
    select(activeSideDrawerSelector)
  );
  activeRecord$: Observable<Record> = this.store.pipe(
    select(activeRecordSelector)
  );

  constructor(
    private readonly store: Store<AppState>,
    private readonly dialogRef: MatDialogRef<RemindersFormDialogA11yComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      record?: Record;
      reminder?: Reminders;
      disabled?: boolean;
    }
  ) {}

  onClose(): void {
    this.dialogRef.close();
  }
}
