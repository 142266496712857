import { Pipe, PipeTransform } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { forkJoin, Observable } from 'rxjs';
import {
  dictionarySelector,
  localeDefaultSelector,
} from '../../dictionary/store/dictionary.selectors';
import { map, take } from 'rxjs/operators';
import { DateHelper } from '../../core/helpers/date.helper';

@Pipe({
  name: 'sdFormattedDate',
})
export class SdFormattedDatePipe implements PipeTransform {
  constructor(private readonly store: Store<AppState>) {}

  transform(date: Date): Observable<string> {
    return forkJoin([
      this.store.pipe(select(dictionarySelector), take(1)),
      this.store.pipe(select(localeDefaultSelector), take(1)),
    ]).pipe(
      map(([dictionary, locale]) =>
        date ? DateHelper.formatDate(date.toString(), dictionary, locale) : ''
      )
    );
  }
}
