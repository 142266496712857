<div class="related-records-option">
  <div
    (click)="itemWithLink ? redirectToRecord() : null"
    (keydown.enter)="itemWithLink ? redirectToRecord() : null"
    class="related-records-result-left-container"
    [ngClass]="{ 'no-pointer': !itemWithLink }">
    <div
      [ngClass]="{ 'no-pointer': !itemWithLink }"
      class="related-records-option-image">
      <div class="related-records-option-image-container">
        <app-sd-svg-a11y
          [color]="'var(--primaryColor)'"
          [height]="1"
          [src]="relatedRecord.recordType?.logo"
          [width]="1"></app-sd-svg-a11y>
      </div>
    </div>
    <div>
      <h3>{{ relatedRecord.name }}</h3>
      <div
        [ngClass]="{ 'no-pointer': !itemWithLink }"
        class="related-records-list-item-time">
        <span class="last-modified">{{
          relatedRecord?.lastModifiedBy
            ? ('globalparams_lastmodifiedby' | dictionary | async)
            : ('globalparams_lastmodified' | dictionary | async)
        }}</span>
        <strong *ngIf="relatedRecord?.lastModifiedBy" class="by">{{
          relatedRecord?.lastModifiedBy
        }}</strong>

        <span *ngIf="happensToday" class="at">{{
          'globalparams_lastmodifiedat' | dictionary | async
        }}</span>
        <span *ngIf="!happensToday" class="at">{{
          'globalparams_lastmodifiedon' | dictionary | async
        }}</span>
        <strong class="date">{{
          relatedRecord?.updatedAt | sdFormattedDate | async
        }}</strong>
      </div>
    </div>
  </div>
  <div class="related-records-result-right-container">
    <div
      (click)="redirectToRecord()"
      (keydown.enter)="redirectToRecord()"
      class="related-records-list-item-label">
      <span>{{ relatedRecord?.recordSubtype?.displayValue[0]?.value }}</span>
    </div>
    <div *ngIf="userCanOperate()">
      <div *ngIf="!spinner" class="related-records-section-item-actions">
        <app-sd-link-menu-a11y
          (clickOverOptionMenu)="onClickOverOptionMenu($event)"
          (emitterClicked)="onEmitterClicked($event)"
          [accessibility]="{
            ariaLabel:
              'relatedrecordstags_actionmenutooltip' | dictionary | async,
            tabIndex: -1
          }"
          [border]="false"
          [buttonHeight]="1.2"
          [buttonWidth]="2"
          [disableRipple]="true"
          [iconColor]="sdColorPalette.primaryAccentColor"
          [icon]="
            cdn + ('globalparams_horizontalactionmenuicon' | dictionary | async)
          "
          [setMenuData]="[
            {
              menuLinkText:
                'relatedrecordstags_actionmenudelete' | dictionary | async,
              visible: true,
              menuLinkType: sdLinkMenuLinkType.emitter,
              key: menuItemType.delete
            }
          ]"
          [squareBackground]="true"
          [tooltip]="
            'relatedrecordstags_actionmenutooltip' | dictionary | async
          "
          [transparentBackground]="true">
        </app-sd-link-menu-a11y>
      </div>
      <app-sd-progress-spinner-a11y
        *ngIf="spinner"></app-sd-progress-spinner-a11y>
    </div>
  </div>
</div>
