import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  featureNameSdBreadcrumbsA11y,
  SdBreadCrumbsA11yState,
} from './sd-breadcrumbs-a11y.reducer';

export const sdBreadCrumbsA11yStateSelector =
  createFeatureSelector<SdBreadCrumbsA11yState>(featureNameSdBreadcrumbsA11y);

export const sdBreadCrumbsA11ySelector = createSelector(
  sdBreadCrumbsA11yStateSelector,
  state => state.breadCrumbs
);
