import {
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  Signal,
} from '@angular/core';
import { SdAutoCompleteA11yEnum } from '../../../shared/sd-autocomplete-a11y/model/sd-autocomplete-a11y.enum';
import { SdAutocompleteA11yModule } from '../../../shared/sd-autocomplete-a11y/sd-autocomplete-a11y.module';
import { FormControl } from '@angular/forms';
import { Option } from '../../../shared/sd-forms/models/option.model';
import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { debounceTime, tap } from 'rxjs/operators';
import {
  NetworkSearchActions,
  networkSearchHasMoreSelector,
  searchingNetworkSelector,
} from '../../store/network-search.store';
import { SdAccessibilitySetting } from '../../../core/models/sd-accessibility-model';

@Component({
  selector: 'app-network-autocomplete',
  standalone: true,
  imports: [SdAutocompleteA11yModule],
  template: `
    <app-sd-autocomplete-a11y
      [template]="template"
      [required]="required"
      [loading]="loading()"
      [spinner]="loading()"
      [readonly]="readOnly"
      [hasMore]="hasMore()"
      [keyMode]="false"
      (optionSelected)="optionSelected.emit($event)"
      [controller]="_controller"
      [customTooltip]="customTooltip"
      [error]="error"
      [placeholder]="placeholder"
      [setOptions]="options"
      [setSdAccessibility]="setSdAccessibility" />
  `,
})
export class NetworkAutocompleteComponent {
  private readonly store = inject(Store<AppState>);
  protected readonly SdAutoCompleteA11yEnum = SdAutoCompleteA11yEnum;
  _controller: FormControl;
  loading: Signal<boolean> = this.store.selectSignal(searchingNetworkSelector);
  hasMore = this.store.selectSignal(networkSearchHasMoreSelector);
  @Input() required: boolean;
  @Input({ required: true }) set controller(controller: FormControl) {
    this._controller = controller;
    this._controller?.valueChanges
      .pipe(
        debounceTime(1000),
        tap(filter => console.log({ filter })),
        tap(filter =>
          this.store.dispatch(
            !!filter && typeof filter === 'string'
              ? NetworkSearchActions.filterChanged({ filter })
              : NetworkSearchActions.clear()
          )
        )
      )
      .subscribe();
  }
  @Input() customTooltip: string;
  @Input() error: string;
  @Input() readOnly = false;
  @Input() placeholder: string;
  @Input() options: Option[];
  @Input() template = SdAutoCompleteA11yEnum.default;
  @Input() setSdAccessibility: SdAccessibilitySetting;
  @Output() optionSelected = new EventEmitter<Option>();
}
