import { Dictionary } from '../models/dictionary.model';
import { Relationship } from '../models/relationship.model';
import { RelationshipType } from '../models/relationship-type';
import { DicPrice } from '../models/dic-price.model';
import { SubscriptionStatus } from '../models/subscription-status.model';
import { SidedrawerType } from '../models/sidedrawer-type.model';
import { BlockedFileFormat } from '../models/blocked-file-format.model';
import { Tos } from '../models/tos.model';
import { PrivacyPolicy } from '../models/privacy-policy.model';
import { Support } from '../models/support.model';
import {
  DictionaryActions,
  DictionaryActionsTypes,
} from './dictionary.actions';
import { Language } from '../models/language.model';
import { Locale } from '../models/locale.model';
import { ImageFileFormat } from '../models/image-file-format.model';
import { VideoFileFormat } from '../models/video-file-format.model';
import { Currency } from '../models/currency.model';
import { DictionaryDisplayType } from '../models/dictionary-display-type.model';
import { CustomFieldFormType } from '../models/custom-field-form-type.model';
import { DataBaseRegion } from '../models/data-base-region.models';
import { HelpSections } from '../models/help-sections.model';
import { HelpVideos } from '../models/help-videos.model';
import { FrequencyDTO } from '../models/frequency.dto';
import { ReminderStatusDTO } from 'src/app/reminders/models/reminder-status-dto';
import { SdSearchEntity } from '../models/sd-search-entity.model';
import { DictionaryRole } from '../models/dictionary-role.model';
import { IdpMfaByBrandCode } from '../models/idp-mfa-by-brand-code.model';
import { MenuByIdp } from '../models/menu-by-idp.model';
import { TdVcard } from '../models/td.vcard';
import { SdLabel } from '../models/sd-label.model';
import { HelpArticle } from '../models/help-article.model';

export const featureName = 'dictionary';

export interface DictionaryState {
  frequencies: FrequencyDTO[];
  reminderStatus: ReminderStatusDTO[];
  languages: Language[];
  localeDefault: Locale;
  dictionary: Dictionary;
  relationships: Relationship[];
  relationshipTypes: RelationshipType[];
  plans: DicPrice[];
  subscriptionsStatuses: SubscriptionStatus[];
  sideDrawerTypes: SidedrawerType[];
  blockedFileFormats: BlockedFileFormat[];
  imageFileFormats: ImageFileFormat[];
  videoFileFormats: VideoFileFormat[];
  tos: Tos[];
  privacyPolicies: PrivacyPolicy[];
  supports: Support[];
  displayTypes: DictionaryDisplayType[];
  currencies: Currency[];
  customFieldFormTypes: CustomFieldFormType[];
  dataBaseRegions: DataBaseRegion[];
  helpSections: HelpSections[];
  helpVideos: HelpVideos[];
  dictionaryRoles: DictionaryRole[];
  sdSearchEntities: SdSearchEntity[];
  idpMfaByBrandCodes: IdpMfaByBrandCode[];
  menubyidp: MenuByIdp[];
  gettingDictionary: boolean;
  tdVCard: TdVcard[];
  sdLabels: SdLabel[];
  helpArticles: HelpArticle[];
}

export const initialDictionaryState: DictionaryState = {
  frequencies: null,
  reminderStatus: null,
  languages: null,
  localeDefault: null,
  dictionary: null,
  relationships: null,
  relationshipTypes: null,
  plans: null,
  subscriptionsStatuses: null,
  sideDrawerTypes: null,
  blockedFileFormats: null,
  imageFileFormats: null,
  videoFileFormats: null,
  tos: null,
  privacyPolicies: null,
  supports: null,
  currencies: null,
  displayTypes: null,
  customFieldFormTypes: null,
  dataBaseRegions: null,
  helpSections: null,
  helpVideos: null,
  dictionaryRoles: null,
  sdSearchEntities: null,
  idpMfaByBrandCodes: null,
  menubyidp: null,
  gettingDictionary: false,
  tdVCard: null,
  sdLabels: null,
  helpArticles: null,
};

export function dictionaryReducer(
  state: DictionaryState = initialDictionaryState,
  action: DictionaryActions
): DictionaryState {
  switch (action.type) {
    case DictionaryActionsTypes.BlockedFileFormatsLoaded:
      return {
        ...state,
        blockedFileFormats: action.payload.data,
      };
    case DictionaryActionsTypes.ImageFileFormatsLoaded:
      return {
        ...state,
        imageFileFormats: action.payload.data,
      };
    case DictionaryActionsTypes.VideoFileFormatsLoaded:
      return {
        ...state,
        videoFileFormats: action.payload.data,
      };
    case DictionaryActionsTypes.DictionaryLoaded:
      return {
        ...state,
        dictionary: action.payload.data,
      };
    case DictionaryActionsTypes.LanguagesLoaded:
      return {
        ...state,
        languages: action.payload.languages,
      };
    case DictionaryActionsTypes.FrequenciesLoaded:
      return {
        ...state,
        frequencies: action.payload.frequencies,
      };
    case DictionaryActionsTypes.ReminderStatusLoaded:
      return {
        ...state,
        reminderStatus: action.payload.reminderStatus,
      };
    case DictionaryActionsTypes.LocaleDefaultChange:
      return {
        ...state,
        localeDefault: action.payload.data,
      };
    case DictionaryActionsTypes.DictionaryPricesLoaded:
      return {
        ...state,
        plans: action.payload.data,
      };
    case DictionaryActionsTypes.PrivacyPoliciesLoaded: {
      let pps = [...action.payload.data];
      try {
        pps = pps.sort(
          (a, b) =>
            b.privacypolicy_versionnumber - a.privacypolicy_versionnumber
        );
      } catch (e) {
        console.error(e);
        pps = [...action.payload.data];
      }
      return {
        ...state,
        privacyPolicies: pps,
      };
    }
    case DictionaryActionsTypes.RelationshipsLoaded:
      return {
        ...state,
        relationships: action.payload.data,
      };
    case DictionaryActionsTypes.RelationshipTypesLoaded:
      return {
        ...state,
        relationshipTypes: action.payload.data,
      };
    case DictionaryActionsTypes.SideDrawerTypesLoaded:
      return {
        ...state,
        sideDrawerTypes: action.payload.data,
      };
    case DictionaryActionsTypes.SubscriptionsStatusLoaded:
      return {
        ...state,
        subscriptionsStatuses: action.payload.data,
      };
    case DictionaryActionsTypes.SupportLoaded:
      return {
        ...state,
        supports: action.payload.data,
      };
    case DictionaryActionsTypes.DisplayTypesLoaded:
      return {
        ...state,
        displayTypes: action.payload.data,
      };
    case DictionaryActionsTypes.TosLoaded: {
      let tosSorted = [...action.payload.data];
      try {
        tosSorted = tosSorted.sort(
          (a, b) => b.tos_versionnumber - a.tos_versionnumber
        );
      } catch (e) {
        console.error(e);
        tosSorted = [...action.payload.data];
      }
      return {
        ...state,
        tos: tosSorted,
      };
    }
    case DictionaryActionsTypes.CurrenciesLoaded:
      return {
        ...state,
        currencies: action.payload.data,
      };
    case DictionaryActionsTypes.CustomFieldFormTypesLoaded: {
      return {
        ...state,
        customFieldFormTypes: action?.payload?.data,
      };
    }
    case DictionaryActionsTypes.DataBaseRegionsLoaded:
      return {
        ...state,
        dataBaseRegions: action.payload.data,
      };
    case DictionaryActionsTypes.HelpSectionsLoaded:
      return {
        ...state,
        helpSections: action.payload.data,
      };
    case DictionaryActionsTypes.HelpVideosLoaded:
      return {
        ...state,
        helpVideos: action.payload.data,
      };
    case DictionaryActionsTypes.DictionaryRolesLoaded:
      return {
        ...state,
        dictionaryRoles: action.payload.data,
      };
    case DictionaryActionsTypes.SdSearchEntitiesLoaded:
      return {
        ...state,
        sdSearchEntities: action.payload.data,
      };
    case DictionaryActionsTypes.IdpMfaByBrandCodesLoaded:
      return {
        ...state,
        idpMfaByBrandCodes: action.payload.data,
      };
    case DictionaryActionsTypes.MenubyidpLoaded:
      return {
        ...state,
        menubyidp: action.payload.data,
      };
    case DictionaryActionsTypes.TdVCardLoaded:
      return {
        ...state,
        tdVCard: action.payload.data,
      };
    case DictionaryActionsTypes.SdLabelsLoaded:
      return {
        ...state,
        sdLabels: action.payload.data,
      };
    case DictionaryActionsTypes.HelpArticlesLoaded:
      return {
        ...state,
        helpArticles: action.payload.data,
      };
    case DictionaryActionsTypes.GettingDictionaryChanges:
      return {
        ...state,
        gettingDictionary: action.payload.gettingDictionary,
      };
    default:
      return state;
  }
}
