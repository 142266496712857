<app-sd-main-content-template-a11y>
  <div *ngIf="vm$ | async as vm" section-body>
    <app-sd-breadcrumbs-a11y [setBreadCrumbs]="vm.breadCrumbs" />
    <div class="app-records-type-switcher-container">
      <app-records-type-switcher></app-records-type-switcher>
    </div>
    <div *ngIf="!vm.loading; else spinner" class="record-form-view">
      <div
        *ngIf="(activeRecord$ | async) === null"
        class="record-form-view-primary-fields">
        <app-sd-input-a11y
          (suffixClicked)="nameControl.reset()"
          [controller]="nameControl"
          [error]="
            isSubmitted
              ? ('globalparams_requirederror' | dictionary | async)
              : ''
          "
          [placeholder]="
            'recordformview_personalizednameplaceholder' | dictionary | async
          "
          [template]="SdInputA11yTemplates.inputWithSuffix"
          [tooltip]="
            'recordformview_personalizednametooltip' | dictionary | async
          "
          [type]="'text'"
          class="personalized-name-input" />

        <app-record-subtype-autocomplete-a11y
          (subTypeSelected)="onSubTypeChange($event)"
          [controller]="subTypeControl"
          [error]="
            subTypeControl.hasError('notFromSelection')
              ? ('globalparams_selectionerror' | dictionary | async)
              : ('globalparams_requirederror' | dictionary | async)
          "
          [recordSubTypeName]="''"
          [startValue]="vm.activeRecord?.recordSubtype" />
      </div>

      <div *ngIf="activeRecord$ | async as record">
        <app-records-list-item
          [recordItem]="record"
          (emiterClicked)="onEmitterClicked($event)"
          (recordRenameClicked)="openRenameAndChangeTypeDialog(record)"
          [template]="recordListItemTemplate.recordHeader"
          [setSpinner]="spinner$ | async" />
      </div>

      <div class="record-form-view-buttons">
        <app-sd-flat-button-a11y
          (buttonClicked)="onCancel()"
          [primary]="false"
          [setSdAccessibility]="{
            ariaLabel: 'globalparams_cancel' | dictionary | async
          }"
          >{{ 'globalparams_cancel' | dictionary | async }}
        </app-sd-flat-button-a11y>

        <app-sd-flat-button-a11y
          (buttonClicked)="onConfirm()"
          [primary]="!form.invalid"
          [inactiveButtonClass]="true"
          [setSdAccessibility]="{
            ariaLabel: 'recordformview_submitbutton' | dictionary | async
          }"
          >{{ 'recordformview_submitbutton' | dictionary | async }}
        </app-sd-flat-button-a11y>
      </div>

      <app-sd-tabs-template-a11y
        [links]="vm.links"
        [template]="tabsMode.tabsWithRouterLinks" />

      <div class="record-form-view-buttons">
        <app-sd-flat-button-a11y
          (buttonClicked)="onCancel()"
          [primary]="false"
          [setSdAccessibility]="{
            ariaLabel: 'globalparams_cancel' | dictionary | async
          }"
          >{{ 'globalparams_cancel' | dictionary | async }}
        </app-sd-flat-button-a11y>

        <app-sd-flat-button-a11y
          (buttonClicked)="onConfirm()"
          [primary]="!form.invalid"
          [inactiveButtonClass]="true"
          [setSdAccessibility]="{
            ariaLabel: 'recordformview_submitbutton' | dictionary | async
          }"
          >{{ 'recordformview_submitbutton' | dictionary | async }}
        </app-sd-flat-button-a11y>
      </div>
    </div>

    <ng-template #spinner>
      <div class="records-form-view-spinner">
        <app-sd-progress-spinner-a11y [diameter]="20" />
      </div>
    </ng-template>
  </div>
</app-sd-main-content-template-a11y>
