import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DestroyRef, inject } from '@angular/core';
import { NetworkSearchActions } from './network-search.store';
import { NetworkListActions } from './network-list.store';
import { map, switchMap, tap } from 'rxjs/operators';
import { NetworkPermanentActions } from './network-permanent.store';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import {
  FilingCabinetActions,
  filingCabinetSideDrawersSelector,
} from '../../filing-cabinet/store/filing-cabinet.store';
import { activeSideDrawerSelector } from '../../sidedrawer/store/sidedrawer.selector';
import {
  AccountActionsTypes,
  AccountLoaded,
} from '../../account/store/account.actions';
import { MyTeamsRequested } from './my-teams/my-teams-list.actions';
import { combineLatest } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export const networkPermanentLoaded$ = createEffect(
  () =>
    inject(Actions).pipe(
      ofType(NetworkSearchActions.loaded, NetworkListActions.loaded),
      map(action =>
        NetworkPermanentActions.loaded({
          networks: action?.response?.data ?? [],
        })
      )
    ),
  { functional: true }
);

export const networkPermanentAllNetworksRequestedForReminders$ = createEffect(
  () => {
    const store = inject(Store<AppState>);
    return inject(Actions).pipe(
      ofType(NetworkPermanentActions.allNetworksRequestedForReminders),
      tap(() => {
        const sideDrawers =
          store.selectSignal(filingCabinetSideDrawersSelector)()?.size > 0
            ? [
                ...Array.from(
                  store
                    .selectSignal(filingCabinetSideDrawersSelector)()
                    ?.values()
                )?.map(fcSd => fcSd.sidedrawer),
              ]
            : store.selectSignal(activeSideDrawerSelector)()
              ? [store.selectSignal(activeSideDrawerSelector)()?.id]
              : [];
        const operations = sideDrawers.map(sideDrawerId => ({
          sideDrawerId,
          allPageRequested: true,
        }));
        store.dispatch(
          NetworkListActions.multipleOperationsRequested({
            operations,
          })
        );
        store.dispatch(
          NetworkPermanentActions.networksRequestedChange({ requested: true })
        );
      })
    );
  },
  { functional: true, dispatch: false }
);

export const fillingCabinetAllNetworksRequestedForReminders$ = createEffect(
  () => {
    const store = inject(Store<AppState>);
    const actions = inject(Actions);
    const destroyRef = inject(DestroyRef);
    return inject(Actions).pipe(
      ofType(
        NetworkPermanentActions.fillingCabinetAllNetworksRequestedForReminders
      ),
      switchMap(() =>
        combineLatest([
          actions.pipe(
            ofType(FilingCabinetActions.filingCabinetSideDrawersLoaded)
          ),
        ]).pipe(
          takeUntilDestroyed(destroyRef),
          tap(([action]) => {
            if (
              action.type ===
              FilingCabinetActions.filingCabinetSideDrawersLoaded.type
            ) {
              store.dispatch(
                NetworkPermanentActions.allNetworksRequestedForReminders()
              );
            }
          })
        )
      )
    );
  },
  { functional: true, dispatch: false }
);

export const networkAccountLoaded$ = createEffect(
  () =>
    inject(Actions).pipe(
      ofType<AccountLoaded>(AccountActionsTypes.AccountLoaded),
      map(() => new MyTeamsRequested())
    ),
  { functional: true }
);
