import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SdQueueItem } from '../models/sd-queue-item.model';
import { Update } from '@ngrx/entity';

export const QueueActions = createActionGroup({
  source: 'Queue',
  events: {
    'Status Change': props<{
      status: 'idle' | 'processing' | 'completed' | 'cancelled';
    }>(),
    'Start Process': emptyProps(),
    'Cancel Process': emptyProps(),
    'Reset Process': emptyProps(),
    'Process Completes': emptyProps(),
    'Process Next Item': emptyProps(),
    'Item Added': props<{ item: SdQueueItem }>(),
    'Item Removed': props<{ id: string }>(),
    'Item Update': props<{ item: Update<SdQueueItem> }>(),
    'Item Completes': props<{ item: Update<SdQueueItem> }>(),
    'Widow Status Change': props<{
      windowStatus: 'hidden' | 'minimized' | 'maximized';
    }>(),
  },
});
