import { ComponentStore } from '@ngrx/component-store';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NetworkV2 } from '../networks/models/network.v2.type';
import { basePathSelector } from '../sidedrawer/store/sidedrawer.selector';
import {
  NetworksRoutesEnum,
  NetworksRoutesTabsEnum,
} from '../networks/routes/networks-routes.enum';
import { NetworkType } from '../networks/models/network-type.model';
import { NetworkEntity } from '../networks/models/network-entity.enum';
import { Store } from '@ngrx/store';
import { AppState } from '../reducers';
import { ContributorType } from '../networks/models/contributor-type.model';
import { MatDialog } from '@angular/material/dialog';
import { from, mergeMap, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NetworkDeleteDialogComponent } from '../networks/shared/network-delete-dialog/network-delete-dialog.component';
import { NetworkRelationshipDialogComponent } from '../networks/shared/network-relationship-dialog/network-relationship-dialog.component';
import { SdSnackBarDisplaySnackBar } from '../shared/sd-snack-bar/store/sd-snack-bar.actions';
import { dictionarySelector } from '../dictionary/store/dictionary.selectors';
import { LiveAnnouncer } from '@angular/cdk/a11y';

type NetworkListItemState = {
  spinner: boolean;
};

@Injectable()
export class NetworkListItemStore extends ComponentStore<NetworkListItemState> {
  private readonly store = inject(Store<AppState>);
  private readonly liveAnnouncer = inject(LiveAnnouncer);
  private readonly router = inject(Router);
  private readonly dialog = inject(MatDialog);
  readonly spinner = this.selectSignal(state => state.spinner);

  update = this.effect(
    (
      trigger$: Observable<{ network: NetworkV2; networkEntity: NetworkEntity }>
    ) =>
      trigger$.pipe(
        tap(() => this.patchState({ spinner: true })),
        mergeMap(({ network, networkEntity }) => {
          if (network?.contributorType === ContributorType.team) {
            const url =
              this.store.selectSignal(basePathSelector)() +
              `${NetworksRoutesEnum.root}/${NetworksRoutesEnum.form}/${
                NetworkType.team
              }/${network?.teamNetwork?.teamId}/${
                networkEntity === NetworkEntity.records
                  ? NetworksRoutesTabsEnum.records
                  : NetworksRoutesTabsEnum.sideDrawer
              }`;
            return from(this.router.navigateByUrl(url));
          }
          // TODO see this...
          const collaboratorOrInvitationKey = network?.contributor?.openId
            ? network?.contributor.openId
            : network?.contributor?.email;

          const networkType = network?.contributor?.openId
            ? NetworkType.collaborator
            : NetworkType.invitation;
          const url =
            this.store.selectSignal(basePathSelector)() +
            `${NetworksRoutesEnum.root}/${NetworksRoutesEnum.form}/${networkType}/${collaboratorOrInvitationKey}/${
              networkEntity === NetworkEntity.records
                ? NetworksRoutesTabsEnum.records
                : NetworksRoutesTabsEnum.sideDrawer
            }`;
          return from(this.router.navigateByUrl(url));
        })
      )
  );

  delete = this.effect(
    (
      trigger$: Observable<{ network: NetworkV2; networkEntity: NetworkEntity }>
    ) =>
      trigger$.pipe(
        tap(() => this.patchState({ spinner: true })),
        mergeMap(({ network }) =>
          this.dialog
            .open(NetworkDeleteDialogComponent, {
              autoFocus: 'h1',
              disableClose: true,
              data: { network },
            })
            .afterClosed()
        ),
        tap(() => this.patchState({ spinner: false }))
      )
  );

  updateRelation = this.effect((trigger$: Observable<{ network: NetworkV2 }>) =>
    trigger$.pipe(
      tap(() => this.patchState({ spinner: true })),
      mergeMap(({ network }) =>
        this.dialog
          .open(NetworkRelationshipDialogComponent, {
            autoFocus: false,
            disableClose: true,
            data: { network },
          })
          .afterClosed()
      ),
      tap(() => this.patchState({ spinner: false }))
    )
  );

  copyLink = this.effect((trigger$: Observable<{ network: NetworkV2 }>) =>
    trigger$.pipe(
      tap(() => this.patchState({ spinner: true })),
      mergeMap(({ network }) => {
        let url: string;
        if (network?.contributorType === ContributorType.team) {
          url =
            window.location.origin +
            this.store.selectSignal(basePathSelector)() +
            `${NetworksRoutesEnum.root}/${NetworksRoutesEnum.form}/${NetworkType.team}/${network?.teamNetwork?.teamId}/${NetworksRoutesTabsEnum.sideDrawer}`;
        } else {
          const collaboratorOrInvitationKey = network?.contributor?.openId
            ? network?.contributor.openId
            : network?.contributor?.email;
          const networkType = network?.contributor?.openId
            ? NetworkType.collaborator
            : NetworkType.invitation;
          url =
            window.location.origin +
            this.store.selectSignal(basePathSelector)() +
            `${NetworksRoutesEnum.root}/${NetworksRoutesEnum.form}/${networkType}/${collaboratorOrInvitationKey}/${NetworksRoutesTabsEnum.sideDrawer}`;
        }
        return from(navigator.clipboard.writeText(url));
      }),
      tap(() => this.patchState({ spinner: false })),
      tap(() =>
        this.liveAnnouncer.announce(
          this.store.selectSignal(dictionarySelector)()
            ?.globalparams_copiedtoclipboard
        )
      ),
      tap(() =>
        this.store.dispatch(
          new SdSnackBarDisplaySnackBar({
            message:
              this.store.selectSignal(dictionarySelector)()
                ?.globalparams_copiedtoclipboard,
          })
        )
      )
    )
  );

  constructor() {
    super({
      spinner: false,
    });
  }
}
