import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { DialogTemplateTypes } from 'src/app/shared/templates/enums/templates.enum';
import { SdDialogTemplateA11yModule } from '../../templates/dialog-template-a11y/sd-dialog-template-a11y.module';
import { DictionaryPipeModule } from '../../../dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { AsyncPipe } from '@angular/common';
import { SdFlatButtonA11yModule } from '../../sd-flat-button-a11y/sd-flat-button-a11y.module';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-generic-error-dialog',
  template: `
    <app-sd-dialog-template-a11y
      (closeButtonClicked)="onClose()"
      [type]="dialogTemplateTypes.Info">
      <ng-container dialog-header>
        <h2 class="base-dialog-title">
          {{ 'genericerrordialog_title' | dictionary | async }}
        </h2>
      </ng-container>
      <ng-container dialog-body>
        <div
          [tabindex]="0"
          [innerHTML]="'genericerrordialog_body' | dictionary | async"
          class="base-dialog-content"></div>
      </ng-container>
      <ng-container dialog-footer>
        <app-sd-flat-button-a11y (buttonClicked)="onClose()" [primary]="true">
          {{ 'genericerrordialog_primarybutton' | dictionary | async }}
        </app-sd-flat-button-a11y>
      </ng-container>
    </app-sd-dialog-template-a11y>
  `,
  styleUrls: ['./generic-error-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SdDialogTemplateA11yModule,
    DictionaryPipeModule,
    AsyncPipe,
    SdFlatButtonA11yModule,
  ],
})
export class GenericErrorDialogComponent {
  dialogTemplateTypes = DialogTemplateTypes;

  constructor(
    private readonly dialogRef: MatDialogRef<GenericErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { error: HttpErrorResponse }
  ) {}

  onClose(): void {
    this.dialogRef.close();
  }
}
