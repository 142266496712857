import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { select, Store } from '@ngrx/store';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { UtilsHelper } from '../../../core/helpers/utils.helper';
import { RecordsRoles } from '../../../core/roles/records.roles';
import { SidedrawerRoles } from '../../../core/roles/sidedrawer.roles';
import { dictionarySelector } from '../../../dictionary/store/dictionary.selectors';
import { AppState } from '../../../reducers';
import { Option } from '../../../shared/sd-forms/models/option.model';
import { ContributorType } from '../../models/contributor-type.model';
import { NetworkResourceType } from '../../models/network-resource-type.model';
import { Network } from '../../models/network.model';
import { NewNetworkRow } from '../../models/new-network-row.model';
import { TeamNetwork } from '../../models/team-network.model';
import { myTeamsListSelector } from '../../store/my-teams/my-teams-list.selectors';
import { SdQueueItem } from 'src/app/queue/models/sd-queue-item.model';
import { QueueActions } from 'src/app/queue/store/queue.actions';
import { NetworkService } from '../../services/network.service';
import { NetworkType } from '../../models/network-type.model';
import { Team } from 'src/app/account/models/team.model';
import { CreateRecordNetworkDto } from '../../models/create-record-network.dto';
import { CreateSidedrawerNetworkDto } from '../../models/create-sidedrawer-network.dto';
import { NetworkHelper } from '../../helpers/network.helper';
import { MyTeamsRequested } from '../../store/my-teams/my-teams-list.actions';
import { gettingNetworkListSelector } from '../../store/network-list.store';
import { networkPermanentListAsOptions } from '../../store/network-permanent.store';

export interface ShareResourceDialogState {
  rows: Map<number, NewNetworkRow>;
  resourceType?: NetworkResourceType;
  sideDrawerId?: string;
  recordId?: string;
  recordTypeName?: string;
  infoRequestId?: string;
  status?: boolean;
}

@Injectable()
export class ShareResourceDialogStore extends ComponentStore<ShareResourceDialogState> {
  readonly gettingInformation$ = this.store.select(gettingNetworkListSelector);
  readonly resourceType$ = this.select(state => state.resourceType);
  readonly rows$ = this.select(state => state.rows);
  readonly recordId$ = this.select(state => state.recordId);
  readonly sideDrawerId$ = this.select(state => state.sideDrawerId);
  readonly status$ = this.select(state => state.status);

  readonly accountOptions$: Observable<Option[]> = this.store.select(
    networkPermanentListAsOptions({
      asUsers: true,
      excludeTeams: true,
    })
  );

  readonly teamOptions$: Observable<Option[]> = combineLatest([
    this.store.select(myTeamsListSelector),
    this.select(state => state.rows),
  ]).pipe(
    map(([myTeams, rows]) => {
      return myTeams.map(myTeam => {
        const option = new Option(
          myTeam.id,
          myTeam.name,
          myTeam.logo,
          'Team',
          false
        );

        UtilsHelper.mapToArray<NewNetworkRow>(rows).forEach(row => {
          if (row.teamId === myTeam.id) {
            option.disabled = true;
          }
        });
        return option;
      });
    })
  );

  readonly contributorTypeOptions$: Observable<Option[]> = forkJoin([
    this.store.pipe(select(myTeamsListSelector), take(1)),
    this.store.pipe(select(dictionarySelector), take(1)),
  ]).pipe(
    map(([myTeams, dictionary]) => {
      const cdn = environment.cdn;
      const options = [
        new Option(
          ContributorType.account,
          dictionary.globalparams_contributortypeaccount,
          cdn + dictionary.globalparams_contributortypeaccounticon
        ),
      ];
      if (myTeams.length > 0) {
        options.push(
          new Option(
            ContributorType.team,
            dictionary.globalparams_contributortypeteam,
            cdn + dictionary.globalparams_contributortypeteamicon
          )
        );
      }
      return options;
    })
  );

  createRow = this.effect((id$: Observable<number | null>) => {
    return id$.pipe(
      switchMap(id =>
        forkJoin([this.rows$.pipe(take(1)), of(id).pipe(take(1))])
      ),
      tap(([rows, id]) => {
        let newId = 0.5;
        const newContributorType = ContributorType.account;
        if (id) {
          const rowArray = UtilsHelper.mapToArray<NewNetworkRow>(rows).sort(
            (a, b) => UtilsHelper.compareNumbers(a.id, b.id)
          );
          const currentValueIndex = rowArray.findIndex(row => row.id === id);
          const currentValue = rows.get(id)?.id;
          const nextValue = rowArray.at(currentValueIndex + 1)?.id ?? 1;
          newId = (nextValue - currentValue) / 2 + currentValue;
        }

        const newRows = new Map<number, NewNetworkRow>(rows);
        newRows.set(newId, {
          id: newId,
          contributorType: newContributorType,
        });

        this.patchState({
          rows: newRows,
        });
        this.setStatusRows();
      })
    );
  });

  deleteRow = this.effect((id$: Observable<number | null>) => {
    return id$.pipe(
      switchMap(id =>
        forkJoin([this.rows$.pipe(take(1)), of(id).pipe(take(1))])
      ),
      tap(([rows, id]) => {
        const newRows = new Map<number, NewNetworkRow>(rows);
        newRows.delete(id);
        this.patchState({
          rows: newRows,
        });
        this.setStatusRows();
      })
    );
  });

  updateRow = this.effect((item$: Observable<NewNetworkRow>) => {
    return item$.pipe(
      switchMap(item => {
        return this.rows.set(item.id, item);
      }),
      tap(() => {
        this.setStatusRows();
      })
    );
  });

  getInitialInformation = this.effect(
    (
      payload$: Observable<{
        resourceType: NetworkResourceType;
        sideDrawerId?: string;
        recordId?: string;
        recordTypeName?: string;
        infoRequestId?: string;
      }>
    ) => {
      return payload$.pipe(
        map(payload => {
          this.patchState({
            resourceType: payload.resourceType,
            sideDrawerId: payload.sideDrawerId,
            recordId: payload.recordId,
            recordTypeName: payload.recordTypeName,
            infoRequestId: payload.infoRequestId,
            status: false,
          });
        }),
        tap(() => {
          this.createRow(null);
          this.store.dispatch(new MyTeamsRequested());
        })
      );
    }
  );

  readonly addRowsToQueue = this.effect(
    (
      params$: Observable<{
        callback: (rows: Map<number, NewNetworkRow>, status: boolean) => void;
      }>
    ) =>
      params$.pipe(
        switchMap(params => forkJoin([of(params).pipe(take(1))])),
        switchMap(([params]) =>
          this.rows$.pipe(
            take(1),
            tap(rows => {
              const arrayValidAndUniqueRows = this.getValidAndUniqueRows(rows);

              arrayValidAndUniqueRows.forEach(row => {
                let teamSelected: Team;
                if (row.teamId) {
                  teamSelected = NetworkHelper.getTeamObject(
                    row.teamId,
                    this.store
                  );
                }

                const createRecordNetworkDto: CreateRecordNetworkDto = {
                  recordRole: <RecordsRoles>row.permission.key,
                  contributor: {
                    teamId: row.teamId,
                    email: row.email,
                    firstName: row.fullName,
                  },
                  relation: row.relation,
                  contributorType: row.contributorType,
                  expiryDate: row?.expiryDate ?? null,
                };

                const createSidedrawerNetworkDto: CreateSidedrawerNetworkDto = {
                  sidedrawerRole: <SidedrawerRoles>row.permission.key,
                  contributor: {
                    teamId: row.teamId,
                    email: row.email,
                    firstName: row.fullName,
                  },
                  relation: row.relation,
                  contributorType: row.contributorType,
                  expiryDate: row?.expiryDate ?? null,
                };

                const item: SdQueueItem = {
                  id: this.generateRandomId(),
                  operation$: this.networkService.createRecordNetworkForQueue(
                    this.sideDrawerId,
                    this.recordId,
                    this.recordId
                      ? createRecordNetworkDto
                      : createSidedrawerNetworkDto
                  ),
                  progress: 0,
                  state: 'pending',
                  itemType: 'network',
                  network: {
                    network: teamSelected
                      ? teamSelected
                      : createRecordNetworkDto,
                    uploadName: teamSelected
                      ? teamSelected.name
                      : row.fullName
                        ? row.fullName
                        : row.email,
                    permission: {
                      ...row.permission,
                      value: row.permission.key,
                    },
                    type: teamSelected
                      ? ContributorType.team
                      : ContributorType.account,
                  },
                };

                this.store.dispatch(QueueActions.itemAdded({ item }));
              });

              let status = false;
              if (arrayValidAndUniqueRows.length > 0) {
                this.store.dispatch(QueueActions.startProcess());
                status = true;
              }
              params.callback(rows, status);
            })
          )
        )
      )
  );

  constructor(
    private readonly store: Store<AppState>,
    private readonly networkService: NetworkService
  ) {
    super({
      rows: new Map<number, NewNetworkRow>(),
      status: false,
    });
  }

  get rows(): Map<number, NewNetworkRow> {
    return this.get().rows;
  }

  set rows(value: Map<number, NewNetworkRow>) {
    this.patchState({ rows: value });
  }

  get recordId(): string {
    return this.get().recordId;
  }

  set recordId(value: string) {
    this.patchState({ recordId: value });
  }

  public get resourceType(): NetworkResourceType {
    return this.get().resourceType;
  }

  get sideDrawerId(): string {
    return this.get().sideDrawerId;
  }

  set status(value: boolean) {
    this.patchState({ status: value });
  }

  private generateRandomId(): string {
    const envelopeId = [];
    const randomArray = window.crypto.getRandomValues(new Uint32Array(3));
    randomArray.forEach(randomNumber => {
      envelopeId.push(randomNumber.toString(16));
    });
    return envelopeId.join('-');
  }

  private getValidAndUniqueRows(
    rows: Map<number, NewNetworkRow>
  ): NewNetworkRow[] {
    const arrayValidRows = UtilsHelper.mapToArray<NewNetworkRow>(rows).filter(
      row => !(!row.email && !row.teamId)
    );

    const mapUniqueRows: Map<string, NewNetworkRow> = new Map();
    arrayValidRows.forEach(row => {
      mapUniqueRows.set(row.email ? row.email : row.teamId, row);
    });

    return UtilsHelper.mapToArray<NewNetworkRow>(mapUniqueRows);
  }

  private setStatusRows(): void {
    this.rows$
      .pipe(
        take(1),
        tap(rows => {
          this.status = this.getValidAndUniqueRows(rows).length > 0;
        })
      )
      .subscribe();
  }
}

export type NetworkOrTeamNetworkType = (
  | {
      network: Network;
      type: NetworkType;
    }
  | {
      network: TeamNetwork;
      type: NetworkType;
    }
)[];
