import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SdContentTemplateComponent } from './components/sd-content-template/sd-content-template.component';
import { RecordsTypeSwitcherModule } from '../../../records/shared/records-type-switcher/records-type-switcher.module';
import { SdProgressSpinnerA11yModule } from '../../sd-progress-spinner-a11y/sd-progress-spinner-a11y/sd-progress-spinner-a11y.module';

@NgModule({
  declarations: [SdContentTemplateComponent],
  exports: [SdContentTemplateComponent],
  imports: [
    CommonModule,
    SdProgressSpinnerA11yModule,
    RecordsTypeSwitcherModule,
  ],
})
export class SdContentTemplateModule {}
