import { createFeature, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { SdQueueItem } from '../models/sd-queue-item.model';
import { QueueActions } from './queue.actions';

export interface QueueState extends EntityState<SdQueueItem> {
  status: 'idle' | 'processing' | 'completed' | 'cancelled';
  windowStatus: 'hidden' | 'minimized' | 'maximized';
}

export const queueEntityAdapter = createEntityAdapter<SdQueueItem>({
  selectId: sdQueueItem => sdQueueItem.id,
});

export const initialQueueState: QueueState = queueEntityAdapter.getInitialState(
  {
    status: 'idle',
    windowStatus: 'hidden',
  }
);
export const queueFeature = createFeature({
  name: 'queue',
  reducer: createReducer(
    initialQueueState,
    on(QueueActions.itemAdded, (state, { item }) =>
      queueEntityAdapter.addOne(item, state)
    ),
    on(QueueActions.itemRemoved, (state, { id }) =>
      queueEntityAdapter.removeOne(id, state)
    ),
    on(QueueActions.itemCompletes, (state, { item }) =>
      queueEntityAdapter.updateOne(item, state)
    ),
    on(QueueActions.itemUpdate, (state, { item }) =>
      queueEntityAdapter.updateOne(item, state)
    ),
    on(QueueActions.resetProcess, () => initialQueueState),
    on(QueueActions.cancelProcess, state => ({
      ...state,
      status: 'cancelled' as const,
    })),
    on(QueueActions.processCompletes, state => ({
      ...state,
      status: 'completed' as const,
    })),
    on(QueueActions.widowStatusChange, (state, { windowStatus }) => ({
      ...state,
      windowStatus,
    }))
  ),
});

export const queueStateSelector = queueFeature.selectQueueState;
export const queueStatusSelector = queueFeature.selectStatus;
export const queueWindowsStatusSelector = queueFeature.selectWindowStatus;

export const { selectAll, selectEntities, selectTotal, selectIds } =
  queueEntityAdapter.getSelectors();
