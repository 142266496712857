import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { Actions, ofType } from '@ngrx/effects';
import { Update } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { asapScheduler, switchMap, take, tap } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { AppState } from 'src/app/reducers';
import { DateTimeRangeFormatted } from 'src/app/reminders/models/dateTimeRangeFormatted.model';
import { ErrorType } from 'src/app/reminders/models/enums/error-type.enum';
import { Frequency } from 'src/app/reminders/models/enums/frequency.enum';
import { ReminderStatus } from 'src/app/reminders/models/enums/reminder-status.enum';
import { Recurrent } from 'src/app/reminders/models/recurrent.model';
import { ReminderShortDictionary } from 'src/app/reminders/models/reminder-short-dictionary.model';
import { Reminders } from 'src/app/reminders/models/reminders.model';
import { Schedule } from 'src/app/reminders/models/schedule.model';
import { SfrError } from 'src/app/reminders/models/sfr-error.model';
import { To } from 'src/app/reminders/models/to.model';
import { RemindersService } from 'src/app/reminders/services/reminders.service';
import {
  ReminderAddError,
  ReminderCreated,
  ReminderCreateRequested,
  ReminderSetErrors,
  RemindersListActionsTypes,
  ReminderSpinnerChange,
  ReminderUpdated,
  ReminderUpdateRequested,
} from 'src/app/reminders/store/reminders-list.action';
import { Option } from 'src/app/shared/sd-forms/models/option.model';
import { RemindersFormInfoDialogComponent } from '../../../reminders-form-info-dialog/components/reminders-form-info-dialog/reminders-form-info-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DateHelper } from 'src/app/core/helpers/date.helper';
import { Locale } from 'src/app/dictionary/models/locale.model';

export interface RemindersFormState {
  savingInformation: boolean;
  reminder: Reminders;
  dateTimeRangeFormatted: DateTimeRangeFormatted;
  contactsSelected: Option[];
  activeReminder: boolean;
  form: UntypedFormGroup;
  monthSelectionOption: Option;
  reminderShortDictionary: ReminderShortDictionary;
  isDialog: boolean;
  resource: string;
  months: Option[];
  cancelRedirect: boolean;
  finished: boolean;
  reminderResponse: Reminders;
  localeDefault: Locale;
}

@Injectable()
export class RemindersFormStore extends ComponentStore<RemindersFormState> {
  readonly gettingInformation$ = this.select(state => state.savingInformation);
  readonly reminder$ = this.select(state => state.reminder);
  readonly dateTimeRangeFormatted$ = this.select(
    state => state.dateTimeRangeFormatted
  );
  readonly contactsSelected$ = this.select(state => state.contactsSelected);
  readonly activeReminder$ = this.select(state => state.activeReminder);
  readonly form$ = this.select(state => state.form);
  readonly monthSelectionOption$ = this.select(
    state => state.monthSelectionOption
  );
  readonly reminderShortDictionary$ = this.select(
    state => state.reminderShortDictionary
  );
  readonly isDialog$ = this.select(state => state.isDialog);
  readonly resource$ = this.select(state => state.resource);
  readonly finished$ = this.select(state => state.finished);
  readonly reminderResponse$ = this.select(state => state.reminderResponse);

  errors = new Map<string | ErrorType, SfrError>();
  readonly getReminderById = this.effect(
    (
      params$: Observable<{
        reminderId: string;
        callback: (reminder: Reminders) => void;
      }>
    ) =>
      params$.pipe(
        tap(() => {
          this.patchState({
            savingInformation: true,
          });
        }),
        switchMap(params =>
          this.reminderService.getOneReminder(params.reminderId).pipe(
            tapResponse(
              reminder => {
                this.patchState({
                  savingInformation: false,
                  reminder,
                });
                if (params.callback) {
                  params.callback(this.reminder);
                }
              },
              (error: HttpErrorResponse) => {
                this.errors.set(ErrorType.errorReminder, {
                  type: ErrorType.errorReminder,
                  statusCode: error?.error?.statusCode,
                });

                asapScheduler.schedule(() =>
                  this.store.dispatch(
                    new ReminderSpinnerChange({ state: false })
                  )
                );
                this.showDialogSuccessOrWithErrors();
              }
            )
          )
        )
      )
  );
  generateRecurrence$: Observable<ReminderUpdated | ReminderAddError>;

  constructor(
    private reminderService: RemindersService,
    private readonly store: Store<AppState>,
    private readonly dialog: MatDialog,
    private actions$: Actions
  ) {
    super({
      savingInformation: false,
      reminder: null,
      dateTimeRangeFormatted: {
        endDateFormatted: '',
        startDateFormatted: '',
        startDateFormattedLocal: '',
        endDateFormattedLocal: '',
      },
      contactsSelected: [],
      activeReminder: true,
      form: null,
      monthSelectionOption: null,
      reminderShortDictionary: null,
      isDialog: null,
      resource: null,
      months: [],
      cancelRedirect: false,
      finished: false,
      reminderResponse: null,
      localeDefault: null,
    });
  }

  get reminder(): Reminders {
    return this.get().reminder;
  }

  set reminder(reminder: Reminders) {
    this.patchState({
      reminder,
    });
  }

  set reminderResponse(reminderResponse: Reminders) {
    this.patchState({
      reminderResponse,
    });
  }

  get reminderResponse(): Reminders {
    return this.get().reminderResponse;
  }

  get savingInformation(): boolean {
    return this.get().savingInformation;
  }

  set savingInformation(savingInformation: boolean) {
    this.patchState({
      savingInformation,
    });
  }

  get isDialog(): boolean {
    return this.get().isDialog;
  }

  set isDialog(isDialog: boolean) {
    this.patchState({
      isDialog,
    });
  }

  get form(): UntypedFormGroup {
    return this.get().form;
  }

  get resource(): string {
    return this.get().resource;
  }

  set resource(resource: string) {
    this.patchState({
      resource,
    });
  }

  get dateTimeRangeFormatted(): DateTimeRangeFormatted {
    return this.get().dateTimeRangeFormatted;
  }

  set dateTimeRangeFormatted(dateTimeRangeFormatted: DateTimeRangeFormatted) {
    this.patchState({
      dateTimeRangeFormatted,
    });
  }

  get activeReminder(): boolean {
    return this.get().activeReminder;
  }

  set activeReminder(activeReminder: boolean) {
    this.patchState({
      activeReminder,
    });
  }

  get contactsSelected(): Option[] {
    return this.get().contactsSelected;
  }

  set contactsSelected(contactsSelected: Option[]) {
    this.patchState({
      contactsSelected,
    });
  }

  get monthSelectionOption(): Option {
    return this.get().monthSelectionOption;
  }

  set monthSelectionOption(monthSelectionOption: Option) {
    this.patchState({
      monthSelectionOption,
    });
  }

  get months(): Option[] {
    return this.get().months;
  }

  set months(months: Option[]) {
    this.patchState({
      months,
    });
  }

  get reminderShortDictionary(): ReminderShortDictionary {
    return this.get().reminderShortDictionary;
  }

  set reminderShortDictionary(
    reminderShortDictionary: ReminderShortDictionary
  ) {
    this.patchState({
      reminderShortDictionary,
    });
  }

  get cancelRedirect(): boolean {
    return this.get().cancelRedirect;
  }

  get finished(): boolean {
    return this.get().finished;
  }

  set finished(value: boolean) {
    this.patchState({ finished: value });
  }

  get localeDefault(): Locale {
    return this.get().localeDefault;
  }

  showDialogSuccessOrWithErrors(): void {
    if (this.cancelRedirect) {
      this.finished = true;
      return;
    }

    const dialogRef = this.dialog.open(RemindersFormInfoDialogComponent, {
      autoFocus: false,
      data: {
        errors: this.errors,
      },
    });
    dialogRef.afterClosed().subscribe((hasError: false) => {
      this.savingInformation = false;
      if (this.isDialog) {
        // emmit close dialog...
        return;
      }
      if (hasError) {
        this.handleErrors();
      }
    });
  }

  getPattern(forApi = true): string {
    if (
      this.form?.get('recurrenceController').value === Frequency.Daily ||
      this.form?.get('recurrenceController').value === Frequency.DoesNotRepeat
    ) {
      return '';
    }
    if (
      this.form?.get('recurrenceController').value !== Frequency.Daily &&
      this.form?.get('recurrenceController').value !== Frequency.Yearly
    ) {
      if (
        forApi &&
        this.form?.get('recurrenceController').value === Frequency.Weekly
      ) {
        const weeklyPatternInEnglish = DateHelper.getNamesDaysInEnglish(
          this.localeDefault,
          this.form?.get('patternController').value.split(', ')
        );
        return weeklyPatternInEnglish.join(', ');
      }

      return this.form?.get('patternController').value;
    }
    if (this.form?.get('recurrenceController').value === Frequency.Yearly) {
      if (forApi) {
        return `${this.form?.get('monthsController').value}/${
          this.form?.get('patternController').value
        }`;
      }
      if (!forApi) {
        if (this.monthSelectionOption?.value) {
          return `${this.monthSelectionOption?.value} ${
            this.form?.get('patternController').value
          }`;
        }
        const selectedMonthOption = this.months.find(
          element => element.key === this.form?.get('monthsController')?.value
        );
        if (selectedMonthOption) {
          return `${selectedMonthOption.value} ${
            this.form?.get('patternController').value
          }`;
        }
        return `${this.form?.get('monthsController').value}/${
          this.form?.get('patternController').value
        }`;
      }
    }
  }

  addOrUpdateReminder(
    callback?: (hasErrors: boolean, reminder?: Reminders) => void
  ): void {
    this.savingInformation = true;
    const reminder = this.generateReminderDTO();
    if (!reminder?.id) {
      asapScheduler.schedule(() =>
        this.store.dispatch(new ReminderCreateRequested({ reminder: reminder }))
      );
      this.actions$
        .pipe(
          ofType(
            RemindersListActionsTypes.ReminderCreated,
            RemindersListActionsTypes.ReminderAddError
          ),
          take(1),
          tap((action: ReminderCreated | ReminderAddError) => {
            if (action.type === RemindersListActionsTypes.ReminderAddError) {
              if (!!action.payload.errors && action.payload.errors.size > 0) {
                this.errors = new Map([
                  ...this.errors,
                  ...action.payload.errors,
                ]);
                asapScheduler.schedule(() =>
                  this.store.dispatch(
                    new ReminderSetErrors({ errors: new Map() })
                  )
                );
                asapScheduler.schedule(() =>
                  this.store.dispatch(
                    new ReminderSpinnerChange({ state: false })
                  )
                );
              }
            }

            if (action.type === RemindersListActionsTypes.ReminderCreated) {
              this.reminderResponse = action.payload?.reminder;

              this.errors = new Map([]);
              asapScheduler.schedule(() =>
                this.store.dispatch(new ReminderSpinnerChange({ state: false }))
              );
              asapScheduler.schedule(() =>
                this.store.dispatch(
                  new ReminderSetErrors({ errors: new Map() })
                )
              );
            }

            this.showDialogSuccessOrWithErrors();
            this.savingInformation = false;

            if (callback) {
              callback(this.hasErrors(), this.reminderResponse);
            }
          })
        )
        .subscribe();
    } else {
      const updatedReminder: Update<Reminders> = {
        id: reminder.id,
        changes: { ...reminder },
      };
      asapScheduler.schedule(() =>
        this.store.dispatch(
          new ReminderUpdateRequested({
            reminder: updatedReminder,
          })
        )
      );
      this.actions$
        .pipe(
          ofType(
            RemindersListActionsTypes.ReminderUpdated,
            RemindersListActionsTypes.ReminderAddError
          ),
          take(1),
          tap((action: ReminderUpdated | ReminderAddError) => {
            if (action.type === RemindersListActionsTypes.ReminderAddError) {
              if (!!action.payload.errors && action.payload.errors.size > 0) {
                this.errors = new Map([
                  ...this.errors,
                  ...action.payload.errors,
                ]);
                asapScheduler.schedule(() =>
                  this.store.dispatch(
                    new ReminderSetErrors({ errors: new Map() })
                  )
                );
                asapScheduler.schedule(() =>
                  this.store.dispatch(
                    new ReminderSpinnerChange({ state: false })
                  )
                );
              }
            }

            if (action.type === RemindersListActionsTypes.ReminderUpdated) {
              this.reminderResponse = {
                ...action.payload?.reminder.changes,
                id: action.payload?.reminder.id as string,
              };
              this.errors = new Map([]);
              asapScheduler.schedule(() =>
                this.store.dispatch(new ReminderSpinnerChange({ state: false }))
              );
              asapScheduler.schedule(() =>
                this.store.dispatch(
                  new ReminderSetErrors({ errors: new Map() })
                )
              );
            }

            this.showDialogSuccessOrWithErrors();
            this.savingInformation = false;
            if (callback) {
              callback(this.hasErrors());
            }
          })
        )
        .subscribe();
    }
  }

  getErrorMessageTime(controlName: string): string {
    if (this.form?.get(controlName).hasError('required')) {
      return this.reminderShortDictionary.remindersFormFieldMandatoryMessage;
    }
    if (this.form?.get(controlName).hasError('invalidTime')) {
      this.form?.controls['startDateController'].updateValueAndValidity();
      return this.reminderShortDictionary
        .remindersFormStartTimeMandatoryMessage;
    }
    this.form?.controls['startDateController'].updateValueAndValidity();
    return null;
  }

  getErrorMessageStartDate(controlName: string): string {
    if (this.form?.get(controlName).hasError('required')) {
      return this.reminderShortDictionary.remindersFormFieldMandatoryMessage;
    }
    if (this.form?.get(controlName).hasError('invalidDate')) {
      this.form?.controls['endDateController'].updateValueAndValidity();
      this.form?.controls['timeController'].updateValueAndValidity();
      return this.reminderShortDictionary.remindersFormEndDateMandatoryMessage;
    }
    this.form?.controls['timeController'].updateValueAndValidity();
    this.form?.controls['endDateController'].updateValueAndValidity();
    return null;
  }

  getErrorMessageEndDate(controlName: string): string {
    if (this.form?.get(controlName).hasError('required')) {
      return this.reminderShortDictionary.remindersFormFieldMandatoryMessage;
    }
    if (this.form?.get(controlName).hasError('invalidDate')) {
      this.form?.controls['startDateController'].updateValueAndValidity();
      return this.reminderShortDictionary.remindersFormEndDateMandatoryMessage;
    }
    this.form?.controls['startDateController'].updateValueAndValidity();
    return null;
  }

  private handleErrors(): void {
    let errorReminder: boolean;
    let errorRecord: boolean;
    this.errors.forEach((error, index) => {
      if (index === ErrorType.errorReminder) {
        errorReminder = true;
      }
      if (index === ErrorType.errorCollaboratorRecord) {
        errorRecord = true;
      }
    });
    if (errorRecord === true) {
      this.errors.delete(ErrorType.errorCollaboratorRecord);
    }
    if (errorReminder === true) {
      this.errors.delete(ErrorType.errorReminder);
    }
    return;
  }

  private generateReminderDTO(): Reminders {
    const reminder = new Reminders(
      this.form?.get('nameController').value,
      this.resource,
      this.form?.get('messageController').value,
      new Schedule(
        this.dateTimeRangeFormatted.startDateFormatted,
        this.activeReminder === true
          ? new Recurrent(
              this.form?.get('recurrenceController').value !==
              Frequency.DoesNotRepeat
                ? this.form.get('recurrenceController').value
                : null,
              this.form?.get('everyController').value
                ? Number(this.form.get('everyController').value)
                : 1,
              this.getPattern().replace(/\s/g, ''),
              this.form?.get('endDateController').value !==
                Frequency.DoesNotRepeat &&
              this.form?.get('endDateController').value !== null
                ? this.dateTimeRangeFormatted.endDateFormatted
                : null
            )
          : new Recurrent(null, 1)
      ),
      new To(
        this.contactsSelected
          .filter(cs => cs.key.indexOf('@') === -1)
          .map(c => c.key),
        this.contactsSelected
          .filter(cs => cs.key.indexOf('@') > 0)
          .map(c => c.value)
      ),
      this.reminder?.id ?? undefined,
      this.reminder?.status ?? ReminderStatus.Active
    );

    return {
      ...reminder,
      messageType:
        this.form?.get('recurrenceController').value !== Frequency.DoesNotRepeat
          ? 'scheduledReminder'
          : 'reminder',
    };
  }

  private hasErrors(): boolean {
    return this.errors?.size > 0;
  }
}
