import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Account } from '../models/account.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {
  AccountLoaded,
  AccountUpdated,
  SettingsLoaded,
} from '../store/account.actions';
import { Settings } from '../models/settings.model';
import { Mfa } from '../models/mfa.model';
import { AccountForm } from '../models/account-form.model';
import { authHeadersSelector } from '../../auth/store/auth.selectors';

@Injectable()
export class AccountService {
  private userApi = environment.userApi;
  private accountForm: AccountForm;

  constructor(
    private readonly store: Store<AppState>,
    private readonly http: HttpClient
  ) {}

  setCreateAccountInformation(accountForm: AccountForm): void {
    this.accountForm = { ...accountForm };
  }

  getCreateAccountInformation(): AccountForm {
    return this.accountForm;
  }

  getAccountByOpenId(openId: string): Observable<Account> {
    return this.http.get<Account>(this.userApi + `accounts/open-id/${openId}`, {
      headers: this.store.selectSignal(authHeadersSelector)(),
    });
  }

  getCustomerId(openId: string): Observable<string> {
    return this.http
      .get<Account>(this.userApi + `accounts/open-id/${openId}`, {
        headers: this.store.selectSignal(authHeadersSelector)(),
      })
      .pipe(
        map(response => {
          this.store.dispatch(new AccountLoaded({ data: response }));
          return response.customerId;
        })
      );
  }

  getAccountSettings(accountId: string): Observable<Settings> {
    return this.http.get<Settings>(
      this.userApi + `accounts/account-id/${accountId}/settings`,
      {
        headers: this.store.selectSignal(authHeadersSelector)(),
      }
    );
  }

  createMfaCode(
    accountId: string,
    mfa: Mfa,
    brandCode: string
  ): Observable<unknown> {
    return this.http.post(
      this.userApi +
        `accounts/account-id/${accountId}/mfa?brandCode=${brandCode}`,
      mfa,
      {
        headers: this.store.selectSignal(authHeadersSelector)(),
      }
    );
  }

  checkMfaCode(accountId: string, code: string): Observable<unknown> {
    return this.http.get(
      this.userApi + `accounts/account-id/${accountId}/mfa/mfa-code/${code}`,
      {
        headers: this.store.selectSignal(authHeadersSelector)(),
      }
    );
  }

  createAccount(
    newAccount: Account,
    brandCode: string,
    invitationCode?: string
  ): Observable<{ id: string }> {
    let body = {};
    if (invitationCode) {
      body = { ...body, invitationCode };
    }
    body = { ...body, ...newAccount, brandCodeAffiliations: [brandCode] };
    return this.http.post<{ id: string }>(
      this.userApi + `accounts?brandCode=${brandCode}`,
      body,
      {
        headers: this.store.selectSignal(authHeadersSelector)(),
      }
    );
  }

  sendInvitationCode(
    account: Account,
    invitationCode: string
  ): Observable<Account> {
    return this.http.put<Account>(
      this.userApi + `accounts/account-id/${account.id}`,
      { ...account, invitationCode },
      {
        headers: this.store.selectSignal(authHeadersSelector)(),
      }
    );
  }

  updateAccountSettings(
    account: Account,
    settings: Settings
  ): Observable<Settings> {
    return this.http
      .put<Settings>(
        this.userApi + `accounts/account-id/${account.id}/settings`,
        { ...settings },
        {
          headers: this.store.selectSignal(authHeadersSelector)(),
          observe: 'response',
        }
      )
      .pipe(
        map(response => {
          this.store.dispatch(new SettingsLoaded({ data: settings }));
          return response.body;
        })
      );
  }

  updateAccountSettingsV2(
    account: Account,
    settings: Settings
  ): Observable<Settings> {
    return this.http.put<Settings>(
      this.userApi + `accounts/account-id/${account.id}/settings`,
      { ...settings },
      {
        headers: this.store.selectSignal(authHeadersSelector)(),
      }
    );
  }

  updateAccount(account: Account): Observable<Account> {
    const dto: Account = { ...account };
    delete dto.region;
    if (!account.phones || account.phones?.length === 0) {
      delete dto.phones;
    }
    if (!account.maritalStatus) {
      delete dto.maritalStatus;
    }
    if (!account.gender) {
      delete dto.gender;
    }
    return this.http
      .put<Account>(
        this.userApi + `accounts/account-id/${account.id}`,
        { ...dto },
        {
          headers: this.store.selectSignal(authHeadersSelector)(),
          observe: 'response',
        }
      )
      .pipe(
        map(response => {
          this.store.dispatch(new AccountUpdated({ data: account }));
          return response.body;
        })
      );
  }

  checkUserName(userName: string): Observable<string> {
    return this.http.get<string>(
      this.userApi + `accounts/username/${userName}/check`,
      {
        headers: this.store.selectSignal(authHeadersSelector)(),
      }
    );
  }

  getIntercomHash(email: string): Observable<{ hash: string }> {
    return this.http.post<{ hash: string }>(
      environment.intercomHashUrl,
      { email },
      {
        headers: this.store.selectSignal(authHeadersSelector)(),
      }
    );
  }
}
