import { ActionReducerMap } from '@ngrx/store';
import { searchBarReducer, SearchBarState } from './search-bar.reducer';
import {
  recordListReducer,
  SearchRecordListState,
} from './search-record-list.reducer';
import {
  SearchSideDrawerListState,
  sideDrawerListReducer,
} from './search-sidedrawer-list.reducer';
import {
  searchSideDrawerRequestsListReducer,
  SearchSideDrawerRequestsListState,
} from './search-sidedrawer-requests-list.reducer';
import {
  fileListReducer,
  SearchFileListState,
} from './search-file-list.reducer';

export const featureName = 'search-module';

export interface SearchState {
  records: SearchRecordListState;
  searchbar: SearchBarState;
  sidedrawer: SearchSideDrawerListState;
  file: SearchFileListState;
  requestsForSideDrawer: SearchSideDrawerRequestsListState;
}

export const searchReducer: ActionReducerMap<SearchState> = {
  records: recordListReducer,
  searchbar: searchBarReducer,
  sidedrawer: sideDrawerListReducer,
  file: fileListReducer,
  requestsForSideDrawer: searchSideDrawerRequestsListReducer,
};
