import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PaginatorService } from '../../core/services/paginator.service';
import {
  SideDrawerNetworksInitialPageLoaded,
  SideDrawerNetworksRequestsActionsTypes,
} from './side-drawer-networks-requests.actions';
import { forkJoin, of, switchMap, tap } from 'rxjs';
import { accountDataBaseRegionSelector } from '../../account/store/account.selector';
import { catchError, map, take } from 'rxjs/operators';
import { SideDrawerNetwork } from '../models/side-drawer-network.model';
import { SideDrawerNetworksHelper } from '../helpers/side-drawer-networks.helper';
import { SideDrawerNetworkRequestType } from '../models/side-drawer-network-request-type.enum';
import { SideDrawerNetworksRequest } from '../models/side-drawer-network-request.model';
import { ErrorLoaded } from '../../core/store/core.actions';
import { sideDrawerNetworkListSelector } from './side-drawer-network-list.selectors';
import { SideDrawerNetworkLoaded } from './side-drawer-network-list.actions';
import {
  GettingSideDrawerNetworksRequestsInfiniteListChange,
  SideDrawerNetworksInfiniteListInitialPageLoaded,
  SideDrawerNetworksInfiniteListNextPageRequested,
  SideDrawerNetworksInfiniteListPageLoaded,
  SideDrawerNetworksInfiniteListRequested,
  SideDrawerNetworksRequestsInfiniteListActionsTypes,
} from './side-drawer-networks-requests-infinite-list.actions';
import {
  sideDrawerNetworksInfiniteListItemsPerPageSelector,
  sideDrawerNetworksInfiniteListLastRequestIdSelector,
  sideDrawerNetworksInfiniteListOrderNextIdSelector,
  sideDrawerNetworksRequestInfiniteListEntityByIdSelector,
} from './side-drawer-networks-requests-infinite-list.selectors';

@Injectable()
export class SideDrawerNetworksRequestsInfiniteListEffects {
  SideDrawerNetworksInfiniteListRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SideDrawerNetworksInfiniteListRequested>(
        SideDrawerNetworksRequestsInfiniteListActionsTypes.SideDrawerNetworksInfiniteListRequested
      ),
      tap(() =>
        this.store.dispatch(
          new GettingSideDrawerNetworksRequestsInfiniteListChange({
            state: true,
          })
        )
      ),
      switchMap(action =>
        forkJoin([
          this.store.pipe(select(accountDataBaseRegionSelector), take(1)),
          this.store.pipe(
            select(
              sideDrawerNetworksRequestInfiniteListEntityByIdSelector({
                id: action.payload?.requestId,
              })
            ),
            take(1)
          ),
        ])
      ),
      switchMap(([dataBaseRegion, requestRequired]) =>
        this.paginatorService
          .getPaginatedResource<SideDrawerNetwork>(
            SideDrawerNetworksHelper.getSideDrawerNetworksResourceUrl(
              requestRequired?.requestType ??
                SideDrawerNetworkRequestType.owned,
              {
                dataBaseRegion:
                  requestRequired?.dataBaseRegion ?? dataBaseRegion,
                nextPage: requestRequired?.nextPage ?? null,
              }
            )
          )
          .pipe(
            map(response => {
              return new SideDrawerNetworksInfiniteListPageLoaded({
                result: SideDrawerNetworksRequest.fromPaginatedResponse(
                  response,
                  requestRequired?.dataBaseRegion ?? dataBaseRegion,
                  requestRequired?.requestType ??
                    SideDrawerNetworkRequestType.owned
                ),
              });
            }),
            catchError(error =>
              of(new ErrorLoaded({ httpError: error, display404: true }))
            )
          )
      ),
      tap(() =>
        this.store.dispatch(
          new GettingSideDrawerNetworksRequestsInfiniteListChange({
            state: false,
          })
        )
      )
    )
  );

  SideDrawerNetworksInfiniteListPageLoaded$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SideDrawerNetworksInfiniteListPageLoaded>(
        SideDrawerNetworksRequestsInfiniteListActionsTypes.SideDrawerNetworksInfiniteListPageLoaded
      ),
      switchMap(action =>
        forkJoin([
          this.store.pipe(select(sideDrawerNetworkListSelector), take(1)),
          of(action.payload.result),
          this.store.pipe(
            select(sideDrawerNetworksInfiniteListItemsPerPageSelector),
            take(1)
          ),
        ])
      ),
      map(
        ([sideDrawerNetworkList, sideDrawerNetworksRequest, itemsPerPage]) => {
          const sideDrawerNetworks =
            SideDrawerNetworksHelper.filterDuplicatedSdNetworks(
              sideDrawerNetworkList,
              sideDrawerNetworksRequest.data
            );
          if (sideDrawerNetworks.length < itemsPerPage) {
            this.store.dispatch(
              new SideDrawerNetworksInfiniteListNextPageRequested()
            );
          }
          return new SideDrawerNetworkLoaded({
            networks: sideDrawerNetworks,
          });
        }
      )
    )
  );

  SideDrawerNetworksInfiniteListNextPageRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SideDrawerNetworksInfiniteListNextPageRequested>(
        SideDrawerNetworksRequestsInfiniteListActionsTypes.SideDrawerNetworksInfiniteListNextPageRequested
      ),
      switchMap(() =>
        this.store.pipe(
          select(sideDrawerNetworksInfiniteListLastRequestIdSelector),
          take(1)
        )
      ),
      switchMap(lastRequestId =>
        forkJoin([
          this.store.pipe(
            select(
              sideDrawerNetworksRequestInfiniteListEntityByIdSelector({
                id: lastRequestId,
              })
            ),
            take(1)
          ),
          this.store.pipe(
            select(
              sideDrawerNetworksInfiniteListOrderNextIdSelector({
                currentId: lastRequestId,
              })
            ),
            take(1)
          ),
        ])
      ),
      map(([lastRequest, nextRequestId]) => {
        // NOTE we need to load the next request page
        if (lastRequest.hasMore) {
          return new SideDrawerNetworksInfiniteListRequested({
            requestId: lastRequest.id,
          });
        }
        // NOTE next request by role-region, for example: owned-ru, owned-us, owned-eu
        if (nextRequestId) {
          return new SideDrawerNetworksInfiniteListRequested({
            requestId: nextRequestId,
          });
        }
        return new GettingSideDrawerNetworksRequestsInfiniteListChange({
          state: false,
        });
      })
    )
  );

  SideDrawerNetworksInitialPageLoaded$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SideDrawerNetworksInitialPageLoaded>(
        SideDrawerNetworksRequestsActionsTypes.SideDrawerNetworksInitialPageLoaded
      ),
      map(
        action =>
          new SideDrawerNetworksInfiniteListInitialPageLoaded({
            result: { ...action.payload.result, hasMore: true },
          })
      )
    )
  );

  constructor(
    private readonly store: Store<AppState>,
    private readonly actions$: Actions,
    private readonly paginatorService: PaginatorService
  ) {}
}
