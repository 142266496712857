<button
  *ngIf="!viewOnly; else viewOnlyTemplate"
  #matTooltip="matTooltip"
  (click)="!disabled ? btnClicked.emit(true) : null"
  (mouseleave)="!!tooltip ? matTooltip.hide() : ''"
  [attr.aria-label]="sdAccessibility?.ariaLabel"
  [disableRipple]="disableRipple"
  [disabled]="disabled"
  [matTooltip]="!!tooltip ? tooltip : ''"
  [ngClass]="{
    disabled: disabled,
    rounded: !squareBackground,
    primary: primary,
    secondary: !primary,
    transparent: transparentBackground,
    backgroundColor: openedState
  }"
  [ngStyle]="{
    width: buttonWidth + 'rem',
    height: buttonHeight + 'rem'
  }"
  [tabIndex]="sdAccessibility?.tabIndex"
  class="sd-icon-button sd-iconEnabled-button"
  mat-button>
  <app-sd-svg-a11y
    *ngIf="!spinner"
    [color]="
      iconColor
        ? iconColor
        : primary
          ? sdColorPalette.secondaryAccentColor
          : sdColorPalette.primaryColor
    "
    [height]="!!iconHeight ? iconHeight : 0.938"
    [setSdAccessibility]="{ tabIndex: -1 }"
    [src]="icon"
    [width]="!!iconWidth ? iconWidth : 0.938"></app-sd-svg-a11y>
  <mat-spinner
    *ngIf="!!spinner"
    [mode]="spinnerMode"
    [value]="spinnerProgress"
    diameter="20"></mat-spinner>
</button>

<ng-template #viewOnlyTemplate>
  <div
    [attr.aria-label]="sdAccessibility?.ariaLabel"
    [attr.role]="sdAccessibility?.role ?? 'img'"
    [ngStyle]="{ width: buttonWidth + 'rem', height: buttonHeight + 'rem' }"
    class="sd-icon-button"
    [ngClass]="{
      disabled: disabled,
      rounded: !squareBackground,
      primary: primary,
      secondary: !primary,
      transparent: transparentBackground
    }"
    #matTooltip="matTooltip"
    [matTooltip]="!!tooltip ? tooltip : ''"
    (mouseleave)="!!tooltip ? matTooltip.hide() : ''">
    <app-sd-svg-a11y
      *ngIf="!spinner"
      [color]="
        iconColor
          ? iconColor
          : primary
            ? sdColorPalette.secondaryAccentColor
            : sdColorPalette.primaryColor
      "
      [setSdAccessibility]="{ tabIndex: -1 }"
      [height]="!!iconHeight ? iconHeight : 0.938"
      [width]="!!iconWidth ? iconWidth : 0.938"
      [src]="icon"></app-sd-svg-a11y>
  </div>
</ng-template>
