export interface TdVcard {
  ACF2: string;
  about: string;
  accountApplicationcd: string;
  active: boolean;
  address1: string;
  address2: string;
  advisorCode: string;
  businessUnit: string;
  city: string;
  country: string;
  designations: string;
  email: string;
  fax: string;
  firstName: string;
  groupName: string;
  lastName: string;
  lob: string;
  mobile: string;
  phone: string;
  photo: string;
  postalCode: string;
  state: string;
  title: string;
  url: string;
  _id: string;
  contactLinks?: ContactLinks[];
}

export class ContactLinks {
  icon?: string;
  label?: string;
  link?: string;
  orderId?: number;
  type?: string;
}

export class TdVcardClass implements TdVcard {
  constructor(
    public ACF2: string,
    public about: string,
    public accountApplicationcd: string,
    public active: boolean,
    public address1: string,
    public address2: string,
    public advisorCode: string,
    public businessUnit: string,
    public city: string,
    public country: string,
    public designations: string,
    public email: string,
    public fax: string,
    public firstName: string,
    public groupName: string,
    public lastName: string,
    public lob: string,
    public mobile: string,
    public phone: string,
    public photo: string,
    public postalCode: string,
    public state: string,
    public title: string,
    public url: string,
    public _id: string,
    public contactLinks?: ContactLinks[]
  ) {}

  public static fromDictionary(tdVcard: TdVcard): TdVcard {
    tdVcard = { ...tdVcard, contactLinks: this.mapContactLinks(tdVcard) };
    return tdVcard;
  }

  public static mapContactLinks(tdVcard: TdVcard): ContactLinks[] {
    const newContactLinks = [];
    if (tdVcard.url.trim().length > 0) {
      const contactlink = new ContactLinks();
      contactlink.icon = 'https://cdn.sidedrawer.com/svgs/globe-regular.svg';
      contactlink.label = tdVcard.url;
      contactlink.link = tdVcard.url;
      newContactLinks.push(contactlink);
    }
    if (tdVcard.phone.trim().length > 0) {
      const contactlink = new ContactLinks();
      contactlink.icon =
        'https://cdn.sidedrawer.com/svgs/phone-alt-regular.svg';
      contactlink.label = tdVcard.phone;
      contactlink.link = tdVcard.phone;
      newContactLinks.push(contactlink);
    }
    if (tdVcard.fax.trim().length > 0) {
      const contactlink = new ContactLinks();
      contactlink.icon =
        'https://cdn.sidedrawer.com/svgs/phone-alt-regular.svg';
      contactlink.label = tdVcard.fax;
      contactlink.link = tdVcard.fax;
      newContactLinks.push(contactlink);
    }
    if (tdVcard.mobile.trim().length > 0) {
      const contactlink = new ContactLinks();
      contactlink.icon =
        'https://cdn.sidedrawer.com/svgs/phone-alt-regular.svg';
      contactlink.label = tdVcard.mobile;
      contactlink.link = tdVcard.mobile;
      newContactLinks.push(contactlink);
    }
    if (tdVcard.email.trim().length > 0) {
      const contactlink = new ContactLinks();
      contactlink.icon = 'https://cdn.sidedrawer.com/svgs/envelope-regular.svg';
      contactlink.label = tdVcard.email;
      contactlink.link = tdVcard.email;
      newContactLinks.push(contactlink);
    }

    return newContactLinks;
  }
}
