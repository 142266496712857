import { Action } from '@ngrx/store';
import { SpecificField } from '../models/specific-field.model';

export enum SpecificFieldsActionsTypes {
  RecordSubtypeSpecificFieldsRequested = '[Record Subtype Autocomplete] Records SubTypes Specific Fields Requested',
  RecordSubtypeSpecificFieldsLoaded = '[Specific Fields Effects] Records SubTypes Specific Fields Loaded',
  ClearRecordSubTypeSpecificFields = '[Record Subtype Autocomplete] Clear Records Specific Fields SubTypes',
  GettingRecordSubTypeSpecificFields = '[Specific Fields Effects] Getting Records Specific Fields SubTypes',
}

export class RecordSubtypeSpecificFieldsRequested implements Action {
  readonly type =
    SpecificFieldsActionsTypes.RecordSubtypeSpecificFieldsRequested;

  constructor(
    public payload: {
      recordSubTypeName: string;
    }
  ) {}
}

export class RecordSubtypeSpecificFieldsLoaded implements Action {
  readonly type = SpecificFieldsActionsTypes.RecordSubtypeSpecificFieldsLoaded;

  constructor(public payload: { specificFields: SpecificField[] }) {}
}

export class GettingRecordSubTypeSpecificFields implements Action {
  readonly type = SpecificFieldsActionsTypes.GettingRecordSubTypeSpecificFields;

  constructor(public payload: { state: boolean }) {}
}

export class ClearRecordSubTypeSpecificFields implements Action {
  readonly type = SpecificFieldsActionsTypes.ClearRecordSubTypeSpecificFields;
}

export type SpecificFieldsActions =
  | RecordSubtypeSpecificFieldsRequested
  | RecordSubtypeSpecificFieldsLoaded
  | GettingRecordSubTypeSpecificFields
  | ClearRecordSubTypeSpecificFields;
