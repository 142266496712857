<div
  [ngClass]="
    disabled ? 'sync-from-cloud-item disabled' : 'sync-from-cloud-item'
  "
  class="sync-from-cloud-item">
  <div
    (click)="
      driveItem?.type === 'file'
        ? checkboxClicked.emit(true)
        : folderClicked.emit(true)
    "
    (keyup)="
      driveItem?.type === 'file'
        ? checkboxClicked.emit(true)
        : folderClicked.emit(true)
    "
    class="sync-from-cloud-item-image">
    <app-sd-svg-a11y
      *ngIf="driveItem?.type === 'file'"
      [color]="primaryColor"
      [height]="1.625"
      [src]="cdn + ('globalparams_fileicon' | dictionary | async)"
      [width]="1.625" />

    <app-sd-svg-a11y
      *ngIf="driveItem?.type !== 'file'"
      [color]="primaryColor"
      [height]="1.625"
      [src]="cdn + ('globalparams_foldericon' | dictionary | async)"
      [width]="1.625" />
  </div>

  <div
    (click)="
      driveItem?.type === 'file'
        ? checkboxClicked.emit(true)
        : folderClicked.emit(true)
    "
    (keyup)="
      driveItem?.type === 'file'
        ? checkboxClicked.emit(true)
        : folderClicked.emit(true)
    "
    class="sync-from-cloud-item-name">
    {{ driveItem?.name }}
  </div>

  <div
    *ngIf="driveItem?.type === 'file' || driveItem?.type === 'folder'"
    class="sync-from-cloud-item-check-box">
    <button (click)="checkboxClicked.emit(true)" mat-button>
      <div *ngIf="selected" class="sync-from-cloud-item-check-box-selected">
        <mat-icon>check</mat-icon>
      </div>
      <div
        *ngIf="!selected"
        class="sync-from-cloud-item-check-box-not-selected"></div>
    </button>
  </div>
</div>
