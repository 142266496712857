import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SdFlatButtonA11yModule } from '../sd-flat-button-a11y/sd-flat-button-a11y.module';
import { SdContentHeaderA11yComponent } from './components/sd-content-header-a11y/sd-content-header-a11y.component';
import { DictionaryPipeModule } from 'src/app/dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { SdProgressSpinnerA11yModule } from '../sd-progress-spinner-a11y/sd-progress-spinner-a11y/sd-progress-spinner-a11y.module';
import { SdSortButtonA11yComponent } from '../sd-sort-button-a11y/components/sd-sort-button-a11y/sd-sort-button-a11y.component';
import { SdLinkMenuA11yModule } from '../sd-link-menu-a11y/sd-link-menu-a11y.module';
import { SdIconButtonA11yComponent } from '../sd-icon-button-a11y/components/sd-icon-button-a11y/sd-icon-button-a11y.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SdSlideToggleA11yModule } from '../sd-slide-toggle-a11y/sd-slide-toggle-a11y.module';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [SdContentHeaderA11yComponent],
  exports: [SdContentHeaderA11yComponent],
  imports: [
    CommonModule,
    SdFlatButtonA11yModule,
    MatProgressSpinnerModule,
    SdIconButtonA11yComponent,
    DictionaryPipeModule,
    SdProgressSpinnerA11yModule,
    SdSortButtonA11yComponent,
    SdLinkMenuA11yModule,
    SdSlideToggleA11yModule,
    MatTooltipModule,
  ],
})
export class SdContentHeaderA11yModule {}
