import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { ErrorType } from '../models/enums/error-type.enum';
import { Reminders } from '../models/reminders.model';
import { SfrError } from '../models/sfr-error.model';

export enum RemindersListActionsTypes {
  RemindersWithNoResourceRequested = '[] Reminders With No Resource Requested',
  RemindersRequested = '[] Reminders Requested',
  RemindersLoaded = '[Reminders effects] Reminders Loaded',
  ReminderChangeStatusRequested = '[Reminders Change Status Dialog] Reminder Change Status Requested',
  ReminderChangeStatus = '[Reminders Change Status Dialog] Reminder Change Status Success',
  ReminderSpinnerChange = '[Reminder Service] Reminder spinner change',
  ReminderRemovedAll = '[Reminders List Component] Reminder Remove All',
  ReminderCreateRequested = '[Reminders effects] Reminder Create Requested',
  ReminderCreated = '[Reminders effects] Reminder Added Success',
  ReminderUpdateRequested = '[Reminders effects] Reminder Update Requested',
  ReminderUpdated = '[Reminders effects] Reminder Updated Success',
  ReminderDeleteRequested = '[Reminders effects] Reminder Delete Requested',
  ReminderDeleted = '[Reminders effects] Reminder Deleted Success',
  ReminderAddError = '[Reminders] Reminder Add Error',
  ReminderSetErrors = '[Reminders] Reminder Set Errors',
  ReminderGetReminderByIdRequested = '[Reminders effects] Reminder Get Reminder By Id Requested',
  ReminderGetReminderByIdSuccess = '[Reminders effects] Reminder Get Reminder By Id Success',
  ReminderCopyLinkRequested = '[Reminders effects] Reminder Copy Link Requested',
  FilingCabinetAllRemindersRequested = '[Reminders List a11y] Filing Cabinet All Reminders Requested',
}

export class RemindersWithNoResourceRequested implements Action {
  readonly type = RemindersListActionsTypes.RemindersWithNoResourceRequested;

  constructor(public payload?: { recordId: string }) {}
}

export class ReminderCreateRequested implements Action {
  readonly type = RemindersListActionsTypes.ReminderCreateRequested;

  constructor(public payload: { reminder: Reminders }) {}
}

export class ReminderUpdateRequested implements Action {
  readonly type = RemindersListActionsTypes.ReminderUpdateRequested;

  constructor(
    public payload: {
      reminder: Update<Reminders>;
    }
  ) {}
}

export class ReminderAddError implements Action {
  readonly type = RemindersListActionsTypes.ReminderAddError;

  constructor(public payload: { errors: Map<string | ErrorType, SfrError> }) {}
}

export class ReminderSetErrors implements Action {
  readonly type = RemindersListActionsTypes.ReminderSetErrors;

  constructor(public payload: { errors: Map<string | ErrorType, SfrError> }) {}
}

export class RemindersRequested implements Action {
  readonly type = RemindersListActionsTypes.RemindersRequested;

  constructor(public payload: { resource: string; sideDrawerId: string }) {}
}

export class RemindersLoaded implements Action {
  readonly type = RemindersListActionsTypes.RemindersLoaded;

  constructor(public payload: { reminders: Reminders[] }) {}
}

export class ReminderCreated implements Action {
  readonly type = RemindersListActionsTypes.ReminderCreated;

  constructor(public payload: { reminder: Reminders }) {}
}

export class ReminderUpdated implements Action {
  readonly type = RemindersListActionsTypes.ReminderUpdated;

  constructor(
    public payload: {
      reminder: Update<Reminders>;
    }
  ) {}
}

export class ReminderDeleteRequested implements Action {
  readonly type = RemindersListActionsTypes.ReminderDeleteRequested;

  constructor(public payload: { id: string }) {}
}

export class ReminderDeleted implements Action {
  readonly type = RemindersListActionsTypes.ReminderDeleted;

  constructor(public payload: { id: string }) {}
}

export class ReminderChangeStatusRequested implements Action {
  readonly type = RemindersListActionsTypes.ReminderChangeStatusRequested;

  constructor(
    public payload: { reminder: Reminders; id: string; status: string }
  ) {}
}

export class ReminderChangeStatus implements Action {
  readonly type = RemindersListActionsTypes.ReminderChangeStatus;

  constructor(public payload: { reminder: Update<Reminders> }) {}
}

export class ReminderSpinnerChange implements Action {
  readonly type = RemindersListActionsTypes.ReminderSpinnerChange;

  constructor(public payload: { state: boolean }) {}
}

export class ReminderRemovedAll implements Action {
  readonly type = RemindersListActionsTypes.ReminderRemovedAll;
}

export class ReminderGetReminderByIdRequested implements Action {
  readonly type = RemindersListActionsTypes.ReminderGetReminderByIdRequested;

  constructor(public payload: { id: string }) {}
}

export class ReminderGetReminderByIdSuccess implements Action {
  readonly type = RemindersListActionsTypes.ReminderGetReminderByIdSuccess;

  constructor(public payload: { reminder: Reminders }) {}
}

export class ReminderCopyLinkRequested implements Action {
  readonly type = RemindersListActionsTypes.ReminderCopyLinkRequested;

  constructor(public payload: { reminder: Reminders; sideDrawerId: string }) {}
}
export class FilingCabinetAllRemindersRequested implements Action {
  readonly type = RemindersListActionsTypes.FilingCabinetAllRemindersRequested;
}

export type RemindersListActions =
  | RemindersWithNoResourceRequested
  | RemindersRequested
  | RemindersLoaded
  | ReminderChangeStatusRequested
  | ReminderSpinnerChange
  | ReminderRemovedAll
  | ReminderCreated
  | ReminderDeleted
  | ReminderUpdated
  | ReminderChangeStatus
  | ReminderCreateRequested
  | ReminderUpdateRequested
  | ReminderAddError
  | ReminderSetErrors
  | ReminderDeleteRequested
  | ReminderGetReminderByIdSuccess
  | ReminderGetReminderByIdRequested
  | ReminderCopyLinkRequested
  | FilingCabinetAllRemindersRequested;
