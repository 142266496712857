import {
  createActionGroup,
  createFeature,
  createReducer,
  createSelector,
  emptyProps,
  on,
  props,
} from '@ngrx/store';
import { Tenant } from '../model/tenant.model';
import { HttpErrorResponse } from '@angular/common/http';
import { Option } from '../../shared/sd-forms/models/option.model';

export const TenantsActions = createActionGroup({
  source: 'tenants',
  events: {
    Requested: emptyProps(),
    Loaded: props<{ tenants: Tenant[] }>(),
    Error: props<{ httpError: HttpErrorResponse }>(),
    'Set Active Tenant': props<{ tenant: Tenant }>(),
  },
});

interface TenantsState {
  gettingTenants: boolean;
  tenants: Tenant[];
  activeTenant: Tenant;
  httpError: HttpErrorResponse;
}

const initialState: TenantsState = {
  gettingTenants: false,
  tenants: [],
  activeTenant: null,
  httpError: null,
};

export const tenantsFeature = createFeature({
  name: 'tenants',
  reducer: createReducer(
    initialState,
    on(TenantsActions.requested, state => ({
      ...state,
      gettingTenants: true,
    })),
    on(TenantsActions.loaded, (state, { tenants }) => ({
      ...state,
      gettingTenants: false,
      tenants,
    })),
    on(TenantsActions.error, (state, { httpError }) => ({
      ...state,
      gettingTenants: false,
      httpError,
    })),
    on(TenantsActions.setActiveTenant, (state, { tenant }) => ({
      ...state,
      activeTenant: tenant,
    }))
  ),
});

export const gettingTenantsSelector = tenantsFeature.selectGettingTenants;
export const tenantsSelector = tenantsFeature.selectTenants;
export const tenantsAsOptionsSelector = createSelector(
  tenantsSelector,
  tenants =>
    tenants.map(
      tenant =>
        new Option(
          tenant.id,
          tenant.name,
          tenant.logoUrl,
          tenant.defaultBrandCode
        )
    )
);

export const tenantByIdSelector = (payload: { tenantId: string }) =>
  createSelector(tenantsSelector, tenants =>
    tenants.find(tenant => tenant.id === payload.tenantId)
  );
export const activeTenantSelector = tenantsFeature.selectActiveTenant;
