import { Action } from '@ngrx/store';
import { SideDrawerNetworksRequest } from 'src/app/sidedrawer-networks/models/side-drawer-network-request.model';

export enum SearchSideDrawerRequestsListActionsTypes {
  SearchSideDrawerRequestListInitialPageLoaded = '[Search Sidedrawer List Effects] Search SideDrawer Request List Initial Page Loaded',
  SearchSideDrawerRequestListPageLoaded = '[Search Sidedrawer List Effects] Search SideDrawer Request List Page Loaded',
}

export class SearchSideDrawerRequestListInitialPageLoaded implements Action {
  readonly type =
    SearchSideDrawerRequestsListActionsTypes.SearchSideDrawerRequestListInitialPageLoaded;

  constructor(public payload: { result: SideDrawerNetworksRequest }) {}
}

export class SearchSideDrawerRequestListPageLoaded implements Action {
  readonly type =
    SearchSideDrawerRequestsListActionsTypes.SearchSideDrawerRequestListPageLoaded;

  constructor(public payload: { result: SideDrawerNetworksRequest }) {}
}

export type SearchSideDrawerRequestsListActions =
  | SearchSideDrawerRequestListInitialPageLoaded
  | SearchSideDrawerRequestListPageLoaded;
