import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeleteRecordDialogComponent } from './components/delete-record-dialog/delete-record-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { SdButtonsModule } from '../../../shared/sd-buttons/sd-buttons.module';
import { DictionaryPipeModule } from 'src/app/dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { SdDialogTemplateA11yModule } from 'src/app/shared/templates/dialog-template-a11y/sd-dialog-template-a11y.module';
import { SdFlatButtonA11yModule } from 'src/app/shared/sd-flat-button-a11y/sd-flat-button-a11y.module';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [DeleteRecordDialogComponent],
  exports: [DeleteRecordDialogComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    SdButtonsModule,
    MatProgressSpinnerModule,
    DictionaryPipeModule,
    SdDialogTemplateA11yModule,
    SdFlatButtonA11yModule,
  ],
})
export class DeleteRecordDialogModule {}
