import {
  NetworksQueueActions,
  NetworksQueueActionsTypes,
} from './network-queue-actions';

export interface NetworksQueueState {
  getNewNetworksRelations: boolean;
}

export const initialNetworksQueueState: NetworksQueueState = {
  getNewNetworksRelations: false,
};

export function networksQueueReducer(
  state: NetworksQueueState = initialNetworksQueueState,
  action: NetworksQueueActions
): NetworksQueueState {
  switch (action.type) {
    case NetworksQueueActionsTypes.GetNewNetworksRelations:
      return {
        ...state,
        getNewNetworksRelations: action.payload.state,
      };
    default:
      return state;
  }
}
