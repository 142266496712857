import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { SideDrawerNetworksRequest } from 'src/app/sidedrawer-networks/models/side-drawer-network-request.model';
import {
  SearchSideDrawerRequestsListActions,
  SearchSideDrawerRequestsListActionsTypes,
} from './search-sidedrawer-requests-list.actions';

export interface SearchSideDrawerRequestsListState
  extends EntityState<SideDrawerNetworksRequest> {
  gettingSideDrawerNetworks: boolean;
  itemsPerPage: number;
  listOrder: string[];
  lastRequestId: string;
}

export const searchSideDrawerRequestsListAdapter: EntityAdapter<SideDrawerNetworksRequest> =
  createEntityAdapter<SideDrawerNetworksRequest>({
    selectId: sdNetworkRequest => sdNetworkRequest.id,
  });

export const initialSearchSideDrawerRequestsListState: SearchSideDrawerRequestsListState =
  searchSideDrawerRequestsListAdapter.getInitialState({
    gettingSideDrawerNetworks: false,
    itemsPerPage: 20,
    listOrder: [],
    lastRequestId: null,
  });

export function searchSideDrawerRequestsListReducer(
  state: SearchSideDrawerRequestsListState = initialSearchSideDrawerRequestsListState,
  action: SearchSideDrawerRequestsListActions
): SearchSideDrawerRequestsListState {
  switch (action.type) {
    case SearchSideDrawerRequestsListActionsTypes.SearchSideDrawerRequestListInitialPageLoaded:
    case SearchSideDrawerRequestsListActionsTypes.SearchSideDrawerRequestListPageLoaded:
      return {
        ...searchSideDrawerRequestsListAdapter.upsertOne(
          action.payload?.result,
          state
        ),
        lastRequestId: action.payload?.result.id,
      };
    default:
      return state;
  }
}

export const { selectAll, selectEntities } =
  searchSideDrawerRequestsListAdapter.getSelectors();
