import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SdSvgA11yComponent } from './components/sd-svg-a11y/sd-svg-a11y.component';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [SdSvgA11yComponent],
  imports: [CommonModule, MatBadgeModule, MatTooltipModule],
  exports: [SdSvgA11yComponent],
})
export class SdSvgA11yModule {}
