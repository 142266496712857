import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureName, SidedrawerState } from './sidedrawer.reducer';
import { SidedrawerRoles } from '../../core/roles/sidedrawer.roles';
import { sideDrawerNetworkEntitiesSelector } from '../../sidedrawer-networks/store/side-drawer-network-list.selectors';
import { Dictionary } from '@ngrx/entity';
import { SideDrawerNetwork } from '../../sidedrawer-networks/models/side-drawer-network.model';
import { RoutesHelper } from '../../core/helpers/routes.helper';
import { UtilsHelper } from '../../core/helpers/utils.helper';
import { CloudProvider } from '../../integrations/models/cloud-provider.model';
import { SignatureProvider } from '../../signature/models/signature-provider.model';
import { isTenantUser } from '../../auth/store/auth.selectors';
import {
  dataBaseRegionsSelector,
  dataBaseRegionsWithCountryCodeSelector,
  dictionaryRolesListSelector,
} from '../../dictionary/store/dictionary.selectors';
import { DataBaseRegion } from '../../dictionary/models/data-base-region.models';
import { accountDataBaseRegionSelector } from '../../account/store/account.selector';
import { SideDrawer } from '../models/side-drawer.model';

export const sidedrawerSelector =
  createFeatureSelector<SidedrawerState>(featureName);

export const activeMyOtherSideDrawerSelector = createSelector(
  sidedrawerSelector,
  sideDrawerNetworkEntitiesSelector,
  (state: SidedrawerState, sdNetworks: Dictionary<SideDrawerNetwork>) =>
    sdNetworks[`${state.activeSideDrawer.id}`]
);

export const updateSpinnerSelector = createSelector(
  sidedrawerSelector,
  state => state.updating
);

export const activeSideDrawerSelector = createSelector(
  sidedrawerSelector,
  state => (state?.activeSideDrawer ? state?.activeSideDrawer : null)
);

export const rawActiveSideDrawerRoleSelector = createSelector(
  activeSideDrawerSelector,
  state =>
    !!state && !!state.userSidedrawerRole && state.userSidedrawerRole.length > 0
      ? state.userSidedrawerRole
      : null
);

export const activeSideDrawerRoleSelector = createSelector(
  rawActiveSideDrawerRoleSelector,
  dictionaryRolesListSelector,
  (roles, dicRoles) =>
    roles?.map(
      role =>
        (dicRoles?.find(dicRole => dicRole.roleId === role)
          ?.roleGroupId as SidedrawerRoles) ?? role
    )
);

export const isSdOwnerSelector = createSelector(
  activeSideDrawerRoleSelector,
  role => role?.some(role => role === SidedrawerRoles.owner)
);

export const isSdEditorSelector = createSelector(
  activeSideDrawerRoleSelector,
  role => role?.some(role => role === SidedrawerRoles.editor)
);

export const isSdOwnerOrSdEditorSelector = createSelector(
  isSdOwnerSelector,
  isSdEditorSelector,
  (isOwner, isEditor) => isOwner || isEditor
);

export const isSdViewerSelector = createSelector(
  activeSideDrawerRoleSelector,
  role => role?.some(role => role === SidedrawerRoles.viewer)
);

export const isSdOwnerSdEditorSdViewerSelector = createSelector(
  isSdOwnerSelector,
  isSdEditorSelector,
  isSdViewerSelector,
  (owner, editor, viewer) => owner || editor || viewer
);

export const activeSideDrawerLicensePrioritySelector = createSelector(
  activeSideDrawerSelector,
  state =>
    !!state && state.subscriptionFeatures
      ? 'account.licensePriority' in state.subscriptionFeatures
        ? state.subscriptionFeatures['account.licensePriority']
        : null
      : null
);

export const activeSideDrawerIntegrations = createSelector(
  activeSideDrawerSelector,
  state => state.integrations
);

export const activeSideDrawerCloudIntegrations = createSelector(
  activeSideDrawerIntegrations,
  integrations =>
    integrations?.filter(integration =>
      UtilsHelper.getListFromEnum(CloudProvider).some(
        cloud => cloud.value === integration
      )
    ) as CloudProvider[]
);

export const activeSideDrawerSignatureIntegrations = createSelector(
  activeSideDrawerIntegrations,
  integrations =>
    integrations?.filter(integration =>
      UtilsHelper.getListFromEnum(SignatureProvider).some(
        cloud => cloud.value === integration
      )
    ) as SignatureProvider[]
);

export const activeSideDrawerDocusignIntegrationAvailableSelector =
  createSelector(
    activeSideDrawerSignatureIntegrations,
    integrations =>
      integrations?.some(integration => integration === 'docusign') ?? false
  );

export const activeSideDrawerOwnersSelector = createSelector(
  activeSideDrawerSelector,
  state => state.owners
);

export const recordTypesSelector = createSelector(
  activeSideDrawerSelector,
  state =>
    !!state?.recordsType && state?.recordsType?.length > 0
      ? !!state?.userSidedrawerRole && state?.userSidedrawerRole?.length > 0
        ? state?.recordsType
        : state?.recordsType?.filter(types => types.count > 0)
      : []
);

export const recordTypeSelectorByRecordTypeName = (payload: {
  recordTypeName: string;
}) =>
  createSelector(recordTypesSelector, recordsType =>
    !!recordsType && recordsType.length > 0
      ? recordsType.find(
          recordType => recordType.name === payload.recordTypeName
        )
      : null
  );

export const recordTypesWithRecordsSelector = createSelector(
  activeSideDrawerSelector,
  state =>
    !!state.recordsType && state.recordsType.length > 0
      ? state.recordsType.filter(types => types.count > 0)
      : []
);

export const recordsTypesWithRecordsSelector = createSelector(
  activeSideDrawerSelector,
  sd => sd?.recordsType?.filter(rt => rt.count > 0)
);

export const sideDrawerDefaultSelector = createSelector(
  sidedrawerSelector,
  state => (state.sideDrawerDefault ? state.sideDrawerDefault : null)
);

export const sideDrawerSpinnerSelector = createSelector(
  sidedrawerSelector,
  state => state.loading
);

export const sideDrawerErrorSelector = createSelector(
  sidedrawerSelector,
  state => state.error
);

export const inboxEnabledSelector = createSelector(
  activeSideDrawerSelector,
  state =>
    state?.subscriptionFeatures?.['sidedrawer.any.sd.featureInbox'] === '1'
);

export const activeSideDrawerHasEmailSelector = createSelector(
  activeSideDrawerSelector,
  state => !!state?.email
);

export const createSideDrawerSpinnerChangeSelector = createSelector(
  sidedrawerSelector,
  state => state.creatingSideDrawer
);

export const transferSideDrawerSpinnerChangeSelector = createSelector(
  sidedrawerSelector,
  state => state.transferringSideDrawer
);

export const basePathSelector = createSelector(activeSideDrawerSelector, sd =>
  RoutesHelper.getBasePath(sd?.id)
);

export const activeSideDrawerMaxUploadMBsSelector = createSelector(
  activeSideDrawerSelector,
  activeSd =>
    parseInt(activeSd?.subscriptionFeatures?.['sidedrawer.maxUploadMBs'], 10)
);

export const docuSignAvailableSelector = createSelector(
  isTenantUser,
  activeSideDrawerDocusignIntegrationAvailableSelector,
  (isTenantUser, isDocusignAvailable) => isTenantUser && isDocusignAvailable
);

export const activeSideDrawerDataBaseRegionAsDataBaseRegionSelector =
  createSelector(
    activeSideDrawerSelector,
    dataBaseRegionsWithCountryCodeSelector,
    (sd: SideDrawer, dataBaseRegions: DataBaseRegion[]) =>
      dataBaseRegions?.find(
        region => region.databaseregion === sd.dataBaseRegion
      )
  );

export const notActiveSideDrawerDataBaseRegionSelector = createSelector(
  activeSideDrawerSelector,
  dataBaseRegionsWithCountryCodeSelector,
  (sd: SideDrawer, dataBaseRegions: DataBaseRegion[]) =>
    dataBaseRegions?.filter(
      region => region.databaseregion !== sd.dataBaseRegion
    )
);
