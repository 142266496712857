<div
  #tooltip="matTooltip"
  [attr.role]="'link'"
  [attr.aria-label]="recordType?.displayValue | displayValue | async"
  tabindex="0"
  (mouseleave)="tooltip.hide()"
  [matTooltip]="
    recordType?.displayValue[0]?.value + ' (' + recordType?.count + ')'
  "
  [ngClass]="
    active
      ? 'records-type-switcher-tile-container active'
      : 'records-type-switcher-tile-container'
  ">
  <div class="records-type-switcher-image">
    <app-sd-svg-a11y
      *ngIf="!reset"
      [color]="active ? 'var(--appBackground)' : 'var(--primaryColor)'"
      [height]="1.875"
      [setSdAccessibility]="{
        ariaLabel: recordType?.displayValue | displayValue | async,
        role: 'img',
        alt: recordType?.displayValue | displayValue | async,
        tabIndex: -1
      }"
      [src]="recordType.logo"
      [width]="1.5"></app-sd-svg-a11y>
  </div>
</div>
