import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SdSelectorA11yComponent } from './components/sd-selector-a11y.component';

import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { DisplayValuePipe } from '../sd-pipes/display-value-pipe/display-value.pipe';
import { DisplayValuePipeModule } from '../sd-pipes/display-value-pipe/display-value-pipe.module';

@NgModule({
  declarations: [SdSelectorA11yComponent],
  imports: [
    CommonModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    DisplayValuePipeModule,
  ],
  exports: [SdSelectorA11yComponent],
})
export class SdSelectorA11Module {}
