import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AccountService } from '../services/account.service';
import {
  AccountActionsTypes,
  AccountBrandCodeAffiliationsUpdateRequested,
  AccountLoaded,
  AccountRequested,
  ChangeLanguageRequested,
  CheckUserNameAndRefreshToken,
  CreateAccountFails,
  CreateAccountRequested,
  CreateAccountSpinnerChanged,
  CreateAccountSucceed,
  EnrollMFASilentlyRequested,
  SettingsLoaded,
  SettingsRequested,
} from './account.actions';
import {
  catchError,
  exhaustMap,
  last,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { Router } from '@angular/router';
import {
  AccountRoutesEnum,
  getAccountRoute,
} from '../routes/account-routes.enum';
import { CoreRoutes, getCoreRoute } from '../../core/routes/core.routes';
import { RoutesHelper } from '../../core/helpers/routes.helper';
import { EMPTY, forkJoin, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AppState } from '../../reducers';
import { select, Store } from '@ngrx/store';
import { startFlowInvitationCodeSelector } from '../../start-flow/store/start-flow.selectors';
import {
  dataBaseRegionByCountryCode,
  idpMfaByBrandCodesByBrandCodeSelector,
  languageSelector,
} from '../../dictionary/store/dictionary.selectors';
import { Country } from '../../shared/sd-forms/models/country.model';
import {
  authUserSelector,
  tokenSelector,
} from '../../auth/store/auth.selectors';
import { Language } from '../../dictionary/models/language.model';
import { Account } from '../models/account.model';
import { Email } from '../models/email.model';
import { Settings } from '../models/settings.model';
import { Locale } from '../../dictionary/models/locale.model';
import { Agreement } from '../models/agreement.model';
import { defaultBrandingSelector } from '../../core/store/core.selectors';
import { HttpErrorResponse } from '@angular/common/http';
import { CreateAccountErrorDialogComponent } from '../shared/create-account-error-dialog/create-account-error-dialog.component';
import { LogOut, RefreshTokenRequired } from '../../auth/store/auth.actions';
import {
  AccountCreated,
  StartFlowInvitationUsed,
} from '../../start-flow/store/start-flow.actions';
import {
  accountBrandCodeAffiliationsSelector,
  accountSelector,
  settingsSelector,
} from './account.selector';
import {
  BrandingConfigLoaded,
  CoreActionsTypes,
  ErrorLoaded,
} from '../../core/store/core.actions';
import { MfaMode } from '../models/mfa-mode.model';
import { MatDialog } from '@angular/material/dialog';
import {
  DictionaryRequested,
  LocaleDefaultChange,
} from '../../dictionary/store/dictionary.actions';
import { SideDrawerHomeRequested } from '../../sidedrawer/store/sidedrawer.actions';
import { activeSideDrawerSelector } from '../../sidedrawer/store/sidedrawer.selector';
import { LandingRoutes } from '../../landing/routes/landing.routes';
import {
  FilingCabinetActions,
  filingCabinetSelector,
  filingCabinetSideDrawersWithDataSelector,
} from '../../filing-cabinet/store/filing-cabinet.store';

@Injectable()
export class AccountEffects {
  accountRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<AccountRequested>(AccountActionsTypes.AccountRequested),
      mergeMap(action =>
        this.accountService.getAccountByOpenId(action.payload.openId).pipe(
          map(account => new AccountLoaded({ data: account })),
          tap({
            error: error => {
              const queryParams = RoutesHelper.getParams(false);
              if (error) {
                this.router.navigate(
                  [getAccountRoute(AccountRoutesEnum.create)],
                  {
                    queryParams,
                  }
                );
              } else {
                this.router.navigate([getCoreRoute(CoreRoutes.error)], {
                  queryParams,
                });
              }
            },
          })
        )
      )
    );
  });

  settingsRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<SettingsRequested>(AccountActionsTypes.SettingsRequested),
      mergeMap(action =>
        this.accountService.getAccountSettings(action.payload.accountId).pipe(
          catchError(() => {
            const queryParams = RoutesHelper.getParams(false);
            this.router.navigate([getCoreRoute(CoreRoutes.error)], {
              queryParams,
            });
            return of(null);
          }),
          map(response =>
            response ? new SettingsLoaded({ data: response }) : null
          )
        )
      )
    );
  });

  accountLoaded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<AccountLoaded>(AccountActionsTypes.AccountLoaded),
        switchMap(action => {
          if (window.Intercom) {
            return this.accountService
              .getIntercomHash(action.payload.data.emails[0].address)
              .pipe(
                tap({
                  next: ({ hash }) => {
                    window.Intercom('update', {
                      app_id: environment.intercomAppId,
                      email:
                        action.payload.data.emails?.[0]?.address ??
                        action.payload.data.username,
                      name: `${action.payload.data.firstName} ${action.payload.data.lastName}`,
                      user_hash: hash,
                    });
                  },
                  error: () => {
                    window.Intercom('update', {
                      app_id: environment.intercomAppId,
                      email:
                        action.payload.data.emails?.[0]?.address ??
                        action.payload.data.username,
                      name: `${action.payload.data.firstName} ${action.payload.data.lastName}`,
                    });
                  },
                })
              );
          }
          return EMPTY;
        })
      ),
    { dispatch: false }
  );

  createAccountRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateAccountRequested>(
        AccountActionsTypes.CreateAccountRequested
      ),
      tap(() =>
        this.store.dispatch(new CreateAccountSpinnerChanged({ state: true }))
      ),
      map(action => action.payload),
      switchMap(({ form, createAccountWithInvitationCodeFails }) =>
        forkJoin([
          this.store.pipe(select(startFlowInvitationCodeSelector), take(1)),
          this.store.pipe(select(languageSelector), take(1)),
          this.store.pipe(select(authUserSelector), take(1)),
          this.store.pipe(select(defaultBrandingSelector), take(1)),
          this.store.pipe(
            select(defaultBrandingSelector),
            take(1),
            switchMap(brand =>
              this.store
                .select(
                  idpMfaByBrandCodesByBrandCodeSelector({
                    brandCode: brand.brandCode,
                  })
                )
                .pipe(take(1))
            )
          ),
          this.store.pipe(
            select(
              dataBaseRegionByCountryCode({
                countryCode:
                  Country.getCountryByCountryName(
                    form.primaryResidenceSelected.country
                  )?.countryCode ?? form.primaryResidenceSelected.country,
              })
            ),
            take(1)
          ),
        ]).pipe(
          map(
            ([
              invitationCode,
              languages,
              authUser,
              brand,
              idpMfa,
              dataBaseRegion,
            ]) => ({
              invitationCode,
              languages,
              authUser,
              brand,
              idpMfa,
              dataBaseRegion,
              form,
              createAccountWithInvitationCodeFails,
            })
          )
        )
      ),
      switchMap(
        ({
          form,
          dataBaseRegion,
          authUser,
          languages,
          invitationCode,
          createAccountWithInvitationCodeFails,
          brand,
          idpMfa,
        }) => {
          const localeSelected = Language.getLocales(languages).find(
            locale => locale.localeId === form.locale.localeId
          );
          const account = new Account();
          account.username = authUser.email;
          account.firstName = form.firstName?.trim();
          account.lastName = form.lastName?.trim();
          account.primaryResidence = form.primaryResidenceSelected;
          const emailVerified =
            typeof authUser.emailVerified === 'boolean'
              ? authUser.emailVerified
              : typeof authUser.emailVerified === 'string' &&
                authUser.emailVerified === 'true';
          account.emails = [new Email(true, emailVerified, authUser.email)];
          account.openId = authUser.sub;
          account.settings = new Settings();
          account.settings.country = Country.getCountryByCountryName(
            form.primaryResidenceSelected.country
          ).countryCode;
          account.settings.communicationLanguage =
            Locale.getLocaleId(localeSelected);
          account.settings.preferredLanguage =
            Locale.getLocaleId(localeSelected);
          account.settings.notificationMethod = 'email';
          account.settings.mfaMode = MfaMode.never;
          account.settings.idpMfa = idpMfa;
          account.settings.mfaDisabled = true;
          account.identityProvider = authUser.identityProvider;
          const tosAgreement = new Agreement(
            Locale.getLocaleId(localeSelected),
            'tos',
            0,
            true
          );
          const ppAgreement = new Agreement(
            Locale.getLocaleId(localeSelected),
            'privacy_policies',
            0,
            true
          );
          account.agreements = [tosAgreement, ppAgreement];
          account.dataBaseRegion = dataBaseRegion?.databaseregion;
          return this.accountService
            .createAccount(
              account,
              brand.brandCode,
              !createAccountWithInvitationCodeFails ? invitationCode : null
            )
            .pipe(
              map(response => {
                const newAccount = { ...account, id: response.id };
                return new CheckUserNameAndRefreshToken({
                  account: newAccount,
                  invitationCode,
                  createAccountWithInvitationCodeFails,
                });
              }),
              catchError((error: HttpErrorResponse) => {
                this.store.dispatch(
                  new CreateAccountSpinnerChanged({ state: false })
                );
                if (error?.error?.message === 'invalid_invitation_code') {
                  return of(
                    new CreateAccountRequested({
                      form,
                      createAccountWithInvitationCodeFails: true,
                    })
                  );
                }
                return of(new CreateAccountFails({ error }));
              })
            );
        }
      )
    )
  );

  createAccountFails$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateAccountFails>(AccountActionsTypes.CreateAccountFails),
      switchMap(action =>
        this.dialog
          .open(CreateAccountErrorDialogComponent, {
            data: { error: action.payload.error },
            autoFocus: false,
          })
          .afterClosed()
      ),
      map(() => new LogOut())
    )
  );

  checkUserNameAndRefreshToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CheckUserNameAndRefreshToken>(
        AccountActionsTypes.CheckUserNameAndRefreshToken
      ),
      switchMap(({ payload }) =>
        forkJoin([
          this.store.select(authUserSelector).pipe(take(1)),
          of(payload).pipe(take(1)),
        ])
      ),
      switchMap(([authUser, payload]) =>
        this.accountService.checkUserName(authUser.email).pipe(
          take(1),
          catchError(() => of(payload)),
          map(() => payload)
        )
      ),
      map(
        ({ account, invitationCode, createAccountWithInvitationCodeFails }) => {
          this.store.dispatch(new RefreshTokenRequired());
          return new CreateAccountSucceed({
            account,
            invitationCode,
            createAccountWithInvitationCodeFails,
          });
        }
      )
    )
  );

  createAccountSucceed$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateAccountSucceed>(AccountActionsTypes.CreateAccountSucceed),
      switchMap(action =>
        this.store.pipe(
          select(tokenSelector),
          take(2),
          last(),
          map(() => action.payload)
        )
      ),
      tap(({ invitationCode, createAccountWithInvitationCodeFails }) => {
        console.log({invitationCode, createAccountWithInvitationCodeFails});
        // REMOVE QUERIES FROM URL
        try {
          window.history.replaceState(
            {},
            document.title,
            `${window.location.pathname}`
          );
        } catch (e) {
          console.warn(e);
        }
      }),
      map(({ account }) => {
        this.store.dispatch(new AccountLoaded({ data: account }));
        this.store.dispatch(new StartFlowInvitationUsed());
        return new AccountCreated({ account });
      })
    )
  );

  EnrollMFASilentlyRequested$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<EnrollMFASilentlyRequested>(
          AccountActionsTypes.EnrollMFASilentlyRequested
        ),
        switchMap(() =>
          this.store.select(defaultBrandingSelector).pipe(take(1))
        ),
        switchMap(defaultBranding =>
          forkJoin([
            this.store
              .select(
                idpMfaByBrandCodesByBrandCodeSelector({
                  brandCode: defaultBranding.brandCode,
                })
              )
              .pipe(take(1)),
            this.store.select(accountSelector).pipe(take(1)),
            this.store.select(settingsSelector).pipe(take(1)),
          ])
        ),
        exhaustMap(([idpMfa, account, settings]) => {
          return this.accountService.updateAccountSettingsV2(account, {
            ...settings,
            mfaMode: MfaMode.never,
            idpMfa: idpMfa,
            mfaDisabled: true,
          });
        })
      ),
    { dispatch: false }
  );

  brandingConfigLoaded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<BrandingConfigLoaded>(CoreActionsTypes.BrandingConfigLoaded),
        switchMap(action =>
          forkJoin([
            of(action.payload.data?.brandCode),
            this.store.pipe(select(accountSelector), take(1)),
            this.store.pipe(
              select(accountBrandCodeAffiliationsSelector),
              take(1)
            ),
          ])
        ),
        map(([brandCode, account, brandCodeAffiliations]) => {
          if (!account) {
            return;
          }
          if (
            !brandCodeAffiliations.some(
              brandCodeAffiliation => brandCodeAffiliation === brandCode
            )
          ) {
            return this.store.dispatch(
              new AccountBrandCodeAffiliationsUpdateRequested({
                brandCodes: [brandCode],
              })
            );
          }
        })
      ),
    { dispatch: false }
  );

  accountBrandCodeAffiliationsUpdateRequested$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<AccountBrandCodeAffiliationsUpdateRequested>(
          AccountActionsTypes.AccountBrandCodeAffiliationsUpdateRequested
        ),
        switchMap(action =>
          forkJoin([
            of(action.payload.brandCodes),
            this.store.pipe(select(accountSelector), take(1)),
          ])
        ),
        map(([brandCodes, account]) => ({
          ...account,
          brandCodeAffiliations:
            account?.brandCodeAffiliations?.length > 0
              ? [...account.brandCodeAffiliations, ...brandCodes]
              : brandCodes,
        })),
        switchMap(account => this.accountService.updateAccount(account))
      ),
    { dispatch: false }
  );

  changeLanguageRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ChangeLanguageRequested>(
        AccountActionsTypes.ChangeLanguageRequested
      ),
      switchMap(action =>
        forkJoin([
          this.store.select(accountSelector).pipe(take(1)),
          this.store.select(settingsSelector).pipe(take(1)),
          of(action.payload.locale),
          this.store.select(activeSideDrawerSelector).pipe(take(1)),
          this.store.select(filingCabinetSelector).pipe(take(1)),
          this.store
            .select(filingCabinetSideDrawersWithDataSelector)
            .pipe(take(1)),
        ])
      ),
      exhaustMap(([account, settings, locale, activeSd, fc, fcSds]) =>
        this.accountService
          .updateAccountSettingsV2(account, {
            ...settings,
            communicationLanguage: Locale.getLocaleId(locale),
            preferredLanguage: Locale.getLocaleId(locale),
          })
          .pipe(
            map(() => {
              this.store.dispatch(
                new DictionaryRequested({
                  localeId: Locale.getLocaleId(locale),
                })
              );
              if (fc) {
                this.store.dispatch(
                  FilingCabinetActions.filingCabinetSideDrawersHomeRequested({
                    sideDrawers: fcSds.map(sd => ({
                      sideDrawerId: sd.sidedrawer,
                      dataBaseRegion: sd.region,
                    })),
                    localeDefault: Locale.getLocaleId(locale),
                  })
                );
              }
              if (activeSd) {
                this.store.dispatch(
                  new SideDrawerHomeRequested({
                    sdId: activeSd.id,
                    localeDefault: Locale.getLocaleId(locale),
                  })
                );
                this.router.navigateByUrl(
                  `/core/home/${activeSd.id}/${LandingRoutes.root}`
                );
              }
              return new LocaleDefaultChange({ data: locale });
            }),
            catchError((httpError: HttpErrorResponse) =>
              of(new ErrorLoaded({ httpError, display404: true }))
            )
          )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly accountService: AccountService,
    private readonly store: Store<AppState>,
    private readonly router: Router,
    private readonly dialog: MatDialog
  ) {}
}
