<div *ngIf="emptyStateInline === false" class="empty-state-container">
  <app-sd-svg-a11y
    [color]="sdColorPalette.primaryColor"
    [height]="3.75"
    [setSdAccessibility]="{ tabIndex: -1 }"
    [src]="emptyStateIcon"
    [width]="3.75"></app-sd-svg-a11y>
  <div
    [attr.aria-label]="emptyStateString"
    [ngStyle]="{
      'margin-bottom': marginBottom ? marginBottom + remUnit : '0'
    }"
    class="empty-state-message">
    {{ emptyStateString }}
  </div>
</div>

<div
  *ngIf="emptyStateInline === true"
  [ngStyle]="{
    'margin-bottom': marginBottom ? marginBottom + remUnit : '0',
    'margin-left': marginLeft ? marginLeft + remUnit : '0',
    'margin-top': marginTop ? marginTop + remUnit : '0'
  }"
  class="empty-state-message-inline"
  [attr.aria-label]="emptyStateString">
  {{ emptyStateString }}
</div>
