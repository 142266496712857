import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import { map, tap } from 'rxjs/operators';
import { Network } from '../../../../networks/models/network.model';
import { TeamNetwork } from '../../../../networks/models/team-network.model';
import { Relation } from '../../../../records/models/relation.model';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { SdValidationFormErrors } from 'src/app/account/models/sd-validation-form-errors.model';
import { Option } from '../../../sd-forms/models/option.model';
import { Relationship } from '../../../../dictionary/models/relationship.model';
import { AppState } from '../../../../reducers';
import {
  relationshipSelector,
  relationshipTypesSelector,
} from '../../../../dictionary/store/dictionary.selectors';
import { SdFormHelper } from '../../../../core/helpers/sd-form.helper';

@Component({
  selector: 'app-sd-network-relationship-form-a11y',
  templateUrl: './sd-network-relationship-form-a11y.component.html',
  styleUrls: ['./sd-network-relationship-form-a11y.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SdNetworkRelationshipFormA11yComponent
  implements OnInit, OnDestroy
{
  network: Network | TeamNetwork;
  @Input() placeholder: string;
  @Input() error: string;
  @Input() relationship: string;
  @Output() relationChange = new EventEmitter<Relation>();
  @Output() isValid = new EventEmitter<boolean>(null);
  @Output() formErrors = new EventEmitter<SdValidationFormErrors>(null);
  relationController = new UntypedFormControl(null);
  options$: Observable<Option[]>;
  subscription = new Subscription();
  relationships: Relationship[];
  errors: SdValidationFormErrors;
  relationForm: UntypedFormGroup;

  constructor(private readonly store: Store<AppState>) {}

  @Input() set setNetwork(value: Network | TeamNetwork) {
    this.network = value;
    if (this.network) {
      this.setOptions();
    }
  }

  private _isSubmitted: boolean;

  //TODO Review this
  get isSubmitted(): boolean {
    return this._isSubmitted;
  }

  @Input() set isSubmitted(value: boolean) {
    this._isSubmitted = value;
    if (this._isSubmitted === true) {
      this.relationForm?.markAllAsTouched();
    }
  }

  ngOnInit(): void {
    this.relationForm = new UntypedFormGroup({
      relationController: this.relationController,
    });
    this.setOptions();
    const relation$ = this.relationController.valueChanges.pipe(
      tap(value => {
        const defaultRelation = {
          personalOther: 'Other',
          personal: 'other',
        };
        let relation: Relation = {};
        if (!value) {
          this.emitFormState(defaultRelation);
          return;
        } else if (typeof this.relationController.value === 'string') {
          if (this.relationController.value?.trim()?.length === 0) {
            this.emitFormState(defaultRelation);
            return;
          }
          relation = {
            personalOther: this.relationController.value,
            personal: 'other',
          };
        } else {
          const { key } = this.relationController.value;
          const selected = this.relationships.find(
            rel => rel.relationship_relationshipid === key
          );
          relation[
            `${
              selected.relationship_relationshipgroupid === 'personal'
                ? 'personal'
                : 'profession'
            }`
          ] = key;
        }
        this.emitFormState(relation);
      })
    );
    this.subscription.add(relation$.subscribe());
  }

  setOptions(): void {
    this.options$ = combineLatest([
      this.store.pipe(select(relationshipSelector)),
      this.store.pipe(select(relationshipTypesSelector)),
    ]).pipe(
      map(data => {
        const [relationships, relationshipTypes] = data;
        this.relationships = relationships;
        return relationships
          .filter(
            relationship => relationship.relationship_relationshipid !== 'other'
          )
          .map(
            relationship =>
              new Option(
                relationship.relationship_relationshipid,
                relationship.relationship_relationshipname,
                null,
                relationshipTypes.find(
                  type =>
                    type.relationshiptype_groupid ===
                    relationship.relationship_relationshipgroupid
                ).relationshiptype_groupname
              )
          );
      }),
      tap(options => this.initRelation(options))
    );
  }

  initRelation(options: Option[]): void {
    if (this.relationship && !this.relationController.value) {
      const founded = options.find(
        o => o.key === this.relationship?.trim()?.toLocaleLowerCase()
      );
      this.relationController.setValue(founded ? founded : this.relationship);
      return;
    }
    if (!this.network) {
      this.relationController.setValue(null);
      return;
    }
    if (this.network?.relation) {
      const { personal, profession, personalOther, professionOther } =
        this.network.relation;
      if (personal?.length > 0 && personal !== 'other') {
        this.relationController.setValue(
          options.find(option => option.key === personal)
        );
        return;
      }
      if (profession?.length > 0 && profession !== 'other') {
        this.relationController.setValue(
          options.find(option => option.key === profession)
        );
        return;
      }
      if (personalOther?.length > 0) {
        this.relationController.setValue(personalOther);
        return;
      }
      if (professionOther?.length > 0) {
        this.relationController.setValue(professionOther);
        return;
      }
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  emitFormState(relation: Relation): void {
    this.isValid.emit(
      !this.relationForm.controls.relationController.hasError('required')
    );
    if (!this.relationForm.controls.relationController.hasError('required')) {
      this.relationChange.emit(relation);
      this.errors = null;
      this.formErrors.emit(this.errors);
    }
    //TODO Review dictionary variables
    if (this.relationForm.controls.relationController.hasError('required')) {
      this.errors = {
        relation: SdFormHelper.getErrorFormControl(
          this.relationForm,
          'relationController',
          'required',
          'globalparams_requirederror'
        ),
      };
      this.formErrors.emit(this.errors);
    }
  }
}
