import { CoreRoutes } from '../../core/routes/core.routes';

export enum HomeRoutes {
  root = 'home',
  main = '',
}

export function getHomeRoute(route: HomeRoutes): string {
  return (
    CoreRoutes.root +
    '/' +
    (route.length > 0 ? HomeRoutes.root + '/' + route : HomeRoutes.root)
  );
}
