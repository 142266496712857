import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { IonRouterOutlet } from '@ionic/angular/common';
import { Actions } from '@ngrx/effects';
import { App } from '@capacitor/app';
import { environment } from '../../../environments/environment';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { BiometricsActions } from './biometrics/store/biometrics.store';

@Component({
  selector: 'app-mobile-root',
  template: `
    <ion-app>
      <ion-content>
        <ion-router-outlet />
      </ion-content>
    </ion-app>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [IonRouterOutlet],
})
export class MobileAppComponent implements OnInit {
  inactivityTimeStamp: number;

  constructor(
    private readonly platform: Platform,
    private readonly actions: Actions,
    private readonly store: Store<AppState>
  ) {
    // this.actions.pipe(tap(action => console.log(action))).subscribe();
    App.addListener('appStateChange', ({ isActive }) => {
      console.log({ isActive, timestamp: new Date() });
      if (!isActive) {
        this.inactivityTimeStamp = Date.now();
        return;
      }
      if (this.inactivityTimeStamp) {
        const millisecondsPaused = Date.now() - this.inactivityTimeStamp;
        console.log({
          millisecondsPaused,
          inactivityTimeStamp: this.inactivityTimeStamp,
          logout: environment.appPauseApp * 1000 < millisecondsPaused,
        });
        this.inactivityTimeStamp = null;
        if (environment.appPauseApp * 1000 < millisecondsPaused) {
          this.store.dispatch(BiometricsActions.authenticate());
        }
      }
    });
  }

  ngOnInit(): void {
    this.platform.backButton.subscribeWithPriority(9999, () => {
      console.log(this.store)
    });
  }
}
