import { createSelector } from '@ngrx/store';
import { plansStateSelector } from './plans.selectors';

export const plansViewsStateSelector = createSelector(
  plansStateSelector,
  state => state.views
);

export const plansProgressSpinnerSelector = createSelector(
  plansViewsStateSelector,
  state => state.progressSpinner
);

export const plansSortPlanListBySelector = createSelector(
  plansViewsStateSelector,
  state => state.sortPlanListBy
);

export const planDetailsQueueSelector = createSelector(
  plansViewsStateSelector,
  state => state.queue
);

export const planViewsHideCompletedSelector = createSelector(
  plansViewsStateSelector,
  state => state.hideCompleted
);
