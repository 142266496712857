import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import {
  catchError,
  concat,
  forkJoin,
  map,
  mergeMap,
  of,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { PaginatorApiResponse } from 'src/app/core/models/paginator-api-response.dto';
import { PaginatorService } from 'src/app/core/services/paginator.service';
import { ErrorLoaded } from 'src/app/core/store/core.actions';
import { Locale } from 'src/app/dictionary/models/locale.model';
import { localeDefaultSelector } from 'src/app/dictionary/store/dictionary.selectors';
import { RecordsHelper } from 'src/app/records/helpers/records.helper';
import { RecordListItem } from 'src/app/records/models/record-list-item.model';
import { AppState } from 'src/app/reducers';
import { activeSideDrawerSelector } from 'src/app/sidedrawer/store/sidedrawer.selector';
import { SfrSortOption } from '../models/enums/sfr-sort-option.enum';
import { TagSimpleFileRequest } from '../models/enums/tag-sfr.enum';
import {
  FilingCabinetAllSimpleFileRequest,
  SimpleFileRequestActionsTypes,
  SimpleFileRequestDeleteRecord,
  SimpleFileRequestLoaded,
  SimpleFileRequestLoadedForAppend,
  SimpleFileRequestNextPageRequested,
  SimpleFileRequestRequested,
  SimpleFileRequestRequestedForAppend,
  SimpleFileRequestSpinnerChange,
} from './sfr.action';
import { sfrStateSelector } from './sfr.selectors';
import {
  FilingCabinetActions,
  filingCabinetSideDrawersSelector,
  filingCabinetSideDrawersWithDataSelector,
} from '../../filing-cabinet/store/filing-cabinet.store';
import { delay, exhaustMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import {
  RecordChangeStatus,
  RecordChangeStatusRequested,
  RecordDeleted,
  RecordsListActionsTypes,
} from '../../records/store/records-list.actions';
import {
  FileHistoryActionsTypes,
  FileHistoryDeleted,
  FileHistoryLoaded,
} from 'src/app/files/store/file-history.actions';
import { activeRecordSelector } from 'src/app/records/store/records-list.selectors';
import { fileListByRecordIdSelector } from 'src/app/files/store/file-history.selector';
import { SfrWorkflowStatus } from '../models/enums/sfr-workflow-status.enum';
import { getRemindersOrderedByStatusListSelector } from './reminders-list.selectors';
import { TypesOfReminders } from '../models/enums/types-of-reminders.enum';
import { ReminderChangeStatusRequested } from './reminders-list.action';
import { ReminderStatus } from '../models/enums/reminder-status.enum';

@Injectable()
export class SimpleFileRequestEffects {
  simpleFileRequestRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<SimpleFileRequestRequested>(
        SimpleFileRequestActionsTypes.SimpleFileRequestRequested
      ),
      tap(() =>
        this.store.dispatch(
          new SimpleFileRequestSpinnerChange({ spinner: true })
        )
      ),
      mergeMap(action =>
        this.paginatorService
          .getPaginatedResource<RecordListItem>(
            RecordsHelper.getRecordsResourceUrl(
              action.payload.sideDrawerId,
              action.payload.locale,
              {
                uniqueReference: action.payload.uniqueReference,
                nextPage: action.payload.nextPage,
              }
            )
          )
          .pipe(
            map(
              (response: PaginatorApiResponse<RecordListItem>) =>
                new SimpleFileRequestLoaded({
                  data: {
                    ...response,
                    data: response.data.map(rec => ({
                      ...rec,
                      sideDrawerId: action.payload.sideDrawerId,
                    })),
                  },
                  sortBy: SfrSortOption.lastModified,
                  sideDrawerId: action.payload.sideDrawerId,
                })
            ),
            tap(() =>
              this.store.dispatch(
                new SimpleFileRequestSpinnerChange({ spinner: false })
              )
            ),
            catchError(error => {
              this.store.dispatch(
                new SimpleFileRequestSpinnerChange({ spinner: false })
              );
              return of(
                new ErrorLoaded({ httpError: { ...error }, display404: false })
              );
            })
          )
      )
    );
  });

  simpleFileRequestRequestedForAppend$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<SimpleFileRequestRequestedForAppend>(
        SimpleFileRequestActionsTypes.SimpleFileRequestRequestedForAppend
      ),
      tap(() =>
        this.store.dispatch(
          new SimpleFileRequestSpinnerChange({ spinner: true })
        )
      ),
      mergeMap(action =>
        this.paginatorService
          .getPaginatedResource<RecordListItem>(
            RecordsHelper.getRecordsResourceUrl(
              action.payload.sideDrawerId,
              action.payload.locale,
              {
                uniqueReference: action.payload.uniqueReference,
                nextPage: action.payload.nextPage,
              }
            )
          )
          .pipe(
            map(
              (response: PaginatorApiResponse<RecordListItem>) =>
                new SimpleFileRequestLoadedForAppend({
                  data: {
                    ...response,
                    data: response.data.map(rec => ({
                      ...rec,
                      sideDrawerId: action.payload.sideDrawerId,
                    })),
                  },
                  sortBy: SfrSortOption.lastModified,
                  sideDrawerId: action.payload.sideDrawerId,
                })
            ),
            tap(() =>
              this.store.dispatch(
                new SimpleFileRequestSpinnerChange({ spinner: false })
              )
            ),
            catchError(error => {
              this.store.dispatch(
                new SimpleFileRequestSpinnerChange({ spinner: false })
              );
              return of(
                new ErrorLoaded({ httpError: { ...error }, display404: false })
              );
            })
          )
      )
    );
  });

  simpleFileRequestNextPageRequested$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SimpleFileRequestNextPageRequested>(
          SimpleFileRequestActionsTypes.SimpleFileRequestNextPageRequested
        ),
        mergeMap(() =>
          forkJoin([
            this.store.pipe(
              select(filingCabinetSideDrawersWithDataSelector),
              take(1)
            ),
            this.store.pipe(select(activeSideDrawerSelector), take(1)),
            this.store.pipe(select(localeDefaultSelector), take(1)),
            this.store.pipe(select(sfrStateSelector), take(1)),
          ]).pipe(
            tap(([fcSds, activeSideDrawer, locale, recordState]) => {
              const { requestsInformation, gettingSFR } = recordState;
              if (gettingSFR) {
                return;
              }
              if (fcSds?.length > 0) {
                fcSds.forEach(fcSd => {
                  if (requestsInformation.get(fcSd.id)?.hasMore) {
                    this.store.dispatch(
                      new SimpleFileRequestRequestedForAppend({
                        sideDrawerId: fcSd.id,
                        locale: Locale.getLocaleId(locale),
                        nextPage: requestsInformation.get(fcSd.id)?.nextPage,
                        uniqueReference: TagSimpleFileRequest.simpleFileRequest,
                      })
                    );
                  }
                });
                return;
              }
              if (requestsInformation.get(activeSideDrawer.id)?.hasMore) {
                this.store.dispatch(
                  new SimpleFileRequestRequestedForAppend({
                    sideDrawerId: activeSideDrawer.id,
                    locale: Locale.getLocaleId(locale),
                    nextPage: requestsInformation.get(activeSideDrawer.id)
                      ?.nextPage,
                    uniqueReference: TagSimpleFileRequest.simpleFileRequest,
                  })
                );
              }
            })
          )
        )
      ),
    { dispatch: false }
  );

  filingCabinetAllSimpleFileRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FilingCabinetAllSimpleFileRequest>(
        SimpleFileRequestActionsTypes.FilingCabinetAllSimpleFileRequest
      ),
      tap(() =>
        this.store.dispatch(
          new SimpleFileRequestSpinnerChange({ spinner: true })
        )
      ),
      switchMap(() =>
        forkJoin([
          this.store.select(activeSideDrawerSelector).pipe(take(1)),
          this.store.select(filingCabinetSideDrawersSelector).pipe(take(1)),
          this.store.select(localeDefaultSelector).pipe(take(1)),
        ])
      ),
      map(([activeSd, fcSds, locale]) => ({
        sideDrawersIds:
          fcSds?.size > 0
            ? Array.from(fcSds.values()).map(fcSd => fcSd.sidedrawer)
            : [activeSd.id],
        locale,
      })),
      exhaustMap(({ sideDrawersIds, locale }) =>
        concat(
          ...sideDrawersIds.map(sdId =>
            this.paginatorService
              .getPaginatedResource<RecordListItem>(
                RecordsHelper.getRecordsResourceUrl(
                  sdId,
                  Locale.getLocaleId(locale),
                  {
                    uniqueReference: TagSimpleFileRequest.simpleFileRequest,
                  }
                )
              )
              .pipe(
                catchError((error: HttpErrorResponse) => {
                  if (error.status !== 403 && error.status !== 401) {
                    this.store.dispatch(
                      new ErrorLoaded({
                        httpError: { ...error },
                        display404: false,
                      })
                    );
                  }
                  return of(null);
                }),
                tap((response: PaginatorApiResponse<RecordListItem>) => {
                  if (response) {
                    this.store.dispatch(
                      new SimpleFileRequestLoadedForAppend({
                        data: {
                          ...response,
                          data: response.data.map(rec => ({
                            ...rec,
                            sideDrawerId: sdId,
                          })),
                        },
                        sortBy: SfrSortOption.lastModified,
                        sideDrawerId: sdId,
                      })
                    );
                  }
                })
              )
          )
        ).pipe(
          takeUntil(
            this.actionsListener$.pipe(
              ofType(FilingCabinetActions.filingCabinetClearAllData)
            )
          ),
          map(() => new SimpleFileRequestSpinnerChange({ spinner: false }))
        )
      )
    )
  );

  recordDeleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType<RecordDeleted>(RecordsListActionsTypes.RecordDeleted),
      map(
        ({ payload }) => new SimpleFileRequestDeleteRecord({ id: payload.id })
      )
    )
  );

  fileHistoryDeletedAndLoaded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<FileHistoryDeleted | FileHistoryLoaded>(
          FileHistoryActionsTypes.FileHistoryDeleted,
          FileHistoryActionsTypes.FileHistoryLoaded
        ),
        map(() => {
          const activeRecord = this.store.selectSignal(activeRecordSelector)();
          const activeSideDrawer = this.store.selectSignal(
            activeSideDrawerSelector
          )();
          if (activeRecord) {
            const fileHistory = this.store.selectSignal(
              fileListByRecordIdSelector({ recordId: activeRecord?.id })
            )();

            if (
              activeRecord.uniqueReference ===
                TagSimpleFileRequest.simpleFileRequest &&
              activeRecord.status === SfrWorkflowStatus.sfrComplete &&
              fileHistory.length === 0
            ) {
              this.store.dispatch(
                new RecordChangeStatusRequested({
                  record: activeRecord,
                  sideDrawerId: activeSideDrawer.id,
                  status: SfrWorkflowStatus.sfrNew,
                })
              );
            }
          }
        })
      ),
    { dispatch: false }
  );

  recordChangeStatusForSFR$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<RecordChangeStatus>(RecordsListActionsTypes.RecordChangeStatus),
        map(({ payload }) => {
          const activeRecord = this.store.selectSignal(activeRecordSelector)();

          if (
            payload.record.changes.uniqueReference ===
              TagSimpleFileRequest.simpleFileRequest &&
            activeRecord
          ) {
            const reminders = this.store.selectSignal(
              getRemindersOrderedByStatusListSelector({
                typeOfReminder: TypesOfReminders.record,
                recordId: activeRecord.id,
              })
            )();

            let reminderStatusForChange = null;
            if (activeRecord.status === SfrWorkflowStatus.sfrComplete) {
              reminderStatusForChange = ReminderStatus.Paused;
            }
            if (activeRecord.status === SfrWorkflowStatus.sfrNew) {
              reminderStatusForChange = ReminderStatus.Active;
            }

            reminders?.forEach(reminder => {
              if (
                reminder.status === ReminderStatus.Finished ||
                reminder.status === reminderStatusForChange
              ) {
                return;
              }

              of(reminder)
                .pipe(
                  delay(1000),
                  take(1),
                  tap(() => {
                    this.store.dispatch(
                      new ReminderChangeStatusRequested({
                        reminder: reminder,
                        id: reminder.id,
                        status: reminderStatusForChange,
                      })
                    );
                  })
                )
                .subscribe();
            });
          }
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly actionsListener$: ActionsSubject,
    private readonly paginatorService: PaginatorService,
    private readonly store: Store<AppState>
  ) {}
}
