import { Action } from '@ngrx/store';
import { BrandingConfig } from '../models/branding-config.model';
import { HttpErrorResponse } from '@angular/common/http';
import { TenantRole } from '../models/tenant-role.model';

export enum CoreActionsTypes {
  SideDrawerBrandingRequested = '[SideDrawer Service] SideDrawer Branding Requested',
  BrandingConfigLoaded = '[Core Service] Branding Configuration Loaded',
  DefaultBrandingLoaded = '[Core Service] Default Branding Loaded',
  SecurityCompleted = '[MFA / Security Settings Dialog] Security Completed is True',
  MobileDevice = '[App Component] The device is mobile',
  ResizeEvent = '[App Component] Window resized',
  ErrorLoaded = '[Error Info] Error Loaded',
  RenderConfetti = '[Home Template] Render Confetti',
  GettingBrandingChange = '[] Getting Branding Change',
  TenantsRolesLoaded = '[Start Flow Effect] Tenants Roles Loaded',
  setGeneralProgressBar = '[General Progress Bar] Set General Progress Bar',
  RedirectoToUrl = '[Redirect to URL] Redirect to URL',
}

export class SideDrawerBrandingRequested implements Action {
  readonly type = CoreActionsTypes.SideDrawerBrandingRequested;

  constructor(
    public payload: { sdId: string; localeDefault: string; styleMode?: string }
  ) {}
}

export class BrandingConfigLoaded implements Action {
  readonly type = CoreActionsTypes.BrandingConfigLoaded;

  constructor(public payload: { data: BrandingConfig }) {}
}

export class DefaultBrandingLoaded implements Action {
  readonly type = CoreActionsTypes.DefaultBrandingLoaded;

  constructor(public payload: { data: BrandingConfig }) {}
}

export class SecurityCompleted implements Action {
  readonly type = CoreActionsTypes.SecurityCompleted;
}

export class MobileDevice implements Action {
  readonly type = CoreActionsTypes.MobileDevice;
}

export class ResizeEvent implements Action {
  readonly type = CoreActionsTypes.ResizeEvent;

  constructor(public payload: { width: number; height: number }) {}
}

export class ErrorLoaded implements Action {
  readonly type = CoreActionsTypes.ErrorLoaded;

  constructor(
    public payload: { httpError: HttpErrorResponse; display404?: boolean }
  ) {}
}

export class RenderConfetti implements Action {
  readonly type = CoreActionsTypes.RenderConfetti;
}

export class GettingBrandingChange implements Action {
  readonly type = CoreActionsTypes.GettingBrandingChange;

  constructor(public payload: { gettingBranding: boolean }) {}
}
export class TenantsRolesLoaded implements Action {
  readonly type = CoreActionsTypes.TenantsRolesLoaded;

  constructor(public payload: { tenantRoles: TenantRole[] }) {}
}

export class SetGeneralProgressBar implements Action {
  readonly type = CoreActionsTypes.setGeneralProgressBar;

  constructor(public payload: { show: boolean }) {}
}

export class RedirectoToUrl implements Action {
  readonly type = CoreActionsTypes.RedirectoToUrl;

  constructor(public payload: { url: string; params?: {} }) {}
}

export type CoreActions =
  | SideDrawerBrandingRequested
  | BrandingConfigLoaded
  | DefaultBrandingLoaded
  | SecurityCompleted
  | MobileDevice
  | ResizeEvent
  | ErrorLoaded
  | RenderConfetti
  | GettingBrandingChange
  | TenantsRolesLoaded
  | SetGeneralProgressBar
  | RedirectoToUrl;
