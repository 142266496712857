import { Action } from '@ngrx/store';
import { DriveItem } from '../models/drive-item.model';

export enum IntegrationActionsTypes {
  DriveFileItemToAddAdded = '[] Drive File Item to Add Added',
  DriveFileItemToAddRemoved = '[] Drive File Item to Add Removed',
  DriveFolderItemToAddAdded = '[] Drive Folder Item to Add Added',
  DriveFolderItemToAddRemoved = '[] Drive Folder Item to Add Removed',
  DriveFileItemToRemoveAdded = '[] Drive File Item to Remove Added',
  DriveFileItemToRemoveRemoved = '[] Drive File Item to Remove Removed',
  DriveFolderItemToRemoveAdded = '[] Drive Folder Item to Remove Added',
  DriveFolderItemToRemoveRemoved = '[] Drive Folder Item to Remove Removed',
  ClearDriveInformation = '[] Clear Drive Information',
}

export class DriveFileItemToAddAdded implements Action {
  readonly type = IntegrationActionsTypes.DriveFileItemToAddAdded;

  constructor(public payload: { item: DriveItem }) {}
}

export class DriveFileItemToAddRemoved implements Action {
  readonly type = IntegrationActionsTypes.DriveFileItemToAddRemoved;

  constructor(public payload: { id: string }) {}
}

export class DriveFolderItemToAddAdded implements Action {
  readonly type = IntegrationActionsTypes.DriveFolderItemToAddAdded;

  constructor(public payload: { item: DriveItem }) {}
}

export class DriveFolderItemToAddRemoved implements Action {
  readonly type = IntegrationActionsTypes.DriveFolderItemToAddRemoved;

  constructor(public payload: { id: string }) {}
}

export class DriveFileItemToRemoveAdded implements Action {
  readonly type = IntegrationActionsTypes.DriveFileItemToRemoveAdded;

  constructor(public payload: { item: DriveItem }) {}
}

export class DriveFileItemToRemoveRemoved implements Action {
  readonly type = IntegrationActionsTypes.DriveFileItemToRemoveRemoved;

  constructor(public payload: { id: string }) {}
}

export class DriveFolderItemToRemoveAdded implements Action {
  readonly type = IntegrationActionsTypes.DriveFolderItemToRemoveAdded;

  constructor(public payload: { item: DriveItem }) {}
}

export class DriveFolderItemToRemoveRemoved implements Action {
  readonly type = IntegrationActionsTypes.DriveFolderItemToRemoveRemoved;

  constructor(public payload: { id: string }) {}
}

export class ClearDriveInformation implements Action {
  readonly type = IntegrationActionsTypes.ClearDriveInformation;
}

export type IntegrationActions =
  | DriveFileItemToAddAdded
  | DriveFileItemToAddRemoved
  | DriveFolderItemToAddAdded
  | DriveFolderItemToAddRemoved
  | DriveFileItemToRemoveAdded
  | DriveFileItemToRemoveRemoved
  | DriveFolderItemToRemoveAdded
  | DriveFolderItemToRemoveRemoved
  | ClearDriveInformation;
