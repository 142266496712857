export class SdRadioGroupInput {
  constructor(
    public key: string,
    public title?: string,
    public description?: string,
    public customValue?: string,
    public allowAnswer?: boolean,
    public allowAnswerRequired?: boolean,
    public disabled?: boolean
  ) {}
}
