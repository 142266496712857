import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import {
  featureName as startFlowFeatureName,
  startFlowReducer,
} from '../../../start-flow/store/start-flow.reducer';
import {
  accountReducer,
  featureName as accountFeatureName,
} from '../../../account/store/account.reducer';
import { EffectsModule } from '@ngrx/effects';
import { AccountEffects } from '../../../account/store/account.effects';
import { DictionaryModule } from '../../../dictionary/dictionary.module';
import { SidedrawerNetworksModule } from '../../../sidedrawer-networks/sidedrawer-networks.module';
import { MatDialogModule } from '@angular/material/dialog';
import { TdStartFlowEffects } from './store/td-start-flow.effects';
import { TdCoreModule } from '../core/td-core.module';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(startFlowFeatureName, startFlowReducer),
    StoreModule.forFeature(accountFeatureName, accountReducer),
    EffectsModule.forFeature([TdStartFlowEffects, AccountEffects]),
    DictionaryModule,
    SidedrawerNetworksModule,
    MatDialogModule,
    TdCoreModule,
  ],
})
export class TdStartFlowModule {}
