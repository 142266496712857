import { AuthActions, AuthActionsTypes } from './auth.actions';
import { AuthUser } from '../models/auth-user.model';

export const featureName = 'auth';

export interface AuthState {
  token: string;
  user: Partial<AuthUser>;
}

export const initialAuthState: AuthState = {
  token: null,
  user: null,
};

export function authReducer(
  state: AuthState = initialAuthState,
  action: AuthActions
): AuthState {
  switch (action.type) {
    case AuthActionsTypes.Login:
      return {
        ...state,
        token: action.payload.token,
        user: action.payload.user,
      };
    case AuthActionsTypes.Logout:
      return initialAuthState;
    case AuthActionsTypes.TokenUpdate:
      return {
        ...state,
        token: action.payload.token,
      };
    case AuthActionsTypes.AuthUserUpdate:
      return {
        ...state,
        user: action.payload.user,
      };
    default:
      return state;
  }
}
