<app-sd-autocomplete-a11y
  (optionCountrySelected)="controller.setValue($event.countryName)"
  [template]="sdAutoCompleteA11yEnum.country"
  [controller]="controller"
  [error]="placeholder + ' ' + error"
  [placeholder]="placeholder"
  [readonly]="false"
  [setOptionsCountry]="filteredOptions | async"
  [setSdAccessibility]="sdAccessibility">
</app-sd-autocomplete-a11y>
