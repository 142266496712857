import { Action } from '@ngrx/store';
import { AuthUser } from '../models/auth-user.model';

export enum AuthActionsTypes {
  AuthorizeUser = '[Authorize Component] Authorize User',
  Login = '[Authorize Component] LogIn',
  Logout = '[Auth Effect / Left Menu] LogOut',
  TokenUpdate = '[Auth Effect] Auth Token Update',
  AuthUserUpdate = '[Auth Effect] Auth User Update',
  RefreshTokenRequired = '[Auth Effect] Refresh Token Required',
  DisplayInactiveTimeWarning = '[Auth Effect] Display Inactive Time Warning',
  StartInactivityTime = '[Auth Effect] Start Inactivity time subscription',
  StartRefreshTokenTime = '[Auth Effect] Stop Refresh Token time subscription',
}

export class AuthorizeUser implements Action {
  readonly type = AuthActionsTypes.AuthorizeUser;
}

export class LogIn implements Action {
  readonly type = AuthActionsTypes.Login;

  constructor(public payload: { token: string; user }) {}
}

export class LogOut implements Action {
  readonly type = AuthActionsTypes.Logout;
}

export class TokenUpdate implements Action {
  readonly type = AuthActionsTypes.TokenUpdate;

  constructor(public payload: { token: string }) {}
}

export class AuthUserUpdate implements Action {
  readonly type = AuthActionsTypes.AuthUserUpdate;

  constructor(public payload: { user: AuthUser }) {}
}

export class RefreshTokenRequired implements Action {
  readonly type = AuthActionsTypes.RefreshTokenRequired;
}

export class DisplayInactiveTimeWarning implements Action {
  readonly type = AuthActionsTypes.DisplayInactiveTimeWarning;
}

export class StartInactivityTime implements Action {
  readonly type = AuthActionsTypes.StartInactivityTime;
}

export class StartRefreshTokenTime implements Action {
  readonly type = AuthActionsTypes.StartRefreshTokenTime;
}

export type AuthActions =
  | AuthorizeUser
  | LogIn
  | LogOut
  | TokenUpdate
  | AuthUserUpdate
  | RefreshTokenRequired
  | DisplayInactiveTimeWarning
  | StartInactivityTime
  | StartRefreshTokenTime;
